import { useState, useEffect } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import {
    useGetEmployeeProfileById,
    useUpdateEmployeeProfile,
} from "../../../api/dataSource";
import { useParams } from "react-router";
import { useGetEmployeePerformanceById } from "../../../api/EmployeePerformance/Queries";
import { Edit2, FileText } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import EmployeePerformanceEvaluationModal from "./EmployeePerformanceEvaluationModal";

const dateFormat = "d/m/Y";
const options = {
    dateFormat: dateFormat,
};
const tableHeaderData = [
    "Evaluation title",
    "Year",
    "Month",
    "Score",
    "remark",
    "Action",
];
const EmployeePerformanceEvaluation = () => {
    const { id } = useParams();
    const { data: empData, refetch } = useGetEmployeeProfileById(id);
    const { data: employeePerformanceData, refetch: empPerformanceRefetch } = useGetEmployeePerformanceById(id)
    // console.log(employeePerformanceData?.getEmployeePerformanceDataByEmpId)
    const [selectedEmployeeForUpdate, setSelectedEmployeeForUpdate] = useState(null)
    const [showUpdateEmployeeModal, setShowUpdateEmployeeModal] = useState(false);
    const [employeeDetail, setEmployeeDetail] = useState({ id: id, empCode: "" })
    // console.log(employeeDetail)
    useState(null);

    function handleCloseUpdateProfile() {
        try {
            setShowUpdateEmployeeModal(!showUpdateEmployeeModal);

            // If the modal is being closed (was open before toggling)
            if (showUpdateEmployeeModal) {
                // refetchEmployeeProfile();
            }
        } catch (error) {
            // Handle errors and log them to the console
            console.error(error);
        }
    }


    useEffect(() => {
        if (empData) {
            employeeDetail.empCode = empData?.getEmployeeProfileById?.empCode
        }
    }, [empData]);

    return (
        <Row>
            <Col>
                <Card>
                    <CardHeader>
                        <CardTitle>
                            <div>
                                <h2>Quaterly Performance</h2>
                            </div>
                        </CardTitle>
                        <Button.Ripple
                            color="light"
                            onClick={(e) => {
                                handleCloseUpdateProfile();
                                setSelectedEmployeeForUpdate("add");
                            }}
                        >
                            Add performance
                        </Button.Ripple>
                    </CardHeader>
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableHeaderData.map((item) => {
                                    return <th key={uuidv4()}>{item}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {employeePerformanceData?.getEmployeePerformanceDataByEmpId?.map((item) => (
                                <tr key={uuidv4()}>
                                    <td>{item?.evaluationTitle}</td>
                                    <td>{item?.year}</td>
                                    <td>{item?.month}</td>
                                    <td>{item?.score}</td>
                                    <td>{item?.remark}</td>
                                    <td>
                                        <Button
                                            className="rounded-circle btn-icon"
                                            color="none"
                                            onClick={() => {
                                                handleCloseUpdateProfile();
                                                setSelectedEmployeeForUpdate(item);
                                            }}
                                        >
                                            <Edit2 size={15} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            </Col>
            {selectedEmployeeForUpdate && (
                <EmployeePerformanceEvaluationModal
                    employeeDetails={employeeDetail}
                    showModal={showUpdateEmployeeModal}
                    onClose={handleCloseUpdateProfile}
                    data={selectedEmployeeForUpdate}
                    // allHostelsData={allHostelsData?.getHostelsNameandId}
                    refetchEmployeeProfile={empPerformanceRefetch}
                />
            )}
        </Row>
    )
}

export default EmployeePerformanceEvaluation