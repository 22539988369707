import React, { useEffect, useState } from "react";
import StepOutProductTable from "./StepOutProductTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "@components/common/TablePagination";
import { useHistory } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetPaginatedStepOutProductList } from "../../../api/dataSource";

const StepOutProduct = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const { data, refetch } = useGetPaginatedStepOutProductList(limit, page);

  const history = useHistory();

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedStepOutProduct?.data.length > 0) {
      setTableData(data?.getPaginatedStepOutProduct?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedStepOutProduct?.count / limit));
  }, [
    data?.getPaginatedStepOutProduct?.count,
    data?.getPaginatedStepOutProduct?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedStepOutProduct?.data.length) {
      let editData = data?.getPaginatedStepOutProduct?.data?.find(
        (item) => item._id === id,
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedStepOutProduct?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/stepOut/edit/stepOutProduct/${id}`);
    } else {
      setId(id);
    }
  };

  const handleCreateUpdateStepOutProduct = (id) => {
    history.push(`/stepOut/edit/stepOutProduct/${id}`);
  };

  return (
      <div>
      <StepOutProductTable
        setId={setId}
        id={id}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        handleEdit={handleEdit}
        handleCreateUpdateStepOutProduct={handleCreateUpdateStepOutProduct}
        refetch
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
        setId={setId}
      />
    </div>
  );
};

export default StepOutProduct;
