import React, { useEffect, useState } from "react";
import { useGetHourlyReportOTA } from "../../api/dataSource";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { dateValidator } from "../../utility/Utils";
import toaster from "@components/common/Toaster";
import HourlyReport from "./OtaHourlyReport";
import { selectThemeColors } from "@utils";
import Select from "react-select";
import { options } from "./dropDownOptions";
const OTAHourlyReport = () => {
  const [tableData, setTableData] = useState([]);
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [searchFromDate, setSearchFromDate] = useState(null);
  const [searchToDate, setSearchToDate] = useState(null);
  const [source, setSource] = useState([]);
  const [finalSource, setFinalSource] = useState([]);
  // console.log(source);
  const { data, refetch } = useGetHourlyReportOTA(
    searchFromDate,
    searchToDate,
    finalSource
  );
  // console.log(searchFromDate);
  // console.log(searchToDate);

  useEffect(() => {
    if (data?.get_hourly_report_ota) {
      setTableData(data?.get_hourly_report_ota);
    } else {
      setTableData([]);
    }
  }, [data]);

  const submitHandler = () => {
    if (dateValidator(fromDate, toDate)) {
      setSearchFromDate(fromDate);
      setSearchToDate(toDate);
      setFinalSource(source?.map((item) => item.value));
    } else {
      toaster("error", "Invalid Dates");
    }
  };

  const handleResetData = () => {
    setSearchFromDate(dateFormatter(new Date()));
    setSearchToDate(dateFormatter(new Date()));
    setFromDate(dateFormatter(new Date()));
    setToDate(dateFormatter(new Date()));
    setSource([]);
  };
  return (
    <div>
      <Row style={{ marginBottom: "40px" }}>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              From Date *
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromDate}
              onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
              id="fromDate"
            />
          </FormGroup>
        </Col>

        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              To Date *
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toDate}
              onChange={(date) => setToDate(dateFormatter(new Date(date)))}
              id="fromDate"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="Source">
              <strong>Source</strong>
            </Label>
            <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="source"
                name="source"
                value={source}
                options={options}
                onChange={(value) => {
                  setSource(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </FormGroup>
        </Col>
        <Col sm="4" style={{ marginTop: "22px" }}>
          <Button.Ripple
            onClick={submitHandler}
            className="ml-2 w-25"
            color="primary"
          >
            Search
          </Button.Ripple>

          <Button.Ripple
            onClick={handleResetData}
            className="ml-2 w-25"
            color="danger"
          >
            Reset
          </Button.Ripple>
        </Col>
      </Row>
      {tableData?.length > 0 ? (
        <HourlyReport data={tableData} refetch={refetch} />
      ) : (
        <h2 style={{ marginTop: "150px", textAlign: "center" }}>
          No Record found
        </h2>
      )}
    </div>
  );
};

export default OTAHourlyReport;
