import React from "react";
import { Edit } from "react-feather";
import { Badge, Button } from "reactstrap";
import { dateFormatHandler } from "@utils";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";

const TableBody = ({ tableRowData, openModal, handleEdit }) => {
  return (
    <>
      {tableRowData.map((item) => (
        <tr key={item?._id}>
          <td style={{ width: "5%" }}>
            <Button
              className="rounded-circle btn-icon"
              color="none"
              onClick={(e) => {
                e.preventDefault();
                openModal(true);
                handleEdit(item?._id);
              }}
            >
              <Edit
                color={reactFeatherIcon.iconColor}
                size={reactFeatherIcon.iconSize}
              />
            </Button>
          </td>
          <td>
            <span className="align-middle font-weight-bold">
              {item?.memberShipType}
            </span>
          </td>
          <td>
            <span className="align-middle font-weight-bold">
              {item?.hostelId?.name}
            </span>
          </td>
          <td>{item?.roomName}</td>
          <td>
            <Badge
              pill
              color={item?.isActive ? "light-success" : "light-danger"}
              className="mr-1"
            >
              {item?.isActive ? "Active" : "Inactive"}
            </Badge>
          </td>
          <td>
            {dateFormatHandler(item?.checkInDate)} -{" "}
            {dateFormatHandler(item?.checkOutDate)}
          </td>
          {/* <td>{item?.meal}</td> */}
          {/* <td>{dateFormatHandler(item?.createdAt)}</td> */}
        </tr>
      ))}
    </>
  );
};

export default TableBody;
