import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroupText,
  InputGroup,
  FormText,
} from "reactstrap";
import SwitchIcon from "@components/common/switch-icon";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";
import {
  // useAddHostel,
  useUpdateHostel,
  useAllAmenities,
} from "../../../../api/dataSource";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import { useParams } from "react-router";
import SaveDiscardBtnGroup from "./save-discard-btn-group";
import { tableDataHandler } from "@store/Cms/cms.action";

const animatedComponents = makeAnimated();

const DetailsForm = () => {
  const { id } = useParams();
  const stateData = useSelector((state) => state?.cms);
  const [name, setName] = useState("");
  const [brand, setBrand] = useState(null);
  const [businessType, setBusinessType] = useState(null);
  const [hotelCode, setHotelCode] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [locationUrl, setLocationUrl] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [reviewUrl, setReviewUrl] = useState("");
  const [onAmenities, setOnAmenities] = useState([]);
  const [offAmenities, setOffAmenities] = useState([]);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [reviewLinkOne, setReviewLinkOne] = useState("");
  const [reviewLinkTwo, setReviewLinkTwo] = useState("");
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [status, setStatus] = useState(false);
  const [isWorkation, setIsWorkation] = useState(false);
  const [RegistrationDetails, setRegistrationDetails] = useState("");

  const dispatch = useDispatch();

  // const [addHostelHandler, { data }] = useAddHostel();
  const [updateHostelHandler, { data: updatdedHostelData }] = useUpdateHostel();
  const { data: amenitiesData } = useAllAmenities();

  const BrandList = [
    { label: "The Hosteller", value: "theHosteller" },
    { label: "Bam", value: "Bam" },
  ];

  const BusinessTypeList = [
    { label: "COCO", value: "COCO" },
    { label: "FOFO", value: "FOFO" },
    { label: "FOCO", value: "FOCO" },
    { label: "OMC", value: "OMC" },
  ];

  // useEffect(() => {
  //   if (data?.createHostel?.name || updatdedHostelData) {
  //     if (data?.createHostel?.name) {
  //       toaster("success", "Hostel added successfully");
  //     }

  //     if (updatdedHostelData) {
  //       toaster("success", "Hostel updated successfully");
  //     }
  //     refetch();
  //     handleToggle();
  //   }
  // }, [data, updatdedHostelData]);

  console.log(stateData?.location, "stateData");

  useEffect(() => {
    if (amenitiesData?.getAllAminities?.data?.length) {
      amenitiesData?.getAllAminities?.data?.map((item) => {
        setAmenitiesList((prev) => [
          ...prev,
          { ...item, label: item?.name, value: item?._id },
        ]);
      });
    }
  }, [amenitiesData?.getAllAminities?.data]);

  const formatEmenity = (amenities) => {
    setOnAmenities([]);
    setOffAmenities([]);

    amenities?.map((e) => {
      if (e.amenity?.name) {
        if (e.status) {
          setOnAmenities((prev) => [
            ...prev,
            { ...e.amenity, label: e.amenity?.name, value: e.amenity?._id },
          ]);
        } else {
          setOffAmenities((prev) => [
            ...prev,
            { ...e.amenity, label: e.amenity?.name, value: e.amenity?._id },
          ]);
        }
      }
      return;
    });
  };

  useEffect(() => {
    if (stateData?.tableData) {
      const {
        // address: { addressLine1, addressLine2, city, state, pincode },
        address,
        email,
        phone,
        name,
        amenities,
        locationUrl,
        reviewUrl,
        status,
        // ezeeConfig: { hotelCode, authCode },
        ezeeConfig,
        reviewUrlList,
        isWorkation,
        RegistrationDetails,
      } = stateData?.tableData;

      formatEmenity(amenities);

      setReviewLinkOne(reviewUrlList?.one);
      setReviewLinkTwo(reviewUrlList?.two);
      setName(name);
      setEmail(email);
      setPhone(phone);
      setAddressLine1(address?.addressLine1);
      setAddressLine2(address?.addressLine2);
      setCity(address?.city);
      setState(address?.state);
      setPincode(address?.pincode);
      setAuthCode(ezeeConfig?.authCode);
      setHotelCode(ezeeConfig?.hotelCode);
      setLocationUrl(locationUrl);
      setLatitude(
        stateData?.tableData?.location?.latitude === null
          ? ""
          : stateData?.tableData?.location?.latitude
      );
      setLongitude(
        stateData?.tableData?.location?.longitude === null
          ? ""
          : stateData?.tableData?.location?.longitude
      );
      setReviewUrl(reviewUrl);
      setStatus(status);
      setIsWorkation(isWorkation);
      setBrand(
        stateData?.tableData?.brand !== null
          ? {
              label:
                stateData?.tableData?.brand === "theHosteller"
                  ? "The Hosteller"
                  : "Bam",
              value: stateData?.tableData?.brand,
            }
          : null
      );
      setRegistrationDetails(
        RegistrationDetails !== null ? RegistrationDetails : ""
      );
      setBusinessType(
        stateData?.tableData?.businessType !== null
          ? {
              label: stateData?.tableData?.businessType,
              value: stateData?.tableData?.businessType,
            }
          : null
      );
    } else {
      setReviewLinkOne("");
      setReviewLinkTwo("");
      setName("");
      setEmail("");
      setPhone("");
      setAddressLine1("");
      setAddressLine2("");
      setCity("");
      setState("");
      setPincode("");
      setAuthCode("");
      setHotelCode("");
      setLocationUrl("");
      setLatitude("");
      setLongitude("");
      setReviewUrl("");
      setOnAmenities([]);
      setOffAmenities([]);
      setStatus(false);
      setIsWorkation(false);
      setBrand(null);
      setRegistrationDetails("");
      setBusinessType(null);
    }
  }, [stateData]);

  const submitHandler = (e) => {
    e.preventDefault();
    const validMapLink = "https://www.google.com/maps";

    if (!locationUrl?.includes(validMapLink))
      return toaster("error", "Invalid google map link");

    if (phone.length !== 10)
      return toaster("error", "Please add valid phone number");

    if (!email.includes("@") || !email.includes("."))
      return toaster("error", "Please add valid email");

    const updatedAmenities = onAmenities?.map((item) => item._id);

    const amenities = [];

    onAmenities.map((e) => {
      amenities.push({ amenity: e._id, status: true });
    });

    offAmenities.map((e) => {
      amenities.push({ amenity: e._id, status: false });
    });

    let data = {
      name: name,
      phone: phone,
      email: email,
      locationUrl,
      reviewUrl,
      status,
      ezeeConfig: {
        hotelCode: hotelCode,
        authCode: authCode,
      },
      reviewUrlList: {
        one: reviewLinkOne,
        two: reviewLinkTwo,
      },
      amenities,
      address: {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        pincode: pincode,
      },
      location: {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
      },
      isWorkation,
      // url: `/hostels/${name.toLowerCase().split(" ").join("-")}`,
      brand: brand?.value,
      RegistrationDetails,
      businessType: businessType?.value,
    };

    // console.log(data, "DATA");

    if (
      name &&
      phone &&
      email &&
      locationUrl &&
      reviewUrl &&
      updatedAmenities?.length &&
      hotelCode &&
      authCode &&
      addressLine1 &&
      city &&
      state &&
      pincode &&
      reviewLinkOne &&
      latitude &&
      longitude &&
      brand
      // && amenities?.length
    ) {
      if (stateData) {
        updateHostelHandler(id, data);
        toaster("success", "Hostel updated successfully");
        dispatch(
          tableDataHandler({
            ...stateData?.tableData,
            address: { addressLine1, addressLine2, city, state, pincode },
            email,
            phone,
            name,
            amenities,
            locationUrl,
            reviewUrl,
            status,
            ezeeConfig: { hotelCode, authCode },
            isWorkation,
            RegistrationDetails,
          })
        );
      }
      // else {
      //   addHostelHandler(data);
      //   toaster("success", "Hostel added successfully");
      // }

      // setName("");
      // setEmail("");
      // setPhone("");
      // setAddressLine1("");
      // setAddressLine2("");
      // setCity("");
      // setState("");
      // setPincode("");
      // setAuthCode("");
      // setCode("");
      // setLocationUrl("");
      // setLatitude("");
      // setLongitude("");
      // setReviewLinkOne("");
      // setReviewLinkTwo("");
      // setOnAmenities([]);
      // setOffAmenities([]);
      // setStatus(false);
      // setIsWorkation(false);
      // setBrand(null);
      // setRegistrationDetails("");
      // setBusinessType(null);
    } else {
      toaster("error", "Please fill all the required details marked with (*)");
    }
  };

  const handleChangeReviewUrl = (e) => {
    if (e.target.id === "reviewLinkOne") {
      setReviewUrl(reviewLinkOne);
    } else if (e.target.id === "reviewLinkTwo") {
      setReviewUrl(reviewLinkTwo);
    } else setReviewUrl();
  };

  return (
    <>
      <form className="mt-2" onSubmit={submitHandler}>
        {/* <Row className='mt-2'>
          <Col sm='6' md='6'>
            <FormGroup>
              <Label className='form-label' for='name'>
                Hostel Name
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={name}
                id='name'
                name='name'
                placeholder='Add Hostel Name'
              />
            </FormGroup>
          </Col>
          <Col sm='6' md='6'>
            <FormGroup>
              <Label className='form-label' for='name'>
                Property Code
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={hostelCode}
                id='name'
                name='name'
                placeholder='Add Hostel Name'
              />
            </FormGroup>
          </Col>

          <Col sm='6' md='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='phone'>
                Phone
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={phone}
                id='phone'
                name='phone'
                placeholder='Phone'
              />
            </FormGroup>
          </Col>
          <Col sm='6' md='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='phone'>
                Email
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={email}
                id='phone'
                name='phone'
                placeholder='Phone'
              />
            </FormGroup>
          </Col>
          <Col sm='6' md='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='addressLine1'>
                Address Line 1
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={addressLine1}
                id='addressLine1'
                name='addressLine1'
                placeholder='Add Address'
              />
            </FormGroup>
          </Col>
          <Col sm='6' md='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='addressLine2'>
                Address Line 2
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={addressLine2}
                id='addressLine2'
                name='addressLine2'
                placeholder='Add Address'
              />
            </FormGroup>
          </Col>
          <Col sm='6' md='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='city'>
                City
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={city}
                id='city'
                name='city'
                placeholder='Add City'
              />
            </FormGroup>
          </Col>
          <Col sm='6' md='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='state'>
                State
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={hostelState}
                id='state'
                name='state'
                placeholder='Add State'
              />
            </FormGroup>
          </Col>
          <Col sm='6' md='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='pincode'>
                Pincode
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={pincode}
                id='pincode'
                name='pincode'
                placeholder='Add Pincode'
              />
            </FormGroup>
          </Col>
          <Col sm='6' md='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='locationUrl'>
                Google Map Link
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={locationUrl}
                id='locationUrl'
                name='locationUrl'
                placeholder='Add Map Link'
              />
            </FormGroup>
          </Col>
          <Col sm='6' md='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='reviewUrl'>
                Review Link
              </Label>
              <Input
                type='text'
                disabled
                defaultValue={reviewUrl}
                id='reviewUrl'
                name='reviewUrl'
                placeholder='Add Review Link'
              />
            </FormGroup>
          </Col>
        </Row> */}

        <Row>
          <Col sm="6">
            <FormGroup>
              <Label for="onAmenities">Brand*</Label>
              <Select
                isSearchable={true}
                isClearable={false}
                id="staffRole"
                name="staffRole"
                value={brand}
                options={BrandList}
                onChange={(value) => setBrand(value)}
                className="react-select"
                classNamePrefix="select"
                isDisabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                Hostel Name*
              </Label>
              <Input
                type="text"
                value={name}
                id="name"
                name="name"
                placeholder="Add Hostel"
                onChange={(e) => setName(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="onAmenities">Business Type</Label>
              <Select
                isSearchable={true}
                isClearable={false}
                id="businessType"
                name="businessType"
                value={businessType}
                options={BusinessTypeList}
                onChange={(value) => setBusinessType(value)}
                className="react-select"
                classNamePrefix="select"
                isDisabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                Auth Code*
              </Label>
              <Input
                type="text"
                value={authCode}
                id="Auth Code"
                name="Auth Code"
                placeholder="Auth Code"
                onChange={(e) => setAuthCode(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                Property Code*
              </Label>
              <Input
                type="text"
                value={hotelCode}
                id="Property Code"
                name="Property Code"
                placeholder="Property Code"
                onChange={(e) => setHotelCode(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="phone">
                Phone*
              </Label>
              <Input
                type="number"
                value={phone}
                id="phone"
                name="phone"
                placeholder="Phone"
                onChange={(e) => setPhone(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="Email">
                Email*
              </Label>
              <Input
                type="email"
                value={email}
                id="Email"
                name="Email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="RegistrationDetails">
                Registration Details
              </Label>
              <Input
                type="text"
                value={RegistrationDetails}
                id="Registration Details"
                name="Registration Details"
                placeholder="Registration Details"
                onChange={(e) => setRegistrationDetails(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="addressLine1">
                Address Line 1*
              </Label>
              <Input
                type="text"
                value={addressLine1}
                id="addressLine1"
                name="addressLine1"
                placeholder="Add Address"
                onChange={(e) => setAddressLine1(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="addressLine2">
                Address Line 2
              </Label>
              <Input
                type="text"
                value={addressLine2}
                id="addressLine2"
                name="addressLine2"
                placeholder="Add Address"
                onChange={(e) => setAddressLine2(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="city">
                City*
              </Label>
              <Input
                type="text"
                value={city}
                id="city"
                name="city"
                placeholder="Add City"
                onChange={(e) => setCity(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="state">
                State*
              </Label>
              <Input
                type="text"
                value={state}
                id="state"
                name="state"
                placeholder="Add State"
                onChange={(e) => setState(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="pincode">
                Pincode*
              </Label>
              <Input
                type="number"
                value={pincode}
                id="pincode"
                name="pincode"
                placeholder="Add Pincode"
                onChange={(e) => setPincode(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label for="onAmenities">Amenities (Include)*</Label>
              <Select
                onChange={(value) => setOnAmenities(value)}
                isClearable={false}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={onAmenities}
                isMulti
                options={amenitiesList}
                className="react-select"
                classNamePrefix="select"
                isDisabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label for="offAmenities">Amenities (Exclude)*</Label>
              <Select
                onChange={(value) => setOffAmenities(value)}
                isClearable={false}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={offAmenities}
                isMulti
                options={amenitiesList}
                className="react-select"
                classNamePrefix="select"
                isDisabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="locationUrl">
                Google Map Link*
              </Label>
              <Input
                type="text"
                value={locationUrl}
                id="locationUrl"
                name="locationUrl"
                placeholder="Add Map Link"
                onChange={(e) => setLocationUrl(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
              <FormText className="text-muted">
                *the embedded link should start with :
                https://www.google.com/maps/
              </FormText>
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="latitude">
                Latitude*
              </Label>
              <Input
                type="text"
                value={latitude}
                id="latitude"
                name="latitude"
                placeholder="Add Latitude"
                onChange={(e) => setLatitude(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="longitude">
                Longitude*
              </Label>
              <Input
                type="text"
                value={longitude}
                id="longitude"
                name="longitude"
                placeholder="Add Longitude"
                onChange={(e) => setLongitude(e.target.value)}
                disabled={stateData?.tableData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="6" className="mt-2">
            <h4>Reviews</h4>
          </Col>
          <Col sm="6">
            <p>Add & Select any one review link</p>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="reviewUrl">
                Review Link One*
              </Label>
              <InputGroup>
                <InputGroupText className="rounded-0">
                  <div className="form-check">
                    <Input
                      type="radio"
                      id="reviewLinkOne"
                      name="reviewUrl"
                      // disabled={!reviewLinkOne}
                      checked={reviewUrl === reviewLinkOne}
                      onChange={handleChangeReviewUrl}
                      disabled={stateData?.tableData?._id}
                    />
                  </div>
                </InputGroupText>
                <Input
                  type="text"
                  id="reviewLinkOne"
                  name="reviewLinkOne"
                  value={reviewLinkOne}
                  onChange={(e) => setReviewLinkOne(e.target.value)}
                  placeholder="Review Link One"
                  disabled={stateData?.tableData?._id}
                />
              </InputGroup>
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="reviewUrl">
                Review Link Two
              </Label>
              <InputGroup>
                <InputGroupText className="rounded-0">
                  <div className="form-check">
                    <Input
                      type="radio"
                      id="reviewLinkTwo"
                      name="reviewUrl"
                      // disabled={!reviewLinkTwo}
                      checked={reviewUrl === reviewLinkTwo}
                      onChange={handleChangeReviewUrl}
                      disabled={stateData?.tableData?._id}
                    />
                  </div>
                </InputGroupText>
                <Input
                  type="text"
                  id="reviewLinkTwo"
                  name="reviewLinkTwo"
                  value={reviewLinkTwo}
                  onChange={(e) => setReviewLinkTwo(e.target.value)}
                  placeholder="Review Link Two"
                  disabled={stateData?.tableData?._id}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm="6" style={{ marginBottom: "6px" }}>
            <SwitchIcon
              checked={isWorkation}
              id="isWorkation"
              name="isWorkation"
              label="Is Workation ?"
              handleChecked={(val) => setIsWorkation(val)}
              disabled={stateData?.tableData?._id}
            />
          </Col>

          <Col sm="6">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Publish on Website ?"
              handleChecked={(val) => setStatus(val)}
              disabled={stateData?.tableData?._id}
            />
          </Col>
        </Row>
        <SaveDiscardBtnGroup disabled={stateData?.tableData?._id} />
      </form>
    </>
  );
};

export default DetailsForm;
