import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import { DragDrop } from "@uppy/react";
import { HOSTELLER } from "../../../../utility/constant";
import { useUpdatePolicy, useUploadImage } from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import toaster from "@components/common/Toaster";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const Hero = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState(null);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (state?.tableData) {
      setTitle(state.tableData.title);
      setImagePreview({ type: "existing", url: state?.tableData.image });
    }
  }, [state?.tableData]);

  const [updatePolicyHandler, { data, reset }] = useUpdatePolicy();

  const [uploadImageHandler, { loading: imageLoad, data: imageData }] =
    useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  useEffect(() => {
    if (data?.updatePolicy?._id) {
      toaster("success", "Hero details updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          title,
          image: imagePreview?.url,
        })
      );

      reset();
    }
  }, [data]);

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setImagePreview({ type: "new", url: preview });

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, `common/policy`);
    toaster("success", "Hero image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading hero image: ${error.message}`);
  });

  const renderPreview = () =>
    imagePreview ? (
      <img className="w-100 pb-4 mb-4" src={imagePreview?.url} alt="avatar" />
    ) : null;

  const submitDataHandler = (e) => {
    e.preventDefault();

    if (title) {
      const data = { title };

      imagePreview.type === "new" && (data.image = imageData?.uploadImage?.url);

      updatePolicyHandler(HOSTELLER, data);

      setImagePreview({ type: "existing", url: imageData?.uploadImage?.url });
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="title">
              Title
            </Label>

            <Input
              type="text"
              value={title}
              id="title"
              name="title"
              placeholder="Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6" className="mb-2">
          <Label className="form-label">Cover Image</Label>
          <DragDrop
            uppy={uppy}
            width="100%"
            note="Image size should be less than 500KB"
            locale={{
              strings: {
                dropHereOr: "Drop image here or %{browse}",
                browse: "browse",
              },
            }}
          />
        </Col>
        <Col sm="6">
          <Label className="form-label w-100">Image preview</Label>
          {imageLoad ? "Uploading image..." : renderPreview()}
        </Col>
      </Row>
      <SaveDiscardBtnGroup loading={imageLoad} />
    </form>
  );
};

export default Hero;
