import gql from "graphql-tag";

export const GET_OFFERS_PAGE = gql`
  query getOffersPage {
    getOffersPage {
      _id
      title
      description
      image
    }
  }
`;
