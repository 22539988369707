import { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import toaster from "@components/common/Toaster";

const UrlForm = ({ showModal, closeModal, imageLink, addImageLink }) => {
  const [imageTitle, setImageTitle] = useState("");
  const [dispOrder, setDispOrder] = useState("")

  //check valid url
  useEffect(() => {
    setImageTitle(imageLink.imageTitle);
    setDispOrder(imageLink.displayOrder)
  }, [imageLink.imageTitle, imageLink.displayOrder]);

  const handleClose = () => {
    closeModal();
    setImageTitle("");
    setDispOrder("")
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addImageLink(imageLink.index, imageTitle, dispOrder);
    handleClose();
    toaster("success", "Added successfully");
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={handleClose}
      ></ModalHeader>
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <div className="text-center mb-2">
          <h3>Add image details</h3>
        </div>
        <Row tag="form" className="gy-1 pt-75">
          <Col xs={12}>
            <Label className="form-label" for="imageTitle">
              Image Title
            </Label>

            <Input
              id="imageTitle"
              value={imageTitle}
              onChange={(e) => setImageTitle(e.target.value)}
              placeholder="Image title"
            />
          </Col>

          <Col xs={12}>
            <Label className="form-label" for="displayOrder">
              Display Order
            </Label>

            <Input
              id="displayOrder"
              value={dispOrder}
              onChange={(e) => setDispOrder(e.target.value)}
              placeholder="Display Order"
            />
          </Col>

          <Col xs={12} className="text-center mt-2">
            <Button onClick={handleSubmit} className="me-1" color="light mr-3">
              Proceed
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              onClick={handleClose}
            >
              Discard
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UrlForm;
