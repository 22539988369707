import React from "react";
import { Edit2, Eye, Trash } from "react-feather";
import { Badge, Button } from "reactstrap";
import { dateFormatHandler } from "@utils";

const WorkationTableBody = ({
  tableRowData,
  openModal,
  handleEdit,
  setShow,
  isPackage,
  handleDelete,
}) => {
  return (
    <>
      {tableRowData &&
        tableRowData.map((item) => (
          <tr key={item._id}>
            {item?.name && (
              <td>
                <span className='align-middle font-weight-bold'>
                  {item?.name}
                </span>
              </td>
            )}
            {item?.minStay && (
              <>
                <td>{item?.room?.roomName}</td>
                <td>{item?.minStay}</td>
                <td>{item?.discountPercentage}</td>
              </>
            )}
            <td>
              <Badge
                pill
                color={item?.status ? "light-success" : "light-danger"}
                className='mr-1'
              >
                {item?.status ? "Active" : "Inactive"}
              </Badge>
            </td>
            <td>{dateFormatHandler(item?.createdAt)}</td>
            <td>
              {isPackage && !item?.minStay && (
                <Button
                  className='rounded-circle btn-icon'
                  color='none'
                  onClick={() => setShow(item?.hostelId)}
                >
                  <Eye size={15} />
                </Button>
              )}

              {(!isPackage || item?.minStay) && (
                <>
                  <Button
                    className='rounded-circle btn-icon'
                    color='none'
                    onClick={(e) => {
                      e.preventDefault();

                      const id =
                        isPackage && !item?.minStay ? item.hostelId : item._id;

                      handleEdit(id);
                      openModal && openModal();
                    }}
                  >
                    <Edit2 size={15} />
                  </Button>
                </>
              )}
              {handleDelete && (
                <Button
                  className='rounded-circle btn-icon'
                  color='none'
                  onClick={(e) => {
                    e.preventDefault();
                    const id =
                      isPackage && !item?.minStay ? item._id : item.hostelId;
                    handleDelete(id);
                  }}
                >
                  <Trash size={15} />
                </Button>
              )}
            </td>
          </tr>
        ))}
    </>
  );
};

export default WorkationTableBody;
