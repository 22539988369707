import gql from "graphql-tag";

export const GET_PAGINATED_STEP_OUT_PRODUCT_LIST = gql`
  query getPaginatedStepOutProduct($filter: filter) {
    getPaginatedStepOutProduct(filter: $filter) {
      data {
        _id
        title
        location
        category {
          _id
          name
        }
        images {
          image
          name
          displayOrder
        }
        imageLandscape
        imagePortrait
        videoUrl
        basePrice
        tax
        keyDetails {
          duration
          difficulty
          batchSize
          altitude
        }
        shortDescription
        fullDescription
        inclusions {
          image
          name
          displayOrder
        }
        itinerary {
          name
          displayOrder
          title
          description
          accommodation
          activity
          meals
          transportation
          images
        }
        startDate
        duration
        pickupPoints {
          location
          address
          price
        }
        roomType {
          roomName
          price
          occupancy
          description
          maxInventory
        }
        inclusionInfo {
          title
          description
          displayOrder
        }
        exclusionInfo {
          title
          description
          displayOrder
        }
        otherInfo {
          title
          description
          displayOrder
        }
        similarProducts {
          _id
          title
        }
        seo {
          metaTitle
          metaDesc
          keywords
        }
        url
        organizer
        status
        faqs {
          question
          answer
        }
        policies
        batches {
          startDate
          basePrice
          status
        }
      }
      count
      page
      limit
    }
  }
`;

export const GET_STEP_OUT_PRODUCT_BY_ID = gql`
  query getStepOutProductById($id: ID) {
    getStepOutProductById(id: $id) {
      _id
      title
      location
      category {
        _id
        name
      }
      images {
        image
        name
        displayOrder
      }
      imageLandscape
      imagePortrait
      videoUrl
      basePrice
      tax
      keyDetails {
        duration
        difficulty
        batchSize
        altitude
      }
      shortDescription
      fullDescription
      inclusions {
        image
        name
        displayOrder
      }
      itinerary {
        name
        displayOrder
        title
        description
        accommodation
        activity
        meals
        transportation
        images
      }
      startDate
      duration
      pickupPoints {
        location
        address
        price
      }
      roomType {
        roomName
        price
        occupancy
        description
        maxInventory
      }
      inclusionInfo {
        title
        description
        displayOrder
      }
      exclusionInfo {
        title
        description
        displayOrder
      }
      otherInfo {
        title
        description
        displayOrder
      }
      similarProducts {
        _id
        title
      }
      seo {
        metaTitle
        metaDesc
        keywords
      }
      url
      organizer
      status
      faqs {
        question
        answer
      }
      policies
      batches {
        startDate
        basePrice
        status
      }
      tripUsp {
        imageUrl
        title
        description
        displayOrder
      }
    }
  }
`;

export const GET_STEP_OUT_PRODUCT_LIST = gql`
  query getAllStepOutProduct {
    getAllStepOutProduct {
      _id
      title
      location
      category {
        _id
        name
      }
      images {
        image
        name
        displayOrder
      }
      imageLandscape
      imagePortrait
      videoUrl
      basePrice
      tax
      keyDetails {
        duration
        difficulty
        batchSize
        altitude
      }
      shortDescription
      fullDescription
      inclusions {
        image
        name
        displayOrder
      }
      itinerary {
        name
        displayOrder
        title
        description
        accommodation
        activity
        meals
        transportation
        images
      }
      startDate
      duration
      pickupPoints {
        location
        address
        price
      }
      roomType {
        roomName
        price
        occupancy
        description
        maxInventory
      }
      inclusionInfo {
        title
        description
        displayOrder
      }
      exclusionInfo {
        title
        description
        displayOrder
      }
      otherInfo {
        title
        description
        displayOrder
      }
      similarProducts {
        _id
        title
      }
      seo {
        metaTitle
        metaDesc
        keywords
      }
      url
      organizer
      status
      faqs {
        question
        answer
      }
      policies
      batches {
        startDate
        basePrice
        status
      }
    }
  }
`;
