import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { competitors, occupancies, platforms, sortOptions } from "./options";
import { selectThemeColors } from "@utils";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import {
  convertDetailedFormatToDateString,
} from "../helpers";

function FilterSection({
  uniqueCompetitors,
  handleDateRangeChange,
  reviewDateFrom,
  reviewDateTo,
  handleCompetitorChange,
  handlePlatformChange,
  selectedOccupancy,
  handleOccupancyChange,
  sortOrder,
  // handleSortChange,
  hostelsList,
  handleHostelChange,
  hostel,
}) {
  const adjusttheDateAndhandleDateRangeChange = (dates) => {
    const [startDate, endDate] = dates;

    if (!startDate || !endDate) return;
    // Adjust the date range to handle lag of 1 day
    const adjustedStartDate = new Date(startDate);
    adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);

    // Adjust the date range to handle lag of 1 day
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

    handleDateRangeChange([adjustedStartDate, adjustedEndDate]);
  };
  return (
    <Row>
      <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 201 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              <strong>Hostel Name</strong>
            </Label>
            <Select
              // isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              options={hostelsList}
              onChange={(value) => {
                handleHostelChange(value);
                // setHostel(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col>

      <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Date Range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              id="reviewDate"
              placeholder="Select Date Range"
              options={{
                mode: "range",
              }}
              value={[
                reviewDateFrom,
                reviewDateTo,
              ]}
              onChange={(date) => {
                adjusttheDateAndhandleDateRangeChange(date);
              }}
            />
          </FormGroup>
        </div>
      </Col>

      <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Competitor</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="competitor"
              name="competitor"
              closeMenuOnSelect={false}
              options={uniqueCompetitors}
              onChange={handleCompetitorChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col>

      <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Platform</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="platform"
              name="platform"
              closeMenuOnSelect={false}
              options={platforms}
              onChange={handlePlatformChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col>

      <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Occupancy</strong>
            </Label>
            <Select
              isSearchable={true}
              theme={selectThemeColors}
              id="occupancy"
              name="occupancy"
              value={selectedOccupancy}
              options={occupancies}
              onChange={handleOccupancyChange}
              className="basic-single-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col>

      {/* <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100 ">
            <Label className="form-label" for="url">
              <strong>Sort by</strong>
            </Label>
            <Select
              isSearchable={true}
              theme={selectThemeColors}
              id="sort"
              name="sort"
              value={sortOrder}
              options={sortOptions}
              onChange={handleSortChange}
              className="basic-single-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col> */}
    </Row>
  );
}

export default FilterSection;
