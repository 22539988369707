import React, { useEffect, useState } from "react";
import TravelPackagesTable from "../../../@core/components/tables/travelPackagesTable";
import { useHostelList } from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import AddEditModal from "./AddEditModal";

const TravelPackage = () => {
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [page, setPage] = useState(1);

  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    const HostelsList = allHostelsData?.getHostelList?.map((el) => {
      return { label: el.name, value: el._id };
    });
    setHostelsList(HostelsList);
  }, [allHostelsData]);

  const handleSearch = () => {
    const data = {
      hostelId: hostel?.value,
    };
    setFilteredData(data?.hostelId);
    setPage(1);
  };

  const handleReset = () => {
    setHostel([]);
    setFilteredData(null);
    setPage(1);
  };

  return (
    localStorage.getItem("modules")?.includes("Packages") && (
      <>
        <div>
          <Row>
            <Col sm="4">
              <FormGroup className="w-100">
                <Label className="form-label" for="hostel">
                  <strong>Hostel Name</strong>
                </Label>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  id="hostel"
                  name="hostel"
                  value={hostel}
                  options={HostelsList}
                  onChange={(value) => {
                    setHostel(value);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            <Col className="mb-2">
              <Button
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="white"
                appearance="primary"
                type="button"
                onClick={() => {
                  handleSearch();
                }}
              >
                Search
              </Button>

              <Button
                className="pull-right"
                style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  marginLeft: "40px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="danger"
                appearance="primary"
                type="button"
                onClick={() => {
                  handleReset();
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </div>
        <TravelPackagesTable
          AddEditModal={AddEditModal}
          hostel={hostel}
          page={page}
          setPage={setPage}
          setHostel={setHostel}
          filteredData={filteredData}
        ></TravelPackagesTable>
      </>
    )
  );
};

export default TravelPackage;
