import React, { useEffect, useState } from "react";
import AddEditModal from "./AddEditModal";
import ReplyTemplateTable from "./ReplyTemplateTable";
import TablePagination from "@components/common/TablePagination";
import { useHistory } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button, Form } from "reactstrap";
import Select from "react-select";
import {
  useGetAllPaginatedReplyTemplate,
  useHostelList,
} from "../../api/dataSource";
import { selectThemeColors } from "@utils";

const ReplyTemplate = () => {
  const [hostel, setHostel] = useState(null);

  const [HostelsList, setHostelsList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [filteredData, setFilteredData] = useState({
    hostel: JSON.parse(localStorage.getItem("hostels")),
  });

  const { data, refetch } = useGetAllPaginatedReplyTemplate(
    limit,
    page,
    filteredData
  );
  const { data: HostelsData } = useHostelList();

  const history = useHistory();

  const refreshHandler = () => {
    setHostel([]);
    setFilteredData(null);
  };

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    const allowedHostel = JSON.parse(localStorage.getItem("hostels"));

    if (HostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostel !== null && allowedHostel?.length !== 0
          ? allowedHostel?.map((hostelId) => {
              return HostelsData?.getHostelList?.find(
                (item) => item?._id === hostelId
              );
            })
          : HostelsData?.getHostelList;

      let hostelList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(hostelList);
    }
  }, [HostelsData]);

  useEffect(() => {
    if (data?.getPaginatedReplyTemplate?.data.length > 0) {
      setTableData(data?.getPaginatedReplyTemplate?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedReplyTemplate?.count / limit));
  }, [
    data?.getPaginatedReplyTemplate?.count,
    data?.getPaginatedReplyTemplate?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedReplyTemplate?.data.length) {
      let editData = data?.getPaginatedReplyTemplate?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedReplyTemplate?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/orm/edit/replyTemplate/${id}`);
    } else {
      setId(id);
    }
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    const data = {
      hostel: hostel?.map((item) => item.value),
    };
    setFilteredData(data);
  };

  return (
    localStorage.getItem("modules")?.includes("ORM Configuration") && (
      <div>
        <Form onSubmit={handleSubmit}>
          <Row>
            {/* // ! Hostel */}
            <Col sm="6">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="hostel">
                    Hostel
                  </Label>
                </strong>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  theme={selectThemeColors}
                  isMulti
                  id="hostel"
                  name="hostel"
                  value={hostel}
                  options={HostelsList}
                  onChange={(value) => {
                    setHostel(value);
                  }}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            {/* <Col sm="1">
              <Button.Ripple
                color="light"
                type="submit"
                style={{ marginTop: "21px" }}
              >
                Search
              </Button.Ripple>
            </Col> */}
            <Col sm="3">
              <Button
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="white"
                appearance="primary"
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Search
              </Button>

              <Button
                style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  marginLeft: "40px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="danger"
                appearance="primary"
                type="button"
                onClick={() => {
                  refreshHandler();
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
        <ReplyTemplateTable
          setId={setId}
          id={id}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          handleEdit={handleEdit}
          refetch={refetch}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
          setId={setId}
        />
      </div>
    )
  );
};

export default ReplyTemplate;
