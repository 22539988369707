import { useEffect, useState } from "react";
import { Edit } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Table,
  Badge,
  Button,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { dateFormatHandler } from "@utils";
import NotSatisfiedIcon from "./svgIcons/NotSatisfiedIcon.svg";
import NeutralIcon from "./svgIcons/NeutralIcon.svg";
import SatisfiedIcon from "./svgIcons/SatisfiedIcon.svg";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Checkin - out Date",
  "Hostel Name",
  "Guest Name",
  "Mobile Number",
  "Reservation ID",
  "Customer Feedback",
  "Customer Remark",
  "SM Status",
  "CX Status",
  "SM TAT (hours)",
  "Follow up Feedback",
];

const FeedbackTable = ({
  openModal,
  setId,
  setReservationId,
  data,
  refetch,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  // const feedbackIcons = {
  //   Satisfied: <img src={SatisfiedIcon} alt="Satisfied Icon" />,
  //   Neutral: <img src={NeutralIcon} alt="Neutral Icon" />,
  //   NotSatisfied: <img src={NotSatisfiedIcon} alt="Not Satisfied Icon" />,
  // };

  const feedbackIcons = {
    Satisfied: (
      <img
        src={SatisfiedIcon}
        alt="Satisfied Icon"
        style={{ width: "50px", height: "30px" }}
      />
    ),
    Neutral: (
      <img
        src={NeutralIcon}
        alt="Neutral Icon"
        style={{ width: "50px", height: "30px" }}
      />
    ),
    NotSatisfied: (
      <img
        src={NotSatisfiedIcon}
        alt="Not Satisfied Icon"
        style={{ width: "50px", height: "30px" }}
      />
    ),
  };

  const iconStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  //for response and feedback time
  const getTime = (dateParams) => {
    var created_date = new Date(dateParams);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = created_date.getFullYear();
    var month = months[created_date.getMonth()];
    var date = created_date.getDate();
    var hour = created_date.getHours();
    var min = created_date.getMinutes();
    var sec = created_date.getSeconds();
    var curtime =
      month + " " + date + " " + year + ", " + hour + ":" + min + ":" + sec;
    return curtime;
  };

  function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  function truncate(customerRemark, n) {
    return customerRemark.length > n
      ? customerRemark.slice(0, n - 1) + "..."
      : customerRemark;
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle></CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "5%" }}>
                    <Button
                      href="/"
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>
                    {item.checkinDate
                      ? dateFormatHandler(item.checkinDate)
                      : ""}{" "}
                    -{" "}
                    {item.checkinDate
                      ? dateFormatHandler(item.checkoutDate)
                      : ""}
                  </td>
                  <td>
                    <span className="font-weight-bold">{item.hostelName}</span>
                  </td>
                  <td>
                    <span className="font-weight-bold">{item.firstName}</span>
                  </td>
                  <td>{item.mobile}</td>
                  <td>{item.reservationId}</td>

                  <td>
                    <Badge
                      pill
                      color={
                        item?.customerFeedback === "Excellent"
                          ? "success"
                          : item?.customerFeedback === "Good"
                          ? "primary"
                          : "danger"
                      }
                      className="mr-1"
                    >
                      {item.customerFeedback ? item.customerFeedback : ""}
                    </Badge>
                  </td>
                  <td>
                    {
                      <span className="font-weight-bold">
                        {item.customerRemark
                          ? truncate(item.customerRemark, 30)
                          : ""}
                      </span>
                    }
                  </td>

                  <td>
                    <Badge
                      pill
                      color={
                        item?.feedbackStatus === "Closed"
                          ? "light-info"
                          : item?.feedbackStatus === "Resolved"
                          ? "light-success"
                          : item?.feedbackStatus === "Not Resolved"
                          ? "light-danger"
                          : item?.feedbackStatus === "Cannot connect with guest"
                          ? "light-primary"
                          : ""
                      }
                      className="mr-1"
                    >
                      {item.feedbackStatus ? item.feedbackStatus : ""}
                    </Badge>
                  </td>

                  <td>
                    <Badge
                      pills
                      Component
                      color={
                        item?.finalStatus === "Closed"
                          ? "light-warning"
                          : "light-dark"
                      }
                      className="mr-1"
                    >
                      {item.finalStatus ? item.finalStatus : ""}
                    </Badge>
                  </td>

                  <td>
                    {item.customerFeedback === "Bad" &&
                    item.feedbackTime &&
                    item.responseTime
                      ? (
                          Math.abs(
                            new Date(item.responseTime) -
                              new Date(item.feedbackTime)
                          ) /
                          (1000 * 60 * 60)
                        ).toFixed(2)
                      : ""}
                  </td>

                  <td style={{ textAlign: "center" }}>
                    {feedbackIcons[item.followupFeedback] && (
                      <div style={iconStyle}>
                        {feedbackIcons[item.followupFeedback]}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default FeedbackTable;
