import { Heart, Circle } from "react-feather";

export default localStorage.getItem("modules")?.includes("Loyalty")
  ? [
    {
      id: "loyalty",
      title: "Loyalty",
      icon: <Heart size={20} />,
      badge: "light-warning",
      children: [
        (localStorage.getItem("modules")?.includes("Loyalty")) && {
          id: "loyaltyCoins",
          title: "Loyalty Coins",
          icon: <Circle size={12} />,
          navLink: "/loyalty/loyaltyCoins",
        },
        {
          id: "loyaltyReport",
          title: " Loyalty Coins Usage",
          icon: <Circle size={12} />,
          navLink: "/report/loyaltyReport",
        },

      ],
    },
  ]
  : [];
