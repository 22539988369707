import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import styled from "styled-components";
import {
  useGetWebCheckinDataByReservationId,
  useMakeCheckin,
  useMakeCheckout,
  useRoomAssign,
} from "../../api/dataSource";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { formatDateToDDMMYYYY } from "./helpers";
import OffCanvasTable from "./OffCanvasTable";
import DefaultHosteller from "./icons/Logo-WxB 5.png";

const bookingSourceImages = {
  "booking.com":
    "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg",
  goibibo:
    "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg",
  hostelworld:
    "https://static.thehosteller.com/Hostel/Media/hostelworld%202%20(1)-1695295577721.svg",
  google:
    "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg",
  makemytrip:
    "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg",
  tripadvisor:
    "https://static.thehosteller.com/Hostel/Media/Tripadvisor%20(1)-1695295571996.svg",
};

const Offcanvas = ({
  open,
  handleToggle,
  large,
  hostelId,
  Guestname,
  contact,
  Guestemail,
  reservationNumber,
  totalAmount,
  Bookstatus,
  arrivalDate,
  departureDate,
  bookingDate,
  roomType,
  roomNumber,
  roomNumbers,
  selectedReservation,
  ArrivalTime,
  DepartureTime,
  bookingData,
  showGroupBooking,
  Deposit,
}) => {
  const ReservationInfoData = [
    { label: "Reservation Number", value: reservationNumber },
    { label: "Status", value: Bookstatus },
    { label: "Source", value: selectedReservation?.Source },
    { label: "Booking Date", value: bookingDate },
    {
      label: "Arrival Date",
      value: `${arrivalDate?.split(" ")[0]} ${ArrivalTime || ""}`,
    },
    {
      label: "Departure Date",
      value: `${departureDate?.split(" ")[0]} ${DepartureTime || ""}`,
    },
    { label: "Room Type", value: roomType },
    { label: "Room Number", value: roomNumber },
    { label: " ", value: " " }, //just for spacing
    { label: "Total Amount", value: totalAmount },
    { label: "Deposit Amount", value: Deposit },
    { label: "Due Amount", value: selectedReservation?.DueAmount },
  ].map((item) => {
    if (!item.value) {
      item.value = "--";
    }
    return item;
  });

  const todaysDate = formatDateToDDMMYYYY(
    new Date().toISOString().split("T")[0]
  );
  // console.log(departureDate);
  // console.log(departureDate?.split(" ")[0] === todaysDate);
  const { data: reservationData } =
    useGetWebCheckinDataByReservationId(reservationNumber);
  const reservationInfo = reservationData?.getWebcheckinDataByReservationId;

  function removeDecimalsInString(str) {
    return str.replace(/-?\d+(\.\d+)?/g, (match) => {
      return Math.round(parseFloat(match));
    });
  }

  const getRoomTypeList = () =>
    roomNumbers?.map((room) => {
      return {
        label: room?.Name,
        value: room?.ID,
      };
    });

  const [RoomTypeList, setRoomTypeList] = useState(getRoomTypeList());
  const [selectedRoomType, setSelectedRoomType] = useState([]);

  const [roomNumberList, setRoomNumberList] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);

  useEffect(() => {
    if (selectedRoomType) {
      // console.log(selectedRoomType);
      const list = [];
      roomNumbers
        ?.find((room) => room?.ID === selectedRoomType?.value)
        ?.Rooms?.map((room) => {
          list.push({
            label: room?.RoomName,
            value: room?.RoomID,
          });
        });
      // console.log(list);
      setRoomNumberList(list);
    }
  }, [selectedRoomType]);

  // console.log(RoomTypeList, roomNumberList);

  const { makeCheckinHandler } = useMakeCheckin();
  const { makeCheckoutHandler } = useMakeCheckout();
  const { roomAssignHandler } = useRoomAssign();

  // console.log(hostelId);
  const onMakeCheckin = async () => {
    try {
      const response = await makeCheckinHandler(
        hostelId,
        reservationInfo?.reservationID,
        reservationInfo?.firstName,
        reservationInfo?.email,
        reservationInfo?.residingCity,
        reservationInfo?.phoneNumber,
        reservationInfo?.phoneNumber
      );

      // console.log(response?.data);
      if (response?.data?.checkin?.Error[0]?.ErrorMessage) {
        toaster("error", response?.data?.checkin?.Error[0]?.ErrorMessage);
      } else {
        toaster("success", "Checkin Successful!");
      }
    } catch (err) {
      // console.error(err);
      toaster("error", "An error occurred. Please try again later.");
    }
  };

  const onMakeCheckout = async () => {
    try {
      const response = await makeCheckoutHandler(
        hostelId,
        reservationInfo?.reservationID
      );

      // console.log(response?.data);
      // console.log(response.e);
      if (response?.data?.checkout?.Error[0]?.ErrorMessage) {
        toaster("error", response?.data?.checkout?.Error[0]?.ErrorMessage);
      } else {
        toaster("success", "Checkout Successful!");
      }
    } catch (err) {
      toaster("error", "An error occurred. Please try again later.");
    }
  };

  const onChangeRoomSubmit = async () => {
    try {
      const response = await roomAssignHandler(
        hostelId,
        reservationNumber,
        selectedRoomType?.value,
        selectedRoom?.value
      );
      if (response?.data?.room_assign?.Errors[0]?.ErrorMessage) {
        toaster("error", response?.data?.room_assign?.Errors[0]?.ErrorMessage);
      } else {
        toaster("success", "Room Assignment Successful!");
      }
    } catch (err) {
      console.log(err);
      toaster("error", "An error occurred. Please try again later.");
    }
  };

  const getInclusion = () => {
    console.log(selectedReservation);
    try {
      return Object.values(selectedReservation?.ExtraCharges).flat();
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const sanitizeCharacter = (str) => {
    return str.replace(/\s+/g, "").toLowerCase();
  };

  return (
    <StyledModalWrap
      unmountOnClose={true}
      isOpen={open}
      toggle={handleToggle}
      className={
        large
          ? "ml-auto m-0 modal-lg position-relative"
          : "ml-auto m-0 position-relative"
      }
    >
      <ModalHeader toggle={handleToggle}>Details</ModalHeader>
      <ModalBody>
        <main style={{ width: "100%" }}>
          {/* icons of sources  */}
          {bookingSourceImages[
            sanitizeCharacter(selectedReservation?.Source)
          ] && (
            <section style={{ position: "absolute", top: 10, right: 12 }}>
              <img
                style={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "100%",
                  objectFit: "contain",
                  border: "1px solid #ddd",
                  padding: "5px",
                }}
                src={
                  bookingSourceImages[
                    sanitizeCharacter(selectedReservation?.Source)
                  ]
                }
                alt={selectedReservation?.Source}
              />
            </section>
          )}
          <section style={{ width: "100%" }}>
            <p style={{ fontSize: "20px" }}>
              <strong>{Guestname}</strong>
            </p>

            <span style={{}}>
              <strong>Contact</strong> : {contact}
            </span>
            <br />

            <span>
              <strong>Email</strong> : {Guestemail}
            </span>
          </section>

          <section
            style={{
              //   background: "lightgreen",
              marginTop: "20px",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
            }}
          >
            {ReservationInfoData?.map((item, index) => (
              <div
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <p style={{ textAlign: "start" }}>
                  <span>
                    <strong>{item.label}</strong>
                  </span>{" "}
                  <br />
                  <span>{item.value}</span>
                </p>
              </div>
            ))}
          </section>
          {/* <section style={{ width: "100%" }}>
            <p style={{ fontSize: "20px", marginTop: "20px" }}>
              <strong>Change Room </strong>
            </p>

            <FormGroup className="w-100">
              <Label className="form-label" for="roomType">
                <strong>Room Type</strong>
              </Label>

              <Select
                // isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                // isMulti
                id="roomType"
                name="roomType"
                value={selectedRoomType}
                options={RoomTypeList}
                onChange={(value) => {
                  setSelectedRoomType(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
                menuPlacement="top"
              />
            </FormGroup>

            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Room Number</strong>
              </Label>
              <Select
                // isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                // isMulti
                id="roomNumber"
                name="roomNumber"
                value={selectedRoom}
                options={roomNumberList}
                onChange={(value) => {
                  setSelectedRoom(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
                menuPlacement="top"
              />
            </FormGroup>

            <button
              disabled={!selectedRoomType?.value || !selectedRoom?.value}
              style={{
                backgroundColor:
                  !selectedRoomType || !selectedRoom ? "lightblue" : "blue",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={onChangeRoomSubmit}
            >
              Submit
            </button>
          </section> */}
          <hr />
          <section
            style={{
              //   background: "lightgreen",
              marginTop: "20px",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
            }}
          >
            {getInclusion()?.map((item, index) => (
              <div
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <p style={{ textAlign: "start" }}>
                  <span>{item?.ChargeName}</span> <br />
                  <span>
                    <strong>{Math.round(item?.ChargeAmount)}</strong>
                  </span>
                </p>
              </div>
            ))}
          </section>
          {/* inclusion  */}
          {getInclusion()?.length ? <hr /> : null}
          <section style={{ textAlign: "left", marginTop: "10px" }}>
            {!reservationInfo?.reservationID && (
              <p style={{ fontSize: "14px" }}>
                <strong>Web Check-in Form Pending</strong>
              </p>
            )}
            {reservationInfo?.reservationID && (
              <p style={{ fontSize: "14px" }}>
                <strong>Web Check-in Form Submitted</strong>
              </p>
            )}
          </section>
          {/* group booking table  */}
          {showGroupBooking && reservationNumber.split("-").length > 1 && (
            <section
              style={{
                width: "100%",
                overflow: "visible",
                paddingBottom: "70px",
              }}
            >
              <OffCanvasTable
                BookingData={bookingData}
                reservationNumber={reservationNumber}
              />
            </section>
          )}
        </main>
      </ModalBody>
      <ModalFooter>
        <section
          style={{
            // position: "absolute",
            // bottom: "10px",
            // right: "10px",
            // marginTop: "20px",
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {arrivalDate?.split(" ")[0] === todaysDate ? (
            <button
              type="button"
              disabled={
                !reservationInfo?.reservationID ||
                // !selectedReservation?.DueAmount ||
                Bookstatus !== "Arrived"
              }
              style={{
                backgroundColor: !reservationInfo?.reservationID
                  ? "#FFE500"
                  : "#FEF9CC",
                color: "black",
                padding: "10px 20px",

                border: "none",
                borderRadius: "5px",
                // cursor: "pointer",
              }}
              onClick={onMakeCheckin}
            >
              Checkin
            </button>
          ) : null}

          {Bookstatus !== "Checked Out" &&
            departureDate?.split(" ")[0] === todaysDate && (
              <button
                type="button"
                style={{
                  backgroundColor: "#FFE500",
                  color: "black",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  // cursor: "pointer",
                }}
                onClick={onMakeCheckout}
              >
                Checkout
              </button>
            )}
        </section>
      </ModalFooter>
    </StyledModalWrap>
  );
};

const StyledModalWrap = styled(Modal)`
  .modal-content {
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }
  .modal-header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    .close {
      margin: 0;
    }
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background-color: #f8f8f8;
  }
`;

export default Offcanvas;
