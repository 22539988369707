import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
} from "reactstrap";
import {
  useCancelOrder,
  useUpdateOrderStatus,
} from "../../../api/Unbox/UnboxOrder/Mutations";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "./PrintComponent";
import { Minus, Plus } from "react-feather";
//import { Cut, Line, Printer, Text, render } from "react-thermal-printer";

//import { downloadIcon } from "../../assets/images/connect/downloadIcon.svg";

// const statusList = [
//   { label: "Pending", value: "Pending" },
//   { label: "Preparing", value: "Preparing" },
//   { label: "Ready", value: "Ready" },
//   { label: "Cancelled", value: "Cancelled" },
// ];

const CustomCard = ({
  item,
  newRefetch,
  // preparedRefetch,
  // readyRefetch,
  // cancelRefetch,
}) => {
  // const backgroundColor =
  //   item?.status === "Pending"
  //     ? "#ff8c8c"
  //     : item?.status === "Preparing"
  //     ? "orange"
  //     : "green";

  const status = item?.status;
  // console.log(item?.printCount);
  const buttonLabel1 =
    status === "Pending"
      ? "Accept and Print"
      : status === "Preparing"
      ? "Ready for pick up"
      : status === "Ready"
      ? "Delivered"
      : "";

  const buttonLabel2 = status === "Pending" ? "Reject" : `Reprint`;

  const id = item?._id;
  const [open, setOpen] = useState(null);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const [updateHandler, { data: updateStatus }] = useUpdateOrderStatus();
  const [cancelOrderHandler, { data: cancelOrderData }] = useCancelOrder();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    pageStyle: `

          @media print {
            html, body {
              margin: 0;
              padding: 0;

            }

             .print-component {
          padding: 3mm;
          box-sizing: border-box;
        }
       


          } 
    `,
    content: () => componentRef.current,
  });
  // const handlePrint = () => {
  //   if (componentRef.current) {
  //     componentRef.current.printToPDF();
  //   }
  // };

  // const handlePrint = () => {
  //   console.log("clicked");
  //   outputHandler("handleClicked");
  // };

  // const handlePrint = async () => {
  //   try {
  //     const receipt = (
  //       <Printer type="epson" width={42}>
  //         <Line />
  //         <Text>Name: {item?.guestName || ""}</Text>
  //         <Text>Order ID: {item?.orderId}</Text>
  //         <Text>Mobile No.: {item?.mobileNo || ""}</Text>
  //         <Text>Token number: {item?.tokenNo || ""}</Text>

  //         <Line />
  //         {item?.printCount >= 1 && <Text>Duplicate print</Text>}
  //         <Text>Ordered item:</Text>
  //         {item?.items?.map((el) => (
  //           <Text key={el.itemName}>
  //             {el.itemName} {el?.options?.name ? `(${el?.options?.name})` : ""}{" "}
  //             x{el?.quantity}
  //             {el?.extras?.map((extra, index) => (
  //               <Text key={index}>{extra.name}</Text>
  //             ))}
  //           </Text>
  //         ))}
  //         {item?.guestRemark && (
  //           <Text>Guest Instructions: {item?.guestRemark}</Text>
  //         )}
  //         <Text align="center">End of receipt</Text>
  //         <Line />
  //         <Cut />
  //       </Printer>
  //     );
  //     // render(receipt)
  //     //   .then((data) => {
  //     //     const requiredData = data;
  //     //     console.log(requiredData);
  //     //     const buffer = Buffer.from(data);

  //     //     // Log the string representation
  //     //     console.log(buffer.toString("utf8"));
  //     //     return window.navigator.serial
  //     //       .requestPort()
  //     //       .then((port) => ({ port, data }));
  //     //   })
  //     //   .then(({ port, data }) => {
  //     //     console.log(port, data);
  //     //     console.log(port.onconnect, port.getInfo());
  //     //     port
  //     //       .getSignals()
  //     //       .then((res) => console.log(res))
  //     //       .catch((error) => console.log(error));
  //     //     //if (port.onconnect)
  //     //     return port.open({ baudRate: 9600 }).then(() => ({ port, data }));
  //     //   })
  //     //   .then(({ port, data }) => {
  //     //     const writer = port.writable?.getWriter();
  //     //     console.log(writer);
  //     //     if (writer) {
  //     //       writer
  //     //         .write(data)
  //     //         .then(() => {
  //     //           writer.releaseLock();
  //     //           console.log("Print job sent successfully");
  //     //         })
  //     //         .catch((err) => console.error("Error writing to port:", err));
  //     //     } else {
  //     //       console.error("Failed to get writer for port");
  //     //     }
  //     //   })
  //     //   .catch((err) => {
  //     //     console.error("Error during print process:", err);
  //     //   });

  //     const data = await render(receipt);
  //     const port = await navigator.serial.requestPort();
  //     console.log(port);
  //     await port.open({ baudRate: 9600 });
  //     console.log(data);

  //     const writer = port.writable?.getWriter();
  //     if (writer != null) {
  //       await writer.write(data);
  //       writer.releaseLock();
  //     }
  //   } catch (error) {
  //     console.error("Error printing receipt:", error);
  //   }
  // };

  const handleClickForStatus = () => {
    try {
      setOpen();
      if (id) {
        if (status === "Pending") {
          updateHandler(id, { status: "Preparing", printCount: 1 });
          handlePrint();
        } else if (status === "Preparing") {
          updateHandler(id, { status: "Ready" });
        } else if (status === "Ready") {
          updateHandler(id, { status: "Delivered" });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReject = () => {
    try {
      if (item?.orderId) {
        cancelOrderHandler({
          orderId: item?.orderId,
          cancellationRemark: "Cancelled",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (updateStatus || cancelOrderData) {
      newRefetch();
      // preparedRefetch();
      // readyRefetch();
      // if (cancelRefetch) {
      //   cancelRefetch();
      // }
    }
  }, [newRefetch, updateStatus, cancelOrderData]);
  return (
    <div style={{ width: "380px" }}>
      <div style={{ display: "none" }} className="print-container">
        <PrintComponent item={item} ref={componentRef} />
      </div>

      <Card>
        <CardHeader
          style={{
            backgroundColor: "#ffe500",
            width: "100%",
            color: "primary",
          }}
          //onClick={() => toggle(id)}
        >
          <div
            style={{
              marginBottom: "-3px",
              width: "100%",
            }}
          >
            {/* {item?.printCount > 0 ? "Duplicate" : ""} */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                //gridTemplateColumns: "repeat(2,1fr)",
              }}
            >
              <div style={{ marginBottom: "" }}>
                <strong>Name:</strong> <span>{item?.guestName || ""}</span>
              </div>
              <div style={{ marginBottom: "" }}>
                <strong>Order ID:</strong>{" "}
                <span style={{ fontWeight: "" }}>{item?.orderId}</span>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginBottom: "" }}>
                <strong>Mobile No:</strong> <span>{item?.mobileNo || ""}</span>
              </div>

              <div>
                <strong>Token No:</strong>{" "}
                <span style={{ fontWeight: "" }}>{item?.tokenNo || ""}</span>
              </div>
            </div>
            <div>
              <strong>Order Time :</strong>{" "}
              <span style={{ fontWeight: "" }}>
                {new Date(item?.createdAt).toLocaleString("en-IN", {
                  hour12: true,
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }) || ""}
              </span>
            </div>
          </div>
        </CardHeader>
        <hr style={{ margin: "0" }} />

        <CardBody style={{ padding: "13px 20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => toggle(id)}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginTop: "4px",
                padding: "0px",
              }}
            >
              Ordered items
            </span>
            <span
              style={{
                padding: "0px",
                cursor: "pointer",
                fontSize: "20px",
                // marginBottom: "px",
                transform: !open ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 0.2s ease-in-out",
              }}
              onClick={() => toggle(id)}
              color="light"
              size="md"
            >
              {/* &#x2304; */}
              {!open ? <Plus size={15} /> : <Minus size={15} />}
            </span>
          </div>
          <Collapse isOpen={open === id ? true : false}>
            <div style={{ marginTop: "-10px" }}>
              {item?.items?.map((el, i) => (
                <div
                  key={el?._id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: i === 0 ? "15px" : "5px",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "0px",
                      //maxHeight: "45px",
                    }}
                  >
                    <span
                      style={{
                        margin: "",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {el.itemName}
                      <span>
                        {el?.options?.name ? `(${el?.options?.name})` : ""}
                      </span>
                    </span>
                    <span>
                      {el?.extras?.map((i, j) => (
                        <li key={j}>{i?.name}</li>
                      ))}
                    </span>
                  </div>
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    x{el?.quantity}
                  </span>
                </div>
              ))}
              {item?.guestRemark && (
                <div>
                  <strong>Guest Instructions: </strong>
                  {item?.guestRemark}
                </div>
              )}
            </div>
          </Collapse>
        </CardBody>
        <CardFooter className="" style={{ padding: "18px 20px 20px 20px" }}>
          {/* <Label
            className="form-label"
            for="status"
            style={{ fontWeight: "600", fontSize: "16px" }}
          >
            Status
          </Label>
          <Select
            isClearable={false}
            isSearchable={false}
            id="status"
            name="status"
            options={statusList}
            value={status}
            onChange={(value) => handleChange(value)}
            classNamePrefix="select"
          /> */}

          <div
            style={{
              display: "grid",
              gridTemplateColumns:
                item?.status === "Pending" || item?.status === "Preparing"
                  ? "repeat(2,1fr)"
                  : "repeat(1,1fr)",
              gap: "5px",
            }}
          >
            <Button.Ripple
              style={{
                padding:
                  item?.status === "Ready" || item?.status === "Delivered"
                    ? "15px"
                    : "10px",
                fontWeight: "bold",
              }}
              type="button"
              className="btn-icon rounded float-right"
              color="light"
              size="sm"
              onClick={() => handleClickForStatus()}
              disabled={status === "Delivered"}
            >
              {buttonLabel1}
              {item?.status === "Pending" && (
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.8586 13.0354H2.85856C2.68175 13.0354 2.51218 13.1056 2.38716 13.2307C2.26213 13.3557 2.19189 13.5253 2.19189 13.7021C2.19189 13.8789 2.26213 14.0484 2.38716 14.1735C2.51218 14.2985 2.68175 14.3687 2.85856 14.3687H14.8586C15.0354 14.3687 15.2049 14.2985 15.33 14.1735C15.455 14.0484 15.5252 13.8789 15.5252 13.7021C15.5252 13.5253 15.455 13.3557 15.33 13.2307C15.2049 13.1056 15.0354 13.0354 14.8586 13.0354Z"
                    fill="black"
                  />
                  <path
                    d="M8.8582 1.7019C8.68139 1.7019 8.51182 1.77214 8.38679 1.89717C8.26177 2.02219 8.19153 2.19176 8.19153 2.36857V9.42857L5.9982 7.22857C5.87266 7.10304 5.7024 7.03251 5.52486 7.03251C5.34733 7.03251 5.17707 7.10304 5.05153 7.22857C4.92599 7.35411 4.85547 7.52437 4.85547 7.7019C4.85547 7.87944 4.92599 8.0497 5.05153 8.17524L8.38486 11.5086C8.44684 11.5711 8.52057 11.6207 8.60181 11.6545C8.68305 11.6883 8.77019 11.7058 8.8582 11.7058C8.9462 11.7058 9.03334 11.6883 9.11458 11.6545C9.19582 11.6207 9.26955 11.5711 9.33153 11.5086L12.6649 8.17524C12.727 8.11308 12.7763 8.03929 12.81 7.95807C12.8436 7.87686 12.8609 7.78981 12.8609 7.7019C12.8609 7.614 12.8436 7.52695 12.81 7.44574C12.7763 7.36452 12.727 7.29073 12.6649 7.22857C12.6027 7.16641 12.5289 7.1171 12.4477 7.08346C12.3665 7.04982 12.2794 7.03251 12.1915 7.03251C12.1036 7.03251 12.0166 7.04982 11.9354 7.08346C11.8541 7.1171 11.7804 7.16641 11.7182 7.22857L9.52486 9.42857V2.36857C9.52486 2.19176 9.45463 2.02219 9.3296 1.89717C9.20458 1.77214 9.03501 1.7019 8.8582 1.7019Z"
                    fill="black"
                  />
                </svg>
              )}
            </Button.Ripple>
            {(item?.status === "Pending" || item?.status === "Preparing") && (
              <Button.Ripple
                type="button"
                color="#FFFFFF"
                style={{ border: "1px solid #FFE700", fontWeight: "" }}
                disabled={status === "Preparing" && item?.printCount >= 2}
                onClick={
                  status === "Pending"
                    ? () => handleReject()
                    : () => {
                        updateHandler(id, { printCount: item?.printCount + 1 });
                        handlePrint();
                      }
                }
              >
                {buttonLabel2}
                {item?.status === "Preparing" && (
                  <svg
                    width="15"
                    height="17"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.8586 13.0354H2.85856C2.68175 13.0354 2.51218 13.1056 2.38716 13.2307C2.26213 13.3557 2.19189 13.5253 2.19189 13.7021C2.19189 13.8789 2.26213 14.0484 2.38716 14.1735C2.51218 14.2985 2.68175 14.3687 2.85856 14.3687H14.8586C15.0354 14.3687 15.2049 14.2985 15.33 14.1735C15.455 14.0484 15.5252 13.8789 15.5252 13.7021C15.5252 13.5253 15.455 13.3557 15.33 13.2307C15.2049 13.1056 15.0354 13.0354 14.8586 13.0354Z"
                      fill="black"
                    />
                    <path
                      d="M8.8582 1.7019C8.68139 1.7019 8.51182 1.77214 8.38679 1.89717C8.26177 2.02219 8.19153 2.19176 8.19153 2.36857V9.42857L5.9982 7.22857C5.87266 7.10304 5.7024 7.03251 5.52486 7.03251C5.34733 7.03251 5.17707 7.10304 5.05153 7.22857C4.92599 7.35411 4.85547 7.52437 4.85547 7.7019C4.85547 7.87944 4.92599 8.0497 5.05153 8.17524L8.38486 11.5086C8.44684 11.5711 8.52057 11.6207 8.60181 11.6545C8.68305 11.6883 8.77019 11.7058 8.8582 11.7058C8.9462 11.7058 9.03334 11.6883 9.11458 11.6545C9.19582 11.6207 9.26955 11.5711 9.33153 11.5086L12.6649 8.17524C12.727 8.11308 12.7763 8.03929 12.81 7.95807C12.8436 7.87686 12.8609 7.78981 12.8609 7.7019C12.8609 7.614 12.8436 7.52695 12.81 7.44574C12.7763 7.36452 12.727 7.29073 12.6649 7.22857C12.6027 7.16641 12.5289 7.1171 12.4477 7.08346C12.3665 7.04982 12.2794 7.03251 12.1915 7.03251C12.1036 7.03251 12.0166 7.04982 11.9354 7.08346C11.8541 7.1171 11.7804 7.16641 11.7182 7.22857L9.52486 9.42857V2.36857C9.52486 2.19176 9.45463 2.02219 9.3296 1.89717C9.20458 1.77214 9.03501 1.7019 8.8582 1.7019Z"
                      fill="black"
                    />
                  </svg>
                )}
              </Button.Ripple>
            )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default CustomCard;
