import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const tableHeaderData = ["Hostel Name", ""];

const CarReportTable = ({ openModal, setId, data, refetch, isHostel }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  if (isHostel?.value === true) {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <div>
                  <h2>Contribution Analysis Report (Stay Date)</h2>
                </div>
              </CardTitle>
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  {tableHeaderData.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item) => (
                  <tr key={uuidv4()}>
                    <td>
                      <h5>{item?.hostelName}</h5>
                      <br></br>
                    </td>
                    <td>
                      <Table size="sm" hover responsive>
                        <thead>
                          <th>Source</th>
                          <th>Room Sold</th>
                          <th>Occupancy</th>
                          <th>ADR</th>
                          <th>Revenue</th>
                          <th>%Rev</th>
                        </thead>
                        <tbody>
                          {item?.Sources?.map((source) => {
                            return (
                              <tr>
                                <td>{source?.Source}</td>
                                <td>{source?.RoomSold}</td>
                                <td>{source?.occupancy}</td>
                                <td>{source?.ADR}</td>
                                <td>{source?.Revenue.toFixed(2)}</td>
                                <td>
                                  {source?.percentRev === null
                                    ? "--"
                                    : source?.percentRev}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <h6>Total</h6>
                            </td>
                            <td>
                              <h6>{item?.TotalRoomSold}</h6>
                            </td>
                            <td>
                              <h6>100%</h6>
                            </td>
                            <td>
                              <h6>{item?.TotalADR}</h6>
                            </td>
                            <td>
                              <h6>{item?.TotalRevenue}</h6>
                            </td>
                            <td>
                              <h6>100%</h6>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <div>
                  <h2>Contribution Analysis Report (Stay Date)</h2>
                </div>
              </CardTitle>
            </CardHeader>
            {tableData?.map((item) => (
              <div key={uuidv4()}>
                <h5>{item?.hostelName}</h5>
                <Table size="sm" hover responsive>
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>Room Sold</th>
                      <th>Occupancy</th>
                      <th>ADR</th>
                      <th>Revenue</th>
                      <th>%Rev</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.Sources?.map((source) => (
                      <tr key={uuidv4()}>
                        <td>{source?.Source}</td>
                        <td>{source?.RoomSold}</td>
                        <td>{source?.occupancy}</td>
                        <td>{source?.ADR}</td>
                        <td>{source?.Revenue.toFixed(2)}</td>
                        <td>
                          {source?.percentRev === null
                            ? "--"
                            : source?.percentRev}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <h6>Total</h6>
                      </td>
                      <td>
                        <h6>{item?.TotalRoomSold}</h6>
                      </td>
                      <td>
                        <h6>100%</h6>
                      </td>
                      <td>
                        <h6>{item?.TotalADR}</h6>
                      </td>
                      <td>
                        <h6>{item?.TotalRevenue}</h6>
                      </td>
                      <td>
                        <h6>100%</h6>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ))}
          </Card>
        </Col>
      </Row>
    );
  }
};

export default CarReportTable;
