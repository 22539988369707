import gql from "graphql-tag";

// ! createRequests added by Ashwin
export const POST_ADD_WEB_APP_REQUEST = gql`
  mutation createRequests($requestsInput: RequestsInput) {
    createRequests(requestsInput: $requestsInput) {
      requestCategory
    }
  }
`;

// ! updateRequests added by Ashwin
export const UPDATE_WEB_APP_REQUEST = gql`
  mutation updateRequests($id: ID!, $requestsUpdate: RequestsInput) {
    updateRequests(id: $id, requestsUpdate: $requestsUpdate) {
      requestCategory
    }
  }
`;

export const CREATE_HOSTEL_REQUEST = gql`
  mutation createHostelRequest($hostelRequestInput: [HostelRequestInput]) {
    createHostelRequest(hostelRequestInput: $hostelRequestInput) {
      data
    }
  }
`;

export const CREATE_AND_UPDATE_REQUEST = gql`
  mutation createAndUpdate($hostelRequestInput: [HostelRequestInput]) {
    createAndUpdate(hostelRequestInput: $hostelRequestInput) {
      data
    }
  }
`;
