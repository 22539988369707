import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { Plus, Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateWorkationLandingPageDetails } from "@api";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const FaqForm = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [updateWorkationLandingPageDetailsHandler, { data }] =
    useUpdateWorkationLandingPageDetails();

  const [features, setFeatures] = useState([
    {
      id: uuidv4(),
      question: "",
      answer: "",
    },
  ]);

  const faqs = state?.tableData?.faqs;

  useEffect(() => {
    if (faqs && faqs.length > 0) {
      let upadtedHighlight = faqs.map((item) => {
        return {
          id: uuidv4(),
          question: item?.question,
          answer: item?.answer,
        };
      });

      setFeatures(upadtedHighlight);
    }
  }, [faqs]);

  // useEffect(() => {
  //   if (data?.updateWorkationLandingPageDetails?.slug) {
  //     toaster("success", "Submitted successfully");
  //   }
  // }, [data?.updateWorkationLandingPageDetails?.slug, faqs]);

  const handleTextChange = (val, id, key) =>
    setFeatures(
      features.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeAnswer = (val, id, key) =>
    setFeatures(
      features.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleAddFeature = () =>
    setFeatures([...features, { id: uuidv4(), question: "", answer: "" }]);

  const handleRemoveFeature = (id) => {
    let newFeatureData = features.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setFeatures(newFeatureData));
  };

  const handleSubmitHandler = () => {
    let isValid = true;
    let featuresClone = JSON.parse(JSON.stringify(features));
    featuresClone.forEach((item) => {
      delete item.id;
    });

    let faqData = { faqs: featuresClone };

    featuresClone?.forEach((item) => {
      isValid &&
        (item?.question && item?.answer ? (isValid = true) : (isValid = false));
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...faqData,
        })
      );
      updateWorkationLandingPageDetailsHandler("workationlandingpage", faqData);
      toaster("success", "Faq's updated successfully");
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitHandler();
      }}
    >
      {features.map(({ id, question, answer }, index) => (
        <div key={id}>
          <Row className="mt-2">
            <Col sm="12" md="11">
              <FormGroup>
                <FormGroup className="w-100">
                  <Label className="form-label" for="cancel">
                    Question
                  </Label>

                  <Input
                    type="text"
                    value={question}
                    id="cancel"
                    name="cancel"
                    placeholder={`Question`}
                    onChange={(e) =>
                      handleTextChange(e.target.value, id, "question")
                    }
                  />
                </FormGroup>

                <FormGroup className="w-100">
                  <Label className="form-label" for="cancel">
                    Answer
                  </Label>

                  <Input
                    rows={4}
                    type="textarea"
                    value={answer}
                    id="cancel"
                    name="cancel"
                    placeholder="Answer"
                    onChange={(e) =>
                      handleTextChangeAnswer(e.target.value, id, "answer")
                    }
                  />
                </FormGroup>
              </FormGroup>
            </Col>

            <Col sm="12" md="1">
              {features.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle mb-1 mr-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveFeature(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          </Row>
        </div>
      ))}

      <SaveDiscardBtnGroup addAction={handleAddFeature} />
    </form>
  );
};

export default FaqForm;
