import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateStepOutHomePageDetails } from "../../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { STEP_OUT_HOMEPAGE } from "../../../../utility/constant";

const Overview = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");

  const [
    updateStepOutHomePageDetailsHandler,
    { data: updatedStepOutProductData },
  ] = useUpdateStepOutHomePageDetails();

  const state = useSelector((state) => state?.cms);
  useEffect(() => {
    if (state?.tableData) {
      setTitle(state?.tableData?.title !== null ? state?.tableData?.title : "");

      setSubTitle(
        state?.tableData?.subTitle !== null ? state?.tableData?.subTitle : ""
      );

      setMetaTitle(
        state?.tableData?.seo?.metaTitle !== null
          ? state?.tableData?.seo?.metaTitle
          : ""
      );

      setMetaDesc(
        state?.tableData?.seo?.metaDesc !== null
          ? state?.tableData?.seo?.metaDesc
          : ""
      );

      setKeywords(
        state?.tableData?.seo?.keywords !== null
          ? state?.tableData?.seo?.keywords
          : ""
      );
    } else {
      setTitle("");
      setSubTitle("");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
    }
  }, [state]);

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {
      title,
      subTitle,
      seo: { metaTitle, metaDesc, keywords },
    };

    if (data.title && data.subTitle && data.seo) {
      updateStepOutHomePageDetailsHandler(STEP_OUT_HOMEPAGE, data);
      toaster("success", "Overview updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="title">
              Title
            </Label>
            <Input
              type="text"
              value={title}
              id="title"
              name="title"
              placeholder="Add title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="subTitle">
              Subtitle
            </Label>
            <Input
              type="text"
              value={subTitle}
              id="subTitle"
              name="subTitle"
              placeholder="Add subtitle"
              onChange={(e) => setSubTitle(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* SEO part */}
        <Col sm="11">
          <FormGroup className="w-100">
            <Label className="form-label" for="seo">
              SEO
            </Label>
            <Col sm="11">
              <Label className="form-label" for="seo">
                MetaTitle
              </Label>
              <Input
                type="text"
                value={metaTitle}
                id="metaTitle"
                name="metaTitle"
                placeholder="Add Meta Title"
                onChange={(e) => setMetaTitle(e.target.value)}
              />
            </Col>
            <br />
            <Col sm="11">
              <Label className="form-label" for="seo">
                Meta Description
              </Label>
              <Input
                type="text"
                value={metaDesc}
                id="metaDesc"
                name="metaDesc"
                placeholder="Add Meta Desc"
                onChange={(e) => setMetaDesc(e.target.value)}
              />
            </Col>
            <br />
            <Col sm="11">
              <Label className="form-label" for="seo">
                Keywords
              </Label>
              <Input
                type="text"
                value={keywords}
                id="keywords"
                name="keywords"
                placeholder="Add Keywords"
                onChange={(e) => setKeywords(e.target.value)}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>

      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Overview;
