import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CmsTabs from "@components/Cms/CmsTabs";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Options from "./Options";
import Extras from "./Extras";
import { tableDataHandler } from "@store/Cms/cms.action";
import { useGetMasterMenuByID } from "../../../api/Unbox/UnboxMasterMenu/Queries";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Overview from "./Overview";
const tabOptions = [
  {
    id: 0,
    title: "Overview",
    formComponent: <Overview />,
  },
  {
    id: 1,
    title: "Options & Extras",
    formComponent: <Options />,
  },
  // {
  //   id: 2,
  //   title: "Extras",
  //   formComponent: <Extras />,
  // },
];
const UnboxMasterMenuTabs = () => {
  const [active, setActive] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, refetch } = useGetMasterMenuByID(id);
  useEffect(() => {
    if (data?.getUnboxMasterMenu) {
      dispatch(tableDataHandler(data?.getUnboxMasterMenu));
    }
    refetch();
  }, [data, dispatch]);

  const handleRenderChildFormComponent = () => {
    const fountActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (fountActiveTabWithRenderChildFormComp) {
      return fountActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };
  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>

      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>
                  {active === 0 &&
                    "Unbox Master Menu" &&
                    tabOptions.find((item) => item.id === active).title}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => {
          return (
            <Row key={id}>
              <Col>
                <Card>
                  <CardHeader>
                    <CardTitle>{title}</CardTitle>
                  </CardHeader>
                  <CardBody>{formComponent}</CardBody>
                </Card>
              </Col>
            </Row>
          );
        })
      )}
    </div>
  );
};

export default UnboxMasterMenuTabs;
