//Added By Gaurav
import gql from "graphql-tag";

export const GET_DAILY_BOOKINGS = gql`
query getDailyEzeeBookings($filter:filter){
    getDailyEzeeBookings(filter: $filter){
        data {
            GuestName
            ReservationNo
            FolioNo
            Source
            RoomNo
            Status
            TotalExclusivTax
            TotalTax
            TotalInclusiveTax
            ReservationDate
            ArrivalDate
            DepartureDate
            hostelName
            OtherRevenueExclusiveTax
            OtherRevenueInclusiveTax
           }
        count
        page
}
}
`;


