import { Grid, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Stepout") ////localStorage.getItem("role") === "Super Admin" ?
  ? [
      {
        id: "stepOut",
        title: "Step Out",
        icon: <Grid size={20} />,
        badge: "light-warning",
        children: [
          {
            id: "stepOutHomepage",
            title: "Homepage",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutHomepage",
          },
          {
            id: "stepOutCategory",
            title: "Category",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutCategory",
          },
          {
            id: "stepOutProduct",
            title: "Product",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutProduct",
          },
          {
            id: "stepOutPolicies",
            title: "Policies",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutPolicies",
          },
          {
            id: "stepOutContactUs",
            title: "Contact Us",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutContactUs",
          },
          {
            id: "stepOutCoupons",
            title: "Coupons",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutCoupons",
          },
          {
            id: "stepOutLocations",
            title: "Locations",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutLocations",
          },
          {
            id: "stepOutBlogCategory",
            title: "Blog Category",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutBlogCategory",
          },
          {
            id: "stepOutBlogs",
            title: "Blog",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutBlogs",
          },
          {
            id: "stepOutUser",
            title: "Users",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutUsers",
          },
          {
            id: "stepOutWallOfLove",
            title: "Wall Of Love",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutWallOfLove",
          },
          {
            id: "stepOutBookings",
            title: "Booking",
            icon: <Circle size={12} />,
            navLink: "/stepOut/stepOutBookings",
          },
        ],
      },
    ]
  : [];
