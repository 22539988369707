import { useEffect, useState } from "react";
import { Col, Card, Row, Button, FormGroup, Label } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import SwitchIcon from "@components/common/switch-icon";
import { useCreateHostelRooms, useGetSmartLockOtp } from "../../../api/HostelRooms/index";
import toaster from "../../../@core/components/common/Toaster";
import { dateFormatter } from "../../../utility/Utils";
const tableHeaderData = [
  "Room Name",
  "Room No",
  "Room Otp",
  "Smart Lock ID",
  "Smart Link",
];

const HostelRoomsDataTable = ({ requestListData, selectedHostelId, refetch }) => {
  const [createHostelRoomsHandler, { data: createHostelRoomsData }] =
    useCreateHostelRooms();
  const [getsmartLockOtpHandler, { data:roomOtp}] = useGetSmartLockOtp()
  const [tableData, setTableData] = useState(requestListData);
  useEffect(() => {
    if (requestListData?.getHostelRoomsByHostelId?.length > 0) {
      setTableData(requestListData?.getHostelRoomsByHostelId);
    } else {
      setTableData([]);
    }
  }, [requestListData]);

  const [arrRequestObject, setArrRequestObject] = useState([]);

  useEffect(() => {
    let newArr = [];
    tableData?.forEach((i) => {
      let updateObject = {
        roomNo: i.roomNo,
        hostelId: selectedHostelId,
        status: i?.status,
        roomOtp: i?.roomOtp,
        smartLink: i?.smartLink,
        _id: i?._id,
        smartLockId: i?.smartLockId,
      };
      newArr.push(updateObject);
      setArrRequestObject(newArr);
    });
  }, [selectedHostelId, tableData]);

  const handleAddRequest = (value, item) => {
    try {
      const updatedArray = arrRequestObject.map((i) => {
        if (i?._id === item?._id) {
          i.status = value;
        }
        return i;
      });
      setArrRequestObject(updatedArray);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (createHostelRoomsData) {
      toaster("success", `Hostel request saved`);
    }
  }, [createHostelRoomsData]);

  const handleCreateHostelRooms = (roomsParams) => {
    const testObj = {
      hostelRooms: roomsParams,
    };
    createHostelRoomsHandler(testObj);
  };

  const [checkedAllStatus, setCheckedAllStatus] = useState(false);

  useEffect(()=>{
    if(roomOtp){
      refetch()
    }
  },[roomOtp])

  const formatDate=(date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);
  const formatDatee = dateFormatter(new Date());

  return (
    <>
      {tableData && (
        <>
          <Row>
            <Col>
              <Card>
                <Table responsive stripped bordered hover size="sm">
                  <thead>
                    <tr>
                      {tableHeaderData?.map((item) => {
                        return <th key={uuidv4()}>{item}</th>;
                      })}
                      <th>
                        <SwitchIcon
                          checked={checkedAllStatus}
                          handleChecked={(value) => {
                            const updatedArray = arrRequestObject.map((i) => {
                              i.status = value;
                              return i;
                            });
                            setCheckedAllStatus(value);
                            setArrRequestObject(updatedArray);
                          }}
                          id={`custom-checkbox}}`}
                        />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {tableData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.roomName}</td>
                          <td>{item?.roomNo}</td>
                          <td>{item?.roomOtp} <Button.Ripple onClick={()=>getsmartLockOtpHandler(item?.hostelId, item?.roomNo)} style={{display:"flex", fontSize:"1.5rem", margin:"0 auto", justifyContent:"center",width:"80%"}}>&#x21bb;</Button.Ripple> </td>
                          {/* <td>
                            <input
                              type="text"
                              value={
                                arrRequestObject?.find(
                                  (r) => r._id === item._id
                                )?.roomOtp || ""
                              }
                              onChange={(e) => {
                                const updatedArray = arrRequestObject.map(
                                  (i) => {
                                    if (i._id === item._id) {
                                      i.roomOtp = parseInt(e.target.value) || 0;
                                    }
                                    return i;
                                  }
                                );
                                setArrRequestObject(updatedArray);
                              }}
                              style={{
                                width: "70px",
                                border: "2px solid lightgray",
                                borderRadius: "3px",
                                // padding: "5px",
                              }}
                            />
                          </td> */}
                          <td>
                            <input
                              type="text"
                              value={
                                arrRequestObject?.find(
                                  (r) => r._id === item._id
                                )?.smartLockId || ""
                              }
                              onChange={(e) => {
                                const updatedArray = arrRequestObject.map(
                                  (i) => {
                                    if (i._id === item._id) {
                                      i.smartLockId = e.target.value || "";
                                    }
                                    return i;
                                  }
                                );
                                setArrRequestObject(updatedArray);
                              }}
                              style={{
                                width: "225px",
                                border: "2px solid lightgray",
                                borderRadius: "3px",
                                // padding: "5px",
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={
                                arrRequestObject?.find(
                                  (r) => r._id === item._id
                                )?.smartLink || ""
                              }
                              onChange={(e) => {
                                const updatedArray = arrRequestObject.map(
                                  (i) => {
                                    if (i._id === item._id) {
                                      i.smartLink = e.target.value || "";
                                    }
                                    return i;
                                  }
                                );
                                setArrRequestObject(updatedArray);
                              }}
                              style={{
                                width: "250px",
                                border: "2px solid lightgray",
                                borderRadius: "3px",
                                display:"inline"
                              }}
                            />
                            <a href={`${item?.smartLink}?checkin=${formatDatee}&checkout=${formattedDate+"T11:00:00"}&primaryColor=e5cf00&gradient=e5cf00,FFE700`} target="blank">
                            &#x2197;
                            </a>
                          </td>
                          <td>
                            <SwitchIcon
                              checked={
                                arrRequestObject?.length > 0
                                  ? arrRequestObject.find(
                                      (r) => r._id === item._id
                                    )?.status
                                  : item?.status
                              }
                              id={`custom-checkbox-${index}}}`}
                              name={"select" + index}
                              handleChecked={(value) => {
                                handleAddRequest(value, item);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          {requestListData?.getHostelRoomsByHostelId?.length ? (
            <Button
              color="light"
              className="float-right"
              onClick={() => {
                handleCreateHostelRooms(arrRequestObject);
              }}
            >
              Save
            </Button>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default HostelRoomsDataTable;
