import React from "react";
import { Card, CardHeader, CardTitle, Label, Table } from "reactstrap";
//import { useGetAllCafeMasterList } from "../../../api/Unbox/UnboxCafeMaster/Queries";
const month = [
  { key: "Jan", value: 1 },
  { key: "Feb", value: 2 },
  { key: "Mar", value: 3 },
  { key: "Apr", value: 4 },
  { key: "May", value: 5 },
  { key: "Jun", value: 6 },
  { key: "Jul", value: 7 },
  { key: "Aug", value: 8 },
  { key: "Sep", value: 9 },
  { key: "Oct", value: 10 },
  { key: "Nov", value: 11 },
  { key: "Dec", value: 12 },
];

export const getMonth = (fromDate, toDate) => {
  let fromDateObj = new Date(fromDate);
  let toDateObj = new Date(toDate);

  let fromYear = fromDateObj.getFullYear();
  let toYear = toDateObj.getFullYear();

  let fromMonth = fromDateObj.getMonth() + 1;
  let toMonth = toDateObj.getMonth() + 1;

  let monthArray = [];

  if (fromYear === toYear) {
    while (fromMonth <= toMonth) {
      monthArray.push(fromMonth);
      fromMonth++;
    }
  } else {
    while (fromMonth <= 12) {
      monthArray.push(fromMonth);
      fromMonth++;
    }
    fromMonth = 1;
    while (fromMonth <= toMonth) {
      monthArray.push(fromMonth);
      fromMonth++;
    }
  }

  return monthArray;
};

const Report = ({
  monthwiseData,
  overallData,
  fromDate,
  toDate,

  //filter
}) => {
  const monthArray = getMonth(fromDate, toDate);
  let totalOrderCount = 0;
  let totalOverAllSales = 0;
  let totalNetSales = 0;
  let totalTax = 0;
  let totalDiscount = 0;
  let totalAov = 0;
  let totalBedNights = 0;
  //const { data: cafeListData } = useGetAllCafeMasterList();
  return (
    <div style={{ overflowX: "auto", overflowY: "auto" }}>
      <Card>
        <CardHeader>
          <CardTitle className="" style={{ display: "flex", gap: "10px" }}>
            <h4 style={{ marginTop: "2px" }}>Cafewise Sales </h4>
            <span>
              (
              <Label>
                Total Orders : {overallData?.totalOverAllOrderCount}
              </Label>{" "}
              | <Label>Total Sales : ₹{overallData?.totalOverAllSales}</Label> |{" "}
              <Label>Net Sales : ₹{overallData?.totalNetSales}</Label> |{" "}
              <Label>Total Tax : ₹{overallData?.totalTax}</Label> |{" "}
              <Label>Total Discount : ₹{overallData?.totalDiscount} </Label> |{" "}
              <Label>AOV : ₹{overallData?.avgOrderValue}</Label>)
            </span>
          </CardTitle>
        </CardHeader>
        <div style={{ overflowX: "auto", maxHeight: "800px" }}>
          <Table striped bordered>
            <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
              {/* <tr>
                <th style={{ position: "sticky", left: 0, zIndex: 2 }}>
                  Month
                </th> */}
              {/* {filter !== "Cafewise Sales" ? (
                  monthArray?.map((monthNum, i) => {
                    const findMonth = month?.find((i) => i?.value === monthNum);
                    return (
                      <th
                        colSpan="5"
                        style={{
                          //marginLeft: "50px",
                          textAlign: "center",
                          minWidth: "150px",
                          position: "sticky",

                          zIndex: 1,
                          // border: "1px solid grey",
                        }}
                        key={i}
                      >
                        {findMonth?.key}
                      </th>
                    );
                  })
                ) : ( */}

              {/* <th
                  colSpan="5"
                  style={{
                    //marginLeft: "50px",
                    textAlign: "center",
                    minWidth: "150px",
                    position: "sticky",

                    zIndex: 1,
                    // border: "1px solid grey",
                  }}
                >
                  Overall Data
                </th>
                 } 
              </tr> */}
              <tr>
                <th style={{ position: "sticky", left: 0, zIndex: 1 }}>
                  Cafe name
                </th>
                {/* {filter !== "Cafewise Sales" ? (
                  monthArray?.map((monNum, i) => {
                    return (
                      <React.Fragment key={i}>
                        <th>Order Count</th>
                        <th>Total Sales</th>
                        <th>Net Sales</th>
                        <th>Total Tax</th>
                        <th>Total Discount</th>
                      </React.Fragment>
                    );
                  })
                ) :  */}

                <th>Order Count</th>
                <th>Total Sales</th>
                <th>Net Sales</th>
                <th>Total Tax</th>
                <th>Total Discount</th>
                <th>Total BedNights</th>
                <th>AOV</th>
                <th>O/BN</th>
                {/* } */}
              </tr>
            </thead>
            <tbody>
              {/* {cafeListData?.getAllCafeMasterList?.map((cafe) => {
                const findCafeData = monthwiseData?.find(
                  (cafeData) =>
                    cafe?.cafeName === cafeData?.cafeInfo[0]?.cafeName
                );
                return (
                  <tr key={cafe?._id}>
                    <td
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backdropFilter: "blur(400px)",
                      }}
                    >
                      {cafe?.cafeName}
                    </td>
                    {!findCafeData
                      ? monthArray?.map((monthNum) => (
                          <>
                            <td>{"--"}</td>
                            <td>{"--"}</td>
                          </>
                        ))
                      : monthArray?.map((monthNum) => {
                          const findMonthIfExist =
                            findCafeData?.monthRangeData?.find(
                              (i) => i?.month === monthNum
                            );

                          return findMonthIfExist ? (
                            <>
                              <td>{findMonthIfExist?.totalOrderCount}</td>
                              <td>
                                {findMonthIfExist?.totalOrderAmount.toFixed(2)}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                            </>
                          );
                        })}
                  </tr>
                );
              })} */}
              {/* {filter !== "Cafewise Menu" */}
              {
                monthwiseData?.map((cafeData) => {
                  totalOrderCount += cafeData?.orderCount;
                  totalNetSales += cafeData?.netSales;
                  totalOverAllSales += cafeData?.orderAmount;
                  totalDiscount += cafeData?.discount;
                  totalTax += cafeData?.tax;
                  totalBedNights += cafeData?.perHostelBedNight || 0;
                  return (
                    <tr key={cafeData?._id}>
                      <td
                        style={{
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          //backgroundColor: "white",
                          backdropFilter: "blur(400px)",
                        }}
                      >
                        {cafeData?.cafeInfo[0]?.cafeName}
                      </td>
                      {/* {filter !== "Cafewise Sales" ? (
                        monthArray?.map((month, i) => {
                          console.log("render");
                          const findIfDateExist =
                            cafeData?.monthRangeData?.find(
                              (cafeData) => cafeData?.month === month
                            );

                          return findIfDateExist ? (
                            <React.Fragment key={i}>
                              <td>{findIfDateExist?.totalOrderCount}</td>
                              <td>
                                {findIfDateExist?.totalOrderAmount.toFixed(2)}
                              </td>
                              <td>
                                {findIfDateExist?.totalNetSales.toFixed(2)}
                              </td>
                              <td>{findIfDateExist?.totalTax.toFixed(2)}</td>
                              <td>
                                {findIfDateExist?.totalDiscount.toFixed(2)}
                              </td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={i}>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                            </React.Fragment>
                          );
                        })
                      ) :  */}
                      {/* <>
                          {console.log("render")} */}
                      <td>{cafeData?.orderCount}</td>
                      <td>{cafeData?.orderAmount.toFixed(2)}</td>
                      <td>{cafeData?.netSales.toFixed(2)}</td>
                      <td>{cafeData?.tax.toFixed(2)}</td>
                      <td>{cafeData?.discount.toFixed(2)}</td>
                      <td>{cafeData?.perHostelBedNight || 0}</td>
                      <td>{cafeData?.avgOrderValue.toFixed(2)}</td>
                      <td>
                        {cafeData?.orderPerBedNight
                          ? cafeData?.orderPerBedNight.toFixed(2)
                          : ""}
                      </td>
                      {/* </> */}
                      {/* } */}
                    </tr>
                  );
                })
                // : monthwiseData?.map((cafeData) => {
                //     return (
                //       <tr key={cafeData?._id}>
                //         <td
                //           style={{
                //             position: "sticky",
                //             left: 0,
                //             zIndex: 1,
                //             //backgroundColor: "white",
                //             backdropFilter: "blur(400px)",
                //           }}
                //         >
                //           {cafeData?.cafeInfo[0]?.cafeName}
                //         </td>
                //         <td>{cafeData?.orderCount}</td>
                //         <td>{cafeData?.orderAmount.toFixed(2)}</td>
                //         <td>{cafeData?.netSales.toFixed(2)}</td>
                //         <td>{cafeData?.tax.toFixed(2)}</td>
                //         <td>{cafeData?.discount.toFixed(2)}</td>
                //       </tr>
                //     );
                //   })}
              }
              <tr>
                <td
                  style={{
                    position: "sticky",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                    //backgroundColor: "white",
                    backdropFilter: "blur(400px)",
                  }}
                >
                  Overall
                </td>
                <td>{totalOrderCount}</td>
                <td>{totalOverAllSales.toFixed(2)}</td>
                <td>{totalNetSales.toFixed(2)}</td>
                <td>{totalTax.toFixed(2)}</td>
                <td>{totalDiscount.toFixed(2)}</td>
                <td>{totalBedNights || ""}</td>
                <td>{(totalNetSales / totalOrderCount).toFixed(2)}</td>
                <td>{(totalOrderCount / totalBedNights).toFixed(2)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Card>
    </div>
  );
};

export default Report;
