import { Edit } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "../../../utility/Utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  // "Guest Name",
  "Transaction Id",
  "Reason",
  "Email",
  "Amount",
  "Date",
  "Ref. Reservation Id",
  // "Status",
];

const WalletInfoTable = ({ openModal, setId, data }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Wallet Transaction</h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Wallet Transaction
            </Button.Ripple>
          </CardHeader>

          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={item._id}>
                  <td style={{ width: "5%" }}>
                    <Button
                      type="button"
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  {/* <td>{item?.userId?.fullName}</td> */}
                  <td>{item?.transactionId}</td>
                  <td>{item?.reason}</td>
                  <td>{item?.userId?.email}</td>
                  <td>{item?.amount?.toFixed(2)}</td>
                  <td>{dateFormatHandler(item?.transactionDate)}</td>
                  <td>{item?.refReservationId}</td>

                  {/* <td>
                    <Badge
                      pill
                      color={item?.status ? "light-success" : "light-danger"}
                      className="mr-1"
                    >
                      {item?.status ? "Active" : "Inactive"}
                    </Badge>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default WalletInfoTable;
