import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const tableHeaderData = [
  "id",
  "name",
  "addr1",
  "addr2",
  "city",
  "province",
  "postal_code",
  "country",
  "latitude",
  "longitude",
  "category",
  "hotel_brand",
  "phone",
  "star_rating",
  "alternate_hotel_id",
];

const HotelCenterTable = ({ data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);
  console.log(tableData);
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>Google Hotel Center - Hotel List</h2>
              </div>
            </CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item?.id}</td>
                  <td>{item?.name}</td>
                  <td>{item?.addr1}</td>
                  <td>{item?.addr2}</td>
                  <td>{item?.city}</td>
                  <td>{item?.province}</td>
                  <td>{item?.postal_code}</td>
                  <td>{item?.country}</td>
                  <td>{item?.latitude}</td>
                  <td>{item?.longitude}</td>
                  <td>{item?.category}</td>
                  <td>{item?.hotel_brand}</td>
                  <td>{item?.phone}</td>
                  <td>{item?.star_rating}</td>
                  <td>{item?.alternate_hotel_id}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default HotelCenterTable;
