import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import toaster from "@components/common/Toaster";
import { store } from "@store/store";
import { userLogout } from "@store/user/user.action";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_SERVER_URL,
  // uri: "https://admin-devapi.thehosteller.com/graphql",
});

const authLink = setContext((_, { headers }) => {
  const state = store.getState();
  const token = state?.user?.details?.token;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    toaster("error", graphQLErrors[0]?.message);
    console.error("GraphQL Error ---- ", graphQLErrors);
  }

  if (networkError) {
    if (networkError.statusCode === 401 || networkError.statusCode === 403) {
      userLogout();
    } else {
      toaster("error", "Something went wrong, please try again later");
    }

    console.error("Network Error ----", networkError);
  }

  if (response) {
    response.errors = null;
  }
});

export const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({ addTypename: false }),
});
