import gql from "graphql-tag";

export const GET_MEMBERSHIPS = gql`
  query getMemberships($strainer: strainer) {
    getMemberships(strainer: $strainer) {
      membershipTypeCount
      totalMembershipAmount
    }
  }
`;

export const GET_PAGINATED_MEMBERSHIPS_LIST = gql`
  query getPaginatedMembershipsList($filter: filter) {
    getPaginatedMembershipsList(filter: $filter) {
      data {
        _id
        membershipId
        userId
        startDate
        endDate
        membershipType
        membershipAmount
        discountPercentage
        cashbackPercentage
        bookingId
        membershipTax
        totalAmount
        reservationNo
        fullName
        email
        mobile
        guestId
        address
        city
        state
        country
        pincode
        travelKitSend
      }
      count
      page
      limit
    }
  }
`;
export const GET_ALL_MEMBERSHIPS = gql`
  query getAllMemberships($strainer: strainer) {
    getAllMemberships(strainer: $strainer) {
      _id
      membershipId
      userId
      startDate
      endDate
      membershipType
      membershipAmount
      discountPercentage
      cashbackPercentage
      bookingId
      membershipTax
      totalAmount
      reservationNo
      fullName
      email
      mobile
      guestId
      address
      city
      state
      country
      pincode
      travelKitSend
    }
  }
`;
