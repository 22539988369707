import React, { useEffect, useState } from "react";
import { useGetAllCafeMasterList } from "../../../api/Unbox/UnboxCafeMaster/Queries";
import CafewiseTable from "./CafewiseTable";

const UnboxCafewiseMenu = () => {
  const [tableData, setTableData] = useState(null);

  const { data: allCafeMasterList } = useGetAllCafeMasterList();

  useEffect(() => {
    if (allCafeMasterList?.getAllCafeMasterList?.length) {
      setTableData(allCafeMasterList?.getAllCafeMasterList);
    } else {
      setTableData([]);
    }
  }, [allCafeMasterList?.getAllCafeMasterList]);
  return (
    <div>
      <CafewiseTable cafewiseMenuList={tableData} />
    </div>
  );
};

export default UnboxCafewiseMenu;
