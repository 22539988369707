import React, { useState } from "react";
import { formatDateToDDMMYYYY } from "./helpers";
import Offcanvas from "./Offcanvas";
import TableDatawAsPerCheckout from "./TableDatawAsPerCheckout";
import TableDataAsPerCheckin from "./TableDataAsPerCheckin";
import { useSkin } from "@hooks/useSkin";
import { Table as ReactTable } from "reactstrap";

function Table({
  dateRanges,
  tableContainerRef,
  cellRef,
  roomNameRef,
  bookingData,
  roomNames,
  roomNamesList,
  roomNumbers,
  totalWidthOfTableContainer,
  widthOfEachCell,
  hostel,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [collapsedRows, setCollapsedRows] = useState({});
  const [skin, setSkin] = useSkin();

  // console.log(roomNamesList);
  // console.log(roomNames);
  // console.log(roomNumbers);
  // 161D31

  const defaultColor = "grey";
  const StatusColourCode = [
    { label: "Arrived", value: "#F16363" },
    { label: "Void", value: "grey" },
    { label: "No Show", value: defaultColor },
    { label: "Due Out", value: "#993525" },
    { label: "Unconfirmed Reservation", value: "#F04E4F" },
    { label: "Checked Out", value: "#3568FA" },
    { label: "Confirmed Reservation", value: "#60CD10" },
    { label: "Cancel", value: "red" },
    { label: "Stayover", value: "#336666" },
    // { label: "Maintenance Block", value: "#666666" },
  ];

  const formatedDateTime = (datetime) => {
    const [date, time] = datetime.split("T");
    return `${formatDateToDDMMYYYY(date)} ${time}`;
  };

  const toggleCollapse = (index) => {
    setCollapsedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleBandClick = (reservation) => {
    setSelectedReservation(reservation);
    setModalOpen(true);
  };

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const uniqueRoomNumbers = (roomNames) =>
    roomNames
      ? roomNumbers?.find((item) => item?.ID === roomNames?.value)?.Rooms
      : roomNumbers?.[0]?.Rooms;

  return (
    <>
      <div
        ref={tableContainerRef}
        className="table-container-dashboard"
        style={{
          // maxWidth: "max-content",
          width: "100%",
          overflow: "auto",
          height: "80vh",
          position: "relative",
          zIndex: 1,
          paddingBottom: "100px",
        }}
      >
        <ReactTable
          style={{
            maxWidth: "max-content",
            width: "100%",
            minHeight: "max-content",
            marginTop: "20px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <thead >
            <tr>
              <th
                style={{
                  minWidth: "220px",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  zIndex: 3,
                  paddingLeft: "30px",
                }}
                className="th"
              >
                Room No
              </th>
              {dateRanges.map((date, index) => (
                <th
                  style={{
                    minWidth: "120px",
                    position: "sticky",
                    top: 0,
                    left: 220,
                    zIndex: 3,
                  }}
                  key={index}
                  className="th"
                >
                  {date}
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ }} className="tbody">
            {roomNamesList?.map((room, index) => (
              <React.Fragment key={index}>
                <tr
                  style={{ maxWidth: "200px" }}
                  onClick={() => toggleCollapse(index)}
                >
                  <td
                    style={{
                      maxWidth: "200px",
                      borderTop: "1px solid #ddd",
                      borderBottom: "1px solid #ddd",
                      cursor: "pointer",
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      paddingLeft : "5px",
                      backgroundColor : skin === "dark" ? "#343D55" : "#ddd"
                    }}
                    // colSpan={dateRanges.length + 1}
                  >
                    <div style={{height : "40px" , width : "100%" , padding : "5px"}}>
                    {room?.label}
                    </div>
                  </td>
                </tr>
                {!collapsedRows[index] &&
                  uniqueRoomNumbers(room)?.map((roomNumber) => (
                    <tr style={{ overflowX: "hidden" }} key={roomNumber.RoomID}>
                      <td
                        ref={roomNameRef}
                        style={{
                          position: "sticky",
                          left: -1,
                          zIndex: 2,
                          background: skin === "dark" ? "#343D55" : "white",
                          height: "59px",
                          width: "100px",
                          paddingLeft: 30,
                          paddingTop : 10,
                          borderTop: "1px solid #ddd",
                          borderBottom: "1px solid #ddd",
                          overflow: "visible",
                        }}
                      >
                          {roomNumber.RoomName}
                      </td>
                      {dateRanges.map((date, idx) => (
                        <td
                          key={idx}
                          className="reservation-cell"
                          ref={cellRef}
                          style={{ position: "relative" }}
                        >
                          {/* once check as per checkin and then for checkout */}
                          <TableDataAsPerCheckin
                            idx={idx}
                            cellRef={cellRef}
                            date={date}
                            room={room}
                            roomNumber={roomNumber}
                            dateRanges={dateRanges}
                            StatusColourCode={StatusColourCode}
                            defaultColor={defaultColor}
                            handleBandClick={handleBandClick}
                            widthOfEachCell={widthOfEachCell}
                            bookingData={bookingData}
                          />
                          <TableDatawAsPerCheckout
                            idx={idx}
                            cellRef={cellRef}
                            date={date}
                            room={room}
                            roomNumber={roomNumber}
                            dateRanges={dateRanges}
                            StatusColourCode={StatusColourCode}
                            defaultColor={defaultColor}
                            handleBandClick={handleBandClick}
                            bookingData={bookingData}
                            widthOfEachCell={widthOfEachCell}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </ReactTable>

        {selectedReservation && (
          <Offcanvas
            open={modalOpen}
            handleToggle={handleModalToggle}
            editData={selectedReservation}
            Guestname={selectedReservation?.GuestName}
            contact={selectedReservation?.Mobile}
            Guestemail={selectedReservation?.Email}
            reservationNumber={selectedReservation?.ReservationNo}
            Bookstatus={selectedReservation?.BookingStatus}
            arrivalDate={formatedDateTime(selectedReservation?.ArrivalDate)}
            departureDate={formatedDateTime(selectedReservation?.DepartureDate)}
            bookingDate={formatDateToDDMMYYYY(
              selectedReservation?.ReservationDate
            )}
            roomType={selectedReservation?.RatePlan}
            roomNamesList={roomNamesList}
            roomNumbers={roomNumbers}
            roomNumber={selectedReservation?.RoomNo}
            hostelId={hostel?.value}
            selectedReservation={selectedReservation}
            ArrivalTime={selectedReservation?.ArrivalTime}
            DepartureTime={selectedReservation?.DepartureTime}
            large={true}
            bookingData={bookingData}
            totalAmount={selectedReservation?.TotalInclusiveTax}
            showGroupBooking={true}
            Deposit = {selectedReservation?.Deposit}
          />
        )}
      </div>
    </>
  );
}

export default Table;
