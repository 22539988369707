import gql from "graphql-tag";
export const GET_PAGINATED_OPS_FINANCE_LIST = gql`
  query getPaginatedOpsFinance($filter: filter) {
    getPaginatedOpsFinance(filter: $filter) {
      data {
        _id
        hostelId {
          _id
          name
        }
        entryType
        entryDate
        entryTime
        head
        subHead
        paymentMode
        amountWithoutGST
        GST
        totalAmount
        remarks
        remarkImages {
          image
        }
        entryBy
        reservationId
        paymentReferenceId
        uploadInvoice {
          image
        }
        expenseType
        entryFrom
        remainingBalance
        hostelDetails {
          _id
          name
        }
        subFields {
          subHeadType
          startDate
          endDate
          accountId
          quantity
          vendorName
          warranty
          labourAmount
          materialAmount
          otaName
          dairyItems {
            item
            price
            quantity
            total
          }
          refEmployee {
            id
            name
          }
        }
        createdAt
        openingBalance
        closingBalance
        logs
        invoicePendingStatusCheck
        pendingInvoiceCorrectionRemark
        gstNumber
        vendorNameDayBook
      }
      count
      page
      limit
      remainingBalance
    }
  }
`;

export const GET_OPS_FINANCE_REPORT = gql`
  query getOpsFinanceReport {
    getOpsFinanceReport {
      _id
      hostelId {
        _id
        name
      }
      entryType
      entryDate
      entryTime
      head
      subHead
      paymentMode
      amountWithoutGST
      GST
      totalAmount
      remarks
      remarkImages {
        image
      }
      entryBy
      reservationId
      paymentReferenceId
      uploadInvoice {
        image
      }
      expenseType
      entryFrom
      subFields {
        subHeadType
        startDate
        endDate
        accountId
        quantity
        vendorName
        warranty
        labourAmount
        materialAmount
        otaName
        dairyItems {
          item
          price
          quantity
          total
        }
        refEmployee {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_DAY_BOOK_DATA_FOR_EXPORT = gql`
  query getAllDayBookDataForExport($filter: filter) {
    getAllDayBookDataForExport(filter: $filter) {
      data {
        _id
        hostelId {
          _id
          name
        }
        entryType
        entryDate
        entryTime
        head
        subHead
        paymentMode
        amountWithoutGST
        GST
        totalAmount
        remarks
        remarkImages {
          image
        }
        entryBy
        reservationId
        paymentReferenceId
        uploadInvoice {
          image
        }
        expenseType
        entryFrom
        remainingBalance
        hostelDetails {
          _id
          name
        }
        subFields {
          subHeadType
          startDate
          endDate
          accountId
          quantity
          vendorName
          warranty
          labourAmount
          materialAmount
          otaName
          dairyItems {
            item
            price
            quantity
            total
          }
          refEmployee {
            id
            name
          }
        }
        openingBalance
        closingBalance
        createdAt
        entryDateConverted
        createdAtConverted
        pendingInvoiceCorrectionRemark
        gstNumber
        vendorNameDayBook
      }
    }
  }
`;

export const GET_HOSTEL_WISE_CLOSING_BALANCE = gql`
  query getHostelWiseClosingBalance($toDate: Date) {
    getHostelWiseClosingBalance(toDate: $toDate) {
      hostelName
      closingBalance
      hostelId
    }
  }
`;
