import gql from "graphql-tag";

export const GET_PAGINATED_STEP_OUT_CONTACT_US_LIST = gql`
  query getPaginatedStepOutContactUsList($filter: filter) {
    getPaginatedStepOutContactUsList(filter: $filter) {
    data {
        _id
        fullName
        emailId
        phoneNumber
        message
        }
    count
    page
    limit
    }
  }
`