import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import TableList from "../common/TableList";
import { useGetAllPaginatedRequests } from "../../../api/dataSource";
import { TABLE_TYPE } from "../common/TableList/constant";

const tableHeaderList = [
  "Action",
  "Request category",
  "Request name",
  "Max. Allowed Request(Per day)",
];

const CategoryTable = ({ AddEditModal }) => {
  const [limit, setLimit] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useGetAllPaginatedRequests(limit, page);

  const history = useHistory();

  const handleAdd = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data) {
      let requestsList = [];
      data?.getPaginatedRequestList?.data?.map((item) => {
        requestsList.push({
          id: item?._id,
          requestCategory: item?.requestCategory,
          requestName: item?.requestName,
          // tat: item?.tat,
          maxAllowedRequest: item?.maxAllowedRequest,
        });
      });

      setTableData(requestsList);
      setTotalPage(Math.ceil(data?.getPaginatedRequestList?.count / limit));
    }
  }, [data]);

  useEffect(() => {
    if (editId && data?.getPaginatedRequestList?.data?.length) {
      let editData = data?.getPaginatedRequestList?.data.filter(
        (item) => item._id === editId
      );
      setEditData(editData);
    } else {
      setEditData(null);
    }
  }, [editId, data]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/cms/edit/requests/${id}`);
    } else {
      setEditId(id);
    }
  };

  // const handleDelete = (id) => {
  //   confirmDelete(() => {
  //     deleteHostelHandler(id);
  //     refetch();
  //   });
  // };

  return (
    <div>
      <TableList
        handleAdd={AddEditModal ? handleAdd : null}
        // filterHeader="All Configure Requests"
        // tableHeader="Configure Requests"
        tableHeader="Master Request Configuration"
        type={TABLE_TYPE.CONFIGURE_REQUESTS}
        tableData={tableData}
        tableHeaderList={tableHeaderList}
        openModal={() => setOpenModal(!openModal)}
        handleEdit={handleEdit}
        // handleDelete={AddEditModal ? handleDelete : null}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={() => setOpenModal(!openModal)}
          refetch={refetch}
          editData={editData}
          setEditId={setEditId}
        />
      )}
    </div>
  );
};

export default CategoryTable;
