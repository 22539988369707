import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";

export const GET_EMPLOYEE_CURRENT_SALARY_STRUCTURE = gql`
    query($id:ID){
    getEmployeeCurrentSalaryStructureById(id:$id){
        _id 
        empId
        fromYear
        toYear
        fromMonth
        toMonth
        yearlyCTC
        monthlyCTC
        fromDate
        toDate
        HRA
        statutoryBonus
        specialAllowance
        grossSalary
        PF
        ESIC
        PT
        LWF
        otherDeduction
        advances
        finesAndPenalty
        holidayWage
        otherIncentive
    }
}
`

export const GET_EMPLOYEE_SALARY_STRUCTURE = gql`
    query($empId:ID){
    getEmployeeSalaryStructure(empId:$empId){
        _id 
        empId
        empCode
        fromYear
        toYear
        fromMonth
        toMonth
        yearlyCTC
        monthlyCTC
        fromDate
        toDate
        HRA
        statutoryBonus
        specialAllowance
        grossSalary
        PF
        ESIC
        PT
        LWF
        otherDeduction
        advances
        finesAndPenalty
        holidayWage
        otherIncentive
        basicSalary
        arrers
            }
}
`

export const useGetEmployeeCurrentMonthSalaryStructure = (id) => {
    const { loading, error, data, refetch } = useQuery(GET_EMPLOYEE_CURRENT_SALARY_STRUCTURE, {
        variables: { id }
    });
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};

export const useGetEmployeeSalaryStructure = (empId) => {
    const { loading, error, data, refetch } = useQuery(GET_EMPLOYEE_SALARY_STRUCTURE, {
        variables: { empId }
    });
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};