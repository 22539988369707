import React, { useState, useEffect } from "react";
import { Col, FormGroup, Input, Label, Row, CustomInput } from "reactstrap";
import SlideModal from "./slide-modal/index";
import SwitchIcon from "@components/common/switch-icon";
import Select from "react-select";
import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import toaster from "@components/common/Toaster";
import {
  useCreateLoyaltyCoins,
  useUpdateLoyaltyCoins,
} from "../../../api/dataSource";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [email, setEmail] = useState("");
  const [bookingId, setBookingId] = useState(null);
  const [reservationId, setReservationId] = useState("");
  const [membershipId, setMembershipId] = useState("");
  const [bookingStatus, setBookingStatus] = useState("active");
  const [coins, setCoins] = useState("");
  const [coinsType, setCoinsType] = useState(null);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [isUsable, setIsUsable] = useState(true);
  const [createUserAccount, setCreateUserAccount] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [logs, setLogs] = useState(editData?.logs ? editData?.logs : []);

  const [createLoyaltyCoinsHandler, { data: createLoyaltyCoinsData }] =
    useCreateLoyaltyCoins();
  const [updateLoyaltyCoinsHandler, { data: updateLoyaltyCoinsData }] =
    useUpdateLoyaltyCoins();

  const BookingStatusList = [{ label: "active", value: "active" }];
  const CoinsTypeList = [
    { label: "Credit", value: "earned" },
    { label: "Debit", value: "used" },
  ];

  useEffect(() => {
    if (
      createLoyaltyCoinsData?.createLoyaltyCoins?.eventName ||
      updateLoyaltyCoinsData
    ) {
      refetch();
      handleToggle();

      toaster("success", "Loyalty coins added successfully");
    }
  }, [createLoyaltyCoinsData, updateLoyaltyCoinsData]);

  useEffect(() => {
    if (editData?._id) {
      setEmail(editData?.userId?.email);
      setBookingId(editData?.bookingId);
      setReservationId(editData?.reservationId);
      setMembershipId(editData?.membershipId);
      setBookingStatus({
        label: editData?.bookingStatus,
        value: editData?.bookingStatus,
      });
      setCoins(editData?.coins);
      setCoinsType({
        label: editData?.coinsType === "earned" ? "Credit" : "Debit",
        value: editData?.coinsType,
      });
      setEventName(editData?.eventName);
      setEventDate(editData?.eventDate);
      setIsUsable(editData?.isUsable);
      setLogs(editData?.logs ? editData?.logs : []);
      setCreateUserAccount(true);
      setFullName("");
      setMobile("");
    } else {
      setEmail("");
      setBookingId(null);
      setReservationId("");
      setMembershipId("");
      setBookingStatus(null);
      setCoins("");
      setCoinsType(null);
      setEventName("");
      setEventDate(new Date());
      setIsUsable(false);
      setLogs([]);
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const data = {
      email: email.toLowerCase(),
      bookingId,
      reservationId,
      membershipId,
      bookingStatus: bookingStatus?.value,
      coins: Number(coins),
      coinsType: coinsType?.value,
      eventName,
      eventDate,
      isUsable,
      logs: [
        ...logs,
        `${localStorage.getItem("name")} ${
          editData ? "modified" : "created"
        } this loyalty coins details on ${new Date().toLocaleString("en-IN", {
          hour12: false,
        })}`,
      ],
      createUserAccount,
      fullName,
      mobile,
    };

    if (email && coins && coinsType && eventName && eventDate) {
      if (editData?._id) {
        const id = editData?._id;
        // updateLoyaltyCoinsHandler(id, data);
        toaster("error", "Cannot edit the loyalty coins details once created");
        handleToggle();
      } else {
        createLoyaltyCoinsHandler(data);
      }
      setEmail("");
      setBookingId(null);
      setReservationId("");
      setMembershipId("");
      setBookingStatus(null);
      setCoins("");
      setCoinsType(null);
      setEventName("");
      setEventDate(new Date());
      setIsUsable(false);
      setLogs([]);
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      editData={editData}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {editData?._id && (
            <Col sm="12">
              <FormGroup className="w-100">
                <strong>
                  <h5
                    style={{
                      backgroundColor: "#ff0000",
                      padding: "5px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Cannot edit the coins details
                  </h5>
                </strong>
              </FormGroup>
            </Col>
          )}
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="email">
                  Guest Email*
                </Label>
              </strong>
              <Input
                type="email"
                value={email}
                id="email"
                name="email"
                // placeholder="Add Email"
                onChange={(e) => setEmail(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup>
              <CustomInput
                type="checkbox"
                id="createUserAccount"
                name="user account"
                value={createUserAccount}
                label="Create User Account"
                className="custom-control-Primary"
                onChange={(e) => setCreateUserAccount(e.target.checked)}
              />
            </FormGroup>
          </Col>

          {createUserAccount && (
            <Col sm="12">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="fullName">
                    Full Name*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={fullName}
                  id="fullName"
                  name="fullName"
                  // placeholder="Add Full Name"
                  onChange={(e) => setFullName(e.target.value)}
                  isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}
          {createUserAccount && (
            <Col sm="12">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="mobile">
                    Mobile Number*
                  </Label>
                </strong>
                <Input
                  type="mobile"
                  value={mobile}
                  id="mobile"
                  name="mobile"
                  // placeholder="Add Mobile Number"
                  onChange={(e) => setMobile(e.target.value)}
                  isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="coinsType">
                  Coins Type*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="coinsType"
                name="coinsType"
                value={coinsType}
                options={CoinsTypeList}
                onChange={(value) => setCoinsType(value)}
                classNamePrefix="select"
                isDisabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="coins">
                  Coins* (100 Coins = 1 Rs.)
                </Label>
              </strong>
              <Input
                type="text"
                value={coins}
                id="coins"
                name="coins"
                // placeholder="Add Coins"
                onChange={(e) => setCoins(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="eventName">
                  Description*
                </Label>
              </strong>
              <Input
                type="text"
                value={eventName}
                id="eventName"
                name="eventName"
                // placeholder="Add Event Name"
                onChange={(e) => setEventName(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="event date">
                  Date*
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={eventDate}
                onChange={(date) =>
                  setEventDate(new Date(dateFormatter(new Date(date))))
                }
                id="eventDate"
                // placeholder="Add Event Date"
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="bookingId">
                  Reference Booking Id (Optional)
                </Label>
              </strong>
              <Input
                type="text"
                value={bookingId}
                id="bookingId"
                name="bookingId"
                // placeholder="Add Booking Id"
                onChange={(e) => setBookingId(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          {/* <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="bookingStatus">
                  Booking Status*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="bookingStatus"
                name="bookingStatus"
                value={bookingStatus}
                options={BookingStatusList}
                onChange={(value) => setBookingStatus(value)}
                classNamePrefix="select"
                isDisabled={editData?._id}
              />
            </FormGroup>
          </Col> */}
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="reservationId">
                  Reference Reservation Id (Optional)
                </Label>
              </strong>
              <Input
                type="text"
                value={reservationId}
                id="reservationId"
                name="reservationId"
                // placeholder="Add Reservation Id"
                onChange={(e) => setReservationId(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="membershipId">
                  Reference Membership Id (Optional)
                </Label>
              </strong>
              <Input
                type="text"
                value={membershipId}
                id="membershipId"
                name="membershipId"
                // placeholder="Add Membership Id"
                onChange={(e) => setMembershipId(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <SwitchIcon
              checked={isUsable}
              id="isUsable"
              name="isUsable"
              label={
                <span style={{ fontWeight: "bold", marginBottom: "100px" }}>
                  Status ?
                </span>
              }
              handleChecked={(value) => setIsUsable(value)}
              disabled={editData?._id}
            />
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>
              <br />
              {logs
                ?.map((log, i) => {
                  return (
                    <Label className="form-label" for="name">
                      {log}.
                    </Label>
                  );
                })
                .reverse()}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
