//  Added by Nitish
import React, { useState, useEffect } from "react";
import TablePagination from "../../@core/components/common/TablePagination";
import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import Select from "react-select";
import {
  useGetPaginatedHostelComparisons,
  useGetHostelsNameandId,
} from "../../api/HostelComparisons/HostelComparisonsDataSource";
import AddEditModal from "./AddEditModal";
import HostelComparisonTable from "./HostelComparisonTable";
import { selectThemeColors } from "@utils";
import { useGetAllCompetitorsName } from "../../api/dataSource";

const HostelComparisons = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [hostel, setHostel] = useState([]);
  const [hostelIdArray, setHostelIdArray] = useState([]);
  const [hostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useGetHostelsNameandId();
  const [competitor, setCompetitor] = useState(null);
  const [platform, setPlatform] = useState(null);

  const [filterFields, setFilterFields] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });

  const { data, refetch } = useGetPaginatedHostelComparisons({
    limit,
    page,
    filterFields,
  });

  //Added by Ashish
  const {data : uniqueCompetitors} = useGetAllCompetitorsName();

  const CompetitorDropdown = uniqueCompetitors?.getUniqueCompetitors?.map((item) => {
    return { label: item, value: item };
  });

  // console.log(CompetitorDropdown, "CompetitorDropdown");


  const submitHandler = () => {
    const filterFields = {
      hostelIdArray: hostel?.map((hostel) => hostel.value),
      competitor: competitor?.value,
      platform: platform?.value,
    };
    setFilterFields(filterFields);
    setPage(1);
  };

  const refreshHandler = () => {
    setFilterFields(null);
    setHostel([]);
    setCompetitor(null);
    setPlatform(null);
    setPage(1);
  };

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelsNameandId?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelsNameandId?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelsNameandId;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelsNameandId, limit, page]);

  useEffect(() => {
    if (data?.getPaginatedHostelComparisons?.data?.length > 0) {
      setTableData(data.getPaginatedHostelComparisons?.data);
      setTotalPage(Math.ceil(data.getPaginatedHostelComparisons.count / limit));
    } else {
      setTableData([]);
      setTotalPage(1);
    }
  }, [
    data?.getPaginatedHostelComparisons?.data,
    data?.getPaginatedHostelComparisons?.count,
    limit,
    filterFields,
  ]);

  useEffect(() => {
    if (data?.getPaginatedHostelComparisons?.data.length) {
      let editData = data?.getPaginatedHostelComparisons?.data?.find(
        (item) => item._id === id
      );
      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedHostelComparisons?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const PlatformDropdown = [
    { label: "Booking.com", value: "Booking.com" },
    { label: "Goibibo", value: "Goibibo" },
    { label: "MakeMyTrip", value: "MakeMyTrip" },
  ];

  // const CompetitorDropdown = [
  //   { label: "Zostel", value: "Zostel" },
  //   { label: "GoStops", value: "GoStops" },
  // ];

  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              <strong>Hostel Name</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              options={hostelsList}
              onChange={(value) => {
                setHostel(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="competitor">
              <strong>Competitor</strong>
            </Label>
            <Select
              isClearable={true}
              id="competitor"
              name="competitor"
              value={competitor}
              options={CompetitorDropdown}
              onChange={(value) => {
                setCompetitor(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="Platform">
              <strong>Platform</strong>
            </Label>
            <Select
              isClearable={true}
              id="Platform"
              name="Platform"
              value={platform}
              options={PlatformDropdown}
              onChange={(value) => {
                setPlatform(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3" className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={() => {
              submitHandler();
            }}
          >
            Search
          </Button>

          <Button
            className="pull-right"
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={() => {
              refreshHandler();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>

      <HostelComparisonTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        editData={editData}
        refetch={refetch}
      />

      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default HostelComparisons;
