import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const PropertyWiseNoShowCancellationTable = ({ data, filter }) => {
  const [tableData, setTableData] = useState(data);

  const tableHeaderData = [
    `${filter?.reportType === "Property Wise" ? "HOSTEL" : "SOURCE"}`,
    "PAID BOOKING",
    "UNPAID BOOKING",
    "TOTAL BOOKINGS",
    "REVENUE",
  ];

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              {(filter?.reportType === "Property Wise" &&
                filter?.BookingStatus === "No Show" &&
                "Property wise no show report") ||
                (filter?.reportType === "Property Wise" &&
                  filter?.BookingStatus === "Cancel" &&
                  "Property wise cancel report") ||
                (filter?.reportType === "Source Wise" &&
                  filter?.BookingStatus === "No Show" &&
                  "Source wise no show report") ||
                (filter?.reportType === "Source Wise" &&
                  filter?.BookingStatus === "Cancel" &&
                  "Source wise cancel report")}
            </CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return (
                    <th key={uuidv4()}>
                      <div>{item}</div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item?.hostelName ? item?.hostelName : item?._id}</td>
                  {/* <td>{item?._id && item?._id}</td> */}
                  <td>{item?.paidBookings}</td>
                  <td>{item?.unpaidBookings}</td>
                  <td>{item?.totalBookings}</td>
                  <td>{item?.revenue?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default PropertyWiseNoShowCancellationTable;
