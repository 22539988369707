import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import { useParams } from "react-router";
import confirmDelete from "@components/common/confirmDelete";
import {
  useHostelList,
  useUpdateHomePageDetails,
} from "../../../../api/dataSource";

const Workation = () => {
  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [updateHomePageDetailsHandler, { data: updatedHostelsData }] =
    useUpdateHomePageDetails();
  const [selectedHostel, setSelectedHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    if (state?.tableData?.workations?.length) {
      let alreadySelectedHostels = state?.tableData?.workations?.map((item) => {
        return {
          uuId: uuidv4(),
          workations: { label: item?.name, value: item?._id },
        };
      });
      setSelectedHostel(alreadySelectedHostels);
    }
  }, [state]);

  const handleAddDestinations = () => {
    setSelectedHostel([
      ...selectedHostel,
      {
        uuId: uuidv4(),
        workations: [],
      },
    ]);
  };

  const handleRemoveSelectedHostel = (uuId) => {
    confirmDelete(() =>
      setSelectedHostel(selectedHostel.filter((item) => item.uuId !== uuId))
    );
  };

  const handleChangeSelectedHostel = (workations, uuId) => {
    setSelectedHostel(
      selectedHostel?.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              workations: { label: workations.label, value: workations.value },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      // eslint-disable-next-line array-callback-return
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const [finalHostelsArr, setFinalHostelsArr] = useState([]);

  useEffect(() => {
    if (updatedHostelsData) {
      let updatedHostels = [];
      finalHostelsArr.forEach((i) => {
        updatedHostels.push(
          allHostelsData?.getHostelList?.find((j) => j._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          workations: updatedHostels,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allHostelsData?.getHostelList,
    dispatch,
    finalHostelsArr,
    updatedHostelsData,
  ]);

  const submitDataHandler = () => {
    var finalListOfHostelsId = [];
    selectedHostel?.forEach((item) => {
      finalListOfHostelsId.push(item.workations.value);
    });
    setFinalHostelsArr(finalListOfHostelsId);
    let data = {
      workations: finalListOfHostelsId,
    };

    // if (data?.workations?.length > 0) {
    if (id && data?.workations?.every((workation) => workation !== undefined)) {
      updateHomePageDetailsHandler(id, {
        workations: data.workations,
      });
      toaster("success", "Workations updated successfully");
    } else {
      toaster("error", "Please select the workations");
    }
    // } else {
    //   toaster("error", "Please select at least one workation");
    // }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDataHandler();
      }}
    >
      <Row className="mt-2">
        {selectedHostel?.map(({ uuId, workations }, index) => {
          return (
            <Col className="d-flex align-items-center" sm="12" key={index}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"reviews" + uuId}>
                  Selected Workation {index + 1}
                </Label>
                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Select
                        isSearchable={true}
                        id={uuId}
                        name="hostel"
                        value={workations}
                        options={HostelsList}
                        onChange={(value) => {
                          handleChangeSelectedHostel(value, uuId);
                        }}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              {selectedHostel.length > 0 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveSelectedHostel(uuId)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          );
        })}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddDestinations} />
    </form>
  );
};

export default Workation;
