import React, { useEffect, useState } from "react";
import HourlySalesChart from "./HourlySalesChart";
import { useWebsiteHourlySalesReport } from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import { CSVLink } from "react-csv";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { dateValidator } from "../../../utility/Utils";
import toaster from "@components/common/Toaster";

// const tableHeaderData = [
//   { label: "id", key: "hotelCenterId" },
//   { label: "name", key: "name" },
//   { label: "addr1", key: "addr1"},
//   { label: "addr2", key: "addr2"},
//   { label: "city", key: "city"},
//   { label: "province", key: "province" },
//   { label: "postal_code", key: "postal_code" },
//   { label: "country", key: "country" },
//   { label: "latitude", key: "latitude" },
//   { label: "longitude" ,key: "longitude" },
//   { label: "category", key: "category" },
//   { label: "hotel_brand", key: "hotel_brand" },
//   { label: "phone", key: "phone" },
//   { label: "star_rating", key: "star_rating"},
//   {label: "alternate_hotel_id", key: "alternate_hotel_id"}
// ];

const HourlySales = () => {
  const [tableData, setTableData] = useState([]);
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [searchFromDate, setSearchFromDate] = useState(null);
  const [searchToDate, setSearchToDate] = useState(null);
  const { data, refetch } = useWebsiteHourlySalesReport(searchFromDate,searchToDate);

  useEffect(() => {
    if (data?.websiteHourlySalesReport) {
      setTableData(data?.websiteHourlySalesReport);
    } else {
      setTableData([]);
    }
  }, [data]);

  const submitHandler = ()=>{
    if(dateValidator(fromDate, toDate)){
      setSearchFromDate(fromDate)
      setSearchToDate(toDate)
    } else {
      toaster("error", "Invalid Dates")
    }
  }

  const handleResetData = ()=>{
    setSearchFromDate(dateFormatter(new Date()))
    setSearchToDate(dateFormatter(new Date()))
    setFromDate(dateFormatter(new Date()))
    setToDate(dateFormatter(new Date()))
  }
  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        {/* <Row> */}
        {/* <Col sm="2">
          <CSVLink
            headers={tableHeaderData}
            data={tableData}
            filename={"Google_Hotel_Center_hotelList.csv"}
          >
            <Button.Ripple
              className="btn-icon mb-2 ml-1"
              color="light"
              type="button"
              size="sm"
            >
              Export
            </Button.Ripple>
          </CSVLink>
        </Col> */}
        {/* </Row> */}

        <Row style={{marginBottom:"40px"}}>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              From Date *
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromDate}
              onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
              id="fromDate"
            />
          </FormGroup>
        </Col>

        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              To Date *
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toDate}
              onChange={(date) => setToDate(dateFormatter(new Date(date)))}
              id="fromDate"
            />
          </FormGroup>
        </Col>
        <Col sm="6" style={{marginTop:"22px"}}>
        <Button.Ripple
              onClick={submitHandler}
              className="ml-2 w-25"
              color="primary"
            >
              Search
            </Button.Ripple>

            <Button.Ripple
              onClick={handleResetData}
              className="ml-2 w-25"
              color="danger"
            >
              Reset
            </Button.Ripple>
        </Col>
        </Row>
        {tableData?.length > 0?<HourlySalesChart data={tableData} refetch={refetch} />:<h2 style={{marginTop:"150px", textAlign:"center"}}>No Record found</h2>}
        
      </div>
    )
  );
};

export default HourlySales;
