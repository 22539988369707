import React, { useEffect, useState } from "react";
import { Button, Card, Col, Label, Row, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { useCreateAndUpdateCafewiseMenu } from "../../../api/Unbox/UnboxCafewise/Mutation";
import SwitchIcon from "@components/common/switch-icon";
import toaster from "../../../@core/components/common/Toaster";
import moment from "moment";
const tableHeaderData = [
  "Item",
  "Category",
  // "Tat",
  "Season Charge",
  // "Sold Out",
  // "Start Time",
  // "End Time",
];
const tableHeaderData1 = [
  "Start Time 1",
  "End Time 1",
  "Start Time 2",
  "End Time 2",
  "Start Time 3",
  "End Time 3",
];

const ItemwiseTabel = ({ itemListData, cafeId, hostelId, refetch }) => {
  const [tableData, setTableData] = useState(itemListData);

  const [createCafewiseMenuHandler, { data: createCafeWiseData }] =
    useCreateAndUpdateCafewiseMenu();

  useEffect(() => {
    if (itemListData?.getCafewiseMenuByCafeId?.length > 0) {
      setTableData(itemListData?.getCafewiseMenuByCafeId);
    } else {
      setTableData([]);
    }
    refetch();
  }, [itemListData]);

  const [cafewise, setCafeWise] = useState([]);

  useEffect(() => {
    let newArr = [];
    tableData?.forEach((i) => {
      let updateObject = {
        item: i._id,
        hostel: hostelId,
        cafe: cafeId,
        startTime: i?.startTime,
        endTime: i?.endTime,
        startTime2: i?.startTime2,
        endTime2: i?.endTime2,
        startTime3: i?.startTime3,
        endTime3: i?.endTime3,
        tat: i?.tat,
        seasonCharge: i?.seasonCharge,
        active: i?.active,
        soldOut: i?.soldOut,
      };
      newArr.push(updateObject);
    });
    setCafeWise(newArr);
  }, [hostelId, tableData, cafeId]);

  const handleTimeRequest = (value, name, item) => {
    try {
      const updatedArray = cafewise?.map((element) => {
        if (element?.item === item?._id) {
          if (name === "startTime") {
            element.startTime = moment(value, "HH:mm").format();
          } else if (name === "endTime") {
            element.endTime = moment(value, "HH:mm").format();
          } else if (name === "startTime2") {
            element.startTime2 = moment(value, "HH:mm").format();
          } else if (name === "endTime2") {
            element.endTime2 = moment(value, "HH:mm").format();
          } else if (name === "startTime3") {
            element.startTime3 = moment(value, "HH:mm").format();
          } else if (name === "endTime3") {
            element.endTime3 = moment(value, "HH:mm").format();
          }
        }
        return element;
      });
      setCafeWise(updatedArray);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRequest = (value, name, item) => {
    try {
      const updatedArray = cafewise?.map((element) => {
        if (element?.item === item._id) {
          if (name === "active") {
            element.active = value;
          } else if (name === "soldOut") {
            element.soldOut = value;
          }
        }
        return element;
      });
      setCafeWise(updatedArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (createCafeWiseData) {
      toaster("success", `Cafewise Menu saved`);
    }
  }, [createCafeWiseData]);

  const handleCreateCafeWise = (cafewiseParams) => {
    const testObj = {
      cafewiseMenuInput: cafewiseParams,
    };

    createCafewiseMenuHandler(testObj);
  };
  // console.log(cafewise);
  // console.log(tableData);
  const [allActive, setAllActive] = useState(false);
  return (
    <>
      {tableData && cafeId && (
        <>
          <Row>
            <Col>
              <Card>
                <Table responsive>
                  <thead>
                    <tr>
                      {tableHeaderData?.map((item) => {
                        return <th key={uuidv4()}>{item}</th>;
                      })}
                      <th>
                        <Label
                          htmlFor="custom-checkbox"
                          style={{ marginLeft: "18px" }}
                        >
                          {" "}
                          Active
                        </Label>
                        <SwitchIcon
                          checked={allActive}
                          handleChecked={(value) => {
                            const updatedArray = cafewise?.map((i) => {
                              i.active = value;
                              return i;
                            });
                            setAllActive(value);
                            setCafeWise(updatedArray);
                          }}
                          id={`custom-checkbox`}
                        />
                      </th>
                      {tableHeaderData1?.map((item) => {
                        return <th key={uuidv4()}>{item}</th>;
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {tableData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.itemName}</td>
                          <td>{item?.itemCategory}</td>
                          {/* hostel TAT */}
                          {/* <td>
                            <input
                              type="text"
                              id="tat"
                              name="tat"
                              value={
                                cafewise?.find((r) => r.item === item._id)
                                  ?.tat || ""
                              }
                              onChange={(e) => {
                                const updatedArray = cafewise.map((i) => {
                                  if (i.item === item._id) {
                                    i.tat = parseInt(e.target.value) || 0;
                                  }
                                  return i;
                                });
                                setCafeWise(updatedArray);
                              }}
                              style={{ width: "60px" }}
                            />
                          </td> */}
                          <td>
                            <input
                              type="number"
                              id="seasonCharge"
                              name="seasonCharge"
                              value={
                                cafewise?.find((r) => r.item === item._id)
                                  ?.seasonCharge || ""
                              }
                              onChange={(e) => {
                                const updatedArray = cafewise?.map((i) => {
                                  if (i.item === item._id) {
                                    i.seasonCharge =
                                      parseInt(e.target.value) || 0;
                                  }
                                  return i;
                                });
                                setCafeWise(updatedArray);
                              }}
                              style={{ width: "60px" }}
                            />
                          </td>

                          {/* <td>
                            <SwitchIcon
                              checked={
                                cafewise?.length > 0
                                  ? cafewise.find((r) => r.item === item._id)
                                      .soldOut
                                  : item.soldOut
                              }
                              id={`custom-checkbox-${index}}}`}
                              name={"select" + index}
                              handleChecked={(value) => {
                                handleAddRequest(value, "soldOut", item);
                              }}
                            />
                          </td> */}
                          <td>
                            <SwitchIcon
                              checked={
                                cafewise?.length > 0
                                  ? cafewise?.find((r) => r.item === item._id)
                                      .active
                                  : item.active
                              }
                              id={`custom-checkbox-${index}}`}
                              name={"select" + index}
                              handleChecked={(value) => {
                                handleAddRequest(value, "active", item);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="time"
                              id="startTime"
                              name="startTime"
                              onChange={(e) => {
                                handleTimeRequest(
                                  e.target.value,
                                  "startTime",
                                  item
                                );
                              }}
                              required
                              defaultValue={
                                cafewise?.find((r) => r?.item === item?._id)
                                  ?.startTime
                                  ? moment(
                                      cafewise?.find(
                                        (r) => r?.item === item?._id
                                      )?.startTime
                                    ).format("HH:mm")
                                  : ""
                              }
                            />
                          </td>

                          <td>
                            <input
                              type="time"
                              id="endTime"
                              name="endTime"
                              onChange={(e) => {
                                handleTimeRequest(
                                  e.target.value,
                                  "endTime",
                                  item
                                );
                              }}
                              required
                              defaultValue={
                                cafewise?.find((r) => r?.item === item?._id)
                                  ?.endTime &&
                                moment(
                                  cafewise?.find((r) => r?.item === item?._id)
                                    ?.endTime
                                ).format("HH:mm")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="time"
                              id="startTime2"
                              name="startTime2"
                              onChange={(e) => {
                                handleTimeRequest(
                                  e.target.value,
                                  "startTime2",
                                  item
                                );
                              }}
                              required
                              defaultValue={
                                cafewise?.find((r) => r?.item === item?._id)
                                  ?.startTime2 &&
                                moment(
                                  cafewise?.find((r) => r?.item === item?._id)
                                    ?.startTime2
                                ).format("HH:mm")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="time"
                              id="endTime2"
                              name="endTime2"
                              onChange={(e) => {
                                handleTimeRequest(
                                  e.target.value,
                                  "endTime2",
                                  item
                                );
                              }}
                              required
                              defaultValue={
                                cafewise?.find((r) => r?.item === item?._id)
                                  ?.endTime2 &&
                                moment(
                                  cafewise?.find((r) => r?.item === item?._id)
                                    ?.endTime2
                                ).format("HH:mm")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="time"
                              id="startTime3"
                              name="startTime3"
                              onChange={(e) => {
                                handleTimeRequest(
                                  e.target.value,
                                  "startTime3",
                                  item
                                );
                              }}
                              required
                              defaultValue={
                                cafewise?.find((r) => r?.item === item?._id)
                                  ?.startTime3 &&
                                moment(
                                  cafewise?.find((r) => r?.item === item?._id)
                                    ?.startTime3
                                ).format("HH:mm")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="time"
                              id="endTime3"
                              name="endTime3"
                              onChange={(e) => {
                                handleTimeRequest(
                                  e.target.value,
                                  "endTime3",
                                  item
                                );
                              }}
                              required
                              defaultValue={
                                cafewise?.find((r) => r?.item === item?._id)
                                  ?.endTime3 &&
                                moment(
                                  cafewise?.find((r) => r?.item === item?._id)
                                    ?.endTime3
                                ).format("HH:mm")
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          <Button
            color="light"
            className="float-right"
            onClick={() => {
              handleCreateCafeWise(cafewise);
            }}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
};

export default ItemwiseTabel;
