import React, { useEffect, useState } from "react";
import { FileText } from "react-feather";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Name",
  "Category",
  "Type",
  "WeekDay Price",
  "WeekEnd Price",
];
const MasterMenuTable = ({
  openModal,
  data,
  setId,
  handleCreateUpdateUnboxMasterMenu,
  refetch,
}) => {
  const [tableData, setTableData] = useState(data);
  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
    //refetch();
  }, [data]);
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Unbox Master Menu</CardTitle>

            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Master Menu
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((element, i) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "5%" }}>
                    {/* <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(element?._id);
                        openModal();
                      }}
                    >
                      <FileText size={15} />
                    </Button> */}
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCreateUpdateUnboxMasterMenu(element?._id);
                      }}
                    >
                      <FileText
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>{element?.itemName}</td>
                  <td>{element?.itemCategory}</td>
                  <td>{element?.type}</td>
                  <td>{element?.weekDayPrice}</td>
                  <td>{element?.weekEndPrice}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default MasterMenuTable;
