import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const FeedbackHostelWiseAvgTat = ({ hostelTatData: data }) => {
  // for table
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const [series, setSeries] = useState([
    {
      name: "AvgTAT",
      data: [],
    },
    {
      name: "TotalAvgTAT",
      data: [],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      title: {
        text: "TAT (in hours)",
      },
    },
    yaxis: {
      title: {
        text: "Hostel Name",
      },
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    colors: ["#FFE700", "#FF4560"],
  });

  useEffect(() => {
    if (!data) return;
    const chartDataAvgTAT = data.map((item) => item.avgTAT);
    const chartDataTotalAvgTAT = data.map((item) => item.totalAvgTAT);
    const labels = data.map((item) => item.hostelName);

    setSeries([
      { name: "Hostel wise Avg TAT", data: chartDataAvgTAT },
      { name: "Total Avg TAT", data: chartDataTotalAvgTAT },
    ]);
    setOptions({
      ...options,
      labels: labels,
    });
  }, [data]);

  return (
    <Row>
      <Col sm="6">
        <Card>
          <CardHeader>
            <CardTitle>Hostelwise closure rate</CardTitle>
          </CardHeader>
          <div style={{ height: "900px", overflow: "auto" }}>
            <Table responsive>
              <thead>
                <tr>
                  <td>
                    <h5>Hostel Name</h5>
                  </td>
                  <td>
                    <h5>Hostel Wise Average TAT (in hours)</h5>
                  </td>
                  <td>
                    <h5>Total Average TAT (in hours)</h5>
                  </td>
                  <td>
                    <h5>Review Count</h5>
                  </td>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item) => (
                  <tr key={uuidv4()}>
                    <td>{item?.hostelName.replace("The Hosteller", "")}</td>
                    <td>{item?.avgTAT}</td>
                    <td>{item?.totalAvgTAT}</td>
                    <td>{item?.reviewCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <Col sm="6">
        {" "}
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={1000}
          />
        </div>
      </Col>
    </Row>
  );
};

export default FeedbackHostelWiseAvgTat;
