// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHostel } from "../../../../api/dataSource";
import { useParams } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

const TimingInformation = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);

  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");
  const [guestVisit, setGuestVisit] = useState("");
  const [cafe, setCafe] = useState("");
  const [reception, setReception] = useState("");
  const [other, setOther] = useState("");

  const dispatch = useDispatch();

  const [updateHostelHandler, { data: updatdedHostelData, error }] =
    useUpdateHostel();

  useEffect(() => {
    let prePopulateData;

    if (state.length) {
      tableDataHandler({
        ...state?.tableData,
        cardData: {
          checkin,
          checkout,
          guestVisit,
          cafe,
          reception,
          other,
        },
      });
    }

    if (prePopulateData) {
      setCheckin(prePopulateData?.timingInformation.checkin);
      setCheckout(prePopulateData?.timingInformation.checkout);
      setGuestVisit(prePopulateData?.timingInformation.guestVisit);
      setCafe(prePopulateData?.timingInformation.cafe);
      setReception(prePopulateData?.timingInformation.reception);
      setOther(prePopulateData?.timingInformation.other);
    } else {
      setCheckin("");
      setCheckout("");
      setGuestVisit("");
      setCafe("");
      setReception("");
      setOther("");
    }
  }, [state]);

  useEffect(() => {
    if (state.tableData) {
      setCheckin(state.tableData?.timingInformation?.checkin);
      setCheckout(state.tableData?.timingInformation?.checkout);
      setGuestVisit(state.tableData?.timingInformation?.guestVisit);
      setCafe(state.tableData?.timingInformation?.cafe);
      setReception(state.tableData?.timingInformation?.reception);
      setOther(state.tableData?.timingInformation?.other);
    } else {
      setCheckin("");
      setCheckout("");
      setGuestVisit("");
      setCafe("");
      setReception("");
      setOther("");
    }
  }, [state.tableData]);

  const submitHandler = (e) => {
    // let isValid = false;
    e.preventDefault();

    const data = {
      checkin,
      checkout,
      guestVisit,
      cafe,
      reception,
      other,
    };

    if (id) {
      updateHostelHandler(id, { timingInformation: data });
      toaster("success", "Timing information updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...{ timingInformation: data },
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Check In
            </Label>

            <Input
              type="text"
              value={checkin}
              id="checkin"
              name="checkin"
              placeholder="Check In"
              onChange={(e) => setCheckin(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Check Out
            </Label>

            <Input
              type="text"
              value={checkout}
              id="checkout"
              name="checkout"
              placeholder="Check Out"
              onChange={(e) => setCheckout(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Guest Visit
            </Label>

            <Input
              type="text"
              value={guestVisit}
              id="guestVisit"
              name="guestVisit"
              placeholder="Guest Visit"
              onChange={(e) => setGuestVisit(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Cafe
            </Label>

            <Input
              type="text"
              value={cafe}
              id="cafe"
              name="cafe"
              placeholder="Cafe"
              onChange={(e) => setCafe(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Reception
            </Label>

            <Input
              type="text"
              value={reception}
              id="reception"
              name="reception"
              placeholder="Reception"
              onChange={(e) => setReception(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Common Area
            </Label>

            <Input
              type="text"
              value={other}
              id="other"
              name="other"
              placeholder="Common Area"
              onChange={(e) => setOther(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default TimingInformation;
