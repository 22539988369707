import gql from "graphql-tag";

export const CREATE_STEP_OUT_PRODUCT = gql`
mutation createStepOutProduct($createStepOutInput: CreateStepOutInput) {
    createStepOutProduct(createStepOutInput: $createStepOutInput) {
        _id
        title
    }
}
`

export const UPDATE_STEP_OUT_PRODUCT = gql`
mutation updateStepOutProduct($id: ID $stepOutProductUpdate: CreateStepOutInput) {
    updateStepOutProduct(id: $id stepOutProductUpdate: $stepOutProductUpdate){
        _id
        title
    }
}
`