// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import confirmDelete from "@components/common/confirmDelete";
import {
  useHostelList,
  useUpdateWorkationLandingPageDetails,
} from "../../../../api/dataSource";

const Hostel = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [
    updateWorkationLandingPageDetailsHandler,
    { data: updatedHostelsData },
  ] = useUpdateWorkationLandingPageDetails();
  const [HostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useHostelList();

  //!!!!!!!!!!!!  (WORKATION 1)

  const [selectedHostel1, setSelectedHostel1] = useState([
    {
      uuId1: uuidv4(),
      hostels1: [],
    },
  ]);

  const [location1, setLocation1] = useState("");

  useEffect(() => {
    if (state?.tableData?.workation1.hostels.length) {
      let alreadySelectedHostels = state?.tableData?.workation1?.hostels?.map(
        (hostel) => {
          return {
            uuId1: uuidv4(),
            hostels1: { label: hostel?.name, value: hostel?._id },
          };
        }
      );
      setSelectedHostel1(alreadySelectedHostels);
      setLocation1(state?.tableData?.workation1?.location);
    }
  }, [state]);

  const handleAddHostels1 = () =>
    setSelectedHostel1([
      ...selectedHostel1,
      {
        uuId1: uuidv4(),
        hostels1: [],
      },
    ]);

  const handleChangeLocation1 = (location1) => {
    setLocation1(location1);
  };

  const handleRemoveSelectedHostel1 = (uuId1) =>
    confirmDelete(() =>
      setSelectedHostel1(selectedHostel1.filter((item) => item.uuId1 !== uuId1))
    );

  const handleChangeSelectedHostel1 = (hostel1, uuId1) => {
    setSelectedHostel1(
      selectedHostel1.map((item) => {
        return item.uuId1 === uuId1
          ? {
              ...item,
              hostels1: { label: hostel1.label, value: hostel1.value },
            }
          : item;
      })
    );
  };

  //!!!!!!!!!!!!  (WORKATION 2)

  const [selectedHostel2, setSelectedHostel2] = useState([
    {
      uuId2: uuidv4(),
      hostels2: [],
    },
  ]);

  const [location2, setLocation2] = useState("");

  useEffect(() => {
    if (state?.tableData?.workation2.hostels.length) {
      let alreadySelectedHostels = state?.tableData?.workation2?.hostels?.map(
        (hostel) => {
          return {
            uuId2: uuidv4(),
            hostels2: { label: hostel?.name, value: hostel?._id },
          };
        }
      );
      setSelectedHostel2(alreadySelectedHostels);
      setLocation2(state?.tableData?.workation2?.location);
    }
  }, [state]);

  const handleAddHostels2 = () =>
    setSelectedHostel2([
      ...selectedHostel2,
      {
        uuId2: uuidv4(),
        hostels2: [],
      },
    ]);

  const handleChangeLocation2 = (location2) => {
    setLocation2(location2);
  };

  const handleRemoveSelectedHostel2 = (uuId2) =>
    confirmDelete(() =>
      setSelectedHostel2(selectedHostel2.filter((item) => item.uuId2 !== uuId2))
    );

  const handleChangeSelectedHostel2 = (hostel2, uuId2) => {
    setSelectedHostel2(
      selectedHostel2.map((item) => {
        return item.uuId2 === uuId2
          ? {
              ...item,
              hostels2: { label: hostel2.label, value: hostel2.value },
            }
          : item;
      })
    );
  };

  //!!!!!!!!!!!!  (WORKATION 3)
  const [selectedHostel3, setSelectedHostel3] = useState([
    {
      uuId3: uuidv4(),
      hostels3: [],
    },
  ]);

  const [location3, setLocation3] = useState("");

  useEffect(() => {
    if (state?.tableData?.workation3.hostels.length) {
      let alreadySelectedHostels = state?.tableData?.workation3?.hostels?.map(
        (hostel) => {
          return {
            uuId3: uuidv4(),
            hostels3: { label: hostel?.name, value: hostel?._id },
          };
        }
      );
      setSelectedHostel3(alreadySelectedHostels);
      setLocation3(state?.tableData?.workation3?.location);
    }
  }, [state]);

  const handleAddHostels3 = () =>
    setSelectedHostel3([
      ...selectedHostel3,
      {
        uuId3: uuidv4(),
        hostels3: [],
      },
    ]);

  const handleChangeLocation3 = (location3) => {
    setLocation3(location3);
  };

  const handleRemoveSelectedHostel3 = (uuId3) =>
    confirmDelete(() =>
      setSelectedHostel3(selectedHostel3.filter((item) => item.uuId3 !== uuId3))
    );

  const handleChangeSelectedHostel3 = (hostel3, uuId3) => {
    setSelectedHostel3(
      selectedHostel3.map((item) => {
        return item.uuId3 === uuId3
          ? {
              ...item,
              hostels3: { label: hostel3.label, value: hostel3.value },
            }
          : item;
      })
    );
  };

  //!!!!!!!!!!!!  (WORKATION 4)
  const [selectedHostel4, setSelectedHostel4] = useState([
    {
      uuId4: uuidv4(),
      hostels4: [],
    },
  ]);

  const [location4, setLocation4] = useState("");

  useEffect(() => {
    if (state?.tableData?.workation4.hostels.length) {
      let alreadySelectedHostels = state?.tableData?.workation4?.hostels?.map(
        (hostel) => {
          return {
            uuId4: uuidv4(),
            hostels4: { label: hostel?.name, value: hostel?._id },
          };
        }
      );
      setSelectedHostel4(alreadySelectedHostels);
      setLocation4(state?.tableData?.workation4?.location);
    }
  }, [state]);

  const handleAddHostels4 = () =>
    setSelectedHostel4([
      ...selectedHostel4,
      {
        uuId4: uuidv4(),
        hostels4: [],
      },
    ]);

  const handleChangeLocation4 = (location4) => {
    setLocation4(location4);
  };

  const handleRemoveSelectedHostel4 = (uuId4) =>
    confirmDelete(() =>
      setSelectedHostel4(selectedHostel4.filter((item) => item.uuId4 !== uuId4))
    );

  const handleChangeSelectedHostel4 = (hostel4, uuId4) => {
    setSelectedHostel4(
      selectedHostel4.map((item) => {
        return item.uuId4 === uuId4
          ? {
              ...item,
              hostels4: { label: hostel4.label, value: hostel4.value },
            }
          : item;
      })
    );
  };

  //!!!!!!!!!!!!  (WORKATION 5)
  const [selectedHostel5, setSelectedHostel5] = useState([
    {
      uuId5: uuidv4(),
      hostels5: [],
    },
  ]);

  const [location5, setLocation5] = useState("");

  useEffect(() => {
    if (state?.tableData?.workation5.hostels.length) {
      let alreadySelectedHostels = state?.tableData?.workation5?.hostels?.map(
        (hostel) => {
          return {
            uuId5: uuidv4(),
            hostels5: { label: hostel?.name, value: hostel?._id },
          };
        }
      );
      setSelectedHostel5(alreadySelectedHostels);
      setLocation5(state?.tableData?.workation5?.location);
    }
  }, [state]);

  const handleAddHostels5 = () =>
    setSelectedHostel5([
      ...selectedHostel5,
      {
        uuId5: uuidv4(),
        hostels5: [],
      },
    ]);

  const handleChangeLocation5 = (location5) => {
    setLocation5(location5);
  };

  const handleRemoveSelectedHostel5 = (uuId5) =>
    confirmDelete(() =>
      setSelectedHostel5(selectedHostel5.filter((item) => item.uuId5 !== uuId5))
    );

  const handleChangeSelectedHostel5 = (hostel5, uuId5) => {
    setSelectedHostel5(
      selectedHostel5.map((item) => {
        return item.uuId5 === uuId5
          ? {
              ...item,
              hostels5: { label: hostel5.label, value: hostel5.value },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      // eslint-disable-next-line array-callback-return
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const [finalHostelsArr1, setFinalHostelsArr1] = useState([]);
  const [finalHostelsArr2, setFinalHostelsArr2] = useState([]);
  const [finalHostelsArr3, setFinalHostelsArr3] = useState([]);
  const [finalHostelsArr4, setFinalHostelsArr4] = useState([]);
  const [finalHostelsArr5, setFinalHostelsArr5] = useState([]);

  useEffect(() => {
    if (updatedHostelsData) {
      let updatedHostels1 = [];
      let updatedHostels2 = [];
      let updatedHostels3 = [];
      let updatedHostels4 = [];
      let updatedHostels5 = [];

      finalHostelsArr1.forEach((i) => {
        updatedHostels1.push(
          allHostelsData?.getHostelList?.find((j) => j._id === i)
        );
      });

      finalHostelsArr2.forEach((i) => {
        updatedHostels2.push(
          allHostelsData?.getHostelList?.find((j) => j._id === i)
        );
      });

      finalHostelsArr3.forEach((i) => {
        updatedHostels3.push(
          allHostelsData?.getHostelList?.find((j) => j._id === i)
        );
      });

      finalHostelsArr4.forEach((i) => {
        updatedHostels4.push(
          allHostelsData?.getHostelList?.find((j) => j._id === i)
        );
      });

      finalHostelsArr5.forEach((i) => {
        updatedHostels5.push(
          allHostelsData?.getHostelList?.find((j) => j._id === i)
        );
      });

      let workation1 = {
        location: location1,
        hostels: updatedHostels1,
      };
      let workation2 = {
        location: location2,
        hostels: updatedHostels2,
      };
      let workation3 = {
        location: location3,
        hostels: updatedHostels3,
      };
      let workation4 = {
        location: location4,
        hostels: updatedHostels4,
      };
      let workation5 = {
        location: location5,
        hostels: updatedHostels5,
      };

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          workation1: workation1,
          workation2: workation2,
          workation3: workation3,
          workation4: workation4,
          workation5: workation5,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allHostelsData?.getHostelList,
    dispatch,
    finalHostelsArr1,
    finalHostelsArr2,
    finalHostelsArr3,
    finalHostelsArr4,
    finalHostelsArr5,
    updatedHostelsData,
  ]);

  const submitDataHandler = () => {
    var listOfHostelsId1 = [];
    var listOfHostelsId2 = [];
    var listOfHostelsId3 = [];
    var listOfHostelsId4 = [];
    var listOfHostelsId5 = [];

    selectedHostel1?.forEach((item) => {
      listOfHostelsId1.push(item.hostels1.value);
    });

    setFinalHostelsArr1(listOfHostelsId1);

    selectedHostel2?.forEach((item) => {
      listOfHostelsId2.push(item.hostels2.value);
    });

    setFinalHostelsArr2(listOfHostelsId2);

    selectedHostel3?.forEach((item) => {
      listOfHostelsId3.push(item.hostels3.value);
    });

    setFinalHostelsArr3(listOfHostelsId3);

    selectedHostel4?.forEach((item) => {
      listOfHostelsId4.push(item.hostels4.value);
    });

    setFinalHostelsArr4(listOfHostelsId4);

    selectedHostel5?.forEach((item) => {
      listOfHostelsId5.push(item.hostels5.value);
    });

    setFinalHostelsArr5(listOfHostelsId5);

    let workations1 = {
      location: location1,
      hostels: listOfHostelsId1,
    };
    let workations2 = {
      location: location2,
      hostels: listOfHostelsId2,
    };
    let workations3 = {
      location: location3,
      hostels: listOfHostelsId3,
    };
    let workations4 = {
      location: location4,
      hostels: listOfHostelsId4,
    };
    let workations5 = {
      location: location5,
      hostels: listOfHostelsId5,
    };

    // if (
    //   workations1?.location &&
    //   workations1?.hostels?.every((hostel) => hostel !== undefined) &&
    //   workations2?.location &&
    //   workations2?.hostels?.every((hostel) => hostel !== undefined) &&
    //   workations3?.location &&
    //   workations3?.hostels?.every((hostel) => hostel !== undefined) &&
    //   workations4?.location &&
    //   workations4?.hostels?.every((hostel) => hostel !== undefined) &&
    //   workations5?.location &&
    //   workations5?.hostels?.every((hostel) => hostel !== undefined)
    // ) {
    updateWorkationLandingPageDetailsHandler("workationlandingpage", {
      workation1: workations1,
      workation2: workations2,
      workation3: workations3,
      workation4: workations4,
      workation5: workations5,
    });

    toaster("success", "Workation location updated successfully");
    // } else {
    //   toaster("error", "Please select the hostel");
    // }
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitDataHandler();
        }}
      >
        {/* // TODO  (WORKATION 1) */}
        <h6>Workation Location 1</h6>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor="newDetailsState">
                Location Name
              </Label>
              <Input
                type="text"
                value={location1}
                id={"location1"}
                name={"location1"}
                placeholder="Location"
                onChange={(e) => handleChangeLocation1(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          {selectedHostel1.map(({ uuId1, hostels1 }, index) => (
            <Col className="d-flex align-items-center" sm="12" key={uuId1}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"hostels" + uuId1}>
                  Selected Hostel {index + 1}
                </Label>

                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Select
                        isClearable={false}
                        id={uuId1}
                        name="hostel1"
                        value={hostels1}
                        options={HostelsList}
                        onChange={(value) => {
                          handleChangeSelectedHostel1(value, uuId1);
                        }}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              {selectedHostel1.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveSelectedHostel1(uuId1)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHostels1} />
        <hr />

        {/* // TODO  (WORKATION 2) */}
        <h6>Workation Location 2</h6>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor="newDetailsState">
                Location Name
              </Label>
              <Input
                type="text"
                value={location2}
                id={"location2"}
                name={"location2"}
                placeholder="Location"
                onChange={(e) => handleChangeLocation2(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          {selectedHostel2.map(({ uuId2, hostels2 }, index) => (
            <Col className="d-flex align-items-center" sm="12" key={uuId2}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"hostels" + uuId2}>
                  Selected Hostel {index + 1}
                </Label>

                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Select
                        isClearable={false}
                        id={uuId2}
                        name="hostel2"
                        value={hostels2}
                        options={HostelsList}
                        onChange={(value) => {
                          handleChangeSelectedHostel2(value, uuId2);
                        }}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              {selectedHostel2.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveSelectedHostel2(uuId2)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHostels2} />
        <hr />
        {/* // TODO  (WORKATION 3) */}
        <h6>Workation Location 3</h6>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor="newDetailsState">
                Location Name
              </Label>
              <Input
                type="text"
                value={location3}
                id={"location3"}
                name={"location3"}
                placeholder="Location"
                onChange={(e) => handleChangeLocation3(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          {selectedHostel3.map(({ uuId3, hostels3 }, index) => (
            <Col className="d-flex align-items-center" sm="12" key={uuId3}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"hostels" + uuId3}>
                  Selected Hostel {index + 1}
                </Label>

                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Select
                        isClearable={false}
                        id={uuId3}
                        name="hostel3"
                        value={hostels3}
                        options={HostelsList}
                        onChange={(value) => {
                          handleChangeSelectedHostel3(value, uuId3);
                        }}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              {selectedHostel3.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveSelectedHostel3(uuId3)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHostels3} />
        <hr />

        {/* // TODO  (WORKATION 4) */}
        <h6>Workation Location 4</h6>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor="newDetailsState">
                Location Name
              </Label>
              <Input
                type="text"
                value={location4}
                id={"location4"}
                name={"location4"}
                placeholder="Location"
                onChange={(e) => handleChangeLocation4(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          {selectedHostel4.map(({ uuId4, hostels4 }, index) => (
            <Col className="d-flex align-items-center" sm="12" key={uuId4}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"hostels" + uuId4}>
                  Selected Hostel {index + 1}
                </Label>

                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Select
                        isClearable={false}
                        id={uuId4}
                        name="hostel4"
                        value={hostels4}
                        options={HostelsList}
                        onChange={(value) => {
                          handleChangeSelectedHostel4(value, uuId4);
                        }}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              {selectedHostel4.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveSelectedHostel4(uuId4)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHostels4} />
        <hr />

        {/* // TODO  (WORKATION 5) */}
        <h6>Workation Location 5</h6>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor="newDetailsState">
                Location Name
              </Label>
              <Input
                type="text"
                value={location5}
                id={"location5"}
                name={"location5"}
                placeholder="Location"
                onChange={(e) => handleChangeLocation5(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          {selectedHostel5.map(({ uuId5, hostels5 }, index) => (
            <Col className="d-flex align-items-center" sm="12" key={uuId5}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"hostels" + uuId5}>
                  Selected Hostel {index + 1}
                </Label>

                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Select
                        isClearable={false}
                        id={uuId5}
                        name="hostel5"
                        value={hostels5}
                        options={HostelsList}
                        onChange={(value) => {
                          handleChangeSelectedHostel5(value, uuId5);
                        }}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              {selectedHostel5.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveSelectedHostel5(uuId5)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHostels5} />
        <hr />
      </form>
    </>
  );
};

export default Hostel;
