import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import {
  useCreateWhatsNew,
  useUpdateWhatsNew,
  useUploadImage,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import { tableDataHandler } from "@store/Cms/cms.action";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [tagLine, setTagLine] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [url, setUrl] = useState("");
  const [status, setStatus] = useState(false);
  const [displayOrder, setDisplayOrder] = useState("");

  const [imagePreview, setImagePreview] = useState(null);
  const { id } = useParams();
  const [
    uploadImageHandler,
    { data: imageData, reset: imageReset, loading: imageLoad },
  ] = useUploadImage();

  const [createWhatsNewHandler, { data }] = useCreateWhatsNew();

  const [updateWhatsNewHandler, { data: updatedWhatsNewData }] =
    useUpdateWhatsNew();

  useEffect(() => {
    if (data?.createWhatsNew?.tagLine || updatedWhatsNewData) {
      refetch();
      handleToggle();

      toaster("success", "In the Spot Light added successfully");
    }
  }, [data, updatedWhatsNewData]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setImagePreview([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }
    // setUploadingType(result.successful[0]?.source, state.tableData);

    const url = title ? `WorkationLandingPage` : "common";

    uploadImageHandler(file.data, "icons/Amenities");
    toaster("success", "In the spot light image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading spot light image: ${error.message}`);
  });

  const renderPreview = () =>
    imagePreview ? (
      <img className="rounded mr-1 mb-2 w-50" src={imagePreview} alt="avatar" />
    ) : null;

  useEffect(() => {
    setImagePreview(imageData?.uploadImage?.url);
  }, [imageData]);

  const submitDataHandler = () => {
    let data = {
      tagLine,
      title,
      description,
      buttonText,
      url,
      status,
      image: imagePreview,
      displayOrder: parseInt(displayOrder),
    };

    if (data.tagLine) {
      if (editData) {
        const { _id } = editData;
        updateWhatsNewHandler(_id, data);
      } else {
        createWhatsNewHandler(data);
      }

      setTagLine("");
      setTitle("");
      setDescription("");
      setButtonText("");
      setUrl("");
      setStatus(false);
      setImagePreview("");
      setDisplayOrder("");
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  useEffect(() => {
    if (editData) {
      setTagLine(editData?.tagLine);
      setTitle(editData?.title);
      setDescription(editData?.description);
      setButtonText(buttonText === null ? "" : editData?.buttonText);
      setUrl(editData?.url);
      setStatus(editData?.status);
      setImagePreview(editData?.image);
      setDisplayOrder(editData?.displayOrder);
    } else {
      setTagLine("");
      setTitle("");
      setDescription("");
      setButtonText("");
      setUrl("");
      setStatus(false);
      setImagePreview("");
      setDisplayOrder("");
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Title */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="title">
                Title*
              </Label>
              <Input
                type="text"
                value={title}
                id="title"
                name="title"
                placeholder="Add Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Description */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                Description*
              </Label>
              <Input
                type="text"
                value={description}
                id="description"
                name="description"
                placeholder="Add Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Tagline */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="tagLine">
                Tag Line*
              </Label>
              <Input
                type="text"
                value={tagLine}
                id="tagLine"
                name="tagLine"
                placeholder="Add TagLine"
                onChange={(e) => setTagLine(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Display Order */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="displayOrder">
                Display Order*
              </Label>
              <Input
                type="number"
                value={displayOrder}
                id="displayOrder"
                name="displayOrder"
                placeholder="Add Display Order"
                onChange={(e) => setDisplayOrder(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Button Text */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="buttonText">
                Button Text*
              </Label>
              <Input
                type="text"
                value={buttonText}
                id="buttonText"
                Title
                name="buttonText"
                placeholder="Add Button Text"
                onChange={(e) => setButtonText(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! URL */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                URL*
              </Label>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add Url"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Status */}
          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Publish on Website ?"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>

          {/* // ! Image */}
          <Col sm="12">
            <p>Upload Image</p>
            <DragDrop
              note="Image size should be less than 500KB"
              width="100%"
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: "Drop image here or %{browse}",
                  browse: "browse",
                },
              }}
            />
            <Col sm="6">
              <Label className="form-label w-100">Image preview here</Label>
              {imageLoad ? "Uploading image..." : renderPreview()}
            </Col>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
