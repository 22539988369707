import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { v4 as uuidv4 } from "uuid";
import { useUpdateMasterMenu } from "../../../api/Unbox/UnboxMasterMenu/Mutations";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Plus, X } from "react-feather";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import confirmDelete from "@components/common/confirmDelete";
const Options = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [optionsTitle, setOptionsTitle] = useState("");
  const [extrasTitle, setExtrasTitle] = useState("");
  const [options, setOptions] = useState([
    { id: uuidv4(), name: "", weekDayPrice: "", weekEndPrice: "" },
  ]);

  const [extras, setExtras] = useState(
    //[
    // { id: uuidv4(), name: "", weekDayPrice: "", weekEndPrice: "" },
    //]
    null
  );
  const [visible, setVisible] = useState(false);
  const [updateMasterMenuHandler, { data, refetch }] = useUpdateMasterMenu();

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData?.options?.length) {
      setOptions(
        state?.tableData?.options?.map((item) => {
          return {
            id: uuidv4(),
            name: item?.name,
            weekDayPrice: item?.weekDayPrice,
            weekEndPrice: item?.weekEndPrice,
          };
        })
      );
      setExtras(
        state?.tableData?.extras?.map((item) => {
          return {
            id: uuidv4(),
            name: item?.name,
            weekDayPrice: item?.weekDayPrice,
            weekEndPrice: item?.weekEndPrice,
          };
        })
      );
    }
    setExtrasTitle(
      state?.tableData?.extrasTitle !== null
        ? state?.tableData?.extrasTitle
        : ""
    );
    setOptionsTitle(
      state?.tableData?.optionsTitle !== null
        ? state?.tableData?.optionsTitle
        : ""
    );
    setVisible(true);
  }, [state]);

  const handleChangeName = (val, id, key) => {
    //console.log(val);
    setOptions(
      options?.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );
  };

  const handleChangeWeekDayPrice = (val, id, key) => {
    if (!isNaN(val)) {
      setOptions(
        options?.map((item) =>
          item.id === id ? { ...item, [key]: Number(val) } : item
        )
      );
    } else {
      toaster("error", "Please Enter Number Only");
    }
  };

  const handleChangeWeekEndPrice = (val, id, key) => {
    if (!isNaN(val)) {
      setOptions(
        options?.map((item) =>
          item.id === id ? { ...item, [key]: Number(val) } : item
        )
      );
    } else {
      toaster("error", "Please Enter Number Only");
    }
  };

  const handleChangeExtraName = (val, id, key) => {
    //console.log(val);

    setExtras(
      extras?.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );
  };

  const handleChangeExtraWeekDayPrice = (val, id, key) => {
    if (!isNaN(val) && !val?.includes("-")) {
      setExtras(
        extras?.map((item) =>
          item.id === id ? { ...item, [key]: Number(val) } : item
        )
      );
    } else {
      toaster("error", "Please Enter Number Only");
    }
  };

  const handleChangeExtraWeekEndPrice = (val, id, key) => {
    if (!isNaN(val) && !val?.includes("-")) {
      setExtras(
        extras?.map((item) =>
          item.id === id ? { ...item, [key]: Number(val) } : item
        )
      );
    } else {
      toaster("error", "Please Enter Number Only");
    }
  };

  const handleAddExtra = () => {
    setExtras([
      ...extras,
      {
        id: uuidv4(),
        name: "",
        weekDayPrice: "",
        weekEndPrice: "",
      },
    ]);
  };

  const handleAddOptions = () =>
    setOptions([
      ...options,
      {
        id: uuidv4(),
        name: "",
        weekDayPrice: "",
        weekEndPrice: "",
      },
    ]);

  const handleRemoveExtras = (id) => {
    confirmDelete(() => setExtras(extras?.filter((item) => item.id !== id)));
  };
  const handleRemoveOptions = (id) => {
    confirmDelete(() => setOptions(options?.filter((item) => item.id !== id)));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    let data = {
      optionsTitle: optionsTitle,
      extrasTitle: extrasTitle,
      options: options?.map((item) => {
        delete item.id;
        return item;
      }),
      extras: extras?.map((item) => {
        delete item.id;
        return item;
      }),
    };

    if (id) {
      updateMasterMenuHandler(id, data);
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill all the required details");
    }
  };
  return (
    <div>
      <form className="mt-2" onSubmit={onSubmitHandler}>
        <div>
          <Row>
            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="optionsTitle">
                  Options Title
                </Label>
                <Input
                  type="text"
                  value={optionsTitle}
                  id="optionsTitle"
                  name="optionsTitle"
                  placeholder="Option title"
                  onChange={(e) => setOptionsTitle(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="extraTitle">
                  Extras Title
                </Label>
                <Input
                  type="text"
                  value={extrasTitle}
                  id="extraTitle"
                  name="extrasTitle"
                  placeholder="Extra Title"
                  onChange={(e) => setExtrasTitle(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <h4>Options</h4>
          {options?.map((element, index) => {
            return (
              <div key={element?.id}>
                <Row className="mt-2">
                  <Col sm="7">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="name">
                        Name
                      </Label>
                      <Input
                        type="text"
                        value={element?.name}
                        id="name"
                        name="name"
                        placeholder="Add Name"
                        onChange={(e) =>
                          handleChangeName(e.target.value, element?.id, "name")
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="2">
                    <FormGroup className="w-10">
                      <Label className="form-label" for="weekDayPrice">
                        Week Day Price
                      </Label>
                      <Input
                        type="text"
                        value={element?.weekDayPrice}
                        id="weekDayPrice"
                        name="weekDayPrice"
                        placeholder="Weekday Price"
                        required
                        maxLength={9}
                        onChange={(e) =>
                          handleChangeWeekDayPrice(
                            e.target.value,
                            element?.id,
                            "weekDayPrice"
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="2">
                    <FormGroup className="w-10">
                      <Label className="form-label" for="weekEndPrice">
                        Week End Price
                      </Label>
                      <Input
                        type="text"
                        value={element?.weekEndPrice}
                        id="weekEndPrice"
                        name="weekEndPrice"
                        required
                        maxLength={9}
                        placeholder="Weekend Price"
                        onChange={(e) =>
                          handleChangeWeekEndPrice(
                            e.target.value,
                            element?.id,
                            "weekEndPrice"
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="1">
                    {options.length > 0 ? (
                      <>
                        <Button.Ripple
                          className="btn-icon rounded-circle mt-2 mr-1"
                          color="light"
                          type="button"
                          size="sm"
                          onClick={() => {
                            handleRemoveOptions(element?.id);
                          }}
                        >
                          <X size={18} />
                        </Button.Ripple>
                      </>
                    ) : null}
                  </Col>
                </Row>
                {/* //<hr key={index} style={{ border: "1px solid #ffe700" }} /> */}
              </div>
            );
          })}
          <div
            style={{
              opacity: visible ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
            }}
          >
            <Button.Ripple
              type="button"
              className="btn-icon rounded float-right mr-4 mt-2"
              color="light"
              size="md"
              onClick={handleAddOptions}
            >
              <Plus size={13} className="" />
              <span style={{ fontSize: "15px" }}> Add</span>
            </Button.Ripple>
          </div>
        </div>
        <hr style={{ border: "2px solid #ffe700", marginTop: "70px" }} />
        <div style={{ marginBottom: "20px" }}>
          <h4>Extras</h4>
          {extras?.map((ele, index) => {
            return (
              <div key={ele?.id}>
                <Row className="mt-2">
                  <Col sm="7">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="name">
                        Name
                      </Label>
                      <Input
                        type="text"
                        value={ele?.name}
                        id="name"
                        name="name"
                        placeholder="Add Name"
                        onChange={(e) =>
                          handleChangeExtraName(e.target.value, ele?.id, "name")
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="2">
                    <FormGroup className="w-10">
                      <Label className="form-label" for="weekDayPrice">
                        Week Day Price
                      </Label>
                      <Input
                        type="text"
                        value={ele?.weekDayPrice}
                        id="weekDayPrice"
                        name="weekDayPrice"
                        required
                        maxLength={9}
                        placeholder="Weekday Price"
                        onChange={(e) =>
                          handleChangeExtraWeekDayPrice(
                            e.target.value,
                            ele?.id,
                            "weekDayPrice"
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="2">
                    <FormGroup className="w-10">
                      <Label className="form-label" for="weekEndPrice">
                        Week End Price
                      </Label>
                      <Input
                        type="text"
                        value={ele?.weekEndPrice}
                        id="weekEndPrice"
                        name="weekEndPrice"
                        required
                        maxLength={9}
                        placeholder="Weekend Price"
                        onChange={(e) =>
                          handleChangeExtraWeekEndPrice(
                            e.target.value,
                            ele?.id,
                            "weekEndPrice"
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="1">
                    {extras.length > 0 ? (
                      <>
                        <Button.Ripple
                          className="btn-icon rounded-circle mt-2 mr-1"
                          color="light"
                          type="button"
                          size="sm"
                          onClick={() => {
                            handleRemoveExtras(ele?.id);
                          }}
                        >
                          <X size={18} />
                        </Button.Ripple>
                      </>
                    ) : null}
                  </Col>
                </Row>
                {/* <hr key={index} style={{ border: "1px solid #ffe700" }} /> */}
              </div>
            );
          })}
          <div
            style={{
              opacity: visible ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
            }}
          >
            <Button.Ripple
              type="button"
              className="btn-icon rounded float-right mr-4 mt-2"
              color="light"
              size="md"
              onClick={handleAddExtra}
            >
              <Plus size={13} className="" />
              <span style={{ fontSize: "15px" }}> Add</span>
            </Button.Ripple>
          </div>
        </div>
        <div style={{ marginTop: "90px" }}>
          <SaveDiscardBtnGroup className="mt-2" />
        </div>
      </form>
    </div>
  );
};

export default Options;
