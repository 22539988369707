import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  Button,
  Table,
  Badge,
} from "reactstrap";
import ReviewPaymentDetailsButton from "./Review-Payment-DetailsButton";
// import SaveDiscardBtnPurchase from "./save-discard-btn-group";
import { useGOUpdateGoPurchaseRequest } from "../../../../api/GOPurchaseRequest/Mutation";
import {
  useGetAllVendorDetails,
  useGetCapexVendors,
} from "../../../../api/OpsHostelVendor/Queries";
// import { useGetPaginatedGOPurchaseRequest } from "../../../../api/GOPurchaseRequest/Queries";
import { useParams } from "react-router";
import Select from "react-select";
import AttachmentComponent from "../AttachmentComponent";
import toaster from "@components/common/Toaster";
import { Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import confirmDelete from "@components/common/confirmDelete";
import { useHostelList } from "../../../../api/dataSource";
import {
  useGetGOPurchaseRequestById,
  useGetVendorLastOrder,
  useGetGOPurchaseSameTypeRequest,
} from "../../../../api/GOPurchaseRequest/Queries";
import { useHistory } from "react-router";
import { dateFormatHandler } from "../../../../utility/Utils";
import { pulse } from "./animation.css";

const Overview = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);

  //! AM Create purchase request useState
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [urgency, setUrgency] = useState(null);
  const [head, setHead] = useState(null);
  const [subHead, setSubHead] = useState(null);
  const [subHeadType, setSubHeadType] = useState(null);
  const [items, setItems] = useState([
    {
      id: uuidv4(),
      name: "",
      description: "",
      quantity: "",
      itemStatus: { label: "Accepted", value: "Accepted" },
      itemRemark: "",
    },
  ]);

  //! Purchase Team first purchase request useState
  // const [vendorName, setVendorName] = useState("");
  const [vendor, setVendor] = useState(null);
  const [vendorList, setVendorList] = useState([]);

  const [warranty, setWarranty] = useState("");
  const [deliveryTimeline, setDeliveryTimeline] = useState("");
  const [amount, setAmount] = useState("");
  const [gst, setGst] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [attachment, setAttachment] = useState([]);

  // const [vendorOptions, setVendorOptions] = useState([]);
  // const [selectedVendor, setSelectedVendor] = useState(null);
  // const [vendorRefetchTrigger, setVendorRefetchTrigger] = useState(false);

  //! Status purchase request useState
  const [status, setStatus] = useState(null);
  const [statusQA, setStatusQA] = useState({
    label: "Quotation Added",
    value: "Quotation Added",
  });

  const dbData = state?.tableData;
  const dbStatus = dbData?.status;

  //! AM remark purchase request useState
  const [amRemark, setAmRemark] = useState("");

  //! RM remark purchase request useState
  const [rmRemark, setRmRemark] = useState("");

  //! CEO remark purchase request useState
  const [ceoRemark, setCeoRemark] = useState("");

  //! Entire logs purchase request useState
  const [logs, setLogs] = useState(dbData?.logs ? dbData?.logs : []);

  //! Purchase Team second purchase request useState
  const [amountToPay, setAmountToPay] = useState("");

  //! Finance Team second purchase request useState
  const [paymentDate, setPaymentDate] = useState(null);
  const [bankRefNumber, setBankRefNumber] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [tdsAmount, setTdsAmount] = useState("");
  const [financeTeamRemark, setFinanceTeamRemark] = useState("");
  const [paymentAttachment, setPaymentAttachment] = useState([]);
  const [invoicePending, setInvoicePending] = useState(false);

  const [materialDispatchedRemark, setMaterialDispatchedRemark] = useState("");
  const [requestCompletedRemark, setRequestCompletedRemark] = useState("");
  const [purchaseRemark, setPurchaseRemark] = useState("");

  //! Attachment (Image) useState
  const [dummyState] = useState(null);

  //! Attachment (Payment Image) useState
  const [dummyStatePaymentAttachment] = useState(null);

  //! Validation message useState
  const [validationMessage, setValidationMessage] = useState("");

  const [materialDispatched, setMaterialDispatched] = useState(null);

  //for fetching hostel List
  const { data: allHostelsData } = useHostelList();

  const history = useHistory();

  const hostelId = dbData?.hostel?._id;
  const vendorState = dbData?.hostel?.state;

  // const { refetch } = useGetPaginatedGOPurchaseRequest();

  const uploadImageType = [{ label: "imageURL", value: "InvoiceImage" }];

  // const { data: vendorData, refetch: VendorRefetch } = useGetAllVendorDetails();

  const { data: vendorData } = useGetCapexVendors(hostelId, vendorState);

  const { data: vendorLastOrderData } = useGetVendorLastOrder(
    dbData?.requestId ? dbData?.requestId : null,
    dbData?.hostel?._id,
    dbData?.vendor?._id
  );

  const { data: sameTypeRequestData } = useGetGOPurchaseSameTypeRequest(
    dbData?.createdAt,
    dbData?.hostel?._id,
    dbData?.head,
    dbData?.subHead,
    dbData?.subHeadType
  );

  const vendorInfo = vendorLastOrderData?.getVendorLastOrder;

  const sameTypeRequestInfo = sameTypeRequestData?.getGOPurchaseSameTypeRequest;

  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
    // second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };

  const userName = localStorage.getItem("name");

  const userRole = localStorage.getItem("role");

  const userDepartment = localStorage.getItem("department");

  const userHostel = localStorage.getItem("hostels");

  const urgencyList = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const itemStatusList = [
    { label: "Accepted", value: "Accepted" },
    { label: "Rejected", value: "Rejected" },
  ];

  const statusListForAM = [
    { label: "AM Approved", value: "AM Approved" },
    { label: "AM Rejected", value: "AM Rejected" },
  ];

  const statusListForRM = [
    { label: "RM Approved", value: "RM Approved" },
    { label: "RM Rejected", value: "RM Rejected" },
  ];

  const statusListForCEO = [
    { label: "CEO Approved", value: "CEO Approved" },
    { label: "CEO Rejected", value: "CEO Rejected" },
  ];

  const statusListForFinance = [
    { label: "Payment Done", value: "Payment Done" },
    { label: "Payment Rejected", value: "Payment Rejected" },
  ];

  const finalAMStatusList = [
    {
      label: "Request Partially Completed",
      value: "Request Partially Completed",
    },
    { label: "Request Completed", value: "Request Completed" },
  ];

  const statusListForPurchase = [
    { label: "Quotation Added", value: "Quotation Added" },
    { label: "Purchase Rejected", value: "Purchase Rejected" },
  ];

  const statusMaterialDispatchedList = [
    { label: "Material Dispatched", value: "Material Dispatched" },
  ];

  const statusPartialRequestResolvedList = [
    { label: "Partial Request Resolved", value: "Partial Request Resolved" },
  ];

  const HeadList = [{ label: "CAPEX", value: "CAPEX" }];

  let SubHeadList;

  if (head?.value === "CAPEX") {
    SubHeadList = [
      { label: "Hostel", value: "Hostel" },
      { label: "F&B", value: "F&B" },
      { label: "Ancillary", value: "Ancillary" },
    ];
  }

  // let CapexSubHeadTypeList;
  // if (
  //   subHead?.value === "Hostel" ||
  //   subHead?.value === "F&B" ||
  //   subHead?.value === "Ancillary"
  // ) {
  //   CapexSubHeadTypeList = [
  //     { label: "Civil", value: "Civil" },
  //     { label: "Furniture", value: "Furniture" },
  //     { label: "Furnishing", value: "Furnishing" },
  //     { label: "Fabrication", value: "Fabrication" },
  //     { label: "Electrical", value: "Electrical" },
  //     { label: "Electronics/Equipments", value: "Electronics/Equipments" },
  //     { label: "Plumbing", value: "Plumbing" },
  //     { label: "Others", value: "Others" },
  //   ];
  // }

  const CapexSubHeadTypeList = [
    { label: "Civil", value: "Civil" },
    { label: "Furniture", value: "Furniture" },
    { label: "Furnishing", value: "Furnishing" },
    { label: "Fabrication", value: "Fabrication" },
    { label: "Electrical", value: "Electrical" },
    { label: "Electronics/Equipments", value: "Electronics/Equipments" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Others", value: "Others" },
  ];

  const options =
    // userRole === "Area Manager" ||
    userRole === "Ground Staff" &&
    (dbStatus === "Material Dispatched" ||
      dbStatus === "Partial Request Resolved")
      ? finalAMStatusList
      : userRole === "Area Manager"
      ? statusListForAM
      : userRole === "Regional Manager"
      ? statusListForRM
      : userRole === "CEO"
      ? statusListForCEO
      : userDepartment === "Finance"
      ? statusListForFinance
      : userDepartment === "Purchase" &&
        dbStatus === "Request Partially Completed"
      ? statusPartialRequestResolvedList
      : // : userDepartment === "Purchase" && dbStatus === "Pending"
      // ? statusListForPurchase
      userDepartment === "Purchase"
      ? statusMaterialDispatchedList
      : null;

  //update handler
  const [updateGoPurchaseHandler, { data: updateGoPurchaseData }] =
    useGOUpdateGoPurchaseRequest();

  // const { data: purchaseData, refetch } = useGetGOPurchaseRequestById(id);

  //refetching and toggle logic
  useEffect(() => {
    if (updateGoPurchaseData?.updateGOPurchaseRequest?.status === true) {
      // refetch();
      history.push("/operations/goPurchase");
    }
  }, [updateGoPurchaseData]);

  // useEffect(() => {
  //   if (purchaseData) {
  //     refetch();
  //   }
  // }, [purchaseData, refetch]);

  const handleAmountToPayChange = (e) => {
    const value = parseFloat(e.target.value);
    const parsedValue = parseFloat(value);

    if (value === "" || (!isNaN(parsedValue) && parsedValue >= 0)) {
      setAmountToPay(value);

      if (parsedValue > totalAmount) {
        setValidationMessage(
          "The amount to pay cannot be more than the total amount."
        );
      } else {
        setValidationMessage("");
      }
    }
  };

  const handleAmountToPayForFinance = (e) => {
    const value = e.target.value;
    const parsedValue = parseFloat(value);

    if (value === "" || (!isNaN(parsedValue) && parsedValue >= 0)) {
      setAmountPaid(value);

      if (parsedValue > amountToPay) {
        setValidationMessage(
          "The amount cannot be more than the amount to pay."
        );
      } else {
        setValidationMessage("");
      }
    }
  };

  // useEffect(() => {
  //   if (vendorData?.getAllVendorDetails?.data) {
  //     const filteredVendors = vendorData?.getAllVendorDetails?.data.filter(
  //       (vendor) => {
  //         if (
  //           vendor?.vendorCategory === "CAPEX + OPEX" ||
  //           vendor?.vendorCategory === "CAPEX"
  //         ) {
  //           return (
  //             vendor.hostel?._id === dbData?.hostel?._id ||
  //             vendor?.hostel === null
  //           );
  //         }
  //       }
  //     );

  //     const options = filteredVendors.map((vendor) => ({
  //       label: vendor?.vendorName,
  //       value: vendor?._id,
  //     }));

  //     // setVendorOptions(options);
  //     setVendorList(options);
  //   }
  // }, [vendorData, state]);

  // Effect to refetch vendor details when trigger state changes
  // useEffect(() => {
  //   if (vendorRefetchTrigger) {
  //     VendorRefetch();
  //     setVendorRefetchTrigger(false);
  //   }
  // }, [vendorRefetchTrigger, VendorRefetch]);

  useEffect(() => {
    const options = vendorData?.getCapexVendors?.map((vendor) => ({
      label: vendor?.vendorName,
      value: vendor?._id,
    }));

    // setVendorOptions(options);
    setVendorList(options);
  }, [vendorData]);

  const handleTDSAmount = (e) => {
    const value = e.target.value;
    const parsedValue = parseFloat(value);

    if (value === "" || (!isNaN(parsedValue) && parsedValue >= 0)) {
      setTdsAmount(value);
    }
  };

  useEffect(() => {
    const allowedHostels = JSON.parse(userHostel);
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  //setting edit data value
  useEffect(() => {
    if (dbData?._id) {
      setHostel(
        dbData?.hostel?.name
          ? {
              label: dbData?.hostel?.name,
              value: dbData?.hostel?._id,
            }
          : null
      );

      setUrgency(
        dbData?.urgency !== null
          ? {
              label: dbData?.urgency,
              value: dbData?.urgency,
            }
          : null
      );

      setHead(
        dbData?.head !== null
          ? {
              label: dbData?.head,
              value: dbData?.head,
            }
          : null
      );

      setSubHead(
        dbData?.subHead !== null
          ? {
              label: dbData?.subHead,
              value: dbData?.subHead,
            }
          : null
      );

      setSubHeadType(
        dbData?.subHeadType !== null
          ? {
              label: dbData?.subHeadType,
              value: dbData?.subHeadType,
            }
          : null
      );

      setItems(
        dbData?.items?.map((item) => {
          return {
            id: uuidv4(),
            name: item?.name,
            description: item?.description,
            quantity: item?.quantity,
            itemStatus:
              item?.itemStatus !== null
                ? { label: item?.itemStatus, value: item?.itemStatus }
                : { label: "Accepted", value: "Accepted" },
            itemRemark: item?.itemRemark,
          };
        })
      );

      // setSelectedVendor({
      //   label: dbData?.vendorName,
      //   value: dbData?.vendorName,
      // });
      setVendor(
        dbData?.vendor?._id
          ? {
              label: dbData?.vendor?.vendorName,
              value: dbData?.vendor?._id,
            }
          : null
      );
      // setVendorName(
      //   dbData?.vendorName !== null
      //     ? dbData?.vendorName
      //     : ""
      // );
      // const vendor = vendorData?.getAllVendorDetails?.data?.find(
      //   (vendor) => vendor.vendorName === dbData?.vendorName
      // );
      // setSelectedVendor(vendor || null);
      setWarranty(dbData?.warranty !== null ? dbData?.warranty : "");
      setDeliveryTimeline(
        dbData?.deliveryTimeline !== null ? dbData?.deliveryTimeline : ""
      );
      setAmount(dbData?.amount !== null ? dbData?.amount : "");
      setGst(dbData?.gst !== null ? dbData?.gst : "");
      setTotalAmount(dbData?.totalAmount !== null ? dbData?.totalAmount : "");
      setAttachment(dbData?.attachment !== null ? dbData?.attachment : []);

      setAmRemark(dbData?.amRemark !== null ? dbData?.amRemark : "");

      setRmRemark(dbData?.rmRemark !== null ? dbData?.rmRemark : "");

      setCeoRemark(dbData?.ceoRemark !== null ? dbData?.ceoRemark : "");

      setStatus(
        dbStatus !== null &&
          (dbStatus !== "Quotation Added" ||
            dbStatus !== "Purchase Rejected") && {
            label: dbStatus,
            value: dbStatus,
          }
      );

      setStatusQA(
        dbStatus === "Quotation Added" || dbStatus === "Purchase Rejected"
          ? {
              label: dbStatus,
              value: dbStatus,
            }
          : {
              label: "Quotation Added",
              value: "Quotation Added",
            }
      );
      setAmountToPay(dbData?.amountToPay);

      setPaymentDate(dbData?.paymentDate !== null ? dbData?.paymentDate : null);
      setBankRefNumber(dbData?.bankRefNumber);
      setAmountPaid(dbData?.amountPaid);
      setTdsAmount(dbData?.tdsAmount);
      setFinanceTeamRemark(dbData?.financeTeamRemark);
      setPaymentAttachment(
        dbData?.paymentAttachment !== null ? dbData?.paymentAttachment : []
      );
      setInvoicePending(
        dbData?.invoicePending ? dbData?.invoicePending : false
      );

      setMaterialDispatchedRemark(
        dbData?.materialDispatchedRemark !== null
          ? dbData?.materialDispatchedRemark
          : ""
      );

      setRequestCompletedRemark(
        dbData?.requestCompletedRemark !== null
          ? dbData?.requestCompletedRemark
          : ""
      );

      setPurchaseRemark(
        dbData?.purchaseRemark !== null ? dbData?.purchaseRemark : ""
      );
    } else {
      setHostel(null);
      setUrgency(null);
      setItems([
        {
          id: uuidv4(),
          name: "",
          description: "",
          quantity: "",
          itemStatus: { label: "Accepted", value: "Accepted" },
          itemRemark: "",
        },
      ]);
      setVendor(null);
      setWarranty("");
      setDeliveryTimeline("");
      setAmount("");
      setGst("");
      setTotalAmount("");
      setAttachment([]);
      setStatus(null);
      setStatusQA({ label: "Quotation Added", value: "Quotation Added" });
      setAmountToPay("");
      setPaymentDate(null);
      setBankRefNumber("");
      setAmountPaid("");
      setTdsAmount("");
      setFinanceTeamRemark("");
      setMaterialDispatchedRemark("");
      setRequestCompletedRemark("");
      setPaymentAttachment([]);
      setInvoicePending(false);
      setPurchaseRemark("");
    }
  }, [dbData]);

  const handleTextChangeName = (val, id, key) =>
    setItems(
      items.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDescription = (val, id, key) =>
    setItems(
      items.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeQuantity = (val, id, key) =>
    setItems(
      items.map((item) =>
        item.id === id ? { ...item, [key]: Number(val) } : item
      )
    );

  const handleChangeItemStatus = (val, id, key) =>
    setItems(
      items.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeRemark = (val, id, key) =>
    setItems(
      items.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleAddItems = () =>
    setItems([
      ...items,
      { id: uuidv4(), name: "", description: "", quantity: "" },
    ]);

  const handleRemoveItems = (id) => {
    // eslint-disable-next-line array-callback-return
    let newItemsData = items.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setItems(newItemsData));
  };

  useEffect(() => {
    let sum = 0;

    sum = Number(amount) + Number(gst);

    setTotalAmount(sum);
  }, [amount, gst]);

  const isSelectDisabled = () => {
    if (userRole === "Area Manager" && dbStatus === "Payment Done") {
      return dbStatus !== "Payment Done";
    } else if (
      // userRole === "Area Manager" ||
      userRole === "Ground Staff" &&
      dbStatus === "Material Dispatched"
    ) {
      return dbStatus !== "Material Dispatched";
    } else if (userRole === "Area Manager") {
      return dbStatus !== "Quotation Added";
    } else if (userRole === "Regional Manager") {
      return dbStatus !== "AM Approved";
    } else if (userRole === "CEO") {
      return dbStatus !== "RM Approved";
    } else if (userDepartment === "Finance") {
      return dbStatus !== "Payment Due";
    } else {
      return false;
    }
  };

  const purchaseVendorButton = () => {
    let itemsClone = JSON.parse(JSON.stringify(items));
    itemsClone.forEach((item) => {
      delete item.id;
    });

    const updatedItems = itemsClone.map((item) => ({
      ...item,
      itemStatus: item?.itemStatus?.value,
    }));

    // const hasValidItems = itemsClone.some(
    //   (item) => item.itemStatus === null && item.itemRemark === null
    // );

    // Validate Purchase
    if (userDepartment === "Purchase") {
      // if (dbStatus === "Pending") {
      if (
        statusQA.value === "Purchase Rejected" &&
        // !hasValidItems &&
        !purchaseRemark
      ) {
        toaster("error", "Please add purchase remark");
        return;
      } else if (statusQA.value === "Quotation Added") {
        if (
          // !hasValidItems ||
          !vendor ||
          !warranty ||
          !deliveryTimeline ||
          !amount ||
          !gst ||
          !totalAmount ||
          !attachment?.length ||
          !statusQA ||
          !purchaseRemark
        ) {
          toaster(
            "error",
            "Please fill all the required details marked with *"
          );
          return;
        }
      }
      // }

      if (
        userDepartment === "Purchase" &&
        (statusQA?.value === "Quotation Added" ||
          statusQA?.value === "Purchase Rejected")
      ) {
        const data = {
          hostel: hostel?.value,
          // urgency: urgency?.value,
          items: updatedItems,
          // vendorName: selectedVendor?.vendorName.trim(),
          // vendorName: selectedVendor?.trim(),
          head: head?.value,
          subHead: subHead?.value,
          subHeadType: subHeadType?.value,
          vendor: vendor?.value,
          warranty: Number(warranty),
          deliveryTimeline: Number(deliveryTimeline),
          amount: Number(amount),
          gst: Number(gst),
          totalAmount: Number(totalAmount),
          attachment,
          status: statusQA?.value,
          purchaseRemark,
        };

        updateGoPurchaseHandler(dbData?._id, {
          ...data,
          // status: status?.value ? status?.value : dbStatus,
          // materialDispatchedRemark,
          // ...dbData?.logs,
          logs: [
            ...dbData?.logs,
            `${userName} (${userDepartment}) has updated - status: ${
              statusQA?.value
            } ${
              statusQA?.value === "Quotation Added"
                ? `with total amount of ₹ ${data?.totalAmount}`
                : ""
            } and remark: ${purchaseRemark} on ${new Date().toLocaleString(
              "en-IN",
              {
                hour12: false,
              }
            )}`,
          ],
        });
        toaster("success", "Purchase Team added details successfully");
      }
    }
  };

  //submit handler for form
  const purchaseApprovalButton = () => {
    // const onSubmitHandler = (e) => {
    //   e.preventDefault();
    let itemsClone = JSON.parse(JSON.stringify(items));
    itemsClone.forEach((item) => {
      delete item.id;
    });

    // if (
    //   !data?.hostel ||
    //   !data?.urgency ||
    //   !data?.items ||
    //   !data?.items.length === 0
    // ) {
    //   toaster("error", "Please fill all the required details marked with '*'");
    //   return;
    // }

    // for (const item of data.items) {
    //   if (!item.name || !item.quantity) {
    //     toaster("error", "All items must have a name and quantity");
    //     return;
    //   }
    // }

    // Validate Area Manager
    if (
      userRole === "Area Manager" &&
      dbStatus &&
      (!status?.value || !amRemark)
    ) {
      toaster("error", "Please fill all the required details marked with *");
      return;
    }

    // Validate Regional Manager
    if (userRole === "Regional Manager" && (!status?.value || !rmRemark)) {
      toaster("error", "Please fill all the required details marked with *");
      return;
    }

    // Validate CEO
    if (userRole === "CEO" && (!status?.value || !ceoRemark)) {
      toaster("error", "Please fill all the required details marked with *");
      return;
    }

    // Validate Purchase
    // if (userDepartment === "Purchase") {
    //   // if (dbStatus === "Pending") {
    //   if (status.value === "Purchase Rejected" && !purchaseRemark) {
    //     toaster("error", "Please add purchase remark");
    //     return;
    //   } else if (status.value === "Quotation Added") {
    //     if (
    //       !vendor ||
    //       !warranty ||
    //       !deliveryTimeline ||
    //       !amount ||
    //       !gst ||
    //       !totalAmount ||
    //       !attachment?.length ||
    //       !statusQA ||
    //       !purchaseRemark
    //     ) {
    //       toaster(
    //         "error",
    //         "Please fill all the required details marked with *"
    //       );
    //       return;
    //     }
    //   }
    // }

    // if (dbStatus === "Purchase Approved") {
    //   if (
    //     !hostel ||
    //     !amountToPay ||
    //     !bankAccountNumber ||
    //     !ifscCode ||
    //     !bankType ||
    //     !poAttachment?.length ||
    //     !invoiceAttachment?.length
    //   ) {
    //     toaster(
    //       "error",
    //       "Please fill all the required details marked with *"
    //     );
    //     return;
    //   }
    // }

    // Validate Finance
    if (userDepartment === "Finance") {
      if (status?.value === "Payment Done") {
        if (
          !paymentAttachment.length ||
          !paymentDate ||
          !bankRefNumber ||
          amountPaid == null ||
          amountPaid < 0 ||
          tdsAmount == null ||
          tdsAmount < 0 ||
          invoicePending == null ||
          !financeTeamRemark
        ) {
          toaster(
            "error",
            "Please fill all the required details marked with *"
          );
          return;
        }
      } else if (status?.value === "Payment Rejected") {
        if (!status?.value || !financeTeamRemark) {
          toaster(
            "error",
            "Please fill all the required details marked with *"
          );
          return;
        }
      } else {
        if (!status?.value) {
          toaster(
            "error",
            "Please fill all the required details marked with *"
          );
          return;
        }
      }
    }

    // if (data?.hostel) {
    if (dbData?._id) {
      if (userRole === "Area Manager") {
        if (dbStatus === "Payment Done") {
          updateGoPurchaseHandler(dbData?._id, {
            // ...data,
            hostel: hostel?.value,
            status: status?.value,
            logs: [
              ...dbData?.logs,
              `${userName} (${userRole}) has updated - status: ${
                status?.value
              } on ${new Date().toLocaleString("en-IN", {
                hour12: false,
              })}`,
            ],
          });
          toaster("success", "Area Manager updated request completed");
        } else if (amRemark?.length > 0) {
          updateGoPurchaseHandler(dbData?._id, {
            // ...data,
            hostel: hostel?.value,
            status: status?.value,
            amRemark,
            logs: [
              ...dbData?.logs,
              `${userName} (${userRole}) has updated - status: ${
                status?.value
              } remark: ${amRemark} on ${new Date().toLocaleString("en-IN", {
                hour12: false,
              })}`,
            ],
          });
          toaster("success", "Area Manager remark added successfully");
        }
      }

      if (userRole === "Regional Manager") {
        if (rmRemark?.length > 0) {
          updateGoPurchaseHandler(dbData?._id, {
            // ...data,
            hostel: hostel?.value,
            status: status?.value,
            rmRemark,
            logs: [
              ...dbData?.logs,
              `${userName} (${userRole}) has updated - status: ${
                status?.value
              } remark: ${rmRemark} on ${new Date().toLocaleString("en-IN", {
                hour12: false,
              })}`,
            ],
          });
          toaster("success", "Regional Manager remark added successfully");
        }
      }

      if (userRole === "CEO") {
        if (ceoRemark?.length > 0) {
          updateGoPurchaseHandler(dbData?._id, {
            // ...data,
            hostel: hostel?.value,
            status: status?.value,
            ceoRemark,
            logs: [
              ...dbData?.logs,
              `${userName} (${userRole}) has updated - status: ${
                status?.value
              } remark: ${ceoRemark} on ${new Date().toLocaleString("en-IN", {
                hour12: false,
              })}`,
            ],
          });
          toaster("success", "CEO Remark added successfully");
        }
      }

      if (userDepartment === "Purchase") {
        if (dbStatus === "Request Partially Completed") {
          updateGoPurchaseHandler(dbData?._id, {
            // ...data,
            hostel: hostel?.value,
            status: status?.value,
            materialDispatchedRemark,
            logs: [
              ...dbData?.logs,
              `${userName} (${userDepartment}) has updated - status: ${
                status?.value
              } remark: ${materialDispatchedRemark} on ${new Date().toLocaleString(
                "en-IN",
                {
                  hour12: false,
                }
              )}`,
            ],
          });
          toaster("success", "Purchase added status successfully");
        } else if (status?.value === "Material Dispatched") {
          updateGoPurchaseHandler(dbData?._id, {
            status: status?.value ? status?.value : dbStatus,
            materialDispatchedRemark,
            // ...dbData?.logs,
            logs: [
              ...dbData?.logs,
              `${userName} (${userDepartment}) has updated - status: ${
                status?.value
              } remark: ${materialDispatchedRemark} on ${new Date().toLocaleString(
                "en-IN",
                {
                  hour12: false,
                }
              )}`,
            ],
          });
          toaster("success", "Purchase Team added details successfully");
        }
        // else if (
        //   (statusQA?.value === "Quotation Added" ||
        //     statusQA?.value === "Purchase Rejected")
        // ) {
        //   const data = {
        //     hostel: hostel?.value,
        //     // urgency: urgency?.value,
        //     // items: itemsClone,
        //     // vendorName: selectedVendor?.vendorName.trim(),
        //     // vendorName: selectedVendor?.trim(),
        //     head: head?.value,
        //     subHead: subHead?.value,
        //     subHeadType: subHeadType?.value,
        //     vendor: vendor?.value,
        //     warranty: Number(warranty),
        //     deliveryTimeline: Number(deliveryTimeline),
        //     amount: Number(amount),
        //     gst: Number(gst),
        //     totalAmount: Number(totalAmount),
        //     attachment,
        //     status: statusQA?.value,
        //     purchaseRemark,
        //   };

        //   updateGoPurchaseHandler(dbData?._id, {
        //     ...data,
        //     // status: status?.value ? status?.value : dbStatus,
        //     // materialDispatchedRemark,
        //     // ...dbData?.logs,
        //     logs: [
        //       ...dbData?.logs,
        //       `${userName} (${userDepartment}) has updated - status: ${status?.value} ${
        //         status?.value === "Quotation Added"
        //           ? `with total amount of ₹ ${data?.totalAmount}`
        //           : ""
        //       } and remark: ${purchaseRemark} on ${new Date().toLocaleString(
        //         "en-IN",
        //         {
        //           hour12: false,
        //         }
        //       )}`,
        //     ],
        //   });
        //   toaster("success", "Purchase Team added details successfully");
        // }
      }

      if (userRole === "Ground Staff") {
        if (
          dbStatus === "Material Dispatched" ||
          dbStatus === "Partial Request Resolved"
        ) {
          // dbStatus === "Request Partially Completed"))
          updateGoPurchaseHandler(dbData?._id, {
            // ...data,
            hostel: hostel?.value,
            status: status?.value,
            requestCompletedRemark,
            logs: [
              ...dbData?.logs,
              `${userName} (${userRole}) has updated - status: ${
                status?.value
              } remark: ${requestCompletedRemark} on  ${new Date().toLocaleString(
                "en-IN",
                {
                  hour12: false,
                }
              )}`,
            ],
          });
          toaster("success", "Ground Staff added status successfully");
        }
      }
    } else {
      toaster("error", "Please fill all the required details marked with '*'");
    }
  };

  return (
    <div>
      {/* <form className="mt-2" onSubmit={onSubmitHandler}> */}
      {
        /* (userRole === "Area Manager" ||
        userRole === "Regional Manager" ||
        userRole === "CEO" ||
        userDepartment === "Purchase" ||
        userDepartment === "Finance" ||
        dbStatus === "Request Completed") &&  */
        <Row>
          <Col sm="6">
            <Row>
              {/* //! Create GO Purchase Request */}
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Hostel*
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="hostel"
                    name="hostel"
                    options={hostelsList}
                    value={hostel}
                    isDisabled={dbData?._id}
                    onChange={(value) => setHostel(value)}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="urgency">
                    Urgency*
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="urgency"
                    name="urgency"
                    options={urgencyList}
                    value={urgency}
                    onChange={(value) => setUrgency(value)}
                    classNamePrefix="select"
                    isDisabled={dbData?._id}
                  />
                </FormGroup>
              </Col>

              <Col sm="4">
                <FormGroup className="w-100">
                  <Label className="form-label" for="head">
                    Request head*
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="head"
                    name="head"
                    options={HeadList}
                    value={head}
                    classNamePrefix="select"
                    onChange={(module) => {
                      setHead(module);
                    }}
                    isDisabled={
                      !(userDepartment === "Purchase" && dbStatus === "Pending")
                    }
                  />
                </FormGroup>
              </Col>

              <Col sm="4">
                <FormGroup className="w-100">
                  <Label className="form-label" for="subHead">
                    Request sub-head*
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="subHead"
                    name="subHead"
                    value={subHead}
                    options={SubHeadList}
                    required
                    onChange={(value) => setSubHead(value)}
                    classNamePrefix="select"
                    isDisabled={
                      !(userDepartment === "Purchase" && dbStatus === "Pending")
                    }
                  />
                </FormGroup>
              </Col>

              <Col sm="4">
                <FormGroup className="w-100">
                  <Label className="form-label" for="subHeadType">
                    CAPEX type*
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="subHeadType"
                    name="subHeadType"
                    value={subHeadType}
                    options={CapexSubHeadTypeList}
                    required
                    onChange={(value) => setSubHeadType(value)}
                    classNamePrefix="select"
                    isDisabled={
                      !(userDepartment === "Purchase" && dbStatus === "Pending")
                    }
                  />
                </FormGroup>
              </Col>
              <hr
                style={{
                  borderTop: "2px solid #ffe700",
                  width: "100%",
                }}
              />

              {items.map(
                (
                  { id, name, description, quantity, itemStatus, itemRemark },
                  index
                ) => (
                  <React.Fragment key={id}>
                    <Col sm="12">
                      <Row>
                        <Col sm="12">
                          <FormGroup className="w-100">
                            <Label className="form-label" for="index">
                              <strong> Item {index + 1}</strong>
                            </Label>
                          </FormGroup>
                        </Col>

                        <Col sm="6">
                          <FormGroup className="w-100">
                            <Label className="form-label" for="name">
                              Name*
                            </Label>

                            <Input
                              type="text"
                              value={name}
                              id="cancel"
                              name="cancel"
                              // placeholder={`Name`}
                              onChange={(e) =>
                                handleTextChangeName(e.target.value, id, "name")
                              }
                              disabled={dbData?._id}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="6">
                          <FormGroup className="w-100">
                            <Label className="form-label" for="quantity">
                              Quantity*
                            </Label>

                            <Input
                              type="number"
                              value={quantity}
                              id="quantity"
                              name="quantity"
                              //  placeholder="Quantity"
                              onChange={(e) =>
                                handleTextChangeQuantity(
                                  e.target.value,
                                  id,
                                  "quantity"
                                )
                              }
                              min="0"
                              onWheel={(e) => e.target.blur()}
                              disabled={dbData?._id}
                            />
                          </FormGroup>
                        </Col>

                        {description && (
                          <Col sm="12">
                            <FormGroup className="w-100">
                              <Label className="form-label" for="description">
                                Description
                              </Label>

                              <Input
                                type="textarea"
                                value={description}
                                id="description"
                                name="description"
                                // placeholder="Description"
                                rows="1"
                                onChange={(e) =>
                                  handleTextChangeDescription(
                                    e.target.value,
                                    id,
                                    "description"
                                  )
                                }
                                disabled={dbData?._id}
                              />
                            </FormGroup>
                          </Col>
                        )}

                        {((userRole === "Area Manager" &&
                          dbData?.vendor?._id) ||
                          (userRole === "Regional Manager" &&
                            dbStatus !== "AM Rejected") ||
                          (userRole === "CEO" && dbStatus !== "RM Rejected") ||
                          userDepartment === "Purchase" ||
                          userDepartment === "Finance" ||
                          dbStatus === "Request Partially Completed" ||
                          dbStatus === "Request Completed" ||
                          (userRole === "Ground Staff" &&
                            dbData?.vendor?.vendorName) ||
                          statusQA?.value === "Purchase Rejected") && (
                          <>
                            <Col sm="6">
                              <FormGroup className="w-100">
                                <Label className="form-label" for="itemStatus">
                                  Item Status*
                                </Label>

                                <Select
                                  isClearable={false}
                                  isSearchable={true}
                                  id="itemStatus"
                                  name="itemStatus"
                                  options={itemStatusList}
                                  // value={{
                                  //   label: itemStatus,
                                  //   value: itemStatus,
                                  // }}
                                  value={itemStatus}
                                  onChange={(value) =>
                                    handleChangeItemStatus(
                                      // value?.value,
                                      value,
                                      id,
                                      "itemStatus"
                                    )
                                  }
                                  classNamePrefix="select"
                                  isDisabled={dbStatus !== "Pending"}
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="6">
                              <FormGroup className="w-100">
                                <Label className="form-label" for="itemRemark">
                                  Item Remark
                                </Label>

                                <Input
                                  type="textarea"
                                  value={itemRemark}
                                  id="itemRemark"
                                  name="itemRemark"
                                  // placeholder="Description"
                                  rows="1"
                                  onChange={(e) =>
                                    handleTextChangeRemark(
                                      e.target.value,
                                      id,
                                      "itemRemark"
                                    )
                                  }
                                  disabled={dbStatus !== "Pending"}
                                />
                              </FormGroup>
                            </Col>
                          </>
                        )}
                        {/* <Col
                        sm="2"
                        md="1"
                        // style={{ marginTop: "10px" }}
                      >
                        {items.length > 1 ? (
                          <>
                            <Button.Ripple
                              className="btn-icon rounded-circle mb-1 mr-1"
                              color="light"
                              type="button"
                              size="sm"
                              onClick={() => handleRemoveItems(id)}
                              disabled={dbData?._id}
                            >
                              <Trash size={14} />
                            </Button.Ripple>
                          </>
                        ) : null}
                      </Col> */}
                        <hr
                          style={{
                            borderTop: "2px solid #ffe700",
                            width: "100%",
                          }}
                        />
                      </Row>
                    </Col>
                  </React.Fragment>
                )
              )}

              {/* //! - Update GO Purchase Request */}
              {((userRole === "Area Manager" && dbData?.vendor?._id) ||
                (userRole === "Regional Manager" &&
                  dbStatus !== "AM Rejected") ||
                (userRole === "CEO" && dbStatus !== "RM Rejected") ||
                userDepartment === "Purchase" ||
                userDepartment === "Finance" ||
                dbStatus === "Request Partially Completed" ||
                dbStatus === "Request Completed" ||
                (userRole === "Ground Staff" && dbData?.vendor?.vendorName) ||
                statusQA?.value === "Purchase Rejected") && (
                <>
                  <Col sm="12">
                    <FormGroup className="">
                      <Label className="form-label" for="statusQA">
                        Status*
                      </Label>
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="statusQA"
                        name="statusQA"
                        // options={options}
                        options={statusListForPurchase}
                        value={statusQA}
                        onChange={(value) => setStatusQA(value)}
                        classNamePrefix="select"
                        // isDisabled={dbStatus === "Request Completed"}
                        isDisabled={
                          userDepartment !== "Purchase" ||
                          dbData?.paymentDetails?.find(
                            (item) => item.paymentStatus === "Payment Done"
                          )
                          // || dbStatus !== "Pending" ||
                          // userRole === "Ground Staff"
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="purchaseRemark">
                        Purchase remark*
                      </Label>
                      <Input
                        type="textarea"
                        value={purchaseRemark}
                        // disabled={dbData?.purchaseRemark}
                        disabled={
                          userDepartment !== "Purchase" ||
                          dbData?.paymentDetails?.find(
                            (item) => item.paymentStatus === "Payment Done"
                          )
                        }
                        id="purchaseRemark"
                        name="purchaseRemark"
                        // placeholder="Remark"
                        onChange={(e) => setPurchaseRemark(e.target.value)}
                      />
                    </FormGroup>
                  </Col>

                  {statusQA?.value !== "Purchase Rejected" && (
                    <Col sm="12">
                      <FormGroup className="w-100">
                        <Label className="form-label" for="vendor">
                          Vendor name*
                        </Label>
                        {/* <Input
                          type="text"
                          value={vendorName}
                          id="vendorName"
                          name="vendorName"
                          placeholder="Add Vendor Name"
                          onChange={(e) => setVendorName(e.target.value)}
                          disabled={
                            dbStatus !== "Pending" ||
                            userRole === "Ground Staff"
                          }
                        /> */}
                        {/* <Select
                          id="vendorName"
                          name="vendorName"
                          options={vendorOptions}
                          value={
                            // vendorOptions.find(
                            //   (option) =>
                            //     option.value._id === selectedVendor?._id
                            // ) || null
                            selectedVendor
                          }
                          onChange={(selectedOption) => {
                            setSelectedVendor(selectedOption.value);
                          }}
                          isDisabled={
                            dbStatus !== "Pending" ||
                            userRole === "Ground Staff"
                          }
                          onFocus={() => setVendorRefetchTrigger(true)}
                        /> */}

                        <Select
                          id="vendor"
                          name="vendor"
                          isClearable={false}
                          isSearchable={true}
                          options={vendorList}
                          value={vendor}
                          onChange={(value) => {
                            setVendor(value);
                          }}
                          classNamePrefix="select"
                          isDisabled={
                            userDepartment !== "Purchase" ||
                            dbData?.paymentDetails?.find(
                              (item) => item.paymentStatus === "Payment Done"
                            )
                            // dbStatus !== "Pending" ||
                            // userRole === "Ground Staff"
                          }
                          // onFocus={() => setVendorRefetchTrigger(true)}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {/* <Col> */}
                  {/* <Row> */}
                  {statusQA?.value !== "Purchase Rejected" && (
                    <Col sm="6">
                      <FormGroup className="w-100">
                        <Label className="form-label" for="warranty">
                          Warranty (in months)*
                        </Label>
                        <Input
                          type="text"
                          value={warranty}
                          id="warranty"
                          name="warranty"
                          // placeholder="Add Warranty"
                          onChange={(e) => setWarranty(e.target.value)}
                          disabled={
                            userDepartment !== "Purchase" ||
                            dbData?.paymentDetails?.find(
                              (item) => item.paymentStatus === "Payment Done"
                            )
                            // dbStatus !== "Pending" ||
                            // userRole === "Ground Staff"
                          }
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {statusQA?.value !== "Purchase Rejected" && (
                    <Col sm="6">
                      <FormGroup className="w-100">
                        <Label className="form-label" for="deliveryTimeline">
                          Delivery timeline (in days)*
                        </Label>
                        <Input
                          type="text"
                          value={deliveryTimeline}
                          id="deliveryTimeline"
                          name="deliveryTimeline"
                          // placeholder="Add Delivery Timeline"
                          onChange={(e) => setDeliveryTimeline(e.target.value)}
                          disabled={
                            userDepartment !== "Purchase" ||
                            dbData?.paymentDetails?.find(
                              (item) => item.paymentStatus === "Payment Done"
                            )
                            // dbStatus !== "Pending" ||
                            // userRole === "Ground Staff"
                          }
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {statusQA?.value !== "Purchase Rejected" && (
                    <Col sm="3">
                      <FormGroup className="w-100">
                        <Label className="form-label" for="amount">
                          Amount*
                        </Label>
                        <Input
                          type="text"
                          value={amount}
                          id="amount"
                          name="amount"
                          //  placeholder="Add Amount"
                          // onChange={(e) => setAmount(e.target.value)}
                          onChange={(e) =>
                            e.target.value >= 0 && setAmount(e.target.value)
                          }
                          disabled={
                            userDepartment !== "Purchase" ||
                            dbData?.paymentDetails?.find(
                              (item) => item.paymentStatus === "Payment Done"
                            )
                            // dbStatus !== "Pending" ||
                            // userRole === "Ground Staff"
                          }
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {statusQA?.value !== "Purchase Rejected" && (
                    <Col sm="3">
                      <FormGroup className="w-100">
                        <Label className="form-label" for="gst">
                          GST amount*
                        </Label>
                        <Input
                          type="text"
                          value={gst}
                          id="gst"
                          name="gst"
                          //  placeholder="Add GST amount"
                          // onChange={(e) => setGst(e.target.value)}
                          onChange={(e) =>
                            e.target.value >= 0 && setGst(e.target.value)
                          }
                          disabled={
                            userDepartment !== "Purchase" ||
                            dbData?.paymentDetails?.find(
                              (item) => item.paymentStatus === "Payment Done"
                            )
                            // dbStatus !== "Pending" ||
                            // userRole === "Ground Staff"
                          }
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {/* </Row> */}
                  {/* </Col> */}

                  {statusQA?.value !== "Purchase Rejected" && (
                    <Col sm="6">
                      <FormGroup className="w-100">
                        <Label className="form-label" for="totalAmount">
                          Total amount*
                        </Label>
                        <Input
                          type="number"
                          value={totalAmount}
                          id="totalAmount"
                          name="totalAmount"
                          //  placeholder="Add total amount"
                          onChange={(e) => setTotalAmount(e.target.value)}
                          // disabled={dbStatus !== "Pending" }
                          disabled={dbData?._id}
                          min="0"
                          step="0.01"
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {statusQA?.value !== "Purchase Rejected" && (
                    <Col sm="12">
                      <Label className="form-label" for="attachment">
                        Proforma quotation/invoice*
                      </Label>
                      <AttachmentComponent
                        imageList={attachment}
                        setImageList={setAttachment}
                        editData={dummyState}
                        uploadType={uploadImageType}
                        userDepartment={userDepartment}
                      />
                    </Col>
                  )}

                  {/* {userDepartment === "Purchase" &&
                      dbStatus === "Pending" && (
                        <Col sm="6" className="mb-2">
                          <Button
                            style={{
                              backgroundColor: "#ffe700",
                              height: "40px",
                              marginTop: "25px",
                              marginLeft: "80px",
                            }}
                            color="white"
                            appearance="primary"
                            type="button"
                            onClick={() =>
                              window.open(
                                "https://www.admin.thehosteller.com/operations/vendor",
                                "_blank"
                              )
                            }
                          >
                            Add new vendor
                          </Button>
                        </Col>
                      )} */}

                  {
                    /* userDepartment === "Purchase" &&
                      dbStatus === "Pending"  */
                    userDepartment === "Purchase" && (
                      <Col sm="12" style={{ textAlign: "right" }}>
                        {/* <ReviewPaymentDetailsButton /> */}
                        <Button
                          style={{
                            backgroundColor: "#ffe700",
                            height: "40px",
                            marginTop: "22px",
                            borderRadius: "5px",
                            padding: "10px 40px",
                            outline: "none",
                            border: "0",
                          }}
                          color="white"
                          appearance="primary"
                          type="button"
                          onClick={purchaseVendorButton}
                          disabled={dbData?.paymentDetails?.find(
                            (item) => item.paymentStatus === "Payment Done"
                          )}
                        >
                          Save
                        </Button>
                      </Col>
                    )
                  }
                </>
              )}
              {/* <Col sm="12">
         <Label className="form-label" for="attachment">
           <strong>Upload Performa quotation/invoice</strong>
         </Label>
         <AttachmentComponent
           imageList={attachment}
           setImageList={setAttachment}
           dbData={dummyState}
           uploadType={uploadImageType}
           // userRole={userRole}
         />
       </Col> */}
            </Row>
          </Col>
          <Col sm="6">
            {" "}
            {/*Approval Select tag */}
            {((userRole === "Area Manager" && dbStatus === "Quotation Added") ||
              /* || dbStatus === "Material Dispatched" */
              (userRole === "Regional Manager" && dbStatus === "AM Approved") ||
              (userRole === "CEO" && dbStatus === "RM Approved") ||
              (userRole === "Ground Staff" &&
                (dbStatus === "Material Dispatched" ||
                  dbStatus === "Partial Request Resolved")) ||
              (userDepartment === "Purchase" &&
                dbStatus === "Request Partially Completed")) && (
              <Row>
                <Col sm="12">
                  <FormGroup className="">
                    <Label className="form-label" for="status">
                      Status*
                    </Label>
                    <Select
                      isClearable={false}
                      // isSearchable={true}
                      id="status"
                      name="status"
                      options={options}
                      onChange={(value) => setStatus(value)}
                      classNamePrefix="select"
                      // isDisabled={dbStatus === "Request Completed"}
                      isDisabled={isSelectDisabled()}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {/*//! Conditional rendering and remarks parts */}
            {userRole === "Area Manager" && dbStatus === "Quotation Added" && (
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="amRemark">
                      Remark*
                    </Label>
                    <Input
                      type="textarea"
                      value={amRemark}
                      // disabled={dbData?.amRemark}
                      id="amRemark"
                      name="amRemark"
                      // placeholder="Area manager remark"
                      onChange={(e) => setAmRemark(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {userRole === "Regional Manager" && dbStatus === "AM Approved" && (
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="rmRemark">
                      Remark*
                    </Label>
                    <Input
                      type="textarea"
                      value={rmRemark}
                      // disabled={dbData?.rmRemark}
                      id="rmRemark"
                      name="rmRemark"
                      //  placeholder="Regional manager remark"
                      onChange={(e) => setRmRemark(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {userRole === "CEO" && dbStatus === "RM Approved" && (
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="ceoRemark">
                      Remark*
                    </Label>
                    <Input
                      type="textarea"
                      value={ceoRemark}
                      // disabled={dbData?.ceoRemark}
                      id="ceoRemark"
                      name="ceoRemark"
                      // placeholder="CEO remark"
                      onChange={(e) => setCeoRemark(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {userDepartment === "Purchase" &&
              (dbStatus === "Payment Due" || dbStatus === "Payment Done") && (
                // || dbStatus === "Purchase Approved"
                <Row>
                  <Col sm="12">
                    <FormGroup className="">
                      <Label className="form-label" for="status">
                        Status*
                      </Label>
                      <Select
                        isClearable={false}
                        // isSearchable={true}
                        id="status"
                        name="status"
                        options={options}
                        onChange={(value) => setStatus(value)}
                        classNamePrefix="select"
                        // isDisabled={dbStatus === "Request Completed"}
                        isDisabled={isSelectDisabled()}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Label
                        className="form-label"
                        for="materialDispatchedRemark"
                      >
                        Remark*
                      </Label>
                      <Input
                        type="textarea"
                        value={materialDispatchedRemark}
                        // disabled={dbData?.materialDispatchedRemark}
                        id="materialDispatchedRemark"
                        name="materialDispatchedRemark"
                        // placeholder="Material dispatched remark"
                        onChange={(e) =>
                          setMaterialDispatchedRemark(e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            {userDepartment === "Purchase" &&
              dbStatus === "Request Partially Completed" && (
                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Label
                        className="form-label"
                        for="materialDispatchedRemark"
                      >
                        Remark*
                      </Label>
                      <Input
                        type="textarea"
                        value={materialDispatchedRemark}
                        // disabled={dbData?.materialDispatchedRemark}
                        id="materialDispatchedRemark"
                        name="materialDispatchedRemark"
                        //  placeholder="Material dispatched remark"
                        onChange={(e) =>
                          setMaterialDispatchedRemark(e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            {userRole === "Ground Staff" &&
              (dbStatus === "Material Dispatched" ||
                /* dbStatus === "Request Partially Completed") && ( */
                dbStatus === "Partial Request Resolved") && (
                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Label
                        className="form-label"
                        for="requestCompletedRemark"
                      >
                        Remark*
                      </Label>
                      <Input
                        type="textarea"
                        value={requestCompletedRemark}
                        // disabled={dbData?.requestCompletedRemark}
                        id="requestCompletedRemark"
                        name="requestCompletedRemark"
                        //  placeholder="Remark"
                        onChange={(e) =>
                          setRequestCompletedRemark(e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            {vendorInfo?.requestId && (
              <b>
                <Badge color={"light-info"} className={"pulse"}>
                  {/* {`Order placed with same vendor on Date: ${new Intl.DateTimeFormat(
                    "en-IN",
                    dateOptions
                  ).format(new Date(vendorInfo?.createdAt))}, RequestID: ${
                    vendorInfo.requestId
                  }`} */}
                  {`Order placed with same vendor on Date: ${dateFormatHandler(
                    vendorInfo?.createdAt
                  )}, RequestID: ${vendorInfo.requestId}`}
                </Badge>
              </b>
            )}
            <br />
            <br />
            {sameTypeRequestInfo?.length > 0 && (
              <b>
                <Badge color={"light-warning"} className="pulse">
                  {`Same type of request placed on ${dateFormatHandler(
                    sameTypeRequestInfo[0]?.createdAt
                  )} with Request ID: ${sameTypeRequestInfo[0]?.requestId}`}
                </Badge>
              </b>
            )}
            <Row>
              {
                /* (userRole === "Area Manager" ||
                userRole === "Regional Manager" ||
                userRole === "CEO" ||
                (userDepartment === "Purchase" &&
                  dbStatus === "Payment Due") */

                ((userRole === "Area Manager" &&
                  dbStatus === "Quotation Added") ||
                  /* ||
                    dbStatus === "Material Dispatched" */
                  (userRole === "Regional Manager" &&
                    dbStatus === "AM Approved") ||
                  (userRole === "CEO" && dbStatus === "RM Approved") ||
                  (userDepartment === "Purchase" &&
                    (dbStatus === "Payment Due" ||
                      dbStatus === "Payment Done")) ||
                  // || dbStatus === "Purchase Approved"
                  (userRole === "Ground Staff" &&
                    (dbStatus === "Material Dispatched" ||
                      dbStatus === "Partial Request Resolved")) ||
                  (userDepartment === "Purchase" &&
                    dbStatus === "Request Partially Completed")) && (
                  <Col sm="12" style={{ textAlign: "right" }}>
                    <Button
                      style={{
                        backgroundColor: "#ffe700",
                        height: "40px",
                        marginTop: "22px",
                        borderRadius: "5px",
                        padding: "10px 40px",
                        outline: "none",
                        border: "0",
                      }}
                      color="white"
                      appearance="primary"
                      type="button"
                      onClick={purchaseApprovalButton}
                    >
                      Save
                    </Button>
                  </Col>
                )
              }
            </Row>
            {/* //! Designated Status */}
            <Row>
              <Col sm="12">
                <Table bordered>
                  <tbody>
                    {dbStatus && (
                      <tr>
                        <td>
                          <Label className="form-label" for="status">
                            <strong> Current status: </strong>{" "}
                          </Label>
                        </td>
                        <td> {dbStatus}</td>
                      </tr>
                    )}

                    {/* {dbData?.amRemark && (
                      <tr>
                        <td>
                          <Label className="form-label" for="amRemark">
                            <strong>Area manager remark:</strong>
                          </Label>{" "}
                        </td>
                        <td>{dbData?.amRemark}</td>
                      </tr>
                    )}
                    {dbData?.rmRemark && (
                      <tr>
                        <td>
                          <Label className="form-label" for="rmRemark">
                            <strong>Regional manager remark:</strong>{" "}
                          </Label>
                        </td>
                        <td> {dbData?.rmRemark}</td>
                      </tr>
                    )}
                    {dbData?.ceoRemark && (
                      <tr>
                        <td>
                          <Label className="form-label" for="ceoRemark">
                            <strong> CEO remark:</strong>{" "}
                          </Label>
                        </td>
                        <td>{dbData?.ceoRemark}</td>
                      </tr>
                    )}
                    {dbData?.financeTeamRemark && (
                      <tr>
                        <td>
                          {" "}
                          <Label className="form-label" for="financeTeamRemark">
                            <strong> Finance remark:</strong>{" "}
                          </Label>
                        </td>
                        <td> {dbData?.financeTeamRemark}</td>
                      </tr>
                    )}
                    {dbData?.deductionRemark && (
                      <tr>
                        <td>
                          {" "}
                          <Label className="form-label" for="deductionRemark">
                            <strong> Deduction remark:</strong>{" "}
                          </Label>
                        </td>
                        <td> {dbData?.deductionRemark}</td>
                      </tr>
                    )}
                    {dbData?.materialDispatchedRemark && (
                      <tr>
                        <td>
                          {" "}
                          <Label
                            className="form-label"
                            for="materialDispatchedRemark"
                          >
                            <strong> Material dispatched remark:</strong>{" "}
                          </Label>
                        </td>
                        <td> {dbData?.materialDispatchedRemark}</td>
                      </tr>
                    )}
                    {dbData?.requestCompletedRemark && (
                      <tr>
                        <td>
                          {" "}
                          <Label
                            className="form-label"
                            for="requestCompletedRemark"
                          >
                            <strong> Remark:</strong>{" "}
                          </Label>
                        </td>
                        <td> {dbData?.requestCompletedRemark}</td>
                      </tr>
                    )} */}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="12">
                {/* <hr/> */}
                <FormGroup className="w-100 mt-2">
                  {dbData?._id && (
                    <Label className="form-label" for="logs">
                      <strong>Logs:</strong>
                    </Label>
                  )}
                  <br />
                  {dbData?.logs
                    ?.map((log, i) => {
                      return (
                        <Label className="form-label" for="name" key={i}>
                          <li key={i}>{log}.</li>
                        </Label>
                      );
                    })
                    .reverse()}
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      }
      {/* 
      {(userRole === "Area Manager" ||
        userRole === "Regional Manager" ||
        userRole === "CEO" ||
        userDepartment === "Purchase" ||
        userDepartment === "Finance") && (
        <ReviewPaymentDetailsButton
          addAction={handleAddItems}
          // userRole={dbStatus === "Request Completed"}
          userRole={dbData?._id}
        />
      )} */}

      {/* {userRole === "Area Manager" &&
        dbStatus === "Material Dispatched" && (
          <Row>
            <Col sm="12">
              <FormGroup className="w-100 mb-1 pb-2 mt-1">
                <Label className="form-label" for="status">
                  Status*
                </Label>
                <Select
                  isClearable={false}
                  // isSearchable={true}
                  id="status"
                  name="status"
                  options={options}
                  onChange={(value) => setStatus(value)}
                  classNamePrefix="select"
                  // isDisabled={dbStatus === "Request Completed"}
                  isDisabled={isSelectDisabled()}
                />
              </FormGroup>
            </Col>
          </Row>
        )} */}

      {/* <SaveDiscardBtnPurchase /> */}
      {/* </form>  */}
    </div>
  );
};

export default Overview;
