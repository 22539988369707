import { gql } from "@apollo/client";

export const GET_ALL_WALL_OF_LOVE_IMAGES = gql`
query {
    getAllImages{
        ImageUrl
        _id
        ImageName
        status
        DisplayOrder
    }
}
`