import { Grid, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Merchandise Store") ? [

    {
        id: "merchandiseStore",
        title: "Merchandise Store",
        icon: <Grid size={20} />,
        badge: "light-warning",
        children: [
            {
                id: "MerchandiseProduces",
                title: "Products",
                icon: <Circle size={12} />,
                badge: "light-warning",
                navLink: "/merchandise/products",
            },
            {
                id: "MerchandiseOrders",
                title: "Orders",
                icon: <Circle size={12} />,
                badge: "light-warning",
                navLink: "/merchandise/orders",
            },
        ]
    }
] : [];