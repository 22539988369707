import React, { useState, useEffect } from "react";
import SlideModal from "../../../@core/components/common/slide-modal/index";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import {
  useUploadImage,
  useCreateStepOutProduct,
  useUpdateStepOutProduct,
  useGetAllStepOutCategoryList,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import SwitchIcon from "@components/common/switch-icon";
import Select from "react-select";
import { XCircle } from "react-feather";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [imageLandscape, setImageLandscape] = useState(null);
  const [imagePortrait, setImagePortrait] = useState(null);
  const [basePrice, setBasePrice] = useState("");
  const [tax, setTax] = useState(null);
  const [duration, setDuration] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [url, setUrl] = useState("/products/");
  const [organizer, setOrganizer] = useState("");
  const [dateRanges, setDateRanges] = useState([]);
  const [status, setStatus] = useState(false);
  const [
    uploadLandscapeImageHandler,
    {
      data: landscapeImageData,
      reset: landscapeImageReset,
      loading: landscapeImageLoad,
    },
  ] = useUploadImage();

  const [
    uploadPortraitImageHandler,
    {
      data: portraitImageData,
      reset: portraitImageReset,
      loading: portraitImageLoad,
    },
  ] = useUploadImage();

  const [createStepOutProductHandler, { data: createdStepOutProductData }] =
    useCreateStepOutProduct();
  const [updateStepOutProductHandler, { data: updatedStepOutProductData }] =
    useUpdateStepOutProduct();

  const { data: allCategoryList } = useGetAllStepOutCategoryList();

  useEffect(() => {
    const catList = allCategoryList?.getAllStepOutCategory?.map((item) => {
      return { label: item?.name, value: item?._id };
    });
    setCategoryList(catList);
  }, [allCategoryList]);

  useEffect(() => {
    if (createdStepOutProductData || updatedStepOutProductData) {
      refetch();
      handleToggle();
      toaster("success", "Product added successfully");
    }
  }, [createdStepOutProductData, updatedStepOutProductData]);

  const uppyLandscapeImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  const uppyPortraitImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppyLandscapeImage.use(thumbnailGenerator);
  uppyPortraitImage.use(thumbnailGenerator);

  uppyLandscapeImage.on("thumbnail:generated", (file, preview) => {
    setImageLandscape([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyLandscapeImage.removeFile(file.id);
      return;
    }

    uploadLandscapeImageHandler(file.data, "images/StepOutProduct");
    toaster("success", "Landscape image uploaded successfully");
  });

  uppyLandscapeImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading landscape image: ${error.message}`);
  });

  uppyPortraitImage.on("thumbnail:generated", (file, preview) => {
    setImagePortrait([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyPortraitImage.removeFile(file.id);
      return;
    }

    uploadPortraitImageHandler(file.data, "images/StepOutProduct");
    toaster("success", "Portrait image uploaded successfully");
  });

  uppyPortraitImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading portrait image: ${error.message}`);
  });

  const renderLandscapeImagePreview = () =>
    imageLandscape ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={imageLandscape}
        alt="avatar"
      />
    ) : null;

  useEffect(() => {
    setImageLandscape(landscapeImageData?.uploadImage?.url);
  }, [landscapeImageData]);

  const renderPortraitImagePreview = () =>
    imagePortrait ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={imagePortrait}
        alt="avatar"
      />
    ) : null;

  useEffect(() => {
    setImagePortrait(portraitImageData?.uploadImage?.url);
  }, [portraitImageData]);

  const handleStartDateChange = (index, event) => {
    const updatedRanges = [...dateRanges];
    const startDate = event.target.value ? new Date(event.target.value) : null;
    updatedRanges[index] = startDate || "";
    setDateRanges(updatedRanges);
  };

  const addDateRange = () => {
    const newRange = {};
    setDateRanges([...dateRanges, ""]);
  };

  const removeDateRange = (index) => {
    const updatedRanges = [...dateRanges];
    updatedRanges.splice(index, 1);
    setDateRanges(updatedRanges);
  };

  const TaxList = [
    { label: "0", value: "0" },
    { label: "5", value: "5" },
    { label: "12", value: "12" },
    { label: "18", value: "18" },
    { label: "28", value: "28" },
  ];

  useEffect(() => {
    if (editData?._id) {
      setTitle(editData?.title !== null ? editData?.title : "");
      setLocation(editData?.location !== null ? editData?.location : "");
      setCategory(
        editData?.category !== null
          ? { label: editData?.category?.name, value: editData?.category?._id }
          : null
      );
      setImageLandscape(
        editData?.imageLandscape !== null ? editData?.imageLandscape : null
      );
      setImagePortrait(
        editData?.imagePortrait !== null ? editData?.imagePortrait : null
      );

      setBasePrice(editData?.basePrice !== null ? editData?.basePrice : "");
      setTax(
        editData?.tax !== null
          ? { label: editData?.tax, value: editData?.tax }
          : null
      );
      setDuration(editData?.duration !== null ? editData?.duration : "");

      setMetaTitle(
        editData?.seo?.metaTitle !== null ? editData?.seo?.metaTitle : ""
      );
      setMetaDesc(
        editData?.seo?.metaDesc !== null ? editData?.seo?.metaDesc : ""
      );
      setKeywords(
        editData?.seo?.keywords !== null ? editData?.seo?.keywords : ""
      );
      setUrl(editData?.url !== null ? editData?.url : "/products/");
      setOrganizer(editData?.organizer !== null ? editData?.organizer : "");
      if (editData?.startDate?.length > 0) {
        setDateRanges(
          editData?.startDate?.map((date) => {
            return new Date(date);
          })
        );
      } else {
        setDateRanges([]);
      }
      setStatus(editData?.status !== null ? editData?.status : false);
    } else {
      setTitle("");
      setLocation("");
      setCategory(null);
      setImageLandscape(null);
      setImagePortrait(null);
      setBasePrice("");
      setTax(null);
      setDuration("");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setUrl("/products/");
      setOrganizer("");
      setDateRanges([]);
      setStatus(false);
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const data = {
      title,
      location,
      category: category?.value,
      imageLandscape,
      imagePortrait,
      basePrice: parseInt(basePrice),
      tax: parseInt(tax?.value),
      duration: parseInt(duration),
      seo: {
        metaTitle,
        metaDesc,
        keywords,
      },
      url,
      organizer,
      startDate: dateRanges?.filter((date) => {
        return new Date(date);
      }),
      status,
    };
    if (data.url && data.title && data.url.includes("/products/")) {
      if (editData?._id) {
        updateStepOutProductHandler(editData?._id, data);
      } else {
        createStepOutProductHandler(data);
      }
      setTitle("");
      setLocation("");
      setCategory(null);
      setImageLandscape(null);
      setImagePortrait(null);
      setBasePrice("");
      setTax(null);
      setDuration("");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setUrl("/products/");
      setOrganizer("");
      setDateRanges([]);
      setStatus(false);
    } else if (data.title && !data.url?.includes("/products/")) {
      toaster("error", "Products Url must contain /products/ as prefix");
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="title">
                  Title
                </Label>
              </strong>
              <Input
                type="text"
                value={title}
                id="title"
                name="title"
                placeholder="Add title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="location">
                  Location
                </Label>
              </strong>
              <Input
                type="text"
                value={location}
                id="location"
                name="location"
                placeholder="Add location"
                onChange={(e) => setLocation(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="category">
                <strong>Category</strong>
              </Label>
              <Select
                isSearchable={true}
                id="category"
                name="category"
                value={category}
                options={categoryList}
                onChange={(value) => {
                  setCategory(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="basePrice">
                  Base price
                </Label>
              </strong>
              <Input
                type="text"
                value={basePrice}
                id="basePrice"
                name="basePrice"
                placeholder="Add basePrice"
                onChange={(e) => setBasePrice(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="tax">
                  Tax (%)
                </Label>
              </strong>
              <Select
                isSearchable={true}
                id="tax"
                name="tax"
                value={tax}
                options={TaxList}
                onChange={(value) => {
                  setTax(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* <Col sm="12">
            <FormGroup className="w-100">
              <div>
                <strong>
                  <Label style={{ marginRight: "10px" }}>Start date</Label>
                </strong>
                {dateRanges?.map((range, index) => (
                  <>
                    <div key={index}>
                      <label style={{ marginRight: "10px" }}>{index + 1}</label>
                      <input
                        type="date"
                        style={{ marginRight: "10px" }}
                        value={range ? range.toISOString().substr(0, 10) : ""}
                        onChange={(event) =>
                          handleStartDateChange(index, event)
                        }
                      />
                      <a href="#" onClick={() => removeDateRange(index)}>
                        <XCircle />
                      </a>
                    </div>
                    <br />
                  </>
                ))}
                <Button color="light" onClick={addDateRange}>
                  Add Start Date
                </Button>
              </div>
            </FormGroup>
          </Col> */}

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="duration">
                  Duration (Nights)
                </Label>
              </strong>
              <Input
                type="text"
                value={duration}
                id="duration"
                name="duration"
                placeholder="Add duration"
                onChange={(e) => setDuration(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="url">
                  Url (format:- /products/productName)*
                </Label>
              </strong>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add url"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <strong>
              <Label className="form-label" for="metaTitle">
                Seo
              </Label>
            </strong>
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="metaTitle">
                  Meta title
                </Label>

                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add metaTitle"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="metaDesc">
                  Meta desc
                </Label>

                <Input
                  type="text"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add metaDesc"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="keywords">
                  Keywords
                </Label>

                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="organizer">
                  Organizer
                </Label>
              </strong>
              <Input
                type="text"
                value={organizer}
                id="organizer"
                name="organizer"
                placeholder="Add organizer"
                onChange={(e) => setOrganizer(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <Row>
              <Col sm="6">
                <p>Upload Landscape Image (574px X 268px)</p>
                <DragDrop
                  note="Image size should be less than 500KB"
                  width="100%"
                  uppy={uppyLandscapeImage}
                  locale={{
                    strings: {
                      dropHereOr: "Drop image here or %{browse}",
                      browse: "browse",
                    },
                  }}
                />
                <Col sm="13">
                  <Label className="form-label w-100">
                    Landscape Image preview here
                  </Label>
                  {landscapeImageLoad
                    ? "Uploading image..."
                    : renderLandscapeImagePreview()}
                </Col>
              </Col>

              <Col sm="6">
                <p>Upload Portrait Image</p>
                <DragDrop
                  note="News image size should be less than 500KB"
                  width="100%"
                  uppy={uppyPortraitImage}
                  locale={{
                    strings: {
                      dropHereOr: "Drop news image here or %{browse}",
                      browse: "browse",
                    },
                  }}
                />
                <Col sm="13">
                  <Label className="form-label w-100">
                    Portrait image preview here
                  </Label>
                  {portraitImageLoad
                    ? "Uploading image..."
                    : renderPortraitImagePreview()}
                </Col>
              </Col>
            </Row>
          </Col>

          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Product status?"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
