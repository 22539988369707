import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";

export const CREATE_HOSTEL_VENDOR = gql`
  mutation createHostelVendor($hostelVendorInput: HostelVendorInput) {
    createHostelVendor(hostelVendorInput: $hostelVendorInput) {
      vendorName
    }
  }
`;

export const useCreateOpsHostelVendor = () => {
  const [CreateOpsFinance, { loading, error, data, refetch }] =
    useMutation(CREATE_HOSTEL_VENDOR);

  useLoadingHandler(loading);

  const createOpsHostelVendorHandler = (hostelVendorInput) => {
    CreateOpsFinance({
      variables: { hostelVendorInput },
    });
  };

  return [createOpsHostelVendorHandler, { loading, error, data, refetch }];
};

export const UPDATE_HOSTEL_VENDOR = gql`
  mutation updateHostelVendor($id: ID, $hostelVendorUpdate: HostelVendorInput) {
    updateHostelVendor(id: $id, hostelVendorUpdate: $hostelVendorUpdate) {
      vendorName
    }
  }
`;

export const useUpdateOpsHostelVendor = () => {
  const [updateOpsFinance, { loading, error, data, reset }] =
    useMutation(UPDATE_HOSTEL_VENDOR);

  useLoadingHandler(loading);

  const updateOpsHostelVendorHandler = (id, hostelVendorUpdate) => {
    updateOpsFinance({
      variables: { id, hostelVendorUpdate },
    });
  };

  return [updateOpsHostelVendorHandler, { loading, error, data, reset }];
};
