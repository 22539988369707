import React, { useEffect, useState } from "react";
import { Card, CardHeader, Table } from "reactstrap";

const Report = ({ itemwiseData, fromDate, toDate, menuActiveData }) => {
  const [filteredMenu, setFilteredMenu] = useState(null);

  useEffect(() => {
    if (menuActiveData?.getCafewiseMenuActiveForItemwise?.length > 0) {
      const filterArray =
        menuActiveData?.getCafewiseMenuActiveForItemwise?.filter((itemData) => {
          const isItemPresent = itemwiseData?.some(
            (i) => i?._id === itemData?.result[0]?.itemName
          );
          return !isItemPresent;
        });
      setFilteredMenu(filterArray);
    }
  }, [itemwiseData, menuActiveData]);
  return (
    <div style={{ overflowX: "auto", overflowY: "auto", marginTop: "20px" }}>
      <Card>
        <CardHeader>
          <h4 style={{}}>Itemwise Sales Report</h4>
        </CardHeader>
        <div style={{ overflowX: "auto", maxHeight: "500px" }}>
          <Table bordered>
            <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
              {/* <tr>
                <th style={{ position: "sticky", left: 0, zIndex: 2 }}>
                  CafeName
                </th>
                {itemwiseData?.map((cafe) => {
                  return (
                    <th
                      colSpan="2"
                      style={{
                        textAlign: "center",
                        minWidth: "150px",
                        position: "sticky",
                        zIndex: 1,
                      }}
                    >
                      {cafe?.cafeInfo[0]?.cafeName}
                    </th>
                  );
                })}
              </tr> */}
              <tr>
                <th style={{ position: "sticky", left: 0, zIndex: 1 }}>
                  Items
                </th>
                {/* {itemwiseData?.map((cafe) => {
                  return (
                    <> */}
                <th>Quantiy Sold</th>
                <th>Total Amount</th>
                {/* <th>Net Sales</th>
                      <th>Total Tax</th> */}
                {/* </> */}
                {/* );
                })} */}
              </tr>
            </thead>
            <tbody>
              {/* {menuList?.getAllMasterMenu?.map((menu) => {
                const findMenuPresentOnEachItemwiseData = itemwiseData?.map(
                  (itemData) => {
                    return (
                      itemData?.itemData?.find(
                        (item) => item?.itemName === menu?.itemName
                      ) || null
                    );
                  }
                );

                return (
                  <tr>
                    <td
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backdropFilter: "blur(400px)",
                      }}
                    >
                      {menu?.itemName}
                    </td>
                    {findMenuPresentOnEachItemwiseData?.map((findData) => {
                      return (
                        <>
                          <td>{findData ? findData?.quantitySold : "--"}</td>
                          <td>
                            {findData ? findData?.totalAmount.toFixed(2) : "--"}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              })} */}
              {/* {menuList?.getAllMasterMenu?.map((menu) => {
                const findMenuPresentOnEachItemwiseData =
                  itemwiseData?.find(
                    (itemData) => itemData?._id === menu?.itemName
                  ) || null;
                return (
                  <tr>
                    <td
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backdropFilter: "blur(400px)",
                      }}
                    >
                      {menu?.itemName}
                    </td>
                    <td>
                      {findMenuPresentOnEachItemwiseData
                        ? findMenuPresentOnEachItemwiseData?.quantitySold
                        : "--"}
                    </td>
                    <td>
                      {findMenuPresentOnEachItemwiseData
                        ? findMenuPresentOnEachItemwiseData?.totalAmount.toFixed(
                            2
                          )
                        : "--"}
                    </td>
                  </tr>
                );
              })} */}
              {itemwiseData?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item?._id}</td>
                    <td>{item?.quantitySold || "--"}</td>
                    <td>{item?.totalAmount.toFixed(2) || "--"}</td>
                  </tr>
                );
              })}
              {filteredMenu?.map((item, j) => {
                return (
                  <tr key={j}>
                    <td>{item?.result[0]?.itemName}</td>
                    <td>{"--"}</td>
                    <td>{"--"}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Card>
    </div>
  );
};

export default Report;
