import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { DragDrop } from "@uppy/react";
import { useUploadImage } from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import { v4 as uuidv4 } from "uuid";
import confirmDelete from "@components/common/confirmDelete";
import { Plus, X, Camera } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useUpdateMerchandiseProduct } from "../../../api/MerchandiseStore/Mutation";
import { tableDataHandler } from "@store/Cms/cms.action";

const Items = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(true);
  const state = useSelector((state) => state?.cms);
  const [index, setIndex] = useState("");

  const [itemList, setItemsList] = useState([
    { id: uuidv4(), name: "", quantity: "", image: "", description: "" },
  ]);

  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();

  const [updataMerchandiseProductHandler, { data: updatedData }] =
    useUpdateMerchandiseProduct();
  const onSubmitHandler = (e) => {
    e.preventDefault();
    let datalist = itemList.map((item) => {
      return {
        name: item?.name,
        // description: item?.description,
        quantity: Number(item?.quantity),
        displayOrder: Number(item?.displayOrder),
        image: item?.image,
      };
    });

    const data = {
      items: datalist,
    };
    if (id && itemList[0]?.image && itemList[0]?.name && itemList[0].quantity) {
      updataMerchandiseProductHandler(id, data);
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  const handleAddItems = () =>
    setItemsList([
      ...itemList,
      {
        id: uuidv4(),
        name: "",
        quantity: "",
        image: "",
        description: "",
      },
    ]);

  const handleRemoveItems = (id) => {
    confirmDelete(() =>
      setItemsList(itemList?.filter((item) => item.id !== id))
    );
  };

  const handleValueChange = (val, id, key) => {
    setItemsList(
      itemList?.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );
  };

  useEffect(() => {
    if (state?.tableData?.items?.length) {
      setItemsList(
        state?.tableData?.items?.map((item) => {
          return {
            id: uuidv4(),
            name: item?.name,
            quantity: item?.quantity,
            image: item?.image,
            description: item?.description,
          };
        })
      );
    }
  }, [state]);
  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }

    uploadImageHandler(
      e.target.files[0],
      `${state.tableData?.name
        ?.toLowerCase()
        ?.replaceAll(" ", "-")}/Merchendise}`
    );

    toaster("success", "Item image uploaded successfully");
  };
  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setItemsList(
        itemList.map((item) => {
          if (item.id === index) {
            return { ...item, image: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);
  return (
    <div>
      <form className="mt-2" onSubmit={onSubmitHandler}>
        <div>
          {itemList?.map(
            ({ name, quantity, id, image, description }, index) => {
              return (
                <div>
                  <Row key={index}>
                    <Col sm="5">
                      <FormGroup className="w-100">
                        <Label className="form-label" for="itemName">
                          Item name*
                        </Label>
                        <Input
                          type="text"
                          value={name}
                          id="itemName"
                          name="itemName"
                          placeholder="Item name"
                          onChange={(e) =>
                            handleValueChange(e.target.value, id, "name")
                          }
                        />

                        <Label className="form-label" for="itemQuantity">
                          Item quantity in kit*
                        </Label>
                        <Input
                          type="text"
                          value={quantity}
                          id="itemQuantity"
                          name="itemQuantity"
                          placeholder="Quantity"
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              handleValueChange(e.target.value, id, "quantity");
                            }
                          }}
                        />

                        {/* <Label className="form-label" for="itemDescription">
                          Description
                        </Label>
                        <Input
                          type="textarea"
                          value={description}
                          id="itemDescription"
                          name="itemDescription"
                          placeholder="Description"
                          onChange={(e) =>
                            handleValueChange(e.target.value, id, "description")
                          }
                        /> */}
                      </FormGroup>
                    </Col>
                    <Col sm="6" style={{ display: "flex", flexDirection: "column" }}>
                      upload image*
                      <div>
                        {image && (
                          <img
                            key={index}
                            className="img-thumbnail rounded mt-2 mr-1"
                            src={image}
                            alt="avatar"
                            style={{ height: "200px", width: "auto" }}
                          />
                        )}

                        <label htmlFor={`myFile${id}`}>
                          <p className="btn btn-sm btn-light">
                            <Camera size={15} />
                          </p>
                          <input
                            name={`myFile${id}`}
                            id={`myFile${id}`}
                            className="d-none"
                            type="file"
                            onChange={(e) => {
                              fileUploader(e, id);
                            }}
                          />
                        </label>
                      </div>
                    </Col>
                    <Col sm="1">
                      {itemList.length > 1 ? (
                        <>
                          <Button.Ripple
                            className="btn-icon rounded-circle mt-2 mr-1"
                            color="light"
                            type="button"
                            size="sm"
                            onClick={() => {
                              handleRemoveItems(id);
                            }}
                          >
                            <X size={18} />
                          </Button.Ripple>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                  <hr
                    style={{
                      border: "1px solid #ffe700",
                      marginTop: "70px",
                    }}
                  />
                </div>
              );
            }
          )}
          <div
            style={{
              opacity: visible ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
            }}
          >
            <Button.Ripple
              type="button"
              className="btn-icon rounded float-right mr-4 mt-2"
              color="light"
              size="md"
              onClick={handleAddItems}
            >
              <Plus size={13} className="" />
              <span style={{ fontSize: "15px" }}> Add</span>
            </Button.Ripple>
          </div>
        </div>
        <div style={{ marginTop: "90px" }}>
          <SaveDiscardBtnGroup className="mt-2" />
        </div>
      </form>
    </div>
  );
};

export default Items;
