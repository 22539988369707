import gql from "graphql-tag";

// ! createBlogsCategory added by Ashwin
export const POST_ADD_BLOG_Category = gql`
  mutation createBlogCategory($blogCategoryInput: BlogCategoryInput) {
    createBlogCategory(blogCategoryInput: $blogCategoryInput) {
        categoryName
    }
  }
`;

// ! updateBlogsCategory added by Ashwin
export const UPDATE_BLOG_CATEGORY = gql`
  mutation updateBlogCategory($id: ID!, $blogCategoryUpdate: BlogCategoryInput) {
    updateBlogCategory(id: $id, blogCategoryUpdate: $blogCategoryUpdate) {
        categoryName
    }
  }
`;

// ! createBlogsBlog added by Ashwin
export const POST_ADD_BLOGS_BLOG = gql`
  mutation createBlog($blogInput: BlogInput) {
    createBlog(blogInput: $blogInput) {
      blogTitle
    }
  }
`;

// ! updateDestination added by Ashwin
export const UPDATE_BLOGS_BLOG = gql`
  mutation updateBlog($id: ID!, $blogUpdate: BlogInput) {
    updateBlog(id: $id, blogUpdate: $blogUpdate) {
      blogTitle
      featuredImage
    }
  }
`;
