import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation, useQuery } from "@apollo/client";

export const CREATE_OTHERS_SEO = gql`
mutation createOtherSeo($SeoObjectInput:SeoObjectInput){
    createOthersSeo(SeoObjectInput:$SeoObjectInput){
            pageTitle
    }

}
`

export const UPDATE_OTHERS_SEO = gql`
mutation ($id:ID,$SeoObjectInput:SeoObjectInput){
    updateOthersSeo(id:$id,SeoObjectInput:$SeoObjectInput){
        pageTitle
    }
}
`

export const useCreateOthersSeo = () => {
    const [OthersSeo, { loading, error, data, refetch }] =
        useMutation(CREATE_OTHERS_SEO);

    useLoadingHandler(loading);

    const CreateOthersSeoHandler = (SeoObjectInput) => {
        OthersSeo({
            variables: { SeoObjectInput },
        });
    };

    return [CreateOthersSeoHandler, { loading, error, data, refetch }];
};

export const useUpdateOthersSeo = () => {
    const [updateOthersSeo, { loading, error, data, refetch }] = useMutation(UPDATE_OTHERS_SEO);
    useLoadingHandler(loading);

    const updateOthersSeoHandler = (id, SeoObjectInput) => {
        updateOthersSeo({
            variables: {
                id, SeoObjectInput
            }
        })
    };
    return [updateOthersSeoHandler, { loading, error, data, refetch }];
}
