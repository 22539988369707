import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdatePartner } from "@api";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import ZigZagSection from "../ZigZagSection";

const ZigZag = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");

  const [sectionOne, setSectionOne] = useState({});
  const [sectionTwo, setSectionTwo] = useState({});
  const [sectionThree, setSectionThree] = useState({});

  const [updatePartner, { data, reset }] = useUpdatePartner();

  useEffect(() => {
    if (state.tableData?.zigZag) {
      setHeading(state.tableData.zigZag.heading);
      setDescription(state.tableData.zigZag.description);

      setSectionOne(state.tableData.zigZag.sections?.[0]);
      setSectionTwo(state.tableData.zigZag.sections?.[1]);
      setSectionThree(state.tableData.zigZag.sections?.[2]);
    }
  }, [state.tableData]);

  useEffect(() => {
    if (data?.updatePartner) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          zigZag: {
            heading,
            description,
            sections: [sectionOne, sectionTwo, sectionThree],
          },
        })
      );

      reset();
    }
  }, [data?.updatePartner]);

  const submitDataHandler = (e) => {
    e.preventDefault();

    let isValid = false;

    const zigZag = {
      heading,
      description,
      sections: [sectionOne, sectionTwo, sectionThree],
    };

    zigZag.sections.length >= 3 &&
      zigZag.sections.forEach((item) => {
        if (
          item.heading &&
          item.pointers.length >= 3 &&
          item.subPointers.length >= 4
        ) {
          isValid = true;
        } else {
          isValid = false;
        }
      });

    if (heading && description && isValid) {
      updatePartner({ zigZag });
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className='mt-2' onSubmit={submitDataHandler}>
      <Row>
        <Col className='ml-1' sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='heading'>
              Heading
            </Label>

            <Input
              type='text'
              value={heading}
              id='heading'
              name='heading'
              placeholder='Heading'
              onChange={(e) => setHeading(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col className='ml-1' sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='description'>
              Description
            </Label>

            <Input
              type='text'
              value={description}
              id='description'
              name='description'
              placeholder='Description'
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>

      <ZigZagSection
        title='First'
        section={sectionOne}
        setSection={setSectionOne}
      />

      <ZigZagSection
        title='Second'
        section={sectionTwo}
        setSection={setSectionTwo}
      />

      <ZigZagSection
        title='Third'
        section={sectionThree}
        setSection={setSectionThree}
      />

      <SaveDiscardBtnGroup />
    </form>
  );
};

export default ZigZag;
