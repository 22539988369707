import React, { useState, useEffect } from "react";
import SlideModal from "@components/common/slide-modal";
import { useCreateNewPost, useHostelList, useUploadImage } from "../../api/dataSource";
import { Row, Col, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Uppy from "@uppy/core";
import { DragDrop } from "@uppy/react";
import toaster from "@components/common/Toaster";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import ConnectDocumentSection from "./ConnectDocumentSection";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";
import Editor from "./Editor";

const AddEditConnectModel = ({ open, handleToggle, refetch }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(() =>
    EditorState.createEmpty()
  );
  const [department, setDepartment] = useState("")
  const [previewArr, setPreviewArr] = useState([]);
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [publishDate, setPublishDate] = useState(new Date());
  const userName = localStorage.getItem("name");
  const userId = localStorage.getItem("userId")
  const [postImageUrl, setPostImageUrl] = useState("")
  const { data: allHostelsData } = useHostelList();
  const [imageList, setImageList] = useState([]);
  const [dummyState, setDummyState] = useState(null);
  const UploadType = [{ label: "documents", value: "documentsImages" }];


  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();
  useEffect(() => {
    if (imageData) {
      setPostImageUrl(imageData?.uploadImage?.url)
    }
  }, [imageData])

  useEffect(() => {
    // comment this if you want all hostel list in array
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
            return allHostelsData?.getHostelList?.find(
              (item) => item?._id === hostelID
            );
          })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  const [createNewPostHandler, { loading, error, data }] = useCreateNewPost();

  const submitHandler = () => {
    const hostelArray = hostel?.map((hostelvalue) => hostelvalue.value);
    const attachmentList = imageList?.map((image) => image.image);
    const departmentList = department.length ? department?.map((item) => item?.value) : [];
    const convertedDescription = inputDataConvertor(description);
    if (title && convertedDescription != "<p></p>") {
      const conversationInput = {
        title,
        description: inputDataConvertor(description),
        hostels: hostelArray,
        image: postImageUrl,
        addedBy: userName,
        department: departmentList,
        attachment: attachmentList,
        addedById: userId,
      }
      try {
        createNewPostHandler(conversationInput);
        toaster("success", "Submitted successfully");
        handleToggle();
        setTitle("");
        setDescription(""); setDepartment("");
        setPreviewArr([]);
        setHostelsList("")
        setImageList([])
      } catch (error) {
        console.log(error.message)
      }
    }else{
      toaster("error", "Please fill the details")
    }


  }
  useEffect(()=>{
    if(data){
      refetch()
    }
  },[data])

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".pdf"],
      maxFileSize: 2000000,
    },
  });

  uppy.use(thumbnailGenerator);
  uppy.on("thumbnail:generated", (file, preview) => {
    setPreviewArr([preview]);
    uploadImageHandler(file.data, "image/walloflove");
  });

  const renderPreview = () => {
    if (previewArr.length) {
      return previewArr.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };


  const DepartmentList = [
    { label: "Sales", value: "Sales" },
    { label: "Marketing", value: "Marketing" },
    { label: "Technology", value: "Technology" },
    { label: "Management", value: "Management" },
    { label: "Customer Experience", value: "Customer Experience" },
    { label: "Operations", value: "Operations" },
    { label: "Finance", value: "Finance" },
    { label: "HR", value: "HR" },
  ];

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => submitHandler()}
    // style={{width:"60%"}}
    >
      <form>
        <Row>
          <Col style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Row>
              <Col>
                <label>Title*</label>
                <Input
                  autoFocus
                  type="text"
                  value={title}
                  id="Title"
                  name="Title"
                  placeholder="Title"
                  onChange={(e) => setTitle(e.target.value)}
                  maxLength={500}
                />
              </Col>
            </Row>
            <Row style={{marginBottom:"30px"}}>
              <Col >
                <label>Description*</label>
                {/* <MyEditor
                  editorState={description}
                  setEditorState={(val) => setDescription(val)}
                /> */}

                <Editor
                editorState={description}
                setEditorState={(val)=> setDescription(val)}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Department
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="hostel"
                    name="hostel"
                    options={DepartmentList}
                    value={department}
                    onChange={(value) => setDepartment(value)}
                    classNamePrefix="select"
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Hostel
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="hostel"
                    name="hostel"
                    options={HostelsList}
                    value={hostel}
                    onChange={(value) => setHostel(value)}
                    classNamePrefix="select"
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginBottom: "30px" }}>
              <Col>
                <div style={{ width: "50%" }}>
                  <Label className="form-label">Banner Image</Label>
                  <DragDrop
                    note="Image size should be less than 2MB"
                    width="100%"
                    uppy={uppy}
                  />
                </div>
                {renderPreview()}
              </Col>
            </Row>

            <Row>
              <Col>
                <Label>Upload Documents</Label>
                <ConnectDocumentSection
                  imageList={imageList}
                  setImageList={setImageList}
                  editData={dummyState}
                  uploadType={UploadType}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Label>Added By</Label>
                <Input
                  type="text"
                  value={userName}
                  id="description"
                  name="description"
                />
              </Col>
            </Row>

          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditConnectModel;
