import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useUpdateHomePageDetails } from "../../../../api/dataSource";

import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import toaster from "@components/common/Toaster";
import { Row } from "reactstrap";
import { tableDataHandler } from "@store/Cms/cms.action";
import NewHomepageImageSection from "./NewHomepage";

const UploadType = [
  { value: "offers", label: "Offers" },
  { value: "grow", label: "Grow" },
];
export const NewImages = () => {
  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const [imageList, setImageList] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);
  const [updateHomePageDetailsHandler, { data }] = useUpdateHomePageDetails();
  const dispatch = useDispatch();

  useEffect(() => {
    setImageList(
      state?.tableData?.newImages?.length > 0 ? state?.tableData?.newImages : []
    );
  }, [state?.tableData?.newImages]);

  useEffect(() => {
    let arrProcessedImages = [];
    imageList.forEach((i) => {
      let imageObj = {
        type: i?.type,
        image: i?.image,
        key: i?.key,
        link: i?.link,
        displayOrder: i?.displayOrder,
        deviceType: i?.deviceType,
      };
      arrProcessedImages.push(imageObj);
    });

    setSortedImages(
      arrProcessedImages.sort((a, b) => a.displayOrder - b.displayOrder)
    );
  }, [imageList]);

  useEffect(() => {
    if (data?.updateHomePage?._id) {
      // toaster("success", "Updated successfully");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data?.updateHomePage?._id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];
    imageList?.forEach((image) => {
      image.image &&
        images.push({
          type: image.type,
          image: image.image,
          key: image.key,
          link: image.link,
          displayOrder: parseInt(image.displayOrder),
          deviceType: image?.deviceType || "",
        });
    });
    const isDeviceTypeEmpty = images?.some((el) => el?.deviceType === "");
    if (images?.length !== 0 && !isDeviceTypeEmpty) {
      updateHomePageDetailsHandler(id, { newImages: images });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          newImages: imageList,
        })
      );
    } else {
      toaster("error", "Please upload images and their device type correctly");
    }
  };
  return (
    <div className="mt-2" onSubmit={handleSubmit}>
      <form>
        <Row>
          <NewHomepageImageSection
            imageList={sortedImages}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};
