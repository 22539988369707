import { Home, Circle } from "react-feather";

export default [
  {
    id: "packages",
    title: "Packages",
    icon: <Home size={20} />,
    badge: "light-warning",
    badgeText: "2",
    children: [
      {
        id: "packagesHostel",
        title: "Hostel",
        icon: <Circle size={12} />,
        navLink: "/package/hostel",
      },
      {
        id: "packagesWorkation",
        title: "eCommerce",
        icon: <Circle size={12} />,
        navLink: "/package/workation",
      },
      {
        id: "packagesTrips",
        title: "eCommerce",
        icon: <Circle size={12} />,
        navLink: "/package/trip",
      },
    ],
  },
];
