import React, { useEffect, useState } from "react";
const NotificationContainer = ({
  setShowNotification,
  notificationData,
  setNotificationData,
  showNotification,
}) => {
  const [audio, setAudio] = useState(
    new Audio("https://static.thehosteller.com/notificationSound.mp3")
  );

  const viewFunction = () => {
    setShowNotification(false);
    setNotificationData(null);
    window.location.href = `${notificationData?.data?.url}`;
  };

  const closeFunction = () => {
    audio.pause();
    setShowNotification(false);
    setNotificationData(null);
  };

  // useEffect(() => {
  //   if (showNotification) {
  //     audio.play();
  //   } else {
  //     audio.pause();
  //   }
  // }, [showNotification]);

  useEffect(() => {
    const disableSound = localStorage.getItem("disableSound") === "true";
    if (showNotification) {
      if (!disableSound) {
        audio.play();
      }
    } else {
      audio.pause();
    }
  }, [showNotification, audio]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 999,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          textAlign: "center",
          position: "relative",
          color: "#000",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            margin: "0 0 10px 0",
            color: "#000",
            fontSize: "24px",
            fontWeight: "500",
          }}
        >
          {notificationData?.data?.title}
        </p>
        <p style={{ margin: "0 0 10px 0", width: "90%" }}>
          {notificationData?.data?.body}
        </p>
        <div>
          <button
            onClick={viewFunction}
            style={{
              left: "60%",
              borderRadius: "5px",
              backgroundColor: "#FFFF00",
              color: "white",
              border: "none",
              cursor: "pointer",
              padding: "10px",
              width: "120px",
              color: "black",
              fontWeight: "600",
              marginRight: "20px",
            }}
          >
            View
          </button>
          <button
            onClick={closeFunction}
            style={{
              bottom: "20px",
              left: "60%",
              borderRadius: "5px",
              backgroundColor: "#FFFFFF",
              color: "white",
              border: "1px solid yellow",
              cursor: "pointer",
              padding: "10px",
              width: "120px",
              color: "black",
              fontWeight: "600",
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationContainer;
