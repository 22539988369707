import React, { useState } from "react";
import AddEditModal from "./AddEditModal";
import DestinationsTable from "../../../@core/components/tables/DestinationsTable";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";

const Destination = () => {
  const [destination, setDestination] = useState("");
  const [status, setStatus] = useState(null);
  const [filter, setFilter] = useState(null);
  const [page, setPage] = useState(1);

  const statusType = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const handleSearch = () => {
    const data = { destination, destinationStatus: status?.value };
    setFilter(data);
    setPage(1);
  };

  const handleReset = () => {
    setDestination("");
    setStatus(null);
    setFilter(null);
    setPage(1);
  };
  return (
    localStorage.getItem("modules")?.includes("Packages") && (
      <>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="destination">
                  Destination Name
                </Label>
              </strong>
              <Input
                type="text"
                value={destination}
                id="destination"
                name="destination"
                placeholder="Destination Name"
                onChange={(e) => setDestination(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="status">
                  Destination Status
                </Label>
              </strong>
              <Select
                isSearchable={true}
                isClearable={false}
                id="status"
                name="status"
                value={status}
                options={statusType}
                onChange={(value) => setStatus(value)}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>

            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <DestinationsTable
          AddEditModal={AddEditModal}
          filter={filter}
          page={page}
          setPage={setPage}
        />
      </>
    )
  );
};

export default Destination;
