import gql from "graphql-tag";

export const UPDATE_FEEDBACK_RATING = gql`
  mutation updateFeedbackRating(
    $reservationId: String
    $feedbackRatingDetails: InputFeedbackRating
    $feedbackTime: Date
  ) {
    updateFeedbackRating(
      reservationId: $reservationId
      feedbackRatingDetails: $feedbackRatingDetails
      feedbackTime: $feedbackTime
    ) {
      customerFeedback
      customerRemark
    }
  }
`;
