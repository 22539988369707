import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";

const SlideModal = ({
  open,
  handleToggle,
  submitDataHandler,
  children,
  large,
  editData,
}) => {
  const today = new Date();

  const createdAtDate = editData?.createdAt
    ? new Date(editData?.createdAt)
    : null;

  const userRole = localStorage.getItem("role");

  let activeHours;

  switch (userRole) {
    case "Area Manager":
      activeHours = 48;
      break;
    case "Ground Staff":
      activeHours = 24;
      break;
    default:
      activeHours = 720;
  }

  const timeDifference = createdAtDate
    ? today.getTime() - createdAtDate.getTime()
    : null;

  const differenceInHours = timeDifference
    ? timeDifference / (1000 * 60 * 60)
    : null;

  const isDisabled = differenceInHours > activeHours;

  return (
    <StyledModalWrap
      unmountOnClose={true}
      isOpen={open}
      toggle={handleToggle}
      className={large ? "ml-auto m-0 modal-lg" : "ml-auto m-0"}
    >
      <ModalHeader toggle={handleToggle}>Details</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button
          color="light"
          onClick={submitDataHandler}
          className="save-button"
          disabled={isDisabled}
        >
          Save
        </Button>
      </ModalFooter>
    </StyledModalWrap>
  );
};

const StyledModalWrap = styled(Modal)`
  .modal-content {
    min-height: 100vh;
  }
  .modal-header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    .close {
      margin: 0;
    }
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background-color: #f8f8f8;
  }
`;

export default SlideModal;
