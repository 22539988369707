import React, { useEffect, useState } from "react";
import { Edit } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeader = [
  "Action",
  "Order ID",
  "RES ID",
  "Guest Name",
  "Mobile No",
  "Cafe Name",
  "Amount",
  "Payment Mode",
  "Discount Amount",
  "Coupon Applied",
  "Order Date",
  "Status",
  "Rating",
  "Print Count",
];
const UnboxOrderTable = ({
  openModal,
  data,
  setId,
  refetch,
  totalSales,
  netSales,
  totalOrder,
  totalDiscount,
}) => {
  const [tableData, setTableData] = useState(data);
  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);
  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle style={{ display: "flex", gap: "10px" }}>
                Orders (<span>Total Orders : {totalOrder}</span>|
                <span>Total Sales : {totalSales}</span>|
                <span>Net Sales : {netSales}</span>|
                <span>Total Discount : {totalDiscount}</span>)
              </CardTitle>
              <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                Add Order
              </Button.Ripple>
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  {tableHeader?.map((item) => {
                    return <th key={uuidv4()}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((element, i) => (
                  <tr key={uuidv4()}>
                    <td style={{ width: "5%" }}>
                      <Button
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(element?._id);
                          openModal();
                        }}
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td>{element?.orderId}</td>
                    <td>{element?.reservationId || ""}</td>
                    <td>{element?.guestName}</td>
                    <td>{element?.mobileNo}</td>
                    <td>{element?.cafe?.cafeName}</td>
                    <td>{element?.finalAmount?.toFixed(2)}</td>
                    <td>{element?.paymentMode || ""}</td>
                    <td>{element?.discountAmount?.toFixed(2)}</td>
                    <td>{element?.couponCode}</td>
                    <td style={{ display: "flex", flexDirection: "column" }}>
                      <span>
                        {" "}
                        {moment(
                          new Date(element?.orderDate),
                          "D/M/YYYY"
                        ).format("DD/MM/YYYY")}
                      </span>
                      <span>
                        {moment(element?.createdAt).format("hh:mm A") !==
                        "Invalid date"
                          ? moment(element?.createdAt).format("hh:mm A")
                          : ""}
                      </span>
                    </td>
                    <td>{element?.status}</td>
                    <td>{element?.rating}</td>
                    <td>{element?.printCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UnboxOrderTable;
