// Coded by Nitish
import React, { useEffect, useState, Fragment } from "react";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import {
  useCreateNewORMReview,
  useGetHostelsNameandId,
} from "../../api/dataSource";
import toaster from "@components/common/Toaster";
import SlideModal from "@components/common/slide-modal";
import Flatpickr from "react-flatpickr";

const AddReviewModal = ({ open, handleToggle, refetch }) => {
  const [formData, setFormData] = useState({
    guestName: "",
    hostel: null,
    reviewDate: null,
    review: "",
    rating: 0,
    bookingSource: "",
    sentiment: "",
  });

  const [guestName, setGuestName] = useState("");
  const [hostel, setHostel] = useState(null);
  const [HostelsList, setHostelsList] = useState([]);
  const [reviewDate, setReviewDate] = useState(null);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [bookingSource, setBookingSource] = useState("");
  const [createNewORMReviewHandler] = useCreateNewORMReview();
  const [sentiment, setSentiment] = useState("");

  const bookingSourceDropdown = [
    { label: "Email", value: "Email" },
    { label: "Social Media", value: "Social Media" },
    { label: "Complaints", value: "Complaints" },
  ];

  const ratingDropdown = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
  ];

  const sentimentDropdown = [
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "Neutral", value: "Neutral" },
  ];

  const { data: allHostelsData } = useGetHostelsNameandId();

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelsNameandId?.map((hostel) => {
      return { label: hostel?.name, value: hostel?._id };
    });

    HostelsList?.push({ label: "All", value: null });
    setHostelsList(HostelsList);
  }, [allHostelsData]);

  useEffect(() => {
    if (open) {
      setFormData({
        guestName: "",
        hostel: null,
        reviewDate: null,
        review: "",
        rating: 0,
        bookingSource: "",
        sentiment: "",
      });
    }
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !guestName ||
      !hostel ||
      !reviewDate ||
      !review ||
      !rating ||
      !bookingSource ||
      !sentiment
    ) {
      toaster("error", "All fields are required!", "red");
      return;
    }

    const reviewInput = {
      guestName,
      hostel: hostel?.value,
      reviewDate,
      review,
      rating: rating.value,
      outOfRating: 5,
      bookingSource,
      sentiment: sentiment.value,
    };

    try {
      await createNewORMReviewHandler(reviewInput);
      toaster("success", "Review added successfully!");
      setGuestName("");
      setHostel(null);
      setReviewDate(null);
      setReview("");
      setRating(0);
      setBookingSource("");
      setSentiment("");
      refetch();
      handleToggle();
    } catch (error) {
      console.error("Error while adding review:", error);
      toaster("error", "Failed to add review. Please try again later.", "red");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={handleSubmit}
    >
      <form>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostel">
                  Hostel*
                </Label>
              </strong>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={HostelsList}
                required
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="bookingSource">
                  Booking Source
                </Label>
              </strong>
              <Select
                id="bookingSource"
                name="bookingSource"
                value={bookingSourceDropdown.find(
                  (option) => option.value === bookingSource
                )}
                options={bookingSourceDropdown}
                required
                onChange={(selectedOption) =>
                  setBookingSource(selectedOption.value)
                }
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="guestName">
                  Guest Name
                </Label>
              </strong>
              <Input
                type="text"
                id="guestName"
                name="guestName"
                placeholder="Guest Name"
                value={guestName}
                onChange={(e) => setGuestName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="rating">
                <strong>Rating (outof 5)</strong>
              </Label>
              <Select
                isClearable={true}
                id="rating"
                name="rating"
                value={ratingDropdown.find((option) => option.value === rating)}
                options={ratingDropdown}
                onChange={(value) => {
                  setRating(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="sentiment">
                <strong>Sentiment</strong>
              </Label>
              <Select
                isClearable={true}
                id="sentiment"
                name="sentiment"
                value={sentimentDropdown.find(
                  (option) => option.value === sentiment
                )}
                options={sentimentDropdown}
                onChange={(value) => {
                  setSentiment(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="review">
                  Review
                </Label>
              </strong>
              <Input
                type="textarea"
                id="review"
                name="review"
                placeholder="Review"
                value={review}
                required
                rows={2}
                onChange={(e) => setReview(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="reviewDate">
                  Review Date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={reviewDate}
                onChange={(value) => setReviewDate(value)}
                id="reviewDate"
                placeholder="Select Date"
                options={{
                  enableTime: false,
                  dateFormat: "d-m-y",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddReviewModal;
