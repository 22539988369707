import { useEffect, useState } from "react";
import { Edit, Trash2 } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Avatar from "@components/common/avatar";
import { useDeleteAmenity } from "@api";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

const tableHeaderData = ["Action", "", "Icon", "Name", "key"];

const AmenitiesTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState([]);
  const [deleteAmenityHandler] = useDeleteAmenity();

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handleDelete = (e, item) => {
    e.preventDefault();
    deleteAmenityHandler(item._id);
    refetch();

    toaster("success", "Amenity deleted successfully");
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <h2>Amenities</h2>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Amenity
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "10px" }}>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td style={{ width: "10px" }}>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) =>
                        confirmDelete(() => handleDelete(e, item))
                      }
                    >
                      <Trash2
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>
                    <Avatar img={item?.icon} />
                  </td>
                  <td>{item?.name}</td>
                  <td>{item?.key}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default AmenitiesTable;
