import { Upload } from "react-feather";

const role = localStorage.getItem("role");
export default role === "Super Admin"
  ? [
      // {
      //   id: "fileUpload",
      //   title: "File Upload",
      //   icon: <Upload />,
      //   navLink: "/fileUpload",
      // },
    ]
  : [];