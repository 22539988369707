import React, { useState, useEffect } from "react";
import SlideModal from "../../../@core/components/common/slide-modal/index";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import {
  useCreateStepOutBooking,
  useUpdateStepOutBooking,
} from "../../../api/dataSource";
import Select from "react-select";

import toaster from "@components/common/Toaster";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [status, setStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [pickupPoint, setPickupPoint] = useState("");
  const [totalPriceWithOutTax, setTotalPriceWithOutTax] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
  const [razorpayOrderId, setRazorpayOrderId] = useState("");
  const [razorpayPaymentId, setRazorpayPaymentId] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [reservationId, setReservationId] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const [createStepOutBookingHandler, { data: createdStepOutBookingData }] =
    useCreateStepOutBooking();
  const [updateStepOutBookingHandler, { data: updatedStepOutBookingData }] =
    useUpdateStepOutBooking();

  const BookingStatusList = [
    { label: "Init", value: "Init" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  useEffect(() => {
    if (createdStepOutBookingData || updatedStepOutBookingData) {
      refetch();
      handleToggle();
      // toaster("success", "Booking added successfully");
    }
  }, [createdStepOutBookingData, updatedStepOutBookingData]);

  useEffect(() => {
    if (editData?._id) {
      setStatus(
        editData?.status !== null
          ? { label: editData?.status, value: editData?.status }
          : null
      );
      setProductName(
        editData?.productName !== null ? editData?.productName : ""
      );
      setCategory(editData?.category !== null ? editData?.category : "");
      setPickupPoint(
        editData?.pickupPoint !== null ? editData?.pickupPoint : ""
      );
      setTotalPriceWithOutTax(
        editData?.totalPriceWithOutTax !== null
          ? editData?.totalPriceWithOutTax
          : 0
      );
      setTotalTax(editData?.totalTax !== null ? editData?.totalTax : 0);
      setTotalPriceWithTax(
        editData?.totalPriceWithTax !== null ? editData?.totalPriceWithTax : 0
      );
      setRazorpayOrderId(
        editData?.razorpayOrderId !== null ? editData?.razorpayOrderId : ""
      );
      setRazorpayPaymentId(
        editData?.razorpayPaymentId !== null ? editData?.razorpayPaymentId : ""
      );
      setCouponDiscount(
        editData?.couponDiscount !== null ? editData?.couponDiscount : 0
      );
      setReservationId(
        editData?.reservationId !== null ? editData?.reservationId : ""
      );
      setPromoCode(editData?.promoCode !== null ? editData?.promoCode : "");
    } else {
      setStatus(null);
      setProductName("");
      setCategory("");
      setPickupPoint("");
      setTotalPriceWithOutTax(0);
      setTotalTax(0);
      setTotalPriceWithTax(0);
      setRazorpayOrderId("");
      setRazorpayPaymentId("");
      setCouponDiscount(0);
      setReservationId("");
      setPromoCode("");
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const data = {
      status: status.value,
      productName,
      category,
      pickupPoint,
      totalPriceWithOutTax: Number(totalPriceWithOutTax),
      totalTax: Number(totalTax),
      totalPriceWithTax: Number(totalPriceWithTax),
      razorpayOrderId,
      razorpayPaymentId,
      couponDiscount: Number(couponDiscount),
      reservationId,
      promoCode,
    };

    if (data.status) {
      if (editData?._id) {
        updateStepOutBookingHandler(editData?._id, data);
        toaster("success", "Booking updated successfully");
      } else {
        createStepOutBookingHandler(data);
        toaster("success", "Booking added successfully");
      }
      setStatus(null);
      setProductName("");
      setCategory("");
      setPickupPoint("");
      setTotalPriceWithOutTax(0);
      setTotalTax(0);
      setTotalPriceWithTax(0);
      setRazorpayOrderId("");
      setRazorpayPaymentId("");
      setCouponDiscount(0);
      setReservationId("");
      setPromoCode("");
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="status">
                Status
              </Label>
              <Select
                isClearable={false}
                id="status"
                name="status"
                value={status}
                options={BookingStatusList}
                required
                onChange={(value) => setStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="productName">
                Product Name
              </Label>
              <Input
                type="text"
                value={productName}
                id="productName"
                name="productName"
                placeholder="Add Product Name"
                onChange={(e) => setProductName(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="category">
                Category
              </Label>
              <Input
                type="text"
                value={category}
                id="category"
                name="category"
                placeholder="Add Category"
                onChange={(e) => setCategory(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="pickupPoint">
                Pickup Point
              </Label>
              <Input
                type="text"
                value={pickupPoint}
                id="pickupPoint"
                name="pickupPoint"
                placeholder="Add Pickup Point"
                onChange={(e) => setPickupPoint(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="totalPriceWithOutTax">
                Total Price Without Tax
              </Label>
              <Input
                type="text"
                value={totalPriceWithOutTax}
                id="totalPriceWithOutTax"
                name="totalPriceWithOutTax"
                placeholder="Add Total Price Without Tax"
                onChange={(e) => setTotalPriceWithOutTax(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="totalTax">
                Total Tax
              </Label>
              <Input
                type="text"
                value={totalTax}
                id="totalTax"
                name="totalTax"
                placeholder="Add Total Price Without Tax"
                onChange={(e) => setTotalTax(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="totalPriceWithTax">
                Total Price With Tax
              </Label>
              <Input
                type="text"
                value={totalPriceWithTax}
                id="totalPriceWithTax"
                name="totalPriceWithTax"
                placeholder="Add Total Price With Tax"
                onChange={(e) => setTotalPriceWithTax(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="razorpayOrderId">
                Razorpay Order Id
              </Label>
              <Input
                type="text"
                value={razorpayOrderId}
                id="razorpayOrderId"
                name="razorpayOrderId"
                placeholder="Add Razorpay Order Id"
                onChange={(e) => setRazorpayOrderId(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="razorpayPaymentId">
                Razorpay Payment Id
              </Label>
              <Input
                type="text"
                value={razorpayPaymentId}
                id="razorpayPaymentId"
                name="razorpayPaymentId"
                placeholder="Add Razorpay Payment Id"
                onChange={(e) => setRazorpayPaymentId(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="couponDiscount">
                Coupon Discount
              </Label>
              <Input
                type="text"
                value={couponDiscount}
                id="couponDiscount"
                name="couponDiscount"
                placeholder="Add Coupon Discount"
                onChange={(e) => setCouponDiscount(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="reservationId">
                Reservation Id
              </Label>
              <Input
                type="text"
                value={reservationId}
                id="reservationId"
                name="reservationId"
                placeholder="Add Reservation Id"
                onChange={(e) => setReservationId(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="promoCode">
                Promo Code
              </Label>
              <Input
                type="text"
                value={promoCode}
                id="promoCode"
                name="promoCode"
                placeholder="Add Promo Code"
                onChange={(e) => setPromoCode(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
