import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateStepOutProduct } from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";

const KeyDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [duration, setDuration] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [batchSize, setBatchSize] = useState("");
  const [altitude, setAltitude] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [fullDescription, setFullDescription] = useState(() =>
    EditorState.createEmpty()
  );

  const [updateStepOutProductHandler, { data: updateStepOutProductData }] =
    useUpdateStepOutProduct();

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData) {
      setDuration(
        state?.tableData?.keyDetails?.duration !== null
          ? state?.tableData?.keyDetails?.duration
          : ""
      );
      setDifficulty(
        state?.tableData?.keyDetails?.difficulty !== null
          ? state?.tableData?.keyDetails?.difficulty
          : ""
      );
      setBatchSize(
        state?.tableData?.keyDetails?.batchSize !== null
          ? state?.tableData?.keyDetails?.batchSize
          : ""
      );
      setAltitude(
        state?.tableData?.keyDetails?.altitude !== null
          ? state?.tableData?.keyDetails?.altitude
          : ""
      );
      setShortDescription(
        state?.tableData?.shortDescription !== null
          ? state?.tableData?.shortDescription
          : ""
      );
      setFullDescription(
        htmlToDraftConvert(
          state?.tableData?.fullDescription !== null
            ? state?.tableData?.fullDescription
            : ""
        )
      );
    }
  }, [state]);

  const submitDataHandler = (e) => {
    e.preventDefault();

    let data = {
      keyDetails: {
        duration,
        difficulty,
        batchSize,
        altitude,
      },
      shortDescription,
      fullDescription: inputDataConvertor(fullDescription),
    };
    if (id) {
      updateStepOutProductHandler(id, data);
      toaster("success", "Key details updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <Row>
            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="duration">
                  Duration
                </Label>

                <Input
                  type="text"
                  value={duration}
                  id="duration"
                  name="duration"
                  placeholder="Add duration"
                  onChange={(e) => setDuration(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="difficulty">
                  Difficulty
                </Label>

                <Input
                  type="text"
                  value={difficulty}
                  id="difficulty"
                  name="difficulty"
                  placeholder="Add difficulty"
                  onChange={(e) => setDifficulty(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="batchSize">
                  Batch size
                </Label>

                <Input
                  type="text"
                  value={batchSize}
                  id="batchSize"
                  name="batchSize"
                  placeholder="Add batchSize"
                  onChange={(e) => setBatchSize(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="altitude">
                  Altitude
                </Label>

                <Input
                  type="text"
                  value={altitude}
                  id="altitude"
                  name="altitude"
                  placeholder="Add altitude"
                  onChange={(e) => setAltitude(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="shortDescription">
              Short description
            </Label>
            <Input
              type="textarea"
              value={shortDescription}
              id="shortDescription"
              name="shortDescription"
              placeholder="Add shortDescription"
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="fullDescription">
              Full description
            </Label>
            <div className="mt-1 ">
              <MyEditor
                id="fullDescription"
                editorState={fullDescription}
                setEditorState={(val) => setFullDescription(val)}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default KeyDetails;
