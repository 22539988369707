import React from "react";
import { Edit2, Copy } from "react-feather";
import { Button, UncontrolledTooltip } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toaster from "@components/common/Toaster";

const SeoTableList = ({ tableRowData, openModal, handleEdit }) => {
  const edit = (e, id) => {
    e.preventDefault();
    openModal();
    handleEdit(id);
  };

  const onCopy = () => {
    toaster("success", "URL copied successfully");
  };
  return (
    <>
      {tableRowData.map(({ id, h1Tags, name, url }) => {
        return (
          name && (
            <tr key={id}>
              <td>
                <span className='align-middle font-weight-bold'>{name}</span>
              </td>
              <td>{h1Tags}</td>
              <td>{url}</td>
              <td className='d-flex'>
                <CopyToClipboard
                  onCopy={onCopy}
                  text={process.env.REACT_WEB_ORIGIN + url}
                >
                  <Button
                    className='rounded-circle btn-icon'
                    color='none'
                    id='copyBtn'
                  >
                    <Copy size={15} />
                  </Button>
                </CopyToClipboard>

                <UncontrolledTooltip placement='top' target='copyBtn'>
                  Copy URL
                </UncontrolledTooltip>

                <Button
                  className='rounded-circle btn-icon'
                  color='none'
                  id='editBtn'
                  onClick={(e) => edit(e, id)}
                >
                  <Edit2 size={15} />
                </Button>

                <UncontrolledTooltip placement='top' target='editBtn'>
                  Edit
                </UncontrolledTooltip>
              </td>
            </tr>
          )
        );
      })}
    </>
  );
};

export default SeoTableList;
