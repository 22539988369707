import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation, useQuery } from "@apollo/client";

export const UPDATE_EMPLOYEE_SALARY_STRUCTURE = gql`
mutation updateEmployeeSalaryStructure($id:ID, $salaryStructure:salaryStructure){
    updateEmployeeSalaryStructure   (id:$id, salaryStructure:$salaryStructure){
        empId
    }
}
`

export const useUpdateEmployeeSalaryStructure = () => {
    const [employeeSalaryStructure, { loading, error, data, refetch }] =
        useMutation(UPDATE_EMPLOYEE_SALARY_STRUCTURE);

    useLoadingHandler(loading);

    const updateEmployeeSalaryStructureHandler = async (id, salaryStructure) => {
        return await employeeSalaryStructure({
            variables: { id, salaryStructure },
        });
    };

    return [updateEmployeeSalaryStructureHandler, { loading, error, data, refetch }];
};