import Cancellation from "../../views/Rules/Cancellation";

const RulesRoutes = [
  {
    path: "/rules/cancellations",
    component: Cancellation,
    exact: true,
  },
];

export default RulesRoutes;
