import React from "react";
import Loader from "react-js-loader";

const AppLoader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        zIndex: "99999",
        position: "fixed",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        top: 0,
        left: 0,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          position: "absolute",
          overflow: "hidden",
          opacity: "0.2",
        }}
      />
      <Loader
        type='bubble-loop'
        bgColor={"#FFE500"}
        color={"#202020"}
        size={140}
      />
    </div>
  );
};

export default AppLoader;
