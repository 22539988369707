import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const CREATE_UNBOX_CAFE_MASTER = gql`
  mutation CreateCafeMaster($cafeMasterInput: cafeMasterInput) {
    createCafeMaster(cafeMasterInput: $cafeMasterInput) {
      cafeName
      cafeTimings {
        _id
        fromTime
        toTime
      }
    }
  }
`;

export const useCreateCafeMaster = () => {
  const [createCafeMaster, { loading, error, data, refetch }] = useMutation(
    CREATE_UNBOX_CAFE_MASTER
  );

  useLoadingHandler(loading);

  const createCafeMasterHandler = (cafeMasterInput) => {
    createCafeMaster({
      variables: {
        cafeMasterInput,
      },
    });
  };

  return [createCafeMasterHandler, { data, error, refetch }];
};

export const UPDATE_UNBOX_CAFE_MASTER = gql`
  mutation UpdateCafeMaster($id: ID, $cafeMasterInput: cafeMasterInput) {
    updateCafeMaster(id: $id, cafeMasterInput: $cafeMasterInput) {
      hostel {
        _id
        name
      }
      cafeName
    }
  }
`;

export const useUpdateCafeMaster = () => {
  const [updateCafeMaster, { loading, error, data, refetch }] = useMutation(
    UPDATE_UNBOX_CAFE_MASTER
  );

  useLoadingHandler(loading);

  const updateCafeMasterHandler = (id, cafeMasterInput) => {
    updateCafeMaster({
      variables: {
        id,
        cafeMasterInput,
      },
    });
  };

  return [updateCafeMasterHandler, { data, error, refetch }];
};
