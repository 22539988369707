import { PenTool, Circle } from "react-feather";

export default [
  {
    id: "blogs",
    title: "Blogs",
    icon: <PenTool size={20} />,
    badge: "light-warning",
    badgeText: "2",
    children: [
      {
        id: "blogsCategory",
        title: "Category",
        icon: <Circle size={12} />,
        navLink: "/blogs/category",
      },
      {
        id: "blogsBlog",
        title: "Blog",
        icon: <Circle size={12} />,
        navLink: "/blogs/blog",
      }
    ],
  },
];
