import { useEffect, useState } from "react";
import { Col, Card, Row, Button, FormGroup, Label, Input } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import SwitchIcon from "@components/common/switch-icon";
import { useCreateHostelRequest, useUpdateHostel, useGetHostelById } from "../../../api/dataSource";
import toaster from "../../../@core/components/common/Toaster";
import moment from "moment";
const tableHeaderData = [
  "Request Name",
  "Category",
  "From Time",
  "To Time",
  "TAT (in min)",
];

const HostelRequestedDataTable = ({ requestListData, selectedHostelId }) => {
  const [createHostelRequestHandler, { data: createHostelRequestData }] =
    useCreateHostelRequest();
  const [updateHostelHandler, { data: updatdedHostelData }] = useUpdateHostel();
  const { data, refetch } = useGetHostelById(selectedHostelId);
  const [tableData, setTableData] = useState(requestListData);
  const [gluAnnouncement, setGluAnnouncement] = useState("");

  useEffect(() => {
    if (requestListData?.getRequestByHostelId?.length) {
      setTableData(requestListData?.getRequestByHostelId);
    }
  }, [requestListData]);

  const [arrRequestObject, setArrRequestObject] = useState([]);

  useEffect(() => {
    let newArr = [];
    tableData?.forEach((i) => {
      let updateObject = {
        request: i._id,
        hostel: selectedHostelId,
        isActive: i?.isActive,
        fromTime: i?.fromTime,
        toTime: i?.toTime,
        tat: i?.tat,
      };
      newArr.push(updateObject);
      setArrRequestObject(newArr);
    });
  }, [selectedHostelId, tableData]);

  const handleAddRequest = (value, item) => {
    try {
      const updatedArray = arrRequestObject.map((i) => {
        if (i.request === item?._id) {
          i.isActive = value;
        }
        return i;
      });
      setArrRequestObject(updatedArray);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTimeRequest = (value, time, item) => {
    try {
      const updatedArray = arrRequestObject.map((i) => {
        if (i.request === item?._id) {
          if (time == "fromTime") {
            i.fromTime = moment(value, "HH:mm").format(); //value;
          } else if (time == "toTime") {
            i.toTime = moment(value, "HH:mm").format(); //value;
          }
        }
        return i;
      });
      setArrRequestObject(updatedArray);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (createHostelRequestData) {
      toaster("success", `Hostel request saved`);
    }
  }, [createHostelRequestData]);

  const handleCreateHostelRequest = (requestParams) => {
    const testObj = {
      hostelRequestInput: requestParams,
    };
    createHostelRequestHandler(testObj);
  };

  const [checkedAllStatus, setCheckedAllStatus] = useState(false);

  const handleGluAnouncement = () => {
    const data = {
      gluAnnouncement,
    };
    if (gluAnnouncement) {
      updateHostelHandler(selectedHostelId, data)
      toaster("success", "announcement added succeffully")
    } else {
      toaster("error", "please fill glu anouncement")

    }
  }

  useEffect(() => {
    if (data?.getHostelById?.gluAnnouncement) {
      setGluAnnouncement(data?.getHostelById?.gluAnnouncement)
    } else {
      setGluAnnouncement("")
    }
  }, [data])

  return (
    <>
      {tableData && (
        <>
          <Row>
            <Col>
              <Col>
                <Row>
                  <FormGroup className="w-50">
                    <Label className="form-label" htmlFor="gluAnnouncement">
                      Glu Announcement
                    </Label>

                    <Input
                      type="text"
                      value={gluAnnouncement}
                      id="gluAnnouncement"
                      name="gluAnnouncement"
                      placeholder="Glu Announcement"
                      onChange={(e) => setGluAnnouncement(e.target.value)}
                    />
                  </FormGroup>
                  <Button
                    style={{
                      backgroundColor: "#ffe700",
                      height: "40px",
                      marginTop: "22px",
                      marginLeft: "20px",
                      borderRadius: "5px",
                      padding: "10px 30px",
                      outline: "none",
                      border: "0",
                    }}
                    color="white"
                    appearance="primary"
                    type="button"
                    onClick={handleGluAnouncement}
                  >
                    Save
                  </Button>
                </Row>
              </Col>
              <Card>
                <Table responsive>
                  <thead>
                    <tr>
                      {tableHeaderData?.map((item) => {
                        return <th key={uuidv4()}>{item}</th>;
                      })}
                      <th>
                        <SwitchIcon
                          checked={checkedAllStatus}
                          handleChecked={(value) => {
                            const updatedArray = arrRequestObject.map((i) => {
                              i.isActive = value;
                              return i;
                            });
                            setCheckedAllStatus(value);
                            setArrRequestObject(updatedArray);
                          }}
                          id={`custom-checkbox}}`}
                        />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {tableData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.requestName}</td>
                          <td>{item?.requestCategory}</td>
                          <td>
                            <input
                              type="time"
                              id="fromTime"
                              name="fromTime"
                              min="06:00"
                              max="23:00"
                              onChange={(e) => {
                                handleTimeRequest(
                                  e.target.value,
                                  "fromTime",
                                  item
                                );
                              }}
                              required
                              defaultValue={
                                arrRequestObject?.find(
                                  (r) => r?.request === item?._id
                                )?.fromTime &&
                                moment(
                                  arrRequestObject?.find(
                                    (r) => r?.request === item?._id
                                  )?.fromTime
                                ).format("HH:mm")
                              }
                            />
                          </td>

                          <td>
                            <input
                              type="time"
                              id="toTime"
                              name="toTime"
                              min="06:00"
                              max="23:00"
                              onChange={(e) => {
                                handleTimeRequest(
                                  e.target.value,
                                  "toTime",
                                  item
                                );
                              }}
                              required
                              defaultValue={
                                arrRequestObject?.find(
                                  (r) => r?.request === item?._id
                                )?.toTime &&
                                moment(
                                  arrRequestObject?.find(
                                    (r) => r?.request === item?._id
                                  )?.toTime
                                ).format("HH:mm")
                              }
                            />
                          </td>

                          {/* hostel TAT */}
                          <td>
                            <input
                              type="text"
                              value={
                                arrRequestObject?.find(
                                  (r) => r.request === item._id
                                )?.tat || ""
                              }
                              onChange={(e) => {
                                const updatedArray = arrRequestObject.map(
                                  (i) => {
                                    if (i.request === item._id) {
                                      i.tat = parseInt(e.target.value) || 0;
                                    }
                                    return i;
                                  }
                                );
                                setArrRequestObject(updatedArray);
                              }}
                              style={{ width: "60px" }}
                            />
                          </td>

                          <td>
                            <SwitchIcon
                              checked={
                                arrRequestObject?.length > 0
                                  ? arrRequestObject.find(
                                    (r) => r.request === item._id
                                  ).isActive
                                  : item.isActive
                              }
                              id={`custom-checkbox-${index}}}`}
                              name={"select" + index}
                              handleChecked={(value) => {
                                handleAddRequest(value, item);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          <Button
            color="light"
            className="float-right"
            onClick={() => {
              handleCreateHostelRequest(arrRequestObject);
            }}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
};

export default HostelRequestedDataTable;
