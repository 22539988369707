import React, { useEffect, useState } from 'react'
import MerchandiseProductTable from './MerchandiseProductTable'
import AddEditModal from './AddEditModal'
import { useGetAllMerchandiseProducts } from '../../../api/MerchandiseStore/Queries';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MerchandiseProducts = () => {
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState("");
    const [editData, setEditData] = useState(null);
    const [tableData, setTableData] = useState([])
    const history = useHistory();

    const { data: merchandiseProducts, refetch: merchandiseProductRefetch } =
        useGetAllMerchandiseProducts();
    const toggleModal = () => {
        setOpenModal(!openModal);
        setId(null);
        setEditData(null);
    };
    useEffect(() => {
        if (merchandiseProducts?.getAllMerchandiseProducts?.length > 0) {
            setTableData(merchandiseProducts?.getAllMerchandiseProducts)
        }
    }, [merchandiseProducts])

    const handleCreateUpdateMerchandiseProducts = (id) => {
        history.push(`/merchandise/edit/merchandiseProduct/${id}`);
    };

    useEffect(() => {
        if (merchandiseProducts?.getAllMerchandiseProducts) {
            let editData = merchandiseProducts?.getAllMerchandiseProducts?.find((item) => item._id === id)
            setEditData(editData);
        } else {
            setId(null);
            setEditData(null);
        }
    }, [id])
    return (
        localStorage.getItem("modules")?.includes("Merchandise Store") && (

            <>
                <MerchandiseProductTable
                    openModal={() => setOpenModal(!openModal)}
                    tableData={tableData}
                    setId={setId}
                    handleCreateUpdateMerchandiseProducts={handleCreateUpdateMerchandiseProducts}
                />
                <AddEditModal
                    open={openModal}
                    handleToggle={toggleModal}
                    refetch={merchandiseProductRefetch}
                    editData={editData}
                />
            </>
        ))
}

export default MerchandiseProducts