import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const GET_TIMEWISE_SALES_REPORT = gql`
  query getTimewiseOrderSales($fromDate: Date, $toDate: Date, $cafe: [ID]) {
    getTimewiseOrderSales(fromDate: $fromDate, toDate: $toDate, cafe: $cafe) {
      _id
      hourRangeData {
        hour
        totalOrderCount
        totalOrderAmount
        totalNetSales
      }
      cafeInfo {
        cafeName
      }
    }
  }
`;

export const useGetTimewiseSalesReport = (fromDate, toDate, cafe) => {
  const { loading, error, data, refetch } = useQuery(
    GET_TIMEWISE_SALES_REPORT,
    {
      variables: { fromDate, toDate, cafe },
      skip: !fromDate || !toDate,
    }
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_DAYWISE_SALES_REPORT = gql`
  query getDaywiseSales($fromDate: Date, $toDate: Date, $cafe: [ID]) {
    getDaywiseSales(fromDate: $fromDate, toDate: $toDate, cafe: $cafe) {
      _id
      dayRangeData {
        date
        totalOrderCount
        totalOrderAmount
        totalNetSales
      }
      cafeInfo {
        cafeName
      }
    }
  }
`;

export const useGetDaywiseSalesReport = (fromDate, toDate, cafe) => {
  const { loading, error, data, refetch } = useQuery(GET_DAYWISE_SALES_REPORT, {
    variables: {
      fromDate,
      toDate,
      cafe,
    },
    skip: !fromDate || !toDate,
  });

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_MONTHWISE_SALES_REPORT = gql`
  query getMonthwiseSales(
    $fromDate: Date
    $toDate: Date
    $cafe: [ID]
    $filter: String
    $hostelId: [ID]
  ) {
    getMonthwiseSales(
      fromDate: $fromDate
      toDate: $toDate
      cafe: $cafe
      filter: $filter
      hostelId: $hostelId
    ) {
      pipelineOutput1
      dataType
      totalOverAllOrderCount
      totalOverAllSales
      totalTax
      totalNetSales
      totalDiscount
      avgOrderValue
    }
  }
`;

export const useGetMonthwiseSalesReport = (
  fromDate,
  toDate,
  cafe,
  filter,
  hostelId
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_MONTHWISE_SALES_REPORT,
    {
      variables: {
        fromDate,
        toDate,
        cafe,
        filter,
        hostelId,
      },
      skip: !fromDate || !toDate,
    }
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ITEMWISE_SALES_REPORT = gql`
  query getItemwiseSales(
    $fromDate: Date
    $toDate: Date
    $cafe: [ID]
    $value: String
  ) {
    getItemwiseSales(
      fromDate: $fromDate
      toDate: $toDate
      cafe: $cafe
      value: $value
    ) {
      _id
      quantitySold
      totalAmount
    }
  }
`;

export const useGetItemwiseSalesReport = (fromDate, toDate, cafe, value) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ITEMWISE_SALES_REPORT,
    {
      variables: {
        fromDate,
        toDate,
        cafe,
        value,
      },
      skip: !fromDate || !toDate,
    }
  );
  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_MONTHWISE_SALESREPORT_EXPORT = gql`
  query getMonthwiseSalesExportReport(
    $data: monthwiseSalesInput
    $monthArrayInput: [Int]
    $prop: String
  ) {
    getMonthwiseSalesExportReport(
      data: $data
      monthArrayInput: $monthArrayInput
      prop: $prop
    )
  }
`;

export const useGetMonthwiseSalesReportExport = (
  reportData,
  monthArrayInput,
  prop
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_MONTHWISE_SALESREPORT_EXPORT,
    {
      variables: {
        data: reportData,
        monthArrayInput: monthArrayInput,
        prop: prop,
      },
      skip: !reportData || !monthArrayInput,
    }
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_TIMEWISE_SALES_REPORT_EXPORT = gql`
  query getTimewiseSalesExportReport($timewise: [timewiseSalesInput]) {
    getTimewiseSalesExportReport(timewise: $timewise)
  }
`;

export const useGetTimewiseSalesReportExport = (reportData) => {
  const { loading, error, data, refetch } = useQuery(
    GET_TIMEWISE_SALES_REPORT_EXPORT,
    {
      variables: {
        timewise: reportData,
      },
      skip: !reportData,
    }
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_DAYWISE_SALES_EXPORT_REPORT = gql`
  query getDaywiseSalesExportReport(
    $daywise: [daywiseSalesInput]
    $dateArrayInput: [dateInput]
  ) {
    getDaywiseSalesExportReport(
      daywise: $daywise
      dateArrayInput: $dateArrayInput
    )
  }
`;

export const useGetDaywiseSalesReportExport = (reportData, dateList) => {
  const { loading, error, data, refetch } = useQuery(
    GET_DAYWISE_SALES_EXPORT_REPORT,
    {
      variables: {
        daywise: reportData,
        dateArrayInput: dateList,
      },
      skip: !reportData || !dateList,
    }
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ITEMWISE_SALES_EXPORT_REPORT = gql`
  query getItemwiseSalesExportReport($itemwise: [itemwiseSalesInput]) {
    getItemwiseSalesExportReport(itemwise: $itemwise)
  }
`;

export const useGetItemwiseSalesReportExport = (reportData) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ITEMWISE_SALES_EXPORT_REPORT,
    {
      variables: {
        itemwise: reportData,
      },
      skip: !reportData,
    }
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};
