import { Col, Card, Row } from "reactstrap";
import { Table } from "reactstrap";
import Header from "./Header";
import TripBody from "./TableBody/Product/TripList";
import TablePagination from "../TablePagination";
import HostelTableBody from "./TableBody/Product/HostelList";
import WorkationTableBody from "./TableBody/Product/WorkationList";
import SeoTableListBody from "./TableBody/Seo/SeoTableList";
import CancellationTableListBody from "./TableBody/Cancellation/CancellationTableList";
import DestinationTableBody from "./TableBody/Product/DestinationList";
import WhatsNewTableBody from "./TableBody/Product/WhatsNewList"; //! Added by Ashwin
import PressMentionTableBody from "./TableBody/Product/PressMentionList"; //! Added by Ashwin
import BlogCategoryTableBody from "./TableBody/Product/BlogCategoryList";
import BlogsBlogTableBody from "./TableBody/Product/BlogsBlogList";
import DisplayAdsTableBody from "./TableBody/Product/DisplayAdsList";
import MessageTemplateTableBody from "./TableBody/Product/MessageTemplateList";
import MessageConfigurationTableBody from "./TableBody/Product/MessageConfigurationList";
import TravelPackageTableBody from "./TableBody/Product/travelPackage";
import WebAppRequestsTableBody from "./TableBody/Product/WebAppRequestList";
import WebAppUserRequestTableBody from "./TableBody/Product/WebAppUserRequestList";
import WebAppEventsAndActivitiesTableBody from "./TableBody/Product/WebAppEventsAndActivitiesList";
import ReviewsLinksTableBody from "./TableBody/Product/ReviewsLinksList";
import { TABLE_TYPE } from "./constant";

const TableList = ({
  type,
  filterHeader,
  tableHeader,
  handleAdd,
  handleDelete,
  tableData,
  tableHeaderList,
  openModal,
  handleEdit,
  setPage,
  page,
  totalPage,
  headerAction,
  hostelList,
  selectedAction,
  isPackage,
  setShow,
  handleCreateUpdateBlogs,
  handleCreateUpdateEventsAndActivities,
  refetch
}) => {
  const getBody = () => {
    switch (type) {
      case TABLE_TYPE.TRIP:
        return (
          <TripBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        );
      case TABLE_TYPE.HOSTEL:
        return (
          <HostelTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        );
      case TABLE_TYPE.WORKATION:
        return (
          <WorkationTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            handleDelete={handleDelete}
          />
        );
      case TABLE_TYPE.SEO:
        return (
          <SeoTableListBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
          />
        );
      case TABLE_TYPE.CANCELLATIONS:
        return (
          <CancellationTableListBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.DESTINATION:
        return (
          <DestinationTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            // handleDelete={handleDelete}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.WHATSNEW:
        return (
          <WhatsNewTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            // handleDelete={handleDelete}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.PRESSMENTION:
        return (
          <PressMentionTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            refetch={refetch}
            // handleDelete={handleDelete}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.CATEGORY:
        return (
          <BlogCategoryTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            // handleDelete={handleDelete}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.BLOG:
        return (
          <BlogsBlogTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            handleDelete={handleDelete}
            handleCreateUpdateBlogs={handleCreateUpdateBlogs}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.DIAPLAYADS:
        return (
          <DisplayAdsTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.MESSAGE_TEMPLATE:
        return (
          <MessageTemplateTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            // handleDelete={handleDelete}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.MESSAGE_CONFIGURATION:
        return (
          <MessageConfigurationTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            // handleDelete={handleDelete}
          />
        );

      case TABLE_TYPE.TRAVEL_PACKAGE:
        return (
          <TravelPackageTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            // isPackage={isPackage}
            // setShow={setShow}
            // handleDelete={handleDelete}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.CONFIGURE_REQUESTS:
        return (
          <WebAppRequestsTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            // handleDelete={handleDelete}
          />
        );

      case TABLE_TYPE.USER_REQUESTS:
        return (
          <WebAppUserRequestTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            // handleDelete={handleDelete}
          />
        );

      // ! Added by Ashwin
      case TABLE_TYPE.EVENTS_AND_ACTIVITIES:
        return (
          <WebAppEventsAndActivitiesTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            isPackage={isPackage}
            setShow={setShow}
            handleDelete={handleDelete}
            handleCreateUpdateEventsAndActivities={
              handleCreateUpdateEventsAndActivities
            }
            refetch={refetch}
          />
        );

        case TABLE_TYPE.REVIEWS_LINKS:
        return (
          <ReviewsLinksTableBody
            tableRowData={tableData}
            openModal={openModal}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        );

      default:
        return null;
    }
  };

  const tableHeadStyle = {
    minWidth: "140px",
  };

  return (
    <Row>
      <Col>
        <Card>
          <Header
            handleAdd={handleAdd}
            filterHeader={filterHeader}
            tableHeader={tableHeader}
            type={type}
            handleEdit={handleEdit}
            headerAction={headerAction}
            hostelList={hostelList}
            selectedAction={selectedAction}
            // handleCreateUpdateBlogs={handleCreateUpdateBlogs}
          />
          <Table responsive size="sm">
            <thead>
              <tr>
                {tableHeaderList?.map((item) => (
                  <th key={item} style={tableHeadStyle}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{getBody()}</tbody>
          </Table>
          <TablePagination
            totalPage={totalPage}
            setPage={setPage}
            page={page}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TableList;
