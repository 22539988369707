// checkin

import { gql } from "@apollo/client";

export const CHECKIN = gql`
  mutation checkin($hostelId: ID!, $checkinArray: JSON!) {
    checkin(hostelId: $hostelId, checkinArray: $checkinArray)
  }
`;

export const CHECKOUT = gql`
  mutation checkout($hostelId: ID!, $checkoutArray: JSON!) {
    checkout(hostelId: $hostelId, checkoutArray: $checkoutArray)
  }
`;

export const ROOM_ASSIGN  = gql`
mutation room_assign($hostelId: ID! $roomData: JSON!) {
room_assign (hostelId: $hostelId roomData:$roomData)
}
`
