import gql from "graphql-tag";

export const GET_SEO_BY_ID = gql`
  query getSeoById($id: ID!) {
    getSeoById(id: $id) {
      _id
      hostelId
      h1Tags
      metaDesc
      url
      keywords
      productType
    }
  }
`;

export const GET_SEO_BY_TYPE = gql`
  query getAllSeo($filter: filter) {
    getAllSeo(filter: $filter) {
      data {
        _id
        hostelId {
          _id
          name
        }
        tripId {
          _id
          name
        }
        workationPackageId {
          _id
          name
        }
        h1Tags
        metaDesc
        url
        keywords
        productType
      }
      count
      page
      limit
    }
  }
`;
