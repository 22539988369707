import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const CREATE_UNBOXBANNER = gql`
mutation createUnboxCafeBanner($unboxBannerInput:unboxBannerInput){
    createUnboxCafeBanner(data:$unboxBannerInput){
        data{
            title
        }
        title
    }
}
`

export const DELETE_UNBOXBANNER = gql`
mutation deleteById($id:ID){
    deleteUnboxBanner(id:$id){
        message
    }
}
`

export const UPDATE_UNBOXBANNER = gql`
mutation updateUnboxCafeBanner($id:ID, $unboxBannerInput:unboxBannerInput){
    updateUnboxCafeBanner(id:$id,unboxBannerUpdate:$unboxBannerInput){
        title
    }
}
`

export const useCreateUnboxBanner = ()=>{
    const [createUnboxBanner, {data, refetch , loading, error}] = useMutation(CREATE_UNBOXBANNER);
    useLoadingHandler();

    const createUnboxBannerHandler = (unboxBannerInput)=>{
        createUnboxBanner({
            variables:{
                unboxBannerInput
            }
        })
    }

    return [createUnboxBannerHandler, {data, refetch, loading, error}];
}

export const useDeleteUnboxBanner = ()=>{
    const [deleteUnboxBanner, {data, refetch, loading, error}] = useMutation(DELETE_UNBOXBANNER);
    useLoadingHandler();

    const deleteUnboxBannerHandler = (id) => {
        deleteUnboxBanner({
            variables:{
                id
            }
        })
    }
    return [deleteUnboxBannerHandler, {data, refetch, loading, error}];
}

export const useUpdateUnboxBanner = ()=>{
    const [updateUnboxBanner, {data, refetch, loading, error}] = useMutation(UPDATE_UNBOXBANNER);
    useLoadingHandler();
    
    const updateUnboxBannerHandler = (id, unboxBannerInput)=>{
        updateUnboxBanner({
            variables:{
                id, 
                unboxBannerInput
            }
        })
    }

    return [updateUnboxBannerHandler, {data, refetch, loading, error}];
}