const formatDate = (date) => {
  const options = { weekday: "short", day: "2-digit", month: "short" };
  return date.toLocaleDateString("en-US", options);
};

export function convertDateStringToDetailedFormat(dateString) {
  if (!dateString) return null;

  // Create a Date object from the dateString
  const date = new Date(dateString);

  // If the dateString is in the past, return an error message
  if (isNaN(date)) {
    return "Invalid date";
  }

  // Get the current date and time to set the time part of the date object
  const currentTime = new Date();
  date.setHours(currentTime.getHours());
  date.setMinutes(currentTime.getMinutes());
  date.setSeconds(currentTime.getSeconds());
  date.setMilliseconds(currentTime.getMilliseconds());

  // Return the detailed date string
  return date.toString();
}

export const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const year = new Date().getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return `${day}-${month}-${year}`;
};

export const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);
  const year = new Date().getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return `${year}-${month}-${day}`;
};

export const generateDateRange = (startDate, endDate = null) => {
  const dateArray = [];
  const currentDate = new Date(startDate);

  if (!endDate) {
    // Generate the next 7 dates if no end date is provided
    for (let i = 0; i < 7; i++) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  } else {
    // Generate the date range between startDate and endDate
    const finalDate = new Date(endDate);
    while (currentDate <= finalDate) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  return dateArray;
};

export const getDatesInRange = (startDate, endDate) => {
  if (!startDate || !endDate) return;
  const date = new Date(startDate.getTime());
  const dates = [];
  while (date <= endDate) {
    const formattedDate = formatDate(new Date(date));
    dates.push(formattedDate);
    date.setDate(date.getDate() + 1);
  }
  return dates;
};
