import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useUpdateStepOutLocation,
  useGetAllStepOutLocationList,
} from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import { v4 as uuidv4 } from "uuid";
import { Trash } from "react-feather";
import Select from "react-select";
import confirmDelete from "@components/common/confirmDelete";

const Locations = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const { data: allLocations } = useGetAllStepOutLocationList();

  const [updateStepOutLocationHandler, { data }] = useUpdateStepOutLocation();

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData) {
      setSelectedLocations(
        state?.tableData?.locations?.map((item) => {
          return {
            uuId: uuidv4(),
            locations: {
              label: item?.title,
              value: item?._id,
            },
          };
        })
      );
    }
  }, [state]);

  const handleAddLocations = () =>
    setSelectedLocations([
      ...selectedLocations,
      {
        uuId: uuidv4(),
        locations: [],
      },
    ]);

  const handleRemoveSelectedLocations = (uuId) =>
    confirmDelete(() =>
      setSelectedLocations(
        selectedLocations.filter((item) => item.uuId !== uuId)
      )
    );

  const handleChangeSelectedLocations = (locations, uuId) => {
    setSelectedLocations(
      selectedLocations?.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              locations: {
                label: locations.label,
                value: locations.value,
              },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let LocationsList = [];

    if (allLocations?.getAllStepOutLocation?.length) {
      allLocations?.getAllStepOutLocation?.map((item) => {
        LocationsList.push({ label: item?.title, value: item?._id });
        setLocationsList(LocationsList);
      });
    }
  }, [allLocations?.getAllStepOutLocation]);

  const [finalLocationsArray, setFinalLocationsArray] = useState([]);

  useEffect(() => {
    if (data) {
      let updatedLocation = [];
      finalLocationsArray.forEach((i) => {
        updatedLocation.push(
          allLocations?.getAllStepOutLocation?.find((j) => j?._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          locations: updatedLocation,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allLocations?.getAllStepOutLocation,
    dispatch,
    finalLocationsArray,
    data,
  ]);
  const submitDataHandler = (e) => {
    e.preventDefault();
    var finalListOfLocationsId = [];
    selectedLocations?.forEach((item) => {
      finalListOfLocationsId.push(item.locations.value);
    });
    setFinalLocationsArray(finalListOfLocationsId);
    let data = {
      locations: finalListOfLocationsId,
    };

    if (data?.locations?.length > 0) {
      if (
        state?.tableData?._id &&
        data?.locations?.every((location) => location !== undefined)
      ) {
        updateStepOutLocationHandler(id, data);
        toaster("success", "Locations updated successfully");

        dispatch(
          tableDataHandler({
            ...state?.tableData,
            ...data,
          })
        );
      } else {
        toaster("error", "Please select the location");
      }
    } else {
      toaster("error", "Please select at least one location");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row className="mt-2">
        {selectedLocations?.map(({ uuId, locations }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={uuId}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"locations" + uuId}>
                Selected locations {index + 1}
              </Label>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Select
                      isSearchable={true}
                      id={uuId}
                      name="locations"
                      value={locations}
                      options={locationsList}
                      onChange={(value) => {
                        handleChangeSelectedLocations(value, uuId);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {selectedLocations.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveSelectedLocations(uuId)}
                >
                  <Trash size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddLocations} />
    </form>
  );
};

export default Locations;
