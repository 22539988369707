import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, CustomInput } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import { useUpdateEzeeMeals } from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [hostelName, setHostelName] = useState("");
  const [ReservationNo, setReservationNo] = useState("");
  const [ArrivalDate, setArrivalDate] = useState(new Date());
  const [DepartureDate, setDepartureDate] = useState(new Date());
  const [Room, setRoom] = useState("");
  const [RatePlan, setRatePlan] = useState("");
  const [RoomNo, setRoomNo] = useState("");
  const [GuestName, setGuestName] = useState("");
  const [mealAddedInEzeeCheck, setMealAddedInEzeeCheck] = useState(false);

  const [updateEzeeMealsHandler, { data: updatedEzeeMealsData }] =
    useUpdateEzeeMeals();

  useEffect(() => {
    if (updatedEzeeMealsData) {
      refetch();
      handleToggle();

      toaster("success", "Ezee meal updated successfully");
    }
  }, [updatedEzeeMealsData]);

  const submitDataHandler = () => {
    let data = {
      mealAddedInEzee: mealAddedInEzeeCheck,
    };

    if (data) {
      if (editData?._id) {
        updateEzeeMealsHandler(editData._id, data);
      }

      setMealAddedInEzeeCheck(false);
    }
  };

  useEffect(() => {
    if (editData?._id) {
      setMealAddedInEzeeCheck(editData?.mealAddedInEzee);
      setHostelName(editData?.hostelName);
      setReservationNo(editData?.ReservationNo);
      setArrivalDate(editData?.ArrivalDate);
      setDepartureDate(editData?.DepartureDate);
      setRoom(editData?.Room);
      setRatePlan(editData?.RatePlan);
      setRoomNo(editData?.RoomNo);
      setGuestName(editData?.GuestName);
    } else {
      setMealAddedInEzeeCheck(false);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostelName">
                Hostel Name
              </Label>

              <Input
                type="text"
                value={hostelName}
                id="hostelName"
                name="hostelName"
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="ReservationNo">
                Reservation No.
              </Label>

              <Input
                type="text"
                value={ReservationNo}
                id="ReservationNo"
                name="ReservationNo"
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="ArrivalDate">
                Checkin Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={ArrivalDate}
                onChange={(date) =>
                  setArrivalDate(dateFormatter(new Date(date)))
                }
                id="ArrivalDate"
                options={{
                  dateFormat: "d-m-Y",
                  maxDate: new Date(),
                }}
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="DepartureDate">
                Checkout Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={DepartureDate}
                onChange={(date) =>
                  setDepartureDate(dateFormatter(new Date(date)))
                }
                id="DepartureDate"
                options={{
                  dateFormat: "d-m-Y",
                  maxDate: new Date(),
                }}
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="Room">
                Room
              </Label>

              <Input type="text" value={Room} id="Room" name="Room" disabled />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="RatePlan">
                Rate Plan
              </Label>

              <Input
                type="text"
                value={RatePlan}
                id="RatePlan"
                name="RatePlan"
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="RoomNo">
                Room No.
              </Label>

              <Input
                type="text"
                value={RoomNo}
                id="RoomNo"
                name="RoomNo"
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="GuestName">
                Guest Name
              </Label>

              <Input
                type="text"
                value={GuestName}
                id="GuestName"
                name="GuestName"
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <CustomInput
                type="checkbox"
                id="mealAddedInEzeeCheck"
                name="user account"
                value={mealAddedInEzeeCheck}
                label="Meal added in ezee?"
                className="custom-control-Primary"
                onChange={(e) => setMealAddedInEzeeCheck(e.target.checked)}
                checked={mealAddedInEzeeCheck}
              />
              {mealAddedInEzeeCheck && (
                <i className="fa fa-check text-success ml-1" />
              )}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
