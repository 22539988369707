import React, { useEffect, useState } from "react";
import DailyBookingsTable from "./DailyBookingsTable";
import { useGetDailyBookings } from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { CSVLink } from "react-csv";
import { dateFormatter } from "@utils";
import { useHostelList } from "../../../api/dataSource";
import { selectThemeColors } from "@utils";
import Select from "react-select";
import { dateValidator } from "../../../utility/Utils";
import toaster from "@components/common/Toaster";

const tableHeaderData = [
  { label: "Guest Name", key: "GuestName" },
  { label: "Reservation Id", key: "ReservationNo" },
  { label: "Booking Date", key: "ReservationDate" },
  { label: "Checkin Date", key: "ArrivalDate" },
  { label: "Checkout Date", key: "DepartureDate" },
  { label: "Folio No", key: "FolioNo" },
  { label: "Hostel Name", key: "hostelName" },
  { label: "Booking Source", key: "Source" },
  { label: "Room No", key: "RoomNo" },
  { label: "Room Charges(Rs)", key: "TotalExclusivTax" },
  { label: "Tax(Rs)", key: "TotalTax" },
  { label: "Net(Rs)", key: "TotalInclusiveTax" },
  { label: "Other Revenue", key: "OtherRevenueExclusiveTax" },
  { label: "Other Revenue(Tax)", key: "OtherRevenueInclusiveTax" },
];

const DailyBookings = () => {
  const [tableData, setTableData] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useHostelList();
  // const [limit, setLimit] = useState(20);
  // const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [searchFromDate, setSearchFromDate] = useState(dateFormatter(new Date()));
  const [searchToDate, setSearchToDate] = useState(dateFormatter(new Date()));
  const [searchHostel, setSearchHostel] = useState([])
  const { data, refetch } = useGetDailyBookings(
    searchFromDate,
    searchToDate,
    searchHostel.length > 0 ? searchHostel.map((hostel) => hostel.value) : null
  );
  // const [totalPage, setTotalPage] = useState(1);

  // useEffect(() => {
  //   refetch();
  // }, [page]);

  // useEffect(() => {
  //   refetch();
  // }, [hostel]);

  useEffect(() => {
    if (data?.getDailyEzeeBookings?.data) {
      setTableData(data?.getDailyEzeeBookings?.data);
    } else {
      setTableData([]);
    }

    // setTotalPage(Math.ceil(data?.getDailyEzeeBookings?.count / limit));
  }, [data]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const handleSearch = ()=>{
    if(dateValidator(fromDate, toDate)){
      setSearchFromDate(fromDate)
      setSearchToDate(toDate)
      setSearchHostel(hostel)
    }else{
      toaster("error", "Invalid Dates")
    }
  }
  
  const handleResetData = ()=>{
    setTableData([])
    setSearchFromDate(dateFormatter(new Date()))
    setSearchToDate(dateFormatter(new Date()))
    setFromDate(dateFormatter(new Date()))
    setToDate(dateFormatter(new Date()))
    setHostel([])
    setSearchHostel([])
  }

  return (
    localStorage.getItem("modules")?.includes("Reports") &&  <div>
      {/* <Row>
      <Col sm="1">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              Rooms Sold: <b>{data?.getDailyEzeeBookings?.count}</b>
            </Label>
          </FormGroup>
          
        </Col>
      </Row> */}
      <Row>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              From Date *
            </Label>

            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromDate}
              onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
              id="fromDate"
              placeholder="From Date"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="toDate">
              To Date *
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toDate}
              onChange={(date) => setToDate(dateFormatter(new Date(date)))}
              id="toDate"
              placeholder="To Date"
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              Hostel*
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              options={HostelsList}
              onChange={(value) => {
                setHostel(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{marginBottom:"10px"}}>
        <Col>
        <Button.Ripple
              onClick={handleSearch}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft:"10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="primary"
              appearance="danger"
              type="button"
            >
              Search
            </Button.Ripple>
            <Button.Ripple
              onClick={handleResetData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft:"10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button.Ripple>

        <CSVLink
            headers={tableHeaderData}
            data={tableData}
            filename={"Bookings_report.csv"}
          >
            <Button.Ripple
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft:"10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="success"
              appearance="success"
              type="button"
            >
              Export
            </Button.Ripple>
          </CSVLink>
        </Col>
      </Row>
      {tableData.length >0 ? <DailyBookingsTable data={tableData} refetch={refetch} totalCount={data?.getDailyEzeeBookings?.count} />:<h2 style={{textAlign:"center", marginTop:"150px"}}>No Record Found</h2>}
      
      {/* <TablePagination setPage={setPage} page={page} totalPage={totalPage} /> */}
    </div>
  );
};

export default DailyBookings;
