export const holidayList = [
  {
    label: "19/09/2023",
    value: new Date("2023-09-19").toDateString("en-CA"),
  },
  {
    label: "02/10/2023",
    value: new Date("2023-10-02").toDateString("en-CA"),
  },
  {
    label: "24/10/2023",
    value: new Date("2023-10-24").toDateString("en-CA"),
  },
  {
    label: "14/11/2023",
    value: new Date("2023-11-14").toDateString("en-CA"),
  },
  {
    label: "25/12/2023",
    value: new Date("2023-12-25").toDateString("en-CA"),
  },
  {
    label: "26/01/2024",
    value: new Date("2024-01-26").toDateString("en-CA"),
  },
  {
    label: "25/03/2024",
    value: new Date("2024-03-25").toDateString("en-CA"),
  },
  {
    label: "29/03/2024",
    value: new Date("2024-03-29").toDateString("en-CA"),
  },
  {
    label: "09/04/2024",
    value: new Date("2024-04-09").toDateString("en-CA"),
  },
  {
    label: "11/04/2024",
    value: new Date("2024-04-11").toDateString("en-CA"),
  },
  {
    label: "01/05/2024",
    value: new Date("2024-05-01").toDateString("en-CA"),
  },
  {
    label: "17/06/2024",
    value: new Date("2024-06-17").toDateString("en-CA"),
  },
  {
    label: "15/08/2024",
    value: new Date("2024-08-15").toDateString("en-CA"),
  },
  {
    label: "07/09/2024",
    value: new Date("2024-09-07").toDateString("en-CA"),
  },
  {
    label: "02/10/2024",
    value: new Date("2024-10-02").toDateString("en-CA"),
  },
  {
    label: "12/10/2024",
    value: new Date("2024-10-12").toDateString("en-CA"),
  },
  {
    label: "01/11/2024",
    value: new Date("2024-11-01").toDateString("en-CA"),
  },
  {
    label: "02/11/2024",
    value: new Date("2024-11-02").toDateString("en-CA"),
  },
  {
    label: "25/12/2024",
    value: new Date("2024-12-25").toDateString("en-CA"),
  },
];

// Now the holidayList includes both sets of events
