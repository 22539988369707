import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUploadImage, useUpdateOffersPage, useGetOffersPage } from "@api";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

const OffersHome = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const { data } = useGetOffersPage();

  const [
    updateOffersPageHandler,
    { data: updateOffersPage, reset: resetOfferPage },
  ] = useUpdateOffersPage();

  const [uploadImageHandler, { loading: imageLoad, data: imageData }] =
    useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 1000000,
    },
  });

  useEffect(() => {
    let prePopulateData;

    if (data?.getOffersPage) {
      prePopulateData = data?.getOffersPage;
    }

    if (prePopulateData) {
      setTitle(prePopulateData?.title);
      setDescription(prePopulateData?.description);
      setImagePreview({ type: "existing", url: prePopulateData?.image });
    }
  }, [data?.getOffersPage]);

  useEffect(() => {
    if (state.tableData?.title) {
      setTitle(state.tableData.title);
      setDescription(state.tableData.description);
      setImagePreview({ type: "existing", url: state.tableData.image });
    }
  }, [state.tableData]);

  useEffect(() => {
    if (updateOffersPage?.updateOffersPage) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          title,
          description,
          image: imagePreview?.url,
        })
      );

      resetOfferPage();
    }
  }, [updateOffersPage?.updateOffersPage]);

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setFormData([...formData, new File([preview[0]], "filename")]);
    setImagePreview({ type: "new", url: preview });
    uploadImageHandler(file.data, `offers`);
  });

  const renderPreview = () =>
    imagePreview ? (
      <img
        className='rounded mr-1 mb-2 w-50'
        src={imagePreview?.url}
        alt='avatar'
      />
    ) : null;

  const submitDataHandler = (e) => {
    e.preventDefault();

    if (title && description) {
      const data = {
        title,
        description,
      };

      imagePreview.type === "new" && (data.image = imageData?.uploadImage?.url);

      updateOffersPageHandler(data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className='mt-2' onSubmit={submitDataHandler}>
      <Row>
        <Col sm='6' className='mb-2'>
          <Label className='form-label' htmlFor='location'>
            Card Image
          </Label>
          <DragDrop
            uppy={uppy}
            width='100%'
            note='Max size 1MB'
            locale={{
              strings: {
                dropHereOr: "Drop image here or %{browse}",
                browse: "browse",
              },
            }}
          />
        </Col>
        <Col sm='6'>
          <Label className='form-label w-100'>Image preview here</Label>
          {imageLoad ? "Uploading image..." : renderPreview()}
        </Col>
        <Col sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='title'>
              Title
            </Label>

            <Input
              type='text'
              value={title}
              id='title'
              name='title'
              placeholder='Title'
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='description'>
              Description
            </Label>

            <Input
              type='textarea'
              value={description}
              id='description'
              name='description'
              placeholder='Description'
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup loading={imageLoad} />
    </form>
  );
};

export default OffersHome;
