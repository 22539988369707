import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useUpdateStepOutLocation,
  useGetAllStepOutCategoryList,
} from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import { v4 as uuidv4 } from "uuid";
import { Trash } from "react-feather";
import Select from "react-select";
import confirmDelete from "@components/common/confirmDelete";

const Category = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const { data: allCategories } = useGetAllStepOutCategoryList();

  const [updateStepOutLocationHandler, { data }] = useUpdateStepOutLocation();

  const state = useSelector((state) => state?.cms);
  useEffect(() => {
    if (state?.tableData) {
      setTitle(state?.tableData?.flavour?.title);
      setHighlightedText(state?.tableData?.flavour?.highlightedText);
      setSelectedCategories(
        state?.tableData?.category?.map((item) => {
          return {
            uuId: uuidv4(),
            category: { label: item?.name, value: item?._id },
          };
        })
      );
    }
  }, [state]);

  const handleAddCategories = () =>
    setSelectedCategories([
      ...selectedCategories,
      {
        uuId: uuidv4(),
        category: [],
      },
    ]);

  const handleRemoveSelectedCategories = (uuId) =>
    confirmDelete(() =>
      setSelectedCategories(
        selectedCategories.filter((item) => item.uuId !== uuId)
      )
    );

  const handleChangeSelectedCategories = (category, uuId) => {
    setSelectedCategories(
      selectedCategories?.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              category: {
                label: category.label,
                value: category.value,
              },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let CategoriesList = [];

    if (allCategories?.getAllStepOutCategory?.length) {
      allCategories?.getAllStepOutCategory?.map((item) => {
        CategoriesList.push({ label: item?.name, value: item?._id });
        setCategoriesList(CategoriesList);
      });
    }
  }, [allCategories?.getAllStepOutCategory]);

  const [finalCategoriesArray, setFinalCategoriesArray] = useState([]);

  useEffect(() => {
    if (data) {
      let updatedCategory = [];
      finalCategoriesArray.forEach((i) => {
        updatedCategory.push(
          allCategories?.getAllStepOutCategory?.find((j) => j?._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          category: updatedCategory,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allCategories?.getAllStepOutCategory,
    dispatch,
    finalCategoriesArray,
    data,
  ]);
  const submitDataHandler = (e) => {
    e.preventDefault();
    var finalListOfCategoriesId = [];
    selectedCategories?.forEach((item) => {
      finalListOfCategoriesId.push(item.category.value);
    });
    setFinalCategoriesArray(finalListOfCategoriesId);
    let data = {
      flavour: {
        title,
        highlightedText,
      },
      category: finalListOfCategoriesId,
    };

    if (data?.category?.length > 0) {
      if (
        state?.tableData?._id &&
        data?.flavour?.title &&
        data?.flavour?.highlightedText &&
        data?.category?.every((hostel) => hostel !== undefined)
      ) {
        updateStepOutLocationHandler(id, data);
        toaster("success", "Category updated successfully");

        dispatch(
          tableDataHandler({
            ...state?.tableData,
            ...data,
          })
        );
      } else {
        toaster("error", "Please fill the details and select category");
      }
    } else {
      toaster("error", "Please select at least one category");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <Label className="form-label" for="flavour">
            <strong>Flavour</strong>
          </Label>
          <Col sm="12">
            <Row>
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="title">
                    Title
                  </Label>

                  <Input
                    type="text"
                    value={title}
                    id="title"
                    name="title"
                    placeholder="Add title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="highlightedText">
                    Highlighted text
                  </Label>

                  <Input
                    type="text"
                    value={highlightedText}
                    id="highlightedText"
                    name="highlightedText"
                    placeholder="Add highlighted text"
                    onChange={(e) => setHighlightedText(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>

      <hr style={{ border: "1px solid #ffe700" }} />

      <Row className="mt-2">
        {selectedCategories?.map(({ uuId, category }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={uuId}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"category" + uuId}>
                Selected category {index + 1}
              </Label>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Select
                      isSearchable={true}
                      id={uuId}
                      name="category"
                      value={category}
                      options={categoriesList}
                      onChange={(value) => {
                        handleChangeSelectedCategories(value, uuId);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {selectedCategories.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveSelectedCategories(uuId)}
                >
                  <Trash size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddCategories} />
    </form>
  );
};

export default Category;
