// ! Coded by Ashwin

import gql from "graphql-tag";

export const GET_EVENT_BOOKINGS = gql`
  query {
    getEventBookings {
      amount
      event {
        _id
        title
        eventDate
      }
      hostel {
        _id
        name
      }
      paymentStatus
      quantity
      razorpayOrderId
      reservationId
      tax
      totalAmount
    }
  }
`;

export const GET_PAGINATED_EVENT_BOOKINGS = gql`
  query ($filter: filter) {
    getPaginatedEventBookings(filter: $filter) {
      count
      limit
      page
      totalRevenue
      data {
        _id
        reservationId
        quantity
        amount
        hostel
        tax
        totalAmount
        paymentStatus
        razorpayOrderId
        razorpayPaymentId
        eventDate
        bookingId
        bookingDate
        cancellationDate
        cancellationRemark
        cancellationAmount
        cancelledBy
        refundId
        refundDate
        refundStatus
        invoiceNo
        status
        remark
        logs
        rating
        createdAt
        guestDetails {
          GuestName
          Mobile
        }
        eventDetails {
          time
          price
          category
          title
        }
        hostelDetails {
          _id
          name
        }
        title
        category
        event
      }
    }
  }
`;

export const GET_ALL_EVENT_BOOKINGS_FOR_EXPORT = gql`
  query ($filter: filter) {
    getAllEventBookingsForExport(filter: $filter) {
      data {
        _id
        reservationId
        quantity
        amount
        hostel
        tax
        totalAmount
        paymentStatus
        razorpayOrderId
        razorpayPaymentId
        eventDate
        bookingId
        bookingDate
        cancellationDate
        cancellationRemark
        cancellationAmount
        cancelledBy
        refundId
        refundDate
        refundStatus
        invoiceNo
        status
        remark
        logs
        rating
        createdAt
        guestDetails {
          GuestName
          Mobile
        }
        eventDetails {
          time
        }
        hostelDetails {
          _id
          name
        }
        title
        category
      }
    }
  }
`;

//added by nitish - for event invoice download
export const GET_EVENTS_INOVOICE_ON_SUCCESS = gql`
  query getEventsInvoiceOnSuccess($bookingId: String!) {
    getEventsInvoiceOnSuccess(bookingId: $bookingId) {
      legalEntityName
      locationName
      locationAddress
      gstin
      invoiceNo
      invoiceDate
      customerName
      stateNamePlaceOfSupply
      hsnCode
      serviceDescription
      paymentRefId
      price
      tax
      taxRate
      quantity
      totalPrice
      event
    }
  }
`;
