import { useEffect, useState } from "react";
import { Eye } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "@utils";

const tableHeaderData = [
    "Hostel Name",
    "Messages"
];


const WhatsappMessagesTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length>0) {
      setTableData(data);
    }
    else {
      setTableData([])
    }
  }, [data]);
  
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>WA Message Delivery Report</CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })} 
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item?._id }</td>
                    {/* {
                    console.log( item.messages.forEach(element => {
                    if (element.type==="BookingConfirmationMessage" ) {
                      // console.log(element.count)
                      var count = element.count
                      //  <td>element.count</td>
                      return count
                    } 
                    // <td>count</td>     
                  }))
                  } */}
                  <td>
                  {item.messages.map((message, index)=>{
                      
                      return(
                        <>
                        <tr mb="2" key={index}>{message.type}{" : "}{message.count} </tr>
                        
                        </>
                      )
                       
                      
                       
                  })}
                  </td>
                  {/* <hr sm="6" mt="-1"/> */}
                  
                </tr>  
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default WhatsappMessagesTable;