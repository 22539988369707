import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import {
  useAddWebAppRequest,
  useUpdateWebAppRequest,
  useUploadImage,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import Select from "react-select";
import { DragDrop } from "@uppy/react";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [requestCategory, setRequestCategory] = useState(null);
  const [requestName, setRequestName] = useState("");
  const [requestDescription, setRequestDescription] = useState("");
  // const [tat, setTat] = useState("");
  const [maxAllowedRequest, setMaxAllowedRequest] = useState("");

  const [imagePreview, setImagePreview] = useState(null);
  const [logs, setLogs] = useState(editData?.logs ? editData?.logs : []);
  const [
    uploadImageHandler,
    { data: imageData, reset: imageReset, loading: imageLoad },
  ] = useUploadImage();

  const [addWebAppRequestHandler, { data }] = useAddWebAppRequest();
  const [updateWebAppRequestHandler, { data: updatedWebAppRequestData }] =
    useUpdateWebAppRequest();

  const RequestCategoryList = [
    { label: "Housekeeping", value: "Housekeeping" },
    { label: "Maintenance", value: "Maintenance" },
    { label: "Booking", value: "Booking" },
    { label: "Other requests", value: "Other requests" },
  ];

  // useEffect(() => {
  //   if (data?.createRequests?.requestCategory || updatedWebAppRequestData) {
  //     refetch();
  //     handleToggle();
  //   }
  // }, [data, updatedWebAppRequestData]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 1048576,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setImagePreview([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "icons/Request");
    toaster("success", "Request image uploaded successfully");
  });

  const renderPreview = () =>
    imagePreview ? (
      <img className="rounded mr-1 mb-2 w-50" src={imagePreview} alt="avatar" />
    ) : null;

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading image: ${error.message}`);
  });

  useEffect(() => {
    setImagePreview(imageData?.uploadImage?.url);
  }, [imageData]);

  const submitDataHandler = () => {
    let data = {
      requestCategory: requestCategory?.value,
      requestName,
      requestDescription,
      // tat: parseInt(tat),
      maxAllowedRequest: parseInt(maxAllowedRequest),
      requestIcon: imagePreview,
      logs: [
        ...logs,
        `${localStorage.getItem("name")} ${
          editData ? "modified" : "created"
        } the master request configuration on ${new Date().toLocaleString(
          "en-IN",
          {
            hour12: false,
          }
        )}`,
      ],
    };

    if (data.requestCategory) {
      if (editData?.length) {
        const { _id } = editData[0];

        updateWebAppRequestHandler(_id, data);
        toaster("success", "Request updated successfully");
      } else {
        addWebAppRequestHandler(data);
        toaster("success", "Request added successfully");
      }

      setRequestCategory(null);
      setRequestDescription("");
      setRequestName("");
      setImagePreview(null);
      // setTat("");
      setMaxAllowedRequest("");
      setLogs([]);
      refetch();
      handleToggle();
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  useEffect(() => {
    if (editData?.length) {
      setRequestCategory(
        editData[0]?.requestCategory !== null
          ? {
              label: editData[0]?.requestCategory,
              value: editData[0]?.requestCategory,
            }
          : null
      );
      setRequestName(
        editData[0]?.requestName !== null ? editData[0]?.requestName : ""
      );
      setRequestDescription(
        editData[0]?.requestDescription !== null
          ? editData[0]?.requestDescription
          : ""
      );
      // setTat(editData[0]?.tat !== null ? editData[0]?.tat : "");
      setImagePreview(
        editData[0]?.requestIcon !== null ? editData[0]?.requestIcon : null
      );
      setMaxAllowedRequest(
        editData[0]?.maxAllowedRequest !== null
          ? editData[0]?.maxAllowedRequest
          : ""
      );
      setLogs(editData[0]?.logs ? editData[0]?.logs : []);
    } else {
      setRequestCategory(null);
      setRequestName("");
      setRequestDescription("");
      // setTat("");
      setImagePreview(null);
      setMaxAllowedRequest("");
      setLogs([]);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Request Category */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestCategory">
                Request Category*
              </Label>
              <Select
                isClearable={false}
                id="requestCategory"
                name="requestCategory"
                value={requestCategory}
                options={RequestCategoryList}
                required
                onChange={(value) => setRequestCategory(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Request Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestName">
                Request Name
              </Label>
              <Input
                type="text"
                value={requestName}
                id="requestName"
                name="requestName"
                placeholder="Add Request Name"
                onChange={(e) => setRequestName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Request Description */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestDescription">
                Request Description
              </Label>

              <Input
                type="text"
                value={requestDescription}
                id="requestDescription"
                name="requestDescription"
                placeholder="Add Request Description"
                onChange={(e) => setRequestDescription(e.target.value)}
              />

              {/* <br /> */}
            </FormGroup>
          </Col>

          {/* // ! TAT */}
          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="tat">
                Completion Time (in minutes)
              </Label>
              <Input
                
                type="text"
                value={tat}
                id="tat"
                name="tat"
                placeholder="Add TAT"
                onChange={(e) => setTat(e.target.value)}
              />
            </FormGroup>
          </Col> */}

          {/* // ! Max Allowed Request */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="maxAllowedRequest">
                Max Allowed Request (per day/user)
              </Label>

              <Input
                type="text"
                min="0"
                value={maxAllowedRequest}
                id="maxAllowedRequest"
                name="maxAllowedRequest"
                placeholder="Add Max Allowed Request"
                onChange={(e) => setMaxAllowedRequest(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Image */}
          {/* <Col sm="12">
            <Label className="form-label">Upload Image</Label>
            <DragDrop
              note="Image size should be less than 1MB"
              width="100%"
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: "Drop image here or %{browse}",
                  browse: "browse",
                },
              }}
            />
            {imageLoad ? "Uploading image..." : renderPreview()}
          </Col> */}

          <Col
            sm="12"
            style={{
              marginTop: "20px",
              marginBottom: "50px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "50%" }}>
              <Label className="form-label">Upload Image</Label>
              <DragDrop
                note="Image size should be less than 1MB"
                width="100%"
                uppy={uppy}
                locale={{
                  strings: {
                    dropHereOr: "Drop image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
            </div>

            <div style={{ width: "50%", marginLeft: "10px" }}>
              {/* <Label className="form-label">Image Preview</Label> */}
              <div
                className="service-icon-preview"
                style={{
                  width: "400px",
                  marginTop: "50px",
                }}
              >
                {imageLoad ? "Uploading icon..." : <>{renderPreview()}</>}
              </div>
            </div>
          </Col>

          <Col sm="12">
            {/* <hr/> */}
            <FormGroup className="w-100">
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>
              <br />
              {logs
                ?.map((log, i) => {
                  return (
                    <Label className="form-label" for="name">
                      {/* <p >{log}.</p> */}
                      {log}.
                    </Label>
                  );
                })
                .reverse()}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
