import React, { useState, useEffect } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import SlideModal from "@components/common/slide-modal";
import {
  useCreateUser,
  useUpdateUser,
  useHostelList,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import { dateFormatHandler } from "@utils";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const [createUserHandler, { data: createUserData }] = useCreateUser();
  const [updateUserHandler, { data: updateUserData }] = useUpdateUser();

  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    if (createUserData?.createUser?.fullName || updateUserData) {
      refetch();
      handleToggle();

      // toaster("success", "User added successfully");
    }
  }, [createUserData, updateUserData]);

  useEffect(() => {
    if (editData?._id) {
      setFullName(editData?.fullName);
      setEmail(editData?.email);
      setMobile(editData?.mobile);
    } else {
      setFullName("");
      setEmail("");
      setMobile("");
    }
  }, [editData]);

  const hostelName = (hostelId) => {
    return allHostelsData?.getHostelList?.map((hostel) => {
      if (hostel?._id === hostelId) {
        return hostel?.name;
      }
    });
  };

  const userBookingTotalAmount = (item) => {
    let sum = 0;
    if (item && item.length > 0) {
      sum = item.reduce((acc, booking) => {
        if (booking && !isNaN(booking.totalAmount)) {
          return acc + booking.totalAmount;
        } else {
          return acc;
        }
      }, 0);
    }
    return sum.toFixed(2);
  };

  const onSubmitHandler = () => {
    const data = {
      fullName,
      email,
      mobile,
    };

    if (data?.fullName && data?.email && data?.mobile) {
      if (editData?._id) {
        const id = editData?._id;
        updateUserHandler(id, data);
        toaster("success", "User updated successfully");
      } else {
        createUserHandler(data);
        toaster("success", "User added successfully");
      }

      setFullName("");
      setEmail("");
      setMobile("");
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="email">
                  Email*
                </Label>
              </strong>
              <Input
                type="email"
                value={email}
                id="email"
                name="email"
                placeholder="Add Email"
                onChange={(e) => setEmail(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="fullName">
                  Full Name*
                </Label>
              </strong>
              <Input
                type="text"
                value={fullName}
                id="fullName"
                name="fullName"
                placeholder="Add Full Name"
                onChange={(e) => setFullName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="mobile">
                  Mobile Number*
                </Label>
              </strong>
              <Input
                type="mobile"
                value={mobile}
                id="mobile"
                name="mobile"
                placeholder="Add Mobile Number"
                onChange={(e) => setMobile(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="created">
                  Signup Date - {dateFormatHandler(editData?.createdAt)}
                </Label>
              </strong>
            </FormGroup>
          </Col>

          {editData?.UserBookings && (
            <>
              <Col sm="12">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="UserBookingsCount">
                      Total User Bookings -{" "}
                      {editData?.UserBookingsCount
                        ? editData?.UserBookingsCount
                        : "NA"}
                    </Label>
                  </strong>
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="totalAmount">
                      Total Booking Amount - ₹{" "}
                      {userBookingTotalAmount(editData?.UserBookings)}
                    </Label>
                  </strong>
                </FormGroup>
              </Col>

              {editData?.UserBookings?.map((item, index) => {
                return (
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="index">
                          Booking {index + 1}
                        </Label>
                      </strong>
                    </FormGroup>

                    <Col sm="12">
                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="providerRefId">
                            Reservation Id -{" "}
                            {item?.providerRefId ? item?.providerRefId : "NA"}
                          </Label>
                        </strong>
                      </FormGroup>

                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="hostelId">
                            Hostel -{" "}
                            {item?.hostelId ? hostelName(item?.hostelId) : "NA"}{" "}
                          </Label>
                        </strong>
                      </FormGroup>

                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="checkinDate">
                            Checkin Date -{" "}
                            {item?.checkinDate
                              ? dateFormatHandler(item?.checkinDate)
                              : "NA"}
                          </Label>
                        </strong>
                      </FormGroup>

                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="checkoutDate">
                            Checkout Date -{" "}
                            {item?.checkoutDate
                              ? dateFormatHandler(item?.checkoutDate)
                              : "NA"}
                          </Label>
                        </strong>
                      </FormGroup>

                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="totalAmount">
                            Total Amount -{" "}
                            {item?.totalAmount
                              ? `₹ ${item?.totalAmount}`
                              : "NA"}
                          </Label>
                        </strong>
                      </FormGroup>

                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="createdAt">
                            Booking Date -{" "}
                            {item?.createdAt
                              ? dateFormatHandler(item?.createdAt)
                              : "NA"}
                          </Label>
                        </strong>
                      </FormGroup>
                    </Col>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
