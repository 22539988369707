import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import {
  Check,
  Edit,
  X,
  CheckSquare,
  Trash,
  Trash2,
  Info,
} from "react-feather";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";
const userRole = localStorage.getItem("role");

const originalTableHeaderData = [
  "Action",
  "Delete",
  "Hostel Name",
  "Review Date",
  "Name",
  "Reservation ID",
  "Rating",
  "Sentiment",
  "Source",
  "Review",
  "Ticket Status",
  "Ticket Number",
  "Justified?",
];

const tableHeaderData =
  userRole === "Super Admin"
    ? originalTableHeaderData
    : originalTableHeaderData.filter((header) => header !== "Delete");

const bookingSourceImages = {
  "Booking.com":
    "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg",
  Goibibo:
    "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg",
  HostelWorld:
    "https://static.thehosteller.com/Hostel/Media/hostelworld%202%20(1)-1695295577721.svg",
  Google:
    "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg",
  MakeMyTrip:
    "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg",
  Tripadvisor:
    "https://static.thehosteller.com/Hostel/Media/Tripadvisor%20(1)-1695295571996.svg",
  Airbnb:
    "https://static.thehosteller.com/Hostel/Media/image-1725440828248.svg",
};

const getSentimentColor = (sentiment) => {
  switch (sentiment) {
    case "Positive":
      return "success";
    case "Neutral":
      return "warning";
    case "Negative":
      return "danger";
    default:
      return "secondary";
  }
};

const LegendItem = ({ icon, label, color }) => (
  <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
    {icon}
    <span style={{ marginLeft: "5px" }}>{label}</span>
    {color && (
      <Badge color={color} style={{ marginLeft: "5px" }}>
        {label}
      </Badge>
    )}
  </div>
);

const ReviewsTable = ({
  openModal,
  setId,
  data,
  openAddReviewModal,
  exportData,
  headers,
  dataCount,
  deleteOrmReviewHandler,
}) => {
  return (
    <div>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <LegendItem
          icon={<CheckSquare size={20} style={{ color: "#FF4500" }} />}
          label="Ticket Closed - CX"
        />
        <span style={{ margin: "0 5px" }}>|</span>
        <LegendItem
          icon={<Check size={20} style={{ color: "#FF4500" }} />}
          label="Ticket Closed - GO"
        />
        <span style={{ margin: "0 5px" }}>|</span>
        <LegendItem icon={<Check size={20} />} label="Ticket Created" />
        <span style={{ margin: "0 5px" }}>|</span>
        <LegendItem icon={<X size={20} />} label="Not Created" />
      </div>

      <div style={{ textAlign: "right", marginTop: "10px" }}>
        <strong>
          <div style={{ textAlign: "right", fontSize: "12px" }}>
            <Info size={16} /> Reviews are synced twice daily: 8:30 AM & 3:30
            PM. Auto-replies to positive reviews: 9:15 AM & 4:15 PM.
          </div>
        </strong>
      </div>

      <div style={{ overflowX: "auto", overflowY: "auto" }}>
        <div
          style={{
            overflowX: "auto",
            maxHeight: "500px",
            marginBottom: "20px",
          }}
        >
          <Card>
            <CardHeader>
              <CardTitle>{dataCount} Reviews</CardTitle>
              <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  openAddReviewModal();
                }}
              >
                Add Review
              </Button.Ripple>
            </CardHeader>
          </Card>
          <Table>
            <thead
              className="text-center"
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              {" "}
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => {
                // const reviewDate = new Date(item?.reviewDate);

                return (
                  <tr key={index}>
                    <td>
                      <Button
                        href="/"
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    {userRole === "Super Admin" && (
                      <td>
                        <Button
                          onClick={() => {
                            deleteOrmReviewHandler(item?._id);
                          }}
                          color="none"
                        >
                          <Trash2
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>
                    )}
                    <td>{item?.hostel?.name}</td>
                    <td>
                      {new Date(item?.reviewDate).toLocaleDateString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}
                    </td>
                    <td>{item?.guestName}</td>
                    <td>{item?.reservationId}</td>
                    <td>
                      <strong>
                        {item?.rating} / {item?.outOfRating}
                      </strong>
                    </td>

                    <td>
                      <Badge color={getSentimentColor(item?.sentiment)}>
                        {item?.sentiment}
                      </Badge>
                    </td>

                    <td>
                      {item?.bookingSource &&
                      bookingSourceImages[item.bookingSource] ? (
                        <img
                          src={bookingSourceImages[item.bookingSource]}
                          alt={item.bookingSource}
                          width={
                            ["Tripadvisor", "HostelWorld"].includes(
                              item.bookingSource
                            )
                              ? "90"
                              : "75"
                          }
                          height={
                            ["Tripadvisor", "HostelWorld", "Airbnb"].includes(
                              item.bookingSource
                            )
                              ? "45"
                              : "25"
                          }
                        />
                      ) : (
                        <span>{item?.bookingSource}</span>
                      )}
                    </td>

                    <td>{item?.review}</td>

                    <td>
                      <span
                        title={
                          item?.hostelFeedbackDetails &&
                          item?.cxDetails &&
                          item?.ticketDetails
                            ? "Ticket Closed - CX"
                            : item?.hostelFeedbackDetails && item?.ticketDetails
                            ? "Ticket Closed - GO"
                            : item?.ticketDetails
                            ? "Ticket Created"
                            : "Not Created"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {item?.hostelFeedbackDetails &&
                        item?.cxDetails &&
                        item?.ticketDetails ? (
                          <CheckSquare size={20} style={{ color: "#FF4500" }} />
                        ) : item?.hostelFeedbackDetails &&
                          item?.ticketDetails ? (
                          <Check size={20} style={{ color: "#FF4500" }} />
                        ) : item?.ticketDetails ? (
                          <Check size={20} />
                        ) : (
                          <X size={20} />
                        )}
                      </span>
                    </td>
                    <td>{item?.cxTicketNumber}</td>
                    <td>{item?.cxDetails?.Justified}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ReviewsTable;
