// ** React Imports
import { Link } from "react-router-dom";

// ** Custom Components
import Avatar from "@components/common/avatar";

// ** Store & Actions
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

// ** Third Party Components
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import {
  User,
  Mail,
  CheckSquare,
  MessageSquare,
  Settings,
  CreditCard,
  HelpCircle,
  Power,
  Edit3,
} from "react-feather";
// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg";
import { selectCurrentUser } from "@store/user/user.selector";
import { userLogout } from "../../../../redux/user/user.action";
import { adminIcon } from "../../../../utility/constant";
import { useState } from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import UserProfile from "../../../../router/routes/UserProfile";
// import { DefaultRoute } from "../../../../router/routes/index"

const UserDropdown = () => {
  const state = useSelector((state) => state);

  //** Vars
  const userAvatar = defaultAvatar;

  const [setModal, setShowModal] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name font-weight-bold">
            {state?.user?.details?.userDetails?.fullName}
          </span>
          <span className="user-status">
            {state?.user?.details?.userDetails?.role}
          </span>
        </div>
        <Avatar img={adminIcon} imgHeight="30" imgWidth="30" />
      </DropdownToggle>
      <DropdownMenu right>
        {/* <DropdownItem  onClick={() => }>
          <User size={14} className='mr-75' />
          <span className='align-middle'>Profile</span>
        </DropdownItem> */}
        <DropdownItem onClick={() => setShowModal(true)}>
          <Edit3 size={14} className="mr-75" />
          <span className="align-middle">Change Password</span>
          {setModal && <ChangePasswordModal setShowModal={setShowModal} />}
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to="/login"
          onClick={() => {
            userLogout();
            localStorage.removeItem("email");
            localStorage.removeItem("role");
            localStorage.removeItem("name");
            localStorage.removeItem("department");
            localStorage.removeItem("modules");
            localStorage.removeItem("hostels");
          }}
        >
          <Power size={14} className="mr-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps)(UserDropdown);

// <DropdownItem tag={Link} to='/pages/profile'>
//   <User size={14} className='mr-75' />
//   <span className='align-middle'>Profile</span>
// </DropdownItem>
// <DropdownItem tag={Link} to='/apps/email'>
//   <Mail size={14} className='mr-75' />
//   <span className='align-middle'>Inbox</span>
// </DropdownItem>
// <DropdownItem tag={Link} to='/apps/todo'>
//   <CheckSquare size={14} className='mr-75' />
//   <span className='align-middle'>Tasks</span>
// </DropdownItem>
// <DropdownItem tag={Link} to='/apps/chat'>
//   <MessageSquare size={14} className='mr-75' />
//   <span className='align-middle'>Chats</span>
// </DropdownItem>
// <DropdownItem divider />
// <DropdownItem tag={Link} to='/pages/account-settings'>
//   <Settings size={14} className='mr-75' />
//   <span className='align-middle'>Settings</span>
// </DropdownItem>
// <DropdownItem tag={Link} to='/pages/pricing'>
//   <CreditCard size={14} className='mr-75' />
//   <span className='align-middle'>Pricing</span>
// </DropdownItem>
// <DropdownItem tag={Link} to='/pages/faq'>
//   <HelpCircle size={14} className='mr-75' />
//   <span className='align-middle'>FAQ</span>
// </DropdownItem>
