import gql from "graphql-tag";

export const GET_PAGINATED_USER_LIST = gql`
  query getPaginatedUserList($filter: filter) {
    getPaginatedUserList(filter: $filter) {
      data {
        _id
        fullName
        email
        mobile
        password
      }
      count
      page
      limit
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($id: ID) {
    getUserById(id: $id) {
      _id
      fullName
      email
      mobile
      password
    }
  }
`;

export const GET_PAGINATED_USERS_BOOKINGS_LIST = gql`
  query getUsersAndBookings($filter: filter) {
    getUsersAndBookings(filter: $filter) {
      data {
        _id
        fullName
        email
        mobile
        password
        UserBookings
        UserBookingsCount
        createdAt
      }
      count
      page
      limit
    }
  }
`;
