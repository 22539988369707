import gql from "graphql-tag";

export const GET_CANCEL_CONFIG_BY_ID = gql`
  query getCancelConfig($id: ID!) {
    getCancelConfig(id: $id) {
      _id
      type
      message
      status
      minDay
      maxDay
      deduction
    }
  }
`;

export const GET_ALL_CANCEL_CONFIG = gql`
  query getAllCancelConfig($filter: filter) {
    getAllCancelConfig(filter: $filter) {
      data {
        _id
        type
        message
        status
        minDay
        maxDay
        deduction
        usedCount
      }
      count
      page
      limit
    }
  }
`;
