// !Added by Ashwin

import React from "react";
import { Edit } from "react-feather";
import { Button } from "reactstrap";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";

const MessageTemplateTableBody = ({ tableRowData, openModal, handleEdit }) => {
  return (
    <>
      {tableRowData.map(({ TemplateName, TemplateType, id }) => (
        <tr key={id}>
          <td style={{ width: "5%" }}>
            <Button
              className="rounded-circle btn-icon"
              color="none"
              onClick={(e) => {
                e.preventDefault();
                openModal();
                handleEdit(id);
              }}
            >
              <Edit
                color={reactFeatherIcon.iconColor}
                size={reactFeatherIcon.iconSize}
              />
            </Button>
          </td>
          <td>
            <span className="align-middle font-weight-bold">
              {TemplateName}
            </span>
          </td>
          <td>
            <span className="align-middle font-weight-bold">
              {TemplateType}
            </span>
          </td>
        </tr>
      ))}
    </>
  );
};

export default MessageTemplateTableBody;
