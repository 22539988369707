import gql from "graphql-tag";

export const GET_GUEST_DATABASE = gql`
  query getGuestDatabase($filter: filter) {
    getGuestDatabase(filter: $filter) {
      GuestName
      Email
      Mobile
    }
  }
`;

export const GET_GUEST_DETAILS_WITH_SAME_NUMBER_OR_EMAIL = gql`
  query getGuestDetailsWithSameNumberOrEmail($filter: filter) {
    getGuestDetailsWithSameNumberOrEmail(filter: $filter) {
      GuestName
      ReservationNo
      Email
      Mobile
      uniqueId
      hostelName
      Source
      ArrivalDate
      ArrivalTime
      DepartureDate
      DepartureTime
      TotalInclusiveTax
      TotalExclusivTax
      Status
    }
  }
`;
