import gql from "graphql-tag";

export const CREATE_CANCEL_CONFIG = gql`
  mutation createCancelConfig($CancelConfigInput: CancelConfigInput) {
    createCancelConfig(CancelConfigInput: $CancelConfigInput) {
      success
      message
    }
  }
`;

export const UPDATE_CANCEL_CONFIG = gql`
  mutation updateCancelConfig(
    $id: ID!
    $CancelConfigUpdate: CancelConfigInput
  ) {
    updateCancelConfig(id: $id, CancelConfigUpdate: $CancelConfigUpdate) {
      _id
    }
  }
`;
