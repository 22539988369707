import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Table,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { dateFormatHandler } from "@utils";

const tableHeaderData = [
  "Guest Name",
  "Mobile No",
  "Hostel Name",
  "Reservation Id",
  "Purchased date",
  "Checkin Date",
  // "Room Name",
  "Add On",
  "Quantity",
  "Amount",
  "Payment Ref Id",
];

const AddOnTable = ({ data }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>ADD ONS</CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData?.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item?.guestName}</td>
                  <td>
                    <Badge color="light-secondary">{item?.mobile}</Badge>
                  </td>
                  <td>{item?.hostelName}</td>
                  <td>
                    <Badge color="light-info">
                      {item?.reservationId ? item?.reservationId : "--"}
                    </Badge>
                  </td>
                  <td>
                    {item?.purchasedDate
                      ? dateFormatHandler(item?.purchasedDate)
                      : dateFormatHandler(item?.createdAt)}
                  </td>
                  <td>{dateFormatHandler(item?.checkinDate)}</td>
                  <td>{item?.extraCharges?.charge}</td>
                  <td>{item?.extraCharges?.qty}</td>
                  <td>{item?.extraCharges?.rate}</td>
                  <td>
                    <Badge color="light-secondary">
                      {item?.razorpayPaymentId}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default AddOnTable;
