import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
// import TableList from "@components/common/TableList";
import TableList from "../common/TableList";
import { useGetAllMessageTemplateList } from "../../../api/dataSource";
import { TABLE_TYPE } from "../common/TableList/constant";

const tableHeaderList = ["Action", "Template Name", "Template Type"];

const MessageTemplateTable = ({ AddEditModal }) => {
  // const [limit, setLimit] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  // const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useGetAllMessageTemplateList();

  const history = useHistory();

  const handleAdd = () => {
    setOpenModal(true);
  };

  // useEffect(() => {
  //   refetch();
  // }, [page]);

  useEffect(() => {
    if (data) {
      let MessageTemplateList = [];
      data?.getAllMessageTemplateList?.map((item) => {
        MessageTemplateList.push({
          id: item?._id,
          TemplateName: item?.TemplateName,
          TemplateType: item?.TemplateType,
        });
      });

      setTableData(MessageTemplateList);
      // setTotalPage(Math.ceil(data?.getPaginatedBlogCategoryList?.count / limit));
    }
  }, [data]);
  useEffect(() => {
    if (editId && data?.getAllMessageTemplateList?.length) {
      let editData = data?.getAllMessageTemplateList?.filter(
        (item) => item._id === editId
      );
      setEditData(editData);
    } else {
      setEditData(null);
    }
  }, [editId, data]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/cms/edit/hostel/${id}`);
    } else {
      setEditId(id);
    }
  };

  return (
    <div>
      <TableList
        handleAdd={AddEditModal ? handleAdd : null}
        // filterHeader="All WA Templates"
        tableHeader="WA Templates"
        type={TABLE_TYPE.MESSAGE_TEMPLATE}
        tableData={tableData}
        tableHeaderList={tableHeaderList}
        openModal={() => setOpenModal(!openModal)}
        handleEdit={handleEdit}
        // handleDelete={AddEditModal ? handleDelete : null}
        setPage={setPage}
        // page={page}
        // totalPage={totalPage}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={() => setOpenModal(!openModal)}
          refetch={refetch}
          editData={editData}
          setEditId={setEditId}
        />
      )}
    </div>
  );
};

export default MessageTemplateTable;
