import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const GET_PAGINATED_CAFE_MASTER = gql`
  query GetPaginatedCafeMaster($filter: filter) {
    getPaginatedCafeMaster(filter: $filter) {
      data {
        _id
        hostel {
          _id
          name
        }
        cafeName
        cafeActiveDays
        cafeTimings {
          fromTime
          toTime
        }
        active
      }
      count
      limit
      page
    }
  }
`;

export const useGetPaginatedCafeMaster = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_CAFE_MASTER,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { error, data, refetch };
};

export const GET_ALL_CAFE_MASTER_LIST = gql`
  query getAllCafeMasterList {
    getAllCafeMasterList {
      _id
      cafeName
      cafeActiveDays
      hostel {
        _id
        name
      }
      cafeTimings {
        fromTime
        toTime
      }
      active
    }
  }
`;

export const useGetAllCafeMasterList = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_CAFE_MASTER_LIST);

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_CAFE_MASTER_BY_ID = gql`
  query GetCafeMasterById($id: ID) {
    getCafeMasterById(id: $id) {
      _id
      hostel {
        _id
        name
      }
      cafeName
      cafeActiveDays
      cafeTimings {
        _id
        fromTime
        toTime
      }
      active
    }
  }
`;

export const useGetCafeMasterById = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_CAFE_MASTER_BY_ID, {
    variables: {
      id,
    },
  });

  useLoadingHandler(loading);

  return { error, data, refetch };
};

export const GET_CAFE_BY_HOSTEL_ID = gql`
  query GetCafeByHostelId($hostelId: [ID]) {
    getCafeByHostelId(hostelId: $hostelId) {
      _id
      cafeName
    }
  }
`;

export const useGetCafeByHostelId = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(GET_CAFE_BY_HOSTEL_ID, {
    variables: {
      hostelId,
    },
    skip: !hostelId ? true : false,
  });

  useLoadingHandler(loading);

  return { error, data, refetch };
};
