import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const SearchDataTable = ({ tableData, duration, fromDate, toDate }) => {
  const [calendar, setCalendar] = useState([]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const generateCalendarDates = (fromDate, toDate) => {
    const calendarDates = [];
    let currentDate = new Date(fromDate);
    while (currentDate <= new Date(toDate)) {
      calendarDates.push(
        `${days[currentDate.getDay()]}-${String(currentDate.getDate()).padStart(
          2,
          "0"
        )}-${months[currentDate.getMonth()]}`
      );
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return calendarDates;
  };

  useEffect(() => {
    setCalendar(generateCalendarDates(fromDate, toDate));
  }, [duration]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <div>
              <h2> Hostel Search Frequency/Demand Report</h2>
            </div>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                <th style={{ position: "sticky", left: 0, zIndex: 1 }}>
                  Hostels
                </th>
                {calendar.map((date, index) => {
                  const [day, dateOfMonth, month] = date.split("-");
                  const totalSearches = tableData.reduce((total, hostel) => {
                    const searchData = hostel?.data?.find((search) => {
                      const searchDate = new Date(search.date);
                      return (
                        `${days[searchDate.getDay()]}-${String(
                          searchDate.getDate()
                        ).padStart(2, "0")}-${
                          months[searchDate.getMonth()]
                        }` === date
                      );
                    });
                    return total + (searchData ? searchData.searches : 0);
                  }, 0);
                  return (
                    <th key={index}>
                      <div>{day}</div>
                      <div>
                        {dateOfMonth}-{month}
                      </div>
                      <div>Searches:{totalSearches}</div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((hostel, index) => (
                <tr key={index}>
                  <td
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      fontWeight: "500",
                      backdropFilter: "blur(100px)",
                    }}
                  >
                    {hostel?.hostelName}
                  </td>
                  {calendar.map((date, index) => {
                    const searchData = hostel.data.find((search) => {
                      const searchDate = new Date(search.date);
                      return (
                        `${days[searchDate.getDay()]}-${String(
                          searchDate.getDate()
                        ).padStart(2, "0")}-${
                          months[searchDate.getMonth()]
                        }` === date
                      );
                    });
                    return (
                      <td
                        key={index}
                        title={
                          searchData
                            ? `${searchData.searches} searches on ${
                                new Date(searchData.date)
                                  .toISOString()
                                  .split("T")[0]
                              }`
                            : "0 searches"
                        }
                        style={{
                          fontWeight:
                            searchData && searchData.searches > 20
                              ? "800"
                              : "500",
                        }}
                      >
                        {searchData ? searchData.searches : 0}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default SearchDataTable;
