import { gql } from "@apollo/client";

export const ADD_STEP_OUT_BLOG_CATEGORY_DATA = gql`
  mutation CreateStepOutBlogCategory(
    $StepOutBlogCategoryInput: StepOutBlogCategoryInput
  ) {
    createStepOutBlogCategory(
      StepOutBlogCategoryInput: $StepOutBlogCategoryInput
    ) {
      _id
      categoryName
      image
      url
      seo {
        metaTitle
        metaDesc
        keywords
      }
      status
    }
  }
`;

export const UPDATE_STEP_OUT_BLOG_CATEGORY_DATA = gql`
  mutation UpdateStepOutBlogCategory(
    $id: ID
    $StepOutBlogUpdate: StepOutBlogCategoryInput
  ) {
    updateStepOutBlogCategory(id: $id, StepOutBlogUpdate: $StepOutBlogUpdate) {
      _id
      categoryName
      image
      url
      seo {
        metaDesc
        metaTitle
        keywords
      }
      status
    }
  }
`;
