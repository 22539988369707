import React, { useEffect, useState } from "react";
import MembershipsTable from "./MembershipsTable";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { dateFormatHandler, dateFormatter } from "@utils";
// import { CSVLink } from "react-csv";
import {
  useHostelList,
  useGetPaginatedMemberShipsList,
} from "../../../api/dataSource";
import { Col, Row, Input, FormGroup, Label, Button, Form } from "reactstrap";
import TablePagination from "@components/common/TablePagination";
import { selectThemeColors } from "@utils";

const exportHeaders = [
  { label: "Id", key: "membershipId" },
  { label: "Type", key: "membershipType" },
  { label: "Reservation No", key: "reservationNo" },
  { label: "Name", key: "fullName" },
  { label: "Mobile", key: "mobile" },
  { label: "Email", key: "email" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Amount", key: "membershipAmount" },
  { label: "Discount Percent", key: "discountPercentage" },
  { label: "Cashback Percent", key: "cashbackPercentage" },
  { label: "Tax", key: "membershipTax" },
  { label: "Total Amount", key: "totalAmount" },
  { label: "Address", key: "address" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Country", key: "country" },
  { label: "Pincode", key: "pincode" },
  { label: "Travel Kit Send", key: "travelKitSend" },
];

const Memberships = () => {
  const [tableData, setTableData] = useState([]);
  //   const [date, setDate] = useState(
  //     dateFormatter(new Date())
  //   );
  //   const [hostelIds, setHostelIds] = useState([])
  //   const [HostelsList, setHostelsList] = useState("");
  //   const { data: allHostelsData } = useHostelList();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [membershipType, setMembershipType] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const [filteredData, setFilteredData] = useState({});
  const { data, loading, refetch } = useGetPaginatedMemberShipsList(
    limit,
    page,
    filteredData
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [membershipId, setMembershipId] = useState("");
  const [travelKitSend, setTravelKitSend] = useState(null);

  const MembershipTypeList = [
    { label: "WANDERER", value: "WANDERER" },
    { label: "EXPLORER", value: "EXPLORER" },
    { label: "VOYAGER", value: "VOYAGER" },
    { label: "FREE_EXPLORER", value: "FREE_EXPLORER" },
  ];

  const TravelKitSendList = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  useEffect(() => {
    refetch();
  }, [data]);

  //   useEffect(() => {
  //     refetch();
  //   }, [hostelIds]);

  //   useEffect(() => {
  //     refetch();
  //   }, [date]);

  // useEffect(() => {
  //   if (data?.getMemberships) {
  //     setTableData(data?.getMemberships);
  //   } else {
  //     setTableData([]);
  //   }
  // }, [data]);

  //   useEffect(() => {
  //     let HostelsList = [];
  //     if (allHostelsData?.getHostelList?.length) {
  //       allHostelsData?.getHostelList?.map((item) => {
  //         HostelsList.push({ label: item?.name, value: item?._id });
  //         setHostelsList(HostelsList);
  //       });
  //     }
  //   }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (data?.getPaginatedMembershipsList?.data.length > 0) {
      setTableData(data?.getPaginatedMembershipsList?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedMembershipsList?.count / limit));
  }, [
    data?.getPaginatedMembershipsList?.count,
    data?.getPaginatedMembershipsList?.data,
    limit,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      membershipType: membershipType?.value,
      startDate,
      endDate,
      fullName,
      email,
      mobile,
      membershipId,
      travelKitSend: travelKitSend?.value,
    };
    setFilteredData(data);
    setPage(1);
  };

  const handleReset = () => {
    setMembershipType(null);
    setFilteredData({});
    setStartDate(null);
    setEndDate(null);
    setFullName("");
    setEmail("");
    setMobile("");
    setMembershipId("");
    setTravelKitSend(null);
    setPage(1);
  };
  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        {/* <Form onSubmit={handleSubmit}> */}
        <Form>
          <Row>
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="membershipType">
                    Membership List
                  </Label>
                </strong>
                <Select
                  isSearchable={true}
                  isClearable={false}
                  // theme={selectThemeColors}
                  // isMulti
                  id="membershipType"
                  name="membershipType"
                  value={membershipType}
                  options={MembershipTypeList}
                  onChange={(value) => setMembershipType(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="endDate">
                    Membership date range
                  </Label>
                </strong>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  id="doj"
                  placeholder="Select membership date range"
                  options={{
                    mode: "range",
                    // ...options,
                  }}
                  value={[startDate, endDate]}
                  onChange={(date) => {
                    setStartDate(dateFormatter(date[0]));
                    setEndDate(dateFormatter(date[1]));
                  }}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="fullName">
                    Name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={fullName}
                  id="fullName"
                  name="fullName"
                  placeholder="Add Full Name"
                  onChange={(e) => setFullName(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="email">
                    Email
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={email}
                  id="email"
                  name="email"
                  placeholder="Add Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="mobile">
                    Mobile Number
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={mobile}
                  id="mobile"
                  name="mobile"
                  placeholder="Add Mobile Number"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="membershipId">
                    Membership Id
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={membershipId}
                  id="membershipId"
                  name="membershipId"
                  placeholder="Add Membership Id"
                  onChange={(e) => setMembershipId(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="travelKitSend">
                    Travel Kit Send
                  </Label>
                </strong>
                <Select
                  isSearchable={true}
                  isClearable={false}
                  // theme={selectThemeColors}
                  // isMulti
                  id="travelKitSend"
                  name="travelKitSend"
                  value={travelKitSend}
                  options={TravelKitSendList}
                  onChange={(value) => setTravelKitSend(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
            {/* <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="date">
                Select Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={date}
                onChange={(date)=>setDate(dateFormatter(new Date(date)))}
                id="date"
                placeholder="Date"
              />
            </FormGroup>
          </Col>
          <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              Hostel*
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostelIds}
              options={HostelsList}
              onChange={(value) => {
                setHostelIds(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>*/}
            {/* <Col sm="1">
              <Button.Ripple
                color="light"
                type="submit"
                style={{ marginTop: "21px" }}
              >
                Search
              </Button.Ripple>
            </Col> */}

            <Col sm="2" style={{ display: "flex" }}>
              <Button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginRight: "10px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="white"
                appearance="primary"
                type="button"
              >
                Search
              </Button>

              <Button
                onClick={handleReset}
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginLeft: "10px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="danger"
                appearance="danger"
                type="button"
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
        {loading ? (
          <h2 style={{ textAlign: "center", marginTop: "110px" }}>
            Please wait as this may take some time...
          </h2>
        ) : tableData.length > 0 ? (
          <>
            <MembershipsTable
              data={tableData}
              headers={exportHeaders}
              exportData={tableData}
              strainer={filteredData}
              refetch={refetch}
            />
            <TablePagination
              setPage={setPage}
              page={page}
              totalPage={totalPage}
            />
          </>
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "110px" }}>
            No Data Found
          </h2>
        )}
      </div>
    )
  );
};

export default Memberships;
