import React, { useEffect, useState } from "react";
import LoyaltyReportTable from "./LoyaltyReportTable";
import { useGetLoyaltyReport } from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import { Col, Row, Input, FormGroup, Label, Button, Form } from "reactstrap";

const LoyaltyReport = () => {
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState({});
  const [email, setEmail] = useState("");

  const { data, refetch } = useGetLoyaltyReport(limit, page, filteredData);

  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data?.getLoyaltyCoinsReport?.data) {
      setTableData(data?.getLoyaltyCoinsReport?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getLoyaltyCoinsReport?.count / limit));
  }, [data]);

  const handleSearch = () => {
    // e.preventDefault();
    const data = {
      email,
    };

    setFilteredData(data);
    setPage(1);
  };

  const handleReset = () => {
    setEmail("");
    setFilteredData({});
    setPage(1);
  };

  return (
    localStorage.getItem("modules")?.includes("Loyalty") && (
      <>
        {/* <Form onSubmit={handleSubmit}> */}
        <Row>
          <Col sm="8">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="email">
                  User Email
                </Label>
              </strong>
              <Input
                type="text"
                value={email}
                id="email"
                name="email"
                placeholder="Add user email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* <Col sm="1">
            <Button.Ripple
              color="light"
              type="submit"
              style={{ marginTop: "21px" }}
            >
              Search
            </Button.Ripple>
          </Col> */}

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>

            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        {/* </Form> */}
        <LoyaltyReportTable data={tableData} refetch={refetch} />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      </>
    )
  );
};

export default LoyaltyReport;
