import React, { useEffect, useState } from "react";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import SearchDataTable from "./SearchDataTable";
import { useSearchDataInRange } from "../../../api/dataSource";
import { CSVLink } from "react-csv";
import { dateValidator } from "../../../utility/Utils";
import toaster from "@components/common/Toaster";

const SearchReport = () => {
  const [getSearchDataHandler, { loading, data, error, refetch }] =
    useSearchDataInRange();
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [tableData, setTableData] = useState([]);
  const [invalidDuration, setInvalidDuration] = useState(false);
  const [duration, setDuration] = useState(0);
  const [displayTable, setDisplayTable] = useState(false);
  useEffect(() => {
    if (data) {
      setTableData(data.getCheckinData);
    }
  }, [data]);

  // const tableHeaderData = [
  //   { label: "id", key: "id" },
  //   { label: "name", key: "name" },
  //   { label: "addr1", key: "addr1"},
  //   { label: "addr2", key: "addr2"},
  //   { label: "city", key: "city"},
  //   { label: "province", key: "province" },
  //   { label: "postal_code", key: "postal_code" },
  //   { label: "country", key: "country" },
  //   { label: "latitude", key: "latitude" },
  //   { label: "longitude" ,key: "longitude" },
  //   { label: "category", key: "category" },
  //   { label: "hotel_brand", key: "hotel_brand" },
  //   { label: "phone", key: "phone" },
  //   { label: "star_rating", key: "star_rating"},
  //   {label: "alternate_hotel_id", key: "alternate_hotel_id"}
  // ];
  useEffect(()=>{
    getSearchDataHandler(fromDate,toDate);
  },[])

  const calculateDayDifference = () => {
    const Days =
      (new Date(toDate) - new Date(fromDate)) / (1000 * 60 * 60 * 24);
    setDuration(Days);
    if (Days > 31) {
      return false;
    }
    return true;
  };

  const submitHandler = async () => {
    if (calculateDayDifference()) {
      setTableData([])
      if(dateValidator(fromDate, toDate)){
        const result = await getSearchDataHandler(fromDate, toDate);
        setInvalidDuration(false);
        setDisplayTable(true);
      } else {
        toaster("error", "Invalid Dates")
      }
    } else {
      setInvalidDuration(true);
      setDisplayTable(false);
    }
  };

  const handleResetData = async ()=>{
    setFromDate(dateFormatter(new Date()));
    setToDate(dateFormatter(new Date()));
    await getSearchDataHandler(fromDate, toDate);
    setTableData([])
  }

  return (
    <div>
      <Row>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              From Date *
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromDate}
              onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
              id="fromDate"
            />
          </FormGroup>
        </Col>

        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              To Date *
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toDate}
              onChange={(date) => setToDate(dateFormatter(new Date(date)))}
              id="fromDate"
            />
          </FormGroup>
        </Col>

        {/* <Col sm="2">
          <Button.Ripple style={{ marginTop: "23px" }}               color="light"
onClick={submitHandler}>
            Search{" "}
          </Button.Ripple>

        </Col> */}

        <Col sm="6" style={{marginTop:"22px"}}>
        <Button.Ripple
              onClick={submitHandler}
              className="ml-2 w-25"
              color="primary"
            >
              Search
            </Button.Ripple>

            <Button.Ripple
              onClick={handleResetData}
              className="ml-2 w-25"
              color="danger"
            >
              Reset
            </Button.Ripple>
        </Col>

        {invalidDuration && (
          <Row style={{ width:"100%", display:"flex", alignItem:"center"}}>
          <Col>
            <p style={{ color: "red", marginTop: "28px" , textAlign:"center", fontSize:"20px"}}>
              Dates should be less than 31 days!
            </p>
          </Col>
          </Row>
        )}

{/* <CSVLink
            headers={tableHeaderData}
            data={tableData}
            filename={"Google_Hotel_Center_hotelList.csv"}
          >
            <Button.Ripple
              className="btn-icon mb-2 ml-1"
              color="light"
              type="button"
              size="sm"
            >
              Export
            </Button.Ripple>
          </CSVLink> */}
      </Row>
      {(tableData.length >0) ? (
        <SearchDataTable
          tableData={tableData}
          duration={duration}
          fromDate={fromDate}
          toDate={toDate}
        />
      ):(<h2 style={{textAlign:"center", marginTop:"150px"}}>No Record Found</h2>)}
    </div>
  );
};

export default SearchReport;
