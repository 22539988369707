import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Button, InputGroup } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Plus, Trash } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useParams } from "react-router-dom";
import { useUpdateTrip } from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Avatar from "@components/common/avatar";

const IncludeExcludeForm = () => {
  const inclusionsHeading = "Inclusion";
  const exclusionsHeading = "Exclusion";

  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [updateTripHandler, { data }] = useUpdateTrip();
  const [inclusionsIcon, setInclusionsIcon] = useState(
    "https://static.thehosteller.com/users/avatars/green-checkmark%20%281%29-1649755195948.svg"
  );
  const [exclusionsIcon, setExclusionsIcon] = useState(
    "https://static.thehosteller.com/users/avatars/red-x%20%281%29-1649754889671.svg"
  );
  const [inclusions, setInclusions] = useState([{ id: uuidv4(), text: "" }]);
  const [exclusions, setExclusions] = useState([{ id: uuidv4(), text: "" }]);

  const handleChange = (val, id, type) => {
    if (type === inclusionsHeading) {
      setInclusions(
        inclusions.map((item) =>
          item.id === id ? { ...item, text: val } : item
        )
      );
    } else if (type === exclusionsHeading) {
      setExclusions(
        exclusions.map((item) =>
          item.id === id ? { ...item, text: val } : item
        )
      );
    }
  };

  useEffect(() => {
    if (data?.updateTrip?.name) {
      toaster("success", "Submitted successfully");
    }
  }, [data?.updateTrip?.name]);

  const addListHandler = (type) => {
    if (type === exclusionsHeading) {
      setExclusions((prev) => [...prev, { id: uuidv4(), text: "" }]);
    } else if (type === inclusionsHeading) {
      setInclusions((prev) => [...prev, { id: uuidv4(), text: "" }]);
    }
  };

  const removeListHandler = (id, type) => {
    if (type === exclusionsHeading) {
      setExclusions(exclusions.filter((item) => item.id !== id));
    } else if (type === inclusionsHeading) {
      setInclusions(inclusions.filter((item) => item.id !== id));
    }
  };

  useEffect(() => {
    if (state?.tableData?.otherInfo?.length > 0) {
      state?.tableData?.otherInfo?.map((item) => {
        if (
          item.heading === inclusionsHeading ||
          item.heading === `${inclusionsHeading}s`
        ) {
          setInclusions(
            item.textlist?.map((text) => ({ id: uuidv4(), text: text }))
          );
        } else if (
          item.heading === exclusionsHeading ||
          item.heading === `${exclusionsHeading}s`
        ) {
          setExclusions(
            item.textlist?.map((text) => ({ id: uuidv4(), text: text }))
          );
        }
      });
    }
  }, [state]);

  const submitDatahandler = (e) => {
    e.preventDefault();

    let isValid = true;

    inclusions?.forEach((data) => {
      isValid && data?.text ? (isValid = true) : (isValid = false);
    });

    exclusions?.forEach((data) => {
      isValid && data?.text ? (isValid = true) : (isValid = false);
    });

    if (isValid) {
      const otherInfo = [
        {
          icon: inclusionsIcon,
          heading: inclusionsHeading,
          textlist: inclusions.map((item) => item.text),
        },
        {
          icon: exclusionsIcon,
          heading: exclusionsHeading,
          textlist: exclusions.map((item) => item.text),
        },
      ];

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          otherInfo,
        })
      );
      let data = { otherInfo };

      updateTripHandler(id, data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form onSubmit={submitDatahandler}>
      <Row className='my-5 d-flex justify-content-between'>
        <Col md='5'>
          <h2>
            <Avatar img={inclusionsIcon} /> {inclusionsHeading}{" "}
            {
              <Button
                className='btn-icon ml-2'
                color='light'
                size='sm'
                onClick={() => addListHandler(inclusionsHeading)}
              >
                <Plus size={14} /> Add
              </Button>
            }
          </h2>

          {inclusions.map(({ id, text }, index) => (
            <InputGroup className='mt-1' key={id}>
              <Input
                onChange={(e) =>
                  handleChange(e.target.value, id, inclusionsHeading)
                }
                value={text}
                placeholder='Add inclusion'
              />

              {(index > 0 || inclusions.length > 1) && (
                <Button
                  className='btn-icon rounded-0'
                  color='danger'
                  type='button'
                  onClick={() => removeListHandler(id, inclusionsHeading)}
                >
                  <Trash size={14} />
                </Button>
              )}
            </InputGroup>
          ))}
        </Col>

        <Col md='5'>
          <h2>
            <Avatar img={exclusionsIcon} /> {exclusionsHeading}{" "}
            {
              <Button
                className='btn-icon ml-2'
                color='light'
                size='sm'
                onClick={() => addListHandler(exclusionsHeading)}
              >
                <Plus size={14} /> Add
              </Button>
            }
          </h2>

          {exclusions.map(({ text, id }, index) => (
            <InputGroup className='mt-1' key={id}>
              <Input
                value={text}
                id={"text" + id}
                name={"text" + id}
                placeholder='Add inclusion'
                onChange={(e) =>
                  handleChange(e.target.value, id, exclusionsHeading)
                }
              />

              {(index > 0 || exclusions.length > 1) && (
                <Button
                  className='btn-icon rounded-0'
                  color='danger'
                  type='button'
                  onClick={() => removeListHandler(id, exclusionsHeading)}
                >
                  <Trash size={14} />
                </Button>
              )}
            </InputGroup>
          ))}
        </Col>
      </Row>

      <SaveDiscardBtnGroup />
    </form>
  );
};

export default IncludeExcludeForm;
