import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Card, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useUploadImage } from "../../../api/dataSource";
import { useDispatch, useSelector } from "react-redux";
import { DragDrop } from "@uppy/react";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import toaster from "@components/common/Toaster";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateMerchandiseProduct } from "../../../api/MerchandiseStore/Mutation";

const ProductDetail = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState(null);
  const [previewArr, setPreviewArr] = useState([]);
  const [displayOrder, setDisplayOrder] = useState(null);
  const state = useSelector((state) => state?.cms);
  console.log(state?.tableData);
  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();
  const [updataMerchandiseProductHandler, { data: updatedData }] =
    useUpdateMerchandiseProduct();
  useEffect(() => {
    if (state?.tableData) {
      setName(state?.tableData?.name);
      setDescription(state?.tableData?.description);
      setPrice(state?.tableData?.price);
      setTax(state?.tableData?.tax);
      setPreviewArr([state?.tableData?.mainImage]);
      setDisplayOrder(state?.tableData?.displayOrder);
    }
  }, [state]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppy.use(thumbnailGenerator);
  uppy.on("thumbnail:generated", (file, preview) => {
    setPreviewArr([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "image/walloflove");
    // setImageUrl(imageData?.uploadImage?.url)
    toaster("success", "product image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading wall of love image: ${error.message}`);
  });

  const renderPreview = () => {
    if (previewArr.length) {
      return previewArr.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
          style={{ height: "300px", width: "auto" }}
        />
      ));
    } else {
      return null;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log(
    //   name,
    //   description,
    //   price,
    //   tax,
    //   displayOrder,
    //   imageData?.uploadImage?.url
    // );
    if (
      (name &&
        description &&
        price &&
        tax &&
        displayOrder &&
        (imageData?.uploadImage?.url || previewArr))) {
      const data = {
        name,
        description,
        price: Number(price),
        tax: Number(tax),
        displayOrder: Number(displayOrder),
        mainImage: imageData?.uploadImage?.url,
      };

      updataMerchandiseProductHandler(id, data);
      toaster("success", "product detail updated successfully")
      if (updatedData) {
      }
    } else {
      toaster("error", "Please fill all required fields");
    }
  };
  return (
    <div>
      <form onSubmit={submitHandler}>
        <Row>
          <Col sm="6">
            <label>Name*</label>
            <Input
              autoFocus
              type="text"
              value={name}
              id="name"
              name="name"
              placeholder="Name"
              onChange={(e) =>
                e.target.value !== " "
                  ? setName(e.target.value)
                  : toaster("error", "initaial space not allowed")
              }
            />
          </Col>

          <Col sm="2">
            <label>Price*</label>
            <Input
              autoFocus
              type="Number"
              value={price}
              id="price"
              name="price"
              placeholder="Price"
              // onChange={(e) => setPrice(e.target.value)}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  setPrice(e.target.value);
                }
              }}
            />
          </Col>

          <Col sm="2">
            <label>Tax* (%)</label>
            <Input
              autoFocus
              type="Number"
              value={tax}
              id="tax"
              name="tax"
              placeholder="Tax"
              // onChange={(e) => setTax(e.target.value)}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  setTax(e.target.value);
                }
              }}
            />
          </Col>

          <Col>
            <label>Display order*</label>
            <Input
              autoFocus
              type="Number"
              value={displayOrder}
              id="displayOrder"
              name="displayOrder"
              placeholder="Display order"
              // onChange={(e) => setDisplayOrder(e.target.value)}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  setDisplayOrder(e.target.value);
                }
              }}
            />
          </Col>
        </Row>
        <Row sm="6" style={{ marginTop: "10px" }}>
          <Col sm="12">
            <label>Description*</label>
            <Input
              autoFocus
              type="textarea"
              value={description}
              id="description"
              name="description"
              placeholder="Description"
              onChange={(e) =>
                e.target.value !== " "
                  ? setDescription(e.target.value)
                  : toaster("error", "initial space not allowed")
              }
            />
          </Col>
        </Row>

        <Row>
          <Col sm="4" style={{ marginTop: "10px" }}>
            <p>Upload image*</p>
            <DragDrop
              note="Image size should be less than 500 KB"
              uppy={uppy}
            />
          </Col>
          <Col sm="4">{previewArr?.[0] !== null && renderPreview()}</Col>
          <Col sm="4" style={{ marginTop: "200px" }}>
            <SaveDiscardBtnGroup />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ProductDetail;
