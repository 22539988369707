import React, { useEffect, useState } from "react";
import {
  // useGetOrderByCafeId,
  // useGetOrderCancelled,
  useGetOrderNew,
  // useGetOrderPreparing,
  // useGetOrderReady,
} from "../../../api/Unbox/UnboxOrder/Queries";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import CustomCard from "./customCard";
import {
  useGetAllCafeMasterList,
  useGetCafeByHostelId,
} from "../../../api/Unbox/UnboxCafeMaster/Queries";
import Select from "react-select";

const OrderDashboard = () => {
  const [cafeId, setCafeId] = useState(null);
  const hostelId = JSON.parse(localStorage?.getItem("hostels")) || [];
  const [cafeList, setCafeList] = useState([]);
  const [orderPreparingData, setOrderPreparingData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [orderReady, setOrderReady] = useState([]);
  const [cancelledOrder, setOrderCancel] = useState([]);
  const [completedOrder, setCompletedOrder] = useState([]);
  const [guestName, setGuestName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [filterFeilds, setFilterFeilds] = useState({});
  const { data: cafes } = useGetCafeByHostelId(hostelId);
  const { data: cafeListData, refetch: cafeRefetch } =
    useGetAllCafeMasterList();
  //console.log(hostelId);
  // const { data, refetch } = useGetOrderByCafeId(cafeId?.value);

  // const { data: preparedOrder, refetch: preparedRefetch } =
  //   useGetOrderPreparing(cafeId?.value);

  // const { data: readyOrder, refetch: readyRefetch } = useGetOrderReady(
  //   cafeId?.value
  // );

  // const { data: cancelOrder, refetch: cancelRefetch } = useGetOrderCancelled(
  //   cafeId?.value
  // );

  const { data: newData, refetch: newRefetch } = useGetOrderNew(
    cafeId?.value,
    filterFeilds
  );

  useEffect(() => {
    if (hostelId?.length !== 0 && cafes?.getCafeByHostelId?.length) {
      if (cafes?.getCafeByHostelId?.length === 1) {
        setCafeId({
          label: cafes?.getCafeByHostelId[0]?.cafeName,
          value: cafes?.getCafeByHostelId[0]?._id,
        });
        setCafeList([
          {
            label: cafes?.getCafeByHostelId[0]?.cafeName,
            value: cafes?.getCafeByHostelId[0]?._id,
          },
        ]);
      } else {
        const cafeArray = cafes?.getCafeByHostelId?.map((cafe) => {
          return { label: cafe?.cafeName, value: cafe?._id };
        });

        setCafeList(cafeArray);
      }
    } else {
      const cafeArray = cafeListData?.getAllCafeMasterList?.map((cafe) => {
        return { label: cafe?.cafeName, value: cafe?._id };
      });

      setCafeList(cafeArray);
    }
  }, [cafes?.getCafeByHostelId, cafeListData, hostelId?.length]);

  useEffect(() => {
    if (newData?.getOrderNew?.getOrders?.length > 0) {
      setTableData([...newData?.getOrderNew?.getOrders]);
    } else {
      setTableData([]);
    }
    if (newData?.getOrderNew?.getOrderPreparing?.length > 0) {
      setOrderPreparingData([...newData?.getOrderNew?.getOrderPreparing]);
    } else {
      setOrderPreparingData([]);
    }
    if (newData?.getOrderNew?.getOrderReady?.length > 0) {
      setOrderReady([...newData?.getOrderNew?.getOrderReady]);
    } else {
      setOrderReady([]);
    }
    if (newData?.getOrderNew?.getOrderCompleted?.length > 0) {
      setCompletedOrder([...newData?.getOrderNew?.getOrderCompleted]);
    } else {
      setCompletedOrder([]);
    }
    if (newData?.getOrderNew?.getOrderCancelled?.length > 0) {
      setOrderCancel([...newData?.getOrderNew?.getOrderCancelled]);
    } else {
      setOrderCancel([]);
    }
  }, [
    newData?.getOrderNew?.getOrders,
    newData?.getOrderNew?.getOrderPreparing,
    newData?.getOrderNew?.getOrderReady,
    newData?.getOrderNew?.getOrderCancelled,
    newData?.getOrderNew?.getOrderCompleted,
  ]);

  useEffect(() => {
    // refetch();
    // preparedRefetch();
    // readyRefetch();
    newRefetch();
  }, [cafeId]);

  const handleSearch = () => {
    const filterFeilds = {
      guestName: guestName,
      orderId: orderId,
    };
    setFilterFeilds(filterFeilds);
  };

  const handleReset = () => {
    setFilterFeilds({});
    setGuestName("");
    setOrderId("");
  };

  return (
    <>
      <Row>
        <Col sm="10" style={{ display: "flex", flexDirection: "column" }}>
          {cafeList?.length !== 1 && (
            <Col sm="5">
              <Label className="form-label" for="cafe">
                <strong>Cafe</strong>
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="cafe"
                name="cafe"
                options={cafeList}
                //value={}
                onChange={(value) => setCafeId(value)}
                classNamePrefix="select"
              />
            </Col>
          )}

          <div style={{ display: "flex", marginTop: "10px" }}>
            <Col sm="5">
              <FormGroup className="w-100">
                <Label className="form-label" for="guestName">
                  <strong>Guest Name</strong>
                </Label>
                <Input
                  id="guestName"
                  name="guestName"
                  placeholder="Guest Name"
                  value={guestName}
                  onChange={(e) => setGuestName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col sm="5">
              <FormGroup className="w-100">
                <Label className="form-label" for="orderId">
                  <strong>Order ID</strong>
                </Label>
                <Input
                  id="orderId"
                  name="orderId"
                  value={orderId}
                  placeholder="Order ID"
                  onChange={(e) => {
                    setOrderId(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col className="" sm="3" style={{ display: "flex", gap: "20px" }}>
              <Button
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 30px",
                  outline: "none",
                  border: "0",
                }}
                color="white"
                appearance="primary"
                type="button"
                onClick={handleSearch}
              >
                Search
              </Button>

              <Button
                style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  //marginLeft: "40px",
                  borderRadius: "5px",
                  padding: "10px 30px",
                  outline: "none",
                  border: "0",
                }}
                color="danger"
                appearance="primary"
                type="button"
                onClick={handleReset}
              >
                Reset
              </Button>
            </Col>
          </div>
        </Col>
        {tableData?.length ||
        orderPreparingData?.length ||
        orderReady?.length ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4,1fr)",
              overflowX: "auto",
              width: "100%",
              marginTop: "2%",
            }}
          >
            <Col>
              {tableData?.length ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    display: "block",
                    textAlign: "center",
                    marginBottom: "10px",
                    width: "380px",
                    //fontWeight: "bold",
                    color: "#2d2d2d",
                  }}
                >
                  Incoming Order
                </span>
              ) : (
                <></>
              )}
              {tableData?.map((order, i) => (
                <CustomCard
                  key={i}
                  item={order}
                  //refetch={refetch}
                  // preparedRefetch={preparedRefetch}
                  // readyRefetch={readyRefetch}
                  // cancelRefetch={cancelRefetch}
                  newRefetch={newRefetch}
                  // ref={componentRef}
                  // handlePrint={handlePrint}
                />
              ))}
            </Col>
            <Col>
              {orderPreparingData?.length ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    display: "block",
                    textAlign: "center",
                    marginBottom: "10px",
                    width: "380px",
                    color: "#2d2d2d",
                  }}
                >
                  Order Preparing
                </span>
              ) : (
                <></>
              )}
              {orderPreparingData?.map((order, i) => (
                <CustomCard
                  key={i}
                  item={order}
                  // refetch={refetch}
                  // preparedRefetch={preparedRefetch}
                  // readyRefetch={readyRefetch}
                  // cancelRefetch={cancelRefetch}
                  newRefetch={newRefetch}
                  // ref={componentRef}
                  // handlePrint={handlePrint}
                />
              ))}
            </Col>
            <Col>
              {orderReady?.length ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    textAlign: "center",
                    display: "block",
                    marginBottom: "10px",
                    width: "380px",
                    color: "#2d2d2d",
                  }}
                >
                  Order Ready For Pick Up
                </span>
              ) : (
                <></>
              )}
              {orderReady?.map((order, i) => (
                <CustomCard
                  key={i}
                  item={order}
                  // refetch={refetch}
                  // preparedRefetch={preparedRefetch}
                  // readyRefetch={readyRefetch}
                  // cancelRefetch={cancelRefetch}
                  newRefetch={newRefetch}
                />
              ))}
            </Col>
            {/* <Col>
              {completedOrder?.length ? (
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  ORDER COMPLETED
                </span>
              ) : (
                <></>
              )}
              {completedOrder?.map((order, i) => (
                <CustomCard
                  key={i}
                  item={order}
                  // refetch={refetch}
                  // preparedRefetch={preparedRefetch}
                  // readyRefetch={readyRefetch}
                  // cancelRefetch={cancelRefetch}
                  newRefetch={newRefetch}
                />
              ))}
            </Col> */}
            {/* <Col>
              {cancelledOrder?.length ? <Label>Cancelled Order</Label> : <></>}
              {cancelledOrder?.map((order, i) => (
                <CustomCard
                  key={i}
                  item={order}
                  // refetch={refetch}
                  // preparedRefetch={preparedRefetch}
                  // readyRefetch={readyRefetch}
                  // cancelRefetch={cancelRefetch}
                  newRefetch={newRefetch}
                />
              ))}
            </Col> */}
          </div>
        ) : (
          <Col sm="11" style={{ textAlign: "center", marginTop: "5%" }}>
            <h5>Please Select cafe to see orders</h5>
          </Col>
        )}
      </Row>
    </>
  );
};

export default OrderDashboard;
