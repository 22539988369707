import React, { useEffect, useState } from "react";
import SlideModal from "@components/common/slide-modal";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import SwitchIcon from "@components/common/switch-icon";
import toaster from "@components/common/Toaster";

// Imports for uppy image uploader
// import "uppy/dist/uppy.css";
// import "@uppy/status-bar/dist/style.css";
// import "@styles/react/libs/file-uploader/file-uploader.scss";
// import Uppy from "@uppy/core";
// import thumbnailGenerator from "@uppy/thumbnail-generator";
// import { DragDrop } from "@uppy/react";

import {
  useAddStepOutBlogCategory,
  useUpdateStepOutBlogCategory,
  //   useUploadImage,
} from "../../../api/dataSource";

const AddEditModal = ({ openModal, handleToggle, refetch, editData }) => {
  const [categoryName, setCategoryName] = useState("");
  const [url, setUrl] = useState("");
  //const [seo, setSeo] = useState("");
  //const [blogImage, setBlogImage] = useState(null);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [status, setStatus] = useState(false);

  // <--handler and custom hook for uploading image-->
  // const [
  //   uploadImageHandler,
  //   { data: imageData, reset: imageReset, loading: imageLoad },
  // ] = useUploadImage();

  //handler and custom hook for adding the Blog category
  const [addStepOutBlogCategoryHandler, { data }] = useAddStepOutBlogCategory();

  //handler and custom hook for updating the blog category
  const [updateStepOutBlogCategoryHandler, { data: updateBlogCateory }] =
    useUpdateStepOutBlogCategory();

  useEffect(() => {
    if (data?.createStepOutBlogCategory?.categoryName || updateBlogCateory) {
      refetch();
      handleToggle();
      // toaster("success", "Blog Category added successfully");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updateBlogCateory]);

  useEffect(() => {
    if (editData?._id) {
      setCategoryName(
        editData?.categoryName !== null ? editData?.categoryName : ""
      );
      setUrl(editData?.url !== null ? editData?.url : "");
      //setBlogImage(editData?.image !== null ? editData?.image : "");

      setMetaTitle(
        editData?.seo?.metaTitle !== null ? editData?.seo.metaTitle : ""
      );
      setMetaDesc(
        editData?.seo?.metaDesc !== null ? editData?.seo?.metaDesc : ""
      );
      setKeywords(
        editData?.seo?.keywords !== null ? editData?.seo?.keywords : ""
      );
      setStatus(editData?.status !== null ? editData?.status : false);
    } else {
      setCategoryName("");
      setMetaTitle("");
      //setBlogImage(null);
      setMetaDesc("");
      setKeywords("");
      setUrl("");
      setStatus(false);
    }
  }, [editData]);

  // Uppy file Uploader for uploading image
  // const uppy = new Uppy({
  //   meta: { type: "avatar" },
  //   autoProceed: true,
  //   restrictions: {
  //     maxNumberOfFiles: 1,
  //     allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
  //     maxFileSize: 2097152,
  //   },
  // });

  // uppy.use(thumbnailGenerator);

  // uppy.on("thumbnail:generated", (file, preview) => {
  //   setBlogImage([preview]);
  //   uploadImageHandler(file.data, "icons/BlogCategoryTest");
  // });

  // const renderPreview = () =>
  //   blogImage ? (
  //     <img className="rounded mr-1 mb-2 w-50" src={blogImage} alt="avatar" />
  //   ) : null;

  // useEffect(() => {
  //   setBlogImage(imageData?.uploadImage?.url);
  // }, [imageData]);

  // useEffect(() => {
  //   if (editData?._id) {
  //     setCategoryName(
  //       editData?.categoryName !== null ? editData?.categoryName : ""
  //     );
  //     setUrl(editData?.url !== null ? editData?.url : "");
  //     //setBlogImage(editData?.image !== null ? editData?.image : "");

  //     setMetaTitle(
  //       editData?.seo?.metaTitle !== null ? editData?.seo.metaTitle : ""
  //     );
  //     setMetaDesc(
  //       editData?.seo?.metaDesc !== null ? editData?.seo?.metaDesc : ""
  //     );
  //     setKeywords(
  //       editData?.seo?.keywords !== null ? editData?.seo?.keywords : ""
  //     );
  //     setStatus(editData?.status !== null ? editData?.status : false);
  //   }
  // }, [editData]);

  const submitFormHandler = () => {
    let data = {
      categoryName,
      url,
      seo: { metaTitle, metaDesc, keywords },
      //image: blogImage,  //passing image feild here
      status,
    };
    if (data?.url) {
      if (editData?._id) {
        updateStepOutBlogCategoryHandler(editData?._id, data);
        refetch();
        toaster("success", "Blog Category updated successfully");
      } else {
        addStepOutBlogCategoryHandler(data);
        refetch();
        toaster("success", "Blog Category added successfully");
      }
      setCategoryName("");
      setMetaTitle("");
      //setBlogImage(null);
      setMetaDesc("");
      setKeywords("");
      setUrl("");
      setStatus(false);
    } else {
      toaster("error", "Please fill the details");
    }
    // handleToggle();
  };

  return (
    <SlideModal
      open={openModal}
      handleToggle={handleToggle}
      submitDataHandler={submitFormHandler}
    >
      <form>
        <Row>
          {/* Category name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="categoryName">
                Category Name
              </Label>
              <Input
                type="text"
                value={categoryName}
                id="categoryName"
                name="categoryName"
                placeholder="Add Category Name"
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* SEO */}
          <Col sm="11">
            <FormGroup className="w-100">
              <Label className="form-label" for="seo">
                SEO
              </Label>
              <Col sm="11">
                <Label className="form-label" for="seo">
                  MetaTitle
                </Label>
                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add Meta Title"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </Col>
              <br />
              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Description
                </Label>
                <Input
                  type="text"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add Meta Desc"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </Col>
              <br />
              <Col sm="12">
                <Label className="form-label" for="seo">
                  Keywords
                </Label>
                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add Keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </Col>
            </FormGroup>
          </Col>
          {/* url */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                URL (Format: /blogs/category/category-name)
              </Label>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add URL"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* Image uploader and preview column */}
          {/* <Col
            sm="12"
            style={{
              marginTop: "20px",
              marginBottom: "50px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "50%" }}>
              <Label className="form-label">Banner Image</Label>
              <DragDrop
                note="Image size should be less than 2MB"
                width="100%"
                uppy={uppy}
                locale={{
                  strings: {
                    dropHereOr: "Drop image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
            </div>

            <div style={{ width: "50%", marginLeft: "10px" }}>
              <div
                className="service-icon-preview"
                style={{
                  width: "400px",
                  marginTop: "50px",
                }}
              >
                {imageLoad ? "Uploading icon..." : <>{renderPreview()}</>}
              </div>
            </div>
          </Col> */}

          {/* Switch Icon Component for status update */}
          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Publish on Website ?"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
