import React, { useEffect, useState } from "react";
import ConnectTop from "./ConnectTop";
import AddEditConnectModel from "./AddEditConnectModel";
import ConnectPosts from "./ConnectPosts";
import { useCreateNewConversation, useGetAllPosts } from "../../api/dataSource";
import AddEditConnectModelnew from "./AddEditConnectModelnew";

const Connect = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");

  const { data, refetch } = useGetAllPosts();

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(()=>{
    if(data){
      refetch();
    }
  },[data])


  return (
    <div>
      <ConnectTop openModal={() => setOpenModal(!openModal)}         refetch={refetch}
 />
      <ConnectPosts postList={data} refetch={refetch} />
      <AddEditConnectModel
        open={openModal}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default Connect;
