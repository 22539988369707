// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import UnpaidReservationTable from "./UnpaidReservationTable";
import AddEditModal from "./AddEditModal";
import { useGetUnpaidBookingsList, useAllUnpaidBookingsList, useHostelList } from "../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import { selectThemeColors, dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";

const tableHeaderDataObject = [
  { label: "Payment Link Sent", key: "paymentLinkSent" },
  { label: "PaymentId", key: "paymentId" },
  { label: "Source", key: "Source" },
  { label: "Reservation No", key: "uniqueId" },
  { label: "Guest Name", key: "GuestName" },
  { label: "Due Amount", key: "DueAmount" },
  { label: "Arrival Date", key: "ArrivalDate" },
  { label: "Departure Date", key: "DepartureDate" },
  { label: "Reservation Date", key: "ReservationDate" },
  { label: "Mobile", key: "Mobile" },
  { label: "Hostel Name", key: "hostelName" },
  { label: "Logs", key: "logs" }
];

const paymentStatusList = [
  { label: "Paid", value: true },
  { label: "Unpaid", value: false },
];

const UnpaidReservation = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  // ! Coded by Ashwin
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const [dueAmoutPaid, setDueAmountPaid] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [checkinStartDate, setCheckinStartDate] = useState(null);
  const [checkinEndDate, setCheckinEndDate] = useState(null);
  const [filter, setFilter] = useState(null);
  const [exportTableData, setExportTableData] = useState([]);

  const { data, refetch } = useGetUnpaidBookingsList(
    limit,
    page,
    filter?.hostelIds,
    filter?.paymentStatus?.value,
    filter?.checkinStartDate,
    filter?.checkinEndDate
  );

  const { data: exportData } = useAllUnpaidBookingsList(
    limit,
    page,
    filter?.hostelIds,
    filter?.paymentStatus?.value,
    filter?.checkinStartDate,
    filter?.checkinEndDate
  )
  const [totalPage, setTotalPage] = useState(1);
  const { data: allHostelsData } = useHostelList();

  const handleClear = () => {
    setCheckinEndDate(null);
    setCheckinStartDate(null);
    setHostel(null)
    setPaymentStatus(null)
    setPage(1);
    setFilter(null);
    // refetch()
  };

  const handleSearch = () => {
    const data = {
      hostelIds: hostel?.length > 0 ? hostel.map((hostel) => hostel.value) : null,
      paymentStatus,
      checkinStartDate,
      checkinEndDate,
    };
    setPage(1)
    setFilter(data);
  };

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    refetch();
  }, [hostel]);

  useEffect(() => {
    if (data?.getUnpaidBookingsList?.data?.length <= 0) {
      setTableData([]);
    } else if (data?.getUnpaidBookingsList.data.length > 0) {
      setTableData(data?.getUnpaidBookingsList.data);
    }

    setTotalPage(Math.ceil(data?.getUnpaidBookingsList?.count / limit));
  }, [data]);

  useEffect(() => {
    if (data?.getUnpaidBookingsList.data.length) {
      let editData = data?.getUnpaidBookingsList?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    if (exportData?.getAllUnpaidBookingsList?.length > 0) {
      setExportTableData(exportData?.getAllUnpaidBookingsList);
    } else {
      setExportTableData([]);
    }
  }, [exportData]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  return (
    localStorage.getItem("modules")?.includes("Reports") && <div>
      <Row>
        <Col sm="4">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              Hostel
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              options={HostelsList}
              onChange={(value) => {
                setHostel(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="paymentStatus">
              Due Amount Status
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              id="paymentStatus"
              name="paymentStatus"
              value={paymentStatus}
              options={paymentStatusList}
              onChange={(value) => {
                setPaymentStatus(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              Check-in date range
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={checkinStartDate}
              options={{
                mode: "range",
              }}
              onChange={(date) => {
                setCheckinStartDate(dateFormatter(date[0]));
                setCheckinEndDate(dateFormatter(date[1]));
              }}
              id="checkinDate"
              // isClearable={true}
              placeholder="Check-in date range"
            />
          </FormGroup>
        </Col>
        <Col>
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              marginLeft: "0px",
              borderRadius: "5px",
              padding: "10px 20px",
              outline: "none",
              border: "0",
            }}
            color="primary"
            type="button"
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button>
        </Col>
        <Col>
          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "-40px",
              // marginRight: "20px",
              borderRadius: "5px",
              padding: "10px 20px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            type="button"
            onClick={() => {
              handleClear();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <UnpaidReservationTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        headers={tableHeaderDataObject}
        exportData={exportTableData}
        page={page}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      {
        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      }
    </div>
  );
};

export default UnpaidReservation;