import gql from "graphql-tag";

export const CREATE_REPLY_TEMPLATE = gql`
  mutation createReplyTemplate($replyTemplateInput: ReplyTemplateInput) {
    createReplyTemplate(replyTemplateInput: $replyTemplateInput) {
        templateTitle
    }
  }
`;

export const UPDATE_REPLY_TEMPLATE = gql`
  mutation updateReplyTemplate($id: ID!, $replyTemplateUpdate: ReplyTemplateInput) {
    updateReplyTemplate(id: $id, replyTemplateUpdate: $replyTemplateUpdate) {
        templateTitle
    }
  }
`;