import React from "react";
import AuthSuccess from "../../common/AuthSuccess";

const ForgotPasswordStepThree = () => {
  return (
    <AuthSuccess
      title="Password Reset Successfully"
      description="No worries, we’ll send you reset instructions."
      btnText="Login now"
    />
  );
};

export default ForgotPasswordStepThree;
