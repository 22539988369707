import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_HOSTEL_VENDOR_LIST = gql`
  query getPaginatedHostelVendor($filter: filter) {
    getPaginatedHostelVendor(filter: $filter) {
      data {
        _id
        hostel {
          _id
          name
        }
        vendorName
        contactNo
        email
        services
        vendorGSTNumber
        vendorBankAccNumber
        vendorIFSC
        panNo
        vendorType
        pocName
        accountType
        bankDetailImage {
          imageURL
        }
        vendorHead
        vendorSubHead
        vendorPriority
        addressDetails
        agreement {
          imageURL
        }
        agreementStartDate
        agreementEndDate
        paymentCycle
        vendorCategory
        vendorState
        logs
        vendorStatus
        createdAt
      }
      count
      page
      limit
    }
  }
`;

export const useGetPaginatedOpsHostelVendorList = (
  limit,
  page,
  filterFields
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_HOSTEL_VENDOR_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFields,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_HOSTEL_VENDOR_REPORT = gql`
  query getHostelVendorReport {
    getHostelVendorReport {
      _id
      hostel {
        _id
        name
      }
      vendorName
      contactNo
      email
      services
    }
  }
`;

export const useGetOpsHostelVendorList = () => {
  const { loading, error, data, refetch } = useQuery(GET_HOSTEL_VENDOR_REPORT);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const GET_All_VENDOR_DETAILS = gql`
  query getAllVendorDetails {
    getAllVendorDetails {
      data {
        _id
        hostel {
          _id
          name
        }
        vendorName
        contactNo
        email
        services
        vendorGSTNumber
        vendorBankAccNumber
        vendorIFSC
        panNo
        vendorType
        pocName
        accountType
        bankDetailImage {
          imageURL
        }
        vendorHead
        vendorSubHead
        vendorPriority
        addressDetails
        agreement {
          imageURL
        }
        agreementStartDate
        agreementEndDate
        paymentCycle
        vendorCategory
        vendorState
        logs
        vendorStatus
        createdAt
      }
    }
  }
`;

export const useGetAllVendorDetails = () => {
  const { loading, error, data, refetch } = useQuery(GET_All_VENDOR_DETAILS);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const GET_CAPEX_VENDORS = gql`
  query getCapexVendors($hostelId: ID, $vendorState: String) {
    getCapexVendors(hostelId: $hostelId, vendorState: $vendorState) {
      _id
      vendorName
    }
  }
`;

export const GET_OPEX_VENDORS = gql`
  query getOpexVendors($hostelId: ID, $vendorState: String) {
    getOpexVendors(hostelId: $hostelId, vendorState: $vendorState) {
      _id
      vendorName
      vendorIFSC
      vendorBankAccNumber
      bankDetailImage {
        imageURL
      }
    }
  }
`;

export const useGetCapexVendors = (hostelId, vendorState) => {
  const { loading, error, data, refetch } = useQuery(GET_CAPEX_VENDORS, {
    variables: {
      hostelId,
      vendorState,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

//Added by Nitish
export const useGetOpexVendors = (hostelId, vendorState) => {
  const { loading, error, data, refetch } = useQuery(GET_OPEX_VENDORS, {
    variables: {
      hostelId,
      vendorState,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const GET_ALL_CAPEX_VENDORS = gql`
  query getAllCapexVendors {
    getAllCapexVendors {
      _id
      vendorName
    }
  }
`;

export const useGetAllCapexVendorNames = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_CAPEX_VENDORS);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};
