import WalletInfo from "../../views/Wallet/WalletInfo/index";
import WalletReport from "../../views/Wallet/WalletReport/index";

const Wallet = [
  {
    path: "/wallet/walletBalance",
    component: WalletInfo,
    exact: true,
  },
  {
    path: "/wallet/walletReport",
    component: WalletReport,
    exact: true,
  },
];

export default Wallet;
