import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Button, InputGroup } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Plus, Trash } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useParams } from "react-router-dom";
import { useUpdateWorkationPackage } from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";

const Policy = () => {
  const hostelType = "Hostel Policy";
  const bookingType = "Booking Policy";
  const cancellationType = "Cancellation Policy";

  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [updateWorkationPackageHandler, { data }] = useUpdateWorkationPackage();

  const [hostelPolicy, setHostelPolicy] = useState([
    { id: uuidv4(), text: "" },
  ]);
  const [bookingPolicy, setBookingPolicy] = useState([
    { id: uuidv4(), text: "" },
  ]);
  const [cancellationPolicy, setCancellationPolicy] = useState([
    { id: uuidv4(), text: "" },
  ]);

  const handleChange = (val, id, type) => {
    if (type === hostelType) {
      setHostelPolicy(
        hostelPolicy.map((item) =>
          item.id === id ? { ...item, text: val } : item
        )
      );
    } else if (type === bookingType) {
      setBookingPolicy(
        bookingPolicy.map((item) =>
          item.id === id ? { ...item, text: val } : item
        )
      );
    } else if (type === cancellationType) {
      setCancellationPolicy(
        cancellationPolicy.map((item) =>
          item.id === id ? { ...item, text: val } : item
        )
      );
    }
  };

  useEffect(() => {
    if (data?.updateWorkationPackage?._id) {
      toaster("success", "Submitted successfully");
    }
  }, [data?.updateWorkationPackage?._id]);

  const addListHandler = (type) => {
    if (type === bookingType) {
      setBookingPolicy((prev) => [...prev, { id: uuidv4(), text: "" }]);
    } else if (type === hostelType) {
      setHostelPolicy((prev) => [...prev, { id: uuidv4(), text: "" }]);
    } else if (type === cancellationType) {
      setCancellationPolicy((prev) => [...prev, { id: uuidv4(), text: "" }]);
    }
  };

  const removeListHandler = (id, type) => {
    if (type === bookingType) {
      setBookingPolicy(bookingPolicy.filter((item) => item.id !== id));
    } else if (type === hostelType) {
      setHostelPolicy(hostelPolicy.filter((item) => item.id !== id));
    } else if (type === cancellationType) {
      setCancellationPolicy(
        cancellationPolicy.filter((item) => item.id !== id)
      );
    }
  };

  useEffect(() => {
    const policy = state?.tableData?.policy;

    if (policy) {
      policy.hostelPolicy?.length &&
        setHostelPolicy(
          policy.hostelPolicy.map((text) => ({ id: uuidv4(), text: text }))
        );

      policy.bookingPolicy?.length &&
        setBookingPolicy(
          policy.bookingPolicy.map((text) => ({ id: uuidv4(), text: text }))
        );

      policy.cancellationPolicy?.length &&
        setCancellationPolicy(
          policy.cancellationPolicy.map((text) => ({
            id: uuidv4(),
            text: text,
          }))
        );
    }
  }, [state?.tableData?.policy]);

  const submitDatahandler = (e) => {
    e.preventDefault();

    let isValid = true;

    hostelPolicy?.forEach((data) => {
      isValid && data?.text ? (isValid = true) : (isValid = false);
    });

    bookingPolicy?.forEach((data) => {
      isValid && data?.text ? (isValid = true) : (isValid = false);
    });

    cancellationPolicy?.forEach((data) => {
      isValid && data?.text ? (isValid = true) : (isValid = false);
    });

    if (isValid) {
      const policy = {
        hostelPolicy: hostelPolicy.map((item) => item.text),
        bookingPolicy: bookingPolicy.map((item) => item.text),
        cancellationPolicy: cancellationPolicy.map((item) => item.text),
      };

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          policy,
        })
      );

      updateWorkationPackageHandler(id, { policy });
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form onSubmit={submitDatahandler}>
      <Row className='my-5 d-flex justify-content-between'>
        <Col className='mb-5' md='12'>
          <h2>
            {hostelType}{" "}
            {
              <Button
                className='btn-icon ml-2'
                color='light'
                size='sm'
                onClick={() => addListHandler(hostelType)}
              >
                <Plus size={14} /> Add
              </Button>
            }
          </h2>

          {hostelPolicy.map(({ id, text }, index) => (
            <InputGroup className='mt-1' key={id}>
              <Input
                onChange={(e) => handleChange(e.target.value, id, hostelType)}
                value={text}
                type='textarea'
                placeholder='add policy'
              />

              {(index > 0 || hostelPolicy.length > 1) && (
                <Button
                  className='btn-icon rounded-0'
                  color='danger'
                  type='button'
                  onClick={() => removeListHandler(id, hostelType)}
                >
                  <Trash size={14} />
                </Button>
              )}
            </InputGroup>
          ))}
        </Col>
        <Col className='mb-5' md='12'>
          <h2>
            {bookingType}{" "}
            {
              <Button
                className='btn-icon ml-2'
                color='light'
                size='sm'
                onClick={() => addListHandler(bookingType)}
              >
                <Plus size={14} /> Add
              </Button>
            }
          </h2>

          {bookingPolicy.map(({ id, text }, index) => (
            <InputGroup className='mt-1' key={id}>
              <Input
                onChange={(e) => handleChange(e.target.value, id, bookingType)}
                value={text}
                type='textarea'
                placeholder='add policy'
              />

              {(index > 0 || bookingPolicy.length > 1) && (
                <Button
                  className='btn-icon rounded-0'
                  color='danger'
                  type='button'
                  onClick={() => removeListHandler(id, bookingType)}
                >
                  <Trash size={14} />
                </Button>
              )}
            </InputGroup>
          ))}
        </Col>
        <Col className='mb-5' md='12'>
          <h2>
            {cancellationType}{" "}
            {
              <Button
                className='btn-icon ml-2'
                color='light'
                size='sm'
                onClick={() => addListHandler(cancellationType)}
              >
                <Plus size={14} /> Add
              </Button>
            }
          </h2>

          {cancellationPolicy.map(({ id, text }, index) => (
            <InputGroup className='mt-1' key={id}>
              <Input
                onChange={(e) =>
                  handleChange(e.target.value, id, cancellationType)
                }
                value={text}
                type='textarea'
                placeholder='add policy'
              />

              {(index > 0 || cancellationPolicy.length > 1) && (
                <Button
                  className='btn-icon rounded-0'
                  color='danger'
                  type='button'
                  onClick={() => removeListHandler(id, cancellationType)}
                >
                  <Trash size={14} />
                </Button>
              )}
            </InputGroup>
          ))}
        </Col>
      </Row>

      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Policy;
