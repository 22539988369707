import gql from "graphql-tag";

export const CREATE_STEP_OUT_CATEGORY = gql`
mutation createStepOutCategory($stepOutCategoryInput: StepOutCategoryInput) {
    createStepOutCategory(stepOutCategoryInput: $stepOutCategoryInput) {
        _id
        name
    }
}
`

export const UPDATE_STEP_OUT_CATEGORY = gql`
mutation updateStepOutCategory($id: ID $stepOutCategoryUpdate: StepOutCategoryInput) {
    updateStepOutCategory(id: $id stepOutCategoryUpdate: $stepOutCategoryUpdate){
        _id
        name
    }
}
`