import React, { useEffect, useState } from "react";
import WhatsappMessagesTable from "./WhatsappMessagesTable";
import { useGetWhatsappMessagesReport } from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { dateValidator } from "../../../utility/Utils";
import toaster from "@components/common/Toaster";

const WhatsappMessages = () => {
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [searchFromDate, setSearchFromDate] = useState(dateFormatter(new Date()));
  const [searchToDate, setSearchToDate] = useState(dateFormatter(new Date()));
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  const { data, refetch } = useGetWhatsappMessagesReport(
    limit,
    page,
    searchFromDate,
    searchToDate
  );
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    refetch();
  }, [fromDate]);

  useEffect(() => {
    refetch();
  }, [toDate]);

  useEffect(() => {
    if (data?.getWhatsappMessagesReport?.data) {
      setTableData(data?.getWhatsappMessagesReport?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getWhatsappMessagesReport?.count / limit));
  }, [data]);

  const handleSearch = ()=>{
    if(dateValidator(fromDate, toDate)){
      setSearchFromDate(fromDate);
      setSearchToDate(toDate)
    }else{
      toaster("error", "Invalid Dates")
    }
  }

  const handleResetData = ()=>{
    setFromDate(dateFormatter(new Date()))
    setToDate(dateFormatter(new Date()))
    setSearchFromDate(dateFormatter(new Date()))
    setSearchToDate(dateFormatter(new Date()))
    setTableData([])
  }

  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        <Row>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                To Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
                placeholder="To Date"
              />
            </FormGroup>
          </Col>
          <Col style={{ marginTop: "22px" }}>
            <Button.Ripple
              onClick={handleSearch}
              className="ml-2"
              color="primary"
            >
              Search
            </Button.Ripple>
            <Button.Ripple
              onClick={handleResetData}
              className="ml-2"
              color="danger"
            >
              Reset
            </Button.Ripple>

            
          </Col>
        </Row>

        {tableData.length > 0 ? <WhatsappMessagesTable data={tableData} refetch={refetch} />
:<h2 style={{textAlign:"center", marginTop:"150px"}}>No Record Found</h2> }

{tableData.length > 0 && <TablePagination setPage={setPage} page={page} totalPage={totalPage} />}
        
      </div>
    )
  );
};

export default WhatsappMessages;
