import { Server, Circle } from "react-feather";

// const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

export default //role === "Super Admin" ||
modules?.includes("Instant Communication")
  ? [
      {
        id: "communications",
        title: "Instant Communication",
        icon: <Server size={20} />,
        badge: "light-warning",
        children: [
          // ! Added by Ashwin
          {
            id: "messageTemplate",
            title: "WA Template",
            icon: <Circle size={12} />,
            navLink: "/communications/messageTemplate",
          },
          {
            id: "messageConfiguration",
            title: "WA Scheduler",
            icon: <Circle size={12} />,
            navLink: "/communications/messageConfiguration",
          },
        ],
      },
    ]
  : [];
