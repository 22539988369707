import React, { useEffect, useState } from "react";
import OccupancyTrackerTable from "./OccupancyTrackerTable";
import { useGetOccupancyTrackerReport } from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { dateFormatHandler, dateFormatter } from "@utils";
// import { CSVLink } from "react-csv";
import { useHostelList } from "../../../api/dataSource";
import { selectThemeColors } from "@utils";

const OccupancyTracker = () => {
  const [tableData, setTableData] = useState([]);
  const [date, setDate] = useState(dateFormatter(new Date()));
  const [hostelIds, setHostelIds] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useHostelList();
  const [searchDate, setSearchDate] = useState(dateFormatter(new Date()));
  const [searchHostelIds, setSearchHostelIds] = useState(null)

  const { data, refetch } = useGetOccupancyTrackerReport(
    searchDate,
    searchHostelIds?.length > 0 ? searchHostelIds?.map((hostelId) => hostelId?.value) : [],
  );

  // const { data, refetch } = useGetOccupancyTrackerReport(
  //   searchDate,
  //   hostelIds.length > 0 ? hostelIds.map((hostelId) => hostelId?.value) : [],
  //   shouldSkip
  // );

  // useEffect(() => {
  //   refetch();
  // }, [hostelIds]);

  // useEffect(() => {
  //   refetch();
  // }, [date]);

  useEffect(() => {
    if (data?.getOccupancyTrackerReport) {
      setTableData(data?.getOccupancyTrackerReport);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const getOccupancyTrakerData = ()=>{
    setSearchDate(date);
    setSearchHostelIds(hostelIds)
  }
  
  const handleResetData = ()=>{
    setTableData([]);
    setSearchHostelIds(null)
    setDate(dateFormatter(new Date()))
    setSearchDate(dateFormatter(new Date()));
    setHostelIds([])
  }

  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        <Row>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="date">
                Select Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={date}
                onChange={(date) => setDate(dateFormatter(new Date(date)))}
                id="date"
                placeholder="Date"
              />
            </FormGroup>
          </Col>
          <Col sm="6" style={{zIndex:"5"}}>
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel*
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostelIds}
                options={HostelsList}
                onChange={(value) => {
                  setHostelIds(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
                style={{zIndex:'4'}}
                // style={{ position: "sticky", top: 0 }}
              />
            </FormGroup>
          </Col>
          <Col sm="2" style={{display:"flex"}}>
            <Button
              onClick={getOccupancyTrakerData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginRight: "10px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
            >
              Search
            </Button>

            <Button
              onClick={handleResetData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft:"10px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button>
          </Col>
        </Row>
        {(tableData.length > 0) && <OccupancyTrackerTable data={tableData} />}
        {(tableData.length == 0)&&  <h2 style={{textAlign:"center", marginTop:"150px"}}>No Record Found</h2>}
      </div>
    )
  );
};

export default OccupancyTracker;
