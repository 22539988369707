import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { useGetEmployeeProfileByHostelId } from "../../../api/dataSource";
import { selectThemeColors } from "@utils";

const HostelAdvanceSalarySubFields = ({
  setDataToSubmit,
  hostel,
  editData,
}) => {
  const [subHeadType, setSubHeadType] = useState(null);
  const [employee, setEmployee] = useState(null);

  const [EmployeeList, setEmployeeList] = useState([]);

  const { data } = useGetEmployeeProfileByHostelId(
    hostel?.length > 0 ? hostel : null
  );

  const HostelSalaryType = [
    { label: "Hostel", value: "Hostel" },
    { label: "F&B", value: "F&B" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    let EmployeeList = data?.getEmployeeProfileListByHostelId?.map((item) => ({
      label: item?.fullName && `${item?.fullName} (${item?.empCode})`,
      value: item?.fullName && `${item?._id}`,
    }));

    setEmployeeList(EmployeeList);
  }, [data]);

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );
    setEmployee(
      editData?.subFields?.refEmployee !== null
        ? {
            label: editData?.subFields?.refEmployee?.name,
            value: editData?.subFields?.refEmployee?.id,
          }
        : null
    );
  }, [editData]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
      refEmployee: { name: employee?.label, id: employee?.value },
    };

    setDataToSubmit(data);
  }, [subHeadType, employee]);
  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="subHeadType">
            Salary Type
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={HostelSalaryType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            // isDisabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="employee">
            Employee*
          </Label>
          <Select
            isClearable={true}
            isSearchable={true}
            theme={selectThemeColors}
            id="employee"
            name="employee"
            value={employee}
            options={EmployeeList}
            onChange={(value) => {
              setEmployee(value);
            }}
            className="basic-multi-select"
            classNamePrefix="select"
            // isDisabled={editData?._id}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default HostelAdvanceSalarySubFields;
