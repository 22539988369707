import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation, useQuery } from "@apollo/client";

export const CREATE_MERCHANDISE_PRODUCT = gql`
mutation createMerchandiseProduct($productInfoInput:productInfoInput){
    createMerchandiseProduct(productInfoInput:$productInfoInput){
        name
    }
}   
`

export const useCreateMerchandiseProduct = () => {
    const [MerchandiseProduct, { loading, error, data, refetch }] =
        useMutation(CREATE_MERCHANDISE_PRODUCT);

    useLoadingHandler(loading);

    const CreateMerchandiseProductHandler = (productInfoInput) => {
        MerchandiseProduct({
            variables: { productInfoInput },
        });
    };

    return [CreateMerchandiseProductHandler, { loading, error, data, refetch }];
};

export const UPDATE_MERCHANDISE_PRODUCT = gql`
mutation updateMerchandiseProductById($productId:ID, $updateData:updateData){
    updateMerchandiseProductById(productId:$productId, updateData:$updateData){
        name
    }
}
`

export const useUpdateMerchandiseProduct = () => {
    const [MerchandiseProductUpdate, { loading, error, data, refetch }] =
        useMutation(UPDATE_MERCHANDISE_PRODUCT);

    useLoadingHandler(loading);

    const updataMerchandiseProductHandler = (productId, updateData) => {
        MerchandiseProductUpdate({
            variables: { productId, updateData },
        });
    };

    return [updataMerchandiseProductHandler, { loading, error, data, refetch }];
};