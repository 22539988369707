import gql from "graphql-tag";

export const GET_CONTRIBUTION_ANALYSIS_REPORT = gql`
  query getContributionAnalysisReport(
    $fromDate: String
    $toDate: String
    $isHostel: Boolean
  ) {
    getContributionAnalysisReport(
      fromDate: $fromDate
      toDate: $toDate
      isHostel: $isHostel
    ) {
      _id
      Sources
      TotalRevenue
      TotalRoomSold
      TotalADR
      hostelName
    }
  }
`;
