import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Trash } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateTrip } from "@api";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";

const ItenaryForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [updateTripHandler, { data }] = useUpdateTrip();
  const state = useSelector((state) => state?.cms);
  const [days, setDays] = useState([
    {
      id: uuidv4(),
      content: EditorState.createEmpty(),
    },
  ]);

  useEffect(() => {
    if (data?.updateTrip?.name) {
      toaster("success", "Submitted successfully");
    }
  }, [data?.updateTrip?.name]);

  useEffect(() => {
    if (state?.tableData?.itinerary?.length > 0) {
      setDays(
        state?.tableData?.itinerary?.map((item) => ({
          content: htmlToDraftConvert(item.content),
          id: uuidv4(),
        }))
      );
    }
  }, [state]);

  const handleChangeDays = (val, id) =>
    setDays(
      days.map((item) => (item.id === id ? { ...item, content: val } : item))
    );

  const handleRemoveDays = (id) =>
    setDays(days.filter((item) => item.id !== id));

  const handleAddDays = () =>
    setDays([...days, { id: uuidv4(), content: EditorState.createEmpty() }]);

  const submitDataHandler = (e) => {
    e.preventDefault();

    let isValid = false;

    let updatedArray = days.map((item) => ({
      content: inputDataConvertor(item?.content),
    }));

    days?.forEach((data) => {
      inputDataConvertor(data?.content) ? (isValid = true) : (isValid = false);
    });

    if (isValid) {
      const data = {
        itinerary: updatedArray,
      };

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );

      updateTripHandler(id, data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form onSubmit={submitDataHandler}>
      <Row className='mt-2'>
        {days.map(({ content, id }, index) => (
          <Col className='d-flex align-items-center' sm='12' key={id}>
            <FormGroup className='w-100'>
              <Label className='form-label' for={"days" + id}>
                Day {index + 1}
              </Label>
              {/* <Input
                autoFocus={index === 0}
                rows={4}
                type='textarea'
                value={content}
                id={"days" + id}
                name={"days" + id}
                placeholder='Add Itinerary'
                onChange={(e) => handleChangeDays(e.target.value, id)}
              /> */}

              <MyEditor
                editorState={content}
                setEditorState={(val) => handleChangeDays(val, id)}
              />
            </FormGroup>
            {days.length > 1 ? (
              <>
                <Button.Ripple
                  className='btn-icon rounded-circle ml-1'
                  color='light'
                  type='button'
                  size='sm'
                  onClick={() => handleRemoveDays(id)}
                >
                  <Trash size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddDays} />
    </form>
  );
};

export default ItenaryForm;
