import React from "react";
import AddEditModal from "./AddEditModal";
import MessageTemplateTable from "../../../@core/components/tables/MessageTemplateTable";

const MessageTemplate = () =>
  localStorage.getItem("modules")?.includes("Instant Communication") && (
    <MessageTemplateTable AddEditModal={AddEditModal} />
  );

export default MessageTemplate;
