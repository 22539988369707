import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdatePartner } from "@api";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";

const WhyHosteller = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [heading, setHeading] = useState("");
  const [cards, setCards] = useState([
    {
      id: uuidv4(),
      title: "",
      description: "",
    },
  ]);

  const [updatePartner, { data, reset }] = useUpdatePartner();

  useEffect(() => {
    if (state.tableData?.whyHosteller) {
      setHeading(state.tableData.whyHosteller.heading);

      let upadtedCards = state.tableData.whyHosteller.cards?.map((item) => {
        return {
          id: uuidv4(),
          title: item?.title,
          description: item?.description,
        };
      });

      setCards(upadtedCards);
    }
  }, [state.tableData]);

  useEffect(() => {
    if (data?.updatePartner) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          whyHosteller: { heading, cards },
        })
      );

      reset();
    }
  }, [data?.updatePartner]);

  const handleRemoveCards = (id) =>
    setCards(cards.filter((item) => item.id !== id));

  const handleAddCards = () => {
    if (cards.length < 8) {
      setCards([...cards, { id: uuidv4(), title: "", description: "" }]);
    } else toaster("error", "Maximum limit of 8 has reached");
  };

  const handleChangeCardName = (name, id) => {
    setCards(
      cards.map((item) => (item.id === id ? { ...item, title: name } : item))
    );
  };

  const handleChangeCardContent = (description, id) => {
    setCards(
      cards.map((item) =>
        item.id === id ? { ...item, description: description } : item
      )
    );
  };

  const submitDataHandler = (e) => {
    e.preventDefault();

    let isValid = false;

    const whyHosteller = {
      heading,
      cards,
    };

    cards?.forEach((item) => {
      if (item.title && item.description) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (heading && isValid) {
      cards?.forEach((item) => delete item.id);

      updatePartner({ whyHosteller });
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className='mt-2' onSubmit={submitDataHandler}>
      <Row>
        <Col className='ml-1' sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='heading'>
              Heading
            </Label>

            <Input
              type='text'
              value={heading}
              id='heading'
              name='heading'
              placeholder='Heading'
              onChange={(e) => setHeading(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>

      <br />

      <Col className='mb-2' sm='12'>
        <h3>Cards (Max - 8)</h3>
      </Col>

      {cards.map(({ title, description, id }, index) => (
        <Col className='d-flex align-items-center' sm='12' key={id}>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor={"cards" + id}>
              Card {index + 1}
            </Label>

            <Input
              type='text'
              value={title}
              id={"cards" + id}
              name={"Title"}
              placeholder='Title'
              onChange={(e) => handleChangeCardName(e.target.value, id)}
            />
            <br />
            <Input
              rows={4}
              type='textarea'
              value={description}
              id={"cards" + id}
              name={"Add content"}
              placeholder='Add Card'
              onChange={(e) => handleChangeCardContent(e.target.value, id)}
            />
          </FormGroup>
          {cards.length > 1 ? (
            <>
              <Button.Ripple
                className='btn-icon rounded-circle ml-1'
                color='light'
                type='button'
                size='sm'
                onClick={() => handleRemoveCards(id)}
              >
                <X size={14} />
              </Button.Ripple>
            </>
          ) : null}
        </Col>
      ))}
      <SaveDiscardBtnGroup addAction={handleAddCards} />
    </form>
  );
};

export default WhyHosteller;
