import React, { useEffect, useState } from "react";
import {
  useHostelList,
  useGetEmployeeLeaveBalanceByHostelId,
  useGetEmployeeLeaveBalanceByEmployeeId,
} from "../../../api/dataSource";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import { useHistory } from "react-router";
import EmployeeLeaveBalanceTable from "./EmployeeLeaveBalanceTable";
import AddEditModal from "./AddEditModal";

const EmployeeLeaveBalance = () => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [singleEmployee, setSingleEmployee] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");

  const { data: allHostelsData } = useHostelList();
  const { data: employeeLeaveBalanceData, refetch } =
    useGetEmployeeLeaveBalanceByHostelId(
      hostel?.value?.length > 0 ? hostel?.value : null
    );
  const { data: singleEmployeeLeaveBalanceData } =
    useGetEmployeeLeaveBalanceByEmployeeId(
      singleEmployee?.length > 0 ? singleEmployee : null
    );

  const history = useHistory();

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelList?.map((hostel) => ({
      label: hostel?.name,
      value: hostel?._id,
    }));

    setHostelsList(HostelsList);
  }, [allHostelsData]);

  useEffect(() => {
    let EmployeeList =
      employeeLeaveBalanceData?.getEmployeeLeaveBalanceListByHostelId?.map(
        (item) => ({
          label:
            item?.empId?.fullName &&
            `${item?.empId?.fullName} (${item?.empId?.empCode})`,
          value: item?.empId?.fullName && `${item?.empId?._id}`,
        })
      );

    setEmployeeList(EmployeeList);
  }, [employeeLeaveBalanceData]);

  useEffect(() => {
    if (
      employeeLeaveBalanceData?.getEmployeeLeaveBalanceListByHostelId?.length
    ) {
      setTableData(
        employeeLeaveBalanceData?.getEmployeeLeaveBalanceListByHostelId
      );
    } else {
      setTableData([]);
    }
  }, [employeeLeaveBalanceData]);

  useEffect(() => {
    setSingleEmployee(employee?.value);
  }, [employee]);

  useEffect(() => {
    if (singleEmployeeLeaveBalanceData?.getEmployeeLeaveBalanceById?.length) {
      setTableData(singleEmployeeLeaveBalanceData?.getEmployeeLeaveBalanceById);
    }
  }, [singleEmployeeLeaveBalanceData]);

  useEffect(() => {
    if (
      id &&
      employeeLeaveBalanceData?.getEmployeeLeaveBalanceListByHostelId?.length
    ) {
      let editData =
        employeeLeaveBalanceData?.getEmployeeLeaveBalanceListByHostelId?.find(
          (item) => item._id === id
        );
      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [employeeLeaveBalanceData?.getEmployeeLeaveBalanceListByHostelId, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/hr/edit/employeeLeaveBalance/${id}`);
    } else {
      setId(id);
    }
  };
  return (
    localStorage.getItem("modules")?.includes("Employee Profile") && (
      <div>
        <Row>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          {employeeLeaveBalanceData?.getEmployeeLeaveBalanceListByHostelId
            .length > 0 ? (
            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="employee">
                  Employee
                </Label>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  theme={selectThemeColors}
                  id="employee"
                  name="employee"
                  value={employee}
                  options={EmployeeList}
                  onChange={(value) => {
                    setEmployee(value);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          ) : null}
        </Row>
        {/* {employeeLeaveBalanceData?.getEmployeeLeaveBalanceListByHostelId.length >
      0 ? ( */}
        <>
          <EmployeeLeaveBalanceTable
            setId={setId}
            id={id}
            openModal={() => setOpenModal(!openModal)}
            data={tableData}
            handleEdit={handleEdit}
          />
          <AddEditModal
            open={openModal}
            editData={editData}
            handleToggle={toggleModal}
            refetch={refetch}
            setId={setId}
          />
        </>
        {/* ) : null} */}
      </div>
    )
  );
};

export default EmployeeLeaveBalance;
