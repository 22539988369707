import React, { useEffect, useState } from "react";
import { useGetAllCafeMasterList } from "../../../api/Unbox/UnboxCafeMaster/Queries";
import CafewiseTable from "../UnboxCafewiseMenu/CafewiseTable";
import LogModal from "./LogModal";

const Soldout = () => {
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [soldOutLog, setSoldOutLog] = useState(null);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const { data: allCafeMasterList } = useGetAllCafeMasterList();

  useEffect(() => {
    if (allCafeMasterList?.getAllCafeMasterList?.length) {
      setTableData(allCafeMasterList?.getAllCafeMasterList);
    } else {
      setTableData([]);
    }
  }, [allCafeMasterList?.getAllCafeMasterList]);
  return (
    <div>
      <CafewiseTable
        cafewiseMenuList={tableData}
        // openModal={openModal}
        // setOpenModal={setOpenModal}
        handleToggle={toggleModal}
        setSoldOutLog={setSoldOutLog}
      />
      <LogModal
        open={openModal}
        handleToggle={toggleModal}
        soldOutLog={soldOutLog}
      />
    </div>
  );
};

export default Soldout;
