import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useUpdateStepOutCategory,
  useUploadImage,
} from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const [updateStepOutCategoryHandler, { data: updateStepOutCategoryData }] =
    useUpdateStepOutCategory();

  const [
    uploadImageHandler,
    { data: imageData, reset: imageReset, loading: imageLoad },
  ] = useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setImage([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "images/StepOutCategory");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading media image: ${error.message}`);
  });

  const renderImagePreview = () =>
    image ? (
      <img className="rounded mr-1 mb-2 w-50" src={image} alt="avatar" />
    ) : null;

  useEffect(() => {
    setImage(imageData?.uploadImage?.url);
  }, [imageData]);

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData) {
      setTitle(state?.tableData?.overView?.title);
      setSubTitle(state?.tableData?.overView?.subTitle);
      setDescription(state?.tableData?.overView?.description);
      setImage(state?.tableData?.overView?.image);
    }
  }, [state]);

  const submitDataHandler = (e) => {
    e.preventDefault();

    let data = {
      overView: { title, subTitle, description, image },
    };
    if (id) {
      updateStepOutCategoryHandler(id, data);
      toaster("success", "Details updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <Row>
            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="title">
                  Title
                </Label>

                <Input
                  type="text"
                  value={title}
                  id="title"
                  name="title"
                  placeholder="Add title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="subTitle">
                  Sub Title
                </Label>

                <Input
                  type="text"
                  value={subTitle}
                  id="subTitle"
                  name="subTitle"
                  placeholder="Add subTitle"
                  onChange={(e) => setSubTitle(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="description">
                  Description
                </Label>

                <Input
                  type="textarea"
                  value={description}
                  id="description"
                  name="description"
                  placeholder="Add description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <p>Upload Image (608px X 499px)</p>
              <DragDrop
                note="Image size should be less than 500KB"
                width="100%"
                uppy={uppy}
                locale={{
                  strings: {
                    dropHereOr: "Drop image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
              <Col sm="13">
                <Label className="form-label w-100">Image preview here</Label>
                {imageLoad ? "Uploading image..." : renderImagePreview()}
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Details;
