export const DesignationList = [
  { label: "Senior General Manager", value: "Senior General Manager" },
  { label: "General Manager", value: "General Manager" },
  {
    label: "Assistant General Manager",
    value: "Assistant General Manager",
  },
  { label: "Front Desk Manager", value: "Front Desk Manager" },
  { label: "Front Desk Executive", value: "Front Desk Executive" },
  { label: "Front Desk Associate", value: "Front Desk Associate" },
  { label: "Cafe Manager", value: "Cafe Manager" },
  { label: "Assistant Cafe Manager", value: "Assistant Cafe Manager" },
  { label: "Cafe Executive", value: "Cafe Executive" },
  { label: "Head Chef", value: "Head Chef" },
  { label: "Chef", value: "Chef" },
  { label: "Bartender", value: "Bartender" },
  { label: "Helper", value: "Helper" },
  { label: "Server", value: "Server" },
  { label: "Utility (UT)", value: "Utility (UT)" },
  { label: "HK Supervisor", value: "HK Supervisor" },
  { label: "HK Executive", value: "HK Executive" },
  { label: "HK Associate", value: "HK Associate" },
  { label: "Multi Skilled Technician", value: "Multi Skilled Technician" },
  {
    label: "Multi Skilled Technician Supervisor",
    value: "Multi Skilled Technician Supervisor",
  },
  { label: "Security Guard", value: "Security Guard" },
  { label: "Intern", value: "Intern" },
  { label: "Others", value: "Others" },
  { label: "Community Manager", value: "Community Manager" },
];
