import React, { useEffect, useState } from "react";
import { Edit2 } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
const tableHeaderData = ["Category Name", "Status", "Action"];

const StepOutBlogCategoryTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Blog Categories</h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Category
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((element, i) => (
                <tr key={uuidv4()}>
                  <td>{element?.categoryName}</td>
                  <td>
                    <Badge
                      pill
                      color={element?.status ? "light-success" : "light-danger"}
                      className="mr-1"
                    >
                      {element?.status ? "Active" : "Inactive"}
                    </Badge>
                  </td>
                  <td>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(element._id);
                        openModal();
                      }}
                    >
                      <Edit2 size={15} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default StepOutBlogCategoryTable;
