import React from "react";
import SearchResultCard from "./SearchResultCard";
import { Card } from "reactstrap";

function SearchResultComponent({
  searchResult,
  setIsSearchResultComponentOpen,
}) {
  return (
    <>
      <div
        onClick={() => setIsSearchResultComponentOpen(false)}
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          // backgroundColor: "rgba(0, 0, 0, 0.5)",
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      ></div>
      <main
        style={{
          position: "absolute",
          top: `${searchResult?.length ? "75" : "75"}px`,
          right: "0",
          minWidth: "max-content",
          maxHeight: "400px",
          overflowY: "auto",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          justifyContent: "center",
          alignItems: "start",
          zIndex: 1001,
          paddingTop: `${searchResult?.length > 3 ? "140px" : "20px"}`, // 100px considering from top  of the SearchResultCard
          paddingBottom: `${searchResult?.length > 3 ? "50px" : "20px"}`,
          paddingLeft: "20px",
          boxShadow:
            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
        }}
      >
        {searchResult?.length ? (
          searchResult?.map((data) => (
            <div style={{ width: "100%" , minHeight : "max-content" }} key={data?.uniqueId}>
              <SearchResultCard
                source={data?.Source}
                name={data?.GuestName}
                reservationId={data?.ReservationNo}
                nights={data?.NoOfNights}
                roomType={data?.RatePlan}
                roomNo={data?.RoomNo}
                BookingStatus={data?.BookingStatus}
                mobileNo={data?.Mobile}
                email={data?.Email}
                ArrivalDate={data?.ArrivalDate}
                ArrivalTime={data?.ArrivalTime}
                DepartureDate={data?.DepartureDate}
                DepartureTime={data?.DepartureTime}
                bookingDate={data?.ReservationDate}
                totalAmount={data?.TotalInclusiveTax}
                Deposit={data?.Deposit}
                hostelId={data?.hostelId}
                Adult={data?.Adult}
                Child={data?.Child}
              />
            </div>
          ))
        ) : (
          <div style={{ width: "370px", padding: "20px 10px" }}>
            No Record Found
          </div>
        )}
      </main>
    </>
  );
}

export default SearchResultComponent;
