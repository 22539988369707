// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import { STEP_OUT_HOMEPAGE } from "../../../../utility/constant";
import confirmDelete from "@components/common/confirmDelete";
import {
  useGetAllStepOutLocationList,
  useUpdateStepOutHomePageDetails,
} from "../../../../api/dataSource";

const Blog = () => {
  const state = useSelector((state) => state?.cms);

  const dispatch = useDispatch();

  const [selectedLocation, setSelectedLocation] = useState([]);
  const [LocationsList, setLocationsList] = useState([]);

  const { data: allLocationsData } = useGetAllStepOutLocationList();

  const [updateStepOutHomePageDetailsHandler, { data: updatedLocationsData }] =
    useUpdateStepOutHomePageDetails();

  useEffect(() => {
    if (state?.tableData?.locations?.length) {
      let alreadySelectedLocations = state?.tableData?.locations?.map(
        (item) => {
          return {
            uuId: uuidv4(),
            locations: { label: item?.title, value: item?._id },
          };
        }
      );
      setSelectedLocation(alreadySelectedLocations);
    }
  }, [state]);

  const handleAddLocations = () => {
    if (selectedLocation?.length < 4) {
      setSelectedLocation([
        ...selectedLocation,
        {
          uuId: uuidv4(),
          locations: [],
        },
      ]);
    } else {
      toaster("error", "Max 4 Locations are allowed");
    }
  };
  const handleRemoveSelectedLocation = (uuId) =>
    confirmDelete(() =>
      setSelectedLocation(selectedLocation.filter((item) => item.uuId !== uuId))
    );

  const handleChangeSelectedLocation = (location, uuId) => {
    const isDuplicate = selectedLocation?.find(
      (item) => item?.locations?.value === location?.value
    );
    if (isDuplicate) {
      return toaster("error", "Location already present");
    }

    setSelectedLocation(
      selectedLocation?.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              locations: {
                label: location.label,
                value: location.value,
              },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let LocationsList = [];

    if (allLocationsData?.getAllStepOutLocation?.length) {
      // eslint-disable-next-line array-callback-return
      allLocationsData?.getAllStepOutLocation?.map((item) => {
        LocationsList.push({ label: item?.title, value: item?._id });
        setLocationsList(LocationsList);
      });
    }
  }, [allLocationsData?.getAllStepOutLocation]);

  const [finalLocationArr, setFinalLocationArr] = useState([]);

  useEffect(() => {
    if (updatedLocationsData) {
      let updatedLocations = [];
      finalLocationArr.forEach((i) => {
        updatedLocations.push(
          allLocationsData?.getAllStepOutLocation?.find((j) => j?._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          locations: updatedLocations,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allLocationsData?.getAllStepOutLocation,
    dispatch,
    finalLocationArr,
    updatedLocationsData,
  ]);

  const submitDataHandler = () => {
    var finalListOfLocationsId = [];
    selectedLocation?.forEach((item) => {
      finalListOfLocationsId.push(item.locations.value);
    });
    setFinalLocationArr(finalListOfLocationsId);
    let data = {
      locations: finalListOfLocationsId,
    };

    if (data?.locations?.length > 0) {
      if (data?.locations?.every((location) => location !== undefined)) {
        updateStepOutHomePageDetailsHandler(STEP_OUT_HOMEPAGE, {
          locations: data.locations,
        });
        toaster("success", "Locations updated successfully");
      } else {
        toaster("error", "Please select the locations");
      }
    } else {
      toaster("error", "Please select at least one location");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDataHandler();
      }}
    >
      <Row className="mt-2">
        {selectedLocation?.map(({ uuId, locations }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={uuId}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"reviews" + uuId}>
                Selected Location {index + 1}
              </Label>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Select
                      isSearchable={true}
                      id={uuId}
                      name="locations"
                      value={locations}
                      options={LocationsList}
                      onChange={(value) => {
                        handleChangeSelectedLocation(value, uuId);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {selectedLocation.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveSelectedLocation(uuId)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>
      <SaveDiscardBtnGroup addAction={handleAddLocations} />
    </form>
  );
};

export default Blog;
