import gql from "graphql-tag";

export const XLSX_UPLOAD = gql`
  mutation uploadXlsxFile($file: Upload!) {
    uploadXlsxFile(file: $file) {
      url
      key
    }
  }
`;
