import React, { useState, useEffect } from "react";
import {
  useGetOtaReviewsReport,
  useGetReviewSentimentAnalysis,
  useGetOverallAverageRating,
} from "../../api/dataSource";
import { Badge, Table } from "reactstrap";

const ORMSourceReport = () => {
  const [tableData, setTableData] = useState([]);
  const { data, refetch } = useGetOtaReviewsReport();
  const { data: sentimentAnalysisData, refetch: refetchSentimentAnalysis } =
    useGetReviewSentimentAnalysis();

  const { data: overallRatingData } = useGetOverallAverageRating();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    refetchSentimentAnalysis();
  }, [refetchSentimentAnalysis]);

  useEffect(() => {
    if (data?.getOtaReviewsReport?.length > 0) {
      setTableData(data?.getOtaReviewsReport);
    } else {
      setTableData([]);
    }
  }, [data]);

  const bookingSources = [
    { name: "bookingCom", headerName: "Booking.com" },
    { name: "hostelWorld", headerName: "Hostel World" },
    { name: "google", headerName: "Google" },
    { name: "goibibo", headerName: "Goibibo" },
    { name: "makeMyTrip", headerName: "MakeMyTrip" },
    { name: "tripadvisor", headerName: "TripAdvisor" },
  ];

  const bookingSourceImages = {
    "Booking.com":
      "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg",
    Goibibo:
      "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg",
    "Hostel World":
      "https://static.thehosteller.com/Hostel/Media/hostelworld%202%20(1)-1695295577721.svg",
    Google:
      "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg",
    MakeMyTrip:
      "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg",
    TripAdvisor:
      "https://static.thehosteller.com/Hostel/Media/Tripadvisor%20(1)-1695295571996.svg",
  };

  const formatRating = (rating) => {
    if (rating === null) {
      return "0";
    }

    return isNaN(rating) ? "0" : parseFloat(rating).toFixed(2);
  };

  return (
    <div>
      <h2>Overall Average Rating</h2>
      {/* <h5>
        Overall Rating:{" "}
        {overallRatingData?.getOverallAverageRating?.toFixed(2) || "N/A"}
      </h5> */}

      {/* <h2>Source Wise Rating Report</h2> */}
      <Table striped bordered>
        <thead>
          <tr>
            <th>Hostel Name</th>
            {bookingSources.map((source) => (
              <React.Fragment key={source.name}>
                <th className="text-center" colSpan="2">
                  <img
                    src={bookingSourceImages[source.headerName]}
                    alt={source.headerName}
                    style={{ height: "25px", width: "150px" }}
                  />
                </th>
              </React.Fragment>
            ))}
          </tr>

          <tr>
            <th></th>
            {bookingSources.map((source) => (
              <React.Fragment key={source.name}>
                <th style={{ fontSize: "12px", textAlign: "center" }}>
                  Avg. Rating
                </th>
                <th style={{ fontSize: "12px", textAlign: "center" }}>
                  Review Count
                </th>
              </React.Fragment>
            ))}
          </tr>
        </thead>

        <tbody>
          {tableData.map((hostel) => (
            <tr className="text-center" key={hostel?.hostelId}>
              <td>{hostel?.hostelName}</td>
              {bookingSources.map((source) => (
                <React.Fragment key={source?.name}>
                  <td>{formatRating(hostel[source?.name + "Rating"])}</td>
                  <td>{hostel[source?.name + "Count"]}</td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {/* New table for sentiment analysis data */}
      <h2>Sentiment Analysis</h2>
      <Table striped bordered style={{ width: "50%" }}>
        <thead>
          <tr>
            <th>Sentiment Category</th>
            <th>Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Badge color="dark">ORM</Badge>
            </td>
            <td>
              <Badge color="dark">
                {sentimentAnalysisData?.getReviewSentimentAnalysis?.orm?.toFixed(
                  2
                ) || "N/A"}
              </Badge>
            </td>
          </tr>
          <tr>
            <td>
              <Badge color="success">Positive Rate</Badge>
            </td>
            <td>
              <Badge color="success">
                {sentimentAnalysisData?.getReviewSentimentAnalysis?.positiveRate?.toFixed(
                  2
                ) || "N/A"}
              </Badge>
            </td>
          </tr>
          <tr>
            <td>
              <Badge color="warning">Neutral Rate</Badge>
            </td>
            <td>
              <Badge color="warning">
                {sentimentAnalysisData?.getReviewSentimentAnalysis?.neutralRate?.toFixed(
                  2
                ) || "N/A"}
              </Badge>
            </td>
          </tr>
          <tr>
            <td>
              <Badge color="danger">Negative Rate</Badge>
            </td>
            <td>
              <Badge color="danger">
                {sentimentAnalysisData?.getReviewSentimentAnalysis?.negativeRate?.toFixed(
                  2
                ) || "N/A"}
              </Badge>
            </td>
          </tr>
          <tr>
            <td>
              <Badge color="secondary">NPS</Badge>
            </td>
            <td>
              <Badge color="secondary">
                {sentimentAnalysisData?.getReviewSentimentAnalysis?.nps?.toFixed(
                  2
                ) || "N/A"}
              </Badge>
            </td>
          </tr>
          <tr>
            <td>
              <Badge color="dark">Review rate </Badge>
            </td>
            <td>
              <Badge color="dark">
                {sentimentAnalysisData?.getReviewSentimentAnalysis?.reviewRate?.toFixed(
                  2
                ) || "N/A"}
              </Badge>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ORMSourceReport;
