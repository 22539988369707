import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useUploadImage,
  useUpdateStepOutCategory,
} from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";

const Overview = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  //const [topDoddle, setTopDoddle] = useState(null);
  const [themeColor, setThemeColor] = useState("##FFFFFF");
  const [highlightedColor, setHighlightedColor] = useState("##FFFFFF");
  const [highlightedTextColor, setHighlightedTextColor] = useState("##FFFFFF");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [url, setUrl] = useState("/category/");
  const [
    uploadImageHandler,
    {
      data: bannerImageData,
      reset: bannerImageReset,
      loading: bannerImageLoad,
    },
  ] = useUploadImage();

  const [
    uploadTopDoodleImageHandler,
    { data: topDoddleData, reset: topDoddleReset, loading: topDoddleLoad },
  ] = useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2097152,
    },
  });

  const uppyTopDoodle = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2097152,
    },
  });

  uppy.use(thumbnailGenerator);
  uppyTopDoodle.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setBannerImage([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "icons/StepOut/Category");
    toaster("success", "Banner image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading banner image: ${error.message}`);
  });

  // uppyTopDoodle.on("thumbnail:generated", (file, preview) => {
  //   setTopDoddle([preview]);
  //   uploadTopDoodleImageHandler(file.data, "icons/StepOut/Category");
  // });

  const renderPreview = () =>
    bannerImage ? (
      <img className="rounded mr-1 mb-2 w-50" src={bannerImage} alt="avatar" />
    ) : null;

  // const renderTopDoodlePreview = () =>
  //   topDoddle ? (
  //     <img className="rounded mr-1 mb-2 w-50" src={topDoddle} alt="avatar" />
  //   ) : null;

  useEffect(() => {
    setBannerImage(bannerImageData?.uploadImage?.url);
  }, [bannerImageData]);

  // useEffect(() => {
  //   setTopDoddle(topDoddleData?.uploadImage?.url);
  // }, [topDoddleData]);

  const [updateStepOutCategoryHandler, { data: updateStepOutCategoryData }] =
    useUpdateStepOutCategory();

  const state = useSelector((state) => state?.cms);
  useEffect(() => {
    if (state?.tableData) {
      setName(state?.tableData?.name !== null ? state?.tableData?.name : "");
      setDescription(
        state?.tableData?.description !== null
          ? state?.tableData?.description
          : ""
      );
      setBannerImage(
        state?.tableData?.bannerImage !== null
          ? state?.tableData?.bannerImage
          : null
      );
      setThemeColor(
        state?.tableData?.theme?.themeColor !== null
          ? state?.tableData?.theme?.themeColor
          : "##FFFFFF"
      );
      setHighlightedColor(
        state?.tableData?.theme?.highlightedColor !== null
          ? state?.tableData?.theme?.highlightedColor
          : "##FFFFFF"
      );
      setHighlightedTextColor(
        state?.tableData?.theme?.highlightedTextColor !== null
          ? state?.tableData?.theme?.highlightedTextColor
          : "##FFFFFF"
      );
      setMetaTitle(
        state?.tableData?.seo?.metaTitle !== null
          ? state?.tableData?.seo?.metaTitle
          : ""
      );
      setMetaDesc(
        state?.tableData?.seo?.metaDesc !== null
          ? state?.tableData?.seo?.metaDesc
          : ""
      );
      setKeywords(
        state?.tableData?.seo?.keywords !== null
          ? state?.tableData?.seo?.keywords
          : ""
      );
      setUrl(
        state?.tableData?.url !== null ? state?.tableData?.url : "/category/"
      );
      // setTopDoddle(
      //   state?.tableData?.topDoddle !== null
      //     ? state?.tableData?.topDoddle
      //     : null
      // );
    } else {
      setName("");
      setDescription("");
      setBannerImage(null);
      setThemeColor("##FFFFFF");
      setHighlightedColor("##FFFFFF");
      setHighlightedTextColor("##FFFFFF");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setUrl("/category/");
      //setTopDoddle(null);
    }
  }, [state]);

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {
      name,
      description,
      bannerImage,
      theme: {
        themeColor,
        highlightedColor,
        highlightedTextColor,
      },
      seo: {
        metaTitle,
        metaDesc,
        keywords,
      },
      url,
      //topDoddle,
    };

    if (data.name && data.url && data?.url?.includes("/category/")) {
      updateStepOutCategoryHandler(id, data);
      toaster("success", "Overview updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else if (data.name && !data?.url?.includes("/category/")) {
      toaster("error", "Category Url must contain /category/ as prefix");
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="name">
                Name*
              </Label>
            </strong>
            <Input
              type="text"
              value={name}
              id="name"
              name="name"
              placeholder="Add name"
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="description">
                Description
              </Label>
            </strong>
            <Input
              type="textarea"
              value={description}
              id="description"
              name="description"
              placeholder="Add description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="url">
                Url* (format:- /category/categoryname)
              </Label>
            </strong>
            <Input
              type="textarea"
              value={url}
              id="url"
              name="url"
              placeholder="Add url"
              onChange={(e) => setUrl(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <strong>
            <Label className="form-label" for="themeColor">
              Theme
            </Label>
          </strong>

          <Row>
            <Col sm="4">
              <FormGroup className="w-100">
                <Label className="form-label" for="themeColor">
                  Theme color
                </Label>

                <Row>
                  <Col sm="6">
                    <Input
                      type="color"
                      value={themeColor}
                      id="themeColor"
                      name="themeColor"
                      placeholder="Add theme color"
                      onChange={(e) => setThemeColor(e.target.value)}
                    />
                  </Col>

                  <Col sm="6">
                    <Input
                      type="text"
                      value={themeColor}
                      id="themeColor"
                      name="themeColor"
                      placeholder="Add theme color"
                      onChange={(e) => setThemeColor(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            <Col sm="4">
              <FormGroup className="w-100">
                <Label className="form-label" for="highlightedColor">
                  Highlighted color
                </Label>

                <Row>
                  <Col sm="6">
                    <Input
                      type="color"
                      value={highlightedColor}
                      id="highlightedColor"
                      name="highlightedColor"
                      placeholder="Add highlighted color"
                      onChange={(e) => setHighlightedColor(e.target.value)}
                    />
                  </Col>

                  <Col sm="6">
                    <Input
                      type="text"
                      value={highlightedColor}
                      id="highlightedColor"
                      name="highlightedColor"
                      placeholder="Add highlighted color"
                      onChange={(e) => setHighlightedColor(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            <Col sm="4">
              <FormGroup className="w-100">
                <Label className="form-label" for="highlightedTextColor">
                  Highlighted text color
                </Label>

                <Row>
                  <Col sm="6">
                    <Input
                      type="color"
                      value={highlightedTextColor}
                      id="highlightedTextColor"
                      name="highlightedTextColor"
                      placeholder="Add highlighted text color"
                      onChange={(e) => setHighlightedTextColor(e.target.value)}
                    />
                  </Col>

                  <Col sm="6">
                    <Input
                      type="text"
                      value={highlightedTextColor}
                      id="highlightedTextColor"
                      name="highlightedTextColor"
                      placeholder="Add highlighted text color"
                      onChange={(e) => setHighlightedTextColor(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </Col>

        <Col sm="12">
          <strong>
            <Label className="form-label" for="metaTitle">
              Seo
            </Label>
          </strong>
          <Row>
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="metaTitle">
                  Meta title
                </Label>

                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add meta title"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="metaDesc">
                  Meta desc.
                </Label>

                <Input
                  type="textarea"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add meta desc."
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="keywords">
                  Keywords
                </Label>

                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>

        <Col sm="6">
          <strong>
            <Label className="form-label">Banner Image (1250px X 375px)</Label>
          </strong>
          <DragDrop
            note="Image size should be less than 2MB"
            width="100%"
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: "Drop image here or %{browse}",
                browse: "browse",
              },
            }}
          />

          <Col sm="13">
            <Label className="form-label w-100">Image preview here</Label>
            {bannerImageLoad ? "Uploading icon..." : <>{renderPreview()}</>}
          </Col>
        </Col>

        {/* <Col sm="6">
          <strong>
            <Label className="form-label">Top Doddle Image</Label>
          </strong>
          <DragDrop
            note="Image size should be less than 2MB"
            width="100%"
            uppy={uppyTopDoodle}
            locale={{
              strings: {
                dropHereOr: "Drop image here or %{browse}",
                browse: "browse",
              },
            }}
          />

          <Col sm="13">
            <Label className="form-label w-100">Image preview here</Label>
            {topDoddleLoad ? (
              "Uploading icon..."
            ) : (
              <>{renderTopDoodlePreview()}</>
            )}
          </Col>
        </Col> */}
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Overview;
