// ! Coded by Ashwin

import gql from "graphql-tag";

export const GET_STEP_OUT_COUPON_BY_ID = gql`
  query {
    getStepOutCouponById(id: $id) {
      _id
      name
      private
      status
      promoCode
      product
      subCategory
      discount
      expirationDate
      maxBookingDate
      minBookingDate
      maxStayDate
      minStayDate
      duration
      quantity
      maxDiscountValue
      numberOfUsage
      products {
        _id
        title
      }
      minBookingAmount
      minimumNights
      maximumNights
      couponsPerUser
      couponsPerDay
      newUserOnly
      dormsOnly
      sameDayBooking
      excludedWeekDays
      blockDates
      logs
      }
  }
`;

export const GET_PAGINATED_STEP_OUT_COUPON_LIST = gql`
  query getPaginatedStepOutCouponList($filter: filter) {
    getPaginatedStepOutCouponList(filter: $filter) {
      data {
      _id
      name
      private
      status
      promoCode
      product
      subCategory
      discount
      expirationDate
      maxBookingDate
      minBookingDate
      maxStayDate
      minStayDate
      duration
      quantity
      maxDiscountValue
      numberOfUsage
      products {
        _id
        title
      }
      minBookingAmount
      minimumNights
      maximumNights
      couponsPerUser
      couponsPerDay
      newUserOnly
      dormsOnly
      sameDayBooking
      excludedWeekDays
      blockDates
      logs
      }
      count
      limit
      page
    }
  }
`;

