// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateStepOutLocation } from "../../../api/dataSource";
import { Row, FormGroup, Input, Label, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import ImageSection from "../StepOutProduct/ImageSection";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadType = [
  {
    value: "general",
    label: "General",
  },
];

const LocationGallery = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageList, setImageList] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);

  const [updateStepOutLocationHandler, { data: updateStepOutLocationData }] =
    useUpdateStepOutLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (imageList?.length === 0) {
      state?.tableData?.galleryImage?.length &&
        setImageList(state?.tableData?.galleryImage);
    }
    setTitle(state?.tableData?.title);
    setDescription(state?.tableData?.description);
  }, [imageList?.length, state]);

  useEffect(() => {
    let arrProcessedImages = [];
    imageList.forEach((i) => {
      let imageObj = {
        displayOrder: i.displayOrder,
        image: i?.image,
        name: i.name,
      };
      arrProcessedImages.push(imageObj);
    });

    setSortedImages(
      arrProcessedImages.sort((a, b) => a.displayOrder - b.displayOrder)
    );
  }, [imageList]);

  useEffect(() => {
    if (updateStepOutLocationData?.updateStepOutLocation?.title) {
      toaster("success", "Location gallery updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          galleryImage: imageList,
          title,
          description,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateStepOutLocationData?.updateStepOutLocation?.title]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const galleryImage = imageList.map((image) => ({
      image: image?.image,
      name: image?.name,
      displayOrder: image?.displayOrder,
    }));
    const data = {
      galleryImage,
      title,
      description,
    };

    updateStepOutLocationHandler(id, data);
  };

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="title">
                Title
              </Label>

              <Input
                type="text"
                value={title}
                id="title"
                name="title"
                placeholder="Add title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="description">
                Description
              </Label>

              <Input
                type="textarea"
                value={description}
                id="description"
                name="description"
                placeholder="Add description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Col>

          <ImageSection
            imageList={sortedImages}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default LocationGallery;
