// ** Navigation sections imports
import booking from "./booking";
import calendar from "./calendar";
import cms from "./cms";
import guests from "./guests";
import packages from "./packages";
import blogs from "./blogs"
import communications from "./communications";
import webApp from "./webApp";

// ** Merge & Export
export default [...cms, ...packages, ...booking, ...calendar, ...guests, ...blogs, ...communications, ...webApp];
