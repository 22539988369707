import React from "react";
import { Edit2 } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const tableHeaderData = ["Page Title", "Meta Title", "Keywords", "Actions"];

const OthersTable = ({ openModal, tableData, setId }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Other Pages SEO </h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add SEO Details
            </Button.Ripple>
          </CardHeader>

          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData?.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item.pageTitle}</td>
                  <td>{item.metaTitle}</td>
                  <td>{item.keywords}</td>
                  <td>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit2 size={15} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default OthersTable;
