import { useState, useEffect } from "react";
import { holidayList } from "./Holidays";

export default function DropDown(props) {
  const {
    employeeData,
    handleMarkAttendene,
    index,
    arrAttendance,
    attendanceDate,
    pl,
    cl,
    empName,
    wo,
    empStatus,
    logs,
    selectedHostel,
    empType,
  } = props;

  const [isHoliday, setIsHoliday] = useState(false);

  const AttendanceList = [
    { label: "P", value: "P" },
    { label: "A", value: "A" },
    { label: "HD", value: "HD" },
    { label: "WO", value: "WO" },
    { label: "CL", value: "CL" },
    { label: "PL", value: "PL" },
    { label: "LWP", value: "LWP" },
  ];

  const holidayAttendanceList = [
    { label: "HO", value: "HO" },
    { label: "HOP", value: "HOP" },
    { label: "LWP", value: "LWP" },
    { label: "A", value: "A" },
  ];

  // useEffect to check if the selected attendance date is a holiday.
  // It searches the holidayList array for a match with the formatted date string
  // of the attendanceDate. If a match is found, setIsHoliday is set to true.

  useEffect(() => {
    // Find a holiday in the holidayList that matches the formatted date string of attendanceDate.
    const holidayDate = holidayList.find((list) => {
      return list.value === attendanceDate.toDateString("en-CA");
    });

    // If a holidayDate is found, set the state variable setIsHoliday to true.
    if (holidayDate) {
      setIsHoliday(true);
    }
  }, [attendanceDate]);

  const empExit = ["Resigned", "Absconding", "Termination"];

  const currentDate = new Date();
  const prevDays = 1;
  const futureDays = 0;

  // Function to generate an array of allowed attendance dates within a specified range.
  // It takes the current date, the number of previous days, and the number of future days
  // as parameters and returns an array of formatted date strings within the date range.

  const attendanceAllowedDates = (currentDate, prevDays, futureDays) => {
    try {
      // Initialize an empty array to store formatted date strings.
      const dateArray = [];

      // Create Date objects for today, the start date, and the end date.
      const today = new Date(currentDate);
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - prevDays);
      const endDate = new Date(today);
      endDate.setDate(today.getDate() + futureDays);

      // Initialize a current date within the specified date range.
      let currentDateInRange = new Date(startDate);

      // Iterate through the date range and push formatted date strings into the array.
      while (currentDateInRange <= endDate) {
        dateArray.push(
          new Date(currentDateInRange).toLocaleDateString("en-CA")
        );
        currentDateInRange.setDate(currentDateInRange.getDate() + 1);
      }

      // Return the array of formatted date strings.
      return dateArray;
    } catch (error) {
      console.error(error);
    }
  };

  // Create a Date object representing the date one day before the current date.
  // This is useful for scenarios where a default date is needed, such as showing
  // an "absent" status if no specific date is provided.

  const showAbsentIfBlank = new Date();
  showAbsentIfBlank.setDate(showAbsentIfBlank.getDate() - 1);

  return (
    <div>
      {/* Conditionally render attendance status based on allowed dates and user role/hostel */}
      {(!attendanceAllowedDates(currentDate, prevDays, futureDays).includes(
        attendanceDate.toLocaleDateString("en-CA")
      ) &&
        localStorage.getItem("role") !== "HR") ||
      employeeData?.hostelId !== selectedHostel ? (
        // Display attendance status or 'A' for absent if no specific attendance is available
        <p className="border text-center mt-1" title={empName}>
          {arrAttendance?.find(
            (i) => i.customId === `emp-${employeeData?.empId}-${index}`
          )?.attendance
            ? arrAttendance?.find(
                (i) => i.customId === `emp-${employeeData?.empId}-${index}`
              )?.attendance
            : attendanceDate.toLocaleDateString("en-CA") <
              showAbsentIfBlank.toLocaleDateString("en-CA")
            ? "A"
            : "-"}
        </p>
      ) : (
        // Render a dropdown for selecting attendance if allowed
        <select
          name="attendancePerDay"
          style={{ cursor: "pointer" }}
          title={empName}
          id={`emp-${employeeData?.empId}-${index}`}
          onChange={(e) => {
            // Handle attendance marking when the selection changes
            const empData = {
              ...employeeData,
              attendance: e.target.value,
              customId: e.target.id,
              logs: logs
                ? [
                    ...logs,
                    `${localStorage.getItem("name")} - ${localStorage.getItem(
                      "role"
                    )} marked this attendance as ${
                      e.target.value
                    } on ${attendanceDate.toLocaleDateString("en-IN", {
                      hour12: false,
                    })}`,
                  ]
                : [
                    `${localStorage.getItem("name")} - ${localStorage.getItem(
                      "role"
                    )} marked this attendance as ${
                      e.target.value
                    } on ${attendanceDate.toLocaleDateString("en-IN", {
                      hour12: false,
                    })}`,
                  ],
            };
            if (`emp-${employeeData?.empId}-${index}` === `${e.target.id}`) {
              handleMarkAttendene(empData, e.target.id);
            }
          }}
          value={
            arrAttendance?.find(
              (i) => i.customId === `emp-${employeeData?.empId}-${index}`
            )?.attendance
              ? arrAttendance?.find(
                  (i) => i.customId === `emp-${employeeData?.empId}-${index}`
                )?.attendance
              : "select"
          }
          disabled={
            (!attendanceAllowedDates(
              currentDate,
              prevDays,
              futureDays
            ).includes(attendanceDate.toLocaleDateString("en-CA")) &&
              localStorage.getItem("role") !== "HR") ||
            employeeData?.hostelId !== selectedHostel
              ? true
              : false
          }
        >
          {/* Render dropdown options based on attendance list and conditions */}
          <option value=""></option>
          {isHoliday === false
            ? AttendanceList.map((attendance, index) => {
                return (
                  <option
                    key={index}
                    value={attendance.value}
                    disabled={
                      (attendance.value === "PL" && (pl < 1 || !pl)) ||
                      (attendance.value === "CL" && (cl < 1 || !cl)) ||
                      (attendance.value === "WO" && wo >= 4) ||
                      (attendance.value === "PL" && empType === "Agency") ||
                      (attendance.value === "CL" && empType === "Agency") ||
                      empExit.includes(empStatus)
                        ? true
                        : false
                    }
                    title={employeeData.empName}
                    style={{
                      backgroundColor: `${
                        (attendance.value === "PL" && (pl <= 0 || !pl)) ||
                        (attendance.value === "CL" && (cl <= 0 || !cl))
                          ? `#EBEBE4`
                          : ``
                      }`,
                      padding: "10px",
                    }}
                  >
                    {attendance.label}
                  </option>
                );
              })
            : holidayAttendanceList.map((attendance, index) => {
                return (
                  <option key={index} value={attendance.value}>
                    {attendance.label}
                  </option>
                );
              })}
        </select>
      )}
    </div>
  );
}
