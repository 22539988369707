import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_MENU_CATEGORY = gql`
  query GetPaginatedMenuCategory($filter: filter) {
    getPaginatedUnboxMenuCategory(filter: $filter) {
      data {
        _id
        categoryName
        displayOrder
      }
      count
      limit
      page
    }
  }
`;

export const useGetPaginatedMenuCategory = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_MENU_CATEGORY,
    {
      variables: {
        filter: { limit, page },
      },
    }
  );
  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ALL_MENU_CATEGORY_LIST = gql`
  query GetAllUnboxMenuCategory {
    getAllUnboxMenuCategory {
      categoryName
      displayOrder
    }
  }
`;

export const useGetMenuCategoryList = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_MENU_CATEGORY_LIST
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};
