import React from "react";
import { Edit, Trash2 } from "react-feather";
import { Badge, Button } from "reactstrap";
import { dateFormatHandler } from "@utils";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";

const ReviewsLinksTableBody = ({
  tableRowData,
  openModal,
  handleEdit,
  handleDelete,
}) => {
  return (
    <>
      {tableRowData.map(({ name, createdAT, status, id }) => (
        <tr key={id}>
          <td style={{ width: "5%" }}>
            <Button
              className="rounded-circle btn-icon"
              color="none"
              onClick={(e) => {
                e.preventDefault();
                openModal();
                handleEdit(id);
              }}
            >
              <Edit
                color={reactFeatherIcon.iconColor}
                size={reactFeatherIcon.iconSize}
              />
            </Button>

            {handleDelete && (
              <Button
                className="rounded-circle btn-icon"
                color="none"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(id);
                }}
              >
                <Trash2
                  color={reactFeatherIcon.iconColor}
                  size={reactFeatherIcon.iconSize}
                />
              </Button>
            )}
          </td>
          <td>
            <span className="align-middle font-weight-bold">{name}</span>
          </td>
          <td>{dateFormatHandler(createdAT)}</td>
          <td>
            <Badge
              pill
              color={status ? "light-success" : "light-danger"}
              className="mr-1"
            >
              {status ? "Active" : "Inactive"}
            </Badge>
          </td>
        </tr>
      ))}
    </>
  );
};

export default ReviewsLinksTableBody;
