import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";

export const GET_ALL_OTHERS_SEO = gql`
    query {
    getAllOthersSeo{
        _id
        metaDescription
        metaImage
        metaTitle
        pageTitle
        keywords
    }
}
`
export const useGetAllOthersSeo = () => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_OTHERS_SEO);
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};
