// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, Row, FormGroup, Label, Input } from "reactstrap";
import { dateFormatHandler } from "@utils";
import SlideModal from "@components/common/slide-modal";
import { useUpdateEzeeBookingRemarks } from "../../api/dataSource";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import toaster from "@components/common/Toaster";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [source, setSource] = useState("");
  const [reservationNo, setReservationNo] = useState("");
  const [guestName, setGuestName] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [reservationDate, setReservationDate] = useState("");
  const [voucherNo, setVoucherNo] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [alternateEmail, setAlternateEmail] = useState("");
  const [alternateMobile, setAlternateMobile] = useState("");
  const [updateEzeeBookingRemarksHandler, data] = useUpdateEzeeBookingRemarks();

  // useEffect(() => {
  //   if (data) {
  //     refetch();
  //     handleToggle();

  //     toaster("success", "Remark added successfully");
  //   }
  // }, [data]);

  useEffect(() => {
    if (editData?._id) {
      setSource(editData.Source);
      setGuestName(editData.GuestName);
      setReservationNo(editData.uniqueId);
      setDueAmount(editData.DueAmount);
      setHostelName(editData.hostelName);
      setArrivalDate(editData.ArrivalDate);
      setDepartureDate(editData.DepartureDate);
      setReservationDate(editData.ReservationDate);
      setVoucherNo(editData.VoucherNo);
      setStatus(editData.Status);
      setMobile(editData.Mobile);
      setEmail(editData.Email);
      setAlternateEmail(editData?.alternateEmail);
      setAlternateMobile(editData?.alternateMobile)
    } else {
      setSource("");
      setGuestName("");
      setReservationNo("");
      setDueAmount("");
      setHostelName("");
      setArrivalDate("");
      setDepartureDate("");
      setReservationDate("");
      setVoucherNo("");
      setStatus("");
      setMobile("");
      setEmail("");
      setAlternateEmail("");
      setAlternateMobile("")
    }
  }, [editData]);

  const submitDataHandler = () => {
    const data = {
      uniqueId: editData?.uniqueId,
      remarksLogUpdate: remarks,
      alternateEmail,
      alternateMobile,
    };
    if (data?.uniqueId && data?.remarksLogUpdate) {
      updateEzeeBookingRemarksHandler(data?.uniqueId, data?.remarksLogUpdate, data?.alternateEmail, data?.alternateMobile);
      toaster("success", "Remark added successfully");
    } else {
      toaster("error", "Please fill all the required details");
    }
    setRemarks("");
    refetch();
    handleToggle();
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => submitDataHandler()}
    >
      <form>
        <Row>
          {/* // ! Due Amount */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="dueAmount">
                <strong>Due Amount*</strong>
              </Label>
              <br />
              <Label className="form-label" for="dueAmount">
                {dueAmount}
              </Label>

              <hr />
            </FormGroup>
          </Col>

          {/*// ! Booking Details*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="source">
                <strong>Booking Details*</strong>
              </Label>
              <br />
              <Label className="form-label" for="hostelName">
                {hostelName}
              </Label>
              <br />
              <Label className="form-label" for="reservationNo">
                {reservationNo}
              </Label>
              <br />
              <Label className="form-label" for="source">
                {source}
              </Label>
              <br />
              <Label className="form-label" for="arrivalDate - departureDate">
                {dateFormatHandler(arrivalDate)} -{" "}
                {dateFormatHandler(departureDate)}
              </Label>
              <br />
              <Label className="form-label" for="reservationDate">
                {dateFormatHandler(reservationDate)}
              </Label>
              <br />
              <Label className="form-label" for="voucherNo">
                {voucherNo}
              </Label>
              <br />
              <Label className="form-label" for="status">
                {status}
              </Label>
              <hr />
            </FormGroup>
          </Col>

          {/* // ! Guest Details */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="source">
                <strong>Guest Details*</strong>
              </Label>
              <br />
              <Label className="form-label" for="guestName">
                {guestName}
              </Label>
              <br />
              <Label className="form-label" for="mobile">
                {mobile}
              </Label>
              <br />
              <Label className="form-label" for="email">
                {email}
              </Label>
              <hr />
            </FormGroup>
          </Col>

          <Col>
            <Label for="alternateEmail">Alternate Email</Label>
            <Input
              type="text"
              value={alternateEmail}
              id="alternateEmail"
              name="alternateEmail"
              placeholder="Alternate Email"
              onChange={(e) => setAlternateEmail(e.target.value)}
            />

            <Label for="alternateEmail">Alternate Phone Number</Label>
            <Input
              type="text"
              value={alternateMobile}
              id="alternatePhone"
              name="alternatePhone"
              placeholder="Alternate Phone Number"
              onChange={(e) => setAlternateMobile(e.target.value)}
            />
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="remarks">
                  Remarks*
                </Label>
              </strong>
              <Input
                type="textarea"
                value={remarks}
                id="remarks"
                name="remarks"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="source">
                <strong>Logs</strong>
              </Label>
              <br />

              {editData?.logs !== null
                ? editData?.logs?.map((log) => {
                    return (
                      <Label className="form-label" for="logs">
                        {log}.
                      </Label>
                    );
                  })
                : []}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
