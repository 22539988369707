import { DollarSign, Circle } from "react-feather";

// const role = localStorage.getItem("role");

export default // role === "Super Admin" ||
localStorage.getItem("modules")?.includes("Wallet")
  ? [
      {
        id: "wallet",
        title: "Wallet",
        icon: <DollarSign size={20} />,
        badge: "light-warning",
        children: [
          {
            id: "walletBalance",
            title: "Wallet Transaction",
            icon: <Circle size={12} />,
            navLink: "/wallet/walletBalance",
          },
          {
            id: "walletReport",
            title: "Wallet Balance Report",
            icon: <Circle size={12} />,
            navLink: "/wallet/walletReport",
          },
        ],
      },
    ]
  : [];
