import { useState, useEffect } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import {
  useCreateEmployeeAttendance,
  useGetEmployeeAttendanceByHostelId,
  useGetHostelWiseLeaveBalance,
  useUploadImage,
  useSaveEmployeePaySlip,
  useGetEmployeePaySlips,
  useSaveMultiplePaySlips,
  useGetEmployeeAttendanceExportFile,
} from "../../../api/dataSource";
import DropDown from "./DropDown";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import OtModal from "./OtModal";
import UpdateEmployeeModal from "./UpdateEmployeeModal";
import { CSVLink } from "react-csv";
import LogsModal from "./LogsModal";
import { useCreateEmployeeMonthlyAttendance, useProcessEmployeeMonthlySalary, useViewEmployeeMonthlypaySlip } from "../../../api/EmployeeMonthlyAttendance/Mutation";
import toaster from "@components/common/Toaster";
const MySwal = withReactContent(Swal);
const tableStartFrom = 8;

const EmployeeAttendanceTable = (props) => {
  const {
    tableHeaderData,
    employeeProfileData,
    selectedHostel,
    selectedMonth,
    selectedYear,
    allHostelsData,
    refetchEmployeeProfile,
    dateArray,
  } = props;
  const hostelId = selectedHostel;
  const [showModal, setShowModal] = useState(false);
  const [showUpdateEmployeeModal, setShowUpdateEmployeeModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedEmployeeForUpdate, setSelectedEmployeeForUpdate] =
    useState(null);
  const [createEmployeeAttendanceHandler] = useCreateEmployeeAttendance();
  const [arrAttendance, setArrAttendance] = useState([]);
  const [employeeAttendanceInput, setEmployeeAttendanceInput] = useState([]);
  const [overtime, setOvertime] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [bulkFiles, setBulkFilesData] = useState([]);

  const { data: hostelWiseLeaveBalanceData } = useGetHostelWiseLeaveBalance(
    hostelId,
    selectedYear,
    selectedMonth
  );

  const { data: employeeAttendanceData, refetch: refetchAttendance } =
    useGetEmployeeAttendanceByHostelId(hostelId, selectedMonth, selectedYear);

  const { data: employeeAttendanceDataForExport, refetch } =
    useGetEmployeeAttendanceByHostelId(null, selectedMonth, selectedYear);
  const [createEmployeeMonthlyAttendanceHandler, { data: CreatingAttendanceData }] = useCreateEmployeeMonthlyAttendance()
  const [processEmployeeMonthlySalaryHandler, { data: processedSalaryData }] = useProcessEmployeeMonthlySalary()
  // const [getEmployeeMonthlyPayslipHandler, { data: paySlipData }] = useViewEmployeeMonthlypaySlip()
  // console.log(paySlipData)
  const [getEmployeeAttendanceExportFileHandler, { data: attendanceExportFile }] = useGetEmployeeAttendanceExportFile();
  const [uploadImageHandler] = useUploadImage();

  const [saveEmployeePaySlipHandler] = useSaveEmployeePaySlip();
  const [saveMultiplePaySlipsHandler] = useSaveMultiplePaySlips();

  const getPaySlipInput = {
    hostelId,
    month: selectedMonth,
    year: selectedYear,
  };

  const { data: getEmployeePaySlips, refetch: payslipRefetch } =
    useGetEmployeePaySlips(getPaySlipInput);

  useEffect(() => {
    // Check if employeeAttendanceData is available
    if (employeeAttendanceData) {
      // Map over the employeeAttendanceData to transform it into the desired format
      const empData =
        employeeAttendanceData?.getEmployeeAttendanceByHostelId?.map((emp) => {
          // Create an object with the required properties
          const empObject = {
            attendanceDate: new Date(emp.attendanceDate), // Convert attendanceDate to a Date object
            empId: emp?.empId?._id, // Extract empId from the nested structure
            attendance: emp?.attendance, // Extract attendance information
            empCode: emp?.empId?.empCode, // Extract empCode from the nested structure
            hostelId: emp?.hostelId?._id, // Extract hostelId from the nested structure
            overTime: emp?.overTime, // Extract overTime information
            logs: emp?.logs, // Extract logs information
            customId: `emp-${emp?.empId._id}-${new Date(
              emp.attendanceDate
            )?.getDate()}`, // Create a customId using empId and attendanceDate
          };
          return empObject; // Return the transformed object for each employee
        });

      // Set the transformed employee data in arrAttendance state
      setArrAttendance(empData);
    }
  }, [employeeAttendanceData]);

  useEffect(() => {
    // Check if arrAttendance is available
    if (arrAttendance) {
      // Initialize a new array to store modified objects
      const newArr = [];

      // Iterate over each object in arrAttendance
      arrAttendance.forEach((obj) => {
        // Destructure the object, extracting customId and the rest of the properties
        const { customId, ...rest } = obj;

        // Push the modified object (without customId) to the new array
        newArr.push(rest);
      });

      // Set the modified array as the state in employeeAttendanceInput
      setEmployeeAttendanceInput(newArr);
    }
  }, [arrAttendance]);

  const handleMarkAttendene = (attendance) => {
    try {
      // Filter out existing data for the given employee and date
      const alreadyExistingData = arrAttendance?.filter((i) => {
        return (
          (i?.empId === attendance?.empId &&
            i?.attendanceDate?.toLocaleDateString("en-IN", {
              timeZone: "Asia/Kolkata",
            }) !==
            attendance?.attendanceDate?.toLocaleDateString("en-IN", {
              timeZone: "Asia/Kolkata",
            })) ||
          i?.empId !== attendance?.empId
        );
      });

      // Combine existing data and the new attendance data
      const filteredData = [...alreadyExistingData, attendance];

      // Update the state with the modified attendance data
      setArrAttendance(filteredData);
    } catch (error) {
      console.error(error);
    }
  };

  const getPayableDays = (data) => {
    // Use the reduce function to iterate through the attendance data
    const count = data.reduce((accumulator, currentValue) => {
      // Switch statement to handle different attendance types
      switch (currentValue.attendance) {
        case "P":
        case "PL":
        case "CL":
        case "HO":
          // Full day present, PL (Paid Leave), CL (Casual Leave), HO (Holiday)
          return accumulator + 1;
        case "HD":
          // Half day present
          return accumulator + 0.5;
        case "HOP":
          // Holiday with Pay
          return accumulator + 2;
        default:
          break;
      }
      return accumulator;
    }, 0);

    // Return the total count of payable days
    return count;
  };

  // Function to calculate the total occurrences of a target value in an array of objects
  function fnCalculateTotalWeekOffs(arrayOfObjects, key, targetValue) {
    // Initialize a counter to store the total count
    let count = 0;

    // Iterate through each object in the array
    arrayOfObjects.forEach((obj) => {
      // Get the value associated with the specified key in the current object
      const value = obj[key];

      // Check if the value matches the target value
      if (value === targetValue) {
        // Increment the counter if there is a match
        count++;
      }
    });

    // Return the total count of occurrences
    return count;
  }

  // Function to calculate total payable days
  function getPayDays(data) {
    try {
      // Calculate the total present days using the getPayableDays function
      const totalPresent = getPayableDays(data);

      // Initialize the variable to store the total number of week-offs
      let totalWeekOff = 0;

      // Determine the total number of week-offs based on the total present days
      if (totalPresent >= 24) {
        totalWeekOff = 4;
      } else if (totalPresent >= 18) {
        totalWeekOff = 3;
      } else if (totalPresent >= 12) {
        totalWeekOff = 2;
      } else if (totalPresent >= 6) {
        totalWeekOff = 1;
      }

      // Return the total payable days by adding the total present days and week-offs
      return totalPresent + totalWeekOff;
    } catch (error) {
      // Handle errors and log them
      console.error(error);
    }
  }

  // Function to calculate total overtime
  function getTotalOT(employeeData) {
    try {
      // Use the reduce function to calculate the total overtime
      const totalOT = employeeData.reduce((accumulator, currentObject) => {
        // Add the overtime of the current object to the accumulator
        return (
          accumulator + (currentObject?.overTime ? currentObject?.overTime : 0)
        );
      }, 0);

      // Return the total overtime
      return totalOT;
    } catch (error) {
      // Handle errors and log them
      console.error(error);
    }
  }

  // Function to handle closing and updating employee profile modal
  function handleCloseUpdateProfile() {
    try {
      // Toggle the visibility of the update profile modal
      setShowUpdateEmployeeModal(!showUpdateEmployeeModal);

      // If the modal is being closed (was open before toggling)
      if (showUpdateEmployeeModal) {
        // Trigger a refetch of employee profile data
        refetchEmployeeProfile();
      }
    } catch (error) {
      // Handle errors and log them to the console
      console.error(error);
    }
  }

  // Current date
  const currentDate = new Date();

  // Number of days in the past to consider
  const prevDays = 1;

  // Number of days in the future to consider
  const futureDays = 0;

  // Create a date representing the first day of the selected month and year
  const passedDate = new Date(new Date().toLocaleDateString("en-CA"));

  // Set the month of the passedDate based on selectedMonth (adjusted to 0-index)
  passedDate.setMonth(selectedMonth - 1);

  // Set the year of the passedDate based on selectedYear
  passedDate.setFullYear(selectedYear);

  // Function to generate an array of formatted date strings within a date range
  const attendanceAllowedDates = (currentDate, prevDays, futureDays) => {
    try {
      // Initialize an empty array to store formatted date strings
      const dateArray = [];

      // Convert currentDate to a JavaScript Date object
      const today = new Date(currentDate);

      // Calculate the start date by subtracting prevDays from today
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - prevDays);

      // Calculate the end date by adding futureDays to today
      const endDate = new Date(today);
      endDate.setDate(today.getDate() + futureDays);

      // Initialize currentDateInRange with the startDate
      let currentDateInRange = new Date(startDate);

      // Loop through the date range and push formatted date strings to dateArray
      while (currentDateInRange <= endDate) {
        dateArray.push(
          new Date(currentDateInRange).toLocaleDateString("en-CA")
        );
        // Increment currentDateInRange by one day
        currentDateInRange.setDate(currentDateInRange.getDate() + 1);
      }

      // Return the array of formatted date strings
      return dateArray;
    } catch (error) {
      console.error(error);
    }
  };

  // Check if the currentDate is in the same month as passedDate
  const isCurrentMonth =
    currentDate.toLocaleDateString("en-CA") ===
      passedDate.toLocaleDateString("en-CA")
      ? true
      : false;

  const fnGetEmployeePaySlip = (empId) => {
    try {
      // Use the find method to locate the pay slip for the specified empId
      return getEmployeePaySlips?.getEmployeePaySlips.find(
        (emp) => emp.empId === empId
      );
    } catch (error) {
      // Log and handle errors if any
      console.error(error);
    }
  };

  const fnUploadPayslip = async (file, empId) => {
    try {
      // Use the uploadImageHandler function to upload the payslip file
      const payslipUrl = await uploadImageHandler(file, "payslip");

      // Prepare the payslip input data
      const paySlipInput = {
        hostelId: hostelId,
        empId: empId,
        month: selectedMonth,
        year: selectedYear,
        payslip: payslipUrl?.data?.uploadImage?.url,
      };

      // Save the payslip information using saveEmployeePaySlipHandler
      const payslip = await saveEmployeePaySlipHandler(paySlipInput);

      // If the payslip is successfully saved, trigger a refetch
      if (payslip) {
        payslipRefetch();
      }
    } catch (error) {
      // Log and handle errors if any
      console.error(error);
    }
  };

  const [updatedTableHeader, setUpdatedTableHeader] = useState(tableHeaderData);

  useEffect(() => {
    // Define additional headers to be added after the existing table headers
    const postTableHeader = [
      { label: "Pay Days", key: "payDays" },
      { label: "Total OT", key: "totalOT" },
      { label: "Employee Type", key: "empType" },
      { label: "Present", key: "Present" },
      { label: "WO", key: "wo" },
      { label: "HO", key: "ho" },
      { label: "HD", key: "hd" },
      { label: "PL", key: "paidLeave" },
      { label: "CL", key: "casualLeave" },
      { label: "HOP", key: "hop" },
      { label: "Mandays", key: "mandays" },
      { label: "Paid Days Without WO", key: "PaidDaysWithoutWO" },
      { label: "sys WO", key: "sysWO" },
      { label: "Paid Days", key: "paidDays" },
      { label: "WO + OT", key: "woOt" }
    ];

    // Combine the existing table headers with the additional headers
    const combinedData = tableHeaderData.concat(postTableHeader);

    // Define headers to be added at specific positions
    const addHostelName = { label: "Hostel Name", key: "hostelName" };
    const addDateOfExit = { label: "Date Of Exit", key: "dateOfExit" };

    // Add the new headers at specific positions in the combined data
    combinedData.splice(5, 0, addHostelName);
    combinedData.splice(7, 0, addDateOfExit);

    // Define fields to be removed from the combined data
    const fieldsToRemove = ["pl", "cl"];

    // Filter out the fields to be removed, resulting in the updated table header
    const newHeaders = combinedData.filter(
      (obj) => !fieldsToRemove.includes(obj.key)
    );
    //newHeaders.push({ label: "Agency Name", value: "agencyName" });

    // Set the updated table header in the component state
    setUpdatedTableHeader(newHeaders);
  }, [tableHeaderData]);

  const exportBuild = () => {
    try {
      // Initialize an empty array to store the merged data
      const mergedData = [];

      // Iterate through each employee in the attendance data
      employeeAttendanceDataForExport?.getEmployeeAttendanceByHostelId.forEach(
        (employee) => {
          // Create an object to store employee-specific data
          const attendanceData = getAttendanceCount(employeeAttendanceDataForExport?.getEmployeeAttendanceByHostelId?.filter(
            (emp) => emp?.empId?._id === employee?.empId?._id
          ))
          const employeeData = {
            Present: attendanceData?.P,
            // wo: attendanceData?.WO,
            ho: attendanceData?.HO,
            hd: attendanceData?.HD,
            paidLeave: attendanceData?.PL,
            casualLeave: attendanceData?.CL,
            hop: attendanceData?.HOP,
            mandays: attendanceData?.Manday,
            PaidDaysWithoutWO: attendanceData?.PaidDaysWithoutWO,
            sysWO: attendanceData?.sysWo,
            paidDays: attendanceData?.PaidDays,
            woOt: attendanceData?.WOoT,
            empCode: employee.empId?.empCode,
            empName: employee.empId?.fullName,
            hostelName: employee.hostelId?.name,
            doj: new Date(employee.empId?.doj).toLocaleDateString("en-CA"),
            subDepartment: employee.empId?.subDepartment,
            designation: employee.empId?.designation,
            empStatus: employee.empId?.empStatus,
            empType: employee.empId?.empType,
            //agencyName: employee.empId?.agencyName,
            dateOfExit:
              employee.empId?.dateOfExit !== null
                ? new Date(employee.empId?.dateOfExit).toLocaleDateString(
                  "en-IN"
                )
                : "",
            // pl: hostelWiseLeaveBalanceData?.getHostelwiseLeaveBalance?.find(
            //   (i) => i.empId === employee?.empId?._id
            // )?.PLCount,
            // cl: hostelWiseLeaveBalanceData?.getHostelwiseLeaveBalance?.find(
            //   (i) => i.empId === employee?.empId?._id
            // )?.CLCount,
            wo: fnCalculateTotalWeekOffs(
              employeeAttendanceDataForExport?.getEmployeeAttendanceByHostelId?.filter(
                (emp) => emp?.empId?._id === employee?.empId?._id
              ),
              "attendance",
              "WO"
            ),
            payDays: getPayDays(
              employeeAttendanceDataForExport?.getEmployeeAttendanceByHostelId?.filter(
                (emp) => emp?.empId?._id === employee?.empId?._id
              )
            ),
            totalOT: getTotalOT(
              employeeAttendanceDataForExport?.getEmployeeAttendanceByHostelId?.filter(
                (emp) => emp?.empId?._id === employee?.empId?._id
              )
            ),
          };
          // Initialize the dateArray with key-value pairs
          dateArray.forEach((dateObj) => {
            employeeData[dateObj.key] = "";
          });

          // Set the attendance data for the specific date
          employeeData[
            new Date(employee.attendanceDate).toLocaleDateString("en-CA")
          ] = employee.attendance;

          // Check if data for the employee already exists in mergedData
          const alreadyExistingData = mergedData.find(
            (emp) =>
              emp.empCode === employee.empCode &&
              emp?.hostelName === employee?.hostelId.name
          );

          // Update existing data if found, otherwise, add new data
          if (alreadyExistingData) {
            alreadyExistingData[
              new Date(employee.attendanceDate).toLocaleDateString("en-CA")
            ] = employee.attendance;
          } else {
            mergedData.push(employeeData);
          }
        }
      );

      // Set the merged data in the component state
      setExportData(mergedData);
    } catch (error) {
      console.error(error);
    }
  };

  const [openLogs, setOpenLogs] = useState(false);

  const isLogsAvailable = (arr) => {
    try {
      // Initialize a variable to track if logs are available
      let logsAvailable = false;

      // Iterate through each item in the array
      arr?.forEach((i) => {
        // Check if the current item has logs
        if (i?.logs?.length > 0) {
          // Set logsAvailable to true if logs are found
          logsAvailable = true;
        }
      });

      // Return the final result indicating whether logs are available
      return logsAvailable;
    } catch (error) {
      // Handle any errors that might occur during the execution
      console.error(error);
    }
  };

  useEffect(() => {
    // Check if employeeProfileData is available
    if (employeeProfileData) {
      // Refetch attendance data
      refetchAttendance();

      // Refetch payslip data
      payslipRefetch();

      // Refetch additional data (assuming it's provided by the refetch function)
      refetch();
    }
  }, [employeeProfileData, payslipRefetch, refetch, refetchAttendance]);

  const activeStatus = ["Probation", "Confirmed", "Resigned"];
  // const inactiveStatus = ["Termination", "Absconding", "Resigned"];

  const headerStyle = {
    fontSize: "12px",
    whiteSpace: "nowrap",
    textAlign: "center",
  };

  const createPaySlipData = async (list) => {
    let EmployeDataArray = [];
    for (let i = 0; i < list?.length; i++) {
      const item = {};
      let filename = list[i]?.name;
      let imageUrl;
      if (filename !== undefined) {
        const trimmedFilename = filename?.split("_");
        filename = trimmedFilename[0];
        const image = await uploadImageHandler(list[i]);
        imageUrl = image?.data?.uploadImage?.url;
      }
      item.empCode = filename;
      item.month = selectedMonth;
      item.year = selectedYear;
      item.payslip = imageUrl;
      EmployeDataArray.push(item);
    }
    const fileData = {
      files: EmployeDataArray,
    };
    const payslip = await saveMultiplePaySlipsHandler(fileData);
    if (payslip) {
      payslipRefetch();
    }
  };


  const getAttendanceCount = (data) => {
    const attendanceObject = {
      P: 0,
      WO: 0,
      HO: 0,
      HD: 0,
      PL: 0,
      CL: 0,
      HOP: 0,
      Manday: 0,
      PaidDaysWithoutWO: 0,
      sysWo: 0,
      PaidDays: 0,
      WOoT: 0,
    };

    // Use .forEach() for side effects
    const value = getTotalOT(data);
    data?.forEach((item) => {
      switch (item?.attendance) {
        case "P":
          attendanceObject.P++;
          break;
        case "WO":
          attendanceObject.WO++;
          break;
        case "HO":
          attendanceObject.HO++;
          break;
        case "HD":
          attendanceObject.HD++;
          break;
        case "PL":
          attendanceObject.PL++;
          break;
        case "CL":
          attendanceObject.CL++;
          break;
        case "HOP":
          attendanceObject.HOP++;
          attendanceObject.HO++;
          break;
        default:
          break;
      }
    });

    attendanceObject.Manday = attendanceObject.P + attendanceObject.WO + attendanceObject.HO + attendanceObject.PL + attendanceObject.CL + (attendanceObject.HD / 2);
    attendanceObject.PaidDaysWithoutWO = attendanceObject.P + attendanceObject.HO + attendanceObject.PL + attendanceObject.CL + (attendanceObject.HD / 2) + attendanceObject.HOP;

    if (attendanceObject.PaidDaysWithoutWO >= 24) {
      attendanceObject.sysWo = 4 - attendanceObject.WO;
    } else if (attendanceObject.PaidDaysWithoutWO >= 18) {
      attendanceObject.sysWo = 3 - attendanceObject.WO;
    } else if (attendanceObject.PaidDaysWithoutWO >= 12) {
      attendanceObject.sysWo = 2 - attendanceObject.WO;
    } else if (attendanceObject.PaidDaysWithoutWO >= 6) {
      attendanceObject.sysWo = 1 - attendanceObject.WO;
    } else {
      attendanceObject.sysWo = 0 - attendanceObject.WO;
    }

    if (attendanceObject.sysWo > 0) {
      attendanceObject.PaidDays = attendanceObject.Manday;
      attendanceObject.WOoT = attendanceObject.sysWo + value / 10;
    } else {
      attendanceObject.PaidDays = attendanceObject.Manday + attendanceObject.sysWo
      attendanceObject.WOoT = value / 10;

    }

    return attendanceObject;
  };


  // const compileAttendance = async () => {
  //   const compiledArray = [];
  //   console.log("calling")
  //   const attendanceCounts = {};

  //   await employeeAttendanceDataForExport?.getEmployeeAttendanceByHostelId.forEach((employee) => {
  //     const empId = employee?.empId?._id;
  //     const empCode = employee?.empCode;
  //     if (empId && !(empId in attendanceCounts)) {
  //       const attendanceData = getAttendanceCount(
  //         employeeAttendanceDataForExport?.getEmployeeAttendanceByHostelId.filter(
  //           (emp) => emp?.empId?._id === empId
  //         )
  //       );
  //       attendanceCounts[empId] = attendanceData;
  //       const attendance = {
  //         empId, ...attendanceData, empCode, selectedYear,
  //         selectedMonth, date: new Date(selectedYear, selectedMonth - 1, 2)
  //       };
  //       console.log(attendance);
  //       compiledArray.push(attendance)
  //     }
  //   });
  //   console.log(compiledArray, "compiled")
  //   await saveMonthlyDataToDB(compiledArray)
  // };

  // const saveMonthlyDataToDB = async (attendanceList) => {
  //   const attendanceListData = {
  //     attendance: attendanceList,
  //   };
  //   await createEmployeeMonthlyAttendanceHandler(attendanceListData)
  // }

  useEffect(() => {
    // console.log(CreatingAttendanceData?.createMonthlyAttendance?.message)
    if (CreatingAttendanceData?.createMonthlyAttendance?.message) {
      toaster("success", CreatingAttendanceData?.createMonthlyAttendance?.message)
    }

    if (CreatingAttendanceData?.createMonthlyAttendance?.xlsx?.data) {
      if (CreatingAttendanceData?.createMonthlyAttendance?.xlsx?.data.length > 0) {
        const byteArray = new Uint8Array(
          CreatingAttendanceData?.createMonthlyAttendance?.xlsx?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `monthlyAttendance.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [CreatingAttendanceData])

  useEffect(() => {

    if (attendanceExportFile?.getAttendanceXlsxExport?.data) {

      if (attendanceExportFile?.getAttendanceXlsxExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          attendanceExportFile?.getAttendanceXlsxExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ProcessedMonthlyAttendance.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [attendanceExportFile])



  useEffect(() => {
    if (processedSalaryData?.processEmployeeMonthlySalary?.message) {
      toaster("success", processedSalaryData?.processEmployeeMonthlySalary?.message)
    }
    if (processedSalaryData?.processEmployeeMonthlySalary?.xlsx) {
      if (processedSalaryData?.processEmployeeMonthlySalary?.xlsx?.data.length > 0) {
        const byteArray = new Uint8Array(
          processedSalaryData?.processEmployeeMonthlySalary?.xlsx?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ProcessedSalaries.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [processedSalaryData])

  // useEffect(() => {

  //   if (paySlipData?.getMonthlyPayslip?.buffer?.data) {

  //     if (paySlipData?.getMonthlyPayslip?.buffer?.data.length > 0) {
  //       const byteArray = new Uint8Array(
  //         paySlipData?.getMonthlyPayslip?.buffer?.data
  //       );
  //       const blob = new Blob([byteArray], {
  //         type: 'application/pdf',
  //       });
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = `paySlip.pdf`;
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       document.body.removeChild(a);
  //     }
  //   }
  // }, [paySlipData])
  return (
    <Row>
      {hostelId && (
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                Employee attendance (count:{" "}
                {employeeProfileData?.getEmployeeProfileForAttendance?.length})
              </CardTitle>
              {localStorage.getItem("role") === "HR" && (
                <Col>
                  <CSVLink
                    headers={updatedTableHeader}
                    data={exportData}
                    onClick={() => {
                      //refetch();
                      if (
                        employeeAttendanceDataForExport?.getEmployeeAttendanceByHostelId
                      ) {
                        exportBuild();
                      } else {
                        refetch();
                      }
                    }}
                    filename={"attendance.csv"}
                    className="float-right"
                  >
                    <Button.Ripple type="button" color="success">
                      Export
                    </Button.Ripple>
                  </CSVLink>
                </Col>
              )}

              {localStorage.getItem("role") === "HR" && (
                <Col>
                  <Button.Ripple
                    onClick={() => getEmployeeAttendanceExportFileHandler(selectedMonth, selectedYear)}
                    type="button"
                    color="danger"
                    className="ml-2"
                  >
                    Export
                  </Button.Ripple>

                  <Button.Ripple
                    type="button"
                    color="success"
                    onClick={() => createEmployeeMonthlyAttendanceHandler(selectedMonth, selectedYear)}
                    className="ml-2"
                  >
                    Process attendance
                  </Button.Ripple>

                  <Button.Ripple
                    type="button"
                    color="success"
                    onClick={() => processEmployeeMonthlySalaryHandler(selectedMonth, selectedYear)}
                    className="ml-2"
                  >
                    Process salary
                  </Button.Ripple>
                </Col>
              )}

            </CardHeader>
            <div>
              <Table size="sm" responsive hover>
                <thead>
                  <tr>
                    {tableHeaderData?.map((item, index) => {
                      return (
                        <th key={uuidv4()} style={headerStyle}>
                          {index < tableStartFrom ? (
                            item.label
                          ) : (
                            <>
                              {new Date(item.label)?.toLocaleDateString(
                                "en-IN"
                              )}
                              <br />
                              {new Date(item.label).toLocaleString("en-US", {
                                weekday: "short",
                              })}
                            </>
                          )}
                        </th>
                      );
                    })}
                    {tableHeaderData?.length > 0 && (
                      <>
                        <th key={uuidv4()} style={headerStyle}>
                          OT
                        </th>
                        <th key={uuidv4()}>WO</th>
                        <th key={uuidv4()} style={headerStyle}>
                          Pay days
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          Total OT
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          Present
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          WO
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          HO
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          HD
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          PL
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          CL
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          hop
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          mandays
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          Paid days without WO
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          Sys WO
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          Paid Days
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          HOP
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          WO + OT
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          {localStorage.getItem("role") === "HR" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              Upload Payslip
                              <label
                                htmlFor={`payslip`}
                                className="border border-2 rounded w-100 text-center"
                                style={{
                                  fontSize: "10px",
                                  minWidth: "100px",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontWeight: "bold",
                                  color:
                                    localStorage.getItem("role") === "HR"
                                      ? "#0099cc"
                                      : "gray",
                                  cursor: "pointer",
                                }}
                              >
                                Upload
                              </label>
                              <input
                                type="file"
                                className="d-none"
                                multiple
                                id={`payslip`}
                                name="payslip"
                                onChange={async (e) => {
                                  // fnUploadPayslip(
                                  //   e.target.files,
                                  //   // employeeData?._id
                                  // );
                                  createPaySlipData(e.target.files);
                                }}
                              />
                            </div>
                          )}
                        </th>
                        {localStorage.getItem("role") !== "Ground Staff" && (
                          <>
                            <th key={uuidv4()} style={headerStyle}>
                              View Payslip
                            </th>
                            <th key={uuidv4()} style={headerStyle}>
                              Emp Update
                            </th>
                            <th key={uuidv4()} style={headerStyle}>
                              View Logs
                            </th>
                          </>
                        )}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {employeeProfileData?.getEmployeeProfileForAttendance?.map(
                    (employeeData, index) => {
                      const attendanceData = getAttendanceCount(arrAttendance?.filter(
                        (emp) => emp?.empId === employeeData?._id
                      ))
                      return (
                        <tr key={uuidv4()}>
                          <td style={headerStyle}>
                            {employeeData?.empCode}
                          </td>
                          <td
                            style={headerStyle}
                            className={
                              employeeData?.hostel?._id !== hostelId
                                ? `text-danger`
                                : `text-black`
                            }
                          >
                            {employeeData?.fullName}{" "}
                            {employeeData?.hostel?._id !== hostelId &&
                              `(Transferred)`}
                          </td>
                          <td style={headerStyle}>
                            {new Date(employeeData?.doj).toLocaleDateString(
                              "en-IN",
                              {
                                timeZone: "Asia/Kolkata",
                              }
                            )}
                          </td>
                          <td style={headerStyle}>
                            {employeeData?.subDepartment}
                          </td>
                          <td style={headerStyle}>{employeeData?.designation}</td>
                          <td style={headerStyle}>
                            {employeeData?.empStatus === "Resigned" ? (
                              new Date(employeeData?.dateOfExit) > new Date() ? (
                                <span>Active - {employeeData?.empStatus}</span>
                              ) : (
                                <span>Inactive - {employeeData?.empStatus}</span>
                              )
                            ) : activeStatus.includes(employeeData?.empStatus) ? (
                              <span>Active - {employeeData?.empStatus}</span>
                            ) : (
                              <span>Inactive - {employeeData?.empStatus}</span>
                            )}
                          </td>
                          {/* <td style={headerStyle}>{employeeData?.empType}</td> */}
                          <td style={headerStyle}>
                            {hostelWiseLeaveBalanceData?.getHostelwiseLeaveBalance?.find(
                              (i) => i.empId === employeeData?._id
                            )?.PLCount === undefined ||
                              employeeData?.empType === "Agency"
                              ? 0
                              : Number(
                                hostelWiseLeaveBalanceData?.getHostelwiseLeaveBalance?.find(
                                  (i) => i.empId === employeeData?._id
                                )?.PLCount
                              )?.toFixed(2)}
                          </td>
                          <td style={headerStyle}>
                            {hostelWiseLeaveBalanceData?.getHostelwiseLeaveBalance?.find(
                              (i) => i.empId === employeeData?._id
                            )?.CLCount === undefined ||
                              employeeData?.empType === "Agency"
                              ? 0
                              : Number(
                                hostelWiseLeaveBalanceData?.getHostelwiseLeaveBalance?.find(
                                  (i) => i.empId === employeeData?._id
                                )?.CLCount
                              )?.toFixed(2)}
                          </td>
                          {tableHeaderData?.map((date, index) => {
                            return (
                              index >= tableStartFrom && (
                                <td key={index}>
                                  <DropDown
                                    employeeData={{
                                      attendanceDate: new Date(
                                        new Date(date.label).toLocaleDateString(
                                          "en-CA"
                                        )
                                      ),
                                      empId: employeeData?._id,
                                      empCode: employeeData?.empCode,
                                      hostelId: employeeData?.hostel?._id,
                                      overTime: overtime?.overTime,
                                    }}
                                    selectedHostel={hostelId}
                                    empName={employeeData?.fullName}
                                    attendanceDate={
                                      new Date(
                                        new Date(date.label).toLocaleDateString(
                                          "en-CA"
                                        )
                                      )
                                    }
                                    logs={
                                      arrAttendance?.find(
                                        (emp) =>
                                          emp?.empId === employeeData?._id &&
                                          emp?.attendanceDate.toLocaleDateString(
                                            "en-CA"
                                          ) ===
                                          new Date(
                                            date.label
                                          ).toLocaleDateString("en-CA")
                                      )?.logs
                                    }
                                    index={index - (tableStartFrom - 1)}
                                    handleMarkAttendene={handleMarkAttendene}
                                    arrAttendance={arrAttendance}
                                    pl={
                                      hostelWiseLeaveBalanceData?.getHostelwiseLeaveBalance?.find(
                                        (i) => i.empId === employeeData?._id
                                      )?.PLCount
                                    }
                                    cl={
                                      hostelWiseLeaveBalanceData?.getHostelwiseLeaveBalance?.find(
                                        (i) => i.empId === employeeData?._id
                                      )?.CLCount
                                    }
                                    wo={fnCalculateTotalWeekOffs(
                                      arrAttendance?.filter(
                                        (emp) => emp?.empId === employeeData?._id
                                      ),
                                      "attendance",
                                      "WO"
                                    )}
                                    empStatus={employeeData?.empStatus}
                                    empType={employeeData?.empType}
                                  />
                                </td>
                              )
                            );
                          })}
                          <td style={headerStyle}>
                            <Button.Ripple
                              type="button"
                              color={`${!isCurrentMonth &&
                                localStorage.getItem("role") !== "HR"
                                ? `secondary`
                                : `light`
                                }`}
                              style={{ fontSize: "10px" }}
                              disabled={
                                !isCurrentMonth &&
                                localStorage.getItem("role") !== "HR"
                              }
                              onClick={() => {
                                setShowModal(!showModal);
                                const emp = arrAttendance.filter(
                                  (i) => i.empId === employeeData._id
                                );
                                setSelectedEmployee(emp);
                              }}
                            >
                              Add OT
                            </Button.Ripple>
                          </td>
                          <td style={headerStyle}>
                            {fnCalculateTotalWeekOffs(
                              arrAttendance?.filter(
                                (emp) => emp?.empId === employeeData?._id
                              ),
                              "attendance",
                              "WO"
                            )}
                          </td>
                          <td style={headerStyle}>
                            {getPayDays(
                              arrAttendance?.filter(
                                (emp) => emp?.empId === employeeData?._id
                              )
                            )}
                          </td>
                          <td style={headerStyle}>
                            {getTotalOT(
                              arrAttendance?.filter(
                                (emp) => emp?.empId === employeeData?._id
                              )
                            )}
                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.P}
                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.WO}

                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.HO}

                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.HD}

                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.PL}

                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.CL}

                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.HOP}
                          </td>
                          <td style={headerStyle}>
                            {/* {attendanceData?.P + attendanceData?.WO + attendanceData?.HO + attendanceData?.PL + attendanceData?.CL + (attendanceData?.HD / 2)} */}
                            {attendanceData?.Manday}
                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.PaidDaysWithoutWO}
                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.sysWo}
                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.PaidDays}
                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.HOP}
                          </td>
                          <td style={headerStyle}>
                            {attendanceData?.WOoT}
                          </td>

                          {localStorage.getItem("role") !== "Ground Staff" && (
                            <>
                              <td style={headerStyle}>
                                {localStorage.getItem("role") === "HR" && (
                                  <>
                                    <label
                                      htmlFor={`payslip-${index}`}
                                      className="border border-2 rounded w-100 text-center"
                                      style={{
                                        fontSize: "10px",
                                        minWidth: "100px",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        fontWeight: "bold",
                                        color:
                                          localStorage.getItem("role") === "HR"
                                            ? "#0099cc"
                                            : "gray",
                                        cursor: "pointer",
                                      }}
                                    // onClick={() => { getEmployeeMonthlyPayslipHandler(employeeData?.empCode, selectedMonth, selectedYear) }}
                                    >
                                      Upload
                                    </label>
                                    <input
                                      type="file"
                                      className="d-none"
                                      id={`payslip-${index}`}
                                      name="payslip"
                                      onChange={async (e) => {
                                        fnUploadPayslip(
                                          e.target.files[0],
                                          employeeData?._id
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </td>
                              <td style={headerStyle}>
                                {fnGetEmployeePaySlip(employeeData?._id) && (
                                  <span
                                    className="border border-2 rounded w-100 text-center d-block"
                                    style={{
                                      fontSize: "10px",
                                      minWidth: "100px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                      fontWeight: "bold",
                                      color: "#0099cc",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      window.open(
                                        fnGetEmployeePaySlip(employeeData?._id)
                                          ?.payslip
                                      );
                                    }}
                                  >
                                    View
                                  </span>
                                )}
                              </td>
                              <td style={headerStyle}>
                                <Button.Ripple
                                  type="button"
                                  color="info"
                                  onClick={() => {
                                    handleCloseUpdateProfile();
                                    setSelectedEmployeeForUpdate(employeeData);
                                  }}
                                >
                                  Update
                                </Button.Ripple>
                              </td>
                              <td>
                                <Button.Ripple
                                  type="button"
                                  color={`${isLogsAvailable(
                                    arrAttendance?.filter(
                                      (i) => i.empId === employeeData._id
                                    )
                                  )
                                    ? `warning`
                                    : `secondary`
                                    }`}
                                  onClick={() => {
                                    const emp = arrAttendance?.filter(
                                      (i) => i.empId === employeeData._id
                                    );
                                    const logsAvailable = isLogsAvailable(emp);
                                    if (logsAvailable) {
                                      setOpenLogs(!openLogs);
                                      setSelectedEmployee(emp);
                                    }
                                  }}
                                >
                                  Logs
                                </Button.Ripple>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    }
                  )}
                  <tr>
                    {tableHeaderData?.map((item, index) => {
                      return index < tableStartFrom ? (
                        <td key={index}></td>
                      ) : (
                        <td key={index}>
                          <Button.Ripple
                            type="button"
                            className="text-center px-1"
                            style={{ padding: "7px" }}
                            color={
                              !attendanceAllowedDates(
                                currentDate,
                                prevDays,
                                futureDays
                              ).includes(
                                new Date(item.key).toLocaleDateString("en-CA")
                              ) && localStorage.getItem("role") !== "HR"
                                ? `secondary`
                                : `success`
                            }
                            key={index}
                            disabled={
                              !attendanceAllowedDates(
                                currentDate,
                                prevDays,
                                futureDays
                              ).includes(
                                new Date(item.key).toLocaleDateString("en-CA")
                              ) && localStorage.getItem("role") !== "HR"
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              MySwal.fire({
                                title: "Attention: Attendance Submission",
                                text: "Are you sure everything is correct?",
                                icon: "info",
                                showCancelButton: true,
                                showCloseButton: true,
                                confirmButtonColor: "#FFE700",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                                customClass: {
                                  confirmButton: "mr-1",
                                  cancelButton: "ml-1",
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  createEmployeeAttendanceHandler(
                                    employeeAttendanceInput
                                  );
                                }
                              });
                            }}
                          >
                            Save
                          </Button.Ripple>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
          <div className="note">
            Note:
            <p>
              <span>P = Present, </span>
              <span>A = Absent, </span>
              <span>HD = Half Day, </span>
              <span>WO = Weekly Off, </span>
              <span>CL = Casual Leave, </span>
              <span>PL = Paid Leave, </span>
              <span>LWP = Leave Without Pay, </span>
              <span>HO = Holiday, </span>
              <span>
                HOP = Holiday Pay ( if employee works on a public holiday)
              </span>
            </p>
          </div>
        </Col>
      )}
      {selectedEmployee && (
        <OtModal
          showModal={showModal}
          onClose={setShowModal}
          data={selectedEmployee}
          setOvertime={setOvertime}
          arrAttendance={arrAttendance}
          setArrAttendance={setArrAttendance}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
      )}
      {selectedEmployeeForUpdate && (
        <UpdateEmployeeModal
          showModal={showUpdateEmployeeModal}
          onClose={handleCloseUpdateProfile}
          data={selectedEmployeeForUpdate}
          allHostelsData={allHostelsData?.getHostelsNameandId}
          refetchEmployeeProfile={refetchEmployeeProfile}
        />
      )}

      {selectedEmployee && (
        <LogsModal
          showModal={openLogs}
          data={selectedEmployee}
          empData={employeeProfileData?.getEmployeeProfileForAttendance}
          onClose={setOpenLogs}
        />
      )}
    </Row>
  );
};

export default EmployeeAttendanceTable;
