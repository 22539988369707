import Feedback from "../../views/Feedback";

const FeedbackRoutes = [
  {
    path: "/feedback",
    exact: true,
    component: Feedback,
  },
];

export default FeedbackRoutes;
