import Offers from "../../views/Offers";

const OffersRoutes = [
  // {
  //   path: "/offers",
  //   exact: true,
  //   component: Offers,
  // },
];

export default OffersRoutes;
