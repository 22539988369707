//Added By Gaurav
import gql from "graphql-tag";

export const GET_CHATBOT_BOOKING = gql`
  query getChatbotBookings($filter: filter) {
    getChatbotBookings(filter: $filter) {
      count
      page
      limit
      data {
        status
        providerRefId
        payableAmount
        hostelId {
          name
        }
        bookingSource
        checkinDate
        checkoutDate
        createdAt
        contactDetails {
          name
          mobile
          email
          countryCode
          address {
            addressLine
            city
            state
            country
            pincode
          }
        }
      }
    }
  }
`;

//Added By Nitish
export const GET_CHATBOT_BOOKINGS_FOR_EXPORT = gql`
  query getChatbotBookingsForExport($filter: filter) {
    getChatbotBookingsForExport(filter: $filter) {
      data {
        status
        providerRefId
        payableAmount
        hostelId {
          name
        }
        checkinDate
        checkoutDate
        createdAt
      }
    }
  }
`;
