import gql from "graphql-tag";

// ! createEmployeeProfile added by Ashwin
export const CREATE_EMPLOYEE_PROFILE = gql`
  mutation createEmployeeProfile($employeeProfileInput: EmployeeProfileInput) {
    createEmployeeProfile(employeeProfileInput: $employeeProfileInput) {
      empCode
    }
  }
`;

// ! updateEmployeeProfile added by Ashwin
export const UPDATE_EMPLOYEE_PROFILE = gql`
  mutation updateEmployeeProfile(
    $id: ID!
    $employeeProfileUpdate: EmployeeProfileInput
  ) {
    updateEmployeeProfile(
      id: $id
      employeeProfileUpdate: $employeeProfileUpdate
    ) {
      empCode
    }
  }
`;

export const UPDATE_EMPLOYEE_TRANSFER_DETAILS = gql`
  mutation (
    $id: ID
    $employeeTransferUpdate: EmployeeTransferInput
    $fromAppraisal: Boolean
    $fromTransfer:Boolean
  ) {
    updateEmployeeTransferDetails(
      id: $id
      employeeTransferUpdate: $employeeTransferUpdate
      fromAppraisal: $fromAppraisal
      fromTransfer: $fromTransfer
    ) {
      _id
      dateOfExit
      empStatus
      exitReason
      hostel {
        _id
        name
      }
      resignedStatus
      transferDate
    }
  }
`;

export const CREATE_EMPLOYEE_ATTENDANCE = gql`
  mutation ($employeeAttendanceInput: [EmployeeAttendanceInput]) {
    createEmployeeAttendance(
      employeeAttendanceInput: $employeeAttendanceInput
    ) {
      status
    }
  }
`;

export const CREATE_EMPLOYEE_LEAVE_BALANCE = gql`
  mutation createEmployeeLeaveBalance(
    $employeeLeaveBalanceInput: EmployeeLeaveBalanceInput
  ) {
    createEmployeeLeaveBalance(
      employeeLeaveBalanceInput: $employeeLeaveBalanceInput
    ) {
      balance
    }
  }
`;

// ! updateEmployeeLeaveBalance added by Ashwin
export const UPDATE_EMPLOYEE_LEAVE_BALANCE = gql`
  mutation updateEmployeeLeaveBalance(
    $id: ID!
    $employeeLeaveBalanceUpdate: EmployeeLeaveBalanceInput
  ) {
    updateEmployeeLeaveBalance(
      id: $id
      employeeLeaveBalanceUpdate: $employeeLeaveBalanceUpdate
    ) {
      balance
    }
  }
`;

export const SAVE_EMPLOYEE_PAYSLIP = gql`
  mutation ($paySlipInput: paySlipInput) {
    saveEmployeePaySlip(paySlipInput: $paySlipInput) {
      empId
      payslip
    }
  }
`;

export const SAVE_MULTIPLE_PAYSLIP = gql`
  mutation ($multipleFileInput: multipleFileInput) {
    saveBulkEmployeePaySlip(multipleFileInput: $multipleFileInput)
  }
`;

export const UPLOAD_XLSX_FILE_EMPLOYEE_PROFILE = gql`
  mutation($file:Upload){
    uploadXlsxFileForEmployeeProfile(file:$file){
      status,
      message
    }
  }
`

export const UPLOAD_XLSX_FILE_EMPLOYEE_TRAINING_SCORE = gql`
  mutation($file:Upload){
    uploadXlsxFileForEmployeeTrainingScore(file:$file){
      status,
      message
    }
  }
`
export const UPLOAD_XLSX_FILE_EMPLOYEE_PERFORMANCE_EVAL = gql`
  mutation($file:Upload){
    uploadXlsxFileForEmployeePerformanceEval(file:$file){
      status,
      message
    }
  }
`
export const GET_XLSX_FORMAT_FOR_EMPLOYEE = gql`
mutation getXlsxFormatforEmployOperations($operationName:String){
  getXlsxFormatforEmployOperations(operationName:$operationName)
}
`

export const UPLOAD_XLSX_FILE_EMPLOYEE_SALARY_STRUCTURE = gql`
  mutation($file:Upload){
    uploadXlsxFileForEmployeeSalaryStructure(file:$file){
      status,
      message
    }
  }
`

export const GET_ATTENDANCE_DATA_EXPORT = gql`
  mutation($month:Int, $year:Int) {
    getAttendanceXlsxExport(month:$month, year:$year)
  }
`