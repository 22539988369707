import { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { Col, Card, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { useHostelRoomsByHostelId } from "../../../api/HostelRooms/index";
import HostelRoomsDataTable from "./HostelRoomsDataTable";

const tableHeaderData = ["Hostels", "Action"];

const HostelRoomsTable = ({ hostelListData }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedHostelId, setSelectedHostelId] = useState(null);
  const { data: roomListResponse, refetch } =
    useHostelRoomsByHostelId(selectedHostelId);

  useEffect(() => {
    refetch();
  }, [selectedHostelId, refetch]);

  useEffect(() => {
    if (hostelListData?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return hostelListData?.find((hostel) => hostel?._id === hostelID);
            })
          : hostelListData;
      setTableData(filteredHostel);
    }
  }, [hostelListData]);

  return (
    <Row>
      <Col sm="4">
        <Card>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr
                  key={uuidv4()}
                  onClick={() => {
                    setSelectedHostelId(item?._id);
                    refetch();
                  }}
                  className={selectedHostelId === item?._id ? "bg-primary" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    <b>{item?.name}</b>
                  </td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownItem>
                        <ChevronRight className="mr-50" size={17} />
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
              {/*  */}
            </tbody>
          </Table>
        </Card>
      </Col>
      <Col sm="8">
        <HostelRoomsDataTable
          requestListData={roomListResponse}
          selectedHostelId={selectedHostelId}
          refetch={refetch}
        />
      </Col>
    </Row>
  );
};

export default HostelRoomsTable;
