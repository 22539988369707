import React, { useEffect, useState } from "react";
import CheckoutTable from "./CheckoutTable";
import {
  useGetContactlessCheckoutList,
  useGetAllContactlessCheckoutData,
} from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import { useHostelList } from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import Flatpickr from "react-flatpickr";

const exportHeaders = [
  { label: "Reservation ID", key: "reservationID" },
  { label: "Hostel Name", key: "guestDetails.hostelName" },
  { label: "First Name", key: "guestDetails.FirstName" },
  { label: "Last Name", key: "guestDetails.LastName" },
  { label: "Check-in Date", key: "guestDetails.ArrivalDate" },
  { label: "Check-out Date", key: "guestDetails.DepartureDate" },
  { label: "Mobile", key: "guestDetails.Mobile" },
  { label: "Checkout Time", key: "checkoutTime" },
  { label: "Checkout Feedback", key: "checkOutFeedback" },
  { label: "Checkout Remark", key: "checkOutRemark" },
];

const ContactlessCheckout = () => {
  const [tableData, setTableData] = useState([]);
  const [exportTableData, setExportTableData] = useState([]);
  // const [limit, setLimit] = useState(10);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [reservationID, setReservationID] = useState("");
  const [hostelIdArray, setHostelIdArray] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useHostelList();
  const [checkOutFeedback, setCheckOutFeedback] = useState(null);
  const [checkOutDropdownList, setCheckOutDropdownList] = useState("");
  const [checkoutTime, setCheckoutTime] = useState(null);
  const [checkOutTimeDropdownList, setCheckOutTimeDropdownList] = useState("");
  const [reservationIDInput, setReservationIDInput] = useState("");
  const [checkOutStartDate, setCheckOutStartDate] = useState(null);
  const [checkOutEndDate, setCheckOutEndDate] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const [filterParams, setFilterParams] = useState(null);
  const [dataCount, setDataCount] = useState(0);

  const inputFilter = {
    limit,
    page,
    hostelIdArray:
      filterParams?.hostelIdArray?.length > 0
        ? filterParams.hostelIdArray.map((hostel) => hostel.value)
        : JSON.parse(localStorage.getItem("hostels")),
    checkOutFeedback: filterParams?.checkOutFeedback
      ? filterParams.checkOutFeedback.value
      : null,
    checkoutTime: filterParams?.checkoutTime
      ? filterParams.checkoutTime.value
      : null,
    reservationID: reservationID !== "" ? reservationID : null,
    checkOutStartDate: filterParams?.checkOutStartDate,
    checkOutEndDate: filterParams?.checkOutEndDate,
  };

  const { data, refetch } = useGetContactlessCheckoutList(inputFilter);

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    setDataCount(data?.getContactlessCheckoutList?.count);
  }, [data?.getContactlessCheckoutList?.count]);

  //for all data export with filters
  const exportInputFilter = {
    hostelIdArray:
      filterParams?.hostelIdArray?.length > 0
        ? filterParams.hostelIdArray.map((hostel) => hostel.value)
        : JSON.parse(localStorage.getItem("hostels")),
    checkOutFeedback: filterParams?.checkOutFeedback
      ? filterParams.checkOutFeedback.value
      : null,
    checkoutTime: filterParams?.checkoutTime
      ? filterParams.checkoutTime.value
      : null,
    reservationID: reservationID !== "" ? reservationID : null,
    checkOutStartDate: filterParams?.checkOutStartDate,
    checkOutEndDate: filterParams?.checkOutEndDate,
  };

  const { data: exportData } =
    useGetAllContactlessCheckoutData(exportInputFilter);

  const submitHandler = () => {
    setReservationID(reservationIDInput !== "" ? reservationIDInput : null);

    setFilterParams({
      hostelIdArray,
      checkOutFeedback,
      checkoutTime,
      reservationID: reservationID !== "" ? reservationID : null,
      checkOutStartDate,
      checkOutEndDate,
    });
  };

  const refreshHandler = () => {
    try {
      setHostelIdArray([]);
      setReservationID("");
      setCheckoutTime("");
      setCheckOutFeedback("");
      setFilterParams(null);
      setReservationIDInput("");
      setCheckOutStartDate(null);
      setCheckOutEndDate(null);
    } catch (error) {
      console.error(error);
    }
  };

  const dateFormat = "d/m/Y";
  const options = {
    dateFormat: dateFormat,
  };

  useEffect(() => {
    if (data?.getContactlessCheckoutList?.data?.length > 0) {
      setTableData(data?.getContactlessCheckoutList?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getContactlessCheckoutList?.count / limit));
  }, [
    data?.getContactlessCheckoutList?.count,
    data?.getContactlessCheckoutList?.data,
    limit,
  ]);

  //for all data export with filters
  useEffect(() => {
    if (exportData?.getAllContactlessCheckoutData?.data?.length > 0) {
      setExportTableData(exportData?.getAllContactlessCheckoutData?.data);
    } else {
      setExportTableData([]);
    }
  }, [exportData?.getAllContactlessCheckoutData?.data]);

  useEffect(() => {
    const allowedHostel = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostel !== null && allowedHostel?.length !== 0
          ? allowedHostel?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelList);
    }
  }, [allHostelsData?.getHostelList, data?.getHostelList]);

  //checkout feedback filter dropdown
  useEffect(() => {
    let checkOutDropdownList = [
      { label: "Excellent", value: "Excellent" },
      { label: "Good", value: "Good" },
      { label: "Bad", value: "Bad" },
    ];

    if (data?.getContactlessCheckoutList?.length !== 0) {
      // eslint-disable-next-line array-callback-return
      data?.getContactlessCheckoutList?.data?.map((item) => {
        setCheckOutDropdownList(checkOutDropdownList);
      });
    }
  }, [
    data?.getContactlessCheckoutList?.data,
    data?.getContactlessCheckoutList?.length,
  ]);

  //checkout time filter dropdown
  useEffect(() => {
    if (Array.isArray(data?.getContactlessCheckoutList?.data)) {
      let checkOutDropdownList = [
        { label: "Excellent", value: "Excellent" },
        { label: "Good", value: "Good" },
        { label: "Bad", value: "Bad" },
      ];
      setCheckOutDropdownList(checkOutDropdownList);

      let checkOutTimeDropdownList = [
        { label: "7 am-8 am", value: "7 am-8 am" },
        { label: "8 am-9 am", value: "8 am-9 am" },
        { label: "9 am-10 am", value: "9 am-10 am" },
        { label: "10 am-11 am", value: "10 am-11 am" },
      ];
      setCheckOutTimeDropdownList(checkOutTimeDropdownList);
    }
  }, [data?.getContactlessCheckoutList?.data]);

  return (
    localStorage.getItem("modules")?.includes("Glu Reports") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong>Select Feedback</strong>
              </Label>
              <Select
                isClearable={true}
                id="Status"
                name="Status"
                value={checkOutFeedback}
                options={checkOutDropdownList}
                onChange={(value) => {
                  setCheckOutFeedback(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong>Select Time</strong>
              </Label>
              <Select
                isClearable={true}
                id="checkoutTime"
                name="checkoutTime"
                value={checkoutTime}
                options={checkOutTimeDropdownList}
                onChange={(value) => {
                  setCheckoutTime(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="reservationID">
                <strong>Reservation ID</strong>
              </Label>
              <Input
                type="text"
                id="reservationID"
                name="reservationID"
                placeholder="Type here..."
                value={reservationIDInput}
                onChange={(e) => {
                  setReservationIDInput(e.target.value.toUpperCase());
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                <strong> Hostel Name</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostelIdArray}
                options={HostelsList}
                onChange={(value) => {
                  setHostelIdArray(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="expiry date">
                <strong> Date Range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                onChange={(date) => {
                  setCheckOutStartDate(new Date(date[0]));
                  setCheckOutEndDate(new Date(date[1]));
                }}
                value={[checkOutStartDate, checkOutEndDate]}
                id="daterange"
                placeholder="Check Out Date Range"
                options={{
                  mode: "range",
                  ...options,
                }}
                required
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                submitHandler();
              }}
            >
              Search
            </Button>
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                refreshHandler();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <CheckoutTable
          data={tableData}
          headers={exportHeaders}
          exportData={exportTableData}
          dataCount={dataCount}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      </div>
    )
  );
};

export default ContactlessCheckout;
