import gql from "graphql-tag";

export const USER_LOGIN = gql`
  mutation adminUserLogin($email: String!, $password: String!, $otp: String!) {
    adminUserLogin(email: $email, password: $password, otp: $otp) {
      token
      message
      status
      adminUser {
        _id
        role
        name
        email
        department
        hostels
        modules
      }
    }
  }
`;

export const USER_OTP = gql`
  mutation sendOtpMail($email: String!, $password: String!) {
    sendOtpMail(email: $email, password: $password) {
      status      
      message
    }
  }
`;


//! Login code
// export const USER_LOGIN = gql`
//   mutation login($email: String!, $password: String!) {
//     login(email: $email, password: $password) {
//       token
//       user {
//         _id
//         role
//         fullName
//       }
//     }
//   }
// `;
