import { PenTool, Circle } from "react-feather";

// const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

export default // role === "Marketing" ||
// role === "Super Admin" ||
// role === "Sales Admin" ||
// role === "Marketing Admin" ||
modules?.includes("Blogs")
  ? [
      {
        id: "blogs",
        title: "Blogs",
        icon: <PenTool size={20} />,
        badge: "light-warning",
        children: [
          // ! Added by Ashwin
          {
            id: "blogsCategory",
            title: "Category",
            icon: <Circle size={12} />,
            navLink: "/blogs/category",
          },
          {
            id: "blogsBlog",
            title: "Blog",
            icon: <Circle size={12} />,
            navLink: "/blogs/blog",
          },
        ],
      },
    ]
  : [];
