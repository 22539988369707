import StepoutBlogTable from './StepoutBlogTable'
import { useGetAllStepoutBlog, useGetPaginatedStepoutBlog } from "../../../api/dataSource"
import { useHistory } from "react-router";
import TablePagination from "@components/common/TablePagination";

import React, { useEffect, useState } from "react";
import AddEditModal from './AddEditModal';



const StepoutBlogs = () => {

    const [limit, setlimit] = useState(20)
    const [page, setPage] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [id, setId] = useState("");
    const [tableData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(1);

    const { data, refetch } = useGetPaginatedStepoutBlog(limit, page);
    const history = useHistory();
    useEffect(() => {
        refetch();
    }, [page, refetch]);

    useEffect(() => {
        if (data?.getPaginatedStepoutBlogList?.data.length > 0) {
            setTableData(data.getPaginatedStepoutBlogList?.data);
        } else {
            setTableData([]);
        }
        setTotalPage(Math.ceil(data?.getPaginatedStepoutBlogList?.count / limit));
    }, [
        data?.getPaginatedStepoutBlogList?.count,
        data?.getPaginatedStepoutBlogList?.data,
        limit
    ]);

    useEffect(() => {
        if (id && data?.getPaginatedStepoutBlogList?.data.length) {
            let editData = data?.getPaginatedStepoutBlogList?.data?.find(
                (item) => item._id === id,
            );
            setEditData(editData)
        } else {
            setId(null);
            setEditData(null);
        }
    }, [data?.getPaginatedStepoutBlogList?.data, id]);

    const toggleModal = () => {
        setOpenModal(!openModal);
        setId(null);
        setEditData(null);
    }

    const handleCreateUpdateStepOutBlog = (id) => {
        history.push(`/stepOut/edit/stepOutBlog/${id}`)
    }


    return (
        <div>
            <StepoutBlogTable
                data={tableData}
                setId={setId}
                id={id}
                openModal={() => setOpenModal(!openModal)}
                handleCreateUpdateStepOutBlog={handleCreateUpdateStepOutBlog}
                refetch
            />

            <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

            <AddEditModal
                open={openModal}
                editData={editData}
                handleToggle={toggleModal}
                refetch={refetch}
                setId={setId}
            />
        </div>

    )
}

export default StepoutBlogs;