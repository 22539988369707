// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import confirmDelete from "@components/common/confirmDelete";

import {
  useSimilarBlogsList,
  useUpdateBlogsBlog,
} from "../../../api/dataSource";

const SimilarBlogs = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateBlogsBlogHandler, { data: updatedBlogsBlogData }] =
    useUpdateBlogsBlog();
  const [selectedSimilarBlogs, setSelectedSimilarBlogs] = useState([]);
  const [SimilarBlogsList, setBlogCategoryList] = useState([]);
  // const { data: allBlogCategoryData } = useBlogCategoryList();
  const { data: allSimilarBlogsData } = useSimilarBlogsList();

  useEffect(() => {
    if (state?.tableData?.similarBlogs.length) {
      let alreadySelectedSimilarBlogs = state?.tableData?.similarBlogs?.map(
        (item) => {
          return {
            uuId: uuidv4(),
            similarBlogs: { label: item?.blogTitle, value: item?._id },
          };
        }
      );
      setSelectedSimilarBlogs(alreadySelectedSimilarBlogs);
    }
  }, [state]);
  const handleAddSimilarBlogs = () =>
    setSelectedSimilarBlogs([
      ...selectedSimilarBlogs,
      {
        uuId: uuidv4(),
        similarBlogs: [],
      },
    ]);

  const handleRemoveSelectedSimilarBlogs = (uuId) =>
    confirmDelete(() =>
      setSelectedSimilarBlogs(
        selectedSimilarBlogs.filter((item) => item.uuId !== uuId)
      )
    );

  const handleChangeSelectedSimilarBlogs = (similarBlogs, uuId) => {
    setSelectedSimilarBlogs(
      selectedSimilarBlogs.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              similarBlogs: {
                label: similarBlogs.label,
                value: similarBlogs.value,
              },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let SimilarBlogsList = [];
    if (allSimilarBlogsData?.getBlogsList?.length) {
      allSimilarBlogsData?.getBlogsList?.map((item) => {
        SimilarBlogsList.push({ label: item?.blogTitle, value: item?._id });
        setBlogCategoryList(SimilarBlogsList);
      });
    }
  }, [allSimilarBlogsData?.getBlogsList]);
  const [finalSimilarBlogsArr, setFinalSimilarBlogArr] = useState([]);

  useEffect(() => {
    if (updatedBlogsBlogData) {
      let updatedSimilarBlogs = [];
      finalSimilarBlogsArr.forEach((i) => {
        updatedSimilarBlogs.push(
          allSimilarBlogsData?.getBlogsList?.find((j) => j._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          similarBlogs: updatedSimilarBlogs,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allSimilarBlogsData?.getBlogsList,
    dispatch,
    finalSimilarBlogsArr,
    updatedBlogsBlogData,
  ]);

  const submitDataHandler = () => {
    var finalListOfSimilarBlogsId = [];
    selectedSimilarBlogs?.forEach((item) =>
      finalListOfSimilarBlogsId.push(item.similarBlogs.value)
    );
    setFinalSimilarBlogArr(finalListOfSimilarBlogsId);
    let data = {
      id,
      similarBlogs: finalListOfSimilarBlogsId,
    };

    if (data?.similarBlogs?.length > 0) {
      if (id && data?.similarBlogs?.every((blog) => blog !== undefined)) {
        updateBlogsBlogHandler(id, { similarBlogs: data.similarBlogs });
        toaster("success", "Similar blogs updated successfully");
      } else {
        toaster("error", "Please select the blog");
      }
    } else {
      toaster("error", "Please select at least one blog");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDataHandler();
      }}
    >
      <Row className="mt-2">
        {selectedSimilarBlogs.map(({ uuId, similarBlogs }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={uuId}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"reviews" + uuId}>
                Similar Blog {index + 1}
              </Label>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Select
                      // isClearable={false}
                      isSearchable={true}
                      id={uuId}
                      name="similarBlogs"
                      value={similarBlogs}
                      options={SimilarBlogsList}
                      onChange={(value) => {
                        handleChangeSelectedSimilarBlogs(value, uuId);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {selectedSimilarBlogs.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveSelectedSimilarBlogs(uuId)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddSimilarBlogs} />
    </form>
  );
};

export default SimilarBlogs;
