// ! Coded by Ashwin
import gql from "graphql-tag";

export const CREATE_COUPON = gql`
  mutation createCoupon($couponInput: CouponInput) {
    createCoupon(couponInput: $couponInput) {
      name
      promoCode
      product
      subCategory
      discount
      maxDiscountValue
      expirationDate
      maxBookingDate
      minBookingDate
      maxStayDate
      minStayDate
      duration
      quantity
      numberOfUsage
      status
      rules
      private
      minBookingAmount
      minimumNights
      maximumNights
      minimumAdults
      prefix
      startNumber
      endNumber
      couponsPerUser
      newUserOnly
      maximumNights
      couponsPerDay
      dormsOnly
      sameDayBooking
      
    }
  }
`;

export const UPDATE_COUPON = gql`
  mutation updateCoupon($id: ID!, $couponUpdate: CouponInput) {
    updateCoupon(id: $id, couponUpdate: $couponUpdate) {
      name
    }
  }
`;

export const UPDATE_COUPON_GENERATOR = gql`
mutation updateCouponGenerator($couponUpdate: CouponInput) {
    updateCouponGenerator(couponUpdate: $couponUpdate){
    name
    }
}
`
