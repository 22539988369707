import React, { useEffect, useState } from "react";
import SlideModal from "../../../@core/components/common/slide-modal";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { useGetAllCafeMasterList } from "../../../api/Unbox/UnboxCafeMaster/Queries";
import Select from "react-select";
import { DragDrop } from "@uppy/react";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { useUploadImage } from "../../../api/dataSource";
import {
  useCreateUnboxBanner,
  useUpdateUnboxBanner,
} from "../../../api/Unbox/UnboxBanner/Mutations";
import toaster from "@components/common/Toaster";

const AddEditModal = ({ open, handleToggle, editData, refetch }) => {
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [displayOrder, setDisplayOrder] = useState(null);
  const [cafe, setCafe] = useState(null);
  const [cafeList, setCafeList] = useState([]);
  const [previewArr, setPreviewArr] = useState(null);
  const [postImageUrl, setPostImageUrl] = useState("");
  const [createUnboxBannerHandler, { data }] = useCreateUnboxBanner();
  const [updateUnboxBannerHandler, { data: updatedData }] =
    useUpdateUnboxBanner();

  useEffect(() => {
    if (
      data?.createUnboxCafeBanner?.title ||
      updatedData?.updateUnboxCafeBanner?.title
    ) {
      refetch();
    }
  }, [
    data?.createUnboxCafeBanner?.title,
    updatedData?.updateUnboxCafeBanner?.title,
  ]);
  useEffect(() => {
    if (editData?._id) {
      setTitle(editData.title);
      setDisplayOrder(editData.displayOrder);
      setStartDate(editData.startDate);
      setEndDate(editData?.endDate);
      setCafe(editData?.cafe?._id);
      // setImageUrl(editData.ImageUrl);
      setPreviewArr([editData?.image]);
    } else {
      setTitle("");
      setDisplayOrder("");
      setStartDate(new Date());
      setEndDate(new Date());
      setPreviewArr([]);
      setCafe(null);
    }
  }, [editData]);

  const submitHandler = async () => {
    try {
      const cafeInp = cafe?.map((cafe) => cafe.value);

      // if (
      //   title.trim() === "" ||
      //   title.includes(".") ||
      //   !startDate ||
      //   !endDate ||
      //   !cafe ||
      //   !displayOrder ||
      //   !imageData?.uploadImage
      // ) {
      //   toaster(
      //     "error",
      //     "please all fill data, without leading spaces and dot"
      //   );
      //   return;
      // }

      const inputData = {
        title,
        startDate,
        endDate,
        cafe: cafeInp,
        displayOrder: parseInt(displayOrder),
        image: imageData?.uploadImage?.url,
      };

      if (editData?._id) {
        updateUnboxBannerHandler(editData?._id, inputData);
        toaster("success", "banner updated successfully");
        //refetch();
        handleToggle();
      } else {
        await createUnboxBannerHandler(inputData);
        toaster("success", "banner added successfully");
        //refetch();
        handleToggle();
      }
      setTitle("");
      setStartDate(new Date());
      setEndDate(new Date());
      setDisplayOrder("");
      setPostImageUrl("");
      setCafe(null);
    } catch (error) {
      console.error(error);
    }
  };

  const { data: cafelist, refetch: cafeRefetch } = useGetAllCafeMasterList();

  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();

  useEffect(() => {
    if (imageData) {
      setPostImageUrl(imageData?.uploadImage?.url);
    }
  }, [imageData]);

  useEffect(() => {
    if (cafelist) {
      setCafeList(cafeList?.getAllCafeMasterList);
      const cafeNameList = cafelist?.getAllCafeMasterList?.map((ele) => ({
        label: ele.cafeName,
        value: ele._id,
      }));
      setCafeList(cafeNameList);

      if (editData?.cafe) {
        const selectedCafe = editData?.cafe?.map((item) => ({
          label: item?.cafeName,
          value: item?._id,
        }));
        setCafe(selectedCafe);
      }
    }
  }, [cafelist, editData?.cafe]);

  const renderPreview = () => {
    if (previewArr?.length > 0) {
      return previewArr?.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".pdf"],
      maxFileSize: 2000000,
    },
  });

  uppy.use(thumbnailGenerator);
  uppy.on("thumbnail:generated", (file, preview) => {
    setPreviewArr([preview]);
    uploadImageHandler(file.data, "image/walloflove");
  });

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitHandler}
    >
      <form>
        <Row>
          <Col>
            <Row>
              <Col>
                <label>Title*</label>
                <Input
                  autoFocus
                  type="text"
                  value={title}
                  id="title"
                  name="title"
                  placeholder="Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="startDate">
                    Start Date*
                  </Label>
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    value={startDate}
                    onChange={(date) =>
                      setStartDate(new Date(dateFormatter(new Date(date))))
                    }
                    name="startDate"
                    id="startDate"
                    placeholder="Start Date"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="endDate">
                    End Date*
                  </Label>
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    value={endDate}
                    onChange={(date) =>
                      setEndDate(new Date(dateFormatter(new Date(date))))
                    }
                    id="endDate"
                    name="endDate"
                    placeholder="End Date"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Display Order*</label>
                <Input
                  autoFocus
                  type="text"
                  value={displayOrder}
                  id="displayorder"
                  name="displayorder"
                  placeholder="Display Order"
                  onChange={(e) => setDisplayOrder(e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="cafe">
                    Cafe*
                  </Label>
                  <Select
                    isMulti
                    isClearable={false}
                    isSearchable={true}
                    id="cafe"
                    name="cafe"
                    options={cafeList}
                    value={cafe}
                    onChange={(value) => setCafe(value)}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginBottom: "30px" }}>
              <Col>
                <div>
                  <Label className="form-label">
                    Banner Image* (Recommended dimension - 1200 X 550){" "}
                  </Label>
                  <DragDrop
                    note="Image size should be less than 2MB"
                    width="100%"
                    uppy={uppy}
                  />
                </div>
                {renderPreview()}
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
