import gql from "graphql-tag";

export const GET_ADD_ON_REPORT = gql`
  query getAddOns($filter: filter) {
    getAddOns(filter: $filter) {
      count
      page
      limit
      data {
        _id
        checkinDate
        checkoutDate
        email
        extraCharges
        guestName
        hostelId
        mobile
        noOfGuest
        noOfNights
        reservationId
        roomName
        totalAmount
        hostelName
        createdAt
        purchasedDate
        razorpayPaymentId
      }
    }
  }
`;

export const GET_ADD_ON_LIST = gql`
  query {
    getListOfAddons {
      addOn
    }
  }
`;
