import React, { useEffect, useState } from "react";
import { Col, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";

const AncillaryPayoutSubFields = ({ setDataToSubmit, editData }) => {
  const [subHeadType, setSubHeadType] = useState(null);
  const [vendorName, setVendorName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const AncillaryPayoutVendorType = [
    { label: "Guest Laundry", value: "Guest Laundry" },
    { label: "Transportation", value: "Transportation" },
    { label: "Activity/Adventure", value: "Activity/Adventure" },
    { label: "Accommodation", value: "Accommodation" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );

    // setVendorName(
    //   editData?.subFields?.vendorName !== null
    //     ? {
    //         label: editData?.subFields?.vendorName,
    //         value: editData?.subFields?.vendorName,
    //       }
    //     : null
    // );

    setVendorName(
      editData?.subFields?.vendorName !== null
        ? editData?.subFields?.vendorName
        : ""
    );

    setStartDate(
      editData?.subFields?.startDate !== null
        ? editData?.subFields?.startDate
        : new Date()
    );
    setEndDate(
      editData?.subFields?.endDate !== null
        ? editData?.subFields?.endDate
        : new Date()
    );
    setQuantity(
      editData?.subFields?.quantity !== null
        ? editData?.subFields?.quantity
        : ""
    );
  }, [editData]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
      vendorName,
      quantity,
      startDate,
      endDate,
    };

    setDataToSubmit(data);
  }, [subHeadType, vendorName, quantity, startDate, endDate]);

  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="subHeadType">
            Vendor Type
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={AncillaryPayoutVendorType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            // isDisabled={editData?._id}
          />
        </FormGroup>
      </Col>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="vendorName">
            Vendor Name
          </Label>
          <Input
            type="text"
            value={vendorName}
            id="vendorName"
            name="vendorName"
            placeholder="Add Vendor Name"
            onChange={(e) => setVendorName(e.target.value)}
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <strong>
            <Label className="form-label" for="start date">
              Payout Start Date
            </Label>
          </strong>
          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={startDate}
            onChange={(date) =>
              setStartDate(new Date(dateFormatter(new Date(date))))
            }
            id="startDate"
            placeholder="Select start date"
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="end date">
            Payout End Date
          </Label>

          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={endDate}
            onChange={(date) =>
              setEndDate(new Date(dateFormatter(new Date(date))))
            }
            id="endDate"
            placeholder="Select end date"
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="quantity">
            Quantity
          </Label>
          <Input
            type="text"
            value={quantity}
            id="quantity"
            name="quantity"
            placeholder="Add Quantity"
            onChange={(e) => setQuantity(e.target.value)}
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default AncillaryPayoutSubFields;
