import React, { useEffect, useState } from "react";
import Report, { getMonth } from "./Report";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import {
  useGetMonthwiseSalesReport,
  useGetMonthwiseSalesReportExport,
} from "../../../api/Unbox/UnboxSalesReport/Queries";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";
import Flatpickr from "react-flatpickr";
import {
  useGetAllCafeMasterList,
  useGetCafeByHostelId,
} from "../../../api/Unbox/UnboxCafeMaster/Queries";
const { dateFormatter } = require("../../../utility/Utils");

const ReportTypeList = [
  { label: "Monthwise Sales", value: "Monthwise Sales" },
  { label: "Cafewise Sales", value: "Cafewise Sales" },
];

const MonthwiseSales = () => {
  const hostelId = JSON.parse(localStorage.getItem("hostels")) || [];
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [cafe, setCafe] = useState(null);
  const [cafelist, setCafelist] = useState(null);
  const [searchFromDate, setSearchFromDate] = useState(null);
  const [searchToDate, setSearchToDate] = useState(null);
  const [searchCafe, setSearchCafe] = useState(null);
  const [searchHostel, setSearchHostel] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [monthArray, setMonthArray] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [prop, setProp] = useState(null);
  const [searchFilter, setSearchFilter] = useState(null);
  const [overallData, setOverallData] = useState({
    totalOverAllOrderCount: 0,
    totalOverAllSales: 0,
    totalTax: 0,
    totalNetSales: 0,
    totalDiscount: 0,
    avgOrderValue: 0,
  });
  const { data: cafes } = useGetCafeByHostelId(hostelId);

  const { data: cafeListData } = useGetAllCafeMasterList();

  const { data } = useGetMonthwiseSalesReport(
    searchFromDate,
    searchToDate,
    searchCafe,
    searchFilter
    //searchHostel
  );
  const { data: Data } = useGetMonthwiseSalesReportExport(
    exportData,
    monthArray,
    prop
  );
  useEffect(() => {
    if (data?.getMonthwiseSales?.pipelineOutput1?.length > 0) {
      setTableData(data?.getMonthwiseSales?.pipelineOutput1);
      setProp(data?.getMonthwiseSales?.dataType);
      setOverallData({
        totalOverAllOrderCount: data?.getMonthwiseSales?.totalOverAllOrderCount,
        totalOverAllSales: data?.getMonthwiseSales?.totalOverAllSales,
        totalTax: data?.getMonthwiseSales?.totalTax,
        totalNetSales: data?.getMonthwiseSales?.totalNetSales,
        totalDiscount: data?.getMonthwiseSales?.totalDiscount,
        avgOrderValue: data?.getMonthwiseSales?.avgOrderValue,
      });
    } else {
      setTableData([]);
      setProp(null);
      setOverallData({
        totalOverAllOrderCount: 0,
        totalOverAllSales: 0,
        totalTax: 0,
        totalNetSales: 0,
        totalDiscount: 0,
        avgOrderValue: 0,
      });
    }
  }, [data?.getMonthwiseSales?.pipelineOutput1]);

  useEffect(() => {
    if (hostelId?.length !== 0 && cafes?.getCafeByHostelId?.length) {
      const cafeArray = cafes?.getCafeByHostelId?.map((cafe) => {
        return {
          label: cafe?.cafeName,
          value: cafe?._id,
          hostelId: cafe?.hostel,
        };
      });

      setCafelist(cafeArray);
    } else {
      const cafeArray = cafeListData?.getAllCafeMasterList?.map((cafe) => {
        return {
          label: cafe?.cafeName,
          value: cafe?._id,
          hostelId: cafe?.hostel?._id,
        };
      });

      setCafelist(cafeArray);
    }
  }, [cafes?.getCafeByHostelId, cafeListData, hostelId?.length]);

  useEffect(() => {
    if (Data?.getMonthwiseSalesExportReport?.data) {
      if (Data?.getMonthwiseSalesExportReport?.data.length > 0) {
        const byteArray = new Uint8Array(
          Data?.getMonthwiseSalesExportReport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `unbox_Monthwise_SalesReport.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setExportData(null);
      }
    }
  }, [Data]);

  const handleDateRange = (value, name) => {
    try {
      if (name === "fromDate") {
        setFromDate(dateFormatter(new Date(value)));
      } else if (name === "toDate") {
        setToDate(dateFormatter(new Date(value)));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearch = () => {
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);

    const fromDatePlus12Months = new Date(fromDateObj);
    fromDatePlus12Months.setMonth(fromDatePlus12Months.getMonth() + 11);

    if (
      fromDate &&
      toDate &&
      toDateObj <= fromDatePlus12Months &&
      toDateObj >= fromDateObj
    ) {
      setSearchFromDate(fromDate);
      setSearchToDate(toDate);

      if (cafe?.length > 0) {
        setSearchCafe(cafe?.map((i) => i?.value));
        setSearchHostel(cafe?.map((i) => i?.hostelId));
      } else {
        setSearchCafe(null);
        setSearchHostel(null);
      }
      if (filter?.value) {
        setSearchFilter(filter?.value);
      } else {
        setSearchFilter(null);
      }
    } else {
      toaster(
        "error",
        "Please select Date with end date should be less than or equal to 12 months from the start date"
      );
    }
  };
  const handelExport = () => {
    if (data?.getMonthwiseSales?.pipelineOutput1?.length > 0) {
      setExportData(data?.getMonthwiseSales);
      setMonthArray(getMonth(searchFromDate, searchToDate));
    } else {
      toaster("error", "No data to export");
    }
  };

  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setTableData(null);
    setCafe(null);
    setSearchFromDate(null);
    setSearchToDate(null);
    setSearchCafe(null);
    setExportData(null);
    setMonthArray(null);
    setFilter(null);
    setSearchFilter(null);
  };

  return (
    <div>
      <Row>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              <strong>Start Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromDate}
              onChange={(date) => {
                handleDateRange(date, "fromDate");
                //setFromDate(dateFormatter(new Date(date)));
              }}
              id="fromDate"
              placeholder="From Date"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="toDate">
              <strong>End Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toDate}
              onChange={(date) => {
                //setToDate(dateFormatter(new Date(date)));
                handleDateRange(date, "toDate");
              }}
              id="toDate"
              placeholder="To Date"
            />
          </FormGroup>
        </Col>
        {cafelist?.length !== 1 && (
          <Col sm="2" style={{ zIndex: 3 }}>
            <FormGroup className="w-100">
              <Label className="form-label" for="cafe">
                <strong>Cafe</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="cafe"
                name="cafe"
                value={cafe}
                closeMenuOnSelect={false}
                options={cafelist}
                onChange={(value) => {
                  setCafe(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        )}
        {/* <Col sm="2" style={{ zIndex: "3" }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="reportType">
              <strong>Report Type</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              id="reportType"
              name="reportType"
              value={filter}
              options={ReportTypeList}
              closeMenuOnSelect={true}
              onChange={(value) => {
                setFilter(value);
              }}
              menuPortalTarget={document.body}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col> */}
        <Col className="" sm="3" style={{ display: "flex", gap: "30px" }}>
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 20px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              //marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 20px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            style={{
              backgroundColor: "##ff9999",
              height: "40px",
              marginTop: "22px",
              //marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 20px",
              outline: "none",
              border: "0",
            }}
            color="success"
            appearance="success"
            type="button"
            onClick={handelExport}
          >
            Export
          </Button>
        </Col>
      </Row>
      {tableData?.length > 0 ? (
        <div style={{ overflowY: "auto" }}>
          <Report
            monthwiseData={tableData}
            overallData={overallData}
            fromDate={searchFromDate}
            toDate={searchToDate}
            filter={"Cafewise Sales"}
          />
        </div>
      ) : data?.getMonthwiseSales?.pipelineOutput1?.length === 0 ? (
        <h5 style={{ textAlign: "center" }}>No orders</h5>
      ) : (
        <h5 style={{ textAlign: "center" }}>Please select Date Range</h5>
      )}
    </div>
  );
};

export default MonthwiseSales;
