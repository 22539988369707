// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import PartnerNewTable from "./PartnerNewTable";
import AddEditModal from "./AddEditModal";
import { useGetAllCoupons } from "@api";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

import { useGetAllPaginatedPartnerNew } from "../../api/dataSource";

const Staffs = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [coupons, setCoupons] = useState(); // ! for only defining purpose
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  // ! Coded by Ashwin
  const { data, refetch } = useGetAllPaginatedPartnerNew(limit, page); // ! Coded by Ashwin
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data?.getPaginatedPartnerNewList?.data) {
      setTableData(data?.getPaginatedPartnerNewList?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedPartnerNewList?.count / limit));
  }, [data]);

  useEffect(() => {
    if (data?.getPaginatedPartnerNewList?.data.length) {
      let editData = data?.getPaginatedPartnerNewList?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  return (
    localStorage.getItem("modules")?.includes("CMS") && (
      <div>
        <PartnerNewTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          refetch={refetch}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      </div>
    )
  );
};

export default Staffs;
