import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useUpdateStepOutProduct,
  useUploadImage,
} from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import { v4 as uuidv4 } from "uuid";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Trash, Camera } from "react-feather";
import Avatar from "@components/common/avatar";
import confirmDelete from "@components/common/confirmDelete";

const Moments = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [tripUsp, setTripUsp] = useState([
    { id: uuidv4(), imageUrl: "", title: "", description: "", displayOrder: 0 },
  ]);
  const [index, setIndex] = useState("");

  const [updateStepOutProductHandler, { data: updateStepOutProductData }] =
    useUpdateStepOutProduct();

  const [uploadProductImageHandler, { data: productImageData }] =
    useUploadImage();

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData) {
      setTripUsp(
        state?.tableData?.tripUsp?.map((item) => {
          return {
            id: uuidv4(),
            imageUrl: item?.imageUrl,
            title: item?.title,
            description: item?.description,
            displayOrder: item?.displayOrder,
          };
        })
      );
    }
  }, [state]);

  useEffect(() => {
    if (productImageData?.uploadImage?.url) {
      setTripUsp(
        tripUsp?.map((item) => {
          if (item.id === index) {
            return {
              ...item,
              imageUrl: productImageData?.uploadImage?.url,
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [productImageData?.uploadImage?.url]);

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }

    uploadProductImageHandler(
      e.target.files[0],
      `${state.tableData?.name?.toLowerCase()?.replaceAll(" ", "-")}` +
        "/STEPOUTPRODUCT"
    );
    toaster("success", "Trip usp image uploaded successfully");
  };

  const handleTextChangeTitle = (val, id, key) =>
    setTripUsp(
      tripUsp?.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDescription = (val, id, key) =>
    setTripUsp(
      tripUsp?.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDisplayOrder = (val, id, key) =>
    setTripUsp(
      tripUsp?.map((item) =>
        item.id === id ? { ...item, [key]: parseInt(val) } : item
      )
    );

  // const handleAddStepOutProduct = () =>
  //   setTripUsp([
  //     ...tripUsp,
  //     {
  //       id: uuidv4(),
  //       imageUrl: "",
  //       title: "",
  //       description: "",
  //       displayOrder: 0,
  //     },
  //   ]);

  const handleAddStepOutProduct = () => {
    // Ensure tripUsp is defined
    setTripUsp((prevTripUsp) => [
      ...(prevTripUsp || []), // Use the existing array or an empty array if undefined
      {
        id: uuidv4(),
        imageUrl: "",
        title: "",
        description: "",
        displayOrder: 0, // Set a default numeric value
      },
    ]);
  };

  const handleRemoveStepOutProduct = (id) => {
    // eslint-disable-next-line array-callback-return
    let newStepOutProductData = tripUsp.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setTripUsp(newStepOutProductData));
  };

  const submitDataHandler = (e) => {
    e.preventDefault();
    let data = {
      tripUsp: tripUsp?.map((item) => {
        delete item.id;
        return item;
      }),
    };

    if (id) {
      updateStepOutProductHandler(id, data);
      toaster("success", "Trip usp updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      {tripUsp?.map(
        ({ id, imageUrl, title, description, displayOrder }, index) => (
          <div key={id}>
            <Row className="mt-2">
              <Col sm="12" md="11">
                <FormGroup>
                  <div className="mt-2 d-flex align-items-center">
                    <div className="mb-1 mr-2">
                      {
                        <Avatar
                          img={
                            imageUrl?.includes(".mp4")
                              ? "https://global.discourse-cdn.com/business4/uploads/prezi/original/2X/a/aaaff5d5dec03e32799015d424b4a5db51c9eeff.png"
                              : imageUrl
                          }
                          alt={imageUrl}
                          imgHeight={100}
                          imgWidth={100}
                          onClick={() => window.open(imageUrl, "_blank")}
                        />
                      }
                    </div>

                    <label htmlFor={`myFile${id}`}>
                      <p className="btn btn-sm btn-light">
                        <Camera size={15} />
                      </p>
                      <input
                        name={`myFile${id}`}
                        id={`myFile${id}`}
                        className="d-none"
                        type="file"
                        onChange={(e) => fileUploader(e, id)}
                      />
                    </label>
                  </div>
                  <FormGroup className="w-100">
                    <Label className="form-label" for="title">
                      Title
                    </Label>

                    <Input
                      rows={4}
                      type="text"
                      value={title}
                      id="title"
                      name="title"
                      placeholder="Title"
                      onChange={(e) =>
                        handleTextChangeTitle(e.target.value, id, "title")
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="description">
                      Description
                    </Label>

                    <Input
                      rows={4}
                      type="text"
                      value={description}
                      id="description"
                      name="description"
                      placeholder="Description"
                      onChange={(e) =>
                        handleTextChangeDescription(
                          e.target.value,
                          id,
                          "description"
                        )
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="displayOrder">
                      Display order
                    </Label>

                    <Input
                      rows={4}
                      type="number"
                      value={displayOrder}
                      id="displayOrder"
                      name="displayOrder"
                      placeholder="Display order"
                      onChange={(e) =>
                        handleTextChangeDisplayOrder(
                          e.target.value,
                          id,
                          "displayOrder"
                        )
                      }
                    />
                  </FormGroup>
                </FormGroup>
              </Col>

              <Col sm="12" md="1">
                {tripUsp.length > 1 ? (
                  <>
                    <Button.Ripple
                      className="btn-icon rounded-circle mb-1 mr-1"
                      color="light"
                      type="button"
                      size="sm"
                      onClick={() => handleRemoveStepOutProduct(id)}
                    >
                      <Trash size={14} />
                    </Button.Ripple>
                  </>
                ) : null}
              </Col>
            </Row>
            <hr key={index} style={{ border: "1px solid #ffe700" }} />
          </div>
        )
      )}
      <SaveDiscardBtnGroup addAction={handleAddStepOutProduct} />
    </form>
  );
};

export default Moments;
