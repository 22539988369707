import React, { useState, useEffect } from "react";
import styles from "./FeedbackCard.module.css";
import { rating } from "./FeedbackMood";
import { useGetFeedbackByReservationId, useUpdateFeedbackRating } from "@api";
import { useParams } from "react-router-dom";
import { dateFormatHandler } from "@utils";
import { useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
// import { tableDataHandler } from "@store/Cms/cms.action";
// import { Table } from "reactstrap";
// import { Col, Card, Row, CardHeader, CardTitle } from "reactstrap";

function FeedbackCard() {
  const [feedBack, setFeedBack] = useState(rating.Neutral);
  const [message, setMessage] = useState("");
  const { reservationId } = useParams();
  const { data, refetch } = useGetFeedbackByReservationId(reservationId);
  const [updateFeedbackRatingHandler] = useUpdateFeedbackRating();
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  // const [firstName, setFirstName] = useState("");

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data?.getFeedbackByReservationId) {
      setTableData(data?.getFeedbackByReservationId);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handleClick = (feedSelected) => {
    setFeedBack(feedSelected);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const feedbackTime = new Date();
    const updateFeedbackData = {
      reservationId,
      feedbackRatingDetails: {
        customerFeedback: feedBack,
        customerRemark: message,
      },
      feedbackTime: feedbackTime,
    };
    updateFeedbackRatingHandler(updateFeedbackData);
    toaster("success", "Submitted successfully");
  };

  return (
    <div className="flex justify-center sm:justify-center relative top-20 ">
      <div className="p-6 rounded-lg shadow-lg bg-white max-w-sm">
        <h5>
          Name: {data ? data.getFeedbackByReservationId.salutation : ""}{" "}
          {data ? data.getFeedbackByReservationId.firstName : ""}{" "}
          {data ? data.getFeedbackByReservationId.lastName : ""}
        </h5>
        <h5>
          Reservation ID:{" "}
          {data ? data.getFeedbackByReservationId.reservationId : ""}
        </h5>
        <h5>Mobile: {data ? data.getFeedbackByReservationId.mobile : ""}</h5>
        <h5>
          Checkin Date:{" "}
          {data
            ? dateFormatHandler(data.getFeedbackByReservationId.checkinDate)
            : ""}
        </h5>
        <h5>
          Checkout Date:{" "}
          {data
            ? dateFormatHandler(data.getFeedbackByReservationId.checkoutDate)
            : ""}
        </h5>

        <br></br>
        <p className="text-black ">
          Ohai, <br /> we hope you are having a pleasant stay with us at The
          Hosteller <br /> Let us know how your experience is going so far?
        </p>
        <p className="text-left mb-2 tracking-tight text-gray-900 dark:text-white">
          {`Rate us quickly on so that we don't leave any stone unturned in
          providing you with a comfortable stay`}
          .
        </p>

        <form
          name="feedbackForm"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="style.flex justify-evenly pb-3">
            {rating.map(({ emoji, name, value }, index) => (
              <div
                key={index}
                className="cursor-pointer flex flex-col justify-center text-center"
                onClick={() => handleClick(value)}
              >
                <input
                  required
                  // className="hidden"
                  type="radio"
                  name="Feedback"
                  id={name}
                  value={value}
                />
                <div
                  className={`mx-6 mt-10  ${
                    feedBack === value ? "text-6xl" : "text-5xl"
                  }`}
                >
                  {emoji}
                </div>
                <p className="text-black mt-2">{value}</p>
              </div>
            ))}
          </div>
          <div className="messageDiv">
            <input
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              type="text"
              id="large-input"
              placeholder="Enter your Feedback"
              className={`${styles.messageInbox} block p-4 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700  dark:placholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:border-blue-500 `}
            />
          </div>
          <div className="feedbackButtonDiv">
            <button
              className={`${styles.feedbackButton} hover:scale-105 text-black focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center `}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FeedbackCard;
