// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateStepOutLocation } from "../../../api/dataSource";
import { useParams } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

const SEOForm = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [url, setUrl] = useState("");

  const [updateStepOutLocationHandler, { data: updateStepOutLocationData }] =
    useUpdateStepOutLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    let prePopulateData;

    if (state.length) {
      tableDataHandler({
        ...state?.tableData,
        cardData: {
          metaTitle,
          metaDesc,
          keywords,
        },
        url,
      });
    }

    if (prePopulateData) {
      setMetaTitle(prePopulateData?.seo.metaTitle);
      setMetaDesc(prePopulateData?.seo.metaDesc);
      setKeywords(prePopulateData?.seo.keywords);
      setUrl(prePopulateData?.url);
    } else {
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setUrl("");
    }
  }, [state]);

  useEffect(() => {
    if (state.tableData) {
      setMetaTitle(state.tableData?.seo?.metaTitle);
      setMetaDesc(state.tableData?.seo?.metaDesc);
      setKeywords(state.tableData?.seo?.keywords);
      setUrl(state.tableData?.url);
    } else {
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setUrl("");
    }
  }, [state.tableData]);

  const submitHandler = (e) => {
    let isValid = false;
    e.preventDefault();

    const data = {
      metaTitle,
      metaDesc,
      keywords,
    };

    if (id) {
      updateStepOutLocationHandler(id, { seo: data, url });
      toaster("success", "SEO details updated successfully");
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...{ seo: data, url },
        })
      );
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="seo">
              Meta Title
            </Label>

            <Input
              type="text"
              value={metaTitle}
              id="metaTitle"
              name="metaTitle"
              placeholder="Meta Title"
              onChange={(e) => setMetaTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="seo">
              Meta Description
            </Label>

            <Input
              type="text"
              value={metaDesc}
              id="metaDesc"
              name="metaDesc"
              placeholder="Meta Description"
              onChange={(e) => setMetaDesc(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="seo">
              Keywords
            </Label>

            <Input
              type="text"
              value={keywords}
              id="keywords"
              name="keywords"
              placeholder="Keywords"
              onChange={(e) => setKeywords(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="seo">
              Url
            </Label>

            <Input
              type="text"
              value={url}
              id="url"
              name="url"
              placeholder="Url"
              onChange={(e) => setUrl(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default SEOForm;
