// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import confirmDelete from "@components/common/confirmDelete";

import {
  useGetAllStepOutProductList,
  useUpdateStepOutProduct,
} from "../../../api/dataSource";

const SimilarProducts = () => {
  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedSimilarProducts, setSelectedSimilarProducts] = useState([]);
  const [similarProductsList, setSimilarProductsList] = useState([]);
  const { data: allProducts } = useGetAllStepOutProductList();
  const [updateStepOutProductHandler, { data }] = useUpdateStepOutProduct();

  useEffect(() => {
    if (state?.tableData?.similarProducts?.length) {
      let alreadySelectedSimilarProducts =
        state?.tableData?.similarProducts?.map((item) => {
          return {
            uuId: uuidv4(),
            similarProducts: { label: item?.title, value: item?._id },
          };
        });
      setSelectedSimilarProducts(alreadySelectedSimilarProducts);
    }
  }, [state]);

  const handleAddSimilarProducts = () =>
    setSelectedSimilarProducts([
      ...selectedSimilarProducts,
      {
        uuId: uuidv4(),
        similarProducts: [],
      },
    ]);

  const handleRemoveSelectedSimilarProducts = (uuId) =>
    confirmDelete(() =>
      setSelectedSimilarProducts(
        selectedSimilarProducts.filter((item) => item.uuId !== uuId)
      )
    );

  const handleChangeSelectedSimilarProducts = (product, uuId) => {
    setSelectedSimilarProducts(
      selectedSimilarProducts?.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              similarProducts: {
                label: product.label,
                value: product.value,
              },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let similarProductsList = [];

    if (allProducts?.getAllStepOutProduct?.length) {
      // eslint-disable-next-line array-callback-return
      allProducts?.getAllStepOutProduct?.map((item) => {
        similarProductsList.push({ label: item?.title, value: item?._id });
        setSimilarProductsList(similarProductsList);
      });
    }
  }, [allProducts?.getAllStepOutProduct]);

  const [finalSimilarProductArr, setFinalSimilarProductArr] = useState([]);

  useEffect(() => {
    if (data) {
      let updatedSimilarProduct = [];
      finalSimilarProductArr.forEach((i) => {
        updatedSimilarProduct.push(
          allProducts?.getAllStepOutProduct?.find((j) => j?._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          similarProducts: updatedSimilarProduct,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allProducts?.getAllStepOutProduct,
    dispatch,
    finalSimilarProductArr,
    data,
  ]);

  const submitDataHandler = () => {
    var finalListOfSimilarProductsId = [];
    selectedSimilarProducts?.forEach((item) => {
      finalListOfSimilarProductsId.push(item.similarProducts.value);
    });
    setFinalSimilarProductArr(finalListOfSimilarProductsId);
    let data = {
      id,
      similarProducts: finalListOfSimilarProductsId,
    };

    if (data?.similarProducts?.length > 0) {
      if (
        id &&
        data?.similarProducts?.every((product) => product !== undefined)
      ) {
        updateStepOutProductHandler(id, {
          similarProducts: data.similarProducts,
        });
        toaster("success", "Similar products updated successfully");
      } else {
        toaster("error", "Please select the product");
      }
    } else {
      toaster("error", "Please select at least one product");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDataHandler();
      }}
    >
      <Row className="mt-2">
        {selectedSimilarProducts?.map(({ uuId, similarProducts }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={uuId}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"reviews" + uuId}>
                Selected similar products {index + 1}
              </Label>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Select
                      isSearchable={true}
                      id={uuId}
                      name="similarProducts"
                      value={similarProducts}
                      options={similarProductsList}
                      onChange={(value) => {
                        handleChangeSelectedSimilarProducts(value, uuId);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {selectedSimilarProducts.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveSelectedSimilarProducts(uuId)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>
      <SaveDiscardBtnGroup addAction={handleAddSimilarProducts} />
    </form>
  );
};

export default SimilarProducts;
