import React, { forwardRef } from "react";
//import { Cut, Line, Printer, Text, render } from "react-thermal-printer";
//import html2pdf from "html2pdf.js";
//import { useImperativeHandle } from "react";

//import { Card, CardBody, CardHeader } from "reactstrap";

// const PrintComponent = forwardRef(({ item }, ref) => {
//   return (
//     <div style={{ width: "100%" }} ref={ref} className="print-component">
//       <Card>
//         <CardHeader style={{ backgroundColor: "#ffe500" }}>
//           <div
//             style={{
//               fontSize: "20px",
//               marginBottom: "-3px",
//             }}
//           >
//             <div style={{ marginBottom: "6px", marginTop: "-3px" }}>
//               <strong>Name:</strong>{" "}
//               <span style={{ fontWeight: "400" }}>{item?.guestName || ""}</span>
//             </div>

//             <div style={{ marginBottom: "6px" }}>
//               <strong>Order ID:</strong>{" "}
//               <span style={{ fontWeight: "400" }}>{item?.orderId}</span>
//             </div>
//             <div style={{ marginBottom: "" }}>
//               <strong>MobileNo.:</strong> <span>{item?.mobileNo || ""}</span>
//             </div>
//             <div>
//               <strong>Token number:</strong>{" "}
//               <span style={{ fontWeight: "400", marginBottom: "5px" }}>
//                 {item?.tokenNo || ""}
//               </span>
//             </div>
//           </div>
//         </CardHeader>
//         <hr style={{ margin: "0" }} />
//         <CardBody style={{ padding: "13px 20px" }}>
//           {item?.printCount >= 1 && <div>Duplicate print</div>}
//           <span
//             style={{
//               fontSize: "20px",
//               fontWeight: "bold",
//               marginTop: "0px",
//               padding: "0px",
//             }}
//           >
//             Ordered item
//           </span>

//           <div style={{ marginTop: "-10px" }}>
//             {item?.items?.map((el) => (
//               <div
//                 key={el?._id}
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   marginTop: "10px",
//                   gap: "20px",
//                 }}
//               >
//                 <div style={{ marginBottom: "-10px" }}>
//                   <span
//                     style={{
//                       margin: "",
//                       fontSize: "20px",
//                       fontWeight: "400",
//                     }}
//                   >
//                     {el.itemName}
//                     <span>
//                       {el?.options?.name ? `(${el?.options?.name})` : ""}
//                     </span>
//                   </span>
//                   <p>
//                     {el?.extras?.map((i, j) => (
//                       <li key={j}>{i?.name}</li>
//                     ))}
//                   </p>
//                 </div>
//                 <span
//                   style={{
//                     fontSize: "20px",
//                   }}
//                 >
//                   x{el?.quantity}
//                 </span>
//               </div>
//             ))}
//             {item?.guestRemark && (
//               <div>
//                 <strong>Guest Instructions: </strong>
//                 {item?.guestRemark}
//               </div>
//             )}
//           </div>
//         </CardBody>
//       </Card>
//     </div>
//   );
// });

//const PrintComponent = forwardRef(({ item }, ref) => {
// const printContentRef = useRef();
// useImperativeHandle(ref, () => ({
//   printToPDF() {
//     // const element = document.getElementById("print-content");
//     const element = printContentRef.current;
//     const opt = {
//       margin: 5,
//       filename: `order.pdf`,
//       image: { type: "jpeg", quality: 0.98 },
//       html2canvas: { scale: 1.7 },
//       jsPDF: { unit: "mm", format: "a7", orientation: "portrait" },
//     };

//     html2pdf()
//       .from(element)
//       .set(opt)
//       .toPdf()
//       .output("blob")
//       .then((blob) => {
//         const pdfUrl = URL.createObjectURL(blob);
//         const iframe = document.createElement("iframe");
//         iframe.style.display = "none";
//         iframe.src = pdfUrl;
//         //console.log(pdfUrl);
//         document.body.appendChild(iframe);
//         iframe.onload = () => {
//           //console.log("here");
//           iframe.contentWindow.print();

//           //document.body.removeChild(iframe);
//         };
//       });
//   },
// }));

// const print = async () => {
//   try {
//     const data = await render(receipt);

//     const port = await window.navigator.serial.requestPort();
//     await port.open({ baudRate: 9600 });

//     const writer = port.writable?.getWriter();
//     if (writer) {
//       const uint8ArrayData = new Uint8Array(data);

//       await writer.write(uint8ArrayData);

//       writer.releaseLock();
//     }

//     await port.close();
//     console.log("yes");
//   } catch (error) {
//     console.error("Failed to print receipt:", error);
//   }
// };
//const PrintComponent = ({ item }) => {
//   return (
//     <div
//       style={{
//         width: "100%",
//         height: "100%",
//         overflow: "hidden",
//       }}
//       ref={ref}
//       className="print-component"
//     >
//       <div style={{ width: "100%", height: "100%" }} id="print-content">
//         <div>
//           {/* style={{ backgroundColor: "#ffe500" }} */}
//           <div
//             style={{
//               fontSize: "12px",
//               marginBottom: "-3px",
//             }}
//           >
//             <div style={{ marginBottom: "3px", marginTop: "-3px" }}>
//               <strong>Name:</strong>{" "}
//               <span style={{ fontWeight: "400" }}>{item?.guestName || ""}</span>
//             </div>

//             <div style={{ marginBottom: "3px" }}>
//               <strong>Order ID:</strong>{" "}
//               <span style={{ fontWeight: "400" }}>{item?.orderId}</span>
//             </div>
//             <div style={{ marginBottom: "3px" }}>
//               <strong>MobileNo.:</strong> <span>{item?.mobileNo || ""}</span>
//             </div>
//             <div>
//               <strong>Token number:</strong>{" "}
//               <span style={{ fontWeight: "400", marginBottom: "5px" }}>
//                 {item?.tokenNo || ""}
//               </span>
//             </div>
//           </div>
//         </div>
//         <hr style={{ margin: "0" }} />
//         <div style={{ padding: "10px" }}>
//           {item?.printCount >= 1 && (
//             <div style={{ fontSize: "12px" }}>Duplicate print</div>
//           )}
//           <span
//             style={{
//               fontSize: "12px",
//               fontWeight: "bold",
//               marginTop: "0px",
//               padding: "0px",
//             }}
//           >
//             Ordered item
//           </span>

//           <div style={{ marginTop: "-10px", fontSize: "12px" }}>
//             {item?.items?.map((el) => (
//               <div
//                 key={el?._id}
//                 style={{
//                   display: "flex",
//                   //justifyContent: "space-between",
//                   marginTop: "10px",
//                   gap: "10px",
//                 }}
//               >
//                 <div style={{ marginBottom: "-10px" }}>
//                   <span
//                     style={{
//                       margin: "",
//                       fontSize: "10px",
//                       fontWeight: "400",
//                     }}
//                   >
//                     {el.itemName}
//                     <span>
//                       {el?.options?.name ? `(${el?.options?.name})` : ""}
//                     </span>
//                   </span>
//                   <span style={{ fontSize: "10px" }}>
//                     {el?.extras?.map((i, j) => (
//                       <li key={j}>{i?.name}</li>
//                     ))}
//                   </span>
//                 </div>
//                 <span
//                   style={{
//                     fontSize: "10px",
//                   }}
//                 >
//                   x{el?.quantity}
//                 </span>
//               </div>
//             ))}
//             {item?.guestRemark && (
//               <div style={{ marginTop: "4px" }}>
//                 <strong>Guest Instructions: </strong>
//                 {item?.guestRemark}
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// });

const PrintComponent = forwardRef(({ item }, ref) => {
  const dateAndTime = new Date(item?.createdAt).toLocaleString("en-IN", {
    hour12: true,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  return (
    <div
      ref={ref}
      style={{
        fontSize: "22px",
        padding: "5px",
        top: "2px",
        fontWeight: "bold",
      }}
      className="print-component"
    >
      <div>
        <strong>Order Time:</strong> <span> {dateAndTime}</span>
      </div>
      <div>
        <strong>Name:</strong> <span>{item?.guestName || ""}</span>
      </div>
      <div>
        <strong>OrderId: </strong> <span>{item?.orderId || ""}</span>
      </div>
      <div>
        <strong>MobileNo:</strong> <span>{item?.mobileNo || ""}</span>
      </div>
      <div>
        <strong>Token No:</strong> <span>{item?.tokenNo || ""}</span>
      </div>
      <hr style={{ backgroundColor: "black", margin: "0" }} />
      <div style={{}}>
        {/* {item?.items?.map((el) => (
          <div
            key={el?._id}
            style={{
              display: "flex",
              //justifyContent: "space-between",
              marginTop: "10px",
              gap: "10px",
            }}
          >
            <div style={{}}>
              <span style={{}}>
                {el.itemName}
                <span>{el?.options?.name ? `(${el?.options?.name})` : ""}</span>
              </span>
              <span style={{}}>
                {el?.extras?.map((i, j) => (
                  <li key={j}>{i?.name}</li>
                ))}
              </span>
            </div>
            <span style={{}}>x{el?.quantity}</span>
          </div>
        ))} */}
        <div>
          {/* <table width={"100%"}>
            <tbody>
              {item?.items?.map((menu, i) => {
                const itemArray = menu?.itemName?.split(" ");
                return (
                  <tr key={i}>
                    <td>
                      {menu?.quantity} X {menu.itemName}
                      <span>
                        {menu?.options?.name ? `(${menu?.options?.name})` : ""}
                      </span>
                      <span style={{}}>
                        {menu?.extras?.map((i, j) => (
                          <li key={j}>{i?.name}</li>
                        ))}
                      </span>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  {item?.guestRemark && (
                    <>
                      <strong>Instructions: </strong>
                      {item?.guestRemark}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>***End of Receipt***</td>
              </tr>
            </tbody>
          </table> */}
          <table width={"100%"}>
            <tbody>
              {item?.items?.map((menu, i) => {
                const itemWords = menu?.itemName?.split(" ");
                const lines = [];
                const maxWordsPerLine = 2;

                for (let j = 0; j < itemWords.length; j += maxWordsPerLine) {
                  lines.push(
                    itemWords?.slice(j, j + maxWordsPerLine).join(" ")
                  );
                }

                return (
                  <React.Fragment key={i}>
                    <tr>
                      <td>
                        {menu?.quantity} X {lines[0]}
                        {lines?.slice(1)?.map((line, index) => (
                          <div key={index} style={{ marginLeft: "15px" }}>
                            {line}
                          </div>
                        ))}
                        <div>
                          {menu?.options?.name
                            ? `(${menu?.options?.name})`
                            : ""}
                        </div>
                        <span style={{}}>
                          {menu?.extras?.map((extra, j) => (
                            <li key={j}>{extra?.name}</li>
                          ))}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>---------------------</td>
                    </tr>
                  </React.Fragment>
                );
              })}
              <tr>
                <td>
                  {item?.guestRemark && (
                    <>
                      <strong>Instructions: </strong>
                      {item?.guestRemark}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>***End of Receipt***</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* //<hr style={{ marginTop: "3px" }} /> */}
      {/* <span style={{}}>--------------End of Receipt--------------</span> */}
    </div>
  );
});

export default PrintComponent;
