import React, { useEffect, useState } from "react";
import StayDateBookingTable from "./StayDateBookingTable";
import { useGetStayDateBooking } from "../../../api/dataSource";
// import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { CSVLink } from "react-csv";
import { useHostelList } from "../../../api/dataSource";
import Select from "react-select";
import { dateFormatter } from "@utils";
import { selectThemeColors } from "@utils";

const StayDateBooking = () => {
  const [tableData, setTableData] = useState([]);
  // const [limit, setLimit] = useState(20);
  // const [page, setPage] = useState(1);
  const [hostel, setHostel] = useState([]);
  const [date, setDate] = useState(dateFormatter(new Date()));
  const [HostelsList, setHostelsList] = useState([]);
  const [status, setStatus] = useState({
    label: "Active",
    value: "Active",
  });
  const { data: allHostelsData } = useHostelList();
  const [filterData, setFilterData] = useState({
    date,
    hostel: null,
    status,
  });

  const { data, refetch } = useGetStayDateBooking(
    filterData?.date,
    filterData?.hostel, //hostel.length > 0 ? hostel.map((hostel) => hostel.value) : null,
    filterData?.status?.value
  );

  const tableHeaderData = [
    { label: "Guest Name", key: "GuestName" },
    { label: "Reservation No", key: "ReservationNo" },
    { label: "Booking Date", key: "ReservationDate" },
    { label: "Checkin Date", key: "ArrivalDate" },
    { label: "Checkout Date", key: "DepartureDate" },
    { label: "Folio No", key: "FolioNo" },
    { label: "Hostel Name", key: "hostelName" },
    { label: "Booking Source", key: "Source" },
    { label: "Room No", key: "RoomNo" },
    { label: "Status", key: "Status" },
    { label: "Booking Status", key: "BookingStatus" },
    { label: "Room Charges", key: `BaseRateExclusiveTax.${date}` },
    { label: "Room Charges(Tax)", key: `BaseRateInclusiveTax.${date}` },
    { label: "Other Charges", key: "otherCharges" },
    { label: "Total", key: "TotalExclusivTax" },
    { label: "Total(Tax)", key: "TotalInclusiveTax" },
    { label: "Other Revenue", key: "OtherRevenueExclusiveTax" },
    { label: "Other Revenue(Tax)", key: "OtherRevenueInclusiveTax" },
  ];

  const statusList = [
    { label: "Active", value: "Active" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  const submitHandler = () => {
    const data = {
      date,
      hostel:
        hostel?.length > 0 ? hostel?.map((hostel) => hostel?.value) : null,
      status: status,
    };

    setFilterData(data);
  };
  const resetHandler = () => {
    setFilterData({});
    setStatus({
      label: "Active",
      value: "Active",
    });
    setDate(dateFormatter(new Date()));
    setHostel([]);
    refetch();
  };
  // const [totalPage, setTotalPage] = useState(1);

  // useEffect(() => {
  //   refetch();
  // }, [page]);

  // useEffect(() => {
  //   refetch();
  // }, [refetch, status]);

  // useEffect(() => {
  //   refetch();
  // }, [hostel]);

  useEffect(() => {
    if (data?.getDailyBookingsByStayDate) {
      setTableData(data?.getDailyBookingsByStayDate);
    } else {
      setTableData([]);
    }
    // setTotalPage(Math.ceil(data?.getDailyEzeeBookings?.count / limit));
  }, [data]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        {/* <Row>
          <Col>
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Rooms Sold: <b>{data?.getDailyBookingsByStayDate?.length}</b>
              </Label>
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="date">
                Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={date}
                onChange={(date) => setDate(dateFormatter(new Date(date)))}
                id="date"
                placeholder="Date"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Status*
              </Label>
              {/* <Label className="form-label" for="status">
              <h5>Check coupons status*</h5>
            </Label> */}
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={false}
                isSearchable={true}
                id="status"
                name="status"
                value={status}
                options={statusList}
                onChange={(value) => {
                  setStatus(value);
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="1">
            <Button.Ripple
              color="primary"
              type="button"
              onClick={() => {
                submitHandler();
              }}
              style={{
                marginTop: "21px",
              }}
              // size="sm"
            >
              Search
            </Button.Ripple>
          </Col>
          <Col sm="1">
            <Button.Ripple
              color="danger"
              type="button"
              onClick={() => {
                resetHandler();
              }}
              style={{
                marginTop: "21px",
              }}
              // size="sm"
            >
              Reset
            </Button.Ripple>
          </Col>
          <Col sm="1">
            <CSVLink
              headers={tableHeaderData}
              data={tableData}
              filename={"staydateBookings.csv"}
            >
              <Button.Ripple
                color="secondary"
                type="button"
                style={{
                  marginTop: "21px",
                }}

                // size="sm"
              >
                Export
              </Button.Ripple>
            </CSVLink>
          </Col>
        </Row>
        <StayDateBookingTable data={tableData} date={date} refetch={refetch} totalCount={data?.getDailyBookingsByStayDate?.length}/>
        {/* <TablePagination setPage={setPage} page={page} totalPage={totalPage} /> */}
      </div>
    )
  );
};

export default StayDateBooking;
