import HostelComparisons from "../../views/HostelComparisons";

const HostelComparisonsRoutes = [
  {
    path: "/hostelComparisons",
    component: HostelComparisons,
    exact: true,
  },
];
export default HostelComparisonsRoutes;
