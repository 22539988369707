import React, { useState } from "react";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import * as XLSX from "xlsx";
import { useUploadXlsx } from "../../api/dataSource";
// import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";

const FileUpload = () => {
  const [selectData, setSelectData] = useState(null);
  const [data, setData] = useState([]);
  const [uploadXlsxHandler, { data: xlsxData }] = useUploadXlsx();

  const SelectDataList = [{ label: "Employee", value: "employee" }];

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setData(parsedData);
    };
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // uploadXlsxHandler(data);
    data.map((item) => uploadXlsxHandler(item));
  };
  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="selectData">
              Select Data
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="selectData"
              name="selectData"
              options={SelectDataList}
              required
              value={selectData}
              onChange={(value) => setSelectData(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <div>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />

          <Button type="submit">Submit</Button>

          {data.length > 0 && (
            <table>
              <thead>
                <tr>
                  {Object.keys(data[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, index) => (
                      <td key={index}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Row>
      {/* <SaveDiscardBtnGroup /> */}
    </form>
  );
};

export default FileUpload;
