import React, { useEffect, useState } from "react";
import HomePageTable from "./HomePageTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "@components/common/TablePagination";
import { useHistory } from "react-router";
import { useGetAllPaginatedHomePage } from "../../../api/dataSource";

const HomePage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const { data, refetch } = useGetAllPaginatedHomePage(limit, page);
  const history = useHistory();

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedHomePageList?.data.length > 0) {
      setTableData(data?.getPaginatedHomePageList?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedHomePageList?.count / limit));
  }, [
    data?.getPaginatedHomePageList?.count,
    data?.getPaginatedHomePageList?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedHomePageList?.data.length) {
      let editData = data?.getPaginatedHomePageList?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedHomePageList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/cms/edit/homePage/${id}`);
    } else {
      setId(id);
    }
  };

  
  const handleCreateUpdateHomepage = (id) => {
    history.push(`/cms/edit/homePage/${id}`);
  };

  return (
    localStorage.getItem("modules")?.includes("CMS") && (
      <div>
        <HomePageTable
          setId={setId}
          id={id}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          handleEdit={handleEdit}
          handleCreateUpdateHomepage={handleCreateUpdateHomepage}
          refetch
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
          setId={setId}
        />
      </div>
    )
  );
};

export default HomePage;
