import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

const GET_PROPERTY_WISE_NO_SHOW_AND_CANCELLATION_REPORT = gql`
  query getPropertyWiseNoShowAndCancellationReport($dates: DatesInput) {
    getPropertyWiseNoShowAndCancellationReport(dates: $dates) {
      data {
        _id
        hostelName
        totalBookings
        paidBookings
        unpaidBookings
        revenue
      }
    }
  }
`;
const EXPORT_NOSHOW_CANCEL_RESERVATION_DATA = gql`
  query exportNoshowCancelReservationData($dates: DatesInput) {
    exportNoshowCancelReservationData(dates: $dates) {
      ReservationNo
      ArrivalDate
      DepartureDate
      _id
      GuestName
      ReservationDate
      Room
      Source
      Mobile
      Email
      Phone
      NoOfGuest
      NoOfNights
      DueAmount
      Deposit
      Status
      BookingStatus
      TotalTax
      TotalInclusiveTax
      TotalExclusivTax
      hostelName
    }
  }
`;

export const useGetPropertyWiseNoShowAndCancellationReport = (dates) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PROPERTY_WISE_NO_SHOW_AND_CANCELLATION_REPORT,
    {
      variables: {
        dates,
      },
      skip: !dates,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useExportNoshowCancelReservationData = (dates) => {
  const { loading, error, data, refetch } = useQuery(
    EXPORT_NOSHOW_CANCEL_RESERVATION_DATA,
    {
      variables: {
        dates,
      },
      skip: !dates,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
