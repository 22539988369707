import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Label, Input, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import SwitchIcon from "@components/common/switch-icon";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { Trash } from "react-feather";
import { useUpdateStepOutProduct } from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import { dateFormatter } from "@utils";
import confirmDelete from "@components/common/confirmDelete";

const Batches = () => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [batches, setBatches] = useState([
    {
      id: uuidv4(),
      startDate: null,
      basePrice: "",
      status: false,
    },
  ]);

  const [updateStepOutProductHandler, { data }] = useUpdateStepOutProduct();

  const batchesStateData = state?.tableData?.batches;
  useEffect(() => {
    if (batchesStateData) {
      const sortedArray = [...batchesStateData]?.sort(
        (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
      );
      let UpdatedBatches = sortedArray?.map((item) => {
        return {
          id: uuidv4(),
          startDate: item?.startDate,
          basePrice: item?.basePrice,
          status: item?.status,
        };
      });
      setBatches(UpdatedBatches);
    }
  }, [batchesStateData]);

  useEffect(() => {
    if (data?.updateStepOutProduct?.title) {
      toaster("success", "Batches updated successfully");
    }
  }, [data?.updateStepOutProduct?.title, batchesStateData]);

  const handleStartDateChange = (val, id, key) => {
    setBatches(
      batches?.map((item) =>
        item?.id === id ? { ...item, [key]: dateFormatter(val) } : item
      )
    );
  };

  const handleBasePriceChange = (val, id, key) => {
    setBatches(
      batches?.map((item) =>
        item?.id === id ? { ...item, [key]: Number(val) } : item
      )
    );
  };

  const handelStatusChange = (val, id, key) => {
    setBatches((prevBatches) =>
      prevBatches.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };

  const handleAddBatch = () => {
    setBatches([
      ...batches,
      {
        id: uuidv4(),
        startDate: null,
        basePrice: "",
        status: false,
      },
    ]);
  };

  const handleRemoveBatch = (id) => {
    let newBatchData = batches.filter((item) => {
      if (item?.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setBatches(newBatchData));
  };

  const handleSubmitHandler = () => {
    let isValid = true;
    let batchesClone = JSON.parse(JSON.stringify(batches));
    batchesClone.forEach((item) => {
      delete item.id;
    });

    let batchData = { batches: batchesClone };

    batchesClone?.forEach((item) => {
      isValid &&
        (item?.startDate && item?.basePrice
          ? (isValid = true)
          : (isValid = false));
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...batchData,
        })
      );
      updateStepOutProductHandler(id, batchData);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };
  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitHandler();
      }}
    >
      {batches?.map(({ id, startDate, basePrice, status }, index) => (
        <div key={id}>
          <Row className="mt-2">
            <Col sm="12">
              <strong>
                <Label className="form-label" for="cancel">
                  Batch {index + 1}
                </Label>
              </strong>
            </Col>
            <Col sm="12">
              <Row>
                <Col sm="5">
                  <Label className="form-label" for="cancel">
                    Start Date
                  </Label>
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    value={startDate}
                    disabled={
                      startDate === null
                        ? false
                        : dateFormatter(new Date()) > dateFormatter(startDate)
                    }
                    onChange={(date) =>
                      handleStartDateChange(date, id, "startDate")
                    }
                    id="startDate"
                    placeholder="select start date"
                  />
                </Col>

                <Col sm="4">
                  <Label className="form-label" for="basePrice">
                    Base Price
                  </Label>

                  <Input
                    type="text"
                    value={basePrice}
                    disabled={
                      dateFormatter(new Date()) > dateFormatter(startDate)
                    }
                    id="basePrice"
                    name="basePrice"
                    placeholder={`Base Price`}
                    onChange={(e) =>
                      handleBasePriceChange(e.target.value, id, "basePrice")
                    }
                  />
                </Col>

                <Col sm="2 ">
                  <Label
                    className="form-label ml-1 pl-1 pb-1"
                    for={`status_${id}`}
                  >
                    Status
                  </Label>
                  <SwitchIcon
                    className=""
                    checked={status}
                    id={`status_${id}`}
                    name={`status_${id}`}
                    disabled={
                      dateFormatter(new Date()) > dateFormatter(startDate)
                    }
                    handleChecked={(value) =>
                      handelStatusChange(value, id, "status")
                    }
                  />
                </Col>

                <Col sm="1" md="1" style={{ marginTop: "25px" }}>
                  {batches.length > 0 ? (
                    <>
                      <Button.Ripple
                        className="btn-icon rounded-circle mb-1 mr-1"
                        color="light"
                        type="button"
                        size="sm"
                        onClick={() => handleRemoveBatch(id)}
                      >
                        <Trash size={14} />
                      </Button.Ripple>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ))}
      <SaveDiscardBtnGroup addAction={handleAddBatch} />
    </form>
  );
};

export default Batches;
