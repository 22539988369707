import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import {
  useGetRecommendationByProductType,
  useUpdateLandingPageDetails,
} from "@api";
import MultiCheckbox from "@components/common/multi-checkbox";
import { useSelector, useDispatch } from "react-redux";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { Edit2, Check } from "react-feather";
import { HOSTELLER } from "../../../utility/constant";

const Form = ({ type }) => {
  const state = useSelector((state) => state?.cms);
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState();
  const [editTab, setEditTab] = useState();
  const [recommendationList, setRecommendationList] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const dispatch = useDispatch();
  const [changeMade, setChangeMade] = useState(false);

  const { data: recommendationData } = useGetRecommendationByProductType(type);
  const [updateLandingPageDetailsHandler, { data: updatedData }] =
    useUpdateLandingPageDetails();

  useEffect(() => {
    if (recommendationData?.getRecommendationByProductType)
      setRecommendationList(recommendationData.getRecommendationByProductType);
  }, [recommendationData?.getRecommendationByProductType]);

  useEffect(() => {
    const activeRecommendationList = data?.recommendations?.find(
      (v) => v._id === activeTab?._id
    );

    setEditTab();

    setRecommendations(activeRecommendationList?.cards.map((v) => v?._id));
  }, [activeTab]);

  useEffect(() => {
    if (state?.tableData) {
      const existData = state.tableData.mainSection?.find(
        (v) => v.type === type
      );

      existData?.recommendations?.length > 0 &&
        !activeTab &&
        setActiveTab(existData?.recommendations[0]);

      existData && setData(existData);
    }
  }, [state]);

  useEffect(() => {
    if (updatedData?.updateLandingPage?._id) {
      toaster("success", "Submitted successfully");

      let mainSection = [...state.tableData.mainSection];

      const index = mainSection?.findIndex((v) => v.type === type);

      mainSection[index] = {
        ...data,
        recommendations: data.recommendations.map((r) => ({
          _id: r._id,
          title: r.title,
          cards:
            r._id !== activeTab._id
              ? r.cards
              : recommendationList
                  .filter((v) => recommendations.includes(v._id))
                  .reverse(),
        })),
      };

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          mainSection,
        })
      );
    }
  }, [updatedData?.updateLandingPage]);

  const onTextChange = (e) => {
    setChangeMade(true);
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onRecommendationChange = (e, item) => {
    e.preventDefault();
    setChangeMade(true);

    const itemIndex = data.recommendations.findIndex((v) => v._id === item._id);

    let recommendations = [...data.recommendations];

    recommendations[itemIndex] = {
      ...recommendations[itemIndex],
      title: e.target.value,
    };

    setData((prev) => ({ ...prev, recommendations }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      recommendations.length !== 3 &&
      recommendations.length !== 6 &&
      recommendations.length !== 9
    )
      return toaster("error", "Recommendations could be only 3, 6 or 9 items");

    let mainSection = [...state.tableData.mainSection];

    const index = mainSection?.findIndex((v) => v.type === type);

    mainSection[index] = { ...data };

    mainSection = mainSection.map((item) => ({
      ...item,
      recommendations: item.recommendations.map((r) => ({
        title: r.title,
        cards:
          r._id === activeTab._id ? recommendations : r.cards.map((c) => c._id),
      })),
      __typename: undefined,
    }));

    updateLandingPageDetailsHandler(HOSTELLER, { mainSection }); // "hosteller" is used to get landing page doc

    setChangeMade(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row className='mt-2'>
        <Col sm='12' md='6'>
          <FormGroup>
            <Label className='form-label' for='title'>
              Title
            </Label>
            <Input
              type='text'
              defaultValue={data.title || ""}
              id='title'
              name='title'
              placeholder='Add title'
              onChange={onTextChange}
            />
          </FormGroup>
        </Col>
        <Col sm='12' md='6'>
          <FormGroup>
            <Label className='form-label' for='heading'>
              Heading
            </Label>
            <Input
              type='text'
              defaultValue={data.heading || ""}
              id='heading'
              name='heading'
              placeholder='Add heading'
              onChange={onTextChange}
            />
          </FormGroup>
        </Col>
        <Col sm='12'>
          <FormGroup>
            <Label className='form-label' for='description'>
              Description
            </Label>
            <Input
              type='textarea'
              defaultValue={data.description || ""}
              id='description'
              name='description'
              placeholder='Add description'
              onChange={onTextChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <h4 className='my-2'>Select {type} Recommendation</h4>
      <Card className='border'>
        <CardHeader className='d-flex justify-content-between flex-wrap'>
          {data?.recommendations?.length > 0 &&
            data?.recommendations?.map((item, index) => (
              <div key={index} className='d-flex flex-fill mr-2'>
                <InputGroup onClick={() => setActiveTab(item)}>
                  <Input
                    type='text'
                    value={item.title}
                    disabled={item._id !== editTab}
                    style={{ cursor: "pointer" }}
                    autoFocus={item._id === editTab}
                    onChange={(e) => onRecommendationChange(e, item)}
                  />

                  <Button
                    className='btn-icon rounded-0'
                    color={item._id === activeTab?._id ? "light" : "secondary"}
                    onClick={() => {
                      item._id !== editTab
                        ? setEditTab(item._id)
                        : setEditTab();
                    }}
                  >
                    {item._id === editTab ? (
                      <Check size={16} />
                    ) : (
                      <Edit2 size={16} />
                    )}
                  </Button>
                </InputGroup>
              </div>
            ))}
        </CardHeader>

        <CardBody>
          <p>
            Select Recommendations for <b> {activeTab?.title?.toUpperCase()}</b>{" "}
            :
          </p>
          <MultiCheckbox
            list={recommendationList}
            data={recommendations}
            setData={setRecommendations}
            setChangeMade={setChangeMade}
            max={9}
          />
        </CardBody>
      </Card>

      <SaveDiscardBtnGroup disabled={!changeMade} />
    </form>
  );
};

export default Form;
