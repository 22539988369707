import React, { useState, useEffect } from "react";
import StepoutUserTable from "./StepoutUserTable";
import { useGetPaginatedStepoutUser } from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";

const StepOutUser = () => {
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  const { data, refetch } = useGetPaginatedStepoutUser(limit, page);
  // console.log(data?.getPaginatedProfile?.count)

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedProfile?.data?.length > 0) {
      setTableData(data?.getPaginatedProfile?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getPaginatedProfile?.count / limit));
  }, [
    data?.getPaginatedProfile?.count,
    data?.getPaginatedProfile?.data,
    limit,
  ]);

  return (
    <div>
      <StepoutUserTable data={tableData} />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
    </div>
  );
};

export default StepOutUser;
