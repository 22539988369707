import React, { useEffect, useState } from "react";
import { useGetPaginatedMenuCategory } from "../../../api/Unbox/Queries";
import MenuCategoryTable from "./MenuCategoryTable";
import TablePagination from "../../../@core/components/common/TablePagination";
import AddEditModal from "./AddEditModal";

const UnboxMenuCategory = () => {
  const [openModal, setOpenModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [editData, setEditData] = useState(null);

  const { data, refetch } = useGetPaginatedMenuCategory(limit, page);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    if (data?.getPaginatedUnboxMenuCategory?.data?.length > 0) {
      setTableData(data?.getPaginatedUnboxMenuCategory?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getPaginatedUnboxMenuCategory?.count / limit));
  }, [
    data?.getPaginatedUnboxMenuCategory?.count,
    data?.getPaginatedUnboxMenuCategory?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedUnboxMenuCategory?.data?.length > 0) {
      let findData = data?.getPaginatedUnboxMenuCategory?.data?.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedUnboxMenuCategory?.data, id]);

  return (
    <div>
      <MenuCategoryTable
        data={tableData}
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      <AddEditModal
        setId={setId}
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default UnboxMenuCategory;
