import React from "react";
import AddEditModal from "./AddEditModal";
import RequestsTable from "../../../@core/components/tables/RequestsTable";

const Requests = () =>
  localStorage.getItem("modules")?.includes("Glu Configuration") && (
    <RequestsTable AddEditModal={AddEditModal} />
  );

export default Requests;
