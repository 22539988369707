//Added By Gaurav
import gql from "graphql-tag";

export const GET_COUPON_APPLIED_BOOKINGS = gql`
query getCouponAppliedBookings($filter:filter){
    getCouponAppliedBookings(filter: $filter){
        data {
            _id
            usageCount
            totalAmountDiscounted
            totalAmountCharged
            totalNoOfNights
        }
        limit
        count
        page
}
}
`;

export const GET_APPLIED_COUPON_LIST_EXPORT = gql`
query getListForExport($filter:filter){
    getCouponAppliedBookingsForExport(filter:$filter){
        data{
            _id
            usageCount
            totalAmountDiscounted
            totalAmountCharged
            totalNoOfNights
        }
    }
}
`


