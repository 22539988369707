import React, { useEffect, useState } from "react";
import {
  useCreateMasterMenu,
  useUpdateMasterMenu,
} from "../../../api/Unbox/UnboxMasterMenu/Mutations";
import SlideModal from "../../../@core/components/common/slide-modal";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { useUploadImage } from "../../../api/dataSource";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import toaster from "@components/common/Toaster";
import { useGetMenuCategoryList } from "../../../api/Unbox/Queries";

export const typeOptions = [
  { label: "Veg", value: "Veg" },
  { label: "Non-Veg", value: "Non-Veg" },
];

export const taxOptions = [{ label: "5", value: "5" }];
const AddEditModal = ({ open, handleToggle, editData, refetch }) => {
  const [itemName, setItemName] = useState("");
  const [itemCategory, setItemCategory] = useState(null);
  const [menuCategoryList, setMenuCategoryList] = useState([]);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [type, setType] = useState(null);
  const [weekDayPrice, setWeekDayPrice] = useState(0);
  const [weekEndPrice, setWeekEndPrice] = useState(0);
  const [hsnCode, setHsnCode] = useState("");
  const [tax, setTax] = useState(null);
  const [nudge, setNudge] = useState("");

  const { data: allCategoryList, refetch: menuRefetch } =
    useGetMenuCategoryList();
  const [createMasterMenuHandler, { data: createMasterMenuData }] =
    useCreateMasterMenu();

  const [updateMasterMenuHandler, { data: updateMasterMenuData }] =
    useUpdateMasterMenu();
  const [uploadImageHandler, { data: imageData, reset, loading: imageLoad }] =
    useUploadImage();

  useEffect(() => {
    if (allCategoryList?.getAllUnboxMenuCategory?.length) {
      let list = allCategoryList?.getAllUnboxMenuCategory?.map((ele) => {
        return { label: ele?.categoryName, value: ele?.categoryName };
      });
      setMenuCategoryList(list);
    }
    menuRefetch();
  }, [allCategoryList?.getAllUnboxMenuCategory]);

  useEffect(() => {
    if (createMasterMenuData?.createUnboxMasterMenu || updateMasterMenuData) {
      refetch();
      handleToggle();
    }
  }, [createMasterMenuData?.createUnboxMasterMenu, updateMasterMenuData]);

  useEffect(() => {
    if (editData?._id) {
      setItemName(editData?.itemName !== null ? editData?.itemName : "");
      setItemCategory(
        editData?.itemCategory !== null
          ? { label: editData?.itemCategory, value: editData?.itemCategory }
          : null
      );
      setDescription(
        editData?.description !== null ? editData?.description : ""
      );
      setImage(editData?.image !== null ? editData?.image : null);
      setType(
        editData?.type !== null
          ? { label: editData?.type, value: editData?.type }
          : null
      );
      setWeekDayPrice(
        editData?.weekDayPrice !== null ? editData?.weekDayPrice : 0
      );
      setWeekEndPrice(
        editData?.weekEndPrice !== null ? editData?.weekEndPrice : 0
      );
      setTax(
        editData?.tax ? { label: editData?.tax, value: editData?.tax } : 5
      );
      setHsnCode(editData?.hsnCode !== null ? editData?.hsnCode : "");
      setNudge(editData?.nudge !== null ? editData?.nudge : "");
    } else {
      setItemName("");
      setItemCategory(null);
      setDescription("");
      setImage(null);
      setType(null);
      setWeekDayPrice(0);
      setWeekEndPrice(0);
      setHsnCode("");
      setTax(null);
      setNudge("");
    }
  }, [editData]);

  const handleAddPrice = (val, name) => {
    if (!isNaN(val) && !val?.includes("-")) {
      if (name === "weekDayPrice") {
        setWeekDayPrice(val);
      } else {
        setWeekEndPrice(val);
      }
    } else {
      toaster("error", "Enter Number Only");
    }
  };

  const handleAddString = (value, name) => {
    if (value !== " ") {
      if (name === "itemName") {
        setItemName(value);
      } else if (name === "description") {
        setDescription(value);
      }
    } else {
      toaster("error", "No initial space");
    }
  };
  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2097152,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setImage([preview]);
    uploadImageHandler(file.data, "icons/UnboxMasterMenu");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading amenity image: ${error.message}`);
  });

  const renderPreview = () =>
    image ? (
      <img className="rounded mr-1 mb-2 w-50" src={image} alt="avatar" />
    ) : null;

  useEffect(() => {
    setImage(imageData?.uploadImage?.url);
  }, [imageData]);

  const submitFormHandler = () => {
    let data = {
      itemName,
      itemCategory: itemCategory?.value,
      description,
      type: type?.value,
      image,
      weekDayPrice: +weekDayPrice,
      weekEndPrice: +weekEndPrice,
      tax: Number(tax?.value) || 5,
      hsnCode,
      nudge,
    };
    if (
      data?.itemName?.trim() !== "" &&
      data?.itemCategory?.trim() !== "" &&
      data?.type &&
      data?.weekDayPrice &&
      data?.description?.trim() !== "" &&
      data?.weekEndPrice
    ) {
      if (editData?._id) {
        updateMasterMenuHandler(editData?._id, data);
        toaster("success", "Master menu updated successfully");
      } else {
        createMasterMenuHandler(data);
        toaster("success", "Master menu added successfully");
      }
      setItemName("");
      setItemCategory(null);
      setDescription("");
      setType(null);
      setImage(null);
      setWeekDayPrice(0);
      setWeekEndPrice(0);
      setHsnCode("");
      setTax(0);
      setNudge("");
      //handleToggle();
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => submitFormHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="itemName">
                Item Name*
              </Label>
              <Input
                type="text"
                value={itemName}
                required
                id="itemName"
                name="itemName"
                placeholder="Add Item Name"
                onChange={(e) => handleAddString(e.target.value, "itemName")}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="itemCategory">
                Item Category*
              </Label>
              <Select
                isClearable={true}
                id="itemCategory"
                name="itemCategory"
                required
                theme={selectThemeColors}
                options={menuCategoryList}
                value={itemCategory}
                onChange={(value) => setItemCategory(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="description">
                Description*
              </Label>
              <Input
                type="text"
                value={description}
                required
                id="description"
                name="description"
                placeholder="Add Item Description"
                onChange={(e) => handleAddString(e.target.value, "description")}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="type">
                Type*
              </Label>
              <Select
                isClearable={true}
                id="type"
                name="type"
                required
                theme={selectThemeColors}
                options={typeOptions}
                value={type}
                onChange={(value) => setType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr)",
              margin: "0 13px",
              gap: "5px",
            }}
          >
            <FormGroup className="w-52">
              <Label className="form-label" for="weekDayPrice">
                Week Day Price*
              </Label>
              <Input
                type="text"
                value={weekDayPrice}
                required
                id="weekDayPrice"
                name="weekDayPrice"
                placeholder="Weekday Price"
                maxLength={9}
                onChange={(e) => handleAddPrice(e.target.value, "weekDayPrice")}
              />
            </FormGroup>
            <FormGroup className="w-52">
              <Label className="form-label" for="weekEndPrice">
                Week End Price*
              </Label>
              <Input
                type="text"
                value={weekEndPrice}
                required
                id="weekEndPrice"
                name="weekEndPrice"
                placeholder="Weekend Price"
                maxLength={9}
                onChange={(e) => handleAddPrice(e.target.value, "weekEndPrice")}
              />
            </FormGroup>
          </div>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="tax">
                Tax(%)
              </Label>
              <Select
                value={tax}
                required
                options={taxOptions}
                theme={selectThemeColors}
                id="tax"
                name="tax"
                placeholder="Select Tax Percentage"
                onChange={(value) => setTax(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hsnCode">
                HSN Code
              </Label>
              <Input
                type="text"
                value={hsnCode}
                required
                id="hsnCode"
                name="hsnCode"
                placeholder="Hsn Code"
                onChange={(e) => setHsnCode(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="nudge">
                Nudge
              </Label>
              <Input
                type="text"
                value={nudge}
                required
                id="nudge"
                name="nudge"
                placeholder="Add Nudge"
                onChange={(e) => setNudge(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col
            sm="12"
            style={{
              marginTop: "20px",
              marginBottom: "10px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "50%" }}>
              <Label className="form-label">Menu Image</Label>
              <DragDrop
                note="Image size should be less than 2MB"
                width="100%"
                uppy={uppy}
                locale={{
                  strings: {
                    dropHereOr: "Drop image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
            </div>

            <div style={{ width: "50%", marginLeft: "10px" }}>
              <div
                className="service-icon-preview"
                style={{
                  width: "300px",
                  //heigth: "360px",
                  margin: "25px",
                  padding: "5px",
                }}
              >
                {imageLoad ? "Uploading icon..." : <>{renderPreview()}</>}
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
