import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
// import TableList from "@components/common/TableList";
import TableList from "../common/TableList";
import { useGetPaginatedDisplayAdsList } from "../../../api/dataSource";
import { TABLE_TYPE } from "../common/TableList/constant";

const tableHeaderList = ["Action", "Ad Name", "Ad Type", "Status"];

const DisplayAdsTable = ({ AddEditModal }) => {
  const [limit, setLimit] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useGetPaginatedDisplayAdsList(limit, page);

  const history = useHistory();

  const handleAdd = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    refetch();
  }, [page]);
  useEffect(() => {
    if (data) {
      let displayAdsList = [];
      data?.getPaginatedDisplayAdsList?.data?.map((item) => {
        displayAdsList.push({
          id: item._id,
          adName: item?.adName,
          adType: item?.adType,
          url: item?.url,
          portraitImage: item?.portraitImage,
          landscapeImage: item?.landscapeImage,
          displayOrder: item?.displayOrder,
          status: item?.status,
        });
      });

      setTableData(displayAdsList);
      setTotalPage(Math.ceil(data?.getPaginatedDisplayAdsList?.count / limit));
    }
  }, [data]);

  useEffect(() => {
    if (editId && data?.getPaginatedDisplayAdsList?.data?.length) {
      let editData = data?.getPaginatedDisplayAdsList?.data.filter(
        (item) => item._id === editId
      );
      setEditData(editData);
    } else {
      setEditData(null);
    }
  }, [editId, data]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/cms/edit/pressMention/${id}`);
    } else {
      setEditId(id);
    }
  };

  return (
    <div>
      <TableList
        handleAdd={AddEditModal ? handleAdd : null}
        // filterHeader="All Display Ads"
        tableHeader="Display Ads"
        type={TABLE_TYPE.DIAPLAYADS}
        tableData={tableData}
        tableHeaderList={tableHeaderList}
        openModal={() => setOpenModal(!openModal)}
        handleEdit={handleEdit}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={() => setOpenModal(!openModal)}
          refetch={refetch}
          editData={editData}
          setEditId={setEditId}
        />
      )}
    </div>
  );
};

export default DisplayAdsTable;
