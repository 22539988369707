import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import ReviewPaymentDetailsButton from "./Review-Payment-DetailsButton";
import { useGOUpdateGoPurchaseRequest } from "../../../../api/GOPurchaseRequest/Mutation";
import { useParams } from "react-router";
import Select from "react-select";
import AttachmentComponent from "../AttachmentComponent";
import toaster from "@components/common/Toaster";
import Flatpickr from "react-flatpickr";
import { v4 as uuidv4 } from "uuid";
import confirmDelete from "@components/common/confirmDelete";
import AddPaymentDetailsButton from "./AddPaymentDetailsButton";
import { Trash, Upload } from "react-feather";
import Avatar from "@components/common/avatar";
import { useUploadImage } from "../../../../api/dataSource";
import { useHistory } from "react-router";

const PurchaseAndFinance = (refetch) => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);

  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [ifscCode, setifscCode] = useState("");
  const [bankType, setBankType] = useState("");
  const [bankDetailImage, setBankDetailImage] = useState([]);
  const [poAttachment, setPoAttachment] = useState([]);
  const [invoiceAttachment, setInvoiceAttachment] = useState([]);

  // const [paymentAttachment, setPaymentAttachment] = useState([]);
  const [deductionAmount, setDeductionAmount] = useState(0);
  const [deductionRemark, setDeductionRemark] = useState("");
  const [deductionAttachment, setDeductionAttachment] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([
    {
      id: uuidv4(),
      amountToPay: 0,
      paymentStatus: "Payment Due",
      paymentDate: null,
      bankRefNumber: "",
      amountPaid: 0,
      tdsAmount: 0,
      financeTeamRemark: "",
      paymentAttachment: null,
    },
  ]);

  const [index, setIndex] = useState("");

  //! Amount to pay Validation message useState
  const [amountToPayValidationMessage, setAmountToPayValidationMessage] =
    useState("");

  //! Amount paid Validation message useState
  const [amountPaidValidationMessage, setAmountPaidValidationMessage] =
    useState("");

  //! TDS amount Validation message useState
  const [tdsAmountValidationMessage, setTdsAmountValidationMessage] =
    useState("");

  //! Attachment (Payment Image) useState
  //   const [dummyStatePaymentAttachment] = useState(null);

  //   const uploadTypePayment = [{ label: "imageURL", value: "paymentAttachment" }];

  //! Update handler
  const [updateGoPurchaseHandler, { data: updateGoPurchaseData }] =
    useGOUpdateGoPurchaseRequest();

  //! Image upload handler
  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  const userDepartment = localStorage.getItem("department");

  //! Attachment (Bank Detail Image) useState
  const [dummyStateBankDetail] = useState(null);

  //! Attachment (Po Image) useState
  const [dummyStatePo] = useState(null);

  //! Attachment (Invoice Image) useState
  const [dummyStateInvoice] = useState(null);

  //! Attachment (Deduction Image) useState
  const [dummyStateDeduction] = useState(null);

  const history = useHistory();

  const dbData = state?.tableData;
  const dbStatus = dbData?.status;

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setPaymentDetails(
        paymentDetails?.map((item) => {
          if (item.id === index) {
            return { ...item, paymentAttachment: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  const paymentStatusList = [
    { label: "Payment Due", value: "Payment Due" },
    { label: "Payment Done", value: "Payment Done" },
    { label: "Payment Rejected", value: "Payment Rejected" },
    { label: "Payment Done - On Account", value: "Payment Done - On Account" },
  ];

  const bankTypeOptions = [
    { label: "Current", value: "Current" },
    { label: "Saving", value: "Saving" },
  ];

  const uploadTypeBankDetail = [
    { label: "imageURL", value: "bankDetailImage" },
  ];

  const uploadTypePo = [{ label: "imageURL", value: "poAttachment" }];

  const uploadTypeInvoice = [{ label: "imageURL", value: "invoiceAttachment" }];

  const uploadTypeDeduction = [
    { label: "imageURL", value: "deductionAttachment" },
  ];

  const fileUploader = (e, id) => {
    const file = e.target.files[0];
    if (!file) return;

    setIndex(id);

    const maxFileSize = 2097152;

    if (file.size > maxFileSize) {
      toaster("error", "File size exceeds the maximum limit of 2 MB.");
      return;
    }

    uploadImageHandler(
      file,
      `${state.tableData?.requestId?.toLowerCase()?.replaceAll(" ", "-")}` +
        `/Operations/Capex/PaymentAttachment`
    );

    toaster("success", "Payment attachment image uploaded successfully");
  };

  //refetching and toggle logic
  useEffect(() => {
    if (updateGoPurchaseData?.updateGOPurchaseRequest?.status === true) {
      history.push("/operations/goPurchase");
    }
  }, [updateGoPurchaseData]);

  const handleAmountToPayChange = (value, id, key) => {
    const parsedValue = parseFloat(value);

    if (value === "" || (!isNaN(parsedValue) && parsedValue >= 0)) {
      setPaymentDetails(
        paymentDetails?.map((item) =>
          item.id === id ? { ...item, [key]: parsedValue } : item
        )
      );

      if (parsedValue > dbData?.totalAmount) {
        if (key === "amountToPay") {
          setAmountToPayValidationMessage(
            "The amount to pay cannot be more than the total amount."
          );
        } else if (key === "amountPaid") {
          setAmountPaidValidationMessage(
            "The amount paid cannot be more than the total amount."
          );
        } else if (key === "tdsAmount") {
          setTdsAmountValidationMessage(
            "The TDS amount cannot be more than the total amount."
          );
        }
      } else {
        setAmountToPayValidationMessage("");
        setAmountPaidValidationMessage("");
        setTdsAmountValidationMessage("");
      }
    }
  };

  const handlePaymentStatusChange = (data, id, key) => {
    setPaymentDetails(
      paymentDetails?.map((item) =>
        item.id === id ? { ...item, [key]: data } : item
      )
    );
  };

  const handlePaymentDateChange = (value, id, key) => {
    setPaymentDetails(
      paymentDetails?.map((item) =>
        item.id === id ? { ...item, [key]: value } : item
      )
    );
  };

  const handleBankRefNumberAndFinanceRemarkTextChange = (value, id, key) => {
    setPaymentDetails(
      paymentDetails?.map((item) =>
        item.id === id ? { ...item, [key]: value } : item
      )
    );
  };

  const handleAddPaymentDetails = () => {
    setPaymentDetails([
      ...paymentDetails,
      {
        id: uuidv4(),
        amountToPay: 0,
        paymentStatus: "Payment Due",
        paymentDate: null,
        bankRefNumber: "",
        amountPaid: 0,
        tdsAmount: 0,
        financeTeamRemark: "",
        paymentAttachment: null,
      },
    ]);
  };

  const handleRemovePaymentDetails = (id) => {
    let newPaymentDetailsData = paymentDetails.filter((item) => {
      if (item.id !== id) {
        return true;
      }

      confirmDelete(() => setPaymentDetails(newPaymentDetailsData));
    });
  };

  // let totalAmountPaid, totalTdsAmount;

  const totalAmountPaid = dbData?.paymentDetails.reduce(
    (total, payment) => total + payment?.amountPaid,
    0
  );

  const totalTdsAmount = dbData?.paymentDetails.reduce(
    (total, payment) => total + payment?.tdsAmount,
    0
  );

  useEffect(() => {
    if (dbData?._id) {
      let updatedPaymentDetails = dbData?.paymentDetails?.map((item) => {
        return {
          id: uuidv4(),
          amountToPay: item?.amountToPay,
          paymentStatus: item?.paymentStatus,
          // ? { label: item?.paymentStatus, value: item?.paymentStatus }
          // : null,
          paymentDate: item?.paymentDate,
          bankRefNumber: item?.bankRefNumber,
          amountPaid: item?.amountPaid,
          tdsAmount: item?.tdsAmount,
          financeTeamRemark: item?.financeTeamRemark,
          paymentAttachment: item?.paymentAttachment,
          dbpaymentStatus: item?.paymentStatus,
        };
      });

      setPaymentDetails(updatedPaymentDetails);

      if (
        updatedPaymentDetails?.length === 0 &&
        userDepartment === "Purchase" &&
        dbStatus === "Purchase Approved"
      )
        setPaymentDetails([
          // ...paymentDetails,
          {
            id: uuidv4(),
            amountToPay: 0,
            paymentStatus: "Payment Due",
            paymentDate: null,
            bankRefNumber: "",
            amountPaid: 0,
            tdsAmount: 0,
            financeTeamRemark: "",
            paymentAttachment: null,
          },
        ]);
      setDeductionAmount(
        dbData?.deductionAmount > 0 ? dbData?.deductionAmount : 0
      );
      setDeductionRemark(
        dbData?.deductionRemark ? dbData?.deductionRemark : ""
      );

      // setBankAccountNumber(dbData?.bankAccountNumber);
      setBankAccountNumber(dbData?.vendor?.vendorBankAccNumber);

      // setifscCode(dbData?.ifscCode);
      setifscCode(dbData?.vendor?.vendorIFSC);
      setBankType(
        dbData?.vendor?.accountType
          ? {
              label: dbData?.vendor?.accountType,
              value: dbData?.vendor?.accountType,
            }
          : null
      );
      setBankDetailImage(
        dbData?.vendor?.bankDetailImage !== null
          ? dbData?.vendor?.bankDetailImage
          : []
      );
      setPoAttachment(
        dbData?.poAttachment !== null ? dbData?.poAttachment : []
      );
      setInvoiceAttachment(
        dbData?.invoiceAttachment !== null ? dbData?.invoiceAttachment : []
      );
      setDeductionAttachment(
        dbData?.deductionAttachment !== null ? dbData?.deductionAttachment : []
      );
    }
  }, [state]);
  const onSubmitHandler = (e) => {
    e.preventDefault();
    let paymentDetailsClone = JSON.parse(JSON.stringify(paymentDetails));
    // let paymentDetailsClone = JSON.parse(JSON.stringify({...paymentDetails, paymentStatus: paymentStatus?.value}));
    paymentDetailsClone.forEach((item) => {
      delete item.id;
    });

    const data = {
      // bankAccountNumber,
      // ifscCode,
      // bankType: bankType?.value,
      // bankDetailImage,
      poAttachment,
      invoiceAttachment,
      paymentDetails: paymentDetailsClone,
      deductionAmount: Number(deductionAmount),
      deductionRemark,
      deductionAttachment,
      // totalAmountPaid,
      // totalTdsAmount,
    };

    if (dbStatus === "Purchase Approved") {
      if (
        // !bankAccountNumber ||
        // !ifscCode ||
        // !bankType ||
        // !invoiceAttachment?.length ||
        !poAttachment?.length
      ) {
        toaster("error", "Please upload the po attachment");
        return;
      }
    }

    if (userDepartment === "Purchase" && dbData?.paymentDetails?.length >= 1) {
      if (!invoiceAttachment?.length) {
        toaster("error", "Please upload the invoice attachment");
        return;
      }
    }

    if (userDepartment === "Purchase") {
      if (data?.paymentDetails?.find((el) => el?.amountToPay <= 0)) {
        toaster("error", "Amount to pay should be more than '0'");
        return;
      }

      let totalAmountToPay = 0;
      data?.paymentDetails?.map((item) => {
        return (totalAmountToPay =
          (item?.paymentStatus !== "Payment Rejected" ? item.amountToPay : 0) +
          totalAmountToPay);
      });

      if (totalAmountToPay > dbData?.totalAmount) {
        toaster(
          "error",
          "Total amount to pay cannot be more than total amount"
        );
        return;
      }
      if (totalAmountToPay + Number(deductionAmount) > dbData?.totalAmount) {
        toaster(
          "error",
          "Total amount to pay & deduction cannot be more than total amount"
        );
        return;
      }
    }

    if (
      userDepartment === "Finance" &&
      data?.paymentDetails?.find(
        (el) => el?.amountPaid + el?.tdsAmount > el?.amountToPay
      )
    ) {
      toaster("error", "Amount paid should not be more than amount to pay");
      return;
    }

    if (
      userDepartment === "Purchase"
      // && dbStatus === "Purchase Approved" ||
    ) {
      if (deductionAmount > 0 && deductionRemark?.length > 0) {
        updateGoPurchaseHandler(dbData?._id, {
          hostel: dbData?.hostel?._id,
          ...data,
          // status: dbStatus,
          logs: [
            ...dbData?.logs,
            `${localStorage.getItem("name")} (${localStorage.getItem(
              "department"
            )}) has updated - deduction amount: ${deductionAmount} remark: ${deductionRemark} on ${new Date().toLocaleString(
              "en-IN",
              {
                hour12: false,
              }
            )}`,
          ],
        });
      } else {
        updateGoPurchaseHandler(dbData?._id, {
          hostel: dbData?.hostel?._id,
          ...data,
          // status: dbStatus,
          logs: [
            ...dbData?.logs,
            `${localStorage.getItem("name")} 
            (${localStorage.getItem(
              "department"
            )}) has updated - Payment details on ${new Date().toLocaleString(
              "en-IN",
              {
                hour12: false,
              }
            )}`,
          ],
        });
      }
      toaster("success", "Payment details added successfully");
    }

    if (userDepartment === "Finance") {
      updateGoPurchaseHandler(dbData?._id, {
        hostel: dbData?.hostel?.value,
        ...data,
        // status: dbStatus,
        logs: [
          ...dbData?.logs,
          `${localStorage.getItem("name")} 
            (${localStorage.getItem(
              "department"
            )}) has updated - Payment details on ${new Date().toLocaleString(
            "en-IN",
            {
              hour12: false,
            }
          )}`,
        ],
      });
      toaster("success", "Payment details saved successfully");
    }
  };
  return (
    <form className="mt-2" onSubmit={onSubmitHandler}>
      <Row>
        {/* {dbStatus !== "Purchase Approved" && ( */}
        <Col sm="10">
          {" "}
          <h3>
            {" "}
            Purchase team can add payment details once the request is approved
          </h3>
        </Col>
        {/* } */}
        {userDepartment === "Purchase" &&
          (dbStatus === "Purchase Approved" ||
            dbStatus === "Payment Due" ||
            dbStatus === "Payment Done" ||
            dbStatus === "Payment Done - On Account" ||
            dbStatus === "Material Dispatched" ||
            dbStatus === "Request Partially Completed" ||
            dbStatus === "Partial Request Resolved" ||
            dbStatus === "Request Completed") && (
            <Col sm="2">
              {" "}
              <div>
                <AddPaymentDetailsButton addAction={handleAddPaymentDetails} />
              </div>
            </Col>
          )}
      </Row>

      {((userDepartment === "Purchase" && dbStatus === "Purchase Approved") ||
        /* dbData?.bankAccountNumber?.length > 0) && ( */
        (dbData?.vendor?._id &&
          (dbStatus === "Purchase Approved" ||
            dbStatus === "Material Dispatched" ||
            dbStatus === "Request Partially Completed" ||
            dbStatus === "Partial Request Resolved" ||
            dbStatus === "Request Completed" ||
            dbStatus === "Payment Due" ||
            dbStatus === "Payment Done" ||
            dbStatus === "Payment Done - On Account"))) && (
        <Row>
          <hr
            style={{
              borderTop: "2px solid #ffe700",
              width: "100%",
            }}
          />
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="bankAccountNumber">
                Vendor bank account number*
              </Label>
              <Input
                type="text"
                value={bankAccountNumber}
                // disabled={dbData?.vendor?.vendorBankAccNumber}
                disabled={dbData?.vendor?._id}
                id="bankAccountNumber"
                name="bankAccountNumber"
                // placeholder="Bank account number"
                onChange={(e) => setBankAccountNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="ifscCode">
                IFSC code*
              </Label>
              <Input
                type="text"
                value={ifscCode}
                // disabled={dbData?.vendor?.vendorIFSC}
                disabled={dbData?.vendor?._id}
                id="ifscCode"
                name="ifscCode"
                // placeholder="IFSC code"
                onChange={(e) => setifscCode(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="bankType">
                Account type*
              </Label>

              <Select
                isClearable={false}
                isSearchable={true}
                id="bankType"
                name="bankType"
                value={bankType}
                options={bankTypeOptions}
                onChange={(value) => setBankType(value)}
                classNamePrefix="select"
                // isDisabled={dbData?.vendor?.accountType}
                isDisabled={dbData?.vendor?._id}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <div style={{ marginBottom: "6px" }}>
              <Label className="form-label" for="bankDetailImage">
                Bank detail image
              </Label>
            </div>
            <div>
              {/* <AttachmentComponent
              imageList={bankDetailImage}
              setImageList={setBankDetailImage}
              editData={dummyStateBankDetail}
              uploadType={uploadTypeBankDetail}
              userDepartment={userDepartment}
            /> */}
              {dbData?.vendor?.bankDetailImage?.map((item, index) =>
                /*<img
                    className="imageSection__image"
                    src={item?.imageURL}
                    alt="Attachment"
                    key={index}
                    style={{
                      width: "6rem",
                      height: "6rem",
                      objectFit: "cover",
                      cursor: "pointer",
                      marginLeft: "1.7rem",
                    }}
                    onClick={() => window.open(item?.imageURL, "_blank")}
                  />*/

                item?.imageURL?.includes(".pdf") ? (
                  <img
                    className="imageSection__image"
                    src={
                      "https://static-dev.thehosteller.com/GO_PURCHASE_REQUEST/pdfImage-1720680601586.png"
                    }
                    alt="Attachment"
                    key={index}
                    style={{
                      width: "6rem",
                      height: "6rem",
                      objectFit: "cover",
                      cursor: "pointer",
                      marginLeft: "1.7rem",
                    }}
                    onClick={() => window.open(item?.imageURL, "_blank")}
                  />
                ) : (
                  <img
                    className="imageSection__image"
                    src={item?.imageURL}
                    alt="Attachment"
                    key={index}
                    style={{
                      width: "6rem",
                      height: "6rem",
                      objectFit: "cover",
                      cursor: "pointer",
                      marginLeft: "1.7rem",
                    }}
                    onClick={() =>
                      window.open(item?.paymentAttachment, "_blank")
                    }
                  />
                )
              )}
            </div>
          </Col>
          <Col sm="4">
            <Label className="form-label" for="poAttachment">
              PO attachment*
            </Label>
            <AttachmentComponent
              imageList={poAttachment}
              setImageList={setPoAttachment}
              editData={dummyStatePo}
              uploadType={uploadTypePo}
              userDepartment={userDepartment}
            />
          </Col>
          <Col sm="4">
            <Label className="form-label" for="invoiceAttachment">
              Invoice attachment*
            </Label>
            <AttachmentComponent
              imageList={invoiceAttachment}
              setImageList={setInvoiceAttachment}
              editData={dummyStateInvoice}
              uploadType={uploadTypeInvoice}
              userDepartment={userDepartment}
            />
          </Col>
        </Row>
      )}

      {/* <Col sm="12">
          <Label className="form-label" for="paymentAttachment">
            <strong>Payment attachment*</strong>
          </Label>
          <AttachmentComponent
            imageList={paymentAttachment}
            setImageList={setPaymentAttachment}
            editData={dummyStatePaymentAttachment}
            uploadType={uploadTypePayment}
            //   userDepartment={userDepartment}
          />
        </Col> */}
      {/* <Col sm="4">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="paymentStatus">
                        Payment status*
                      </Label>

                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="paymentStatus"
                        name="paymentStatus"
                        value={{
                          label: item?.paymentStatus,
                          value: item?.paymentStatus,
                        }}
                        options={paymentStatusList}
                        onChange={(status) =>
                          handlePaymentStatusChange(
                            status?.value,
                            item?.id,
                            "paymentStatus"
                          )
                        }
                        classNamePrefix="select"
                        isDisabled={userDepartment !== "Finance"}
                      />
                    </FormGroup>
                  </Col> */}

      <hr
        style={{
          borderTop: "2px solid #ffe700",
          width: "100%",
        }}
      />
      <Row>
        <Col sm="12">
          {paymentDetails?.map(
            (
              /* {
              id,
              amountToPay,
             paymentStatus, 
              paymentDate,
              bankRefNumber,
              amountPaid,
              tdsAmount,
              financeTeamRemark,
              paymentAttachment,
            }, */
              item,
              index
            ) => (
              <React.Fragment key={id}>
                <Row>
                  <Col sm="10">
                    <FormGroup>
                      <Label className="form-label" for="index">
                        <strong>Payment details {index + 1}</strong>
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col sm="2">
                    {userDepartment === "Purchase" &&
                    item?.paymentStatus === "Payment Due" &&
                    paymentDetails.length > 1 ? (
                      /* <Col sm="2">
                    {userDepartment === "Purchase" &&
                    item?.paymentStatus !== "Payment Done" &&
                    paymentDetails.length > 1 ? ( */
                      <>
                        <Button.Ripple
                          className="btn-icon rounded-circle"
                          color="light"
                          type="button"
                          size="sm"
                          onClick={() => handleRemovePaymentDetails(item?.id)}
                          //   disabled={editData?._id}
                        >
                          <Trash size={14} />
                        </Button.Ripple>
                      </>
                    ) : null}
                  </Col>
                </Row>

                <Row>
                  {" "}
                  {((userDepartment === "Purchase" &&
                    dbData?.totalAmount > 0) ||
                    (userDepartment === "Finance" && item?.amountToPay > 0) ||
                    item?.amountToPay > 0) && (
                    <Col sm="4">
                      <FormGroup className="w-100">
                        <Label className="form-label" for="amountToPay">
                          Amount to pay*
                        </Label>
                        <Input
                          type="number"
                          value={item?.amountToPay}
                          disabled={
                            userDepartment !== "Purchase" ||
                            item?.paymentStatus === "Payment Done" ||
                            item?.paymentStatus === "Payment Rejected"
                          }
                          id="amountToPay"
                          name="amountToPay"
                          //  placeholder="Amount to pay"
                          onChange={(e) => {
                            handleAmountToPayChange(
                              e.target.value,
                              // id,
                              item?.id,
                              "amountToPay"
                            );
                          }}
                          min="0"
                          step="0.01"
                          onWheel={(e) => e.target.blur()}
                        />
                        {amountToPayValidationMessage && (
                          <div style={{ color: "red" }}>
                            {amountToPayValidationMessage}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  )}
                  {(userDepartment === "Finance" || item?.amountToPay > 0) && (
                    <Col sm="4">
                      <FormGroup className="w-100">
                        <Label className="form-label" for="paymentStatus">
                          Payment status*
                        </Label>

                        <Select
                          isClearable={false}
                          isSearchable={true}
                          id="paymentStatus"
                          name="paymentStatus"
                          value={{
                            label: item?.paymentStatus,
                            value: item?.paymentStatus,
                          }}
                          options={paymentStatusList}
                          onChange={(status) =>
                            handlePaymentStatusChange(
                              status?.value,
                              item?.id,
                              "paymentStatus"
                            )
                          }
                          classNamePrefix="select"
                          // isDisabled={userDepartment !== "Finance"}
                          // isDisabled={
                          //   userDepartment !== "Finance" ||
                          //   item?.paymentStatus === "Payment Done" ||
                          //   item?.paymentStatus === "Payment Rejected"
                          // }
                          isDisabled={
                            !(
                              userDepartment === "Finance" &&
                              item?.dbpaymentStatus === "Payment Due"
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {(item?.paymentStatus === "Payment Done" ||
                    item?.paymentStatus === "Payment Rejected" ||
                    item?.paymentStatus === "Payment Done - On Account" ||
                    userDepartment === "Finance") && (
                    <>
                      {(item?.paymentStatus === "Payment Done" ||
                        item?.paymentStatus === "Payment Rejected" ||
                        item?.paymentStatus ===
                          "Payment Done - On Account") && (
                        <Col sm="4">
                          <FormGroup>
                            <Label className="form-label" for="paymentDate">
                              Payment date*
                            </Label>
                            <Flatpickr
                              className="form-control bg-white border-dark text-dark"
                              value={item?.paymentDate}
                              // disabled={userDepartment !== "Finance"}
                              // disabled={
                              //   userDepartment !== "Finance" ||
                              //   dbData?.paymentDetails?.some(
                              //     (item) =>
                              //       item?.paymentStatus === "Payment Done" ||
                              //       item?.paymentStatus === "Payment Rejected"
                              //   )
                              // }
                              // disabled={
                              //   userDepartment !== "Finance" ||
                              //   item?.paymentStatus === "Payment Done" ||
                              //   item?.paymentStatus === "Payment Rejected"
                              // }
                              disabled={
                                !(
                                  userDepartment === "Finance" &&
                                  item?.dbpaymentStatus === "Payment Due"
                                )
                              }
                              onChange={
                                (date) => {
                                  handlePaymentDateChange(
                                    date,
                                    // id,
                                    item?.id,
                                    "paymentDate"
                                  );
                                }
                                // (date) => setPaymentDate(date)
                              }
                              id="paymentDate"
                              // placeholder="Payment date"
                              required
                            />
                          </FormGroup>
                        </Col>
                      )}

                      {(item?.paymentStatus === "Payment Done" ||
                        item?.paymentStatus ===
                          "Payment Done - On Account") && (
                        <Col sm="4">
                          <FormGroup className="w-100">
                            <Label className="form-label" for="amountPaid">
                              Amount paid*
                            </Label>
                            <Input
                              type="number"
                              value={item?.amountPaid}
                              // disabled={userDepartment !== "Finance"}
                              disabled={
                                !(
                                  userDepartment === "Finance" &&
                                  item?.dbpaymentStatus === "Payment Due"
                                )
                              }
                              id="amountPaid"
                              name="amountPaid"
                              //  placeholder="Amount paid"
                              onChange={(e) => {
                                handleAmountToPayChange(
                                  e.target.value,
                                  // id,
                                  item?.id,
                                  "amountPaid"
                                );
                              }}
                              min="0"
                              step="0.01"
                              onWheel={(e) => e.target.blur()}
                            />
                            {amountPaidValidationMessage && (
                              <div style={{ color: "red" }}>
                                {amountPaidValidationMessage}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      )}

                      {(item?.paymentStatus === "Payment Done" ||
                        item?.paymentStatus ===
                          "Payment Done - On Account") && (
                        <Col sm="4">
                          <FormGroup className="w-100">
                            <Label className="form-label" for="tdsAmount">
                              TDS amount*
                            </Label>
                            <Input
                              type="number"
                              value={item?.tdsAmount}
                              // disabled={userDepartment !== "Finance"}
                              // disabled={
                              //   userDepartment !== "Finance" ||
                              //   dbData?.paymentDetails?.some(
                              //     (item) =>
                              //       item?.paymentStatus === "Payment Done" ||
                              //       item?.paymentStatus === "Payment Rejected"
                              //   )
                              // }
                              disabled={
                                !(
                                  userDepartment === "Finance" &&
                                  item?.dbpaymentStatus === "Payment Due"
                                )
                              }
                              id="tdsAmount"
                              name="tdsAmount"
                              //  placeholder="TDS amount"
                              onChange={(e) => {
                                handleAmountToPayChange(
                                  e.target.value,
                                  // id,
                                  item?.id,
                                  "tdsAmount"
                                );
                              }}
                              min="0"
                              step="0.01"
                              onWheel={(e) => e.target.blur()}
                            />
                            {tdsAmountValidationMessage && (
                              <div style={{ color: "red" }}>
                                {tdsAmountValidationMessage}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      )}

                      {(item?.paymentStatus === "Payment Done" ||
                        item?.paymentStatus ===
                          "Payment Done - On Account") && (
                        <Col sm="4">
                          <FormGroup className="w-100">
                            <Label className="form-label" for="bankRefNumber">
                              Bank ref. number*
                            </Label>

                            <Input
                              type="text"
                              value={item?.bankRefNumber}
                              id="bankRefNumber"
                              name="bankRefNumber"
                              //  placeholder={`Bank ref. number`}
                              onChange={(e) =>
                                handleBankRefNumberAndFinanceRemarkTextChange(
                                  e.target.value,
                                  // id,
                                  item?.id,
                                  "bankRefNumber"
                                )
                              }
                              // disabled={userDepartment !== "Finance"}
                              // disabled={
                              //   userDepartment !== "Finance" ||
                              //   dbData?.paymentDetails?.some(
                              //     (item) =>
                              //       item?.paymentStatus === "Payment Done" ||
                              //       item?.paymentStatus === "Payment Rejected"
                              //   )
                              // }
                              disabled={
                                !(
                                  userDepartment === "Finance" &&
                                  item?.dbpaymentStatus === "Payment Due"
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      )}

                      {(item?.paymentStatus === "Payment Done" ||
                        item?.paymentStatus === "Payment Rejected" ||
                        item?.paymentStatus ===
                          "Payment Done - On Account") && (
                        <Col sm="4">
                          <FormGroup className="w-100">
                            <Label
                              className="form-label"
                              for="financeTeamRemark"
                            >
                              Finance team remark*
                            </Label>

                            <Input
                              type="text"
                              value={item?.financeTeamRemark}
                              id="financeTeamRemark"
                              name="financeTeamRemark"
                              //  placeholder={`Finance team remark`}
                              onChange={(e) =>
                                handleBankRefNumberAndFinanceRemarkTextChange(
                                  e.target.value,
                                  // id,
                                  item?.id,
                                  "financeTeamRemark"
                                )
                              }
                              // disabled={userDepartment !== "Finance"}
                              // disabled={
                              //   userDepartment !== "Finance" ||
                              //   dbData?.paymentDetails?.some(
                              //     (item) =>
                              //       item?.paymentStatus === "Payment Done" ||
                              //       item?.paymentStatus === "Payment Rejected"
                              //   )
                              // }
                              disabled={
                                !(
                                  userDepartment === "Finance" &&
                                  item?.dbpaymentStatus === "Payment Due"
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      )}

                      {(item?.paymentStatus === "Payment Done" ||
                        item?.paymentStatus ===
                          "Payment Done - On Account") && (
                        <Col
                          sm="12"
                          // className="mt-2 d-flex align-items-center gap-2"
                        >
                          <label htmlFor="">Payment attachment</label>

                          <div className="mt-2 d-flex align-items-center gap-2">
                            {/* <Avatar
                              img={item?.paymentAttachment}
                              alt={item?.paymentAttachment}
                              imgHeight={100}
                              imgWidth={100}
                            /> */}

                            {/* {item?.paymentAttachment && (
                            <img
                              // className="imageSection__image"
                              src={item?.paymentAttachment}
                              alt="avatar"
                              style={{
                                width: "6rem",
                                height: "6rem",
                                objectFit: "cover",
                                cursor: "pointer",
                                marginLeft: "1.7rem",
                              }}
                              onClick={() =>
                                window.open(item?.paymentAttachment, "_blank")
                              }
                            />
                          )} */}

                            {item?.paymentAttachment !== null ? (
                              item?.paymentAttachment?.includes(".pdf") ? (
                                <img
                                  className="imageSection__image"
                                  src={
                                    "https://static-dev.thehosteller.com/GO_PURCHASE_REQUEST/pdfImage-1720680601586.png"
                                  }
                                  alt="Attachment"
                                  style={{
                                    width: "6rem",
                                    height: "6rem",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    marginLeft: "1.7rem",
                                  }}
                                  onClick={() =>
                                    window.open(
                                      item?.paymentAttachment,
                                      "_blank"
                                    )
                                  }
                                />
                              ) : (
                                <img
                                  className="imageSection__image"
                                  src={item?.paymentAttachment}
                                  alt="Attachment"
                                  style={{
                                    width: "6rem",
                                    height: "6rem",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    marginLeft: "1.7rem",
                                  }}
                                  onClick={() =>
                                    window.open(
                                      item?.paymentAttachment,
                                      "_blank"
                                    )
                                  }
                                />
                              )
                            ) : null}

                            {userDepartment === "Finance" && (
                              <label htmlFor={`myFile${item?.id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} />
                                </p>
                                <input
                                  name={`myFile${item?.id}`}
                                  id={`myFile${item?.id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) => fileUploader(e, item?.id)}
                                />
                              </label>
                            )}
                          </div>

                          {/* {userDepartment === "Finance" && (
                          <label htmlFor={`myFile${item?.id}`}>
                            <p className="btn btn-sm">
                              <Upload size={30} />
                            </p>
                            <input
                              name={`myFile${item?.id}`}
                              id={`myFile${item?.id}`}
                              className="d-none"
                              type="file"
                              onChange={(e) => fileUploader(e, item?.id)}
                            />
                          </label>
                        )} */}

                          {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "6em",
                            height: "6em",
                            cursor: "pointer",
                            margin: "0.5em",
                          }}
                          // onClick={handleUploadClick}
                        >
                          <Upload size={30} />{" "}
                          <input
                            name={`myFile${item?.id}`}
                            id={`myFile${item?.id}`}
                            className="d-none"
                            type="file"
                            onChange={(e) => fileUploader(e, item?.id)}
                          />
                        </div> */}
                        </Col>
                      )}
                    </>
                  )}
                </Row>

                <hr
                  style={{
                    borderTop: "2px solid #ffe700",
                    width: "100%",
                  }}
                />
              </React.Fragment>
            )
          )}
        </Col>
      </Row>

      {
        /* dbData?.totalAmount > 0 &&
        (dbStatus === "Purchase Approved" ||
          dbData?.paymentDetails?.find(
            (item) => item.amountToPay > 0
          )) */

        /* dbData?.paymentDetails?.find(
          (item) => item.amountToPay > 0
        )  */

        /* (userDepartment === "Purchase" || deductionAmount > 0) && ( */
        ((userDepartment === "Purchase" &&
          dbData?.paymentDetails?.find((item) => item.amountToPay > 0)) ||
          deductionAmount > 0) && (
          <Row>
            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="deductionAmount">
                  Deduction amount
                </Label>

                <Input
                  type="text"
                  value={deductionAmount}
                  id="deductionAmount"
                  name="deductionAmount"
                  //  placeholder="Deduction amount"
                  onChange={(e) => setDeductionAmount(e.target.value)}
                  disabled={userDepartment !== "Purchase"}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="deductionRemark">
                  Deduction remark
                </Label>

                <Input
                  type="text"
                  value={deductionRemark}
                  id="deductionRemark"
                  name="deductionRemark"
                  //  placeholder="Deduction remark"
                  onChange={(e) => setDeductionRemark(e.target.value)}
                  disabled={userDepartment !== "Purchase"}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <Label className="form-label" for="deductionAttachment">
                Deduction attachment
              </Label>
              <AttachmentComponent
                imageList={deductionAttachment}
                setImageList={setDeductionAttachment}
                editData={dummyStateDeduction}
                uploadType={uploadTypeDeduction}
                userDepartment={userDepartment}
              />
            </Col>
          </Row>
        )
      }

      {((userDepartment === "Purchase" &&
        (dbStatus === "Purchase Approved" ||
          (dbData?.vendor?._id && dbStatus === "Purchase Approved") ||
          ((userDepartment === "Purchase" || userDepartment === "Finance") &&
            dbData?.paymentDetails?.find((item) => item?.amountToPay > 0)))) ||
        userDepartment === "Finance") && <ReviewPaymentDetailsButton />}
    </form>
  );
};

export default PurchaseAndFinance;
