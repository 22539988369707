// Added by Nitish
import gql from "graphql-tag";

export const GET_PAGINATED_HOSTEL_COMPARISONS = gql`
  query getPaginatedHostelComparisons($filter: filter) {
    getPaginatedHostelComparisons(filter: $filter) {
      count
      limit
      page
      data {
        _id
        hostel {
          name
          _id
        }
        platform
        url
        competitor
        weightage
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_HOSTEL_COMPARISONS = gql`
  query getAllHostelComparisons {
    getAllHostelComparisons {
      _id
      hostel {
        name
        _id
      }
      platform
      url
      weightage
      createdAt
      updatedAt
    }
  }
`;

//Added by Ashish
export const GET_ALL_COMPETITORS_NAME = gql`
  query getUniqueCompetitors {
    getUniqueCompetitors
  }
`;
