// competitorRates

import BookingDashboardComponent from "../../views/BookingDashboard";


const BookingDashboard = [
  {
    path: "/bookingDashboard",
    component: BookingDashboardComponent,
    exact: true,
  },
];

export default BookingDashboard;
