import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import Chart from "react-apexcharts";

const tableHeaderData = ["Feedback", "Total Count"];

const FeedbackReportTable = ({ openModal, setId, data, refetch }) => {
  //for table
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  //for chart
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      width: 380,
      type: "bar",
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const colorMap = {
    Excellent: "#4CAF50",
    Good: "#FFE700",
    Bad: "#FF2C2C",
  };

  useEffect(() => {
    if (!data) return;
    const chartData = data.map((item) => item.totalFeedback);
    const labels = data.map((item) => item._id);
    const colors = labels.map((label) => colorMap[label || "#000000"]);

    setSeries(chartData);
    setOptions({
      ...options,
      colors: colors,
      labels: labels,
    });
  }, [data]);

  return (
    <Row className="">
      <Col sm="6">
        <Card className="">
          <CardHeader>
            <CardTitle>Customer feedback analysis</CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <Badge
                      color={
                        item?._id === "Good"
                          ? "primary"
                          : item?._id === "Excellent"
                          ? "success"
                          : item?._id === "Bad"
                          ? "danger"
                          : ""
                      }
                      className="font-weight-bold"
                    >
                      {item?._id}{" "}
                    </Badge>
                  </td>
                  <td className="font-weight-bold ">{item?.totalFeedback}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
      <Col sm="6">
        <div className="pie px-3">
          <Chart options={options} series={series} type="pie" width="370" />
        </div>
      </Col>
    </Row>
  );
};

export default FeedbackReportTable;
