// ! Login code
import { useEffect, useState } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InputPasswordToggle from "@components/common/input-password-toggle";
import {
  Col,
  CardTitle,
  CardText,
  Form,
  Input,
  FormGroup,
  Label,
  // CustomInput,
  Button,
  FormFeedback,
} from "reactstrap";
import {
  useLoginUser,
  useOtpUser,
  useUpdateFirebaseToken,
} from "../../../../../api/dataSource";
import "@styles/base/pages/page-auth.scss";
import { userSetDetails } from "@store/user/user.action";
import { emailValidationHandler } from "@utils";
import toaster from "../../../common/Toaster";
import { getFirebaseToken } from "../../../../../firebase";

const LoginStepFinal = ({ dispatchUserSetDetails }) => {
  const [errors, setErrors] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loginUserHandler, { data }] = useLoginUser();
  const [otpUserHandler, sendOtpMail] = useOtpUser();
  // const [checked, setChecked] = useState(true);
  const [updateFirebaseTokenHandler] = useUpdateFirebaseToken();

  useEffect(() => {
    //   if (data?.login?.token) {
    //     dispatchUserSetDetails({
    //       token: data?.login?.token,
    //       userDetails: data?.login?.user,
    //     });

    //     toaster("success", `Welcome ${data?.login?.user?.fullName}`);
    //   }
    // }, [data]);

    if (data?.adminUserLogin?.token) {
      dispatchUserSetDetails({
        token: data?.adminUserLogin?.token,
        adminUser: data?.adminUserLogin?.adminUser,
      });
      localStorage.setItem("tokenReset", "1709");
      localStorage.setItem("email", data?.adminUserLogin?.adminUser?.email);
      localStorage.setItem("role", data?.adminUserLogin?.adminUser?.role);
      localStorage.setItem("name", data?.adminUserLogin?.adminUser?.name);
      localStorage.setItem(
        "department",
        data?.adminUserLogin?.adminUser?.department
      );
      localStorage.setItem(
        "modules",
        JSON.stringify(data?.adminUserLogin?.adminUser?.modules)
      );
      localStorage.setItem(
        "hostels",
        JSON.stringify(data?.adminUserLogin?.adminUser?.hostels)
      );

      // Update Firebase token
      (async () => {
        try {
          const newToken = await getFirebaseToken();
          const email = localStorage.getItem("email");

          if (newToken && email) {
            localStorage.setItem("firebaseToken", newToken);
            updateFirebaseTokenHandler(email, newToken);
          }
        } catch (error) {
          console.error("Error updating Firebase token:", error);
        }
      })();

      toaster("success", `Welcome ${data?.adminUserLogin?.adminUser?.name}`);
    }

    if (data?.adminUserLogin?.status === false) {
      toaster("error", `${data?.adminUserLogin?.message}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updateFirebaseTokenHandler]);

  useEffect(() => {
    if (sendOtpMail?.data?.sendOtpMail) {
      //console.log(sendOtpMail?.data?.sendOtpMail?.message);
      //console.log(sendOtpMail?.data?.sendOtpMail?.status);
      if (sendOtpMail?.data?.sendOtpMail?.status === true) {
        setIsOtpSent(true);
        toaster("success", `OTP sent,  please check your email`);
      } else {
        toaster("error", `${sendOtpMail?.data?.sendOtpMail?.message}`);
      }
    }
  }, [sendOtpMail?.data?.sendOtpMail]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let isEmailValid = emailValidationHandler(email);

    setErrors({
      validEmail: isEmailValid,
      validPassword: password.length > 5,
    });

    // console.log(errors);

    if (isEmailValid && password) {
      // console.log(email, password, "INDEX");

      if (!isOtpSent) {
        //Sent OTP

        otpUserHandler(email, password);
      } else {
        loginUserHandler(email, password, otp);
      }
    }
  };

  return (
    <Col
      className="d-flex align-items-center auth-bg px-2 p-lg-2"
      lg="4"
      sm="12"
    >
      <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
        <CardTitle tag="h2" className="font-weight-bold mb-1">
          Welcome to Hosteller! 👋🏻
        </CardTitle>
        <CardText className="mb-2">
          Please sign-in to your account and start the adventure
        </CardText>
        <Form className="auth-login-form mt-2" onSubmit={handleSubmit}>
          <FormGroup>
            <Label className="form-label" for="login-phone">
              Email*
            </Label>

            <Input
              autoFocus
              type="email"
              value={email}
              id="login-phone"
              name="login-phone"
              onChange={(e) => setEmail(e.target.value)}
              className={classnames({
                "is-invalid": errors && !errors["validEmail"],
              })}
            />
            {errors && !errors["validEmail"] && (
              <FormFeedback>Please enter a valid email</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <div className="d-flex justify-content-between">
              <Label className="form-label" for="login-password">
                Password*
              </Label>
              <Link to="/forgot-password">
                <small>Forgot Password?</small>
              </Link>
            </div>
            <InputPasswordToggle
              value={password}
              id="login-password"
              name="login-password"
              onChange={(e) => setPassword(e.target.value)}
              className={classnames({
                "is-invalid": errors && !errors["validPassword"],
              })}
            />
            {errors && !errors["validPassword"] && (
              <FormFeedback>Please enter a valid password</FormFeedback>
            )}
          </FormGroup>
          {/* <FormGroup>
            <CustomInput
              type="checkbox"
              checked={checked}
              className="custom-control-Primary"
              id="remember-me"
              label="Remember Me"
              onChange={() => setChecked(!checked)}
            />
          </FormGroup> */}
          {isOtpSent && (
            <FormGroup>
              <div className="d-flex justify-content-between">
                <Label className="form-label" for="otp">
                  OTP* (Check your email for OTP)
                </Label>
                {/* <Link to="/forgot-password">
                <small>GET OTP</small>
              </Link> */}
              </div>
              <Input
                value={otp}
                id="otp"
                name="otp"
                onChange={(e) => setOtp(e.target.value)}
                className={classnames({
                  "is-invalid": errors && !errors["validPassword"],
                })}
              />
              {errors && !errors["validPassword"] && (
                <FormFeedback>Please enter a valid OTP</FormFeedback>
              )}
            </FormGroup>
          )}

          <Button.Ripple type="submit" color="light" block>
            Sign in
          </Button.Ripple>
        </Form>
      </Col>
    </Col>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchUserSetDetails: (data) => dispatch(userSetDetails(data)),
});

export default connect(null, mapDispatchToProps)(LoginStepFinal);
