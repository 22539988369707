import React, { useState, useEffect } from "react";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import Select from "react-select";
import {
  useUpdateWorkationLandingPageDetails,
  useHostelList,
} from "../../../../api/dataSource";

const WorkationOfWeek = () => {
  const state = useSelector((state) => state?.cms);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [hostel, setHostel] = useState(null);
  const [hostelList, setHostelList] = useState([]);
  const [
    updateWorkationLandingPageDetailsHandler,
    { data: updatedWorkationLandingPageData, error },
  ] = useUpdateWorkationLandingPageDetails();
  const { data: hostelData } = useHostelList();
  const dispatch = useDispatch();

  useEffect(() => {
    let hostelList = [];
    if (hostelData?.getHostelList?.length) {
      hostelData?.getHostelList?.map((item) =>
        hostelList.push({ value: item._id, label: item?.name })
      );
      setHostelList(hostelList);
    }
  }, [hostelData?.getHostelList]);

  useEffect(() => {
    if (state?.tableData?._id) {
      setTitle(state?.tableData?.workationOfWeek?.title);
      setDescription(state?.tableData?.workationOfWeek?.description);
      setHostel(
        state?.tableData?.workationOfWeek?.hostel?.name
          ? {
              label: state?.tableData?.workationOfWeek?.hostel?.name,
              value: state?.tableData?.workationOfWeek?.hostel?._id,
            }
          : ""
      );
    } else {
      setTitle("");
      setDescription("");
      setHostel(null);
    }
  }, [state?.tableData?.workationOfWeek]);

  const submitHandler = (e) => {
    let isValid = false;
    e.preventDefault();

    const data = {
      workationOfWeek: {
        title,
        description,
        hostel: hostel.value,
      },
    };

    data.workationOfWeek.title &&
    data.workationOfWeek.description &&
    data.workationOfWeek.hostel
      ? (isValid = true)
      : (isValid = false);

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          workationOfWeek: data?.workationOfWeek,
        })
      );
      updateWorkationLandingPageDetailsHandler("workationlandingpage", data);
      toaster("success", "Workation of week updated successfully");
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        {/*//! Title */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="title">
              Title*
            </Label>

            <Input
              type="text"
              value={title}
              id="title"
              name="title"
              placeholder="Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*//! Description */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="description">
              Description*
            </Label>

            <Input
              type="textarea"
              value={description}
              id="description"
              name="description"
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*//! Hostel  */}
        <Col sm="12">
          <FormGroup>
            <Label for="hostel">Hostel*</Label>
            <Select
              isClearable={false}
              id="hostel"
              name="hostel"
              value={hostel}
              options={hostelList}
              onChange={(value) => setHostel(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default WorkationOfWeek;
