import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import Chart from "react-apexcharts";

const tableHeaderData = ["Manager Status", "Total Count"];

const FeedbackTotalFeedbackStatus = ({ feedbackStatusData: data }) => {
  //for table
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  //for chart
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      width: 380,
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "flat",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      hover: {
        filter: "none",
      },
    },
    theme: {
      palette: "palette5",
    },
    legend: {
      labels: {
        colors: "#7E7E7E",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const colorMap = {
    Resolved: "#4CAF50",
    "Not Resolved": "#FF2C2C",
    "Not Responded": "#FFE700",
    "Cannot connect with guest": "#00CFE8",
  };

  useEffect(() => {
    if (!data) return;
    const chartData = data.map((item) => ({
      name: item.feedbackStatus,
      data: [item.totalCount],
    }));
    const labels = data.map((item) => item.feedbackStatus);
    const colors = labels.map((label) => colorMap[label || "#000000"]);

    setSeries(chartData);
    setOptions({
      ...options,
      xaxis: {
        categories: labels,
      },
      colors: colors,
    });
  }, [data]);

  return (
    <Row className="mt-1">
      <Col sm="6">
        <Card className="mt-2">
          <CardHeader>
            <CardTitle>Hostel feedback analysis</CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <Badge
                      color={
                        item?.feedbackStatus === "Resolved"
                          ? "success"
                          : item?.feedbackStatus === "Not Resolved"
                          ? "danger"
                          : item?.feedbackStatus === "Not Responded"
                          ? "primary"
                          : item?.feedbackStatus === "Cannot connect with guest"
                          ? "info"
                          : ""
                      }
                      className="font-weight-bold"
                    >
                      {item?.feedbackStatus}{" "}
                    </Badge>
                  </td>
                  <td className="font-weight-bold">{item?.totalCount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
      <Col sm="6">
        <div id="chart" className="p-2">
          <Chart options={options} series={series} type="bar" width="400" />
        </div>
      </Col>
    </Row>
  );
};

export default FeedbackTotalFeedbackStatus;
