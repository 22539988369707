import { useState, useEffect } from "react";
import { FormGroup, Label, Row, Col, Input } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import {
    useGetEmployeeProfileById,
    useUpdateEmployeeProfile,
} from "../../../api/dataSource";
import { useParams } from "react-router";
import AppraisalTabel from "../EmployeeAttendance/AppraisalTabel";
import { DesignationList } from "../../utils";

const dateFormat = "d/m/Y";
const options = {
    dateFormat: dateFormat,
};
const EmployeeTraining = () => {
    const { id } = useParams();
    const { data: empData, refetch } = useGetEmployeeProfileById(id);

    const [updateEmployeeProfileHandler] = useUpdateEmployeeProfile();
    const [trainingDate, setTrainingDate] = useState(null)
    const [trainingStatus, setTrainingStatus] = useState(null)
    const [trainingScore, setTrainingScore] = useState("")
    const [trainingRemark, setTrainingRemark] = useState("")
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            let updateParams = {
                trainingDate,
                trainingStatus: trainingStatus?.value,
                trainingScore: Number(trainingScore),
                trainingRemark,
            };
            const updateData = await updateEmployeeProfileHandler(id, updateParams);
            if (updateData?.data?.updateEmployeeProfile) {
                toaster("success", "Employee training details added successfully");
                refetch();
            } else {
                toaster("error", `seems like network issue`);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        console.log(empData?.getEmployeeProfileById)
        if (empData?.getEmployeeProfileById) {
            const data = empData?.getEmployeeProfileById;
            setTrainingDate(data?.trainingDate);
            setTrainingScore(data?.trainingScore);
            setTrainingStatus({ label: data?.trainingStatus, value: data?.traningStatus });
            setTrainingRemark(data?.trainingRemark)
        }
    }, [empData, refetch]);

    const trainingStatusOptions = [
        { label: "Pending", value: "Pending" },
        { label: "Completed", value: "Completed" },
    ]
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col sm="6">
                        <label htmlFor="traningDate">Training date</label>
                        <Flatpickr
                            className="form-control bg-white border-dark text-dark"
                            value={trainingDate}
                            onChange={(date) =>
                                setTrainingDate(new Date(dateFormatter(new Date(date))))
                            }
                            options={{
                                ...options,
                            }}
                            id="trainingDate"
                            placeholder="Training date"
                        />
                    </Col>

                    <Col sm="6">
                        <label htmlFor="trainingStatus">Training status</label>
                        <Select
                            isClearable={true}
                            id="trainingStatus"
                            name="trainingStatus"
                            value={
                                trainingStatus
                            }
                            options={trainingStatusOptions}
                            onChange={(selectedOption) => {
                                setTrainingStatus(selectedOption);
                            }}
                            classNamePrefix="select"
                        />
                    </Col>
                    <Col sm="6">
                        <Label className="form-label" for="trainingScore">
                            Training score
                        </Label>
                        <Input
                            type="text"
                            value={trainingScore}
                            placeholder="Training score"
                            onChange={(e) => {
                                setTrainingScore(e.target.value);
                            }}
                        />
                    </Col>

                    <Col sm="6">
                        <Label className="form-label" for="trainingScore">
                            Remark
                        </Label>
                        <Input
                            type="textarea"
                            value={trainingRemark}
                            placeholder="Training remark"
                            onChange={(e) => {
                                setTrainingRemark(e.target.value);
                            }}
                        />
                    </Col>
                </Row>

                <SaveDiscardBtnGroup />
            </form>
        </>
    )
}

export default EmployeeTraining