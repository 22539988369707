import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  useDestinationList,
  useUpdateHomePageDetails,
  useUploadImage,
} from "../../../../api/dataSource";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Camera, X } from "react-feather";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import Avatar from "@components/common/avatar";
import confirmDelete from "@components/common/confirmDelete";
import { tableDataHandler } from "@store/Cms/cms.action";

export const NewDestinations = () => {
  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [index, setIndex] = useState("");
  // const [destinations,setDestinations]= useState("")

  const [newDestinations, setNewDestinations] = useState([
    {
      id: uuidv4(),
      destination: null,
      icon: "",
      displayOrder: "",
    },
  ]);
  const [destinationsList, setDestinationsList] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [updateHomePageDetailsHandler, { data: updatedDestinationsData }] =
    useUpdateHomePageDetails();

  const { data: allDestinationsData } = useDestinationList();

  const [uploadCategoryImageHandler, { data: categoryImageData }] =
    useUploadImage();

  useEffect(() => {
    if (allDestinationsData?.getDestinationList?.length > 0) {
      const destination = allDestinationsData?.getDestinationList?.map((el) => {
        return { label: el?.destination, value: el?._id };
      });

      setDestinationsList(destination);
    }
  }, [allDestinationsData]);

  useEffect(() => {
    if (categoryImageData?.uploadImage?.url) {
      setNewDestinations(
        newDestinations.map((item) => {
          if (item.id === index) {
            return {
              ...item,
              icon: categoryImageData?.uploadImage?.url,
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [categoryImageData?.uploadImage?.url]);

  useEffect(() => {
    if (state?.tableData?.newDestinations?.length > 0) {
      let alreadySelectedDestinations = state?.tableData?.newDestinations?.map(
        (item) => {
          if (item?.destination?.destination) {
            return {
              id: uuidv4(),
              destination: {
                label: item?.destination?.destination,
                value: item?.destination?._id,
              },
              icon: item?.icon,
              displayOrder: item?.displayOrder || "",
            };
          }
          return {
            id: uuidv4(),
            destination: item?.destination,
            icon: item?.icon,
            displayOrder: item?.displayOrder || "",
          };
        }
      );
      setNewDestinations(alreadySelectedDestinations);
    }
  }, [state?.tableData?.newDestinations]);

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }

    uploadCategoryImageHandler(
      e.target.files[0],
      `${state.tableData?.slug?.toLowerCase()?.replaceAll(" ", "-")}` +
        "/NEWHOMEPAGE_DESTINATION"
    );
    toaster("success", "Destination icon uploaded successfully");
  };
  const handleAddDestinations = () =>
    setNewDestinations([
      ...newDestinations,
      {
        id: uuidv4(),
        destination: null,
        icon: "",
        displayOrder: "",
      },
    ]);

  const handleChangeDestinations = (val, id, key) => {
    console.log(val, id, key);
    setNewDestinations(
      newDestinations?.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };

  const handleRemoveDestination = (id) => {
    let remove = newDestinations.filter((item) => item?.id !== id);
    confirmDelete(() => setNewDestinations(remove));
  };

  const submitDataHandler = (e) => {
    e.preventDefault();
    let data = {
      newDestinations: newDestinations?.map((destinationData) => {
        delete destinationData?.id;
        return {
          destination: destinationData?.destination?.value,
          icon: destinationData?.icon,
          displayOrder: Number(destinationData?.displayOrder),
        };
      }),
    };
    console.log(data);

    if (state?.tableData?._id) {
      updateHomePageDetailsHandler(id, data);
      toaster("success", "Home destination Updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          newDestinations: newDestinations,
        })
      );
    }
    // else if (data?.newDestinations?.length <= 13) {
    //   toaster("error", "Please add minimum 14 destinations");
    // }
    else {
      toaster("error", "Please fill the details");
    }
  };

  const handleDragStart = (e, index) => {
    setDraggedItem(index);
    console.log(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };

  // const handleDrop = (e, index, item) => {
  //   console.log(item);
  //   //draggedTo = index;
  //   handleChangeDisplayOrders(index);
  // };
  const handleChangeDisplayOrders = (e, draggedToIndex) => {
    const updatedItem = [...newDestinations]?.map((item) => ({ ...item }));

    const updatedDestinations = { ...updatedItem[draggedItem] };
    if (draggedItem > draggedToIndex) {
      updatedDestinations.displayOrder = draggedToIndex;
    } else {
      updatedDestinations.displayOrder = draggedToIndex + 2;
    }
    updatedItem[draggedItem] = updatedDestinations;
    updatedItem.sort((a, b) => a.displayOrder - b.displayOrder);

    let currentDisplayOrder = 1;
    for (let i = 0; i < updatedItem.length; i++) {
      updatedItem[i] = {
        ...updatedItem[i],
        displayOrder: currentDisplayOrder++,
      };
    }
    console.log(updatedItem);
    setNewDestinations(updatedItem);
  };

  return (
    <div>
      <form className="mt-2" onSubmit={submitDataHandler}>
        <Row className="mt-2">
          {newDestinations
            // ?.sort((a, b) => a?.displayOrder - b?.displayOrder)
            ?.map(({ id, destination, icon, displayOrder }, i) => (
              <Col
                className="d-flex align-items-center p-2"
                sm="12"
                key={i}
                draggable
                onDragOver={(e) => e.preventDefault()}
                onDragStart={(e) => handleDragStart(e, i)}
                onDrop={(e) => handleChangeDisplayOrders(e, i)}
              >
                <FormGroup className="w-100">
                  <Label className="form-label" htmlFor={"reviews" + id}>
                    Destination {i + 1}
                  </Label>
                  <Row>
                    <Col sm="10">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormGroup className="w-50">
                          <div className="" style={{ display: "flex" }}>
                            <div className="mb-1 mr-2">
                              {
                                <Avatar
                                  img={icon}
                                  alt={icon}
                                  imgHeight={100}
                                  imgWidth={100}
                                />
                              }
                            </div>

                            <label
                              htmlFor={`myFile${id}`}
                              style={{ marginTop: "40px" }}
                            >
                              <p className="btn btn-sm btn-light">
                                <Camera size={15} />
                              </p>
                              <input
                                name={`myFile${id}`}
                                id={`myFile${id}`}
                                className="d-none"
                                type="file"
                                onChange={(e) => fileUploader(e, id)}
                              />
                            </label>
                          </div>
                        </FormGroup>

                        <FormGroup
                          className="w-100"
                          style={{ margin: "0px 25px 0px 0" }}
                        >
                          <Label className="form-label" htmlFor={"destination"}>
                            Select destination
                          </Label>
                          <Select
                            isSearchable={true}
                            id={id}
                            name="destination"
                            value={destination}
                            options={destinationsList}
                            onChange={(value) => {
                              handleChangeDestinations(
                                value,
                                id,
                                "destination"
                              );
                            }}
                            classNamePrefix="select"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor={"displayOrder"}
                          >
                            Display order
                          </Label>
                          <Input
                            type="text"
                            value={displayOrder}
                            id={id}
                            name="displayOrder"
                            placeholder="Add Display order"
                            onChange={(e) =>
                              handleChangeDestinations(
                                e.target.value,
                                id,
                                "displayOrder"
                              )
                            }
                          />
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                {newDestinations.length > 0 ? (
                  <>
                    <Button.Ripple
                      className="btn-icon rounded-circle ml-1"
                      color="light"
                      type="button"
                      size="sm"
                      onClick={() => handleRemoveDestination(id)}
                    >
                      <X size={14} />
                    </Button.Ripple>
                  </>
                ) : null}
                <hr style={{ border: "1px solid #ffe700" }} />
              </Col>
            ))}
        </Row>
        <SaveDiscardBtnGroup addAction={handleAddDestinations} />
      </form>
    </div>
  );
};
