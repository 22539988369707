import React, { useEffect, useState } from 'react'
import MerchandiseOrdersTable from './MerchandiseOrdersTable'
import { useGetAllConfirmedOrders } from '../../../api/MerchandiseOrder/Queries';
import TablePagination from '../../../@core/components/common/TablePagination';
import AddEditModal from './AddEditModal';
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import { useGetMerchandiseReport } from '../../../api/MerchandiseOrder/Mutation';

const { dateFormatter } = require("../../../utility/Utils");

const MerchandiseOrders = () => {
    const [tableData, setTableData] = useState([])
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null)
    const [merchFilter, setMerchFilter] = useState({});
    const [status, setStatus] = useState(null);
    const [orderId, setOrderId] = useState(null)
    const [id, setId] = useState(null);
    const [userName, setUserName] = useState(null)

    const { data: merchandiseOrdersList, refetch: merchandiseOrderRefetch } =
        useGetAllConfirmedOrders({ limit, page, merchFilter });
    const [getMerchandiseReportHandler, { data }] = useGetMerchandiseReport()
    // console.log(data)
    const statusValueList = [
        { label: "Pending", value: "Pending" },
        { label: "Shipped", value: "Shipped" },
        { label: "Delivered", value: "Delivered" },
    ];


    useEffect(() => {
        if (merchandiseOrdersList?.getAllSuccessfullOrders?.data) {
            setTableData(merchandiseOrdersList?.getAllSuccessfullOrders?.data)
            setTotalPage(merchandiseOrdersList?.getAllSuccessfullOrders?.count / limit)
        }
    }, [merchandiseOrdersList])

    useEffect(() => {
        if (merchandiseOrdersList?.getAllSuccessfullOrders?.data) {
            let editData = merchandiseOrdersList?.getAllSuccessfullOrders?.data?.find((item) => item._id === id)
            setEditData(editData);
        } else {
            setId(null);
            setEditData(null);
        }
    }, [id])

    const toggleModal = () => {
        setOpenModal(!openModal);
        setId(null);
        setEditData(null);
    };

    const handleSearch = () => {
        if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
            toaster("error", "Start date should be less than end date");
        } else {

            const filterFeild = {
                fromDate: fromDate,
                toDate: toDate,
                status: status?.value,
                orderId: orderId,
                userName: userName,
            };
            setPage(1);
            // console.log(filterFeild)
            setMerchFilter(filterFeild);
        }
    }

    const handleReset = () => {
        setFromDate(null)
        setToDate(null)
        setMerchFilter({})
        setStatus(null)
        setOrderId("")
        setId(null)
        setUserName("")
    }

    const handleClickExport = async () => {
        const data = await getMerchandiseReportHandler(merchFilter)
    }

    useEffect(() => {
        if (data?.getMerchandiseXlsxReport?.data) {
            if (data?.getMerchandiseXlsxReport?.data.length > 0) {
                const byteArray = new Uint8Array(
                    data?.getMerchandiseXlsxReport?.data
                );
                const blob = new Blob([byteArray], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `merchandiseOrdersreport.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
    }, [data]);


    return (
        localStorage.getItem("modules")?.includes("Merchandise Store") && (
            <div>
                <Row>
                    {/* <Col sm="3">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="fromDate">
                                <strong>From Date</strong>
                            </Label>
                            <Flatpickr
                                className="form-control bg-white border-dark text-dark"
                                value={fromDate}
                                onChange={(date) => {
                                    setFromDate(dateFormatter(new Date(date)));
                                }}
                                id="fromDate"
                                placeholder="From Date"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="toDate">
                                <strong>To Date</strong>
                            </Label>
                            <Flatpickr
                                className="form-control bg-white border-dark text-dark"
                                value={toDate}
                                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                                id="toDate"
                                placeholder="To Date"
                            />
                        </FormGroup>
                    </Col> */}

                    <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="url">
                                <strong>Date range</strong>
                            </Label>
                            <Flatpickr
                                className="form-control bg-white border-dark text-dark"
                                value={fromDate}
                                options={{
                                    mode: "range",
                                }}
                                onChange={(date) => {
                                    setFromDate(dateFormatter(date[0]));
                                    setToDate(dateFormatter(date[1]));
                                }}
                                id="checkinDate"
                                isClearable={true}
                                placeholder="Date range"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="status">
                                <strong>Status</strong>
                            </Label>
                            <Select
                                isClearable={true}
                                // isSearchable={true}
                                id="status"
                                name="status"
                                value={status}
                                // theme={selectThemeColors}
                                closeMenuOnSelect={true}
                                options={statusValueList}
                                onChange={(value) => {
                                    setStatus(value);
                                    console.log(value)
                                }}
                                classNamePrefix="select"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="orderId">
                                <strong>Order Id</strong>
                            </Label>
                            <Input
                                id="orderId"
                                name="orderId"
                                value={orderId}
                                placeholder="Order Id"
                                onChange={(e) => {
                                    setOrderId(e.target.value);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="userName">
                                <strong>Name</strong>
                            </Label>
                            <Input
                                id="userName"
                                name="userName"
                                placeholder="Name"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="" sm="3" style={{ display: "flex", gap: "20px" }}>
                        <Button
                            style={{
                                backgroundColor: "#ffe700",
                                height: "40px",
                                marginTop: "22px",
                                borderRadius: "5px",
                                padding: "10px 30px",
                                outline: "none",
                                border: "0",
                            }}
                            color="white"
                            appearance="primary"
                            type="button"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>

                        <Button
                            style={{
                                backgroundColor: "#ff9999",
                                height: "40px",
                                marginTop: "22px",
                                //marginLeft: "40px",
                                borderRadius: "5px",
                                padding: "10px 30px",
                                outline: "none",
                                border: "0",
                            }}
                            color="danger"
                            appearance="primary"
                            type="button"
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                        <Button
                            style={{
                                height: "40px",
                                marginTop: "22px",
                                //marginLeft: "40px",
                                borderRadius: "5px",
                                padding: "10px 30px",
                                outline: "none",
                                border: "0",
                            }}
                            color="success"
                            appearance="success"
                            onClick={(e) => {
                                handleClickExport();
                            }}
                        >
                            Export
                        </Button>
                    </Col>
                </Row>
                <MerchandiseOrdersTable
                    tableData={tableData}
                    openModal={() => setOpenModal(!openModal)}
                    setId={setId}
                />
                <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
                <AddEditModal
                    open={openModal}
                    handleToggle={toggleModal}
                    refetch={merchandiseOrderRefetch}
                    editData={editData}
                />
            </div>
        ))
}

export default MerchandiseOrders