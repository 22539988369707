import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import MultiCheckbox from "@components/common/multi-checkbox";
import { useGetRecommendationByProductType, useUpdateTrip } from "@api";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { useParams } from "react-router-dom";
import { MODULE_TYPE_CAP } from "../../../../utility/constant";

const Recommendations = () => {
  const state = useSelector((state) => state?.cms);
  const [recommendationList, setRecommendationList] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const { data } = useGetRecommendationByProductType(MODULE_TYPE_CAP.TRIP);
  const [updateTripHandler, { data: updatedTripData }] = useUpdateTrip();

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (data?.getRecommendationByProductType)
      setRecommendationList(data.getRecommendationByProductType);
  }, [data?.getRecommendationByProductType]);

  useEffect(() => {
    if (state?.tableData?.recommendations?.length > 0) {
      const list = state.tableData?.recommendations.map((v) => v._id);

      setRecommendations(list);
    }
  }, [state]);

  useEffect(() => {
    if (updatedTripData?.updateTrip?.name) {
      toaster("success", "Submitted successfully");
    }
  }, [updatedTripData?.updateTrip?.name]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (recommendations.length >= 3 || recommendations.length <= 0) {
      const data = recommendationList.filter((v) =>
        recommendations.includes(v._id)
      );

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          recommendations: data.reverse(),
        })
      );

      updateTripHandler(id, { recommendations });
    } else {
      toaster("error", "Minimum 3 items are required");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h4 className='py-1'>Select Recommendation (Min 3 - Max 6)</h4>

        <MultiCheckbox
          list={recommendationList}
          data={recommendations}
          setData={setRecommendations}
          max={6}
        />

        <SaveDiscardBtnGroup />
      </form>
    </>
  );
};

export default Recommendations;
