import "dotenv/config";

// ** React Imports
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { persistor, store } from "@store/store";
import { PersistGate } from "redux-persist/integration/react";

// ** CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { Toaster } from "react-hot-toast";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";
import { ApolloProvider } from "@apollo/client";

// ** Ripple Button
import "@components/common/ripple-button";

// ** Fake Database
import "./@fake-db";

// ** flatpickr
import "flatpickr/dist/themes/airbnb.css";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

// ** Lazy load app
import App from "./App";
import { client } from "./api/graphql.config";
import { IntlProviderWrapper } from "./utility/context/Internationalization";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <ApolloProvider client={client}>
              <App />
            </ApolloProvider>
          </IntlProviderWrapper>
          <Toaster position="top-right" />
        </ThemeContext>
      </AbilityContext.Provider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
