import { useEffect, useState } from "react";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Badge,
  Label,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "@utils";

const tableHeaderData = [
  "Guest Name",
  "Reservation No",
  "Booking Date",
  "Checkin-Checkout",
  "Folio No",
  "Hostel Name",
  "Booking Source",
  "Room No",
  "Status",
  "Booking Status",
  "Room Charges", //BaseRateExclusiveTax
  "Room Charges(Tax)", //BaseRateInclusiveTax
  "Other Charges",
  "Total",
  "Total(Tax)",
  "Other Revenue",
  "Other Revenue(Tax)",
];

const StayDateBookingTable = ({ date, data, refetch, totalCount }) => {
  const [tableData, setTableData] = useState(data);
  const [totalBaseRateExclusiveTax, settotalBaseRateExclusiveTax] = useState(0);
  const [totalOtherCharges, setTotalOtherCharges] = useState(0);

  useEffect(() => {
    let total_BaseRateExclusiveTax = 0;
    if (data?.length > 0) {
      setTableData(data);
      data?.map(
        (item) =>
          (total_BaseRateExclusiveTax =
            total_BaseRateExclusiveTax +
            (item?.BaseRateExclusiveTax[date]
              ? Number(item?.BaseRateExclusiveTax[date])
              : 0))
      );
      settotalBaseRateExclusiveTax(
        parseFloat(total_BaseRateExclusiveTax).toFixed(2)
      );
    } else {
      setTableData([]);
      settotalBaseRateExclusiveTax(0);
    }
  }, [data]);

  useEffect(() => {
    let total_OtherCharges = 0;
    if (data?.length > 0) {
      setTableData(data);
      data?.map(
        (item) => (total_OtherCharges = total_OtherCharges + item?.otherCharges)
      );
      setTotalOtherCharges(parseFloat(total_OtherCharges).toFixed(2));
    } else {
      setTableData([]);
      setTotalOtherCharges(0);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>Daily Bookings (Stay Date)</h2>
              </div>
            </CardTitle>
            <Col>
              <Label className="form-label" for="product">
                <h5>
                  Rooms Sold: <b>{totalCount}</b>
                </h5>
              </Label>
            </Col>
          </CardHeader>
          <Table responsive size="sm" hover bordered>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item?.GuestName}</td>
                  <td>
                    <Badge color="light-info">{item?.ReservationNo}</Badge>
                  </td>

                  <td>{dateFormatHandler(item?.ReservationDate)}</td>
                  <td>
                    {item?.ArrivalDate
                      ? dateFormatHandler(item?.ArrivalDate)
                      : ""}
                    -
                    {item?.DepartureDate
                      ? dateFormatHandler(item?.DepartureDate)
                      : ""}
                  </td>
                  <td>{item?.FolioNo}</td>
                  <td>{item?.hostelName}</td>
                  <td>{item?.Source}</td>
                  <td>{item?.RoomNo}</td>
                  <td>
                    <Badge
                      color={
                        item?.Status === "Active"
                          ? "light-success"
                          : "light-danger"
                      }
                    >
                      {item?.Status}
                    </Badge>
                  </td>
                  <td>{item?.BookingStatus}</td>
                  <td>
                    {item?.BaseRateExclusiveTax[date]
                      ? parseFloat(item?.BaseRateExclusiveTax[date]).toFixed(2)
                      : 0}
                  </td>
                  <td>
                    {item?.BaseRateInclusiveTax[date]
                      ? parseFloat(item?.BaseRateInclusiveTax[date]).toFixed(2)
                      : 0}
                  </td>
                  <td>{item?.otherCharges}</td>
                  <td>{item?.TotalExclusivTax?.toFixed(2)}</td>
                  <td>{item?.TotalInclusiveTax?.toFixed(2)}</td>
                  <td>{item?.OtherRevenueExclusiveTax?.toFixed(2)}</td>
                  <td>{item?.OtherRevenueInclusiveTax?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <h6>Total</h6>
                </td>
                <td>
                  <h6>{totalBaseRateExclusiveTax}</h6>
                </td>
                <td></td>
                <td>
                  <h6>{totalOtherCharges}</h6>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default StayDateBookingTable;
