import React, { useState, useEffect } from "react";
import SlideModal from "../../../@core/components/common/slide-modal";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import toaster from "@components/common/Toaster";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import SwitchIcon from "@components/common/switch-icon";
import {
  useCreateStepOutBlog,
  useUpdateStepoutBlog,
} from "../../../api/dataSource";
import Flatpickr from "react-flatpickr";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [blogTitle, setBlogTitle] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [url, setUrl] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [tags, setTags] = useState("");
  const [timetoRead, setTimeToRead] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [status, setStatus] = useState(false);
  const [seo, setSeo] = useState("");

  const [createStepoutBlogHandler, { data: createdStepoutBlogData }] =
    useCreateStepOutBlog();
  const [updateStepoutBlogHandler, { data: updatedStepoutBlogData }] =
    useUpdateStepoutBlog();

  useEffect(() => {
    if (editData?._id) {
      setBlogTitle(editData?.blogTitle);
      setAuthorName(editData?.authorName);
      setShortDescription(editData?.shortDescription);
      setDisplayOrder(editData?.displayOrder);
      setMetaTitle(editData?.seo?.metaTitle);
      setMetaDesc(editData?.seo?.metaDesc);
      setKeywords(editData?.seo?.keywords);
      setUrl(editData?.url);

      setStatus(editData?.status);
      setTags(editData?.tags);
      setTimeToRead(editData?.timetoRead);
      setPublishDate(editData?.publishDate);
    } else {
      setBlogTitle("");
      setAuthorName("");
      setShortDescription("");
      setDisplayOrder("");
      setMetaDesc("");
      setMetaTitle("");
      setKeywords("");

      setUrl("");
      setSeo("");
      setStatus(false);
      setTags("");
      setTimeToRead("");
      setPublishDate("");
    }
  }, [editData]);

  useEffect(() => {
    if (createdStepoutBlogData || updatedStepoutBlogData) {
      refetch();
      handleToggle();
    }
  }, [createdStepoutBlogData, updatedStepoutBlogData]);

  const submitDataHandler = () => {
    const data = {
      blogTitle,
      authorName,
      shortDescription,
      displayOrder: parseInt(displayOrder),
      url,
      seo: {
        metaTitle,
        metaDesc,
        keywords,
      },
      status,
      tags,
      timetoRead,
      publishDate,
    };
    if (data.blogTitle) {
      if (editData?._id) {
        const { _id } = editData;
        updateStepoutBlogHandler(_id, data);
        toaster("success", "Blog updated successfully");
      } else {
        createStepoutBlogHandler(data);
        toaster("success", "Blog added successfully");
      }

      setBlogTitle("");
      setAuthorName("");
      setMetaDesc("");
      setMetaTitle("");
      setKeywords("");
      setShortDescription("");
      setDisplayOrder("");
      setUrl("");
      setSeo("");
      setTags("");
      setTimeToRead("");
      setPublishDate(new Date());
      setStatus(false);
    } else {
      toaster("error", "Please fill the details");
    }
  };

  useEffect(() => {
    if (editData?._id) {
      setBlogTitle(editData?.blogTitle !== null ? editData?.blogTitle : "");
      setShortDescription(
        editData?.shortDescription !== null ? editData?.shortDescription : ""
      );
      setMetaTitle(
        editData?.seo?.metaTitle !== null ? editData?.seo?.metaTitle : ""
      );
      setMetaDesc(
        editData?.seo?.metaDesc !== null ? editData?.seo?.metaDesc : ""
      );
      setKeywords(
        editData?.seo?.keywords !== null ? editData?.seo?.keywords : ""
      );
      setUrl(editData?.url !== null ? editData?.url : "");
      setStatus(editData?.status !== null ? editData?.status : false);
      setTimeToRead(editData?.timetoRead !== null ? editData?.timetoRead : "");
    }
  }, [editData]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2097152,
    },
  });

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => submitDataHandler()}
    >
      <form>
        <Row>
          {/* // ! Blog Title */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="blogTitle">
                Blog Title
              </Label>
              <Input
                type="text"
                value={blogTitle}
                id="blogTitle"
                name="blogTitle"
                placeholder="Add Blog Title"
                onChange={(e) => setBlogTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Author Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="authorName">
                Author Name
              </Label>
              <Input
                type="text"
                value={authorName}
                id="authorName"
                name="authorName"
                placeholder="Add Author Name"
                onChange={(e) => setAuthorName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Short Description */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="shortDescription">
                Short Description
              </Label>
              <Input
                type="textarea"
                value={shortDescription}
                id="shortDescription"
                name="shortDescription"
                placeholder="Add Short Description"
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Tags */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="tags">
                Tags
              </Label>
              <Input
                type="text"
                value={tags}
                id="tags"
                name="tags"
                placeholder="Add Tags"
                onChange={(e) => setTags(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Time To Read */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="timeToRead">
                Time To Read
              </Label>
              <Input
                type="text"
                value={timetoRead}
                id="timeToRead"
                name="timeToRead"
                placeholder="Add Time To Read"
                onChange={(e) => setTimeToRead(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Publish Date */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="publish date">
                Publish Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={publishDate}
                onChange={(date) => setPublishDate(date)}
                id="publishDate"
                placeholder="Add Publish Date"
                required
              />
            </FormGroup>
          </Col>

          {/* // ! Display Order */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="displayOrder">
                Display Order
              </Label>
              <Input
                type="text"
                value={displayOrder}
                id="displayOrder"
                name="displayOrder"
                placeholder="Add Display Order"
                onChange={(e) => setDisplayOrder(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! SEO */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="seo">
                SEO
              </Label>

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Title
                </Label>
                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add Meta Title"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </Col>
              <br />

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Description
                </Label>
                <Input
                  type="text"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add Meta Desc"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </Col>
              <br />
              <Col sm="12">
                <Label className="form-label" for="seo">
                  Keywords
                </Label>
                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add Keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </Col>
            </FormGroup>
          </Col>

          {/* // ! URL */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                URL (Format: /blogs/blog-name)
              </Label>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add URL"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Status */}
          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Publish on Website ?"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
