import gql from "graphql-tag";

export const GET_EZEE_MEAL_REPORT = gql`
  query getEzeeMealReport($filter: filter) {
    getEzeeMealReport(filter: $filter) {
      data{
        _id
        Room
        ReservationNo
        GuestName
        RoomNo
        hostelName
        ArrivalDate
        DepartureDate
        mealAddedInEzee
        RatePlan
      }
      count
      page
      limit
    }
  }
`;