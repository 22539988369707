import { gql, useMutation, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const CREATE_CAFE_WISE_MENU = gql`
  mutation CreateCafewiseMenu($cafewiseMenuInput: [CafewiseMenuInput]) {
    createCafewiseMenu(cafewiseMenuInput: $cafewiseMenuInput) {
      data
    }
  }
`;

// export const useCreateCafewiseMenu = () => {
//   const [createCafewiseMenu, { loading, error, data, refetch }] = useMutation(
//     CREATE_CAFE_WISE_MENU
//   );

//   useLoadingHandler(loading);

//   const createCafewiseMenuHandler = ({ cafewiseMenuInput }) => {
//     createCafewiseMenu({
//       variables: {
//         cafewiseMenuInput,
//       },
//     });
//   };

//   return [createCafewiseMenuHandler, { data, error, refetch }];
// };

export const CREATE_AND_UPDATE = gql`
  mutation createAndUpdateCafewise($cafewiseMenuInput: [CafewiseMenuInput]) {
    createAndUpdateCafewise(cafewiseMenuInput: $cafewiseMenuInput) {
      data
    }
  }
`;

export const useCreateAndUpdateCafewiseMenu = () => {
  const [createCafewiseMenu, { loading, error, data, refetch }] =
    useMutation(CREATE_AND_UPDATE);

  useLoadingHandler(loading);

  const createCafewiseMenuHandler = ({ cafewiseMenuInput }) => {
    createCafewiseMenu({
      variables: {
        cafewiseMenuInput,
      },
    });
  };

  return [createCafewiseMenuHandler, { data, error, refetch }];
};

export const UPDATE_SINGLE = gql`
  mutation updateCafewiseSingle($CafewiseMenuInput: CafewiseMenuInput) {
    updateCafewiseSingle(CafewiseMenuInput: $CafewiseMenuInput) {
      _id
      soldOutLogs
    }
  }
`;

export const useUpdateCafewiseSingle = () => {
  const [updateMenu, { loading, data, refetch, error }] =
    useMutation(UPDATE_SINGLE);

  useLoadingHandler(loading);

  const updateHandler = (CafewiseMenuInput) => {
    updateMenu({
      variables: {
        CafewiseMenuInput,
      },
    });
  };

  return [updateHandler, { data, error, refetch }];
};
