import React, { useEffect, useState } from "react";
import FinanceTable from "./FinanceTable";
import TablePagination from "@components/common/TablePagination";
import {
  useGetPaginatedOpsFinanceList,
  useHostelList,
  useGetAllDayBookDataForExport,
  useGetHostelWiseClosingBalance,
} from "../../../api/dataSource";
import CashInAddEditModal from "./CashInAddEditModal";
import CashOutAddEditModal from "./CashOutAddEditModal";
import Select from "react-select";
// import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Button,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";

import {
  selectThemeColors,
  dateFormatter,
  dateFormatterDDMMYYYY,
} from "@utils";
import Flatpickr from "react-flatpickr";
import { CSVLink } from "react-csv";

const Finance = () => {
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [cashInOpenModal, setCashInOpenModal] = useState(false);
  const [cashOutOpenModal, setCashOutOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [remainingBalance, setRemainingBalance] = useState("");
  const [transactionDateTo, setTransactionDateTo] = useState(null);
  const [transactionDateFrom, setTransactionDateFrom] = useState(null);
  const [amountAbove, setAmountAbove] = useState("");

  const [filterFields, setFilterFields] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });

  const [entryType, setEntryType] = useState("");
  const [head, setHead] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [subHead, setSubHead] = useState("");
  const [exportDayBook, setExportDayBook] = useState([]);
  const [exportClosingBalanceData, setExportClosingBalanceData] = useState([]);
  const [pendingInvoice, setPendingInvoice] = useState("");

  const { data, refetch } = useGetPaginatedOpsFinanceList(
    limit,
    page,
    filterFields
  );

  const { data: exportData } = useGetAllDayBookDataForExport(filterFields);
  const { data: allHostelsData } = useHostelList();

  const cashInToggleModal = () => {
    setCashInOpenModal(!cashInOpenModal);
    setId(null);
    setEditData(null);
  };
  const cashOutToggleModal = () => {
    setCashOutOpenModal(!cashOutOpenModal);
    setId(null);
    setEditData(null);
  };

  const [showLoremModal, setShowLoremModal] = useState(false);

  const toggleLoremModal = () => {
    setShowLoremModal(!showLoremModal);
  };

  useEffect(() => {
    refetch();
  }, [refetch, page]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    if (data?.getPaginatedOpsFinance?.data.length > 0) {
      setTableData(data?.getPaginatedOpsFinance?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedOpsFinance?.count / limit));
  }, [
    data?.getPaginatedOpsFinance?.count,
    data?.getPaginatedOpsFinance?.data,
    limit,
  ]);

  useEffect(() => {
    if (exportData?.getAllDayBookDataForExport?.data?.length > 0) {
      const processedData = exportData?.getAllDayBookDataForExport?.data.map(
        (item) => ({
          ...item,
          invoiceURLs: item?.uploadInvoice
            ? item?.uploadInvoice?.map((invoice) => invoice.image).join(", ")
            : "",
        })
      );
      setExportDayBook(processedData);
    } else {
      setExportDayBook([]);
    }
  }, [exportData]);

  useEffect(() => {
    if (id && data?.getPaginatedOpsFinance?.data.length > 0) {
      let findData = data?.getPaginatedOpsFinance.data.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedOpsFinance?.data, id]);

  useEffect(() => {
    setRemainingBalance(data?.getPaginatedOpsFinance?.remainingBalance);
  }, [data?.getPaginatedOpsFinance?.remainingBalance]);

  const handleSearch = () => {
    const filterFields = {
      hostelIdArray:
        hostel.length > 0
          ? hostel?.map((hostel) => hostel.value)
          : JSON.parse(localStorage.getItem("hostels")),
      entryType: entryType?.value,
      head: head?.value,
      // head: head?.map((item) => item.value).toString(),
      // head:
      //   head && Array.isArray(head)
      //     ? head.map((item) => item.value).toString()
      //     : "",
      transactionDateFrom,
      transactionDateTo,
      subHead: subHead?.value,
      // subHead:
      //   subHead && Array.isArray(subHead)
      //     ? subHead.map((item) => item.value).toString()
      //     : "",
      reservationId,
      // amountAbove: parseFloat(amountAbove),
      amountAbove: amountAbove.value,
      pendingInvoice: pendingInvoice?.value,
    };
    setPage(1);
    setFilterFields(filterFields);
  };

  const handleReset = () => {
    setFilterFields({
      hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    });
    setHostel([]);
    setEntryType("");
    setHead("");
    setSubHead("");
    setReservationId("");
    setTransactionDateFrom(null);
    setTransactionDateTo(null);
    setAmountAbove("");
    setPendingInvoice("");
    setPage(1);
  };

  const entryTypeDropDownList = [
    { label: "Cash In", value: "Cash In" },
    { label: "Cash Out", value: "Cash Out" },
  ];

  const totalAmountRangeDropdown = [
    { label: "0 - 1000", value: "0 - 1000" },
    { label: "1000 - 5000", value: "1000 - 5000" },
    { label: "5000 - 10000", value: "5000 - 10000" },
    { label: "More Than 10000", value: "More Than 10000" },
  ];

  const pendingInvoiveDropdown = [
    { label: "Pending", value: "Pending" },
    { label: "Completed", value: "Completed" },
  ];

  // const headDropDownList = [
  //   { label: "HO", value: "HO" },
  //   { label: "F&B", value: "F&B" },
  //   { label: "Ancillary", value: "Ancillary" },
  //   { label: "CAPEX", value: "CAPEX" },
  // ];

  const [headDropDownList, setHeadDropDownList] = useState([
    { label: "Bed", value: "Bed" },
    { label: "F&B", value: "F&B" },
    { label: "Ancillary", value: "Ancillary" },
    { label: "HO", value: "HO" },
  ]);

  useEffect(() => {
    let adjustedHeadDropDownList = [];
    if (entryType && entryType.value === "Cash In") {
      adjustedHeadDropDownList = [
        { label: "Bed", value: "Bed" },
        { label: "F&B", value: "F&B" },
        { label: "Ancillary", value: "Ancillary" },
        { label: "HO", value: "HO" },
      ];
    } else if (entryType && entryType.value === "Cash Out") {
      adjustedHeadDropDownList = [
        { label: "Hostel", value: "Hostel" },
        { label: "F&B", value: "F&B" },
        { label: "Ancillary", value: "Ancillary" },
        { label: "CAPEX", value: "CAPEX" },
      ];
    }
    setHeadDropDownList(adjustedHeadDropDownList);
  }, [entryType]);

  const [SubHeadDropDownList, setSubHeadDropDownList] = useState([
    { label: "Rental", value: "Rental" },
    { label: "Payout", value: "Payout" },
    { label: "Salary", value: "Salary" },
    { label: "Advance Salary", value: "Advance Salary" },
    { label: "Utilities", value: "Utilities" },
    { label: "R&M", value: "R&M" },
    { label: "HK", value: "HK" },
    { label: "Commission", value: "Commission" },
    { label: "General", value: "General" },
    { label: "Material", value: "Material" },
    { label: "Vendor Payout", value: "Vendor Payout" },
    { label: "Hostel", value: "Hostel" },
    { label: "F&B", value: "F&B" },
    { label: "Ancillary", value: "Ancillary" },
  ]);

  useEffect(() => {
    let adjustedSubHeadDropDownList = [];
    if (head) {
      if (head.value === "Hostel") {
        adjustedSubHeadDropDownList = [
          { label: "Rental", value: "Rental" },
          { label: "Payout", value: "Payout" },
          { label: "Salary", value: "Salary" },
          { label: "Advance Salary", value: "Advance Salary" },
          { label: "Utilities", value: "Utilities" },
          { label: "R&M", value: "R&M" },
          { label: "HK", value: "HK" },
          { label: "Commission", value: "Commission" },
          { label: "General", value: "General" },
        ];
      } else if (head.value === "F&B") {
        adjustedSubHeadDropDownList = [
          { label: "Material", value: "Material" },
          { label: "Vendor Payout", value: "Vendor Payout" },
        ];
      } else if (head.value === "Ancillary") {
        adjustedSubHeadDropDownList = [{ label: "Payout", value: "Payout" }];
      } else if (head.value === "CAPEX") {
        adjustedSubHeadDropDownList = [
          { label: "Hostel", value: "Hostel" },
          { label: "F&B", value: "F&B" },
          { label: "Ancillary", value: "Ancillary" },
        ];
      }
    }
    setSubHeadDropDownList(adjustedSubHeadDropDownList);
  }, [head]);

  // const SubHeadDropDownList = [
  //   { label: "Rental", value: "Rental" },
  //   { label: "Payout", value: "Payout" },
  //   { label: "Salary", value: "Salary" },
  //   { label: "Advance Salary", value: "Advance Salary" },
  //   { label: "Utilities", value: "Utilities" },
  //   { label: "R&M", value: "R&M" },
  //   { label: "HK", value: "HK" },
  //   { label: "Commission", value: "Commission" },
  //   { label: "General", value: "General" },

  //   { label: "Material", value: "Material" },

  //   { label: "Payout", value: "Payout" },

  //   { label: "Hostel", value: "Hostel" },
  //   { label: "F&B", value: "F&B" },
  //   { label: "Ancillary", value: "Ancillary" },
  // ];

  const exportHeaders = [
    { label: "Type", key: "entryType" },
    { label: "Head", key: "head" },
    { label: "Sub Head", key: "subHead" },
    { label: "Sub Head Type", key: "subFields.subHeadType" },
    { label: "Vendor Name", key: "subFields.vendorName" },
    { label: "Hostel Name", key: "hostelDetails.name" },
    { label: "Reservation Id", key: "reservationId" },
    {
      label: "Transaction Date",
      key: "entryDateConverted",
    },
    { label: "Transaction Time", key: "entryTime" },
    {
      label: "Entry Date",
      key: "createdAtConverted",
    },
    { label: "Amount", key: "amountWithoutGST" },
    { label: "GST", key: "GST" },
    { label: "GST Number", key: "gstNumber" },
    { label: "Vendor Name", key: "vendorNameDayBook" },
    { label: "Total Amount", key: "totalAmount" },
    { label: "Opening Balance", key: "openingBalance" },
    { label: "Closing Balance", key: "closingBalance" },
    { label: "Payment Mode", key: "paymentMode" },
    { label: "Expense Type", key: "expenseType" },
    { label: "Entry By", key: "entryBy" },
    { label: "Remarks", key: "remarks" },
    { label: "Invoice URL", key: "invoiceURLs" },
    { label: "Pending Invoice Remark", key: "pendingInvoiceCorrectionRemark" },
  ];

  return (
    localStorage.getItem("modules")?.includes("Operations") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Transaction Date Range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="transactionDate"
                placeholder="Transaction Date Range"
                options={{
                  mode: "range",
                }}
                value={[transactionDateFrom, transactionDateTo]}
                onChange={(date) => {
                  setTransactionDateFrom(dateFormatter(date[0]));
                  setTransactionDateTo(dateFormatter(date[1]));
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Hostel</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                closeMenuOnSelect={false}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="reservationId">
                <strong>Reservation ID</strong>
              </Label>
              <Input
                type="text"
                id="reservationId"
                name="reservationId"
                placeholder="Reservation ID"
                value={reservationId}
                onChange={(e) => {
                  setReservationId(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="amountAbove">
                <strong>Total Amount</strong>
              </Label>
              <Select
                isSearchable={true}
                isClearable={false}
                theme={selectThemeColors}
                id="amountAbove"
                name="amountAbove"
                options={totalAmountRangeDropdown}
                value={amountAbove}
                onChange={(value) => {
                  setAmountAbove(value);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="invoiceStatus">
                <strong>Invoice Status</strong>
              </Label>
              <Select
                isClearable={true}
                theme={selectThemeColors}
                id="invoiceStatus"
                name="invoiceStatus"
                value={pendingInvoice}
                options={pendingInvoiveDropdown}
                onChange={(value) => {
                  setPendingInvoice(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="bookingSource">
                <strong>Cash In/Out</strong>
              </Label>
              <Select
                isClearable={true}
                theme={selectThemeColors}
                id="entryType"
                name="entryType"
                value={entryType}
                options={entryTypeDropDownList}
                onChange={(value) => {
                  setEntryType(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            {entryType && (
              <FormGroup className="w-100">
                <Label className="form-label" for="head">
                  <strong>Head</strong>
                </Label>
                <Select
                  isSearchable={true}
                  isClearable={false}
                  theme={selectThemeColors}
                  // isMulti
                  id="head"
                  name="head"
                  value={head}
                  options={headDropDownList}
                  onChange={(value) => {
                    setHead(value);
                  }}
                  classNamePrefix="select"
                />
              </FormGroup>
            )}
          </Col>

          <Col sm="2">
            {head && (
              <FormGroup className="w-100">
                <Label className="form-label" for="subHead">
                  <strong>Cash Out Sub Head</strong>
                </Label>
                <Select
                  isSearchable={true}
                  isClearable={false}
                  theme={selectThemeColors}
                  // isMulti
                  id="subHead"
                  name="subHead"
                  value={subHead}
                  options={SubHeadDropDownList}
                  onChange={(value) => {
                    setSubHead(value);
                  }}
                  classNamePrefix="select"
                />
              </FormGroup>
            )}
          </Col>

          <Col sm="auto" className="mb-2">
            {" "}
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
          </Col>
          <Col sm="auto" className="mb-2">
            {" "}
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                // marginLeft: "30px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>

          <Col sm="auto" className="mb-2">
            <div style={{ marginTop: "22px" }}>
              {" "}
              <CSVLink
                headers={exportHeaders}
                data={exportDayBook}
                filename={"DayBook_Data.csv"}
              >
                <Button
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="success"
                  appearance="success"
                  type="button"
                >
                  Export
                </Button>
              </CSVLink>
            </div>
          </Col>
        </Row>
        <FinanceTable
          setId={setId}
          cashInOpenModal={() => setCashInOpenModal(!cashInOpenModal)}
          cashOutOpenModal={() => setCashOutOpenModal(!cashOutOpenModal)}
          data={tableData}
          refetch={refetch}
          remainingBalance={remainingBalance}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <CashInAddEditModal
          open={cashInOpenModal}
          handleToggle={cashInToggleModal}
          refetch={refetch}
          editData={editData}
          setId={setId}
        />

        <CashOutAddEditModal
          open={cashOutOpenModal}
          handleToggle={cashOutToggleModal}
          refetch={refetch}
          editData={editData}
          setId={setId}
        />
      </div>
    )
  );
};

export default Finance;
