import React, { useEffect, useState } from "react";
import SlideModal from "@components/common/slide-modal";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import {
  useCreateReplyTemplate,
  useUpdateReplyTemplate,
  useHostelList,
} from "../../api/dataSource";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function AddEditModal({ open, handleToggle, editData, refetch, setId }) {
  const [templateTitle, setTemplateTitle] = useState("");
  const [templateContent, setTemplateContent] = useState("");
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useHostelList();

  const [createReplyTemplateHandler, { data: createReplyTemplateData }] =
    useCreateReplyTemplate();
  const [updateReplyTemplateHandler, { data: updateReplyTemplateData }] =
    useUpdateReplyTemplate();

  useEffect(() => {
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        setHostelsList((prev) => [
          ...prev,
          { ...item, label: item?.name, value: item?._id },
        ]);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const handleChange = function (HostelsList) {
    return setHostel(HostelsList);
  };

  useEffect(() => {
    if (createReplyTemplateData || updateReplyTemplateData) {
      refetch();
      handleToggle();

      toaster("success", "Reply Template added successfully");
    }
  }, [createReplyTemplateData, updateReplyTemplateData]);
  
  const handleSubmit = () => {
    const hostelsArray = [];
    // eslint-disable-next-line array-callback-return
    hostel?.map((item) => {
      hostelsArray?.push(item?.value);
    });

    const data = {
      templateTitle,
      templateContent,
      hostels: JSON.parse(JSON.stringify(hostelsArray)),
    };
    if (data.templateTitle && data.templateContent && data.hostels) {
      if (editData) {
        updateReplyTemplateHandler(editData?._id, data);
        setId("");
      } else {
        createReplyTemplateHandler(data);
      }
      refetch();
      setTemplateTitle("");
      setTemplateContent("");
      setHostel([]);
    } else {
      toaster("error", "Please fill all required details");
    }
  };

  useEffect(() => {
    if (editData) {
      let hotelSelectedData = [];
      editData?.hostels?.map((hostel) => {
        allHostelsData?.getHostelList?.map((hostellist) => {
          if (hostel?._id === hostellist?._id) {
            hotelSelectedData?.push({
              label: hostel?.name,
              value: hostel?._id,
            });
          }
        });
      });

      setHostel(hotelSelectedData);
      setTemplateTitle(editData?.templateTitle);
      setTemplateContent(editData?.templateContent);
      // refetch();
    } else {
      setHostel([]);
    }
  }, [editData, allHostelsData, refetch]);

  return (
    <SlideModal
      submitDataHandler={handleSubmit}
      handleToggle={handleToggle}
      open={open}
    >
      <form action="">
        <Row>
          <Col>
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostels
              </Label>
              <Select
                onChange={handleChange}
                isClearable={false}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for="title">Template Title</Label>
              <Input
                placeholder="Template Title"
                value={templateTitle}
                onChange={(e) => setTemplateTitle(e.target.value)}
                type="text"
                name="templateTitle"
                id="templateTitle"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for="templateContent">Template Content</Label>
              <Input
                placeholder="Template Content"
                value={templateContent}
                onChange={(e) => setTemplateContent(e.target.value)}
                type="textarea"
                name="templateContent"
                id="templateContent"
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
}
export default AddEditModal;
