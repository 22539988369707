import FeedbackForm from "../../views/FeedbackForm";

const FeedbackFormRoutes = [
  {
    path: "/feedbackform",
    exact: true,
    component: FeedbackForm,
  },
  {
    path: "/feedbackform/:reservationId",
    component: FeedbackForm,
    exact: true,
    meta: {
      navLink: "/feedbackform",
    },
  },
];

export default FeedbackFormRoutes;
