import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import toaster from "@components/common/Toaster";
import { useUpdateReviewsLinks } from "../../../api/dataSource";

const AddEditModal = ({
  open,
  handleToggle,
  refetch,
  editData,
  setEditId,
  tableData,
}) => {
  const [booking, setBooking] = useState("");
  const [tripAdvisor, setTripAdvisor] = useState("");
  const [hostelWorld, setHostelWorld] = useState("");
  const [goibibo, setGoibibo] = useState("");
  const [mmt, setMmt] = useState("");
  const [google, setGoogle] = useState("");

  const [bookingAvgRatingLink, setBookingAvgRatingLink] = useState("");
  const [tripAdvisorAvgRatingLink, setTripAdvisorAvgRatingLink] = useState("");
  const [hostelWorldAvgRatingLink, setHostelWorldAvgRatingLink] = useState("");
  const [goibiboAvgRatingLink, setGoibiboAvgRatingLink] = useState("");
  const [mmtAvgRatingLink, setMmtAvgRatingLink] = useState("");
  const [googleAvgRatingLink, setGoogleAvgRatingLink] = useState("");
  const [bookingUfiCode, setBookingUfiCode] = useState("");
  const [bookingHotelCode, setBookingHotelCode] = useState("");

  const [updateReviewsLinksHandler, data] = useUpdateReviewsLinks();

  // useEffect(() => {
  //   refetch();
  // }, [tableData]);

  useEffect(() => {
    if (editData?.length) {
      // setBooking(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.booking
      //     : ""
      // );
      // setTripAdvisor(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.tripAdvisor
      //     : ""
      // );
      // setHostelWorld(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.hostelWorld
      //     : ""
      // );
      // setGoibibo(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.goibibo
      //     : ""
      // );
      // setMmt(
      //   editData[0]?.reviewsLinks !== null ? editData[0]?.reviewsLinks?.mmt : ""
      // );
      // setGoogle(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.google
      //     : ""
      // );

      // //average rating link
      // setBookingAvgRatingLink(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.bookingAvgRatingLink
      //     : ""
      // );
      // setTripAdvisorAvgRatingLink(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.tripAdvisorAvgRatingLink
      //     : ""
      // );
      // setHostelWorldAvgRatingLink(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.hostelWorldAvgRatingLink
      //     : ""
      // );
      // setGoibiboAvgRatingLink(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.goibiboAvgRatingLink
      //     : ""
      // );
      // setMmtAvgRatingLink(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.mmtAvgRatingLink
      //     : ""
      // );
      // setGoogleAvgRatingLink(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.googleAvgRatingLink
      //     : ""
      // );
      // setBookingUfiCode(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.bookingUfiCode
      //     : ""
      // );
      // setBookingHotelCode(
      //   editData[0]?.reviewsLinks !== null
      //     ? editData[0]?.reviewsLinks?.bookingHotelCode
      //     : ""
      // );

      setBooking(editData[0]?.reviewsLinks?.booking || "");
      setTripAdvisor(editData[0]?.reviewsLinks?.tripAdvisor || "");
      setHostelWorld(editData[0]?.reviewsLinks?.hostelWorld || "");
      setGoibibo(editData[0]?.reviewsLinks?.goibibo || "");
      setMmt(editData[0]?.reviewsLinks?.mmt || "");
      setGoogle(editData[0]?.reviewsLinks?.google || "");
      setBookingAvgRatingLink(
        editData[0]?.reviewsLinks?.bookingAvgRatingLink || ""
      );
      setTripAdvisorAvgRatingLink(
        editData[0]?.reviewsLinks?.tripAdvisorAvgRatingLink || ""
      );
      setHostelWorldAvgRatingLink(
        editData[0]?.reviewsLinks?.hostelWorldAvgRatingLink || ""
      );
      setGoibiboAvgRatingLink(
        editData[0]?.reviewsLinks?.goibiboAvgRatingLink || ""
      );
      setMmtAvgRatingLink(editData[0]?.reviewsLinks?.mmtAvgRatingLink || "");
      setGoogleAvgRatingLink(
        editData[0]?.reviewsLinks?.googleAvgRatingLink || ""
      );
      setBookingUfiCode(editData[0]?.reviewsLinks?.bookingUfiCode || "");
      setBookingHotelCode(editData[0]?.reviewsLinks?.bookingHotelCode || "");
    }
  }, [editData]);

  const resetFields = () => {
    setBooking("");
    setTripAdvisor("");
    setHostelWorld("");
    setGoibibo("");
    setMmt("");
    setGoogle("");
    setBookingAvgRatingLink("");
    setTripAdvisorAvgRatingLink("");
    setHostelWorldAvgRatingLink("");
    setGoibiboAvgRatingLink("");
    setMmtAvgRatingLink("");
    setGoogleAvgRatingLink("");
    setBookingUfiCode("");
    setBookingHotelCode("");
  };

  const submitDataHandler = () => {
    let data = {
      reviewsLinks: {
        booking,
        tripAdvisor,
        hostelWorld,
        goibibo,
        mmt,
        google,
        bookingAvgRatingLink,
        tripAdvisorAvgRatingLink,
        hostelWorldAvgRatingLink,
        goibiboAvgRatingLink,
        mmtAvgRatingLink,
        googleAvgRatingLink,
        bookingUfiCode,
        bookingHotelCode,
      },
    };

    if (data?.reviewsLinks) {
      if (editData?.length) {
        const { _id } = editData[0];
        updateReviewsLinksHandler(_id, data);
        toaster("success", "Reviews links added successfully");
        // handleToggle();
        // refetch();
        resetFields();
        handleToggle();
        refetch();
      }
      setEditId(null);
    } else {
      toaster("error", "Please fill all the required details marked with (*)");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="booking">
                Booking.com Review Link (Website review page - Inspect and get
                URL link)
              </Label>
              <Input
                type="textarea"
                value={booking}
                id="booking"
                name="booking"
                placeholder="Add Booking"
                onChange={(e) => setBooking(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="bookingaveragelink">
                Booking.com Average Rating Link (Website review page URL)
              </Label>
              <Input
                type="textarea"
                value={bookingAvgRatingLink}
                id="bookingaveragelink"
                name="bookingaveragelink"
                placeholder="Add Booking average rating link"
                onChange={(e) => setBookingAvgRatingLink(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="tripAdvisor">
                Trip Advisor (Website Review page URL)
              </Label>
              <Input
                type="textarea"
                value={tripAdvisor}
                id="tripAdvisor"
                name="tripAdvisor"
                placeholder="Enter location ID"
                onChange={(e) => setTripAdvisor(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="tripadvisoraverage">
                Trip Advisor Average Rating Link (Website Review page URL)
              </Label>
              <Input
                type="textarea"
                value={tripAdvisorAvgRatingLink}
                id="tripadvisoraverage"
                name="tripadvisoraverage"
                placeholder="Add Trip Advisor average rating link"
                onChange={(e) => setTripAdvisorAvgRatingLink(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostelWorld">
                Hostel World Review Link (Review Page - Network URL)
              </Label>
              <Input
                type="textarea"
                value={hostelWorld}
                id="hostelWorld"
                name="hostelWorld"
                placeholder="Add Hostel World"
                onChange={(e) => setHostelWorld(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostelworldaverage">
                Hostel World Average Rating Link (Review Page link)
              </Label>
              <Input
                type="textarea"
                value={hostelWorldAvgRatingLink}
                id="hostelworldaverage"
                name="hostelworldaverage"
                placeholder="Add Hostel World average rating link"
                onChange={(e) => setHostelWorldAvgRatingLink(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="goibibo">
                Goibibo Review Link (Website review page - Network URL)
              </Label>
              <Input
                type="textarea"
                value={goibibo}
                id="goibibo"
                name="goibibo"
                placeholder="Add Goibibo"
                onChange={(e) => setGoibibo(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="goibiboaverage">
                Goibibo Average Rating Link (Website review page - Network URL)*
              </Label>
              <Input
                type="textarea"
                value={goibiboAvgRatingLink}
                id="goibiboaverage"
                name="goibiboaverage"
                placeholder="Add Goibibo average rating link"
                onChange={(e) => setGoibiboAvgRatingLink(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="mmt">
                MMT Review Link ((MMT Backend hostel code))
              </Label>
              <Input
                type="textarea"
                value={mmt}
                id="mmt"
                name="mmt"
                placeholder="Add MMT"
                onChange={(e) => setMmt(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="mmtaverage">
                MMT Average Rating Link (MMT Backend hostel code)
              </Label>
              <Input
                type="textarea"
                value={mmtAvgRatingLink}
                id="mmtaverage"
                name="mmtaverage"
                placeholder="Add MMT average rating link"
                onChange={(e) => setMmtAvgRatingLink(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="google">
                Google (location/ID)
              </Label>
              <Input
                type="textarea"
                value={google}
                id="google"
                name="google"
                placeholder="Enter loactions/ID"
                onChange={(e) => setGoogle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="googleaverage">
                Google Average Rating Link (location/ID)
              </Label>
              <Input
                type="textarea"
                value={googleAvgRatingLink}
                id="googleaverage"
                name="googleaverage"
                placeholder="Add Google average rating link"
                onChange={(e) => setGoogleAvgRatingLink(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="googleaverage">
                Booking.com UFI code (Response GraphQL Payload)
              </Label>
              <Input
                type="textarea"
                value={bookingUfiCode}
                id="bookingUfiCode"
                name="bookingUfiCode"
                placeholder="Add Booking.com UFI code"
                onChange={(e) => setBookingUfiCode(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="googleaverage">
                Booking.com Hotel code (Response GraphQL Payload)
              </Label>
              <Input
                type="textarea"
                value={bookingHotelCode}
                id="bookingHotelCode"
                name="bookingHotelCode"
                placeholder="Add Booking.com Hotel code"
                onChange={(e) => setBookingHotelCode(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
