import React, { useState } from "react";
import AddEditModal from "./AddEditModal";
import BlogTable from "../../../@core/components/tables/BlogTable";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";

const Blog = () => {
  const [blogTitle, setBlogTitle] = useState("");
  const [status, setStatus] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);

  const statusType = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const handleSearch = () => {
    const data = {
      blogTitle,
      status: status?.value,
    };
    setFilterData(data);
    setPage(1);
  };

  const handleReset = () => {
    setBlogTitle("");
    setStatus(null);
    setFilterData(null);
    setPage(1);
  };

  return (
    localStorage.getItem("modules")?.includes("Blogs") && (
      <>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="blogTitle">
                  Blog Title
                </Label>
              </strong>
              <Input
                type="text"
                value={blogTitle}
                id="blogTitle"
                name="blogTitle"
                placeholder="Blog Title"
                onChange={(e) => setBlogTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="status">
                  Status
                </Label>
              </strong>
              <Select
                isSearchable={true}
                isClearable={true}
                id="status"
                name="status"
                value={status}
                options={statusType}
                onChange={(value) => setStatus(value)}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        <BlogTable
          filterData={filterData}
          AddEditModal={AddEditModal}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
        />
      </>
    )
  );
};
export default Blog;
