import gql from "graphql-tag";

export const GET_OFFER_BY_ID = gql`
  query getOffer($id: ID!) {
    getOffer(id: $id) {
      _id
      title
      shortDescription
      expiryDate
      coverImage
      thumbnail
      couponCode
      offerDetails
      status
    }
  }
`;

export const GET_ALL_OFFER = gql`
  query getAllOffer($filter: filter) {
    getAllOffer(filter: $filter) {
      data {
        _id
        title
        shortDescription
        expiryDate
        coverImage
        thumbnail
        couponCode
        offerDetails
        status
      }
      count
      page
      limit
    }
  }
`;
