import React from "react";
import AddEditModal from "./AddEditModal";
import EventsAndActivitiesTable from "../../../@core/components/tables/EventsAndActivitiesTable";

const EventsAndActivities = () =>
  localStorage.getItem("modules")?.includes("Glu Configuration") && (
    <EventsAndActivitiesTable AddEditModal={AddEditModal} />
  );

export default EventsAndActivities;
