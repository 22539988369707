import React, { useState, useEffect } from "react";
import { useGetPaginatedUserBookingsList } from "../../../api/dataSource";
import UserInfoTable from "./UserInfoTable";
import TablePagination from "@components/common/TablePagination";
import AddEditModal from "./AddEditModal";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import Select from "react-select";

const UserInfo = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [userBooking, setUserBooking] = useState(null);
  const [email, setEmail] = useState("");
  const [filter, setFilter] = useState(null);

  const { data, loading, refetch } = useGetPaginatedUserBookingsList(
    limit,
    page,
    {
      email: filter?.email ? filter?.email : "",
      fromDate: filter?.fromDate ? filter?.fromDate : null,
      toDate: filter?.toDate ? filter?.toDate : null,
      userBooking: filter?.userBooking ? filter?.userBooking : null,
    } 
  );

  const UserBookingList = [
    { label: "With Booking", value: "withBooking" },
    { label: "Without Booking", value: "withoutBooking" },
  ];

  const count = data?.getUsersAndBookings?.count;

  const handleSearch = () => {
    const data = {
      email,
      fromDate,
      toDate,
      userBooking: userBooking?.value,
    };
    setFilter(data);
    setPage(1);
  };

  const handleReset = () => {
    setEmail("");
    setFilter(null);
    setFromDate(null);
    setToDate(null);
    setUserBooking(null);
    setPage(1);
  };

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    if (data?.getUsersAndBookings?.data) {
      setTableData(data?.getUsersAndBookings?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getUsersAndBookings?.count / limit));
  }, [data, limit]);

  useEffect(() => {
    if (id && data?.getUsersAndBookings?.data) {
      let editData = data?.getUsersAndBookings?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getUsersAndBookings?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  return (
    localStorage.getItem("modules")?.includes("Website Users") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="fromDate">
                  Signup From Date
                </Label>
              </strong>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="toDate">
                  Signup To Date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
                placeholder="To Date"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="userBooking">
                <strong>User Booking Type</strong>
              </Label>
              <Select
                isClearable={true}
                id="userBooking"
                name="userBooking"
                value={userBooking}
                options={UserBookingList}
                onChange={(value) => {
                  setUserBooking(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="email">
                  Email
                </Label>
              </strong>
              <Input
                type="text"
                value={email}
                id="email"
                name="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>

            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        {loading ? (
          <h2 style={{ textAlign: "center", marginTop: "110px" }}>
            Please wait as this may take some time...
          </h2>
        ) : tableData.length > 0 ? (
          <>
            <UserInfoTable
              setId={setId}
              id={id}
              openModal={() => setOpenModal(!openModal)}
              data={tableData}
              count={count}
            />
            <TablePagination
              setPage={setPage}
              page={page}
              totalPage={totalPage}
            />
            <AddEditModal
              open={openModal}
              editData={editData}
              handleToggle={toggleModal}
              refetch={refetch}
            />{" "}
          </>
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "110px" }}>
            No Data Found
          </h2>
        )}
      </div>
    )
  );
};

export default UserInfo;
