import gql from "graphql-tag";

export const GET_PAGINATED_WALLET = gql`
  query getPaginatedWallet($filter: filter) {
    getPaginatedWallet(filter: $filter) {
      data {
        _id
        userId {
          _id
          fullName
          firstName
          lastName
          email
        }
        email
        transactionId
        amount
        reason
        description
        transactionDate
        refReservationId
        status
        logs
      }
      limit
      page
      count
    }
  }
`;

export const GET_ALL_WALLET = gql`
  query getAllWallet {
    getAllWallet {
      _id
      userId {
        _id
        fullName
        firstName
        lastName
        email
      }
      email
      transactionId
      amount
      reason
      description
      transactionDate
      refReservationId
      status
      logs
    }
  }
`;

export const GET_WALLET_BALANCE_REPORT = gql`
  query getWalletBalanceReport($filter: filter) {
    getWalletBalanceReport(filter: $filter) {
      data {
        amount
        fullName
        mobile
        email
        firstName
        lastName
        userId
      }
      limit
      page
      count
      totalamount
    }
  }
`;

export const GET_WALLET_BALANCE_REPORT_LIST = gql`
  query getAllWalletBalanceReport {
    getAllWalletBalanceReport {
      amount
      fullName
      mobile
      email
      firstName
      lastName
      userId
    }
  }
`;
