import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_GO_PURCHASE_REQUEST = gql`
  mutation CreateGOPurchaseRequest(
    $purchaseRequestInput: purchaseRequestInput
  ) {
    createGOPurchaseRequest(purchaseRequestInput: $purchaseRequestInput) {
      hostel {
        _id
      }
      urgency
    }
  }
`;

export const useCreateGoPurchaseRequest = () => {
  const [createGOPurchase, { loading, error, data, refetch }] = useMutation(
    CREATE_GO_PURCHASE_REQUEST
  );

  useLoadingHandler(loading);

  const createGOPurchaseHandler = (purchaseRequestInput) => {
    createGOPurchase({
      variables: {
        purchaseRequestInput,
      },
    });
  };

  return [createGOPurchaseHandler, { loading, error, data, refetch }];
};

export const UPDATE_GO_PURCHASE_REQUEST = gql`
  mutation UpdateGOPurchaseRequest(
    $id: ID
    $purchaseRequestInput: purchaseRequestInput
  ) {
    updateGOPurchaseRequest(
      id: $id
      purchaseRequestInput: $purchaseRequestInput
    ) {
      status
    }
  }
`;

export const useGOUpdateGoPurchaseRequest = () => {
  const [updateGoPurchase, { loading, error, data, refetch }] = useMutation(
    UPDATE_GO_PURCHASE_REQUEST
  );

  useLoadingHandler(loading);

  const updateGoPurchaseHandler = (id, purchaseRequestInput) => {
    updateGoPurchase({
      variables: { id, purchaseRequestInput },
    });
  };

  return [updateGoPurchaseHandler, { loading, error, data, refetch }];
};

export const GET_GO_PURCHASE_REQUEST_REPORT = gql`
  mutation getGOPurchaseRequestReport($filter: filter) {
    getGOPurchaseRequestReport(filter: $filter)
  }
`;

export const useGetGOPurchaseRequestReport = () => {
  const [getGOPurchaseRequestReportData, { loading, error, data, refetch }] =
    useMutation(GET_GO_PURCHASE_REQUEST_REPORT);
  useLoadingHandler(loading);
  const getGOPurchaseRequestReportDataHandler = (filter) => {
    
    getGOPurchaseRequestReportData({
      variables: {
        filter,
      },
    });
  };
  return [
    getGOPurchaseRequestReportDataHandler,
    { data, refetch, loading, error },
  ];
};
