import { Children } from "react";
import { Activity, Circle } from "react-feather";

const role = localStorage.getItem("role");

export default role === "Super Admin" || "Developer"
  ? [
      {
        id: "rateComparison",
        title: "Competitor Data",
        icon: <Activity size={20} />,
        badge: "light-warning",
        navLink: "/hostelComparisons",

        // Children: [
        //   {
        //     id: "competitorData",
        //     title: "Competitor Data",
        //     icon: <Circle size={12} />,
        //     navLink: "/hostelComparisons",
        //   },
        // ],
      },
    ]
  : [];
