import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const tableHeaderData = [
  "Hostel name",
  "Available bed nights",
  "Sold bed nights",
  "Occupancy",
  "Adr",
  "Revenue",
];

const OccupancyADRTable = ({ data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Occupancy ADR Report</CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <h6>{item?.name}</h6>
                  </td>

                  <td>
                    <h6>{item?.totalActiveRooms}</h6>
                  </td>

                  <td>
                    <h6>{item?.totalOccupiedRooms}</h6>
                  </td>

                  <td>
                    <h6> {item?.occupancy} </h6>
                  </td>

                  <td>
                    <h6>{item?.adr}</h6>
                  </td>

                  <td>
                    <h6>{item?.revenue}</h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default OccupancyADRTable;
