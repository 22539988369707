// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateStepOutProduct } from "../../../api/dataSource";
import { Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import ImageSection from "./ImageSection";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadType = [
  {
    value: "general",
    label: "General",
  },
];

const Inclusions = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);

  const [updateStepOutProductHandler, { data: updateStepOutProductData }] =
    useUpdateStepOutProduct();

  const dispatch = useDispatch();

  useEffect(() => {
    if (imageList.length === 0) {
      state?.tableData?.inclusions?.length &&
        setImageList(state?.tableData?.inclusions);
    }
  }, [imageList.length, state?.tableData?.inclusions]);

  useEffect(() => {
    let arrProcessedImages = [];
    imageList.forEach((i) => {
      let imageObj = {
        displayOrder: i.displayOrder,
        image: i.image,
        name: i.name,
      };
      arrProcessedImages.push(imageObj);
    });

    setSortedImages(
      arrProcessedImages.sort((a, b) => a.displayOrder - b.displayOrder)
    );
  }, [imageList]);

  useEffect(() => {
    if (updateStepOutProductData?.updateStepOutProduct?.title) {
      toaster("success", "Inclusion icons updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          inclusions: imageList,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateStepOutProductData?.updateStepOutProduct?.title]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const inclusions = imageList.map((image) => ({
      image: image.image,
      name: image?.name,
      displayOrder: image?.displayOrder,
    }));

    updateStepOutProductHandler(id, { inclusions });
  };

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={sortedImages}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default Inclusions;
