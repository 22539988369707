import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const CREATE_UNBOXCOUPON = gql`
mutation createUnboxCoupon($unboxCouponInput: unboxCouponInput){
    createUnboxCoupon(unboxCouponInput:$unboxCouponInput){
        couponTitle
    }
}
`

export const UPDATE_UNBOXCOUPON = gql`
mutation updateUnboxCoupon($id: ID,$unboxCouponInput: unboxCouponInput){
    updateUnboxCoupon(id: $id, unboxCouponInput: $unboxCouponInput){
        couponTitle
    }
}
`

export const DELETE_UNBOXCOUPON = gql`
mutation deleteUnboxCoupon($id: ID){
    deleteUnboxCouponById(id: $id)
}
`

export const useCreateUnboxCoupon = ()=>{
    const [createUnboxCoupon, {data, refetch , loading, error}] = useMutation(CREATE_UNBOXCOUPON);
    useLoadingHandler();

    const createUnboxCouponHandler = (unboxCouponInput)=>{
        createUnboxCoupon({
            variables:{
                unboxCouponInput
            }
        })
    }

    return [createUnboxCouponHandler, {data, refetch, loading, error}];
}

export const useUpdateUnboxCoupon = ()=>{
    const [updateUnboxCoupon, {data, refetch, loading, error}] = useMutation(UPDATE_UNBOXCOUPON);
    useLoadingHandler();

    const updateUnboxCouponHandler = (id, unboxCouponInput) => {
        updateUnboxCoupon({
            variables:{
                id, 
                unboxCouponInput
            }
        })
    }
    return [updateUnboxCouponHandler, {data, refetch, loading, error}];
}

export const useDeleteUnboxCoupon = ()=>{
    const [deleteUnboxCoupon, {data, refetch, loading, error}] = useMutation(DELETE_UNBOXCOUPON);
    useLoadingHandler();

    const deleteUnboxCouponHandler = (id)=>{
        deleteUnboxCoupon({
            variables:{
                id
            }
        })
    }

    return [deleteUnboxCouponHandler, {data, loading, refetch, loading}];
}