import { CheckCircle, User } from "react-feather";
import { Grid, Circle } from "react-feather";

const modules = JSON.parse(localStorage.getItem("modules"));
const role = localStorage.getItem("role");
const email = localStorage.getItem("email");

export default modules?.includes("Booking Dashboard") 
  ? [
      {
        id: "bookingDashboard",
        title: "Booking Dashboard",
        icon: <CheckCircle size={20} />,
        badge: "light-warning",
        navLink: "/bookingDashboard",
      },
    ]
  : [];

// export default [
//   {
//     id: "bookingDashboard",
//     title: "Booking Dashboard",
//     icon: <CheckCircle size={20} />,
//     badge: "light-warning",
//     navLink: "/bookingDashboard",
//   },
// ];
