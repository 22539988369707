import { Gift } from "react-feather";

// const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

// export default role === "Revenue" ||
// role === "Super Admin" ||
// role === "Sales Admin" ||
// role === "Marketing Admin" ||
// role === "Customer Experience" ||
// modules?.includes("Coupons")
export default modules?.includes("Coupon Admin")
  ? [
      {
        id: "coupons",
        title: "Coupons",
        icon: <Gift />,
        navLink: "/coupons",
      },
    ]
  : [];
