import React, { useState, useEffect } from "react";
import {
  useGetCorporateReportData,
  useGetHostelWiseDataForCorporateReport,
  useGetCxReputationReport,
} from "../../api/dataSource";
import { dateFormatter, addDays } from "@utils";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { CSVLink } from "react-csv";

const ORMCorporateReport = () => {
  const [reviewDateFrom, setReviewDateFrom] = useState(
    dateFormatter(new Date(new Date().setDate(1)))
  );

  const [reviewDateTo, setReviewDateTo] = useState(dateFormatter(new Date()));

  const [tableData, setTableData] = useState([]);

  // const { data } = useGetCorporateReportData(reviewDateFrom, reviewDateTo);

  // const { data: corporateReportData } = useGetHostelWiseDataForCorporateReport(
  //   reviewDateFrom,
  //   reviewDateTo
  // );

  // const { data: reputationReportData } = useGetCxReputationReport(
  //   null,
  //   reviewDateFrom,
  //   reviewDateTo
  // );

  const [filterFields, setFilterFields] = useState({
    reviewDateFrom,
    reviewDateTo,
  });

  const { data } = useGetCorporateReportData(
    filterFields.reviewDateFrom,
    filterFields.reviewDateTo
  );

  const { data: corporateReportData } = useGetHostelWiseDataForCorporateReport(
    filterFields.reviewDateFrom,
    filterFields.reviewDateTo
  );

  const { data: reputationReportData } = useGetCxReputationReport(
    null,
    filterFields.reviewDateFrom,
    filterFields.reviewDateTo
  );

  const handleSearch = () => {
    setFilterFields({
      reviewDateFrom,
      reviewDateTo,
    });
  };

  const refreshHandler = () => {
    setReviewDateFrom(dateFormatter(new Date(new Date().setDate(1))));
    setReviewDateTo(dateFormatter(new Date()));
    setFilterFields({
      reviewDateFrom: dateFormatter(new Date(new Date().setDate(1))),
      reviewDateTo: dateFormatter(new Date()),
    });
  };

  //useEffect for property wise leaderboard
  useEffect(() => {
    if (corporateReportData?.getHostelWiseDataForCorporateReport?.length > 0) {
      setTableData(corporateReportData?.getHostelWiseDataForCorporateReport);
    } else {
      setTableData([]);
    }
  }, [corporateReportData]);

  const cardStyle = {
    flex: "1 1 calc(25% - 20px)",
    margin: "10px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "15px",
  };

  const sideValueStyle = {
    fontSize: "30px",
    color: "#618054",
  };

  const brandScoreValueStyle = {
    fontSize: "30px",
    color: "#618054",
  };

  const otherValueStyle = {
    fontSize: "30px",
    color: "#618054",
  };

  const tableHeaderData = ["Source", "Reservations", "Reviews", "Review Rate"];

  const newData = [
    {
      source: "Google",
      reservations: reputationReportData?.getCxReputationReport?.googleReservs,
      reviews: reputationReportData?.getCxReputationReport?.googleReviews,
      reviewRate: reputationReportData?.getCxReputationReport?.googleReviewRate,
    },
    {
      source: "Goibibo",
      reservations: reputationReportData?.getCxReputationReport?.goibiboReservs,
      reviews: reputationReportData?.getCxReputationReport?.goibiboReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.goibiboReviewRate,
    },
    {
      source: "Booking.com",
      reservations:
        reputationReportData?.getCxReputationReport?.bookingComReservs,
      reviews: reputationReportData?.getCxReputationReport?.bookingComReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.bookingComReviewRate,
    },
    {
      source: "HostelWorld",
      reservations:
        reputationReportData?.getCxReputationReport?.hostelWorldReservs,
      reviews: reputationReportData?.getCxReputationReport?.hostelWorldReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.hostelWorldReviewRate,
    },
    {
      source: "MakeMyTrip",
      reservations:
        reputationReportData?.getCxReputationReport?.makemyTripReservs,
      reviews: reputationReportData?.getCxReputationReport?.makemyTripReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.makemyTripReviewRate,
    },
    {
      source: "TripAdvisor",
      reservations:
        reputationReportData?.getCxReputationReport?.tripadvisorReservs,
      reviews: reputationReportData?.getCxReputationReport?.tripadvisorReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.tripadvisorReviewRate,
    },
  ];

  const csvData = [
    [
      "Hostel Name",
      "Reviews",
      "ORM Score",
      "Positive Count",
      "Positive Percentage",
      "Negative Count",
      "Negative Percentage",
      "Neutral Count",
      "Neutral Percentage",
    ],
    ...tableData?.map((item) => [
      item?.hostelName,
      item?.reviewCount,
      item?.orm?.toFixed(1),
      item?.positiveCount,
      `${item?.positivePercentage?.toFixed(1)}%`,
      item?.negativeCount,
      `${item?.negativePercentage?.toFixed(1)}%`,
      item?.neutralCount,
      `${item?.neutralPercentage?.toFixed(1)}%`,
    ]),
  ];

  const reviewRateData = [
    ["Source", "Reservations", "Reviews", "Review Rate"],
    ...newData?.map((item) => [
      item?.source,
      item?.reservations,
      item?.reviews,
      `${item?.reviewRate?.toFixed(1)}%`,
    ]),
  ];

  const fixedRatings = {
    Google: 22.5,
    Goibibo: 12.5,
    "Booking.com": 12.5,
    HostelWorld: 12.5,
    MakeMyTrip: 12.5,
    TripAdvisor: 2.5,
  };

  const brandData = [
    [
      "Brand ORM",
      "Brand NPS",
      "Total Reviews",
      "Review Rate",
      "Positive",
      "Negative",
      "Neutral",
      "Positive rate",
      "Negative rate",
      "Neutral rate",
      "Booking.com",
      "Goibibo",
      "MakeMyTrip",
      "Hostel World",
      "Google",
      "Tripadvisor",
    ],
    ...(data?.getCorporateReportData ? [data.getCorporateReportData] : []).map(
      (item) => [
        `${item?.orm?.toFixed(2)}`,
        `${item?.nps?.toFixed(2)}`,
        `${item?.totalReviews?.toFixed(2)}`,
        `${item?.reviewRate?.toFixed(2)}%`,
        item?.positiveReviews,
        item?.negativeReviews,
        item?.neutralReviews,
        `${item?.positiveRate?.toFixed(2)}%`,
        `${item?.negativeRate?.toFixed(2)}%`,
        `${item?.neutralRate?.toFixed(2)}%`,
        `${item?.bookingComAvgRating?.toFixed(2)}`,
        `${item?.goibiboAvgRating?.toFixed(2)}`,
        `${item?.makeMyTripAvgRating?.toFixed(2)}`,
        `${item?.hostelWorldAvgRating?.toFixed(2)}`,
        `${item?.googleAvgRating?.toFixed(2)}`,
        `${item?.tripadvisorAvgRating?.toFixed(2)}`,
      ]
    ),
  ];

  const combinedData = [
    ["Brand Report"],
    ...brandData,
    ["", "", ""],
    ["Property wise Leaderboard    "],
    ...csvData,
    ["", "", ""],
    ["Review Rate"],
    ...reviewRateData,
  ];

  return (
    localStorage.getItem("modules")?.includes("ORM Reports") && (
      <div>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>Brand Report</h2>
              </div>
            </CardTitle>
          </CardHeader>
        </Card>

        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                Review Date - From
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={reviewDateFrom}
                onChange={(date) =>
                  setReviewDateFrom(dateFormatter(new Date(date)))
                }
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                Review Date - To
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={reviewDateTo}
                onChange={(date) =>
                  setReviewDateTo(dateFormatter(new Date(date)))
                }
                id="toDate"
                placeholder="To Date"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>

            <Button
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                refreshHandler();
              }}
            >
              Reset
            </Button>
          </Col>
          <CSVLink
            style={{
              backgroundColor: "#28C76F",
              color: "#FFFFFF",
              height: "40px",
              marginTop: "22px",
              marginLeft: "20px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="success"
            appearance="success"
            type="button"
            data={combinedData}
            filename={"Brand_Report_ORM.csv"}
          >
            Export
          </CSVLink>
        </Row>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <div style={cardStyle}>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <p style={brandScoreValueStyle}>
                {data?.getCorporateReportData?.orm?.toFixed(2)}
              </p>
            </div>
            <h4>Brand ORM</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <p style={brandScoreValueStyle}>
                {data?.getCorporateReportData?.nps?.toFixed(2)}
              </p>
            </div>
            <h4>Brand NPS</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "10px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.totalReviews}
              </p>
            </div>
            <h4>Total reviews</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "10px",
              }}
            >
              <p style={sideValueStyle}>
                {data?.getCorporateReportData?.reviewRate?.toFixed(2)}%
              </p>
            </div>
            <h4>Review rate</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.positiveReviews}
              </p>
            </div>
            <h4>Positive</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.negativeReviews}
              </p>
            </div>
            <h4>Negative</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.neutralReviews}
              </p>
            </div>
            <h4>Neutral</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={sideValueStyle}>
                {data?.getCorporateReportData?.positiveRate?.toFixed(2)}%
              </p>
            </div>
            <h4>Positive rate</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={sideValueStyle}>
                {data?.getCorporateReportData?.negativeRate?.toFixed(2)}%
              </p>
            </div>
            <h4>Negative rate</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={sideValueStyle}>
                {data?.getCorporateReportData?.neutralRate?.toFixed(2)}%
              </p>
            </div>
            <h4>Neutral rate</h4>
          </div>

          <div style={cardStyle}>
            <div
              style={{
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.bookingComAvgRating?.toFixed(2)}
              </p>
            </div>
            <img
              src={
                "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg"
              }
              alt={"Booking.com"}
              style={{
                width: "100px",
              }}
            />
          </div>

          <div style={cardStyle}>
            <div
              style={{
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.goibiboAvgRating?.toFixed(2)}
              </p>
            </div>
            <img
              src={
                "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg"
              }
              alt={"Goibibo"}
              style={{
                width: "80px",
              }}
            />
          </div>

          <div style={cardStyle}>
            <div
              style={{
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.makeMyTripAvgRating?.toFixed(2)}
              </p>
            </div>
            <img
              src={
                "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg"
              }
              alt={"MakeMyTrip"}
              style={{
                width: "80px",
              }}
            />
          </div>

          <div style={cardStyle}>
            <div
              style={{
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.hostelWorldAvgRating?.toFixed(2)}
              </p>
            </div>
            <img
              src={
                "https://static.thehosteller.com/Hostel/Media/hostelworld%202%20(1)-1695295577721.svg"
              }
              alt={"HostelWorld"}
              style={{
                width: "100px",
              }}
            />
          </div>

          <div style={cardStyle}>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.googleAvgRating?.toFixed(2)}
              </p>
            </div>
            <img
              src={
                "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg"
              }
              alt={"Google"}
              style={{
                width: "80px",
              }}
            />
          </div>

          <div style={cardStyle}>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <p style={otherValueStyle}>
                {data?.getCorporateReportData?.tripadvisorAvgRating?.toFixed(2)}
              </p>
            </div>
            <img
              src={
                "https://static.thehosteller.com/Hostel/Media/Tripadvisor%20(1)-1695295571996.svg"
              }
              alt={"Trip Advisor"}
              style={{
                width: "80px",
              }}
            />
          </div>
        </div>
        <br />
        <br />
        <div style={{ overflowX: "auto", overflowY: "auto" }}>
          <div
            style={{
              overflowX: "auto",
              maxHeight: "300px",
              marginBottom: "40px",
            }}
          >
            <Card>
              <CardHeader>
                <CardTitle>Property wise Leaderboard</CardTitle>
              </CardHeader>

              <Table striped bordered>
                <thead
                  className="text-center"
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  <tr>
                    <th style={{ whiteSpace: "nowrap" }}>Hostel Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Reviews</th>
                    <th style={{ whiteSpace: "nowrap" }}>ORM Score</th>
                    <th style={{ whiteSpace: "nowrap" }}>Positive Count</th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      Positive Percentage
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>Negative Count</th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      Negative Percentage
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>Neutral Count</th>
                    <th style={{ whiteSpace: "nowrap" }}>Neutral Percentage</th>
                  </tr>
                </thead>
                <tbody
                  className="text-center"
                  style={{ overflowY: "auto", maxHeight: "400px" }}
                >
                  {tableData?.map((item) => (
                    <tr key={item?.hostelId}>
                      <td>{item?.hostelName}</td>
                      <td>{item?.reviewCount}</td>
                      <td
                        style={{ color: item?.orm < 85.0 ? "red" : "inherit" }}
                      >
                        {item?.orm?.toFixed(1)}
                      </td>
                      <td>{item?.positiveCount}</td>
                      <td>{item?.positivePercentage?.toFixed(1) + "%"}</td>
                      <td>{item?.negativeCount}</td>
                      <td>{item?.negativePercentage?.toFixed(1) + "%"}</td>
                      <td>{item?.neutralCount}</td>
                      <td>{item?.neutralPercentage?.toFixed(1) + "%"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>

          <div>
            <Card>
              <CardHeader>
                <CardTitle>
                  <div>
                    <h2>Review Rate</h2>
                  </div>
                </CardTitle>
              </CardHeader>

              <Table striped bordered>
                <thead className="text-center">
                  <tr>
                    {tableHeaderData.map((item, index) => (
                      <th key={index}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {newData?.map((item, index) => {
                    const reviewRate = item?.reviewRate?.toFixed(2);
                    const fixedRating = fixedRatings[item?.source];
                    const isBelowFixedRating = reviewRate < fixedRating;

                    return (
                      <tr className="text-center" key={index}>
                        <td>
                          <b>{item?.source}</b>
                        </td>
                        <td>{item?.reservations}</td>
                        <td>{item?.reviews}</td>
                        <td
                          style={{
                            color: isBelowFixedRating ? "red" : "black",
                          }}
                        >
                          {reviewRate}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </div>
      </div>
    )
  );
};

export default ORMCorporateReport;
