import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const GET_PAGINATED_BLACKLISTED_GUESTS = gql`
  query getPaginatedBlacklistedGuests($filter: filter) {
    getPaginatedBlacklistedGuests(filter: $filter) {
      page
      limit
      count
      data {
        _id
        hostel {
          _id
          name
        }
        reservationID
        name
        email
        mobile
        documentID
        reason
      }
    }
  }
`;

export const useGetPaginatedBlacklistedGuests = (limit, page, filterFeilds) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_BLACKLISTED_GUESTS,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFeilds,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
