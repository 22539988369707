import { useState, useEffect } from "react";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Button,
  FormGroup,
  Table,
} from "reactstrap";

export default function OtModal(props) {
  const {
    showModal,
    onClose,
    data,
    arrAttendance,
    setArrAttendance,
    selectedMonth,
    selectedYear,
  } = props;

  const [selectedDate, setSelectedDate] = useState(new Date().getDate());
  const [selectedOT, setSelectedOT] = useState(0);
  const [selectedDateDisplay, setSelectedDateDisplay] = useState(new Date());
  const [totalOverTime, setTotalOverTime] = useState(0);

  const OtOptions = [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ];
  const handleClose = () => {
    onClose(!showModal);
    setSelectedDate(new Date().getDate());
    setSelectedDateDisplay(new Date());
    setSelectedOT(0);
    setTotalOverTime(0);
  };

  useEffect(() => {
    const sum = data.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.overTime;
    }, 0);

    if (sum > 0) {
      setTotalOverTime(sum);
    }
  }, [data]);

  const handleProcessOT = (selectedDate, selectedOT) => {
    try {
      if (selectedDate) {
        let selectedObject = arrAttendance.find(
          (i) => i.customId === `emp-${data[0]?.empId}-${selectedDate}`
        );
        if (selectedObject) {
          selectedObject.overTime = selectedOT;
          const filteredAttendance = arrAttendance.filter(
            (i) => i.customId !== `emp-${data[0]?.empId}-${selectedDate}`
          );
          filteredAttendance.push(selectedObject);
          setArrAttendance(filteredAttendance);
          handleClose();
        } else {
          console.error("Object not found");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const removedNullOT = data?.filter(
      (i) => i.overTime !== null && i.overTime !== 0
    );
    setFilteredData(removedNullOT);
  }, [data]);

  const currentDate = new Date();
  const passedDate = new Date();
  passedDate.setMonth(selectedMonth - 1);
  passedDate.setFullYear(selectedYear);

  const isCurrentMonthOT =
    currentDate.toLocaleDateString("en-CA") ===
    passedDate.toLocaleDateString("en-CA")
      ? true
      : false;

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={handleClose}
      ></ModalHeader>
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <div className="text-center mb-2">
          <h3>Add OT</h3>
        </div>
        <Row tag="form" className="gy-1 pt-75">
          <Col xs={6}>
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>OT Date</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="otDate"
                placeholder="Select Date"
                options={{
                  minDate:
                    localStorage.getItem("role") === "HR"
                      ? null
                      : new Date(new Date().toLocaleDateString("en-CA")),
                  maxDate:
                    localStorage.getItem("role") === "HR" ? null : new Date(),
                }}
                value={selectedDateDisplay}
                onChange={(date) => {
                  if (localStorage.getItem("role") === "HR") {
                    setSelectedDateDisplay(date[0]);
                    setSelectedDate(date[0].getDate());
                  }
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup className="w-100">
              <Label for="Status">
                <strong>Select Hours</strong>
              </Label>
              <Select
                id="otHours"
                name="otHours"
                options={OtOptions}
                required
                classNamePrefix="select"
                onChange={(selectedOption) => {
                  if (selectedDate) {
                    setSelectedOT(Number(selectedOption.value));
                  } else {
                    alert("Please select date first");
                  }
                }}
              />
            </FormGroup>
          </Col>

          {filteredData?.length !== 0 && (
            <Col xs={12}>
              <Table responsive size="sm" hover>
                <thead>
                  <tr>
                    <td>Date</td>
                    {filteredData
                      .sort((a, b) => a.attendanceDate - b.attendanceDate)
                      .map((i, index) => {
                        return (
                          <th key={index} className="text-center">
                            {new Date(i?.attendanceDate).toLocaleDateString(
                              "en-IN"
                            )}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Overtime in hours</td>
                    {filteredData
                      .sort((a, b) => a.attendanceDate - b.attendanceDate)
                      .map((i, index) => {
                        return (
                          <td key={index} className="text-center">
                            {i?.overTime ? i?.overTime : 0}
                          </td>
                        );
                      })}
                  </tr>
                </tbody>
              </Table>
              <strong className="d-block pt-2">
                Total Overtime (In Hours) : {totalOverTime}
              </strong>
            </Col>
          )}

          <Col xs={12} className="text-center mt-2">
            <FormGroup className="w-100">
              <Button.Ripple
                type="button"
                color={`${
                  !isCurrentMonthOT && localStorage.getItem("role") !== "HR"
                    ? `secondary`
                    : `light`
                }`}
                disabled={
                  !isCurrentMonthOT && localStorage.getItem("role") !== "HR"
                    ? true
                    : false
                }
                onClick={(e) => {
                  if (
                    !isCurrentMonthOT &&
                    localStorage.getItem("role") !== "HR"
                  ) {
                    console.log("button ko disable kr do");
                  } else {
                    e.preventDefault();
                    handleProcessOT(selectedDate, selectedOT);
                  }
                }}
              >
                Submit
              </Button.Ripple>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
