import React, { useEffect, useState } from "react";
import ProductDetail from "./ProductDetail";
import CmsTabs from "@components/Cms/CmsTabs";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Images from "./Images";
import Items from "./Items";
import { useGetProductDetailsById } from "../../../api/MerchandiseStore/Queries";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { tableDataHandler } from "@store/Cms/cms.action";

const tabOptions = [
  {
    id: 0,
    title: "Product detail",
    formComponent: <ProductDetail />,
  },
  // {
  //   id: 1,
  //   title: "Product Images",
  //   formComponent: <Images />,
  // },
  {
    id: 2,
    title: "Items",
    formComponent: <Items />,
  },
];
const MerchandiseProductTabs = () => {
  const [active, setActive] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data, refetch } = useGetProductDetailsById(id);
  console.log(data);
  useEffect(() => {
    if (data?.getMerchandiseProductDetailsById) {
      dispatch(tableDataHandler(data?.getMerchandiseProductDetailsById));
    }
    refetch();
  }, [data, dispatch]);
  const handleRenderChildFormComponent = () => {
    const fountActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (fountActiveTabWithRenderChildFormComp) {
      return fountActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };
  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>
                  {active === 0 &&
                    "Products" &&
                    tabOptions.find((item) => item.id === active).title}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => {
          return (
            <Row key={id}>
              <Col>
                <Card>
                  <CardHeader>
                    <CardTitle>{title}</CardTitle>
                  </CardHeader>
                  <CardBody>{formComponent}</CardBody>
                </Card>
              </Col>
            </Row>
          );
        })
      )}
    </div>
  );
};

export default MerchandiseProductTabs;
