import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import InclusionsIconsImageSection from "./InclusionsIconsImageSection";
import { useUpdateEventsAndActivities } from "../../../api/dataSource";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useParams } from "react-router-dom";

const UploadType = [{ value: "desktop", label: "Desktop" }];

const InclusionsIconsForm = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);
  const [updateEventsAndActivitiesHandler, { data, reset }] =
    useUpdateEventsAndActivities();
  const dispatch = useDispatch();

  useEffect(() => {
    if (imageList.length === 0) {
      state?.tableData &&
        state?.tableData?.inclusionsIcons?.length &&
        setImageList(state?.tableData?.inclusionsIcons);
    }
  }, [imageList.length, state?.tableData?.inclusionsIcons]);

  useEffect(() => {
    let arrProcessedImages = [];
    imageList.forEach((i) => {
      let imageObj = {
        // type: i.type,
        imagePath: i.imagePath,
        // key: i.key,
        imageTitle: i.imageTitle,
        displayOrder: i.displayOrder,
      };
      arrProcessedImages.push(imageObj);
    });

    setSortedImages(
      arrProcessedImages.sort((a, b) => a.displayOrder - b.displayOrder)
    );
  }, [imageList]);

  // useEffect(() => {
  //   if (data?.updateEventsAndActivities?.inclusionsIcons?.imageTitle) {
  //     // toaster("success", "Submitted successfully");

  //     dispatch(
  //       tableDataHandler({
  //         ...state?.tableData,
  //         inclusionsIcons: imageList,
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, data?.updateEventsAndActivities?.title]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];
    imageList.map((image) => {
      image.imagePath &&
        images.push({
          imagePath: image.imagePath,
          imageTitle: image.imageTitle,
          displayOrder: parseInt(image.displayOrder),
        });
    });
    updateEventsAndActivitiesHandler(id, { inclusionsIcons: images });
    toaster("success", "Inclusion icons updated successfully");

    dispatch(
      tableDataHandler({
        ...state?.tableData,
        inclusionsIcons: imageList,
      })
    );
  };
  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <InclusionsIconsImageSection
            imageList={sortedImages}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default InclusionsIconsForm;
