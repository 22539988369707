import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { DragDrop } from "@uppy/react";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import thumbnailFeaturedImageGenerator from "@uppy/thumbnail-generator";
import Uppy from "@uppy/core";
import { useUpdateStepoutBlog, useUploadImage } from "../../../api/dataSource";

const FeaturedImage = () => {
  const { id } = useParams();
  const [featuredImage, setFeaturedImage] = useState([]);
  const state = useSelector((state) => state?.cms);
  const [featuredUploadImageHandler, { data: featuredImageData }] =
    useUploadImage();

  const dispatch = useDispatch();

  const uppyFeaturedImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  const [updateStepoutBlogHandler, { data: updatedStepoutBlogData }] =
    useUpdateStepoutBlog();

  useEffect(() => {
    if (state.tableData) {
      setFeaturedImage([state.tableData?.featuredImage]);
    } else {
      setFeaturedImage([]);
    }
  }, [state.tableData]);

  uppyFeaturedImage.use(thumbnailFeaturedImageGenerator);
  uppyFeaturedImage.on("thumbnail:generated", (file, preview) => {
    setFeaturedImage([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyFeaturedImage.removeFile(file.id);
      return;
    }

    featuredUploadImageHandler(file.data);
    toaster("success", "Feature image uploaded successfully");
  });

  uppyFeaturedImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading featured image: ${error.message}`);
  });

  const FeaturedImageRenderPreview = () => {
    if (featuredImage.length) {
      return featuredImage.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };

  const [featuredImageURL, setFeaturedImageURL] = useState(
    state?.tableData?.featuredImage
  );

  useEffect(() => {
    if (featuredImageData) {
      setFeaturedImageURL(featuredImageData?.uploadImage?.url);
    }
  }, [featuredImageData]);

  useEffect(() => {
    if (updatedStepoutBlogData?.updateStepoutBlog?.blogTitle) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          featuredImage: featuredImageURL,
        })
      );
    }
  }, [dispatch, featuredImageURL, updatedStepoutBlogData]);

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      featuredImage:
        featuredImageURL === ""
          ? state?.tableData?.featuredImage
          : featuredImageURL,
    };
    if (id) {
      updateStepoutBlogHandler(id, data);
      toaster("success", "Featured image updated successfully");
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <Label className="form-label" htmlFor="location">
            Featured Image
          </Label>

          <DragDrop
            id={2}
            note="Image size should be less than 500KB"
            uppy={uppyFeaturedImage}
          />

          {FeaturedImageRenderPreview()}
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default FeaturedImage;
