import React, { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import { useAddCancelConfig, useUpdateCancelConfig } from "@api";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import Select from "react-select";

const DURATIONS = {
  SEVEN: { LABEL: "1 - 7", VALUE: 1 },
  EIGHT: { LABEL: "8+", VALUE: 8 },
};

const durationList = [
  { label: DURATIONS.SEVEN.LABEL, value: DURATIONS.SEVEN.VALUE },
  { label: DURATIONS.EIGHT.LABEL, value: DURATIONS.EIGHT.VALUE },
];

const typeList = [{ label: "Hostel", value: "Hostel" }];

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [formObject, setFormObject] = useState({});
  const [duration, setDuration] = useState(null);
  const [type, setType] = useState({ label: "Hostel", value: "Hostel" });

  const [addCancelConfigHandler, { data }] = useAddCancelConfig();
  const [updateCancelConfigHandler, { data: updatdedData }] =
    useUpdateCancelConfig();

  useEffect(() => {
    if (
      data?.createCancelConfig?.success ||
      updatdedData?.updateCancelConfig?._id
    ) {
      refetch();
      handleToggle();

      toaster("success", "Rules applied successfully");
    }
  }, [
    data?.createCancelConfig?.success,
    updatdedData?.updateCancelConfig?._id,
  ]);

  useEffect(() => {
    if (editData) {
      const { deduction, minDay, message, status, type } = editData;

      setDuration(durationList.find((item) => item.value === minDay));

      setFormObject({ deduction: deduction.toString(), message, status, type });
    } else {
      setFormObject({});
      setDuration(null);
    }
  }, [editData]);

  const onChange = (e) =>
    setFormObject((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const submitDataHandler = () => {
    if (formObject.deduction < 0 || formObject.deduction > 100)
      return toaster("error", "Invalid percentage value! (0 - 100)");

    if (
      formObject.deduction &&
      formObject.message &&
      duration?.value &&
      type?.value
    ) {
      let sendData = {
        ...formObject,
        deduction: parseInt(formObject.deduction),
        minDay: duration.value,
        maxDay: duration.label === DURATIONS.SEVEN ? 7 : 9999,
        type: type.value,
      };

      if (editData) {
        updateCancelConfigHandler(editData?._id, sendData);
      } else {
        addCancelConfigHandler(sendData);
      }
      setFormObject({});
      setDuration(null);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label for="type">Type</Label>
              <Select
                isClearable={false}
                id="type"
                name="type"
                value={type}
                options={typeList}
                onChange={(value) => setType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="message">
                Message
              </Label>
              <Input
                type="text"
                value={formObject.message || ""}
                id="message"
                name="message"
                placeholder="Add Message"
                onChange={onChange}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label for="duration">Duration (Days)</Label>
              <Select
                isClearable={false}
                id="duration"
                name="duration"
                isDisabled={editData}
                value={duration}
                options={durationList}
                onChange={(value) => setDuration(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="deduction">
                Deduction (%)
              </Label>
              <InputGroup className="mb-2">
                <Input
                  type="number"
                  value={formObject.deduction || ""}
                  min="0"
                  max="100"
                  id="deduction"
                  name="deduction"
                  placeholder="Add Deduction"
                  onChange={onChange}
                />
                <InputGroupText className="rounded-0 bg-primary">
                  %
                </InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>

          <Col sm="12">
            <SwitchIcon
              checked={formObject.status || false}
              id="status"
              name="status"
              label="Status : "
              handleChecked={(val) =>
                setFormObject((prev) => ({ ...prev, status: val }))
              }
            />
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
