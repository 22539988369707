import React, { useEffect, useState } from "react";
import {
  useGetItemwiseSalesReport,
  useGetItemwiseSalesReportExport,
} from "../../../api/Unbox/UnboxSalesReport/Queries";
import toaster from "@components/common/Toaster";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { selectThemeColors } from "@utils";

import Report from "./Report";
import {
  useGetAllCafeMasterList,
  useGetCafeByHostelId,
} from "../../../api/Unbox/UnboxCafeMaster/Queries";
import { useGetCafewiseMenuActiveForItemwise } from "../../../api/Unbox/UnboxCafewise/Queries";
const { dateFormatter } = require("../../../utility/Utils");

const filterList = [
  { label: "Quanity Sold", value: "quantity" },
  { label: "Price", value: "price" },
];
const ItemwiseSales = () => {
  const hostelId = JSON.parse(localStorage.getItem("hostels")) || [];
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [cafe, setCafe] = useState(null);
  const [searchFromDate, setSearchFromDate] = useState(null);
  const [searchToDate, setSearchToDate] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [searchCafe, setSearchCafe] = useState(null);
  const [cafelist, setCafelist] = useState(null);
  const [value, setValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [exportData, setExportData] = useState(null);
  const { data: cafes } = useGetCafeByHostelId(hostelId);

  const { data: cafeListData } = useGetAllCafeMasterList();

  const { data } = useGetItemwiseSalesReport(
    searchFromDate,
    searchToDate,
    searchCafe,
    searchValue
  );
  const { data: menuActiveData } = useGetCafewiseMenuActiveForItemwise(searchCafe)
  const { data: Data } = useGetItemwiseSalesReportExport(exportData);
  useEffect(() => {
    if (data?.getItemwiseSales?.length > 0) {
      setTableData(data?.getItemwiseSales);
    } else if (data?.getItemwiseSales?.length === 0) {
      setTableData([]);
    }

  }, [data?.getItemwiseSales]);

  useEffect(() => {
    if (hostelId?.length !== 0 && cafes?.getCafeByHostelId?.length) {
      const cafeArray = cafes?.getCafeByHostelId?.map((cafe) => {
        return { label: cafe?.cafeName, value: cafe?._id };
      });

      setCafelist(cafeArray);
    } else {
      const cafeArray = cafeListData?.getAllCafeMasterList?.map((cafe) => {
        return { label: cafe?.cafeName, value: cafe?._id };
      });

      setCafelist(cafeArray);
    }
  }, [cafes?.getCafeByHostelId, cafeListData, hostelId?.length]);

  useEffect(() => {
    if (Data?.getItemwiseSalesExportReport?.data?.length > 0) {
      const byteArray = new Uint8Array(
        Data?.getItemwiseSalesExportReport?.data
      );
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `unbox_Itemwise_SalesReport.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setExportData(null);
    }
  }, [Data]);

  const handleDateRange = (value, name) => {
    try {
      if (name === "fromDate") {
        setFromDate(dateFormatter(new Date(value)));
      } else if (name === "toDate") {
        setToDate(dateFormatter(new Date(value)));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearch = () => {
    // const fromDateObj = new Date(fromDate);
    // const toDateObj = new Date(toDate);

    // const fromDatePlus12Months = new Date(fromDateObj);
    // fromDatePlus12Months.setMonth(fromDatePlus12Months.getMonth() + 12);

    // if (toDateObj < fromDatePlus12Months && toDateObj >= fromDateObj) {
    if (fromDate && toDate && new Date(fromDate) <= new Date(toDate)) {
      setSearchFromDate(fromDate);
      setSearchToDate(toDate);
      // if (cafe?.length > 0) {
      // setSearchCafe(cafe?.map((i) => i?.value));
      if (cafe?.length > 0) {
        setSearchCafe(cafe?.map((i) => i?.value));
      }
      if (value?.value) {
        setSearchValue(value?.value);
      } else {
        setSearchValue(null);
      }
    }
    // } else {
    //   setSearchCafe(null);
    // }
    else {
      toaster(
        "error",
        "Please select date range with start date should be less than end date"
      );
    }
  };

  const handleExport = () => {
    if (data?.getItemwiseSales?.length > 0) {
      setExportData(data?.getItemwiseSales);
    } else {
      toaster("error", "No data to export");
    }
  };

  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setTableData(null);
    setSearchFromDate(null);
    setSearchToDate(null);
    setSearchCafe(null);
    setSearchValue(null);
    setCafe(null);
    setValue(null);
    setExportData(null);
  };

  return (
    <div>
      <Row>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              <strong>Start Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromDate}
              onChange={(date) => {
                handleDateRange(date, "fromDate");
                //setFromDate(dateFormatter(new Date(date)));
              }}
              id="fromDate"
              placeholder="From Date"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="toDate">
              <strong>End Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toDate}
              onChange={(date) => {
                //setToDate(dateFormatter(new Date(date)));
                handleDateRange(date, "toDate");
              }}
              id="toDate"
              placeholder="To Date"
            />
          </FormGroup>
        </Col>
        {cafelist?.length !== 1 && (
          <Col sm="3" style={{ zIndex: 3 }}>
            <FormGroup className="w-100">
              <Label className="form-label" for="cafe">
                <strong>Cafe</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="cafe"
                name="cafe"
                value={cafe}
                options={cafelist}
                closeMenuOnSelect={false}
                onChange={(value) => {
                  setCafe(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        )}
        <Col sm="3" style={{ zIndex: 3 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="value">
              <strong>Sort</strong>
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              theme={selectThemeColors}
              id="value"
              name="value"
              value={value}
              options={filterList}
              onChange={(value) => {
                setValue(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col className="" sm="3" style={{ display: "flex", gap: "30px" }}>
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              //marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            style={{
              backgroundColor: "##ff9999",
              height: "40px",
              marginTop: "22px",
              //marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="success"
            appearance="success"
            type="button"
            onClick={handleExport}
          >
            Export
          </Button>
        </Col>
      </Row>
      {tableData?.length > 0 ? (
        <div style={{ overflowY: "auto" }}>
          <Report
            itemwiseData={tableData}
            fromDate={searchFromDate}
            toDate={searchToDate}
            menuActiveData={menuActiveData}
          />
        </div>
      ) : tableData?.length === 0 ? (
        <h5 style={{ textAlign: "center" }}>No orders</h5>
      ) : (
        <h5 style={{ textAlign: "center" }}>Please select Date Range</h5>
      )}
    </div>
  );
};

export default ItemwiseSales;
