import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";

const CapexSubHeadsSubFields = ({ setDataToSubmit, editData }) => {
  const [subHeadType, setSubHeadType] = useState(null);

  const CapexSubFieldsType = [
    { label: "Civil", value: "Civil" },
    { label: "Furniture", value: "Furniture" },
    { label: "Furnishing", value: "Furnishing" },
    { label: "Fabrication", value: "Fabrication" },
    { label: "Electrical", value: "Electrical" },
    { label: "Electronics/Equipments", value: "Electronics/Equipments" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );
  }, [editData]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
    };
    setDataToSubmit(data);
  }, [subHeadType]);

  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="subHeadType">
            Capex Type*
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={CapexSubFieldsType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            isDisabled={editData?.subFields?.subHeadType}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default CapexSubHeadsSubFields;
