// ! Coded by Ashwin

import gql from "graphql-tag";

export const GET_PAGINATED_STAFF_LIST = gql`
  query getPaginatedStaffList($filter: filter) {
    getPaginatedStaffList(filter: $filter) {
      count
      page
      limit
      data {
        _id
        hostel
        staffName
        staffEmail
        staffPhoneNumber
        staffRole
        staffPassword
      }
    }
  }
`;

//Added by Nitish
export const GET_HOSTEL_NAMES_BY_STAFF_EMAIL = gql`
  query getHostelNamesByStaffEmail($filter: filter) {
    getHostelNamesByStaffEmail(filter: $filter) {
      data {
        hostel
      }
    }
  }
`;
