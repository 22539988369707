import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_GO_PAYMENT_REQUEST = gql`
  query GetPaginatedPaymentRequest($filter: filter) {
    getPaginatedPaymentRequest(filter: $filter) {
      page
      limit
      count
      totalAmountSum
      totalPaidSum
      data {
        _id
        requestID
        hostel {
          _id
          name
        }
        head
        subHead
        severity
        subFields {
          subHeadType
        }
        purpose
        vendorName
        amount
        gst
        totalAmount
        bankAccountNumber
        ifscCode
        bankType
        bankDetailImage {
          imageURL
        }
        poAttachment {
          imageURL
        }
        invoiceAttachment {
          imageURL
        }
        status
        amRemark
        rmRemark
        ceoRemark
        paymentDueTime

        invoicePending
        invoicePendingRemark
        createdAt
        logs
        paymentDetails {
          amountToPay
          dueDate
          paymentStatus
          paymentDate
          bankRefNumber
          amountPaid
          tdsAmount
          financeTeamRemark
        }
        financePaymentAttachment {
          imageURL
        }
      }
    }
  }
`;

export const useGetPaginatedGoPaymentRequest = (limit, page, filterFeilds) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_GO_PAYMENT_REQUEST,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFeilds,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
