import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const FeedbackHostelWiseAvgTat = ({ updateByData: data }) => {
  // for table
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col sm="6">
        <Card>
          <CardHeader>
            <CardTitle>Closure time analysis</CardTitle>
          </CardHeader>
          <div>
            <Table responsive>
              <thead>
                <tr>
                  <td>
                    <h5>Updated By: </h5>
                  </td>
                  <td>
                    <h5>Total Feedback Closed</h5>
                  </td>
                  <td>
                    <h5>Average TAT (in hours)</h5>
                  </td>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item) => (
                  <tr key={uuidv4()}>
                    <td>{item?._id}</td>
                    <td>{item?.closed}</td>
                    <td>{item?.avgTAT.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default FeedbackHostelWiseAvgTat;
