import React from "react";
//import SlideModal from "../../../@core/components/common/slide-modal";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import styled from "styled-components";
const LogModal = ({ open, handleToggle, soldOutLog, children, large }) => {
  return (
    <div>
      {/* <SlideModal open={open} handleToggle={handleToggle}>
        <div>
          {soldOutLog?.length > 0 &&
            soldOutLog?.map((log, i) => {
              return (
                <li key={i} style={{ marginTop: "2px", padding: "2px" }}>
                  {log}
                </li>
              );
            })}
        </div>
      </SlideModal> */}
      <StyledModalWrap
        unmountOnClose={true}
        isOpen={open}
        toggle={handleToggle}
        className={large ? "ml-auto m-0 modal-lg" : "ml-auto m-0"}
      >
        <ModalHeader toggle={handleToggle}>Logs</ModalHeader>
        <ModalBody>
          {soldOutLog?.length > 0 ? (
            soldOutLog
              ?.slice()
              ?.reverse()
              ?.map((log, i) => {
                return (
                  <li key={i} style={{ marginTop: "2px", padding: "2px" }}>
                    <span>{log}</span>
                  </li>
                );
              })
          ) : (
            <span>No logs available</span>
          )}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </StyledModalWrap>
    </div>
  );
};

const StyledModalWrap = styled(Modal)`
  .modal-content {
    min-height: 100vh;
  }
  .modal-header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    .close {
      margin: 0;
    }
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background-color: #f8f8f8;
  }
`;

export default LogModal;
