// competitorRates

import CompetitorRatesComponent from "../../views/CompetitorRates";


const CompetitorRates = [
  {
    path: "/competitorRates",
    component: CompetitorRatesComponent,
    exact: true,
  },
//   {
//     path: "/competitorRates/messageConfiguration",
//     component: MessageConfiguration,
//     exact: true,
//   }
];

export default CompetitorRates;
