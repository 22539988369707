//Added By Gaurav
import gql from "graphql-tag";

export const GET_HOTELS_FOR_HOTEL_CENTER = gql`
query {
    getHostelsForHotelCenter {
        id
        hotelCenterId
        name
        addr1
        addr2
        city
        province
        postal_code
        country
        latitude
        longitude
        category
        hotel_brand
        phone
        star_rating
        alternate_hotel_id
    }
}
`;
