import { LoaderActionTypes } from "./loader.actionTypes";

const initialState = {
  loading: false,
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoaderActionTypes.START_LOADING:
      return { ...state, loading: true };
    case LoaderActionTypes.STOP_LOADING:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default loaderReducer;
