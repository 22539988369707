import React, { useEffect, useState } from "react";
import { Edit, Image, MoreHorizontal } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { dateFormatterDDMMYYYY } from "@utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Transaction Date",
  "Entry Date",
  "Opening Balance",
  "Total Amount",
  "Closing Balance",
  "Head",
  "Sub Head",
  "RESERVATION ID",
  "Type",
  "Hostel Name",
  // "Amount",
  // "GST",
  "Payment Mode",
  "Invoice Image",
];

const FinanceTable = ({
  cashInOpenModal,
  cashOutOpenModal,
  setId,
  data,
  remainingBalance,
}) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const headerStyle = {
    fontSize: "12px",
    whiteSpace: "nowrap",
    textAlign: "center",
  };

  const handleImagePopup = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      images.forEach((image) => {
        window.open(
          image.image,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=600,height=400"
        );
      });
    } else if (typeof images === "string") {
      window.open(
        images,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=600,height=400"
      );
    }
  };

  const highlightRowStyle = {
    // backgroundColor: "#ff4d4d",
    backgroundColor: "#FF3131",
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              {" "}
              <div
                className=""
                style={{
                  marginLeft: "2px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h2>Current closing balance</h2>
                <Badge
                  color={remainingBalance >= 0 ? "dark" : "danger"}
                  style={{
                    marginLeft: "10px",
                    fontSize: "1rem",
                    marginBottom: "4px",
                  }}
                >
                  {typeof remainingBalance === "string"
                    ? parseFloat(remainingBalance).toFixed(0)
                    : remainingBalance}
                </Badge>
              </div>
            </CardTitle>

            <CardHeader className="d-flex justify-content-between align-items-center px-3">
              <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  cashInOpenModal();
                }}
              >
                {/* Cash In */}
                Cash In Entry
              </Button.Ripple>
              <div className="mx-2"></div>
              <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  cashOutOpenModal();
                }}
              >
                {/* Cash Out  */}
                Cash Out Entry
              </Button.Ripple>
            </CardHeader>
          </CardHeader>

          <Table size="sm" responsive className="table-striped table-bordered">
            <thead style={headerStyle}>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody style={headerStyle}>
              {tableData?.map((element, i) => (
                <tr
                  key={uuidv4()}
                  style={
                    element.invoicePendingStatusCheck ? highlightRowStyle : {}
                  }
                >
                  {element?.entryType === "Cash In" && (
                    <td>
                      <Button
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(element._id);
                          cashInOpenModal();
                        }}
                        title="You can update entries only within 24 hours of creation."
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                  )}

                  {element?.entryType === "Cash Out" && (
                    <td>
                      <Button
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(element._id);
                          cashOutOpenModal();
                        }}
                        title="You can update entries only within 24 hours of creation."
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                  )}
                  <td>
                    {dateFormatterDDMMYYYY(element?.entryDate)}{" "}
                    {/* {element?.entryTime} */}
                  </td>

                  <td>
                    {dateFormatterDDMMYYYY(element?.createdAt)}{" "}
                    {new Date(element?.createdAt).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </td>

                  <td>
                    <Badge
                      style={{ backgroundColor: "#FFA500", color: "white" }}
                    >
                      {element?.openingBalance?.toFixed(0)}
                    </Badge>
                  </td>

                  <td>
                    <Badge
                      color={
                        element?.entryType === "Cash In" ? "success" : "danger"
                      }
                    >
                      {" "}
                      {element?.totalAmount?.toFixed(0)}
                    </Badge>
                  </td>

                  <td>
                    <Badge
                      style={{ backgroundColor: "#4169E1", color: "white" }}
                    >
                      {element?.closingBalance?.toFixed(0)}
                    </Badge>
                  </td>

                  <td>
                    {element?.head?.length > 1
                      ? element?.head.map((i, index) =>
                          index === element?.head.length - 1 ? `${i}` : `${i}, `
                        )
                      : element.head}
                  </td>
                  <td>{element.subHead}</td>

                  <td>{element?.reservationId}</td>

                  <td>
                    <Badge
                      color={
                        element?.entryType === "Cash In" ? "success" : "danger"
                      }
                    >
                      {element?.entryType}
                    </Badge>
                  </td>

                  <td>{element?.hostelDetails?.name}</td>

                  {/* <td>
                    {element?.amountWithoutGST
                      ? element?.amountWithoutGST
                      : "-"}
                  </td> */}
                  {/* <td>{element?.GST ? element?.GST : "-"}</td> */}

                  <td>{element?.paymentMode}</td>

                  <td>
                    {element?.uploadInvoice?.length > 0 ? (
                      <Image
                        size={20}
                        onClick={() => handleImagePopup(element.uploadInvoice)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default FinanceTable;
