import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation, useQuery } from "@apollo/client";

export const CREATE_EMPLOYEE_MONTHLY_ATTENDANCE = gql`
mutation ($month:Int, $year:Int){
    createMonthlyAttendance(month:$month, year:$year){
        message
        status
        xlsx
    }
}
`

export const PROCESS_EMPLOYEE_MONTHLY_SALARY = gql`
mutation ($month:Int, $year:Int){
    processEmployeeMonthlySalary(month:$month, year:$year){
        status
      message
      xlsx
    }
}
`

export const GET_EMPLOYEE_MONTHLY_PAYSLIP = gql`
mutation ($empCode:String, $month:Int, $year:Int){
    getMonthlyPayslip(empCode:$empCode, month:$month, year:$year)
}
`



export const useCreateEmployeeMonthlyAttendance = () => {
    const [createEmployeeMonthlyAttendance, { loading, error, data, refetch }] =
        useMutation(CREATE_EMPLOYEE_MONTHLY_ATTENDANCE);
    useLoadingHandler(loading);
    const createEmployeeMonthlyAttendanceHandler = (month, year) => {
        createEmployeeMonthlyAttendance({
            variables: {
                month, year,
            },
        });
    };
    return [createEmployeeMonthlyAttendanceHandler, { data, refetch, loading, error }];
};

export const useProcessEmployeeMonthlySalary = () => {
    const [processEmployeeMonthlySalary, { loading, error, data, refetch }] =
        useMutation(PROCESS_EMPLOYEE_MONTHLY_SALARY);
    useLoadingHandler(loading);
    const processEmployeeMonthlySalaryHandler = (month, year) => {
        processEmployeeMonthlySalary({
            variables: {
                month, year,
            },
        });
    };
    return [processEmployeeMonthlySalaryHandler, { data, refetch, loading, error }];
};

export const useViewEmployeeMonthlypaySlip = () => {
    const [getEmployeeMonthlyPayslip, { loading, error, data, refetch }] =
        useMutation(GET_EMPLOYEE_MONTHLY_PAYSLIP);
    useLoadingHandler(loading);
    const getEmployeeMonthlyPayslipHandler = (empCode, month, year) => {
        console.log(empCode, month, year)
        getEmployeeMonthlyPayslip({
            variables: {
                empCode, month, year,
            },
        });
    };
    return [getEmployeeMonthlyPayslipHandler, { data, refetch, loading, error }];
};