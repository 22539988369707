import { useEffect, useState } from "react";
import { Edit, Trash } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import Avatar from "@components/common/avatar";
// import { useDeleteAmenity } from "@api";
// import toaster from "@components/common/Toaster";

const tableHeaderData = [
  "Thumbnail",
  "Title",
  "Code",
  "Status",
  "Expiry",
  "Actions",
];

const OffersTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState([]);
  // const [deleteAmenityHandler] = useDeleteAmenity();

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    }
  }, [data]);

  // const handleDelete = (e, item) => {
  //   e.preventDefault();
  //   deleteAmenityHandler(item._id);
  //   refetch();

  //   toaster("success", "Amenity deleted successfully");
  // };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle></CardTitle>
            <Button.Ripple
              color='light'
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Offer
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <Avatar img={item?.thumbnail} />
                  </td>
                  <td>{item?.title}</td>
                  <td>
                    {
                      <Badge pill color='light-primary' className='mr-1'>
                        {item?.couponCode}
                      </Badge>
                    }
                  </td>
                  <td>
                    {
                      <Badge
                        pill
                        color={item?.status ? "light-success" : "light-danger"}
                        className='mr-1'
                      >
                        {item?.status ? "Active" : "Inactive"}
                      </Badge>
                    }
                  </td>
                  <td>
                    {
                      <Badge
                        pill
                        color={
                          new Date(item?.expiryDate) > new Date()
                            ? "light-success"
                            : "light-danger"
                        }
                        className='mr-1'
                      >
                        {new Date(item?.expiryDate).toDateString()}
                      </Badge>
                    }
                  </td>
                  <td>
                    <Button
                      className='rounded-circle btn-icon'
                      color='none'
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit size={18} />
                    </Button>

                    {/* <Button
                      className='rounded-circle btn-icon'
                      color='none'
                      onClick={(e) => handleDelete(e, item)}
                    >
                      <Trash size={18} />
                    </Button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default OffersTable;
