// ! Added by Ashwin

import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import ImageSection from "./ImageSection";
import { useUpdateHomePageDetails } from "../../../../api/dataSource";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { HOMEPAGE } from "../../../../utility/constant";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useParams } from "react-router";

const UploadType = [{ value: "homePage", label: "HomePage" }];

const TravellerGallery = () => {
  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const [imageList, setImageList] = useState([]);
  const [updateHomePageDetailsHandler, { data: updatedData }] =
    useUpdateHomePageDetails();
  const dispatch = useDispatch();

  useEffect(() => {
    state?.tableData &&
      state.tableData.travellerGallery?.length &&
      setImageList(state.tableData.travellerGallery);
  }, [state?.tableData]);

  const [arrImages, setArrImages] = useState([]);

  useEffect(() => {
    if (updatedData) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          travellerGallery: arrImages,
        })
      );
      // toaster("success", "Updated successfully");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, arrImages, updatedData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const images = [];
    // eslint-disable-next-line array-callback-return
    imageList.map((image) => {
      image.image &&
        images.push({
          type: image.type,
          image: image.image,
          key: image.key,
          userName: image.userName,
        });
    });
    setArrImages(images);

    if (images?.length !== 0) {
      updateHomePageDetailsHandler(id, { travellerGallery: images }); // "hosteller" is used to get landing page doc
      toaster("success", "Updated traveller's gallery images successfully");
    } else {
      toaster("error", "Please upload traveller's gallery images");
    }
  };

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={imageList}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default TravellerGallery;
