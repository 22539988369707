import ReviewsLinks from "../../views/ORM/ReviewsLinks";
import ORMReviews from "../../views/ORMReviews";
import ORMSyncReviews from "../../views/ORMSyncReviews";
import ORMSourceReport from "../../views/ORMSourceReport";
import ORMHomepage from "../../views/ORMHomepage";
import ORMTargetRatings from "../../views/ORMTargetRatings";
import ORMSummary from "../../views/ORMSummary";
import ReplyTemplate from "../../views/ReplyTemplate";
import ORMCorporateReport from "../../views/ORMCorporateReport";
import ORMTicketsReport from "../../views/ORMTicketsReport";
import CategoryClassification from "../../views/ORM_Category_Classification";
import ORMHostelwiseReport from "../../views/ORM_Hostelwise_Report";

const ORMRoutes = [
  {
    path: "/orm/reviewsLinks",
    component: ReviewsLinks,
    exact: true,
  },
  {
    path: "/orm/reviews",
    component: ORMReviews,
    exact: true,
  },
  {
    path: "/orm/syncReviews",
    component: ORMSyncReviews,
    exact: true,
  },
  {
    path: "/orm/sourceReport",
    component: ORMSourceReport,
    exact: true,
  },
  {
    path: "/orm/homepage",
    component: ORMHomepage,
    exact: true,
  },
  {
    path: "/orm/ticketsReport",
    component: ORMTicketsReport,
    exact: true,
  },
  {
    path: "/orm/corporateReport",
    component: ORMCorporateReport,
    exact: true,
  },
  {
    path: "/orm/targetRatings",
    component: ORMTargetRatings,
    exact: true,
  },
  {
    path: "/orm/summary",
    component: ORMSummary,
    exact: true,
  },
  {
    path: "/orm/replyTemplate",
    component: ReplyTemplate,
    exact: true,
  },
  {
    path: "/orm/categoryClassification",
    component: CategoryClassification,
    exact: true,
  },
  {
    path: "/orm/HostelwiseReport",
    component: ORMHostelwiseReport,
    exact: true,
  },
];
export default ORMRoutes;
