import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";

const HostelCommissionSubFields = ({ setDataToSubmit, editData }) => {
  const [subHeadType, setSubHeadType] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [otaName, setOtaName] = useState(null);

  const HostelCommissionType = [
    { label: "OTA", value: "OTA" },
    { label: "Non-OTA", value: "Non-OTA" },
  ];
  const HostelCommissionOTAName = [
    { label: "Booking.com", value: "Booking.com" },
    { label: "Goibibo", value: "Goibibo" },
    { label: "HostelWorld", value: "HostelWorld" },
    { label: "MakeMyTrip", value: "MakeMyTrip" },
    { label: "Tripadvisor", value: "Tripadvisor" },
    { label: "Google", value: "Google" },
  ];

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );
    setStartDate(
      editData?.subFields?.startDate !== null
        ? editData?.subFields?.startDate
        : new Date()
    );
    setEndDate(
      editData?.subFields?.endDate !== null
        ? editData?.subFields?.endDate
        : new Date()
    );

    setOtaName(
      editData?.subFields?.quantity !== null ? editData?.subFields?.otaName : ""
    );
  }, [editData]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
      otaName: otaName?.value,
      startDate,
      endDate,
    };

    setDataToSubmit(data);
  }, [subHeadType, otaName, startDate, endDate]);
  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="subHeadType">
            Commissions Type
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={HostelCommissionType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            // isDisabled={editData?._id}
          />
        </FormGroup>
      </Col>

      {subHeadType?.value === "OTA" && (
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="otaName">
              OTA Name
            </Label>
            <Select
              isClearable={false}
              id="otaName"
              name="otaName"
              value={otaName}
              options={HostelCommissionOTAName}
              required
              onChange={(value) => setOtaName(value)}
              classNamePrefix="select"
              // isDisabled={editData?._id}
            />
          </FormGroup>
        </Col>
      )}
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="start date">
            Commission Start Date
          </Label>

          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={startDate}
            onChange={(date) =>
              setStartDate(new Date(dateFormatter(new Date(date))))
            }
            id="startDate"
            placeholder="Select start date"
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="end date">
            Commission End Date
          </Label>

          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={endDate}
            onChange={(date) =>
              setEndDate(new Date(dateFormatter(new Date(date))))
            }
            id="endDate"
            placeholder="Select end date"
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default HostelCommissionSubFields;
