import React from "react";
import { CardHeader, Col, Row, Table } from "reactstrap";

const time = [
  "00:00-01:00",
  "01:00-02:00",
  "02:00-03:00",
  "03:00-04:00",
  "04:00-05:00",
  "05:00-06:00",
  "06:00-07:00",
  "07:00-08:00",
  "08:00-09:00",
  "09:00-10:00",
  "10:00-11:00",
  "11:00-12:00",
  "12:00-13:00",
  "13:00-14:00",
  "14:00-15:00",
  "15:00-16:00",
  "16:00-17:00",
  "17:00-18:00",
  "18:00-19:00",
  "19:00-20:00",
  "20:00-21:00",
  "21:00-22:00",
  "22:00-23:00",
  "23:00-00:00",
];
const Report = ({ timewiseData }) => {
  //console.log(timewiseData);
  return (
    <div style={{}}>
      <Row>
        <Col>
          <>
            <CardHeader>
              <h4 style={{}}>Timewise Sales Report</h4>
            </CardHeader>
            <div style={{ overflowX: "auto", maxHeight: "600px" }}>
              <Table striped bordered>
                <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                  <tr>
                    <th
                      style={{ position: "sticky", top: 0, left: 0, zIndex: 2 }}
                    >
                      Cafe Name
                    </th>
                    {timewiseData?.map((cafe, index) => (
                      <React.Fragment key={index}>
                        <th
                          colSpan="3"
                          style={{
                            marginLeft: "50px",
                            //border: "1px solid black",
                            textAlign: "center",
                            minWidth: "150px",
                            position: "sticky",

                            zIndex: 1,
                          }}
                        >
                          {cafe?.cafeInfo[0]?.cafeName}
                        </th>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <th style={{ position: "sticky", left: 0, zIndex: 1 }}>
                      Hours
                    </th>
                    {timewiseData?.map((cafe, i) => {
                      return (
                        <React.Fragment key={i}>
                          <th>Order Count</th>
                          <th>Total Sales</th>
                          <th>Net Sales</th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {time?.map((i, j) => {
                    const data = timewiseData?.map((element, index) => {
                      return (
                        element?.hourRangeData?.find(
                          (rangeData) => j === rangeData?.hour
                          //i?.split(":")[0] == rangeData?.hour
                        ) || null
                      );
                    });

                    return (
                      <tr style={{ padding: "2px", margin: "2px" }} key={j}>
                        <td
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backdropFilter: "blur(400px)",
                          }}
                        >
                          {i}
                        </td>
                        {/* <td>1</td>
                      <td>2</td> */}
                        {data?.map((hourlyData, index) => {
                          return (
                            <React.Fragment key={index}>
                              <td>
                                {hourlyData
                                  ? hourlyData?.totalOrderCount
                                  : "--"}
                              </td>
                              <td>
                                {hourlyData
                                  ? hourlyData?.totalOrderAmount.toFixed(2)
                                  : "--"}
                              </td>
                              <td>
                                {hourlyData
                                  ? hourlyData?.totalNetSales.toFixed(2)
                                  : "--"}
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default Report;
