import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import TableList from "@components/common/TableList";
import {
  useGetAllMessageTemplateList,
  useGetPaginatedMessageConfigurationList,
} from "../../../api/dataSource";
import { TABLE_TYPE } from "../common/TableList/constant";

const tableHeaderList = [
  "Actions",
  "Campaign Name",
  "Template Name",
  "Start - End Date",
  "Time to send",
];

const MessageConfigurationTable = ({ AddEditModal }) => {
  const [limit, setLimit] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useGetPaginatedMessageConfigurationList(
    limit,
    page
  );

  const history = useHistory();

  const handleAdd = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    refetch();
  }, [page]);

  const timeFormatHandler = (timeToSend) => {
    if (timeToSend !== null) {
      let myTime = new Date(`${timeToSend}`.toString());

      const hours = `${
        myTime.getHours() <= 9 ? `0${myTime.getHours()}` : myTime.getHours()
      }`;
      const minutes = `${
        myTime.getMinutes() <= 9
          ? `0${myTime.getMinutes()}`
          : myTime.getMinutes()
      }`;

      return `${hours}:${minutes}`;
    } else {
      return "-";
    }
  };

  useEffect(() => {
    if (data) {
      let MessageConfigurationList = [];
      data?.getPaginatedMessageConfigurationList?.data?.map((item) => {
        MessageConfigurationList.push({
          id: item?._id,
          TemplateName: item?.template?.TemplateName,
          timeToSend: timeFormatHandler(item?.timeToSend),
          fromDate: item?.fromDate,
          toDate: item?.toDate,
          campaignName: item?.campaignName,
        });
      });

      setTableData(MessageConfigurationList);
      setTotalPage(
        Math.ceil(data?.getPaginatedMessageConfigurationList?.count / limit)
      );
    }
  }, [data]);
  useEffect(() => {
    if (editId && data?.getPaginatedMessageConfigurationList?.data?.length) {
      let editData = data?.getPaginatedMessageConfigurationList?.data?.filter(
        (item) => item._id === editId
      );
      setEditData(editData);
    } else {
      setEditData(null);
    }
  }, [editId, data]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/cms/edit/hostel/${id}`);
    } else {
      setEditId(id);
    }
  };

  return (
    <div>
      <TableList
        handleAdd={AddEditModal ? handleAdd : null}
        // filterHeader="All Scheduler Configuration"
        tableHeader="WA Scheduler"
        type={TABLE_TYPE.MESSAGE_CONFIGURATION}
        tableData={tableData}
        tableHeaderList={tableHeaderList}
        openModal={() => setOpenModal(!openModal)}
        handleEdit={handleEdit}
        // handleDelete={AddEditModal ? handleDelete : null}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={() => setOpenModal(!openModal)}
          refetch={refetch}
          editData={editData}
          setEditId={setEditId}
        />
      )}
    </div>
  );
};

export default MessageConfigurationTable;
