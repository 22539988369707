import React, { useEffect, useState } from "react";
import StepOutBookingsTable from "./StepOutBookingsTable";
import TablePagination from "@components/common/TablePagination";
import {
  useGetAllStepOutCategoryList,
  useGetAllStepOutProductList,
  useGetStepOutPaginatedBookingList,
} from "../../../api/dataSource";
import Flatpickr from "react-flatpickr";
import AddEditModal from "./AddEditModal";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { dateFormatter } from "@utils";

const StepOutBookings = () => {
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState(null);
  const [guestName, setGuestName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterFeilds, setFilterFeilds] = useState(null);
  const { data, refetch } = useGetStepOutPaginatedBookingList(
    limit,
    page,
    filterFeilds
  );

  //fetching category list
  const { data: allCategoryList } = useGetAllStepOutCategoryList();

  //fetching product list
  const { data: allProductList } = useGetAllStepOutProductList();
  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    refetch();
  }, [refetch, page]);

  useEffect(() => {
    const catList = allCategoryList?.getAllStepOutCategory?.map((item) => {
      return { label: item?.name, value: item?.name };
    });

    const prodList = allProductList?.getAllStepOutProduct?.map((item) => {
      return { label: item?.title, value: item?.title };
    });
    setCategoryList(catList);
    setProductList(prodList);
  }, [allCategoryList, allProductList]);

  useEffect(() => {
    if (data?.getStepOutPaginatedBooking?.data.length > 0) {
      setTableData(data?.getStepOutPaginatedBooking?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getStepOutPaginatedBooking?.count / limit));
  }, [
    data?.getStepOutPaginatedBooking?.count,
    data?.getStepOutPaginatedBooking?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getStepOutPaginatedBooking?.data.length > 0) {
      let findData = data?.getStepOutPaginatedBooking.data.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getStepOutPaginatedBooking?.data, id]);

  const handelSearch = () => {
    const filterFields = {
      categoryName: category?.map((el) => el?.value),
      productName: product?.map((el) => el?.value),
      guestName,
      startDate,
      endDate,
    };
    setPage(1);
    setFilterFeilds(filterFields);
  };

  const handelReset = () => {
    setFilterFeilds(null);
    setCategory(null);
    setProduct(null);
    setGuestName("");
    setStartDate("");
    setEndDate("");
  };
  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Category</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              isMulti
              id="category"
              name="category"
              value={category}
              closeMenuOnSelect={false}
              options={categoryList}
              onChange={(value) => {
                setCategory(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Product</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              isMulti
              id="product"
              name="product"
              value={product}
              closeMenuOnSelect={false}
              options={productList}
              onChange={(value) => setProduct(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="guestName">
              <strong>Start Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={startDate}
              onChange={(date) => {
                setStartDate(dateFormatter(new Date(date)));
              }}
              id="startDate"
              placeholder="Start Date"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="guestName">
              <strong>End Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={endDate}
              onChange={(date) => {
                setEndDate(dateFormatter(new Date(date)));
              }}
              id="endDate"
              placeholder="End Date"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="guestName">
              <strong>Guest Name</strong>
            </Label>
            <Input
              type="text"
              value={guestName}
              id="guestName"
              name="guestName"
              placeholder="Guest Name"
              onChange={(e) => setGuestName(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 20px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handelSearch}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 23px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={handelReset}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <StepOutBookingsTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      <AddEditModal
        open={openModal}
        handleToggle={toggleModal}
        refetch={refetch}
        editData={editData}
        setId={setId}
      />
    </div>
  );
};

export default StepOutBookings;
