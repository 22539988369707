import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateAbout, useUploadImage } from "@api";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { v4 as uuidv4 } from "uuid";
import { X, Camera, Plus } from "react-feather";
import Avatar from "@components/common/avatar";
import confirmDelete from "@components/common/confirmDelete";

const Story = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [index, setIndex] = useState("");
  const [cards, setCards] = useState([
    {
      id: uuidv4(),
      name: "",
      number: "",
    },
  ]);
  const [locations, setLocations] = useState([
    {
      id: uuidv4(),
      name: "aa",
      longitude: "ss",
      latitude: "cc",
    },
  ]);

  const [updateAbout, { data, reset }] = useUpdateAbout();
  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  useEffect(() => {
    if (state.tableData?.story) {
      setHeading(state.tableData.story.heading);
      setDescription(state.tableData.story.description);

      setCards(
        state.tableData.story.cards?.map((item) => ({
          id: uuidv4(),
          name: item?.name,
          number: item?.number,
          icon: item?.icon,
        }))
      );

      setLocations(
        state.tableData.story.locations?.map((item) => ({
          id: uuidv4(),
          name: item?.name,
          longitude: item?.longitude,
          latitude: item?.latitude,
        }))
      );
    }
  }, [state.tableData]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setCards(
        cards.map((item) => {
          if (item.id === index) {
            return { ...item, icon: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  useEffect(() => {
    if (data?.updateAbout) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          story: { heading, description, cards, locations },
        })
      );

      reset();
    }
  }, [data?.updateAbout]);

  const handleRemoveCards = (id) =>
    confirmDelete(() => setCards(cards.filter((item) => item.id !== id)));

  const handleAddCards = () => {
    if (cards.length < 5) {
      setCards([...cards, { id: uuidv4(), name: "" }]);
    } else toaster("error", "Maximum limit of 5 has reached");
  };

  const handleChangeCardName = (name, id) => {
    setCards(
      cards.map((item) => (item.id === id ? { ...item, name: name } : item))
    );
  };

  const handleChangeCardNumber = (value, id) => {
    setCards(
      cards.map((item) => (item.id === id ? { ...item, number: value } : item))
    );
  };

  const handleRemoveLocations = (id) =>
    confirmDelete(() =>
      setLocations(locations.filter((item) => item.id !== id))
    );

  const handleAddLocations = () => {
    setLocations([
      ...locations,
      { id: uuidv4(), name: "", longitude: "", latitude: "" },
    ]);
  };

  const handleChangeLocationName = (name, id) => {
    setLocations(
      locations.map((item) => (item.id === id ? { ...item, name } : item))
    );
  };

  const handleChangeLocationLongitude = (value, id) => {
    setLocations(
      locations.map((item) =>
        item.id === id ? { ...item, longitude: value } : item
      )
    );
  };

  const handleChangeLocationLatitude = (value, id) => {
    setLocations(
      locations.map((item) =>
        item.id === id ? { ...item, latitude: value } : item
      )
    );
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);
    uploadImageHandler(e.target.files[0], "About");
  };

  const submitDataHandler = (e) => {
    e.preventDefault();

    let isValid = true;

    const story = {
      heading,
      description,
      cards,
      locations,
    };

    cards?.forEach((item) => {
      isValid &&
        (item.name && item.number && item.icon
          ? (isValid = true)
          : (isValid = false));
    });

    locations?.forEach((item) => {
      isValid &&
        (item.name && item.longitude && item.latitude
          ? (isValid = true)
          : (isValid = false));
    });

    if (heading && description && isValid) {
      cards?.forEach((item) => delete item.id);

      updateAbout({ story });
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="heading">
              Heading
            </Label>

            <Input
              type="text"
              value={heading}
              id="heading"
              name="heading"
              placeholder="Heading"
              onChange={(e) => setHeading(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="description">
              Description
            </Label>

            <Input
              type="textarea"
              value={description}
              id="description"
              name="description"
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        </Col>

        <br />

        <Col className="mt-3 mb-1" sm="12">
          <h3>Cards (Max - 5)</h3>
        </Col>

        {cards.map(({ name, icon, number, id }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={index}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"cards" + id}>
                Card {index + 1}
              </Label>

              <div className="mt-2 d-flex align-items-center">
                <div className="mb-1 mr-2">
                  {<Avatar size="lg" img={icon} alt={icon} />}
                </div>

                <label htmlFor={`myFile${id}`}>
                  <p className="btn btn-sm btn-light">
                    <Camera size={15} />
                  </p>
                  <input
                    name={`myFile${id}`}
                    id={`myFile${id}`}
                    className="d-none"
                    type="file"
                    onChange={(e) => fileUploader(e, id)}
                  />
                </label>
              </div>

              <Input
                type="text"
                value={name}
                id={"cards" + id}
                name={"name"}
                placeholder="name"
                onChange={(e) => handleChangeCardName(e.target.value, id)}
              />

              <br />

              <Input
                rows={4}
                type="textarea"
                value={number}
                id={"description" + id}
                name={"Add Description"}
                placeholder="Add Description"
                onChange={(e) => handleChangeCardNumber(e.target.value, id)}
              />
            </FormGroup>
            {index === cards.length - 1 ? (
              <Button.Ripple
                className="btn-icon rounded-circle ml-1"
                color="light"
                type="button"
                size="sm"
                onClick={() => handleAddCards()}
              >
                <Plus size={14} />
              </Button.Ripple>
            ) : null}
            {cards.length > 1 ? (
              <Button.Ripple
                className="btn-icon rounded-circle ml-1"
                color="danger"
                type="button"
                size="sm"
                onClick={() => handleRemoveCards(id)}
              >
                <X size={14} />
              </Button.Ripple>
            ) : null}
          </Col>
        ))}

        <Col className="mt-3 mb-1" sm="12">
          <h3>Map Locations</h3>
        </Col>

        {locations.map(({ name, longitude, latitude, id }, index) => (
          <Col className="mb-1" sm="12" key={index}>
            <Row>
              <Col className="mb-1" sm="12">
                <h5>Location {index + 1}</h5>
              </Col>

              <Col>
                <FormGroup className="w-100">
                  <Label className="form-label" htmlFor={"name" + id}>
                    Name
                  </Label>

                  <Input
                    type="text"
                    value={name}
                    id={"name" + id}
                    name={"name"}
                    placeholder="name"
                    onChange={(e) =>
                      handleChangeLocationName(e.target.value, id)
                    }
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup className="w-100">
                  <Label className="form-label" htmlFor={"longitude" + id}>
                    Longitude
                  </Label>

                  <Input
                    type="text"
                    value={longitude}
                    id={"longitude" + id}
                    name={"longitude"}
                    placeholder="longitude"
                    onChange={(e) =>
                      handleChangeLocationLongitude(e.target.value, id)
                    }
                  />
                </FormGroup>
              </Col>

              <Col className="d-flex align-items-center">
                <FormGroup className="w-100">
                  <Label className="form-label" htmlFor={"latitude" + id}>
                    Latitude
                  </Label>

                  <Input
                    type="text"
                    value={latitude}
                    id={"latitude" + id}
                    name={"latitude"}
                    placeholder="latitude"
                    onChange={(e) =>
                      handleChangeLocationLatitude(e.target.value, id)
                    }
                  />
                </FormGroup>
                {index === locations.length - 1 ? (
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleAddLocations(id)}
                  >
                    <Plus size={14} />
                  </Button.Ripple>
                ) : null}
                {locations.length > 1 ? (
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="danger"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveLocations(id)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                ) : null}
              </Col>
            </Row>
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Story;
