import React from "react";
import {
  Card,
  Col,
  Row,
  CardHeader,
  Button,
  CardTitle,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Edit, Trash2 } from "react-feather";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";
import { useDeleteUnboxBanner } from "../../../api/Unbox/UnboxBanner/Mutations";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "",
  "Title",
  "Display Order",
  "Start Date",
  "End Date",
];

const UnboxBannerTable = ({ tableData, openModal, setId, refetch }) => {
  // console.log(tableData)
  const [deleteUnboxBannerHandler, { data }] = useDeleteUnboxBanner();

  const deleteUnboxBannerFunction = (id) => {
    deleteUnboxBannerHandler(id);
    refetch();
  };
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Unbox Banner</CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add
            </Button.Ripple>
          </CardHeader>

          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData?.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>

            <tbody>
              {tableData?.map((item) => {
                // console.log(item)
                return (
                  <tr key={uuidv4()}>
                    <td style={{ width: "10px" }}>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                        color="none"
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td style={{ width: "10px" }}>
                      <Button
                        onClick={() => {
                          deleteUnboxBannerFunction(item?._id);
                        }}
                        color="none"
                      >
                        <Trash2
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td>{item?.title}</td>
                    <td>{item?.displayOrder}</td>
                    <td>{dateFormatterDDMMYYYY(item?.startDate)}</td>
                    <td>{dateFormatterDDMMYYYY(item?.endDate)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default UnboxBannerTable;
