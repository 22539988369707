import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useGetRecommendationById,
  useUpdateRecommendation,
  useUploadImage,
  useAddRecommendations,
} from "@api";
import { useParams } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import toaster from "@components/common/Toaster";
import { MODULE_TYPE } from "../../../../utility/constant";
import { tableDataHandler } from "@store/Cms/cms.action";

const CardForm = ({ cardType }) => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState([]);
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [existing, setExisting] = useState(false);

  const { data, refetch } = useGetRecommendationById(id, cardType);

  const [updateRecommendationHandler, { data: updatedRecommendationData }] =
    useUpdateRecommendation();
  const [addRecommendationHandler, { data: addedRecommendationData }] =
    useAddRecommendations();

  const [uploadImageHandler, { loading: imageLoad, data: imageData }] =
    useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 5000000,
    },
  });

  useEffect(() => {
    let prePopulateData;

    if (data?.getRecommendationByProductTypeAndId) {
      prePopulateData = data?.getRecommendationByProductTypeAndId;
      setExisting(true);
    } else if (addedRecommendationData?.createRecommendation) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          cardData: {
            name: name,
            shortDesc: description,
            duration: parseInt(duration),
            price: parseInt(price),
            productType: cardType,
            image: imagePreview?.url,
          },
        })
      );

      // prePopulateData = addedRecommendationData?.createRecommendation;
    }

    if (prePopulateData) {
      setName(prePopulateData?.name);
      setDuration(prePopulateData?.duration);
      setPrice(prePopulateData?.price);
      setDescription(prePopulateData?.shortDesc);

      setImagePreview({ type: "existing", url: prePopulateData?.image?.url });
    }
  }, [
    data?.getRecommendationByProductTypeAndId,
    addedRecommendationData?.createRecommendation,
  ]);

  useEffect(() => {
    if (state.tableData?.cardData) {
      setName(state.tableData?.cardData?.name);
      setDuration(state.tableData?.cardData?.duration);
      setPrice(state.tableData?.cardData?.price);
      setDescription(state.tableData?.cardData?.shortDesc);

      setImagePreview({
        type: "existing",
        url: state.tableData?.cardData?.image,
      });
    }
  }, [state.tableData]);

  useEffect(() => {
    if (updatedRecommendationData?.updateRecommendationByProductId) {
      refetch();
      toaster("success", "Submitted successfully");
    }
  }, [updatedRecommendationData?.updateRecommendationByProductId]);

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setFormData([...formData, new File([preview[0]], "filename")]);
    setImagePreview({ type: "new", url: preview });
    uploadImageHandler(
      file.data,
      `${state.tableData?.name
        ?.toLowerCase()
        ?.replaceAll(" ", "-")}/${cardType}`
    );
  });

  const renderPreview = () =>
    imagePreview ? (
      <img
        className='rounded mr-1 mb-2 w-50'
        src={imagePreview?.url}
        alt='avatar'
      />
    ) : null;

  const submitDataHandler = (e) => {
    e.preventDefault();

    if (name && description && duration && price && id && cardType) {
      const data = {
        name: name,
        shortDesc: description,
        duration: parseInt(duration),
        price: parseInt(price),
        productType: cardType,
      };

      cardType.toLowerCase() === MODULE_TYPE.HOSTEL && (data.hostelId = id);

      cardType.toLowerCase() === MODULE_TYPE.TRIP && (data.tripId = id);

      cardType.toLowerCase() === MODULE_TYPE.WORKATION &&
        (data.workationId = id);

      imagePreview.type === "new" &&
        (data.image = {
          url: imageData?.uploadImage?.url,
          key: imageData?.uploadImage?.key,
        });

      existing
        ? updateRecommendationHandler(id, null, cardType, data)
        : addRecommendationHandler(data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className='mt-2' onSubmit={submitDataHandler}>
      <Row>
        <Col sm='6' className='mb-2'>
          <Label className='form-label' htmlFor='location'>
            Card Image
          </Label>
          <DragDrop
            uppy={uppy}
            width='100%'
            note='Image size should be less than 5MB'
            locale={{
              strings: {
                dropHereOr: "Drop image here or %{browse}",
                browse: "browse",
              },
            }}
          />
        </Col>
        <Col sm='6'>
          <Label className='form-label w-100'>Image preview here</Label>
          {imageLoad ? "Uploading image..." : renderPreview()}
        </Col>
        <Col sm='6'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='name'>
              {cardType} Name
            </Label>

            <Input
              type='text'
              value={name}
              id='name'
              name='name'
              placeholder={`${cardType} name`}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm='6'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='duration'>
              Duration
            </Label>

            <Input
              type='number'
              value={duration}
              id='duration'
              name='duration'
              placeholder='Duration'
              onChange={(e) => setDuration(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm='6'>
          <FormGroup>
            <Label className='form-label' htmlFor='description'>
              Description
            </Label>

            <Input
              rows={4}
              type='textarea'
              value={description}
              id='description'
              name='description'
              placeholder='Description'
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm='6'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='price'>
              Price
            </Label>

            <Input
              type='number'
              value={price}
              id='price'
              name='price'
              placeholder='Add Price'
              onChange={(e) => setPrice(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup loading={imageLoad} />
    </form>
  );
};

export default CardForm;
