import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";


export const GET_EMPLOYEE_PERFORMANCE_DETAILS = gql`
query getEmployeePerformanceDataByEmpId($id:ID) {
    getEmployeePerformanceDataByEmpId(id:$id){
    _id
        empCode
        year
        month
        score
        remark
        evaluationTitle
    }
}
`

export const useGetEmployeePerformanceById = (id) => {
    const { loading, error, data, refetch } = useQuery(GET_EMPLOYEE_PERFORMANCE_DETAILS, {
        variables: {
            id,
        }
    });
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};