import React from "react";
import AuthSuccess from "../../common/AuthSuccess";

const RegisterStepThree = () => {
  return (
    <AuthSuccess
      title='Signup Successfully'
      description='No worries, we’ll send you reset instructions.'
      btnText='Visit Cms'
    />
  );
};

export default RegisterStepThree;
