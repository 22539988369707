//Added By Gaurav
import gql from "graphql-tag";

export const GET_EXTRA_CHARGES_REPORT = gql`
query getExtraChargesReport($filter:filter){
        getExtraChargesReport(filter: $filter) {
        providerRefId
        rooms
        extraCharges
        totalRoomPrice
        totalExtraCharges
        payableAmount
        hostelId
        createdAt
        checkinDate
        checkoutDate
        noOfNights
    }
}
`;
