// ! Coded by Ashwin

import FileUpload from "../../views/FileUpload";

const FileUploadRoutes = [
  {
    path: "/fileUpload",
    exact: true,
    component: FileUpload,
  },
];

export default FileUploadRoutes;
