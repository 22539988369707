import gql from "graphql-tag";

export const GET_PAGINATED_STEP_OUT_CATEGORY_LIST = gql`
  query getPaginatedStepOutCategory($filter: filter) {
    getPaginatedStepOutCategory(filter: $filter) {
      data {
        _id
        name
        description
        bannerImage
        theme {
          themeColor
          highlightedColor
          highlightedTextColor
        }
        overView {
          title
          subTitle
          description
          image
        }
        feature {
          title
          highlightedText
          items {
            imageUrl
            description
            displayOrder
          }
        }
        moments {
          title
          highlightedText
          description
          items {
            imageUrl
            tag
            description
            displayOrder
          }
        }
        blogs {
          _id
          blogTitle
        }
        similar {
          title
          highlightedText
          items {
            imageUrl
            title
            redirectUrl
          }
        }
        seo {
          metaTitle
          metaDesc
          keywords
        }
        url
        status
        topDoddle
      }
      count
      page
      limit
    }
  }
`;

export const GET_STEP_OUT_CATEGORY_BY_ID = gql`
  query getStepOutCategoryById($id: ID) {
    getStepOutCategoryById(id: $id) {
      _id
      name
      description
      bannerImage
      theme {
        themeColor
        highlightedColor
        highlightedTextColor
      }
      overView {
        title
        subTitle
        description
        image
      }
      feature {
        title
        highlightedText
        items {
          imageUrl
          description
          displayOrder
        }
      }
      moments {
        title
        highlightedText
        description
        items {
          imageUrl
          tag
          description
          displayOrder
        }
      }
      blogs {
        _id
        blogTitle
      }
      similar {
        title
        highlightedText
        items {
          imageUrl
          title
          redirectUrl
        }
      }
      seo {
        metaTitle
        metaDesc
        keywords
      }
      url
      status
      topDoddle
    }
  }
`;

export const GET_STEP_OUT_CATEGORY_LIST = gql`
  query getAllStepOutCategory {
    getAllStepOutCategory {
      _id
      name
      description
      bannerImage
      theme {
        themeColor
        highlightedColor
        highlightedTextColor
      }
      overView {
        title
        subTitle
        description
        image
      }
      feature {
        title
        highlightedText
        items {
          imageUrl
          description
          displayOrder
        }
      }
      moments {
        title
        highlightedText
        description
        items {
          imageUrl
          tag
          description
          displayOrder
        }
      }
      blogs {
        _id
        blogTitle
      }
      similar {
        title
        highlightedText
        items {
          imageUrl
          title
          redirectUrl
        }
      }
      seo {
        metaTitle
        metaDesc
        keywords
      }
      url
      status
      topDoddle
    }
  }
`;
