import gql from "graphql-tag";

export const CREATE_STEP_OUT_LOCATION = gql`
mutation createStepOutLocation($stepOutLocationInput: StepOutLocationInput) {
    createStepOutLocation(stepOutLocationInput: $stepOutLocationInput) {
        _id
        title
    }
}
`

export const UPDATE_STEP_OUT_LOCATION = gql`
mutation updateStepOutLocation($id: ID $stepOutLocationUpdate: StepOutLocationInput) {
    updateStepOutLocation(id: $id stepOutLocationUpdate: $stepOutLocationUpdate){
        _id
        title
    }
}
`