// ! Coded by Ashwin

// import gql from "graphql-tag";

// export const GET_COUPON_LIST = gql`
//   query {
//     getCouponList {
//       coupons
//     }
//   }
// `;

import gql from "graphql-tag";

export const GET_COUPON_BY_ID = gql`
  query {
    getCouponById(id: $id) {
      id
      name
      status
      promoCode
      product
      subCategory
      discount
      expirationDate
      maxBookingDate
      minBookingDate
      maxStayDate
      minStayDate
      maxDiscountValue
      duration
      quantity
      numberOfUsage
      createdAt
      updatedAt
      private
      rules
      minBookingAmount
      minimumNights
      minimumAdults
      couponsPerUser
      newUserOnly
      maximumNights
      couponsPerDay
      dormsOnly
      sameDayBooking
      excludedWeekDays
    }
  }
`;

export const GET_PAGINATED_COUPON_LIST = gql`
  query getPaginatedCouponList($filter: filter) {
    getPaginatedCouponList(filter: $filter) {
      data {
        name
        status
        promoCode
        product
        subCategory
        discount
        expirationDate
        maxBookingDate
        minBookingDate
        maxStayDate
        minStayDate
        duration
        quantity
        numberOfUsage
        maxDiscountValue
        rules
        _id
        private
        minBookingAmount
        minimumNights
        minimumAdults
        prefix
        startNumber
        endNumber
        couponsPerUser
        newUserOnly
        maximumNights
        couponsPerDay
        dormsOnly
        sameDayBooking
        excludedWeekDays
        blockDates
        logs
      }
      count
      limit
      page
    }
  }
`;

export const GET_PAGINATED_COUPON_GENERATOR_LIST = gql`
  query getPaginatedCouponsGeneratorList($filter: filter) {
    getPaginatedCouponsGeneratorList(filter: $filter) {
      data {
        name
        status
        promoCode
        product
        subCategory
        discount
        expirationDate
        maxBookingDate
        minBookingDate
        maxStayDate
        minStayDate
        duration
        quantity
        numberOfUsage
        maxDiscountValue
        rules
        _id
        private
        minBookingAmount
        minimumNights
        maximumNights
        minimumAdults
        prefix
        startNumber
        endNumber
        couponsPerUser
        newUserOnly
        maximumNights
        couponsPerDay
        dormsOnly
        sameDayBooking
        excludedWeekDays
        blockDates
        logs
      }
      count
      limit
      page
    }
  }
`;
