import React, { useEffect, useState } from "react";
import { Edit, FileText } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { dateFormatHandler } from "../../../utility/Utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Hostel",
  "Reservation Id",
  "Name",
  "Email",
  "Mobile",
  "Document Id",
];

const BlacklistedGuestsTable = ({
  openModal,
  setId,
  data,
  refetch,
  loading,
}) => {
  const [tableData, setTableData] = useState(data);

  const userRole = localStorage.getItem("role");

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };
  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const tableDataStyle = {
    minWidth: "240px",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Blacklisted Guests</CardTitle>

            <CardHeader className="d-flex justify-content-between align-items-center px-3">
              {
                <Button.Ripple
                  color="light"
                  onClick={(e) => {
                    e.preventDefault();
                    openModal();
                  }}
                >
                  Add Blacklisted Guest
                </Button.Ripple>
              }
            </CardHeader>
          </CardHeader>
          {loading ? (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              Please wait...
            </h2>
          ) : tableData.length > 0 ? (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    {tableHeaderData.map((item) => {
                      return (
                        <th style={{ whiteSpace: "nowrap" }} key={uuidv4()}>
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((element, i) => (
                    <tr key={uuidv4()}>
                      <td>
                        <Button
                          className="rounded-circle btn-icon"
                          color="none"
                          onClick={(e) => {
                            e.preventDefault();
                            setId(element._id);
                            openModal();
                          }}
                        >
                          <Edit
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>
                      <td>{element?.hostel?.name}</td>
                      <td>{element?.reservationID}</td>
                      <td>{element?.name}</td>
                      <td>{element?.email}</td>
                      <td>{element?.mobile}</td>
                      <td>{element?.documentID}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              No request to show
            </h2>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default BlacklistedGuestsTable;
