import React, { useEffect, useState } from "react";
import SlideModal from "../../../@core/components/common/slide-modal";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { useGetAllCafeMasterList } from "../../../api/Unbox/UnboxCafeMaster/Queries";
import {
  useCreateUnboxCoupon,
  useUpdateUnboxCoupon,
} from "../../../api/Unbox/UnboxCoupon/Mutations";
import toaster from "@components/common/Toaster";
import { selectThemeColors } from "@utils";

const daysList = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];
const AddEditModal = ({ open, handleToggle, editData, refetch }) => {
  const [title, setTitle] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [description, setDescription] = useState("");
  const [couponType, setCouponType] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [discount, setDiscount] = useState("");
  const [maxDiscountValue, setMaxDiscountValue] = useState("");
  const [minOrderValue, setMinOrderValue] = useState("");
  const [cafeList, setCafeList] = useState([]);
  const [cafe, setCafe] = useState([]);
  const [couponCategory, setCouponCategory] = useState("");
  const [validDays, setValidDays] = useState(null);
  const [createUnboxCouponHandler, { data }] = useCreateUnboxCoupon();
  const [updateUnboxCouponHandler, { data: updatedData }] =
    useUpdateUnboxCoupon();
  const typeOptions = [
    {
      label: "Private",
      value: "Private",
    },
    { label: "Public", value: "Public" },
  ];

  const categoryOptions = [
    { label: "Absolute", value: "Absolute" },
    { label: "Percentage", value: "Percentage" },
  ];

  const CouponStatusList = [
    { label: "Paused", value: "paused" },
    { label: "Live", value: "live" },
    { label: "Completed", value: "completed" },
  ];

  const enterOnlyNumberAsInput = (val, name) => {
    if (!isNaN(val) && !val.includes("-")) {
      if (name === "discount") {
        setDiscount(val);
      } else if (name === "maxDiscountValue") {
        setMaxDiscountValue(val);
      } else {
        setMinOrderValue(val);
      }
    } else {
      toaster("error", "Please Enter Number Only");
    }
  };

  const enterOnlyAlphanumericAsInput = (val, name) => {
    if (name === "title") {
      setTitle(val);
    } else if (name === "promocode") {
      setPromoCode(val);
    } else {
      setDescription(val);
    }
  };

  useEffect(() => {
    if (editData?._id) {
      setTitle(editData?.couponTitle);
      setPromoCode(editData?.promoCode);
      setDescription(editData?.description);
      setCouponType(editData?.couponType);
      setDiscount(editData?.discount);
      setMaxDiscountValue(editData?.maxDiscountValue);
      setMinOrderValue(editData?.minOrderValue);
      setCouponStatus(editData?.couponType);
      setExpiryDate(editData?.expiryDate);
      setCouponType({
        label:
          editData?.couponType?.toString() === "Public" ? "Private" : "Public",
        value: editData?.couponType,
      });

      setCouponCategory({
        label:
          editData?.couponCategory?.toString() === "Percentage"
            ? "Percentage"
            : "Private",
        value: editData?.couponCategory,
      });

      setCouponStatus({
        label: editData?.status,
        value: editData?.status,
      });

      const couponDays = editData?.couponDays?.map((day) => {
        const findDay = daysList?.find((dayObject) => dayObject?.value === day);
        return findDay;
      });
      setValidDays(couponDays);
    } else {
      setTitle("");
      setPromoCode("");
      setDescription("");
      setCouponType("");
      setCouponStatus("");
      setExpiryDate(new Date());
      setDiscount("");
      setMaxDiscountValue("");
      setMinOrderValue("");
      setCafe([]);
      setCouponCategory("");
      setValidDays(null);
    }
  }, [editData]);

  useEffect(() => {
    if (data || updatedData) {
      refetch();
    }
  }, [data, updatedData]);

  const submitHandler = async () => {
    const cafeInp = cafe?.map((cafe) => cafe.value);
    const submitData = {
      couponTitle: title,
      promoCode: promoCode?.toUpperCase(),
      description,
      couponType: couponType?.value,
      status: couponStatus?.value,
      expiryDate,
      discount: parseInt(discount),
      maxDiscountValue: parseInt(maxDiscountValue),
      minOrderValue: parseInt(minOrderValue),
      cafe: cafeInp,
      couponCategory: couponCategory?.value,
      couponDays: validDays?.map((day) => day?.value),
    };

    if (editData?._id) {
      await updateUnboxCouponHandler(editData?._id, submitData);
      toaster("success", "Coupon updated successfully");
      // refetch()
      handleToggle();
    } else {
      await createUnboxCouponHandler(submitData);
      toaster("success", "Coupon added successfully");
      // refetch();
      handleToggle();
    }
    setTitle("");
    setPromoCode("");
    setDescription("");
    setCouponType("");
    setCouponStatus("");
    setExpiryDate(new Date());
    setDiscount("");
    setMaxDiscountValue("");
    setMinOrderValue("");
    setCafe([]);
    setCouponCategory("");
    setValidDays(null);
    handleToggle();
  };

  const { data: cafelist, refetch: cafeRefetch } = useGetAllCafeMasterList();

  useEffect(() => {
    if (cafelist) {
      // console.log(cafelist?.getAllCafeMasterList)
      setCafeList(cafeList?.getAllCafeMasterList);
      const cafeNameList = cafelist?.getAllCafeMasterList?.map((ele) => ({
        label: ele.cafeName,
        value: ele._id,
      }));
      setCafeList(cafeNameList);
    }

    if (editData?.cafe) {
      const selectedCafe = editData?.cafe?.map((item) => ({
        label: item?.cafeName,
        value: item?._id,
      }));
      setCafe(selectedCafe);
    }
  }, [cafelist, editData?.cafe]);
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitHandler}
    >
      <form>
        <Row>
          <Col>
            <Col>
              <Label>Coupon Title</Label>
              <Input
                autoFocus
                type="text"
                value={title}
                id="title"
                name="title"
                placeholder="Title"
                onChange={(e) =>
                  enterOnlyAlphanumericAsInput(e.target.value, "title")
                }
              />
            </Col>

            <Col>
              <Label>Promo Code</Label>
              <Input
                autoFocus
                type="text"
                value={promoCode}
                id="title"
                name="promocode"
                placeholder="Title"
                onChange={(e) =>
                  enterOnlyAlphanumericAsInput(e.target.value, "promocode")
                }
              />
            </Col>

            <Col>
              <Label>Description</Label>
              <Input
                autoFocus
                type="text"
                value={description}
                id="title"
                name="description"
                placeholder="Title"
                onChange={(e) =>
                  enterOnlyAlphanumericAsInput(e.target.value, "description")
                }
              />
            </Col>
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="hostel">
                  Cafe
                </Label>
                <Select
                  isMulti
                  isClearable={false}
                  isSearchable={true}
                  theme={selectThemeColors}
                  id="hostel"
                  name="hostel"
                  options={cafeList}
                  value={cafe}
                  onChange={(value) => setCafe(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="couponType">
                  Type
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="couponType"
                  name="couponType"
                  options={typeOptions}
                  value={couponType}
                  onChange={(value) => setCouponType(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="couponType">
                  Category
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="couponType"
                  name="couponType"
                  options={categoryOptions}
                  value={couponCategory}
                  onChange={(value) => setCouponCategory(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="couponStatus">
                  Status
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="couponStatus"
                  name="couponStatus"
                  options={CouponStatusList}
                  value={couponStatus}
                  onChange={(value) => setCouponStatus(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="endDate">
                  Expiry Date
                </Label>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  value={expiryDate}
                  onChange={(date) =>
                    setExpiryDate(new Date(dateFormatter(new Date(date))))
                  }
                  id="endDate"
                  name="endDate"
                  placeholder="End Date"
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="applicableDays">
                  Coupon Valid Days
                </Label>
                <Select
                  isMulti
                  isClearable={false}
                  isSearchable={true}
                  theme={selectThemeColors}
                  id="applicableDays"
                  name="applicableDays"
                  options={daysList}
                  value={validDays}
                  onChange={(value) => {
                    setValidDays(value);
                  }}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
            <Col>
              <label>Discount</label>
              <Input
                type="text"
                value={discount}
                id="discount"
                name="discount"
                placeholder="Discount"
                onChange={(e) =>
                  enterOnlyNumberAsInput(e.target.value, "discount")
                }
              />
            </Col>

            <Col>
              <label>Max discount value</label>
              <Input
                type="text"
                value={maxDiscountValue}
                id="maxDiscountValue"
                name="maxDiscountValue"
                placeholder="Max Discount Value"
                onChange={(e) =>
                  enterOnlyNumberAsInput(e.target.value, "maxDiscountValue")
                }
              />
            </Col>

            <Col>
              <label>Min Order Amount</label>
              <Input
                type="text"
                value={minOrderValue}
                id="minOrderValue"
                name="minOrderValue"
                placeholder="Min Order Value"
                onChange={(e) =>
                  enterOnlyNumberAsInput(e.target.value, "minOrderValue")
                }
              />
            </Col>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
