import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateBlogsBlog } from "../../../api/dataSource";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const WhatsNext = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateBlogsBlogHandler, { data: updatedBlogsBlogData }] =
    useUpdateBlogsBlog();
  const [whatsNext, setWhatsNext] = useState([
    {
      id: uuidv4(),
      title: "",
      urlText: "",
      link: "",
    },
  ]);

  useEffect(() => {
    if (state?.tableData?.whatsNext?.length) {
      let upadtedWhatsNext = state?.tableData?.whatsNext?.map((item) => {
        return {
          id: uuidv4(),
          title: item?.title,
          urlText: item?.urlText,
          link: item?.link,
        };
      });
      setWhatsNext(upadtedWhatsNext);
    }
  }, [state]);

  useEffect(() => {
    if (updatedBlogsBlogData?.updateBlog?._id) {
      toaster("success", "Submitted successfully");
    }
  }, [updatedBlogsBlogData]);

  const handleRemoveWhatsNext = (id) =>
    confirmDelete(() =>
      setWhatsNext(whatsNext.filter((item) => item.id !== id))
    );

  const handleAddWhatsNext = () =>
    setWhatsNext([
      ...whatsNext,
      { id: uuidv4(), title: "", urlText: "", link: "" },
    ]);

  const handleChangeWhatsNextTitle = (title, id) => {
    setWhatsNext(
      whatsNext?.map((item) =>
        item?.id === id ? { ...item, title: title } : item
      )
    );
  };

  const handleChangeWhatsNextUrlText = (urlText, id) => {
    setWhatsNext(
      whatsNext?.map((item) =>
        item?.id === id ? { ...item, urlText: urlText } : item
      )
    );
  };

  const handleChangeWhatsNextLink = (link, id) => {
    setWhatsNext(
      whatsNext?.map((item) =>
        item?.id === id ? { ...item, link: link } : item
      )
    );
  };

  const submitDatahandler = () => {
    let isValid = false;

    let data = {
      whatsNext: whatsNext,
    };

    whatsNext?.forEach((item) => {
      if (item?.title && item?.urlText && item?.link) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (
      isValid &&
      id &&
      data?.whatsNext?.every((whatsNext) => whatsNext !== undefined)
    ) {
      whatsNext?.forEach((item) => delete item.id);

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          whatsNext: data?.whatsNext,
        })
      );
      updateBlogsBlogHandler(id, data);
      toaster("success", "Whats next updated successfully");
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDatahandler();
      }}
    >
      <Row className="mt-2">
        {whatsNext.map(({ title, urlText, link, id }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={id}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"whatsNext" + id}>
                What's Next {index + 1}
              </Label>

              <Col>
                <Label className="form-label" htmlFor={"title" + id}>
                  Title
                </Label>
                <Input
                  type="text"
                  value={title}
                  id={"title" + id}
                  name={"Title"}
                  placeholder="Title"
                  onChange={(e) =>
                    handleChangeWhatsNextTitle(e.target.value, id)
                  }
                />
              </Col>
              <br />
              <Col>
                <Label className="form-label" htmlFor={"urlText" + id}>
                  Url Text
                </Label>
                <Input
                  type="text"
                  value={urlText}
                  id={"uelText" + id}
                  name={"urlText"}
                  placeholder="Url Text"
                  onChange={(e) =>
                    handleChangeWhatsNextUrlText(e.target.value, id)
                  }
                />
              </Col>
              <br />
              <Col>
                <Label className="form-label" htmlFor={"link" + id}>
                  Link
                </Label>
                <Input
                  type="text"
                  value={link}
                  id={"link" + id}
                  name={"Link"}
                  placeholder="Link"
                  onChange={(e) =>
                    handleChangeWhatsNextLink(e.target.value, id)
                  }
                />
              </Col>
              <br />
            </FormGroup>
            {whatsNext.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveWhatsNext(id)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddWhatsNext} />
    </form>
  );
};

export default WhatsNext;
