import Category from "../../views/Blogs/Category";
import MessageTemplate from "../../views/Communication/MessageTemplate/index"
import MessageConfiguration from "../../views/Communication/MessageConfiguration/index"

const CommunicationRoutes = [
  {
    path: "/communications/messageTemplate",
    component: MessageTemplate,
    exact: true,
  },
  {
    path: "/communications/messageConfiguration",
    component: MessageConfiguration,
    exact: true,
  }
];

export default CommunicationRoutes;
