import React from "react";
import { Edit2 } from "react-feather";
import { Button, Badge } from "reactstrap";

const CancellationTableList = ({ tableRowData, openModal, handleEdit }) => {
  const edit = (e, id) => {
    e.preventDefault();
    openModal();
    handleEdit(id);
  };
  return (
    <>
      {tableRowData.map(
        ({ id, message, minDay, maxDay, usedCount, deduction, status }) => {
          return (
            id && (
              <tr key={id}>
                <td>{message}</td>
                <td>{minDay}</td>
                <td>{maxDay}</td>
                <td>{usedCount}</td>
                <td>{deduction}</td>
                <td>
                  <Badge
                    pill
                    color={status ? "light-success" : "light-danger"}
                    className='mr-1'
                  >
                    {status ? "Active" : "Inactive"}
                  </Badge>
                </td>
                <td>
                  <Button
                    className='rounded-circle btn-icon'
                    color='none'
                    onClick={(e) => edit(e, id)}
                  >
                    <Edit2 size={15} />
                  </Button>
                </td>
              </tr>
            )
          );
        }
      )}
    </>
  );
};

export default CancellationTableList;
