import React, { useEffect, useState } from "react";
import {
  useGetDaywiseSalesReport,
  useGetDaywiseSalesReportExport,
} from "../../../api/Unbox/UnboxSalesReport/Queries";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import Report, { generateDateRange } from "./Report";
import { selectThemeColors } from "@utils";
import {
  useGetAllCafeMasterList,
  useGetCafeByHostelId,
} from "../../../api/Unbox/UnboxCafeMaster/Queries";
const { dateFormatter } = require("../../../utility/Utils");

const DaywiseSales = () => {
  const hostelId = JSON.parse(localStorage.getItem("hostels")) || [];
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [cafe, setCafe] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [searchCafe, setSearchCafe] = useState(null);
  const [searchFromDate, setSearchFromDate] = useState(null);
  const [searchToDate, setSearchToDate] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [cafelist, setCafelist] = useState(null);
  const [dateList, setDateList] = useState(null);

  const { data: cafes } = useGetCafeByHostelId(hostelId);

  const { data: cafeListData } = useGetAllCafeMasterList();

  const { data } = useGetDaywiseSalesReport(
    searchFromDate,
    searchToDate,
    searchCafe
  );

  const { data: Data } = useGetDaywiseSalesReportExport(exportData, dateList);
  // const downloadReport = (Data) => {
  //   if (Data?.getDaywiseSalesExportReport?.data) {
  //     if (Data?.getDaywiseSalesExportReport?.data.length > 0) {
  //       const byteArray = new Uint8Array(
  //         Data?.getDaywiseSalesExportReport?.data
  //       );
  //       const blob = new Blob([byteArray], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = `unbox_Daywise_SalesReport_pg.xlsx`;
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       document.body.removeChild(a);
  //     }
  //     setExportData(null);
  //   }
  // };

  useEffect(() => {
    if (data?.getDaywiseSales?.length > 0) {
      setTableData(data?.getDaywiseSales);
    } else if (data?.getDaywiseSales?.length === 0) {
      setTableData([]);
    }
  }, [data?.getDaywiseSales]);

  useEffect(() => {
    if (hostelId?.length !== 0 && cafes?.getCafeByHostelId?.length) {
      const cafeArray = cafes?.getCafeByHostelId?.map((cafe) => {
        return { label: cafe?.cafeName, value: cafe?._id };
      });

      setCafelist(cafeArray);
    } else {
      const cafeArray = cafeListData?.getAllCafeMasterList?.map((cafe) => {
        return { label: cafe?.cafeName, value: cafe?._id };
      });

      setCafelist(cafeArray);
    }
  }, [cafes?.getCafeByHostelId, cafeListData, hostelId?.length]);

  useEffect(() => {
    if (Data?.getDaywiseSalesExportReport?.data) {
      if (Data?.getDaywiseSalesExportReport?.data.length > 0) {
        const byteArray = new Uint8Array(
          Data?.getDaywiseSalesExportReport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `unbox_Daywise_SalesReport.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setExportData(null);
      }
    }
  }, [Data]);

  const handleDateRange = (value, name) => {
    try {
      if (name === "fromDate") {
        setFromDate(dateFormatter(new Date(value)));
      } else if (name === "toDate") {
        setToDate(dateFormatter(new Date(value)));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearch = () => {
    if (fromDate && toDate && new Date(fromDate) <= new Date(toDate)) {
      setSearchFromDate(fromDate);
      setSearchToDate(toDate);
      if (cafe?.length > 0) {
        setSearchCafe(cafe?.map((i) => i?.value));
      } else {
        setSearchCafe(null);
      }
    } else {
      toaster(
        "error",
        "Please select date range with start date should be less than end date"
      );
    }
  };

  const handleExport = () => {
    const dateList = generateDateRange(searchFromDate, searchToDate);
    if (data?.getDaywiseSales?.length > 0) {
      setExportData(data?.getDaywiseSales);
      setDateList(dateList);
    } else {
      toaster("error", "No data to export");
    }
  };

  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setTableData(null);
    setSearchFromDate(null);
    setSearchToDate(null);
    setSearchCafe(null);
    setCafe(null);
    setExportData(null);
    setDateList(null);
  };

  return (
    <div>
      <Row>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              <strong>Start Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromDate}
              onChange={(date) => {
                handleDateRange(date, "fromDate");
                //setFromDate(dateFormatter(new Date(date)));
              }}
              id="fromDate"
              placeholder="From Date"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="toDate">
              <strong>End Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toDate}
              onChange={(date) => {
                //setToDate(dateFormatter(new Date(date)));
                handleDateRange(date, "toDate");
              }}
              id="toDate"
              placeholder="To Date"
            />
          </FormGroup>
        </Col>
        {cafelist?.length !== 1 && (
          <Col sm="3" style={{ zIndex: 3 }}>
            <FormGroup className="w-100">
              <Label className="form-label" for="cafe">
                <strong>Cafe</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="cafe"
                name="cafe"
                value={cafe}
                closeMenuOnSelect={false}
                options={cafelist}
                onChange={(value) => {
                  setCafe(value);
                  //console.log(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        )}
        <Col className="" sm="3" style={{ display: "flex", gap: "30px" }}>
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              //marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            style={{
              backgroundColor: "##ff9999",
              height: "40px",
              marginTop: "22px",
              //marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="success"
            appearance="success"
            type="button"
            onClick={handleExport}
          >
            Export
          </Button>
        </Col>
      </Row>
      {tableData?.length > 0 ? (
        <div>
          <Report
            daywiseData={tableData}
            fromDate={searchFromDate}
            toDate={searchToDate}
          />
        </div>
      ) : tableData?.length === 0 ? (
        <h5 style={{ textAlign: "center" }}>No orders</h5>
      ) : (
        <h5 style={{ textAlign: "center" }}>Please select Date Range</h5>
      )}
    </div>
  );
};

export default DaywiseSales;
