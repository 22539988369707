import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user.reducer";
import layoutReducer from "./layout/layout.reducer";
import navbarReducer from "./navbar/navbar.reducer";
import cmsReducer from "./Cms/cms.reducer";
import loaderReducer from "./loader/loader.reducer";

const persistConfig = {
  key: "HostellerAdmin",
  storage,
  whitelist: ["user", "loader"],
};

const appReducer = combineReducers({
  user: userReducer,
  cms: cmsReducer,
  navbar: navbarReducer,
  layout: layoutReducer,
  loader: loaderReducer,
});

export default persistReducer(persistConfig, appReducer);
