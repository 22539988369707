// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import { useParams } from "react-router";
import confirmDelete from "@components/common/confirmDelete";
import {
  useDestinationList,
  useUpdateHomePageDetails,
} from "../../../../api/dataSource";

const Destination = () => {
  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [updateHomePageDetailsHandler, { data: updatedDestinationsData }] =
    useUpdateHomePageDetails();
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [DestinationsList, setDestinationsList] = useState([]);
  const { data: allDestinationsData } = useDestinationList();

  useEffect(() => {
    if (state?.tableData?.destinations.length) {
      let alreadySelectedDestinations = state?.tableData?.destinations?.map(
        (item) => {
          return {
            uuId: uuidv4(),
            destinations: { label: item?.destination, value: item?._id },
          };
        }
      );
      setSelectedDestination(alreadySelectedDestinations);
    }
  }, [state]);

  const handleAddDestinations = () =>
    setSelectedDestination([
      ...selectedDestination,
      {
        uuId: uuidv4(),
        destinations: [],
      },
    ]);

  const handleRemoveSelectedDestination = (uuId) =>
    confirmDelete(() =>
      setSelectedDestination(
        selectedDestination.filter((item) => item.uuId !== uuId)
      )
    );

  const handleChangeSelectedHostel = (destination, uuId) => {
    setSelectedDestination(
      selectedDestination?.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              destinations: {
                label: destination.label,
                value: destination.value,
              },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let DestinationsList = [];
    if (allDestinationsData?.getDestinationList?.length) {
      // eslint-disable-next-line array-callback-return
      allDestinationsData?.getDestinationList?.map((item) => {
        DestinationsList.push({ label: item?.destination, value: item?._id });
        setDestinationsList(DestinationsList);
      });
    }
  }, [allDestinationsData?.getDestinationList]);

  const [finalDestinationArr, setFinalDestinationArr] = useState([]);

  useEffect(() => {
    if (updatedDestinationsData) {
      let updatedDestinations = [];
      finalDestinationArr.forEach((i) => {
        updatedDestinations.push(
          allDestinationsData?.getDestinationList?.find((j) => j._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          destinations: updatedDestinations,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allDestinationsData?.getHostelList,
    dispatch,
    finalDestinationArr,
    updatedDestinationsData,
  ]);

  const submitDataHandler = () => {
    var finalListOfHostelsId = [];
    selectedDestination?.forEach((item) => {
      finalListOfHostelsId.push(item.destinations.value);
    });
    setFinalDestinationArr(finalListOfHostelsId);
    let data = {
      destinations: finalListOfHostelsId,
    };

    // if (data?.destinations?.length > 0) {
    if (
      id &&
      data?.destinations?.every((destination) => destination !== undefined)
    ) {
      updateHomePageDetailsHandler(id, {
        destinations: data.destinations,
      });
      toaster("success", "Destinations updated successfully");
    } else {
      toaster("error", "Please select the destinations");
    }
    // } else {
    //   toaster("error", "Please select at least one destination");
    // }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDataHandler();
      }}
    >
      <Row className="mt-2">
        {selectedDestination?.map(({ uuId, destinations }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={uuId}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"reviews" + uuId}>
                Selected Destination {index + 1}
              </Label>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Select
                      isSearchable={true}
                      id={uuId}
                      name="destination"
                      value={destinations}
                      options={DestinationsList}
                      onChange={(value) => {
                        handleChangeSelectedHostel(value, uuId);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {selectedDestination.length > 0 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveSelectedDestination(uuId)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>
      <SaveDiscardBtnGroup addAction={handleAddDestinations} />
    </form>
  );
};

export default Destination;
