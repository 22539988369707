import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  useGetAllTravelPackages,
  useHostelList,
} from "../../../api/dataSource";
import TableList from "../../components/common/TableList";
import { TABLE_TYPE } from "../common/TableList/constant";

const tableHeaderList = [
  "Action",
  "Membership",
  "Hostel Name",
  "Room Name",
  "Status",
  "Checkin-Checkout",
];

const TravelPackagesTable = ({
  AddEditModal,
  page,
  setPage,
  filteredData,
}) => {
  const [limit, setLimit] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useGetAllTravelPackages(
    limit,
    page,
    filteredData
  );
  const { data: hostelData } = useHostelList();

  const [show, setShow] = useState("");
  const [editTravelPackageId, setEditTravelPackageId] = useState(null);
  const [refetchTravelPackages, setRefetchTravelPackages] = useState(false);

  const history = useHistory();



  useEffect(() => {
    if (data?.getAllTravelPackages) {
      data?.getAllTravelPackages?.data &&
        setTableData(data?.getAllTravelPackages?.data);

      setTotalPage(Math.ceil(data?.getAllTravelPackages?.count / limit));
    }
  }, [data?.getAllTravelPackages]);

  useEffect(() => {
    if (editId && data?.getAllTravelPackages?.data?.length) {
      let editData = data?.getAllTravelPackages?.data.find(
        (item) => item._id === editId
      );

      setEditData(editData);
    } else {
      setEditId(null);
      setEditData(null);
    }
  }, [editId, data]);

  const handleToggle = () => {
    setOpenModal(!openModal);
    if (editTravelPackageId) setEditTravelPackageId(null);
  };

  useEffect(() => {
    if (!openModal) {
      setEditId(null);
      setEditData(null);
    }
  }, [openModal]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/cms/edit/travelPackage/${id}`);
    } else {
      setEditId(id);
    }
  };

  const handleOpenModal = () => setOpenModal(!openModal);

  const handleAdd = () => setOpenModal(true);


  return (
    <div>
      <TableList
        handleAdd={AddEditModal ? handleAdd : null}
        tableHeader="Travel Packages"
        type={TABLE_TYPE.TRAVEL_PACKAGE}
        tableHeaderList={tableHeaderList}
        tableData={tableData}
        openModal={handleOpenModal}
        handleEdit={handleEdit}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={handleToggle}
          refetch={refetch}
          editData={editData}
          selectedHostel={show}
          setRefetchTravelPackages={setRefetchTravelPackages}
          setEditId={setEditId}
        />
      )}
    </div>
  );
};

export default TravelPackagesTable;
