import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import { X, Link } from "react-feather";
import { useSelector } from "react-redux";
import { useUploadImage } from "@api";
import UrlForm from "./UrlForm";
import confirmDelete from "@components/common/confirmDelete";
import "./index.scss";
import toaster from "@components/common/Toaster";

const ImageSection = ({ imageList, setImageList, uploadType, landingPage }) => {
  const state = useSelector((state) => state?.cms);
  const [imageLink, setImageLink] = useState({});
  const [uploadingType, setUploadingType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadImageHandler, { data: imageData, reset }] = useUploadImage();
  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 1048576,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("complete", (result) => {
    result.successful.forEach((file) => {
      const fileName = file.name;

      // const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

      // if (specialCharsRegex.test(fileName)) {
      //   console.error("File name:", fileName);
      //   toaster(
      //     "error",
      //     "File name contains special characters. Please provide a valid file name."
      //   );
      //   uppy.removeFile(file.id);
      //   return;
      // }

      setUploadingType(result.successful[0]?.source, state.tableData);

      const url = state.tableData?.destination
        ? `Destination/${state.tableData?.destination
            ?.toLowerCase()
            ?.replaceAll(" ", "-")}`
        : "common";
      console.log(result?.successful[0]);
      result.successful[0]?.data &&
        uploadImageHandler(result.successful[0]?.data, `Destination`);

      toaster("success", "Image uploaded successfully");
    });
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading image: ${error.message}`);
  });

  useEffect(() => {
    const newImage = {
      type: uploadType ? uploadingType : undefined,
      key: imageData?.uploadImage?.key,
      imageCaption: "",
      displayOrder: 0,
      imageType: "",
    };

    uploadType
      ? (newImage.image = imageData?.uploadImage?.url)
      : (newImage.url = imageData?.uploadImage?.url);

    if (imageData?.uploadImage?.url) {
      setImageList((prev) => [newImage, ...prev]);

      reset();
      setUploadingType(null);
    }
  }, [imageData]);

  const removeImages = (image) => {
    const newSet = imageList.filter((item) => {
      const imgUrl = item.image ? item.image : item.url;
      return imgUrl !== image;
    });

    setImageList(newSet);
  };

  const handleRemoveImage = (image) => {
    confirmDelete(() => removeImages(image));
  };

  const openModal = (index, imageCaption, displayOrder, imageType) => {
    setShowModal(true);
    setImageLink({ index, imageCaption, displayOrder, imageType });
  };

  const closeModal = () => {
    setShowModal(false);
    setImageLink({});
  };

  const addImageLink = (index, imageCaption, displayOrder, imageType) => {
    let newImageList = [...imageList];
    //let newImageTypeList = [...newImageTypeList];

    const imgWithLink = {
      ...newImageList[index],
      imageCaption,
      displayOrder,
      imageType,
    };

    newImageList[index] = imgWithLink;

    setImageList(newImageList);
  };

  const [draggedItem, setDraggedItem] = useState(null);
  let draggedTo = null;
  const handleDragStart = (e, item) => {
    setDraggedItem(item);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    draggedTo = index;
    handleChangeDisplayOrders();
  };
  const handleChangeDisplayOrders = () => {
    // this is a working code don't touch it
    const updatedImageData = [...imageList].map((item) => ({ ...item }));

    const updatedItem = { ...updatedImageData[draggedItem] };
    if (draggedItem > draggedTo) {
      updatedItem.displayOrder = draggedTo;
    } else {
      updatedItem.displayOrder = draggedTo + 2;
    }
    updatedImageData[draggedItem] = updatedItem;
    updatedImageData.sort((a, b) => a.displayOrder - b.displayOrder);

    let currentDisplayOrder = 1;
    for (let i = 0; i < updatedImageData.length; i++) {
      updatedImageData[i] = {
        ...updatedImageData[i],
        displayOrder: currentDisplayOrder++,
      };
    }
    setImageList(updatedImageData);
  };

  return (
    <div className="imageSection__container">
      <UrlForm
        showModal={showModal}
        closeModal={closeModal}
        setShowModal={setShowModal}
        imageLink={imageLink}
        addImageLink={addImageLink}
      />

      {uploadType?.map(({ value, label }) => (
        <div key={value}>
          <h3 className="imageSection__header mt-2">Upload {label} images</h3>
          <p>
            Recommended dimension -{" "}
            {label === "Mobile" ? "575 x 185 or 375 x 175" : "1200 x 800"}
          </p>
          <div className="imageSection__imagesContainer">
            {imageList?.map((item, index) => {
              if (item.type === value || (!item.type && value === "mobile")) {
                // removed the line from above ===> || item.url
                const image = item.image || item.url;

                return (
                  <div
                    key={index}
                    className="imagesection__imageBox"
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e, index)}
                  >
                    <img
                      className="imageSection__image"
                      src={image}
                      alt="avatar"
                    />
                    <div className="imageSection_footer">
                      <div className="imageSection_icon imageSection__index">
                        {item.displayOrder}
                      </div>
                      {landingPage === true ? (
                        <div
                          onClick={() =>
                            openModal(
                              index,
                              item.imageCaption,
                              item.displayOrder,
                              item.imageType
                            )
                          }
                          className="imageSection_icon imageSection__link"
                        >
                          <Link size={25} />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div
                        onClick={() => handleRemoveImage(image)}
                        className=" imageSection_icon imageSection__removeImage"
                      >
                        <X size={25} />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div className="imageSection__addImage ml-2">
              <DragDrop
                id={value}
                width="12em"
                height="12em"
                locale={{
                  strings: {
                    dropHereOr: "Max 1 MB",
                  },
                }}
                uppy={uppy}
              />
            </div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default ImageSection;
