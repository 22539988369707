import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateAbout, useUploadImage } from "@api";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { v4 as uuidv4 } from "uuid";
import { X, Camera } from "react-feather";
import Avatar from "@components/common/avatar";
import confirmDelete from "@components/common/confirmDelete";

const Product = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [index, setIndex] = useState("");
  const [cards, setCards] = useState([
    {
      id: uuidv4(),
      title: "",
      description: "",
    },
  ]);

  const [updateAbout, { data, reset }] = useUpdateAbout();
  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  useEffect(() => {
    if (state.tableData?.product) {
      setHeading(state.tableData.product.heading);
      setDescription(state.tableData.product.description);

      let upadtedCards = state.tableData.product.cards?.map((item) => {
        return {
          id: uuidv4(),
          title: item?.title,
          description: item?.description,
          icon: item?.icon,
        };
      });

      setCards(upadtedCards);
    }
  }, [state.tableData]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setCards(
        cards.map((item) => {
          if (item.id === index) {
            return { ...item, icon: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  useEffect(() => {
    if (data?.updateAbout) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          product: { heading, description, cards },
        })
      );

      reset();
    }
  }, [data?.updateAbout]);

  const handleRemoveCards = (id) =>
    confirmDelete(() => setCards(cards.filter((item) => item.id !== id)));

  const handleAddCards = () => {
    if (cards.length < 5) {
      setCards([...cards, { id: uuidv4(), title: "" }]);
    } else toaster("error", "Maximum limit of 5 has reached");
  };

  const handleChangeCardName = (name, id) => {
    setCards(
      cards.map((item) => (item.id === id ? { ...item, title: name } : item))
    );
  };

  const handleChangeCardDescription = (value, id) => {
    setCards(
      cards.map((item) =>
        item.id === id ? { ...item, description: value } : item
      )
    );
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);
    uploadImageHandler(e.target.files[0], "About");
  };

  const submitDataHandler = (e) => {
    e.preventDefault();

    let isValid = true;

    const product = {
      heading,
      description,
      cards,
    };

    cards?.forEach((item) => {
      isValid &&
        (item.title && item.description && item.icon
          ? (isValid = true)
          : (isValid = false));
    });

    if (heading && description && isValid) {
      cards?.forEach((item) => delete item.id);

      updateAbout({ product });
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="heading">
              Heading
            </Label>

            <Input
              type="text"
              value={heading}
              id="heading"
              name="heading"
              placeholder="Heading"
              onChange={(e) => setHeading(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="description">
              Description
            </Label>

            <Input
              type="textarea"
              value={description}
              id="description"
              name="description"
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        </Col>

        <br />

        <Col className="mt-3 mb-1" sm="12">
          <h3>Cards (Max - 5)</h3>
        </Col>

        {cards.map(({ title, icon, description, id }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={index}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"cards" + id}>
                Card {index + 1}
              </Label>

              <div className="mt-2 d-flex align-items-center">
                <div className="mb-1 mr-2">
                  {<Avatar size="lg" img={icon} alt={icon} />}
                </div>

                <label htmlFor={`myFile${id}`}>
                  <p className="btn btn-sm btn-light">
                    <Camera size={15} />
                  </p>
                  <input
                    name={`myFile${id}`}
                    id={`myFile${id}`}
                    className="d-none"
                    type="file"
                    onChange={(e) => fileUploader(e, id)}
                  />
                </label>
              </div>

              <Input
                type="text"
                value={title}
                id={"cards" + id}
                name={"Title"}
                placeholder="Title"
                onChange={(e) => handleChangeCardName(e.target.value, id)}
              />

              <br />

              <Input
                rows={4}
                type="textarea"
                value={description}
                id={"description" + id}
                name={"Add Description"}
                placeholder="Add Description"
                onChange={(e) =>
                  handleChangeCardDescription(e.target.value, id)
                }
              />
            </FormGroup>
            {cards.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveCards(id)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddCards} />
    </form>
  );
};

export default Product;
