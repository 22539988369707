import { Grid, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));

export default [
      {
        id: "connect",
        title: "Connect",
        icon: <Grid size={20} />,
        badge: "light-warning",
        navLink: "/connect/connectHomepage",
        
      },
    ];
