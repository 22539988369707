import { useEffect, useState } from "react";
import { MoreVertical, Edit, Trash } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import {
  Table,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Avatar from "@components/common/avatar";
import { useDeleteStaff } from "@api";
import { useGetAllCoupons } from "@api";
import toaster from "@components/common/Toaster";
import { dateFormatHandler } from "@utils";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

const tableHeaderData = ["Action", "Hostel Location", "Hostel Name", "Status"];

const PartnerNewTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState(data);
  const [deleteStaffHandler] = useDeleteStaff();

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    }
  }, [data]);

  const handleDelete = (e, item) => {
    e.preventDefault();
    deleteStaffHandler(item._id);
    refetch();

    toaster("success", "Staff deleted successfully");
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <h2>Partners</h2>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Partner
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "5%" }}>
                    <UncontrolledDropdown>
                      {/* <DropdownToggle
                        className="icon-btn hide-arrow"
                        color="transparent"
                        size="sm"
                        caret
                      >
                        <MoreVertical size={15} />
                      </DropdownToggle> */}
                      {/* <DropdownMenu right> */}
                      <DropdownItem
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                      >
                        <Edit
                          className="mr-50"
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                        {/* <span className="align-middle">Edit</span> */}
                      </DropdownItem>
                      {/* <DropdownItem
                          href="/"
                          onClick={(e) => handleDelete(e, item)}
                        >
                          <Trash className="mr-50" size={15} />
                          <span className="align-middle">Delete</span>
                        </DropdownItem> */}
                      {/* </DropdownMenu> */}
                    </UncontrolledDropdown>
                  </td>
                  <td>{item?.hostelLocation}</td>
                  <td>{item?.hostelName}</td>
                  <td>{item?.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default PartnerNewTable;
