// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import CouponsTable from "./CouponsTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetAllPaginatedCoupons } from "../../api/dataSource";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import Select from "react-select";

const Coupons = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [status, setStatus] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filter, setFilter] = useState(null);

  const { data, refetch } = useGetAllPaginatedCoupons(
    limit,
    page,
    // status?.value,
    // searchKeyword
    filter
  ); // ! Coded by Ashwin

  const CouponStatusList = [
    { label: "paused", value: "paused" },
    { label: "live", value: "live" },
    { label: "completed", value: "completed" },
  ];

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  // Modiefied by Dushynat to Call when Proper access
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("modules"))?.includes("Coupon Admin")) {
      refetch();
    }
  }, [status, page]);

  // useEffect(() => {
  //   refetch();
  // }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedCouponList?.data.length > 0) {
      setTableData(data?.getPaginatedCouponList?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedCouponList?.count / limit));
  }, [
    data?.getPaginatedCouponList?.count,
    data?.getPaginatedCouponList?.data,
    limit,
  ]);

  useEffect(() => {
    if (data?.getPaginatedCouponList?.data.length) {
      let editData = data?.getPaginatedCouponList?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedCouponList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleSearch = () => {
    const data = {
      searchKeyword,
      status: status?.value,
    };
    setFilter(data);
    setPage(1);
  };

  const handleReset = () => {
    setSearchKeyword("");
    setStatus({});
    setFilter(null);
    setPage(1);
  };

  return (
    JSON.parse(localStorage.getItem("modules"))?.includes("Coupon Admin") && (
      <div>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Promo Code</strong>
              </Label>
              <Input
                type="text"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                placeholder="Promo Code"
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Status</strong>
              </Label>
              {/* <Label className="form-label" for="status">
              <h5>Check coupons status*</h5>
            </Label> */}
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                id="status"
                name="status"
                value={status}
                options={CouponStatusList}
                onChange={(value) => {
                  setStatus(value);
                }}
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>

            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        {/* {tableData.length > 0 ? (
          <> */}
        <CouponsTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          refetch={refetch}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
        {/* </>
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Record Found
          </h2>
        )} */}
      </div>
    )
  );
};

export default Coupons;
