import React, { useState, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import SwitchIcon from "@components/common/switch-icon";
import { useSelector, useDispatch } from "react-redux";
import { tableDataHandler } from "@store/Cms/cms.action";
import { useUpdateStepoutBlog } from "../../../api/dataSource";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";

const Overview = () => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const [blogTitle, setBlogTitle] = useState("");
  const [blogCategory, setBlogCategory] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const [tags, setTags] = useState("");
  const [timetoRead, setTimeToRead] = useState("");
  const [publishDate, setPublishDate] = useState(new Date());
  const [displayOrder, setDisplayOrder] = useState("");
  const [url, setUrl] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [status, setStatus] = useState("");
  const [seo, setSeo] = useState("");

  const dispatch = useDispatch();

  const [updateStepoutBlogHandler, { data: updatedStepoutBlogData }] =
    useUpdateStepoutBlog();

  useEffect(() => {
    if (state.tableData) {
      setBlogTitle(state.tableData?.blogTitle);
      setBlogCategory(state.tableData?.blogCategory);
      setAuthorName(state.tableData?.authorName);
      setShortDescription(state.tableData?.shortDescription);
      setDisplayOrder(state.tableData?.displayOrder);
      setMetaTitle(state.tableData?.seo?.metaTitle);
      setMetaDesc(state.tableData?.seo?.metaDesc);
      setKeywords(state.tableData?.seo?.keywords);
      setUrl(state.tableData?.url);
      setStatus(state.tableData?.status);
      setTags(state.tableData?.tags);
      setTimeToRead(state.tableData?.timetoRead);
      setPublishDate(state.tableData?.publishDate);
    } else {
      setBlogTitle("");
      setBlogCategory("");
      setAuthorName("");
      setShortDescription("");
      setDisplayOrder("");
      setUrl("");
      setSeo("");
      setStatus(false);
      setTags("");
      setTimeToRead("");
      setPublishDate("");
    }
  }, [state.tableData]);

  const submitHandler = (e) => {
    let isValid = false;
    e.preventDefault();

    const data = {
      blogTitle,
      authorName,
      shortDescription,
      // featuredImage,
      displayOrder: parseInt(displayOrder),
      url,
      seo: { metaTitle, metaDesc, keywords },
      status,
      tags,
      timetoRead,
      publishDate,
    };

    if (id) {
      updateStepoutBlogHandler(id, data);
      toaster("success", "Overview updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="blogTitle">
              Blog Title
            </Label>
            <Input
              type="text"
              value={blogTitle}
              id="blogTitle"
              name="blogTitle"
              placeholder="Add Blog Title"
              onChange={(e) => setBlogTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="authorName">
              Author Name
            </Label>
            <Input
              type="text"
              value={authorName}
              id="authorName"
              name="authorName"
              placeholder="Add Author Name"
              onChange={(e) => setAuthorName(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="shortDescription">
              Short Description
            </Label>
            <Input
              type="textarea"
              value={shortDescription}
              id="shortDescription"
              name="shortDescription"
              placeholder="Add Short Description"
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="tags">
              Tags
            </Label>
            <Input
              type="text"
              value={tags}
              id="tags"
              name="tags"
              placeholder="Add Tags"
              onChange={(e) => setTags(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="timeToRead">
              Time To Read
            </Label>
            <Input
              type="text"
              value={timetoRead}
              id="timeToRead"
              name="timeToRead"
              placeholder="Add Time To Read"
              onChange={(e) => setTimeToRead(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="publish date">
              Publish Date
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={publishDate}
              onChange={(date) => setPublishDate(date)}
              id="publishDate"
              placeholder="Add Publish Date"
              required
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="displayOrder">
              Display Order
            </Label>
            <Input
              type="text"
              value={displayOrder}
              id="displayOrder"
              name="displayOrder"
              placeholder="Add Display Order"
              onChange={(e) => setDisplayOrder(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              URL (Format: /blogs/blog-name)
            </Label>
            <Input
              type="text"
              value={url}
              id="url"
              name="url"
              placeholder="Add URL"
              onChange={(e) => setUrl(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="seo">
              SEO
            </Label>

            <Col sm="11">
              <Label className="form-label" for="seo">
                Meta Title
              </Label>
              <Input
                type="text"
                value={metaTitle}
                id="metaTitle"
                name="metaTitle"
                placeholder="Add Meta Title"
                onChange={(e) => setMetaTitle(e.target.value)}
              />
            </Col>
            <br />

            <Col sm="11">
              <Label className="form-label" for="seo">
                Meta Description
              </Label>
              <Input
                type="text"
                value={metaDesc}
                id="metaDesc"
                name="metaDesc"
                placeholder="Add Meta Desc"
                onChange={(e) => setMetaDesc(e.target.value)}
              />
              <br />
            </Col>

            <Col sm="11">
              <Label className="form-label" for="seo">
                Keywords
              </Label>
              <Input
                type="text"
                value={keywords}
                id="keywords"
                name="keywords"
                placeholder="Add Keywords"
                onChange={(e) => setKeywords(e.target.value)}
              />
            </Col>
          </FormGroup>
        </Col>

        <Col sm="6">
          <SwitchIcon
            checked={status}
            id="status"
            name="status"
            label="Publish on Website ?"
            handleChecked={(value) => setStatus(value)}
          />
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Overview;
