import gql from "graphql-tag";

export const UPDATE_STEP_OUT_HOMEPAGE_DETAILS = gql`
  mutation updateStepOutHomepage(
    $slug: String
    $stepOutHomepageUpdate: StepOutHomepageUpdate
  ) {
    updateStepOutHomepage(slug: $slug, stepOutHomepageUpdate: $stepOutHomepageUpdate) {
      _id
    }
  }
`;