import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateAbout, useUploadImage } from "@api";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { v4 as uuidv4 } from "uuid";
import { X, Camera } from "react-feather";
import Avatar from "@components/common/avatar";
import confirmDelete from "@components/common/confirmDelete";

const Featured = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [heading, setHeading] = useState("");
  const [index, setIndex] = useState("");
  const [images, setimages] = useState([{ id: uuidv4() }]);

  const [updateAbout, { data, reset }] = useUpdateAbout();
  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  useEffect(() => {
    if (state.tableData?.featured) {
      setHeading(state.tableData.featured.heading);

      let upadtedCards = state.tableData.featured.images?.map((item) => {
        return {
          id: uuidv4(),
          icon: item,
        };
      });

      setimages(upadtedCards);
    }
  }, [state.tableData]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setimages(
        images.map((item) => {
          if (item.id === index) {
            return { ...item, icon: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  useEffect(() => {
    if (data?.updateAbout) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          featured: { heading, images: images.map((img) => img.icon) },
        })
      );

      reset();
    }
  }, [data?.updateAbout]);

  const handleRemoveCards = (id) =>
    confirmDelete(() => setimages(images.filter((item) => item.id !== id)));

  const handleAddCards = () => {
    if (images.length < 5) {
      setimages([...images, { id: uuidv4(), title: "" }]);
    } else toaster("error", "Maximum limit of 5 has reached");
  };

  const handleChangeCardName = (name, id) => {
    setimages(
      images.map((item) => (item.id === id ? { ...item, title: name } : item))
    );
  };

  const handleChangeCardDescription = (value, id) => {
    setimages(
      images.map((item) =>
        item.id === id ? { ...item, description: value } : item
      )
    );
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);
    uploadImageHandler(e.target.files[0], "About");
  };

  const submitDataHandler = (e) => {
    e.preventDefault();

    let isValid = true;

    const featured = {
      heading,
      images: images.map((img) => img.icon),
    };

    images?.forEach((item) => {
      isValid && (item.icon ? (isValid = true) : (isValid = false));
    });

    if (heading && isValid) {
      images?.forEach((item) => delete item.id);

      updateAbout({ featured });
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="heading">
              Heading
            </Label>

            <Input
              type="text"
              value={heading}
              id="heading"
              name="heading"
              placeholder="Heading"
              onChange={(e) => setHeading(e.target.value)}
            />
          </FormGroup>
        </Col>

        <br />

        <Col className="mt-3 mb-1" sm="12">
          <h3>Icons (Max - 5)</h3>
        </Col>

        {images.map(({ icon, id }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={index}>
            <FormGroup>
              <Label className="form-label" htmlFor={"cards" + id}>
                Icon {index + 1}
              </Label>

              <div className="mt-2 d-flex align-items-center">
                <div className="mb-1 mr-2">
                  {<Avatar size="xl" img={icon} alt={icon} />}
                </div>

                <label htmlFor={`myFile${id}`}>
                  <p className="btn btn-sm btn-light">
                    <Camera size={15} />
                  </p>
                  <input
                    name={`myFile${id}`}
                    id={`myFile${id}`}
                    className="d-none"
                    type="file"
                    onChange={(e) => fileUploader(e, id)}
                  />
                </label>
                {images.length > 1 ? (
                  <>
                    <Button.Ripple
                      className="btn ml-2 mb-1"
                      color="secondary"
                      type="button"
                      size="sm"
                      onClick={() => handleRemoveCards(id)}
                    >
                      <X size={14} />
                    </Button.Ripple>
                  </>
                ) : null}
              </div>
            </FormGroup>
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddCards} />
    </form>
  );
};

export default Featured;
