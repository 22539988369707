import React, { useState } from "react";
import { Tooltip, Table as ReactTable } from "reactstrap";
import { useSkin } from "@hooks/useSkin";
import "../styles.css";
import { formatDateToDDMMYYYY } from "../../BookingDashboard/helpers";

const Table = ({
  datesToShowToTableHeader,
  dateRanges,
  showRoomType,
  data,
}) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [skin] = useSkin();
  const timeSlots = [9, 12, 16]; // Fixed time slots
  const backgroundColor = skin === "light" ? "#F3F2F7" : "#343D55";
  
  //  Toggle the visibility of the tooltip for the given id.
  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Toggle the visibility of the accordion for the given index.
  const toggleAccordion = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  //  Render a tooltip for the given id and room name.
  const renderTooltip = (id, roomName, updatedAt) => (
    <Tooltip
      placement="top"
      isOpen={tooltipOpen[id] || false}
      target={id}
      toggle={() => toggleTooltip(id)}
    >
      {roomName || "-"}
      <br />
      {updatedAt && `Updated At: ${formatDateToDDMMYYYY(updatedAt)}`}
    </Tooltip>
  );

  // Helper function to get slot data, rate, and room name
  const getSlotDataTheHosteller = (data, date, slot) => {
    const slotData = data.prices[date]?.find(
      (obj) => parseInt(Object.keys(obj)[0]) === slot
    );
    const rate = slotData ? Object.values(slotData)[0] : "-";
    const avgPrice = data.avgPrices[date]?.[slot] || null;
    const roomName = slotData ? data.roomName || null : null;
    const updatedAt = slotData ? data.updatedAt.split("T")[0] || null : null;
    return { rate, avgPrice, roomName, updatedAt };
  };

  const getSlotDataForCompetitor = (competitor, date, slot) => {
    const slotData = competitor.prices[date]?.find(
      (obj) => Object.keys(obj)[0] == slot
    );
    const rate = slotData ? Object.values(slotData)[0] : "-";
    const roomName = slotData ? competitor.roomName || null : null;
    const updatedAt = slotData
      ? (competitor?.updatedAt).split("T")[0] || null
      : null;
    return { rate, roomName, updatedAt };
  };

  return (
    <section style={{ overflow: "auto", height: "80vh" }}>
      <ReactTable bordered>
        {/* Table Headers */}
        <thead style={{ paddingBottom: "20px" }}>
          <tr style={{ position: "sticky", top: -1, zIndex: 3 }}>
            <th style={{ position: "sticky", left: -1, zIndex: 2 }}>
              Hostel Name
            </th>
            {datesToShowToTableHeader.map((date, index) => (
              <th colSpan={timeSlots.length} key={index}>
                {date}
              </th>
            ))}
          </tr>
          <tr style={{ position: "sticky", top: "35px", zIndex: 3 }}>
            <th style={{ position: "sticky", left: -1, zIndex: 2 }}></th>
            {datesToShowToTableHeader.map((date, index) =>
              timeSlots.map((slot, subIndex) => (
                <th className="th-cr" key={`${index}-${slot}`}>
                  {`${slot}:00`}
                </th>
              ))
            )}
          </tr>
        </thead>

        {/* Table Body */}
        <tbody>
          {data?.map((hostelData, index) => (
            <React.Fragment key={index}>
              {/* Hostel Row */}
              <tr>
                <td
                  onClick={() => toggleAccordion(index)}
                  style={{
                    position: "sticky",
                    left: -1,
                    zIndex: 1,
                    cursor: "pointer",
                    minWidth: "300px",
                    background: backgroundColor,
                  }}
                >
                  {hostelData.name} {expandedRows[index] ? "▲" : "▼"}
                </td>
                {dateRanges.map((date) =>
                  timeSlots.map((slot) => {
                    // Find the rate data for the given date and time slot
                    const { rate, avgPrice, roomName, updatedAt } =
                      getSlotDataTheHosteller(hostelData, date, slot);
                    const tooltipId = `Tooltip-${index}-${date}-${slot}`;
                    return (
                      <td
                        // className="table-light"
                        id={tooltipId}
                        key={tooltipId}
                        onMouseEnter={() => toggleTooltip(tooltipId)}
                        onMouseLeave={() => toggleTooltip(tooltipId)}
                        style={{
                          padding: "5px",
                          textAlign: "left",
                          background: backgroundColor,
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>{rate}</span> {/* Regular price */}
                          {avgPrice && (
                            <span style={{ color: "gray", fontSize: "0.8em" }}>
                              (Avg : {avgPrice})
                            </span>
                          )}
                          {/* Average price */}
                        </div>
                        {roomName &&
                          renderTooltip(tooltipId, roomName, updatedAt)}
                      </td>
                    );
                  })
                )}
              </tr>

              {/* Competitor Rows (Accordion) */}
              {expandedRows[index] &&
                hostelData.competitors.map((competitor, compIdx) => (
                  <tr key={`${index}-${compIdx}`}>
                    <td
                      style={{
                        position: "sticky",
                        left: -1,
                        zIndex: 1,
                        cursor: "pointer",
                        minWidth: "300px",
                        background: backgroundColor,
                        paddingLeft: "50px",
                      }}
                    >
                      {competitor.competitor}
                    </td>
                    {dateRanges.map((date) =>
                      timeSlots.map((slot) => {
                        // Find the rate data for the given date and time slot
                        const { rate, roomName, updatedAt } =
                          getSlotDataForCompetitor(competitor, date, slot);
                        const tooltipId = `Tooltip-${index}-${compIdx}-${date}-${slot}`;
                        return (
                          <td
                            id={tooltipId}
                            key={tooltipId}
                            onMouseEnter={() => toggleTooltip(tooltipId)}
                            onMouseLeave={() => toggleTooltip(tooltipId)}
                            style={{ padding: "5px", textAlign: "left" }}
                          >
                            <span>{rate}</span>
                            {roomName &&
                              renderTooltip(tooltipId, roomName, updatedAt)}
                          </td>
                        );
                      })
                    )}
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </ReactTable>
    </section>
  );
};

export default Table;
