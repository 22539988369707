import React, { useEffect, useState } from "react";
import BlacklistedGuestsTable from "./BlacklistedGuestsTable";
import TablePagination from "@components/common/TablePagination";
import { useHistory } from "react-router";
// import AddEditModal from "./AddEditModal";
import { Button, Col, FormGroup, Label, Row, Input } from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { useGetPaginatedBlacklistedGuests } from "../../../api/WebApp/BlacklistedGuests/Queries";
import { useHostelList } from "../../../api/dataSource";

const BlacklistedGuests = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [hostelsList, setHostelsList] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const [hostel, setHostel] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState();
  const [urgency, setUrgency] = useState(null);
  const [filterFeilds, setFilterFeilds] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });

  const [openModal, setOpenModal] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [requestStartDate, setRequestStartDate] = useState(null);
  const [requestEndDate, setRequestEndDate] = useState(null);
  const { data: allHostelsData } = useHostelList();
  const [vendorName, setVendorName] = useState([]);
  const [head, setHead] = useState(null);
  const [subHead, setSubHead] = useState(null);
  const [subHeadType, setSubHeadType] = useState(null);
  const [paymentDue, setPaymentDue] = useState(null);
  const [completionStartRange, setCompletionStartRange] = useState(null);
  const [completionEndDateRange, setCompletionEndDateRange] = useState(null);
  const [remark, setRemark] = useState("");
  const [itemName, setItemName] = useState("");
  const { data, loading, refetch } = useGetPaginatedBlacklistedGuests(
    limit,
    page,
    filterFeilds
  );

  console.log(data, "DATA");

  const history = useHistory();

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

    useEffect(() => {
      if (data?.getPaginatedBlacklistedGuests?.data?.length > 0) {
        setTableData(data?.getPaginatedBlacklistedGuests?.data);
      } else {
        setTableData([]);
      }

      setTotalPage(Math.ceil(data?.getPaginatedBlacklistedGuests?.count / limit));
    }, [
      data?.getPaginatedBlacklistedGuests?.data,
      data?.getPaginatedBlacklistedGuests?.count,
      limit,
    ]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

    useEffect(() => {
      if (id && data?.getPaginatedBlacklistedGuests?.data?.length > 0) {
        let findData = data?.getPaginatedBlacklistedGuests?.data?.find(
          (item) => item._id === id
        );
        setEditData(findData);
        setId(id);
      } else {
        setId(null);
        setEditData(null);
      }
    }, [data?.getPaginatedBlacklistedGuests?.data, id]);

    console.log(tableData, "TABLE DATA")

  const handleSearch = () => {
    const filterFeilds = {
      hostelIdArray:
        hostel.length > 0
          ? hostel?.map((hostel) => hostel?.value)
          : JSON.parse(localStorage.getItem("hostels")),

      status: statusFilter?.map((obj) => obj?.value),
      requestId,
      paymentStatus: paymentStatus?.value,
      urgency: urgency?.value,
      requestStartDate,
      requestEndDate,
      vendorIDs:
        vendorName.length > 0 ? vendorName?.map((item) => item?.value) : [],
      head: head?.value,
      subHead: subHead?.value,
      subHeadType: subHeadType?.value,
      paymentDue: paymentDue?.value,
      completionStartRange,
      completionEndDateRange,
      remark,
      itemName,
    };
    setPage(1);
    setFilterFeilds(filterFeilds);
  };

  const handleReset = () => {
    setFilterFeilds({
      hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    });

    setStatusFilter(null);
    setHostel([]);
    setRequestId("");
    setUrgency(null);
    setPaymentStatus(null);
    setRequestStartDate(null);
    setRequestEndDate(null);
    setVendorName([]);
    setHead(null);
    setSubHead(null);
    setSubHeadType(null);
    setPaymentDue(null);
    setCompletionStartRange(null);
    setCompletionEndDateRange(null);
    setRemark("");
    setItemName("");
  };

  const handleEdit = (id) => {
    // if (!AddEditModal && id) {
    //   history.push(`/operations/edit/goPurchase/${id}`);
    // } else {
    //   setId(id);
    // }
  };

  return (
    // localStorage.getItem("modules")?.includes("Operations") &&
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Hostel</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              closeMenuOnSelect={false}
              options={hostelsList}
              onChange={(value) => setHostel(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>


        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="requestId">
              <strong>Request Id</strong>
            </Label>
            <Input
              type="text"
              value={requestId}
              id="requestId"
              name="requestId"
              //placeholder="Request Id"
              onChange={(e) => setRequestId(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="urgency">
              <strong>Urgency*</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              id="urgency"
              name="urgency"
            //   options={urgencyList}
              value={urgency}
              onChange={(value) => setUrgency(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="paymentDue">
              <strong>Payment due</strong>
            </Label>
            <Select
              isClearable={true}
              id="paymentDue"
              name="paymentDue"
              // isMulti
              theme={selectThemeColors}
            //   options={paymentDueOptions}
              value={paymentDue}
              onChange={(value) => setPaymentDue(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="remark">
              <strong>Remark</strong>
            </Label>
            <Input
              type="text"
              value={remark}
              id="remark"
              name="remark"
              //placeholder="Remark"
              onChange={(e) => setRemark(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="itemName">
              <strong>Item Name</strong>
            </Label>
            <Input
              type="text"
              value={itemName}
              id="itemName"
              name="itemName"
              //placeholder="Item Name"
              onChange={(e) => setItemName(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>

      <BlacklistedGuestsTable
        setId={setId}
        id={id}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        handleEdit={handleEdit}
        refetch={refetch}
        loading={loading}
      />
      <TablePagination
        setPage={setPage}
        page={page}
        totalPage={totalPage}
        loading={loading}
      />

      {/* <AddEditModal
        setId={setId}
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      /> */}
    </div>
  );
};

export default BlacklistedGuests;
