export const options = [
  { label: "Go ibibo", value: "Go ibibo" },
  { label: "The Hosteller", value: "The Hosteller" },
  {
    label: "The Hosteller Musoorie by the stream side",
    value: "The Hosteller Musoorie by the stream side",
  },
  { label: "THHO", value: "THHO" },
  { label: "HW", value: "HW" },
  { label: "OGP", value: "OGP" },
  { label: "THJ", value: "THJ" },
  { label: "Jai Test", value: "Jai Test" },
  { label: "AIESEC DIIT", value: "AIESEC DIIT" },
  { label: "Cleartrip", value: "Cleartrip" },
  { label: "MakeMytrip", value: "MakeMytrip" },
  { label: "WK", value: "WK" },
  { label: "Extension", value: "Extension" },
  { label: "Website", value: "Website" },
  { label: "Book on Google", value: "Book on Google" },
  {
    label: "HOYA LENS INDIA PRIVATE LIMITED",
    value: "HOYA LENS INDIA PRIVATE LIMITED",
  },
  { label: "On Ground Partner", value: "On Ground Partner" },
  { label: "MakeMyTrip", value: "MakeMyTrip" },
  { label: "Booking.com", value: "Booking.com" },
  { label: "Marketing", value: "Marketing" },
  { label: "HO", value: "HO" },
  { label: "Agoda", value: "Agoda" },
  { label: "Walk-in", value: "Walk-in" },
  { label: "Dorms.com", value: "Dorms.com" },
  { label: "TH-DL", value: "TH-DL" },
  { label: "TH Staff Complimentary", value: "TH Staff Complimentary" },
  {
    label: "Baazi Games Private Limited",
    value: "Baazi Games Private Limited",
  },
  {
    label: "invansys Technologies Pvt Ltd",
    value: "invansys Technologies Pvt Ltd",
  },
  { label: "makemytrip", value: "makemytrip" },
  { label: "Makemytrip.com", value: "Makemytrip.com" },
  { label: "Clear trip", value: "Clear trip" },
  { label: "PMS", value: "PMS" },
  { label: "Business to Business", value: "Business to Business" },
  { label: "SA", value: "SA" },
  { label: "go-mmt", value: "go-mmt" },
  { label: "Repco home finance limited", value: "Repco home finance limited" },
  { label: "Go-MMT", value: "Go-MMT" },
  { label: "TH-ML", value: "TH-ML" },
  { label: "Baazi Networks Pvt Ltd", value: "Baazi Networks Pvt Ltd" },
  { label: "Agoda.com", value: "Agoda.com" },
  {
    label: "Schoolsindia Services Pvt Ltd",
    value: "Schoolsindia Services Pvt Ltd",
  },
  { label: "On Ground partners", value: "On Ground partners" },
  { label: "Hostelworld.com", value: "Hostelworld.com" },
  { label: "PayTM", value: "PayTM" },
  { label: "TH HO", value: "TH HO" },
  { label: "Goibibo", value: "Goibibo" },
  { label: "TD", value: "TD" },
  { label: "Hostelworld", value: "Hostelworld" },
  { label: "On ground partners", value: "On ground partners" },
  { label: "OYO", value: "OYO" },
  { label: "Makemytrip", value: "Makemytrip" },
  { label: "Ex", value: "Ex" },
  { label: "Wanderon", value: "Wanderon" },
  { label: "Goibibo.com", value: "Goibibo.com" },
  { label: "HostelWorld", value: "HostelWorld" },
  { label: "Ranjith Singh", value: "Ranjith Singh" },
  { label: "AIESEC", value: "AIESEC" },
  {
    label: "BAAZI NETWORKS PRIVATE LIMITED",
    value: "BAAZI NETWORKS PRIVATE LIMITED",
  },
  { label: "FOCO Partner", value: "FOCO Partner" },
  { label: "Airbnb", value: "Airbnb" },
  {
    label: "ShootUp Technologies Pvt Ltd",
    value: "ShootUp Technologies Pvt Ltd",
  },
  { label: "Internet Booking Engine", value: "Internet Booking Engine" },
  { label: "Book On Google", value: "Book On Google" },
  { label: "Walk-In", value: "Walk-In" },
  { label: "HyperGuest", value: "HyperGuest" },
  { label: "TrippyTrips", value: "TrippyTrips" },
  { label: "clear trip", value: "clear trip" },
  { label: "Walk in", value: "Walk in" },
  { label: "Travel Desk", value: "Travel Desk" },
  { label: "MakemytripXml", value: "MakemytripXml" },
  { label: "THD", value: "THD" },
  { label: "B2B", value: "B2B" },
  { label: "Staff Accomodation", value: "Staff Accomodation" },
  { label: "Expedia", value: "Expedia" },
  { label: "HostelWorld XML", value: "HostelWorld XML" },
  { label: "Staff Accommodation", value: "Staff Accommodation" },
  { label: "TT", value: "TT" },
  { label: "On Ground Partners", value: "On Ground Partners" },
  { label: "Walk In", value: "Walk In" },
  { label: "EaseMyTrip", value: "EaseMyTrip" },
  { label: "BOG", value: "BOG" },
  { label: "Banbanjara", value: "Banbanjara" },
  { label: "Walkin", value: "Walkin" },
];
