import React, { useState, useEffect } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import SlideModal from "@components/common/slide-modal";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import Select from "react-select";
import { dateFormatter } from "@utils";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";
import {
  useHostelList,
  useCreateEventsAndActivities,
  useUpdateEventsAndActivities,
} from "../../../api/dataSource";

const animatedComponents = makeAnimated();

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(null);
  const [schedulingFrequency, setSchedulingFrequency] = useState(null);
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventStartDate, setEventStartDate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState(new Date());
  const [weekDays, setWeekDays] = useState(null);
  const [months, setMonths] = useState(null);
  const [time, setTime] = useState(new Date());
  const [duration, setDuration] = useState("");
  const [eventType, setEventType] = useState("");
  const [organiser, setOrganiser] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  const [totalInventory, setTotalInventory] = useState("");
  const [published, setPublished] = useState(false);
  const [cutOffTime, setCutOffTime] = useState("");
  const [fromTime, setFromTime] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [hsnCode, setHsnCode] = useState("");
  const [logs, setLogs] = useState(editData?.logs ? editData?.logs : []);

  const { data: allHostelsData } = useHostelList();
  const [
    createEventsAndActivitiesHandler,
    { data: createEventsAndActivitiesData },
  ] = useCreateEventsAndActivities();
  const [
    updateEventsAndActivitiesHandler,
    { data: updateEventsAndActivitiesData },
  ] = useUpdateEventsAndActivities();

  const CategoryList = [
    { label: "Event", value: "Event" },
    { label: "Activity", value: "Activity" },
  ];
  const SchedulingFrequencyList = [
    { label: "once", value: "once" },
    { label: "weekly", value: "weekly" },
    // { label: "monthly", value: "monthly" },
  ];

  const WeekDaysList = [
    { label: "Sunday", value: 1 },
    { label: "Monday", value: 2 },
    { label: "Tuesday", value: 3 },
    { label: "Wednesday", value: 4 },
    { label: "Thursday", value: 5 },
    { label: "Friday", value: 6 },
    { label: "Saturday", value: 7 },
  ];

  const MonthsList = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const taxList = [
    { label: "0", value: "0" },
    { label: "5", value: "5" },
    { label: "12", value: "12" },
    { label: "18", value: "18" },
    { label: "28", value: "28" },
  ];

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelList?.map((hostel) => {
      return { label: hostel?.name, value: hostel?._id };
    });
    setHostelsList(HostelsList);
  }, [allHostelsData]);

  // useEffect(() => {
  //   if (
  //     createEventsAndActivitiesData?.createEventsAndActivities?.title ||
  //     updateEventsAndActivitiesData
  //   ) {
  //     refetch();
  //     handleToggle();
  //     toaster("success", "Events and activities added successfully");
  //   }
  // }, [createEventsAndActivitiesData, updateEventsAndActivitiesData]);

  const submitDataHandler = () => {
    let data = {
      title,
      hostel: hostel?.value,
      category: category?.value,
      schedulingFrequency: schedulingFrequency?.value,
      eventDate: schedulingFrequency?.value === "once" ? eventDate : null,
      eventStartDate:
        schedulingFrequency?.value === "weekly" ? eventStartDate : null,
      eventEndDate:
        schedulingFrequency?.value === "weekly" ? eventEndDate : null,
      weekDays: weekDays?.map((weekDay) => weekDay?.value),
      months: months?.map((month) => month?.value),
      time,
      duration,
      eventType,
      organiser,
      description,
      price: parseFloat(price),
      tax: parseFloat(tax?.value),
      totalInventory: parseInt(totalInventory),
      published,
      cutOffTime: parseInt(cutOffTime),
      fromTime,
      toTime,
      hsnCode,
      logs: [
        ...logs,
        `${localStorage.getItem("name")} ${
          editData ? "modified" : "created"
        } the events and activities configuration on ${new Date().toLocaleString(
          "en-IN",
          {
            hour12: false,
          }
        )}`,
      ],
    };

    if (data?.title) {
      if (editData?.length) {
        const { _id } = editData[0];
        updateEventsAndActivitiesHandler(_id, data);
        toaster("success", "Events and activities updated successfully");
      } else {
        createEventsAndActivitiesHandler(data);
        toaster("success", "Events and activities added successfully");
      }
      setTitle("");
      setHostel(null);
      setCategory(null);
      setSchedulingFrequency(null);
      setEventDate(new Date());
      setEventStartDate(new Date());
      setEventEndDate(new Date());
      setWeekDays(null);
      setMonths(null);
      setTime(new Date());
      setDuration("");
      setEventType("");
      setOrganiser("");
      setDescription("");
      setPrice("");
      setTax("");
      setTotalInventory("");
      setPublished(false);
      setCutOffTime("");
      setFromTime(null);
      setToTime(null);
      setHsnCode("");
      setLogs([]);
      refetch();
      handleToggle();
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };

  useEffect(() => {
    if (editData?.length) {
      setTitle(editData[0]?.title !== null ? editData[0]?.title : "");
      setHostel(
        editData[0]?.hostel?.name
          ? {
              label: editData[0]?.hostel?.name,
              value: editData[0]?.hostel?._id,
            }
          : null
      );
      setCategory(
        editData[0]?.category
          ? { label: editData[0]?.category, value: editData[0]?.category }
          : null
      );
      setSchedulingFrequency(
        editData[0]?.schedulingFrequency
          ? {
              label: editData[0]?.schedulingFrequency,
              value: editData[0]?.schedulingFrequency,
            }
          : null
      );
      setEventDate(
        editData[0]?.eventDate
          ? editData[0]?.eventDate
          : editData[0]?.eventStartDate
      );
      setEventStartDate(editData[0]?.eventStartDate);
      setEventEndDate(editData[0]?.eventEndDate);

      let weekdaysDB = editData[0]?.weekDays?.map((weekday) => {
        return WeekDaysList.find((item) => item.value === weekday);
      });
      setWeekDays(weekdaysDB);

      let monthsDB = editData[0]?.months?.map((month) => {
        return MonthsList.find((item) => item.value === month);
      });
      setMonths(monthsDB);
      setTime(editData[0]?.time);
      setDuration(editData[0]?.duration !== null ? editData[0]?.duration : "");
      setEventType(
        editData[0]?.eventType !== null ? editData[0]?.eventType : ""
      );
      setOrganiser(
        editData[0]?.organiser !== null ? editData[0]?.organiser : ""
      );
      setDescription(
        editData[0]?.description !== null ? editData[0]?.description : null
      );
      setPrice(editData[0]?.price !== null ? editData[0]?.price : "");
      setTax(
        editData[0]?.tax !== null
          ? { label: editData[0]?.tax, value: editData[0]?.tax }
          : ""
      );
      setTotalInventory(
        editData[0]?.totalInventory !== null ? editData[0]?.totalInventory : ""
      );
      setPublished(editData[0]?.published);
      setCutOffTime(
        editData[0]?.cutOffTime === null ? "" : editData[0]?.cutOffTime
      );
      setFromTime(editData[0]?.fromTime);
      setToTime(editData[0]?.toTime);
      setHsnCode(editData[0]?.hsnCode !== null ? editData[0]?.hsnCode : "");
      setLogs(editData[0]?.logs ? editData[0]?.logs : []);
    } else {
      setTitle("");
      setHostel(null);
      setCategory(null);
      setSchedulingFrequency(null);
      setEventDate(new Date());
      setEventStartDate(new Date());
      setEventEndDate(new Date());
      setWeekDays(null);
      setMonths(null);
      setTime(new Date());
      setDuration("");
      setEventType("");
      setOrganiser("");
      setDescription("");
      setPrice("");
      setTax("");
      setTotalInventory("");
      setPublished(false);
      setCutOffTime("");
      setFromTime(null);
      setToTime(null);
      setHsnCode("");
      setLogs([]);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Category */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="category">
                Category*
              </Label>
              <Select
                isClearable={false}
                id="category"
                name="category"
                options={CategoryList}
                required
                value={category}
                onChange={(value) => setCategory(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          {/* // ! Hostel */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={HostelsList}
                required
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          {/* // ! Title */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="title">
                Title*
              </Label>
              <Input
                type="text"
                id="title"
                name="title"
                value={title}
                required
                placeholder="Add title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Scheduling frequency */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="schedulingFrequency">
                Scheduling Frequency*
              </Label>
              <Select
                isClearable={false}
                id="schedulingFrequency"
                name="schedulingFrequency"
                options={SchedulingFrequencyList}
                required
                value={schedulingFrequency}
                onChange={(value) => setSchedulingFrequency(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Week days */}
          {schedulingFrequency?.value === "weekly" ? (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="weekDays">
                  Week Days
                </Label>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  isMulti
                  id="weekDays"
                  name="weekDays"
                  theme={selectThemeColors}
                  components={animatedComponents}
                  options={WeekDaysList}
                  // required
                  value={weekDays}
                  onChange={(value) => setWeekDays(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          ) : null}
          {/* // ! Months */}
          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="months">
                Months
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                isMulti
                id="months"
                name="months"
                theme={selectThemeColors}
                components={animatedComponents}
                options={MonthsList}
                // isDisabled={true}
                required
                value={months}
                onChange={(value) => setMonths(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col> */}

          {/* // ! Event Date */}
          {schedulingFrequency?.value === "once" ? (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="event date">
                  Event Date
                </Label>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  value={eventDate}
                  onChange={(date) =>
                    setEventDate(new Date(dateFormatter(new Date(date))))
                  }
                  id="eventDate"
                  placeholder="Add Event Date"
                  // required
                />
              </FormGroup>
            </Col>
          ) : null}

          {/* // ! Event Start & End Date */}
          {schedulingFrequency?.value === "weekly" ? (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="event start end date">
                  Event Start & End Date
                </Label>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  value={[eventStartDate, eventEndDate]}
                  onChange={(date) => {
                    setEventStartDate(
                      new Date(dateFormatter(new Date(date[0])))
                    );
                    setEventEndDate(new Date(dateFormatter(new Date(date[1]))));
                  }}
                  id="eventDate"
                  options={{ mode: "range" }}
                  placeholder="Add Event Date"
                  // required
                />
              </FormGroup>
            </Col>
          ) : null}

          {/* // ! Time */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="time">
                Event Time (24 hrs format)*
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={time}
                onChange={(value) => {
                  const getHours = value[0]?.getHours();
                  const getMinutes = value[0]?.getMinutes();
                  const dt = new Date();
                  dt.setHours(getHours);
                  dt.setMinutes(getMinutes);
                  setTime(dt);
                }}
                id="time"
                placeholder="Event Time"
                required
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                }}
              />
            </FormGroup>
          </Col>
          {/* // ! Duration */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="duration">
                Duration (in hours)
              </Label>
              <Input
                type="text"
                id="duration"
                value={duration}
                name="duration"
                placeholder="Add duration"
                onChange={(e) => setDuration(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* // ! Event type */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="eventType">
                Event Type
              </Label>
              <Input
                type="text"
                value={eventType}
                id="eventType"
                name="eventType"
                placeholder="Add event type"
                onChange={(e) => setEventType(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* // ! Organiser */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="organiser">
                Organiser
              </Label>
              <Input
                type="text"
                value={organiser}
                id="organiser"
                name="organiser"
                placeholder="Add Organiser"
                onChange={(e) => setOrganiser(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* // ! Description */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="description">
                Description
              </Label>
              <Input
                type="textarea"
                id="description"
                value={description}
                name="description"
                placeholder="Add Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! HSN Code */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hsnCode">
                HSN Code
              </Label>
              <Input
                type="text"
                id="hsnCode"
                name="hsnCode"
                value={hsnCode}
                placeholder="Add HSN Code"
                onChange={(e) => setHsnCode(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Price */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="price">
                Price*
              </Label>
              <Input
                type="text"
                id="price"
                value={price}
                name="price"
                placeholder="Add Price"
                required
                onChange={(e) => setPrice(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* // ! Tax */}
          <Col sm="12">
            {/* <FormGroup className="w-100">
              <Label className="form-label" for="tax">
                Tax (%)*
              </Label>
              <Input
                
                type="text"
                id="tax"
                value={tax}
                name="tax"
                placeholder="Add Tax"
                onChange={(e) => setTax(e.target.value)}
              />
            </FormGroup> */}
            <FormGroup className="w-100">
              <Label className="form-label" for="tax">
                Tax (%)*
              </Label>
              <Select
                id="tax"
                name="tax"
                value={tax}
                options={taxList}
                required
                onChange={(value) => setTax(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          {/* // ! Total inventory */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="totalInventory">
                Total Inventory (per day)*
              </Label>
              <Input
                type="text"
                id="totalInventory"
                value={totalInventory}
                name="totalInventory"
                placeholder="Add total inventory"
                required
                onChange={(e) => setTotalInventory(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Cutoff Time*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="cutOffTime">
                Booking Cutoff Time (hours)
              </Label>
              <Input
                type="text"
                id="cutOffTime"
                value={cutOffTime}
                name="cutOffTime"
                placeholder="Add cutoff time"
                onChange={(e) => setCutOffTime(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! From Time */}
          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromTime">
                Booking Active From Time (24 hrs format)
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromTime}
                onChange={(value) => setFromTime(value)}
                id="fromTime"
                placeholder="Time (24 hrs format)"
                // required
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                }}
              />
            </FormGroup>
          </Col> */}

          {/* // ! To Time */}
          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="toTime">
                Booking Active To Time (24 hrs format)
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toTime}
                onChange={(value) => setToTime(value)}
                id="toTime"
                placeholder="Time (24 hrs format)"
                // required
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                }}
              />
            </FormGroup>
          </Col> */}

          {/* // ! Published */}
          <Col sm="12">
            <SwitchIcon
              checked={published}
              id="status"
              name="status"
              label="Status"
              handleChecked={(value) => setPublished(value)}
            />
          </Col>

          <Col sm="12">
            {/* <hr/> */}
            <FormGroup className="w-100">
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>
              <br />
              {logs
                ?.map((log, i) => {
                  return (
                    <Label className="form-label" for="name">
                      {/* <p >{log}.</p> */}
                      {log}.
                    </Label>
                  );
                })
                .reverse()}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
