/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import FeedbackReportTable from "./FeedbackReportTable";
import FeedbackHostelWise from "../FeedbackReport/FeedbackHostelWise";
import FeedbackFinalStatus from "../FeedbackReport/FeedbackFinalStatus";
import FeedbackHostelWiseAvgTat from "../FeedbackReport/FeedbackHostelWiseAvgTat";
import FeedbackTotalFeedbackStatus from "../FeedbackReport/FeedbackTotalFeedbackStatus";
import FeedbackBadRemarkKeywordData from "../FeedbackReport/FeedbackBadRemarkKeywordData";
import FeedbackCXUpdatedByAvgTAT from "../FeedbackReport/FeedbackCXUpdatedByAvgTAT";
import { useAllFeedback } from "@api";
import {
  useGetAllFeedbackStatusData,
  useGetHostelWiseFeedbackStatusData,
  useGetAllFinalStatusData,
  useGetHostelWiseAvgTat,
  useGetTotalCustomerFeedbackStatusData,
  useGetTotalBadRemarkWordCountAndData,
  useGetCxUpdatedByAvgTatAndCount,
  useGetHostelNamesByStaffEmail,
} from "../../../api/dataSource";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import FeedbackChart from "../FeedbackReport/FeedbackChart";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { selectThemeColors } from "@utils";
import { useHostelList } from "../../../api/dataSource";
import Select from "react-select";

const FeedbackReport = () => {
  const staffEmail =
    localStorage.getItem("email") === undefined
      ? ""
      : localStorage.getItem("email");
  const [tableData, setTableData] = useState([]);
  const [hostelTableData, setHostelTableData] = useState([]);
  const [finalStatusTableData, setFinalStatusTableData] = useState([]);
  const [hostelWiseTatData, setHostelWiseTatData] = useState([]);
  const [feedbackStatusTableData, setFeedbackStatusTableData] = useState([]);
  const [badRemarkKeywordTableData, setBadRemarkKeywordTableData] = useState(
    []
  );
  const [cxUpdatedByTATTableData, setCxUpdatedByTATTableData] = useState([]);
  const [fromDate, setFromDate] = useState(new Date("2023-01-01"));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useHostelList();

  const [arrFilterHostel, setArrFilterHostel] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [assignedHostels, setAssignedHostels] = useState([]);

  const { data: staffEmailData, refetch: staffEmailRefetch } =
    useGetHostelNamesByStaffEmail(staffEmail);

  useEffect(() => {
    if (staffEmailData) {
      const hostels = staffEmailData?.getHostelNamesByStaffEmail?.data?.map(
        (item) => item?.hostel?.hostelName
      );
      // console.log(hostels, "hostels");
      setAssignedHostels(hostels);
    }
  }, [staffEmailData]);

  useEffect(() => {
    if (hostel.length) {
      setArrFilterHostel(hostel?.map((hostel) => hostel.value));
    } else {
      setArrFilterHostel(HostelsList?.map((HostelsList) => HostelsList.value));
    }
  }, [HostelsList, hostel]);

  useEffect(() => {
    let hostelsList = [];
    if (assignedHostels?.length) {
      // console.log(assignedHostels, "currently in if");
      hostelsList = allHostelsData?.getHostelList
        ?.filter((item) => assignedHostels.includes(item.name))
        ?.map((item) => ({ label: item?.name, value: item?.name }));
    } else if (allHostelsData?.getHostelList?.length) {
      hostelsList = allHostelsData?.getHostelList?.map((item) => ({
        label: item?.name,
        value: item?.name,
      }));
    }

    setHostelsList(hostelsList);
  }, [allHostelsData?.getHostelList, staffList, assignedHostels]);

  const { data, refetch } = useGetAllFeedbackStatusData(
    fromDate,
    toDate,
    arrFilterHostel
    // arrFilterHostel > 0
    //   ? arrFilterHostel.map((arrFilterHostel) => arrFilterHostel.value)
    //   : []
    // hostel.length > 0 ? hostel.map((h) => h.value) : []
  );

  const { data: hosteldata, refetch: reload } =
    useGetHostelWiseFeedbackStatusData(
      fromDate,
      toDate,
      arrFilterHostel
      // hostel.length > 0 ? hostel.map((h) => h.value) : []
    );
  const { data: finalStatusData, refetch: refresh } = useGetAllFinalStatusData(
    fromDate,
    toDate,
    arrFilterHostel
    // hostel.length > 0 ? hostel.map((h) => h.value) : []
  );
  const { data: hostelTatData, refetch: refetchData } = useGetHostelWiseAvgTat(
    fromDate,
    toDate,
    arrFilterHostel
    // hostel.length > 0 ? hostel.map((h) => h.value) : []
  );
  const { data: feedbackStatusData, refetch: statusRefetchData } =
    useGetTotalCustomerFeedbackStatusData(
      fromDate,
      toDate,
      arrFilterHostel
      // hostel.length > 0 ? hostel.map((h) => h.value) : []
    );

  const { data: badKeywordData, refetch: badRefetchData } =
    useGetTotalBadRemarkWordCountAndData(
      fromDate,
      toDate,
      arrFilterHostel
      // hostel.length > 0 ? hostel.map((h) => h.value) : []
    );

  const { data: updateByData, refetch: updatedByRefetchData } =
    useGetCxUpdatedByAvgTatAndCount(fromDate, toDate);

  useEffect(() => {
    refetch({
      fromDate,
      toDate,
      arrFilterHostel,
      // hostelName: hostel.length > 0 ? hostel.map((h) => h.value) : [],
    });
  }, [fromDate, toDate, arrFilterHostel]);

  useEffect(() => {
    if (data?.getAllFeedbackStatusData) {
      setTableData(data?.getAllFeedbackStatusData);
    } else {
      setTableData([]);
    }
  }, [data, hostel]);

  useEffect(() => {
    if (hosteldata?.getHostelWiseFeedbackStatusData) {
      setHostelTableData(hosteldata?.getHostelWiseFeedbackStatusData);
    } else {
      setHostelTableData([]);
    }
  }, [hosteldata]);

  useEffect(() => {
    if (finalStatusData?.getAllFinalStatusData) {
      setFinalStatusTableData(finalStatusData?.getAllFinalStatusData);
    } else {
      setFinalStatusTableData([]);
    }
  }, [finalStatusData]);

  useEffect(() => {
    if (hostelTatData?.getHostelWiseAvgTat) {
      setHostelWiseTatData(hostelTatData?.getHostelWiseAvgTat);
    } else {
      setHostelWiseTatData([]);
    }
  }, [hostelTatData]);

  useEffect(() => {
    if (feedbackStatusData?.getTotalCustomerFeedbackStatusData) {
      setFeedbackStatusTableData(
        feedbackStatusData?.getTotalCustomerFeedbackStatusData
      );
    } else {
      setFeedbackStatusTableData([]);
    }
  }, [feedbackStatusData]);

  useEffect(() => {
    if (badKeywordData?.getTotalBadRemarkWordCountAndData) {
      setBadRemarkKeywordTableData(
        badKeywordData?.getTotalBadRemarkWordCountAndData
      );
    } else {
      setBadRemarkKeywordTableData([]);
    }
  }, [badKeywordData]);

  useEffect(() => {
    if (updateByData?.getCxUpdatedByAvgTatAndCount) {
      setCxUpdatedByTATTableData(updateByData?.getCxUpdatedByAvgTatAndCount);
    } else {
      setCxUpdatedByTATTableData([]);
    }
  }, [updateByData]);

  // useEffect(() => {
  //   let HostelsList = [];
  //   if (allHostelsData?.getHostelList?.length) {
  //     allHostelsData?.getHostelList?.map((item) => {
  //       HostelsList.push({ label: item?.name, value: item?.name });
  //       setHostelsList(HostelsList);
  //     });
  //   }
  // }, [allHostelsData?.getHostelList]);

  return (
    localStorage.getItem("modules")?.includes("ORM Reports") && (
      <div>
        <Row>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => {
                  setFromDate(dateFormatter(new Date(date)));
                }}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                To Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => {
                  setToDate(dateFormatter(new Date(date)));
                }}
                id="toDate"
              />
            </FormGroup>
          </Col>
          <Col sm="8">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong>Hostel</strong>
              </Label>

              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value || []);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        </Row>
        <FeedbackReportTable data={tableData} refetch={refetch} />

        <FeedbackTotalFeedbackStatus
          feedbackStatusData={feedbackStatusTableData}
          refetch={statusRefetchData}
        />
        <FeedbackFinalStatus
          finalStatusData={finalStatusTableData}
          refetch={refresh}
        />
        <FeedbackCXUpdatedByAvgTAT
          updateByData={cxUpdatedByTATTableData}
          refetch={updatedByRefetchData}
        />
        <FeedbackHostelWise hosteldata={hostelTableData} refetch={reload} />
        <FeedbackHostelWiseAvgTat
          hostelTatData={hostelWiseTatData}
          refetch={refetchData}
        />
        <FeedbackBadRemarkKeywordData
          badKeywordData={badRemarkKeywordTableData}
          refetch={badRefetchData}
        />
      </div>
    )
  );
};

export default FeedbackReport;
