//  Added by Nitish
import React, { useState, useEffect } from "react";
import AddonBookingsTable from "./AddonBookingsTable";
import AddEditModal from "./AddEditModal";
import Select from "react-select";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import TablePagination from "../../../@core/components/common/TablePagination";
import {
  useGetPaginatedAddOnsBookings,
  useHostelList,
  useGetAllAddOnsBookings,
  useGetHostelsNameandId,
  useGenerateServiceExport,
} from "../../../api/dataSource";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { selectThemeColors, dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";

const AddonBooking = () => {
  const role = localStorage.getItem("role");
  const [openModal, setOpenModal] = useState(false);
  const [reservationId, setReservationId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(null);
  const [deliveryDateTo, setDeliveryDateTo] = useState(null);
  const [deliveryDateFrom, setDeliveryDateFrom] = useState(null);
  const [cancelledBy, setCancelledBy] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  // const [exportTableData, setExportTableData] = useState([]);
  const [bookingDateTo, setBookingDateTo] = useState(null);
  const [bookingDateFrom, setBookingDateFrom] = useState(null);
  // const { data: allHostelsData } = useHostelList();
  const { data: allHostelsData } = useGetHostelsNameandId();
  const [filterFields, setFilterFields] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [submissionTime, setSubmissionTime] = useState(null);

  const { data, refetch } = useGetPaginatedAddOnsBookings({
    limit,
    page,
    filterFields,
  });

  // const { data: exportData } = useGetAllAddOnsBookings({
  //   filterFields,
  // });

  const [dataCount, setDataCount] = useState(0);

  const [generateServiceExportHandler, { data: serviceDataForExport }] =
    useGenerateServiceExport();

  useEffect(() => {
    if (serviceDataForExport?.generateServiceExport?.data) {
      if (serviceDataForExport?.generateServiceExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          serviceDataForExport?.generateServiceExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `GLU_Services_Export.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [serviceDataForExport]);

  const exportInputFilter = {
    filterFields,
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelsNameandId?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelsNameandId?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelsNameandId;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelsNameandId, limit, page]);

  useEffect(() => {
    if (data?.getPaginatedAddOnsBookings?.data.length > 0) {
      setTableData(data?.getPaginatedAddOnsBookings?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getPaginatedAddOnsBookings?.count / limit));
  }, [
    data?.getPaginatedAddOnsBookings?.count,
    data?.getPaginatedAddOnsBookings?.data,
    limit,
    filterFields,
  ]);

  //for all data export with filters
  // useEffect(() => {
  //   if (exportData?.getAllAddOnsBookings?.data.length > 0) {
  //     setExportTableData(exportData?.getAllAddOnsBookings?.data);
  //   } else {
  //     setExportTableData([]);
  //   }
  // }, [exportData?.getAllAddOnsBookings?.data]);

  useEffect(() => {
    if (
      data?.getPaginatedAddOnsBookings?.data &&
      data.getPaginatedAddOnsBookings.data.length > 0
    ) {
      let editData = data.getPaginatedAddOnsBookings.data.find(
        (item) => item._id === id
      );

      if (editData) {
        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedAddOnsBookings?.data, id]);

  useEffect(() => {
    setTotalRevenue(data?.getPaginatedAddOnsBookings?.totalRevenue);
  }, [data?.getPaginatedAddOnsBookings?.totalRevenue]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    setDataCount(data?.getPaginatedAddOnsBookings?.count);
  }, [data?.getPaginatedAddOnsBookings?.count]);

  const serviceStatusDropdownList = [
    { label: "Pending", value: "Pending" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Completed", value: "Completed" },
  ];

  //for service cancelld by filter
  const serviceCancelledByDropdownList = [
    { label: "Hostel", value: "hostel" },
    { label: "Guest", value: "guest" },
  ];

  const submissionTimeOptions = [
    { label: "Pre arrival", value: "prearrival" },
    { label: "On arrival", value: "onarrival" },
  ];

  // Hostel Essentials, Local Activities, Vehicle Rentals, Pickup, Drop, Siteseeing
  //for service category filter
  const serviceCategoryDropdownList = [
    { label: "Add-ons", value: "Add-ons" },
    { label: "Rentals", value: "Rentals" },
    { label: "Transfers", value: "Transfers" },
    { label: "Hostel Essentials", value: "Hostel Essentials" },
    { label: "Local Activities", value: "Local Activities" },
    { label: "Vehicle Rentals", value: "Vehicle Rentals" },
    { label: "Pickup", value: "Pickup" },
    { label: "Drop", value: "Drop" },
    { label: "Sightseeing", value: "Sightseeing" },
    { label: "Car Transfers", value: "Car Transfers" },
  ];

  const handleSearch = () => {
    const filterFields = {
      reservationId,
      bookingId,
      title,
      category: category?.value,
      bookingDateFrom,
      bookingDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      cancelledBy: cancelledBy?.value,
      hostelIdArray: hostel?.map((hostel) => hostel.value),
      status: status?.value,
      // status: status?.map((status) => status.value),
      submissionTime: submissionTime?.value,
    };
    setFilterFields(filterFields);
  };

  const handleReset = () => {
    setFilterFields(null);
    setReservationId("");
    setHostel([]);
    setDeliveryDateFrom(null);
    setDeliveryDateTo(null);
    setBookingDateFrom(null);
    setBookingDateTo(null);
    setBookingId("");
    setCancelledBy(null);
    setSubmissionTime(null);
    setCategory("");
    setStatus(null);
  };

  // const exportHeaders = [
  //   { label: "Reservation ID", key: "reservationId" },
  //   { label: "Guest Name", key: "guestDetails.GuestName" },
  //   { label: "Hostel Name", key: "guestDetails.hostelName" },
  //   { label: "Category", key: "category" },
  //   { label: "Title", key: "title" },
  //   { label: "Quantity", key: "quantity" },
  //   { label: "Variation Option", key: "serviceData.variation.option" },
  //   { label: "Variation Price", key: "serviceData.variation.price" },
  //   { label: "Variation Quantity", key: "serviceData.variation.quantity" },
  //   { label: "Amount (inc. GST)", key: "totalPrice" },
  //   { label: "Booking ID", key: "bookingId" },
  //   { label: "Booking Date/Time", key: "bookingDate" },
  //   { label: "Delivery Date/Time", key: "deliveryDate" },
  //   { label: "Status", key: "status" },
  //   { label: "Comment", key: "remark" },
  //   { label: "Amount (exc. GST)", key: "price" },
  //   { label: "Invoice Number", key: "invoiceNo" },
  //   { label: "Payment Status", key: "paymentStatus" },
  //   { label: "Tax", key: "tax" },
  //   { label: "Razorpay Payment ID", key: "razorpayPaymentId" },
  //   { label: "Cancellation Amount", key: "cancellationAmount" },
  //   { label: "Cancelled By", key: "cancelledBy" },
  //   { label: "Cancellation Date/Time", key: "cancellationDate" },
  //   { label: "Refund Date", key: "refundDate" },
  //   { label: "Refund ID", key: "refundId" },
  //   { label: "Refund Status", key: "refundStatus" },
  //   { label: "Cancellation Remark", key: "cancellationRemark" },
  // ];

  return (
    localStorage.getItem("modules")?.includes("Glu Reports") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Hostel Name</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="Category">
                <strong>Category</strong>
              </Label>
              <Select
                isClearable={true}
                id="Category"
                name="Category"
                value={category}
                options={serviceCategoryDropdownList}
                onChange={(value) => {
                  setCategory(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="Title">
                <strong>Title</strong>
              </Label>
              <Input
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong>Status</strong>
              </Label>
              <Select
                isClearable={true}
                id="Status"
                name="Status"
                value={status}
                options={serviceStatusDropdownList}
                onChange={(value) => {
                  setStatus(value);
                }}
                classNamePrefix="select"
                // isMulti
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Booking Date Range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="checkinDate"
                placeholder="Booking Date Range"
                options={{
                  mode: "range",
                }}
                value={[bookingDateFrom, bookingDateTo]}
                onChange={(date) => {
                  setBookingDateFrom(dateFormatter(date[0]));
                  setBookingDateTo(dateFormatter(date[1]));
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Delivery Date Range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="checkinDate"
                placeholder="Delivery Date Range"
                options={{
                  mode: "range",
                }}
                value={[deliveryDateFrom, deliveryDateTo]}
                onChange={(date) => {
                  setDeliveryDateFrom(dateFormatter(date[0]));
                  setDeliveryDateTo(dateFormatter(date[1]));
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Booking ID</strong>
              </Label>
              <Input
                type="text"
                placeholder="Booking ID"
                value={bookingId}
                onChange={(e) => setBookingId(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Reservation ID</strong>
              </Label>
              <Input
                type="text"
                placeholder="Reservation ID"
                value={reservationId}
                onChange={(e) => setReservationId(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="cancelledBy">
                <strong>Cancelled By</strong>
              </Label>
              <Select
                isClearable={true}
                id="cancelledBy"
                name="cancelledBy"
                value={cancelledBy}
                options={serviceCancelledByDropdownList}
                onChange={(value) => {
                  setCancelledBy(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="bookingTime">
                <strong>Booking Time</strong>
              </Label>
              <Select
                isClearable={true}
                id="bookingTime"
                name="bookingTime"
                value={submissionTime}
                options={submissionTimeOptions}
                onChange={(value) => {
                  setSubmissionTime(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
            {role !== "Ground Staff" && (
              <Button
                style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  marginLeft: "40px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="success"
                appearance="success"
                type="button"
                onClick={() => generateServiceExportHandler(exportInputFilter)}
              >
                Export
              </Button>
            )}
          </Col>
        </Row>

        {/* <div className="mt-2">
        <strong>Total Revenue:</strong> {totalRevenue}
      </div> */}

        <AddonBookingsTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          refetch={refetch}
          data={tableData}
          // headers={exportHeaders}
          // exportData={exportTableData}
          dataCount={dataCount}
          totalRevenue={totalRevenue}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      </div>
    )
  );
};

export default AddonBooking;
