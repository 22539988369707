import { Home } from 'react-feather';

export default [
  {
      id: 'calendar',
      title: 'Calendar',
      icon: <Home />,
      navLink: '/calendar',
  }
   
];
