/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

const Preview = () => {
  const state = useSelector((state) => state?.cms);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (state.tableData) {
      setUrl(state?.tableData?.url);
    }
  }, [state]);

  console.log(url);

  return (
    <div style={{ height: "1000px" }}>
      <iframe
        allowFullScreen
        src={`https://www.thehosteller.com${url}?preview=true`}
        style={{ height: "1000px", width: "100%" }}
      ></iframe>
    </div>
  );
};

export default Preview;
