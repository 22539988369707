import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { inputDataConvertor, htmlToDraftConvert } from "@utils";
import { useUpdateEventsAndActivities } from "../../../api/dataSource";

const MoreInfo = () => {
  const { id } = useParams();
  const [overview, setOverView] = useState("");
  const [whyAttend, setWhyAttend] = useState(() => EditorState.createEmpty());
  const [aboutOrganiser, setAboutOrganiser] = useState(() =>
    EditorState.createEmpty()
  );
  // const [policies, setPolicies] = useState("");
  // const [overview, setOverView] = useState(() => EditorState.createEmpty());
  // const [whyAttend, setWhyAttend] = useState(
  //   useState(() => EditorState.createEmpty()),
  // );
  // const [aboutOrganiser, setAboutOrganiser] = useState(() =>
  //   EditorState.createEmpty(),
  // );
  const [policies, setPolicies] = useState(() => EditorState.createEmpty());

  const dispatch = useDispatch();
  const [
    updateEventsAndActivitiesHandler,
    { data: updateEventsAndActivitiesData },
  ] = useUpdateEventsAndActivities();

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData) {
      setOverView(
        state?.tableData?.overview !== null ? state?.tableData?.overview : ""
      );
      setAboutOrganiser(
        state?.tableData?.aboutOrganiser !== null
          ? htmlToDraftConvert(state?.tableData?.aboutOrganiser)
          : EditorState.createEmpty()
      );
      // setPolicies(state.tableData?.policies);
      setWhyAttend(
        state?.tableData?.whyAttend !== null
          ? htmlToDraftConvert(state?.tableData?.whyAttend)
          : EditorState.createEmpty()
      );

      // setOverView(htmlToDraftConvert(state.tableData?.overview));
      // setAboutOrganiser(htmlToDraftConvert(state.tableData?.aboutOrganiser));
      state?.tableData?.policies &&
        setPolicies(htmlToDraftConvert(state?.tableData?.policies));
      // setWhyAttend(htmlToDraftConvert(state.tableData?.whyAttend));
    } else {
      setOverView("");
      setAboutOrganiser("");
      setPolicies("");
      setWhyAttend("");
    }
  }, [state?.tableData]);

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      overview,
      whyAttend: inputDataConvertor(whyAttend),
      aboutOrganiser: inputDataConvertor(aboutOrganiser),
      // policies,
      // overview: inputDataConvertor(overview),
      // whyAttend: inputDataConvertor(whyAttend),
      // aboutOrganiser: inputDataConvertor(aboutOrganiser),
      policies: inputDataConvertor(policies),
    };

    if (id) {
      updateEventsAndActivitiesHandler(id, data);
      toaster("success", "More info updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        {/* // ! Overview */}
        {/* <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="overview">
              Overview
            </Label>
            <Input
              type="textarea"
              value={overview}
              id="overview"
              name="overview"
              placeholder="Add overview"
              onChange={(e) => setOverView(e.target.value)}
            />
          </FormGroup>
        </Col> */}
        {/* // ! Why Attend */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="whyAttend">
              Why attend
            </Label>
            {/* <Input
              type="textarea"
              value={whyAttend}
              id="whyAttend"
              name="whyAttend"
              placeholder="Add to why attend"
              onChange={(e) => setWhyAttend(e.target.value)}
            /> */}
            <MyEditor
              editorState={whyAttend}
              setEditorState={(val) => setWhyAttend(val)}
            />
          </FormGroup>
        </Col>
        {/* // ! About organiser */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="aboutOrganiser">
              About organiser
            </Label>
            {/* <Input     
              type="textarea"
              value={aboutOrganiser}
              id="aboutOrganiser"
              name="aboutOrganiser"
              placeholder="Add about organiser"
              onChange={(e) => setAboutOrganiser(e.target.value)}
            /> */}
            <MyEditor
              editorState={aboutOrganiser}
              setEditorState={(val) => setAboutOrganiser(val)}
            />
          </FormGroup>
        </Col>
        {/* // ! Policies */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="policies">
              Policies
            </Label>
            {/* <Input
              type="text"
              value={policies}
              id="policies"
              name="policies"
              placeholder="Add policies"
              onChange={(e) => setPolicies(e.target.value)}
            /> */}
            <MyEditor
              editorState={policies}
              setEditorState={(val) => setPolicies(val)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default MoreInfo;
