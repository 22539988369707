import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { useGetTripById } from "@api";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import DetailsForm from "./DetailsForm";
// import LocationForm from "./LocationForm";
import OverviewForm from "./OverviewForm";
import ReviewsForm from "./ReviewsForm";
import IncludeExcludeForm from "./IncludeExcludeForm";
import ItineraryForm from "./ItineraryForm";
import CmsTabs from "@components/Cms/CmsTabs";
import CardForm from "./CardForm";
import FaqForm from "./FaqForm";
import PdfForm from "./PdfForm";
import PhotosForm from "./PhotosForm";
import Recommendations from "./Recommendations";

const tabOptions = [
  {
    id: 0,
    title: "Trip Details",
    formComponent: <DetailsForm />,
  },
  {
    id: 1,
    title: "Overview",
    formComponent: <OverviewForm />,
  },
  {
    id: 2,
    title: "Photos",
    formComponent: <PhotosForm />,
  },
  {
    id: 3,
    title: "Itinerary",
    formComponent: <ItineraryForm />,
  },
  {
    id: 4,
    title: "Includes / Excludes",
    formComponent: <IncludeExcludeForm />,
  },

  {
    id: 5,
    title: "Reviews",
    formComponent: <ReviewsForm />,
  },
  {
    id: 6,
    title: "Itinerary PDF",
    formComponent: <PdfForm />,
  },
  {
    id: 7,
    title: "FAQ",
    formComponent: <FaqForm />,
  },
  {
    id: 8,
    title: "Create Card",
    formComponent: <CardForm />,
  },
  {
    id: 9,
    title: "Recommendations",
    formComponent: <Recommendations />,
  },
];

const AddTrip = () => {
  const { id } = useParams();
  const [active, setActive] = useState(0);
  const { data, refetch } = useGetTripById(id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.getTrip) {
      dispatch(tableDataHandler(data?.getTrip));
    }
  }, [data]);

  useEffect(() => refetch(), []);

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  return (
    <div>
      <div className='mt-1 mb-2'>
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className='border-bottom'>
                <CardTitle>
                  {active !== 0 && "Trip Details - "}
                  {tabOptions.find((item) => item.id === active).title}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className='border-bottom'>
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default AddTrip;
