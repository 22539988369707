import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";

const tableHeaderData = ["Keywords", "Count"];
const usefulWords = [
  "staff",
  "service",
  "cafe",
  "food",
  "property",
  "dirty",
  "room",
  "experience",
  "hospitality",
  "Cleaning",
  "water",
  "room",
  "stay",
  "dorm",
  "breakfast",
  "Rooms",
  "heater",
  "music",
  "price",
  "washrooms",
  "Geyser",
  "kitchen",
  "unfriendly",
  "unhygienic",
  "bedsheets",
  "poor",
  "AC",
  "bathroom",
  "manager",
  "wifi",
  "Pathetic",
  "towels",
  "washrooms",
  "rude",
]; // array of useful words

const FeedbackBadRemarkKeywordData = ({ badKeywordData: data }) => {
  const [tableData, setTableData] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (data?.length > 0) {
      const filteredData = data.filter((item) =>
        usefulWords.includes(item._id)
      );
      setTableData(filteredData);
    } else {
      setTableData([]);
    }
  }, [data]);

  // handle case where data is undefined or empty
  if (!data || data.length === 0) {
    return <div>No data to display</div>;
  }

  // filter out useful words from data
  const filteredData = data.filter((item) => usefulWords.includes(item._id));

  // map data to the format required by apex chart
  const chartData = filteredData.map((item) => ({
    x: item._id,
    y: item.count,
  }));

  const options = {
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: true,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val) => val.toLocaleString(),
      },
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
  };

  const series = [
    {
      data: chartData,
    },
  ];

  const handleKeywordClick = (keyword) => {
    history.push(`/feedback?searchKeyword=${keyword}&customerFeedback=Bad`);
  };

  return (
    <Row className="">
      <Col sm="6">
        <Card className="">
          <CardHeader>
            <CardTitle>Negative keyword analysis</CardTitle>
          </CardHeader>
          <div style={{ height: "900px", overflow: "auto" }}>
            <Table responsive>
              <thead>
                <tr>
                  {tableHeaderData.map((item) => {
                    return <th key={uuidv4()}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item) => (
                  <tr key={uuidv4()}>
                    <td className="font-weight-bold">
                      <a href="#" onClick={() => handleKeywordClick(item._id)}>
                        {item._id}
                      </a>{" "}
                    </td>
                    <td className="font-weight-bold ">{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <Col sm="6">
        <Chart options={options} series={series} type="treemap" height="1000" />
      </Col>
    </Row>
  );
};

export default FeedbackBadRemarkKeywordData;
