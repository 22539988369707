import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import WhyHosteller from "./WhyHosteller";
import HowDoWe from "./HowDoWe";
import ZigZag from "./ZigZag";
import Banner from "./Banner";
import Seo from "./Seo";
import CmsTabs from "@components/Cms/CmsTabs";
import { useDispatch } from "react-redux";
import { useGetPartner } from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";

const tabOptions = [
  {
    id: 0,
    title: "Banner",
    formComponent: <Banner />,
  },
  {
    id: 1,
    title: "Why Hosteller",
    formComponent: <WhyHosteller />,
  },
  {
    id: 2,
    title: "ZigZag",
    formComponent: <ZigZag />,
  },
  {
    id: 3,
    title: "How Do We",
    formComponent: <HowDoWe />,
  },
  {
    id: 4,
    title: "SEO",
    formComponent: <Seo />,
  },
];

const AddHostel = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);

  const { data } = useGetPartner();

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getPartner) {
      dispatch(tableDataHandler(data?.getPartner));
    }
  }, [data]);

  return (
    <div>
      <div className='mt-1 mb-2'>
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className='border-bottom'>
                <CardTitle>
                  Partner With Us Page -{" "}
                  <b>
                    {" "}
                    {tabOptions.find((item) => item.id === active).title}{" "}
                    Section{" "}
                  </b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className='border-bottom'>
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default AddHostel;

// <StatisticsCard />;
