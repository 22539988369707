import React, { useEffect, useState } from "react";
import OffersTable from "../OffersTable";
import AddEditModal from "../AddEditModal";
import { useGetAllOffer } from "@api";
import TablePagination from "@components/common/TablePagination";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const Offers = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { data, refetch } = useGetAllOffer(limit, page);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data?.getAllOffer?.data) {
      setTableData(data?.getAllOffer?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getAllOffer?.count / limit));
  }, [data]);

  useEffect(() => {
    if (data?.getAllOffer?.data?.length) {
      let editData = data?.getAllOffer?.data?.find((item) => item._id === id);

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  return (
    <div>
      <OffersTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default Offers;
