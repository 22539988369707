import React, { useEffect, useState } from "react";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHostel } from "../../../../api/dataSource";
import { Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import ImageSectionPotrait from "./ImageSectionPotrait";

const UploadType = [
  {
    value: "potrait",
    label: "Potrait",
  },
];

const PotraitImageSection = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);

  const [
    updateHostelHandler,
    { data: updateHostel, reset: updateHostelReset },
  ] = useUpdateHostel();

  const dispatch = useDispatch();

  useEffect(() => {
    if (imageList?.length === 0) {
      state?.tableData?.potraitImageArray?.length &&
        setImageList(state?.tableData?.potraitImageArray);
    }
  }, [imageList?.length, state?.tableData?.potraitImageArray]);

  useEffect(() => {
    let arrProcessedImages = [];
    imageList.forEach((i) => {
      let imageObj = {
        type: i?.type,
        image: i?.image,
        key: i?.key,
        displayOrder: i?.displayOrder,
      };
      arrProcessedImages.push(imageObj);
    });

    setSortedImages(
      arrProcessedImages?.sort((a, b) => a.displayOrder - b.displayOrder)
    );
  }, [imageList]);

  //const { data, refetch } = useGetHostelById(id);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];
    imageList?.forEach((image) =>
      images.push({
        type: image?.type,
        image: image?.image,
        key: image?.key,
        displayOrder: parseInt(image?.displayOrder),
      })
    );
    console.log(images);
    if (images?.length > 0) {
      updateHostelHandler(id, { potraitImageArray: images });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          potraitImageArray: images,
        })
      );
      toaster("success", "Submitted successfully");
    } else {
      toaster("error", "Please upload images");
    }
  };

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSectionPotrait
            imageList={sortedImages}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default PotraitImageSection;
