import { useSkin } from "@hooks/useSkin";
import LoginStepFinal from "@components/Auth/Login/LoginStepFinal";
import AuthWrapper from "@components/Auth/common/AuthWrapper";

const Login = () => {
  const [skin, setSkin] = useSkin();

  const illustration = skin === "dark" ? "login-v2-dark.svg" : "login-v2.svg";
  const source = require(`@src/assets/images/pages/${illustration}`).default;

  return (
    <AuthWrapper source={source}>
      <LoginStepFinal />
    </AuthWrapper>
  );
};

export default Login;
