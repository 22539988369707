import gql from "graphql-tag";

export const GET_PAGINATED_STEP_OUT_LOCATION_LIST = gql`
  query getPaginatedStepOutLocation($filter: filter) {
    getPaginatedStepOutLocation(filter: $filter) {
      data {
        _id
        title
        description
        image
        galleryImage {
          image
          name
          displayOrder
        }
        flavour {
          title
          highlightedText
        }
        category {
          _id
          name
        }
        whySection {
          title
          highlightedText
          description
          image
        }
        upcomingAdventuresProducts {
          _id
          title
        }
        locations {
          _id
          title
          image
        }
        seo {
          metaTitle
          metaDesc
          keywords
        }
        url
        status
        moments {
          title
          highlightedText
          description
          items {
            imageUrl
            tag
            description
            displayOrder
          }
        }
      }
      count
      page
      limit
    }
  }
`;

export const GET_STEP_OUT_LOCATION_BY_ID = gql`
  query getStepOutLocationById($id: ID) {
    getStepOutLocationById(id: $id) {
      _id
      title
      description
      image
      galleryImage {
        image
        name
        displayOrder
      }
      flavour {
        title
        highlightedText
      }
      category {
        _id
        name
      }
      whySection {
        title
        highlightedText
        description
        image
      }
      whyYouLoveItSection {
        title
        highlightedText
        items {
          imageUrl
          title
          description
          displayOrder
        }
      }
      upcomingAdventuresProducts {
        _id
        title
      }
      locations {
        _id
        title
        image
      }
      seo {
        metaTitle
        metaDesc
        keywords
      }
      url
      status
      moments {
        title
        highlightedText
        description
        items {
          imageUrl
          tag
          description
          displayOrder
        }
      }
    }
  }
`;

export const GET_STEP_OUT_LOCATION_LIST = gql`
  query getAllStepOutLocation {
    getAllStepOutLocation {
      _id
      title
      description
      image
      galleryImage {
        image
        name
        displayOrder
      }
      flavour {
        title
        highlightedText
      }
      category {
        _id
        name
      }
      whySection {
        title
        highlightedText
        description
        image
      }
      upcomingAdventuresProducts {
        _id
        title
      }
      locations {
        _id
        title
        image
      }
      seo {
        metaTitle
        metaDesc
        keywords
      }
      url
      status
      moments {
        title
        highlightedText
        description
        items {
          imageUrl
          tag
          description
          displayOrder
        }
      }
    }
  }
`;
