import React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import "./style.css";
import Flatpickr from "react-flatpickr";
import { selectThemeColors } from "@utils";
import SearchResultComponent from "./SearchResultComponent";
import { useSkin } from "@hooks/useSkin";
import { formatDateToDDMMYYYY } from "./helpers";


function FilterSection({
  hostel,
  setHostel,
  hostelsList,
  handleDateRangeChange,
  dateRange,
  searchkeyword,
  setSearchkeyword,
  handleClickOnSearch,
  searchResult,
  searchKeyword,
  isSearchResultComponentOpen,
  setIsSearchResultComponentOpen,
}) {

  const [skin, setSkin] = useSkin();

  return (
    <main>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Date Range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              id="reviewDate"
              placeholder="Select Date Range"
              options={{
                mode: "range",
                dateFormat: "d-m-Y",
              }}
              value={[
                formatDateToDDMMYYYY(dateRange.startDate),
                formatDateToDDMMYYYY(dateRange.endDate),
              ]}
              onChange={handleDateRangeChange}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              <strong>Hostel Name</strong>
            </Label>
            <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
              <Select
                // isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                // isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </FormGroup>
        </Col>
        <Col sm="3" className="position-relative">
          <FormGroup className="w-100">
            <Label className="form-label" for="search">
              Search
            </Label>
            <Input
              value={searchkeyword}
              id="search"
              name="search"
              // placeholder=""
              onChange={(e) => {
                // setIsSearchResultComponentOpen(true);
                setSearchkeyword(e.target.value);
              }}
              // disabled={editData?._id}
            />
          </FormGroup>
          {isSearchResultComponentOpen ? (
            <SearchResultComponent
              searchResult={searchResult}
              setIsSearchResultComponentOpen={setIsSearchResultComponentOpen}
            />
          ) : null}
        </Col>
        <Col sm="2" className="mt-2">
          <Button.Ripple
            type="button"
            color="light"
            block
            onClick={handleClickOnSearch}
            // className="w-100"
          >
            Search
          </Button.Ripple>
        </Col>
      </Row>
    </main>
  );
}

export default FilterSection;
