import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_GO_PAYMENT_REQUEST = gql`
  mutation CreatePaymentRequest($paymentRequestInput: paymentRequestInput) {
    createPaymentRequest(paymentRequestInput: $paymentRequestInput) {
      hostel {
        _id
      }
      head
    }
  }
`;

export const useCreateGoPaymentRequest = () => {
  const [createGoPayment, { loading, error, data, refetch }] = useMutation(
    CREATE_GO_PAYMENT_REQUEST
  );

  useLoadingHandler(loading);

  const createGoPaymentHandler = (paymentRequestInput) => {
    createGoPayment({
      variables: {
        paymentRequestInput,
      },
    });
  };

  return [createGoPaymentHandler, { loading, error, data, refetch }];
};

export const UPDATE_GO_PAYMENT_REQUEST = gql`
  mutation UpdatePaymentRequest(
    $id: ID
    $paymentRequestInput: paymentRequestInput
  ) {
    updatePaymentRequest(id: $id, paymentRequestInput: $paymentRequestInput) {
      hostel {
        _id
      }
      vendorName
    }
  }
`;

export const useUpdateGoPaymentRequest = () => {
  const [updateGoPayment, { loading, error, data, refetch }] = useMutation(
    UPDATE_GO_PAYMENT_REQUEST
  );

  useLoadingHandler(loading);

  const updateGoPaymentHandler = (id, paymentRequestInput) => {
    updateGoPayment({
      variables: { id, paymentRequestInput },
    });
  };

  return [updateGoPaymentHandler, { loading, error, data, refetch }];
};

export const GENERATE_OPEX_EXPORT_DATA = gql`
  mutation generatexlsx($filter: filter) {
    generateOpexExport(filter: $filter)
  }
`;

export const useGenerateOpexExport = () => {
  const [generateOpexExport, { loading, error, data, refetch }] = useMutation(
    GENERATE_OPEX_EXPORT_DATA
  );
  useLoadingHandler(loading);
  const generateOpexExportHandler = (filter) => {
    generateOpexExport({
      variables: {
        filter,
      },
    });
  };
  return [generateOpexExportHandler, { data, refetch, loading, error }];
};

//upload pending invoice
export const UPDATE_PENDING_INVOICE = gql`
  mutation UpdatePendingInvoice(
    $id: ID!
    $invoiceAttachment: attachmentImageInput!
  ) {
    updatePendingInvoice(id: $id, invoiceAttachment: $invoiceAttachment) {
      _id
      invoiceAttachment {
        imageURL
      }
    }
  }
`;

export const useUpdatePendingInvoice = () => {
  const [updatePendingInvoice, { loading, error, data, refetch }] = useMutation(
    UPDATE_PENDING_INVOICE
  );

  useLoadingHandler(loading);

  const updatePendingInvoiceHandler = (id, invoiceAttachment) => {
    updatePendingInvoice({
      variables: { id, invoiceAttachment },
    });
  };

  return [updatePendingInvoiceHandler, { loading, error, data, refetch }];
};
