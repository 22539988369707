import Login from "../../views/Auth/Login/index";
import Register from "../../views/Auth/Register/index";
import ForgotPassword from "../../views/Auth/ForgotPassword/index";

const AuthRoutes = [
  {
    path: "/login",
    component: Login,
    layout: "BlankLayout",
    meta: {
      authRoute: true,
    },
  },

  {
    path: "/register",
    component: Register,
    layout: "BlankLayout",
    meta: {
      authRoute: true,
    },
  },

  {
    path: "/forgot-password",
    component: ForgotPassword,
    layout: "BlankLayout",
    meta: {
      authRoute: true,
    },
  },
];

export default AuthRoutes;
