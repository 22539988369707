import gql from "graphql-tag"

export const GET_LOYALTY_REPORT = gql`
query ($filter: filter) {
    getLoyaltyCoinsReport(filter: $filter) {
        data {
            coins
            email
            fullName
            mobile
            firstName
            lastName
            userId
        }
        limit
        page
        count
    }
}
`

export const GET_PAGINATED_LOYALTY_COINS = gql`
query ($filter: filter) {
    getPaginatedLoyaltyCoins(filter: $filter) {
    data {
    _id
    userId {
        _id
        fullName
        firstName 
        lastName
        email
    }
    bookingId
    reservationId
    membershipId
    bookingStatus
    coins
    coinsType
    eventName
    eventDate
    isUsable
    logs 
    }
    limit
    page
    count
    }
}
`

