import Coupon from "../../views/Report/Coupon/index";
import MerchandiseProducts from "../../views/MerchandiseStore/MerchandiseProducts"
import MerchandiseOrders from "../../views/MerchandiseStore/MerchandiseOrders";
import MerchandiseProductTabs from "../../views/MerchandiseStore/MerchandiseProducts/MerchandiseProductTabs";
const MerchandiseRoutes = [
    {
        path: "/merchandise/products",
        component: MerchandiseProducts,
        exact: true,
    }, {
        path: "/merchandise/orders",
        component: MerchandiseOrders,
        exact: true,
    },
    {
        path: "/merchandise/edit/merchandiseProduct/:id",
        component: MerchandiseProductTabs,
        exact: true,
        meta: {
            navLink: "/merchandise",
        },
    },
]

export default MerchandiseRoutes;