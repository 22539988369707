import React, { useEffect, useState } from "react";
import UnboxBannerTable from "./UnboxBannerTable";
import { useGetAllUnboxBanner } from "../../../api/Unbox/UnboxBanner/Queries";
import AddEditModal from "./AddEditModal";
import { useGetAllCafeMasterList } from "../../../api/Unbox/UnboxCafeMaster/Queries";

const UnboxBanner = () => {
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState("");
  const [editData, setEditData] = useState(null);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const { data, refetch } = useGetAllUnboxBanner();
  useEffect(() => {
    if (data?.getUnboxBanner?.length > 0) {
      setTableData(data?.getUnboxBanner);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (data?.getUnboxBanner) {
      let editData = data?.getUnboxBanner.find((item) => item._id === id);
      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [id]);
  return (
    <div>
      <UnboxBannerTable
        tableData={tableData}
        openModal={() => setOpenModal(!openModal)}
        setId={setId}
        refetch={refetch}
      />
      <AddEditModal
        open={openModal}
        handleToggle={toggleModal}
        editData={editData}
        refetch={refetch}
      />
    </div>
  );
};

export default UnboxBanner;
