import { highlight } from "prismjs";
import { v4 as uuidv4 } from "uuid";
import toaster from "@components/common/Toaster";
import { Trash } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import MyEditor from "../../../@core/components/common/Editor";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateStepoutBlog } from "../../../api/dataSource";
import confirmDelete from "@components/common/confirmDelete";

const StepoutBlogContent = () => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [highlight, setHighlight] = useState([
    {
      id: uuidv4(),
      blogContent: EditorState.createEmpty(),
    },
  ]);

  const [updateStepoutBlogHandler, { data: updatedStepoutBlogData }] =
    useUpdateStepoutBlog();

  useEffect(() => {
    if (updatedStepoutBlogData?.updateStepoutBlog?._id) {
      toaster("success", "Blog content updated successfully");
    }
  }, [updatedStepoutBlogData]);

  useEffect(() => {
    if (state?.tableData?.blogContent?.length) {
      let upadtedHighlight = state?.tableData?.blogContent?.map((item) => {
        return {
          id: uuidv4(),
          blogContent: item?.blogContent
            ? customHTMLToDraftConvert(item?.blogContent)
            : customHTMLToDraftConvert(item),
        };
      });
      setHighlight(upadtedHighlight);
    }
  }, [state]);

  const customHTMLToDraftConvert = (value) => {
    value = value
      .replace(/&amp;/g, "&")
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<")
      .replace(/&quot;/g, '"');

    return EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(value).contentBlocks)
    );
  };

  const handleChangeHighlightBlogContent = (blogContent, id) =>
    setHighlight(
      highlight?.map((item) =>
        item.id === id ? { ...item, blogContent: blogContent } : item
      )
    );

  const handleRemoveHighlight = (id) =>
    confirmDelete(() =>
      setHighlight(highlight.filter((item) => item.id !== id))
    );

  const handleAddHighlight = () => {
    if (highlight.length < 10) {
      setHighlight([
        ...highlight,
        { id: uuidv4(), blogContent: EditorState.createEmpty() },
      ]);
    } else {
      toaster("error", "Max 10 are allowed");
    }
  };

  const submitHandler = (e) => {
    let isValid = false;
    e.preventDefault();
    highlight?.forEach((item) => {
      delete item.id;
    });

    let updatedArray = highlight?.map((item) => {
      let blogContent = draftToHtml(
        convertToRaw(item?.blogContent?.getCurrentContent())
      );
      return { ...item, blogContent };
    });

    const data = {
      blogContent: updatedArray?.map((item) => item.blogContent),
    };

    updatedArray?.forEach((item) => {
      if (item?.blogContent) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          blogContent: updatedArray,
        })
      );
      updateStepoutBlogHandler(id, data);
      // toaster("success", "Submitted successfully");
    } else {
      toaster("error", "Please fill in the details");
    }
  };
  return (
    <>
      <form onSubmit={submitHandler}>
        <Row className="mt-2">
          <Col>
            <Label className="form-label mt-2" for={"highlight"}>
              Blog Content
            </Label>
          </Col>

          {highlight?.map(({ blogContent, id }, index) => (
            <Col className="d-flex align-items-end" sm="12" key={id}>
              <FormGroup className="w-100 mt-2">
                <div className="mt-1 ">
                  <MyEditor
                    editorState={blogContent}
                    id={id}
                    setEditorState={(val) =>
                      handleChangeHighlightBlogContent(val, id)
                    }
                  />
                </div>
              </FormGroup>
              {highlight.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveHighlight(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>
        <SaveDiscardBtnGroup addAction={handleAddHighlight} />
      </form>
      {/* hello */}
    </>
  );
};

export default StepoutBlogContent;
