// ! Added by Ashwin

import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import HomepageImageSection from "./HomepageImageSection";
import { useUpdateHomePageDetails } from "../../../../api/dataSource";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useParams } from "react-router";

const UploadType = [
  { value: "desktop", label: "Desktop" },
  { value: "mobile", label: "Mobile" },
];

const Image = () => {
  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const [imageList, setImageList] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);
  const [updateHomePageDetailsHandler, { data, reset }] =
    useUpdateHomePageDetails();
  const dispatch = useDispatch();

  useEffect(() => {
    setImageList(
      state?.tableData?.images?.length > 0 ? state?.tableData?.images : []
    );
  }, [state?.tableData?.images]);

  useEffect(() => {
    let arrProcessedImages = [];
    imageList.forEach((i) => {
      let imageObj = {
        type: i.type,
        image: i.image,
        key: i.key,
        link: i.link,
        displayOrder: i.displayOrder,
      };
      arrProcessedImages.push(imageObj);
    });

    setSortedImages(
      arrProcessedImages.sort((a, b) => a.displayOrder - b.displayOrder)
    );
  }, [imageList]);

  useEffect(() => {
    if (data?.updateHomePage?._id) {
      // toaster("success", "Updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          images: imageList,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data?.updateHomePage?._id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];
    imageList.map((image) => {
      image.image &&
        images.push({
          type: image.type,
          image: image.image,
          key: image.key,
          link: image.link,
          displayOrder: parseInt(image.displayOrder),
        });
    });

    // updateHomePageDetailsHandler(id, { images: images }); // "hosteller" is used to get landing page doc
    // toaster("success", "Submitted successfully");
    if (images?.length !== 0) {
      updateHomePageDetailsHandler(id, { images: images }); // "hosteller" is used to get landing page doc
      toaster("success", "Updated images successfully");
    } else {
      toaster("error", "Please upload images");
    }
  };

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <HomepageImageSection
            imageList={sortedImages}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default Image;
