import { gql } from "@apollo/client";

export const GET_PAGINATED_STEPOUT_USERS = gql`
query getPaginatedStepoutBlogList($filter: filter){
    getPaginatedProfile(filter: $filter){
        data{

        _id
        fullName
        mobile
        email
        gender
        }
        count
        page
        limit
    }
}
`;
