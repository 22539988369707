import { User, Circle } from "react-feather";

// const role = localStorage.getItem("role");

export default 
// role === "Super Admin" ||
localStorage.getItem("modules")?.includes("Website Users")
  ? [
      {
        id: "user",
        title: "Website Users",
        icon: <User size={20} />,
        badge: "light-warning",
        children: [
          (
            // role === "Super Admin" ||
            localStorage.getItem("modules")?.includes("Website Users")) && {
            id: "userInfo",
            title: "User Info",
            icon: <Circle size={12} />,
            navLink: "/user/userInfo",
          },
        ],
      },
    ]
  : [];
