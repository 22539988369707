//Coded by Nitish

import React, { useEffect, useState } from "react";
import GuestDatabaseTable from "./GuestDatabaseTable";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import { useAllGeustDatabase } from "@api";

const GuestDatabase = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dataCount, setDataCount] = useState(0);
  const { data, refetch } = useAllGeustDatabase(searchKeyword);

  useEffect(() => {
    if (data?.getGuestDatabase && searchKeyword !== "") {
      setTableData(data?.getGuestDatabase);
    } else {
      setTableData([]);
    }
  }, [data, searchKeyword]);

  useEffect(() => {
    setDataCount(tableData.length);
  }, [tableData]);

  const handleSearch = () => {
    if (searchQuery !== "") {
      setSearchKeyword(searchQuery);
    }
  };

  const clearData = ()=> {
    setSearchQuery("");
    setTableData([])
  }

  return (
    localStorage.getItem("modules")?.includes("Reports") &&  <div>
      <Row>
        <Col sm="12">
          <FormGroup className="w-75">
            <Label className="form-label" for="url">
              <strong>Search Guest Data</strong>
            </Label>
            <div className="d-flex">
              <Input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Guest Data..."
              />
              <Button
                onClick={handleSearch}
                className="ml-2 w-25"
                color="primary"
              >
                Search
              </Button>
              <Button
                onClick={clearData}
                className="ml-2 w-25"
                color="danger"
              >
                Reset
              </Button>
            </div>
            <div className="mt-2">
               <div> <h2> Data Count: {dataCount}</h2> </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      {tableData.length ?  <GuestDatabaseTable data={tableData} refetch={refetch} />:<h2 style={{textAlign:"center", marginTop:"150px"}}>No Record Found</h2>}
     
    </div>
  );
};

export default GuestDatabase;
