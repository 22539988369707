import React, { useCallback } from "react";
import StripConfigTable from "./StripConfigTable";
import {
  useCreateStripConfig,
  useDeleteStripConfig,
  useGetStripConfigData,
  useUpdateStripConfig,
} from "../../../api/dataSource";
import { useEffect } from "react";
import { useState } from "react";
import toaster from "../../../@core/components/common/Toaster";

function StripConfigIndex() {
  const [stripConfidData, setStripConfigData] = useState(null);
  const { data, refetch } = useGetStripConfigData();
  const [addEditModal, setAddEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState(null);

  //Api
  const [createStripConfigHandler, { data: createStripConfigData }] =
    useCreateStripConfig();
  const [updateStripConfigHandler] = useUpdateStripConfig();
  const [deleteStripHandler, { data: deletedData }] = useDeleteStripConfig();

  const handleToggle = useCallback(() => {
    setAddEditModal((prevValue) => !prevValue);
    refetch();
  }, []);

  useEffect(() => {
    if (deletedData?.deleteStripConfig) {
      refetch();
      toaster("success", "Strip deleted");
    }
  }, [deletedData?.deleteStripConfig, refetch]);

  useEffect(() => {
    if (createStripConfigData?.createStripConfig) {
      refetch();
      handleToggle();
    }
  }, [createStripConfigData?.createStripConfig, handleToggle, refetch]);

  useEffect(() => {
    if (data?.getStripConfigData?.length) {
      const editedData = data?.getStripConfigData?.find(
        (item) => item?._id === id
      );
      setEditData(editedData);
    }
  }, [data?.getStripConfigData, id]);

  useEffect(() => {
    if (data?.getStripConfigData?.length) {
      setStripConfigData(data?.getStripConfigData);
    }
  }, [data?.getStripConfigData]);

  return (
    localStorage.getItem("modules")?.includes("CMS") && (
      <StripConfigTable
        deletedData={deletedData}
        deleteStripHandler={deleteStripHandler}
        refetch={refetch}
        updateStripConfigHandler={updateStripConfigHandler}
        editData={editData}
        setId={setId}
        handleToggle={handleToggle}
        addEditModal={addEditModal}
        createStripConfigHandler={createStripConfigHandler}
        tableBody={stripConfidData}
      />
    )
  );
}

export default StripConfigIndex;
