// ! Added by Ashwin
import gql from "graphql-tag";

export const CREATE_WHATSNEW = gql`
mutation createWhatsNew($whatsNewInput: WhatsNewInput){
createWhatsNew(whatsNewInput: $whatsNewInput){
    tagLine
    image
    title
    description
    url
    status
    buttonText
    displayOrder
    }
}
`

export const UPDATE_WHATSNEW = gql`
mutation updateWhatsNew($id: ID, $whatsNewUpdate: WhatsNewInput) {
    updateWhatsNew(id: $id, whatsNewUpdate: $whatsNewUpdate){
        tagLine
    }
}
`

export const UPDATE_ALL_WHATSNEW = gql`
mutation updateAllWhatsNew($whatsNewList: [WhatsNewInput]){
    updateAllWhatsNew(whatsNewList:$whatsNewList)
}
`

export const UPDATE_WHATSNEW_STATUS = gql `
mutation updateWhatsNewStatus($id: ID, $status: String){
    updateWhatsNewStatus(id: $id, status: $status){
        title
    }
}
`