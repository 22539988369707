// ! Added by Ashwin

import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import ImageSection from "./ImageSection";
import { useUpdateMediaDetails } from "../../../../api/dataSource";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { MEDIA } from "../../../../utility/constant";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadType = [
  { value: "home", label: "Home" },
  { value: "destination", label: "Destination" },
  { value: "hostel", label: "Hostel" },
  { value: "workation", label: "Workation" },
  { value: "policy", label: "Policy" },
  { value: "about", label: "About" },
  { value: "contact", label: "Contact" },
  { value: "pressMention", label: "PressMention" },
  { value: "volunteer", label: "Volunteer" },
  { value: "emailers", label: "Emailers" },
  { value: "others", label: "Others" },
];

const Photos = () => {
  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [updateMediaDetailsHandler, { data, reset }] = useUpdateMediaDetails();
  const dispatch = useDispatch();

  useEffect(() => {
    state?.tableData &&
      state.tableData.images?.length &&
      setImageList(state.tableData.images);
  }, [state?.tableData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];

    imageList.map((image) => {
      image.image &&
        images.push({
          type: image.type,
          image: image.image,
          key: image.key,
        });
    });

    updateMediaDetailsHandler(MEDIA, { images: images }); // "hosteller" is used to get landing page doc
    toaster("success", "Media updated successfully");
    dispatch(
      tableDataHandler({
        ...state?.tableData,
        images,
      })
    );
  };

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={imageList}
            setImageList={setImageList}
            uploadType={UploadType}
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default Photos;
