// Added by Nitish
import gql from "graphql-tag";

export const GET_PAGINATED_ADDONS_BOOKINGS = gql`
  query getPaginatedAddOnsBookings($filter: filter) {
    getPaginatedAddOnsBookings(filter: $filter) {
      count
      limit
      page
      totalRevenue
      data {
        _id
        paymentStatus
        reservationId
        service
        hostel
        price
        tax
        quantity
        totalPrice
        bookingId
        bookingDate
        deliveryDate
        deliveryTime
        ticket
        comment
        razorpayOrderId
        razorpayPaymentId
        cancellationDate
        cancellationRemark
        cancellationAmount
        cancelledBy
        refundId
        refundDate
        refundStatus
        invoiceNo
        status
        remark
        logs
        rating
        createdAt
        guestDetails {
          GuestName
          hostelName
          Mobile
          Email
          hostelName
          hostelId
        }
        category
        title
        dropDate
        serviceData
        checkinDate
        checkoutDate
      }
    }
  }
`;

//for export
export const GET_ALL_ADDONS_BOOKINGS = gql`
  query getAllAddOnsBookings($filter: filter) {
    getAllAddOnsBookings(filter: $filter) {
      data {
        _id
        paymentStatus
        reservationId
        service
        hostel
        price
        tax
        quantity
        totalPrice
        bookingId
        bookingDate
        deliveryDate
        deliveryTime
        ticket
        comment
        razorpayOrderId
        razorpayPaymentId
        cancellationDate
        cancellationRemark
        cancellationAmount
        cancelledBy
        refundId
        refundDate
        refundStatus
        invoiceNo
        status
        remark
        logs
        rating
        createdAt
        guestDetails {
          GuestName
          hostelName
          Mobile
          Email
        }
        category
        title
        dropDate
        serviceData
        checkinDate
        checkoutDate
      }
    }
  }
`;

//added this for invoice download
export const GET_SERVICE_INVOICE_ON_SUCCESS = gql`
  query getServiceInvoiceOnSuccess($bookingId: String!) {
    getServiceInvoiceOnSuccess(bookingId: $bookingId) {
      legalEntityName
      locationName
      locationAddress
      gstin
      invoiceNo
      invoiceDate
      customerName
      stateNamePlaceOfSupply
      hsnCode
      serviceDescription
      paymentRefId
      price
      tax
      taxRate
      quantity
      totalPrice
      service
    }
  }
`;
