// ! Coded by Nitish
import gql from "graphql-tag";

export const GET_CONTACTLESS_CHECKOUT_LIST = gql`
  query getContactlessCheckoutList($inputFilter: inputFilter) {
    getContactlessCheckoutList(inputFilter: $inputFilter) {
      data {
        reservationID
        checkoutTime
        feedbackType
        checkOutFeedback
        checkOutRemark
        guestDetails {
          hostelName
          DepartureDate
          DepartureTime
          Email
          GuestName
          FirstName
          LastName
          Mobile
          Room
          RoomNo
          Source
          hostelId
          webCheckinUpdated
          ArrivalDate
          ArrivalTime
          eylStatus
        }
      }
      count
      page
      limit
    }
  }
`;

export const GET_ALL_CONTACTLESS_CHECKOUT_DATA = gql`
  query getAllContactlessCheckoutData($inputFilter: inputFilter) {
    getAllContactlessCheckoutData(inputFilter: $inputFilter) {
      data {
        reservationID
        checkoutTime
        feedbackType
        checkOutFeedback
        checkOutRemark
        guestDetails {
          hostelName
          DepartureDate
          DepartureTime
          Email
          GuestName
          FirstName
          LastName
          Mobile
          Room
          RoomNo
          Source
          hostelId
          webCheckinUpdated
          ArrivalDate
          ArrivalTime
          eylStatus
        }
      }
      count
    }
  }
`;
