// !Added by Ashwin

import React from "react";
import { Edit, Trash2 } from "react-feather";
import { Button } from "reactstrap";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";

const WebAppRequestsTableBody = ({
  tableRowData,
  openModal,
  handleEdit,
  handleDelete,
}) => {
  return (
    <>
      {tableRowData.map(
        ({ requestCategory, requestName, tat, maxAllowedRequest, id }) => (
          <tr key={id}>
            <td style={{ width: "5%" }}>
              <Button
                className="rounded-circle btn-icon"
                color="none"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                  handleEdit(id);
                }}
              >
                <Edit
                  color={reactFeatherIcon.iconColor}
                  size={reactFeatherIcon.iconSize}
                />
              </Button>

              {handleDelete && (
                <Button
                  className="rounded-circle btn-icon"
                  color="none"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(id);
                  }}
                >
                  <Trash2
                    color={reactFeatherIcon.iconColor}
                    size={reactFeatherIcon.iconSize}
                  />
                </Button>
              )}
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {requestCategory}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {requestName}
              </span>
            </td>
            {/* <td>
            <span className='align-middle font-weight-bold'>{tat}</span>
          </td> */}
            <td>
              <span className="align-middle font-weight-bold">
                {maxAllowedRequest}
              </span>
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default WebAppRequestsTableBody;
