import React from 'react';

const ConnectTagUserName = ({ comment, userList, setComment, setVisibility, setSearchName, setTaggedUsers, }) => {
    const list = userList?.getUserListToTag;
    const handleClick = (name) => {
        setVisibility(false);
        const lastIndex = comment.lastIndexOf('@');
        setComment(prevComment => prevComment.substring(0, lastIndex + 1) + name + ' ');
        setTaggedUsers(taggedUsers => [...taggedUsers, name]);
        setSearchName(name);
    };
    return (
        <div style={{ position: 'absolute' }}>
            {
                list?.length > 0 ?
                        <div style={{
                            position: 'absolute',
                            maxHeight: '100px',
                            top: '40px', overflow: 'auto',
                            msOverflowStyle: 'none',
                            scrollbarWidth: 'none',
                            backdropFilter: 'blue(200px)',
                            zIndex: "10", 
                            backgroundColor: "#808080",
                            color: "white",
                            borderRadius: "0 0 10px 10px",
                            padding: "5px",
                            width:"400px",
                        }}>
                        {list?.map((user, index) => (
                            <div key={index} onClick={() => handleClick(user?.name)} style={{ cursor: "pointer", margin: "1px", backgroundColor: "#808093", borderRadius: "5px" }}>
                                {user?.name}
                            </div>
                        ))}
                    </div> : <></>
            }

        </div>
    );
};

export default ConnectTagUserName;
