import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const GET_PAGINATED_MASTER_MENU = gql`
  query GetPaginatedMasterMenu($filter: filter) {
    getPaginatedMasterMenu(filter: $filter) {
      data {
        _id
        itemName
        itemCategory
        description
        type
        image
        weekDayPrice
        weekEndPrice
        optionsTitle
        extrasTitle
        options {
          name
          weekDayPrice
          weekEndPrice
        }
        extras {
          name
          weekDayPrice
          weekEndPrice
        }
        hsnCode
        tax
        nudge
      }
      count
      limit
      page
    }
  }
`;

export const useGetPaginatedMasterMenu = (limit, page, filterFeilds) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_MASTER_MENU,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFeilds
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_UNBOX_MASTER_MENU_BY_ID = gql`
  query GetUnboxMasterMenu($id: ID) {
    getUnboxMasterMenu(id: $id) {
      itemName
      itemCategory
      description
      image
      type
      weekDayPrice
      weekEndPrice
      optionsTitle
      extrasTitle
      options {
        name
        weekEndPrice
        weekDayPrice
      }
      extras {
        name
        weekDayPrice
        weekEndPrice
      }
      hsnCode
      tax
      nudge
    }
  }
`;

export const useGetMasterMenuByID = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_UNBOX_MASTER_MENU_BY_ID,
    {
      variables: {
        id,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_ALL_MASTER_MENU = gql`
  query GetAllMAsterMenu {
    getAllMasterMenu {
      _id
      itemName
      itemCategory
    }
  }
`;

export const useGetAllMasterMenu = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_MASTER_MENU);

  useLoadingHandler(loading);

  return { data, error, refetch };
};
