//  Added by Nitish
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import { useHostelList } from "../../api/dataSource";
import { selectThemeColors } from "@utils";
// import { Toaster } from "react-hot-toast";
import { Toaster, toast } from "react-hot-toast";

const ORMSyncReviews = () => {
  const [source, setSource] = useState("");
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  const bookingSourceDropDownList = [
    { label: "Booking.com", value: "Booking.com" },
    { label: "Goibibo", value: "Goibibo" },
    { label: "HostelWorld", value: "HostelWorld" },
    { label: "TripAdvisor", value: "Tripadvisor" },
    { label: "Google", value: "Google" },
    { label: "MakeMyTrip", value: "MakeMyTrip" },
    { label: "Airbnb", value: "Airbnb" },
    { label: "All", value: "All" },
  ];

  // const handleSync = () => {
  //   if (!source || !hostel) {
  //     console.error("Source or Hostel must be selected");
  //     return;
  //   }

  //   const apiUrl = `https://api.thehosteller.com/rest/v1/sync-reviews?bookingSource=${source?.value}&hostelId=${hostel?.value}`;

  //   fetch(apiUrl, {
  //     method: "POST",
  //   })
  //     .then((data) => {
  //       console.log("Response Data:", data);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  const handleSync = () => {
    if (!source || !hostel) {
      console.error("Source or Hostel must be selected");
      toast.error("Source or Hostel must be selected");
      return;
    }

    const apiUrl = `https://api.thehosteller.com/rest/v1/start-sync-reviews-from-admin?bookingSource=${source?.value}&hostelId=${hostel?.value}`;

    fetch(apiUrl, {
      method: "POST",
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Sync is in progress...");
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const handleRecentSync = () => {
    if (!source || !hostel) {
      console.error("Source or Hostel must be selected");
      toast.error("Source or Hostel must be selected");
      return;
    }

    const isCron = true;

    const apiUrl = `https://api.thehosteller.com/rest/v1/start-sync-reviews-from-admin?bookingSource=${source?.value}&hostelId=${hostel?.value}&isCron=${isCron}`;

    fetch(apiUrl, {
      method: "POST",
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Sync is in progress...");
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  return (
    localStorage.getItem("modules")?.includes("ORM Configuration") && (
      <div>
        <h2>
          <strong>Sync Reviews</strong>
        </h2>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="Category">
                <strong>Booking Source*</strong>
              </Label>
              <Select
                isClearable={true}
                id="source"
                name="source"
                value={source}
                options={bookingSourceDropDownList}
                onChange={(value) => {
                  setSource(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Hostel Name</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                id="hostel"
                name="hostel"
                value={hostel}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100 mt-2">
              <Button
                className="mr-2"
                color="primary"
                onClick={handleRecentSync}
              >
                Sync Recent Reviews
              </Button>
              <Button color="primary" onClick={handleSync}>
                Sync All Reviews
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
    )
  );
};

export default ORMSyncReviews;
