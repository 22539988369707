import gql from "graphql-tag";

export const GET_REFUNDS = gql`
query getRefunds {
    _id
    amount
    providerOrderRefId
    providerPaymentRefId
    providerRefundRefId
    refunded
}
`