import React, { useEffect, useState } from "react";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateWorkationPackage } from "@api";
import { Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import ImageSection from "@components/common/ImageSection";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadType = [
  {
    value: "general",
    label: "General",
  },
  {
    value: "outdoor",
    label: "Outdoor",
  },
  {
    value: "room",
    label: "Room",
  },
  {
    value: "mobile",
    label: "Mobile",
  },
];

const PhotosForm = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);

  const [
    updateWorkationPackageHandler,
    { data: updatedData, reset: updatedReset },
  ] = useUpdateWorkationPackage();

  const dispatch = useDispatch();

  useEffect(() => {
    state?.tableData?.images?.length && setImageList(state?.tableData?.images);
  }, [state?.tableData?.images]);

  useEffect(() => {
    if (updatedData?.updateWorkationPackage?._id) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          images: imageList,
        })
      );

      updatedReset();
    }
  }, [updatedData?.updateWorkationPackage?._id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = imageList.map((image) => ({
      type: image.type,
      image: image.image,
      key: image.key,
    }));

    updateWorkationPackageHandler(id, { images });
  };

  return (
    <div className='mt-2'>
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={imageList}
            setImageList={setImageList}
            uploadType={UploadType}
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default PhotosForm;
