import { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { Col, Card, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { useGetRequestByHostelId } from "../../../api/dataSource";
import HostelRequestedDataTable from "./HostelRequestedDataTable";

const tableHeaderData = ["Hostels", "Action"];

const HostelRequestTable = ({ hostelListData }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedHostelId, setSelectedHostelId] = useState(null);
  const { data: requestListData } = useGetRequestByHostelId(selectedHostelId);

  useEffect(() => {
    if (hostelListData?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
            return hostelListData?.find((hostel) => hostel?._id === hostelID);
          })
          : hostelListData;
      setTableData(filteredHostel);
    }
  }, [hostelListData]);

  return (
    <Row>
      <Col sm="4">
        <Card>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr
                  key={uuidv4()}
                  onClick={() => {
                    setSelectedHostelId(item?._id);
                  }}
                  className={selectedHostelId === item?._id ? "bg-primary" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <td>{item?.name}</td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownItem>
                        <ChevronRight className="mr-50" size={17} />
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
              {/*  */}
            </tbody>
          </Table>
        </Card>
      </Col>
      <Col sm="8">
        <HostelRequestedDataTable
          requestListData={requestListData}
          selectedHostelId={selectedHostelId}
        />
      </Col>
    </Row>
  );
};

export default HostelRequestTable;
