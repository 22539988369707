import { Edit } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Hostel Name",
  "Reservation No.",
  "Checkin Date",
  "Checkout Date",
  "Rooms",
  "Rate Plan",
  "Room Number",
  "Guest Name",
  "Meals Added in ezee",
];

const EzeeMealstable = ({ openModal, setId, data }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>Ezee Meals</h2>
              </div>
            </CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={uuidv4()}>
                <td style={{ width: "5%" }}>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>{item?.hostelName}</td>
                  <td>{item?.ReservationNo}</td>
                  <td>{dateFormatterDDMMYYYY(item?.ArrivalDate)}</td>
                  <td>{dateFormatterDDMMYYYY(item?.DepartureDate)}</td>
                  <td>{item?.Room}</td>
                  <td>{item?.RatePlan}</td>
                  <td>{item?.RoomNo}</td>
                  <td>{item?.GuestName}</td>
                  <td>
                    <Badge
                      pill
                      color={
                        item?.mealAddedInEzee ? "light-success" : "light-danger"
                      }
                      className="mr-1"
                    >
                      {item?.mealAddedInEzee ? "Yes" : "No"}
                    </Badge>
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default EzeeMealstable;
