import React from "react";
import { Edit } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "@utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Full Name",
  "Email",
  "Mobile",
  "Bookings Count",
  "Total Booking Amount",
  "Signup Date",
];

const UserInfoTable = ({ openModal, setId, data, count }) => {
  const userBookingTotalAmount = (item) => {
    let sum = 0;
    if (item && item.length > 0) {
      sum = item.reduce((acc, booking) => {
        if (booking && !isNaN(booking.totalAmount)) {
          return acc + booking.totalAmount;
        } else {
          return acc;
        }
      }, 0);
    }
    return sum.toFixed(2);
  };

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <div>
                  <h2> Users ({count !== undefined ? count : 0} count) </h2>
                </div>
              </CardTitle>
              {/* <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                Add Users Info.
              </Button.Ripple> */}
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  {tableHeaderData.map((item) => {
                    return <th key={uuidv4()}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  <tr key={uuidv4()}>
                    <td style={{ width: "5%" }}>
                      <Button
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td>{item?.fullName}</td>
                    <td>{item?.email}</td>
                    <td>{item?.mobile}</td>
                    <td>{item?.UserBookings?.length}</td>
                    <td>₹ {userBookingTotalAmount(item?.UserBookings)}</td>
                    <td>{dateFormatHandler(item?.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserInfoTable;
