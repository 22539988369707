import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { X, Link, Upload } from "react-feather";
import { DragDrop } from "@uppy/react";
import { useUploadImage } from "@api";
import confirmDelete from "@components/common/confirmDelete";
import "../../WebApp/UserRequest/StaffImagesSection/index.scss";
import { Col, Row } from "reactstrap";
import toaster from "@components/common/Toaster";

const AttachmentComponent = ({
  imageList,
  setImageList,
  editData,
  uploadType,
  landingPage,
  userDepartment,
  data,
}) => {
  const [imageLink, setImageLink] = useState([]);
  const [uploadingType, setUploadingType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadImageHandler, { data: imageData, reset }] = useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".pdf"],
      maxFileSize: 2097152,
    },
    onBeforeFileAdded: (currentFile, files) => {
      if (currentFile.size > 2097152) {
        toaster("error", "File size exceeds the maximum limit of 2 MB.");
        return false;
      }
      return true;
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("complete", (result) => {

    result.successful.forEach((file) => {
      uppy.removeFile(file.id);

      setUploadingType(result?.successful[0]?.source, editData);

      result.successful[0]?.data &&
        uploadImageHandler(result?.successful[0]?.data, `GO_PURCHASE_REQUEST`);

      toaster("success", "Image / PDF uploaded successfully");
    });
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading image: ${error.message}`);
  });

  useEffect(() => {
    const newImage = {};
    uploadType
      ? (newImage.imageURL = imageData?.uploadImage?.url)
      : (newImage.url = imageData?.uploadImage?.url);

    if (imageData?.uploadImage?.url) {
      setImageList((prev) => [newImage, ...prev]);
      reset();
      setUploadingType(null);
    }
  }, [imageData]);

  const removeImages = (image) => {
    const newSet = imageList?.filter((item) => {
      const imgUrl = item?.imageURL ? item?.imageURL : item?.url;
      return imgUrl !== image;
    });
    setImageList(newSet);
  };

  const handleRemoveImage = (image) => {
    confirmDelete(() => removeImages(image));
  };

  const openModal = (index, link) => {
    setShowModal(true);
    setImageLink({ index, link });
  };

  const closeModal = () => {
    setShowModal(false);
    setImageLink({});
  };

  const addImageLink = (index, link) => {
    let newImageList = [...imageList];
    const imgWithLink = { ...newImageList[index], link };
    newImageList[index] = imgWithLink;
    setImageList(newImageList);
  };

  const handleUploadClick = () => {
    document.querySelector(`#file-input-${uploadType[0].value}`).click();
  };

  return (
    <Row>
      <Col>
        <div className="imageSection__container">
          {uploadType?.map(({ value, label }) => (
            <div key={value}>
              <div className="imageSection__imagesContainer">
                {imageList?.map((item, index) => {
                  const imageURL = item?.imageURL || item?.url;
                  return (
                    <div
                      key={index}
                      className=""
                      style={{
                        width: "6em",
                        height: "6em",
                        position: "relative",
                        margin: "0.5em",
                      }}
                    >
                      {imageURL?.includes(".pdf") ? (
                        <img
                          className="imageSection__image"
                          src={
                            "https://static-dev.thehosteller.com/GO_PURCHASE_REQUEST/pdfImage-1720680601586.png"
                          }
                          alt="Attachment"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(imageURL, "_blank")}
                        />
                      ) : (
                        <img
                          className="imageSection__image"
                          src={imageURL}
                          alt="Attachment"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(imageURL, "_blank")}
                        />
                      )}
                      {userDepartment === "Purchase" && (
                        /* ||
                        userDepartment === "Finance" */
                        <div
                          onClick={() => handleRemoveImage(imageURL)}
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            backgroundColor: "#ffffff",
                            padding: "1px",
                            cursor: "pointer",
                          }}
                        >
                          <X size={16} color="black" />
                        </div>
                      )}
                      {landingPage && (
                        <div
                          onClick={() => openModal(index, item?.link)}
                          style={{
                            position: "absolute",
                            bottom: "2px",
                            right: "2px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            borderRadius: "50%",
                            padding: "2px",
                            cursor: "pointer",
                          }}
                        >
                          <Link size={16} />
                        </div>
                      )}
                    </div>
                  );
                })}
                {userDepartment === "Purchase" && (
                  /* ||
                  userDepartment === "Finance" */
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "6em",
                      height: "6em",
                      cursor: "pointer",
                      margin: "0.5em",
                    }}
                    onClick={handleUploadClick}
                  >
                    <Upload size={30} />
                    <input
                      type="file"
                      id={`file-input-${uploadType[0].value}`}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          if (file.size > 2097152) {
                            toaster(
                              "error",
                              "File size exceeds the maximum limit of 2 MB."
                            );
                          } else {
                            uppy.addFile({
                              name: file.name,
                              type: file.type,
                              data: file,
                            });
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <br />
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default AttachmentComponent;

// import React from "react";
// import { useEffect, useState } from "react";
// import "uppy/dist/uppy.css";
// import "@uppy/status-bar/dist/style.css";
// import "@styles/react/libs/file-uploader/file-uploader.scss";
// import Uppy from "@uppy/core";
// import thumbnailGenerator from "@uppy/thumbnail-generator";
// import { DragDrop } from "@uppy/react";
// import { X, Link } from "react-feather";
// //import { useSelector } from "react-redux";
// import { useUploadImage } from "@api";
// import confirmDelete from "@components/common/confirmDelete";
// import "../../WebApp/UserRequest/StaffImagesSection/index.scss";
// import { Col, Row } from "reactstrap";
// import toaster from "@components/common/Toaster";

// const AttachmentComponent = ({
//   imageList,
//   setImageList,
//   editData,
//   uploadType,
//   landingPage,
//   // userRole,
// }) => {
//   const [imageLink, setImageLink] = useState([]);

//   const [uploadingType, setUploadingType] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [uploadImageHandler, { data: imageData, reset }] = useUploadImage();

//   const uppy = new Uppy({
//     meta: { type: "avatar" },
//     autoProceed: true,
//     restrictions: {
//       maxNumberOfFiles: 1,
//       allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".pdf"],
//       maxFileSize: 2097152,
//     },
//   });

//   uppy.use(thumbnailGenerator);

//   uppy.on("complete", (result) => {
//     setUploadingType(result?.successful[0]?.source, editData);

//     result.successful.forEach((file) => {
//       const fileName = file.name;

//       const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

//       if (specialCharsRegex.test(fileName)) {
//         console.error("File name:", fileName);
//         toaster(
//           "error",
//           "File name contains special characters. Please provide a valid file name."
//         );
//         uppy.removeFile(file.id);
//         return;
//       }

//       result.successful[0]?.data &&
//         uploadImageHandler(result?.successful[0]?.data, `GO_PURCHASE_REQUEST`);

//       toaster("success", "Invoice image uploaded successfully");
//     });
//   });

//   uppy.on("restriction-failed", (file, error) => {
//     toaster("error", `Error uploading media image: ${error.message}`);
//   });

//   useEffect(() => {
//     const newImage = {};

//     uploadType
//       ? (newImage.imageURL = imageData?.uploadImage?.url)
//       : (newImage.url = imageData?.uploadImage?.url);

//     if (imageData?.uploadImage?.url) {
//       setImageList((prev) => [newImage, ...prev]);

//       reset();
//       setUploadingType(null);
//     }
//   }, [imageData]);

//   const removeImages = (image) => {
//     const newSet = imageList?.filter((item) => {
//       const imgUrl = item?.imageURL ? item?.imageURL : item?.url;
//       return imgUrl !== image;
//     });

//     setImageList(newSet);
//   };

//   const handleRemoveImage = (image) => {
//     confirmDelete(() => removeImages(image));
//   };

//   const openModal = (index, link) => {
//     setShowModal(true);
//     setImageLink({ index, link });
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setImageLink({});
//   };

//   const addImageLink = (index, link) => {
//     let newImageList = [...imageList];
//     const imgWithLink = { ...newImageList[index], link };

//     newImageList[index] = imgWithLink;
//     setImageList(newImageList);
//   };
//   return (
//     <Row>
//       <Col>
//         <div className="imageSection__container">
//           {uploadType?.map(({ value, label }) => (
//             <div key={value}>
//               {/* <h5 className="imageSection__header">
//               <strong>Upload {label}</strong>
//             </h5> */}
//               <div className="imageSection__imagesContainer">
//                 {imageList?.map((item, index) => {
//                   const imageURL = item?.imageURL || item?.url;
//                   return (
//                     <>
//                       <div
//                         key={index}
//                         className="imagesection__imageBox"
//                         style={{
//                           width: "8em",
//                           height: "8em",
//                         }}
//                       >
//                         <img
//                           className="imageSection__image"
//                           src={imageURL}
//                           alt="avatar"
//                           style={{
//                             width: "100%",
//                             height: "100%",
//                             cursor: "pointer",
//                           }}
//                           onClick={() => window.open(imageURL, "_blank")}
//                         />
//                         <div className="imageSection_footer">
//                           <div className="imageSection_icon imageSection__index">
//                             {index + 1}
//                           </div>
//                           {landingPage && (
//                             <div
//                               onClick={() => openModal(index, item?.link)}
//                               className="imageSection_icon imageSection__link"
//                             >
//                               <Link size={25} />
//                             </div>
//                           )}
//                           {/* {(userRole === "Purchase" ||
//                             userRole === "Area Manager") && ( */}
//                           <div
//                             onClick={() => handleRemoveImage(imageURL)}
//                             className=" imageSection_icon imageSection__removeImage"
//                           >
//                             <X size={20} />
//                           </div>
//                           {/* )} */}
//                         </div>
//                       </div>
//                     </>
//                   );
//                 })}
//                 {/* {(userRole === "Purchase" || userRole === "Area Manager") && ( */}
//                 <div className="relative">
//                   <div className="my-1">
//                     <DragDrop
//                       id={value}
//                       width="8em"
//                       height="8em"
//                       locale={{
//                         strings: {
//                           dropHereOr: "Max 2 MB",
//                         },
//                       }}
//                       uppy={uppy}
//                     />
//                   </div>
//                 </div>
//                 {/* )} */}
//               </div>
//               <hr />
//             </div>
//           ))}
//         </div>
//         {/* <div className="imageSection__container" style={{ display: "flex" }}>
//         <div className="imageSection__imagesContainer">

//         </div>
//       </div> */}
//       </Col>
//     </Row>
//   );
// };

// export default AttachmentComponent;
