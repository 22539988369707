import { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import toaster from "@components/common/Toaster";

const UrlForm = ({ showModal, closeModal, imageLink, addImageLink }) => {
  const [userName, setUserName] = useState("");

  //check valid url

  useEffect(() => {
    setUserName(imageLink.userName);
  }, [imageLink.userName]);

  const handleClose = () => {
    closeModal();
    // setLink("");
    setUserName("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!link.includes("http") || !link.includes(".") || !link.toString())
    //   return toaster("error", "Please add a valid link");

    addImageLink(imageLink.index, userName);
    handleClose();
    // toaster("success", "⚠️ Link added, please save now");
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={handleClose}
      ></ModalHeader>
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <div className="text-center mb-2">
          <h3>Add User Name</h3>
        </div>
        <Row tag="form" className="gy-1 pt-75">
          <Col xs={12}>
            <Label className="form-label" for="link">
              User Name
            </Label>

            <Input
              id="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="user name"
            />
          </Col>

          <Col xs={12} className="text-center mt-2">
            <Button onClick={handleSubmit} className="me-1" color="light mr-3">
              Proceed
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              onClick={handleClose}
            >
              Discard
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UrlForm;
