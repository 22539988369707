// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import confirmDelete from "@components/common/confirmDelete";
import {
  useHostelList,
  useUpdateDestination,
} from "../../../../api/dataSource";

const NearByHostelsForm = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateDestinationHandler, { data: updatedDestinationsData }] =
    useUpdateDestination();
  const [selectedNearByHostel, setSelectedNearByHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    if (state?.tableData?.nearByHostel.length) {
      let alreadySelectedHostels = state?.tableData?.nearByHostel?.map(
        (item) => {
          return {
            uuId: uuidv4(),
            nearByHostel: { label: item?.name, value: item?._id },
          };
        }
      );
      setSelectedNearByHostel(alreadySelectedHostels);
    }
  }, [state]);

  const handleAddDestinations = () =>
    setSelectedNearByHostel([
      ...selectedNearByHostel,
      {
        uuId: uuidv4(),
        nearByHostel: [],
      },
    ]);

  const handleRemoveSelectedDestination = (uuId) =>
    confirmDelete(() =>
      setSelectedNearByHostel(
        selectedNearByHostel.filter((item) => item?.uuId !== uuId)
      )
    );

  const handleChangeSelectedHostel = (nearByHostel, uuId) => {
    setSelectedNearByHostel(
      selectedNearByHostel?.map((item) => {
        return item?.uuId === uuId
          ? {
              ...item,
              nearByHostel: {
                label: nearByHostel?.label,
                value: nearByHostel?.value,
              },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      // eslint-disable-next-line array-callback-return
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const [finalDestinationArr, setFinalDestinationArr] = useState([]);

  useEffect(() => {
    if (updatedDestinationsData) {
      let updatedHostels = [];
      finalDestinationArr?.forEach((i) => {
        updatedHostels.push(
          allHostelsData?.getHostelList?.find((j) => j?._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          nearByHostel: updatedHostels,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allHostelsData?.getHostelList,
    dispatch,
    finalDestinationArr,
    updatedDestinationsData,
  ]);

  const submitDatahandler = () => {
    var finalListOfHostelsId = [];
    selectedNearByHostel?.forEach((item) =>
      finalListOfHostelsId.push(item?.nearByHostel?.value)
    );

    // const newArr = finalListOfHostelsId.filter((i) => Boolean(i));
    // setFinalDestinationArr(newArr);
    setFinalDestinationArr(finalListOfHostelsId);

    let data = {
      id,
      nearByHostel: finalListOfHostelsId,
    };

    if (data?.nearByHostel?.length > 0) {
      if (id && data?.nearByHostel?.every((hostel) => hostel !== undefined)) {
        updateDestinationHandler(id, { nearByHostel: data.nearByHostel });
        toaster("success", "Nearby hostels updated successfully");
      } else {
        toaster("error", "Please select the nearby hostels");
      }
    } else {
      toaster("error", "Please select at least one hostel");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDatahandler();
      }}
    >
      <Row className="mt-2">
        {selectedNearByHostel?.map(({ uuId, nearByHostel }, index) => {
          return (
            <Col className="d-flex align-items-center" sm="12" key={uuId}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"reviews" + uuId}>
                  Selected Hostel {index + 1}
                </Label>

                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Select
                        // isClearable={true}
                        isSearchable={true}
                        id={uuId}
                        name="hostel"
                        value={nearByHostel}
                        options={HostelsList}
                        onChange={(value) => {
                          handleChangeSelectedHostel(value, uuId);
                        }}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>

              {selectedNearByHostel.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveSelectedDestination(uuId)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          );
        })}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddDestinations} />
    </form>
  );
};

export default NearByHostelsForm;
