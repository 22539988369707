import React, {useEffect, useState } from "react";
import StepOutWallOfLoveTable from "./StepOutWallOfLoveTable";
import { useGetAllStepoutWallOfLove } from "../../../api/dataSource";
import StepOutWallOfLoveAddEditModal from "./StepOutWallOfLoveAddEditModal";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const StepOutWallOfLove = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);

  const { data, refetch } = useGetAllStepoutWallOfLove();
  // console.log(data?.getAllImages);

  useEffect(() => {
    if (data?.getAllImages) {
      setTableData(data?.getAllImages);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(()=>{
    if(data?.getAllImages){
      let editData = data?.getAllImages.find((item)=> item._id === id)
      setEditData(editData);
    }else{
      setId(null);
      setEditData(null);
    }
  }, [id])

  const toggleModal = () => {
    setOpenModal(!openModal);
    // console.log("here")
    setId(null);
    setEditData(null);
  };

  return (
    <div>
      <StepOutWallOfLoveTable
        setId={setId}
        openModal={()=>setOpenModal(!openModal)}
        data={tableData}
        refetch={refetch}
      />

      <StepOutWallOfLoveAddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default StepOutWallOfLove;
