import gql from "graphql-tag";

// ! updateMedia added by Ashwin
export const UPDATE_MEDIA_DETAILS = gql`
  mutation updateMedia(
    $slug: String
    $mediaUpdate: MediaInput
  ) {
    updateMedia(slug: $slug, mediaUpdate: $mediaUpdate) {
      _id
    }
  }
`;