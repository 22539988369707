// Added by Nitish

import gql from "graphql-tag";

export const GET_SERVICE_BY_ID = gql`
  query {
    getServiceById(serviceId: $serviceId) {
      _id
      category
      hostel
      title
      description
      totalInventoryPerDay
      price
      tax
      cutOffTime
      fromTime
      toTime
      status
      icon
      serviceNudge
      displayOrder
    }
  }
`;

export const GET_PAGINATED_SERVICE_LIST = gql`
  query getPaginatedServiceList($filter: filter) {
    getPaginatedServiceList(filter: $filter) {
      data {
        _id
        category
        hostel {
          _id
          name
        }
        title
        description
        totalInventoryPerDay
        strikeThroughPrice
        price
        tax
        cutOffTime
        fromTime
        toTime
        status
        icon
        hsnCode
        serviceNudge
        displayOrder
        bookingRule
        variationCaption
        variationOptions {
          option
          description
          price
          displayOrder
        }
        extraCaption
        extraOptions {
          option
          price
          bookingRule
        }
        logs
        vendorName
        vendorEmail
        vendorMobileNumber
        vendorOrg
      }
      count
      limit
      page
    }
  }
`;
