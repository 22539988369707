// ! Added by Ashwin
import gql from "graphql-tag";

export const CREATE_WEBCHECKIN = gql`
  mutation createWebCheckin($webCheckinInput: WebCheckinInput) {
    createWebCheckin(webCheckinInput: $webCheckinInput) {
      hostelId
      hostelName
      firstName
      lastName
      gender
      dateOfBirth
      countryCode
      phoneNumber
      email
      howDidYouBookTheHosteller
      groupSize
      residingCountry
      residingState
      residingCity
      comingFromCity
      goingToCity
      modeOfTransport
      govtIdProof
      govtIdNumber
      uploadGovId
      uploadAadhaarCardImage
      uploadAadhaarCardBackImage
      uploadVisa
      uploadPassportBackImage
      haveYouStayedWithTheHostellerBefore
      whatWouldBestDescribeYou
    }
  }
`;

export const UPDATE_WEBCHECKIN = gql`
  mutation updateWebCheckin($id: ID, $webCheckinUpdate: WebCheckinInput) {
    updateWebCheckin(id: $id, webCheckinUpdate: $webCheckinUpdate) {
      hostelName
      firstName
      lastName
      govtIdNumber
      cFormAppNo
    }
  }
`;

export const GENERATE_WEBCHECKIN_EXCEL_DATA = gql`
mutation generatexlsx($inputFilter: inputFilter){
  generateXlsxWebCheckinList(inputFilter:$inputFilter)
}
`

export const GET_WEBCHECKIN_ANALYSIS_REPORT = gql`
mutation webCheckinDataAnalysisReport($reportInput: reportInput){
  webCheckinDataAnalysisReport(reportInput: $reportInput){
    totalDocuments
    residingCountries
    residingState
    genders
    stayedWithHosteller
    whatWouldBestDescribeYou
    groupSize
  }
}
`
