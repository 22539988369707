// ! Coded by Ashwin

import Staffs from "../../views/Staff";

const StaffsRoutes = [
  {
    path: "/staffs",
    exact: true,
    component: Staffs,
  },
];

export default StaffsRoutes;
