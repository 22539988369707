import gql from "graphql-tag";

export const CREATE_STRIP_CONFIG = gql`
  mutation createStripConfig($InputStripConfig: InputStripConfig) {
    createStripConfig(InputStripConfig: $InputStripConfig) {
      title
    }
  }
`;

export const UPDATED_STRIP_CONFIG = gql`
  mutation updateStripConfig($id: ID, $InputStripConfig: InputStripConfig) {
    updateStripConfig(id: $id, InputStripConfig: $InputStripConfig) {
      title
    }
  }
`;

export const DELETE_STRIP_CONFIG = gql`
  mutation deleteStripConfig($id: ID) {
    deleteStripConfig(id: $id) {
      message
      deletedStaus
    }
  }
`;
