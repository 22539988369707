import gql from "graphql-tag";

export const CREATE_WALLET = gql`
  mutation createWallet($walletInput: WalletInput) {
    createWallet(walletInput: $walletInput) {
      email
    }
  }
`;

export const UPDATE_WALLET = gql`
  mutation updateWallet($id: ID!, $walletUpdate: WalletInput) {
    updateWallet(id: $id, walletUpdate: $walletUpdate) {
      email
    }
  }
`;
