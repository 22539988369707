import React from "react";
import { Button } from "reactstrap";

const CmsTabs = ({ handleSelectActiveTab, active, tabOptions }) => {
  return (
    <div className='d-flex justify-content-between flex-wrap'>
      {tabOptions.map(({ title, id }) => (
        <div key={id} className='d-flex flex-fill'>
          <Button
            block
            className='mr-1 mb-1'
            color={active === id ? "light" : "secondary"}
            size='sm'
            onClick={() => handleSelectActiveTab(id)}
            outline={active !== id}
          >
            {title}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default CmsTabs;
