import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import MultiCheckbox from "@components/common/multi-checkbox";
import {
  useGetRecommendationByProductType,
  useUpdateWorkationPackage,
} from "@api";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { useParams } from "react-router-dom";

const Recommendations = () => {
  const state = useSelector((state) => state?.cms);
  const [recommendationList, setRecommendationList] = useState([]);
  const [workationRecommendations, setWorkationRecommendations] = useState([]);
  const { data } = useGetRecommendationByProductType("Workation");
  const [updateWorkationPackageHandler, { data: updatedData }] =
    useUpdateWorkationPackage();

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (data?.getRecommendationByProductType)
      setRecommendationList(data.getRecommendationByProductType);
  }, [data?.getRecommendationByProductType]);

  useEffect(() => {
    if (state?.tableData?.workationRecommendations?.length > 0) {
      const list = state.tableData?.workationRecommendations?.map((v) => v._id);

      list && setWorkationRecommendations(list);
    }
  }, [state?.tableData?.workationRecommendations]);

  useEffect(() => {
    if (updatedData?.updateWorkationPackage?._id) {
      toaster("success", "Submitted successfully");
    }
  }, [updatedData?.updateWorkationPackage]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      workationRecommendations.length >= 3 ||
      workationRecommendations.length <= 0
    ) {
      const data = recommendationList.filter((v) =>
        workationRecommendations.includes(v._id)
      );

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          workationRecommendations: data.reverse(),
        })
      );

      updateWorkationPackageHandler(id, {
        workationRecommendations: workationRecommendations,
      });
    } else {
      toaster("error", "Minimum 3 items is required");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h4 className='py-1'>
          Select Workation Recommendation (Min 3 - Max 6)
        </h4>

        <MultiCheckbox
          list={recommendationList}
          data={workationRecommendations}
          setData={setWorkationRecommendations}
          max={6}
        />

        <SaveDiscardBtnGroup />
      </form>
    </>
  );
};

export default Recommendations;
