// ! Coded by Ashwin
import gql from "graphql-tag";

export const CREATE_PARTNERNEW = gql`
  mutation createPartnerNew($partnerNewInput: PartnerNewInput) {
    createPartnerNew(partnerNewInput: $partnerNewInput) {
        hostelLocation
        hostelName
        image
        label1 {
            label
            value
        }
        label2 {
            label
            value
        }
        label3 {
            label
            value
        }
        label4 {
            label
            value
        }
        status
    }
  }
`;

export const UPDATE_PARTNERNEW = gql`
  mutation updatePartnerNew($id: ID, $partnerNewUpdate: PartnerNewInput) {
    updatePartnerNew(id: $id, partnerNewUpdate: $partnerNewUpdate) {
        hostelLocation
    }
  }
`;