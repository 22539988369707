import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const tableHeaderData = [
  "Guest Name",
  "Guest Email",
  "Guest Mobile Number",
  "Amount",
];

const WalletReportTable = ({
  openModal,
  setId,
  data,
  refetch,
  totalAmount,
}) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CardHeader>
              <CardTitle>
                <div>
                  <h2>
                    Wallet Balance Report - Total amount Rs. {totalAmount}
                  </h2>
                </div>
              </CardTitle>
            </CardHeader>
          </div>
          <Table responsive size="sm" hover bordered>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    {/* <Badge color="light-info"> */}
                    {item?.fullName}
                    {/* </Badge> */}
                  </td>
                  <td>
                    <em>{item?.email}</em>
                  </td>
                  <td>
                    <em>{item?.mobile}</em>
                  </td>
                  <td>{item?.amount?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default WalletReportTable;
