import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_ADMIN_LOGIN_LOGS_BY_EMAIL = gql`
  query getAdminLoginLogsByEmail($email: String) {
    getAdminLoginLogsByEmail(email: $email) {
      _id
      email
      success
      createdAt
    }
  }
`;

export const useGetAdminLoginLogsByEmail = (email) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ADMIN_LOGIN_LOGS_BY_EMAIL,
    {
      variables: {
        email,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
