import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { useGetReviewSentimentAnalysis } from "../../api/dataSource";

const ORMHostelwiseReport = () => {
  const [hostelID, setHostelID] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const { loading, error, data, refetch } = useGetReviewSentimentAnalysis(
    hostelID,
    dateRange[0],
    dateRange[1]
  );

  const handleSearch = () => {
    setShouldFetchData(true);
    refetch();
  };

  const handleReset = () => {
    setHostelID([]);
    setDateRange([null, null]);
    setShouldFetchData(false);
  };

  return (
    <Card>
      <CardHeader style={{ backgroundColor: "#ffe700" }}>
        <h4>ORM Hostel-wise Report</h4>
      </CardHeader>
      <CardBody>
        <Row>
          {/* Left Column for Hostel Search */}
          <Col md="3">
            <FormGroup>
              <Label for="hostelSelect">Select Hostel</Label>
              {/* Replace with actual hostel search component */}
              <input
                type="text"
                id="hostelSelect"
                className="form-control"
                value={hostelID}
                onChange={(e) => setHostelID(e.target.value)}
                placeholder="Search Hostels"
              />
            </FormGroup>
          </Col>

          {/* Right Column for Date Range Picker */}
          <Col md="6">
            <FormGroup>
              <Label>Date Range</Label>
              <Flatpickr
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                }}
                className="form-control"
                value={dateRange}
                onChange={(dates) => setDateRange(dates)}
              />
            </FormGroup>
          </Col>

          {/* Search and Reset Buttons */}
          <Col md="3">
            <FormGroup>
              <Button
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  marginLeft: "20px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </FormGroup>
          </Col>
        </Row>

        {/* Report Table */}
        {shouldFetchData &&
          !loading &&
          data &&
          data.getReviewSentimentAnalysis && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>ORM</th>
                  <th>Positive Rate</th>
                  <th>Neutral Rate</th>
                  <th>Negative Rate</th>
                  <th>NPS</th>
                  <th>Review Rate</th>
                  <th>Total Reviews</th>
                  <th>Positive Reviews</th>
                  <th>Neutral Reviews</th>
                  <th>Negative Reviews</th>
                  <th>Responsiveness</th>
                  <th>Tickets Created</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.getReviewSentimentAnalysis.orm}</td>
                  <td>{data.getReviewSentimentAnalysis.positiveRate}</td>
                  <td>{data.getReviewSentimentAnalysis.neutralRate}</td>
                  <td>{data.getReviewSentimentAnalysis.negativeRate}</td>
                  <td>{data.getReviewSentimentAnalysis.nps}</td>
                  <td>{data.getReviewSentimentAnalysis.reviewRate}</td>
                  <td>{data.getReviewSentimentAnalysis.totalReviews}</td>
                  <td>{data.getReviewSentimentAnalysis.positiveReviews}</td>
                  <td>{data.getReviewSentimentAnalysis.neutralReviews}</td>
                  <td>{data.getReviewSentimentAnalysis.negativeReviews}</td>
                  <td>{data.getReviewSentimentAnalysis.responsiveness}</td>
                  <td>{data.getReviewSentimentAnalysis.ticketsCreated}</td>
                </tr>
              </tbody>
            </Table>
          )}

        {loading && <p>Loading data...</p>}
        {error && <p>Error fetching data</p>}
      </CardBody>
    </Card>
  );
};

export default ORMHostelwiseReport;
