import { useEffect, useState } from "react";
import { Eye } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
  Label,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "@utils";

const tableHeaderData = [
  "Reservation No",
  "Booking Date/Time",
  "Status",
  "Booking Source",
  "Booking Amount",
  "Hostel Name",
  "CheckinDate - CheckoutDate",

  "Name",
  "Email",
  "Mobile",
  // "Country Code",
  // "Address",
  // "City",
  // "State",
  // "Country",
  // "Pin Code",
];

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  // second: "numeric",
  hour12: true,
  timeZone: "Asia/Kolkata",
};

const ChatbotBookingTable = ({
  openModal,
  setId,
  data,
  refetch,
  totalCount,
}) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Chatbot Bookings</CardTitle>
            <Col>
              <Label className="form-label" for="product">
                Total Bookings: <b>{totalCount}</b>
              </Label>
            </Col>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <Badge color="light-info">
                      {item?.providerRefId ? item?.providerRefId : "--"}
                    </Badge>
                  </td>
                  <td>
                    {new Intl.DateTimeFormat("en-IN", options).format(
                      new Date(item?.createdAt)
                    )}
                  </td>
                  {/* <td>{item?.status}</td> */}
                  <td>
                    <Badge
                      color={
                        item?.status === "confirm"
                          ? "light-success"
                          : item?.status === "pending"
                          ? "light-info"
                          : item?.status === "failure"
                          ? "light-danger"
                          : "light-warning"
                      }
                    >
                      {item?.status}
                    </Badge>
                  </td>
                  <td>{item?.bookingSource}</td>
                  <td>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      maximumFractionDigits: 2,
                    }).format(item?.payableAmount)}
                  </td>
                  <td>{item?.hostelId?.name}</td>
                  <td>
                    {item?.checkinDate
                      ? dateFormatHandler(item?.checkinDate)
                      : ""}{" "}
                    -{" "}
                    {item?.checkinDate
                      ? dateFormatHandler(item?.checkoutDate)
                      : ""}
                  </td>
                  <td>{item?.contactDetails?.name}</td>
                  <td>{item?.contactDetails?.email}</td>
                  <td>{item?.contactDetails?.mobile}</td>
                  {/* <td>{item?.contactDetails?.countryCode}</td>
                  <td>{item?.contactDetails?.address?.addressLine}</td>
                  <td>{item?.contactDetails?.address?.city}</td>
                  <td>{item?.contactDetails?.address?.state}</td>
                  <td>{item?.contactDetails?.address?.country}</td>
                  <td>{item?.contactDetails?.address?.pincode}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default ChatbotBookingTable;
