import { useEffect, useState } from "react";
import { Edit } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

const SeoTable = ({ openModal, setId, data, selectModules }) => {
  const [tableData, setTableData] = useState(data);

  const tableHeaderData = [
    "Action",
    `${selectModules?.label}`,
    "Title",
    "Description",
    "Keywords",
  ];

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>{selectModules?.label}</h2>
              </div>
            </CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "5%" }}>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>
                    {(selectModules?.label === "Hostels" ? item?.name : null) ||
                      (selectModules?.label === "Destinations"
                        ? item?.destination
                        : null) ||
                      (selectModules?.label === "Blogs"
                        ? item?.blogTitle
                        : null) ||
                      (selectModules?.label === "Blogs Category"
                        ? item?.categoryName
                        : null) ||
                      (selectModules?.label === "Landing Page"
                        ? item?.slug
                        : null)}
                  </td>
                  <td>{item?.seo?.metaTitle}</td>
                  <td>{item?.seo?.metaDesc}</td>
                  <td>{item?.seo?.keywords}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default SeoTable;
