// !Added by Ashwin

import React from "react";
import { Edit, Trash } from "react-feather";
import { Badge, Button } from "reactstrap";
// import { dateFormatHandler } from "@utils";
import SwitchIcon from "@components/common/switch-icon";
import { useUpdatePressMention } from "../../../../../../api/dataSource";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";

const WhatsNewTableBody = ({
  tableRowData,
  openModal,
  handleEdit,
  handleDelete,
  refetch,
}) => {
  const [updatePressMentionHandler, { data: updatedPressMentionData }] =
    useUpdatePressMention();

  const createObject = (value, id, e) => {
    let data = {
      status: value,
    };
    if (id) {
      updatePressMentionHandler(id, data);
      refetch();
    }
  };

  return (
    <>
      {tableRowData.map(({ title, publishedDate, createdAT, status, id }) => (
        <tr key={id}>
          <td style={{ width: "5%" }}>
            <Button
              className="rounded-circle btn-icon"
              color="none"
              onClick={(e) => {
                e.preventDefault();
                openModal();
                handleEdit(id);
              }}
            >
              <Edit
                color={reactFeatherIcon.iconColor}
                size={reactFeatherIcon.iconSize}
              />
            </Button>

            {/* {handleDelete && (
              <Button
                className='rounded-circle btn-icon'
                color='none'
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(id);
                }}
              >
                <Trash size={15} />
              </Button>
            )} */}
          </td>
          <td>
            <span className="align-middle font-weight-bold">{title}</span>
          </td>
          {/* <td>{dateFormatHandler(createdAT)}</td> */}
          <td>
            <span className="align-middle font-weight-bold">
              {publishedDate}
            </span>
          </td>
          {/* <td>
            <Badge
              pill
              color={status ? "light-success" : "light-danger"}
              className='mr-1'
            >
              {status ? "Active" : "Inactive"}
            </Badge>
          </td> */}
          <td>
            <SwitchIcon
              checked={status}
              id={id}
              name="status"
              handleChecked={(value) => createObject(value)}
              onClick={(e) => createObject(!status, id, e)}
            />
          </td>
        </tr>
      ))}
    </>
  );
};

export default WhatsNewTableBody;
