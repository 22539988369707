import React from "react";
import { Edit2, Trash } from "react-feather";
import { Badge, Button } from "reactstrap";
import { dateFormatHandler } from "@utils";

const TableBody = ({ tableRowData, openModal, handleEdit, handleDelete }) => {
  return (
    <>
      {tableRowData.map(
        ({ name, duration, group, price, status, created, id }) => (
          <tr key={id}>
            <td>
              <span className='align-middle font-weight-bold'>{name}</span>
            </td>
            <td>{duration}</td>
            <td>{group}</td>
            <td>{price}</td>
            <td>
              <Badge
                pill
                color={status ? "light-success" : "light-danger"}
                className='mr-1'
              >
                {status ? "Active" : "Inactive"}
              </Badge>
            </td>
            <td>{dateFormatHandler(created)}</td>
            <td>
              <Button
                className='rounded-circle btn-icon'
                color='none'
                onClick={(e) => {
                  e.preventDefault();
                  openModal(true);
                  handleEdit(id);
                }}
              >
                <Edit2 size={15} />
              </Button>
              {handleDelete && (
                <Button
                  className='rounded-circle btn-icon'
                  color='none'
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(id);
                  }}
                >
                  <Trash size={15} />
                </Button>
              )}
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default TableBody;
