import React, { useEffect, useState } from "react";
import {
  useCreateAndUpdateCafewiseMenu,
  useUpdateCafewiseSingle,
} from "../../../api/Unbox/UnboxCafewise/Mutation";
import { Button, Card, Col, FormGroup, Label, Row, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import SwitchIcon from "@components/common/switch-icon";
import toaster from "../../../@core/components/common/Toaster";
import { File } from "react-feather";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";

const tableHeaderData = [
  "Item",
  "Category",
  "Sold Out Hrs",
  "Sold Out",
  "Logs",
];

const ItemwiseTableForSoldOut = ({
  itemListData,
  cafeId,
  hostelId,
  refetch,
  handleToggle,
  setSoldOutLog,
  // openModal,
}) => {
  const userName = localStorage?.getItem("name");
  const [tableData, setTableData] = useState(itemListData);
  const [soldOutLogArray, setSoldOutLogArray] = useState(null);
  const [createCafewiseMenuHandler, { data: createCafeWiseData }] =
    useCreateAndUpdateCafewiseMenu();

  const [updateHandler, { data, error }] = useUpdateCafewiseSingle();
  useEffect(() => {
    if (itemListData?.getCafewiseActiveMenuForSoldout?.length > 0) {
      setTableData(itemListData?.getCafewiseActiveMenuForSoldout);
    } else {
      setTableData([]);
    }
    refetch();
  }, [itemListData?.getCafewiseActiveMenuForSoldout, cafeId]);

  const [cafewise, setCafeWise] = useState([]);

  useEffect(() => {
    //setCafeWise([]);
    let newArr = [];
    if (itemListData?.getCafewiseActiveMenuForSoldout?.length > 0) {
      itemListData?.getCafewiseActiveMenuForSoldout?.forEach((i) => {
        let updateObj = {
          item: i?._id,
          hostel: hostelId,
          cafe: cafeId,
          soldOut: i?.soldOut,
          soldOutHrs: i?.soldOutHrs,
          soldOutStartTime: i?.soldOutStartTime,
          soldOutEndTime: i?.soldOutEndTime,
          soldOutLogs: i?.soldOutLogs?.map((j) => j) || [],
        };
        newArr.push(updateObj);
      });
      setCafeWise(newArr);
    }
  }, [cafeId, hostelId, itemListData?.getCafewiseActiveMenuForSoldout]);
  //console.log(cafewise);

  const handleAddRequest = (value, name, id) => {
    try {
      const updatedArray = cafewise?.map((element) => {
        if (element?.item === id) {
          if (name === `soldOutHrs+${id}`) {
            const hrsToAdd = Number(value);
            element.soldOutHrs = hrsToAdd;
          } else if (name === `soldOut`) {
            if (element?.soldOutHrs) {
              element.soldOut = value;

              const currentTime = new Date();
              const currentDateInDMY = dateFormatterDDMMYYYY(new Date());
              const endTime = new Date(
                currentTime.getTime() + element?.soldOutHrs * 3600000
              );
              const currentDate = currentTime.toLocaleTimeString();
              const endDate = endTime.toLocaleTimeString();
              const endDateInDMY = dateFormatterDDMMYYYY(endTime);

              const soldOutLogString =
                value === true
                  ? `${userName} has put item on Sold Out from ${currentDateInDMY},${currentDate} to ${endDateInDMY},${endDate}`
                  : `${userName} has removed item from Sold Out at ${currentDateInDMY},${currentDate}`;

              let CafewiseMenuInput = {
                item: id,
                cafe: cafeId,
                hostel: hostelId,
                soldOut: value,
                soldOutHrs: element?.soldOutHrs,
                soldOutLogs: [...element?.soldOutLogs, soldOutLogString],
              };
              if (CafewiseMenuInput?.item) {
                updateHandler(CafewiseMenuInput);
              }
            } else {
              toaster("error", "Please select hrs");
            }
          }
        }
        return element;
      });

      setCafeWise(updatedArray);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     toaster("success", `Cafewise Menu saved`);
  //     setCafeWise([]);
  //   }
  // }, [data]);

  // const handleCreateCafeWise = (cafewiseParams) => {
  //   const testObj = {
  //     cafewiseMenuInput: cafewiseParams,
  //   };
  //   //console.log(testObj);
  //   //createCafewiseMenuHandler(testObj);
  // };

  return (
    <>
      {tableData && cafeId && (
        <>
          <Row>
            <Col>
              <Card>
                <Table responsive>
                  <thead>
                    <tr>
                      {tableHeaderData?.map((item) => {
                        return <th key={uuidv4()}>{item}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{item?.itemName}</td>
                          <td>{item?.itemCategory}</td>
                          <td style={{ display: "flex", gap: "14px" }}>
                            <FormGroup
                              className="mt-2"
                              style={{ display: "flex", gap: "3px" }}
                            >
                              <div>
                                <input
                                  type="radio"
                                  id={2}
                                  name={`soldOutHrs+${item?._id}`}
                                  value={2}
                                  checked={
                                    cafewise?.length > 0
                                      ? cafewise?.find(
                                          (r) => r?.item === item?._id
                                        ).soldOutHrs === 2
                                      : item?.soldOutHrs
                                    //item?.soldOutHrs === 2
                                  }
                                  onChange={(e) => {
                                    handleAddRequest(
                                      e.target.value,
                                      `soldOutHrs+${item?._id}`,
                                      item?._id
                                    );
                                  }}
                                />
                              </div>
                              <Label>2Hrs</Label>
                            </FormGroup>
                            <FormGroup
                              className="mt-2"
                              style={{ display: "flex", gap: "3px" }}
                            >
                              <div>
                                <input
                                  type="radio"
                                  id={4}
                                  name={`soldOutHrs+${item?._id}`}
                                  value={4}
                                  checked={
                                    cafewise?.length > 0
                                      ? cafewise?.find(
                                          (r) => r?.item === item?._id
                                        ).soldOutHrs === 4
                                      : item?.soldOutHrs
                                  }
                                  onChange={(e) => {
                                    handleAddRequest(
                                      e.target.value,
                                      `soldOutHrs+${item?._id}`,
                                      item?._id
                                    );
                                  }}
                                />
                              </div>
                              <Label>4Hrs</Label>
                            </FormGroup>
                            <FormGroup
                              className="mt-2"
                              style={{ display: "flex", gap: "3px" }}
                            >
                              <div>
                                <input
                                  type="radio"
                                  id={8}
                                  name={`soldOutHrs+${item?._id}`}
                                  value={8}
                                  checked={
                                    cafewise?.length > 0
                                      ? cafewise?.find(
                                          (r) => r?.item === item?._id
                                        ).soldOutHrs === 8
                                      : item?.soldOutHrs
                                  }
                                  onChange={(e) => {
                                    handleAddRequest(
                                      e.target.value,
                                      `soldOutHrs+${item?._id}`,
                                      item?._id
                                    );
                                  }}
                                />
                              </div>
                              <Label>8Hrs</Label>
                            </FormGroup>
                            <FormGroup
                              className="mt-2"
                              style={{ display: "flex", gap: "3px" }}
                            >
                              <div>
                                <input
                                  type="radio"
                                  id={24}
                                  name={`soldOutHrs+${item?._id}`}
                                  value={24}
                                  checked={
                                    cafewise?.length > 0
                                      ? cafewise?.find(
                                          (r) => r?.item === item?._id
                                        ).soldOutHrs === 24
                                      : item?.soldOutHrs
                                  }
                                  onChange={(e) => {
                                    handleAddRequest(
                                      e.target.value,
                                      `soldOutHrs+${item?._id}`,
                                      item?._id
                                    );
                                  }}
                                />
                              </div>
                              <Label>24Hrs</Label>
                            </FormGroup>
                          </td>
                          <td>
                            <SwitchIcon
                              checked={
                                cafewise?.length > 0
                                  ? cafewise?.find((r) => r?.item === item?._id)
                                      .soldOut
                                  : item?.soldOut
                              }
                              //disabled={true}
                              id={`custom-checkbox-${index}}}`}
                              name={"select" + index}
                              handleChecked={(value) => {
                                handleAddRequest(value, "soldOut", item?._id);
                              }}
                            />
                          </td>
                          <td>
                            <Button
                              onClick={(e) => {
                                handleToggle();
                                setSoldOutLog(item?.soldOutLogs);
                              }}
                              color="none"
                            >
                              <File size={15} />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ItemwiseTableForSoldOut;
