// ! Coded by Ashwin

import Coupon from "../../views/Coupon";

const CouponsRoutes = [
  {
    path: "/coupons",
    exact: true,
    component: Coupon,
  },
];

export default CouponsRoutes;
