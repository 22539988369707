import gql from "graphql-tag";

export const GET_ALL_STEPOUT_BLOGS = gql`
query getStepoutBlogList{
    getStepoutBlogList {
      _id
      blogTitle
    }
}
`

export const GET_STEPOUT_BLOG_BY_ID =   gql`
query getStepoutBlogById($Id: ID){
  getStepoutBlogById(Id:$Id){
      blogTitle
      blogCategory{
        _id
        categoryName
      }
      authorName
      shortDescription
      tags
      timetoRead
      featuredImage
      blogContent
      status
      displayOrder
      whatsNext{
        title
        urlText
        link
      }
      seo{
        metaTitle
        metaDesc
        keywords
      }
      publishDate
      url
      similarBlogs{
        _id
        blogTitle
      }
      blogCategory{
        _id
      }
  }
}
`


export const GET_PAGINATED_STEPOUT_BLOG = gql`
query getPaginatedStepoutBlogList($filter:filter){
  getPaginatedStepoutBlogList(filter:$filter){
          data{
              _id
              blogTitle
              blogCategory {
                _id
                categoryName
              }
              featuredImage
              authorName
              shortDescription
              blogContent
              timetoRead
              displayOrder
              url
              seo{
                  metaDesc
                  metaTitle
                  keywords
              }
              status
              tags
              publishDate
          }
          page
          limit
          count
  }
}
`
