// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import StepOutCouponsTable from "./StepOutCouponsTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetPaginatedStepOutCouponList } from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import Select from "react-select";

const StepOutCoupons = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [filterFields, setfilterFields] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const { data, refetch } = useGetPaginatedStepOutCouponList(
    limit,
    page,
    filterFields
  ); // ! Coded by Ashwin

  const CouponStatusList = [
    { label: "paused", value: "paused" },
    { label: "live", value: "live" },
    { label: "completed", value: "completed" },
  ];

  useEffect(() => {
    refetch();
  }, [refetch, status, page]);

  useEffect(() => {
    if (data?.getPaginatedStepOutCouponList?.data.length > 0) {
      setTableData(data?.getPaginatedStepOutCouponList?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedStepOutCouponList?.count / limit));
  }, [
    data?.getPaginatedStepOutCouponList?.count,
    data?.getPaginatedStepOutCouponList?.data,
    limit,
  ]);

  useEffect(() => {
    if (data?.getPaginatedStepOutCouponList?.data.length) {
      let editData = data?.getPaginatedStepOutCouponList?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedStepOutCouponList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleSearch = () => {
    const filterFields = {
      status: status?.value,
      searchKeyword: searchKeyword,
    };
    setPage(1);
    setfilterFields(filterFields);
  };

  const handleReset = () => {
    setfilterFields(null);
    setStatus(null);
    setSearchKeyword("");
  };
  return (
    <div>
      <Row>
        <Col sm="4">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Promo Code</strong>
            </Label>
            <Input
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              placeholder="Search"
            />
          </FormGroup>
        </Col>
        <Col sm="4">
          <FormGroup className="w-100">
            <Label className="form-label" for="status">
              <strong>Status</strong>
            </Label>
            <Select
              classNamePrefix="select"
              isClearable={true}
              // isSearchable={true}
              id="status"
              name="status"
              value={status}
              options={CouponStatusList}
              onChange={(value) => setStatus(value)}
            />
          </FormGroup>
        </Col>
        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              marginRight: "10px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginRight: "10px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <StepOutCouponsTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default StepOutCoupons;
