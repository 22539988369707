import Amenities from "../../views/Amenities";

const AmenitiesRoutes = [
  {
    path: "/amenities",
    exact: true,
    component: Amenities,
  },
];

export default AmenitiesRoutes;
