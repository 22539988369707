import { gql } from "@apollo/client";

export const GET_STEP_OUT_BLOG_CATEGORY_LIST = gql`
  query {
    getStepOutBlogCategoryList {
      _id
      categoryName
      image
      url
      seo {
        metaTitle
        metaDesc
        keywords
      }
      status
    }
  }
`;

export const GET_PAGINATED_STEP_OUT_BLOG_CATEGORY_LIST = gql`
  query GetStepOutPaginatedBlogCategory($filter: filter) {
    getStepOutPaginatedBlogCategory(filter: $filter) {
      data {
        _id
        categoryName
        image
        url
        seo {
          metaTitle
          metaDesc
          keywords
        }
        status
      }
      count
      limit
      page
    }
  }
`;
