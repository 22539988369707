import gql from "graphql-tag";

// ! createEventsAndActivities added by Ashwin
export const CREATE_EVENTS_AND_ACTIVITIES = gql`
mutation createEventsAndActivities($eventsAndActivitiesInput: EventsAndActivitiesInput) {
    createEventsAndActivities(eventsAndActivitiesInput: $eventsAndActivitiesInput) {
    title
    }
}
`

// ! updateEventsAndActivities added by Ashwin
export const UPDATE_EVENTS_AND_ACTIVITIES = gql`
mutation updateEventsAndActivities($id: ID!, $eventsAndActivitiesUpdate: EventsAndActivitiesInput) {
    updateEventsAndActivities(id: $id, eventsAndActivitiesUpdate: $eventsAndActivitiesUpdate){
    title
    }
}
`