import React, { useState, useEffect } from "react";
import styles from "./FeedbackCard.module.css";
import { useParams } from "react-router-dom";
import { useGetFeedbackByReservationId, useUpdateStatusRemark } from "@api";
import { Label } from "recharts";
import { Col, FormGroup } from "reactstrap";
import Select from "react-select";
import toaster from "@components/common/Toaster";

function FeedbackResponse() {
  const [feedBack, setFeedBack] = useState("");
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const { reservationId } = useParams();
  const { data, refetch } = useGetFeedbackByReservationId(reservationId);
  const [updateStatusRemarkHandler] = useUpdateStatusRemark();
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data?.getFeedbackByReservationId) {
      setTableData(data?.getFeedbackByReservationId);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const responseTime = new Date();
    const updateStatusData = {
      reservationId,
      statusRemarkDetails: {
        feedbackStatus: status.value,
        propertyRemark: message,
      },
      responseTime: responseTime,
    };

    updateStatusRemarkHandler(updateStatusData);
    toaster("success", "Submitted successfully");
  };

  const RemarkstatusList = [
    { label: "Open", value: "Open" },
    { label: "Work In Progress", value: "Work In Progress" },
    { label: "Closed", value: "Closed" },
  ];

  return (
    <div className="flex justify-center sm:justify-center relative top-11">
      <div className="p-6 rounded-lg shadow-lg bg-white max-w-sm">
        <p className="text-black text-center font-bold text-2xl">
          User Feedback
        </p>
        <form
          className="text-black"
          name="feedbackForm"
          onSubmit={(e) => {
            handleSubmit(e);
            // onclick="this.disabled = true"
            //   value="Save"
            // onclick="this.disabled = true"
          }}
        >
          <p className="text-center font-bold mt-10">
            Reservation ID:{" "}
            {data ? data.getFeedbackByReservationId.reservationId : ""}
          </p>
          <p className="flex my-auto justify-start font-bold mt-5">
            Name:{" "}
            <span className="font-normal">
              &nbsp;{data ? data.getFeedbackByReservationId.salutation : ""}{" "}
              {data ? data.getFeedbackByReservationId.firstName : ""}{" "}
              {data ? data.getFeedbackByReservationId.lastName : ""}
            </span>
          </p>
          <p className="my-auto justify-start font-bold mt-5">
            Location:{" "}
            <span className="font-normal">
              &nbsp; {data ? data.getFeedbackByReservationId.hostelCode : ""}
            </span>
          </p>
          <p className="my-auto justify-start font-bold mt-5">
            Feedback:
            <span className="font-normal">
              &nbsp;{" "}
              {data ? data.getFeedbackByReservationId.customerFeedback : ""}
            </span>
          </p>
          <p className=" text-black my-auto justify-start font-bold mt-5">
            Feedback Message:{" "}
            <span className="font-normal ">
              {data ? data.getFeedbackByReservationId.customerRemark : ""}
            </span>{" "}
          </p>
          {/* <p className="my-auto justify-start font-bold mt-5 flex "> */}
          Status:&nbsp;
          <Col>
            <FormGroup>
              <Label for="status">Status</Label>
              <Select
                required
                isClearable={false}
                id="status"
                name="status"
                value={status}
                options={RemarkstatusList}
                onChange={(value) => setStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          {/* </p> */}
          <p className="my-auto justify-start font-bold mt-5 flex">
            Remark: &nbsp;
            <textarea
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="text-black w-56 h-auto relative bottom-4 left-1 flex bg-white rounded border border-solid border-gray-300 ocus:border-blue-600 focus:outline-none font-normal"
              type="text"
              name="Remark"
            />
          </p>
          <div className="flex justify-center">
            <button
              className={`${styles.feedbackButton}  shadow-md text-black focus:ring-4 hover:scale-105 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-3 text-center `}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FeedbackResponse;
