// ! Coded by Ashwin

import PartnerNew from "../../views/PartnerNew/index";

const PartnerNewRoutes = [
  {
    path: "/partnerNew",
    exact: true,
    component: PartnerNew,
  },
];

export default PartnerNewRoutes;
