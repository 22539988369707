import React from "react";
import { Check, X } from "react-feather";
import { CustomInput, Label } from "reactstrap";

const LabelSwitch = () => (
  <>
    <span className='switch-icon-left'>
      <Check size={14} />
    </span>
    <span className='switch-icon-right'>
      <X size={14} />
    </span>
  </>
);

const SwitchIcon = (props) => {
  const { label, checked, name, handleChecked, id, ...prop } = props;
  return (
    <div className='d-flex align-items-center'>
      <Label for={id} className='mb-0 mr-1'>
        {label}
      </Label>
      <CustomInput
        {...prop}
        style={{ position: "relative", zIndex: "0" }}
        type='switch'
        label={<LabelSwitch />}
        id={id}
        name={name}
        inline
        onChange={(e) => handleChecked(e.target.checked)}
        checked={checked}
      />
    </div>
  );
};

export default SwitchIcon;
