//Added By Gaurav
import gql from "graphql-tag";

export const GET_OCCUPANCY_TRACKER_REPORT = gql`
query getOccupancyTrackerReport($date:String, $hostelIds: [ID]){
  getOccupancyTrackerReport(date: $date, hostelIds: $hostelIds){
        hostelName
        hostelId
        Occupancy
}
}
`;
