import { Activity, User } from "react-feather";
import { Grid, Circle } from "react-feather";

const modules = JSON.parse(localStorage.getItem("modules"));
const role = localStorage.getItem("role");
const email = localStorage.getItem("email");

export default modules?.includes("Rate Gain") 
  ? [
      {
        id: "rateGain",
        title: "Rate Gain",
        icon: <Activity size={20} />,
        badge: "light-warning",
        children: [
          {
            id: "competitorRates",
            title: "Competitor Rates",
            icon: <Circle size={12} />,
            navLink: "/competitorRates",
          },

          {
            id: "rateComparison",
            title: "Competitor Data",
            icon: <Circle size={12} />,
            badge: "light-warning",
            navLink: "/hostelComparisons",
          },
          {
            id: "otahousrlyReport",
            title: "OTA Hourly Report",
            icon: <Circle size={12} />,
            badge: "light-warning",
            navLink: "/otahourlyReport",
          },
        ],
      },
    ]
  : [];
