import { Home, Circle } from 'react-feather';

export default [
  {
      id: 'booking',
      title: 'Booking',
      icon: <Home />,
      navLink: '/booking',
  }
   
];
