import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import TableList from "../../../@core/components/common/TableList";
import { useGetAllPaginatedUserRequest } from "../../../api/dataSource";
import { TABLE_TYPE } from "../common/TableList/constant";

const tableHeaderList = [
  "Action",
  "Req ID",
  "Request Date/Time",
  "Request Name",
  "Hostel Name",
  "Guest Name",
  "Res ID",
  "Status",
  "TAT (IN MIN)",
  "Completion Time (in MIN)",
  "Room No",
  "Staff Remark",
  "Rating",
];

const UserRequestTable = ({
  AddEditModal,
  hostel,
  requestCategory,
  requestStatus,
  filterParams,
  requestStartDate,
  requestEndDate,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);

  const { data, refetch } = useGetAllPaginatedUserRequest(
    limit,
    page,
    filterParams?.hostel.length > 0
      ? filterParams?.hostel.map((hostel) => hostel.value)
      : JSON.parse(localStorage.getItem("hostels")) !== null
      ? JSON.parse(localStorage.getItem("hostels"))
      : [],
    filterParams?.requestCategory?.value.length > 0
      ? filterParams?.requestCategory?.value
      : undefined,
    filterParams?.requestStatus?.value.length > 0
      ? filterParams?.requestStatus?.value
      : undefined,
    filterParams?.requestName?.length > 0
      ? filterParams?.requestName
      : undefined,
    filterParams?.roomNumber?.length > 0 ? filterParams?.roomNumber : undefined,
    // filterParams?.requestStartDate,
    // filterParams?.requestEndDate,
    requestStartDate,
    requestEndDate,
    filterParams?.requestNumber?.length > 0
      ? filterParams?.requestNumber
      : undefined,
    filterParams?.reservationId?.length > 0
      ? filterParams?.reservationId
      : undefined
  );

  const history = useHistory();

  useEffect(() => {
    setPage(1);
    refetch();
  }, [hostel, refetch, requestCategory, requestStatus]);

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    setDataCount(data?.getPaginatedUserRequestList?.count);
  }, [data?.getPaginatedUserRequestList?.count]);

  useEffect(() => {
    if (data) {
      let userRequestList = data?.getPaginatedUserRequestList?.data?.map(
        (item) => {
          return {
            id: item?._id,
            requestNumber: item?.requestNumber,
            requestTime: item?.createdAt,
            reservationId: item?.reservationId,
            requestCategory: item?.requestCategory,
            requestName: item?.request?.requestName,
            hostel: item?.ezeebookings?.hostelName,
            guestName: item?.ezeebookings?.GuestName,
            requestStatus: item?.requestStatus,
            room: item?.ezeebookings?.Room,
            roomNo: item?.ezeebookings?.RoomNo,
            tat: item?.request?.tat,
            isCritical: item?.isCritical,
            staffRemark: item?.staffRemark,
            rating: item?.rating,
            createdAt: item?.createdAt,
            updatedAt: item?.updatedAt,
          };
        }
      );
      setTableData(userRequestList);
      setTotalPage(Math.ceil(data?.getPaginatedUserRequestList?.count / limit));
    }
  }, [data, limit]);

  useEffect(() => {
    if (editId && data?.getPaginatedUserRequestList?.data?.length) {
      let editData = data?.getPaginatedUserRequestList?.data.filter(
        (item) => item._id === editId
      );
      setEditData(editData);
    } else {
      setEditData(null);
    }
  }, [editId, data]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/cms/edit/userRequest/${id}`);
    } else {
      setEditId(id);
    }
  };

  // const handleDelete = (id) => {
  //   confirmDelete(() => {
  //     deleteHostelHandler(id);
  //     refetch();
  //   });
  // };

  return (
    <div>
      <TableList
        // handleAdd={AddEditModal ? handleAdd : null}
        // filterHeader="Guest Requests CMS"
        filterHeader={`Guest Requests CMS (${dataCount} Requests)`}
        tableHeader="Web App User Requests"
        type={TABLE_TYPE.USER_REQUESTS}
        tableData={tableData}
        tableHeaderList={tableHeaderList}
        openModal={() => setOpenModal(!openModal)}
        handleEdit={handleEdit}
        // handleDelete={AddEditModal ? handleDelete : null}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={() => setOpenModal(!openModal)}
          refetch={refetch}
          editData={editData}
          setEditId={setEditId}
        />
      )}
    </div>
  );
};

export default UserRequestTable;
