import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  useAddReplyToPost,
  useDeleteConnectPost,
  useGetAllUserList,
  useGetPostDetails,
  useGetReplyToPost,
} from "../../api/dataSource";
import { Badge, Button, Col, Input, Row } from "reactstrap";
import Reply from "./Reply";
import toaster from "@components/common/Toaster";
import { useHistory } from "react-router";
import { htmlParserChecker } from "../../utility/Utils";
import ConnectTagUserName from "./ConnectTagUserName";
import confirmDelete from "@components/common/confirmDelete";
import ConnectPopupImage from "./ConnectPopupImage";
import { Trash } from "react-feather";

const ConnectPostDetail = () => {
  const url = useParams();
  const history = useHistory();

  const { data: replyonpost, refetch: replyrefetch } = useGetReplyToPost(
    url.postId
  );
  const { data: postDetails, refetch } = useGetPostDetails(url.postId);
  const [addReplyToPostHandler, { data: addedReplyData }] = useAddReplyToPost();
  const [featuredImage, setFeaturedImage] = useState("");
  const [comment, setComment] = useState("");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("name")
  const replyList = replyonpost?.getAllReplyToPosts;
  const post = postDetails?.getDetailsOfPost;
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const [deleteConnectPostHandler, { loading, data }] = useDeleteConnectPost()
  const [currentWord, setCurrentWord] = useState("");
  const [isExecuting, setIsExecuting] = useState(false)
  const [searchName, setSearchName] = useState("");
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [showImagePopup, setShowImagePopup] = useState(false)
  const [popupImageUrl, setPopupImageUrl] = useState("")
  const { data: allUserList } = useGetAllUserList(searchName)
  const setImage = async () => {
    if (post && post?.image) {
      await setFeaturedImage(post?.image);
    }
  };
  useEffect(() => {
    setImage();
  }, [post]);

  const commentRegex = /^\s/

  const submitHandler = async () => {
    try {
      // this will be used when unique userId is given to every one in connect
      // const mentionedUsers = comment.match(/@(\w+)/g)
      // if(mentionedUsers){
      //   mentionedUsers.forEach(mention => {
      //     const mentionUserName = mention.substring(1);
      //     console.log(mentionUserName)
      //   })
      // }
      const submitData = {
        connectID: url.postId,
        comment,
        addedBy: userName,
        addedDateTime: new Date(),
        addedById: userId,
        taggedInComment: taggedUsers
      };
      if (comment.trim().length) {
        await addReplyToPostHandler(submitData);
        toaster("success", "Commented");
        setComment("");
        setSearchName("")
        setTaggedUsers([])
      } else {
        toaster("error", "please add comment first")
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (addedReplyData) {
      replyrefetch();
    }
  }, [addedReplyData]);

  const handleNavigate = () => {
    history.push(`/connect/connectHomepage`);
  };

  const handleDeletePost = async () => {
    const postId = post?._id;
    const deleteOperation = await deleteConnectPostHandler(userId, postId)
  }

  const deletePost = async () => {
    !showImagePopup && confirmDelete(() => handleDeletePost());
  }

  useEffect(() => {
    if (data) {
      if (data?.deleteConnectPost?.message === "deletedSuccessfully") {
        toaster("success", "Post Deleted");
        handleNavigate()
      }
    }
  }, [data])

  const handleCommentChange = (e) => {
    const inputValue = e.target.value;
    const commentRegex = /^[^ ].*/;
    // commentRegex.test(inputValue) && setComment(inputValue); // this works fine while entering but has issue while deleting the comment
    setComment(inputValue)
    const lastChar = e.nativeEvent.data;
    if (e.nativeEvent.data == null && isExecuting) {
      setSearchName(searchName.slice(0, -1));
      return;
    }
    if (lastChar === '@' && !isExecuting) {
      setIsExecuting(true);
      setSearchName("");
    } else if (lastChar === ' ' && isExecuting) {
      setTaggedUsers(taggedUsers => [...taggedUsers, searchName]);
      setIsExecuting(false);
    } else if (isExecuting && lastChar !== '@') {
      setSearchName(searchName + lastChar);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && comment.length > 0) {
      submitHandler();
    }
  };

  const toggleImage = (imageUrl) => {
    if (showImagePopup) {
      setShowImagePopup(false)
    } else {
      setShowImagePopup(true)
      setPopupImageUrl(imageUrl)
    }
  }
  return (
    <div style={{ paddingBottom: "100px" }}>
      <div
        className="card"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "22px",
          gap: "20px"
        }}
      >
        <div style={{ display: "flex" }}>
          <svg width="22" height="23" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7118_33940)">
              <circle cx="12.9999" cy="13.8268" r="12.35" fill="#595959" />
              <path d="M8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908Z" fill="#595959" />
              <path d="M20.4425 21.4158V22.943C18.4131 24.601 15.8215 25.5947 12.9998 25.5947C10.1781 25.5947 7.58646 24.601 5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158Z" fill="#F8F8F8" />
              <path d="M5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158V22.943" fill="#F8F8F8" />
              <path d="M17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908Z" fill="#F8F8F8" />
              <path d="M12.9999 26.1766C6.19008 26.1766 0.649902 20.6364 0.649902 13.8266C0.649902 7.01674 6.19008 1.47656 12.9999 1.47656C19.8097 1.47656 25.3499 7.01674 25.3499 13.8266C25.3499 20.6364 19.8097 26.1766 12.9999 26.1766ZM12.9999 2.64035C6.83173 2.64035 1.81369 7.65839 1.81369 13.8266C1.81369 19.9947 6.83173 25.0128 12.9999 25.0128C19.1681 25.0128 24.1861 19.9947 24.1861 13.8266C24.1861 7.65839 19.1681 2.64035 12.9999 2.64035Z" fill="#595959" />
            </g>
            <defs>
              <clipPath id="clip0_7118_33940">
                <rect width="26" height="26" fill="white" transform="translate(0 0.82666)" />
              </clipPath>
            </defs>
          </svg>
          <div style={{ display: 'flex', flexDirection: "column", marginLeft: "10px" }}>
            <span style={{ fontWeight: "500", fontSize: "16px" }}>{post?.addedBy}</span>
            <span>{post?.createdAt ? new Date(post.createdAt).toLocaleDateString('en-GB', options) : ""}</span>
          </div>
          {
            post?.addedById == userId && <span style={{ height: "40px", width: "40px", borderRadius: "20px", backgroundColor: "yellow", right: "20px", position: "absolute", color: "black", textAlign: "center", cursor: "pointer" ,paddingTop:"8px"}} onClick={() => deletePost()}>                    <Trash size={20} />
            </span>
          }
        </div>
        <hr style={{ marginTop: "-10px" }} />
        <div style={{ width: "100%", marginTop: "-22px" }}>
          <h2 style={{ textTransform: "uppercase", fontWeight: "700" }}>{post?.title}</h2>
          {featuredImage && (
            <img
              src={featuredImage}
              alt="Post"
              style={{ width: "auto", marginTop: "5px", borderRadius: "5px" }}
              onClick={()=>toggleImage(featuredImage)}
            />
          )}
        </div>

        <p style={{ fontSize: "16px", color: "#7e7e7e", textAlign: "justify" }}>{htmlParserChecker(post?.description)}</p>

        <div style={{ marginTop: "-20px", fontSize: "16px" }}>
          {
            post?.department.length > 0 &&

            <div>
              <span style={{ fontWeight: "500", color: "#595959" }}>Department:&nbsp;</span>
              <span style={{ color: "#7e7e7e", }}>
                {post?.department?.join(", ")}
              </span>
            </div>
          }
          {
            post?.hostels?.length > 0 &&
            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#595959", fontWeight: "500" }}>Hostels:&nbsp;</span>
              {post?.hostels?.map((hostelId, index) => (
                <span style={{ color: "#7e7e7e" }}>{`${hostelId.name}, `}</span>
              ))}
            </div>
          }
        </div>


        {
          (post?.attachment?.length > 0) && <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px"
              }}
            >
              {post?.attachment?.map((imageItem, index) => (
                <span key={index}>
                  {imageItem.endsWith('.pdf') ? (
                    <a href={imageItem} target="_blank" rel="noopener noreferrer">
                      <svg width="56" height="60" viewBox="0 0 56 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_7118_35360)">
                          <path d="M4.14746 46.3385L12.5815 55.3594H12.5954L38.0933 55.3732L29.8981 45.1072H35.916V34.7114H40.8516V2.13184H4.14746V46.3385Z" fill="white" />
                          <path d="M12.582 55.3598L12.5959 55.3747V55.3598V46.3389H4.14795L12.582 55.3598Z" fill="#CED3DD" />
                          <path d="M46.0032 45.1078V34.7114H40.8521H35.9161V45.1078H29.8984L40.8521 58.8272L51.8058 45.1078H46.0032Z" fill="#FFCE62" />
                          <path d="M33.1192 12.8086C33.7125 12.8086 34.1934 12.2926 34.1934 11.6562C34.1934 11.0199 33.7125 10.5039 33.1192 10.5039H30.0417C29.4485 10.5039 28.9675 11.0199 28.9675 11.6562V19.8015C28.9675 20.4378 29.4485 20.9538 30.0417 20.9538C30.635 20.9538 31.116 20.4378 31.116 19.8015V16.804H32.8748C33.4681 16.804 33.949 16.288 33.949 15.6517C33.949 15.0153 33.4681 14.4993 32.8748 14.4993H31.116V12.8086H33.1192Z" fill="black" />
                          <path d="M19.8584 19.5036C19.8584 20.1784 20.2895 20.7853 20.9517 20.7853C21.0152 20.7851 22.5153 20.7789 23.1152 20.7676C25.428 20.7243 27.1066 18.5727 27.1066 15.6516C27.1066 12.5811 25.4703 10.5181 23.0349 10.5181H20.9326C20.3417 10.5181 19.8584 11.0483 19.8584 11.6787V19.5036ZM23.0349 12.8228C23.486 12.8228 24.9581 13.0268 24.9581 15.6516C24.9581 17.5696 24.0137 18.4457 23.0776 18.4632C22.8312 18.4679 22.4168 18.4715 22.0182 18.4745C22.0155 17.5447 22.0103 13.7913 22.0088 12.8228H23.0349Z" fill="black" />
                          <path d="M12.7393 20.8459C13.3325 20.8459 13.8135 20.3299 13.8135 19.6935V17.2756C14.2084 17.2735 14.621 17.2717 14.8691 17.2717C16.675 17.2717 18.1441 15.716 18.1441 13.8038C18.1441 11.8916 16.6749 10.3359 14.8691 10.3359H12.7393C12.1548 10.3359 11.665 10.8612 11.665 11.4883V19.6934C11.665 20.33 12.146 20.8459 12.7393 20.8459ZM14.869 12.6407C15.4797 12.6407 15.9955 13.1734 15.9955 13.8039C15.9955 14.4345 15.4796 14.9671 14.869 14.9671C14.6227 14.9671 14.215 14.9689 13.8224 14.9709C13.8204 14.5338 13.8177 13.0993 13.8165 12.6407H14.869Z" fill="black" />
                          <path d="M52.7816 44.6259C52.6063 44.2172 52.2252 43.9553 51.8058 43.9553H47.0774V34.7115C47.0774 34.0751 46.5965 33.5591 46.0032 33.5591H41.9257V2.13184C41.9257 1.49551 41.4448 0.979492 40.8515 0.979492H4.14746C3.55417 0.979492 3.07324 1.49551 3.07324 2.13184V19.8439C3.07324 20.4803 3.55417 20.9963 4.14746 20.9963C4.74075 20.9963 5.22168 20.4803 5.22168 19.8439V3.28418H39.7774V33.5591H35.9161H35.916C35.3227 33.5591 34.8418 34.0751 34.8418 34.7115V43.9549H29.8981C29.0008 43.9549 28.4961 45.1229 29.0824 45.8573L35.7582 54.2197L13.6702 54.2076V46.3392C13.6702 45.7029 13.1893 45.1869 12.596 45.1869H5.22168V29.9964C5.22168 29.3601 4.74075 28.8441 4.14746 28.8441C3.55417 28.8441 3.07324 29.3601 3.07324 29.9964V46.3385C3.07324 46.6408 3.19055 46.9415 3.38949 47.1551L11.8235 56.176C12.0713 56.4418 12.4163 56.571 12.7644 56.5117L37.5999 56.5253L40.0361 59.5767C40.2402 59.8325 40.5383 59.9795 40.852 59.9795C41.1657 59.9795 41.4638 59.8325 41.6679 59.5767L52.6216 45.8572C52.8945 45.5154 52.9571 45.0347 52.7816 44.6259ZM11.5218 47.4914V52.5986L7.13003 47.9014L6.74675 47.4914H11.5218ZM40.8521 57.0557L32.2328 46.2599H35.9162C36.5095 46.2599 36.9904 45.7439 36.9904 45.1076V35.8637H44.9291V45.1076C44.9291 45.7439 45.41 46.2599 46.0033 46.2599H49.4715L40.8521 57.0557Z" fill="black" />
                          <path d="M12.7393 28.123H33.1191C33.7124 28.123 34.1933 27.607 34.1933 26.9707C34.1933 26.3344 33.7124 25.8184 33.1191 25.8184H12.7393C12.146 25.8184 11.665 26.3344 11.665 26.9707C11.665 27.607 12.146 28.123 12.7393 28.123Z" fill="black" />
                          <path d="M17.6082 32.4639C17.0149 32.4639 16.5339 32.9799 16.5339 33.6162C16.5339 34.2525 17.0149 34.7686 17.6082 34.7686H28.2501C28.8434 34.7686 29.3243 34.2525 29.3243 33.6162C29.3243 32.9799 28.8434 32.4639 28.2501 32.4639H17.6082Z" fill="black" />
                          <path d="M3.25457 25.4172C3.50379 25.815 3.97698 26.0068 4.41 25.8936C4.84538 25.7799 5.16453 25.3732 5.21137 24.8979C5.25831 24.4218 5.02338 23.9537 4.61915 23.7461C4.21503 23.5385 3.71262 23.6181 3.39325 23.9594C3.03715 24.3399 2.97495 24.9679 3.25457 25.4172Z" fill="black" />
                        </g>
                        <defs>
                          <clipPath id="clip0_7118_35360">
                            <rect width="55" height="59" fill="white" transform="translate(0.476562 0.979492)" />
                          </clipPath>
                        </defs>
                      </svg>

                    </a>
                  ) : (
                    <img
                      src={imageItem}
                      alt={`Attachment ${index + 1}`}
                      style={{ width: "auto", height: "150px",cursor:"pointer" }}
                      onClick={(e) => { toggleImage(imageItem) }}
                    />
                  )}
                </span>
              ))}

            </div>
          </div>
        }
{
  showImagePopup && <ConnectPopupImage popupImageUrl={popupImageUrl} toggleImage={toggleImage}/>
}
      </div>

      <div>
        <h3 style={{ fontWeight: "800" }}>
          Add your comments
        </h3>


        <div style={{ display: 'flex', gap: "10px", position: "relative" }}>
          <Input
            type="text"
            value={comment}
            id="Comment"
            name="Comment"
            placeholder={`Type here`}
            onChange={(e) => handleCommentChange(e)}
            onKeyPress={handleKeyPress}
          />
          {isExecuting &&
            <ConnectTagUserName comment={comment} userList={allUserList} setComment={setComment} setVisibility={setIsExecuting}
              setSearchName={setSearchName} setTaggedUsers={setTaggedUsers} taggedUsers={taggedUsers}
            />}
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button.Ripple
              color="light"
              onClick={submitHandler}
              style={{ alignItems: "right", width: "150px", fontWeight: "600" }}
            >
              Comment
            </Button.Ripple>
          </div>
        </div>
      </div>
      <div style={{ marginLeft: "-20px" }}>
        {replyList?.length > 0 ? (
          replyList?.map((comment, index) => (
            <Reply key={index} commentData={comment} refetch={replyrefetch} />
          ))
        ) : (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <h3>No comments yet</h3>
          </div>
        )}
      </div>
    </div>
  );
};
export default ConnectPostDetail;
