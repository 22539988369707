// !Added by Ashwin

import React from "react";
import { Edit } from "react-feather";
import { Button } from "reactstrap";
import { dateFormatHandler } from "@utils";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";

const MessageConfigurationTableBody = ({
  tableRowData,
  openModal,
  handleEdit,
}) => {
  return (
    <>
      {tableRowData.map(
        ({ campaignName, TemplateName, timeToSend, id, fromDate, toDate }) => (
          <tr key={id}>
            <td style={{ width: "5%" }}>
              <Button
                className="rounded-circle btn-icon"
                color="none"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                  handleEdit(id);
                }}
              >
                <Edit
                  color={reactFeatherIcon.iconColor}
                  size={reactFeatherIcon.iconSize}
                />
              </Button>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {campaignName}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {TemplateName}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {dateFormatHandler(fromDate)} - {dateFormatHandler(toDate)}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {timeToSend}
              </span>
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default MessageConfigurationTableBody;
