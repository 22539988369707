import React, { useEffect, useState } from "react";
import EzeeMealsTable from "./EzeeMealsTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetEzeeMealReport, useHostelList } from "../../../api/dataSource";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { selectThemeColors, dateFormatter } from "@utils";

const EzeeMeals = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [hostel, setHostel] = useState(null);
  const [HostelsList, setHostelsList] = useState([]);
  const [mealAddedInEzee, setMealAddedInEzee] = useState(false);
  const [ArrivalDateFrom, setArrivalDateFrom] = useState(null);
  const [ArrivalDateTo, setArrivalDateTo] = useState(null);
  const [filter, setFilter] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });

  const { data: HostelsData } = useHostelList();
  const { data, refetch } = useGetEzeeMealReport(limit, page, filter);

  const MealAddedInEzeeTypeList = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  useEffect(() => {
    refetch();
  }, [page, refetch, filter]);

  useEffect(() => {
    const allowedHostel = JSON.parse(localStorage.getItem("hostels"));

    if (HostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostel !== null && allowedHostel?.length !== 0
          ? allowedHostel?.map((hostelId) => {
              return HostelsData?.getHostelList?.find(
                (item) => item?._id === hostelId
              );
            })
          : HostelsData?.getHostelList;

      let hostelList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(hostelList);
    }
  }, [HostelsData]);

  useEffect(() => {
    if (data?.getEzeeMealReport?.data?.length > 0) {
      setTableData(data?.getEzeeMealReport?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getEzeeMealReport?.count / limit));
  }, [data?.getEzeeMealReport?.count, data?.getEzeeMealReport?.data, limit]);
  useEffect(() => {
    if (id && data?.getEzeeMealReport?.data.length) {
      let editData = data?.getEzeeMealReport?.data?.find(
        (item) => item._id === id
      );
      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getEzeeMealReport, id]);
  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };
  const handleSearch = () => {
    const data = {
      hostelIdArray:
        hostel?.length > 0
          ? hostel?.map((hostel) => hostel.value)
          : JSON.parse(localStorage.getItem("hostels")),
      mealAddedInEzee: mealAddedInEzee?.value,
      ArrivalDateFrom,
      ArrivalDateTo,
    };
    setFilter(data);
    setPage(1);
  };
  const handleReset = () => {
    setMealAddedInEzee(false);
    setArrivalDateFrom(null);
    setArrivalDateTo(null);
    setFilter({ hostelIdArray: JSON.parse(localStorage.getItem("hostels")) });
    setHostel([]);
    setPage(1);
  };
  return (
    localStorage.getItem("modules")?.includes("Ezee Meals") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostel">
                  Hostel
                </Label>
              </strong>
              <Select
                isClearable={false}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="mealAddedInEzee">
                  Meals added in Ezee
                </Label>
              </strong>

              <Select
                isSearchable={true}
                isClearable={true}
                id="mealAddedInEzee"
                name="mealAddedInEzee"
                value={mealAddedInEzee}
                options={MealAddedInEzeeTypeList}
                onChange={(value) => setMealAddedInEzee(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Checkin date range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="transactionDate"
                placeholder="Checkin date range"
                options={{
                  mode: "range",
                }}
                value={[ArrivalDateFrom, ArrivalDateTo]}
                onChange={(date) => {
                  setArrivalDateFrom(dateFormatter(date[0]));
                  setArrivalDateTo(dateFormatter(date[1]));
                }}
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>

            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        {tableData.length > 0 ? (
          <>
            <EzeeMealsTable
              setId={setId}
              id={id}
              openModal={() => setOpenModal(!openModal)}
              data={tableData}
              refetch
            />

            <TablePagination
              setPage={setPage}
              page={page}
              totalPage={totalPage}
            />

            <AddEditModal
              open={openModal}
              editData={editData}
              handleToggle={toggleModal}
              refetch={refetch}
              setId={setId}
            />
          </>
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Record Found
          </h2>
        )}
      </div>
    )
  );
};
export default EzeeMeals;
