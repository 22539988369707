import { Layers } from "react-feather";

const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

 export default role === "Revenue" ||
role === "Super Admin" ||
role === "Sales Admin" ||
role === "Marketing Admin" ||
modules?.includes("Coupon Admin")
  ? [
    //   {
    //     id: "couponsGenerator",
    //     title: "Coupons Generator",
    //     icon: <Layers />,
    //     navLink: "/couponsGenerator",
    //   },
     ]
  : 
   [];
