import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";

const HostelHKSubFields = ({ setDataToSubmit, editData }) => {
  const [subHeadType, setSubHeadType] = useState(null);

  const HostelHKType = [
    { label: "Toiletries", value: "Toiletries" },
    { label: "Laundry", value: "Laundry" },
    { label: "Garbage", value: "Garbage" },
    { label: "Room Amenities", value: "Room Amenities" },
    { label: "Pest Control", value: "Pest Control" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );
  }, [editData]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
    };

    setDataToSubmit(data);
  }, [subHeadType]);
  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="subHeadType">
            HK Type
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={HostelHKType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            // isDisabled={editData?._id}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default HostelHKSubFields;
