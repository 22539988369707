// Added by Nitish
import gql from "graphql-tag";

export const GET_PAGINATED_OTA_REVIEWS = gql`
  query getPaginatedOtaReviews($filter: filter) {
    getPaginatedOtaReviews(filter: $filter) {
      count
      limit
      page
      data {
        hostel {
          name
          _id
          brand
        }
        _id
        bookingSource
        guestName
        rating
        review
        positiveReview
        negativeReview
        reviewDate
        reviewTime
        roomDetails
        groupType
        noOfNights
        reservationId
        outOfRating
        reviewReply
        replyDate
        reviewId
        emailId
        phoneNumber
        roomNo
        guestFeedbackDetails {
          wasCommunicationStablished
          didTheyInform
          wereTheySatisfied
        }
        hostelFeedbackDetails {
          knowAboutThisInstance
          resolveInTime
          description
          TicketResponseTime
        }
        cxDetails {
          cxSummary
          Justified
          resolution
          coupon
          couponValidity
          cxDetailsSubmitted
          cxTicketResponseTime
        }
        ticketDetails {
          selectedUser {
            label
            value
          }
          selectedCC {
            label
            value
          }
          TicketCreationTime
        }
        sentiment
        images
        cxTicketNumber
        isReviewFake
        areaManagerRemark
        finalCXRemark
        logs
        caseMedia {
          imageURL
        }
        sentForRemoval
      }
    }
  }
`;

export const GET_OTA_REVIEWS_REPORT = gql`
  query ($hostelID: [ID], $reviewDateFrom: String, $reviewDateTo: String) {
    getOtaReviewsReport(
      hostelID: $hostelID
      reviewDateFrom: $reviewDateFrom
      reviewDateTo: $reviewDateTo
    ) {
      hostelId
      hostelName
      googleRating
      googleCount
      goibiboRating
      goibiboCount
      bookingComRating
      bookingComCount
      hostelWorldRating
      hostelWorldCount
      tripadvisorRating
      tripadvisorCount
      makeMyTripRating
      makeMyTripCount
    }
  }
`;

export const GET_OVERALL_AVERAGE_RATING = gql`
  query ($hostelID: [ID], $reviewDateFrom: String, $reviewDateTo: String) {
    getOverallAverageRating(
      hostelID: $hostelID
      reviewDateFrom: $reviewDateFrom
      reviewDateTo: $reviewDateTo
    )
  }
`;

export const GET_REVIEW_SENTIMENT_ANALYSIS = gql`
  query ($hostelID: [ID], $reviewDateFrom: String, $reviewDateTo: String) {
    getReviewSentimentAnalysis(
      hostelID: $hostelID
      reviewDateFrom: $reviewDateFrom
      reviewDateTo: $reviewDateTo
    ) {
      orm
      positiveRate
      neutralRate
      negativeRate
      nps
      reviewRate
      totalReviews
      positiveReviews
      negativeReviews
      neutralReviews
      responsiveness
      ticketsCreated
    }
  }
`;

export const GET_OTA_SUMMARY = gql`
  query ($hostelId: ID) {
    getRecentOtaSummary(hostelId: $hostelId) {
      hostelId
      hostelName
      googleAvgRating
      bookingComAvgRating
      goibiboAvgRating
      hostelWorldAvgRating
      tripadvisorAvgRating
      makeMyTripAvgRating
    }
  }
`;

export const GET_AVG_RATING_OTA_SUMMARY = gql`
  query getAvgRatingOtaSummary {
    getAvgRatingOtaSummary {
      hostelId
      hostelName
      hostelStatus
      googleAvgRating
      bookingComAvgRating
      goibiboAvgRating
      hostelWorldAvgRating
      tripadvisorAvgRating
      makeMyTripAvgRating
    }
  }
`;

export const GET_CX_REPUTATION_REPORT = gql`
  query getCxReputationReport(
    $hostelId: ID
    $fromDate: String
    $toDate: String
  ) {
    getCxReputationReport(
      hostelId: $hostelId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      bookingComReservs
      bookingComReviews
      bookingComReviewRate
      googleReservs
      googleReviews
      googleReviewRate
      goibiboReservs
      goibiboReviews
      goibiboReviewRate
      hostelWorldReservs
      hostelWorldReviews
      hostelWorldReviewRate
      makemyTripReservs
      makemyTripReviews
      makemyTripReviewRate
      tripadvisorReservs
      tripadvisorReviews
      tripadvisorReviewRate
    }
  }
`;

export const GET_CORPORATE_REPORT_DATA = gql`
  query ($reviewDateFrom: String, $reviewDateTo: String) {
    getCorporateReportData(
      reviewDateFrom: $reviewDateFrom
      reviewDateTo: $reviewDateTo
    ) {
      orm
      positiveRate
      neutralRate
      negativeRate
      nps
      reviewRate
      totalReviews
      positiveReviews
      negativeReviews
      neutralReviews
      responsiveness
      ticketsCreated
      googleAvgRating
      bookingComAvgRating
      goibiboAvgRating
      hostelWorldAvgRating
      tripadvisorAvgRating
      makeMyTripAvgRating
    }
  }
`;

export const GET_HOSTEL_WISE_DATA_FOR_CORPORATE_REPORT = gql`
  query ($reviewDateFrom: String, $reviewDateTo: String) {
    getHostelWiseDataForCorporateReport(
      reviewDateFrom: $reviewDateFrom
      reviewDateTo: $reviewDateTo
    ) {
      _id
      reviewCount
      positiveCount
      negativeCount
      neutralCount
      positivePercentage
      negativePercentage
      neutralPercentage
      hostelId
      hostelName
      orm
    }
  }
`;

export const GET_ORM_TICKETS_REPORT = gql`
  query getTicketsReport(
    $hostelID: [ID]
    $reviewDateFrom: String
    $reviewDateTo: String
  ) {
    getTicketsReport(
      hostelID: $hostelID
      reviewDateFrom: $reviewDateFrom
      reviewDateTo: $reviewDateTo
    ) {
      _id
      avgClosureTime
      hostel
      tickets
      ticketsPending
      ticketsResolved
    }
  }
`;

//Added by Nitish
export const GET_AVG_RATING_OTA_REVIEWS = gql`
  query getAvgRatingOtaReviews(
    $hostelID: [ID]
    $reviewDateFrom: String
    $reviewDateTo: String
  ) {
    getAvgRatingOtaReviews(
      hostelID: $hostelID
      reviewDateFrom: $reviewDateFrom
      reviewDateTo: $reviewDateTo
    ) {
      _id
      bookingComAvgRating
      goibiboAvgRating
      googleAvgRating
      hostelWorldAvgRating
      makeMyTripAvgRating
      tripadvisorAvgRating
    }
  }
`;

export const GET_NEGATIVE_KEY_CLASSIFICATION = gql`
  query getNegativeKeyClassification($filter: filter) {
    getNegativeKeyClassification(filter: $filter) {
      word
      count
    }
  }
`;
