// ! Coded by Ashwin

import WebCheckin from "../../views/WebCheckin"

const WebCheckinRoutes = [
  {
    path: "/webCheckin",
    exact: true,
    component: WebCheckin,
  },
];

export default WebCheckinRoutes;
