import React, { useEffect, useState } from 'react'
import UnboxCouponTable from './UnboxCouponTable'
import AddEditModal from './AddEditModal';
import { useGetAllUnboxCoupon } from '../../../api/Unbox/UnboxCoupon/Queries';

const UnboxCoupon = () => {
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState("");
  const [editData, setEditData] = useState(null);
  const {data, refetch} = useGetAllUnboxCoupon();
  const [tableData, setTableData] = useState([])

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(()=>{
    if(data?.getUnboxCoupon){
      setTableData(data?.getUnboxCoupon)
    }else{
      setTableData([])
    }
  },[data])


  useEffect(()=>{
    if(data?.getUnboxCoupon){
      let editData = data?.getUnboxCoupon.find((item)=> item._id === id)
      setEditData(editData);
    }else{
      setId(null);
      setEditData(null);
    }
  }, [id])

  // useEffect(()=>{
  //   if(data?.getUnboxCoupon){
  //     refetch()
  //   }
  // },[data?.getUnboxCoupon])
  return (
    <div>
      <UnboxCouponTable
            openModal={() => setOpenModal(!openModal)}
            tableData={tableData}
            setId={setId}
            refetch={refetch}
      />
      <AddEditModal open={openModal} handleToggle={toggleModal} editData={editData} refetch={refetch}/>
    </div>
  )
}

export default UnboxCoupon