import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";

const HostelUtilitiesSubFields = ({ setDataToSubmit, editData }) => {
  const [subHeadType, setSubHeadType] = useState(null);
  const [accountId, setAccountId] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [quantity, setQuantity] = useState("");
  const [quantityLabel, setQuantityLabel] = useState("Quantity");

  const HostelUtilitiesType = [
    // { label: "Electricity/DG/Fuel", value: "Electricity/DG/Fuel" },
    { label: "Electricity", value: "Electricity" },
    { label: "Fuel", value: "Fuel" },
    // { label: "Water", value: "Water" },
    { label: "Water/Tanks", value: "Water/Tanks" },
    { label: "Internet", value: "Internet" },
    // { label: "TV", value: "TV" },
    { label: "TV/OTT", value: "TV/OTT" },
    { label: "Software", value: "Software" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    if (subHeadType && subHeadType.value === "Fuel") {
      setQuantityLabel("Quantity (in liters)");
    } else {
      setQuantityLabel("Quantity");
    }
  }, [subHeadType]);

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );
    setStartDate(
      editData?.subFields?.startDate !== null
        ? editData?.subFields?.startDate
        : new Date()
    );
    setEndDate(
      editData?.subFields?.endDate !== null
        ? editData?.subFields?.endDate
        : new Date()
    );

    setAccountId(
      editData?.subFields?.accountId !== null
        ? editData?.subFields?.accountId
        : ""
    );
    setQuantity(
      editData?.subFields?.quantity !== null
        ? editData?.subFields?.quantity
        : ""
    );
  }, [editData]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
      accountId,
      startDate,
      endDate,
      quantity,
    };

    setDataToSubmit(data);
  }, [subHeadType, startDate, endDate, accountId, quantity]);
  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="subHeadType">
            Utility Type
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={HostelUtilitiesType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            // isDisabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="accountId">
            Meter / Account Id
          </Label>
          <Input
            type="text"
            value={accountId}
            id="accountId"
            name="accountId"
            placeholder="Add Meter / Account Id"
            onChange={(e) => setAccountId(e.target.value)}
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="start date">
            Utility bill start date
          </Label>

          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={startDate}
            onChange={(date) =>
              setStartDate(new Date(dateFormatter(new Date(date))))
            }
            id="startDate"
            placeholder="Select start date"
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="end date">
            Utility bill end date
          </Label>

          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={endDate}
            onChange={(date) =>
              setEndDate(new Date(dateFormatter(new Date(date))))
            }
            id="endDate"
            placeholder="Select end date"
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="quantity">
            {/* Quantity */}
            {quantityLabel}
          </Label>
          <Input
            type="text"
            value={quantity}
            id="quantity"
            name="quantity"
            placeholder="Add Quantity"
            onChange={(e) => setQuantity(e.target.value)}
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default HostelUtilitiesSubFields;
