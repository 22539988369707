import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";

const AncillaryPayoutSubFields = ({ setDataToSubmit, editData }) => {
  const [subHeadType, setSubHeadType] = useState(null);

  const AncillaryPayoutVendorType = [
    { label: "Guest Laundry", value: "Guest Laundry" },
    { label: "Transportation", value: "Transportation" },
    { label: "Activity/Adventure", value: "Activity/Adventure" },
    { label: "Accommodation", value: "Accommodation" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );
  }, [editData]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
    };

    setDataToSubmit(data);
  }, [subHeadType]);

  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="subHeadType">
            Vendor Type*
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={AncillaryPayoutVendorType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            isDisabled={editData?.subFields?.subHeadType}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default AncillaryPayoutSubFields;
