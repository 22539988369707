import React, { useEffect, useState } from "react";
import StepOutContactUsTable from "./StepOutContactUsTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetPaginatedStepOutContactUsList } from "../../../api/dataSource";

const StepOutContactUs = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState();
  const [page, setPage] = useState(20);
  const [totalPage, setTotalPage] = useState(1);

  const { data, refetch } = useGetPaginatedStepOutContactUsList(limit, page);

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedStepOutContactUsList?.data.length > 0) {
      setTableData(data?.getPaginatedStepOutContactUsList?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(
      Math.ceil(data?.getPaginatedStepOutContactUsList?.count / limit),
    );
  }, [
    data?.getPaginatedStepOutContactUsList?.count,
    data?.getPaginatedStepOutContactUsList?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedStepOutContactUsList?.data.length) {
      let editData = data?.getPaginatedStepOutContactUsList?.data?.find(
        (item) => item._id === id,
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedStepOutContactUsList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  return (
    <div>
      <StepOutContactUsTable
        setId={setId}
        id={id}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        refetch
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
        setId={setId}
      />
    </div>
  );
};

export default StepOutContactUs;
