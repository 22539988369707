import Connect from "../../views/Connect";
import ConnectPostDetail from "../../views/Connect/ConnectPostDetail";

const ConnectRoutes = [
  {
    path: "/connect/connectHomepage",
    component: Connect,
    exact: true,
  },
  {
    path:"/connect/connectPost/:postId",
    component: ConnectPostDetail,
    exact:true
  }
  
];

export default ConnectRoutes;
