import React, { useEffect, useState } from "react";
import TablePagination from "@components/common/TablePagination";
import { useGetPaginatedGoPaymentRequest } from "../../../api/GOPaymentRequest/Queries";
import { useGenerateOpexExport } from "../../../api/GOPaymentRequest/Mutation";
import GOEntryTable from "./GOEntryTable";
import AddEditModal from "./AddEditModal";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  CustomInput,
} from "reactstrap";
import Select from "react-select";
import { useHostelList } from "../../../api/dataSource";
import { selectThemeColors, dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import ReactSlider from "react-slider";

const GOEntry = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [hostelsList, setHostelsList] = useState([]);
  const [headFilter, setHeadFilter] = useState(null);
  const [subHeadFilter, setsubHeadFilter] = useState(null);
  const [subHeadTypeFilter, setSubHeadTypeFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [hostel, setHostel] = useState([]);
  const [filterFeilds, setFilterFeilds] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });
  const [openModal, setOpenModal] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [requestId, setRequestId] = useState("");
  const [severity, setSeverity] = useState(null);
  const [requestDateFrom, setRequestDateFrom] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const [completionDateFrom, setCompletionDateFrom] = useState(null);
  const [completionDateTo, setCompletionDateTo] = useState(null);
  const [requestDateTo, setRequestDateTo] = useState(null);
  const [generateOpexExportHandler, { data: opexDataForExport }] =
    useGenerateOpexExport();

  const [count, setCount] = useState(null);
  const [includeOverdue, setIncludeOverdue] = useState(false);
  const [vendorName, setVendorname] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [totalAmountSum, setTotalAmountSum] = useState(null);
  const [totalPaidSum, setTotalPaidSum] = useState(null);

  const { data: allHostelsData } = useHostelList();
  const { data, loading, refetch } = useGetPaginatedGoPaymentRequest(
    limit,
    page,
    filterFeilds
  );

  const exportInputFilter = {
    filterFeilds,
  };

  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(500000);

  const handleSliderChange = (values) => {
    setMinValue(values[0]);
    setMaxValue(values[1]);
  };

  const handleMinInputChange = (e) => {
    setMinValue(parseFloat(e.target.value));
  };

  const handleMaxInputChange = (e) => {
    setMaxValue(parseFloat(e.target.value));
  };

  const headList = [
    { label: "Hostel", value: "Hostel" },
    { label: "F&B", value: "F&B" },
    { label: "Ancillary", value: "Ancillary" },
  ];

  const subHeadList = [
    { label: "Utilities", value: "Utilities" },
    { label: "R&M", value: "R&M" },
    { label: "HK", value: "HK" },
    { label: "General", value: "General" },
    { label: "Material", value: "Material" },
    { label: "Vendor Payout", value: "Vendor Payout" },
  ];

  const subHeadTypeList = [
    { label: "Electricity", value: "Electricity" },
    { label: "Fuel", value: "Fuel" },
    { label: "Water/Tanks", value: "Water/Tanks" },
    { label: "Internet", value: "Internet" },
    { label: "TV/OTT", value: "TV/OTT" },
    { label: "Software", value: "Software" },
    { label: "Others", value: "Others" },

    { label: "Civil", value: "Civil" },
    { label: "Furniture", value: "Furniture" },
    { label: "Furnishing", value: "Furnishing" },
    { label: "Fabrication", value: "Fabrication" },
    { label: "Electrical", value: "Electrical" },
    { label: "Electronics/Equipments", value: "Electronics/Equipments" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Miscellaneous", value: "Miscellaneous" },

    { label: "Toiletries", value: "Toiletries" },
    { label: "Laundry", value: "Laundry" },
    { label: "Garbage", value: "Garbage" },
    { label: "Room Amenities", value: "Room Amenities" },
    { label: "Pest Control", value: "Pest Control" },
    { label: "Others", value: "Others" },

    { label: "Stationery & Printing", value: "Stationery & Printing" },
    { label: "Legal & Liaisoning", value: "Legal & Liaisoning" },
    { label: "Courier", value: "Courier" },
    { label: "Staff Welfare", value: "Staff Welfare" },
    { label: "Staff Travel", value: "Staff Travel" },
    { label: "Others", value: "Others" },

    { label: "Dairy", value: "Dairy" },
    { label: "Non-Dairy", value: "Non-Dairy" },
    { label: "Vegetables/Fruits", value: "Vegetables/Fruits" },
    { label: "Supplies (Gas, etc)", value: "Supplies (Gas, etc)" },
    { label: "Others (R&M, etc)", value: "Others (R&M, etc)" },

    { label: "Guest Laundry", value: "Guest Laundry" },
    { label: "Transportation", value: "Transportation" },
    { label: "Activity/Adventure", value: "Activity/Adventure" },
    { label: "Accommodation", value: "Accommodation" },
    { label: "Others", value: "Others" },

    { label: "Lock", value: "Lock" },
    { label: "Warmee", value: "Warmee" },
    { label: "Toiletry Kit", value: "Toiletry Kit" },
    { label: "Others", value: "Others" },
  ];

  const severityOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const invoiceStatusOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Uploaded", value: "Uploaded" },
  ];

  const role = localStorage.getItem("role");
  const department = localStorage.getItem("department");

  const statusDropDownList = [
    { label: "Pending", value: "Pending" },
    { label: "AM Approved", value: "AM Approved" },
    { label: "AM Rejected", value: "AM Rejected" },
    { label: "RM Approved", value: "RM Approved" },
    { label: "RM Rejected", value: "RM Rejected" },
    // { label: "CEO Approved", value: "CEO Approved" },
    { label: "CEO Rejected", value: "CEO Rejected" },
    { label: "Payment Due", value: "Payment Due" },
    { label: "Payment Done", value: "Payment Done" },
    { label: "Payment Rejected", value: "Payment Rejected" },
    { label: "All", value: "All" },
  ];

  const paymentStatusDropDownList = [
    { label: "Payment Due", value: "Payment Due" },
    { label: "Payment Done", value: "Payment Done" },
    { label: "Payment Rejected", value: "Payment Rejected" },
  ];

  const getFilteredStatusList = () => {
    if (role === "Ground Staff" || role === "Area Manager") {
      return statusDropDownList;
    }
    if (role === "Regional Manager") {
      return statusDropDownList.filter((status) =>
        [
          "AM Approved",
          "RM Approved",
          "RM Rejected",
          "CEO Approved",
          "CEO Rejected",
          "Payment Due",
          "Payment Done",
          "Payment Rejected",
          "All",
        ].includes(status.value)
      );
    }
    if (role === "CEO") {
      return statusDropDownList.filter((status) =>
        [
          "RM Approved",
          "CEO Approved",
          "CEO Rejected",
          "Payment Due",
          "Payment Done",
          "Payment Rejected",
          "All",
        ].includes(status.value)
      );
    }
    if (department === "Finance") {
      return statusDropDownList.filter((status) =>
        ["Payment Due", "Payment Done", "Payment Rejected", "All"].includes(
          status.value
        )
      );
    }
    return statusDropDownList;
  };

  const filteredStatusList = getFilteredStatusList();

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const toggleIncludeOverdue = () => {
    setIncludeOverdue(!includeOverdue);
  };

  useEffect(() => {
    if (opexDataForExport?.generateOpexExport?.data) {
      if (opexDataForExport?.generateOpexExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          opexDataForExport?.generateOpexExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `OPEX_Payment_Request_Export.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [opexDataForExport]);

  useEffect(() => {
    if (data?.getPaginatedPaymentRequest?.data?.length > 0) {
      setTableData(data?.getPaginatedPaymentRequest?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getPaginatedPaymentRequest?.count / limit));
  }, [
    data?.getPaginatedPaymentRequest?.data,
    data?.getPaginatedPaymentRequest?.count,
    limit,
  ]);

  useEffect(() => {
    const paymentRequest = data?.getPaginatedPaymentRequest;
    if (paymentRequest) {
      setCount(paymentRequest.count);
      setTotalAmountSum(paymentRequest.totalAmountSum);
      setTotalPaidSum(paymentRequest.totalPaidSum);
    }
  }, [data?.getPaginatedPaymentRequest]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    if (id && data?.getPaginatedPaymentRequest?.data?.length > 0) {
      let findData = data?.getPaginatedPaymentRequest?.data?.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedPaymentRequest?.data, id]);

  const handleSearch = () => {
    const filterFeilds = {
      hostelIdArray:
        hostel.length > 0
          ? hostel?.map((hostel) => hostel?.value)
          : JSON.parse(localStorage.getItem("hostels")),
      // head: headFilter?.map((obj) => obj?.value),
      head: headFilter?.value,
      subHead: subHeadFilter?.value,
      subHeadType: subHeadTypeFilter?.value,
      status: statusFilter?.map((obj) => obj?.value),
      requestId,
      severity: severity?.value,
      requestDateFrom,
      requestDateTo,
      invoiceStatus: invoiceStatus?.value,
      includeOverdue,
      vendorName,
      minTotalAmount: minValue,
      maxTotalAmount: maxValue,
      completionDateFrom,
      completionDateTo: completionDateTo
        ? new Date(new Date(completionDateTo).setHours(23, 59, 59, 999))
        : null,
      paymentStatus: paymentStatus?.value,
    };
    setPage(1);
    setFilterFeilds(filterFeilds);
  };

  const handleReset = () => {
    setFilterFeilds({
      hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    });
    setHeadFilter(null);
    setsubHeadFilter(null);
    setSubHeadTypeFilter(null);
    setStatusFilter(null);
    setHostel([]);
    setSeverity(null);
    setRequestId("");
    setRequestDateFrom(null);
    setRequestDateTo(null);
    setInvoiceStatus(null);
    setIncludeOverdue(false);
    setVendorname("");
    setMaxValue(null);
    setMinValue(null);
    setCompletionDateFrom(null);
    setCompletionDateTo(null);
    setPaymentStatus(null);
  };

  const getFilteredSubHeadList = () => {
    if (!headFilter) return [];

    switch (headFilter.value) {
      case "Hostel":
        return subHeadList.filter((item) =>
          ["Utilities", "R&M", "HK", "General"].includes(item.value)
        );
      case "F&B":
        return subHeadList.filter((item) => ["Material"].includes(item.value));
      case "Ancillary":
        return subHeadList.filter((item) =>
          ["Material", "Vendor Payout"].includes(item.value)
        );

      default:
        return subHeadList;
    }
  };

  const getFilteredSubHeadTypeList = () => {
    if (!subHeadFilter) return [];

    switch (subHeadFilter.value) {
      case "Utilities":
        return subHeadTypeList.filter((item) =>
          [
            "Electricity",
            "Fuel",
            "Water/Tanks",
            "Internet",
            "TV/OTT",
            "Software",
          ].includes(item.value)
        );
      case "R&M":
        return subHeadTypeList.filter((item) =>
          [
            "Civil",
            "Furniture",
            "Furnishing",
            "Fabrication",
            "Electrical",
            "Electronics/Equipments",
            "Plumbing",
            "Miscellaneous",
          ].includes(item.value)
        );

      case "HK":
        return subHeadTypeList.filter((item) =>
          [
            "Toiletries",
            "Laundry",
            "Garbage",
            "Fabrication",
            "Room Amenities",
            "Pest Control",
          ].includes(item.value)
        );

      case "General":
        return subHeadTypeList.filter((item) =>
          [
            "Stationery & Printing",
            "Legal & Liaisoning",
            "Courier",
            "Staff Welfare",
            "Staff Travel",
          ].includes(item.value)
        );

      case "Material":
        return subHeadTypeList.filter((item) =>
          [
            "Dairy",
            "Non-Dairy",
            "Vegetables/Fruits",
            "Supplies (Gas, etc)",
            "Others (R&M, etc)",
          ].includes(item.value)
        );

      case "Vendor Payout":
        return subHeadTypeList.filter((item) =>
          [
            "Guest Laundry",
            "Transportation",
            "Activity/Adventure",
            "Accommodation",
          ].includes(item.value)
        );

      default:
        return subHeadTypeList;
    }
  };

  return (
    localStorage.getItem("modules")?.includes("Operations") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Request date</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="transactionDate"
                placeholder="Request date range"
                options={{
                  mode: "range",
                }}
                value={[requestDateFrom, requestDateTo]}
                onChange={(date) => {
                  setRequestDateFrom(dateFormatter(date[0]));
                  setRequestDateTo(dateFormatter(date[1]));
                }}
              />
            </FormGroup>
          </Col>

          {/* <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Completion date</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="transactionDate"
                placeholder="Completion date range"
                options={{
                  mode: "range",
                }}
                value={[completionDateFrom, completionDateTo]}
                onChange={(date) => {
                  setCompletionDateFrom(dateFormatter(date[0]));
                  setCompletionDateTo(dateFormatter(date[1]));
                }}
              />
            </FormGroup>
          </Col> */}

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Completion date</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="transactionDate"
                placeholder="Completion date range"
                options={{
                  mode: "range",
                }}
                value={[completionDateFrom, completionDateTo]}
                onChange={(date) => {
                  setCompletionDateFrom(dateFormatter(date[0]));
                  if (date[1]) {
                    const endOfDay = new Date(
                      new Date(date[1]).setHours(23, 59, 59, 999)
                    );
                    setCompletionDateTo(dateFormatter(endOfDay));
                  } else {
                    setCompletionDateTo(null);
                  }
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestId">
                <strong>Request ID</strong>
              </Label>
              <Input
                type="text"
                id="requestId"
                name="requestId"
                placeholder="Reservation ID"
                value={requestId}
                onChange={(e) => {
                  setRequestId(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorName">
                <strong>Vendor name</strong>
              </Label>
              <Input
                type="text"
                id="vendorName"
                name="vendorName"
                placeholder="Vendor name"
                value={vendorName}
                onChange={(e) => {
                  setVendorname(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Hostel</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                closeMenuOnSelect={false}
                options={hostelsList}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="headFilter">
                <strong>Head</strong>
              </Label>
              <Select
                isClearable={true}
                id="headFilter"
                name="headFilter"
                theme={selectThemeColors}
                options={headList}
                value={headFilter}
                onChange={(value) => setHeadFilter(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="subHeadFilter">
                <strong>Sub head</strong>
              </Label>
              <Select
                isClearable={true}
                id="subHeadFilter"
                name="subHeadFilter"
                theme={selectThemeColors}
                options={getFilteredSubHeadList()}
                value={subHeadFilter}
                onChange={(value) => setsubHeadFilter(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="subHeadTypeFilter">
                <strong>Type</strong>
              </Label>
              <Select
                isClearable={true}
                id="subHeadTypeFilter"
                name="subHeadTypeFilter"
                theme={selectThemeColors}
                options={getFilteredSubHeadTypeList()}
                value={subHeadTypeFilter}
                onChange={(value) => setSubHeadTypeFilter(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="statusFilter">
                <strong>Status</strong>
              </Label>
              <Select
                isClearable={true}
                id="statusFilter"
                name="statusFilter"
                isMulti
                theme={selectThemeColors}
                options={filteredStatusList}
                value={statusFilter}
                onChange={(value) => setStatusFilter(value)}
                classNamePrefix="select"
                // menuPlacement="top"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="severity">
                <strong>Severity</strong>
              </Label>
              <Select
                isClearable={true}
                id="severity"
                name="severity"
                theme={selectThemeColors}
                options={severityOptions}
                value={severity}
                onChange={(value) => setSeverity(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="invoiceStatus">
                <strong>Invoice status</strong>
              </Label>
              <Select
                isClearable={true}
                id="invoiceStatus"
                name="invoiceStatus"
                theme={selectThemeColors}
                options={invoiceStatusOptions}
                value={invoiceStatus}
                onChange={(value) => setInvoiceStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="paymentStatus">
                <strong>Payment status</strong>
              </Label>
              <Select
                isClearable={true}
                id="paymentStatus"
                name="paymentStatus"
                theme={selectThemeColors}
                options={paymentStatusDropDownList}
                value={paymentStatus}
                onChange={(value) => setPaymentStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <Label className="form-label" for="paymentDue">
              <strong>Total amount range</strong>
            </Label>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="slider-thumb"
              trackClassName="slider-track"
              defaultValue={[minValue, maxValue]}
              onChange={handleSliderChange}
              min={0}
              max={500000}
              pearling
              snapDragDisabled
            />
            <div style={{ display: "flex" }}>
              <p>{minValue}</p>
              <p style={{ marginLeft: "auto" }}>{maxValue}</p>
            </div>
          </Col>

          {/* <Col sm="3">
            <Label
              className="form-label"
              for="paymentDue"
              style={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              Total Amount Range
            </Label>
            <div style={{ position: "relative", padding: "20px 0" }}>
              <ReactSlider
                className="custom-slider"
                thumbClassName="custom-thumb"
                trackClassName="custom-track"
                value={[minValue, maxValue]}
                onChange={handleSliderChange}
                min={0}
                max={500000}
                step={0.1}
                pearling
                style={{
                  width: "100%",
                  height: "6px",
                  background: "#ddd",
                  borderRadius: "5px",
                  position: "relative",
                  marginBottom: "10px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                  }}
                >
                  <Label for="minValue" style={{ marginBottom: "5px" }}>
                    Min Amount
                  </Label>
                  <Input
                    type="number"
                    id="minValue"
                    value={minValue}
                    onChange={handleMinInputChange}
                    step="0.1"
                    min="0"
                    style={{
                      width: "100%",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ced4da",
                      boxSizing: "border-box",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                  }}
                >
                  <Label for="maxValue" style={{ marginBottom: "5px" }}>
                    Max Amount
                  </Label>
                  <Input
                    type="number"
                    id="maxValue"
                    value={maxValue}
                    onChange={handleMaxInputChange}
                    step="0.1"
                    min={minValue}
                    style={{
                      width: "100%",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ced4da",
                      boxSizing: "border-box",
                    }}
                  />
                </div>
              </div>
            </div>
            <style>
              {`
          .custom-slider {
            width: 100%;
            height: 6px;
            background: #ddd;
            border-radius: 5px;
          }

          .custom-track {
            background: #007bff;
            height: 6px;
            border-radius: 5px;
          }

          .custom-thumb {
            width: 20px;
            height: 20px;
            background: #007bff;
            border: 2px solid #fff;
            border-radius: 50%;
            cursor: pointer;
            outline: none;
          }
        `}
            </style>
          </Col> */}

          {/* <Col sm="3">
            <FormGroup>
              <Label for="includeOverdue">
                <strong>Include overdue requests</strong>
              </Label>
              <CustomInput
                type="switch"
                id="includeOverdue"
                name="includeOverdue"
                checked={includeOverdue}
                onChange={() => setIncludeOverdue(!includeOverdue)}
              />
            </FormGroup>
          </Col> */}

          <Col className="mb-2 mr-5 d-flex justify-content-end">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={handleSearch}
            >
              Search
            </Button>

            <Button
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>

            <Button
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="success"
              appearance="success"
              type="button"
              onClick={() => generateOpexExportHandler(exportInputFilter)}
            >
              Export
            </Button>
          </Col>
        </Row>
        <GOEntryTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          count={count}
          totalAmountSum={totalAmountSum}
          totalPaidSum={totalPaidSum}
          refetch={refetch}
          loading={loading}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
        <AddEditModal
          setId={setId}
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      </div>
    )
  );
};

export default GOEntry;
