import OTAHourlyReportComponent from "../../views/OTAHourlyReport/index";


const OTAHourlyReport = [
  {
    path: "/otahourlyReport",
    component: OTAHourlyReportComponent,
    exact: true,
  },
];

export default OTAHourlyReport;
