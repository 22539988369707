import gql from "graphql-tag";

export const UPDATE_WORKATION_PACKAGE = gql`
  mutation updateWorkationPackage(
    $id: ID!
    $workationPackageUpdate: WorkationPackageInput
  ) {
    updateWorkationPackage(
      id: $id
      workationPackageUpdate: $workationPackageUpdate
    ) {
      _id
    }
  }
`;
