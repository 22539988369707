import React from "react";
import { Editor } from "react-draft-wysiwyg";
// import "./style.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useUploadImage } from "../../api/dataSource";

const ConnectEditor = ({ editorState, setEditorState }) => {
  // uploadHandler
  const [uploadImageHandler] = useUploadImage();

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const uploadImageCallBack = async (file) => {
    try {
      const imageDatalink = await uploadImageHandler(file, `blogimage`);
      return { data: { link: imageDatalink?.data?.uploadImage?.url } };
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="App">
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'fontFamily', 'link', 'embedded', 'emoji', 'remove', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          blockType: {
            inDropdown: true,
            options: [
              "Normal",
              "H1",
              "H2",
              "H3",
              "H4",
              "H5",
              "H6",
              "Blockquote",
            ],
          },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
          },
          fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Mulish'],
          },
          list: {
            inDropdown: true,
            options: ['unordered', 'ordered'],
          },
          textAlign: {
            inDropdown: true,
            options: ['left', 'center', 'right', 'justify']
          },
          link: {
            defaultTargetOption: "_blank",
            options: ["link"],
          },
          embedded: {
            defaultSize: {
              height: 'auto',
              width: '100%',
            },
            embedCallback: (link) => {
              const detectedSrc = /<iframe.*? src="(.*?)"/.exec(link);
              return (detectedSrc && detectedSrc[1]) ? detectedSrc[1] : link;
            },
            options: ['embedded']
          },

          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: true },
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            className: { width: 500 }
          },
        }}
        editorStyle={{ maxHeight: "400px", padding: "20px", position: "relative" }}
      />
    </div>
  );
};
export default ConnectEditor;
