// ! Added by Ashwin

import UnpaidReservation from "../../views/UnpaidReservation";

const UnpaidReservationRoutes = [
  {
    path: "/unpaidReservation",
    exact: true,
    component: UnpaidReservation,
  },
];

export default UnpaidReservationRoutes;
