import mock from '../mock';

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'analyticsDash',
        isBookmarked: false,
        title: 'Onboarding',
        icon: 'Home',
        link: '/onboarding',
      },
    ],
  },
];

// GET Search Data
mock.onGet('/api/main-search/data').reply((config) => {
  return [200, { searchArr }];
});
