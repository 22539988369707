// ** Third Party Components
import { MoreHorizontal } from "react-feather";
// import { FormattedMessage } from "react-intl";

const VerticalNavMenuSectionHeader = ({ item }) => {
  return (
    <li className='navigation-header'>
      <span>
        {/* <FormattedMessage id={item.header} /> */}
        {item.header}
      </span>
      <MoreHorizontal className='feather-more-horizontal' />
    </li>
  );
};

export default VerticalNavMenuSectionHeader;
