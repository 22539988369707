import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Label,
    Button,
    FormGroup,
    Input,
} from "reactstrap";
import Select from "react-select";
import { useCreateEmployeePerformanceEvaluation, useUpdateEmployeePerformance } from '../../../api/EmployeePerformance/Mutation';


const EmployeePerformanceEvaluationModal = (props) => {
    const { employeeDetails, showModal, onClose, data, allHostelsData, refetchEmployeeProfile } =
        props;
    // console.log(employeeDetails)
    const [componentShown, setComponentShown] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [title, setEvaluationTitle] = useState("");
    const [month, setEvaluationMonth] = useState("")
    const [year, setEvaluationYear] = useState("")
    const [score, setEvaluationScore] = useState("");
    const [remark, setEvaluationRemark] = useState("")

    const [CreateEmployeePerformanceEvaluationHandler, { data: createdData }] =
        useCreateEmployeePerformanceEvaluation();

    const [updateEmployeePerformanceHandler, { data: updatedData }] = useUpdateEmployeePerformance();
    const yearList = [
        { label: "2023", value: 2023 },
        { label: "2024", value: 2024 },
        { label: "2025", value: 2025 },
    ];

    const MonthsList = [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
    ];

    const handleClose = () => {
        onClose(!showModal);
        setComponentShown(null);
        setSelectedAction(null);
    };

    useEffect(() => {
        if (data?._id) {
            const monthValue = MonthsList.find(month => month.value === data?.month);
            setEvaluationMonth(monthValue)
            setEvaluationYear({ label: data?.year, value: data?.year })
            setEvaluationTitle(data?.evaluationTitle)
            setEvaluationScore(data?.score)
            setEvaluationRemark(data?.remark)
        } else {
            setEvaluationMonth("")
            setEvaluationYear("")
            setEvaluationScore("")
            setEvaluationRemark("")
            setEvaluationTitle("")
        }
    }, [data])

    const handleSubmit = async () => {
        let EmployeePerformanceData = {
            empId: employeeDetails?.id,
            empCode: employeeDetails?.empCode,
            month: Number(month?.value),
            evaluationTitle: title,
            year: Number(year?.value),
            score: Number(score),
            remark: remark
        }
        if (data?._id) {
            // console.log(data?._id, EmployeePerformanceData)
            const updateData = await updateEmployeePerformanceHandler(data?._id, EmployeePerformanceData)
            if (updateData) {
                refetchEmployeeProfile()
            }
        } else {
            const createdData = await CreateEmployeePerformanceEvaluationHandler(EmployeePerformanceData)
            if (createdData) {
                refetchEmployeeProfile()
            }

        }
        handleClose()
    }
    return (
        <Modal
            isOpen={showModal}
            toggle={handleClose}
            className="modal-dialog-centered modal-lg"
        >
            <ModalHeader
                className="bg-transparent"
                toggle={handleClose}
            ></ModalHeader>
            <ModalBody className="px-sm-5 mx-50 pb-5">
                <div>
                    <Row>
                        <Col>
                            <Row>
                                <Col sm="6">
                                    <Label for="evaluationMonth">
                                        <strong>Month</strong>
                                    </Label>
                                    {/* <Input
                                        autoFocus
                                        type="text"
                                        id="evaluationMonth"
                                        placeholder="Evaluation month"
                                        cols="2"
                                        value={month}
                                        onChange={(e) => {
                                            setEvaluationMonth(e.target.value);
                                        }}
                                        required
                                    /> */}

                                    <Select
                                        isClearable={true}
                                        id="evaluationMonth"
                                        name="evaluationMonth"
                                        value={
                                            month
                                        }
                                        options={MonthsList}
                                        onChange={(selectedOption) => {
                                            setEvaluationMonth(selectedOption);
                                        }}
                                        classNamePrefix="select"
                                    />
                                </Col>

                                <Col sm="6">
                                    <Label for="evaluationYear">
                                        <strong>Year</strong>
                                    </Label>
                                    <Select
                                        isClearable={true}
                                        id="evaluationYear"
                                        name="evaluationYear"
                                        value={
                                            year
                                        }
                                        options={yearList}
                                        onChange={(selectedOption) => {
                                            setEvaluationYear(selectedOption);
                                        }}
                                        classNamePrefix="select"
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="6">
                                    <Label for="evaluationTitle">
                                        <strong>Evaluation title</strong>
                                    </Label>
                                    <Input
                                        autoFocus
                                        type="text"
                                        id="evaluationTitle"
                                        placeholder="Evaluation title"
                                        cols="2"
                                        value={title}
                                        onChange={(e) => {
                                            setEvaluationTitle(e.target.value);
                                        }}
                                        required
                                    />
                                </Col>

                                <Col sm="6">

                                    <Label for="evaluationScore">
                                        <strong>Score</strong>
                                    </Label>
                                    <Input
                                        autoFocus
                                        type="text"
                                        id="evaluationScore"
                                        placeholder="Evaluation score"
                                        cols="2"
                                        value={score}
                                        onChange={(e) => {
                                            setEvaluationScore(e.target.value);
                                        }}
                                        required
                                    />
                                </Col>

                            </Row>

                            <Row>
                                <Col>

                                    <Label for="evaluationRemark">
                                        <strong>Remark</strong>
                                    </Label>
                                    <Input
                                        autoFocus
                                        type="textarea"
                                        id="evaluationRemark"
                                        placeholder="Evaluation remark"
                                        cols="2"
                                        value={remark}
                                        onChange={(e) => {
                                            setEvaluationRemark(e.target.value);
                                        }}
                                        required
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} className="text-center mt-2">

                                    <Button.Ripple
                                        type="submit"
                                        color="light"
                                        appearance="success"
                                        // disabled={isDisabled}
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    >
                                        Submit
                                    </Button.Ripple>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
        </Modal >
    )
}

export default EmployeePerformanceEvaluationModal