import gql from "graphql-tag";

export const GET_FEEDBACK_BY_ID = gql`
  query getFeedbackByReservationId($reservationId: String) {
    getFeedbackByReservationId(reservationId: $reservationId) {
      salutation
      reservationId
      firstName
      lastName
      hostelName
      checkinDate
      checkoutDate
      mobile
      email
      customerFeedback
      customerRemark
      feedbackStatus
      propertyRemark
      feedbackTime
      responseTime
      bookingSource
      bookingType
      finalRemark
      logs
      ticketEmailSent
    }
  }
`;
