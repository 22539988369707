import React, { useEffect, useState } from "react";
import { Table, Input, Col, Row } from "reactstrap";
import { useGetHostelWiseClosingBalance } from "../../../api/dataSource";
import { CSVLink } from "react-csv";
import Flatpickr from "react-flatpickr";

const HostelwiseClosingBalanceTable = () => {
  const [tableData, setTableData] = useState([]);
  const [assignedHostels, setAssignedHostels] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { data: hostelwiseClosingBalanceData } =
    useGetHostelWiseClosingBalance(selectedDate);

  useEffect(() => {
    const assignedHostelsFromStorage =
      JSON.parse(localStorage.getItem("hostels")) || [];
    setAssignedHostels(assignedHostelsFromStorage);
  }, []);

  useEffect(() => {
    if (hostelwiseClosingBalanceData?.getHostelWiseClosingBalance?.length > 0) {
      let filteredData =
        hostelwiseClosingBalanceData?.getHostelWiseClosingBalance;
      if (assignedHostels.length > 0) {
        filteredData = filteredData.filter((item) =>
          assignedHostels.includes(item.hostelId)
        );
      }
      if (searchQuery.trim() !== "") {
        const searchRegex = new RegExp(searchQuery.trim(), "i");
        filteredData = filteredData.filter((item) =>
          searchRegex.test(item.hostelName)
        );
      }
      setTableData(filteredData);
    } else {
      setTableData([]);
    }
  }, [hostelwiseClosingBalanceData, assignedHostels, searchQuery]);

  const totalClosingBalance = tableData.reduce(
    (total, item) => total + item.closingBalance,
    0
  );

  const exportClosingBalanceHeaders = [
    { label: "Hostel Name", key: "hostelName" },
    { label: "Closing Balance", key: "closingBalance" },
  ];

  const exportClosingBalanceData = [
    ...tableData.map((item) => ({
      hostelName: item.hostelName,
      closingBalance: item?.closingBalance.toFixed(0),
    })),
    { hostelName: "Total", closingBalance: totalClosingBalance.toFixed(0) },
  ];

  return (
    <div style={{ position: "relative" }}>
      <h2 style={{ textAlign: "start", marginBottom: "30px" }}>
        Live cash balance
      </h2>
      <Row>
        <Col sm="6">
          <Row>
            <Col>
              <div style={{ marginBottom: "10px" }}>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  value={selectedDate}
                  placeholder="Select Date"
                  onChange={(dates) => {
                    const selectedDateWithTime = new Date(dates[0]);
                    setSelectedDate(selectedDateWithTime);
                  }}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ marginBottom: "10px" }}>
                <Input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Hostel Name"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <CSVLink
        headers={exportClosingBalanceHeaders}
        data={exportClosingBalanceData}
        filename={"totalClosingBalance.csv"}
        style={{
          position: "absolute",
          top: "2px",
          right: "30px",
          borderRadius: "5px",
          padding: "5px 10px",
          fontSize: "14px",
          backgroundColor: "#28c76f",
          color: "white",
          textDecoration: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        Export
      </CSVLink>
      <Table bordered>
        <thead style={{ backgroundColor: "#f2f2f2" }}>
          <tr>
            <th style={{ textAlign: "start" }}>Hostel Name</th>
            <th style={{ textAlign: "start" }}>Closing Balance</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((item, index) => (
            <tr key={index}>
              <td style={{ textAlign: "start" }}>{item?.hostelName}</td>
              <td style={{ textAlign: "start" }}>
                {item?.closingBalance.toFixed(0)}
              </td>
            </tr>
          ))}
          <tr>
            <td style={{ textAlign: "start", fontWeight: "bold" }}>Total</td>
            <td style={{ textAlign: "start", fontWeight: "bold" }}>
              {totalClosingBalance.toFixed(0)}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default HostelwiseClosingBalanceTable;
