import { useEffect, useState } from "react";
import { Eye } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
  Label,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "@utils";

const tableHeaderData = [
  "Promo Code",
  "Usage Count",
  "No of Nights",
  "Amount Discounted",
  "Amount Charged",
];

const CouponReportTable = ({
  openModal,
  setId,
  data,
  refetch,
  totalAmountDiscounted,
  totalAmountCharged,
  totalDataLength,
}) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>Coupon Usage Report </h2>
              </div>
            </CardTitle>
            <Col style={{ marginRight: "5px" }}>
              <Label className="form-label">
                <h5>
                  
                  Total Coupons: <b>{totalDataLength}</b>
                </h5>
              </Label>
              <Label
                className="form-label"
                style={{ marginRight: "10px", marginLeft: "10px" }}
              >
                <h5>
                  Total Discounted: <b>{totalAmountDiscounted.toFixed(2)}</b>
                </h5>
              </Label>
              <Label className="form-label">
                <h5>
                  Total Charged: <b>{totalAmountCharged.toFixed(2)}</b>
                </h5>
              </Label>
            </Col>
          </CardHeader>
          <Table responsive hover bordered size="sm">
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <Badge color="light-info">
                      {item?._id ? item?._id : "--"}
                    </Badge>
                  </td>
                  <td>{item?.usageCount}</td>
                  <td>{item?.totalNoOfNights}</td>
                  <td>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      maximumFractionDigits: 2,
                    }).format(item?.totalAmountDiscounted)}
                  </td>
                  <td>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      maximumFractionDigits: 2,
                    }).format(item?.totalAmountCharged)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default CouponReportTable;
