import gql from "graphql-tag";

export const GET_WORKATION_PACKAGE_BY_ID = gql`
  query getWorkationPackageById($id: ID!) {
    getWorkationPackageById(id: $id) {
      _id
      name
      description {
        heading
        content
      }
      otherInfo {
        heading
        content
        icon
        key
      }
      images {
        image
        key
        type
      }
      policy {
        bookingPolicy
        hostelPolicy
        cancellationPolicy
      }
      workationFaqs {
        question
        answer
      }
      hostelId
      hostelPolicy {
        general
        pet
        covid
      }
      workationRecommendations {
        _id
      }
      deleted
      status
      reviews {
        userName
        dateTime
        avatar
        content
      }
      thingsToKnow {
        heading
        textlist
      }
      createdAt
    }
  }
`;

export const GET_ALL_WORKATION_PACKAGES = gql`
  query getAllWorkationPackages($filter: filter) {
    getAllWorkationPackages(filter: $filter) {
      data {
        _id
        name
        description {
          heading
          content
        }
        otherInfo {
          heading
          content
          icon
          key
        }
        images {
          image
          key
          type
        }
        policy {
          bookingPolicy
          hostelPolicy
          cancellationPolicy
        }
        workationFaqs {
          question
          answer
        }
        hostelId
        hostelPolicy {
          general
          pet
          covid
        }
        workationRecommendations {
          _id
        }
        deleted
        status
        reviews {
          userName
          dateTime
          avatar
          content
        }
        thingsToKnow {
          heading
          textlist
        }
        createdAt
      }
      count
      page
      limit
    }
  }
`;
