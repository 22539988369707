import gql from "graphql-tag";

export const CREATE_STEP_OUT_BOOKING = gql`
mutation createStepOutBooking($bookingInput: BookingInput) {
    createStepOutBooking(bookingInput: $bookingInput) {
        _id
        status
    }
}
`

export const UPDATE_STEP_OUT_BOOKING = gql`
mutation updateStepOutBooking($id: ID $bookingUpdate: BookingInput) {
    updateStepOutBooking(id: $id bookingUpdate: $bookingUpdate){
        _id
        status
    }
}
`