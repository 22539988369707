import gql from "graphql-tag";

export const GET_SERVICE_EVENTS_SALES_REPORT = gql`
  query getServiceEventsSalesReport($fromDate: String!, $toDate: String!) {
    getServiceEventsSalesReport(fromDate: $fromDate, toDate: $toDate) {
      _id
      hostel
      serviceCount
      serviceRev
      EACount
      EARev
      totalCount
      totalRev
      netRev
      aov
    }
  }
`;

export const GET_SERVICE_CATEGORY_WISE_REVENUE = gql`
  query getServiceCategoryWiseRevenue(
    $hostelId: [ID]
    $fromDate: String!
    $toDate: String!
  ) {
    getServiceCategoryWiseRevenue(
      hostelId: $hostelId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      category
      serviceCount
      serviceRev
    }
  }
`;

export const GET_SERVICE_TITLE_WISE_REVENUE = gql`
  query getServiceTitleWiseRevenue(
    $hostelId: [ID]
    $fromDate: String!
    $toDate: String!
    $category: [String]
  ) {
    getServiceTitleWiseRevenue(
      hostelId: $hostelId
      fromDate: $fromDate
      toDate: $toDate
      category: $category
    ) {
      title
      category
      serviceCount
      serviceRev
    }
  }
`;
