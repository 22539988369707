import React, { useEffect, useState } from "react";
import { Card, CardHeader, Col, Row, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const WebcheckinAnalysisTable = ({ tableData, months }) => {
  const [tableHeaderData, setTableHeaderData] = useState(["Data Point"]);
  const fractionToPercentage = (numerator, denominator) => {
    if (denominator == 0) {
      return "--";
    }
    var result = (numerator / denominator) * 100;
    return result.toFixed(2) + "%";
  };

  const topContribution = (stateList, totalDocuments) => {
    let total = 0;
    for (let i = 0; i < 10; i++) {
      if (stateList[i]) {
        total += stateList[i]?.count;
      }
    }
    const topContribution = fractionToPercentage(total, totalDocuments);
    return topContribution;
  };

  const restOfIndia = (residingState, totalDocuments) => {
    let topContributionNumber = topContribution(residingState, totalDocuments);
    let result = (100 - topContributionNumber.slice(0, -1)).toFixed(2);
    return result + "%";
  };

  useEffect(() => {
    setTableHeaderData(months);
  }, [months]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>Web Checkin Data Analysis</CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total Customers</td>
                {tableData?.map((item, index) => (
                  <td key={index}>{item?.totalDocuments}</td>
                ))}
              </tr>
              <tr>
                <td>Indian</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.residingCountries?.Indian,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Foreigner</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.residingCountries?.Foreigner,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Male</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.genders?.Male,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Female</td>
                {/* <td>{fractionToPercentage(genders?.Female, tableData?.totalDocuments)}</td> */}
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.genders?.Female,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Others</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.genders?.Other,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Returning Customer</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.stayedWithHosteller?.stayedWithHosteller,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Business Professional</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.BusinessProfessional,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Working Professional</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.WorkingProfessional,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Student</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.Student,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Freelancer</td>

                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.FreeLancer,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Not Working/Not Studying</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.whatWouldBestDescribeYou?.Other,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Solo</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.Solo,
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Couple</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.["2"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>3-4 px group</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.["3-4"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>5-6 px group</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.["5-6"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>6+ group</td>
                {tableData?.map((item, index) => (
                  <td key={index}>
                    {fractionToPercentage(
                      item?.groupSize?.["6+"],
                      item?.totalDocuments
                    )}
                  </td>
                ))}
              </tr>

              {[...Array(tableData[0].residingState?.length).keys()].map(
                (i) => (
                  <tr key={i}>
                    <td>{tableData[0].residingState[i]?._id}</td>
                    {tableData?.map((tableItem, index) => (
                      <td key={index}>
                        {tableItem.residingState.map((residingItem) => {
                          if (
                            residingItem._id ===
                            tableData[0].residingState[i]?._id
                          ) {
                            return fractionToPercentage(
                              residingItem.count,
                              tableItem?.totalDocuments
                            );
                          }
                        })}
                      </td>
                    ))}
                  </tr>
                )
              )}

              {tableData && tableData[0]?.residingState?.length > 10 && (
                <tr>
                  <td>Top 10 states contribution</td>
                  {tableData.map((item, index) => (
                    <td key={index}>
                      {topContribution(
                        item?.residingState,
                        item?.totalDocuments
                      )}
                    </td>
                  ))}
                </tr>
              )}

              {tableData && tableData[0]?.residingState?.length > 10 && (
                <tr>
                  <td>Rest of India</td>
                  {tableData?.map((item, index) => (
                    <td key={index}>
                      {restOfIndia(item?.residingState, item?.totalDocuments)}
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default WebcheckinAnalysisTable;
