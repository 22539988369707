import React, { useEffect, useState } from "react";
import AddEditModal from "./AddEditModal";
import LoyaltyCoinsTable from "./LoyaltyCoinsTable";
import TablePagination from "@components/common/TablePagination";
import { useHistory } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetPaginatedLoyaltyCoins } from "../../../api/dataSource";
import { Col, Row, Input, FormGroup, Label, Button, Form } from "reactstrap";

const LoyaltyCoins = () => {
  const [email, setEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [filteredData, setFilteredData] = useState({});

  const { data, refetch } = useGetPaginatedLoyaltyCoins(
    limit,
    page,
    filteredData
  );

  const history = useHistory();

  const count = data?.getPaginatedLoyaltyCoins?.count;

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedLoyaltyCoins?.data.length > 0) {
      setTableData(data?.getPaginatedLoyaltyCoins?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedLoyaltyCoins?.count / limit));
  }, [data?.getPaginatedLoyaltyCoins?.data, limit, count]);

  useEffect(() => {
    if (id && data?.getPaginatedLoyaltyCoins?.data.length) {
      let editData = data?.getPaginatedLoyaltyCoins?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedLoyaltyCoins?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/loyalty/edit/loyaltyCoins/${id}`);
    } else {
      setId(id);
    }
  };

  const handleSearch = () => {
    // e.preventDefault();
    const data = {
      email,
    };

    setFilteredData(data);
    setPage(1);
  };

  const handleReset = () => {
    setEmail("");
    setFilteredData({});
    setPage(1);
  };

  return (
    localStorage.getItem("modules")?.includes("Loyalty") && (
      <div>
        {/* <Form onSubmit={handleSubmit}> */}
        <Row>
          <Col sm="8">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="email">
                  Guest Email
                </Label>
              </strong>
              <Input
                type="text"
                value={email}
                id="email"
                name="email"
                // placeholder="Add user email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* <Col sm="1">
            <Button.Ripple
              color="light"
              type="submit"
              style={{ marginTop: "21px" }}
            >
              Search
            </Button.Ripple>
          </Col> */}

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>

            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        {/* </Form> */}

        <LoyaltyCoinsTable
          setId={setId}
          id={id}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          count={count}
          handleEdit={handleEdit}
          refetch={refetch}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
          setId={setId}
        />
      </div>
    )
  );
};

export default LoyaltyCoins;
