// ! Added by Ashwin

import gql from "graphql-tag";

export const GET_REQUEST_ID = gql`
  query {
    getRequestById(requestId: $requestId) {
      id
      requestCategory
      requestName
      requestDescription
      requestIcon
      tat
      maxAllowedRequest
    }
  }
`;

export const GET_PAGINATED_REQUESTS_LIST = gql`
  query getPaginatedRequestList($filter: filter) {
    getPaginatedRequestList(filter: $filter) {
      data {
        _id
        requestCategory
        requestName
        requestDescription
        requestIcon
        tat
        maxAllowedRequest
        logs
      }
      count
      limit
      page
    }
  }
`;
export const GET_REQUEST_HOSTEL_ID = gql`
  query getRequestByHostelId($hostelId: ID) {
    getRequestByHostelId(hostelId: $hostelId) {
      _id
      requestCategory
      requestName
      requestDescription
      requestIcon
      tat
      isActive
      fromTime
      toTime
      tat
    }
  }
`;

export const GET_REQUEST_NAME_LIST = gql`
  query getRequestNameListByRequestCategory($requestCategory: String) {
    getRequestNameListByRequestCategory(requestCategory: $requestCategory) {
      _id
      requestCategory
      requestName
    }
  }
`;
