// Added by Nitish

import gql from "graphql-tag";

export const UPDATE_EVENT_STATUS_OR_REMARK = gql`
  mutation updateEventStatusOrRemark(
    $id: ID
    $UpdateEventInput: UpdateEventInput
  ) {
    updateEventStatusOrRemark(id: $id, UpdateEventInput: $UpdateEventInput) {
      _id
      reservationId
      quantity
      amount
      tax
      totalAmount
      paymentStatus
      razorpayOrderId
      razorpayPaymentId
      eventDate
      bookingId
      bookingDate
      cancellationDate
      cancellationRemark
      cancellationAmount
      cancelledBy
      refundId
      refundDate
      refundStatus
      invoiceNo
      status
      remark
      logs
    }
  }
`;
