import gql from "graphql-tag";

export const GET_ABOUT = gql`
  query getAbout {
    getAbout {
      _id
      banner
      product
      experience
      team
      story
      featured
      career
    }
  }
`;
