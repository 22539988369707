// ! Added by Ashwin

import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import ImageSection from "./ImageSection";
import { useUpdateWorkationLandingPageDetails } from "../../../../api/dataSource";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { WORKATION_LANDING_PAGE } from "../../../../utility/constant";
import { useParams } from "react-router-dom";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadType = [
  { value: "mornings", label: "Mornings" },
  { value: "work", label: "Work" },
  { value: "afterWork", label: "AfterWork" },
  { value: "weekend", label: "Weekend" },
];

const Image = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);
  const [
    updateWorkationLandingPageDetails,
    { data: updatedWorkationLandingPageData },
  ] = useUpdateWorkationLandingPageDetails();
  const dispatch = useDispatch();

  useEffect(() => {
    if (imageList.length === 0) {
      state?.tableData &&
        state.tableData.images?.length &&
        setImageList(state.tableData.images);
    }
  }, [imageList.length, state?.tableData?.images]);

  useEffect(() => {
    let arrProcessedImages = [];
    imageList.forEach((i) => {
      let imageObj = {
        displayOrder: i.displayOrder,
        image: i.image,
        imageCaption: i.imageCaption,
        imageType: i.imageType,
        key: i.key,
        type: i.type,
      };
      arrProcessedImages.push(imageObj);
    });

    setSortedImages(
      arrProcessedImages.sort((a, b) => a.displayOrder - b.displayOrder)
    );
  }, [imageList]);

  useEffect(() => {
    if (updatedWorkationLandingPageData?.updateWorkationLandingPage) {
      // toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          images: imageList,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatedWorkationLandingPageData?.updateWorkationLandingPage]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];

    imageList.map((image) => {
      image.image &&
        images.push({
          type: image.type,
          image: image.image,
          key: image.key,
          imageCaption: image?.imageCaption,
          displayOrder: image?.displayOrder,
          imageType: image?.imageType,
        });
    });

    updateWorkationLandingPageDetails(WORKATION_LANDING_PAGE, {
      images: images,
    }); // "hosteller" is used to get landing page doc
    toaster("success", "Images updated successfully");
  };

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={sortedImages}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default Image;
