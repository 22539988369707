// ! Added by Ashwin

import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import BannerImageSection from "./BannerImageSection";
import { useUpdateEventsAndActivities } from "../../../api/dataSource";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadType = [{ value: "bannerImages ", label: "Banner" }];

const BannerImages = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [updateEventsAndActivitiesHandler, { data, reset }] =
    useUpdateEventsAndActivities();
  const dispatch = useDispatch();

  useEffect(() => {
    state?.tableData &&
      state.tableData.bannerImages?.length &&
      setImageList(state.tableData.bannerImages);
  }, [state?.tableData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const bannerImages = [];

    imageList.map((image) => {
      image.image &&
        bannerImages.push({
          type: image.type,
          image: image.image,
          key: image.key,
        });
    });

    updateEventsAndActivitiesHandler(id, { bannerImages: bannerImages }); // "hosteller" is used to get landing page doc
    toaster("success", "Banner image updated successfully");
    dispatch(
      tableDataHandler({
        ...state?.tableData,
        bannerImages,
      })
    );
  };

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <BannerImageSection
            imageList={imageList}
            setImageList={setImageList}
            uploadType={UploadType}
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default BannerImages;
