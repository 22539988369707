import React, { useEffect, useState } from "react";
import WebcheckinAnalysisTable from "./WebcheckinAnalysisTable";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { dateValidator } from "../../../utility/Utils";
import { selectThemeColors } from "@utils";
import {
  useGetWebcheckinAnalysisReport,
  useHostelList,
} from "../../../api/dataSource";
import Select from "react-select";
import toaster from "@components/common/Toaster";

const WebcheckinAnalysis = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [HostelsList, setHostelsList] = useState("");
  const [hostel, setHostel] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [monthNames, setMonthNames] = useState(null)
  const { data: allHostelsData } = useHostelList();
  const [format, setFormat] = useState("range")
  const [
    webCheckReportAnalysisHandler,
    { data: analysisReport, loading, refetch },
  ] = useGetWebcheckinAnalysisReport();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formatOptions = [
    {label:"Monthly", value:"monthly"},
    {label:"Range", value:"range"}
  ]


  const handleSearch = () => {
    if(fromDate && toDate){

      if (dateValidator(fromDate, toDate)) {
        const selectedHostelList = hostel?.map((item) => item?.value);
        const reportInput = {
          fromDate,
          toDate,
          hostel: selectedHostelList,
          format:format?.value
        };
        webCheckReportAnalysisHandler(reportInput);
        generateMonths()
      } else {
        toaster("error", "Invalid dates");
      }
    }
  };
  
  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (analysisReport) {
      setTableData(analysisReport.webCheckinDataAnalysisReport);
    }
  }, [analysisReport]);

  const generateMonths = () => {
    // clearing the previous month state
    if(format?.value == "monthly"){
      const monthNames = ["Data Points"]
      const newFromDate = new Date(fromDate)
      const newToDate = new Date(toDate)
      const startMonth = newFromDate.getMonth();
      
      const yearDiff = (newToDate.getFullYear() - newFromDate.getFullYear()) * 12;
      const monthDiff= yearDiff + newToDate.getMonth() - newFromDate.getMonth();
      for(let i = 0; i<= monthDiff; i++){
        const index = (i+startMonth) % 12; 
        monthNames.push(months[index]);
      }
      setMonthNames(monthNames)
    }else {
      const monthNames = ["Data Points", "Percentage"]
      setMonthNames(monthNames)
    }
}

  return (
    <div>
      <Row>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              From Date *
            </Label>

            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromDate}
              onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
              id="fromDate"
              placeholder="From Date"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="toDate">
              To Date *
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toDate}
              onChange={(date) => setToDate(dateFormatter(new Date(date)))}
              id="toDate"
              placeholder="To Date"
            />
          </FormGroup>
        </Col>
        <Col sm="4">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              Hostel
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              options={HostelsList}
              onChange={(value) => {
                setHostel(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              Report format
            </Label>
            <Select
              isClearable={true}
              isSearchable={false}
              theme={selectThemeColors}
              id="hostel"
              name="hostel"
              value={format}
              options={formatOptions}
              onChange={(value) => {
                setFormat(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col style={{ marginTop: "22px" }}>
          <Button.Ripple
            onClick={handleSearch}
            className="ml-2"
            color="primary"
          >
            Search
          </Button.Ripple>
          {/* <Button.Ripple
              // onClick={handleResetData}
              className="ml-2"
              color="danger"
            >
              Reset
            </Button.Ripple> */}

          {/* <Button.Ripple
              className="btn-icon ml-1"
              color="success"
              appearance="success"
              type="button"
              onClick={()=>{getExportDataHandler(searchFromDate, searchToDate)}}
            >
              Export
            </Button.Ripple> */}
        </Col>
      </Row>
      {tableData?.length > 0 && monthNames.length ? (
        <WebcheckinAnalysisTable tableData={tableData} months={monthNames} />
      ) : (
        <h2 style={{textAlign:"center"}}>No data present</h2>
      )}
    </div>
  );
};

export default WebcheckinAnalysis;
