import React, { useState } from "react";
import HostelsTable from "../../../@core/components/tables/HostelsTable";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";

const Hostel = () => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState(null);
  const [isWorkation, setIsWorkation] = useState(null);
  const [activateGlu, setActivateGlu] = useState(null);
  const [businessType, setBusinessType] = useState(null);
  const [filter, setFilter] = useState(null);
  const [page, setPage] = useState(1);

  const statusType = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const isWorkationType = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const activateGluType = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const businessTypeList = [
    { label: "COCO", value: "COCO" },
    { label: "FOFO", value: "FOFO" },
    { label: "FOCO", value: "FOCO" },
    { label: "OMC", value: "OMC" },
  ];

  const handleSearch = () => {
    const data = {
      name,
      hostelStatus: status?.value,
      isWorkation: isWorkation?.value,
      activateGlu: activateGlu?.value,
      businessType: businessType?.map((item) => item?.value),
    };
    setFilter(data);
    setPage(1);
  };

  const handleReset = () => {
    setName("");
    setStatus(null);
    setIsWorkation(null);
    setActivateGlu(null);
    setBusinessType(null);
    setFilter(null);
    setPage(1);
  };

  return (
    localStorage.getItem("modules")?.includes("CMS") && (
      <>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="name">
                  Hostel Name
                </Label>
              </strong>
              <Input
                type="text"
                value={name}
                id="name"
                name="name"
                placeholder="Hostel Name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="status">
                  Hostel Status
                </Label>
              </strong>
              <Select
                isSearchable={true}
                isClearable={false}
                id="status"
                name="status"
                value={status}
                options={statusType}
                onChange={(value) => setStatus(value)}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="isWorkation">
                  Workation Status
                </Label>
              </strong>
              <Select
                isSearchable={true}
                isClearable={false}
                id="isWorkation"
                name="isWorkation"
                value={isWorkation}
                options={isWorkationType}
                onChange={(value) => setIsWorkation(value)}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="activateGlu">
                  Glu Status
                </Label>
              </strong>
              <Select
                isSearchable={true}
                isClearable={false}
                id="activateGlu"
                name="activateGlu"
                value={activateGlu}
                options={activateGluType}
                onChange={(value) => setActivateGlu(value)}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="businessType">
                  Business Type
                </Label>
              </strong>
              <Select
                isSearchable={true}
                id="businessType"
                name="businessType"
                isMulti
                theme={selectThemeColors}
                value={businessType}
                options={businessTypeList}
                onChange={(value) => setBusinessType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <HostelsTable filter={filter} page={page} setPage={setPage} />
      </>
    )
  );
};

export default Hostel;
