import { Layout, Circle } from "react-feather";

// const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

export default // role === "Listing" ||
// role === "Marketing" ||
// role === "Super Admin" ||
// role === "Sales Admin" ||
// role === "Marketing Admin" ||
// role === "Customer Experience" ||
modules?.includes("CMS")
  ? [
      {
        id: "cms",
        title: "CMS",
        icon: <Layout />,
        badge: "light-warning",
        children: [
          // {
          //   id: "cmsLanding",
          //   title: "Landing",
          //   icon: <Circle size={12} />,
          //   navLink: "/cms/landing",
          // },
          {
            id: "cmsHomePage", // ! Added  by Ashwin
            title: "Home / Landing Page",
            icon: <Circle size={12} />,
            navLink: "/cms/homepage",
          },
          {
            id: "cmsDestinations",
            title: "Destinations",
            icon: <Circle size={12} />,
            navLink: "/cms/destinations",
          },
          {
            id: "cmsHostels",
            title: "Hostels",
            icon: <Circle size={12} />,
            navLink: "/cms/hostels",
          },
          {
            id: "cmsWorkationLandingPage",
            title: "Workation Landing Page",
            icon: <Circle size={12} />,
            navLink: "/cms/workationLandingPage",
          },
          // {
          //   id: "cmsWorkations",
          //   title: "Workations",
          //   icon: <Circle size={12} />,
          //   navLink: "/cms/workations",
          // },
          // {
          //   id: "cmsTrips",
          //   title: "Trips",
          //   icon: <Circle size={12} />,
          //   navLink: "/cms/trips",
          // },
          {
            id: "seoModules",
            title: "SEO",
            icon: <Circle size={12} />,
            navLink: "/seo/modules",
          },
          {
            id: "cmsPolicies",
            title: "Policies",
            icon: <Circle size={12} />,
            navLink: "/cms/policies",
          },
          // {
          //   id: "cmsPartner",
          //   title: "Partner",
          //   icon: <Circle size={12} />,
          //   navLink: "/cms/partner",
          // },
          {
            id: "cmsAbout",
            title: "About",
            icon: <Circle size={12} />,
            navLink: "/cms/about",
          },
          {
            id: "cmsInTheSpotLight",
            title: "In The Spot Light",
            icon: <Circle size={12} />,
            navLink: "/cms/inTheSpotLight",
          },
          {
            id: "cmsPressMention",
            title: "Press Mention",
            icon: <Circle size={12} />,
            navLink: "/cms/pressMention",
          },
          {
            id: "cmsMedia", // ! Added  by Ashwin
            title: "Media",
            icon: <Circle size={12} />,
            navLink: "/cms/media",
          },
          {
            id: "amenities", // ! Added  by Ashwin
            title: "Amenities",
            icon: <Circle size={12} />,
            navLink: "/amenities",
          },
          // {
          //   id: "offers", // ! Added  by Ashwin
          //   title: "Offers",
          //   icon: <Circle size={12} />,
          //   navLink: "/offers",
          // },
          {
            id: "partnerNew", // ! Added  by Ashwin
            title: "Partner New",
            icon: <Circle size={12} />,
            navLink: "/partnerNew",
          },
          {
            id: "cmsDisplayAds", // ! Added  by Ashwin
            title: "Display Ads",
            icon: <Circle size={12} />,
            navLink: "/cms/displayAds",
          },
          {
            id: "stripConfig", // ! Added  by Ashwin
            title: "Strip Config",
            icon: <Circle size={12} />,
            navLink: "/cms/stripconfig",
          },

        ],
      },
    ]
  : [];
