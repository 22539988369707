import React, { useEffect, useState } from "react";
import { Trash, Camera } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHostel, useUploadImage } from "../../../../api/dataSource";
import { useParams } from "react-router-dom";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";
import { tableDataHandler } from "@store/Cms/cms.action";
import Avatar from "@components/common/avatar";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const OverviewForm = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const [heading, setHeading] = useState("");
  const [index, setIndex] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [highlight, setHighlight] = useState([
    {
      id: uuidv4(),
      heading: "",
      content: "",
      avatarIcon: "",
    },
  ]);
  const [updateHostelHandler, { data: updatedHostelData }] = useUpdateHostel();
  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  useEffect(() => {
    if (state?.tableData) {
      setHeading(state?.tableData?.description?.heading);
      if (state?.tableData?.description?.content) {
        setContent(htmlToDraftConvert(state?.tableData?.description?.content));
      }

      if (state?.tableData?.shortDesc) {
        setShortDesc(state?.tableData?.shortDesc);
      }
    }
  }, [state]);

  useEffect(() => {
    if (updatedHostelData?.updateHostel?.name) {
      toaster("success", "Overview updated successfully");
    }
  }, [updatedHostelData?.updateHostel]);

  useEffect(() => {
    if (state?.tableData?.otherInfo?.length) {
      let updatedHighlight = state?.tableData?.otherInfo?.map((item) => {
        return {
          id: uuidv4(),
          heading: item?.heading,
          content: item?.content,
          avatarIcon: item?.avatarIcon,
        };
      });
      setHighlight(updatedHighlight);
    }
  }, [state]);

  const handleChangeHighlight = (heading, id) => {
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, heading: heading } : item
      )
    );
  };

  const handleChangeHighlightContent = (content, id) => {
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, content: content } : item
      )
    );
  };

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setHighlight(
        highlight.map((item) => {
          if (item.id === index) {
            return { ...item, avatarIcon: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  const handleRemoveHighlight = (id) =>
    confirmDelete(() =>
      setHighlight(highlight.filter((item) => item.id !== id))
    );

  const handleAddHighlight = () =>
    setHighlight([
      ...highlight,
      { id: uuidv4(), heading: "", content: "", avatarIcon: "" },
    ]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    let emptyContent = highlight.filter(
      (i) => i.heading === "" && i.content === ""
    );
    if (emptyContent.length > 0) {
      toaster("error", "Please fill the details");
    } else {
      highlight.forEach((item) => {
        delete item.id;
      });

      let data = {
        description: {
          heading: heading,
          content: inputDataConvertor(content),
        },
        otherInfo: highlight,
        shortDesc: shortDesc,
      };
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          description: data?.description,
          otherInfo: data?.otherInfo,
          shortDesc: data?.shortDesc,
        })
      );
      updateHostelHandler(id, data);
      toaster("success", "Overview updated successfully");
    }
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }

    uploadImageHandler(
      e.target.files[0],
      `${state.tableData?.name?.toLowerCase()?.replaceAll(" ", "-")}/HOSTEL}`
    );

    toaster("success", "Overview image uploaded successfully");
  };

  return (
    <>
      <form onSubmit={onSubmitHandler}>
        <Row className="mt-2">
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="shortDesc">
                Short Description
              </Label>

              <Input
                rows={4}
                type="textarea"
                value={shortDesc}
                id="shortDesc"
                name="shortDesc"
                placeholder="Short Description"
                onChange={(e) => setShortDesc(e.target.value, id)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label className="form-label" htmlFor="destination">
                Content
              </Label>
              <div className="mt-1 ">
                <MyEditor
                  editorState={content}
                  setEditorState={(val) => setContent(val)}
                />
              </div>
            </FormGroup>
          </Col>
          <br />
          <h4 className="form-label ml-2 mt-2" htmlFor={"highlight" + id}>
            Why you'll love it here
          </h4>
          {highlight.map(({ heading, content, id, avatarIcon }, index) => (
            <Col className="d-flex align-items-end" sm="12" key={index}>
              <FormGroup className="w-100">
                <div className="mt-2 d-flex align-items-center">
                  <div className="mb-1 mr-2">
                    {avatarIcon ? <Avatar img={avatarIcon} alt="img" /> : ""}
                  </div>
                  <label htmlFor={`myFile${id}`}>
                    <p className="btn btn-primary">
                      <Camera size={15} />
                    </p>
                    <input
                      name={`myFile${id}`}
                      id={`myFile${id}`}
                      className="d-none"
                      type="file"
                      key={"file" + id}
                      onChange={(e) => fileUploader(e, id)}
                    />
                  </label>
                </div>
                <br />
                <div className="mb-2">
                  <Input
                    type="text"
                    value={heading}
                    id={"name" + id}
                    name={"name" + id}
                    placeholder="Add Title"
                    key={"name" + id}
                    onChange={(e) => handleChangeHighlight(e.target.value, id)}
                  />
                </div>
                <Input
                  rows={4}
                  type="textarea"
                  value={content}
                  id={"highlight" + id}
                  name={"highlight" + id}
                  placeholder="Add highlight"
                  key={"highlight" + id}
                  onChange={(e) =>
                    handleChangeHighlightContent(e.target.value, id)
                  }
                />
              </FormGroup>
              {highlight.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    key={"content" + id}
                    onClick={() => handleRemoveHighlight(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHighlight} />
      </form>
    </>
  );
};

export default OverviewForm;
