import React, { useEffect, useState } from "react";
import SlideModal from "../../../@core/components/common/slide-modal/index";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  useCreateOthersSeo,
  useUpdateOthersSeo,
} from "../../../api/OthersSeo/Mutation";
import { DragDrop } from "@uppy/react";
import toaster from "@components/common/Toaster";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { useUploadImage } from "../../../api/dataSource";

const OtherAddEditModal = ({
  open,
  handleToggle,
  refetch,
  editData,
  optionList,
}) => {
  const [pageTitle, setPageTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [previewArr, setPreviewArr] = useState([]);

  const [CreateOthersSeoHandler, { data, refetch: createRefetch }] =
    useCreateOthersSeo();
  const [
    updateOthersSeoHandler,
    { data: updateSeoData, refetch: updateRefetch },
  ] = useUpdateOthersSeo();

  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();

  const pageTitleOption = [
    { label: "Hostels", value: "Hostels" },
    { label: "Workation", value: "Workation" },
    { label: "Destinations", value: "Destinations" },
    { label: "Commune", value: "Commune" },
    { label: "Invest", value: "Invest" },
    { label: "Blogs", value: "Blogs" },
    { label: "Volunteer", value: "Volunteer" },
    { label: "Policies", value: "Policies" },
    { label: "Contact Us", value: "Contact Us" },
    { label: "About us", value: "About us" },
  ];
  const submitDataHandler = () => {
    if (metaTitle && metaDesc && keywords && pageTitle) {
      const data = {
        metaDescription: metaDesc,
        metaTitle,
        metaImage: imageData?.uploadImage?.url,
        keywords,
        pageTitle: pageTitle?.value,
      };
      if (editData?._id) {
        updateOthersSeoHandler(editData?._id, data);
      } else {
        CreateOthersSeoHandler(data);
      }
      setPageTitle("");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setMetaImage("");
      setPreviewArr([]);
      handleToggle();
    } else {
      toaster("error", "fill all required fields");
    }
  };

  useEffect(() => {
    if (editData?._id) {
      setPageTitle({ label: editData?.pageTitle, value: editData?.pageTitle });
      setMetaTitle(editData?.metaTitle);
      setMetaDesc(editData?.metaDescription);
      setKeywords(editData?.keywords);
      setMetaImage(editData?.metaImage);
      setPreviewArr([editData?.metaImage]);
    } else {
      setPageTitle("");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setMetaImage("");
      setPreviewArr([]);
    }
  }, [editData]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppy.use(thumbnailGenerator);
  uppy.on("thumbnail:generated", (file, preview) => {
    setPreviewArr([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "Others/Seo");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading meta image: ${error.message}`);
  });

  const renderPreview = () => {
    if (previewArr.length) {
      return previewArr.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (data?.createOthersSeo) {
      toaster("success", "Seo created successfully");
      refetch();
    }
  }, [data]);

  useEffect(() => {
    if (updateSeoData?.updateOthersSeo) {
      toaster("success", "Seo updated successfully");
      refetch();
    }
  }, [updateSeoData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => submitDataHandler()}
    >
      <form>
        <Row>
          <Col>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="product">
                    Page Title*
                  </Label>
                  <Select
                    isClearable={false}
                    id="template"
                    name="template"
                    value={pageTitle}
                    options={pageTitleOption}
                    onChange={(item) => {
                      setPageTitle(item);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Title* (ideal size 50 characters)
                </Label>
                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add Meta Title"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
                <div style={{ textAlign: "right" }}>
                  <Label>Count: {metaTitle?.length}</Label>
                </div>
              </Col>
              <br />

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Description* (ideal size 150 characters)
                </Label>
                <Input
                  type="text"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add Meta Desc"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
                <div style={{ textAlign: "right" }}>
                  <Label>Count: {metaDesc?.length}</Label>
                </div>
              </Col>
              <br />

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Keywords*
                </Label>
                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add Keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </Col>

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Image
                </Label>
                <DragDrop
                  note="Image size should be less than 500 KB"
                  uppy={uppy}
                />
                {renderPreview()}
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default OtherAddEditModal;
