import LoyaltyCoins from "../../views/Loyalty/LoyaltyCoins";

const Loyalty = [
  {
    path: "/loyalty/loyaltyCoins",
    component: LoyaltyCoins,
    exact: true,
  },
];

export default Loyalty;
