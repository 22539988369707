import { ChevronLeft } from "react-feather";
import { Link } from "react-router-dom";
import { Col, CardTitle, CardText, Button } from "reactstrap";
import "@styles/base/pages/page-auth.scss";
import ForgotPasswordIcon from "../../../../.././assets/images/quoality/icons/forgot-password.svg";

const AuthSuccess = ({ title, description, btnText }) => {
  return (
    <Col
      className='d-flex align-items-center auth-bg px-2 p-lg-5'
      lg='4'
      sm='12'
    >
      <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
        <div className='text-center mb-2'>
          <img src={ForgotPasswordIcon} alt='forgot password' />
        </div>
        <CardTitle tag='h2' className='text-center font-weight-bold mb-1'>
          {title}
        </CardTitle>
        <CardText className='mb-2 text-center '>{description}</CardText>

        <Button.Ripple type='submit' color='light' block>
          {btnText}
        </Button.Ripple>
        <p className='text-center mt-2'>
          <Link to='/login'>
            <ChevronLeft className='mr-25' size={14} />
            <span className='align-middle'>Back to login</span>
          </Link>
        </p>
      </Col>
    </Col>
  );
};

export default AuthSuccess;
