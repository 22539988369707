import EzeeMeals from "../../views/Ezee/EzeeMeals/index";

const EzeeRoutes = [
  {
    path: "/ezee/ezeeMeals",
    component: EzeeMeals,
    exact: true,
  },
];

export default EzeeRoutes;
