// ! Added by Ashwin

import React, { useState, useEffect } from "react";
import CouponsGeneratorTable from "./CouponsGeneratorTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "../../@core/components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetAllPaginatedCouponsGenerator } from "../../api/dataSource";

const CouponsGenerator = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { data, refetch } = useGetAllPaginatedCouponsGenerator(limit, page); 

  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (data?.getPaginatedCouponsGeneratorList?.data) {
      setTableData(data?.getPaginatedCouponsGeneratorList?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(
      Math.ceil(data?.getPaginatedCouponsGeneratorList?.count / limit)
    );
  }, [
    data?.getPaginatedCouponsGeneratorList?.count,
    data?.getPaginatedCouponsGeneratorList?.data,
    limit,
  ]);

  useEffect(() => {
    if (data?.getPaginatedCouponsGeneratorList?.data.length) {
      let editData = data?.getPaginatedCouponsGeneratorList?.data?.find(
        (item) => item._id === id
      );
      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedCouponsGeneratorList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  return (
    JSON.parse(localStorage.getItem("modules")).includes("Coupon Admin") &&
    <div>
      <CouponsGeneratorTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
     </div>
  );
};

export default CouponsGenerator;
