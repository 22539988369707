import gql from "graphql-tag";

export const GET_EVENTS_AND_ACTIVITIES_LISt = gql`
  query getPaginatedEventsAndActivitiesList($filter: filter) {
    getPaginatedEventsAndActivitiesList(filter: $filter) {
      data {
        _id
        title
        hostel {
          _id
          name
        }
        category
        schedulingFrequency
        eventDate
        eventStartDate
        eventEndDate
        weekDays
        months
        time
        duration
        eventType
        organiser
        description
        price
        tax
        totalInventory
        published
        overview
        whyAttend
        aboutOrganiser
        policies
        bannerImages {
          image
          key
          type
        }
        inclusionsIcons {
          imagePath
          imageTitle
          displayOrder
        }
        cutOffTime
        fromTime
        toTime
        hsnCode
        logs
      }
      count
      page
      limit
    }
  }
`;

export const GET_EVENTS_AND_ACTIVITIES_BY_ID = gql`
  query getEventsAndActivitiesById($eventsAndActivitiesId: ID) {
    getEventsAndActivitiesById(eventsAndActivitiesId: $eventsAndActivitiesId) {
      _id
      title
      hostel {
        _id
        name
      }
      category
      schedulingFrequency
      eventDate
      eventStartDate
      eventEndDate
      weekDays
      months
      time
      duration
      eventType
      organiser
      description
      price
      tax
      totalInventory
      published
      overview
      whyAttend
      aboutOrganiser
      policies
      bannerImages {
        image
        key
        type
      }
      inclusionsIcons {
        imagePath
        imageTitle
        displayOrder
      }
      cutOffTime
      fromTime
      toTime
      hsnCode
    }
  }
`;
