import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { X, Link, Upload } from "react-feather";
import { useUploadImage } from "@api";
import confirmDelete from "@components/common/confirmDelete";
import "../../WebApp/UserRequest/StaffImagesSection/index.scss";
import { Col, Row } from "reactstrap";

const AttachmentComponent = ({
  imageList,
  setImageList,
  editData,
  uploadType,
  landingPage,
  userRole,
  userDepartment,
}) => {
  const [imageLink, setImageLink] = useState([]);
  const [uploadingType, setUploadingType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadImageHandler, { data: imageData, reset }] = useUploadImage();

  // Initialize Uppy instance
  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".pdf"],
      maxFileSize: 8000000,
    },
  });

  uppy.use(thumbnailGenerator);

  // Handle file upload completion
  uppy.on("complete", (result) => {
    setUploadingType(result?.successful[0]?.source, editData);
    result.successful[0]?.data &&
      uploadImageHandler(result?.successful[0]?.data, `Events_And_Activities`);
  });

  useEffect(() => {
    const newImage = {};
    const imageUrl = imageData?.uploadImage?.url;

    if (uploadType) {
      newImage.imageURL = imageUrl;
    } else {
      newImage.url = imageUrl;
    }

    if (imageUrl) {
      setImageList((prev) =>
        Array.isArray(prev) ? [newImage, ...prev] : [newImage]
      );
      reset();
      setUploadingType(null);
    }
  }, [imageData]);

  const removeImages = (image) => {
    const newSet = Array.isArray(imageList)
      ? imageList.filter((item) => {
          const imgUrl = item?.imageURL ? item?.imageURL : item?.url;
          return imgUrl !== image;
        })
      : []; // Ensure imageList is handled correctly
    setImageList(newSet);
  };

  const handleRemoveImage = (image) => {
    confirmDelete(() => removeImages(image));
  };

  const openModal = (index, link) => {
    setShowModal(true);
    setImageLink({ index, link });
  };

  const closeModal = () => {
    setShowModal(false);
    setImageLink({});
  };

  const addImageLink = (index, link) => {
    let newImageList = Array.isArray(imageList) ? [...imageList] : [];
    const imgWithLink = { ...newImageList[index], link };
    newImageList[index] = imgWithLink;
    setImageList(newImageList);
  };

  const handleUploadClick = () => {
    document.querySelector(`#file-input-${uploadType[0].value}`).click();
  };

  return (
    <Row>
      <Col>
        <div className="imageSection__container">
          {uploadType?.map(({ value, label }) => (
            <div key={value}>
              <div className="imageSection__imagesContainer">
                {Array.isArray(imageList) &&
                  imageList?.map((item, index) => {
                    const imageURL = item?.imageURL || item?.url;
                    return (
                      <div
                        key={index}
                        className=""
                        style={{
                          width: "6em",
                          height: "6em",
                          position: "relative",
                          margin: "0.5em",
                        }}
                      >
                        {imageURL?.includes(".pdf") ? (
                          <img
                            className="imageSection__image"
                            src={
                              "https://static-dev.thehosteller.com/GO_PURCHASE_REQUEST/pdfImage-1720680601586.png"
                            }
                            alt="Attachment"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() => window.open(imageURL, "_blank")}
                          />
                        ) : (
                          <img
                            className="imageSection__image"
                            src={imageURL}
                            alt="avatar"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() => window.open(imageURL, "_blank")}
                          />
                        )}
                        {(userRole === "Ground Staff" ||
                          userDepartment === "Finance" ||
                          "Customer Experience") && (
                          <div
                            onClick={() => handleRemoveImage(imageURL)}
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                              backgroundColor: "#ffffff",
                              padding: "1px",
                              cursor: "pointer",
                            }}
                          >
                            <X size={16} color="black" />
                          </div>
                        )}
                        {landingPage && (
                          <div
                            onClick={() => openModal(index, item?.link)}
                            style={{
                              position: "absolute",
                              bottom: "2px",
                              right: "2px",
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                              borderRadius: "50%",
                              padding: "2px",
                              cursor: "pointer",
                            }}
                          >
                            <Link size={16} />
                          </div>
                        )}
                      </div>
                    );
                  })}
                {(userRole === "Ground Staff" ||
                  userDepartment === "Finance" ||
                  "Customer Experience") && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "6em",
                      height: "6em",
                      cursor: "pointer",
                      margin: "0.5em",
                    }}
                    onClick={handleUploadClick}
                  >
                    <Upload size={30} />
                    <input
                      type="file"
                      id={`file-input-${uploadType[0].value}`}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          uppy.addFile({
                            name: file.name,
                            type: file.type,
                            data: file,
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <br />
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default AttachmentComponent;
