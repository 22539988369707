// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import StaffsTable from "./StaffsTable";
import AddEditModal from "./AddEditModal";
import { useGetAllCoupons } from "@api";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label } from "reactstrap";
import { useGetAllPaginatedStaffs, useHostelList } from "@api";
// import { useHostelList } from "../../../api/dataSource";
import Select from "react-select";
import { selectThemeColors } from "@utils";

const Staffs = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [id, setId] = useState("");
  const [coupons, setCoupons] = useState(); // ! for only defining purpose
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { data: allHostelsData } = useHostelList();
  // const { data, refetch } = useGetAllPaginatedStaffs(limit, page); // ! Coded by Ashwin
  const { data, refetch } = useGetAllPaginatedStaffs(
    limit,
    page,
    hostel.length > 0 ? hostel.map((hostel) => hostel.value) : null
  );
  const [totalPage, setTotalPage] = useState(1);
  const [HostelsList, setHostelsList] = useState("");

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    refetch();
  }, [hostel]);

  useEffect(() => {
    if (data?.getPaginatedStaffList?.data) {
      setTableData(data?.getPaginatedStaffList?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedStaffList?.count / limit));
  }, [data]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (data?.getPaginatedStaffList?.data.length) {
      let editData = data?.getPaginatedStaffList?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  return (
    // <div>
    //   <Row>
    //     <Col sm="8">
    //       <FormGroup className="w-50">
    //         <Label className="form-label" for="product">
    //           Hostel*
    //         </Label>
    //         <Select
    //           isClearable={true}
    //           isSearchable={true}
    //           theme={selectThemeColors}
    //           isMulti
    //           id="hostel"
    //           name="hostel"
    //           value={hostel}
    //           options={HostelsList}
    //           onChange={(value) => {
    //             setHostel(value);
    //           }}
    //           className="basic-multi-select"
    //           classNamePrefix="select"
    //         />
    //       </FormGroup>
    //     </Col>
    //   </Row>
    //   <StaffsTable
    //     setId={setId}
    //     openModal={() => setOpenModal(!openModal)}
    //     data={tableData}
    //     refetch={refetch}
    //   />
    //   <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

    //   <AddEditModal
    //     open={openModal}
    //     editData={editData}
    //     handleToggle={toggleModal}
    //     refetch={refetch}
    //   />
    // </div>
    <></>
  );
};

export default Staffs;
