import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_UNBOX_MENU_CATEGORY = gql`
  mutation CreateUnboxMenuCategory($menuInput: menuInput) {
    createUnboxMenuCategory(menuInput: $menuInput) {
      categoryName
      status
      message
    }
  }
`;

export const useCreateMenuCategory = () => {
  const [createMenuCategory, { loading, error, data, refetch }] = useMutation(
    CREATE_UNBOX_MENU_CATEGORY
  );

  useLoadingHandler(loading);

  const createMenuCategoryHandler = (menuInput) => {
    createMenuCategory({
      variables: {
        menuInput,
      },
    });
  };

  return [createMenuCategoryHandler, { loading, error, data, refetch }];
};

export const UPDATE_UNBOX_MENU_CATEGORY = gql`
  mutation UpdateUnboxMenuCategory($id: ID, $menuInput: menuInput) {
    updateUnboxMenuCategory(id: $id, menuInput: $menuInput) {
      categoryName
      status
      message
    }
  }
`;

export const useUpdateUnboxMenuCategory = () => {
  const [updateMenuCategory, { loading, error, data, refetch }] = useMutation(
    UPDATE_UNBOX_MENU_CATEGORY
  );

  useLoadingHandler(loading);

  const updateMenuCategoryHandler = (id, menuInput) => {
    updateMenuCategory({
      variables: {
        id,
        menuInput,
      },
    });
  };

  return [updateMenuCategoryHandler, { loading, error, data, refetch }];
};
