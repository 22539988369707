import gql from "graphql-tag";

export const GET_STEP_OUT_PAGINATED_BOOKING = gql`
  query getStepOutPaginatedBooking($filter: filter) {
    getStepOutPaginatedBooking(filter: $filter) {
      data {
        _id
        status
        paymentStatus
        userID
        productId
        productName
        category
        bookingDate
        pickupPoint
        rooms {
          roomName
          quantity
          price
        }
        totalPriceWithOutTax
        totalTax
        totalPriceWithTax
        razorpayOrderId
        razorpayPaymentId
        guestDetails {
          salutation
          fullName
          gender
          phoneNumber
          address
        }
        couponId
        couponDiscount
        reservationId
        promoCode
        startDate
        endDate
      }
      limit
      page
      count
    }
  }
`;
