import React, { useEffect, useState } from "react";
import { Edit } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { dateFormatHandler } from "../../../utility/Utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Creation Date",
  "Hostel",
  "Category",
  "Head",
  "Subhead",
  "Type",
  "Company Name",
  "POC Name",
  "Phone No",
  "Priority",
  // "Email",
  // "Services",
  "GST No",
  // "Bank A/C NO",
  // "IFSC Code",
  "Status",
];

const VendorTable = ({ openModal, setId, data, loading, refetch }) => {
  const [tableData, setTableData] = useState(data);
  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Vendors</CardTitle>
            {localStorage.getItem("role") !== "Ground Staff" && (
              <CardHeader className="">
                <Button.Ripple
                  color="light"
                  onClick={(e) => {
                    e.preventDefault();
                    openModal();
                  }}
                >
                  Add new vendor
                </Button.Ripple>
              </CardHeader>
            )}
          </CardHeader>{" "}
          {loading ? (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              Please wait...
            </h2>
          ) : tableData.length > 0 ? (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    {tableHeaderData.map((item) => {
                      return <th key={uuidv4()}>{item}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((element, i) => (
                    <tr key={uuidv4()}>
                      <td style={{ width: "5%" }}>
                        <Button
                          className="rounded-circle btn-icon"
                          color="none"
                          onClick={(e) => {
                            e.preventDefault();
                            setId(element._id);
                            openModal();
                          }}
                        >
                          <Edit
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>
                      <td>{dateFormatHandler(element?.createdAt)}</td>
                      <td>
                        {element?.hostel.length === 1
                          ? element?.hostel?.map((item, index) =>
                              index === element?.hostel?.length - 1
                                ? `${item?.name}`
                                : `${item?.name}, `
                            )
                          : "Multiple"}
                      </td>
                      <td>{element?.vendorCategory}</td>
                      <td>{element?.vendorHead}</td>
                      <td>{element?.vendorSubHead}</td>
                      <td>{element?.vendorType}</td>
                      <td>{element?.vendorName}</td>
                      <td>{element?.pocName}</td>
                      <td>{element?.contactNo}</td>
                      <td>{element?.vendorPriority}</td>
                      {/* <td>{element?.email}</td> */}
                      {/* <td>{element?.services}</td> */}
                      <td>{element?.vendorGSTNumber}</td>
                      {/* <td>{element?.vendorBankAccNumber}</td>
                      <td>{element?.vendorIFSC}</td> */}
                      <td>
                        <Badge
                          pill
                          color={
                            element?.vendorStatus
                              ? "light-success"
                              : "light-danger"
                          }
                          className="mr-1"
                        >
                          {element?.vendorStatus ? "Active" : "Inactive"}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>{" "}
            </>
          ) : (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              No data found
            </h2>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default VendorTable;
