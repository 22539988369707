import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_HOSTEL_BOOKING_OF_GUEST = gql`
  query hostelBookingOfGuest($userId: ID) {
    hostelBookingOfGuest(userId: $userId) {
      _id
      bookingId
      bookingDate
      checkinDate
      checkoutDate
      hostelId {
        _id
        name
      }
      sessionId
      userId {
        fullName
      }
      workationId
      rooms {
        roomUniqueId
        roomName
        travellers {
          firstName
          lastName
          salutation
        }
        quantity
      }
      contactDetails {
        name
        mobile
        email
        countryCode
        address {
          addressLine
          city
          state
          country
          pincode
        }
      }
      price {
        roomId
        amount
        tax
        quantity
        totalPriceWithoutTax
        totalTax
        totalPriceWithTax
        totalAmountWithMeal
        adr
        numberOfNights
      }
      status
      totalTax
      providerRefId
      tripPackageId
      tripId
      createdAt
      updatedAt
      payableAmount
      coupon
      refundType
      refundStatus
      providerRefundRefId
      walletDebit
      cancelDate
      walletRefund
      razorpayRefund
    }
  }
`;

export const useGetHostelBookingOfGuest = (userId) => {
  const { loading, error, data, refetch } = useQuery(
    GET_HOSTEL_BOOKING_OF_GUEST,
    {
      variables: {
        userId,
      },
      skip: !userId,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
