import React, { useState, useEffect } from "react";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import {
  useGetHostel,
  useGetDestination,
  useGetBlogsBlog,
  useGetBlogsCategory,
  useGetAllPaginatedHomePage,
} from "../../api/dataSource";
import { useHistory } from "react-router";
import TablePagination from "@components/common/TablePagination";
import SeoTable from "./SeoTable";
import AddEditModal from "./AddEditModal";
import toaster from "@components/common/Toaster";
import OthersTable from "./Others/OthersTable";
import OtherAddEditModal from "./Others/OtherAddEditModal";
import { useGetAllOthersSeo } from "../../api/OthersSeo/Queries";

const SEO = () => {
  const [selectModules, setSelectModules] = useState(null);
  const [filterFields, setFilterFields] = useState(null);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [editData, setEditData] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const { data: hostelData, refetch: hostelRefetch } = useGetHostel(
    limit,
    page
  );
  const { data: destinationData, refetch: destinationRefetch } =
    useGetDestination(limit, page);

  const { data: blogData, refetch: blogRefetch } = useGetBlogsBlog(limit, page);

  const { data: blogCategoryData, refetch: blogCategoryRefetch } =
    useGetBlogsCategory(limit, page);

  const { data: landingPageData, refetch: landingPageRefetch } =
    useGetAllPaginatedHomePage(limit, page);

  const { data: OthersSeoData, refetch: OthersSeoRefetch } =
    useGetAllOthersSeo();
  const history = useHistory();
  const seoModuleList = [
    { label: "Hostels", value: "hostels" },
    { label: "Destinations", value: "destinations" },
    { label: "Landing Page", value: "landing page" },
    { label: "Blogs", value: "blogs" },
    { label: "Blogs Category", value: "blogsCategory" },
    { label: "Others", value: "others" },
  ];

  useEffect(() => {
    hostelRefetch();
    destinationRefetch();
    blogRefetch();
    blogCategoryRefetch();
    landingPageRefetch();
  }, [page]);

  useEffect(() => {
    if (filterFields?.modules === "hostels") {
      if (hostelData) {
        let hostelList = [];
        hostelData?.getPaginatedHostelList?.data?.map((item) => {
          return hostelList.push(item);
        });
        setTableData(hostelList);
      }

      setTotalPage(
        Math.ceil(hostelData?.getPaginatedHostelList?.count / limit)
      );
    }

    if (filterFields?.modules === "destinations") {
      if (destinationData) {
        let destinationList = [];
        destinationData?.getPaginatedDestinationList?.data?.map((item) => {
          return destinationList.push(item);
        });
        setTableData(destinationList);
      }

      setTotalPage(
        Math.ceil(destinationData?.getPaginatedDestinationList?.count / limit)
      );
    }

    if (filterFields?.modules === "blogs") {
      if (blogData) {
        let blogList = [];
        blogData?.getPaginatedBlogList?.data?.map((item) => {
          return blogList.push(item);
        });
        setTableData(blogList);
      }

      setTotalPage(Math.ceil(blogData?.getPaginatedBlogList?.count / limit));
    }

    if (filterFields?.modules === "blogsCategory") {
      if (blogCategoryData) {
        let blogCategoryList = [];
        blogCategoryData?.getPaginatedBlogCategoryList?.data?.map((item) => {
          return blogCategoryList.push(item);
        });
        setTableData(blogCategoryList);
      }

      setTotalPage(
        Math.ceil(blogCategoryData?.getPaginatedBlogCategoryList?.count / limit)
      );
    }

    if (filterFields?.modules === "landing page") {
      if (landingPageData) {
        let landingPageList = [];
        landingPageData?.getPaginatedHomePageList?.data?.map((item) => {
          return landingPageList.push(item);
        });
        setTableData(landingPageList);
      }

      setTotalPage(
        Math.ceil(landingPageData?.getPaginatedHomePageList?.count / limit)
      );
    }
    if (filterFields?.modules === "others") {
      if (OthersSeoData) {
        let OthersSeoList = [];
        OthersSeoData?.getAllOthersSeo.map((item) => {
          return OthersSeoList.push(item);
        });
        setTableData(OthersSeoList);
      }

      setTotalPage(Math.ceil(OthersSeoData?.getAllOthersSeo?.count / limit));
    }
  }, [
    hostelData,
    destinationData,
    blogData,
    blogCategoryData,
    landingPageData,
    OthersSeoData,
    filterFields?.modules,
  ]);
  useEffect(() => {
    if (filterFields?.modules === "hostels") {
      if (hostelData?.getPaginatedHostelList?.data.length) {
        let editData = hostelData?.getPaginatedHostelList?.data?.find(
          (item) => item._id === id
        );

        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    }

    if (filterFields?.modules === "destinations") {
      if (destinationData?.getPaginatedDestinationList?.data.length) {
        let editData = destinationData?.getPaginatedDestinationList?.data?.find(
          (item) => item._id === id
        );

        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    }

    if (filterFields?.modules === "blogs") {
      if (blogData?.getPaginatedBlogList?.data.length) {
        let editData = blogData?.getPaginatedBlogList?.data?.find(
          (item) => item._id === id
        );

        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    }

    if (filterFields?.modules === "blogsCategory") {
      if (blogCategoryData?.getPaginatedBlogCategoryList?.data.length) {
        let editData =
          blogCategoryData?.getPaginatedBlogCategoryList?.data?.find(
            (item) => item._id === id
          );

        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    }

    if (filterFields?.modules === "landing page") {
      if (landingPageData?.getPaginatedHomePageList?.data.length) {
        let editData = landingPageData?.getPaginatedHomePageList?.data?.find(
          (item) => item._id === id
        );

        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    }

    if (filterFields?.modules === "others") {
      if (OthersSeoData?.getAllOthersSeo?.length) {
        let editData = OthersSeoData?.getAllOthersSeo?.find(
          (item) => item._id === id
        );

        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    }
  }, [
    hostelData?.getPaginatedHostelList?.data,
    destinationData?.getPaginatedDestinationList?.data,
    blogData?.getPaginatedBlogList?.data,
    blogCategoryData?.getPaginatedBlogCategoryList?.data,
    landingPageData?.getPaginatedHomePageList?.data,
    id,
    filterFields?.modules,
  ]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleSearch = () => {
    const filterFields = {
      modules: selectModules?.value,
    };

    if (filterFields?.modules) {
      setFilterFields(filterFields);
      setPage(1);
    }

    if (!selectModules) {
      toaster("error", "Please select a module");
      setTableData([]);
      setPage(1);
      return;
    }
  };

  const handleReset = () => {
    setSelectModules(null);
    setFilterFields(null);
    setTableData([]);
    setPage(1);
  };

  return (
    <>
      <Row>
        <Col sm="4">
          <FormGroup className="w-100">
            <Label className="form-label" for="selectModules">
              <strong>SEO Modules</strong>
            </Label>
            <Select
              classNamePrefix="select"
              isClearable={true}
              // isSearchable={true}
              id="selectModules"
              name="selectModules"
              value={selectModules}
              options={seoModuleList}
              onChange={(value) => setSelectModules(value)}
              isDisabled={selectModules}
            />
          </FormGroup>
        </Col>
        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              marginRight: "10px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginRight: "10px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>

      {
        tableData.length > 0 && filterFields?.modules !== "others" && (
          <>
            <SeoTable
              setId={setId}
              selectModules={selectModules}
              openModal={() => setOpenModal(!openModal)}
              data={tableData}
              refetch={hostelRefetch}
            />
            <TablePagination
              setPage={setPage}
              page={page}
              totalPage={totalPage}
            />
            <AddEditModal
              open={openModal}
              editData={editData}
              handleToggle={toggleModal}
              selectModules={selectModules}
              hostelRefetch={hostelRefetch}
              destinationRefetch={destinationRefetch}
              blogRefetch={blogRefetch}
              blogCategoryRefetch={blogCategoryRefetch}
              landingPageRefetch={landingPageRefetch}
            />
          </>
        )
        //   : (
        // <h2 style={{ textAlign: "center", marginTop: "150px" }}>
        //   No Record Found
        // </h2>
        // )
      }

      {filterFields?.modules === "others" && (
        <>
          <OthersTable
            openModal={() => setOpenModal(!openModal)}
            tableData={tableData}
            setId={setId}
          />
          <OtherAddEditModal
            open={openModal}
            editData={editData}
            handleToggle={toggleModal}
            selectModules={selectModules}
            refetch={OthersSeoRefetch}
          />
        </>
      )}
    </>
  );
};

export default SEO;
