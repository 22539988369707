import gql from "graphql-tag";

export const CREATE_LOYALTY_COINS = gql`
  mutation createLoyaltyCoins($loyaltyCoinsInput: LoyaltyCoinsInput) {
    createLoyaltyCoins(loyaltyCoinsInput: $loyaltyCoinsInput) {
      eventName
    }
  }
`;

export const UPDATE_LOYALTY_COINS = gql`
  mutation updateLoyaltyCoins(
    $id: ID!
    $loyaltyCoinsUpdate: LoyaltyCoinsInput
  ) {
    updateLoyaltyCoins(
      id: $id
      loyaltyCoinsUpdate: $loyaltyCoinsUpdate
    ) {
      eventName
    }
  }
`;