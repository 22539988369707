import React, { useEffect, useState } from "react";
import AddEditModal from "./AddEditModal";
import { useGetPaginatedMasterMenu } from "../../../api/Unbox/UnboxMasterMenu/Queries";
import MasterMenuTable from "./MasterMenuTable";
import TablePagination from "../../../@core/components/common/TablePagination";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";

const UnboxMasterMenu = () => {
  const [openModal, setOpenModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [menuName, setMenuName] = useState("")
  const [filterFeilds, setFilterFeilds] = useState(null)
  const [menuCategory, setmenuCategory] = useState("")
  const history = useHistory();
  const { refetch, data } = useGetPaginatedMasterMenu(limit, page, filterFeilds);

  useEffect(() => {
    refetch();
  }, [page, refetch]);
  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    if (data?.getPaginatedMasterMenu?.data?.length > 0) {
      setTableData(data?.getPaginatedMasterMenu?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getPaginatedMasterMenu?.count / limit));
  }, [
    data?.getPaginatedMasterMenu?.count,
    data?.getPaginatedMasterMenu?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedMasterMenu?.data?.length > 0) {
      let findData = data?.getPaginatedMasterMenu?.data?.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedMasterMenu?.data, id]);


  const handleSearch = () => {
    const filterFeilds = {
      itemName: menuName,
      itemCategory: menuCategory,
    };
    setPage(1);
    setFilterFeilds(filterFeilds);
  }

  const handleReset = () => {
    setFilterFeilds({})
    setMenuName("")
    setmenuCategory("")
    setPage(1)
  }

  const handleCreateUpdateUnboxMasterMenu = (id) => {
    history.push(`/unbox/edit/unboxmastermenu/${id}`);
  };
  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="menuName">
              <strong>Filter by menu</strong>
            </Label>
            <Input
              id="menuName"
              name="menuName"
              value={menuName}
              placeholder="Menu Name"
              onChange={(e) => {
                setMenuName(e.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="menuCategory">
              <strong>Filter by category</strong>
            </Label>
            <Input
              id="menuCategory"
              name="menuCategory"
              value={menuCategory}
              placeholder="Menu Category"
              onChange={(e) => {
                setmenuCategory(e.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col className="" sm="3" style={{ display: "flex", gap: "20px" }}>
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              //marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <MasterMenuTable
        data={tableData}
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        handleCreateUpdateUnboxMasterMenu={handleCreateUpdateUnboxMasterMenu}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      <AddEditModal
        setId={setId}
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default UnboxMasterMenu;
