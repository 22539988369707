import persistStore from "redux-persist/es/persistStore";
import { store } from "../store";
import { UserActionTypes } from "./user.actionTypes";

export const userSetDetails = (user) => ({
  type: UserActionTypes.USER_SET_DETAILS,
  payload: user,
});

// Clear state from Local storage
export const userLogout = async () => {
  const persistor = persistStore(store);
  await persistor.purge();
};
