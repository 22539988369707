const rating = [
    {
      name: "Good",
      emoji: "😍",
      value: "Good",
    },
    {
      name: "Neutral",
      emoji: "😐",
      value: "Neutral",
    },
    {
      name: "Bad",
      emoji: "🙁",
      value: "Bad",
    },
  
  ];
  
  export { rating };
  