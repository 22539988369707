import React, { useEffect, useState } from "react";
import AmenitiesTable from "./AmenitiesTable";
import AddEditModal from "./AddEditModal";
import { useAllAmenities } from "../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const Amenities = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [filter, setFilter] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useAllAmenities(limit, page, filter);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data?.getAllAminities?.data) {
      setTableData(data?.getAllAminities?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getAllAminities?.count / limit));
  }, [data, limit]);

  useEffect(() => {
    if (data?.getAllAminities?.data?.length) {
      let editData = data?.getAllAminities?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleSearch = () => {
    setFilter(name);
    setPage(1);
  };

  const handleReset = () => {
    setName("");
    setFilter(null);
    setPage(1);
  };

  return (
    <div>
      <Row>
        <Col sm="4">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="name">
                Amenity Name
              </Label>
            </strong>
            <Input
              type="text"
              value={name}
              id="name"
              name="name"
              placeholder="Amenity"
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button>
          <Button
            className="pull-right"
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <AmenitiesTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default Amenities;
