// ! Coded by Ashwin
import gql from "graphql-tag";

export const CREATE_STEP_OUT_COUPON = gql`
  mutation createStepOutCoupon($stepOutCouponInput: StepOutCouponInput) {
    createStepOutCoupon(stepOutCouponInput: $stepOutCouponInput) {
      name
    }
  }
`;

export const UPDATE_STEP_OUT_COUPON = gql`
  mutation updateStepOutCoupon($id: ID!, $stepOutCouponUpdate: StepOutCouponInput) {
    updateStepOutCoupon(id: $id, stepOutCouponUpdate: $stepOutCouponUpdate) {
      name
    }
  }
`;

