import Category from "../../views/Blogs/Category";
import Blog from "../../views/Blogs/Blog";

const BlogRoutes = [
  {
    path: "/blogs/category",
    component: Category,
    exact: true,
  },
  {
    path: "/blogs/blog",
    component: Blog,
    exact: true,
  }
];

export default BlogRoutes;
