import React, { useState, useEffect } from "react";
import { MoreVertical, Edit, Trash2 } from "react-feather";
import toaster from "@components/common/Toaster";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";
import {
  Table,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Avatar from "@components/common/avatar";
import { useDeleteStepoutWallOfLove } from "../../../api/dataSource";

const tableHeaderData = ["Action", "Icon", "Name", "status", "Display Order"];

const StepOutWallOfLoveTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState([]);
  const [deleteStepoutWallofLoveHandler] = useDeleteStepoutWallOfLove();

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    }
  }, [data]);

  const handleDelete = (e, item) => {
    e.preventDefault();
    deleteStepoutWallofLoveHandler(item._id);
    refetch();
    toaster("success", "Image deleted successfully");
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Stepout Wall of Love Images </h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Image
            </Button.Ripple>
          </CardHeader>

          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData?.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "10%" }}>
                    {/* <UncontrolledDropdown>
                      <DropdownToggle
                        className="icon-btn hide-arrow"
                        color="transparent"
                        size="sm"
                        caret
                      >
                        <MoreVertical size={15} />
                        <DropdownMenu right>
                          <DropdownItem
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              setId(item._id);
                              openModal();
                            }}
                          >
                            <Edit className="mr-50" size={15} />
                            <span className="align-middle">Edit</span>
                          </DropdownItem>
                          <DropdownItem
                            href="/"
                            onClick={(e) => handleDelete(e, item)}
                          >
                            <Trash2 className="mr-50" size={15} />
                            <span className="align-middle">Delete</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </DropdownToggle>
                    </UncontrolledDropdown> */}
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(e, item);
                      }}
                    >
                      <Trash2
                        className="mr-50"
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>
                    <Avatar img={item?.ImageUrl} />
                  </td>
                  <td>{item.ImageName}</td>
                  <td>
                    <Badge
                      pill
                      color={item?.status ? "light-success" : "light-danger"}
                      className="mr-1"
                    >
                      {item?.status ? "Active" : "Inactive"}
                    </Badge>
                  </td>
                  <td>{item.DisplayOrder}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default StepOutWallOfLoveTable;
