import React, { Fragment, useEffect, useState } from "react";
import {
  useCreateCafeMaster,
  useUpdateCafeMaster,
} from "../../../api/Unbox/UnboxCafeMaster/Mutations";
import { useHostelList } from "../../../api/dataSource";
import SlideModal from "../../../@core/components/common/slide-modal";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Link, Plus, X } from "react-feather";
import { selectThemeColors } from "@utils";

const daysList = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];
const AddEditModal = ({ open, editData, handleToggle, refetch }) => {
  const [hostelList, setHostelList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [cafeName, setCafeName] = useState("");
  const [cafeActiveDays, setCafeActiveDays] = useState(null);
  // const [fromTime, setFromTime] = useState("");
  // const [toTime, setToTime] = useState("");
  const [cafeTimings, setCafeTimings] = useState([
    { id: uuidv4(), fromTime: "", toTime: "" },
  ]);
  const [active, setActive] = useState(false);
  const { data: allHostelList } = useHostelList();
  const [createCafeMasterHandler, { data: createdData }] =
    useCreateCafeMaster();

  const [updateCafeMasterHandler, { data: updatedData }] =
    useUpdateCafeMaster();
  useEffect(() => {
    if (createdData?.createCafeMaster || updatedData) {
      refetch();
    }
  }, [createdData, updatedData]);

  useEffect(() => {
    if (allHostelList?.getHostelList?.length) {
      let hostel = allHostelList?.getHostelList?.map((ele) => ({
        label: ele?.name,
        value: ele?._id,
      }));
      setHostelList(hostel);
    }
  }, [allHostelList?.getHostelList]);

  useEffect(() => {
    if (editData?._id) {
      setHostel(
        editData?.hostel?.name !== null
          ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
          : null
      );
      setCafeName(editData?.cafeName ? editData?.cafeName : "");
      const inActiveDays = editData?.cafeActiveDays?.map((day) => {
        const findDay = daysList?.find((dayObj) => dayObj?.value === day);
        return findDay;
      });
      setCafeActiveDays(inActiveDays);
      // setFromTime(
      //   editData?.fromTime ? moment(editData?.fromTime).format("HH:mm") : ""
      // );
      // setToTime(
      //   editData?.toTime ? moment(editData?.toTime).format("HH:mm") : ""
      // );
      const fetchedCafeTiming = editData?.cafeTimings?.map((cafeTime) => {
        return {
          id: uuidv4(),
          fromTime: cafeTime?.fromTime,
          toTime: cafeTime?.toTime,
        };
      });
      setCafeTimings(fetchedCafeTiming);
      setActive(editData?.active ? true : false);
    } else {
      setHostel(null);
      setCafeName("");
      // setFromTime("");
      // setToTime("");
      setCafeTimings([{ id: uuidv4(), fromTime: "", toTime: "" }]);
      setActive(false);
      setCafeActiveDays(null);
    }
  }, [editData]);

  const handleAddSlots = () => {
    setCafeTimings([
      ...cafeTimings,
      {
        id: uuidv4(),
        fromTime: "",
        toTime: "",
      },
    ]);
  };

  const handleRemoveFilter = (id) => {
    setCafeTimings(cafeTimings?.filter((item) => item?.id !== id));
  };

  const handleAddStartTime = (val, id) => {
    // console.log("cafe", cafeTimings);
    let formattedStartTime = moment(val, "HH:mm").format();
    // const isTimeConflict = cafeTimings.some((el) => {
    //   const fromTime = moment(el.fromTime);
    //   const toTime = moment(el.toTime);
    //   const startTime = moment(val);
    //   return (
    //     startTime.isSameOrAfter(fromTime) && startTime.isSameOrBefore(toTime)
    //   );
    // });
    // console.log(isTimeConflict);
    // if (!isTimeConflict) {
    setCafeTimings(
      cafeTimings?.map((item) =>
        item?.id === id ? { ...item, fromTime: formattedStartTime } : item
      )
    );
    // } else {
    //   toaster("error");
    // }
  };

  const handleAddToTime = (val, id) => {
    let formattedToTime = moment(val, "HH:mm").format();

    setCafeTimings(
      cafeTimings?.map((item) =>
        item?.id === id ? { ...item, toTime: formattedToTime } : item
      )
    );
  };

  const handleAddCafeName = (val) => {
    if (val !== " ") {
      setCafeName(val);
    } else {
      toaster("error", "No intial space");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {
      hostel: hostel?.value,
      cafeName,
      // fromTime: moment(fromTime, "HH:mm").format(),
      // toTime: moment(toTime, "HH:mm").format(),
      cafeActiveDays: cafeActiveDays?.map((days) => days?.value),
      cafeTimings: cafeTimings?.map((cafeTime) => {
        delete cafeTime?.id;
        return cafeTime;
      }),
      active: active,
    };
    if (data?.hostel && data?.cafeName.trim() !== "") {
      if (editData?._id) {
        updateCafeMasterHandler(editData?._id, data);
        toaster("success", "Cafe updated successfully");
      } else {
        createCafeMasterHandler(data);

        toaster("success", "Cafe created successfully");
      }
      setHostel(null);
      setCafeName("");
      // setFromTime("");
      // setToTime("");
      setCafeTimings([{ id: uuidv4(), fromTime: "", toTime: "" }]);
      setActive(false);
      setCafeActiveDays(null);
      handleToggle();
    } else {
      toaster("error", "Please fill the Required Details");
    }
  };
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitHandler}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {editData?._id && (
            <Col className="mb-2">
              <a
                href={`https://www.thehosteller.com/glu/unbox?cafeId=${editData?._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <Link></Link>
                Redirect url
              </a>
            </Col>
          )}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={hostelList}
                isDisabled={editData?.hostel?.name}
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="cafeName">
                Cafe Name*
              </Label>
              <Input
                type="text"
                value={cafeName}
                required
                id="cafeName"
                name="cafeName"
                placeholder="Cafe Name"
                onChange={(e) => handleAddCafeName(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="cafeActiveDays">
                Cafe Active Days
              </Label>
              <Select
                isMulti
                isClearable={false}
                isSearchable={true}
                theme={selectThemeColors}
                id="cafeActiveDays"
                name="cafeActiveDays"
                options={daysList}
                value={cafeActiveDays}
                onChange={(value) => {
                  setCafeActiveDays(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <strong className="">Slot - {1}</strong>
          </Col>
          {cafeTimings?.map((cafetime, i) => {
            return (
              <Fragment key={uuidv4()}>
                {cafeTimings?.length > 1 && i >= 1 && (
                  <Col sm="12">
                    <strong className="">Slot - {i + 1}</strong>
                    <Button.Ripple
                      type="button"
                      className="btn-icon rounded float-right"
                      color="danger"
                      size="sm"
                      onClick={() => handleRemoveFilter(cafetime?.id)}
                    >
                      <X size={14} />
                    </Button.Ripple>
                  </Col>
                )}
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="fromTime">
                      Start Time
                    </Label>
                    <Input
                      type="time"
                      defaultValue={
                        // cafetime?.fromTime
                        moment(cafetime?.fromTime).format("HH:mm")
                      }
                      required={true}
                      id={cafetime?.id}
                      name="fromTime"
                      placeholder="Start Time"
                      onChange={(e) => {
                        handleAddStartTime(e.target.value, e.target.id);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="toTime">
                      End Time
                    </Label>
                    <Input
                      type="time"
                      defaultValue={
                        //cafetime?.toTime
                        moment(cafetime?.toTime).format("HH:mm")
                        // moment().format("HH:mm")
                      }
                      required={true}
                      id={cafetime?.id}
                      name="toTime"
                      placeholder="End Time"
                      onChange={(e) =>
                        handleAddToTime(e.target.value, e.target.id)
                      }
                    />
                  </FormGroup>
                </Col>
              </Fragment>
            );
          })}
          <Col sm="12">
            <Button.Ripple
              type="button"
              className="btn-icon rounded float-right"
              color="light"
              size="sm"
              onClick={handleAddSlots}
            >
              <Plus size={14} className="" />
            </Button.Ripple>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="active">
                Status
              </Label>
              <SwitchIcon
                checked={active}
                id="active"
                name="active"
                handleChecked={(e) => {
                  setActive(e);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
