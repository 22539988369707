import React, { useEffect, useState } from "react";
import SlideModal from "../../../@core/components/common/slide-modal/index";
import { Col, Input, Row } from "reactstrap";
import { DragDrop } from "@uppy/react";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import toaster from "@components/common/Toaster";
import { useUploadImage } from "../../../api/dataSource";
import {
  useCreateMerchandiseProduct,
  useUpdateMerchandiseProduct,
} from "../../../api/MerchandiseStore/Mutation";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(null);
  const [tax, setTax] = useState(null);
  const [previewArr, setPreviewArr] = useState([]);
  const [displayOrder, setDisplayOrder] = useState(null);
  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();
  const [CreateMerchandiseProductHandler, { data: createdData }] =
    useCreateMerchandiseProduct();
  const [updataMerchandiseProductHandler, { data: updatedData }] =
    useUpdateMerchandiseProduct();
  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppy.use(thumbnailGenerator);
  uppy.on("thumbnail:generated", (file, preview) => {
    setPreviewArr([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "image/walloflove");
    // setImageUrl(imageData?.uploadImage?.url)
    toaster("success", "Item image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading wall of love image: ${error.message}`);
  });

  const renderPreview = () => {
    if (previewArr.length) {
      return previewArr.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };

  const onSubmitHandler = () => {
    if (
      name &&
      description &&
      price &&
      tax &&
      displayOrder &&
      previewArr[0]
    ) {
      const data = {
        name,
        description,
        price: Number(price),
        tax: Number(tax),
        displayOrder: Number(displayOrder),
        mainImage: previewArr[0],
      };

      if (editData?._id) {
        updataMerchandiseProductHandler(editData._id, data);
      } else {
        CreateMerchandiseProductHandler(data);
      }
      refetch();
      handleToggle();
    } else {
      toaster("error", "Please fill all required fields");
    }
  };

  useEffect(() => {
    if (editData?._id) {
      setName(editData?.name);
      setDescription(editData?.description);
      setTax(editData?.tax);
      setDisplayOrder(editData?.displayOrder);
      setPrice(editData?.price);
      setPreviewArr([editData?.mainImage]);
    } else {
      setName("");
      setDescription("");
      setTax(null);
      setDisplayOrder(null);
      setPrice("");
      setPreviewArr([]);
    }
  }, [editData]);

  useEffect(() => {
    refetch();
  }, [updatedData, createdData]);
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form>
        <Row>
          <Col>
            <Row>
              <Col>
                <label>Product name*</label>
                <Input
                  autoFocus
                  type="text"
                  value={name}
                  id="name"
                  name="name"
                  placeholder="Product name"
                  onChange={(e) =>
                    e.target.value !== " "
                      ? setName(e.target.value)
                      : toaster("error", "initaial space not allowed")
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Description*</label>
                <Input
                  autoFocus
                  type="textarea"
                  value={description}
                  id="description"
                  name="description"
                  placeholder="Description"
                  onChange={(e) =>
                    e.target.value !== " "
                      ? setDescription(e.target.value)
                      : toaster("error", "initial space not allowed")
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Price*</label>
                <Input
                  autoFocus
                  type="text"
                  value={price}
                  id="price"
                  name="price"
                  placeholder="Price"
                  // onChange={(e) => setPrice(e.target.value)}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setPrice(e.target.value);
                    }
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Tax* (%)</label>
                <Input
                  autoFocus
                  type="text"
                  value={tax}
                  id="tax"
                  name="tax"
                  placeholder="Tax"
                  // onChange={(e) => setTax(e.target.value)}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setTax(e.target.value);
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Display order*</label>
                <Input
                  autoFocus
                  type="text"
                  value={displayOrder}
                  id="price"
                  name="price"
                  placeholder="Display order"
                  // onChange={(e) => setDisplayOrder(e.target.value)}
                  onChange={(e) => {
                    console.log(!isNaN(e.target.value))
                    if (!isNaN(e.target.value)) {
                      setDisplayOrder(e.target.value);
                    }
                  }}

                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Upload image*</p>
                <DragDrop
                  note="Image size should be less than 500 KB"
                  uppy={uppy}
                />
                {renderPreview()}
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
