import React, { useEffect, useState } from "react";
import HostelRoomsTable from "./HostelRoomsTable";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useHostelList } from "../../../api/dataSource";
import { useSyncHostelRooms } from "../../../api/HostelRooms/index";
import { Col, Input, Row, Button, Spinner } from "reactstrap";
import toaster from "../../../@core/components/common/Toaster";

const HostelRooms = () => {
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [syncHostelRoomsHandler, { data: syncResponse }] = useSyncHostelRooms();

  const { data: hostelListData, refetch } = useHostelList();

  useEffect(() => {
    if (hostelListData?.getHostelList) {
      setTableData(hostelListData?.getHostelList);
    } else {
      setTableData([]);
    }
  }, [hostelListData?.getHostelList]);

  const filteredData = tableData.filter((hostel) =>
    hostel.name.toLowerCase().includes(filter.toLowerCase())
  );

  // useEffect(() => {
  //   setIsLoading(false);
  //   if (syncResponse) {
  //     toaster("success", syncResponse?.syncHostelRooms?.message);
  //   }
  // }, [syncResponse]);

  const handleClick = () => {
    setIsLoading(true);
    syncHostelRoomsHandler(null);
    setTimeout(() => {
      setIsLoading(false);
      toaster("success", "Synced all Hostel Rooms");
    }, 30000);
  };

  return (
    localStorage.getItem("modules")?.includes("Glu Configuration") && (
      <div>
        <Row>
          <Col sm="4">
            <Input
              type="text"
              placeholder="Filter Hostels"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Col>
          <Col className="">
            <div className="">
              <Button
                color="secondary"
                onClick={handleClick}
                disabled={isLoading}
                className="float-right"
              >
                {isLoading ? (
                  <>
                    <Spinner size="sm" color="light" className="mr-1" />{" "}
                    Loading...
                  </>
                ) : (
                  "Sync Rooms"
                )}
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <HostelRoomsTable hostelListData={filteredData} refetch={refetch} />
      </div>
    )
  );
};

export default HostelRooms;
