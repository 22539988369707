import React, { useEffect, useState } from "react";
import { Col, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";

const AncillaryMaterialSubFields = ({ setDataToSubmit, editData }) => {
  const [subHeadType, setSubHeadType] = useState(null);
  const [quantity, setQuantity] = useState("");

  const AncillaryMaterialVendorType = [
    { label: "Lock", value: "Lock" },
    { label: "Warmee", value: "Warmee" },
    { label: "Toiletry Kit", value: "Toiletry Kit" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );
    setQuantity(
      editData?.subFields?.quantity !== null
        ? editData?.subFields?.quantity
        : ""
    );
  }, [editData]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
      quantity,
    };

    setDataToSubmit(data);
  }, [subHeadType, quantity]);
  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          {/* <Label className="form-label" for="subHeadType">
            Vendor Type
          </Label> */}
          <Label className="form-label" for="subHeadType">
            Material Type
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={AncillaryMaterialVendorType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            // isDisabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="quantity">
            Quantity
          </Label>
          <Input
            type="text"
            value={quantity}
            id="quantity"
            name="quantity"
            placeholder="Add Quantity"
            onChange={(e) => setQuantity(e.target.value)}
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default AncillaryMaterialSubFields;
