import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const tableHeaderData = [
  'HOSTEL NAME',
  'TOTAL BOOKINGS',
  'TOTAL SALE',
  'TOTAL SALE WITH TAX'
];


const BookingReportTable = ({ openModal, setId, data, refetch, condition }) => {
  const [tableData, setTableData] = useState(data);
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [totalSaleWithTax, setTotalSaleWithTax] = useState(0);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
      calculateTotals(data)
    }
    else {
      setTableData([])
    }
  }, [data]);

  const calculateTotals = (data) => {
    const bookingsTotal = data.reduce((acc, item) => acc + item.totalBookings, 0);
    setTotalBookings(bookingsTotal);

    const saleTotal = data.reduce((acc, item) => acc + item.totalSaleAmount, 0);
    setTotalSale(saleTotal);

    const saleWithTaxTotal = data.reduce((acc, item) => acc + item.totalSaleAmountWithTax, 0);
    setTotalSaleWithTax(saleWithTaxTotal);
  };
  // console.log(totalBookings)

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Hostelwise booking report(Booking Date)</CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item, index) => {
                  return (
                    <th key={uuidv4()}>
                      <div>{item}</div>
                      <div>
                        {item === "TOTAL BOOKINGS" ? `(${totalBookings})` :
                          item === "TOTAL SALE" ? `(${new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", maximumFractionDigits: 2 }).format(totalSale)})` :
                            item === "TOTAL SALE WITH TAX" ? `(${new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", maximumFractionDigits: 2 }).format(totalSaleWithTax)})` :
                              ''
                        }
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    {/* <Badge
                    color = 'light-info'
                  > */}
                    {item?._id}
                    {/* </Badge> */}
                  </td>
                  <td>{item?.totalBookings}</td>
                  <td>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 2 }).format(item?.totalSaleAmount)}</td>
                  <td>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 2 }).format(item?.totalSaleAmountWithTax)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default BookingReportTable;