import React from "react";
import Uppy from "@uppy/core";
import { Col, Label, FormGroup } from "reactstrap";
import { DragDrop } from "@uppy/react";
import thumbnailGenerator from "@uppy/thumbnail-generator";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadImage = ({ title, preview, setPreview, uploadHandle }) => {
  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2000000,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setPreview([preview]);
    uploadHandle(file.data, `offers/${title}`);
  });

  const renderPreview = () => {
    if (preview.length) {
      return preview.map((src, index) => (
        <img
          key={index}
          className='img-thumbnail rounded mt-2 mr-1'
          src={src}
          alt='avatar'
        />
      ));
    } else {
      return null;
    }
  };
  return (
    <>
      <Col sm='6' className='w-100 my-2'>
        <FormGroup>
          <Label className='form-label' for='name'>
            {title} *
          </Label>

          <DragDrop height={200} note='max size 2MB' uppy={uppy} />
        </FormGroup>
      </Col>

      <Col sm='6' className='w-100 my-2'>
        <FormGroup>
          <Label className='form-label' for='name'>
            {title}
          </Label>

          {renderPreview()}
        </FormGroup>
      </Col>
    </>
  );
};

export default UploadImage;
