import React, { useState, useEffect } from "react";
import SlideModal from "./slideModal/index";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const AddEditModal = ({ open, handleToggle, editData }) => {
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (editData?._id) {
      setFullName(editData?.fullName !== null ? editData?.fullName : "");
      setEmailId(editData?.emailId !== null ? editData?.emailId : "");

      setPhoneNumber(
        editData?.phoneNumber !== null ? editData?.phoneNumber : "",
      );
      setMessage(editData?.message !== null ? editData?.message : "");
    }
  }, [editData]);

  return (
    <SlideModal open={open} handleToggle={handleToggle}>
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="name">
                  Full Name
                </Label>
              </strong>
              <Input
                type="text"
                value={fullName}
                id="fullName"
                name="fullName"
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="emailId">
                  Email Id
                </Label>
              </strong>
              <Input
                type="text"
                value={emailId}
                id="emailId"
                name="emailId"
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="phoneNumber">
                  Phone Number
                </Label>
              </strong>
              <Input
                type="text"
                value={phoneNumber}
                id="phoneNumber"
                name="phoneNumber"
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="message">
                  Message
                </Label>
              </strong>
              <Input
                type="textarea"
                value={message}
                id="message"
                name="message"
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
