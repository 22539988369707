// Added by Nitish

import gql from "graphql-tag";

export const CREATE_SERVICE = gql`
  mutation createService($serviceInput: serviceInput) {
    createService(serviceInput: $serviceInput) {
      _id
      category
      title
      description
      totalInventoryPerDay
      price
      tax
      cutOffTime
      fromTime
      toTime
      status
      icon
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation updateService($id: ID!, $serviceInput: serviceInput) {
    updateService(id: $id, serviceInput: $serviceInput) {
      _id
      category
      title
      description
      totalInventoryPerDay
      price
      tax
      cutOffTime
      fromTime
      toTime
      status
      icon
      serviceNudge
      displayOrder
      vendorName
      vendorMobileNumber
      vendorEmail
      vendorOrg
    }
  }
`;
