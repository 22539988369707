import React from "react";
import { Plus } from "react-feather";
import { Button, CardHeader, CardTitle, FormGroup, Label } from "reactstrap";
import Select from "react-select";

const Header = ({
  filterHeader,
  tableHeader,
  type,
  handleAdd,
  handleEdit,
  headerAction,
  hostelList,
  selectedAction,
  // handleCreateUpdateBlogs
}) => {
  return (
    <div>
      <div className="py-1 px-2 d-flex justify-content-between align-items-center border-bottom ">
        <h2 className="">{tableHeader}</h2>
        <div className="mr-1">
          {handleAdd && (
            <Button.Ripple
              color="light"
              onClick={() => {
                handleAdd();
                handleEdit(null);
                // handleCreateUpdateBlogs(null)
              }}
            >
              <Plus size={14} />
              <span className="align-middle ml-1 text-capitalize">
                Add {type}
              </span>
            </Button.Ripple>
          )}
        </div>
      </div>

      <CardHeader>
        <CardTitle>{filterHeader}</CardTitle>
        {hostelList && (
          <FormGroup className="w-50">
            <Label for="room">Select Hostel</Label>
            <Select
              isClearable={true}
              id="room"
              name="room"
              value={selectedAction}
              options={hostelList}
              onChange={(value) => headerAction(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        )}
      </CardHeader>
    </div>
  );
};

export default Header;
