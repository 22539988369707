import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";


export const GET_UNBOX_BANNER = gql`
query getUnboxBanner{
    getUnboxBanner{
        _id
        title
        image
        displayOrder
        startDate
        endDate
        cafe{
            cafeName
            _id
        }
    }
} 
`

export const useGetAllUnboxBanner = (Id)=> {
    const {loading, error, data, refetch} = useQuery(GET_UNBOX_BANNER);
    useLoadingHandler(loading);
    return {data, error, refetch};
}