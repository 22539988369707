import React, { useEffect, useState } from "react";
import AddEditModal from "./AddEditModal";
import WalletInfoTable from "./WalletInfoTable";
import TablePagination from "@components/common/TablePagination";
import { useHistory } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import {
  useGetPaginatedWallet,
  useGetAllWalletList,
} from "../../../api/dataSource";
import { Col, Row, Input, FormGroup, Label, Button, Form } from "reactstrap";
import { CSVLink } from "react-csv";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";

const exportHeaders = [
  { label: "Guest Email", key: "userId.email" },
  { label: "Guest Full Name", key: "userId.fullName" },
  { label: "Transaction Id", key: "transactionId" },
  { label: "Reason", key: "reason" },
  { label: "Transaction Date", key: "transactionDate" },
  { label: "Amount", key: "amount" },
  { label: "Description", key: "description" },

  { label: "Ref. Reservation Id", key: "refReservationId" },
  { label: "Logs", key: "logs" },
];

const reasonList = [
  { label: "Booking cancellation", value: "Booking cancellation" },
  { label: "Customer delight", value: "Customer delight" },
  { label: "I&P benefits", value: "I&P benefits" },
  { label: "Employee benefits", value: "Employee benefits" },
  { label: "Marketing", value: "Marketing" },
  { label: "Owners", value: "Owners" },
];

const WalletInfo = () => {
  const [email, setEmail] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [refReservationId, setRefReservationId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [exportWallet, setExportWallet] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [filteredData, setFilteredData] = useState({});
  const [reason, setReason] = useState({});
  const [transactionDateTo, setTransactionDateTo] = useState(null);
  const [transactionDateFrom, setTransactionDateFrom] = useState(null);

  const { data, refetch } = useGetPaginatedWallet(limit, page, filteredData);

  const { data: exportWalletData } = useGetAllWalletList();

  const history = useHistory();

  const count = data?.getPaginatedWallet?.count;

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    if (exportWalletData?.getAllWallet?.length > 0) {
      setExportWallet(exportWalletData?.getAllWallet);
    } else {
      setExportWallet([]);
    }
  }, [exportWalletData?.getAllWallet]);

  useEffect(() => {
    if (data?.getPaginatedWallet?.data.length > 0) {
      setTableData(data?.getPaginatedWallet?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedWallet?.count / limit));
  }, [data?.getPaginatedWallet?.data, limit, count]);

  useEffect(() => {
    if (id && data?.getPaginatedWallet?.data.length) {
      let editData = data?.getPaginatedWallet?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedWallet?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const refreshHandler = () => {
    setFilteredData({});
    setReason({});
    setEmail("");
    setTransactionId("");
    setRefReservationId("");
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/wallet/edit/walletBalance/${id}`);
    } else {
      setId(id);
    }
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    const data = {
      email: email.toLowerCase(),
      transactionId,
      refReservationId,
      reason: reason?.value,
      transactionDateFrom,
      transactionDateTo,
    };

    setFilteredData(data);
    setPage(1);
  };

  return (
    localStorage.getItem("modules")?.includes("Wallet") && (
      <div>
        <Form>
          <Row>
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="email">
                    Guest Email
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={email}
                  id="email"
                  name="email"
                  // placeholder="Add user email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="transactionId">
                    Wallet Transaction Id
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={transactionId}
                  id="transactionId"
                  name="transactionId"
                  // placeholder="Add user transaction Id"
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="refReservationId">
                    Reference Reservation Id
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={refReservationId}
                  id="refReservationId"
                  name="refReservationId"
                  // placeholder="Add user refReservationId"
                  onChange={(e) => setRefReservationId(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="transactionId">
                    Reason
                  </Label>
                </strong>
                {/* <Label className="form-label" for="status">
              <h5>Check coupons status*</h5>
            </Label> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  id="reason"
                  name="reason"
                  value={reason}
                  options={reasonList}
                  onChange={(value) => {
                    setReason(value);
                  }}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup className="w-100">
                <Label className="form-label" for="url">
                  <strong>Date Range</strong>
                </Label>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  id="transactionDate"
                  placeholder="Transaction Date Range"
                  options={{
                    mode: "range",
                  }}
                  value={[transactionDateFrom, transactionDateTo]}
                  onChange={(date) => {
                    setTransactionDateFrom(dateFormatter(date[0]));
                    setTransactionDateTo(dateFormatter(date[1]));
                  }}
                />
              </FormGroup>
            </Col>

            {/* <Col sm="3">
              <Button.Ripple
                color="light"
                type="submit"
                style={{ marginTop: "21px" }}
              >
                Search
              </Button.Ripple>
            </Col> */}
            {/* <Col sm="1">
              <Button
                style={{
                  // backgroundColor: "#ff9999",
                  // height: "40px",
                  marginTop: "21px",
                  // marginLeft: "20px",
                  // borderRadius: "5px",
                  // padding: "10px 40px",
                  // outline: "none",
                  // border: "0",
                }}
                color="danger"
                // appearance="primary"
                type="button"
                onClick={() => {
                  refreshHandler();
                }}
              >
                Reset
              </Button>
            </Col> */}

            <Col sm="8 mb-2">
              <Button
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="white"
                appearance="primary"
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Search
              </Button>

              <Button
                style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  marginLeft: "40px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="danger"
                appearance="primary"
                type="button"
                onClick={() => {
                  refreshHandler();
                }}
              >
                Reset
              </Button>

              <CSVLink
                headers={exportHeaders}
                data={exportWallet}
                filename={`wallet_list.csv`}
              >
                <Button.Ripple
                  style={{
                    height: "40px",
                    marginTop: "22px",
                    marginLeft: "40px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="success"
                  appearance="success"
                  type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            </Col>

            {/* <Col sm="3">
              <FormGroup className="w-50">
                <CSVLink
                  headers={exportHeaders}
                  data={exportWallet}
                  filename={`wallet_list.csv`}
                >
                  <Button.Ripple
                    // className="float-left"
                    color="light"
                    type="button"
                    size="base"
                  >
                    Export
                  </Button.Ripple>
                </CSVLink>
              </FormGroup>
            </Col> */}
          </Row>
        </Form>

        <WalletInfoTable
          setId={setId}
          id={id}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          count={count}
          handleEdit={handleEdit}
          refetch={refetch}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
          setId={setId}
        />
      </div>
    )
  );
};

export default WalletInfo;
