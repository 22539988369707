import React, { useEffect, useState } from "react";
import StepOutBlogCategoryTable from "./StepOutBlogCategoryTable";
import TablePagination from "@components/common/TablePagination";
import { useGetPaginatedStepOutBlogCategoryList } from "../../../api/dataSource";
import AddEditModal from "./AddEditModal";

const StepOutBlogCateory = () => {
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useGetPaginatedStepOutBlogCategoryList(limit, page);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    refetch();
  }, [refetch, page]);

  useEffect(() => {
    if (data?.getStepOutPaginatedBlogCategory?.data.length > 0) {
      setTableData(data?.getStepOutPaginatedBlogCategory?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(
      Math.ceil(data?.getStepOutPaginatedBlogCategory?.count / limit)
    );
  }, [
    data?.getStepOutPaginatedBlogCategory?.count,
    data?.getStepOutPaginatedBlogCategory?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getStepOutPaginatedBlogCategory?.data.length) {
      let findData = data?.getStepOutPaginatedBlogCategory.data.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getStepOutPaginatedBlogCategory?.data, id]);

  return (
    <div>
      <StepOutBlogCategoryTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      <AddEditModal
        openModal={openModal}
        handleToggle={toggleModal}
        refetch={refetch}
        editData={editData}
        setId={setId}
      />
    </div>
  );
};

export default StepOutBlogCateory;
