import { Server, Circle } from "react-feather";

export default [
    {
        id: "communications",
        title: "Communications",
        icon: <Server size={20} />,
        badge: "light-warning",
        badgeText: "2",
        children: [
            // ! Added by Ashwin
            {
                id: "messageTemplate",
                title: "Template",
                icon: <Circle size={12} />,
                navLink: "/communications/messageTemplate",
            },
            // {
            //     id: "blogsBlog",
            //     title: "Blog",
            //     icon: <Circle size={12} />,
            //     navLink: "/blogs/blog",
            // }

        ],
    }]
