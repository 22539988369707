import React, { useState, useEffect } from "react";
import SlideModal from "./slide-modal/index";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import { useCreateHomePage } from "../../../api/dataSource";
import toaster from "@components/common/Toaster";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [slug, setSlug] = useState("");

  const [createHomePageHandler, { data: createHomePageData }] =
    useCreateHomePage();

  useEffect(() => {
    if (createHomePageData?.createHomePage?.status === false) {
      toaster("error", "Slug already used, please try another");
    }
    if (createHomePageData) {
      if (createHomePageData?.createHomePage?.slug) {
        toaster("success", "Homepage added successfully");
      }
      refetch();
      handleToggle();
    }
  }, [createHomePageData]);

  useEffect(() => {
    if (editData?._id) {
      setSlug(editData?.slug !== null ? editData?.slug : "");
    } else {
      setSlug("");
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const data = {
      slug,
    };

    if (data.slug) {
      createHomePageHandler(data);
      setSlug("");
    } else {
      toaster("error", "Please enter slug");
    }
  };
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      editData={editData?._id}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="slug">
                  Slug*
                </Label>
              </strong>
              <Input
                type="text"
                value={slug}
                id="slug"
                name="slug"
                placeholder="Add Slug"
                onChange={(e) => setSlug(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
