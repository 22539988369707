import gql from "graphql-tag";

export const UPDATE_STATUS_REMARK = gql`
  mutation updateStatusRemark(
    $_id: ID
    $statusRemarkDetails: statusRemarkDetails
  ) {
    updateStatusRemark(_id: $_id, statusRemarkDetails: $statusRemarkDetails) {
      feedbackStatus
      propertyRemark
      responseTime
    }
  }
`;

export const UPDATE_FINAL_REMARK = gql`
  mutation updateFinalRemark($_id: ID, $finalRemarkDetails: InputFinalRemark) {
    updateFinalRemark(_id: $_id, finalRemarkDetails: $finalRemarkDetails) {
      finalStatus
      finalRemark
      updatedBy
    }
  }
`;

//for updateFeedbackTicket
export const UPDATE_FEEDBACK_TICKET = gql`
  mutation updateFeedbackTicket($reservationId: String) {
    updateFeedbackTicket(reservationId: $reservationId) {
      feedbackStatus
      propertyRemark
      finalStatus
    }
  }
`;

// ! Coded by Ashwin
export const UPDATE_FEEDBACK_STATUS = gql`
  mutation updateFeedbackStatus(
    $reservationId: String
    $feedbackStatus: String
  ) {
    updateFeedbackStatus(
      reservationId: $reservationId
      feedbackStatus: $feedbackStatus
    ) {
      feedbackStatus
    }
  }
`;
