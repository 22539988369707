import React from "react";
import { Col, CustomInput, Row } from "reactstrap";
import toaster from "@components/common/Toaster";

const MultiCheckbox = ({ list, data, setData, setChangeMade, max }) => {
  const onChange = (id) => {
    setChangeMade && setChangeMade(true);

    if (data?.includes(id)) {
      setData(data.filter((v) => v !== id));
    } else {
      if (data?.length < max) {
        setData((prev) => [...prev, id]);
      } else {
        toaster("error", `Maximum limit of ${max} has reached`);
      }
    }
  };

  return (
    <Row>
      {list?.length > 0 &&
        list.map((rec) => {
          const foundIndex = data?.findIndex((item) => item === rec._id);

          return (
            <Col key={rec._id} md='3' className='py-1'>
              <CustomInput
                type='checkbox'
                checked={foundIndex >= 0}
                className='custom-control-Primary'
                id={rec._id}
                label={
                  foundIndex < 0 ? (
                    rec.name
                  ) : (
                    <p>
                      {rec.name} {"  "}
                      <sup className='text-light'>{foundIndex + 1}</sup>
                    </p>
                  )
                }
                onChange={() => onChange(rec._id)}
              />
            </Col>
          );
        })}
    </Row>
  );
};

export default MultiCheckbox;
