import { Table } from "reactstrap";

export default function AppraisalTabel(props) {
  const { appraisalData } = props;

  const tableDataStyle = {
    minWidth: "140px",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <div>
        <Table responsive size="sm" hover>
          <thead>
            <tr>
              <th style={tableDataStyle}>Appraised Date</th>
              <th style={tableDataStyle}>Appraisal Date</th>
              <th style={tableDataStyle}>Designation</th>
              <th style={tableDataStyle}>New Designation</th>
              <th style={tableDataStyle}>Old CTC</th>
              <th style={tableDataStyle}>New CTC</th>
              <th style={tableDataStyle}>Performance</th>
              <th style={tableDataStyle}>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {appraisalData?.map((i, index) => {
              return (
                <tr key={index}>
                  <td style={tableDataStyle}>
                    {new Date(i?.appraisedDate).toLocaleDateString("en-IN")}
                  </td>
                  <td style={tableDataStyle}>
                    {new Date(i?.appraisalDate).toLocaleDateString("en-IN")}
                  </td>
                  <td style={tableDataStyle}>{i?.designation}</td>
                  <td style={tableDataStyle}>{i?.newDesignation}</td>
                  <td style={tableDataStyle}>{i?.currentCTC}</td>
                  <td style={tableDataStyle}>{i?.newCTC}</td>
                  <td style={tableDataStyle}>{i?.performanceGrade}</td>
                  <td style={tableDataStyle}>{i?.remarks}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}
