import React, { useEffect, useState } from "react";
import { Plus, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHostel } from "@api";
import { useParams } from "react-router";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";
import MyEditor from "@components/common/Editor";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const HowToReach = () => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [highlight, setHighlight] = useState([
    {
      id: uuidv4(),
      heading: "",
      textlist: EditorState.createEmpty(),
    },
  ]);
  const [updateHostelHandler, { data: updatdedHostelData, error }] =
    useUpdateHostel();

  // useEffect(() => {
  //   if (updatdedHostelData?.updateHostel?.name) {
  //     toaster("success", "How to reach updated successfully");
  //   }
  // }, [updatdedHostelData]);

  useEffect(() => {
    if (state?.tableData?.howToReach?.length) {
      let upadtedHighlight = state?.tableData?.howToReach?.map((item) => {
        return {
          id: uuidv4(),
          heading: item?.heading,
          textlist: htmlToDraftConvert(item?.textlist),
        };
      });
      setHighlight(upadtedHighlight);
    }
  }, [state]);

  const handleChangeHighlightHeading = (heading, id) =>
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, heading: heading } : item
      )
    );

  const handleChangeHighlightTextlist = (textlist, id) =>
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, textlist: textlist } : item
      )
    );

  const handleRemoveHighlight = (id) =>
    confirmDelete(() =>
      setHighlight(highlight.filter((item) => item.id !== id))
    );

  const handleAddHighlight = () => {
    if (highlight.length < 5) {
      setHighlight([
        ...highlight,
        { id: uuidv4(), heading: "", textlist: EditorState.createEmpty() },
      ]);
    } else {
      toaster("error", "Max 5 are allowed");
    }
  };

  const submitHandler = (e) => {
    let isValid = false;
    e.preventDefault();
    highlight.forEach((item) => {
      delete item.id;
    });

    let updatedArray = highlight.map((item) => {
      let textlist = inputDataConvertor(item?.textlist);
      return { ...item, textlist: textlist };
    });
    const data = {
      howToReach: updatedArray,
    };

    updatedArray?.forEach((item) => {
      if (item?.heading && item?.textlist) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (
      isValid &&
      id &&
      data?.howToReach?.every((howToReach) => howToReach !== undefined)
    ) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          howToReach: updatedArray,
        })
      );
      updateHostelHandler(id, data);
      toaster("success", "How to reach updated successfully");
    } else {
      toaster("error", "Please fill in the details");
    }
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <Row className="mt-2">
          <Col>
            <Label className="form-label mt-2" for={"highlight"}>
              How to Reach
            </Label>
          </Col>
          {highlight.map(({ heading, textlist, id }, index) => (
            <Col className="d-flex align-items-end" sm="12" key={id}>
              <FormGroup className="w-100 mt-2">
                <Input
                  type="text"
                  value={heading}
                  id="name"
                  name="name"
                  placeholder="Heading"
                  onChange={(e) =>
                    handleChangeHighlightHeading(e.target.value, id)
                  }
                />
                <div className="mt-1 ">
                  <MyEditor
                    editorState={textlist}
                    setEditorState={(val) =>
                      handleChangeHighlightTextlist(val, id)
                    }
                  />
                </div>
              </FormGroup>
              {highlight.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveHighlight(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHighlight} />
      </form>
    </>
  );
};

export default HowToReach;
