import { Package, Circle } from "react-feather";

// const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

export default 
// role === "Listing" ||
// role === "Marketing" ||
// role === "Super Admin" ||
// role === "Sales Admin" ||
// role === "Marketing Admin" ||
// role === "Customer Experience" ||
modules?.includes("Packages")
  ? [
      {
        id: "packages",
        title: "Packages",
        icon: <Package size={20} />,
        badge: "light-warning",
        children: [
          // ! Added by Ashwin
          {
            id: "packagesDestinations",
            title: "Destinations",
            icon: <Circle size={12} />,
            navLink: "/package/destinations",
          },
          {
            id: "packagesHostels",
            title: "Hostels",
            icon: <Circle size={12} />,
            navLink: "/package/hostels",
          },
          // {
          //   id: "packagesWorkations",
          //   title: "Workations",
          //   icon: <Circle size={12} />,
          //   navLink: "/package/workations",
          // },
          // {
          //   id: "packagesTrips",
          //   title: "Trips",
          //   icon: <Circle size={12} />,
          //   navLink: "/package/trips",
          // },
          {
            id: "travelPackage",
            title: "Travel Packages",
            icon: <Circle size={12} />,
            navLink: "/package/travels",
          },
        ],
      },
    ]
  : [];
