//Added By Nitish
import gql from "graphql-tag";

export const GET_ALL_FEEDBACK_STATUS_DATA = gql`
  query getAllFeedbackStatusData($filter: filter) {
    getAllFeedbackStatusData(filter: $filter) {
      _id
      totalFeedback
    }
  }
`;

export const GET_HOSTELWISE_FEEDBACK_STATUS_DATA = gql`
  query getHostelWiseFeedbackStatusData($filter: filter) {
    getHostelWiseFeedbackStatusData(filter: $filter) {
      _id
      feedbacks
      totalFeedback
      hostelName
    }
  }
`;

export const GET_ALL_FINAL_STATUS_DATA = gql`
  query getAllFinalStatusData($filter: filter) {
    getAllFinalStatusData(filter: $filter) {
      _id
      count
      hostelName
    }
  }
`;

export const GET_HOSTEL_WISE_AVG_TAT = gql`
  query getHostelWiseAvgTat($filter: filter) {
    getHostelWiseAvgTat(filter: $filter) {
      hostelName
      avgTAT
      totalAvgTAT
      reviewCount
    }
  }
`;

export const GET_TOTAL_CUSTOMER_FEEDBACK_STATUS_DATA = gql`
  query getTotalCustomerFeedbackStatusData($filter: filter) {
    getTotalCustomerFeedbackStatusData(filter: $filter) {
      feedbackStatus
      totalCount
    }
  }
`;

export const GET_TOTAL_BAD_REMARK_WORD_COUNT_AND_DATA = gql`
  query getTotalBadRemarkWordCountAndData($filter: filter) {
    getTotalBadRemarkWordCountAndData(filter: $filter) {
      _id
      count
      hostelName
    }
  }
`;

export const GET_CX_UPDATED_BY_AVG_TAT_AND_COUNT = gql`
  query getCxUpdatedByAvgTatAndCount($filter: filter) {
    getCxUpdatedByAvgTatAndCount(filter: $filter) {
      _id
      closed
      avgTAT
    }
  }
`;
