import React, { useEffect, useState } from "react";
import { Col, Row, Input, Label, FormGroup } from "reactstrap";
import Flatpickr from "react-flatpickr";
import UploadImage from "./UploadImage";
import toaster from "@components/common/Toaster";
import SlideModal from "@components/common/slide-modal";
import { useAddOffer, useUpdateOffer, useUploadImage } from "@api";
import SwitchIcon from "@components/common/switch-icon";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";

const AddEditModal = ({
  open,
  handleToggle,
  editData,
  refetch: refetchOffers,
}) => {
  const [thumbPreview, setThumbPreview] = useState([]);
  const [coverPreview, setCoverPreview] = useState([]);
  const [title, setTitle] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [status, setStatus] = useState(false);
  const [addOfferHandler] = useAddOffer();
  const [updateOfferHandler] = useUpdateOffer();
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [offerDetails, setOfferDetails] = useState(() =>
    EditorState.createEmpty()
  );

  const [
    uploadThumbnailHandle,
    { data: thumbnailData, reset: thumbnailReset },
  ] = useUploadImage();

  const [
    uploadCoverImageHandle,
    { data: coverImageData, reset: coverImageReset },
  ] = useUploadImage();

  useEffect(() => {
    if (editData) {
      setTitle(editData.title);
      setCouponCode(editData.couponCode);
      setShortDescription(editData.shortDescription);
      setStatus(editData.status);
      setExpiryDate(editData.expiryDate);
      setThumbPreview([editData.thumbnail]);
      setCoverPreview([editData.coverImage]);
      setOfferDetails(htmlToDraftConvert(editData.offerDetails));
    } else {
      setOfferDetails(EditorState.createEmpty());
      setTitle("");
      setCouponCode("");
      setShortDescription("");
      setStatus(false);
      setExpiryDate(new Date());
      setThumbPreview([]);
      setCoverPreview([]);
    }
  }, [editData]);

  const onSubmitHandler = () => {
    if (
      (coverImageData?.uploadImage?.url || editData.coverImage) &&
      (thumbnailData?.uploadImage?.url || editData.thumbnail) &&
      title &&
      couponCode &&
      shortDescription &&
      expiryDate &&
      inputDataConvertor(offerDetails, "text")
    ) {
      const sendData = {
        title,
        couponCode,
        shortDescription,
        expiryDate,
        status,
        coverImage: coverImageData?.uploadImage?.url || editData.coverImage,
        thumbnail: thumbnailData?.uploadImage?.url || editData.thumbnail,
        offerDetails: inputDataConvertor(offerDetails),
      };

      if (editData?._id) {
        updateOfferHandler(editData._id, sendData);

        toaster("success", "Offer updated successfully");
      } else {
        addOfferHandler({
          ...sendData,
        });

        toaster("success", "Offer added successfully");
      }

      refetchOffers();

      handleToggle();

      thumbnailReset();
      coverImageReset();

      setOfferDetails(EditorState.createEmpty());
      setTitle("");
      setCouponCode("");
      setShortDescription("");
      setStatus(false);
      setExpiryDate(new Date());
      setThumbPreview([]);
      setCoverPreview([]);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      large
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="title">
                Title *
              </Label>

              <Input
                type="text"
                value={title}
                id="title"
                name="title"
                placeholder="Add title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="couponCode">
                couponCode *
              </Label>

              <Input
                type="text"
                value={couponCode}
                id="couponCode"
                name="couponCode"
                placeholder="Add coupon code"
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="shortDescription">
                Short Description *
              </Label>

              <Input
                type="textarea"
                value={shortDescription}
                id="shortDescription"
                name="shortDescription"
                placeholder="Add short description"
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="expiryDate">
                Expiry Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={expiryDate}
                onChange={(date) => setExpiryDate(date)}
                id="expiryDate"
              />
            </FormGroup>
          </Col>

          <Col sm="12" className="my-2">
            <MyEditor
              editorState={offerDetails}
              setEditorState={(val) => setOfferDetails(val)}
            />
          </Col>

          <UploadImage
            title="Thumbnail"
            uploadHandle={uploadThumbnailHandle}
            setPreview={setThumbPreview}
            preview={thumbPreview}
          />

          <UploadImage
            title="Cover-Image"
            uploadHandle={uploadCoverImageHandle}
            setPreview={setCoverPreview}
            preview={coverPreview}
          />
        </Row>

        <Col sm="12" className="mb-3">
          <SwitchIcon
            checked={status}
            id="status"
            name="status"
            label="Publish on Website ?"
            handleChecked={(val) => setStatus(val)}
          />
        </Col>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
