import gql from "graphql-tag";

export const GET_ALL_POSTS = gql`
query getposts{
    getAllPosts{
        _id
        title
        department
        description
        addedBy
        createdAt
        addedById
    }
}
`

export const GET_ALL_POST_REPLY = gql `
query getreply($id: ID){
    getAllReplyToPosts(id: $id){
        _id
        comment
        addedBy
        addedDateTime
        reply
        replyBy
        replyDateTime
        addedById
    }
}
`

export const GET_POST_DETAIL = gql`
query getdetail($id: ID){
    getDetailsOfPost(id: $id){
        _id
        image
        title
        addedBy
        description
        attachment
        disscussionEndDate
        hostels{
            _id 
            name
        }
        department
        addedBy
        createdAt
        addedById
    }
}
`

export const GET_REPLY_ON_POST = gql`
query getreply($id: ID){
    getAllReplyToPosts(id: $id){
        comment
    }
}
`

export const GET_ALL_USER_LIST_TO_TAG = gql`
query getUserList($name:String){
    getUserListToTag(name:$name){
        name
        id
    }
}
`