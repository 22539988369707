import { Edit2 } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const tableHeaderData = [
  "Full Name",
  "Email",
  "Phone Number",
  "Message",
  "Action",
];

const StepOutContactUsTable = ({ openModal, setId, data }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Step Out Contact Us</h2>
              </div>
            </CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item?.fullName}</td>
                  <td>{item?.emailId}</td>
                  <td>{item?.phoneNumber}</td>
                  <td>{item?.message}</td>
                  <td>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit2 size={15} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default StepOutContactUsTable;
