import React, { useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";

import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { useDeleteGluBanner } from "../../../api/dataSource";
import confirmDelete from "@components/common/confirmDelete";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "",
  "Hostels",
  "Banner Type",
  "Title",
  "Display Order",
];

const GluBannerTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState([]);
  const [deleteBannerHandler, { data: deletedBanner }] = useDeleteGluBanner();

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
      if (deletedBanner?.deleteGluBanner?.deletedStaus === true) {
        refetch();
      }
    }
  }, [data, deletedBanner]);

  const deleteHandler = (item) => {
    confirmDelete(() => deleteBannerHandler(item));
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Glu Banner </h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Glu Banner
            </Button.Ripple>
          </CardHeader>

          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData?.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "10px" }}>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td style={{ width: "10px" }}>
                    <Button
                      onClick={() => {
                        deleteHandler(item?._id);
                      }}
                      color="none"
                    >
                      <Trash2
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>

                  <td>
                    {item?.hostel?.length === 0
                      ? "All"
                      : item?.hostel?.map((hostels, index) => {
                          return (
                            <p title={hostels?.name} key={index}>
                              {hostels?.name}
                            </p>
                          );
                        })}
                  </td>
                  <td>{item?.imageType}</td>
                  <td>{item?.title}</td>
                  <td>{item?.displayOrder}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default GluBannerTable;
