import React from "react";
import { Col } from "reactstrap";
import Chart from "react-apexcharts";

const ScoreCard = ({ cardData, bgColor, color }) => {
  const cardStyle = {
    padding: "20px 15px 20px 15px",
    height: "10rem",
    width: "22rem",
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: "10px",
    alignItems: cardData?.positiveRate ? "" : "center",
    backgroundColor: bgColor,
    color: color,
    textAlign: "center",
  };

  const scoreStyle = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const titleStyle = {
    fontSize: "14px",
  };

  const barStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "20px",
    overflow: "hidden",
    margin: "10px",
  };

  const positiveBarStyle = {
    textAlign: "center",
    color: "#fff",
    height: "100%",
    lineHeight: "20px",
    fontSize: "12px",
    justifyContent: "flex-end",
    backgroundColor: "#4CAF50",
    width: `${cardData?.positiveRate}%`,
  };

  const negativeBarStyle = {
    textAlign: "center",
    color: "#fff",
    fontSize: "12px",
    height: "100%",
    lineHeight: "20px",
    backgroundColor: "#F44336",
    width: `${100 - cardData?.positiveRate}%`,
  };

  const chartOptions = {
    chart: {
      type: "donut",
    },
    labels: ["positive", "negative", "neutral"],
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 400,
        colors: ["#ffffff"],
      },
    },
    legend: false,

    theme: {
      palette: "palette5",
    },
  };

  return (
    <Col style={cardStyle}>
      {cardData?.image ? (
        <img
          src={cardData?.image}
          alt="img"
          style={{ height: "25px", width: "150px" }}
        />
      ) : (
        ""
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={cardData?.chart ? "pt-2 pl-2" : ""} style={scoreStyle}>
          {cardData?.score}
        </div>
        {cardData?.chart === true ? (
          <div className="mr-0">
            <Chart
              options={chartOptions}
              series={[
                Number(cardData?.positiveReviews),
                Number(cardData?.negativeReviews),
                Number(cardData?.neutralReviews),
              ]}
              type="pie"
              width="110"
            />
          </div>
        ) : null}
      </div>
      {cardData?.positiveRate ? (
        <div>
          <div style={barStyle}>
            <div style={positiveBarStyle}></div>

            <div style={negativeBarStyle}></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            <div style={{ color: "#4CAF50" }}>{cardData?.positiveRate}%</div>
            <div style={{ color: "#F44336" }}>
              {100 - cardData?.positiveRate}%
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div style={titleStyle}>{cardData?.title}</div>
    </Col>
  );
};

export default ScoreCard;
