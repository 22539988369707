import { v4 as uuidv4 } from "uuid";
import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";
import { Edit } from "react-feather";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Update",
  "Order Id",
  "OrderDate",
  "Name",
  "Number",
  "Address",
  "Status",
  "Products",
  "Tracking ID",
];

const MerchandiseOrdersTable = ({ tableData, openModal, setId }) => {
  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <div>
                  <h2>Merchandise Orders</h2>
                </div>
              </CardTitle>
            </CardHeader>

            <Table responsive>
              <thead>
                <tr>
                  {tableHeaderData.map((item) => {
                    return <th key={uuidv4()}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item) => (
                  <tr key={uuidv4()}>
                    <td>
                      <Button
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td>{item?.orderId}</td>
                    <td>{dateFormatterDDMMYYYY(item?.createdAt)}</td>
                    <td>{item?.user?.name}</td>
                    <td>{item?.user?.mobile}</td>
                    <td>
                      {item?.user?.addressLine1}, {item?.user?.landmark},{" "}
                      {item?.user?.city}, {item?.user?.state},{" "}
                      {item?.user?.pincode}
                    </td>
                    <td>{item?.status}</td>
                    <td>
                      {item?.products?.map((product, index) => (
                        <span key={index}>
                          {`${product?.name} x ${product?.quantity}`}
                          <br />
                        </span>
                      ))}
                    </td>

                    <td>{item?.shippingDetails?.trackingDetails}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MerchandiseOrdersTable;
