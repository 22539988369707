import React, { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  InputGroupText,
  InputGroup,
  FormText,
} from "reactstrap";
import Row from "reactstrap/lib/Row";
import Select from "react-select";
import SlideModal from "@components/common/slide-modal";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";
import {
  useAddHostel,
  useUpdateHostel,
  useAllAmenities,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";

const animatedComponents = makeAnimated();

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [name, setName] = useState("");
  const [brand, setBrand] = useState(null);
  const [businessType, setBusinessType] = useState(null);
  const [code, setCode] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [mapLink, setMapLink] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [reviewUrl, setReviewUrl] = useState("");
  const [onAmenities, setOnAmenities] = useState([]);
  const [offAmenities, setOffAmenities] = useState([]);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [reviewLinkOne, setReviewLinkOne] = useState("");
  const [reviewLinkTwo, setReviewLinkTwo] = useState("");
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [status, setStatus] = useState(false);
  const [isWorkation, setIsWorkation] = useState(false);
  const [RegistrationDetails, setRegistrationDetails] = useState("");
  const [addHostelHandler, { data }] = useAddHostel();
  const [updateHostelHandler, { data: updatdedHostelData }] = useUpdateHostel();
  const { data: amenitiesData } = useAllAmenities();

  const BrandList = [
    { label: "The Hosteller", value: "theHosteller" },
    { label: "Bam", value: "Bam" },
  ];

  const BusinessTypeList = [
    { label: "COCO", value: "COCO" },
    { label: "FOFO", value: "FOFO" },
    { label: "FOCO", value: "FOCO" },
    { label: "OMC", value: "OMC" },
  ];

  // useEffect(() => {
  //   if (data?.createHostel?.name || updatdedHostelData) {
  //     if (data?.createHostel?.name) {
  //       toaster("success", "Hostel added successfully");
  //     }

  //     if (updatdedHostelData) {
  //       toaster("success", "Hostel updated successfully");
  //     }
  //     refetch();
  //     handleToggle();
  //   }
  // }, [data, updatdedHostelData]);

  useEffect(() => {
    if (amenitiesData?.getAllAminities?.data?.length) {
      amenitiesData?.getAllAminities?.data?.map((item) => {
        setAmenitiesList((prev) => [
          ...prev,
          { ...item, label: item?.name, value: item?._id },
        ]);
      });
    }
  }, [amenitiesData?.getAllAminities?.data]);

  const formatEmenity = (amenities) => {
    setOnAmenities([]);
    setOffAmenities([]);

    amenities?.map((e) => {
      if (e.amenity?.name) {
        if (e.status) {
          setOnAmenities((prev) => [
            ...prev,
            { ...e.amenity, label: e.amenity?.name, value: e.amenity?._id },
          ]);
        } else {
          setOffAmenities((prev) => [
            ...prev,
            { ...e.amenity, label: e.amenity?.name, value: e.amenity?._id },
          ]);
        }
      }
      return;
    });
  };

  useEffect(() => {
    if (editData?.length) {
      const {
        address: { addressLine1, addressLine2, city, state, pincode },
        email,
        phone,
        name,
        amenities,
        locationUrl,
        reviewUrl,
        status,
        ezeeConfig: { hotelCode, authCode },
        reviewUrlList,
        isWorkation,
        RegistrationDetails,
      } = editData[0];

      formatEmenity(amenities);

      setReviewLinkOne(reviewUrlList?.one);
      setReviewLinkTwo(reviewUrlList?.two);
      setName(name);
      setEmail(email);
      setPhone(phone);
      setAddressLine1(addressLine1);
      setAddressLine2(addressLine2);
      setCity(city);
      setState(state);
      setPincode(pincode);
      setAuthCode(authCode);
      setCode(hotelCode);
      setMapLink(locationUrl);
      setLatitude(
        editData[0]?.location?.latitude === null
          ? ""
          : editData[0]?.location?.latitude
      );
      setLongitude(
        editData[0]?.location?.longitude === null
          ? ""
          : editData[0]?.location?.longitude
      );
      setReviewUrl(reviewUrl);
      setStatus(status);
      setIsWorkation(isWorkation);
      setBrand(
        editData[0]?.brand !== null
          ? {
              label:
                editData[0]?.brand === "theHosteller" ? "The Hosteller" : "Bam",
              value: editData[0]?.brand,
            }
          : null
      );
      setRegistrationDetails(
        RegistrationDetails !== null ? RegistrationDetails : ""
      );
      setBusinessType(
        editData[0]?.businessType !== null
          ? {
              label: editData[0]?.businessType,
              value: editData[0]?.businessType,
            }
          : null
      );
    } else {
      setReviewLinkOne("");
      setReviewLinkTwo("");
      setName("");
      setEmail("");
      setPhone("");
      setAddressLine1("");
      setAddressLine2("");
      setCity("");
      setState("");
      setPincode("");
      setAuthCode("");
      setCode("");
      setMapLink("");
      setLatitude("");
      setLongitude("");
      setReviewUrl("");
      setOnAmenities([]);
      setOffAmenities([]);
      setStatus(false);
      setIsWorkation(false);
      setBrand(null);
      setRegistrationDetails("");
      setBusinessType(null);
    }
  }, [editData]);

  const submitDataHandler = () => {
    const validMapLink = "https://www.google.com/maps";

    if (!mapLink?.includes(validMapLink))
      return toaster("error", "Invalid google map link");

    if (phone.length !== 10)
      return toaster("error", "Please add valid phone number");

    if (!email.includes("@") || !email.includes("."))
      return toaster("error", "Please add valid email");

    const updatedAmenities = onAmenities?.map((item) => item._id);

    const amenities = [];

    onAmenities.map((e) => {
      amenities.push({ amenity: e._id, status: true });
    });

    offAmenities.map((e) => {
      amenities.push({ amenity: e._id, status: false });
    });

    let data = {
      name: name,
      phone: phone,
      email: email?.toLowerCase(),
      locationUrl: mapLink,
      reviewUrl,
      status,
      ezeeConfig: {
        hotelCode: code,
        authCode: authCode,
      },
      reviewUrlList: {
        one: reviewLinkOne,
        two: reviewLinkTwo,
      },
      amenities,
      address: {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        pincode: pincode,
      },
      location: {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
      },
      isWorkation,
      // url: `/hostels/${name.toLowerCase().split(" ").join("-")}`,
      brand: brand?.value,
      RegistrationDetails,
      businessType: businessType?.value,
    };

    if (
      name &&
      phone &&
      email &&
      mapLink &&
      reviewUrl &&
      updatedAmenities?.length &&
      code &&
      authCode &&
      addressLine1 &&
      city &&
      state &&
      pincode &&
      reviewLinkOne &&
      latitude &&
      longitude &&
      brand
      // && amenities?.length
    ) {
      if (editData?.length) {
        const { _id } = editData[0];

        updateHostelHandler(_id, data);
        toaster("success", "Hostel updated successfully");
      } else {
        addHostelHandler(data);
        toaster("success", "Hostel added successfully");
      }

      setEditId(null);

      setName("");
      setEmail("");
      setPhone("");
      setAddressLine1("");
      setAddressLine2("");
      setCity("");
      setState("");
      setPincode("");
      setAuthCode("");
      setCode("");
      setMapLink("");
      setLatitude("");
      setLongitude("");
      setReviewLinkOne("");
      setReviewLinkTwo("");
      setOnAmenities([]);
      setOffAmenities([]);
      setStatus(false);
      setIsWorkation(false);
      setBrand(null);
      setRegistrationDetails("");
      handleToggle();
      setBusinessType(null);
    } else {
      toaster("error", "Please fill all the required details marked with (*)");
    }
    refetch();
  };

  const handleChangeReviewUrl = (e) => {
    if (e.target.id === "reviewLinkOne") {
      setReviewUrl(reviewLinkOne);
    } else if (e.target.id === "reviewLinkTwo") {
      setReviewUrl(reviewLinkTwo);
    } else setReviewUrl();
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label for="onAmenities">Brand*</Label>
              <Select
                isSearchable={true}
                isClearable={false}
                id="staffRole"
                name="staffRole"
                value={brand}
                options={BrandList}
                onChange={(value) => setBrand(value)}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                Hostel Name*
              </Label>
              <Input
                type="text"
                value={name}
                id="name"
                name="name"
                placeholder="Add Hostel"
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label for="onAmenities">Business Type</Label>
              <Select
                isSearchable={true}
                isClearable={false}
                id="businessType"
                name="businessType"
                value={businessType}
                options={BusinessTypeList}
                onChange={(value) => setBusinessType(value)}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                Auth Code*
              </Label>
              <Input
                type="text"
                value={authCode}
                id="Auth Code"
                name="Auth Code"
                placeholder="Auth Code"
                onChange={(e) => setAuthCode(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                Property Code*
              </Label>
              <Input
                type="text"
                value={code}
                id="Property Code"
                name="Property Code"
                placeholder="Property Code"
                onChange={(e) => setCode(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="phone">
                Phone*
              </Label>
              <Input
                type="number"
                value={phone}
                id="phone"
                name="phone"
                placeholder="Phone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="Email">
                Email*
              </Label>
              <Input
                type="email"
                value={email}
                id="Email"
                name="Email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="RegistrationDetails">
                Registration Details
              </Label>
              <Input
                type="text"
                value={RegistrationDetails}
                id="Registration Details"
                name="Registration Details"
                placeholder="Registration Details"
                onChange={(e) => setRegistrationDetails(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="addressLine1">
                Address Line 1*
              </Label>
              <Input
                type="text"
                value={addressLine1}
                id="addressLine1"
                name="addressLine1"
                placeholder="Add Address"
                onChange={(e) => setAddressLine1(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="addressLine2">
                Address Line 2
              </Label>
              <Input
                type="text"
                value={addressLine2}
                id="addressLine2"
                name="addressLine2"
                placeholder="Add Address"
                onChange={(e) => setAddressLine2(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="city">
                City*
              </Label>
              <Input
                type="text"
                value={city}
                id="city"
                name="city"
                placeholder="Add City"
                onChange={(e) => setCity(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="state">
                State*
              </Label>
              <Input
                type="text"
                value={state}
                id="state"
                name="state"
                placeholder="Add State"
                onChange={(e) => setState(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="pincode">
                Pincode*
              </Label>
              <Input
                type="number"
                value={pincode}
                id="pincode"
                name="pincode"
                placeholder="Add Pincode"
                onChange={(e) => setPincode(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup>
              <Label for="onAmenities">Amenities (Include)*</Label>
              <Select
                onChange={(value) => setOnAmenities(value)}
                isClearable={false}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={onAmenities}
                isMulti
                options={amenitiesList}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup>
              <Label for="offAmenities">Amenities (Exclude)*</Label>
              <Select
                onChange={(value) => setOffAmenities(value)}
                isClearable={false}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={offAmenities}
                isMulti
                options={amenitiesList}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="mapLink">
                Google Map Link*
              </Label>
              <Input
                type="text"
                value={mapLink}
                id="mapLink"
                name="mapLink"
                placeholder="Add Map Link"
                onChange={(e) => setMapLink(e.target.value)}
              />
              <FormText className="text-muted">
                *the embedded link should start with :
                https://www.google.com/maps/
              </FormText>
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="latitude">
                Latitude*
              </Label>
              <Input
                type="text"
                value={latitude}
                id="latitude"
                name="latitude"
                placeholder="Add Latitude"
                onChange={(e) => setLatitude(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="longitude">
                Longitude*
              </Label>
              <Input
                type="text"
                value={longitude}
                id="longitude"
                name="longitude"
                placeholder="Add Longitude"
                onChange={(e) => setLongitude(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12" className="mt-2">
            <h4>Reviews</h4>
          </Col>
          <Col sm="12">
            <p>Add & Select any one review link</p>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="reviewUrl">
                Review Link One*
              </Label>
              <InputGroup>
                <InputGroupText className="rounded-0">
                  <div className="form-check">
                    <Input
                      type="radio"
                      id="reviewLinkOne"
                      name="reviewUrl"
                      disabled={!reviewLinkOne}
                      checked={reviewUrl === reviewLinkOne}
                      onChange={handleChangeReviewUrl}
                    />
                  </div>
                </InputGroupText>
                <Input
                  type="text"
                  id="reviewLinkOne"
                  name="reviewLinkOne"
                  value={reviewLinkOne}
                  onChange={(e) => setReviewLinkOne(e.target.value)}
                  placeholder="Review Link One"
                />
              </InputGroup>
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="reviewUrl">
                Review Link Two
              </Label>
              <InputGroup>
                <InputGroupText className="rounded-0">
                  <div className="form-check">
                    <Input
                      type="radio"
                      id="reviewLinkTwo"
                      name="reviewUrl"
                      disabled={!reviewLinkTwo}
                      checked={reviewUrl === reviewLinkTwo}
                      onChange={handleChangeReviewUrl}
                    />
                  </div>
                </InputGroupText>
                <Input
                  type="text"
                  id="reviewLinkTwo"
                  name="reviewLinkTwo"
                  value={reviewLinkTwo}
                  onChange={(e) => setReviewLinkTwo(e.target.value)}
                  placeholder="Review Link Two"
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm="12" style={{ marginBottom: "12px" }}>
            <SwitchIcon
              checked={isWorkation}
              id="isWorkation"
              name="isWorkation"
              label="Is Workation ?"
              handleChecked={(val) => setIsWorkation(val)}
            />
          </Col>

          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="isStatus"
              name="isStatus"
              label="Publish on Website ?"
              handleChecked={(val) => setStatus(val)}
            />
          </Col>

          {/* <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Publish on Website ?"
              handleChecked={(val) => setStatus(val)}
            />
          </Col> */}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
