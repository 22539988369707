import React, { useEffect, useState } from "react";
import HotelCenterTable from "./HotelCenterTable";
import { useGetHostelsForHotelCenter } from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import { CSVLink } from "react-csv";

const tableHeaderData = [
  { label: "id", key: "id" },
  { label: "name", key: "name" },
  { label: "addr1", key: "addr1"},
  { label: "addr2", key: "addr2"},
  { label: "city", key: "city"},
  { label: "province", key: "province" },
  { label: "postal_code", key: "postal_code" },
  { label: "country", key: "country" },
  { label: "latitude", key: "latitude" },
  { label: "longitude" ,key: "longitude" },
  { label: "category", key: "category" },
  { label: "hotel_brand", key: "hotel_brand" },
  { label: "phone", key: "phone" },
  { label: "star_rating", key: "star_rating"},
  {label: "alternate_hotel_id", key: "alternate_hotel_id"}
];

const HotelCenter = () => {
  const [tableData, setTableData] = useState([]);
  const { data, refetch } = useGetHostelsForHotelCenter();
  
  console.log(data?.getHostelsForHotelCenter)

  useEffect(() => {
    if (data?.getHostelsForHotelCenter) {
      setTableData(data?.getHostelsForHotelCenter);
    } else {
      setTableData([]);
    }
  }, [data]);

  
  return (
    localStorage.getItem("modules")?.includes("Reports") &&  <div>
      <Row>
        <Col sm="2">
          <CSVLink
            headers={tableHeaderData}
            data={tableData}
            filename={"Google_Hotel_Center_hotelList.csv"}
          >
            <Button.Ripple
              color="success"
              appearance="success"
              type="button"

              style={{
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
            >
              Export
            </Button.Ripple>
          </CSVLink>
        </Col>
      </Row>
      <HotelCenterTable data={tableData} refetch={refetch} />
    </div>
  );
};

export default HotelCenter;
