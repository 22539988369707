import { useEffect, useState } from "react";
import { MoreVertical, Edit, Trash } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import {
  Table,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { dateFormatHandler } from "@utils";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Name",
  "Status",
  "Promo Code",
  "Product",
  "Discount (%)",
  "Expires on",
  "Quantity",
  "Number of usage",
];

const CouponsTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <h2> Coupons</h2>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Coupon
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            {tableData.length > 0 ? (
              <tbody>
                {tableData?.map((item) => (
                  <tr key={uuidv4()}>
                    <td style={{ width: "5%" }}>
                      {/* <UncontrolledDropdown>
                      <DropdownToggle
                        className="icon-btn hide-arrow"
                        color="transparent"
                        size="sm"
                        caret
                      >
                        <MoreVertical size={15} />
                      </DropdownToggle> */}
                      {/* <DropdownMenu right> */}
                      <DropdownItem
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                      >
                        <Edit
                          className="mr-50"
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                        {/* <span className="align-middle">Edit</span> */}
                      </DropdownItem>
                      {/* <DropdownItem
                          href="/"
                          onClick={(e) => handleDelete(e, item)}
                        >
                          <Trash className="mr-50" size={15} />
                          <span className="align-middle">Delete</span>
                        </DropdownItem> */}
                      {/* </DropdownMenu> */}
                      {/* </UncontrolledDropdown> */}
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.status}</td>
                    <td>{item?.promoCode}</td>
                    <td>{item?.product}</td>
                    <td>{item?.discount}</td>
                    <td>
                      {item?.expirationDate
                        ? dateFormatHandler(item?.expirationDate)
                        : ""}
                    </td>
                    <td>{item?.quantity}</td>
                    <td>{item?.numberOfUsage}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td
                    colSpan={tableHeaderData.length}
                    style={{ textAlign: "center", height: "150px" }}
                  >
                    <h2>No Record Found</h2>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default CouponsTable;
