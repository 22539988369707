// ! Coded by Ashwin

import gql from "graphql-tag";

export const GET_PAGINATED_WHATSNEW = gql`
query getPaginatedWhatsNewList($filter: filter) {
    getPaginatedWhatsNewList(filter: $filter) {
      count
      page
      limit
      data {
            _id
            tagLine
            image
            title
            description
            url
            status
            buttonText
            displayOrder
      }
    }
}
`