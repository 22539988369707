import gql from "graphql-tag";

export const GET_STEP_OUT_HOMEPAGE_DETAILS = gql`
  query getStepOutHomepageDetails($slug: String) {
    getStepOutHomepageDetails(slug: $slug) {
      _id
      title
      subTitle
      seo {
        metaTitle
        metaDesc
        keywords
      }
      flavour {
        title
        highlightedText
      }
      category {
        _id
        name
      }
      keyFeatures {
        title
        highlightedText
        features {
          image
          title
          description
          displayOrder
        }
      }
      moments {
        title
        highlightedText
        description
        items {
          imageUrl
          tag
          description
          displayOrder
        }
      }
      blogs {
        _id
        blogTitle
      }
      locations {
        _id
        title
      }
    }
  }
`;
