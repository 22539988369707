import React, { useState, useEffect } from "react";
import { Col, FormGroup, Input, Label, Row, CustomInput } from "reactstrap";
import SlideModal from "@components/common/slide-modal";
import { useCreateWallet, useUpdateWallet } from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import Select from "react-select";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [email, setEmail] = useState("");
  const [createUserAccount, setCreateUserAccount] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState(null);
  const [description, setDescription] = useState("");
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [refReservationId, setRefReservationId] = useState("");
  const [status, setStatus] = useState(true);
  const [logs, setLogs] = useState(editData?.logs ? editData?.logs : []);

  const [createWalletHandler, { data: createWalletData }] = useCreateWallet();
  const [updateWalletHandler, { data: updateWalletData }] = useUpdateWallet();

  const ReasonList = [
    { label: "Booking cancellation", value: "Booking cancellation" },
    { label: "Customer delight", value: "Customer delight" },
    { label: "I&P benefits", value: "I&P benefits" },
    { label: "Employee benefits", value: "Employee benefits" },
    { label: "Marketing", value: "Marketing" },
    { label: "Owners", value: "Owners" },
  ];

  useEffect(() => {
    if (createWalletData?.createWallet?.email || updateWalletData) {
      refetch();
      if (createWalletData?.createWallet?.email) {
        handleToggle();
      }
      toaster("success", "Wallet added successfully");
    }
  }, [createWalletData?.createWallet?.email, updateWalletData]);

  useEffect(() => {
    if (editData?._id) {
      setEmail(editData?.email);
      setTransactionId(editData?.transactionId);
      setAmount(editData?.amount);
      setReason(
        editData?.reason
          ? { label: editData?.reason, value: editData?.reason }
          : null
      );
      setDescription(editData?.description);
      setTransactionDate(editData?.transactionDate);
      setRefReservationId(editData?.refReservationId);
      setStatus(editData?.status);
      setLogs(editData?.logs ? editData?.logs : []);
    } else {
      setEmail("");
      setTransactionId("");
      setAmount("");
      setReason(null);
      setDescription("");
      setTransactionDate(new Date());
      setRefReservationId("");
      setStatus(true);
      setLogs([]);
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const data = {
      email: email.toLowerCase(),
      amount: Number(amount),
      reason: reason?.value,
      description,
      transactionDate: dateFormatter(transactionDate),
      refReservationId,
      status,
      logs: [
        ...logs,
        `${localStorage.getItem("name")} ${
          editData ? "modified" : "created"
        } this wallet details on ${new Date().toLocaleString("en-IN", {
          hour12: false,
        })}`,
      ],
      createUserAccount,
      fullName,
      mobile,
    };
    if (email && amount && reason && transactionDate) {
      if (editData?._id) {
        const id = editData?._id;

        updateWalletHandler(id, data);
        handleToggle();
      } else {
        createWalletHandler(data);
      }
      setEmail("");
      setTransactionId("");
      setAmount("");
      setReason(null);
      setDescription("");
      setTransactionDate(new Date());
      setRefReservationId("");
      setStatus(true);
      setLogs([]);
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {editData?._id && (
            <Col sm="12">
              <FormGroup className="w-100">
                <strong>
                  <h5
                    style={{
                      backgroundColor: "#ff0000",
                      padding: "5px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Cannot edit the wallet balance details
                  </h5>
                </strong>
              </FormGroup>
            </Col>
          )}

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="email">
                Email*
              </Label>
              <Input
                type="email"
                value={email}
                id="email"
                name="email"
                placeholder="Add Email"
                onChange={(e) => setEmail(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <CustomInput
                type="checkbox"
                id="createUserAccount"
                name="user account"
                value={createUserAccount}
                label="Create User Account"
                className="custom-control-Primary"
                onChange={(e) => setCreateUserAccount(e.target.checked)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          {createUserAccount && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="fullName">
                  Full Name*
                </Label>
                <Input
                  type="text"
                  value={fullName}
                  id="fullName"
                  name="fullName"
                  placeholder="Add Full Name"
                  onChange={(e) => setFullName(e.target.value)}
                  isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}
          {createUserAccount && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="mobile">
                  Mobile Number*
                </Label>
                <Input
                  type="mobile"
                  value={mobile}
                  id="mobile"
                  name="mobile"
                  placeholder="Add Mobile Number"
                  onChange={(e) => setMobile(e.target.value)}
                  isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="amount">
                Amount*
              </Label>
              <Input
                type="text"
                value={amount}
                id="amount"
                name="amount"
                placeholder="Add Amount"
                onChange={(e) => setAmount(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="reason">
                Reason*
              </Label>
              <Select
                isClearable={false}
                id="reason"
                name="reason"
                value={reason}
                options={ReasonList}
                onChange={(value) => setReason(value)}
                classNamePrefix="select"
                isDisabled={editData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="description">
                Description
              </Label>
              <Input
                type="textarea"
                value={description}
                id="description"
                name="description"
                placeholder="Add Description"
                onChange={(e) => setDescription(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100 hidden">
              <Label className="form-label" for="transactionDate">
                Transaction Date*
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={transactionDate}
                onChange={(date) => setTransactionDate(date)}
                id="transactionDate"
                placeholder="select transaction date"
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="refReservationId">
                Reference Reservation Id
              </Label>
              <Input
                type="text"
                value={refReservationId}
                id="refReservationId"
                name="refReservationId"
                placeholder="Add Reservation Id"
                onChange={(e) => setRefReservationId(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="transactionId">
                Transaction Id*
              </Label>
              <Input
                type="text"
                value={transactionId}
                id="transactionId"
                name="transactionId"
                placeholder="Add Full Name"
                onChange={(e) => setTransactionId(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col> */}

          <Col sm="12" className="hidden">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Status* ?"
              handleChecked={(value) => setStatus(value)}
              disabled={editData?._id}
            />
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="logs"></Label>
              <br />
              {logs
                ?.map((log, i) => {
                  return (
                    <Label className="form-label" for="name" key={i}>
                      {log}.
                    </Label>
                  );
                })
                .reverse()}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
