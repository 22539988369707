import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { taxOptions, typeOptions } from "./AddEditModal";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { useUploadImage } from "../../../api/dataSource";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

import { useUpdateMasterMenu } from "../../../api/Unbox/UnboxMasterMenu/Mutations";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useGetMenuCategoryList } from "../../../api/Unbox/Queries";

const Overview = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [itemName, setItemName] = useState("");
  const [menuCategoryList, setMenuCategoryList] = useState([]);
  const [itemCategory, setItemCategory] = useState(null);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [type, setType] = useState(null);
  const [weekDayPrice, setWeekDayPrice] = useState(0);
  const [weekEndPrice, setWeekEndPrice] = useState(0);
  const [hsnCode, setHsnCode] = useState("");
  const [tax, setTax] = useState(null);
  const [nudge, setNudge] = useState("");
  const state = useSelector((state) => state?.cms);

  const { data: allCategoryList } = useGetMenuCategoryList();

  const [updateMasterMenuHandler, { data: updateMasterMenuData }] =
    useUpdateMasterMenu();
  const [uploadImageHandler, { data: imageData, loading: imageLoad, error }] =
    useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2097152,
    },
  });
  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    //console.log(file);
    setImage([preview]);
    uploadImageHandler(file.data, "icons/UnboxMasterMenu");
    toaster("success", "Image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading amenity image: ${error.message}`);
  });

  const renderPreview = () =>
    image ? (
      <img className="rounded mr-1 w-50" src={image} alt="avatar" />
    ) : null;
  useEffect(() => {
    setImage(imageData?.uploadImage?.url);
  }, [imageData]);

  useEffect(() => {
    if (allCategoryList?.getAllUnboxMenuCategory?.length) {
      let list = allCategoryList?.getAllUnboxMenuCategory?.map((ele) => {
        return { label: ele?.categoryName, value: ele?.categoryName };
      });
      setMenuCategoryList(list);
    }
  }, [allCategoryList?.getAllUnboxMenuCategory]);

  useEffect(() => {
    if (state?.tableData) {
      setItemName(state?.tableData?.itemName ? state?.tableData?.itemName : "");
      setItemCategory(
        state?.tableData?.itemCategory
          ? {
              label: state?.tableData?.itemCategory,
              value: state?.tableData?.itemCategory,
            }
          : null
      );
      setDescription(
        state?.tableData?.description ? state?.tableData?.description : ""
      );
      setImage(
        state?.tableData?.image !== null ? state?.tableData?.image : null
      );
      setType(
        state?.tableData?.type
          ? { label: state?.tableData?.type, value: state?.tableData?.type }
          : null
      );
      setWeekDayPrice(
        state?.tableData?.weekDayPrice !== null
          ? state?.tableData?.weekDayPrice
          : 0
      );
      setWeekEndPrice(
        state?.tableData?.weekEndPrice !== null
          ? state?.tableData?.weekEndPrice
          : 0
      );
      setHsnCode(
        state?.tableData?.hsnCode !== null ? state?.tableData?.hsnCode : ""
      );
      setTax(
        state?.tableData?.tax !== null
          ? { label: state?.tableData?.tax, value: state?.tableData?.tax }
          : 5
      );
      setNudge(state?.tableData?.nudge !== null ? state?.tableData?.nudge : "");
    }
  }, [state]);

  const handleAddPrice = (val, name) => {
    if (!isNaN(val) && !val?.includes("-")) {
      if (name === "weekDayPrice") {
        setWeekDayPrice(val);
      } else {
        setWeekEndPrice(val);
      }
    } else {
      toaster("error", "Enter Number Only");
    }
  };

  const handleAddString = (value, name) => {
    if (value !== " ") {
      if (name === "itemName") {
        setItemName(value);
      }
    } else {
      toaster("error", "No initial space");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      itemName,
      itemCategory: itemCategory?.value,
      description,
      image,
      type: type?.value,
      weekDayPrice: +weekDayPrice,
      weekEndPrice: +weekEndPrice,
      hsnCode,
      tax: +tax?.value || 5,
      nudge,
    };
    if (
      data?.itemName?.trim() !== "" &&
      data?.itemCategory?.trim() !== "" &&
      data?.weekDayPrice &&
      data?.weekEndPrice
    ) {
      updateMasterMenuHandler(id, data);
      toaster("success", "MasterMenu Update successfully");
      dispatch(tableDataHandler({ ...state?.tableData, ...data }));
    } else {
      toaster("error", "Please fill all the required details properly");
    }
  };

  return (
    <div>
      <form onSubmit={submitHandler}>
        <Row>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="itemName">
                Item Name*
              </Label>
              <Input
                type="text"
                value={itemName}
                id="itemName"
                name="itemName"
                placeholder="Add Item Name"
                onChange={(e) => handleAddString(e.target.value, "itemName")}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="itemCategory">
                Item Category*
              </Label>
              <Select
                isClearable={true}
                id="itemCategory"
                name="itemCategory"
                required
                theme={selectThemeColors}
                options={menuCategoryList}
                value={itemCategory}
                onChange={(value) => setItemCategory(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="description">
                Description
              </Label>
              <Input
                type="text"
                value={description}
                id="description"
                name="description"
                placeholder="Add Item Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="type">
                Type
              </Label>
              <Select
                isClearable={true}
                id="type"
                name="type"
                theme={selectThemeColors}
                options={typeOptions}
                value={type}
                onChange={(value) => setType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="weekDayPrice">
                WeekDay Price*
              </Label>
              <Input
                type="text"
                value={weekDayPrice}
                id="weekDayPrice"
                name="weekDayPrice"
                maxLength={9}
                placeholder="Add WeekDay Price"
                onChange={(e) => handleAddPrice(e.target.value, "weekDayPrice")}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="weekEndPrice">
                WeekEnd Price*
              </Label>
              <Input
                type="text"
                value={weekEndPrice}
                id="weekEndPrice"
                name="weekEndPrice"
                maxLength={9}
                placeholder="Add WeekEnd Price"
                onChange={(e) => handleAddPrice(e.target.value, "weekEndPrice")}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="tax">
                Tax(%)
              </Label>
              <Select
                value={tax}
                required
                options={taxOptions}
                theme={selectThemeColors}
                id="tax"
                name="tax"
                placeholder="Select Tax Percentage"
                onChange={(value) => setTax(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="hsnCode">
                HsnCode
              </Label>
              <Input
                type="text"
                value={hsnCode}
                id="hsnCode"
                name="hsnCode"
                placeholder="Hsn Code"
                onChange={(e) => setHsnCode(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="nudge">
                Nudge
              </Label>
              <Input
                type="text"
                value={nudge}
                id="nudge"
                name="nudge"
                placeholder="Add Nudge"
                onChange={(e) => setNudge(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="10" md="11">
            <div
              style={{
                display: "flex",
                margin: "auto",
              }}
            >
              <div style={{ width: "30%" }}>
                <Label className="form-label">Menu Image</Label>
                <DragDrop
                  note="Image size should be less than 2MB"
                  width="80%"
                  uppy={uppy}
                  locale={{
                    strings: {
                      dropHereOr: "Drop image here or %{browse}",
                      browse: "browse",
                    },
                  }}
                />
              </div>
              <div
                className="service-icon-preview"
                style={{
                  width: "40%",
                  //heigth: "360px",
                  margin: "20px 0",
                  padding: "5px",
                }}
              >
                {imageLoad ? "Uploading icon..." : <>{renderPreview()}</>}
              </div>
            </div>
          </Col>
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default Overview;
