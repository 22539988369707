import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const GET_ALL_COUPONS = gql`
  query getUnboxCoupon {
    getUnboxCoupon {
      couponTitle
      cafe {
        cafeName
        _id
      }
      promoCode
      couponType
      status
      discount
      expiryDate
      maxDiscountValue
      minOrderValue
      _id
      description
      couponCategory
      couponDays
    }
  }
`;

export const useGetAllUnboxCoupon = (Id) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_COUPONS);
  useLoadingHandler(loading);
  return { data, error, refetch };
};
