import React from "react";
import AddEditModal from "./AddEditModal";
import DisplayAdsTable from "../../../@core/components/tables/DisplayAdsTable";

const DisplayAds = () =>
  localStorage.getItem("modules")?.includes("CMS") && (
    <DisplayAdsTable AddEditModal={AddEditModal} />
  );

export default DisplayAds;
