import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "@utils";


const tableHeaderData = [
  "Hostel Name",
  "Reservation No",
  "Booking Date",
  "Checkin-Checkout",
  "No of Nights",
  "Room Details (RoomName X Quant)",
  "Room Price",
  "Extra Charge (Charge X Quant X Rate)",
  "Extra-Charges Amount",
  "Total Amount",
];

const ExtraChargesTable = ({ data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);
  console.log(tableData);
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Website Extra Charges Report</CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td><Badge color = 'light-warning'>
                    {item?.hostelId?.name}
                  </Badge>
                  </td>
                  <td><Badge color = 'light-info'>
                    {item?.providerRefId}
                  </Badge>
                  </td>
                  <td>{dateFormatHandler(item?.createdAt)}</td>
                  <td>{item?.checkinDate
                      ? dateFormatHandler(item?.checkinDate)
                      : ""}{" "}
                    -{" "}
                    {item?.checkoutDate
                      ? dateFormatHandler(item?.checkoutDate)
                      : ""}
                  </td>
                  <td>{item?.noOfNights}</td>
                  <td>
                    <tr>
                  {/* <Table size="sm" hover responsive>
                      {/* <thead>
                        <th>Name</th>
                        <th>Quant</th>
                      </thead> */}
                      {/* <tbody>  */}
                        {item?.rooms.map((room) => {
                          return (
                            <tr>
                              <font size="2">{room?.roomName}</font>{" X "}<font size="2">{room?.quantity}</font>
                              {/* <td>{room?.roomName}</td>
                              <td>X</td>
                              <td>{room?.quantity}</td> */}
                            </tr>
                          );
                        })}
                        </tr>
                      {/* </tbody>
                    </Table> */}
                  </td>
                  <td><h6>{item?.totalRoomPrice.toFixed(2)}</h6></td>
                  <td>
                    {/* <Table size="sm" hover responsive> */}
                      {/* <thead>
                        <th>Charge</th>
                        <th>Quant</th>
                        <th>Rate</th>
                      </thead> */}
                      {/* <tbody> */}
                        {item?.extraCharges.map((extra) => {
                          return (
                            <tr>
                              <Badge color="light-secondary">{extra?.charge }</Badge> X { extra?.qty} X { extra?.rate}
                            </tr>
                          );
                        })}
                      {/* </tbody> */}
                    {/* </Table> */}
                  </td>
                  
                  
                  <td><h6>{item?.totalExtraCharges.toFixed(2)}</h6></td>
                  <td><h6>{item?.payableAmount.toFixed(2)}</h6></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default ExtraChargesTable;
