import React, { useState } from "react";
import Offcanvas from "./Offcanvas";
import { formatDateToDDMMYYYY } from "./helpers";
import DefaultHosteller from "./icons/Logo-WxB 5.png";

const bookingSourceImages = {
  "booking.com":
    "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg",
  goibibo:
    "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg",
  hostelworld:
    "https://static.thehosteller.com/Hostel/Media/hostelworld%202%20(1)-1695295577721.svg",
  google:
    "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg",
  makemytrip:
    "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg",
  tripadvisor:
    "https://static.thehosteller.com/Hostel/Media/Tripadvisor%20(1)-1695295571996.svg",
};

const defaultColor = "grey";
const StatusColourCode = [
  { label: "Arrived", value: "#F16363" },
  { label: "Void", value: "grey" },
  { label: "No Show", value: defaultColor },
  { label: "Due Out", value: "#993525" },
  { label: "Unconfirmed Reservation", value: "#F04E4F" },
  { label: "Checked Out", value: "#3568FA" },
  { label: "Confirmed Reservation", value: "#60CD10" },
  { label: "Cancel", value: "red" },
  { label: "Stayover", value: "#336666" },
  // { label: "Maintenance Block", value: "#666666" },
];

function SearchResultCard({
  source,
  name,
  reservationId,
  nights,
  roomNo,
  roomType,
  BookingStatus,
  mobileNo,
  email,
  ArrivalDate,
  ArrivalTime,
  DepartureDate,
  DepartureTime,
  bookingDate,
  totalAmount,
  hostelId,
  Adult,
  Child,
  Deposit,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const formatedDateTime = (datetime) => {
    const [date, time] = datetime.split("T");
    return `${formatDateToDDMMYYYY(date)} ${time}`;
  };

  const getDateAndMonth = (dateinYYYMMDD) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateinYYYMMDD);
    return {
      date: date.getDate(),
      month: monthNames[date.getMonth()],
    };
  };

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const sanitizeCharacter = (str) => {
    return str.replace(/\s+/g, "").toLowerCase();
  };
  return (
    <>
      <section
        onClick={() => setModalOpen(true)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          gap: "20px",
          // border: "1px solid black",
          padding: "5px 10px",
          cursor: "pointer",
          border: "1px solid #ddd",
        }}
      >
        {bookingSourceImages[sanitizeCharacter(source)] && (
          <img
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "100%",
              objectFit: "contain",
            }}
            src={bookingSourceImages[sanitizeCharacter(source)]}
            // src={bookingSourceImages}
          />
        )}
        <div
          style={{ display: "flex", flexDirection: "column", width: "110px" }}
        >
          <strong>{name?.slice(0, 20)}</strong>
          {"\n"}
          <p>{reservationId}</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <section
            style={{
              width: "45px",
              height: "45px",
              backgroundColor: "#F2F6F9",
              padding: "5px , 5px",
              textAlign: "center",
            }}
          >
            {getDateAndMonth(ArrivalDate?.split("T")?.[0]).date} {"\n"}
            <p>{getDateAndMonth(ArrivalDate?.split("T")?.[0]).month}</p>
          </section>
          <section
            style={{
              width: "45px",
              height: "45px",
              backgroundColor: "#E6EAED",
              padding: "5px , 5px",
              textAlign: "center",
            }}
          >
            <strong>{nights}</strong> {"\n"}Night
          </section>
          <section
            style={{
              width: "45px",
              height: "45px",
              backgroundColor: "#F2F6F9",
              padding: "5px , 5px",
              textAlign: "center",
            }}
          >
            {getDateAndMonth(DepartureDate?.split("T")?.[0]).date} {"\n"}
            <p>{getDateAndMonth(DepartureDate?.split("T")?.[0]).month}</p>
          </section>
        </div>
        <div style={{ width: "50px" }}>
          <>
            <strong>Room</strong>
            <br />
            {roomNo && <p style={{ margin: 0 }}>{roomNo}</p>}
          </>
        </div>
        <div style={{ width: "150px" }}>
          <>
            <strong>Rate Type</strong>
            <br />
            {roomType && <p>{roomType.slice(0, 20)}</p>}
          </>
        </div>

        <div style={{ width: "20px", height: "20px", position: "relative" }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-223.1L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6l29.7 0c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9 232 480c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128-16 0z" />
          </svg>
          <p style={{ position: "absolute", right: "-6px", bottom: "-40px" }}>
            {Adult}
          </p>
        </div>

        <div
          style={{
            width: "10px",
            height: "10px",
            position: "relative",
            marginTop: "10px",
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-223.1L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6l29.7 0c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9 232 480c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128-16 0z" />
          </svg>
          <p style={{ position: "absolute", right: "-10px", bottom: "-40px" }}>
            {Child}
          </p>
        </div>

        {BookingStatus && (
          <button
            type="button"
            disabled={true}
            style={{
              display: "flex",
              flex: 12,
              justifyContent: "center",
              alignItems: "center",
              minWidth: "max-content",
              textWrap: "wrap",
              height: "45px",
              border: "none",
              //   border: "1px solid #000",
              padding: "5px 10px",
              marginLeft: "10px",
              background:
                StatusColourCode.find((item) => item.label === BookingStatus)
                  ?.value || defaultColor,
              color: "white",
            }}
          >
            {BookingStatus}
          </button>
        )}
      </section>

      {modalOpen && (
        <Offcanvas
          open={modalOpen}
          handleToggle={handleModalToggle}
          Guestname={name}
          contact={mobileNo}
          Guestemail={email}
          reservationNumber={reservationId}
          Bookstatus={BookingStatus}
          arrivalDate={formatedDateTime(ArrivalDate)}
          departureDate={formatedDateTime(DepartureDate)}
          bookingDate={formatDateToDDMMYYYY(bookingDate)}
          roomType={roomType}
          // roomNumbers={roomNumbers}
          roomNumber={roomNo}
          totalAmount={totalAmount}
          Deposit={Deposit}
          hostelId={hostelId}
          ArrivalTime={ArrivalTime}
          DepartureTime={DepartureTime}
          showGroupBooking={false}
          large={true}
        />
      )}
    </>
  );
}

export default SearchResultCard;
