import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge, Label } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "@utils";

const tableHeaderData = [
    "Guest Name",
    "Reservation Id",
    "Booking Date",
    "Checkin-Checkout",
    "Folio No",
    "Hostel Name",
    "Booking Source",
    "Room No",
    "Status",
    "Total", //TotalExclusivTax
    "Tax",
    "Net",
    "Other Revenue",
    "Other Revenue(Tax)"
];

const DailyBookingsTable = ({ data, refetch, totalCount }) => {
  const [tableData, setTableData] = useState(data);
  const [totalExclusiveTax, setTotalExclusiveTax] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [otherRevenue, setOtherRevenue] = useState(0);
  const [otherRevenueWithTax, setOtherRevenueWithTax] = useState(0)

  useEffect(() => {
    if (data?.length>0) {
      setTableData(data);
      calculateTotals(data)
    }
    else {
      setTableData([])
    }
  }, [data]);

  const calculateTotals = (data) => {
    const totalExclusiveTax = data.reduce((acc, item) => acc + item.TotalExclusivTax, 0);
    setTotalExclusiveTax(totalExclusiveTax);

    const totalTax = data.reduce((acc, item) => acc + item.TotalTax, 0);
    setTotalTax(totalTax);

    const totalNet = data.reduce((acc, item) => acc + item.TotalInclusiveTax, 0);
    setTotalNet(totalNet);

    const otherRevenue = data.reduce((acc, item)=> acc + item.OtherRevenueExclusiveTax, 0);
    setOtherRevenue(otherRevenue)

    const otherRevenueWithTax = data.reduce((acc, item) => acc + item.OtherRevenueInclusiveTax, 0);
    setOtherRevenueWithTax(otherRevenueWithTax)
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Daily Bookings (Booking Date)</CardTitle>
            <Col>
            <Label className="form-label" for="product">
              Rooms Sold: <b>{totalCount}</b>
            </Label>
            </Col>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item, index) => {
                  return (
                    //  CHECKING IF THE CURRENT ROW MATCHES THE TABLE HEAD
                    <th key={uuidv4()}>
                      <div>

                      {item === "Total" ? `${item} (${new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", maximumFractionDigits: 2 }).format(totalExclusiveTax)})` :

item === "Tax" ? `${item} (${new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", maximumFractionDigits: 2 }).format(totalTax)})` :

item === "Net" ? `${item} (${new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", maximumFractionDigits: 2 }).format(totalNet)})` :

item === "Other Revenue" ? `${item} (${new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", maximumFractionDigits: 2 }).format(otherRevenue)})` :

item === "Other Revenue(Tax)" ? `${item} (${new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", maximumFractionDigits: 2 }).format(otherRevenueWithTax)})` :

item
}
</div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item?.GuestName}</td>
                  <td><Badge color="light-info">{item?.ReservationNo}</Badge></td>
                  <td>{dateFormatHandler(item?.ReservationDate)}</td>
                  <td>{item?.ArrivalDate
                      ? dateFormatHandler(item?.ArrivalDate)
                      : ""}{" "}
                    -{" "}
                    {item?.DepartureDate
                      ? dateFormatHandler(item?.DepartureDate)
                      : ""}</td>
                  <td><Badge color= 'light-secondary'>{item?.FolioNo}</Badge></td>
                  <td>{item?.hostelName}</td>
                  <td>{item?.Source}</td>
                  <td>{item?.RoomNo}</td>
                  <td><Badge color={item?.Status==="Active" ? "light-success" : "light-danger"}>{item?.Status}</Badge></td>
                  <td>{ new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 2 }).format(item?.TotalExclusivTax)}</td>
                  <td>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 2 }).format(item?.TotalTax)}</td>
                  <td>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 2 }).format(item?.TotalInclusiveTax)}</td>
                  <td>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 2 }).format(item?.OtherRevenueExclusiveTax)}</td>
                  <td>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 2 }).format(item?.OtherRevenueInclusiveTax)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default DailyBookingsTable;