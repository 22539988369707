import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const UPDATE_MEMBERSHIPS = gql`
  mutation updateMemberships($id: ID!, $membershipInput: membershipInput) {
    updateMemberships(id: $id, membershipInput: $membershipInput) {
      _id
      travelKitSend
    }
  }
`;

export const useUpdateMemberships = () => {
    const [updateMemberships, { loading, error, data, reset }] =
      useMutation(UPDATE_MEMBERSHIPS);
  
    useLoadingHandler(loading);
  
    const updateMembershipHandler = (id, membershipInput) => {
      updateMemberships({
        variables: { id, membershipInput },
      });
    };
  
    return [updateMembershipHandler, { loading, error, data, reset }];
  };