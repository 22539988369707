import React, { useState, useEffect } from "react";
import SlideModal from "../../../@core/components/common/slide-modal/index";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import {
  useUploadImage,
  useCreateStepOutLocation,
  useUpdateStepOutLocation,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import SwitchIcon from "@components/common/switch-icon";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [url, setUrl] = useState("");
  const [status, setStatus] = useState(false);

  const [
    uploadImageHandler,
    {
      data: bannerImageData,
      reset: bannerImageReset,
      loading: bannerImageLoad,
    },
  ] = useUploadImage();

  const [createStepOutLocationHandler, { data: createdStepOutLocationData }] =
    useCreateStepOutLocation();
  const [updateStepOutLocationHandler, { data: updatedStepOutLocationData }] =
    useUpdateStepOutLocation();

  useEffect(() => {
    if (createdStepOutLocationData || updatedStepOutLocationData) {
      refetch();
      handleToggle();
      toaster("success", "Location added successfully");
    }
  }, [createdStepOutLocationData, updatedStepOutLocationData]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2097152,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setBannerImage([preview]);
    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "icons/Locations");
    toaster("success", "Location image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading location image: ${error.message}`);
  });

  const renderPreview = () =>
    bannerImage ? (
      <img className="rounded mr-1 mb-2 w-50" src={bannerImage} alt="avatar" />
    ) : null;

  useEffect(() => {
    setBannerImage(bannerImageData?.uploadImage?.url);
  }, [bannerImageData]);

  useEffect(() => {
    if (editData?._id) {
      setTitle(editData?.title !== null ? editData?.title : "");
      setDescription(
        editData?.description !== null ? editData?.description : ""
      );
      setBannerImage(editData?.image !== null ? editData?.image : null);
      setMetaTitle(
        editData?.seo?.metaTitle !== null ? editData?.seo?.metaTitle : ""
      );
      setMetaDesc(
        editData?.seo?.metaDesc !== null ? editData?.seo?.metaDesc : ""
      );
      setKeywords(
        editData?.seo?.keywords !== null ? editData?.seo?.keywords : ""
      );
      setUrl(editData?.url !== null ? editData?.url : "");
      setStatus(editData?.status !== null ? editData?.status : false);
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const data = {
      title,
      description,
      image: bannerImage,
      seo: {
        metaTitle,
        metaDesc,
        keywords,
      },
      url,
      status,
    };

    if (data.url) {
      if (editData?._id) {
        updateStepOutLocationHandler(editData?._id, data);
      } else {
        createStepOutLocationHandler(data);
      }
      setTitle("");
      setDescription("");
      setBannerImage(null);
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setUrl("");
      setStatus(false);
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="title">
                  Title
                </Label>
              </strong>
              <Input
                type="text"
                value={title}
                id="title"
                name="title"
                placeholder="Add title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="description">
                  Description
                </Label>
              </strong>
              <Input
                type="textarea"
                value={description}
                id="description"
                name="description"
                placeholder="Add description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="url">
                  Url*
                </Label>
              </strong>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add url"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <strong>
              <Label className="form-label" for="metaTitle">
                Seo
              </Label>
            </strong>
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="metaTitle">
                  Meta title
                </Label>

                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add metaTitle"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="metaDesc">
                  Meta desc
                </Label>

                <Input
                  type="text"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add metaDesc"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="keywords">
                  Keywords
                </Label>

                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Col>

          <Col
            sm="12"
            style={{
              marginTop: "20px",
              marginBottom: "50px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "50%" }}>
              <Label className="form-label">Banner Image</Label>
              <DragDrop
                note="Image size should be less than 2MB"
                width="100%"
                uppy={uppy}
                locale={{
                  strings: {
                    dropHereOr: "Drop image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
            </div>

            <div style={{ width: "50%", marginLeft: "10px" }}>
              <div
                className="service-icon-preview"
                style={{
                  width: "400px",
                  marginTop: "50px",
                }}
              >
                {bannerImageLoad ? "Uploading icon..." : <>{renderPreview()}</>}
              </div>
            </div>
          </Col>
          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Product status?"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
