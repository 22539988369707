import React, { useState } from "react";
import { Edit } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import {
  useUpdateAllWhatsNew,
  useUpdateWhatsNew,
} from "../../../api/dataSource";
import SwitchIcon from "@components/common/switch-icon";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Title",
  "Display Order",
  "Publish On Website",
];

const WhatsNewTable = ({
  tableData,
  openModal,
  setId,
  setTableData,
  refetch,
}) => {
  const [draggedItem, setDraggedItem] = useState(null);
  const [status, setStatus] = useState("");
  let draggedTo = null;
  const [updateAllWhatsNewHandler, { data }] = useUpdateAllWhatsNew();
  const [updateWhatsNewHandler, { data: updatedWhatsNewData }] =
    useUpdateWhatsNew();
  const handleDragStart = (e, item) => {
    setDraggedItem(item);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };

  const handleDrop = async (e, index) => {
    draggedTo = index;
    const newTable = await handleChangeDisplayOrders();
    await handleChange(newTable);
  };

  const handleChange = async (tableData) => {
    updateAllWhatsNewHandler(tableData);
    refetch();
  };

  const handleChangeDisplayOrders = () => {
    // this is a working code don't touch it
    const updatedTableData = [...tableData].map((item) => ({ ...item }));

    const updatedItem = { ...updatedTableData[draggedItem] };
    if (draggedItem > draggedTo) {
      updatedItem.displayOrder = draggedTo;
    } else {
      updatedItem.displayOrder = draggedTo + 2;
    }
    updatedTableData[draggedItem] = updatedItem;
    updatedTableData.sort((a, b) => a.displayOrder - b.displayOrder);

    let currentDisplayOrder = 1;
    for (let i = 0; i < updatedTableData.length; i++) {
      updatedTableData[i] = {
        ...updatedTableData[i],
        displayOrder: currentDisplayOrder++,
      };
    }
    setTableData(updatedTableData);
    return updatedTableData;
  };

  const createObject = (value, item, e) => {
    let data = {
      status: value,
    };
    if (item?._id) {
      updateWhatsNewHandler(item?._id, data);
      refetch();
    }
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>In the Spotlight</CardTitle>
            <Row>
              <Col>
                {/* <Button.Ripple onClick={(e) => handleChange()} 
                  style={{marginRight:"10px"}} 
                  color="primary"
                  className="text"
                >Save</Button.Ripple> */}

                <Button.Ripple
                  onClick={(e) => {
                    e.preventDefault();
                    openModal();
                  }}
                  color="light"
                >
                  + Add In The Spot Light
                </Button.Ripple>
              </Col>
            </Row>
          </CardHeader>

          <Table responsive>
            <thead>
              {/* <tr style={{ textAlign: "center" }}> */}
              <tr>
                {tableHeaderData?.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            {tableData.length > 0 ? (
              <tbody>
                {tableData?.map((item, index) => (
                  <tr
                    key={item.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={(e) => handleDragOver(e, index)}
                    onDrop={(e) => handleDrop(e, index)}
                    // style={{ cursor: "pointer", textAlign: "center" }}
                    style={{ cursor: "pointer" }}
                  >
                    <td style={{ width: "5%" }}>
                      <Button
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td>{item?.title}</td>
                    {/* <td style={{ textAlign: "center" }}>
                      {item?.displayOrder}
                    </td> */}
                    <td>{item?.displayOrder}</td>
                    {/* <td style={{ display: "flex", justifyContent: "center" }}> */}
                    <td>
                      <SwitchIcon
                        checked={item?.status}
                        id={item?._id}
                        name="status"
                        handleChecked={(value) => createObject(value)}
                        onClick={(e) => createObject(!item.status, item, e)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  {/* <td
                    colSpan={tableHeaderData.length}
                    style={{ textAlign: "center", height: "150px" }}
                  > */}
                  <td
                    colSpan={tableHeaderData.length}
                    style={{ height: "150px" }}
                  >
                    <div
                      // style={{
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                      //   height: "100%",
                      // }}
                      style={{
                        height: "100%",
                      }}
                    >
                      <h2>No Record Found</h2>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default WhatsNewTable;
