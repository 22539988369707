import React, { useEffect, useState } from "react";
import {
  useUpdateHomePageDetails,
  useUploadImage,
} from "../../../../api/dataSource";
import Avatar from "@components/common/avatar";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { v4 as uuidv4 } from "uuid";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";
import { tableDataHandler } from "@store/Cms/cms.action";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Camera, X } from "react-feather";

const Tagline = () => {
  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [index, setIndex] = useState("");
  const [tagline, setTagline] = useState([
    {
      id: uuidv4(),
      name: "",
      icon: "",
    },
  ]);

  const [updateHomePageDetailsHandler, { data: updatedData }] =
    useUpdateHomePageDetails();

  const [uploadCategoryImageHandler, { data: categoryImageData }] =
    useUploadImage();

  useEffect(() => {
    if (categoryImageData?.uploadImage?.url) {
      setTagline(
        tagline.map((item) => {
          if (item.id === index) {
            return {
              ...item,
              icon: categoryImageData?.uploadImage?.url,
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [categoryImageData?.uploadImage?.url]);

  useEffect(() => {
    if (state?.tableData?.newTagline?.length > 0) {
      let alreadySelected = state?.tableData?.newTagline?.map((item) => {
        return {
          id: uuidv4(),
          name: item?.name,
          icon: item?.icon,
        };
      });

      setTagline(alreadySelected);
    }
  }, [state]);

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }

    uploadCategoryImageHandler(
      e.target.files[0],
      `${state.tableData?.slug?.toLowerCase()?.replaceAll(" ", "-")}` +
        "/NEWHOMEPAGE_DESTINATION"
    );
    toaster("success", "Destination icon uploaded successfully");
  };

  const handleAddTagline = () =>
    setTagline([
      ...tagline,
      {
        id: uuidv4(),
        name: "",
        icon: "",
      },
    ]);

  const handleChangeTagline = (val, id, key) =>
    setTagline(
      tagline?.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleRemoveTagline = (id) => {
    let remove = tagline.filter((item) => item?.id !== id);
    console.log(remove);
    confirmDelete(() => setTagline(remove));
  };

  const submitDataHandler = (e) => {
    e.preventDefault();
    let data = {
      newTagline: tagline?.map((taglineData) => {
        delete taglineData?.id;
        return {
          name: taglineData?.name,
          icon: taglineData?.icon,
        };
      }),
    };

    if (state?.tableData?._id) {
      updateHomePageDetailsHandler(id, data);
      toaster("success", "Home tagline updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          newTagline: tagline,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };
  return (
    <div>
      <form className="mt-2" onSubmit={submitDataHandler}>
        <Row className="mt-2">
          {tagline?.map(({ id, name, icon }, i) => (
            <Col className="d-flex align-items-center" sm="12" key={i}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"reviews" + id}>
                  Tagline {i + 1}
                </Label>
                <Row>
                  <Col sm="10">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormGroup className="w-50">
                        <div className="" style={{ display: "flex" }}>
                          <div className="mb-1 mr-2">
                            {
                              <Avatar
                                img={icon}
                                alt={icon}
                                imgHeight={100}
                                imgWidth={100}
                              />
                            }
                          </div>

                          <label
                            htmlFor={`myFile${id}`}
                            style={{ marginTop: "40px" }}
                          >
                            <p className="btn btn-sm btn-light">
                              <Camera size={15} />
                            </p>
                            <input
                              name={`myFile${id}`}
                              id={`myFile${id}`}
                              className="d-none"
                              type="file"
                              onChange={(e) => fileUploader(e, id)}
                            />
                          </label>
                        </div>
                      </FormGroup>

                      <FormGroup
                        className="w-100"
                        style={{ marginTop: "40px" }}
                      >
                        <Input
                          type="text"
                          id={id}
                          name="tagline"
                          value={name}
                          placeholder="Enter tagline name"
                          onChange={(e) => {
                            handleChangeTagline(e.target.value, id, "name");
                          }}
                          minLength={5}
                          maxLength={30}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </FormGroup>
              {tagline.length > 0 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveTagline(id)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
              <hr style={{ border: "1px solid #ffe700" }} />
            </Col>
          ))}
        </Row>
        <SaveDiscardBtnGroup addAction={handleAddTagline} />
      </form>
    </div>
  );
};

export default Tagline;
