// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateHostel } from "@api";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const ReviewsForm = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateHostelHandler, { data: updatdedHostelData }] = useUpdateHostel();
  const [totalReviews, setTotalReviews] = useState("");
  const [rating, setRating] = useState("");
  const [reviewsCategory, setReviewsCategory] = useState([
    {
      id: uuidv4(),
      category: "",
      rating: "",
    },
  ]);
  const [index, setIndex] = useState("");

  useEffect(() => {
    if (state?.tableData?.reviewsCategory?.length) {
      let upadtedReviews = state?.tableData?.reviewsCategory?.map((item) => {
        return {
          id: uuidv4(),
          category: item?.category,
          rating: item?.rating,
        };
      });
      setReviewsCategory(upadtedReviews);
    }
    setTotalReviews(state.tableData?.totalReviews);
    setRating(state.tableData?.rating);
  }, [state]);

  useEffect(() => {
    if (updatdedHostelData?.updateHostel?.name) {
      toaster("success", "Reviews category updated successfully");
    }
  }, [updatdedHostelData]);

  const handleRemoveReviews = (id) =>
    confirmDelete(() =>
      setReviewsCategory(reviewsCategory.filter((item) => item.id !== id))
    );

  const handleAddReviews = () =>
    setReviewsCategory([
      ...reviewsCategory,
      { id: uuidv4(), category: "", rating: "" },
    ]);

  const handleChangeReviewCategory = (category, id) => {
    setReviewsCategory(
      reviewsCategory.map((item) =>
        item.id === id ? { ...item, category: category } : item
      )
    );
  };

  const handleChangeReviewRating = (rating, id) => {
    setReviewsCategory(
      reviewsCategory.map((item) =>
        item.id === id ? { ...item, rating: parseFloat(rating) } : item
      )
    );
  };

  const submitDatahandler = () => {
    let isValid = false;

    let data = {
      totalReviews: parseInt(totalReviews),
      rating: parseFloat(rating),
      reviewsCategory: reviewsCategory,
    };

    reviewsCategory?.forEach((item) => {
      if (item?.category && item?.rating) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      reviewsCategory?.forEach((item) => delete item.id);

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          reviewsCategory: data?.reviewsCategory,
          totalReviews,
          rating,
        })
      );
      updateHostelHandler(id, data);
      toaster("success", "Reviews category updated successfully");
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDatahandler();
      }}
    >
      <Row className="mt-2">
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="totalReviews">
              Total Reviews
            </Label>

            <Input
              type="text"
              value={totalReviews}
              id="totalReviews"
              name="totalReviews"
              placeholder="Total Reviews"
              onChange={(e) => setTotalReviews(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="rating">
              Overall Rating
            </Label>

            <Input
              type="text"
              value={rating}
              id="rating"
              name="rating"
              placeholder="rating"
              onChange={(e) => setRating(e.target.value)}
            />
          </FormGroup>
        </Col>

        <>
          <hr
            style={{
              borderTop: "2px dashed #ffe700",
              width: "100%",
            }}
          />
        </>

        {reviewsCategory.map(({ category, rating, id }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={id}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"reviews" + id}>
                Reviews Category {index + 1}
              </Label>

              <Row>
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Label className="form-label" htmlFor="newDetailsState">
                      Category Name
                    </Label>
                    <Input
                      type="text"
                      value={category}
                      id={"category" + id}
                      name={"category"}
                      placeholder="Category"
                      onChange={(e) =>
                        handleChangeReviewCategory(e.target.value, id)
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm="6">
                  <FormGroup className="w-100">
                    <Label className="form-label" htmlFor="newDetailsState">
                      Category Rating
                    </Label>
                    <Input
                      type="number"
                      value={rating}
                      id={"rating" + id}
                      name={"rating"}
                      placeholder="Rating"
                      onChange={(e) =>
                        handleChangeReviewRating(e.target.value, id)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {reviewsCategory.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveReviews(id)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddReviews} />
    </form>
  );
};

export default ReviewsForm;
