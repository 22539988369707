import gql from "graphql-tag";

export const CREATE_STEPOUT_BLOG = gql`
mutation createStepoutBlog($StepOutBlogInput:StepOutBlogInput){
    createStepoutBlog(StepOutBlogInput:$StepOutBlogInput){
        blogTitle
    }
}
`

export const UPDATE_STEPOUT_BLOG = gql`
mutation updateStepoutBlog($id:ID,$StepOutBlogInput: StepOutBlogInput){
    updateStepoutBlog(id: $id, StepOutBlogInput: $StepOutBlogInput){
        _id
        blogTitle
    }
}
`