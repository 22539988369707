import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const UPDATE_ORDER_STATUS = gql`
  mutation UpdateOrderStatus($id: ID, $statusUpdate: statusUpdate) {
    updateOrderStatus(id: $id, statusUpdate: $statusUpdate) {
      status
    }
  }
`;

export const useUpdateOrderStatus = () => {
  const [updateOrder, { loading, error, data, refetch }] =
    useMutation(UPDATE_ORDER_STATUS);

  useLoadingHandler(loading);

  const updateHandler = (id, statusUpdate) => {
    updateOrder({
      variables: {
        id,
        statusUpdate,
      },
    });
  };

  return [updateHandler, { data, error, refetch }];
};

export const CREATE_ORDER = gql`
  mutation createOrder($orderInput: orderInput) {
    createOrder(orderInput: $orderInput) {
      amount
      currency
      orderId
      items {
        itemName
        itemCategory
        quantity
        options {
          name
        }
        extras {
          name
        }
      }
      guestName
      tokenNo
      mobileNo
      createdAt
    }
  }
`;

export const useCreateOrder = () => {
  const [createOrder, { loading, error, data, refetch }] =
    useMutation(CREATE_ORDER);

  useLoadingHandler(loading);

  const createOrderHandler = (orderInput) => {
    createOrder({
      variables: {
        orderInput,
      },
    });
  };

  return [createOrderHandler, { data, error, refetch }];
};

export const CANCEL_ORDER = gql`
  mutation CancelOrder($cancelInput: cancelInput) {
    cancelOrder(cancelInput: $cancelInput) {
      status
    }
  }
`;

export const useCancelOrder = () => {
  const [cancelOrder, { loading, error, data, refetch }] =
    useMutation(CANCEL_ORDER);

  useLoadingHandler(loading);

  const cancelOrderHandler = (cancelInput) => {
    cancelOrder({
      variables: {
        cancelInput,
      },
    });
  };

  return [cancelOrderHandler, { data, error, refetch }];
};

export const SEND_INVOICE_DATA = gql`
  mutation sendInvoiceUrlFromObjectId(
    $guestEmail: String
    $documentId: ID
    $guestName: String
    $orderId: String
    $bccMail: String
  ) {
    sendInvoiceUrlFromObjectId(
      guestEmail: $guestEmail
      documentId: $documentId
      guestName: $guestName
      orderId: $orderId
      bccMail: $bccMail
    ) {
      status
      success
    }
  }
`;

export const useSendInvoice = () => {
  const [sendInvoice, { loading, error, data, refetch }] =
    useMutation(SEND_INVOICE_DATA);

  useLoadingHandler(loading);

  const sendInvoiceHandler = (
    guestEmail,
    documentId,
    guestName,
    orderId,
    bccMail
  ) => {
    sendInvoice({
      variables: {
        guestEmail,
        documentId,
        guestName,
        orderId,
        bccMail,
      },
    });
  };

  return [sendInvoiceHandler, { data, error, refetch }];
};
