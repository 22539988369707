import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import Chart from "react-apexcharts";

const tableHeaderData = ["CX Status", "Total Count"];

const FeedbackFinalStatus = ({ finalStatusData: data }) => {
  //for table
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  //for chart
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      width: 380,
      type: "donut",
    },
    labels: [],
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 400,
        colors: ["#ffffff"],
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: "Total",
              fontSize: "24px",
              fontWeight: 600,
              color: "#373d3f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    theme: {
      palette: "palette5",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const colorMap = {
    Closed: "#4CAF50",
    Open: "#FF2C2C",
    Ticket: "#FFE700",
  };

  useEffect(() => {
    if (!data) return;
    const chartData = data.map((item) => item.count);
    const labels = data.map((item) => item._id);
    const colors = labels.map((label) => colorMap[label || "#000000"]);

    setSeries(chartData);
    setOptions({
      ...options,
      labels: labels,
      colors: colors,
    });
  }, [data]);

  return (
    <Row className="mt-3">
      <Col sm="6">
        <Card className="">
          <CardHeader>
            <CardTitle>Closure rate</CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <Badge
                      color={
                        item?._id === "Closed"
                          ? "success"
                          : item?._id === "Open"
                          ? "danger"
                          : item?._id === "Ticket"
                          ? "primary"
                          : ""
                      }
                      className="font-weight-bold"
                    >
                      {item?._id}{" "}
                    </Badge>
                  </td>
                  <td className="font-weight-bold">{item?.count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
      <Col sm="6">
        <div className="donut px-3">
          <Chart options={options} series={series} type="donut" width="350" />
        </div>
      </Col>
    </Row>
  );
};

export default FeedbackFinalStatus;
