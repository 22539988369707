import React, { useState, useEffect } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { v4 as uuidv4 } from "uuid";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetStepOutBlogCategoryList,
  useUpdateStepoutBlog,
} from "../../../api/dataSource";
import { useParams } from "react-router-dom";
import confirmDelete from "@components/common/confirmDelete";

const StepoutBlogCategory = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateStepoutBlogHandler, { data: updatedStepoutBlogData }] =
    useUpdateStepoutBlog();
  const [selectedBlogCategory, setSelectedBlogCategory] = useState([]);
  const [BlogCategoryList, setBlogCategoryList] = useState([]);
  const { data: allBlogCategoryData } = useGetStepOutBlogCategoryList();

  useEffect(() => {
    if (state?.tableData?.blogCategory?.length) {
      let alreadySelectedBlogCategory = state?.tableData?.blogCategory?.map(
        (item) => {
          return {
            uuId: uuidv4(),
            blogCategory: { label: item?.categoryName, value: item?._id },
          };
        }
      );
      setSelectedBlogCategory(alreadySelectedBlogCategory);
    }
  }, [state]);

  const handleAddBlogCategory = () =>
    setSelectedBlogCategory([
      ...selectedBlogCategory,
      {
        uuId: uuidv4(),
        blogCategory: [],
      },
    ]);

  const handleRemoveSelectedBlogCategory = (uuId) =>
    confirmDelete(() =>
      setSelectedBlogCategory(
        selectedBlogCategory.filter((item) => item.uuId !== uuId)
      )
    );

  const handleChangeSelectedBlogCategory = (blogCategory, uuId) => {
    setSelectedBlogCategory(
      selectedBlogCategory.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              blogCategory: {
                label: blogCategory.label,
                value: blogCategory.value,
              },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let BlogCategoryList = [];
    if (allBlogCategoryData?.getStepOutBlogCategoryList?.length) {
      allBlogCategoryData?.getStepOutBlogCategoryList?.map((item) => {
        BlogCategoryList.push({ label: item?.categoryName, value: item?._id });
        setBlogCategoryList(BlogCategoryList);
      });
    }
  }, [allBlogCategoryData?.getStepOutBlogCategoryList]);

  const [finalBlogCategoryArr, setFinalBlogCategoryArr] = useState([]);

  useEffect(() => {
    if (updatedStepoutBlogData) {
      let updatedBlogCategory = [];
      finalBlogCategoryArr.forEach((i) => {
        updatedBlogCategory.push(
          allBlogCategoryData?.getStepOutBlogCategoryList?.find(
            (j) => j._id === i
          )
        );
      });

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          blogCategory: updatedBlogCategory,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allBlogCategoryData?.getStepOutBlogCategoryList,
    dispatch,
    finalBlogCategoryArr,
    updatedStepoutBlogData,
  ]);

  const submitDatahandler = () => {
    var finalListOfBlogCategoryId = [];
    selectedBlogCategory?.forEach((item) => {
      finalListOfBlogCategoryId.push(item.blogCategory.value);
    });
    setFinalBlogCategoryArr(finalListOfBlogCategoryId);
    let data = {
      id,
      blogCategory: finalListOfBlogCategoryId,
    };

    if (data?.blogCategory?.length > 0) {
      if (
        id &&
        data?.blogCategory?.every((category) => category !== undefined)
      ) {
        updateStepoutBlogHandler(id, { blogCategory: data.blogCategory });
        toaster("success", "Category updated successfully");
      } else {
        toaster("error", "Please select the category");
      }
    } else {
      toaster("error", "Please select at least one category");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDatahandler();
      }}
    >
      <Row className="mt-2">
        {selectedBlogCategory.map(({ uuId, blogCategory }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={uuId}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"reviews" + uuId}>
                Blog Category {index + 1}
              </Label>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Select
                      // isClearable={false}
                      isSearchable={true}
                      id={uuId}
                      name="blogCategory"
                      value={blogCategory}
                      options={BlogCategoryList}
                      onChange={(value) => {
                        handleChangeSelectedBlogCategory(value, uuId);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {selectedBlogCategory.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveSelectedBlogCategory(uuId)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddBlogCategory} />
    </form>
  );
};

export default StepoutBlogCategory;
