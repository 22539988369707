import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { htmlParserChecker } from "../../utility/Utils";

const Posts = ({ post }) => {
  // console.log(post)
  const userId = localStorage.getItem("userId")
  const history = useHistory();
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const handleNavigate = () => {
    history.push(`/connect/connectPost/${post?._id}`);
  };
  // const truncateText = (text, maxLength) => {
  //   console.log(text)
  //   if (text.length <= maxLength) {
  //     return text;
  //   }
  //   return text.slice(0, maxLength).trim() + "...";
  // };
  // const truncatedDescription = truncateText(post?.description, 200);


  return (
    <div onClick={handleNavigate} style={{ cursor: "pointer" , overflow:'hidden'}}>
      <div className="card " style={{ padding: "20px", maxHeight: "200px", justifyContent: "space-between" }}>
        <div style={{ display: "flex", height:"30px" }} >
          <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: "10px", minWidth:"20px"}}>
            <g clipPath="url(#clip0_7118_33917)">
              <path d="M15.8476 4.4978H3.5443C1.91937 4.4978 0.597412 5.87968 0.597412 7.57846V16.8392C0.597412 18.5383 1.91937 19.9206 3.5443 19.9206H3.84172L2.93233 22.1068C2.71211 22.6357 2.87207 22.9021 2.98705 23.0139C3.05418 23.079 3.17252 23.1563 3.35631 23.1563C3.48773 23.1563 3.65273 23.1167 3.85666 23.0049L9.47633 19.9206H15.8476C17.4725 19.9206 18.7943 18.5383 18.7943 16.8392V7.57846C18.7943 5.87968 17.4725 4.4978 15.8476 4.4978ZM4.83336 13.3727C4.21988 13.3727 3.72054 12.8506 3.72054 12.2089C3.72054 11.5674 4.21988 11.0455 4.83336 11.0455C5.44701 11.0455 5.94635 11.5674 5.94635 12.2089C5.94635 12.8506 5.44701 13.3727 4.83336 13.3727ZM9.9072 13.3727C9.29355 13.3727 8.79404 12.8506 8.79404 12.2089C8.79404 11.5674 9.29355 11.0455 9.9072 11.0455C10.5208 11.0455 11.0204 11.5674 11.0204 12.2089C11.0204 12.8506 10.5208 13.3727 9.9072 13.3727ZM14.5582 13.3727C13.9444 13.3727 13.4451 12.8506 13.4451 12.2089C13.4451 11.5674 13.9444 11.0455 14.5582 11.0455C15.1719 11.0455 15.6712 11.5674 15.6712 12.2089C15.6712 12.8506 15.1719 13.3727 14.5582 13.3727Z" fill="#595959" />
              <path d="M19.6508 0.521973H7.34744C5.86955 0.521973 4.64259 1.6652 4.43262 3.15025H15.8477C18.1833 3.15025 20.0835 5.13682 20.0835 7.57857V15.9112C21.504 15.6917 22.5975 14.4088 22.5975 12.8636V3.60263C22.5975 1.90402 21.2755 0.521973 19.6508 0.521973Z" fill="#595959" />
            </g>
            <defs>
              <clipPath id="clip0_7118_33917">
                <rect width="22" height="23" fill="white" transform="translate(0.597412 0.342285)" />
              </clipPath>
            </defs>
          </svg>
          <h5 style={{ fontSize: "18px", fontWeight: "600", textTransform: "uppercase" , overflow:"hidden"}}>{post?.title}</h5>
          
        </div>
        <div>
        <p
      className="card-text"
      style={{
        width: "90%",
        overflow: "hidden",
        height:"22px",
        // textOverflow: "ellipsis",
        fontSize: "16px",
        margin: "0px 0 0 33px",
        color: "#7e7e7e",
        
      }}
    >
      {htmlParserChecker(post?.description)}
    </p>
          {
            post?.department.length > 0 &&
            <p style={{ margin: "13px 0 0 33px", fontWeight: "500", color:"#595959",fontSize: "16px" }}>
              Department:&nbsp;

              <span style={{ color: "#7e7e7e", fontWeight: "300", fontSize:"16px" }}>
                {post?.department?.join(", ")}

              </span>
            </p>
          }
        </div>
        <div style={{ display: "flex", alignItems: 'end', marginTop: "10px" }}>
          <svg width="22" height="23" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7118_33940)">
              <circle cx="12.9999" cy="13.8268" r="12.35" fill="#595959" />
              <path d="M8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908Z" fill="#595959" />
              <path d="M20.4425 21.4158V22.943C18.4131 24.601 15.8215 25.5947 12.9998 25.5947C10.1781 25.5947 7.58646 24.601 5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158Z" fill="#F8F8F8" />
              <path d="M5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158V22.943" fill="#F8F8F8" />
              <path d="M17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908Z" fill="#F8F8F8" />
              <path d="M12.9999 26.1766C6.19008 26.1766 0.649902 20.6364 0.649902 13.8266C0.649902 7.01674 6.19008 1.47656 12.9999 1.47656C19.8097 1.47656 25.3499 7.01674 25.3499 13.8266C25.3499 20.6364 19.8097 26.1766 12.9999 26.1766ZM12.9999 2.64035C6.83173 2.64035 1.81369 7.65839 1.81369 13.8266C1.81369 19.9947 6.83173 25.0128 12.9999 25.0128C19.1681 25.0128 24.1861 19.9947 24.1861 13.8266C24.1861 7.65839 19.1681 2.64035 12.9999 2.64035Z" fill="#595959" />
            </g>
            <defs>
              <clipPath id="clip0_7118_33940">
                <rect width="26" height="26" fill="white" transform="translate(0 0.82666)" />
              </clipPath>
            </defs>
          </svg>
          <div style={{marginLeft: "6px",fontSize: "16px",}}>
            <span className="card-subtitle" style={{  fontWeight:"500",color:"#595959" }}>{post?.addedBy}</span>
            <span className="card-subtitle" style={{ marginLeft: "6px",color: "#7e7e7e" }}>{post?.createdAt ? new Date(post.createdAt).toLocaleDateString('en-GB', options) : ""}</span>
            <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{position:"absolute",right:"20", bottom:"20"}}>
              <circle cx="28.544" cy="28.567" r="27.8756" fill="#FFE700" />
              <g clipPath="url(#clip0_7118_34523)">
                <path d="M19.2522 29.8945H34.0794L27.6016 36.3723C27.0839 36.89 27.0839 37.7395 27.6016 38.2572C28.1193 38.7749 28.9556 38.7749 29.4733 38.2572L38.2209 29.5096C38.7386 28.9919 38.7386 28.1556 38.2209 27.6379L29.4865 18.877C28.9688 18.3593 28.1326 18.3593 27.6149 18.877C27.0972 19.3947 27.0972 20.231 27.6149 20.7487L34.0794 27.2397H19.2522C18.5221 27.2397 17.9248 27.837 17.9248 28.5671C17.9248 29.2972 18.5221 29.8945 19.2522 29.8945Z" fill="#323232" /> 
              </g>
              <defs>
                <clipPath id="clip0_7118_34523">
                  <rect width="31.8578" height="31.8578" fill="white" transform="translate(12.6152 12.6382)" />
                </clipPath>
              </defs>
            </svg>

          </div>
        </div>

      </div>
    </div>
  );
};

export default Posts;