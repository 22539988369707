import React, { useEffect, useState } from "react";
import GluBannerTable from "./GluBannerTable";
import { useGetGluBanner } from "../../../api/dataSource";
import AddEditModal from "./AddEditModal";

const GluBanner = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);

  const { data, refetch } = useGetGluBanner();

  useEffect(() => {
    if (data?.getGluBanner) {
      setTableData(data?.getGluBanner);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (data?.getGluBanner) {
      let editData = data?.getGluBanner.find((item) => item._id === id);
      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  return (
    <div>
      <GluBannerTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        refetch={refetch}
      />

      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default GluBanner;
