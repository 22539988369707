import { useState, useEffect } from "react";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import { dateFormatter } from "@utils";
import { useUpdateEmployeeTransferDetails } from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import AppraisalTabel from "./AppraisalTabel";
import { DesignationList } from "../../utils";
import ConnectDocumentSection from "../../Connect/ConnectDocumentSection";
import TransferTable from "./TransferTable";

export default function UpdateEmployeeModal(props) {
  const { showModal, onClose, data, allHostelsData, refetchEmployeeProfile } =
    props;
  const [updateEmployeeHandler] = useUpdateEmployeeTransferDetails();

  const [dateOfExit, setDateOfExit] = useState(null);
  const [transferDate, setTransferDate] = useState(null);
  const [confirmationDate, setConfirmationDate] = useState(null);
  const [empStatus, setEmpStatus] = useState(null);
  const [resignedStatus, setResignedStatus] = useState(null);
  const [exitReason, setExitReason] = useState(null);
  const [selectedHostel, setSelectedHostel] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [componentShown, setComponentShown] = useState(null);
  const [hostelList, setHostelList] = useState([]);
  const [appraisedDate, setAppraisedDate] = useState(
    new Date(dateFormatter(new Date()))
  );
  const [appraisalDate, setAppraisalDate] = useState();
  const [newDesignation, setNewDesignation] = useState();
  const [performanceGrade, setPerformanceGrade] = useState();
  const [newCTC, setNewCTC] = useState();
  const [remarks, setRemarks] = useState();
  const [dateofResignation, setDateOfResignation] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [dummyState, setDummyState] = useState(null);
  const UploadType = [{ label: "documents", value: "documentsImages" }];
  const dateFormat = "d/m/Y";
  const options = {
    dateFormat: dateFormat,
  };

  const EmploymentStatusList = [
    { label: "Absconding", value: "Absconding" },
    { label: "Resigned", value: "Resigned" },
    { label: "Termination", value: "Termination" },
  ];

  const actionList = [
    { label: "Appraisal", value: "empAppraisal" },
    // {
    //   label: "Confirmation",
    //   value: "empConfirmation",
    //   isDisabled:
    //     data?.empStatus !== null && data?.empStatus !== "Probation"
    //       ? true
    //       : false,
    // },
    { label: "Resignation / Termination", value: "empResignation" },
    { label: "Transfer", value: "empTransfer" },
  ];

  const resignedStatusList = [
    {
      label: "Resigned (Notice Period Served)",
      value: "Resigned (Notice Period Served)",
    },
    {
      label: "Resigned (Notice Period Didn't Serve)",
      value: "Resigned (Notice Period Didn't Serve)",
    },
    {
      label: "Resigned (Notice Period Waived Off)",
      value: "Resigned (Notice Period Waived Off)",
    },
  ];

  const performanceGradeOptions = [
    {
      label: "Excellent",
      value: "Excellent",
    },
    {
      label: "Good",
      value: "Good",
    },
    {
      label: "Average",
      value: "Average",
    },
  ];

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (data) {
      if (data?.empStatus) {
        setEmpStatus({ label: data?.empStatus, value: data?.empStatus });
      }
      if (data?.resignedStatus) {
        setResignedStatus({
          label: data?.resignedStatus,
          value: data?.resignedStatus,
        });
      }

      // setDateOfExit(data?.dateOfExit);
      setDateOfExit(new Date(dateFormatter(new Date(data?.dateOfExit))))
      setConfirmationDate(data?.confirmationDate);
      setTransferDate(data?.transferDate);
      setExitReason(data?.exitReason);
      // setDateOfResignation(data?.dateOfResignation);
      setDateOfResignation(
        new Date(dateFormatter(new Date(data?.dateOfResignation)))
      )
      const newImages = data?.attachments.map((url) => ({
        image: url,
      }));
      setImageList([...newImages]);

      const allHostels = allHostelsData.map((hostel) => {
        return {
          label: hostel?.name,
          value: hostel?._id,
          isDisabled: hostel?._id === data?.hostel?._id ? true : false,
        };
      });
      const filteredHostel = allHostels?.find(
        (hostel) => hostel?.value === data?.hostel?._id
      );
      setHostelList(allHostels);
      setSelectedHostel({
        label: filteredHostel?.label,
        value: filteredHostel?.value,
        isDisabled: filteredHostel?.isDisabled,
      });
      setAppraisalDate(new Date(data?.nextAppraisalDate));
      if (data?.nextAppraisalDate) {
        const currentDate = new Date();
        const providedDate = new Date(data?.nextAppraisalDate);
        const isSameYear =
          currentDate.getFullYear() === providedDate.getFullYear();
        const isSameMonth = currentDate.getMonth() === providedDate.getMonth();
        const formDisabled = isSameYear && isSameMonth ? false : true;
        setIsDisabled(formDisabled);
      } else {
        setIsDisabled(true);
      }
    }
  }, [data, allHostelsData]);

  const handleClose = () => {
    onClose(!showModal);
    setComponentShown(null);
    setSelectedAction(null);
  };

  useEffect(() => {
    if (selectedAction) {
      setComponentShown(selectedAction);
    }
  }, [selectedAction]);

  const empTransferSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (selectedHostel?.value && transferDate) {
        let employeUpdateInput = {
          transferDate: transferDate
            ? new Date(transferDate).toUTCString()
            : undefined,
          hostel: selectedHostel ? selectedHostel?.value : undefined,
          logs: [
            ...data?.logs,
            `${data?.fullName} was transferred from ${hostelList.find((i) => i?.value === data?.hostel?._id).label
            } to ${hostelList.find((i) => i?.value === selectedHostel?.value).label
            } on ${new Date().toLocaleDateString(
              "en-IN"
            )} by ${localStorage.getItem("name")} (${localStorage.getItem(
              "role"
            )})`,
          ],
          transferLogs: [
            {
              toHostel: hostelList.find((i) => i?.value === selectedHostel?.value).label,
              fromHostel: hostelList.find((i) => i?.value === data?.hostel?._id).label,
              date: new Date(transferDate).toUTCString()
            },
            ...data?.transferLogs
          ]
        };
        const updateRes = await updateEmployeeHandler(
          data?._id,
          employeUpdateInput,
          false,
          true
        );
        if (updateRes.data.updateEmployeeTransferDetails) {
          toaster("success", "Employee update success");
          refetchEmployeeProfile();
          handleClose();
        } else {
          toaster("error", "Employee update error");
          handleClose();
        }
      } else {
        !selectedHostel?.value && toaster("error", "Please select hostel");
        !transferDate && toaster("error", "Please select transfer date");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const empConfirmationSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (confirmationDate && empStatus) {
        let employeUpdateInput = {
          confirmationDate: confirmationDate
            ? new Date(confirmationDate).toUTCString()
            : undefined,
          empStatus: empStatus ? empStatus?.value : undefined,
          logs: [
            ...data?.logs,
            `${data?.fullName}'s employee status changed to ${empStatus?.value
            } on${new Date().toLocaleDateString(
              "en-IN"
            )} by ${localStorage.getItem("name")} (${localStorage.getItem(
              "role"
            )})`,
          ],
        };
        const updateRes = await updateEmployeeHandler(
          data?._id,
          employeUpdateInput
        );
        if (updateRes.data.updateEmployeeTransferDetails) {
          toaster("success", "Employee update success");
          refetchEmployeeProfile();
          handleClose();
        } else {
          toaster("error", "Employee update error");
          handleClose();
        }
      } else {
        !confirmationDate &&
          toaster("error", `Please select confirmation date`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const empResignationSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (
        dateOfExit &&
        dateofResignation &&
        (dateofResignation <= dateOfExit) &&
        exitReason &&
        empStatus?.value !== "Confirmed" &&
        empStatus?.value !== "Probation"
      ) {
        if (empStatus?.value === "Resigned" && !resignedStatus) {
          toaster("error", `Please select resign status`);
        } else {
          const attachmentList = imageList.map((image) => image?.image);
          if (attachmentList.length > 0 || empStatus?.value !== "Resigned") {
            let employeUpdateInput = {
              dateOfExit: dateOfExit
                ? new Date(dateOfExit).toUTCString()
                : undefined,
              resignedStatus: resignedStatus
                ? resignedStatus?.value
                : undefined,
              exitReason: exitReason ? exitReason : undefined,
              empStatus: empStatus ? empStatus?.value : undefined,
              dateOfResignation: dateofResignation
                ? new Date(dateofResignation).toUTCString()
                : undefined,
              attachments: attachmentList,
              logs: [
                ...data?.logs,
                `${data?.fullName}'s employee status changed ${data?.empStatus && `from ${data?.empStatus}`
                } to ${empStatus?.value} on ${new Date().toLocaleDateString(
                  "en-IN"
                )} by ${localStorage.getItem("name")} (${localStorage.getItem(
                  "role"
                )})`,
              ],
            };
            const updateRes = await updateEmployeeHandler(
              data?._id,
              employeUpdateInput
            );
            if (updateRes.data.updateEmployeeTransferDetails) {
              toaster("success", "Employee update success");
              refetchEmployeeProfile();
              handleClose();
            } else {
              toaster("error", "Employee update error");
              handleClose();
            }
          } else {
            toaster("error", `Please provide Resignation Document`);
          }
        }
      } else {
        !dateOfExit && toaster("error", `Please select exit date`);
        !dateofResignation && toaster("error", "Please select resignation date");
        (dateOfExit < dateofResignation) && toaster("error", "Date of exit can't be less than date of resignation");
        (empStatus?.value === "Confirmed" ||
          empStatus?.value === "Probation") &&
          toaster("error", `Please change employee status`);
        !exitReason && toaster("error", `Please enter exit reason`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const empAppraisalSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (
        appraisedDate &&
        appraisalDate &&
        performanceGrade &&
        newDesignation &&
        newCTC &&
        remarks
      ) {
        const nextAppraisalDate = new Date(data?.nextAppraisalDate);
        nextAppraisalDate.setFullYear(nextAppraisalDate.getFullYear() + 1);
        let newAppraisalObject = {
          designation: data?.designation,
          currentCTC: data?.currentCTC,
          appraisedDate: appraisedDate ? appraisedDate : undefined,
          appraisalDate: data?.nextAppraisalDate
            ? data?.nextAppraisalDate
            : undefined,
          nextAppraisalDate: nextAppraisalDate,
          performanceGrade: performanceGrade,
          newCTC: newCTC,
          newDesignation: newDesignation,
          remarks: remarks,
        };
        let employeUpdateInput = {
          appraisedDate: appraisedDate ? appraisedDate : undefined,
          appraisalDate: data?.nextAppraisalDate
            ? data?.nextAppraisalDate
            : undefined,
          nextAppraisalDate: nextAppraisalDate,
          performanceGrade: performanceGrade,
          newCTC: newCTC,
          designation: data?.designation,
          newDesignation: newDesignation,
          remarks: remarks,
          appraisalLogs: [...data?.appraisalLogs, newAppraisalObject],
        };
        const updateRes = await updateEmployeeHandler(
          data?._id,
          employeUpdateInput,
          true
        );
        if (updateRes.data.updateEmployeeTransferDetails) {
          toaster("success", "Employee update success");
          refetchEmployeeProfile();
          setAppraisalDate();
          setPerformanceGrade();
          setNewCTC();
          setNewDesignation();
          setRemarks();
          handleClose();
        } else {
          toaster("error", "Employee update error");
          handleClose();
        }
      } else {
        !appraisedDate && toaster("error", `Please select appraised date`);
        !data?.nextAppraisalDate &&
          toaster("error", `Please select appraisal date`);
        !performanceGrade &&
          toaster("error", `Please select performance grade`);
        !newDesignation && toaster("error", `Please select new designation`);
        !newCTC && toaster("error", `Please enter new CTC`);
        !remarks && toaster("error", `Please enter remarks`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [isCurrentMonth, setIsCurrentMonth] = useState(false);

  useEffect(() => {
    if (data?.nextAppraisalDate) {
      const targetDate = new Date(data?.nextAppraisalDate);
      const currentDate = new Date();
      if (targetDate.getMonth() === currentDate.getMonth()) {
        setIsCurrentMonth(true);
      } else {
        setIsCurrentMonth(false);
      }
    }
  }, [data?.nextAppraisalDate]);

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={handleClose}
      ></ModalHeader>
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <div className="text-center mb-2">
          <h3>
            Update Employee Details : {data?.fullName}({data?.empCode})
          </h3>
        </div>
        <Row>
          <Col xs={12}>
            <FormGroup className="w-100">
              <Label for="empAction">
                <strong>Action</strong>
              </Label>
              <Select
                isClearable={false}
                id="empAction"
                name="empAction"
                defaultValue={
                  selectedAction?.value ? selectedAction : undefined
                }
                options={actionList}
                onChange={(action) => setSelectedAction(action.value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        </Row>
        {componentShown === "empTransfer" && (
          <form className="mt-2" onSubmit={empTransferSubmitHandler}>
            <Row>
              <Col xs={6}>
                {console.log(hostelList)}
                <FormGroup className="w-100">
                  <Label for="selectedHostel">
                    <strong>Update Location</strong>
                  </Label>
                  <Select
                    required
                    isClearable={false}
                    id="selectedHostel"
                    name="selectedHostel"
                    // defaultValue={
                    //   selectedHostel?.value ? selectedHostel : undefined
                    // }
                    options={hostelList}
                    onChange={(value) => {
                      setSelectedHostel(value);
                    }}
                    classNamePrefix="select"
                    isRequired={true}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup className="w-100">
                  <Label className="form-label" for="url">
                    <strong>Transfer Date</strong>
                  </Label>
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    id="transferDate"
                    placeholder="Select Date"
                    options={{
                      minDate: new Date(),
                    }}
                    value={transferDate ? transferDate : undefined}
                    onChange={(date) =>
                      setTransferDate(new Date(dateFormatter(new Date(date))))
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="text-center mt-2">
                <FormGroup className="w-100">
                  <Button.Ripple
                    type="submit"
                    color="light"
                    appearance="success"
                  >
                    Submit
                  </Button.Ripple>
                </FormGroup>
              </Col>

              <TransferTable data={data?.transferLogs} />

            </Row>
          </form>
        )}
        {componentShown === "empConfirmation" && (
          <form className="mt-2" onSubmit={empConfirmationSubmitHandler}>
            <Row>
              <Col xs={6}>
                <FormGroup className="w-100">
                  <Label className="form-label" for="checkinDate">
                    <strong>Confirmation Date</strong>
                  </Label>
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    id="checkinDate"
                    placeholder="Select Date"
                    value={confirmationDate ? confirmationDate : undefined}
                    onChange={(date) => {
                      if (date) {
                        setConfirmationDate(
                          new Date(dateFormatter(new Date(date)))
                        );
                        setEmpStatus({
                          label: "Confirmed",
                          value: "Confirmed",
                        });
                      }
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="text-center mt-2">
                <FormGroup className="w-100">
                  <Button.Ripple
                    type="submit"
                    color="light"
                    appearance="success"
                  >
                    Submit
                  </Button.Ripple>
                </FormGroup>
              </Col>
            </Row>
          </form>
        )}
        {componentShown === "empResignation" && (
          <form className="mt-2" onSubmit={empResignationSubmitHandler}>
            <Row>
              <Col xs={6}>
                <FormGroup className="w-100">
                  <Label className="form-label" for="dateOfResignation">
                    <strong>Date Of Resignation</strong>
                  </Label>
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    id="dateOfResignation"
                    placeholder="Select Date"
                    value={dateofResignation ? dateofResignation : undefined}
                    onChange={(date) =>
                      setDateOfResignation(
                        new Date(dateFormatter(new Date(date)))
                      )
                    }
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup className="w-100">
                  <Label className="form-label" for="dateOfExit">
                    <strong>Date Of Exit</strong>
                  </Label>
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    id="dateOfExit"
                    placeholder="Select Date"
                    value={dateOfExit ? dateOfExit : undefined}
                    onChange={(date) =>
                      setDateOfExit(new Date(dateFormatter(new Date(date))))
                    }
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup className="w-100">
                  <Label for="empStatus">
                    <strong>Employee Status</strong>
                  </Label>
                  <Select
                    isClearable={false}
                    id="empStatus"
                    name="empStatus"
                    defaultValue={empStatus?.value ? empStatus : undefined}
                    options={EmploymentStatusList}
                    onChange={(value) => { setEmpStatus(value); setResignedStatus(null); setExitReason(null); setImageList([]) }}
                    classNamePrefix="select"
                    isRequired={true}
                  />
                </FormGroup>
              </Col>
              {empStatus?.value === "Resigned" && (
                <Col xs={6}>
                  <FormGroup className="w-100">
                    <Label for="resignStatus">
                      <strong>Resign Status</strong>
                    </Label>
                    <Select
                      id="resignStatus"
                      name="resignStatus"
                      isClearable={false}
                      defaultValue={
                        resignedStatus?.value ? resignedStatus : undefined
                      }
                      options={resignedStatusList}
                      onChange={(value) => setResignedStatus(value)}
                      classNamePrefix="select"
                      isRequired={
                        empStatus?.value === "Resigned" ? true : false
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={6}>
                <FormGroup className="w-100">
                  <Label for="exitReason">
                    <strong>Exit Reason</strong>
                  </Label>
                  <Input
                    autoFocus
                    type="textarea"
                    id="exitReason"
                    placeholder="Type your exit reason..."
                    cols="2"
                    value={exitReason ? exitReason : ""}
                    onChange={(e) => {
                      setExitReason(e.target.value);
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              {empStatus?.value === "Resigned" && (
                <Col>
                  <Label>
                    Upload Resignation Documents
                    {empStatus.value == "Resigned" && "*"}
                  </Label>
                  <ConnectDocumentSection
                    imageList={imageList}
                    setImageList={setImageList}
                    editData={dummyState}
                    uploadType={UploadType}
                  />
                </Col>
              )}
              <Col xs={12} className="text-center mt-2">
                <FormGroup className="w-100">
                  <Button.Ripple
                    type="submit"
                    color="light"
                    appearance="success"
                  >
                    Submit
                  </Button.Ripple>
                </FormGroup>
              </Col>
            </Row>
          </form>
        )}

        {componentShown === "empAppraisal" && (
          <>
            {isCurrentMonth && (
              <form className="mt-2" onSubmit={empAppraisalSubmitHandler}>
                <Row>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="appraisedDate">
                          Appraised Date*
                        </Label>
                      </strong>
                      <Flatpickr
                        id="appraisedDate"
                        className="form-control bg-white border-dark text-dark"
                        placeholder="appraisedDate"
                        options={{
                          ...options,
                        }}
                        value={appraisedDate}
                        onChange={(date) =>
                          setAppraisedDate(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }
                        required
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="appraisalDate">
                          Appraisal Date*
                        </Label>
                      </strong>
                      <Flatpickr
                        id="appraisalDate"
                        className="form-control bg-white border-dark text-dark"
                        placeholder="appraisalDate"
                        value={appraisalDate}
                        options={{
                          ...options,
                        }}
                        required
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="currentCTC">
                          Current CTC*(Annual)
                        </Label>
                      </strong>
                      <Input
                        id="currentCTC"
                        type="text"
                        value={data?.currentCTC ? data?.currentCTC : undefined}
                        placeholder="Current CTC"
                        disabled
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="designation">
                          Designation*
                        </Label>
                      </strong>
                      <Select
                        isClearable={false}
                        id="designation"
                        name="designation"
                        value={
                          data?.designation
                            ? DesignationList.find(
                              (i) => i.value === data?.designation
                            )
                            : undefined
                        }
                        options={DesignationList}
                        classNamePrefix="select"
                        isDisabled
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="performanceGrade">
                          Performance Grade*
                        </Label>
                      </strong>
                      <Select
                        isClearable={false}
                        id="performanceGrade"
                        name="performanceGrade"
                        value={
                          isDisabled && data?.performanceGrade
                            ? performanceGradeOptions.find(
                              (i) => i.value === data?.performanceGrade
                            )
                            : undefined
                        }
                        onChange={(grade) => {
                          setPerformanceGrade(grade?.value);
                        }}
                        options={performanceGradeOptions}
                        classNamePrefix="select"
                        isDisabled={isDisabled}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="newCTC">
                          New CTC* (Annual)
                        </Label>
                      </strong>
                      <Input
                        id="newCTC"
                        type="text"
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value))) {
                            setNewCTC(Number(e.target.value));
                          } else {
                            toaster("error", "please enter numbers only");
                          }
                        }}
                        value={newCTC}
                        placeholder="New CTC"
                        disabled={isDisabled}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="newDesignation">
                          New Designation*
                        </Label>
                      </strong>
                      <Select
                        isClearable={false}
                        id="newDesignation"
                        name="newDesignation"
                        value={
                          data?.newDesignation
                            ? DesignationList.find(
                              (i) => i.value === data?.newDesignation
                            )
                            : undefined
                        }
                        onChange={(designation) => {
                          setNewDesignation(designation?.value);
                        }}
                        options={DesignationList}
                        classNamePrefix="select"
                        required
                        isDisabled={isDisabled}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup className="w-100">
                      <Label for="remarks">
                        <strong>Remarks*</strong>
                      </Label>
                      <Input
                        autoFocus
                        id="remarks"
                        type="textarea"
                        placeholder="Type your exit reason..."
                        cols="2"
                        value={remarks ? remarks : undefined}
                        required
                        disabled={isDisabled}
                        onChange={(e) => {
                          setRemarks(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} className="text-center mt-2">
                    <FormGroup className="w-100">
                      <Button.Ripple
                        type="submit"
                        color="light"
                        appearance="success"
                        disabled={isDisabled}
                      >
                        Submit
                      </Button.Ripple>
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            )}
            {!isCurrentMonth && (
              <p>
                Next appraisal date is:{" "}
                {new Date(data?.nextAppraisalDate).toLocaleDateString("en-IN")}.
              </p>
            )}
            {!data?.nextAppraisalDate && (
              <p>Appraisal and CTC Details not added, Please contact HR.</p>
            )}
            <AppraisalTabel
              appraisalData={data?.appraisalLogs}
              nextAppraisalDate={data?.nextAppraisalDate}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
