import Requests from "../../views/WebApp/Requests/index";
import HostelRequest from "../../views/WebApp/HostelRequest/index";
import AddOn from "../../views/Report/AddOn/index";
import WebCheckin from "../../views/WebCheckin/index";
import UserRequest from "../../views/WebApp/UserRequest/index";
import ContactlessCheckout from "../../views/WebApp/ContactlessCheckout/index";
import EventsAndActivities from "../../views/WebApp/EventsAndActivities/index";
import GluBanner from "../../views/WebApp/glubanner";
import EventBookings from "../../views/WebApp/EventBookings";
import ServiceConfig from "../../views/WebApp/ServiceConfig/index";
import AddonBooking from "../../views/WebApp/AddonBookings";
import HostelRooms from "../../views/WebApp/HostelRooms";
import HostelWiseRevenue from "../../views/WebApp/HostelWiseRevenue";
import CategoryWiseRevenue from "../../views/WebApp/CategoryWiseRevenue";
import ItemWiseRevenue from "../../views/WebApp/ItemWiseRevenue";
import BlacklistedGuests from "../../views/WebApp/BlacklistedGuests";

const WebAppRoutes = [
  {
    path: "/webApp/request",
    component: Requests,
    exact: true,
  },
  {
    path: "/webApp/hostelRequest",
    component: HostelRequest,
    exact: true,
  },
  {
    path: "/webApp/userRequest",
    component: UserRequest,
    exact: true,
  },
  {
    path: "/webApp/events&activities",
    component: EventsAndActivities,
    exact: true,
  },
  {
    path: "/webApp/addOn",
    component: AddOn,
    exact: true,
  },
  {
    path: "/webApp/webCheckin",
    component: WebCheckin,
    exact: true,
  },
  {
    path: "/webApp/ContactlessCheckout",
    component: ContactlessCheckout,
    exact: true,
  },
  {
    path: "/webApp/glubanner",
    component: GluBanner,
    exact: true,
  },
  {
    path: "/webApp/eventBookings",
    component: EventBookings,
    exact: true,
  },
  {
    path: "/webApp/serviceConfig",
    component: ServiceConfig,
    exact: true,
  },
  {
    path: "/webApp/addonBooking",
    component: AddonBooking,
    exact: true,
  },
  {
    path: "/webApp/hostelRooms",
    component: HostelRooms,
    exact: true,
  },
  {
    path: "/webApp/hostelWiseRevenue",
    component: HostelWiseRevenue,
    exact: true,
  },
  {
    path: "/webApp/categoryWiseRevenue",
    component: CategoryWiseRevenue,
    exact: true,
  },
  {
    path: "/webApp/itemWiseRevenue",
    component: ItemWiseRevenue,
    exact: true,
  },
  {
    path: "/webApp/blacklistedGuests",
    component: BlacklistedGuests,
    exact: true,
  },
  // {
  //   path: "/webApp/unboxMasterMenu",
  //   component: UnboxMasterMenu,
  //   exact: true,
  // },
  // {
  //   path: "/webApp/edit/unboxmastermenu/:id",
  //   component: UnboxMasterMenuTabs,
  //   exact: true,
  //   meta: {
  //     navLink: "/webApp",
  //   },
  // },
];

export default WebAppRoutes;
