// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import HostelRequestTable from "./HostelRequestTable";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useHostelList } from "../../../api/dataSource";
import { Col, Input, Row } from "reactstrap";

const HostelRequest = () => {
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState("");

  const { data: hostelListData, refetch } = useHostelList();

  useEffect(() => {
    if (hostelListData?.getHostelList) {
      setTableData(hostelListData?.getHostelList);
    } else {
      setTableData([]);
    }
  }, [hostelListData?.getHostelList]);

  const filteredData = tableData.filter((hostel) =>
    hostel.name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    localStorage.getItem("modules")?.includes("Glu Configuration") && (
      <div>
        <Row>
          <Col sm="4">
            <Input
              type="text"
              placeholder="Filter Hostels"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Col>
        </Row>
        {/* <HostelRequestTable hostelListData={tableData} refetch={refetch} /> */}
        <HostelRequestTable hostelListData={filteredData} refetch={refetch} />
      </div>
    )
  );
};

export default HostelRequest;
