import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import {
  useCreateDisplayAds,
  useUploadImage,
  useUpdateDisplayAds,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [adName, setAdName] = useState("");
  const [adType, setAdType] = useState(null);
  const [url, setUrl] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [status, setStatus] = useState(false);

  const AdTypeList = [
    { label: "Destination", value: "destination" },
    { label: "Hostel", value: "hostel" },
  ];

  const [landscapeImagePreview, setLandscapeImagePreview] = useState(null);
  const [portraitImagePreview, setPortraitImagePreview] = useState(null);
  const [
    uploadLandscapeImageHandler,
    {
      data: landscapeImageData,
      reset: landscapeImageReset,
      loading: landscapeImageLoad,
    },
  ] = useUploadImage();

  const [
    uploadPortraitImageHandler,
    {
      data: portraitImageData,
      reset: portraitImageReset,
      loading: portraitImageLoad,
    },
  ] = useUploadImage();

  const [createDisplayAdsHandler, { data }] = useCreateDisplayAds();

  const [updateDisplayAdsHandler, { data: updatedDisplayAdsData }] =
    useUpdateDisplayAds();

  useEffect(() => {
    if (data?.createPressMention?.adName || updatedDisplayAdsData) {
      refetch();
      handleToggle();

      toaster("success", "Display Ad added successfully");
    }
  }, [data, updatedDisplayAdsData]);

  const uppyLandscapeImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  const uppyPortraitImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppyLandscapeImage.use(thumbnailGenerator);
  uppyPortraitImage.use(thumbnailGenerator);

  uppyLandscapeImage.on("thumbnail:generated", (file, preview) => {
    setLandscapeImagePreview([preview]);
    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyLandscapeImage.removeFile(file.id);
      return;
    }

    uploadLandscapeImageHandler(file.data);
    toaster("success", "Landscape image uploaded successfully");
  });

  uppyLandscapeImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading landscape image: ${error.message}`);
  });

  uppyPortraitImage.on("thumbnail:generated", (file, preview) => {
    setPortraitImagePreview([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyPortraitImage.removeFile(file.id);
      return;
    }

    uploadPortraitImageHandler(file.data);
    toaster("success", "Portrait image uploaded successfully");
  });

  uppyPortraitImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading portrait image: ${error.message}`);
  });

  const renderLandscapeImagePreview = () =>
    landscapeImagePreview ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={landscapeImagePreview}
        alt="avatar"
      />
    ) : null;

  useEffect(() => {
    setLandscapeImagePreview(landscapeImageData?.uploadImage?.url);
  }, [landscapeImageData]);

  const renderPortraitImagePreview = () =>
    portraitImagePreview ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={portraitImagePreview}
        alt="avatar"
      />
    ) : null;

  useEffect(() => {
    setPortraitImagePreview(portraitImageData?.uploadImage?.url);
  }, [portraitImageData]);

  const submitDataHandler = () => {
    let data = {
      adName,
      adType: adType.value,
      url,
      displayOrder: parseInt(displayOrder),
      landscapeImage: landscapeImagePreview,
      portraitImage: portraitImagePreview,
      status,
    };

    if (data.adName) {
      if (editData?.length) {
        const { _id } = editData[0];
        updateDisplayAdsHandler(_id, data);
      } else {
        createDisplayAdsHandler(data);
      }

      setAdName("");
      setAdType(null);
      setUrl("");
      setDisplayOrder("");
      setLandscapeImagePreview("");
      setPortraitImagePreview("");
      setStatus(false);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  useEffect(() => {
    if (editData?.length) {
      const {
        adName,
        adType,
        url,
        displayOrder,
        landscapeImage,
        portraitImage,
        status,
      } = editData[0];
      setAdName(adName);
      setAdType({ label: adType, value: adType });
      setUrl(url);
      setDisplayOrder(displayOrder === null ? "" : displayOrder);
      setLandscapeImagePreview(landscapeImage);
      setPortraitImagePreview(portraitImage);
      setStatus(status);
    } else {
      setAdName("");
      setAdType(null);
      setUrl("");
      setLandscapeImagePreview("");
      setPortraitImagePreview("");
      setDisplayOrder("");
      setStatus(false);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Ad Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="adName">
                Ad Name*
              </Label>
              <Input
                type="text"
                value={adName}
                id="adName"
                name="adName"
                placeholder="Add Ad Name"
                onChange={(e) => setAdName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/*// ! Ad Type */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="adType">
                Ad Type
              </Label>
              <Select
                isClearable={false}
                id="adType"
                name="adType"
                value={adType}
                options={AdTypeList}
                onChange={(value) => setAdType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! URL */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                URL*
              </Label>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add Url"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Display Order */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="displayOrder">
                Display Order*
              </Label>
              <Input
                type="number"
                value={displayOrder}
                id="displayOrder"
                name="displayOrder"
                placeholder="Add Display Order"
                onChange={(e) => setDisplayOrder(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Landscape Image */}
          <Row>
            {/* <Col sm="6">
              <p>Upload Landscape Image</p>
              <DragDrop
                note="Landscape Image size should be less than 500KB"
                width="100%"
                uppy={uppyLandscapeImage}
                locale={{
                  strings: {
                    dropHereOr: "Drop image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
              <Col sm="13">
                <Label className="form-label w-100">
                  Landscape Image preview here
                </Label>
                {landscapeImageLoad
                  ? "Uploading image..."
                  : renderLandscapeImagePreview()}
              </Col>
            </Col> */}

            {/* // ! Portrait Image */}
            <Col sm="6">
              <p>Upload Portrait Image</p>
              <DragDrop
                note="Portrait Image size should be less than 500KB"
                width="100%"
                uppy={uppyPortraitImage}
                locale={{
                  strings: {
                    dropHereOr: "Drop news image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
              <Col sm="13">
                <Label className="form-label w-100">
                  Portrait Image preview here
                </Label>
                {portraitImageLoad
                  ? "Uploading image..."
                  : renderPortraitImagePreview()}
              </Col>
            </Col>

            {/* // ! Status */}
            <Col sm="12">
              <SwitchIcon
                checked={status}
                id="status"
                name="status"
                label="Publish on Website ?"
                handleChecked={(value) => setStatus(value)}
              />
            </Col>
          </Row>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
