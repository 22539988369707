import React from "react";
import { formatDateToYYYYMMDD } from "./helpers";
import Leader from "./icons/groupleader.png";
import GroupBooking from "./icons/groupbooking.png";
import DueAmount from "./icons/paymentpending.png";

function TableDatawAsPerCheckout({
  idx,
  date,
  room,
  roomNumber,
  dateRanges,
  StatusColourCode,
  defaultColor,
  handleBandClick,
  bookingData,
  widthOfEachCell,
}) {
  const getRoomReservations = (roomName, roomNo, date) => {
    const _bookingData = bookingData.find(
      (item) => item?._id?.trim() === room?.label?.trim()
    )?.Bookings;
    return _bookingData?.filter(
      (item) =>
        item?.RoomNo === roomNo &&
        item?.DepartureDate?.split("T")[0] === formatDateToYYYYMMDD(date) &&
        //also check if the checin for the item is within the date range of the table if so the dont return it cause other TableDataAsPerCheckin component will do that
        item?.ArrivalDate?.split("T")[0] < formatDateToYYYYMMDD(dateRanges[0])
    );
  };

  /*
    caution : do not play with this calculateBandPositionAndWidth function.
  */
  const calculateBandPositionAndWidth = (
    arrivalDate,
    departureDate,
    currentDate
  ) => {
    const dayTimeStamp = 86400000; // milliseconds in a day
    const totalDaysSpan = dateRanges.length;
    const totalTimeSpan = totalDaysSpan * dayTimeStamp;

    //make the default arrival and departure date as 14:00:00 and 11:00:00 respectively as per the current requirement
    arrivalDate = arrivalDate?.split("T")[0] + " 14:00:00";
    departureDate = departureDate?.split("T")[0] + " 11:00:00";

    //format the date
    let _arrivalDate = arrivalDate.replace(" ", "T") + "Z";
    let _departureDate = departureDate.replace(" ", "T") + "Z";

    const arrival = new Date(_arrivalDate).getTime();
    const departure = new Date(_departureDate).getTime();
    const today = new Date(formatDateToYYYYMMDD(currentDate)).getTime();

    const endPosition = ((departure - today) / dayTimeStamp) * widthOfEachCell;
    const bandWidth = ((departure - arrival) / dayTimeStamp) * widthOfEachCell;

    return { endPosition, bandWidth };
  };

  /*
    caution : do not play with this adjustedBandWidth function.
  */
  const adjustedBandWidth = (index, bandWidth, endPosition) => {
    const remainingSpace = Math.abs(
      widthOfEachCell * index + (widthOfEachCell - endPosition)
    );

    return Math.min(bandWidth, remainingSpace);
  };

  const getNoOfIconsToShow = (reservation) => {
    let arr = [];

    if (reservation?.ReservationNo.split("-")?.length > 1) {
      arr.push(GroupBooking);
    }
    if (reservation?.ReservationNo?.split("-")[1] === "1") {
      arr.push(Leader);
    }
    if (reservation?.DueAmount > 0) {
      arr.push(DueAmount);
    }
    return arr;
  };

  return (
    <>
      {getRoomReservations(room?.value, roomNumber?.RoomName, date)?.map(
        (reservation, subIdx) => {
          if (
            reservation?.BookingStatus === "Cancel" ||
            reservation?.BookingStatus === "No Show" ||
            reservation?.BookingStatus === "Void"
          )
            return null;
          const { endPosition, bandWidth } = calculateBandPositionAndWidth(
            reservation?.ArrivalDate,
            reservation?.DepartureDate,
            date
          );
          return (
            <div
              key={subIdx}
              className="reservation-band"
              style={{
                position: "absolute",
                right: `${endPosition}px`,
                bottom: "10px",
                backgroundColor:
                  StatusColourCode.find(
                    (item) => item.label === reservation?.BookingStatus
                  )?.value || defaultColor,
                width: `${adjustedBandWidth(idx, bandWidth, endPosition)}px`,
                whiteSpace: "nowrap",
                textOverflow: "hidden",
                cursor: "pointer",
                zIndex: 1,
              }}
              onClick={() => handleBandClick(reservation)}
            >
              {getNoOfIconsToShow(reservation)?.map((item, idx) => (
                <img
                  key={idx}
                  src={item}
                  style={{
                    width: "20px",
                    height: "20px",
                    objectFit: "contain",
                    position: "absolute",
                    right: `${25 * idx}px`,
                    top: "-10px",
                  }}
                />
              ))}

              <p
                style={{
                  marginBottom: "15px",
                  paddingLeft: "5px",
                  color: "white",
                  width: "95%",
                  overflow: "hidden",
                }}
              >
                {reservation?.GuestName}
              </p>
            </div>
          );
        }
      )}
    </>
  );
}

export default TableDatawAsPerCheckout;
