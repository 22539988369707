import gql from "graphql-tag";

// ! Added by Ashwin

// ! createMessageTemplate added by Ashwin
export const POST_ADD_MESSAGE_TEMPLATE = gql`
  mutation createMessageTemplate($messageTemplateInput: MessageTemplateInput) {
    createMessageTemplate(messageTemplateInput: $messageTemplateInput) {
        TemplateName
    }
  }
`;

// ! updateMessageTemplate added by Ashwin
export const UPDATE_MESSAGE_TEMPLATE = gql`
  mutation updateMessageTemplate($id: ID!, $messageTemplateUpdate: MessageTemplateInput) {
    updateMessageTemplate(id: $id, messageTemplateUpdate: $messageTemplateUpdate) {
        TemplateName
    }
  }
`;

// ! createMessageConfiguration added by Ashwin
export const POST_ADD_MESSAGE_CONFIGURATION = gql`
  mutation createMessageConfiguration($messageConfigurationInput: MessageConfigurationInput) {
    createMessageConfiguration(messageConfigurationInput: $messageConfigurationInput) {
      template
      hostels
      guestStatus
      fromDate
      toDate
      timeToSend
    }
  }
`;

// ! updateMessageTemplate added by Ashwin
export const UPDATE_MESSAGE_CONFIGURATION = gql`
  mutation updateMessageConfiguration($id: ID!, $messageConfigurationUpdate: MessageConfigurationInput) {
    updateMessageConfiguration(id: $id, messageConfigurationUpdate: $messageConfigurationUpdate) {
      template
    }
  }
`;
