// ! Added by Ashwin

import gql from "graphql-tag";

export const GET_PAGINATED_ADMIN_USERS_LIST = gql`
  query getPaginatedAdminUsersList($filter: filter) {
    getPaginatedAdminUsersList(filter: $filter) {
      count
      page
      limit
      data {
        _id
        name
        email
        department
        role
        modules
        hostels
        status
        logs
        head
      }
    }
  }
`;

//added by nitish to get all admin users list
export const GET_ALL_ADMIN_USERS_LIST = gql`
  query getAllAdminUsersList($filter: filter) {
    getAllAdminUsersList(filter: $filter) {
      count
      data {
        _id
        name
        email
        password
        department
        role
        modules
        hostels
        status
        logs
      }
    }
  }
`;

export const CHECK_ACCESS = gql`
  query checkAccess {
    checkAccess {
      _id
      role
      modules
      hostels
      department
    }
  }
`;
