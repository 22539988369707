import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHomePageDetails } from "../../../../api/dataSource";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

const Seo = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [updateHomePageDetailsHandler] = useUpdateHomePageDetails();

  const dispatch = useDispatch();

  useEffect(() => {
    let prePopulateData;

    if (state.length) {
      tableDataHandler({
        ...state?.tableData,
        cardData: {
          metaTitle,
          metaDesc,
          keywords,
        },
      });
    }

    if (prePopulateData) {
      setMetaTitle(prePopulateData?.seo.metaTitle);
      setMetaDesc(prePopulateData?.seo.metaDesc);
      setKeywords(prePopulateData?.seo.keywords);
    } else {
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
    }
  }, [state]);

  useEffect(() => {
    if (state.tableData) {
      setMetaTitle(state.tableData?.seo?.metaTitle);
      setMetaDesc(state.tableData?.seo?.metaDesc);
      setKeywords(state.tableData?.seo?.keywords);
    } else {
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
    }
  }, [state.tableData]);

  const submitHandler = (e) => {
    let isValid = false;
    e.preventDefault();

    const data = {
      metaTitle,
      metaDesc,
      keywords,
    };

    if (id) {
      if (data?.metaTitle && data?.metaDesc && data?.keywords) {
        updateHomePageDetailsHandler(id, { seo: data });
        toaster("success", "Updated seo successfully");
        dispatch(
          tableDataHandler({
            ...state?.tableData,
            ...{ seo: data },
          })
        );
      } else {
        toaster("error", "Please fill the seo details marked with *");
      }
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="metaTitle">
              Meta Title*
            </Label>

            <Input
              type="text"
              value={metaTitle}
              id="metaTitle"
              name="metaTitle"
              placeholder="Meta Title"
              onChange={(e) => setMetaTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="metaDesc">
              Meta Description*
            </Label>

            <Input
              type="text"
              value={metaDesc}
              id="metaDesc"
              name="metaDesc"
              placeholder="Meta Description"
              onChange={(e) => setMetaDesc(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="keywords">
              Keywords*
            </Label>

            <Input
              type="text"
              value={keywords}
              id="keywords"
              name="keywords"
              placeholder="Keywords"
              onChange={(e) => setKeywords(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Seo;
