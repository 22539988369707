import Destination from "../../views/Package/Destination"; // ! Added by Ashwin
import Hostel from "../../views/Package/Hostel";
// import Trip from "../../views/Package/Trip";
// import Workation from "../../views/Package/Workation";
import UserProfile from "./UserProfile";
import TravelPackage from "../../views/Package/Travel";

const PackagesRoutes = [
  {
    path: "/package/destinations",
    component: Destination,
    exact: true,
  },
  {
    path: "/package/hostels",
    component: Hostel,
    exact: true,
  },
  // {
  //   path: "/package/workations",
  //   component: Workation,
  //   exact: true,
  // },
  // {
  //   path: "/package/trips",
  //   component: Trip,
  //   exact: true,
  // },
  {
    path: "/userProfile",
    component: UserProfile,
    exact: true,
  },
  {
    path: "/package/travels",
    component: TravelPackage,
    exact: true,
  },
];

export default PackagesRoutes;
