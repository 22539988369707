import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import makeAnimated from "react-select/animated";
import {
  useAddDestination,
  useUpdateDestination,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";
import Select from "react-select";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [destination, setDestination] = useState("");
  const [destinationType, setDestinationType] = useState("");
  const [state, setState] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState(() =>
    EditorState.createEmpty()
  );

  const [interest, setInterest] = useState([]);
  const [status, setStatus] = useState(false);

  const [addDestinationHandler, { data }] = useAddDestination();
  const [updateDestinationHandler, { data: updatedDestinationData }] =
    useUpdateDestination();

  const DestinationTypeList = [
    { label: "Destination", value: "Destination" },
    { label: "State", value: "State" },
    { label: "Category", value: "Category" },
    { label: "Interest", value: "Interest" },
  ];

  // useEffect(() => {
  //   if (data?.createDestination?.destination || updatedDestinationData) {
  //     if (data?.createDestination?.destination) {
  //       toaster("success", "Destination added successfully");
  //     }
  //     if (updatedDestinationData) {
  //       toaster("success", "Destination updated successfully");
  //     }
  //     refetch();
  //     handleToggle();
  //   }
  // }, [data?.createDestination?.destination, updatedDestinationData]);

  const checkdescription = () => {
    try {
      const checkDescription = inputDataConvertor(description);
      return checkDescription;
    } catch (error) {
      return "";
      // toaster("error", "Error");
    }
  };

  const submitDataHandler = () => {
    let data = {
      destination,
      destinationType: destinationType?.value,
      state,
      description: checkdescription(),
      // interest: [interest] || [],
      status,
      url,
      // url: `/destinations/${destination.toLowerCase().split(" ").join("-")}`,
    };
    if (interest?.length > 0) {
      data = { ...data, interest: [interest] };
    }
    if (
      data?.destination?.length > 0 &&
      data?.description?.length > 0 &&
      data?.destinationType?.length > 0 &&
      data?.url?.length > 0
    ) {
      if (editData?.length) {
        const { _id } = editData[0];

        updateDestinationHandler(_id, data);
        toaster("success", "Destination updated successfully");
      } else {
        addDestinationHandler(data);
      }
      setDestination("");
      setDestinationType("");
      setState("");
      setDescription("");
      setInterest([]);
      setStatus(false);
      setUrl("");
      handleToggle();
    } else {
      toaster("error", "Please fill all the required details marked with (*)");
    }
    refetch();
  };

  useEffect(() => {
    if (editData?.length) {
      const {
        destination,
        destinationType,
        state,
        description,
        interest: [interest],
        status,
        url,
      } = editData[0];

      setDestination(destination);
      setDestinationType(
        editData?.destinationType === null || editData?.destinationType === ""
          ? ""
          : {
              label: destinationType,
              value: destinationType,
            }
      );
      setState(state);
      setDescription(htmlToDraftConvert(description));
      setInterest(interest);
      setStatus(status);
      setUrl(url !== null ? url : "");
    } else {
      setDestination("");
      setDestinationType("");
      setState("");
      setDescription("");
      setInterest([]);
      setStatus(false);
      setUrl("");
    }
  }, [editData]);
  console.log(url);
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Destination */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="destination">
                Destination*
              </Label>
              <Input
                type="text"
                value={destination}
                id="destination"
                name="destination"
                placeholder="Add Destination"
                onChange={(e) => setDestination(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Destination Type */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="destinationType">
                Destination Type*
              </Label>
              <Select
                isClearable={false}
                id="destinationType"
                name="destinationType"
                value={destinationType}
                options={DestinationTypeList}
                onChange={(value) => setDestinationType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! State */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="state">
                State
              </Label>
              <Input
                type="text"
                value={state}
                id="state"
                name="state"
                placeholder="state"
                onChange={(e) => setState(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* Add url section for seo */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Url (e.g: /destinations/agra)*
              </Label>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add url e.g: /destinations/agra"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // !   Temporary Description */}
          <Col sm="12">
            <FormGroup>
              <Label className="form-label" htmlFor="destination">
                Description*
              </Label>
              <div className="mt-1 ">
                <MyEditor
                  editorState={description}
                  setEditorState={(val) => {
                    setDescription(val);
                  }}
                />
              </div>
            </FormGroup>
          </Col>
          {/* // ! Interest */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="interest">
                Interest (Enter interests separated by commas e.g:
                snow,mountain)
              </Label>
              <Input
                type="text"
                value={interest}
                id="interest"
                name="interest"
                placeholder="eg: snow,mountain"
                onChange={(e) => setInterest(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Status */}
          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status1"
              name="status1"
              label="Publish on Website ?"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
