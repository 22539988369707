import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const tableHeaderData = [
  "Hostel Name",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
  "12 AM",
];

const OccupancyTrackerTable = ({ data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <div>
      <div style={{ overflowX: "auto", overflowY: "auto" }}>
        <Card>
          <CardHeader>
            <CardTitle>Daily Occupancy Tracker ( Percentage )</CardTitle>
          </CardHeader>

        <div
          style={{
            overflowX: "auto",
            maxHeight: "800px",
            marginBottom: "20px",
          }}
        >
          {/* <CardHeader>
            <CardTitle>Daily Occupancy Tracker ( Percentage )</CardTitle>
          </CardHeader> */}
          <Table stripped bordered>
            <thead
              className="text-center "
              style={{ position: "sticky", top: 0 , zIndex:"2"}}
            >
              <tr>
                <th>Hostel Name</th>
                <th colSpan="3">6 am</th>
                <th colSpan="3">7 am</th>
                <th colSpan="3">8 am</th>
                <th colSpan="3">9 am</th>
                <th colSpan="3">10 am</th>
                <th colSpan="3">11 am</th>
                <th colSpan="3">12 pm</th>
                <th colSpan="3">01 pm</th>
                <th colSpan="3">02 pm</th>
                <th colSpan="3">03 pm</th>
                <th colSpan="3">04 pm</th>
                <th colSpan="3">05 pm</th>
                <th colSpan="3">06 pm</th>
                <th colSpan="3">07 pm</th>
                <th colSpan="3">08 pm</th>
                <th colSpan="3">09 pm</th>
                <th colSpan="3">10 pm</th>
                <th colSpan="3">11 pm</th>
              </tr>
              <tr>
                <th></th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>

                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>

                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>

                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>

                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>

                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
                <th>occup</th>
                <th>adr</th>
                <th>revenue</th>
              </tr>
            </thead>
            {/* </thead> */}
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={{position:"sticky", left:0,zIndex:'1', backdropFilter:"blur(500px)"}}>
                    <h5>{item?.hostelName}</h5>
                  </td>

                  <td style={{}}>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 6;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 6;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 6;
                    })?.revenue?.toFixed()}
                  </td>

                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 7;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 7;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 7;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 8;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 8;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 8;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 9;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 9;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 9;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 10;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 10;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 10;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 11;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 11;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 11;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 12;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 12;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 12;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 13;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 13;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 13;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 14;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 14;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 14;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 15;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 15;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 15;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 16;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 16;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 16;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 17;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 17;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 17;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 18;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 18;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 18;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 19;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 19;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 19;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 20;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 20;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 20;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 21;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 21;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 21;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 22;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 22;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 22;
                    })?.revenue?.toFixed()}
                  </td>
                  <td>
                    {
                      item?.Occupancy.find((occup) => {
                        return occup?.time === 23;
                      })?.percentOccupancy
                    }
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 23;
                    })?.adr?.toFixed()}
                  </td>
                  <td>
                    {item?.Occupancy.find((occup) => {
                      return occup?.time === 23;
                    })?.revenue?.toFixed()}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        </Card>

      </div>
    </div>
  );
};

export default OccupancyTrackerTable;
