import toast from "react-hot-toast";

const toaster = (type, message) => {
  type = type ? type : "error";
  message = message ? message : "Something went wrong";

  if (message !== "Not Authorized") toast[type](message, {duration: 5000});
};

export default toaster;
