import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import TableList from "../../components/common/TableList";
import { useGetHostel, useDeleteHostel } from "../../../api/dataSource";
import { dateFormatHandler } from "@utils";
import { TABLE_TYPE } from "../common/TableList/constant";
import confirmDelete from "@components/common/confirmDelete";

const tableHeaderList = [
  "Action",
  "Hostel Name",
  "Short Name",
  "Business Type",
  "Ezee Code",
  "Created At",
  "Status",
  "Workation Status",
  "Glu Status",
];

const HostelsTable = ({ AddEditModal, filter, page, setPage }) => {
  const [limit, setLimit] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  // const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useGetHostel(limit, page, filter);

  const [deleteHostelHandler] = useDeleteHostel();

  const history = useHistory();

  const handleAdd = () => {
    setOpenModal(true);
  };

  const count = data?.getPaginatedHostelList?.count;

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data) {
      let hostelList = [];
      data.getPaginatedHostelList?.data?.map((item) => {
        hostelList.push({
          id: item._id,
          name: item.name,
          PManager: item?.propertyManager || "",
          AManager: item?.areaManager || "",
          status: item?.status,
          shortName: item?.shortName,
          ezeeConfig: item?.ezeeConfig,
          isWorkation: item?.isWorkation,
          activateGlu: item?.activateGlu,
          businessType: item?.businessType,
          createdAT: dateFormatHandler(item?.createdAt),
        });
      });

      setTableData(hostelList);
      setTotalPage(Math.ceil(data?.getPaginatedHostelList?.count / limit));
    }
  }, [data]);

  useEffect(() => {
    if (editId && data?.getPaginatedHostelList?.data?.length) {
      let editData = data?.getPaginatedHostelList?.data.filter(
        (item) => item._id === editId
      );
      setEditData(editData);
    } else {
      setEditData(null);
    }
  }, [editId, data]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/cms/edit/hostel/${id}`);
    } else {
      setEditId(id);
    }
  };

  const handleDelete = (id) => {
    confirmDelete(() => {
      deleteHostelHandler(id);
      refetch();
    });
  };

  return (
    <div>
      <TableList
        handleAdd={AddEditModal ? handleAdd : null}
        tableHeader={`Hostels (${count !== undefined ? count : 0} count)`}
        // filterHeader="Hostels"
        type={TABLE_TYPE.HOSTEL}
        tableData={tableData}
        tableHeaderList={tableHeaderList}
        openModal={() => setOpenModal(!openModal)}
        handleEdit={handleEdit}
        // handleDelete={AddEditModal ? handleDelete : null}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={() => setOpenModal(!openModal)}
          refetch={refetch}
          editData={editData}
          setEditId={setEditId}
        />
      )}
    </div>
  );
};

export default HostelsTable;
