import React, { useEffect, useMemo, useState } from "react";
import "./styles.css";
import { Button, Card, Col, Row } from "reactstrap";
import {
  useGetAllCompetitorsName,
  useGetCompetitorsByFilters,
  useGetHostelsNameandId,
} from "../../api/dataSource";
import FilterSection from "./Components/FilterSection";
import Table from "./Components/Table";
import { generateDateRange, generateFormattedDates } from "./helpers";

const CompetitorRates = () => {
  //Dates for table
  const [datesToShowToTableHeader, setDatesToShowToTableHeader] = useState(
    generateFormattedDates(new Date(), 7)
  );

  const [dateRangesForMap, setDateRangesForMap] = useState(
    generateDateRange(new Date())
  );

  //Filters
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(["All"]);
  const [selectedOccupancy, setSelectedOccupancy] = useState({
    value: 1,
    label: "1",
  });
  const [sortOrder, setSortOrder] = useState({
    value: "asc",
    label: "Low to High",
  });

  const [hostel, setHostel] = useState([]);
  const [filtersExpanded, setFiltersExpanded] = useState(true);

  //API calls
  const { data: uniqueCompetitors } = useGetAllCompetitorsName();
  const { data: allHostelsData } = useGetHostelsNameandId();
  const { data, refetch, loading, error } = useGetCompetitorsByFilters({
    checkinDate: dateRangesForMap[0],
    checkoutDate: dateRangesForMap.at(-1),
    platform: selectedPlatform.length ? selectedPlatform : ["All"],
    competitor:
      selectedCompetitors.includes("All") || !selectedCompetitors.length
        ? []
        : selectedCompetitors.concat(["The Hosteller"]),
    hostels: hostel?.length ? hostel.map(item => item.value) : ["All"],
    occupency: selectedOccupancy?.value ? selectedOccupancy?.value : 1,
  });

  const _competitorData = data?.getCompetitorsByFilters?.data || [];

  //data for filters
  const getAllCompetitors = useMemo(() => {
    return uniqueCompetitors?.getUniqueCompetitors?.map((competitor) => ({
      value: competitor,
      label: competitor,
    }));
  }, [uniqueCompetitors]);

  const HostelsList = useMemo(() => {
    return allHostelsData?.getHostelsNameandId?.map((item) => ({
      value: item?._id,
      label: item?.name,
    }));
  }, [allHostelsData]);

  console.log(HostelsList);

  //handlers
  const handleCompetitorChange = (selectedOptions) => {
    setSelectedCompetitors(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  const handlePlatformChange = (selectedOptions) => {
    setSelectedPlatform(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  const handleOccupancyChange = (selectedOption) => {
    setSelectedOccupancy(selectedOption);
  };

  const handleDateRangeChange = (selectedDates) => {
    const dateRanges = generateDateRange(selectedDates[0], selectedDates[1]);
    setDateRangesForMap(dateRanges);
    setDatesToShowToTableHeader(
      generateFormattedDates(dateRanges[0], dateRanges.length)
    );
  };

  const handleHostelChange = (selectedOption) => {
    setHostel(selectedOption);
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            style={{
              fontSize: "12px",
              padding: "6px 12px",
              marginBottom: "20px",
              transition:
                "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
              cursor: "pointer",
              outline: "none",
              backgroundImage:
                "linear-gradient(to right, #ff6ec4 0%, #7873f5 100%)",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={() => setFiltersExpanded(!filtersExpanded)}
          >
            {filtersExpanded ? "Collapse Filters" : "Expand Filters"}
          </Button>
          {filtersExpanded && (
            <FilterSection
              uniqueCompetitors={getAllCompetitors}
              handleDateRangeChange={handleDateRangeChange}
              reviewDateFrom={dateRangesForMap[0]}
              reviewDateTo={dateRangesForMap.at(-1)}
              handleCompetitorChange={handleCompetitorChange}
              handlePlatformChange={handlePlatformChange}
              selectedOccupancy={selectedOccupancy}
              handleOccupancyChange={handleOccupancyChange}
              sortOrder={sortOrder}
              // handleSortChange={handleSortChange}
              hostelsList={HostelsList}
              handleHostelChange={handleHostelChange}
              hostel={hostel}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            dateRanges={dateRangesForMap}
            datesToShowToTableHeader={datesToShowToTableHeader}
            selectedCompetitors={selectedCompetitors}
            data={_competitorData}
            // showRoomType
            showMinimumPriceForPropertiesIrrespectiveOfRoomType
            sortOrder={sortOrder}
          />
        </Col>
      </Row>
    </>
  );
};

export default CompetitorRates;
