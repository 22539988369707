// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import {
  useHostelList,
  // useGetEmployeeLeaveBalanceByHostelId,
  useGetEmployeeProfileByHostelId,
  useCreateEmployeeLeaveBalance,
  useUpdateEmployeeLeaveBalance,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SlideModal from "./slide-modal/index";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { selectThemeColors } from "@utils";

const AddEditModal = ({ open, handleToggle, editData, refetch }) => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [leaveType, setLeaveType] = useState(null);
  const [balance, setBalance] = useState("");

  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelList?.map((hostel) => {
      return { label: hostel?.name, value: hostel?._id };
    });
    setHostelsList(HostelsList);
  }, [allHostelsData]);

  const { data: employeeProfileData } = useGetEmployeeProfileByHostelId(
    hostel?.value?.length > 0 ? hostel?.value : null
  );

  const [
    createEmployeeLeaveBalanceHandler,
    { data: createEmployeeLeaveBalanceData },
  ] = useCreateEmployeeLeaveBalance();
  const [
    updateEmployeeLeaveBalanceHandler,
    { data: updateEmployeeLeaveBalanceData },
  ] = useUpdateEmployeeLeaveBalance();

  useEffect(() => {
    let EmployeeList =
      employeeProfileData?.getEmployeeProfileListByHostelId?.map((item) => ({
        label: item?.fullName && `${item?.fullName} (${item?.empCode})`,
        value: item?.fullName && `${item?._id}`,
      }));

    setEmployeeList(EmployeeList);
  }, [employeeProfileData]);

  const YearList = [
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
  ];

  const MonthList = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const LeaveTypeList = [
    { label: "PL", value: "PL" },
    { label: "CL", value: "CL" },
  ];

  useEffect(() => {
    if (editData?._id) {
      const { hostelId, empId, year, month, leaveType, balance } = editData;
      setHostel(
        hostel?._id !== null
          ? { label: hostelId?.name, value: hostelId?._id }
          : null
      );
      setEmployee(
        empId?._id !== null
          ? { label: empId?.fullName, value: empId?._id }
          : null
      );
      setYear(year !== null ? { label: year.toString(), value: year } : null);

      const monthDB = MonthList.find(
        (item) =>
          item.value === month && { label: item.label, value: item.value }
      );
      setMonth(year !== null ? { label: monthDB?.label, value: month } : null);
      setLeaveType(
        leaveType !== null ? { label: leaveType, value: leaveType } : null
      );
      setBalance(balance !== null ? balance : "");
    } else {
      setHostel(null);
      setEmployee(null);
      setYear(null);
      setMonth(null);
      setLeaveType(null);
      setBalance("");
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const data = {
      hostelId: hostel?.value,
      empId: employee?.value,
      year: year?.value,
      month: month?.value,
      leaveType: leaveType?.value,
      balance: parseFloat(balance),
    };

    if (data?.hostelId && data?.empId) {
      if (editData?._id && data.hostelId && data.empId) {
        const { _id } = editData;
        updateEmployeeLeaveBalanceHandler(_id, data);
      } else {
        createEmployeeLeaveBalanceHandler(data);
      }
      setHostel(null);
      setEmployee(null);
      setYear(null);
      setMonth(null);
      setLeaveType(null);
      setBalance("");
      toaster("success", "Employee leave balance added successfully");
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };

  useEffect(() => {
    if (createEmployeeLeaveBalanceData || updateEmployeeLeaveBalanceData) {
      refetch();
      handleToggle();
    }
  }, [createEmployeeLeaveBalanceData, updateEmployeeLeaveBalanceData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {/* // ! Hostel */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={HostelsList}
                required
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Employee */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="employee">
                Employee*
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                id="employee"
                name="employee"
                value={employee}
                options={EmployeeList}
                onChange={(value) => {
                  setEmployee(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! year */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="year">
                Year
              </Label>

              <Select
                isClearable={false}
                id="year"
                name="year"
                value={year}
                options={YearList}
                onChange={(value) => setYear(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! month */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="month">
                Month
              </Label>
              <Select
                isClearable={false}
                id="month"
                name="month"
                value={month}
                options={MonthList}
                onChange={(value) => setMonth(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! leaveType */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="leaveType">
                Leave Type
              </Label>
              <Select
                isClearable={false}
                id="leaveType"
                name="leaveType"
                value={leaveType}
                options={LeaveTypeList}
                onChange={(value) => setLeaveType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! balance */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="balance">
                Balance
              </Label>
              <Input
                type="text"
                value={balance}
                id="balance"
                name="balance"
                placeholder="Add balance leave"
                required
                onChange={(e) => setBalance(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
