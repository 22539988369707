import React from "react";
import { CardHeader, Col, Row, Table } from "reactstrap";
import moment from "moment";
// import { useGetAllCafeMasterList } from "../../../api/Unbox/UnboxCafeMaster/Queries";
export const generateDateRange = (fromDate, toDate) => {
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thrusday",
    "Friday",
    "Saturday",
  ];
  const dateList = [];
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);

  for (
    let currentDate = startDate;
    currentDate <= endDate;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    const formattedDate = {
      //date: currentDate.toLocaleDateString("en-IN"),
      date: moment(currentDate, "D/M/YYYY").format("DD/MM/YYYY"),
      //date: currentDate.toISOString().split("T")[0],
      day: dayArray[currentDate.getDay()],
    };
    dateList.push(formattedDate);
  }

  return dateList;
};
const Report = ({ daywiseData, fromDate, toDate }) => {
  // const { data: cafeListData } = useGetAllCafeMasterList();

  const dateList = generateDateRange(fromDate, toDate);
  return (
    <div
    //style={{ overflowX: "auto", overflowY: "auto" }}
    >
      <Row>
        <Col>
          <>
            <CardHeader style={{ minWidth: "full" }}>
              <h4 style={{}}>Daywise Sales Report</h4>
            </CardHeader>
            <div style={{ overflowX: "auto", maxHeight: "600px" }}>
              <Table bordered>
                <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                  <tr>
                    <th style={{ position: "sticky", left: 0, zIndex: 4 }}>
                      Cafe Name
                    </th>
                    {daywiseData?.map((cafe, index) => {
                      return (
                        <th
                          colSpan="3"
                          style={{
                            //marginLeft: "50px",
                            textAlign: "center",
                            minWidth: "150px",
                            position: "sticky",

                            zIndex: 1,
                            // border: "1px solid grey",
                          }}
                          key={index}
                        >
                          <div>{cafe?.cafeInfo[0]?.cafeName}</div>
                        </th>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ position: "sticky", left: 0, zIndex: 4 }}>
                      Date
                    </th>
                    {daywiseData?.map((cafe, i) => {
                      return (
                        <React.Fragment key={i}>
                          <th
                            style={
                              {
                                //border: "1px solid black"
                              }
                            }
                          >
                            Order Count
                          </th>
                          <th
                            style={
                              {
                                //border: "1px solid black"
                              }
                            }
                          >
                            Total Sales
                          </th>
                          <th>Net Sales</th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {/* {cafeListData?.getAllCafeMasterList?.map((cafe) => {
                    const findCafeData = daywiseData?.find(
                      (cafeData) =>
                        cafe?.cafeName === cafeData?.cafeInfo[0]?.cafeName
                    );

                    return (
                      <tr key={cafe?._id}>
                        <td
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                            //backdropFilter: "blur(400px)",
                          }}
                        >
                          {cafe?.cafeName}
                        </td>
                        {!findCafeData
                          ? dateList?.map((date) => (
                              <>
                                <td>{"--"}</td>
                                <td>{"--"}</td>
                              </>
                            ))
                          : dateList?.map((date) => {
                              const findIfDateExist =
                                findCafeData?.dayRangeData?.find(
                                  (cafeData) => cafeData?.date === date
                                );

                              return findIfDateExist ? (
                                <>
                                  <td>{findIfDateExist?.totalOrderCount}</td>
                                  <td>
                                    {findIfDateExist?.totalOrderAmount.toFixed(
                                      2
                                    )}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>{"--"}</td>
                                  <td>{"--"}</td>
                                </>
                              );
                            })}
                      </tr>
                    );
                  })} */}
                  {/* {daywiseData?.map((cafeData) => {
                    return (
                      <tr key={cafeData?._id}>
                        <td
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                            //backdropFilter: "blur(400px)",
                          }}
                        >
                          {cafeData?.cafeInfo[0]?.cafeName}
                        </td>
                        {dateList?.map((date) => {
                          const findIfDateExist = cafeData?.dayRangeData?.find(
                            (cafeData) => cafeData?.date === date?.date
                          );

                          return findIfDateExist ? (
                            <>
                              <td>{findIfDateExist?.totalOrderCount}</td>
                              <td>
                                {findIfDateExist?.totalOrderAmount.toFixed(2)}
                              </td>
                              <td>
                                {findIfDateExist?.totalNetSales.toFixed(2)}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                            </>
                          );
                        })}
                      </tr>
                    );
                  })} */}
                  {dateList?.map((date, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backdropFilter: "blur(400px)",
                          }}
                        >
                          <span>{date?.date}</span>
                          <span>({date?.day})</span>
                        </td>
                        {/* {findIfDateExist ? (
                          <>
                            <td>{findIfDateExist?.totalOrderCount}</td>
                            <td>{findIfDateExist?.totalOrderAmount}</td>
                            <td>{findIfDateExist?.totalNetSales}</td>
                          </>
                        ) : (
                          <>
                            <td>{"--"}</td>
                            <td>{"--"}</td>
                            <td>{"--"}</td>
                          </>
                        )} */}
                        {daywiseData?.map((ele, j) => {
                          const findIfDateExist =
                            ele?.dayRangeData?.find(
                              (el) => el?.date === date?.date
                            ) || null;

                          return findIfDateExist ? (
                            <React.Fragment key={j}>
                              <td>{findIfDateExist?.totalOrderCount}</td>
                              <td>
                                {findIfDateExist?.totalOrderAmount.toFixed(2)}
                              </td>
                              <td>
                                {findIfDateExist?.totalNetSales.toFixed(2)}
                              </td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={j}>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                              <td>{"--"}</td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default Report;
