import { Edit } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Hostel",
  "Employee (code)",
  "Leave Type",
  "Balance",
];

const EmployeeLeaveBalanceTable = ({ setId, id, openModal, data }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>
                  All employees leave balance (till 1<sup>st</sup> September
                  2023)
                </h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add employee leave balance Info.
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "5%" }}>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>{item?.hostelId?.name}</td>
                  <td>
                    {item?.empId?.fullName} ({item?.empId?.empCode})
                  </td>
                  <td>{item?.leaveType}</td>
                  <td>{item?.balance}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default EmployeeLeaveBalanceTable;
