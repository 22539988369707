import React, { useState, useEffect } from "react";
import AddEditModal from "./AddEditModal";
// import WhatsNewTable from "../../../@core/components/tables/WhatsNewTable";
import WhatsNewTable from "./WhatsNewTable";
import { useGetAllPaginatedWhatsNew } from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";

// const WhatsNew = () =>
//   localStorage.getItem("modules")?.includes("CMS") && (
//     <WhatsNewTable AddEditModal={AddEditModal} />
//   );

const WhatsNew = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");

  const { data, loading, refetch } = useGetAllPaginatedWhatsNew(
    limit,
    page,
    filterData
  );

  const statusType = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const handleSearch = () => {
    const data = {
      title,
      status: status?.value,
    };
    setFilterData(data);
    setPage(1);
  };

  const handleReset = () => {
    setTitle("");
    setStatus(null);
    setFilterData(null);
    setPage(1);
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.getPaginatedWhatsNewList?.data);
      setTotalPage(Math.ceil(data?.getPaginatedWhatsNewList?.count / limit));
    }
  }, [data]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    // console.log("here")
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    if (data?.getPaginatedWhatsNewList) {
      let editData = data?.getPaginatedWhatsNewList?.data?.find(
        (item) => item._id === id
      );
      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [id]);

  return (
    localStorage.getItem("modules")?.includes("CMS") && (
      <>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="title">
                  Title
                </Label>
              </strong>
              <Input
                type="text"
                value={title}
                id="title"
                name="title"
                placeholder="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="status">
                  Publish on website status
                </Label>
              </strong>
              <Select
                isSearchable={true}
                isClearable={false}
                id="status"
                name="status"
                value={status}
                options={statusType}
                onChange={(value) => setStatus(value)}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        <WhatsNewTable
          tableData={tableData}
          setTableData={setTableData}
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          refetch={refetch}
        />
        <TablePagination
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          loading={loading}
        />
        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      </>
    )
  );
};

export default WhatsNew;
