import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateAbout, useUploadImage } from "@api";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { v4 as uuidv4 } from "uuid";
import { X, Camera } from "react-feather";
import Avatar from "@components/common/avatar";
import confirmDelete from "@components/common/confirmDelete";

const Team = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [index, setIndex] = useState("");
  const [members, setMembers] = useState([
    {
      id: uuidv4(),
      name: "",
      position: "",
      linkedIn: "",
    },
  ]);

  const [updateAbout, { data, reset }] = useUpdateAbout();
  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  useEffect(() => {
    if (state.tableData?.team) {
      setHeading(state.tableData.team.heading);
      setDescription(state.tableData.team.description);

      let upadtedMembers = state.tableData.team.members?.map((item) => {
        return {
          id: uuidv4(),
          image: item?.image,
          name: item?.name,
          position: item?.position,
          linkedIn: item?.linkedIn,
        };
      });

      setMembers(upadtedMembers);
    }
  }, [state.tableData]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setMembers(
        members.map((item) => {
          if (item.id === index) {
            return { ...item, image: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  useEffect(() => {
    if (data?.updateAbout) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          team: { heading, description, members },
        })
      );

      reset();
    }
  }, [data?.updateAbout]);

  const handleRemoveCards = (id) =>
    confirmDelete(() => setMembers(members.filter((item) => item.id !== id)));

  const handleAddCards = () => {
    if (members.length < 20) {
      setMembers([
        ...members,
        { id: uuidv4(), name: "", position: "", linkedIn: "" },
      ]);
    } else toaster("error", "Maximum limit of 20 has reached");
  };

  const handleChangeName = (name, id) => {
    setMembers(
      members.map((item) => (item.id === id ? { ...item, name } : item))
    );
  };

  const handleChangePosition = (position, id) => {
    setMembers(
      members.map((item) => (item.id === id ? { ...item, position } : item))
    );
  };

  const handleChangeLinkedIn = (linkedIn, id) => {
    setMembers(
      members.map((item) => (item.id === id ? { ...item, linkedIn } : item))
    );
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);
    uploadImageHandler(e.target.files[0], "About");
  };

  const submitDataHandler = (e) => {
    e.preventDefault();

    let isValid = true;

    const team = {
      heading,
      description,
      members,
    };

    members?.forEach((item) => {
      isValid &&
        (item.name && item.position && item.image && item.linkedIn
          ? (isValid = true)
          : (isValid = false));
    });

    if (heading && description && isValid) {
      members?.forEach((item) => delete item.id);

      updateAbout({ team });
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="heading">
              Heading
            </Label>

            <Input
              type="text"
              value={heading}
              id="heading"
              name="heading"
              placeholder="Heading"
              onChange={(e) => setHeading(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="description">
              Description
            </Label>

            <Input
              type="textarea"
              value={description}
              id="description"
              name="description"
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        </Col>

        <br />

        <Col className="mt-3 mb-1" sm="12">
          <h3>Members</h3>
        </Col>

        {members.map(({ name, image, position, linkedIn, id }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={index}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"cards" + id}>
                Member {index + 1}
              </Label>

              <div className="mt-2 d-flex align-items-center">
                <div className="mb-1 mr-2">
                  {<Avatar size="xl" img={image} alt={image} />}
                </div>

                <label htmlFor={`myFile${id}`}>
                  <p className="btn btn-sm btn-light">
                    <Camera size={25} />
                  </p>
                  <input
                    name={`myFile${id}`}
                    id={`myFile${id}`}
                    className="d-none"
                    type="file"
                    onChange={(e) => fileUploader(e, id)}
                  />
                </label>
              </div>

              <Input
                type="text"
                value={name}
                id={"name" + id}
                name={"name"}
                placeholder="name"
                onChange={(e) => handleChangeName(e.target.value, id)}
              />

              <br />

              <Input
                rows={4}
                type="text"
                value={position}
                id={"position" + id}
                name={"Add position"}
                placeholder="Add position"
                onChange={(e) => handleChangePosition(e.target.value, id)}
              />

              <br />

              <Input
                rows={4}
                type="text"
                value={linkedIn}
                id={"linkedIn" + id}
                name={"Add linkedIn"}
                placeholder="Add linkedIn link"
                onChange={(e) => handleChangeLinkedIn(e.target.value, id)}
              />
            </FormGroup>
            {members.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveCards(id)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddCards} />
    </form>
  );
};

export default Team;
