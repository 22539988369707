import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import { useParams } from "react-router";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateStepOutPolicies } from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import { STEP_OUT_POLICIES } from "../../../utility/constant";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import MyEditor from "@components/common/Editor";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import confirmDelete from "@components/common/confirmDelete";

const Form = ({ type }) => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [updateStepOutPoliciesHandler, { data, reset }] =
    useUpdateStepOutPolicies();
  const [policy, setPolicy] = useState([
    {
      id: uuidv4(),
      header: "",
      description: EditorState.createEmpty(),
    },
  ]);

  useEffect(() => {
    if (state?.tableData?.[type]?.length) {
      let upadtedPolicy = state?.tableData?.[type]?.map((item) => {
        return {
          id: uuidv4(),
          header: item?.header,
          description: item?.description
            ? customHTMLToDraftConvert(item?.description)
            : customHTMLToDraftConvert(item),
        };
      });
      setPolicy(upadtedPolicy);
    }
  }, [state?.tableData?.[type]]);

  const customHTMLToDraftConvert = (value) => {
    value = value
      .replace(/&amp;/g, "&")
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<")
      .replace(/&quot;/g, '"');

    return EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(value)?.contentBlocks)
    );
  };

  useEffect(() => {
    if (data?.updatePolicy?._id) {
      // toaster("success", "Policy updated successfully");

      reset();
    }
  }, [data]);

  const handleRemove = (id) =>
    confirmDelete(() => setPolicy(policy?.filter((item) => item?.id !== id)));

  const handleAdd = () =>
    setPolicy([
      ...policy,
      { id: uuidv4(), header: "", description: EditorState.createEmpty() },
    ]);

  const handleChangeHeader = (header, id) => {
    setPolicy(
      policy?.map((item) => (item?.id === id ? { ...item, header } : item))
    );
  };

  const handleChangeDescription = (description, id) =>
    setPolicy(
      policy?.map((item) => (item?.id === id ? { ...item, description } : item))
    );

  const submitDatahandler = (e) => {
    e.preventDefault();
    let isValid = false;

    let updatedArray = policy?.map((item) => {
      let description = draftToHtml(
        convertToRaw(item?.description?.getCurrentContent())
      );

      return { ...item, description };
    });

    let data = {
      [type]: updatedArray,
    };

    data?.[type]?.forEach((item) => {
      if (item?.header && item?.description) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      data?.[type]?.forEach((item) => delete item?.id);

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          [type]: updatedArray,
        })
      );

      updateStepOutPoliciesHandler(STEP_OUT_POLICIES, data);
      toaster("success", "Policy updated successfully");
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form onSubmit={submitDatahandler}>
      <Row className="mt-2">
        {policy?.map(({ header, description, id }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={index}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"policy" + id}>
                Policy - {index + 1}
              </Label>

              <Input
                type="text"
                value={header}
                id={"policy" + id}
                name={"header"}
                placeholder="Add header"
                onChange={(e) => handleChangeHeader(e.target.value, id)}
              />
              <br />

              <MyEditor
                editorState={description}
                id={id}
                setEditorState={(val) => handleChangeDescription(val, id)}
              />
            </FormGroup>
            {policy?.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemove(id)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAdd} />
    </form>
  );
};

export default Form;
