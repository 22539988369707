import React, { useEffect, useState } from "react";
import WalletReportTable from "./WalletReportTable";
import {
  useGetWalletBalanceReport,
  useGetWalletBalanceReportList,
} from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import { Col, Row, Input, FormGroup, Label, Button, Form } from "reactstrap";
import { CSVLink } from "react-csv";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";

const exportHeaders = [
  { label: "Guest Full Name", key: "fullName" },
  // { label: "First Name", key: "firstName" },
  // { label: "Last Name", key: "lastName" },
  { label: "Guest Email", key: "email" },
  { label: "Guest Mobile Number", key: "mobile" },
  { label: "Amount", key: "amount" },
  // { label: "User Id", key: "userId" },
];

const WalletReport = () => {
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState({});
  const [email, setEmail] = useState("");
  const [exportWallet, setExportWallet] = useState([]);

  const [transactionDate, setTransactionDate] = useState(
    dateFormatter(new Date())
  );

  const { data, refetch } = useGetWalletBalanceReport(
    limit,
    page,
    filteredData
  );

  const { data: exportWalletData } = useGetWalletBalanceReportList();

  const [totalPage, setTotalPage] = useState(1);

  const totalAmount = data?.getWalletBalanceReport?.totalamount;

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (exportWalletData?.getAllWalletBalanceReport?.length > 0) {
      setExportWallet(exportWalletData?.getAllWalletBalanceReport);
    } else {
      setExportWallet([]);
    }
  }, [exportWalletData?.getAllWalletBalanceReport]);

  useEffect(() => {
    if (data?.getWalletBalanceReport?.data) {
      setTableData(data?.getWalletBalanceReport?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getWalletBalanceReport?.count / limit));
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email,
      transactionDate,
    };

    setFilteredData(data);
    setPage(1);
  };

  return (
    localStorage.getItem("modules")?.includes("Wallet") && (
      <>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm="5">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="email">
                    Guest Email
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={email}
                  id="email"
                  name="email"
                  // placeholder="Add user email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="5">
              <FormGroup className="w-100">
                <Label className="form-label" for="transactionDate">
                  Select upto date
                </Label>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  value={transactionDate}
                  onChange={(transactionDate) =>
                    setTransactionDate(dateFormatter(new Date(transactionDate)))
                  }
                  id="transactionDate"
                  placeholder="Date"
                />
              </FormGroup>
            </Col>

            <Col sm="1">
              <Button.Ripple
                color="light"
                type="submit"
                style={{ marginTop: "21px" }}
              >
                Search
              </Button.Ripple>
            </Col>

            <Col sm="1">
              <CSVLink
                headers={exportHeaders}
                data={exportWallet}
                filename={`wallet_report_list.csv`}
              >
                <Button.Ripple
                  className="btn-icon mb-2 ml-1"
                  color="light"
                  type="button"
                  style={{ marginTop: "21px", marginRight: "20px" }}
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            </Col>
          </Row>
        </Form>
        <WalletReportTable
          data={tableData}
          refetch={refetch}
          totalAmount={totalAmount}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      </>
    )
  );
};

export default WalletReport;
