import React, { useEffect, useState } from "react";
import AddOnTable from "./AddOnTable";
import { useGetAddOnReport } from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import Select from "react-select";
import { useHostelList, useAddOnList } from "../../../api/dataSource";
import { selectThemeColors } from "@utils";

const AddOn = () => {
  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const [addOnList, setAddOnList] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [addOn, setAddOn] = useState(null);
  const [filter, setFilter] = useState(null);
  const { data } = useGetAddOnReport(
    limit,
    page,
    filter?.hostelIds?.length > 0
      ? filter?.hostelIds
      : JSON.parse(localStorage.getItem("hostels")),
    filter?.checkinStartDate,
    filter?.checkinEndDate,
    filter?.reservationId,
    filter?.addOn,
    filter?.purchaseStartDate,
    filter?.purchaseEndDate
  );

  const { data: allHostelsData } = useHostelList();
  const { data: allAddOnList } = useAddOnList();

  const [totalPage, setTotalPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [checkinStartDate, setCheckinStartDate] = useState("");
  const [checkinEndDate, setCheckinEndDate] = useState("");

  const handleClear = () => {
    setCheckinEndDate(null);
    setCheckinStartDate(null);
    setReservationId("");
    setAddOn(null);
    setHostel([]);
    setReservationId("");
    setStartDate("");
    setEndDate("");
    setPage(1);
    setFilter(null);
  };

  const handleSearch = () => {
    const data = {
      limit,
      page,
      hostelIds: hostel?.map((hostel) => hostel.value),
      checkinStartDate,
      checkinEndDate,
      reservationId,
      addOn: addOn?.value,
      purchaseStartDate: startDate,
      purchaseEndDate: endDate,
    };
    setFilter(data);
  };

  useEffect(() => {
    // refetch();
    setPage(1);
  }, [filter]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    // setFilter({ ...filter, hostelIds: allowedHostels });
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    if (allAddOnList?.getListOfAddons?.length > 0) {
      let addOnList = allAddOnList?.getListOfAddons?.map((item) => {
        return { label: item?.addOn, value: item?.addOn };
      });
      setAddOnList(addOnList);
    }
  }, [allAddOnList?.getListOfAddons]);

  useEffect(() => {
    if (data?.getAddOns?.data?.length > 0) {
      setTableData(data?.getAddOns?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getAddOns?.count / limit));
  }, [data?.getAddOns?.count, data?.getAddOns?.data, limit]);

  // useEffect(() => {
  //   if (allHostelsData?.getHostelList?.length) {
  //     let HostelsList = allHostelsData?.getHostelList?.map((item) => {
  //       return { label: item?.name, value: item?._id };
  //     });
  //     setHostelsList(HostelsList);
  //   }
  // }, [allHostelsData?.getHostelList]);

  return (
    localStorage.getItem("modules")?.includes("Reports") &&  <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Reservation ID</strong>
            </Label>
            <Input
              type="text"
              value={reservationId}
              onChange={(e) => setReservationId(e.target.value)}
              placeholder="Reservation ID"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Add On</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              id="hostel"
              name="hostel"
              value={addOn}
              options={addOnList}
              onChange={(value) => {
                setAddOn(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Checkin date range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={checkinStartDate}
              options={{
                mode: "range",
              }}
              onChange={(date) => {
                setCheckinStartDate(dateFormatter(date[0]));
                setCheckinEndDate(dateFormatter(date[1]));
              }}
              id="checkinDate"
              // isClearable={true}
              placeholder="Checkin date range"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Purchased date range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={startDate}
              onChange={(date) => {
                setStartDate(dateFormatter(date[0]));
                setEndDate(dateFormatter(date[1]));
              }}
              options={{
                mode: "range",
              }}
              id="purchasedDate"
              // isClearable={true}
              placeholder="Purchased date range"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Hostel</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              options={HostelsList}
              onChange={(value) => {
                setHostel(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col>
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="primary"
            type="button"
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button>
          <Button
            className="pull-right"
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            type="button"
            onClick={() => {
              handleClear();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <AddOnTable data={tableData} />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
    </div>
  );
};

export default AddOn;
