import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CmsTabs from "@components/Cms/CmsTabs";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import { useGetStepOutProductById } from "../../api/dataSource";
import Overview from "./StepOutProduct/Overview";
import KeyDetails from "./StepOutProduct/KeyDetails";
import ImagesForm from "./StepOutProduct/ImagesForm";
import InclusionInfo from "./StepOutProduct/InclusionInfo";
import ExclusionInfo from "./StepOutProduct/ExclusionInfo";
import OtherInfo from "./StepOutProduct/OtherInfo";
import Pickup from "./StepOutProduct/Pickup";
import RoomType from "./StepOutProduct/RoomType";
import Inclusions from "./StepOutProduct/Inclusions";
import SimilarProducts from "./StepOutProduct/SimilarProducts";
import Itinerary from "./StepOutProduct/Itinerary";
import Faqs from "./StepOutProduct/FaqForm";
import Policies from "./StepOutProduct/Policies";
import Batches from "./StepOutProduct/Batches";
import TripUsp from "./StepOutProduct/TripUsp"

const tabOptions = [
  {
    id: 0,
    title: "Overview",
    formComponent: <Overview />,
  },
  {
    id: 1,
    title: "Key details",
    formComponent: <KeyDetails />,
  },
  {
    id: 2,
    title: "Images",
    formComponent: <ImagesForm />,
  },
  {
    id: 3,
    title: "Inclusions Icons",
    formComponent: <Inclusions />,
  },
  {
    id: 4,
    title: "Itinerary",
    formComponent: <Itinerary />,
  },
  {
    id: 5,
    title: "Batches",
    formComponent: <Batches />,
  },
  {
    id: 6,
    title: "Starting points",
    formComponent: <Pickup />,
  },
  {
    id: 7,
    title: "Room types",
    formComponent: <RoomType />,
  },
  {
    id: 8,
    title: "Trip Usp",
    formComponent: <TripUsp />,
  },
  {
    id: 9,
    title: "Inclusion info",
    formComponent: <InclusionInfo />,
  },
  {
    id: 10,
    title: "Exclusion info",
    formComponent: <ExclusionInfo />,
  },
  {
    id: 11,
    title: "Things to pack",
    formComponent: <OtherInfo />, 
  },
  {
    id: 12,
    title: "Faqs",
    formComponent: <Faqs />,
  },
  {
    id: 13,
    title: "Policies",
    formComponent: <Policies />,
  },
  {
    id: 14,
    title: "Similar products",
    formComponent: <SimilarProducts />,
  },
];

const AddStepOutTabs = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [active, setActive] = useState(0);
  const { data, refetch } = useGetStepOutProductById(id);

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getStepOutProductById) {
      dispatch(tableDataHandler(data?.getStepOutProductById));
    }
    refetch();
  }, [data, dispatch]);

  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle>
                  {active !== 0 && "Step out product information - "}
                  {tabOptions.find((item) => item.id === active).title}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default AddStepOutTabs;
