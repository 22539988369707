import React, { useEffect, useState } from "react";
import {
  useGetAllUnboxOrderForExport,
  useGetPaginatedUnboxOrder,
} from "../../../api/Unbox/UnboxOrder/Queries";
import UnboxOrderTable from "./UnboxOrderTable";
import TablePagination from "../../../@core/components/common/TablePagination";
import AddEditModal, { paymentModeList } from "./AddEditModal";
import Select from "react-select";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  useGetAllCafeMasterList,
  useGetCafeByHostelId,
} from "../../../api/Unbox/UnboxCafeMaster/Queries";
import { statusList } from "./AddEditModal";
import Flatpickr from "react-flatpickr";
import toaster from "@components/common/Toaster";
import { selectThemeColors } from "@utils";
const { dateFormatter } = require("../../../utility/Utils");
const UnboxOrder = () => {
  const hostelId = JSON.parse(localStorage.getItem("hostels")) || [];
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [editData, setEditData] = useState(null);
  const [cafeList, setCafeList] = useState([]);
  const [cafe, setCafe] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [guestName, setGuestName] = useState("");
  const [status, setStatus] = useState(null);
  const [filterFeilds, setFilterFeilds] = useState({});
  const [totalSales, setTotalSales] = useState(0);
  const [netSales, setNetTotalSales] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [paymentMode, setPaymentMode] = useState(null);
  const [filter, setFilter] = useState(null);

  const { data: cafes } = useGetCafeByHostelId(hostelId);
  const { data: cafeData, refetch: cafeRefetch } = useGetAllCafeMasterList();
  const { data, refetch } = useGetPaginatedUnboxOrder(
    limit,
    page,
    filterFeilds
  );

  const { data: orderData, error } = useGetAllUnboxOrderForExport(filter);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    if (hostelId?.length !== 0 && cafes?.getCafeByHostelId?.length > 0) {
      let cafe = cafes?.getCafeByHostelId?.map((el) => {
        return { label: el?.cafeName, value: el?._id };
      });
      setCafeList(cafe);
      //setCafe(cafe);
    } else {
      const cafeArray = cafeData?.getAllCafeMasterList?.map((cafe) => {
        return { label: cafe?.cafeName, value: cafe?._id };
      });

      setCafeList(cafeArray);
    }
    // if (cafeData?.getAllCafeMasterList?.length > 0) {
    //   const cafelabel = cafeData?.getAllCafeMasterList?.map((el) => {
    //     return { label: el?.cafeName, value: el?._id };
    //   });

    //   setCafeList(cafelabel);
    // }
  }, [
    cafeData?.getAllCafeMasterList,
    cafes?.getCafeByHostelId,
    hostelId?.length,
  ]);

  useEffect(() => {
    refetch();
    if (data?.getPaginatedUnboxOrder?.data?.length > 0) {
      setTableData(data?.getPaginatedUnboxOrder?.data);
      setTotalSales(data?.getPaginatedUnboxOrder?.totalSales);
      setNetTotalSales(data?.getPaginatedUnboxOrder?.netSales);
      setTotalOrder(data?.getPaginatedUnboxOrder?.totalOrder);
      setTotalDiscount(data?.getPaginatedUnboxOrder?.totalDiscount);
    } else {
      setTableData([]);
      setTotalDiscount(0);
      setTotalOrder(0);
      setTotalSales(0);
      setNetTotalSales(0);
    }
    setTotalPage(Math.ceil(data?.getPaginatedUnboxOrder?.count / limit));
  }, [
    data?.getPaginatedUnboxOrder?.count,
    data?.getPaginatedUnboxOrder?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedUnboxOrder?.data?.length > 0) {
      let findData = data?.getPaginatedUnboxOrder?.data?.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedUnboxOrder?.data, id]);

  useEffect(() => {
    if (orderData?.getAllUnboxOrderForExport?.data) {
      if (orderData?.getAllUnboxOrderForExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          orderData?.getAllUnboxOrderForExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `unbox_Orders.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setFilter(null);
      }
    }
  }, [orderData]);

  const handleSearch = () => {
    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
      toaster("error", "Start date should be less than end date");
    } else {
      const filterFeilds = {
        cafe: cafe?.map((obj) => obj?.value),
        orderId: orderId,
        reservationId: reservationId,
        guestName: guestName,
        status: status?.map((i) => i?.value),
        fromDate: fromDate,
        toDate: toDate,
        paymentMode: paymentMode?.map((i) => i?.value),
      };
      setPage(1);
      setFilterFeilds(filterFeilds);
    }
  };

  const handleClickExport = () => {
    setFilter(filterFeilds);
  };
  const handleReset = () => {
    setFilterFeilds({});
    setCafe(null);
    setOrderId("");
    setReservationId("");
    setGuestName("");
    setStatus(null);
    setFromDate(null);
    setToDate(null);
    setPaymentMode(null);
    setFilter(null);
    setPage(1);
  };
  return (
    <div>
      <Row>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              <strong>From Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromDate}
              onChange={(date) => {
                setFromDate(dateFormatter(new Date(date)));
              }}
              id="fromDate"
              placeholder="From Date"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="toDate">
              <strong>To Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toDate}
              onChange={(date) => setToDate(dateFormatter(new Date(date)))}
              id="toDate"
              placeholder="To Date"
            />
          </FormGroup>
        </Col>
        {/* {cafeList?.length > 1 && ( */}
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="status">
              <strong>Status</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              isMulti
              id="status"
              name="status"
              value={status}
              theme={selectThemeColors}
              closeMenuOnSelect={false}
              options={statusList}
              onChange={(value) => {
                setStatus(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        {hostelId?.length !== 1 && (
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="cafe">
                <strong>Cafe</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="cafe"
                name="cafe"
                value={cafe}
                closeMenuOnSelect={false}
                options={cafeList}
                onChange={(value) => {
                  setCafe(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        )}
        {/* )} */}

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="orderId">
              <strong>Order ID</strong>
            </Label>
            <Input
              id="orderId"
              name="orderId"
              value={orderId}
              placeholder="Order ID"
              onChange={(e) => {
                setOrderId(e.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="reservationId">
              <strong>Reservation ID</strong>
            </Label>
            <Input
              id="reservationId"
              name="reservationId"
              value={reservationId}
              placeholder="Reservation ID"
              onChange={(e) => {
                setReservationId(e.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="guestName">
              <strong>Guest Name</strong>
            </Label>
            <Input
              id="guestName"
              name="guestName"
              placeholder="Guest Name"
              value={guestName}
              onChange={(e) => setGuestName(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="paymentMode">
              <strong>Payment Mode</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              isMulti
              id="paymentMode"
              name="paymentMode"
              theme={selectThemeColors}
              value={paymentMode}
              closeMenuOnSelect={false}
              options={paymentModeList}
              onChange={(value) => {
                setPaymentMode(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col className="" sm="3" style={{ display: "flex", gap: "20px" }}>
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              //marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            style={{
              height: "40px",
              marginTop: "22px",
              //marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="success"
            appearance="success"
            onClick={(e) => {
              handleClickExport();
            }}
          >
            Export
          </Button>
        </Col>
      </Row>
      <UnboxOrderTable
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        setId={setId}
        refetch={refetch}
        totalSales={totalSales}
        netSales={netSales}
        totalOrder={totalOrder}
        totalDiscount={totalDiscount}
        filterFeilds={filterFeilds}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      <AddEditModal
        setId={setId}
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default UnboxOrder;
