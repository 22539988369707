import EmployeeProfile from "../../views/HR/EmployeeProfile";
import AddHRTabs from "../../views/HR/EmployeeProfile/AddHRTabs";
import EmployeeAttendance from "../../views/HR/EmployeeAttendance"
import EmployeeLeaveBalance from "../../views/HR/EmployeeLeaveBalance"
import ImportFiles from "../../views/HR/EmployeeProfile/ImportFiles";

const HRRoutes = [
  {
    path: "/hr/employeeProfile",
    component: EmployeeProfile,
    exact: true,
  },
  {
    path: "/hr/edit/employeeProfile/:id",
    component: AddHRTabs,
    exact: true,
    meta: {
      navLink: "/hr",
    },
  },
  {
    path: "/hr/employeeAttendance",
    component: EmployeeAttendance,
    exact: true,
  },
  {
    path: "/hr/employeeLeaveBalance",
    component: EmployeeLeaveBalance,
    exact: true,
  },
  {
    path: "/hr/importFiles/",
    component: ImportFiles,
    exact: true
  }
];

export default HRRoutes;
