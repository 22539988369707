import React, { useState, useEffect } from "react";
import SlideModal from "../../../@core/components/common/slide-modal/index";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import {
  useUploadImage,
  useCreateStepOutCategory,
  useUpdateStepOutCategory,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [themeColor, setThemeColor] = useState("#FFFFFF");
  const [highlightedColor, setHighlightedColor] = useState("#FFFFFF");
  const [highlightedTextColor, setHighlightedTextColor] = useState("#FFFFFF");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [url, setUrl] = useState("/category/");
  const [
    uploadImageHandler,
    {
      data: bannerImageData,
      reset: bannerImageReset,
      loading: bannerImageLoad,
    },
  ] = useUploadImage();

  const [createStepOutCategoryHandler, { data: createdStepOutCategoryData }] =
    useCreateStepOutCategory();
  const [updateStepOutCategoryHandler, { data: updatedStepOutCategoryData }] =
    useUpdateStepOutCategory();

  useEffect(() => {
    if (createdStepOutCategoryData || updatedStepOutCategoryData) {
      refetch();
      handleToggle();
      toaster("success", "Category added successfully");
    }
  }, [createdStepOutCategoryData, updatedStepOutCategoryData]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2097152,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setBannerImage([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "icons/stepOutCategory");
    toaster("success", "Banner image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading banner image: ${error.message}`);
  });

  const renderPreview = () =>
    bannerImage ? (
      <img className="rounded mr-1 mb-2 w-50" src={bannerImage} alt="avatar" />
    ) : null;

  useEffect(() => {
    setBannerImage(bannerImageData?.uploadImage?.url);
  }, [bannerImageData]);

  useEffect(() => {
    if (editData?._id) {
      setName(editData?.name !== null ? editData?.name : "");
      setDescription(
        editData?.description !== null ? editData?.description : ""
      );
      setBannerImage(
        editData?.bannerImage !== null ? editData?.bannerImage : null
      );
      setThemeColor(
        editData?.theme?.themeColor !== null
          ? editData?.theme?.themeColor
          : "#FFFFFF"
      );
      setHighlightedColor(
        editData?.theme?.highlightedColor !== null
          ? editData?.theme?.highlightedColor
          : "#FFFFFF"
      );
      setHighlightedTextColor(
        editData?.theme?.highlightedTextColor !== null
          ? editData?.theme?.highlightedTextColor
          : "#FFFFFF"
      );
      setMetaTitle(
        editData?.seo?.metaTitle !== null ? editData?.seo?.metaTitle : ""
      );
      setMetaDesc(
        editData?.seo?.metaDesc !== null ? editData?.seo?.metaDesc : ""
      );
      setKeywords(
        editData?.seo?.keywords !== null ? editData?.seo?.keywords : ""
      );
      setUrl(editData?.url !== null ? editData?.url : "/category/");
    } else {
      setName("");
      setDescription("");
      setBannerImage(null);
      setThemeColor("#FFFFFF");
      setHighlightedColor("#FFFFFF");
      setHighlightedTextColor("#FFFFFF");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setUrl("/category/");
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const data = {
      name,
      description,
      bannerImage,
      theme: {
        themeColor,
        highlightedColor,
        highlightedTextColor,
      },
      seo: {
        metaTitle,
        metaDesc,
        keywords,
      },
      url,
    };

    if (data.url && data.name && data.url.includes("/category/")) {
      if (editData?._id) {
        updateStepOutCategoryHandler(editData?._id, data);
      } else {
        createStepOutCategoryHandler(data);
      }
      setName("");
      setDescription("");
      setBannerImage(null);
      setThemeColor("#FFFFFF");
      setHighlightedColor("#FFFFFF");
      setHighlightedTextColor("#FFFFFF");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setUrl("/category/");
    } else if (data.name && !data.url.includes("/category/")) {
      toaster("error", "Category Url must contain /category/ as prefix");
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="name">
                  Name
                </Label>
              </strong>
              <Input
                type="text"
                value={name}
                id="name"
                name="name"
                placeholder="Add name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="description">
                  Description
                </Label>
              </strong>
              <Input
                type="text"
                value={description}
                id="description"
                name="description"
                placeholder="Add description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <strong>
              <Label className="form-label" for="themeColor">
                Theme
              </Label>
            </strong>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="themeColor">
                  Theme color
                </Label>

                <Row>
                  <Col sm="6">
                    <Input
                      type="color"
                      value={themeColor}
                      id="themeColor"
                      name="themeColor"
                      placeholder="Add themeColor"
                      onChange={(e) => setThemeColor(e.target.value)}
                    />
                  </Col>
                  <Col sm="6">
                    <Input
                      type="text"
                      value={themeColor}
                      id="themeColor"
                      name="themeColor"
                      placeholder="Add theme color"
                      onChange={(e) => setThemeColor(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="highlightedColor">
                  Highlighted color
                </Label>

                <Row>
                  <Col sm="6">
                    <Input
                      type="color"
                      value={highlightedColor}
                      id="highlightedColor"
                      name="highlightedColor"
                      placeholder="Add highlightedColor"
                      onChange={(e) => setHighlightedColor(e.target.value)}
                    />
                  </Col>

                  <Col sm="6">
                    <Input
                      type="text"
                      value={highlightedColor}
                      id="highlightedColor"
                      name="highlightedColor"
                      placeholder="Add highlightedColor"
                      onChange={(e) => setHighlightedColor(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="highlightedTextColor">
                  Highlighted text color
                </Label>

                <Row>
                  <Col sm="6">
                    <Input
                      type="color"
                      value={highlightedTextColor}
                      id="highlightedTextColor"
                      name="highlightedTextColor"
                      placeholder="Add highlightedTextColor"
                      onChange={(e) => setHighlightedTextColor(e.target.value)}
                    />
                  </Col>

                  <Col sm="6">
                    <Input
                      type="text"
                      value={highlightedTextColor}
                      id="highlightedTextColor"
                      name="highlightedTextColor"
                      placeholder="Add highlightedTextColor"
                      onChange={(e) => setHighlightedTextColor(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="url">
                  Url(format:- /category/categoryname)*
                </Label>
              </strong>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add url"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <strong>
              <Label className="form-label" for="metaTitle">
                Seo
              </Label>
            </strong>
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="metaTitle">
                  Meta title
                </Label>

                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add metaTitle"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="metaDesc">
                  Meta desc
                </Label>

                <Input
                  type="text"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add metaDesc"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="keywords">
                  Keywords
                </Label>

                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Col>

          <Col
            sm="12"
            style={{
              marginTop: "20px",
              marginBottom: "50px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "50%" }}>
              <Label className="form-label">
                Banner Image (1250px X 373px)
              </Label>
              <DragDrop
                note="Image size should be less than 2MB"
                width="100%"
                uppy={uppy}
                locale={{
                  strings: {
                    dropHereOr: "Drop image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
            </div>

            <div style={{ width: "50%", marginLeft: "10px" }}>
              {/* <Label className="form-label">Image Preview</Label> */}
              <div
                className="service-icon-preview"
                style={{
                  width: "400px",
                  marginTop: "50px",
                }}
              >
                {bannerImageLoad ? "Uploading icon..." : <>{renderPreview()}</>}
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
