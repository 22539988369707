import { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { Table } from "reactstrap";
import { Card, Col, DropdownItem, Row, UncontrolledDropdown } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import ItemwiseTabel from "./ItemwiseTabel";
import {
  useGetCafeMenuActiveForSoldout,
  useGetCafeMenuByCafeId,
} from "../../../api/Unbox/UnboxCafewise/Queries";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ItemwiseTableForSoldOut from "../SoldoutSection/ItemwiseTableForSoldOut";

const tableHeaderData = ["Cafe", "Actions"];
const CafewiseTable = ({ cafewiseMenuList, handleToggle, setSoldOutLog }) => {
  const [tableData, setTableData] = useState([]);
  const [cafeId, setCafeId] = useState(null);
  const [hostelId, setHostelId] = useState(null);
  const { data, refetch } = useGetCafeMenuByCafeId(cafeId);
  const { data: d, refetch: r } = useGetCafeMenuActiveForSoldout(cafeId);
  const url = useLocation();

  // useEffect(() => {
  //   if (cafewiseMenuList?.length) {
  //     setTableData(cafewiseMenuList);
  //   } else {
  //     setTableData([]);
  //   }
  // }, [cafewiseMenuList]);
  //console.log(cafewiseMenuList);
  useEffect(() => {
    if (cafewiseMenuList?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return cafewiseMenuList?.find(
                (item) => item?.hostel?._id === hostelID
              );
              // for (const menu of cafewiseMenuList) {
              //   if (menu?.hostel?._id === hostelID) {
              //     return menu;
              //   }
              // }
            })
          : cafewiseMenuList;
      setTableData(filteredHostel);
    }
  }, [cafewiseMenuList]);
  return (
    <Row>
      <Col sm={url?.pathname === "/unbox/unboxcafewisemenu" ? "4" : "4"}>
        <Card>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr
                  key={uuidv4()}
                  onClick={() => {
                    setCafeId(item?._id);
                    setHostelId(item?.hostel?._id);
                  }}
                  className={cafeId === item?._id ? "bg-primary" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <span>{item?.cafeName}</span>
                      <span style={{ fontSize: "12px" }}>
                        {item?.hostel?.name}
                      </span>
                    </div>
                  </td>
                  {/* <td></td> */}
                  <td>
                    <UncontrolledDropdown>
                      <DropdownItem>
                        <ChevronRight className="mr-50" size={17} />
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
              {/*  */}
            </tbody>
          </Table>
        </Card>
      </Col>
      {url?.pathname === "/unbox/unboxcafewisemenu" ? (
        <Col sm="8">
          <ItemwiseTabel
            itemListData={data}
            cafeId={cafeId}
            hostelId={hostelId}
            refetch={refetch}
          />
        </Col>
      ) : (
        <Col sm="8">
          <ItemwiseTableForSoldOut
            itemListData={d}
            cafeId={cafeId}
            hostelId={hostelId}
            refetch={r}
            //openModal={() => setOpenModal(!openModal)}
            handleToggle={handleToggle}
            setSoldOutLog={setSoldOutLog}
          />
        </Col>
      )}
    </Row>
  );
};

export default CafewiseTable;
