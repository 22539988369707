import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

//Configuration
const firebaseConfig = {
  apiKey: "AIzaSyAbi_irtpL59aewWMH5zecjE6sc3Jr8TN0",
  authDomain: "th-admin-afba3.firebaseapp.com",
  projectId: "th-admin-afba3",
  storageBucket: "th-admin-afba3.appspot.com",
  messagingSenderId: "637109843378",
  appId: "1:637109843378:web:0e9ea5fa5935ddb7b1a0ab",
};

//Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BKNuiWz_3O4sZrEGUqECZrcsD_T_ISwFJnbedRGdNfav8elMXBi7jbcsPi84J123qJ6tT2XtEfSKLcs4Sx8PwVk",
    });

    if (currentToken) {
      // console.log("current token for client: ", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
