import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateAbout, useUploadImage } from "@api";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import ExperienceCard from "../ExperienceCard";
import { v4 as uuidv4 } from "uuid";
import { X, Camera } from "react-feather";

const Experience = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [heading, setHeading] = useState("");
  const [sectionOne, setSectionOne] = useState({});
  const [sectionTwo, setSectionTwo] = useState({});

  const [updateAbout, { data, reset }] = useUpdateAbout();

  useEffect(() => {
    if (state.tableData?.experience) {
      setHeading(state.tableData.experience.heading);
      setSectionOne(state.tableData.experience.sections?.[0]);
      setSectionTwo(state.tableData.experience.sections?.[1]);
    }
  }, [state.tableData]);

  useEffect(() => {
    if (data?.updateAbout) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          experience: { heading, sections: [sectionOne, sectionTwo] },
        })
      );

      reset();
    }
  }, [data?.updateAbout]);

  const submitDataHandler = (e) => {
    e.preventDefault();

    const experience = {
      heading,
      sections: [sectionOne, sectionTwo],
    };

    if (heading) {
      updateAbout({ experience });
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className='mt-2' onSubmit={submitDataHandler}>
      <Row>
        <Col className='ml-1' sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='heading'>
              Heading
            </Label>

            <Input
              type='text'
              value={heading}
              id='heading'
              name='heading'
              placeholder='Heading'
              onChange={(e) => setHeading(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>

      <ExperienceCard
        title='First'
        section={sectionOne}
        setSection={setSectionOne}
      />

      <ExperienceCard
        title='Second'
        section={sectionTwo}
        setSection={setSectionTwo}
      />

      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Experience;
