import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const CREATE_UNBOX_MASTER_MENU = gql`
  mutation CreateUnboxMaterMenu($masterMenuInput: masterMenuInput) {
    createUnboxMasterMenu(masterMenuInput: $masterMenuInput) {
      itemName
      itemCategory
      description
      image
      type
      weekDayPrice
      weekEndPrice
      options {
        name
        weekEndPrice
        weekDayPrice
      }
      extras {
        name
        weekDayPrice
        weekEndPrice
      }
    }
  }
`;

export const useCreateMasterMenu = () => {
  const [createMasterMenu, { loading, error, data, refetch }] = useMutation(
    CREATE_UNBOX_MASTER_MENU
  );

  useLoadingHandler(loading);

  const createMasterMenuHandler = (masterMenuInput) => {
    createMasterMenu({
      variables: {
        masterMenuInput,
      },
    });
  };

  return [createMasterMenuHandler, { loading, error, data, refetch }];
};

export const UPDATE_UNBOX_MASTER_MENU = gql`
  mutation UpdateMasterMenu($id: ID, $masterMenuInput: masterMenuInput) {
    updateMasterMenu(id: $id, masterMenuInput: $masterMenuInput) {
      itemName
      itemCategory
      weekDayPrice
      weekEndPrice
      image
      options {
        name
        weekEndPrice
        weekDayPrice
      }
    }
  }
`;

export const useUpdateMasterMenu = () => {
  const [updateMasterMenu, { loading, error, data, refetch }] = useMutation(
    UPDATE_UNBOX_MASTER_MENU
  );

  useLoadingHandler(loading);

  const updateMasterMenuHandler = (id, masterMenuInput) => {
    updateMasterMenu({
      variables: {
        id,
        masterMenuInput,
      },
    });
  };

  return [updateMasterMenuHandler, { loading, error, data, refetch }];
};
