import { useEffect, useState } from "react";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Badge,
  Button,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "@utils";
import { CSVLink } from "react-csv";

const tableHeaderData = [
  "CHECKIN - CHECKOUT DATE",
  "HOSTEL NAME",
  "GUEST NAME",
  "MOBILE NUMBER",
  "Reservation ID",
  "Checkout Time",
  "Checkout Feedback",
  "Checkout Remark",
  "EYL",
];

const CheckoutTable = ({ data, headers, exportData, dataCount }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  function truncate(customerRemark, n) {
    return customerRemark.length > n
      ? customerRemark.slice(0, n - 1) + "..."
      : customerRemark;
  }

  const tableHeadStyle = {
    minWidth: "140px",
  };

  const tableDataStyle = {
    minWidth: "140px",
    justifyContent: "center",
    alignItems: "center",
  };
  const role = localStorage.getItem("role");

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            style={{
              padding: "0px",
              alignItems: "center",
            }}
            className="px-2 mb-2"
          >
            <CardTitle className="mt-2">
              <div>
                <h2>Check Out Feedback</h2>
              </div>
            </CardTitle>
            {/* Have added the condition because we have to disable the export button for Ground Operation */}
            {role !== "Ground Staff" && (
              <CSVLink
                headers={headers}
                data={exportData}
                filename={`checkout_list.csv`}
              >
                <Button.Ripple
                  style={{
                    height: "40px",
                    marginTop: "22px",
                    marginLeft: "40px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="success"
                  appearance="success"
                  type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            )}
          </CardHeader>

          <Table responsive size="sm">
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return (
                    <th key={uuidv4()} style={tableHeadStyle}>
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={tableDataStyle}>
                    {item.guestDetails?.ArrivalDate
                      ? dateFormatHandler(item?.guestDetails?.ArrivalDate)
                      : ""}
                    -
                    {item.guestDetails?.DepartureDate
                      ? dateFormatHandler(item?.guestDetails?.DepartureDate)
                      : ""}
                  </td>
                  <td style={tableDataStyle}>
                    <h6>{item?.guestDetails?.hostelName}</h6>
                  </td>
                  <td style={tableDataStyle}>
                    <span className="font-weight-bold">
                      {item?.guestDetails?.GuestName}
                    </span>
                  </td>
                  <td style={tableDataStyle}>
                    <h6>{item?.guestDetails?.Mobile}</h6>
                  </td>
                  <td style={tableDataStyle}>
                    <h6>{item?.reservationID}</h6>
                  </td>

                  <td style={tableDataStyle}>
                    <Badge color="light-info">{item?.checkoutTime}</Badge>
                  </td>
                  <td style={tableDataStyle}>
                    <Badge
                      color={
                        item?.checkOutFeedback === "Excellent"
                          ? "success"
                          : item?.checkOutFeedback === "Good"
                          ? "primary"
                          : "danger"
                      }
                      className="mr-1"
                    >
                      {item.checkOutFeedback ? item.checkOutFeedback : ""}
                    </Badge>
                  </td>
                  {/* <td style={tableDataStyle}>
                    <h6>
                      {item?.checkOutRemark
                        ? truncate(item.checkOutRemark, 20)
                        : ""}
                    </h6>
                  </td> */}
                  <td style={tableDataStyle}>
                    <h6>{item?.checkOutRemark}</h6>
                  </td>
                  {/* <td style={tableDataStyle}>
                    <h6>{item?.eylStatus}</h6>
                  </td> */}

                  <td style={tableDataStyle}>
                    <h6>
                      {item?.guestDetails?.eylStatus ? (
                        <Badge pill color="light-success" className="mr-1">
                          Yes
                        </Badge>
                      ) : (
                        <Badge pill color="light-danger" className="mr-1">
                          No
                        </Badge>
                      )}
                    </h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default CheckoutTable;
