import React, { useEffect, useState } from "react";
import { Edit } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Status",
  "Payment Status",
  "Product Name",
  "Category Name",
  "Start Date",
  "End Date",
  "Total Price Without Tax",
  "Guest Full Name",
  "Res. Payment Id",
  "Promo Code",
];

const StepOutBookingsTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState(data);
  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const getDateInLocaleString = (param) => {
    try {
      if (param) {
        const date = new Date(param);
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString("en-US", options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Bookings</h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Booking
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((element, i) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "5%" }}>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(element._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>
                    <Badge
                      pill
                      color={element?.status ? "light-success" : "light-danger"}
                      className="mr-1"
                    >
                      {element?.status ? "Active" : "Inactive"}
                    </Badge>
                  </td>
                  <td>{element?.paymentStatus}</td>
                  <td>{element?.productName}</td>
                  <td>{element?.category}</td>
                  <td>{getDateInLocaleString(element?.startDate)}</td>
                  <td>{getDateInLocaleString(element?.endDate)}</td>
                  <td>{element?.totalPriceWithOutTax}</td>
                  <td>{element?.guestDetails?.fullName}</td>
                  <td>{element?.razorpayPaymentId}</td>
                  <td>{element?.promoCode}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default StepOutBookingsTable;
