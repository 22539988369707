import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import ImageSection from "@components/common/ImageSection";
import { useUpdateTrip } from "@api";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { useParams } from "react-router-dom";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const uploadType = [
  { value: "desktop", label: "Desktop" },
  { value: "mobile", label: "Mobile" },
];

const PhotosForm = () => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [updateTripHandler, { data, reset }] = useUpdateTrip();
  const dispatch = useDispatch();

  useEffect(() => {
    state?.tableData?.images?.length && setImageList(state?.tableData?.images);
  }, [state?.tableData?.images]);

  useEffect(() => {
    if (data?.updateTrip?.name) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          images: imageList,
        })
      );

      reset();
    }
  }, [data?.updateTrip?.name]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];
    imageList.map((image) => {
      (image.image || image.url) &&
        images.push({
          url: image.image || image.url,
          key: image.key,
          type: image.type,
        });
    });

    updateTripHandler(id, { images });
  };

  return (
    <div className='mt-2'>
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={imageList}
            setImageList={setImageList}
            uploadType={uploadType}
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default PhotosForm;
