import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const GET_PAGINATED_UNBOX_ORDER = gql`
  query GetPaginatedUnboxOrder($filter: filter) {
    getPaginatedUnboxOrder(filter: $filter) {
      data {
        _id
        guestName
        mobileNo
        reservationId
        status
        orderId
        orderDate
        paymentStatus
        guestRemark
        orderAmount
        razorpayPaymentId
        couponCode
        discountAmount
        paymentMode
        cafe {
          _id
          cafeName
        }
        items {
          _id
          itemName
          price
          quantity
          options {
            name
          }
          extras {
            name
          }
        }
        finalAmount
        cafeRemark
        cancellationRemark
        cancellationDate
        refundId
        refundStatus
        cancelledBy
        cancellationAmount
        rating
        printCount
        createdAt
         editLogs
      }
      count
      limit
      page
      totalSales
      totalOrder
      netSales
      totalDiscount
    }
  }
`;

export const useGetPaginatedUnboxOrder = (limit, page, filterFeilds) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_UNBOX_ORDER,
    {
      variables: {
        filter: { limit, page, filterFeilds },
      },
    }
  );
  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ORDER_BY_CAFE_ID = gql`
  query GetOrderByCafeId($cafeId: ID) {
    getOrderByCafeId(cafeId: $cafeId) {
      _id
      guestName
      orderId
      status
      items {
        _id
        itemName
        quantity
        options {
          name
        }
        extras {
          name
        }
      }
    }
  }
`;

export const useGetOrderByCafeId = (cafeId) => {
  const { loading, error, data, refetch } = useQuery(GET_ORDER_BY_CAFE_ID, {
    variables: { cafeId },
    skip: !cafeId,
  });

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ORDER_PREPARING = gql`
  query GetOrderPreparing($cafeId: ID) {
    getOrderPreparing(cafeId: $cafeId) {
      _id
      guestName
      orderId
      status
      items {
        _id
        itemName
        quantity
        options {
          name
        }
        extras {
          name
        }
      }
    }
  }
`;

export const useGetOrderPreparing = (cafeId) => {
  const { loading, error, data, refetch } = useQuery(GET_ORDER_PREPARING, {
    variables: { cafeId },
    skip: !cafeId ? true : false,
  });

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ORDER_READY = gql`
  query GetOrderReady($cafeId: ID) {
    getOrderReady(cafeId: $cafeId) {
      _id
      guestName
      orderId
      status
      items {
        _id
        itemName
        quantity
        options {
          name
        }
        extras {
          name
        }
      }
    }
  }
`;

export const useGetOrderReady = (cafeId) => {
  const { loading, error, data, refetch } = useQuery(GET_ORDER_READY, {
    variables: { cafeId },
    skip: !cafeId ? true : false,
  });

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ORDER_CANCEL = gql`
  query GetOrderCancelled($cafeId: ID) {
    getOrderCancelled(cafeId: $cafeId) {
      _id
      guestName
      orderId
      status
      items {
        _id
        itemName
        quantity
        options {
          name
        }
        extras {
          name
        }
      }
    }
  }
`;

export const useGetOrderCancelled = (cafeId) => {
  const { loading, error, data, refetch } = useQuery(GET_ORDER_CANCEL, {
    variables: { cafeId },
    skip: !cafeId ? true : false,
  });

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ORDER_NEW = gql`
  query getOrderNew($dashboardFilter: dashboardFilter) {
    getOrderNew(dashboardFilter: $dashboardFilter) {
      getOrders
      getOrderPreparing
      getOrderReady
    }
  }
`;

export const useGetOrderNew = (cafeId, filterFeilds) => {
  const { loading, error, data, refetch } = useQuery(GET_ORDER_NEW, {
    variables: { dashboardFilter: { cafeId, filterFeilds } },
    skip: !cafeId ? true : false,
  });

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ALL_UNBOX_ORDER_FOR_EXPORT = gql`
  query getAllUnboxOrderForExport($filter: filter) {
    getAllUnboxOrderForExport(filter: $filter)
  }
`;

export const useGetAllUnboxOrderForExport = (filterFeilds) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_UNBOX_ORDER_FOR_EXPORT,
    {
      variables: {
        filter: { filterFeilds },
      },
      skip: !filterFeilds,
    }
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};
