import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import { X, Link } from "react-feather";
import { useSelector } from "react-redux";
import { useUploadImage } from "@api";
import UrlForm from "./UrlForm";
import confirmDelete from "@components/common/confirmDelete";
import "./index.scss";
import toaster from "@components/common/Toaster";

const InclusionsIconsImageSection = ({
  imageList,
  setImageList,
  uploadType,
  landingPage,
}) => {
  const state = useSelector((state) => state?.cms);
  const [imageLink, setImageLink] = useState({});

  const [uploadingType, setUploadingType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadImageHandler, { data: imageData, reset }] = useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 1048576,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("complete", (result) => {
    setUploadingType(result.successful[0]?.source, state.tableData);

    result.successful.forEach((file) => {
      const fileName = file.name;

      const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

      if (specialCharsRegex.test(fileName)) {
        console.error("File name:", fileName);
        toaster(
          "error",
          "File name contains special characters. Please provide a valid file name."
        );
        uppy.removeFile(file.id);
        return;
      }

      result.successful[0]?.data &&
        uploadImageHandler(result.successful[0]?.data, `Inclusions_Icons`);

      toaster("success", "Inclusion icons image uploaded successfully");
    });
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading inclusion icons image: ${error.message}`);
  });

  useEffect(() => {
    const newImage = {
      imageTitle: "",
      displayOrder: "",
    };

    uploadType
      ? (newImage.imagePath = imageData?.uploadImage?.url)
      : (newImage.url = imageData?.uploadImage?.url);

    if (imageData?.uploadImage?.url) {
      setImageList((prev) => [newImage, ...prev]);

      reset();
      setUploadingType(null);
    }
  }, [imageData]);

  const removeImages = (image) => {
    const newSet = imageList.filter((item) => {
      const imgUrl = item.imagePath ? item.imagePath : item.url;
      return imgUrl !== image;
    });

    setImageList(newSet);
  };

  const handleRemoveImage = (image) => {
    confirmDelete(() => removeImages(image));
  };

  const openModal = (index, imageTitle, displayOrder) => {
    setShowModal(true);
    setImageLink({ index, imageTitle, displayOrder });
  };
  const closeModal = () => {
    setShowModal(false);
    setImageLink({});
  };

  const addImageLink = (index, imageTitle, displayOrder) => {
    let newImageList = [...imageList];
    const imgWithLink = { ...newImageList[index], imageTitle, displayOrder };

    newImageList[index] = imgWithLink;
    setImageList(newImageList);
  };

  return (
    <div className="imageSection__container">
      <UrlForm
        showModal={showModal}
        closeModal={closeModal}
        setShowModal={setShowModal}
        imageLink={imageLink}
        addImageLink={addImageLink}
      />

      {uploadType.map(({ value, label }) => (
        <div key={value}>
          <h3 className="imageSection__header mt-2">Upload {label} images</h3>
          <p>
            Recommended dimension -{" "}
            {label === "Mobile" ? "575 x 185 or 375 x 175" : "1200 x 800"}
          </p>
          <div className="imageSection__imagesContainer">
            {imageList.map((item, index) => {
              if (
                item.type === value ||
                (!item.type && value === "mobile") ||
                (!item.type && value === "desktop")
              ) {
                // removed the line from above ===> || item.url
                const image = item.imagePath || item.url || item.displayOrder;

                return (
                  <div key={index} className="imagesection__imageBox">
                    <img
                      className="imageSection__image"
                      src={image}
                      alt="avatar"
                    />
                    <div className="imageSection_footer">
                      <div className="imageSection_icon imageSection__index">
                        {item.displayOrder}
                      </div>
                      {landingPage && (
                        <div
                          onClick={() =>
                            openModal(index, item.imageTitle, item.displayOrder)
                          }
                          className="imageSection_icon imageSection__link"
                        >
                          <Link size={25} />
                        </div>
                      )}
                      <div
                        onClick={() => handleRemoveImage(image)}
                        className=" imageSection_icon imageSection__removeImage"
                      >
                        <X size={25} />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div className="imageSection__addImage ml-2">
              <DragDrop
                id={value}
                width="12em"
                height="12em"
                locale={{
                  strings: {
                    dropHereOr: "Max 1 MB",
                  },
                }}
                uppy={uppy}
              />
            </div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default InclusionsIconsImageSection;
