import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateStepOutProduct } from "../../../api/dataSource";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const Pickup = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [updateStepOutProductHandler, { data }] = useUpdateStepOutProduct();
  const [pickupPoints, setPickupPoints] = useState([
    {
      id: uuidv4(),
      location: "",
      address: "",
      price: 0,
    },
  ]);

  const pickupPointsData = state?.tableData?.pickupPoints;

  useEffect(() => {
    if (pickupPointsData && pickupPointsData.length > 0) {
      let upadtedHighlight = pickupPointsData.map((item) => {
        return {
          id: uuidv4(),
          location: item?.location,
          address: item?.address,
          price: item?.price,
        };
      });

      setPickupPoints(upadtedHighlight);
    }
  }, [pickupPointsData]);

  useEffect(() => {
    if (data?.updateStepOutProduct) {
      toaster("success", "Starting points updated successfully");
    }
  }, [data?.updateStepOutProduct, pickupPointsData]);

  const handleTextChangeLocation = (val, id, key) =>
    setPickupPoints(
      pickupPoints.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );

  const handleTextChangeAddress = (val, id, key) =>
    setPickupPoints(
      pickupPoints.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );

  const handleTextChangeDisplayOrder = (val, id, key) =>
    setPickupPoints(
      pickupPoints.map((item) =>
        item.id === id ? { ...item, [key]: parseInt(val) } : item
      )
    );

  const handleAddStepOutProduct = () =>
    setPickupPoints([
      ...pickupPoints,
      { id: uuidv4(), location: "", address: "", price: "" },
    ]);

  const handleRemoveStepOutProduct = (id) => {
    // eslint-disable-next-line array-callback-return
    let newStepOutProductData = pickupPoints.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setPickupPoints(newStepOutProductData));
  };

  const handleSubmitHandler = () => {
    let isValid = true;
    let StepOutProductClone = JSON.parse(JSON.stringify(pickupPoints));
    StepOutProductClone.forEach((item) => {
      delete item.id;
    });

    let pickupPointsData = { pickupPoints: StepOutProductClone };

    StepOutProductClone?.forEach((item) => {
      isValid &&
        (item?.location && item?.address
          ? (isValid = true)
          : (isValid = false));
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...pickupPointsData,
        })
      );
      updateStepOutProductHandler(id, pickupPointsData);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitHandler();
      }}
    >
      {pickupPoints.map(({ id, location, address, price }, index) => (
        <div key={id}>
          <Row className="mt-2">
            <Col sm="12" md="11">
              <FormGroup>
                <FormGroup className="w-100">
                  <Label className="form-label" for="location">
                    Location
                  </Label>

                  <Input
                    type="text"
                    value={location}
                    id="location"
                    name="location"
                    placeholder={`Location`}
                    onChange={(e) =>
                      handleTextChangeLocation(e.target.value, id, "location")
                    }
                  />
                </FormGroup>

                <FormGroup className="w-100">
                  <Label className="form-label" for="address">
                    Address
                  </Label>

                  <Input
                    rows={4}
                    type="textarea"
                    value={address}
                    id="address"
                    name="address"
                    placeholder="Address"
                    onChange={(e) =>
                      handleTextChangeAddress(e.target.value, id, "address")
                    }
                  />
                </FormGroup>

                <FormGroup className="w-100">
                  <Label className="form-label" for="price">
                    Price
                  </Label>

                  <Input
                    rows={4}
                    type="number"
                    value={price}
                    id="price"
                    name="price"
                    placeholder="Price"
                    onChange={(e) =>
                      handleTextChangeDisplayOrder(e.target.value, id, "price")
                    }
                  />
                </FormGroup>
              </FormGroup>
            </Col>

            <Col sm="12" md="1">
              {pickupPoints.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle mb-1 mr-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveStepOutProduct(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          </Row>
          <hr key={index} style={{ border: "1px solid #ffe700" }} />
        </div>
      ))}

      <SaveDiscardBtnGroup addAction={handleAddStepOutProduct} />
    </form>
  );
};

export default Pickup;
