import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateStepOutProduct } from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";

const Policies = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [policies, setPolicies] = useState(() => EditorState.createEmpty());

  const [updateStepOutProductHandler, { data: updateStepOutProductData }] =
    useUpdateStepOutProduct();

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData) {
      setPolicies(
        htmlToDraftConvert(
          state?.tableData?.policies !== null ? state?.tableData?.policies : ""
        )
      );
    }
  }, [state]);

  const submitDataHandler = (e) => {
    e.preventDefault();

    let data = {
      policies: inputDataConvertor(policies),
    };
    if (id) {
      updateStepOutProductHandler(id, data);
      toaster("success", "Policies updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <div className="mt-1 ">
              <MyEditor
                id="policies"
                editorState={policies}
                setEditorState={(val) => setPolicies(val)}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Policies;
