import React, { useState, useEffect } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import SwitchIcon from "@components/common/switch-icon";
import Select from "react-select";
import { useSelector } from "react-redux";

const levelOptions = [
  { value: "Easy", label: "Easy" },
  { value: "Easy to Medium", label: "Easy to Medium" },
  { value: "Medium", label: "Medium" },
  { value: "Medium to Hard", label: "Medium to Hard" },
  { value: "Hard", label: "Hard" },
];

const TripDetailsForm = () => {
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [region, setRegion] = useState("");
  const [bestVisit, setBestVisit] = useState("");
  const [durationDays, setDurationDays] = useState("");
  const [durationNights, setDurationNights] = useState("");
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState(false);
  const [groupSize, setGroupSize] = useState("");
  const [altitude, setAltitude] = useState("");
  const [level, setLevel] = useState("");
  const [rating, setRating] = useState("");

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData) {
      const {
        altitude,
        amount,
        displayName,
        durationDays,
        durationNights,
        level,
        location,
        name,
        paxGroup,
        rating,
        status,
      } = state?.tableData;

      setName(name);
      setRegion(location?.region);
      setBestVisit(location?.timeToVisit);
      setDisplayName(displayName);
      setAltitude(altitude);
      setDurationDays(durationDays);
      setDurationNights(durationNights);
      setLevel({ value: level, label: level });
      setRating(rating);
      setGroupSize(paxGroup);
      setPrice(amount);
      setStatus(status);
    }
  }, [state?.tableData]);

  return (
    <>
      <form>
        <Row>
          <Col sm='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='name'>
                Trip Name*
              </Label>
              <Input
                type='text'
                disabled
                value={name}
                id='name'
                name='name'
                placeholder='Add Trip Name'
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='region'>
                Region*
              </Label>
              <Input
                type='text'
                disabled
                value={region}
                id='region'
                name='region'
                placeholder='region'
                onChange={(e) => setRegion(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='region'>
                Best time to visit
              </Label>
              <Input
                type='text'
                disabled
                value={bestVisit}
                id='region'
                name='region'
                placeholder='Best time to visit'
                onChange={(e) => setBestVisit(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='displayName'>
                Display Name
              </Label>
              <Input
                type='text'
                disabled
                value={displayName}
                id='displayName'
                name='displayName'
                placeholder='Add Display Name'
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='durationDays'>
                Duration - Days
              </Label>
              <Input
                type='number'
                disabled
                value={durationDays}
                id='durationDays'
                name='durationDays'
                placeholder='Add Duration Days'
                onChange={(e) => setDurationDays(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='durationNights'>
                Duration Nights
              </Label>
              <Input
                type='number'
                disabled
                value={durationNights}
                id='durationNights'
                name='durationNights'
                placeholder='Add Duration Nights'
                onChange={(e) => setDurationNights(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='groupSize'>
                Max Group Size
              </Label>
              <Input
                type='number'
                disabled
                value={groupSize}
                id='groupSize'
                name='groupSize'
                placeholder='Add Group Size'
                onChange={(e) => setGroupSize(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='rating'>
                Rating
              </Label>
              <Input
                type='text'
                disabled
                value={rating}
                id='rating'
                name='rating'
                placeholder='Rating'
                onChange={(e) => setRating(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup>
              <Label for='altitude'>Altitude</Label>
              <Input
                type='number'
                disabled
                value={altitude}
                id='Altitude'
                name='Altitude'
                placeholder='Altitude'
                onChange={(e) => setAltitude(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup>
              <Label for='level'>Difficulty Level</Label>
              <Select
                isClearable={false}
                id='level'
                isDisabled
                name='level'
                value={level}
                options={levelOptions}
                onChange={(value) => setLevel(value)}
                classNamePrefix='select'
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup className='w-100'>
              <Label className='form-label' for='price'>
                Price
              </Label>
              <Input
                type='number'
                disabled
                value={price}
                id='price'
                name='price'
                placeholder='Add price'
                onChange={(e) => setPrice(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm='6'>
            <SwitchIcon
              checked={status}
              disabled
              id='status'
              name='status'
              label='Publish on Website ?'
              handleChecked={(val) => setStatus(val)}
            />
          </Col>
        </Row>
        {/* <SaveDiscardBtnGroup /> */}
      </form>
    </>
  );
};

export default TripDetailsForm;
