import React, { useEffect, useState } from "react";
import VendorTable from "./VendorTable";
import TablePagination from "@components/common/TablePagination";
import { useHostelList } from "../../../api/dataSource";
import { useGetPaginatedOpsHostelVendorList } from "../../../api/OpsHostelVendor/Queries";
import AddEditModal from "./AddEditModal";
import Select from "react-select";
import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import { selectThemeColors } from "@utils";
import { indianStateList } from "../../../utility/Utils";
import makeAnimated from "react-select/animated";

const Vendor = () => {
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [services, setServices] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [vendor, setVendor] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [vendorCategory, setVendorCategory] = useState(null);
  const [vendorState, setVendorState] = useState([]);
  const [vendorStatus, setVendorStatus] = useState(null);

  const [vendorHead, setVendorHead] = useState(null);
  const [vendorSubHead, setVendorSubHead] = useState(null);
  const [vendorType, setVendorType] = useState(null);

  const [filterFields, setFilterFields] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });

  const animatedComponents = makeAnimated();

  const vendorCategoryList = [
    { label: "OPEX", value: "OPEX" },
    { label: "CAPEX", value: "CAPEX" },
    { label: "CAPEX + OPEX", value: "CAPEX + OPEX" },
  ];

  const vendorHeadList = [
    { label: "Hostel", value: "Hostel" },
    { label: "F&B", value: "F&B" },
    { label: "Ancillary", value: "Ancillary" },
    { label: "CAPEX", value: "CAPEX" },
  ];

  let vendorSubHeadList;

  if (vendorHead?.value === "Hostel") {
    vendorSubHeadList = [
      { label: "Utilities", value: "Utilities" },
      { label: "R&M", value: "R&M" },
      { label: "HK", value: "HK" },
      { label: "General", value: "General" },
    ];
  } else if (vendorHead?.value === "F&B") {
    vendorSubHeadList = [
      { label: "Material", value: "Material" },
      // { label: "Staff Welfare", value: "Staff Welfare" },
      // { label: "Staff travel", value: "Staff travel" },
      // { label: "Salary", value: "Salary" },
      // { label: "Advance Salary", value: "Advance Salary" },
    ];
  } else if (vendorHead?.value === "Ancillary") {
    vendorSubHeadList = [
      { label: "Material", value: "Material" },
      // { label: "Payout", value: "Payout" },
      { label: "Vendor Payout", value: "Vendor Payout" },
    ];
  } else if (vendorHead?.value === "CAPEX") {
    vendorSubHeadList = [
      { label: "Hostel", value: "Hostel" },
      { label: "F&B", value: "F&B" },
      { label: "Ancillary", value: "Ancillary" },
    ];
  }

  const HostelUtilitiesType = [
    // { label: "Electricity/DG/Fuel", value: "Electricity/DG/Fuel" },
    { label: "Electricity", value: "Electricity" },
    { label: "Fuel", value: "Fuel" },
    // { label: "Water", value: "Water" },
    { label: "Water/Tanks", value: "Water/Tanks" },
    { label: "Internet", value: "Internet" },
    // { label: "TV", value: "TV" },
    { label: "TV/OTT", value: "TV/OTT" },
    { label: "Software", value: "Software" },
    { label: "Others", value: "Others" },
  ];

  const R_M_SubFieldsType = [
    { label: "Civil", value: "Civil" },
    { label: "Furniture", value: "Furniture" },
    { label: "Furnishing", value: "Furnishing" },
    { label: "Fabrication", value: "Fabrication" },
    { label: "Electrical", value: "Electrical" },
    { label: "Electronics/Equipments", value: "Electronics/Equipments" },
    { label: "Plumbing", value: "Plumbing" },
    // { label: "Others", value: "Others" },
    { label: "Miscellaneous", value: "Miscellaneous" },
  ];

  const HostelHKType = [
    { label: "Toiletries", value: "Toiletries" },
    { label: "Laundry", value: "Laundry" },
    { label: "Garbage", value: "Garbage" },
    { label: "Room Amenities", value: "Room Amenities" },
    { label: "Pest Control", value: "Pest Control" },
    { label: "Others", value: "Others" },
  ];

  const HostelGeneralType = [
    { label: "Stationery & Printing", value: "Stationery & Printing" },
    { label: "Legal & Liaisoning", value: "Legal & Liaisoning" },
    // { label: "Local Transportation", value: "Local Transportation" },
    { label: "Courier", value: "Courier" },
    { label: "Staff Welfare", value: "Staff Welfare" },
    { label: "Staff Travel", value: "Staff Travel" },
    { label: "Others", value: "Others" },
  ];

  const F_AND_B_MATERIAL_TYPE = [
    { label: "Dairy", value: "Dairy" },
    { label: "Non-Dairy", value: "Non-Dairy" },
    { label: "Vegetables/Fruits", value: "Vegetables/Fruits" },
    // { label: "Supplies", value: "Supplies" },
    { label: "Supplies (Gas, etc)", value: "Supplies (Gas, etc)" },
    // { label: "Others", value: "Others" },
    { label: "Others (R&M, etc)", value: "Others (R&M, etc)" },
  ];

  const AncillaryPayoutVendorType = [
    { label: "Guest Laundry", value: "Guest Laundry" },
    { label: "Transportation", value: "Transportation" },
    { label: "Activity/Adventure", value: "Activity/Adventure" },
    { label: "Accommodation", value: "Accommodation" },
    { label: "Others", value: "Others" },
  ];

  const AncillaryMaterialVendorType = [
    { label: "Lock", value: "Lock" },
    { label: "Warmee", value: "Warmee" },
    { label: "Toiletry Kit", value: "Toiletry Kit" },
    { label: "Others", value: "Others" },
  ];

  const CapexSubFieldsType = [
    { label: "Civil", value: "Civil" },
    { label: "Furniture", value: "Furniture" },
    { label: "Furnishing", value: "Furnishing" },
    { label: "Fabrication", value: "Fabrication" },
    { label: "Electrical", value: "Electrical" },
    { label: "Electronics/Equipments", value: "Electronics/Equipments" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Others", value: "Others" },
  ];

  const VendorStatusList = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  const { data, loading, refetch } = useGetPaginatedOpsHostelVendorList(
    limit,
    page,
    filterFields
  );
  const { data: allHostelsData } = useHostelList();

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    refetch();
  }, [refetch, page]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
            return allHostelsData?.getHostelList?.find(
              (item) => item?._id === hostelID
            );
          })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    if (data?.getPaginatedHostelVendor?.data.length > 0) {
      setTableData(data?.getPaginatedHostelVendor?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedHostelVendor?.count / limit));
  }, [
    data?.getPaginatedHostelVendor?.count,
    data?.getPaginatedHostelVendor?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedHostelVendor?.data.length > 0) {
      let findData = data?.getPaginatedHostelVendor.data.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedHostelVendor?.data, id]);

  const options =
    ((vendorHead?.value === "Hostel" && vendorSubHead?.value === "Utilities") ||
      (editData?.vendorHead === "Hostel" &&
        editData?.vendorSubHead === "Utilities")
      ? HostelUtilitiesType
      : null) ||
    ((vendorHead?.value === "Hostel" && vendorSubHead?.value === "R&M") ||
      (editData?.vendorHead === "Hostel" && editData?.vendorSubHead === "R&M")
      ? R_M_SubFieldsType
      : null) ||
    ((vendorHead?.value === "Hostel" && vendorSubHead?.value === "HK") ||
      (editData?.vendorHead === "Hostel" && editData?.vendorSubHead === "HK")
      ? HostelHKType
      : null) ||
    ((vendorHead?.value === "Hostel" && vendorSubHead?.value === "General") ||
      (editData?.vendorHead === "Hostel" && editData?.vendorSubHead === "General")
      ? HostelGeneralType
      : null) ||
    ((vendorHead?.value === "F&B" && vendorSubHead?.value === "Material") ||
      (editData?.vendorHead === "F&B" && editData?.vendorSubHead === "Material")
      ? F_AND_B_MATERIAL_TYPE
      : null) ||
    ((vendorHead?.value === "Ancillary" &&
      vendorSubHead?.value === "Vendor Payout") ||
      (editData?.vendorHead === "Ancillary" &&
        editData?.vendorSubHead === "Vendor Payout")
      ? AncillaryPayoutVendorType
      : null) ||
    ((vendorHead?.value === "Ancillary" &&
      vendorSubHead?.value === "Material") ||
      (editData?.vendorHead === "Ancillary" &&
        editData?.vendorSubHead === "Material")
      ? AncillaryMaterialVendorType
      : null) ||
    ((vendorHead?.value === "CAPEX" &&
      (vendorSubHead?.value === "Hostel" ||
        vendorSubHead?.value === "F&B" ||
        vendorSubHead?.value === "Ancillary")) ||
      (editData?.vendorHead === "CAPEX" &&
        (editData?.vendorSubHead === "Hostel" ||
          editData?.vendorSubHead === "F&B" ||
          editData?.vendorSubHead === "Ancillary"))
      ? CapexSubFieldsType
      : []);

  const handleVendorState = (vendorState) => {
    return setVendorState(vendorState);
  };

  const handleSearch = () => {
    const filterFields = {
      hostelIdArray:
        hostel.length > 0
          ? hostel?.map((hostel) => hostel.value)
          : JSON.parse(localStorage.getItem("hostels")),
      services,
      vendor,
      contact,
      email,
      gstNo,
      accountNo,
      ifscCode,
      vendorCategory: vendorCategory?.value,
      vendorHead: vendorHead?.value,
      vendorSubHead: vendorSubHead?.value,
      vendorType: vendorType?.value,
      vendorStatus: vendorStatus?.value,
      vendorState: vendorState?.map((item) => item.value),
    };
    setPage(1);
    setFilterFields(filterFields);
  };

  const handleReset = () => {
    setHostel([]);
    setVendor("");
    setContact("");
    setGstNo("");
    setEmail("");
    setVendorCategory(null);
    setVendorHead(null);
    setVendorSubHead(null);
    setVendorType(null);
    setVendorStatus(null);
    setVendorState(null)
    setPage(1);
    setFilterFields({
      hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    });
  };

  return (
    localStorage.getItem("modules")?.includes("Operations") && (
      <div>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                closeMenuOnSelect={false}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                State

              </Label>
              <Select
                onChange={handleVendorState}
                isClearable={true}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                id="vendorState"
                name="vendorState"
                value={vendorState}
                options={indianStateList}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Category
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                options={vendorCategoryList}
                value={vendorCategory}
                className="basic-multi-select"
                classNamePrefix="select"
                name="vendorCategory"
                onChange={(value) => {
                  setVendorCategory(value);
                }}
              />
            </FormGroup>
          </Col>


          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorHead">
                Vendor head
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                options={vendorHeadList || []}
                value={vendorHead}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(module) => {
                  setVendorHead(module);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorSubHead">
                Vendor subhead
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                id="vendorSubHead"
                name="vendorSubHead"
                value={vendorSubHead}
                options={vendorSubHeadList || []}
                required
                onChange={(value) => setVendorSubHead(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorType">
                Vendor type
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                id="vendorType"
                name="vendorType"
                value={vendorType}
                options={options}
                required
                onChange={(value) => setVendorType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendor">
                Company name
              </Label>
              <Input
                type="text"
                value={vendor}
                id="vendor"
                name="vendor"
                placeholder="Find vendor company name"
                onChange={(e) => setVendor(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="contact">
                <strong>Contact</strong>
              </Label>
              <Input
                type="text"
                value={contact}
                id="contact"
                name="contact"
                placeholder="Find contact"
                onChange={(e) => setContact(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="email">
                <strong>Email</strong>
              </Label>
              <Input
                type="text"
                value={email}
                id="email"
                name="email"
                placeholder="Find email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col> */}

          {/* <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Services</strong>
              </Label>
              <Input
                type="text"
                value={services}
                id="services"
                name="services"
                placeholder="Find Services"
                onChange={(e) => setServices(e.target.value)}
              />
            </FormGroup>
          </Col> */}

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="gstNo">
                GST number
              </Label>
              <Input
                type="text"
                value={gstNo}
                id="gstNo"
                name="gstNo"
                placeholder="Find GST number"
                onChange={(e) => setGstNo(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* 
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="accountNo">
                <strong>Account Number</strong>
              </Label>
              <Input
                type="text"
                value={accountNo}
                id="accountNo"
                name="accountNo"
                placeholder="Find Account Number"
                onChange={(e) => setAccountNo(e.target.value)}
              />
            </FormGroup>
          </Col> */}

          {/* <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="ifscCode">
                <strong>IFSC CODE</strong>
              </Label>
              <Input
                type="text"
                value={ifscCode}
                id="ifscCode"
                name="ifscCode"
                placeholder="Find IFSC Code"
                onChange={(e) => setIfscCode(e.target.value)}
              />
            </FormGroup>
          </Col> */}






          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorStatus">
                Status
              </Label>
              <Select
                isClearable={true}
                id="vendorStatus"
                name="vendorStatus"
                value={vendorStatus}
                options={VendorStatusList}
                required
                onChange={(value) => setVendorStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <VendorTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          refetch={refetch}
          loading={loading}
        />
        <TablePagination
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          loading={loading}
        />
        <AddEditModal
          open={openModal}
          handleToggle={toggleModal}
          refetch={refetch}
          editData={editData}
          setId={setId}
        />
      </div>
    )
  );
};

export default Vendor;
