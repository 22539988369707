// !Added by Ashwin

import React from "react";
import { Edit, Trash } from "react-feather";
import { Button } from "reactstrap";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";

function completionTimeDifference(updatedAt, createdAt) {
  const updatedAtDate = new Date(updatedAt);
  const createdAtDate = new Date(createdAt);
  const timeDifference = updatedAtDate - createdAtDate;
  const timeDifferenceInMinutes = Math.ceil(timeDifference / (1000 * 60));
  return timeDifferenceInMinutes;
}

const WebAppUserRequestTableBody = ({
  tableRowData,
  openModal,
  handleEdit,
  handleDelete,
}) => {
  return (
    <>
      {tableRowData?.map(
        ({
          requestNumber,
          requestTime,
          reservationId,
          requestCategory,
          requestName,
          hostel,
          guestName,
          requestStatus,
          room,
          roomNo,
          tat,
          staffRemark,
          isCritical,
          id,
          rating,
          createdAt,
          updatedAt,
        }) => (
          <tr key={id}>
            <td style={{ width: "5%" }}>
              <Button
                className="rounded-circle btn-icon"
                color="none"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                  handleEdit(id);
                }}
              >
                <Edit
                  color={reactFeatherIcon.iconColor}
                  size={reactFeatherIcon.iconSize}
                />
              </Button>

              {handleDelete && (
                <Button
                  className="rounded-circle btn-icon"
                  color="none"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(id);
                  }}
                >
                  <Trash
                    color={reactFeatherIcon.iconColor}
                    size={reactFeatherIcon.iconSize}
                  />
                </Button>
              )}
            </td>
            <td className={isCritical === true ? "text-danger" : ""}>
              <span className="align-middle font-weight-bold">
                {requestNumber}
              </span>
            </td>
            <td>
              {/* <span className="align-middle font-weight-bold">
                {new Date(requestTime).getDate() > 10
                  ? new Date(requestTime).getDate()
                  : "0" + new Date(requestTime).getDate()}
                /
                {new Date(requestTime).getMonth() > 10
                  ? new Date(requestTime).getMonth()
                  : "0" + new Date(requestTime).getMonth()}
                /{new Date(requestTime).getFullYear()} -{" "}
                {new Date(requestTime).getHours() >= 9
                  ? new Date(requestTime).getHours()
                  : "0" + new Date(requestTime).getHours()}
                :
                {new Date(requestTime).getMinutes() > 10
                  ? new Date(requestTime).getMinutes()
                  : "0" + new Date(requestTime).getMinutes()}
              </span> */}
              <span className="align-middle font-weight-bold">
                {new Date(requestTime).getDate()}/
                {new Date(requestTime).getMonth() + 1}/
                {new Date(requestTime).getFullYear()} -{" "}
                {new Date(requestTime).getHours()}:
                {new Date(requestTime).getMinutes()}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {requestCategory} - {requestName}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">{hostel}</span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">{guestName}</span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {reservationId}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {requestStatus}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">{tat}</span>
            </td>

            <td>
              {["Close", "Accept", "Reject"].includes(requestStatus) ? (
                <span className="align-middle font-weight-bold">
                  {completionTimeDifference(updatedAt, createdAt)}
                </span>
              ) : (
                <span className="align-middle font-weight-bold">-</span>
              )}
            </td>

            <td>
              <span className="align-middle font-weight-bold">{roomNo}</span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {staffRemark}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">{rating}</span>
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default WebAppUserRequestTableBody;
