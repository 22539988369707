import React, {useEffect, useState} from 'react'
import CmsTabs from "@components/Cms/CmsTabs";
import Overview from './Overview';
import StepoutBlogCategory from './StepoutBlogCategory';
import FeaturedImage from './FeaturedImage';
import { tableDataHandler } from "@store/Cms/cms.action";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import StepoutBlogContent from './StepoutBlogContent';
import SimilarBlogs from './SimilarBlogs'
import Whatnext from './Whatnext';
import { useGetStepoutBlogById } from '../../../api/dataSource';
import { useParams } from "react-router";
import { useDispatch } from 'react-redux';

const AddStepOutBlogTabs = () => {
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  const{ id} = useParams();
  const tabOptions = [
    {
      id:0,
      title:"Overview",
      formComponent:<Overview/>
    },
    {
      id:1,
      title:"Featured Image",
      formComponent: <FeaturedImage />
    },
    {
      id:2,
      title:"Blog content",
      formComponent:<StepoutBlogContent/>
    },
    {
      id:3,
      title:"Category",
      formComponent:<StepoutBlogCategory />
    },
    {
      id:4,
      title:"Similar Blogs",
      formComponent:<SimilarBlogs/>
    },
    {
      id:5,
      title:"Whats next",
      formComponent: <Whatnext/>
    }

  ];

  const {data, refetch} = useGetStepoutBlogById(id)
    useEffect(()=>{
    if(data?.getStepoutBlogById){
      dispatch(tableDataHandler(data?.getStepoutBlogById))
      console.log(data?.getStepoutBlogById);
    }
    refetch();
  },[data, dispatch])

  const handleRenderChildFormComponent = ()=>{
    const fountActiveTabWithRenderChildFormComp = tabOptions.find(
      (item)=>item?.childFormComponents?.length && item.id === active,
    );
    if(fountActiveTabWithRenderChildFormComp){
      return fountActiveTabWithRenderChildFormComp.childFormComponents;
    };
    return null;
  };


  


  return (
    <div>
      <div className='mt-1 mb-2'>
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
       </div>

       {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>
                  {active !== 0 && "Stepout Blog information -"}
                  {tabOptions.find((item)=>item.id === active).title}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item)=> item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
       ):(
        handleRenderChildFormComponent().map(({title, formComponent, id})=>{
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        })
       )}
    </div>
  )
}

export default AddStepOutBlogTabs