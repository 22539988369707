// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import confirmDelete from "@components/common/confirmDelete";
import { useBlogList, useUpdateHostel } from "../../../../api/dataSource";

const Blog = () => {
  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [updateHostelHandler, { data: updatedBlogsData }] = useUpdateHostel();
  const [selectedBlog, setSelectedBlog] = useState([]);
  const [BlogsList, setBlogsList] = useState([]);
  const { data: allBlogsData } = useBlogList();

  useEffect(() => {
    if (state?.tableData?.blogs?.length) {
      let alreadySelectedBlogs = state?.tableData?.blogs?.map((item) => {
        return {
          uuId: uuidv4(),
          blogs: { label: item?.blogTitle, value: item?._id },
        };
      });
      setSelectedBlog(alreadySelectedBlogs);
    }
  }, [state]);

  const handleAddBlogs = () =>
    setSelectedBlog([
      ...selectedBlog,
      {
        uuId: uuidv4(),
        blogs: [],
      },
    ]);

  const handleRemoveSelectedBlog = (uuId) =>
    confirmDelete(() =>
      setSelectedBlog(selectedBlog.filter((item) => item.uuId !== uuId))
    );

  const handleChangeSelectedBlog = (blog, uuId) => {
    setSelectedBlog(
      selectedBlog?.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              blogs: {
                label: blog.label,
                value: blog.value,
              },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let BlogsList = [];

    if (allBlogsData?.getBlogsList?.length) {
      // eslint-disable-next-line array-callback-return
      allBlogsData?.getBlogsList?.map((item) => {
        BlogsList.push({ label: item?.blogTitle, value: item?._id });
        setBlogsList(BlogsList);
      });
    }
  }, [allBlogsData?.getBlogsList]);

  const [finalBlogArr, setFinalBlogArr] = useState([]);

  useEffect(() => {
    if (updatedBlogsData) {
      let updatedBlogs = [];
      finalBlogArr.forEach((i) => {
        updatedBlogs.push(
          allBlogsData?.getBlogsList?.find((j) => j?._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          blogs: updatedBlogs,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBlogsData?.getBlogsList, dispatch, finalBlogArr, updatedBlogsData]);

  const submitDataHandler = () => {
    var finalListOfBlogsId = [];
    selectedBlog?.forEach((item) => {
      finalListOfBlogsId.push(item.blogs.value);
    });
    setFinalBlogArr(finalListOfBlogsId);
    let data = {
      id,
      blogs: finalListOfBlogsId,
    };

    if (data?.blogs?.length > 0) {
      if (id && data?.blogs?.every((blog) => blog !== undefined)) {
        updateHostelHandler(id, {
          blogs: data.blogs,
        });
        toaster("success", "Blogs updated successfully");
      } else {
        toaster("error", "Please select the blogs");
      }
    } else {
      toaster("error", "Please select at least one blog");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDataHandler();
      }}
    >
      <Row className="mt-2">
        {selectedBlog?.map(({ uuId, blogs }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={uuId}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"reviews" + uuId}>
                Selected Blog {index + 1}
              </Label>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Select
                      isSearchable={true}
                      id={uuId}
                      name="blogs"
                      value={blogs}
                      options={BlogsList}
                      onChange={(value) => {
                        handleChangeSelectedBlog(value, uuId);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {selectedBlog.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveSelectedBlog(uuId)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>
      <SaveDiscardBtnGroup addAction={handleAddBlogs} />
    </form>
  );
};

export default Blog;
