import React, { useEffect, useState } from "react";
import SlideModal from "../../../@core/components/common/slide-modal/index";
import { Col, Row, Label, Badge, Input, Button, Spinner } from "reactstrap";
import { dateFormatHandler } from "@utils";
import { useRetryUnconfirmedBooking } from "../../../api/BookingReport/Queries";
import toaster from "@components/common/Toaster";
import { dateFormatter } from "../../../utility/Utils";

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  timeZone: "Asia/Kolkata",
};

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [providerOrderRefId, setProviderOrderRefId] = useState("");
  const [providerPaymentRefId, setProviderPaymentRefId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [retryUncofirmedBookinghandler, { data: retryData, loading, error }] =
    useRetryUnconfirmedBooking();
  const handleSaveClick = () => {
    if (!providerOrderRefId && !providerPaymentRefId) {
      toaster("error", "Razorpay Order/Payment Id is required");
      setProviderOrderRefId("");
      setProviderPaymentRefId("");
      return;
    }
    if (
      new Date(editData?.checkinDate).getTime() <
      new Date(dateFormatter(new Date())).getTime()
    ) {
      toaster("error", "Check-in Date is past date");
      setProviderOrderRefId("");
      setProviderPaymentRefId("");
      return;
    }
    setIsLoading(true);
    retryUncofirmedBookinghandler(
      editData?._id,
      providerOrderRefId,
      providerPaymentRefId
    );
    setProviderOrderRefId("");
    setProviderPaymentRefId("");
    return;
  };

  useEffect(() => {
    setIsLoading(false);
    if (retryData?.retryUnconfirmedBooking?.status) {
      toaster("success", retryData?.retryUnconfirmedBooking?.message);
      refetch();
    } else if (retryData?.retryUnconfirmedBooking?.status === false) {
      toaster("error", retryData?.retryUnconfirmedBooking?.message);
    }
  }, [refetch, retryData]);

  return (
    <SlideModal open={open} handleToggle={handleToggle}>
      <form>
        <div>
          <Row>
            <Row>
              <Col sm="12">
                <strong>
                  <p className="form-label" for="providerRefId">
                    Guest Details
                  </p>
                </strong>
              </Col>
            </Row>
            <Col sm="12">
              <strong>
                <Label className="form-label" for="name">
                  Name
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.contactDetails?.name !== null
                    ? editData?.contactDetails?.name
                    : "-"}
                </Label>
              </Col>
            </Col>
            <Col sm="12">
              <strong>
                <Label className="form-label" for="email">
                  Email
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.contactDetails?.email !== null
                    ? editData?.contactDetails?.email
                    : "-"}
                </Label>
              </Col>
            </Col>
            <Col sm="12">
              <strong>
                <Label className="form-label" for="mobile">
                  Mobile
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.contactDetails?.mobile !== null
                    ? editData?.contactDetails?.mobile
                    : "-"}
                </Label>
              </Col>
            </Col>
          </Row>
          <hr />
        </div>

        <div>
          <Row>
            <Row>
              <Col sm="12">
                <strong>
                  <p className="form-label" for="booking">
                    Booking Details
                  </p>
                </strong>
              </Col>
            </Row>
            <Col sm="12">
              <strong>
                <Label className="form-label" for="providerRefId">
                  Reservation Id
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.providerRefId !== null
                    ? editData?.providerRefId
                    : "-"}
                </Label>
              </Col>
            </Col>
            <Col sm="12">
              <strong>
                <Label className="form-label" for="status">
                  Status
                </Label>
              </strong>
              <Col>
                <Badge
                  color={
                    editData?.status === "confirm"
                      ? "light-success"
                      : editData?.status === "pending"
                      ? "light-info"
                      : editData?.status === "failure"
                      ? "light-danger"
                      : "light-warning"
                  }
                >
                  <Label className="form-label">
                    {editData?.status !== null ? editData?.status : "-"}
                  </Label>
                </Badge>
              </Col>
            </Col>
            <Col sm="12">
              <strong>
                <Label className="form-label" for="mobile">
                  Booking Date / Time
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData &&
                    new Intl.DateTimeFormat("en-IN", options).format(
                      new Date(editData?.createdAt)
                    )}
                </Label>
              </Col>
            </Col>

            <Col sm="12">
              <strong>
                <Label className="form-label" for="coupon">
                  Coupon
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  <Badge className="text-dark" color="light-secondary">
                    {editData?.coupon?.promoCode !== null
                      ? editData?.coupon?.promoCode
                      : "-"}
                  </Badge>
                </Label>
              </Col>
            </Col>

            <Col sm="12">
              <strong>
                <Label className="form-label" for="bookingSource">
                  Booking Source
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  <Badge className="text-dark" color="light-secondary">
                    {editData?.bookingSource !== null
                      ? editData?.bookingSource
                      : "-"}
                  </Badge>
                </Label>
              </Col>
            </Col>

            <Col sm="12">
              <strong>
                <Label className="form-label" for="hostel">
                  Hostel Name
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.hostelId !== null
                    ? editData?.hostelId?.name
                    : null}
                </Label>
              </Col>
            </Col>

            <Col sm="12">
              <strong>
                <Label className="form-label" for="hostel">
                  Rooms
                </Label>
              </strong>
              <Col>
                {editData?.rooms?.map((room, i) => {
                  return (
                    <Label className="form-label">
                      {i + 1}. {room?.roomName} x {room?.quantity}
                    </Label>
                  );
                })}
              </Col>
            </Col>

            <Col sm="12">
              <strong>
                <Label className="form-label" for="check">
                  Checkin - Checkout
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.checkinDate
                    ? dateFormatHandler(editData?.checkinDate)
                    : ""}
                  -
                  {editData?.checkinDate
                    ? dateFormatHandler(editData?.checkoutDate)
                    : ""}
                </Label>
              </Col>
            </Col>

            <Col sm="12">
              <strong>
                <Label className="form-label" for="payableAmount">
                  Razorpay
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.payableAmount !== null
                    ? editData?.payableAmount
                    : "-"}
                </Label>
              </Col>
            </Col>

            <Col sm="12">
              <strong>
                <Label className="form-label" for="walletDebit">
                  Wallet
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.walletDebit !== null ? editData?.walletDebit : "-"}
                </Label>
              </Col>
            </Col>

            <Col sm="12">
              <strong>
                <Label className="form-label" for="mobile">
                  Mobile
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.contactDetails?.mobile !== null
                    ? editData?.contactDetails?.mobile
                    : "-"}
                </Label>
              </Col>
            </Col>

            {editData?.refundType && (
              <Col sm="12">
                <strong>
                  <Label className="form-label" for="refundType">
                    Refund Type / Id / Amount
                  </Label>
                </strong>
                <Col>
                  <Badge
                    className="text-dark"
                    color={
                      editData?.refundType === "Wallet" ? "primary" : "info"
                    }
                  >
                    {editData?.refundType}
                  </Badge>
                  <Label>
                    {editData?.refundType ? " / " : ""}
                    {editData?.providerRefundRefId}
                  </Label>
                  <br />
                  <Label>
                    {editData?.refundType && "Razorpay: "}
                    {editData?.razorpayRefund?.toFixed(2)}
                  </Label>
                  <br />
                  <Label>
                    {editData?.refundType && "Wallet: "}
                    {editData?.walletRefund?.toFixed(2)}
                  </Label>
                </Col>
              </Col>
            )}
          </Row>
          <hr />
        </div>

        {editData &&
          editData.status !== "confirm" &&
          editData.status !== "cancel" && (
            <div>
              <Row>
                {/* <Row>
              <Col sm="12">
                <strong>
                  <p className="form-label" for="bookingDetails">
                    Retry Booking
                  </p>
                </strong>
              </Col>
            </Row> */}
                <Col sm="12">
                  <strong>
                    <Label className="form-label" for="providerOrderRefId">
                      Razorpay Order Id
                    </Label>
                  </strong>

                  <Input
                    type="text"
                    value={providerOrderRefId}
                    onChange={(e) => setProviderOrderRefId(e.target.value)}
                    placeholder=""
                  />
                </Col>
                <Col sm="12">
                  <strong>
                    <Label className="form-label" for="providerPaymentRefId">
                      Razorpay Payment Id
                    </Label>
                  </strong>

                  <Input
                    type="text"
                    value={providerPaymentRefId}
                    onChange={(e) => setProviderPaymentRefId(e.target.value)}
                    placeholder=""
                  />
                </Col>
                <Col>
                  <Button
                    color="success"
                    onClick={handleSaveClick}
                    disabled={
                      editData?.status === "confirm" ||
                      editData?.status === "cancel"
                    }
                    className="ml-auto mt-2 cursor-pointer  btn-icon rounded float-right"
                    style={{
                      fontSize: "12px",
                      padding: "10px 16px",
                    }}
                  >
                    {isLoading ? (
                      <>
                        <Spinner size="sm" color="light" className="mr-1" />{" "}
                        Loading...
                      </>
                    ) : (
                      "Retry Booking"
                    )}
                  </Button>
                </Col>
              </Row>
              <hr />
            </div>
          )}

        <div>
          <Row>
            <Row>
              <Col sm="12">
                <strong>
                  <p className="form-label" for="bookingDetails">
                    Account Details
                  </p>
                </strong>
              </Col>
            </Row>
            <Col sm="12">
              <strong>
                <Label className="form-label" for="name">
                  Name
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.userId?.fullName !== null
                    ? editData?.userId?.fullName
                    : "-"}
                </Label>
              </Col>
            </Col>
            <Col sm="12">
              <strong>
                <Label className="form-label" for="email">
                  Email
                </Label>
              </strong>
              <Col>
                <Label className="form-label">
                  {editData?.userId?.email !== null
                    ? editData?.userId?.email
                    : "-"}
                </Label>
              </Col>
            </Col>
          </Row>
        </div>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
