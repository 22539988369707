import gql from "graphql-tag";

export const GET_POLICIES_BY_TYPE = gql`
  query getPolicyByType($type: String) {
    getPolicyByType(type: $type) {
      _id
      type
      title
      image
      guestPolicy {
        header
        description
      }
      privacyPolicy {
        header
        description
      }
      termsAndCondition {
        header
        description
      }
    }
  }
`;
