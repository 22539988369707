// ! Added by Ashwin
import gql from "graphql-tag";

export const CREATE_DISPLAY_ADS = gql`
mutation createDisplayAds($displayAdsInput: DisplayAdsInput){
    createDisplayAds(displayAdsInput: $displayAdsInput){
        adName
        adType
        url
        portraitImage
        landscapeImage
        displayOrder
        status
    }
}
`

export const UPDATE_DISPLAY_ADS = gql`
mutation updateDisplayAds($id: ID, $displayAdsUpdate: DisplayAdsInput) {
    updateDisplayAds(id: $id, displayAdsUpdate: $displayAdsUpdate){
        adName
    }
}
`