// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CmsTabs from "@components/Cms/CmsTabs";
import Hero from "../Tabs/Hero";
import Guest from "../Tabs/Guest";
import Privacy from "../Tabs/Privacy";
import TermsAndConditions from "../Tabs/TermsAndConditions";
import { useDispatch } from "react-redux";
import { useGetStepOutPolicies } from "../../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import { STEP_OUT_POLICIES } from "../../../../utility/constant";

const tabOptions = [
  // {
  //   id: 0,
  //   title: "Hero",
  //   formComponent: <Hero />,
  // },
  {
    id: 0,
    title: "Guest",
    formComponent: <Guest />,
  },
  {
    id: 1,
    title: "Privacy",
    formComponent: <Privacy />,
  },
  {
    id: 2,
    title: "Terms And Conditions",
    formComponent: <TermsAndConditions />,
  },
];

const StepOutPolicies = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);

  const { data, refetch } = useGetStepOutPolicies(STEP_OUT_POLICIES); // "hosteller" is used to get landing page doc

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getStepOutPoliciesByType) {
      dispatch(tableDataHandler(data?.getStepOutPoliciesByType));
      refetch();
    }
  }, [data, dispatch]);

  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle>
                  Landing Page -{" "}
                  <b>
                    {" "}
                    {tabOptions.find((item) => item.id === active).title}{" "}
                    Section{" "}
                  </b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default StepOutPolicies;
