import React, { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { DragDrop } from "@uppy/react";
import { useSelector, useDispatch } from "react-redux";
import { useUploadImage, useUpdateTrip } from "@api";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import { Col, Row } from "reactstrap";
import toaster from "@components/common/Toaster";

const PdfForm = () => {
  const state = useSelector((state) => state?.cms);
  const [pdfView, setPdfView] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [uploadImageHandler, { data: imageData, reset }] = useUploadImage();
  const [updateTripHandler, { data }] = useUpdateTrip();

  useEffect(() => {
    if (data?.updateTrip?.name) {
      toaster("success", "Submitted successfully");
    }
  }, [data?.updateTrip?.name]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: [".pdf"],
      maxFileSize: 30000000,
    },
  });

  uppy.on("complete", (result) => {
    result.successful[0]?.data &&
      uploadImageHandler(
        result.successful[0]?.data,
        `Trip/${state.tableData?.name?.toLowerCase()?.replaceAll(" ", "-")}`
      );
  });

  useEffect(() => {
    if (state?.tableData?.itineraryDetails) {
      setPdfView(state?.tableData?.itineraryDetails);
    }
  }, [state?.tableData?.itineraryDetails]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setPdfView(imageData?.uploadImage);

      reset();
    }
  }, [imageData]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (pdfView?.url) {
      const { url, key } = pdfView;

      const data = {
        itineraryDetails: { url, key },
      };

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );

      updateTripHandler(id, data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form onSubmit={submitHandler}>
      <Row className='mt-2'>
        <Col sm='12'>
          <DragDrop
            width='100%'
            locale={{
              strings: {
                dropHereOr: "Upload PDF | Max size 30MB",
              },
            }}
            uppy={uppy}
          />
        </Col>

        {pdfView && (
          <Col sm='12'>
            <p className='p-1 my-1 text-center bg-dark text-light'>
              <a target='_blank' href={pdfView.url}>
                {pdfView.key}
              </a>
            </p>
          </Col>
        )}
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default PdfForm;
