import React, { useEffect, useState } from "react";
import { Plus, Trash } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateTrip } from "@api";
import { useParams } from "react-router-dom";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";

const OverviewForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.cms);

  const [highlight, setHighlight] = useState([
    {
      id: uuidv4(),
      content: EditorState.createEmpty(),
      heading: "",
    },
  ]);
  const [updateTripHandler, { data }] = useUpdateTrip();

  useEffect(() => {
    if (data?.updateTrip?.name) {
      toaster("success", "Submitted successfully");
    }
  }, [data?.updateTrip?.name]);

  useEffect(() => {
    if (state?.tableData?.overview?.length > 0) {
      const { overview } = state?.tableData;
      const updatedOverview = overview?.map((item) => {
        return {
          id: uuidv4(),
          heading: item?.heading,
          content: htmlToDraftConvert(item?.content),
        };
      });

      setHighlight(updatedOverview);
    }
  }, [state]);

  const handleChangeHighlight = (val, id) =>
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, heading: val } : item
      )
    );

  const handleChangeHighlightTextContent = (value, id) =>
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, content: value } : item
      )
    );

  const handleRemoveHighlight = (id) =>
    setHighlight(highlight.filter((item) => item.id !== id));

  const handleAddHighlight = () =>
    setHighlight([
      ...highlight,
      {
        id: uuidv4(),
        content: EditorState.createEmpty(),
      },
    ]);

  const submitHandler = (e) => {
    e.preventDefault();

    let isValid = false;

    highlight.forEach((item) => delete item.id);

    let updatedArray = highlight.map((item) => {
      let content = inputDataConvertor(item?.content);

      return { ...item, content };
    });

    updatedArray?.forEach((data) => {
      data?.heading && data?.content ? (isValid = true) : (isValid = false);
    });

    if (isValid) {
      const data = {
        overview: updatedArray,
      };

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );

      updateTripHandler(id, data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <Row className='mt-2'>
          {highlight.map(({ content, heading, id }, index) => (
            <Col className='d-flex align-items-center' sm='12' key={id}>
              <FormGroup className='w-100'>
                <Label className='form-label' for={"highlight" + id}>
                  Highlight {index + 1}
                </Label>

                <Input
                  type='text'
                  value={heading}
                  id={"highlight" + id}
                  name={"highlight" + id}
                  placeholder='Add highlight'
                  onChange={(e) => handleChangeHighlight(e.target.value, id)}
                />
                <div className='mt-1'>
                  <MyEditor
                    editorState={content}
                    setEditorState={(val) =>
                      handleChangeHighlightTextContent(val, id)
                    }
                  />
                </div>
              </FormGroup>
              {highlight.length > 1 && (
                <Button.Ripple
                  className='btn-icon rounded-circle ml-1'
                  color='light'
                  type='button'
                  size='sm'
                  onClick={() => handleRemoveHighlight(id)}
                >
                  <Trash size={14} />
                </Button.Ripple>
              )}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHighlight} />
      </form>
    </>
  );
};

export default OverviewForm;
