import React, { useState } from "react";
import { toNumber } from "reactstrap/lib/utils";
import { useSkin } from "@hooks/useSkin";


function OffCanvasTable({ BookingData, reservationNumber }) {
  const [collapsedRows, setCollapsedRows] = useState({});
  const [skin, setSkin] = useSkin();

  const tableHeadingToShow = [
    "Reservation No",
    "Guest Name",
    "Room No",
    "Total Person",
  ];

  const getGroupBookingDetails = BookingData?.flatMap((item) =>
    item?.Bookings?.filter(
      (_item) =>
        _item.ReservationNo?.split("-")[0] ===
        reservationNumber?.split("-")[0]
    ) || []
  );

  const RoomNumbers = getGroupBookingDetails?.map((item) => item?.Room);
  const uniqueRoomNumbers = [...new Set(RoomNumbers)];

  const getGroupBookingDataAsPerRoomType = (roomName) =>
    getGroupBookingDetails?.filter((item) => item?.Room === roomName);

  console.log(getGroupBookingDetails)

  const toggleCollapse = (index) => {
    setCollapsedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Inline styles
  const tableStyle = {
    width: '100%',
    minHeight: 'max-content',
    borderCollapse: 'collapse',
    margin: '20px 0',
    fontSize: '16px',
    textAlign: 'left',
  };

  const thStyle = {
    // backgroundColor: skin === 'dark' ? '#161D31' : '#f4f4f4',
    // color: skin === 'dark' ? '#fff' : '#333',
    padding: '10px',
    borderBottom: '2px solid #ddd',
    width : "150px"
  };

  const tdStyle = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  };

  const trStyle = {
    cursor: 'pointer',
  };

  const collapsibleRowStyle = {
    // backgroundColor: skin === 'dark' ? '#161D31' : '#fafafa',
  };

  return (
    <table style={tableStyle}>
      <thead>
        <tr>
          {tableHeadingToShow?.map((heading, index) => (
            <th key={index} style={thStyle}>
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {uniqueRoomNumbers?.map((roomNumber, index) => (
          <React.Fragment key={roomNumber + index}>
            <tr
              onClick={() => toggleCollapse(index)}
              style={trStyle}
            >
              <td colSpan={tableHeadingToShow.length} style={tdStyle}>
                <strong>{roomNumber} ({`${getGroupBookingDataAsPerRoomType(roomNumber)?.length}`})</strong>
              </td>
            </tr>
            {!collapsedRows[index] &&
              getGroupBookingDataAsPerRoomType(roomNumber)?.map((data, rowIndex) => (
                <tr key={data?.ReservationNo + rowIndex} style={collapsibleRowStyle}>
                  <td style={tdStyle}>{data?.ReservationNo}</td>
                  <td style={tdStyle}>{data?.GuestName}</td>
                  {/* <td style={tdStyle}>{data?.RoomType}</td> */}
                  <td style={tdStyle}>{data?.RoomNo}</td>
                  <td style={tdStyle}>{data?.NoOfGuest}</td>
                </tr>
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}

export default OffCanvasTable;
