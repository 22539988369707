//Added By Gaurav
import gql from "graphql-tag";

export const GET_WHATSAPP_MESSAGES_REPORT = gql`
query getWhatsappMessagesReport($filter:filter){
    getWhatsappMessagesReport(filter: $filter){
        data {
            _id
            messages{
                type
                count
            }
            totalMessages
        }
        limit
        count
        page
}
}
`;
