import StepOutHomepage from "../../views/StepOut/StepOutHomepage";
import StepOutCategory from "../../views/StepOut/StepOutCategory";
import StepOutProduct from "../../views/StepOut/StepOutProduct";
import StepOutPolicies from "../../views/StepOut/StepOutPolicies";
import StepOutContactUs from "../../views/StepOut/StepOutContactUs";
import StepOutCoupons from "../../views/StepOut/StepOutCoupons";
import AddStepOutTabs from "../../views/StepOut/AddStepOutTabs";
import AddStepOutCategoryTabs from "../../views/StepOut/StepOutCategory/AddStepOutCategoryTabs";
import StepOutLocation from "../../views/StepOut/StepOutLocation";
import AddStepOutLocationTabs from "../../views/StepOut/StepOutLocation/AddStepOutLocationTabs";
import StepOutBlogCateory from "../../views/StepOut/StepOutBlogCategory";
import StepoutBlogs from "../../views/StepOut/StepOutBlog"
import AddStepOutBlogTabs from "../../views/StepOut/StepOutBlog/AddStepOutBlogTabs"
import StepoutUsers from "../../views/StepOut/StepOutUsers"
import StepOutWallOfLove from "../../views/StepOut/StepOutWallOfLove";
import StepOutBookings from "../../views/StepOut/StepOutBookings"

const StepOutRoutes = [
  {
    path: "/stepOut/stepOutHomepage",
    component: StepOutHomepage,
    exact: true,
  },
  {
    path: "/stepOut/stepOutCategory",
    component: StepOutCategory,
    exact: true,
  },
  {
    path: "/stepOut/edit/stepOutCategory/:id",
    component: AddStepOutCategoryTabs,
    exact: true,
    meta: {
      navLink: "/stepOut",
    },
  },
  {
    path: "/stepOut/stepOutProduct",
    component: StepOutProduct,
    exact: true,
  },
  {
    path: "/stepOut/edit/stepOutProduct/:id",
    component: AddStepOutTabs,
    exact: true,
    meta: {
      navLink: "/stepOut",
    },
  },
  {
    path: "/stepOut/stepOutPolicies",
    component: StepOutPolicies,
    exact: true,
  },
  {
    path: "/stepOut/stepOutContactUs",
    component: StepOutContactUs,
    exact: true,
  },
  {
    path: "/stepOut/stepOutCoupons",
    component: StepOutCoupons,
    exact: true,
  },
  {
    path: "/stepOut/stepOutLocations",
    component: StepOutLocation,
    exact: true,
  },
  {
    path: "/stepOut/edit/stepOutLocation/:id",
    component: AddStepOutLocationTabs,
    exact: true,
    meta: {
      navLink: "/stepOut",
    },
  },
  {
    path: "/stepOut/stepOutBlogCategory",
    component: StepOutBlogCateory,
    exact: true,
  },

  {
    path: "/stepOut/stepOutBlogs",
    component: StepoutBlogs,
    exact: true
  },

  {
    path:"/stepOut/edit/stepOutBlog/:id",
    component:AddStepOutBlogTabs,
    exact: true,
    meta:{
      navLink:"/stepOut",
    },
  },
  {
    path: "/stepOut/stepOutUsers",
    component: StepoutUsers,
    exact: true
  },
  {
    path: "/stepOut/stepOutWallOfLove",
    component: StepOutWallOfLove,
    exact: true
  },
  {
    path: "/stepOut/stepOutBookings",
    component: StepOutBookings,
    exact: true,
  },
];

export default StepOutRoutes;
