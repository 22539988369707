import gql from "graphql-tag";

export const UPDATE_GLU_BANNER = gql`
  mutation updateGluBanner($id: ID!, $gluBannerUpdate: InputGluBanner) {
    updateGluBanner(id: $id, gluBannerUpdate: $gluBannerUpdate) {
      image
      url
      title
      imageType
      displayOrder
      logs
    }
  }
`;

export const CREATE_GLU_BANNER = gql`
  mutation createGluBanner($data: InputGluBanner) {
    createGluBanner(data: $data) {
      data {
        image
        url
        title
        imageType
        displayOrder
        hostel {
          _id
          name
        }
        logs
      }
    }
  }
`;

export const DELETE_GLU_BANNER = gql`
  mutation deleteGluBanner($id: ID!) {
    deleteGluBanner(id: $id) {
      deletedStaus
      message
    }
  }
`;
