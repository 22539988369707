import React, { useState, useEffect } from "react";
import SlideModal from "@components/common/slide-modal";
import { Row, Col, Input } from "reactstrap";
import { DragDrop } from "@uppy/react";
import Uppy from "@uppy/core";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";

import thumbnailGenerator from "@uppy/thumbnail-generator";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
// import { useUploadImage } from "@api";
import {
  useCreateStepoutWallOfLove,
  useUpdateStepoutWallOfLove,
  useUploadImage,
} from "../../../api/dataSource";

const StepOutWallOfLoveAddEditModal = ({
  open,
  handleToggle,
  refetch,
  editData,
}) => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState(false);
  const [displayOrder, setDisplayOrder] = useState("");
  const [previewArr, setPreviewArr] = useState([]);
  // const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (editData?._id) {
      setName(editData.ImageName);
      setStatus(editData.status);
      setDisplayOrder(editData.DisplayOrder);
      // setImageUrl(editData.ImageUrl);
      setPreviewArr([editData.ImageUrl]);
    } else {
      setName("");
      setStatus(false);
      setDisplayOrder("");
      setPreviewArr([]);
    }
  }, [editData]);

  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();

  const [createStepoutWallOfLoveHandler, { loading, error, data }] =
    useCreateStepoutWallOfLove();

  const [updateStepoutWallOfLoveHandler, { data: recievedfromUpdate }] =
    useUpdateStepoutWallOfLove();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppy.use(thumbnailGenerator);
  uppy.on("thumbnail:generated", (file, preview) => {
    setPreviewArr([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "image/walloflove");
    // setImageUrl(imageData?.uploadImage?.url)
    toaster("success", "Wall of love image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading wall of love image: ${error.message}`);
  });

  const renderPreview = () => {
    if (previewArr.length) {
      return previewArr.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };

  const submitDataHandler = () => {
    setStatus(status === "true");
    const submitData = {
      ImageName: name,
      status,
      DisplayOrder: parseInt(displayOrder),
      ImageUrl: imageData?.uploadImage?.url,
    };
    try {
      if (editData?._id) {
        updateStepoutWallOfLoveHandler(editData?._id, submitData);
        toaster("success", "Wall of love updated successfully");
        handleToggle();
        refetch();
      } else if (submitData?.ImageUrl) {
        createStepoutWallOfLoveHandler(submitData);
        toaster("success", "Wall of love added successfully");
        handleToggle();
        refetch();
      } else {
        toaster("error", "Please fill the details");
      }
      refetch();
    } catch (error) {
      toaster("error", "Failed to perform action");

      console.error(error.message);
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => submitDataHandler()}
    >
      <form>
        <Row>
          <Col>
            <Row>
              <Col>
                <label>Name*</label>
                <Input
                  autoFocus
                  type="text"
                  value={name}
                  id="name"
                  name="name"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SwitchIcon
                  checked={status}
                  id="status"
                  name="status"
                  label="Publish on Website ?"
                  handleChecked={(value) => setStatus(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Display Order</label>
                <Input
                  autoFocus
                  type="Number"
                  value={displayOrder}
                  id="displayorder"
                  name="displayorder"
                  placeholder="Display Order"
                  onChange={(e) => setDisplayOrder(e.target.value)}
                />
              </Col>
            </Row>
            <p>Upload Image</p>
            <DragDrop
              note="Image size should be less than 500 KB"
              uppy={uppy}
            />
            {renderPreview()}
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default StepOutWallOfLoveAddEditModal;
