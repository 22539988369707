import { useEffect, useState } from "react";
import { Edit, Link, link } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import SwitchIcon from "@components/common/switch-icon";
import { Badge } from "reactstrap";
import { useUpdateService } from "../../../api/dataSource";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Category",
  "Hostel Name",
  "Title",
  "Price",
  "Booking Rule",
  "Status",
];

const ServiceConfigTable = ({ openModal, setId, data, editData, refetch }) => {
  const [tableData, setTableData] = useState(data);
  const [updateServiceHandler, { data: updatdedServiceData }] =
    useUpdateService();
  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const createObject = (value, item, e) => {
    let data = {
      status: value,
    };
    if (item?._id) {
      updateServiceHandler(item?._id, data);
      refetch();
    }
  };
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Hostelwise Services Configuration</h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Services
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>

            <tbody>
              {tableData?.map((item, index) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "5%" }}>
                    <Button
                      href="/"
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                      title="Edit Services"
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>{item?.category}</td>
                  <td>{item?.hostel ? item?.hostel?.name : "All"}</td>
                  <td>{item?.title}</td>
                  <td>{item?.price}</td>
                  <td>{item?.bookingRule}</td>
                  <td>
                    <SwitchIcon
                      checked={item?.status}
                      id={item?._id}
                      name="status"
                      handleChecked={(value) => createObject(value)}
                      onClick={(e) => createObject(!item.status, item, e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default ServiceConfigTable;
