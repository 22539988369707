// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { X } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import confirmDelete from "@components/common/confirmDelete";
import { useHostelList, useUpdateHostel } from "../../../../api/dataSource";

const SimilarHostels = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateHostelHandler, { data: updatedHostelsData }] = useUpdateHostel();
  const [selectedHostel, setSelectedHostel] = useState([
    {
      uuId: uuidv4(),
      similarHostels: [], // ! Changed from blank to hostel
    },
  ]);
  const [HostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    if (state?.tableData?.similarHostels?.length) {
      let alreadySelectedHostels = state?.tableData?.similarHostels?.map(
        (item) => {
          return {
            uuId: uuidv4(),
            similarHostels: { label: item?.name, value: item?._id },
          };
        }
      );
      setSelectedHostel(alreadySelectedHostels);
    }
  }, [state]);

  const handleAddDestinations = () =>
    setSelectedHostel([
      ...selectedHostel,
      {
        uuId: uuidv4(),
        similarHostels: "",
      },
    ]);

  const handleRemoveSelectedDestination = (uuId) =>
    confirmDelete(() =>
      setSelectedHostel(selectedHostel.filter((item) => item.uuId !== uuId))
    );

  const handleChangeSelectedHostel = (hostel, uuId) => {
    setSelectedHostel(
      selectedHostel.map((item) => {
        return item.uuId === uuId
          ? {
              ...item,
              similarHostels: { label: hostel.label, value: hostel.value },
            }
          : item;
      })
    );
  };

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      // eslint-disable-next-line array-callback-return
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const [finalHostelsArr, setFinalHostelsArr] = useState([]);

  useEffect(() => {
    if (updatedHostelsData) {
      let updatedHostels = [];
      finalHostelsArr.forEach((i) => {
        updatedHostels.push(
          allHostelsData?.getHostelList?.find((j) => j._id === i)
        );
      });
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          similarHostels: updatedHostels,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allHostelsData?.getHostelList,
    dispatch,
    finalHostelsArr,
    updatedHostelsData,
  ]);

  const submitDatahandler = () => {
    var finalListOfHostelsId = [];

    selectedHostel?.forEach((item) =>
      finalListOfHostelsId.push(item.similarHostels.value)
    );
    setFinalHostelsArr(finalListOfHostelsId);
    let data = {
      id,
      similarHostels: finalListOfHostelsId,
    };

    if (data?.similarHostels?.length > 0) {
      if (id && data?.similarHostels?.every((hostel) => hostel !== undefined)) {
        updateHostelHandler(id, { similarHostels: data.similarHostels });
        toaster("success", "Similar hostels updated successfully");
      } else {
        toaster("error", "Please select the hostel");
      }
    } else {
      toaster("error", "Please select at least one hostel");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDatahandler();
      }}
    >
      <Row className="mt-2">
        {selectedHostel.map(({ uuId, similarHostels }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={uuId}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"reviews" + uuId}>
                Selected Hostel {index + 1}
              </Label>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Select
                      isSearchable={true}
                      id={uuId}
                      name="similarHostels"
                      value={similarHostels}
                      options={HostelsList}
                      onChange={(value) => {
                        handleChangeSelectedHostel(value, uuId);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            {selectedHostel.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveSelectedDestination(uuId)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>
      <SaveDiscardBtnGroup addAction={handleAddDestinations} />
    </form>
  );
};

export default SimilarHostels;
