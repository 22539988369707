import React from "react";
import { Button } from "reactstrap";
import { Plus, Check } from "react-feather";

const SaveDiscardBtnGroup = ({ loading, addAction, disabled }) => {
  return (
    <div className='d-flex flex-row-reverse m-2'>
      <Button.Ripple
        color={disabled ? "secondary" : "light"}
        disabled={disabled}
        className='mr-2'
        type='submit'
      >
        <Check size={15} className='mr-1' />
        Save Changes
      </Button.Ripple>

      {addAction && (
        <Button.Ripple
          type='button'
          className='btn-icon rounded mr-1'
          color='light'
          size='md'
          onClick={addAction}
        >
          <Plus size={14} className='mr-1' />
          Add
        </Button.Ripple>
      )}
    </div>
  );
};

export default SaveDiscardBtnGroup;
