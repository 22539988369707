import { React, useEffect, useState, useRef } from "react";
import {
  useGetCxReputationReport,
  useGetReviewSentimentAnalysis,
} from "../../api/dataSource";
import {
  Col,
  FormGroup,
  Label,
  Row,
  Table,
  CardHeader,
  CardTitle,
  Card,
  Button,
} from "reactstrap";
import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { useHostelList } from "../../api/dataSource";
import { selectThemeColors } from "@utils";

const ORMSummary = () => {
  // const [fromDate, setFromDate] = useState(
  //   dateFormatter(addDays(dateFormatter(new Date()), -30))
  // );

  const [fromDate, setFromDate] = useState(
    dateFormatter(new Date(new Date().setDate(1)))
  );

  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const { data: allHostelsData } = useHostelList();
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
  const hostelsListRef = useRef([]);
  const allowedHostelsRef = useRef([]);

  // const { data: reputationReportData } = useGetCxReputationReport(
  //   hostel?.value,
  //   fromDate,
  //   toDate
  // );

  // const { data: sentimentAnalysisData, refetch: refetchSentimentAnalysis } =
  //   useGetReviewSentimentAnalysis(hostel?.value, fromDate, toDate);

  const [filterFields, setFilterFields] = useState({
    hostel,
    fromDate,
    toDate,
  });

  const { data: reputationReportData } = useGetCxReputationReport(
    filterFields.hostel ? filterFields.hostel.value : null,
    filterFields.fromDate,
    filterFields.toDate
  );

  const { data: sentimentAnalysisData, refetch: refetchSentimentAnalysis } =
    useGetReviewSentimentAnalysis(
      filterFields.hostel ? filterFields.hostel.value : null,
      filterFields.fromDate,
      filterFields.toDate
    );

  const handleSearch = () => {
    setFilterFields({
      hostel,
      fromDate,
      toDate,
    });
  };

  const refreshHandler = () => {
    setFromDate(dateFormatter(new Date(new Date().setDate(1))));
    setToDate(dateFormatter(new Date()));
    setFilterFields({
      fromDate: dateFormatter(new Date(new Date().setDate(1))),
      toDate: dateFormatter(new Date()),
    });
  };

  const newData = [
    {
      source: "Google",
      reservations: reputationReportData?.getCxReputationReport?.googleReservs,
      reviews: reputationReportData?.getCxReputationReport?.googleReviews,
      reviewRate: reputationReportData?.getCxReputationReport?.googleReviewRate,
    },
    {
      source: "Goibibo",
      reservations: reputationReportData?.getCxReputationReport?.goibiboReservs,
      reviews: reputationReportData?.getCxReputationReport?.goibiboReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.goibiboReviewRate,
    },
    {
      source: "Booking.com",
      reservations:
        reputationReportData?.getCxReputationReport?.bookingComReservs,
      reviews: reputationReportData?.getCxReputationReport?.bookingComReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.bookingComReviewRate,
    },
    {
      source: "HostelWorld",
      reservations:
        reputationReportData?.getCxReputationReport?.hostelWorldReservs,
      reviews: reputationReportData?.getCxReputationReport?.hostelWorldReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.hostelWorldReviewRate,
    },
    {
      source: "MakeMyTrip",
      reservations:
        reputationReportData?.getCxReputationReport?.makemyTripReservs,
      reviews: reputationReportData?.getCxReputationReport?.makemyTripReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.makemyTripReviewRate,
    },
    {
      source: "TripAdvisor",
      reservations:
        reputationReportData?.getCxReputationReport?.tripadvisorReservs,
      reviews: reputationReportData?.getCxReputationReport?.tripadvisorReviews,
      reviewRate:
        reputationReportData?.getCxReputationReport?.tripadvisorReviewRate,
    },
  ];
  const tableHeaderData = ["Source", "Reservations", "Reviews", "Review Rate"];
  const tableHeaderDataTwo = [
    "ORM",
    "Total Reviews",
    "Neutral Review Rate",
    "Negative Review Rate",
  ];

  useEffect(() => {
    let hostelsList = [];

    if (allHostelsData?.getHostelList?.length) {
      if (allowedHostels && allowedHostels.length > 0) {
        const filteredHostels = allowedHostels.map((hostelID) =>
          allHostelsData.getHostelList.find((item) => item?._id === hostelID)
        );

        hostelsList = filteredHostels.map((item) => ({
          label: item?.name,
          value: item?._id,
        }));
      } else {
        hostelsList = allHostelsData.getHostelList.map((item) => ({
          label: item?.name,
          value: item?._id,
        }));
      }

      if (
        JSON.stringify(hostelsList) !==
          JSON.stringify(hostelsListRef.current) ||
        JSON.stringify(allowedHostels) !==
          JSON.stringify(allowedHostelsRef.current)
      ) {
        hostelsListRef.current = hostelsList;
        allowedHostelsRef.current = allowedHostels;

        setHostelsList(hostelsList);

        if (hostelsList.length > 0) {
          setHostel({
            label: hostelsList[0]?.label,
            value: hostelsList[0]?.value,
          });
        }
      }
    }
  }, [allHostelsData?.getHostelList, allowedHostels]);

  const reviewRateLimit = {
    Google: 22.5,
    Goibibo: 12.5,
    "Booking.com": 12.5,
    HostelWorld: 12.5,
    MakeMyTrip: 12.5,
    TripAdvisor: 2.5,
  };

  return (
    localStorage.getItem("modules")?.includes("ORM Reports") && (
      <>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Hostel Name
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                id="hostel"
                name="hostel"
                value={hostel}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                Review Date - From
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                Review Date - To
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
                placeholder="To Date"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>

            <Button
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                refreshHandler();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>
                  <div>
                    <h2>Review Rate</h2>
                  </div>
                </CardTitle>
              </CardHeader>

              <Table striped bordered>
                <thead className="text-center">
                  <tr>
                    {tableHeaderData.map((item, index) => {
                      return <th key={index}>{item}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {newData?.map((item, index) => (
                    <tr className="text-center" key={index}>
                      <td>
                        <b>{item?.source}</b>
                      </td>
                      <td>{item?.reservations}</td>
                      <td>{item?.reviews}</td>
                      {/* <td>{item?.reviewRate?.toFixed(2)}</td> */}
                      <td
                        style={{
                          color:
                            item?.reviewRate < reviewRateLimit[item?.source]
                              ? "red"
                              : "#B4B7BD",
                        }}
                      >
                        {item?.reviewRate?.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
            <br />
            <Card>
              <Table striped bordered>
                <thead className="text-center">
                  <tr>
                    {tableHeaderDataTwo.map((item, index) => {
                      return <th key={index}>{item}</th>;
                    })}
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr>
                    <td>
                      {sentimentAnalysisData?.getReviewSentimentAnalysis?.orm?.toFixed(
                        2
                      )}
                    </td>
                    <td>
                      {
                        sentimentAnalysisData?.getReviewSentimentAnalysis
                          ?.totalReviews
                      }
                    </td>
                    <td>
                      {sentimentAnalysisData?.getReviewSentimentAnalysis?.neutralRate?.toFixed(
                        2
                      )}
                    </td>
                    <td>
                      {sentimentAnalysisData?.getReviewSentimentAnalysis?.negativeRate?.toFixed(
                        2
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </>
    )
  );
};
export default ORMSummary;
