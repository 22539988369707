import { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import toaster from "@components/common/Toaster";

const UrlForm = ({ showModal, closeModal, imageLink, addImageLink }) => {
  const [imageCaption, setImageCaption] = useState("");
  const [displayOrder, setDisplayOrder] = useState(0);
  const [imageType, setImageType] = useState("");

  //check valid url

  const ImageTypeList = [
    { label: "Landscape Image", value: "Landscape Image" },
    { label: "Portrait Image", value: "Portrait Image" },
  ];

  useEffect(() => {
    setImageCaption(imageLink?.imageCaption);
    setDisplayOrder(imageLink?.displayOrder);
    setImageType(
      imageLink.imageType === "" || imageLink.imageType === null
        ? ""
        : { label: imageLink.imageType, value: imageLink.imageType },
    );
  }, [imageLink]);

  const handleClose = () => {
    closeModal();
    setImageCaption("");
    setDisplayOrder("");
    setImageType("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!link.includes("http") || !link.includes(".") || !link.toString())
    //   return toaster("error", "Please add a valid link");

    addImageLink(
      imageLink.index,
      imageCaption,
      parseInt(displayOrder),
      imageType.value,
    );
    handleClose();
    toaster("success", "Added successfully");
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={handleClose}
      ></ModalHeader>
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <div className="text-center mb-2">
          <h3>Add Image Info.</h3>
        </div>
        <Row tag="form" className="gy-1 pt-75">
          {/* // ! Image Caption */}
          <Col xs={12}>
            <Label className="form-label" for="link">
              Image Caption
            </Label>

            <Input
              id="image caption"
              value={imageCaption}
              onChange={(e) => setImageCaption(e.target.value)}
              placeholder="image caption"
            />
          </Col>

          {/* // ! Display Order */}
          <Col xs={12}>
            <Label className="form-label" for="link">
              Display Order
            </Label>

            <Input
              id="display order"
              value={displayOrder}
              onChange={(e) => setDisplayOrder(e.target.value)}
              placeholder="display order"
            />
          </Col>

          {/* // ! Image Type */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="staffRole">
                Image Type
              </Label>
              <Select
                isClearable={false}
                id="image type"
                name="react-select"
                value={imageType}
                options={ImageTypeList}
                onChange={(value) => setImageType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col xs={12} className="text-center mt-2">
            <Button onClick={handleSubmit} className="me-1" color="light mr-3">
              Proceed
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              onClick={handleClose}
            >
              Discard
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UrlForm;