//Added by Nitish
import React, { useState } from "react";
import {
  useGetAvgRatingOtaSummary,
  useGetAvgRatingOtaReviews,
} from "../../api/dataSource";
import {
  Table,
  Input,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardTitle,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";

const ORMTargetRatings = () => {
  const [hostelFilter, setHostelFilter] = useState("");
  const [fromDate, setFromDate] = useState(
    dateFormatter(new Date(new Date().setDate(1)))
  );
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const { data: avgSummaryData } = useGetAvgRatingOtaSummary();
  const { data: avgReviewsRangeRating } = useGetAvgRatingOtaReviews(
    null,
    fromDate,
    toDate
  );

  const getRatingColorClass = (rating, benchmark) =>
    rating && rating >= benchmark ? "text-success" : "text-danger";

  const filteredData = avgSummaryData?.getAvgRatingOtaSummary?.filter(
    (item) =>
      (item?.hostelName?.toLowerCase().includes(hostelFilter?.toLowerCase()) ||
        false) &&
      item.hostelStatus
  );

  // added this for bam saligao - hostelworld check as it is NA.
  // const isSpecialHostel = (item) =>
  //   item?._id === "65fbc7febf0c5e9078a75d22" ||
  //   item?.hostelName === "Bam Goa, Saligao";

  const isSpecialHostel = (item) =>
    item?._id === "65fbc7febf0c5e9078a75d22" ||
    item?.hostelName === "Bam Stays, Saligao" ||
    item?._id === "65fbbc71bf0c5e9078a446e5" ||
    item?.hostelName === "Bam Stays, Assagao";

  // here i check the count of all the hostel as per booking source which are below benchmark rating
  const belowBenchmarkCounts = {
    bookingCom: 0,
    hostelWorld: 0,
    goibibo: 0,
    google: 0,
    makeMyTrip: 0,
    tripadvisor: 0,
  };

  filteredData?.forEach((item) => {
    if (
      item?.bookingComAvgRating === null ||
      parseFloat(item?.bookingComAvgRating?.toFixed(1)) < 8.4
    )
      belowBenchmarkCounts.bookingCom++;
    if (
      !isSpecialHostel(item) &&
      (item?.hostelWorldAvgRating === null ||
        parseFloat(item?.hostelWorldAvgRating?.toFixed(1)) < 8.4)
    )
      belowBenchmarkCounts.hostelWorld++;
    if (
      item?.goibiboAvgRating === null ||
      parseFloat(item?.goibiboAvgRating?.toFixed(1)) < 4.2
    )
      belowBenchmarkCounts.goibibo++;
    if (
      item?.googleAvgRating === null ||
      parseFloat(item?.googleAvgRating?.toFixed(1)) < 4.2
    )
      belowBenchmarkCounts.google++;
    if (
      item?.makeMyTripAvgRating === null ||
      parseFloat(item?.makeMyTripAvgRating?.toFixed(1)) < 4.2
    )
      belowBenchmarkCounts.makeMyTrip++;
    if (
      item?.tripadvisorAvgRating === null ||
      parseFloat(item?.tripadvisorAvgRating?.toFixed(1)) < 4.5
    )
      belowBenchmarkCounts.tripadvisor++;
  });

  const handleResetFilters = () => {
    setHostelFilter("");
    setFromDate(null);
    setToDate(null);
  };

  return (
    localStorage.getItem("modules")?.includes("ORM Reports") && (
      <div>
        <Row className="mb-3 justify-content-between align-items-center">
          <Col sm="6">
            <Input
              type="text"
              placeholder="Filter by Hostel Name"
              value={hostelFilter}
              onChange={(e) => setHostelFilter(e.target.value)}
            />
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                Review Date - From
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(date?.[0])}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                Review Date - To
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(date?.[0])}
                id="toDate"
                placeholder="To Date"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <Button color="success" onClick={handleResetFilters}>
              Reset Filters
            </Button>
          </Col>
        </Row>

        <div style={{ overflowX: "auto", overflowY: "auto" }}>
          <div
            style={{
              overflowX: "auto",
              maxHeight: "500px",
              marginBottom: "20px",
            }}
          >
            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <CardTitle>
                  <h2>Brand Ratings</h2>
                </CardTitle>
                <div
                  style={{ fontSize: "0.8rem", display: "flex", gap: "10px" }}
                >
                  <span>
                    <strong>Ratings benchmark:</strong>
                  </span>
                  <span>Booking.com (8.4)</span> |{" "}
                  <span>HostelWorld (8.4)</span> | <span>Goibibo (4.2)</span> |
                  <span>Google (4.2)</span> | <span>MakeMyTrip (4.2)</span> |
                  <span>Tripadvisor (4.5)</span>
                </div>
              </CardHeader>
            </Card>
            <Table striped bordered>
              <thead
                className="text-center"
                style={{ position: "sticky", top: 0, zIndex: 1 }}
              >
                <tr>
                  <th>Hostel Name</th>
                  <th colSpan="2">
                    Booking.com (
                    <font color="red">{belowBenchmarkCounts.bookingCom}</font>)
                  </th>
                  <th colSpan="2">
                    HostelWorld (
                    <font color="red">{belowBenchmarkCounts.hostelWorld}</font>)
                  </th>
                  <th colSpan="2">
                    Goibibo (
                    <font color="red">{belowBenchmarkCounts.goibibo}</font>)
                  </th>
                  <th colSpan="2">
                    Google (
                    <font color="red">{belowBenchmarkCounts.google}</font>)
                  </th>
                  <th colSpan="2">
                    MakeMyTrip (
                    <font color="red">{belowBenchmarkCounts.makeMyTrip}</font>)
                  </th>
                  <th colSpan="2">
                    Tripadvisor (
                    <font color="red">{belowBenchmarkCounts.tripadvisor}</font>)
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th>Live Rating</th>
                  <th>Range Rating</th>
                  <th>Live Rating</th>
                  <th>Range Rating</th>
                  <th>Live Rating</th>
                  <th>Range Rating</th>
                  <th>Live Rating</th>
                  <th>Range Rating</th>
                  <th>Live Rating</th>
                  <th>Range Rating</th>
                  <th>Live Rating</th>
                  <th>Range Rating</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((item, index) => {
                  if (!item.hostelStatus) {
                    return null;
                  }

                  const specialHostel = isSpecialHostel(item);

                  const rangeRatingData =
                    avgReviewsRangeRating?.getAvgRatingOtaReviews?.find(
                      (rangeItem) => rangeItem?._id === item?.hostelName
                    );

                  return (
                    <tr className="text-center" key={index}>
                      <td>{item?.hostelName}</td>
                      <td
                        className={getRatingColorClass(
                          item?.bookingComAvgRating?.toFixed(1),
                          8.4
                        )}
                      >
                        {item?.bookingComAvgRating?.toFixed(1) || "0"}
                      </td>
                      <td>
                        {rangeRatingData
                          ? parseFloat(
                              rangeRatingData?.bookingComAvgRating || 0
                            ).toFixed(1)
                          : "N/A"}
                      </td>
                      {/* Hostel world */}
                      {/* <td
                        className={
                          specialHostel
                            ? "text-danger"
                            : getRatingColorClass(
                                item?.hostelWorldAvgRating?.toFixed(1),
                                8.4
                              )
                        }
                      >
                        {specialHostel
                          ? "NA"
                          : item?.hostelWorldAvgRating?.toFixed(1) || "0"}
                      </td> */}
                      {/* <td className="text-danger">
                        {specialHostel
                          ? "NA"
                          : rangeRatingData
                          ? parseFloat(
                              rangeRatingData?.hostelWorldAvgRating || 0
                            ).toFixed(1)
                          : "N/A"}
                      </td> */}
                      {/* Hostel world */}
                      {/* <td
                        className={getRatingColorClass(
                          item?.hostelWorldAvgRating?.toFixed(1),
                          8.4
                        )}
                      >
                        {item?.hostelWorldAvgRating?.toFixed(1) || "0"}
                      </td>
                      <td>
                        {rangeRatingData
                          ? parseFloat(
                              rangeRatingData?.hostelWorldAvgRating || 0
                            ).toFixed(1)
                          : "N/A"}
                      </td> */}
                      <td
                        className={
                          specialHostel
                            ? "text-danger"
                            : getRatingColorClass(
                                item?.hostelWorldAvgRating?.toFixed(1),
                                8.4
                              )
                        }
                      >
                        {specialHostel
                          ? "NA"
                          : item?.hostelWorldAvgRating?.toFixed(1) || "0"}
                      </td>
                      <td>
                        {specialHostel
                          ? "NA"
                          : rangeRatingData
                          ? parseFloat(
                              rangeRatingData?.hostelWorldAvgRating || 0
                            ).toFixed(1)
                          : "N/A"}
                      </td>

                      {/* goibibo */}
                      <td
                        className={getRatingColorClass(
                          item?.goibiboAvgRating?.toFixed(1),
                          4.2
                        )}
                      >
                        {item?.goibiboAvgRating?.toFixed(1) || "0"}
                      </td>
                      <td>
                        {rangeRatingData
                          ? parseFloat(
                              rangeRatingData?.goibiboAvgRating || 0
                            ).toFixed(1)
                          : "N/A"}
                      </td>
                      {/* google */}
                      <td
                        className={getRatingColorClass(
                          item?.googleAvgRating?.toFixed(1),
                          4.2
                        )}
                      >
                        {item?.googleAvgRating?.toFixed(1) || "0"}
                      </td>
                      <td>
                        {rangeRatingData
                          ? parseFloat(
                              rangeRatingData?.googleAvgRating || 0
                            ).toFixed(1)
                          : "N/A"}
                      </td>
                      {/* makemytrip */}
                      <td
                        className={getRatingColorClass(
                          item?.makeMyTripAvgRating?.toFixed(1),
                          4.2
                        )}
                      >
                        {item?.makeMyTripAvgRating?.toFixed(1) || "0"}
                      </td>
                      <td>
                        {rangeRatingData
                          ? parseFloat(
                              rangeRatingData?.makeMyTripAvgRating || 0
                            ).toFixed(1)
                          : "N/A"}
                      </td>
                      {/* tripadvisor */}
                      <td
                        className={getRatingColorClass(
                          item?.tripadvisorAvgRating?.toFixed(1),
                          4.5
                        )}
                      >
                        {item?.tripadvisorAvgRating?.toFixed(1) || "0"}
                      </td>
                      <td>
                        {rangeRatingData
                          ? parseFloat(
                              rangeRatingData?.tripadvisorAvgRating || 0
                            ).toFixed(1)
                          : "N/A"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    )
  );
};

export default ORMTargetRatings;
