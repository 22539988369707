import React, { useEffect, useState } from "react";
import { Trash } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateWorkationPackage } from "@api";
import { useParams } from "react-router-dom";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";

const OverviewForm = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const [heading, setHeading] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState(false);
  const [highlight, setHighlight] = useState([
    {
      id: uuidv4(),
      heading: "",
      content: "",
      icon: "",
    },
  ]);
  const [updateWorkationPackageHandler, { data: updatedData }] =
    useUpdateWorkationPackage();

  useEffect(() => {
    if (state?.tableData) {
      setName(state?.tableData?.name);
      setStatus(state?.tableData?.status);
      setHeading(state?.tableData?.description?.heading);

      if (state?.tableData?.description?.content) {
        setContent(htmlToDraftConvert(state?.tableData?.description?.content));
      }
    }
  }, [state]);

  useEffect(() => {
    if (updatedData?.updateWorkationPackage?._id) {
      toaster("success", "Submitted successfully");
    }
  }, [updatedData]);

  useEffect(() => {
    if (state?.tableData?.otherInfo?.length) {
      let updatedHighlight = state?.tableData?.otherInfo?.map((item) => {
        return {
          id: uuidv4(),
          heading: item?.heading,
          content: item?.content,
          icon: item?.icon,
        };
      });
      setHighlight(updatedHighlight);
    }
  }, [state]);

  const handleChangeHighlight = (heading, id) => {
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, heading: heading } : item
      )
    );
  };

  const handleChangeHighlightContent = (content, id) => {
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, content: content } : item
      )
    );
  };

  const handleRemoveHighlight = (id) =>
    setHighlight(highlight.filter((item) => item.id !== id));

  const handleAddHighlight = () =>
    setHighlight([
      ...highlight,
      { id: uuidv4(), heading: "", content: "", icon: "" },
    ]);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    let isValid = false;

    highlight.forEach((item) => {
      delete item.id;
    });

    let data = {
      description: {
        heading: heading,
        content: inputDataConvertor(content),
      },
      otherInfo: highlight,
      status,
    };

    if (
      data?.description?.heading &&
      inputDataConvertor(data?.description?.content, "text")
    ) {
      isValid = true;
    }

    highlight?.forEach((data) => {
      if (data?.heading && data?.content) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          description: data?.description,
          otherInfo: data?.otherInfo,
          status: data?.status,
        })
      );
      updateWorkationPackageHandler(id, data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <>
      <form onSubmit={onSubmitHandler}>
        <Row className='mt-2'>
          <Col sm='12' className='mb-2'>
            <SwitchIcon
              checked={status}
              id='status'
              name='status'
              label='Publish on Website ?'
              handleChecked={(val) => setStatus(val)}
            />
          </Col>
          <Col sm='12'>
            <FormGroup>
              <Label className='form-label' for='workationPackage'>
                Workation Name
              </Label>
              <Input
                type='text'
                defaultValue={name}
                id='workationPackage'
                name='workationPackage'
                placeholder='Add Workation Name'
              />
            </FormGroup>
          </Col>

          <Col sm='12'>
            <FormGroup>
              <Label className='form-label' htmlFor='destination'>
                Content
              </Label>
              <div className='mt-1 '>
                <MyEditor
                  editorState={content}
                  setEditorState={(val) => setContent(val)}
                />
              </div>
            </FormGroup>
          </Col>
          <br />
          <h4 className='form-label ml-2 mt-2' htmlFor={"highlight" + id}>
            Why you'll love it here
          </h4>
          {highlight.map(({ heading, content, id, icon }) => (
            <Col className='d-flex align-items-end' sm='12' key={id}>
              <FormGroup className='w-100'>
                <br />
                <div className='mb-2'>
                  <Input
                    type='text'
                    value={heading}
                    id={"name" + id}
                    name={"name" + id}
                    placeholder='Add Title'
                    onChange={(e) => handleChangeHighlight(e.target.value, id)}
                  />
                </div>
                <Input
                  rows={4}
                  type='textarea'
                  value={content}
                  id={"highlight" + id}
                  name={"highlight" + id}
                  placeholder='Add highlight'
                  onChange={(e) =>
                    handleChangeHighlightContent(e.target.value, id)
                  }
                />
              </FormGroup>
              {highlight.length > 1 ? (
                <>
                  <Button.Ripple
                    className='btn-icon rounded-circle ml-1'
                    color='light'
                    type='button'
                    size='sm'
                    onClick={() => handleRemoveHighlight(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHighlight} />
      </form>
    </>
  );
};

export default OverviewForm;
