import React, { useEffect, useState } from "react";
import {
  useCreateMenuCategory,
  useUpdateUnboxMenuCategory,
} from "../../../api/Unbox/Mutations";
import SlideModal from "../../../@core/components/common/slide-modal";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import toaster from "@components/common/Toaster";
const AddEditModal = ({ open, handleToggle, editData, refetch }) => {
  const [categoryName, setCategoryName] = useState("");
  const [showError, setShowError] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [createMenuCategoryHandler, { data: createdMenuCategory }] =
    useCreateMenuCategory();

  const [updateMenuCategoryHandler, { data: updateMenuCategory }] =
    useUpdateUnboxMenuCategory();
  // console.log(errors?.message.then((res) => res));
  useEffect(() => {
    if (
      createdMenuCategory?.createUnboxMenuCategory?.categoryName ||
      updateMenuCategory?.updateUnboxMenuCategory?.categoryName
    ) {
      refetch();
    } else if (
      createdMenuCategory?.createUnboxMenuCategory?.message?.includes(
        "E11000 duplicate key error"
      ) ||
      updateMenuCategory?.updateUnboxMenuCategory?.message?.includes(
        "E11000 duplicate key error"
      )
    ) {
      toaster("error", "Category already present");
    }
  }, [createdMenuCategory?.createUnboxMenuCategory, updateMenuCategory]);

  useEffect(() => {
    if (editData?._id) {
      setCategoryName(editData?.categoryName || "");
      setDisplayOrder(editData?.displayOrder || "");
    } else {
      setCategoryName("");
      setDisplayOrder("");
    }
  }, [editData]);

  const takeInput = (e) => {
    try {
      if (e.target.value === " ") {
        setShowError("No initials space");
      } else {
        setCategoryName(e.target.value);
        setShowError("");
      }
    } catch (error) {
      setShowError("Invalid Input");
    }
  };

  const handleAddDisplayOrder = (val) => {
    if (!isNaN(val) && !val?.includes("-")) {
      setDisplayOrder(val);
    } else {
      toaster("error", "Enter Number Only");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {
      categoryName,
      displayOrder: Number(displayOrder),
    };
    if (data.categoryName.trim() !== "") {
      if (editData?._id) {
        updateMenuCategoryHandler(editData?._id, data);
        toaster("success", "Category updated successfully");
      } else {
        createMenuCategoryHandler(data);
        toaster("success", "Category Submitted successfully");
      }

      setCategoryName("");
      setDisplayOrder("");
      handleToggle();
    } else {
      toaster("error", "Please fill the Category Name");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitHandler}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="categoryName">
                Category Name*
              </Label>
              <Input
                type="text"
                value={categoryName}
                required
                id="categoryName"
                name="categoryName"
                placeholder="Category Name"
                onChange={(e) => takeInput(e)}
              />
              {showError && <p style={{ color: "red" }}>{showError}</p>}
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="displayOrder">
                Display Order
              </Label>
              <Input
                type="text"
                value={displayOrder}
                required
                id=""
                name="displayOrder"
                placeholder="Display Order"
                onChange={(e) => handleAddDisplayOrder(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
