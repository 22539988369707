import { convertToHTML, convertFromHTML } from "draft-convert";
import { EditorState } from "draft-js";
import parse from "html-react-parser";

export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const colourOptions = [
  { value: "ocean", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
];

export const emailValidationHandler = (value) => {
  if (value) {
    let validationCondition =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = validationCondition.test(String(value).toLowerCase());
    return isValid;
  }
  return false;
};

export const dateFormatHandler = (date) => {
  let myDate = new Date(date);

  if (date?.includes("/")) {
    return date;
  }

  let currentDate = myDate.getDate();
  let month = myDate.getMonth() + 1;
  let year = myDate.getFullYear();

  return `${currentDate}/${month}/${year}`;
};

export const fileUpload = (e) => {
  if (!e.target.files[0]) return;
  return e.target.files[0];
};

export const inputDataConvertor = (editorState) =>
  convertToHTML(editorState?.getCurrentContent());

export const htmlToDraftConvert = (value) => {
  value = value
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&quot;/g, '"');

  return EditorState.createWithContent(convertFromHTML(value));
};

export const dateFormatter = (date) => {
  var d = new Date(date);
  var mm =
    d.getMonth() + 1 < 10
      ? "0" + (d.getMonth() + 1).toString()
      : (d.getMonth() + 1).toString();
  var dd =
    d.getDate() < 10 ? "0" + d.getDate().toString() : d.getDate().toString();
  var yy = d.getFullYear().toString();
  var myDateString = yy + "-" + mm + "-" + dd;

  return myDateString;
};

export const dateFormatterDDMMYYYY = (date) => {
  var d = new Date(date);
  var mm =
    d.getMonth() + 1 < 10
      ? "0" + (d.getMonth() + 1).toString()
      : (d.getMonth() + 1).toString();
  var dd =
    d.getDate() < 10 ? "0" + d.getDate().toString() : d.getDate().toString();
  var yy = d.getFullYear().toString();
  var myDateString = dd + "/" + mm + "/" + yy;

  return myDateString;
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

//added by nitish
export const numberToWords = (num) => {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  const convertIntegerToWords = (integerNum) => {
    if (integerNum < 20) {
      return units[integerNum];
    }

    if (integerNum >= 20 && integerNum < 100) {
      const unit = integerNum % 10;
      const ten = Math.floor(integerNum / 10);
      return `${tens[ten]} ${units[unit]}`;
    }

    if (integerNum >= 100 && integerNum < 1000) {
      const hundred = Math.floor(integerNum / 100);
      const remaining = integerNum % 100;
      if (remaining === 0) {
        return `${units[hundred]} hundred`;
      } else {
        return `${units[hundred]} hundred and ${convertIntegerToWords(
          remaining
        )}`;
      }
    }

    return "Number out of range";
  };

  const formatThousands = (num) => {
    if (num < 1000) {
      return convertIntegerToWords(num);
    }

    const thousand = Math.floor(num / 1000);
    const remaining = num % 1000;
    let result = convertIntegerToWords(thousand) + " Thousand";

    if (remaining > 0) {
      result += " " + convertIntegerToWords(remaining);
    }

    return result;
  };

  const integerPart = Math.floor(num);
  const decimalPart = Math.round((num - integerPart) * 100); // Convert to paise

  let integerWords = formatThousands(integerPart);
  let decimalWords = "";

  if (decimalPart > 0) {
    decimalWords = "and " + convertIntegerToWords(decimalPart) + " Paise";
  }

  return `${integerWords} ${decimalWords}`.trim();
};

export const htmlParserChecker = (text) => {
  try {
    if (text) {
      return parse(`<div>${parse(text)}</div>`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const dateValidator = (fromDate, toDate) => {
  try {
    if (fromDate > toDate) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
};

export const indianStateList = [
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
  { label: "Daman and Diu", value: "Daman and Diu" },
  { label: "Delhi", value: "Delhi" },
  { label: "Goa", value: "Goa" },
  { label: "Gujrat", value: "Gujrat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Ladakh", value: "Ladakh" },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Puducherry", value: "Puducherry" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "UttarPradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
];

export const oneToFiftyCountForSelect = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
  { label: "31", value: 31 },
  { label: "32", value: 32 },
  { label: "33", value: 33 },
  { label: "34", value: 34 },
  { label: "35", value: 35 },
  { label: "36", value: 36 },
  { label: "37", value: 37 },
  { label: "38", value: 38 },
  { label: "39", value: 39 },
  { label: "40", value: 40 },
  { label: "41", value: 41 },
  { label: "42", value: 42 },
  { label: "43", value: 43 },
  { label: "44", value: 44 },
  { label: "45", value: 45 },
  { label: "46", value: 46 },
  { label: "47", value: 47 },
  { label: "48", value: 48 },
  { label: "49", value: 49 },
  { label: "50", value: 50 },
];

//addde by Nitish - convert createdAt date with ddmmyyyy format with proper timestamp
export const formatCreatedAtDate = (date) => {
  if (!date) return null;

  let myDate = new Date(date);

  if (date.includes("/")) {
    return date;
  }

  let currentDate = String(myDate.getDate()).padStart(2, "0");
  let month = String(myDate.getMonth() + 1).padStart(2, "0");
  let year = myDate.getFullYear();
  let hours = myDate.getHours();
  let minutes = String(myDate.getMinutes()).padStart(2, "0");
  let seconds = String(myDate.getSeconds()).padStart(2, "0");

  let period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;

  hours = String(hours).padStart(2, "0");

  return `${currentDate}/${month}/${year} ${hours}:${minutes}:${seconds} ${period}`;
};

export const oneToThirtyForSelect = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
];