import gql from "graphql-tag";

export const GET_STEP_OUT_POLICIES_BY_TYPE = gql`
  query getStepOutPoliciesByType($type: String) {
    getStepOutPoliciesByType(type: $type) {
      _id
      type
      title
      image
      guestPolicy {
        header
        description
      }
      privacyPolicy {
        header
        description
      }
      termsAndCondition {
        header
        description
      }
    }
  }
`;
