import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation, useQuery } from "@apollo/client";

export const CREATE_EMPLOYEE_PERFORMANCE = gql`
mutation createEmployeePerformanceEntry($EmployeePerformanceData:EmployeePerformanceData){
    createEmployeePerformanceEntry(EmployeePerformanceData:$EmployeePerformanceData) {
            name
    }
}
`

export const UPDATE_EMPLOYEE_PERFORMANCE = gql`
mutation updateEmployeePerformance($id:ID, $EmployeePerformanceData:EmployeePerformanceData){
    updateEmployeePerformance(id:$id, EmployeePerformanceData:$EmployeePerformanceData){
        name
    }
}
`

export const useCreateEmployeePerformanceEvaluation = () => {
    const [EmployeePerformance, { loading, error, data, refetch }] =
        useMutation(CREATE_EMPLOYEE_PERFORMANCE);

    useLoadingHandler(loading);

    const CreateEmployeePerformanceEvaluationHandler = async (EmployeePerformanceData) => {
        return await EmployeePerformance({
            variables: { EmployeePerformanceData },
        });
    };

    return [CreateEmployeePerformanceEvaluationHandler, { loading, error, data, refetch }];
};

export const useUpdateEmployeePerformance = () => {
    const [employeePerformanceUpdate, { loading, error, data, refetch }] =
        useMutation(UPDATE_EMPLOYEE_PERFORMANCE);

    useLoadingHandler(loading);

    const updateEmployeePerformanceHandler = async (id, EmployeePerformanceData) => {
        return await employeePerformanceUpdate({
            variables: { id, EmployeePerformanceData },
        });
    };

    return [updateEmployeePerformanceHandler, { loading, error, data, refetch }];
};