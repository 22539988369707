import { useEffect, useState } from "react";
import { MoreVertical, Edit, Trash, Edit2 } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import {
  Table,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { dateFormatHandler } from "@utils";

const tableHeaderData = [
  "Name",
  "Status",
  "Promo Code",
  "Product",
  "Discount (%)",
  "Expires on",
  "Action",
];

const StepOutCouponsTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Step Out Coupons</h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Step Out Coupon
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item?.name}</td>
                  <td>{item?.status}</td>
                  <td>{item?.promoCode}</td>
                  <td>{item?.product}</td>
                  <td>{item?.discount}</td>
                  <td>
                    {item?.expirationDate
                      ? dateFormatHandler(item?.expirationDate)
                      : ""}
                  </td>
                  <td>
                    {/* <UncontrolledDropdown>
                      <DropdownToggle
                        className="icon-btn hide-arrow"
                        color="transparent"
                        size="sm"
                        caret
                      >
                        <MoreVertical size={15} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setId(item._id);
                            openModal();
                          }}
                        >
                          <Edit className="mr-50" size={15} />
                          <span className="align-middle">Edit</span>
                        </DropdownItem> */}
                    {/* <DropdownItem
                          href="/"
                          onClick={(e) => handleDelete(e, item)}
                        >
                          <Trash className="mr-50" size={15} />
                          <span className="align-middle">Delete</span>
                        </DropdownItem> */}
                    {/* </DropdownMenu>
                    </UncontrolledDropdown> */}

                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit2 size={15} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default StepOutCouponsTable;
