import React, { useEffect, useState } from "react";
import TableList from "@components/common/TableList";
import { useGetAllCancelConfig } from "@api";
import { TABLE_TYPE } from "../common/TableList/constant";

const tableHeaderList = [
  "Message",
  "Min Day",
  "Max day",
  "Used Count",
  "Deduction",
  "Status",
  "Actions",
];

const CancellationsTable = ({ AddEditModal }) => {
  const [limit, setLimit] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useGetAllCancelConfig(limit, page);

  const handleAdd = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data) {
      let dataList = [];

      data.getAllCancelConfig?.data?.map((item) => {
        dataList.push({
          id: item._id,
          message: item.message,
          minDay: item.minDay,
          maxDay: item.maxDay,
          usedCount: item.usedCount,
          status: item.status,
          deduction: item.deduction,
        });
      });

      setTableData(dataList);
      setTotalPage(Math.ceil(data?.getAllCancelConfig?.count / limit));
    }
  }, [data]);

  useEffect(() => {
    if (editId && data?.getAllCancelConfig?.data?.length) {
      let editData = data?.getAllCancelConfig?.data.find(
        (item) => item._id === editId
      );
      setEditData(editData);
    } else {
      setEditData(null);
    }
  }, [editId, data]);

  const handleEdit = (id) => setEditId(id);

  return (
    <div>
      <TableList
        handleAdd={AddEditModal ? handleAdd : null}
        filterHeader='All Cancellation Rules'
        tableHeader='Cancellation Rules'
        type={TABLE_TYPE.CANCELLATIONS}
        tableData={tableData}
        tableHeaderList={tableHeaderList}
        openModal={() => setOpenModal(!openModal)}
        handleEdit={handleEdit}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={() => setOpenModal(!openModal)}
          refetch={refetch}
          editData={editData}
        />
      )}
    </div>
  );
};

export default CancellationsTable;
