import { Grid, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Unbox Configuration") ////localStorage.getItem("role") === "Super Admin" ?
  ? [
      {
        id: "unbox",
        title: "Unbox",
        icon: <Grid size={20} />,
        badge: "light-warning",
        children: [
          {
            id: "UnboxMenuCategory",
            title: "Menu Category",
            icon: <Circle size={12} />,
            navLink: "/unbox/unboxmenucategory",
          },
          {
            id: "UnboxMasterMenu",
            title: "Master Menu",
            icon: <Circle size={12} />,
            navLink: "/unbox/unboxmastermenu",
          },
          {
            id: "UnboxCafeMaster",
            title: "Cafe Master",
            icon: <Circle size={12} />,
            navLink: "/unbox/unboxcafemaster",
          },
          {
            id: "UnboxCafewiseMenu",
            title: "Cafewise Menu",
            icon: <Circle size={12} />,
            navLink: "/unbox/unboxcafewisemenu",
          },
          {
            id: "Soldout",
            title: "Soldout Items",
            icon: <Circle size={12} />,
            navLink: "/unbox/soldout",
          },
          {
            id: "UnboxBanner",
            title: "Unbox Banner",
            icon: <Circle size={12} />,
            navLink: "/unbox/unboxBanner",
          },
          {
            id: "UnboxCoupon",
            title: "Unbox Coupon",
            icon: <Circle size={12} />,
            navLink: "/unbox/unboxCoupon",
          },
          {
            id: "UnboxOrder",
            title: "Unbox Orders",
            icon: <Circle size={12} />,
            navLink: "/unbox/unboxOrder",
          },
          {
            id: "OrderDashboard",
            title: "Order Dashboard",
            icon: <Circle size={12} />,
            navLink: "/unbox/orderDashboard",
          },
          {
            id: "TimewiseSales",
            title: "Timewise Sales",
            icon: <Circle size={12} />,
            navLink: "/unbox/timewiseSales",
          },
          {
            id: "DaywiseSales",
            title: "Daywise Sales",
            icon: <Circle size={12} />,
            navLink: "/unbox/daywiseSales",
          },
          {
            id: "MonthwiseSales",
            title: "Cafewise Sales",
            icon: <Circle size={12} />,
            navLink: "/unbox/monthwisesales",
          },
          {
            id: "ItemwiseSales",
            title: "Itemwise Sales",
            icon: <Circle size={12} />,
            navLink: "/unbox/itemwisesales",
          },
        ],
      },
    ]
  : modules?.includes("Unbox Reports")
  ? [
      {
        id: "unbox",
        title: "Unbox",
        icon: <Grid size={20} />,
        badge: "light-warning",
        children: [
          {
            id: "OrderDashboard",
            title: "Order Dashboard",
            icon: <Circle size={12} />,
            navLink: "/unbox/orderDashboard",
          },
          {
            id: "UnboxOrder",
            title: "Unbox Orders",
            icon: <Circle size={12} />,
            navLink: "/unbox/unboxOrder",
          },
          {
            id: "Soldout",
            title: "Soldout Items",
            icon: <Circle size={12} />,
            navLink: "/unbox/soldout",
          },
          {
            id: "TimewiseSales",
            title: "Timewise Sales",
            icon: <Circle size={12} />,
            navLink: "/unbox/timewiseSales",
          },
          {
            id: "DaywiseSales",
            title: "Daywise Sales",
            icon: <Circle size={12} />,
            navLink: "/unbox/daywiseSales",
          },
          {
            id: "MonthwiseSales",
            title: "Daywise Sales",
            icon: <Circle size={12} />,
            navLink: "/unbox/monthwisesales",
          },
          {
            id: "ItemwiseSales",
            title: "Itemwise Sales",
            icon: <Circle size={12} />,
            navLink: "/unbox/itemwisesales",
          },
        ],
      },
    ]
  : [];
