// ! Coded by Ashwin
import gql from "graphql-tag";

export const CREATE_STAFF = gql`
  mutation createStaff($staffInput: StaffInput) {
    createStaff(staffInput: $staffInput) {
      hostel
      staffName
      staffEmail
      staffPhoneNumber
      staffRole
      staffPassword
    }
  }
`;

export const UPDATE_STAFF = gql`
  mutation updateStaff($id: ID, $staffUpdate: StaffInput) {
    updateStaff(id: $id, staffUpdate: $staffUpdate) {
      staffName
    }
  }
`;

export const DELETE_STAFF = gql`
  mutation deleteStaff($id: ID!) {
    deleteStaff(id: $id) {
      status
      message
    }
  }
`;