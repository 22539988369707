import AddHostel from "../../views/Cms/Hostel/Tabs";
import AddWorkation from "../../views/Cms/Workation/Tabs";
import AddTrip from "../../views/Cms/Trip/Tabs";
import Hostel from "../../views/Cms/Hostel";
// import Workation from "../../views/Cms/Workation";
import Trip from "../../views/Cms/Trip";
import Landing from "../../views/Cms/Landing";
import Policies from "../../views/Cms/Policies";
import Partner from "../../views/Cms/Partner";
import About from "../../views/Cms/About";
import Destination from "../../views/Cms/Destination"; // ! Added by Ashwin
import AddDestination from "../../views/Cms/Destination/Tabs"; // ! Added by Ashwin
import HomePage from "../../views/Cms/HomePage";
import AddHomePage from "../../views/Cms/HomePage/Tabs"; // ! Added by Ashwin
import WorkationLandingPage from "../../views/Cms/WorkationLandingPage"; // ! Added by Ashwin
import AddWorkationLandingPage from "../../views/Cms/WorkationLandingPage/Tabs"; // ! Added by Ashwin
import WhatsNew from "../../views/Cms/WhatsNew"; // ! Added by Ashwin
import PressMention from "../../views/Cms/PressMention"; // ! Added by Ashwin
import Media from "../../views/Cms/Media"; // ! Added by Ashwin
import Amenities from "../../views/Amenities";
import Offers from "../../views/Offers";
import PartnerNew from "../../views/PartnerNew";
import AddBlog from "../../views/Blogs/Blog/AddBlog";
import DisplayAds from "../../views/Cms/DisplayAds";
import SEO from "../../views/Seo";
// import AddWhatsNew from "../../views/Cms/WhatsNew/Tabs"// ! Added by Ashwin
import AddEventsAndActivities from "../../views/WebApp/EventsAndActivities/AddEventsAndActivities";
import StripConfigIndex from "../../views/Cms/Stripconfig/StripConfigIndex";
import AddHomePageTabs from "../../views/Cms/HomePage/Tabs/index";

const CmsRoutes = [
  {
    path: "/cms/amenities",
    exact: true,
    component: Amenities,
  },
  {
    path: "/cms/offers",
    exact: true,
    component: Offers,
  },
  {
    path: "/cms/partnerNew",
    exact: true,
    component: PartnerNew,
  },
  {
    path: "/cms/landing",
    exact: true,
    component: Landing,
  },
  {
    path: "/cms/homepage", // ! Added by Ashwin
    exact: true,
    component: HomePage,
  },
  {
    path: "/cms/policies",
    exact: true,
    component: Policies,
  },
  {
    path: "/cms/partner",
    exact: true,
    component: Partner,
  },
  {
    path: "/cms/about",
    exact: true,
    component: About,
  },
  {
    path: "/cms/destinations", // ! Added  by Ashwin
    exact: true,
    component: Destination,
  },
  {
    path: "/cms/hostels",
    exact: true,
    component: Hostel,
  },
  {
    path: "/cms/workationLandingPage", // ! Added  by Ashwin
    exact: true,
    component: WorkationLandingPage,
  },
  // {
  //   path: "/cms/workations",
  //   exact: true,
  //   component: Workation,
  // },
  {
    path: "/cms/trips",
    exact: true,
    component: Trip,
  },
  {
    path: "/cms/inTheSpotLight", // !  Added by Ashwin
    exact: true,
    component: WhatsNew,
  },
  {
    path: "/cms/pressMention", // !  Added by Ashwin
    exact: true,
    component: PressMention,
  },
  {
    path: "/cms/media", // !  Added by Ashwin
    exact: true,
    component: Media,
  },
  {
    path: "/cms/edit/trip/:id",
    component: AddTrip,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  },
  {
    path: "/cms/edit/hostel/:id",
    component: AddHostel,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  },
  {
    path: "/cms/edit/workationLandingPage/:id", // ! Added  by Ashwin
    component: AddWorkationLandingPage,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  },
  {
    path: "/cms/edit/homePage/:id", // ! Added by Ashwin
    component: AddHomePage,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  },
  {
    path: "/cms/edit/workation/:id",
    component: AddWorkation,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  },
  {
    path: "/cms/edit/destination/:id", // ! Added by Ashwin
    component: AddDestination,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  },
  {
    path: "/cms/edit/blog/:id", // ! Added by Ashwin
    component: AddBlog,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  },
  {
    path: "/cms/displayAds", // !  Added by Ashwin
    exact: true,
    component: DisplayAds,
  },
  {
    path: "/cms/stripconfig", // !  Added by Ashwin
    exact: true,
    component: StripConfigIndex,
  },
  // {
  //   path: "/cms/edit/whatsNew/:id", // ! Added by Ashwin
  //   component: AddWhatsNew,
  //   exact: true,
  //   meta: {
  //     navLink: "/cms",
  //   },
  // },
  {
    path: "/webApp/edit/events&activities/:id", // ! Added by Ashwin
    component: AddEventsAndActivities,
    exact: true,
    meta: {
      navLink: "/webApp",
    },
  },
  {
    path: "/cms/edit/homePage/:id",
    component: AddHomePageTabs,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  },
  {
    path: "/seo/modules",
    exact: true,
    component: SEO,
  },
];

export default CmsRoutes;
