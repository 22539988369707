import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import ImageSection from "@components/common/ImageSection";
import { useUpdateLandingPageDetails } from "@api";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import { HOSTELLER } from "../../../../utility/constant";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadType = [
  { value: "desktop", label: "Desktop" },
  { value: "mobile", label: "Mobile" },
];

const HeroImage = () => {
  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [updateLandingPageDetailsHandler, { data, reset }] =
    useUpdateLandingPageDetails();
  const dispatch = useDispatch();

  useEffect(() => {
    state?.tableData &&
      state.tableData.heroImage?.length &&
      setImageList(state.tableData.heroImage);
  }, [state?.tableData]);

  useEffect(() => {
    if (data?.updateLandingPage?._id) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          heroImage: imageList,
        })
      );

      reset();
    }
  }, [data?.updateLandingPage?._id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];

    imageList.map((image) => {
      (image.image || image.url) &&
        images.push({
          type: image.type,
          url: image.image || image.url,
          key: image.key,
          link: image.link,
        });
    });

    updateLandingPageDetailsHandler(HOSTELLER, { heroImage: images }); // "hosteller" is used to get landing page doc
  };

  return (
    <div className='mt-2'>
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={imageList}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default HeroImage;
