//Coded by Nitish

import { useState, useEffect } from "react";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { useAllGuestDetailsWithSameNumberOrEmail } from "@api";
import { dateFormatHandler } from "@utils";
import { CSVLink } from "react-csv";

const tableHeaderData = ["Guest Name", "Email", "Mobile"];

const GuestDatabaseTable = ({ data, refetch }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [guestEmail, setGuestEmail] = useState(null);
  const [guestNumber, setGuestNumber] = useState(null);
  const [exportTableData, setExportTableData] = useState([]);

  const tableHeaderExportData = [
    { label: "Hostel Name", key: "hostelName" },
    { label: "Reservation Number", key: "ReservationNo" },
    { label: "Guest Name", key: "GuestName" },
    { label: "Email", key: "Email" },
    { label: "Number", key: "Mobile" },
    { label: "Source", key: "Source" },
    { label: "Checkin Date", key: "ArrivalDate" },
    { label: "Checkout Date", key: "DepartureDate" },
    { label: "Total Exclusive Tax", key: "TotalExclusivTax" },
    { label: "Total Inclusive Tax", key: "TotalInclusiveTax" },
    { label: "Status", key: "Status" },
  ];

  const handleRowClick = (rowData) => {
    setGuestEmail(rowData.Email);
    setGuestNumber(rowData.Mobile);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setGuestEmail(null);
    setGuestNumber(null);
    setModalOpen(false);
  };

  const { data: guestDetailsData } = useAllGuestDetailsWithSameNumberOrEmail(
    guestEmail,
    guestNumber
  );

  useEffect(() => {
    if (guestDetailsData?.getGuestDetailsWithSameNumberOrEmail) {
      setExportTableData(
        guestDetailsData?.getGuestDetailsWithSameNumberOrEmail
      );
    } else {
      setExportTableData([]);
    }
  }, [guestDetailsData]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Centralised Guest Database</CardTitle>
          </CardHeader>
          <Table hover responsive>
            <thead>
              <tr>
                {tableHeaderData.map((header) => (
                  <th key={uuidv4()}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr
                  key={uuidv4()}
                  onClick={() => handleRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{row.GuestName}</td>
                  <td>{row.Email}</td>
                  <td>{row.Mobile}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal isOpen={modalOpen} toggle={handleModalClose} size="xl">
            <ModalHeader toggle={handleModalClose}>
              Guest Details
              <div className="ml-auto">
                <CSVLink
                  headers={tableHeaderExportData}
                  data={exportTableData}
                  filename={"guestdatabase.csv"}
                >
                  <Button.Ripple
                    className="btn-icon export-button"
                    color="light"
                    type="button"
                    size="small"
                  >
                    Export
                  </Button.Ripple>
                </CSVLink>
              </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: "85vh", overflowY: "auto" }}>
              {guestDetailsData &&
              guestDetailsData?.getGuestDetailsWithSameNumberOrEmail?.length >
                0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Hostel Name</th>
                      <th>Reservation Number</th>
                      <th>Guest Name</th>
                      <th>Email</th>
                      <th>Number</th>
                      <th>Source</th>
                      <th>Checkin-out Date</th>
                      <th>Total( Exclusive Tax)</th>
                      <th>Total (Inclusive Tax)</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {guestDetailsData?.getGuestDetailsWithSameNumberOrEmail.map(
                      (guest) => (
                        <tr>
                          <td>{guest.hostelName ? guest.hostelName : "-"}</td>
                          <td>
                            {guest.ReservationNo ? guest.ReservationNo : "-"}
                          </td>
                          <td>{guest.GuestName ? guest.GuestName : ""}</td>
                          <td>{guest.Email ? guest.Email : "-"}</td>
                          <td>{guest.Mobile ? guest.Mobile : "-"}</td>
                          <td>{guest.Source ? guest.Source : "-"}</td>
                          <td>
                            {guest.ArrivalDate
                              ? dateFormatHandler(guest.ArrivalDate)
                              : "-"}{" "}
                            -{" "}
                            {guest.ArrivalDate
                              ? dateFormatHandler(guest.DepartureDate)
                              : "-"}
                          </td>
                          <td>
                            {guest.TotalExclusivTax
                              ? guest.TotalExclusivTax.toFixed(2)
                              : "-"}
                          </td>
                          <td>
                            {guest.TotalInclusiveTax
                              ? guest.TotalInclusiveTax.toFixed(2)
                              : "-"}
                          </td>

                          <td>{guest.Status ? guest.Status : "-"}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="7" style={{ textAlign: "right" }}>
                        <strong>Total:</strong>
                      </td>
                      <td>
                        {guestDetailsData?.getGuestDetailsWithSameNumberOrEmail
                          .reduce(
                            (total, guest) =>
                              guest.TotalExclusivTax
                                ? total + guest.TotalExclusivTax
                                : total,
                            0
                          )
                          .toFixed(2)}
                      </td>
                      <td>
                        {guestDetailsData?.getGuestDetailsWithSameNumberOrEmail
                          .reduce(
                            (total, guest) =>
                              guest.TotalInclusiveTax
                                ? total + guest.TotalInclusiveTax
                                : total,
                            0
                          )
                          .toFixed(2)}
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </Table>
              ) : (
                <p></p>
              )}
            </ModalBody>
          </Modal>
        </Card>
      </Col>
    </Row>
  );
};

export default GuestDatabaseTable;
