export const competitors = [
  { value: "All", label: "All" },
  { value: "GoStops", label: "GoStops" },
  { value: "The Hosteller", label: "The Hosteller" },
  { value: "Zostel", label: "Zostel" },
];

export const platforms = [
  { value: "All", label: "All" },
  { value: "Booking.com", label: "Booking.com" },
  // { value: "MakeMyTrip", label: "MakeMyTrip" },
];

export const occupancies = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
];

export const sortOptions = [
  { value: "asc", label: "Low to High" },
  // { value: "desc", label: "High to Low" },
  { value: "name", label: "Name" },
];
