// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHostel, useUploadImage } from "../../../../api/dataSource";
import { useParams } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailPortraitGenerator from "@uppy/thumbnail-generator";
import thumbnailLandscapeGenerator from "@uppy/thumbnail-generator";

import { DragDrop } from "@uppy/react";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

const NewDetails = () => {
  const { id } = useParams();
  const [imagePortraitCard, setImagePortraitCard] = useState([]);
  const [imageLandscapeCard, setImageLandscapeCard] = useState([]);
  const state = useSelector((state) => state?.cms);

  const [newDetailsState, setNewDetailsState] = useState("");
  const [interest, setInterest] = useState("");
  const [tagline, setTagline] = useState("");
  const [rating, setRating] = useState("");
  const [totalReviews, setTotalReviews] = useState("");
  const [view360DesktopUrl, setView360DesktopUrl] = useState("");
  const [view360MobileUrl, setView360MobileUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [foodOrderUrl, setFoodOrderUrl] = useState("");
  const [gmapUrl, setGmapUrl] = useState("");
  const [shortName, setShortName] = useState("");
  const [gluAnnouncement, setGluAnnouncement] = useState("");

  const dispatch = useDispatch();

  const [portraitUploadImageHandler, { data: portraitImageData }] =
    useUploadImage();

  const [landscapeUploadImageHandler, { data: landscapeImageData }] =
    useUploadImage();

  const uppyPortraitImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });
  const uppyLandscapeImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  const [updateHostelHandler, { data: updatdedHostelData }] = useUpdateHostel();

  useEffect(() => {
    if (state.tableData) {
      setNewDetailsState(state.tableData?.state);
      setInterest(state.tableData?.interest);
      setTagline(state.tableData?.tagline);
      setRating(state.tableData?.rating);
      setTotalReviews(state.tableData?.totalReviews);
      setImagePortraitCard([state.tableData?.imagePortraitCard]);
      setImageLandscapeCard([state.tableData?.imageLandscapeCard]);
      setView360DesktopUrl(state.tableData?.view360DesktopUrl);
      setView360MobileUrl(state.tableData?.view360MobileUrl);
      setVideoUrl(state.tableData?.videoUrl);
      setFoodOrderUrl(state.tableData?.foodOrderUrl);
      setGmapUrl(state.tableData?.gmapUrl);
      setShortName(state.tableData?.shortName);
      setGluAnnouncement(state.tableData?.gluAnnouncement);
    } else {
      setNewDetailsState("");
      setInterest("");
      setTagline("");
      setRating("");
      setTotalReviews("");
      setImagePortraitCard([]);
      setImageLandscapeCard([]);
      setView360DesktopUrl("");
      setView360MobileUrl("");
      setVideoUrl("");
      setFoodOrderUrl("");
      setGmapUrl("");
      setShortName("");
      setGluAnnouncement("");
    }
  }, [state.tableData]);

  uppyPortraitImage.use(thumbnailPortraitGenerator);

  uppyPortraitImage.on("thumbnail:generated", (file, preview) => {
    setImagePortraitCard([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyPortraitImage.removeFile(file.id);
      return;
    }

    portraitUploadImageHandler(file.data);
    toaster("success", "Portrait image uploaded successfully");
  });

  uppyPortraitImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading portrait image: ${error.message}`);
  });

  uppyLandscapeImage.use(thumbnailLandscapeGenerator);

  uppyLandscapeImage.on("thumbnail:generated", (file, preview) => {
    setImageLandscapeCard([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyLandscapeImage.removeFile(file.id);
      return;
    }

    landscapeUploadImageHandler(file.data);
    toaster("success", "Landscape image uploaded successfully");
  });

  uppyLandscapeImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading landscape image: ${error.message}`);
  });

  const PortraitImageRenderPreview = () => {
    if (imagePortraitCard.length) {
      return imagePortraitCard.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };

  const LandscapeImageRenderPreview = () => {
    if (imageLandscapeCard.length) {
      return imageLandscapeCard.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };

  const [portraitImageURL, setPortraitImageURL] = useState(
    state?.tableData?.imagePortraitCard
  );
  const [landscapeImageURL, setLandscapeImageURL] = useState(
    state?.tableData?.imageLandscapeCard
  );

  useEffect(() => {
    if (landscapeImageData) {
      setLandscapeImageURL(landscapeImageData?.uploadImage?.url);
    }
    if (portraitImageData) {
      setPortraitImageURL(portraitImageData?.uploadImage?.url);
    }
  }, [landscapeImageData, portraitImageData]);

  useEffect(() => {
    if (updatdedHostelData) {
      dispatch(
        tableDataHandler({
          imagePortraitCard: portraitImageURL,
          imageLandscapeCard: landscapeImageURL,
          state: newDetailsState,
          tagline,
          interest,
          rating,
          totalReviews,
          view360DesktopUrl,
          view360MobileUrl,
          videoUrl,
          foodOrderUrl,
          gmapUrl,
          shortName,
          gluAnnouncement,
        })
      );
      toaster("success", "Important details updated successfully");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatdedHostelData]);

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      imagePortraitCard: portraitImageURL,
      imageLandscapeCard: landscapeImageURL,
      state: newDetailsState,
      tagline,
      interest,
      rating: parseFloat(rating),
      totalReviews: parseInt(totalReviews),
      view360DesktopUrl,
      view360MobileUrl,
      videoUrl,
      foodOrderUrl,
      gmapUrl,
      shortName,
      gluAnnouncement,
    };
    if (id) {
      updateHostelHandler(id, data);
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <Label className="form-label" htmlFor="location">
            Portrait Image (Dimension - 1080 X 1350)
          </Label>
          <DragDrop
            id={1}
            note="Image size should be less than 500KB"
            uppy={uppyPortraitImage}
          />
          {PortraitImageRenderPreview()}
        </Col>
        <Col sm="6">
          <Label className="form-label" htmlFor="location">
            Landscape Image (Dimension - 1200 X 800)
          </Label>
          <DragDrop
            id={2}
            note="Image size should be less than 500KB"
            uppy={uppyLandscapeImage}
          />
          {LandscapeImageRenderPreview()}
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              State
            </Label>

            <Input
              type="text"
              value={newDetailsState}
              id="newDetailsState"
              name="newDetailsState"
              placeholder="state"
              onChange={(e) => setNewDetailsState(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="interest">
              Interest
            </Label>

            <Input
              type="text"
              value={interest}
              id="interest"
              name="interest"
              placeholder="interest"
              onChange={(e) => setInterest(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="tagline">
              Tagline
            </Label>

            <Input
              type="text"
              value={tagline}
              id="tagline"
              name="tagline"
              placeholder="tagline"
              onChange={(e) => setTagline(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="rating">
              Rating
            </Label>

            <Input
              type="text"
              value={rating}
              id="rating"
              name="rating"
              placeholder="rating"
              onChange={(e) => setRating(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="totalReviews">
              Total Reviews
            </Label>

            <Input
              type="text"
              value={totalReviews}
              id="totalReviews"
              name="totalReviews"
              placeholder="Total Reviews"
              onChange={(e) => setTotalReviews(e.target.value)}
            />
          </FormGroup>
        </Col> */}

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="view360DesktopUrl">
              360° Desktop View URL
            </Label>

            <Input
              type="text"
              value={view360DesktopUrl}
              id="view360DesktopUrl"
              name="view360DesktopUrl"
              placeholder="360° Desktop View URL"
              onChange={(e) => setView360DesktopUrl(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="view360MobileUrl">
              360° Mobile View URL
            </Label>

            <Input
              type="text"
              value={view360MobileUrl}
              id="view360MobileUrl"
              name="view360MobileUrl"
              placeholder="360° Mobile View URL"
              onChange={(e) => setView360MobileUrl(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="videoUrl">
              Video URL
            </Label>

            <Input
              type="text"
              value={videoUrl}
              id="videoUrl"
              name="videoUrl"
              placeholder="Video URL"
              onChange={(e) => setVideoUrl(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="foodOrderUrl">
              Food Order URL
            </Label>

            <Input
              type="text"
              value={foodOrderUrl}
              id="foodOrderUrl"
              name="foodOrderUrl"
              placeholder="Food Order URL"
              onChange={(e) => setFoodOrderUrl(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="gmapUrl">
              Google Maps URL
            </Label>

            <Input
              type="text"
              value={gmapUrl}
              id="gmapUrl"
              name="gmapUrl"
              placeholder="Google Maps URL"
              onChange={(e) => setGmapUrl(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="gmapUrl">
              Short Name
            </Label>

            <Input
              type="text"
              value={shortName}
              id="shortName"
              name="shortName"
              placeholder="Short Name"
              onChange={(e) => setShortName(e.target.value)}
              maxLength={20}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="gluAnnouncement">
              Glu Announcement
            </Label>

            <Input
              type="text"
              value={gluAnnouncement}
              id="gluAnnouncement"
              name="gluAnnouncement"
              placeholder="Glu Announcement"
              onChange={(e) => setGluAnnouncement(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default NewDetails;
