import gql from "graphql-tag";

export const DELETE_STEP_OUT_WALL_OF_LOVE = gql`
mutation delete($id:ID){
    deleteWallOfLoveImage(id:$id){
        status
    }
}
`

export const CREATE_WALL_OF_LOVE_IMAGE = gql`
mutation uploadWallOfLove($ImageData:ImageData){
    uploadWallOfLoveImage(ImageData:$ImageData){
        _id
        ImageName
        ImageUrl
    }
}
`

export const UPDATE_WALL_OF_LOVE_IMAGE = gql`
mutation updateWallofLove($id:ID, $ImageData:ImageData){
    updateWallOfLoveImage(id:$id, ImageData:$ImageData){
        ImageName
    }
}
`