// Added by Nitish
import gql from "graphql-tag";

export const UPDATE_ADDONS_BOOKING = gql`
  mutation updateAddOnsBooking($id: ID, $UpdateAddonInput: UpdateAddonInput) {
    updateAddOnsBooking(id: $id, UpdateAddonInput: $UpdateAddonInput) {
      paymentStatus
      reservationId
      service
      hostel
      price
      tax
      quantity
      status
      remark
      logs
    }
  }
`;

export const GENERATE_SERVICE_EXPORT_DATA = gql`
  mutation generatexlsx($filter: filter) {
    generateServiceExport(filter: $filter)
  }
`;
