import React from "react";
import ReactPaginate from "react-paginate";
import { CardBody } from "reactstrap";

const TablePagination = ({ setPage, page, totalPage }) => {
  return (
    <CardBody className="border-top d-flex justify-content-between align-items-center">
      <ReactPaginate
        onPageChange={(e) => setPage(e.selected + 1)}
        pageCount={totalPage || 1}
        nextLabel={""}
        initialPage={page ? page - 1 : 0}
        breakLabel={"..."}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        activeClassName={"active"}
        pageClassName={"page-item"}
        previousLabel={""}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next-item"}
        previousClassName={"page-item prev-item"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName={"pagination react-paginate mb-0"}
        forcePage={page ? page - 1 : 0}
      />
    </CardBody>
  );
};

export default TablePagination;

// <p className='mb-0 text-muted'>Showing 1 to 7 of 100 entries</p>;
