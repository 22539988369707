// ! Coded by Ashwin

import AdminUsers from "../../views/AdminUser";

const AdminUsersRoutes = [
  {
    path: "/adminUsers",
    exact: true,
    component: AdminUsers,
  },
];

export default AdminUsersRoutes;
