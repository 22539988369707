import { User } from "react-feather";

const modules = JSON.parse(localStorage.getItem("modules"));
const role = localStorage.getItem("role");
const email= localStorage.getItem("email");
export default modules?.includes("Admin Users") //role === "Super Admin"
  ? [
      {
        id: "adminUsers",
        title: "Admin Users",
        icon: <User />,
        navLink: "/adminUsers",
      },
    ]
  : [];
