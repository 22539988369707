import React from "react";
import AddEditModal from "./AddEditModal";
import MessageConfigurationTable from "../../../@core/components/tables/MessageConfigurationTable";

const MessageConfiguration = () =>
  localStorage.getItem("modules")?.includes("Instant Communication") && (
    <MessageConfigurationTable AddEditModal={AddEditModal} />
  );

export default MessageConfiguration;
