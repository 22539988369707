import UnboxCafeMaster from "../../views/Unbox/UnboxCafeMaster";
import UnboxCafewiseMenu from "../../views/Unbox/UnboxCafewiseMenu";
import UnboxMenuCategory from "../../views/Unbox/UnboxMenuCategory";
import UnboxMasterMenu from "../../views/Unbox/UnboxMasterMenu";
import UnboxMasterMenuTabs from "../../views/Unbox/UnboxMasterMenu/UnboxMasterMenuTabs";
import UnboxBanner from "../../views/Unbox/UnboxBanner";
import UnboxCoupon from "../../views/Unbox/UnboxCoupon";
import UnboxOrder from "../../views/Unbox/UnboxOrder";
import OrderDashboard from "../../views/Unbox/OrderDashboard";
import Soldout from "../../views/Unbox/SoldoutSection";
import TimewiseSales from "../../views/Unbox/TimewiseSalesReport";
import DaywiseSales from "../../views/Unbox/DaywiseSalesReport";
import MonthwiseSales from "../../views/Unbox/MonthwiseSalesReport";
import ItemwiseSales from "../../views/Unbox/ItemwiseSalesReport";

const unboxRoutes = [
  {
    path: "/unbox/unboxmastermenu",
    component: UnboxMasterMenu,
    exact: true,
  },
  {
    path: "/unbox/edit/unboxmastermenu/:id",
    component: UnboxMasterMenuTabs,
    exact: true,
    meta: {
      navLink: "/unbox",
    },
  },
  {
    path: "/unbox/unboxmenucategory",
    component: UnboxMenuCategory,
    exact: true,
  },
  {
    path: "/unbox/unboxcafemaster",
    component: UnboxCafeMaster,
    exact: true,
  },
  {
    path: "/unbox/unboxcafewisemenu",
    component: UnboxCafewiseMenu,
    exact: true,
  },
  {
    path: "/unbox/unboxBanner",
    component: UnboxBanner,
    exact: true,
  },
  {
    path: "/unbox/unboxCoupon",
    component: UnboxCoupon,
    exact: true,
  },
  {
    path: "/unbox/unboxOrder",
    component: UnboxOrder,
    exact: true,
  },
  {
    path: "/unbox/orderDashboard",
    component: OrderDashboard,
    exact: true,
  },
  {
    path: "/unbox/soldout",
    component: Soldout,
    exact: true,
  },
  {
    path: "/unbox/timewiseSales",
    component: TimewiseSales,
    exact: true,
  },
  {
    path: "/unbox/daywiseSales",
    component: DaywiseSales,
    exact: true,
  },
  {
    path: "/unbox/monthwisesales",
    component: MonthwiseSales,
    exact: true,
  },
  {
    path: "/unbox/itemwisesales",
    component: ItemwiseSales,
    exact: true,
  },
];

export default unboxRoutes;
