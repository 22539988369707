import Finance from "../../views/Opeartions/Finance/index";
import GOEntry from "../../views/Opeartions/GOPaymentRequest";
import GOPurchase from "../../views/Opeartions/GOPurchaseRequest";
import Vendor from "../../views/Opeartions/Vendor/index";
import HostelwiseClosingBalanceTable from "../../views/Opeartions/HostelwiseClosingBalance";
import AddGoPurchaseTabs from "../../views/Opeartions/GOPurchaseRequest/Tabs";

const OperationRoutes = [
  {
    path: "/operations/finance",
    component: Finance,
    exact: true,
  },
  {
    path: "/operations/closingBalanceReport",
    component: HostelwiseClosingBalanceTable,
    exact: true,
  },
  {
    path: "/operations/vendor",
    component: Vendor,
    exact: true,
  },
  {
    path: "/operations/goentry",
    component: GOEntry,
    exact: true,
  },
  {
    path: "/operations/goPurchase",
    component: GOPurchase,
    exact: true,
  },
  {
    path: "/operations/edit/goPurchase/:id", 
    component: AddGoPurchaseTabs,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  }
];

export default OperationRoutes;
