import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const DELETE_ADMIN_LOGIN_LOGS_BY_ID = gql`
  mutation deleteAdminLoginLogsByID($loginLogsID: ID) {
    deleteAdminLoginLogsByID(loginLogsID: $loginLogsID) {
      status
    }
  }
`;

export const useDeleteAdminLoginLogsByID = () => {
  const [deleteAdminLoginLogsByID, { loading, error, data, refetch }] =
    useMutation(DELETE_ADMIN_LOGIN_LOGS_BY_ID);

  useLoadingHandler(loading);

  const deleteAdminLoginLogsHandler = (loginLogsID) => {
    deleteAdminLoginLogsByID({
      variables: {
        loginLogsID,
      },
    });
  };

  return [deleteAdminLoginLogsHandler, { loading, error, data, refetch }];
};
