import React from 'react'
import { Card, Col, Table } from 'reactstrap';

const tableDataStyle = {
    minWidth: "100%",
    justifyContent: "center",
    alignItems: "center",
};
const TransferTable = (data) => {
    return (
        <Col>
            <Card style={{ height: "200px", overflowY: "auto" }}>
                <Table >
                    <thead>
                        <tr>
                            <th>hostel</th>
                            <th>date</th>
                        </tr>
                    </thead>
                    <tbody >
                        {data?.data?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item?.fromHostel} &#8680; {item?.toHostel}</td>
                                    <td>{new Date(item?.date).toLocaleDateString("en-IN")}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card>
        </Col>

    )
}

export default TransferTable