import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUploadImage, useUpdateAbout } from "@api";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const Career = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState(null);
  const [heading, setHeading] = useState("");
  const [link, setLink] = useState("");

  const [updateAbout, { data, reset }] = useUpdateAbout();

  const [uploadImageHandler, { loading: imageLoad, data: imageData }] =
    useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 1000000,
    },
  });

  useEffect(() => {
    if (state.tableData?.career) {
      setHeading(state.tableData.career.heading);
      setLink(state.tableData.career.link);
      setImagePreview({
        type: "existing",
        url: state.tableData.career.thumbnail,
      });
    }
  }, [state.tableData]);

  useEffect(() => {
    if (imageData?.uploadImage) {
      setImagePreview({ type: "new", url: imageData?.uploadImage?.url });
    }
  }, [imageData?.uploadImage]);

  useEffect(() => {
    if (data?.updateAbout) {
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          career: { heading, link, thumbnail: imagePreview?.url },
        })
      );

      reset();
    }
  }, [data?.updateAbout]);

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setImagePreview({ type: "new", url: preview });
    uploadImageHandler(file.data, `about`);
  });

  const renderPreview = () => {
    return (
      imagePreview?.url && (
        <img
          className='rounded mr-1 mb-2 w-50'
          src={imagePreview?.url}
          alt='avatar'
        />
      )
    );
  };

  const submitDataHandler = (e) => {
    e.preventDefault();

    if (!link?.includes("https://"))
      return toaster("error", "Invalid button link");

    if (heading && link) {
      const career = {
        heading,
        link,
        thumbnail: imagePreview.url,
      };

      updateAbout({ career });
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className='mt-2' onSubmit={submitDataHandler}>
      <Row>
        <Col sm='6' className='mb-2'>
          <Label className='form-label' htmlFor='location'>
            Thumbnail
          </Label>
          <DragDrop
            uppy={uppy}
            width='100%'
            note='Max size 1MB'
            locale={{
              strings: {
                dropHereOr: "Drop image here or %{browse}",
                browse: "browse",
              },
            }}
          />
        </Col>
        <Col sm='6'>
          <Label className='form-label w-100'>Image preview here</Label>
          {imageLoad ? "Uploading image..." : renderPreview()}
        </Col>
        <Col sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='heading'>
              Heading
            </Label>

            <Input
              type='text'
              value={heading}
              id='heading'
              name='heading'
              placeholder='Heading'
              onChange={(e) => setHeading(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' htmlFor='link'>
              Button Link
            </Label>

            <Input
              type='text'
              value={link}
              id='link'
              name='link'
              placeholder='Button link'
              onChange={(e) => setLink(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup loading={imageLoad} />
    </form>
  );
};

export default Career;
