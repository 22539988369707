import "@styles/react/libs/react-select/_react-select.scss";
import { useState } from "react";
import RegisterStepOne from "@components/Auth/Register/RegisterStepOne";
import RegisterStepThree from "@components/Auth/Register/RegisterStepThree";
import RegisterStepTwo from "@components/Auth/Register/RegisterStepTwo";
import { useSkin } from "@hooks/useSkin";
import AuthWrapper from "@components/Auth/common/AuthWrapper";

const Register = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [skin, setSkin] = useSkin();

  const illustration =
      skin === "dark" ? "register-v2-dark.svg" : "register-v2.svg",
    source = require(`@src/assets/images/pages/${illustration}`).default;

  return (
    <AuthWrapper source={source}>
      {activeStep === 1 && <RegisterStepOne />}
      {activeStep === 2 && <RegisterStepTwo />}
      {activeStep === 3 && <RegisterStepThree />}
    </AuthWrapper>
  );
};

export default Register;
