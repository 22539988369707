import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useUpdateStepOutHomePageDetails,
  useUploadImage,
} from "../../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import { v4 as uuidv4 } from "uuid";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Trash, Camera } from "react-feather";
import Avatar from "@components/common/avatar";
import { STEP_OUT_HOMEPAGE } from "../../../../utility/constant";

const KeyFeatures = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [items, setItems] = useState([
    { id: uuidv4(), image: "", title: "", description: "", displayOrder: 0 },
  ]);
  const [index, setIndex] = useState("");

  const [
    updateStepOutHomePageDetailsHandler,
    { data: updateStepOutCategoryData },
  ] = useUpdateStepOutHomePageDetails();

  const [uploadCategoryImageHandler, { data: categoryImageData }] =
    useUploadImage();

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData) {
      setTitle(state?.tableData?.keyFeatures?.title);
      setHighlightedText(state?.tableData?.keyFeatures?.highlightedText);

      setItems(
        state?.tableData?.keyFeatures?.features?.map((item) => {
          return {
            id: uuidv4(),
            image: item?.image,
            title: item?.title,
            description: item?.description,
            displayOrder: item?.displayOrder,
          };
        })
      );
    }
  }, [state]);

  useEffect(() => {
    if (categoryImageData?.uploadImage?.url) {
      setItems(
        items.map((item) => {
          if (item.id === index) {
            return {
              ...item,
              image: categoryImageData?.uploadImage?.url,
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [categoryImageData?.uploadImage?.url]);

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }

    uploadCategoryImageHandler(
      e.target.files[0],
      `${state.tableData?.name?.toLowerCase()?.replaceAll(" ", "-")}` +
        "/STEPOUTHOMEPAGE"
    );
  };

  const handleTextChangeTitle = (val, id, key) =>
    setItems(
      items?.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDescription = (val, id, key) =>
    setItems(
      items?.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDisplayOrder = (val, id, key) =>
    setItems(
      items?.map((item) =>
        item.id === id ? { ...item, [key]: parseInt(val) } : item
      )
    );

  const handleAddStepOutHomepage = () =>
    setItems([
      ...items,
      {
        id: uuidv4(),
        image: "",
        title: "",
        description: "",
        displayOrder: "",
      },
    ]);

  const handleRemoveStepOutHomepage = (id) => {
    // eslint-disable-next-line array-callback-return
    let newStepOutHomepageData = items.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    setItems(newStepOutHomepageData);
  };

  const submitDataHandler = (e) => {
    e.preventDefault();
    let data = {
      keyFeatures: {
        title,
        highlightedText,
        features: items?.map((item) => {
          delete item.id;
          return item;
        }),
      },
    };

    if (state?.tableData?._id) {
      updateStepOutHomePageDetailsHandler(STEP_OUT_HOMEPAGE, data);
      toaster("success", "Key features updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        <Col sm="12">
          <Row>
            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="title">
                  Title
                </Label>

                <Input
                  type="text"
                  value={title}
                  id="title"
                  name="title"
                  placeholder="Add title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="highlightedText">
                  Highlighted text
                </Label>

                <Input
                  type="text"
                  value={highlightedText}
                  id="highlightedText"
                  name="highlightedText"
                  placeholder="Add highlighted text"
                  onChange={(e) => setHighlightedText(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr key={index} style={{ border: "1px solid #ffe700" }} />

      {items?.map(({ id, image, title, description, displayOrder }, index) => (
        <div key={id}>
          <Row className="mt-2">
            <Col sm="12" md="11">
              <FormGroup>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <div className=" flex flex-col align-items-center">
                    <div className="mb-1 mr-2">
                      {
                        <Avatar
                          img={image}
                          alt={image}
                          imgHeight={100}
                          imgWidth={100}
                        />
                      }
                    </div>

                    <label
                      htmlFor={`myFile${id}`}
                      style={{ marginLeft: "28px" }}
                    >
                      <p className="btn btn-sm btn-light">
                        <Camera size={15} />
                      </p>
                      <input
                        name={`myFile${id}`}
                        id={`myFile${id}`}
                        className="d-none"
                        type="file"
                        onChange={(e) => fileUploader(e, id)}
                      />
                    </label>
                  </div>
                  <div style={{ width: "80%" }}>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <FormGroup className="w-100">
                        <Label className="form-label" for="title">
                          Title
                        </Label>

                        <Input
                          rows={4}
                          type="text"
                          value={title}
                          id="title"
                          name="title"
                          placeholder="Title"
                          onChange={(e) =>
                            handleTextChangeTitle(e.target.value, id, "title")
                          }
                        />
                      </FormGroup>

                      <FormGroup className="w-8">
                        <Label className="form-label" for="displayOrder">
                          Display order
                        </Label>

                        <Input
                          rows={4}
                          type="number"
                          value={displayOrder}
                          id="displayOrder"
                          name="displayOrder"
                          placeholder="Display order"
                          onChange={(e) =>
                            handleTextChangeDisplayOrder(
                              e.target.value,
                              id,
                              "displayOrder"
                            )
                          }
                        />
                      </FormGroup>
                    </div>
                    <FormGroup className="w-100">
                      <Label className="form-label" for="description">
                        Description
                      </Label>

                      <Input
                        rows={4}
                        type="text"
                        value={description}
                        id="description"
                        name="description"
                        placeholder="Description"
                        onChange={(e) =>
                          handleTextChangeDescription(
                            e.target.value,
                            id,
                            "description"
                          )
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              </FormGroup>
            </Col>

            <Col sm="12" md="1">
              {items.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle mb-1 mr-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveStepOutHomepage(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          </Row>
          <hr key={index} style={{ border: "1px solid #ffe700" }} />
        </div>
      ))}
      <SaveDiscardBtnGroup addAction={handleAddStepOutHomepage} />
    </form>
  );
};

export default KeyFeatures;
