//Added By Gaurav
import gql from "graphql-tag";

export const GET_SOURCE_WISE_REPORT = gql`
query getSourceWiseBusinessReport($fromDate:String, $toDate: String){
    getSourceWiseBusinessReport(fromDate: $fromDate, toDate:$toDate){
        _id
        totalBookings
        totalSaleAmount
        totalSaleAmountWithTax
      
}
}
`;
