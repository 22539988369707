import { Home, Circle } from "react-feather";

export default [
  {
    id: "cms",
    title: "CMS Cms",
    icon: <Home />,
    navLink: "/Cms",
  },
  {
    id: "hostelLanding",
    title: "CMS Hostel Landing",
    icon: <Home />,
    navLink: "/hostel",
  },
  {
    id: "workationLanding",
    title: "CMS Workation Landing",
    icon: <Home />,
    navLink: "/workation",
  },
  {
    id: "tripLanding",
    title: "CMS Trip Landing",
    icon: <Home />,
    navLink: "/trip",
  },
  {
    id: "inTheSpotLightLanding",
    title: "In The Spot Light Landing",
    icon: <Home />,
    navLink: "/inTheSpotLight",
  },
  {
    id: "pressMentionLanding",
    title: "Press Mention Landing",
    icon: <Home />,
    navLink: "/pressMention",
  },
  {
    id: "displayAdsLanding",
    title: "Display Ads Landing",
    icon: <Home />,
    navLink: "/displayAds",
  },
  {
    id: "mediaLanding",
    title: "Media Landing",
    icon: <Home />,
    navLink: "/media",
  },
  {
    id: "hostelAdd",
    title: "CMS Hostel Add",
    icon: <Home />,
    navLink: "/hostel/add",
  },
  {
    id: "tripAdd",
    title: "CMS Trips Add",
    icon: <Home />,
    navLink: "/trip/add",
  },
  {
    id: "workationAdd",
    title: "CMS Workation Add",
    icon: <Home />,
    navLink: "/workation/add",
  },
  {
    id: "inTheSpotLightAdd",
    title: "In the Spot Light Add",
    icon: <Home />,
    navLink: "/inTheSpotLight/add",
  },
  {
    id: "pressMentionAdd",
    title: "Press Mention Add",
    icon: <Home />,
    navLink: "/pressMentionAdd/add",
  },
  {
    id: "displayAdsAdd",
    title: "Display Ads Add",
    icon: <Home />,
    navLink: "/displayAdsAdd/add",
  },
  {
    id: "mediaAdd",
    title: "Media Add",
    icon: <Home />,
    navLink: "/mediaAdd/add",
  },
];
