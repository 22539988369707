import React, { useEffect, useState } from "react";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import toaster from "@components/common/Toaster";
import { useSeoByType, useUpdateSeo } from "@api";
import { Col, Row, FormGroup, Input, Label, InputGroup } from "reactstrap";
import makeAnimated from "react-select/animated";
import { selectThemeColors } from "@utils";
import Select from "react-select";
const animatedComponents = makeAnimated();

const Seo = () => {
  const [updateSeoHandler, { data: updatedData }] = useUpdateSeo();

  const [seoData, setSeoData] = useState({});
  const [keywords, setKeywords] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  const { data } = useSeoByType("Partner");

  useEffect(() => {
    if (data) {
      data.getAllSeo?.data?.length > 0 &&
        setSeoData({
          name: "Partner",
          ...data.getAllSeo.data[0],
        });
    }
  }, [data]);

  useEffect(() => {
    if (updatedData?.updateSeo) {
      toaster("success", "Submitted successfully");
    }
  }, [updatedData?.updateSeo]);

  useEffect(() => {
    if (seoData) {
      const formattedKeywords = seoData.keywords
        ?.split(", ")
        ?.map((key) => ({ label: key, value: key }));

      formattedKeywords?.length > 0 && setKeywords(formattedKeywords);
    } else setSeoData({});
  }, [seoData]);

  const handleChange = (e) => {
    e.preventDefault();
    setSeoData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const submitDataHandler = (e) => {
    e.preventDefault();

    const { h1Tags, metaDesc, url } = seoData;
    if (h1Tags && metaDesc && url) {
      const sendData = {
        h1Tags,
        metaDesc,
        keywords: keywords.map((v) => " " + v.value).toString(),
        url,
      };

      updateSeoHandler(seoData._id, sendData);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className='mt-2' onSubmit={submitDataHandler}>
      <Row>
        <Col sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' for='h1Tags'>
              H1 Tag
            </Label>
            <Input
              type='text'
              value={seoData.h1Tags || ""}
              id='h1Tags'
              name='h1Tags'
              placeholder='Add H1 Tag'
              onChange={handleChange}
            />
          </FormGroup>
        </Col>

        <Col sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' for='metaDesc'>
              Meta descrition
            </Label>
            <Input
              type='text'
              value={seoData.metaDesc || ""}
              id='metaDesc'
              name='metaDesc'
              placeholder='Add meta description'
              onChange={handleChange}
            />
          </FormGroup>
        </Col>

        <Col sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' for='keywords'>
              Keywords
            </Label>
            <Select
              onChange={(value) => setKeywords(value)}
              onInputChange={(value) =>
                setKeywordList([{ label: value, value: value }])
              }
              isClearable={false}
              theme={selectThemeColors}
              components={animatedComponents}
              value={keywords}
              isMulti
              options={keywordList}
              className='react-select'
              classNamePrefix='select'
            />
          </FormGroup>
        </Col>

        <Col sm='12'>
          <FormGroup className='w-100'>
            <Label className='form-label' for='url'>
              URL Endpoint
            </Label>
            <InputGroup className='mb-2'>
              <Input
                type='text'
                value={seoData.url || ""}
                id='url'
                name='url'
                placeholder='Add endpoint'
                onChange={handleChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Seo;
