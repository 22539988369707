import gql from "graphql-tag";

export const UPDATE_REVIEWS_LINKS = gql`
  mutation updateReviewsLinks(
    $hostelId: ID!
    $reviewsLinksUpdate: ReviewsLinksInput
  ) {
    updateReviewsLinks(
      hostelId: $hostelId
      reviewsLinksUpdate: $reviewsLinksUpdate
    ) {
      reviewsLinks {
        booking
        tripAdvisor
        hostelWorld
        goibibo
        mmt
        google
        bookingAvgRatingLink
        tripAdvisorAvgRatingLink
        hostelWorldAvgRatingLink
        goibiboAvgRatingLink
        mmtAvgRatingLink
        googleAvgRatingLink
        bookingUfiCode
        bookingHotelCode
      }
    }
  }
`;
