import FeedbackResponse from "../../views/FeedbackResponse";

const FeedbackResponseRoutes = [
  {
    path: "/feedbackresponse",
    exact: true,
    component: FeedbackResponse,
  },
  {
    path: "/feedbackresponse/:reservationId",
    component: FeedbackResponse,
    exact: true,
    meta: {
      navLink: "/feedbackresponse",
    },
  },
  
];

export default FeedbackResponseRoutes;
