import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateStepOutProduct } from "../../../api/dataSource";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const ExclusionInfo = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [updateStepOutProductHandler, { data }] = useUpdateStepOutProduct();
  const [exclusionInfo, setExclusionInfo] = useState([
    {
      id: uuidv4(),
      title: "",
      description: "",
      displayOrder: 0,
    },
  ]);

  const exclusionInfoData = state?.tableData?.exclusionInfo;

  useEffect(() => {
    if (exclusionInfoData && exclusionInfoData.length > 0) {
      let upadtedHighlight = exclusionInfoData.map((item) => {
        return {
          id: uuidv4(),
          title: item?.title,
          description: item?.description,
          displayOrder: item?.displayOrder,
        };
      });

      setExclusionInfo(upadtedHighlight);
    }
  }, [exclusionInfoData]);

  useEffect(() => {
    if (data?.updateStepOutProduct) {
      toaster("success", "Exclusions info updated successfully");
    }
  }, [data?.updateStepOutProduct, exclusionInfoData]);

  const handleTextChangeTitle = (val, id, key) =>
    setExclusionInfo(
      exclusionInfo.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );

  const handleTextChangeDescription = (val, id, key) =>
    setExclusionInfo(
      exclusionInfo.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );

  const handleTextChangeDisplayOrder = (val, id, key) =>
    setExclusionInfo(
      exclusionInfo.map((item) =>
        item.id === id ? { ...item, [key]: parseInt(val) } : item
      )
    );

  const handleAddStepOutProduct = () =>
    setExclusionInfo([
      ...exclusionInfo,
      { id: uuidv4(), title: "", description: "", displayOrder: "" },
    ]);

  const handleRemoveStepOutProduct = (id) => {
    // eslint-disable-next-line array-callback-return
    let newStepOutProductData = exclusionInfo.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setExclusionInfo(newStepOutProductData));
  };

  const handleSubmitHandler = () => {
    let isValid = true;
    let StepOutProductClone = JSON.parse(JSON.stringify(exclusionInfo));
    StepOutProductClone.forEach((item) => {
      delete item.id;
    });

    let exclusionInfoData = { exclusionInfo: StepOutProductClone };

    StepOutProductClone?.forEach((item) => {
      isValid &&
        (item?.title && item?.description
          ? (isValid = true)
          : (isValid = false));
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...exclusionInfoData,
        })
      );
      updateStepOutProductHandler(id, exclusionInfoData);
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitHandler();
      }}
    >
      {exclusionInfo.map(({ id, title, description, displayOrder }, index) => (
        <div key={id}>
          <Row className="mt-2">
            <Col sm="12" md="11">
              <FormGroup>
                <FormGroup className="w-100">
                  <Label className="form-label" for="title">
                    Title
                  </Label>

                  <Input
                    type="text"
                    value={title}
                    id="title"
                    name="title"
                    placeholder={`Title`}
                    onChange={(e) =>
                      handleTextChangeTitle(e.target.value, id, "title")
                    }
                  />
                </FormGroup>

                <FormGroup className="w-100">
                  <Label className="form-label" for="description">
                    Description
                  </Label>

                  <Input
                    rows={4}
                    type="textarea"
                    value={description}
                    id="description"
                    name="description"
                    placeholder="Description"
                    onChange={(e) =>
                      handleTextChangeDescription(
                        e.target.value,
                        id,
                        "description"
                      )
                    }
                  />
                </FormGroup>

                <FormGroup className="w-100">
                  <Label className="form-label" for="displayOrder">
                    Display order
                  </Label>

                  <Input
                    rows={4}
                    type="number"
                    value={displayOrder}
                    id="displayOrder"
                    name="displayOrder"
                    placeholder="Display order"
                    onChange={(e) =>
                      handleTextChangeDisplayOrder(
                        e.target.value,
                        id,
                        "displayOrder"
                      )
                    }
                  />
                </FormGroup>
              </FormGroup>
            </Col>

            <Col sm="12" md="1">
              {exclusionInfo.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle mb-1 mr-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveStepOutProduct(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          </Row>
          <hr key={index} style={{ border: "1px solid #ffe700" }} />
        </div>
      ))}

      <SaveDiscardBtnGroup addAction={handleAddStepOutProduct} />
    </form>
  );
};

export default ExclusionInfo;
