import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { useGetNegativeKeyClassification } from "../../api/dataSource";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

const CategoryClassification = () => {
  const history = useHistory();
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [keywordsData, setKeywordsData] = useState([]);
  const [maxCount, setMaxCount] = useState(100); // Default max count value

  const { data, refetch } = useGetNegativeKeyClassification(fromDate, toDate);

  const handleSearch = () => {
    if (dateRange[0] && dateRange[1]) {
      const formattedFromDate = dateRange[0].toISOString().split("T")[0];
      const formattedToDate = dateRange[1].toISOString().split("T")[0];
      setFromDate(formattedFromDate);
      setToDate(formattedToDate);
      refetch();
    }
  };

  const handleReset = () => {
    setDateRange([
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(),
    ]);
    setFromDate("");
    setToDate("");
    refetch();
  };

  useEffect(() => {
    if (data && data.getNegativeKeyClassification) {
      const counts = data.getNegativeKeyClassification.map(
        (item) => item.count
      );
      const max = Math.max(...counts, 100);
      setMaxCount(max);
      setKeywordsData(data.getNegativeKeyClassification);
    }
  }, [data]);

  const handleKeywordClick = (keyword) => {
    history.push(`/orm/reviews?searchKeyword=${keyword}&sentiment=Negative`);
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  };

  const thTdStyle = {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
  };

  const thStyle = {
    ...thTdStyle,
    backgroundColor: "#f2f2f2",
    color: "#333",
    fontWeight: "bold",
  };

  const progressBarStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#e0e0e0",
    borderRadius: "8px",
    overflow: "hidden",
    height: "20px",
  };

  const progressFillStyle = (count) => {
    const percentage = maxCount > 0 ? (count / maxCount) * 100 : 0;
    return {
      backgroundColor: "#D10000",
      height: "100%",
      width: `${percentage}%`,
      transition: "width 0.3s ease-in-out",
    };
  };

  const progressScoreStyle = {
    marginLeft: "10px",
    fontWeight: "bold",
    color: "#333",
  };

  return (
    <div>
      <h2>Negative Keyword Classification</h2>

      <div style={{ marginBottom: "20px" }}>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                Review Date - From
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={dateRange[0]}
                onChange={(date) => setDateRange([date[0], dateRange[1]])}
                id="fromDate"
                placeholder="From Date"
                options={{ dateFormat: "Y-m-d" }}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                Review Date - To
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={dateRange[1]}
                onChange={(date) => setDateRange([dateRange[0], date[0]])}
                id="toDate"
                placeholder="To Date"
                options={{ dateFormat: "Y-m-d" }}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={handleSearch}
            >
              Search
            </Button>
            <Button
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "20px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </div>

      {keywordsData.length > 0 && (
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Keyword</th>
              <th style={thStyle}></th>
              <th style={thStyle}>Mentions</th>
            </tr>
          </thead>
          <tbody>
            {keywordsData.map((item) => (
              <tr key={item.word}>
                <td
                  style={thTdStyle}
                  onClick={() => handleKeywordClick(item.word)}
                >
                  {item.word}
                </td>
                <td style={thTdStyle}>
                  <div style={progressBarStyle}>
                    <div style={progressFillStyle(item.count)} />
                    <span style={progressScoreStyle}>{item.count}</span>
                  </div>
                </td>
                <td style={thTdStyle}>{item.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CategoryClassification;
