import gql from "graphql-tag";

export const GET_PAGINATED_REPLY_TEMPLATE = gql`
  query getPaginatedReplyTemplate($filter: filter) {
    getPaginatedReplyTemplate(filter: $filter) {
      data {
        _id
        templateTitle
        templateContent
        hostels {
          _id
          name
        }
      }
      count
      limit
      page
    }
  }
`;

export const GET_ALL_REPLY_TEMPLATE = gql`
  query getAllReplyTemplate {
    getAllReplyTemplate {
      data {
        _id
        templateTitle
        templateContent
        hostels {
          _id
          name
        }
      }
    }
  }
`;
