import { Fragment, useState, useEffect, useRef } from "react";
import { isObjEmpty } from "@utils";
import classnames from "classnames";

import { Link, useHistory } from "react-router-dom";

import {
  Col,
  CardTitle,
  CardText,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  CustomInput,
} from "reactstrap";
import "@styles/base/pages/page-auth.scss";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import "@styles/react/libs/react-select/_react-select.scss";

const colourOptions = [
  { value: "ocean", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
];

const RegisterStepOne = () => {
  const isFirstRunPhone = useRef(true);
  const isFirstRunPropertyName = useRef(true);
  const isFirstRunEmail = useRef(true);
  const isFirstRunCustomerName = useRef(true);
  const isFirstRunPincode = useRef(true);
  const isFirstRunTerms = useRef(true);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [pincode, setPincode] = useState("");
  const [terms, setTerms] = useState(false);
  const [errors, setErrors] = useState(null);
  const [multipleProperty, setMultipleProperty] = useState(false);
  const [propertyType, setPropertyType] = useState(null);

  useEffect(() => {
    if (isFirstRunPhone.current) {
      isFirstRunPhone.current = false;
      return;
    }
    phone.length !== 10
      ? setErrors({ ...errors, validPhone: true })
      : setErrors({ ...errors, validPhone: false });
  }, [phone]);

  useEffect(() => {
    if (isFirstRunPropertyName.current) {
      isFirstRunPropertyName.current = false;
      return;
    }
    !propertyName
      ? setErrors({ ...errors, validPropertyName: true })
      : setErrors({ ...errors, validPropertyName: false });
  }, [propertyName]);

  useEffect(() => {
    if (isFirstRunPincode.current) {
      isFirstRunPincode.current = false;
      return;
    }
    !pincode
      ? setErrors({ ...errors, validPincode: true })
      : setErrors({ ...errors, validPincode: false });
  }, [pincode]);

  useEffect(() => {
    if (isFirstRunTerms.current) {
      isFirstRunTerms.current = false;
      return;
    }
    !terms
      ? setErrors({ ...errors, validTerms: true })
      : setErrors({ ...errors, validTerms: false });
  }, [terms]);

  useEffect(() => {
    if (isFirstRunCustomerName.current) {
      isFirstRunCustomerName.current = false;
      return;
    }
    !customerName
      ? setErrors({ ...errors, validCustomerName: true })
      : setErrors({ ...errors, validCustomerName: false });
  }, [customerName]);

  useEffect(() => {
    if (isFirstRunEmail.current) {
      isFirstRunEmail.current = false;
      return;
    }
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
      ? setErrors({ ...errors, validEmail: true })
      : setErrors({ ...errors, validEmail: false });
  }, [email]);

  const Terms = () => {
    return (
      <Fragment>
        I agree to
        <a className="ml-25" href="/" onClick={(e) => e.preventDefault()}>
          privacy policy & terms
        </a>
      </Fragment>
    );
  };

  const handleSubmit = (data) => {
    if (isObjEmpty(errors)) {
      alert(1);
    }
  };

  const handleDisable = () =>
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) ||
    !customerName ||
    !terms ||
    !pincode ||
    !propertyName ||
    phone.length !== 10;

  return (
    <Col
      className='d-flex align-items-center auth-bg px-2 p-lg-2'
      lg='4'
      sm='12'
    >
      <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
        <CardTitle tag='h2' className='font-weight-bold mb-1'>
          Welcome to Quoality
        </CardTitle>
        <CardText className='mb-2'>
          Make your app management easy and fun!
        </CardText>

        <Form className='auth-register-form mt-2' onSubmit={handleSubmit}>
          <FormGroup>
            <Label className='form-label' for='register-phone'>
              Phone Number
            </Label>

            <Input
              autoFocus
              type='number'
              value={phone}
              id='register-phone'
              name='register-phone'
              placeholder='1234567890'
              onChange={(e) => setPhone(e.target.value)}
              className={classnames({
                "is-invalid": errors && errors["validPhone"],
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label className='form-label' for='register-email'>
              Email
            </Label>

            <Input
              type='text'
              value={email}
              id='register-email'
              name='register-email'
              placeholder='1234567890'
              onChange={(e) => setEmail(e.target.value)}
              className={classnames({
                "is-invalid": errors && errors["validEmail"],
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label className='form-label' for='register-propertyName'>
              Property Name / Business Name
            </Label>

            <Input
              type='text'
              value={propertyName}
              id='register-propertyName'
              name='register-propertyName'
              placeholder='Crowne Plaza'
              onChange={(e) => setPropertyName(e.target.value)}
              className={classnames({
                "is-invalid": errors && errors["validPropertyName"],
              })}
            />
          </FormGroup>

          <FormGroup>
            <Label className='form-label' for='register-multipleProperty'>
              Do you have Multiple Property ?
            </Label>
            <CustomInput
              type='checkbox'
              id='register-multipleProperty'
              name='register-multipleProperty'
              value={multipleProperty}
              className='custom-control-Primary'
              onChange={(e) => setMultipleProperty(e.target.checked)}
            />
          </FormGroup>

          <FormGroup>
            <Label className='form-label' for='register-customerName'>
              Customer Name
            </Label>

            <Input
              type='text'
              value={customerName}
              id='register-customerName'
              name='register-customerName'
              placeholder='John Doe'
              onChange={(e) => setCustomerName(e.target.value)}
              className={classnames({
                "is-invalid": errors && errors["validCustomerName"],
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for='property-type'>Property Type</Label>
            <Select
              isClearable={false}
              id='property-type'
              name='react-select'
              value={propertyType}
              options={colourOptions}
              onChange={(value) => setPropertyType(value)}
              classNamePrefix='select'
              className={classnames("react-select", {
                "is-invalid": errors && errors["validPropertyType"],
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label className='form-label' for='register-pincode'>
              PIN Code
            </Label>

            <Input
              type='number'
              value={pincode}
              id='register-pincode'
              name='register-pincode'
              placeholder='John Doe'
              onChange={(e) => setPincode(e.target.value)}
              className={classnames({
                "is-invalid": errors && errors["validPincode"],
              })}
            />
          </FormGroup>

          <FormGroup>
            <CustomInput
              type='checkbox'
              id='terms'
              name='terms'
              value={terms}
              label={<Terms />}
              className='custom-control-Primary'
              onChange={(e) => setTerms(e.target.checked)}
              invalid={errors && errors.validTerms && true}
            />
          </FormGroup>

          <Button.Ripple
            disabled={handleDisable()}
            type='submit'
            block
            color='light'
          >
            Sign up
          </Button.Ripple>
        </Form>
        <p className='text-center mt-2'>
          <span className='mr-25'>Already have an account?</span>
          <Link to='/login'>
            <span>Sign in instead</span>
          </Link>
        </p>
      </Col>
    </Col>
  );
};

export default RegisterStepOne;
