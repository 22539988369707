/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import FeedbackTable from "./FeedbackTable";
import AddEditModal from "./AddEditModal";
import { useAllFeedback, useAllFeedbackListForExport } from "@api";
import TablePagination from "@components/common/TablePagination";
import { selectThemeColors } from "@utils";
import { useLocation, useHistory } from "react-router-dom";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useHostelList } from "../../api/dataSource";
import Select from "react-select";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { CSVLink } from "react-csv";

const Feedback = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [exportTableData, setExportTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [feedbackStatus, setFeedbackStatus] = useState(null);
  const [customerFeedback, setCustomerFeedback] = useState(null);
  const [finalStatus, setFinalStatus] = useState(null);
  const [reservationId, setReservationId] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const { data: allHostelsData } = useHostelList();
  const [followupFeedback, setFollowupFeedback] = useState("");
  const [filterFields, setFilterFields] = useState({
    hostelIdArray: hostelsList,
  });

  const { data, refetch } = useAllFeedback({
    limit,
    page,
    filterFields,
  });

  const [guestName, setGuestName] = useState("");

  useEffect(() => {
    const allowedHostelIds = JSON.parse(localStorage.getItem("hostels")) || [];

    if (allHostelsData?.getHostelList?.length) {
      let filteredHostels;

      if (allowedHostelIds.length !== 0) {
        filteredHostels = allHostelsData?.getHostelList?.filter((item) =>
          allowedHostelIds.includes(item?._id)
        );
      } else {
        filteredHostels = allHostelsData?.getHostelList;
      }

      const hostelsList = filteredHostels.map((item) => ({
        label: item?.name,
        value: item?.name,
      }));

      setHostelsList(hostelsList);
      const hostelValues = hostelsList.map((hostel) => hostel.value);

      setFilterFields({
        hostelIdArray: hostelValues,
      });
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const keyword = queryParams.get("searchKeyword");
    const feedback = queryParams.get("customerFeedback");

    if (keyword && feedback) {
      setSearchKeyword(keyword);
      setCustomerFeedback({ label: "Bad", value: "Bad" });
      handleSearch(keyword, "Bad");
    }
  }, [location.search]);

  const handleSearch = (
    keyword = searchKeyword,
    feedback = customerFeedback ? customerFeedback.value : null
  ) => {
    const selectedHostelNames = hostel.length
      ? hostel.map((h) => h.value)
      : hostelsList.map((hostel) => hostel.value);
    const filterFields = {
      hostelIdArray: selectedHostelNames,
      customerFeedback: customerFeedback ? customerFeedback.value : null,
      feedbackStatus: feedbackStatus ? feedbackStatus.value : null,
      finalStatus: finalStatus ? finalStatus.value : null,
      searchKeyword: keyword,
      fromDate,
      toDate,
      guestName,
      followupFeedback: followupFeedback ? followupFeedback.value : null,
      reservationId,
    };
    setPage(1);
    setFilterFields(filterFields);
    // refetch();
  };

  const handleReset = () => {
    const hostelValues = hostelsList.map((hostel) => hostel.value);
    setFilterFields({ hostelIdArray: hostelValues });
    setHostel([]);
    setToDate(null);
    setFromDate(null);
    setSearchKeyword("");
    setFinalStatus("");
    setFeedbackStatus("");
    setCustomerFeedback([]);
    setFollowupFeedback("");
    setReservationId("");
    setGuestName("");
    history.push("/feedback");
  };

  useEffect(() => {
    if (data?.getFeedbackList?.data.length > 0) {
      setTableData(data?.getFeedbackList?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getFeedbackList?.count / limit));
  }, [data?.getFeedbackList?.count, data?.getFeedbackList?.data, limit]);

  useEffect(() => {
    if (data?.getFeedbackList?.data?.length) {
      let editData = data?.getFeedbackList?.data?.find(
        (item) => item._id === id
      );

      if (editData) {
        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getFeedbackList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  //Feedback filter dropdown
  const feedbackDropdownList = [
    { label: "Excellent", value: "Excellent" },
    { label: "Good", value: "Good" },
    { label: "Bad", value: "Bad" },
  ];

  //SM filter dropdown
  const statusDropdownList = [
    { label: "Resolved", value: "Resolved" },
    { label: "Not Resolved", value: "Not Resolved" },
    {
      label: "Cannot connect with guest",
      value: "Cannot connect with guest",
    },
  ];

  //CX filter dropdown
  const cxDropdownList = [
    { label: "Closed", value: "Closed" },
    { label: "Open", value: "Open" },
  ];

  const followupFeedbackDropdown = [
    { label: "Satisfied", value: "Satisfied" },
    { label: "Neutral", value: "Neutral" },
    { label: "Not Satisfied", value: "NotSatisfied" },
  ];

  return (
    localStorage.getItem("modules")?.includes("ORM Reports") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Hostel</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                closeMenuOnSelect={false}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong>Select Feedback</strong>
              </Label>
              <Select
                isClearable={true}
                id="Status"
                name="Status"
                value={customerFeedback}
                options={feedbackDropdownList}
                onChange={(value) => {
                  setCustomerFeedback(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong>SM Status</strong>
              </Label>
              <Select
                isClearable={true}
                id="feedbackStatus"
                name="feedbackStatus"
                value={feedbackStatus}
                options={statusDropdownList}
                onChange={(value) => {
                  setFeedbackStatus(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="followupFeedback">
                <strong>Follow up Feedback</strong>
              </Label>
              <Select
                isClearable={true}
                id="followupFeedback"
                name="followupFeedback"
                value={followupFeedback}
                options={followupFeedbackDropdown}
                onChange={(value) => {
                  setFollowupFeedback(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong>CX Status</strong>
              </Label>
              <Select
                isClearable={true}
                id="finalStatus"
                name="finalStatus"
                value={finalStatus}
                options={cxDropdownList}
                onChange={(value) => {
                  setFinalStatus(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Search Customer Remark</strong>
              </Label>
              <Input
                type="text"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                placeholder="Search customer remarks..."
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                <strong>Guest Name</strong>
              </Label>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Guest Name"
                value={guestName}
                onChange={(e) => {
                  setGuestName(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="reservationId">
                <strong>Reservation ID</strong>
              </Label>
              <Input
                type="text"
                id="reservationId"
                name="reservationId"
                placeholder="Reservation ID"
                value={reservationId}
                onChange={(e) => {
                  setReservationId(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => {
                  setFromDate(dateFormatter(new Date(date)));
                }}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                To Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => {
                  setToDate(dateFormatter(new Date(date)));
                }}
                id="toDate"
              />
            </FormGroup>
          </Col>
          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        <FeedbackTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          refetch={refetch}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      </div>
    )
  );
};

export default Feedback;
