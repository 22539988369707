import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Plus, X, Camera } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateTrip, useUploadImage } from "@api";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import Avatar from "@components/common/avatar";
import toaster from "@components/common/Toaster";

const ReviewsForm = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [updateTripHandler, { data: updatdedTripData }] = useUpdateTrip();
  const [reviews, setReviews] = useState([
    {
      id: uuidv4(),
      userName: "",
      content: "",
    },
  ]);
  const [index, setIndex] = useState("");

  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  useEffect(() => {
    if (state?.tableData?.reviews?.length) {
      let upadtedReviews = state?.tableData?.reviews?.map((item) => {
        return {
          id: uuidv4(),
          userName: item?.userName,
          content: item?.content,
          avatar: item?.avatar,
        };
      });
      setReviews(upadtedReviews);
    }
  }, [state]);

  useEffect(() => {
    if (updatdedTripData?.updateTrip?.name) {
      toaster("success", "Submitted successfully");
    }
  }, [updatdedTripData?.updateTrip?.name]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setReviews(
        reviews.map((item) => {
          if (item.id === index) {
            return { ...item, avatar: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  const handleRemoveReviews = (id) =>
    setReviews(reviews.filter((item) => item.id !== id));

  const handleAddReviews = () =>
    setReviews([...reviews, { id: uuidv4(), userName: "", content: "" }]);

  const handleChangeReviewName = (name, id) => {
    setReviews(
      reviews.map((item) =>
        item.id === id ? { ...item, userName: name } : item
      )
    );
  };

  const handleChangeReviewContent = (content, id) => {
    setReviews(
      reviews.map((item) =>
        item.id === id ? { ...item, content: content } : item
      )
    );
  };

  const submitDatahandler = () => {
    let isValid = false;

    let data = {
      reviews: reviews,
    };

    reviews?.forEach((item) => {
      if (item.userName && item.content && item.avatar) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      reviews?.forEach((item) => delete item.id);

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          reviews: data?.reviews,
        })
      );
      updateTripHandler(id, data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);
    uploadImageHandler(
      e.target.files[0],
      `${state.tableData?.name?.toLowerCase()?.replaceAll(" ", "-")}/HOSTEL}`
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDatahandler();
      }}
    >
      <Row className='mt-2'>
        {reviews.map(({ userName, content, id, avatar }, index) => (
          <Col className='d-flex align-items-center' sm='12' key={id}>
            <FormGroup className='w-100'>
              <Label className='form-label' htmlFor={"reviews" + id}>
                Review {index + 1}
              </Label>

              <div className='mt-2 d-flex align-items-center'>
                <div className='mb-1 mr-2'>
                  {<Avatar img={avatar} alt={avatar} />}
                </div>

                <label htmlFor={`myFile${id}`}>
                  <p className='btn btn-sm btn-light'>
                    <Camera size={15} />
                  </p>
                  <input
                    name={`myFile${id}`}
                    id={`myFile${id}`}
                    className='d-none'
                    type='file'
                    onChange={(e) => fileUploader(e, id)}
                  />
                </label>
              </div>

              <Input
                type='text'
                value={userName}
                id={"reviews" + id}
                name={"Username"}
                placeholder='Username'
                onChange={(e) => handleChangeReviewName(e.target.value, id)}
              />
              <br />
              <Input
                rows={4}
                type='textarea'
                value={content}
                id={"reviews" + id}
                name={"Add content"}
                placeholder='Add Review'
                onChange={(e) => handleChangeReviewContent(e.target.value, id)}
              />
            </FormGroup>
            {reviews.length > 1 ? (
              <>
                <Button.Ripple
                  className='btn-icon rounded-circle ml-1'
                  color='light'
                  type='button'
                  size='sm'
                  onClick={() => handleRemoveReviews(id)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddReviews} />
    </form>
  );
};

export default ReviewsForm;
