import { ChevronLeft } from "react-feather";
import { Link } from "react-router-dom";
import {
  Col,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "@styles/base/pages/page-auth.scss";
import { useEffect, useState } from "react";
import { useForgetPassword } from "../../../../../api/dataSource";
import toaster from "@components/common/Toaster";

const ForgotPasswordStepOne = () => {
  const [forgetPasswordHandler, { data }] = useForgetPassword();
  const [email, setEmail] = useState("");
  // const isFirstRunPhone = useRef(true);
  // const [skin, setSkin] = useSkin();
  // const [phone, setPhone] = useState("");
  // const [errors, setErrors] = useState(null);

  // useEffect(() => {
  //   if (isFirstRunPhone.current) {
  //     isFirstRunPhone.current = false;
  //     return;
  //   }
  //   phone.length !== 10
  //     ? setErrors({ ...errors, validPhone: true })
  //     : setErrors({ ...errors, validPhone: false });
  // }, [phone]);

  const HandleSubmit = (e) => {
    e.preventDefault();
    try {
      forgetPasswordHandler(email);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      if (data && data?.forgetPassword) {
        toaster("success", "Email sent successfully");
      } else {
        toaster("error", "This email is not registered");
      }
    }
  }, [data]);

  return (
    <>
      <Col
        className="d-flex align-items-center auth-bg px-2 p-lg-2"
        lg="4"
        sm="12"
      >
        <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
          <CardTitle tag="h2" className="font-weight-bold mb-1">
            Forgot Password? 🔑
          </CardTitle>
          <CardText className="mb-2">
            Enter your email and we'll send you instructions to reset your
            password.
          </CardText>
          <Form
            className="auth-forgot-password-form mt-2"
            onSubmit={HandleSubmit}
          >
            <FormGroup>
              <Label className="form-label" for="forgot-phone">
                Email
              </Label>

              <Input
                autoFocus
                type="email"
                value={email}
                id="forgot-password"
                name="forgot-password"
                placeholder="Enter your Email"
                onChange={(e) => setEmail(e.target.value)}
                // className={classnames({
                //   "is-invalid": errors && errors["validPhone"],
                // })}
              />
            </FormGroup>
            <Button.Ripple
              // disabled={phone.length !== 10}
              type="submit"
              color="light"
              block
            >
              Send Reset Link
            </Button.Ripple>
          </Form>
          <p className="text-center mt-2">
            <Link to="/login">
              <ChevronLeft className="mr-25" size={14} />
              <span className="align-middle">Back to login</span>
            </Link>
          </p>
        </Col>
      </Col>
    </>
  );
};

export default ForgotPasswordStepOne;
