import gql from "graphql-tag";

export const UPDATE_EZEE_MEALS = gql`
  mutation ($id: ID, $ezeeMealUpdate: EzeeMealInput) {
    updateEzeeMeal(id: $id, ezeeMealUpdate: $ezeeMealUpdate) {
      status
      message
    }
  }
`;
