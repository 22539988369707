import gql from "graphql-tag";

export const GET_FEEDBACK = gql`
  query getFeedbackList($filter: filter) {
    getFeedbackList(filter: $filter) {
      count
      limit
      page
      data {
        _id
        salutation
        reservationId
        firstName
        lastName
        hostelName
        checkinDate
        checkoutDate
        mobile
        email
        customerFeedback
        customerRemark
        feedbackStatus
        propertyRemark
        feedbackTime
        responseTime
        bookingSource
        bookingType
        finalRemark
        logs
        finalStatus
        updatedAt
        ticketEmailSent
        updatedBy
        room
        roomName
        followupFeedback
      }
    }
  }
`;

export const GET_ALL_FEEDBACK_LIST_FOR_EXPORT = gql`
  query getAllFeedbackListForExport($filter: filter) {
    getAllFeedbackListForExport(filter: $filter) {
      data {
        salutation
        reservationId
        firstName
        lastName
        hostelName
        checkinDate
        checkoutDate
        mobile
        email
        customerFeedback
        customerRemark
        feedbackStatus
        propertyRemark
        feedbackTime
        responseTime
        bookingSource
        bookingType
        finalRemark
        logs
        finalStatus
        updatedAt
        ticketEmailSent
        updatedBy
        room
        roomName
      }
    }
  }
`;
