//added by nitish

import React from "react";
import Logo from "./TheHostellerLogo3.png";
import { numberToWords } from "@utils";

function ServicesInvoice({ data }) {
  const containerStyle = {
    margin: "0 auto",
    padding: "10px 14px",
  };

  const logoStyle = {
    width: "1000px",
    height: "1000px",
  };

  const boldTextStyle = {
    fontWeight: "bold",
    fontSize: "10px",
  };

  const listItemStyle = {
    listStyle: "none",
    marginTop: "5px",
  };

  const fontSemiBoldStyle = {
    fontWeight: "600",
  };

  const italicStyle = {
    fontStyle: "italic",
  };

  const footerTextStyle = {
    fontWeight: "600",
    fontSize: "10px",
  };

  const footerSignatureStyle = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "10px",
  };

  return (
    <div style={containerStyle}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginLeft: "40px",
          }}
        >
          <img
            src={Logo}
            alt="Hosteller Logo"
            style={{ logoStyle, width: "auto", height: "30px" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "4px",
          }}
        >
          <p style={{ fontWeight: "bold", fontSize: "12px" }}>Tax Invoice</p>
        </div>
        <ul style={listItemStyle}>
          <li style={boldTextStyle}>
            Legal Entity Name:
            <span style={fontSemiBoldStyle}>{data?.legalEntityName}</span>
          </li>
          <li style={boldTextStyle}>
            Location Name:
            <span style={fontSemiBoldStyle}>{data?.locationName}</span>
          </li>
          <li style={boldTextStyle}>
            Location Address:
            <span style={fontSemiBoldStyle}>
              {`${data?.locationAddress?.addressLine1}, ${data?.locationAddress?.addressLine2}, ${data?.locationAddress?.city}, ${data?.locationAddress?.state}, ${data?.locationAddress?.pincode}`}
            </span>
          </li>
          <li style={boldTextStyle}>
            GSTIN:
            <span style={fontSemiBoldStyle}>{data?.gstin}</span>
          </li>
          <li style={boldTextStyle}>
            Invoice No:
            <span style={fontSemiBoldStyle}>{data?.invoiceNo}</span>
          </li>
          <li style={boldTextStyle}>
            Invoice Date:
            <span style={fontSemiBoldStyle}>{data?.invoiceDate}</span>
          </li>
          <li style={{ ...boldTextStyle, marginTop: "10px" }}>
            Customer Name:
            <span style={fontSemiBoldStyle}>{data?.customerName}</span>
          </li>
          <li style={boldTextStyle}>
            State name & Place of supply:
            <span style={fontSemiBoldStyle}>
              {data?.stateNamePlaceOfSupply}
            </span>
          </li>
          <li style={boldTextStyle}>
            HSN Code:
            <span style={fontSemiBoldStyle}>{data?.hsnCode}</span>
          </li>
          <li style={boldTextStyle}>
            Service Description:
            <span style={fontSemiBoldStyle}>
              Ancillary Service / Composite Sales
            </span>
          </li>
        </ul>
        <div style={{ marginTop: "5px" }}>
          <table style={{ width: "100%" }}>
            <thead>
              <tr style={{ border: "1px solid black" }}>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  Particulars
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  Gross Value
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  Discount
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  Net value
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  CGST(Rate)
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  CGST(INR)
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  SGST(Rate)
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  SGST(INR)
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid black", textAlign: "center" }}>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "10px",
                    padding: "2px",
                  }}
                >
                  {data?.quantity} x {data?.service}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "10px",
                    padding: "2px",
                  }}
                >
                  {/* {Number(data?.price) * Number(data?.quantity)} */}
                  {Number(data?.price) }
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "8px",
                    padding: "2px",
                  }}
                >
                  0
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "10px",
                    padding: "2px",
                  }}
                >
                  {/* {data?.price * data?.quantity} */}
                  {data?.price}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "10px",
                    padding: "2px",
                  }}
                >
                  {(data?.taxRate / 2)?.toFixed(2)}%
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "10px",
                    padding: "2px",
                  }}
                >
                  {/* {((data?.tax * data?.quantity) / 2)?.toFixed(2)} */}
                  {((data?.tax) / 2)?.toFixed(2)}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "10px",
                    padding: "2px",
                  }}
                >
                  {(data?.taxRate / 2).toFixed(2)}%
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "10px",
                    padding: "2px",
                  }}
                >
                  {/* {((data?.tax * data?.quantity) / 2)?.toFixed(2)} */}
                  {((data?.tax) / 2)?.toFixed(2)}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "10px",
                    padding: "2px",
                  }}
                >
                  {data?.totalPrice?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}
        >
          <p style={{ ...boldTextStyle, marginBottom: "2px" }}>
            Amount (in words):{" "}
            <span style={{ fontWeight: "600" }}>
              {numberToWords(data?.totalPrice)}Only settled via digital
              mode/payment received upon delivery against
            </span>{" "}
            <span style={italicStyle}>
              payment reference ID:&nbsp;&nbsp;&nbsp;&nbsp;
              {data?.paymentRefId}
            </span>
          </p>
          <p style={footerTextStyle}>Supply attracts reverse charge: No</p>
        </div>

        <div
          style={{ display: "flex", marginTop: "28px", alignItems: "flex-end" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={boldTextStyle}>
              For The Hosteller Hospitality Private Limited
            </p>
            <p style={footerTextStyle}>PAN: AAFCT2095L</p>
            <p style={footerTextStyle}>CIN: U55101MH2014PTC350259</p>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <p style={footerSignatureStyle}>Authorised Signature</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesInvoice;
