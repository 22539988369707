import React, { useEffect, useState } from "react";
import BookingReportTable from "./BookingReportTable";
import { useGetSalesDataHostelWise } from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { dateFormatHandler , dateFormatter} from "@utils";
import { CSVLink } from "react-csv";
import { dateValidator } from "../../../utility/Utils";
import toaster from "@components/common/Toaster";

const tableHeaderData = [
  { label: "Hostel Name", key: "_id" },
  { label: "Total Bookings", key: "totalBookings" },
  { label: "Total Sale", key: "totalSaleAmount" },
  { label: "Total Sale with tax", key: "totalSaleAmountWithTax" },
];

const BookingReport = () => {
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState(
    dateFormatter(new Date())
  );
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [searchFromDate, setSearchFromDate] = useState(dateFormatter(new Date()));
  const [searchToDate, setSearchToDate] = useState(dateFormatter(new Date()));
  const { data, refetch } = useGetSalesDataHostelWise(
    limit,
    page,
    searchFromDate,
    searchToDate
  );
  const [totalPage, setTotalPage] = useState(1);
  

  const fromDateHandler = (date) => {
    setFromDate(dateFormatter(new Date(date)));
  };

  const toDateHandler = (date) => {
    setToDate(dateFormatter(new Date(date)));
  };

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data?.getSalesDataHostelWise?.data) {
      setTableData(data?.getSalesDataHostelWise?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getSalesDataHostelWise?.count / limit));
  }, [data]);

  const handleSearch = ()=>{
    if(dateValidator(fromDate, toDate)){
      setSearchFromDate(fromDate)
      setSearchToDate(toDate);
    } else {
      toaster("error", "Invalid Dates")
    }
  }

  const handleResetData = ()=>{
    setSearchFromDate(dateFormatter(new Date()));
    setSearchToDate(dateFormatter(new Date()));
    setFromDate(dateFormatter(new Date()))
    setToDate(dateFormatter(new Date()))
  }

  return (
    localStorage.getItem("modules")?.includes("Reports") && <div>
        <Row>
          {/* <Col sm="2">
            <FormGroup className="w-100">
              <CSVLink
                headers={tableHeaderData}
                data={tableData}
                filename={"Bookings_report.csv"}
              >
                <Button.Ripple
                  className="btn-icon ml-1 mt-2"
                  color="light"
                  type="button"
                  size="sm"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            </FormGroup>
          </Col> */}
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={fromDateHandler}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                To Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={toDateHandler}
                id="toDate"
              />
            </FormGroup>
          </Col>
          <Col style={{ marginTop: "22px" , display:"flex"}}>
            <Button.Ripple
              onClick={handleSearch}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft:"10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="primary"
              appearance="danger"
              type="button"
            >
              Search
            </Button.Ripple>
            <Button.Ripple
              onClick={handleResetData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft:"10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button.Ripple>

            <FormGroup>
              <CSVLink
                headers={tableHeaderData}
                data={tableData}
                filename={"Bookings_report.csv"}
              >
                <Button.Ripple
                  style={{
                    backgroundColor: "#ffe700",
                    height: "40px",
                    marginLeft:"10px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="success"
                appearance="success"
                type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            </FormGroup>
          </Col>
        </Row>

        {tableData.length > 0 ? <BookingReportTable data={tableData} />:<h2 style={{textAlign:"center", marginTop:"150px"}}>No Record Found</h2>}

      
    </div>
  );
};

export default BookingReport;
