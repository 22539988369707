import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export default function LogsModal(props) {
  const { showModal, onClose, data, empData } = props;
  const [employee, setEmployee] = useState(null);

  const handleClose = () => {
    try {
      onClose(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data) {
      setEmployee(empData?.find((emp) => emp?._id === data[0]?.empId));
    }
  }, [data, empData]);

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader className="bg-transparent" toggle={handleClose}>
        Employee Name : {employee?.fullName} ({employee?.empCode})
      </ModalHeader>
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <h3>Attendance Logs</h3>
        <ul>
          {data?.map((emp) => {
            return emp?.logs?.map((log, index) => {
              return <li key={index}>{log}</li>;
            });
          })}
        </ul>
        <hr />
        <h3>Profile Logs</h3>
        <ul>
          {empData
            ?.find((emp) => emp?._id === employee?._id)
            ?.logs?.map((log, index) => {
              return <li key={index}>{log}</li>;
            })}
        </ul>
      </ModalBody>
    </Modal>
  );
}
