import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateStepOutProduct } from "../../../api/dataSource";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const OtherInfo = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [updateStepOutProductHandler, { data }] = useUpdateStepOutProduct();
  const [roomType, setRoomType] = useState([
    {
      id: uuidv4(),
      roomName: "",
      description: "",
      occupancy: 0,
      price: 0,
      maxInventory: 0,
    },
  ]);

  const roomTypeData = state?.tableData?.roomType;

  useEffect(() => {
    if (roomTypeData && roomTypeData.length > 0) {
      let upadtedHighlight = roomTypeData.map((item) => {
        return {
          id: uuidv4(),
          roomName: item?.roomName,
          description: item?.description,
          occupancy: item?.occupancy,
          price: item?.price,
          maxInventory: item?.maxInventory,
        };
      });

      setRoomType(upadtedHighlight);
    }
  }, [roomTypeData]);

  useEffect(() => {
    if (data?.updateStepOutProduct) {
      toaster("success", "Room types updated successfully");
    }
  }, [data?.updateStepOutProduct, roomTypeData]);

  const handleTextChangeRoomName = (val, id, key) =>
    setRoomType(
      roomType.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDescription = (val, id, key) =>
    setRoomType(
      roomType.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDisplayOrder = (val, id, key) =>
    setRoomType(
      roomType.map((item) =>
        item.id === id ? { ...item, [key]: parseInt(val) } : item
      )
    );

  const handleTextChangePrice = (val, id, key) =>
    setRoomType(
      roomType.map((item) =>
        item.id === id ? { ...item, [key]: parseInt(val) } : item
      )
    );

  const handleTextChangeMaxInventory = (val, id, key) =>
    setRoomType(
      roomType.map((item) =>
        item.id === id ? { ...item, [key]: parseInt(val) } : item
      )
    );

  const handleAddStepOutProduct = () =>
    setRoomType([
      ...roomType,
      {
        id: uuidv4(),
        roomName: "",
        description: "",
        occupancy: "",
        price: "",
        maxInventory: "",
      },
    ]);

  const handleRemoveStepOutProduct = (id) => {
    // eslint-disable-next-line array-callback-return
    let newStepOutProductData = roomType.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setRoomType(newStepOutProductData));
  };

  const handleSubmitHandler = () => {
    let isValid = true;
    let StepOutProductClone = JSON.parse(JSON.stringify(roomType));
    StepOutProductClone.forEach((item) => {
      delete item.id;
    });

    let roomTypeData = { roomType: StepOutProductClone };

    StepOutProductClone?.forEach((item) => {
      isValid &&
        (item?.roomName && item?.description
          ? (isValid = true)
          : (isValid = false));
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...roomTypeData,
        })
      );
      updateStepOutProductHandler(id, roomTypeData);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitHandler();
      }}
    >
      {roomType.map(
        (
          { id, roomName, description, occupancy, price, maxInventory },
          index
        ) => (
          <div key={id}>
            <Row className="mt-2">
              <Col sm="12" md="11">
                <FormGroup>
                  <FormGroup className="w-100">
                    <Label className="form-label" for="roomName">
                      Room name
                    </Label>

                    <Input
                      type="text"
                      value={roomName}
                      id="roomName"
                      name="roomName"
                      placeholder={`Room name`}
                      onChange={(e) =>
                        handleTextChangeRoomName(e.target.value, id, "roomName")
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="description">
                      Description
                    </Label>

                    <Input
                      rows={4}
                      type="textarea"
                      value={description}
                      id="description"
                      name="description"
                      placeholder="Description"
                      onChange={(e) =>
                        handleTextChangeDescription(
                          e.target.value,
                          id,
                          "description"
                        )
                      }
                    />
                  </FormGroup>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <FormGroup className="w-100">
                      <Label className="form-label" for="occupancy">
                        Occupancy
                      </Label>

                      <Input
                        rows={4}
                        type="number"
                        value={occupancy}
                        id="occupancy"
                        name="occupancy"
                        placeholder="Occupancy"
                        onChange={(e) =>
                          handleTextChangeDisplayOrder(
                            e.target.value,
                            id,
                            "occupancy"
                          )
                        }
                      />
                    </FormGroup>

                    <FormGroup className="w-100">
                      <Label className="form-label" for="maxInventory">
                        Max Inventory
                      </Label>

                      <Input
                        rows={4}
                        type="number"
                        value={maxInventory}
                        id="maxInventory"
                        name="maxInventory"
                        placeholder="Max Inventory"
                        onChange={(e) =>
                          handleTextChangeMaxInventory(
                            e.target.value,
                            id,
                            "maxInventory"
                          )
                        }
                      />
                    </FormGroup>

                    <FormGroup className="w-100">
                      <Label className="form-label" for="price">
                        Price
                      </Label>

                      <Input
                        rows={4}
                        type="number"
                        value={price}
                        id="price"
                        name="price"
                        placeholder="Price"
                        onChange={(e) =>
                          handleTextChangePrice(e.target.value, id, "price")
                        }
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>

              <Col sm="12" md="1">
                {roomType.length > 1 ? (
                  <>
                    <Button.Ripple
                      className="btn-icon rounded-circle mb-1 mr-1"
                      color="light"
                      type="button"
                      size="sm"
                      onClick={() => handleRemoveStepOutProduct(id)}
                    >
                      <Trash size={14} />
                    </Button.Ripple>
                  </>
                ) : null}
              </Col>
            </Row>
            <hr key={index} style={{ border: "1px solid #ffe700" }} />
          </div>
        )
      )}

      <SaveDiscardBtnGroup addAction={handleAddStepOutProduct} />
    </form>
  );
};

export default OtherInfo;
