import gql from "graphql-tag"

export const CREATE_USER = gql`
mutation createUser($body: UserInput) {
    createUser(body: $body) {
        fullName
    }
}
`

export const UPDATE_USER = gql`
mutation updateUser($id: ID, $userUpdate: UserInput){
    updateUser(id: $id, userUpdate: $userUpdate) {
        fullName
    }
}
`