// ! Added by Ashwin

import CouponsGenerator from "../../views/CouponsGenerator"

const CouponsGeneratorRoutes = [
    {
        path: "/couponsGenerator",
        exact: true,
        component: CouponsGenerator
    }
]

export default CouponsGeneratorRoutes