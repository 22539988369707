// ! Added by Ashwin
import gql from "graphql-tag";

export const CREATE_PRESS_MENTION = gql`
mutation createPressMention($pressMentionInput: PressMentionInput){
    createPressMention(pressMentionInput: $pressMentionInput){
        image
        newsImage
        title
        publishedDate
        url
        displayOrder
        status
    }
}
`

export const UPDATE_PRESS_MENTION = gql`
mutation updatePressMention($id: ID, $pressMentionUpdate: PressMentionInput) {
    updatePressMention(id: $id, pressMentionUpdate: $pressMentionUpdate){
        image
    }
}
`