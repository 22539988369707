import { Edit } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "../../../utility/Utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Guest Name",
  // "First Name",
  // "Last Name",
  "Email",
  "Description",
  "Coins",
  "Date",
  "Status",
];

const LoyaltyCoinsTable = ({ openModal, setId, data }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Loyalty Coins</h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Coins
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>{item?.userId?.fullName}</td>
                  {/* <td>{item?.userId?.firstName}</td>
                  <td>{item?.userId?.lastName}</td> */}
                  <td>{item?.userId?.email}</td>
                  <td>{item?.eventName}</td>
                  <td>{item?.coins}</td>
                  <td>{dateFormatHandler(item?.eventDate)}</td>
                  <td>
                    <Badge
                      pill
                      color={item?.isUsable ? "light-success" : "light-danger"}
                      className="mr-1"
                    >
                      {item?.isUsable ? "Active" : "Inactive"}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default LoyaltyCoinsTable;
