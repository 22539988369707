// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Row,
//   Col,
//   FormGroup,
//   Label,
//   Button,
//   Input,
// } from "reactstrap";
// import { useGetServiceEventsSalesReport } from "./../../../api/dataSource";
// import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_green.css";
// import SalesTable from "./SalesTable";

// const IndexPage = () => {
//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);
//   const [hostelFilter, setHostelFilter] = useState("");

//   useEffect(() => {
//     const today = new Date();
//     const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

//     setFromDate(startOfMonth);
//     setToDate(today);
//   }, []);

//   const { data, loading, error, refetch } = useGetServiceEventsSalesReport(
//     fromDate?.toISOString(),
//     toDate?.toISOString()
//   );

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error.message}</p>;

//   const salesData = data?.getServiceEventsSalesReport || [];
//   const filteredData = salesData.filter((item) =>
//     item.hostel.toLowerCase().includes(hostelFilter.toLowerCase())
//   );

//   const handleReset = () => {
//     setFromDate(null);
//     setToDate(null);
//     setHostelFilter("");
//     refetch();
//   };

//   return (
//     <Container style={{ marginTop: "1rem" }}>
//       <Row style={{ marginBottom: "1rem", alignItems: "center" }}>
//         <Col md={4} sm={12}>
//           <FormGroup>
//             <Label for="fromDate">From Date:</Label>
//             <Flatpickr
//               id="fromDate"
//               className="form-control"
//               style={{
//                 backgroundColor: "#fff",
//                 borderColor: "#343a40",
//                 color: "#343a40",
//                 borderRadius: "0.25rem",
//                 padding: "0.375rem 0.75rem",
//                 boxSizing: "border-box",
//               }}
//               value={fromDate}
//               onChange={(date) => setFromDate(date[0])}
//               placeholder="From Date"
//               options={{ dateFormat: "Y-m-d" }}
//             />
//           </FormGroup>
//         </Col>
//         <Col md={4} sm={12}>
//           <FormGroup>
//             <Label for="toDate">To Date:</Label>
//             <Flatpickr
//               id="toDate"
//               className="form-control"
//               style={{
//                 backgroundColor: "#fff",
//                 borderColor: "#343a40",
//                 color: "#343a40",
//                 borderRadius: "0.25rem",
//                 padding: "0.375rem 0.75rem",
//                 boxSizing: "border-box",
//               }}
//               value={toDate}
//               onChange={(date) => setToDate(date[0])}
//               placeholder="To Date"
//               options={{ dateFormat: "Y-m-d" }}
//             />
//           </FormGroup>
//         </Col>
//         <Col md={4} sm={12}>
//           <FormGroup>
//             <Label for="hostelFilter">Hostel Filter:</Label>
//             <Input
//               id="hostelFilter"
//               type="text"
//               value={hostelFilter}
//               onChange={(e) => setHostelFilter(e.target.value)}
//               placeholder="Filter by Hostel Name"
//               style={{ maxWidth: "300px" }}
//             />
//           </FormGroup>
//         </Col>
//         <Col sm={12} className="d-flex">
//           <FormGroup>
//             <Button color="danger" onClick={handleReset}>
//               Reset
//             </Button>
//           </FormGroup>
//         </Col>
//       </Row>
//       <SalesTable data={filteredData} />
//     </Container>
//   );
// };

// export default IndexPage;

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";
import { useGetServiceEventsSalesReport } from "./../../../api/dataSource";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import SalesTable from "./SalesTable";
import { CSVLink } from "react-csv";

const IndexPage = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [hostelFilter, setHostelFilter] = useState("");

  useEffect(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    setFromDate(startOfMonth);
    setToDate(today);
  }, []);

  const { data, loading, error, refetch } = useGetServiceEventsSalesReport(
    fromDate?.toISOString(),
    toDate?.toISOString()
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const salesData = data?.getServiceEventsSalesReport || [];
  const filteredData = salesData.filter((item) =>
    item.hostel.toLowerCase().includes(hostelFilter.toLowerCase())
  );

  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setHostelFilter("");
    refetch();
  };

  const csvHeaders = [
    { label: "Hostel", key: "hostel" },
    { label: "Service Count", key: "serviceCount" },
    { label: "Service Revenue", key: "serviceRev" },
    { label: "E&A Count", key: "EACount" },
    { label: "E&A Revenue", key: "EARev" },
    { label: "Total Count", key: "totalCount" },
    { label: "Total Revenue", key: "totalRev" },
    { label: "Net Revenue", key: "netRev" },
    { label: "AOV", key: "aov" },
  ];

  const csvData = filteredData.map((item) => ({
    hostel: item.hostel,
    serviceCount: item.serviceCount,
    serviceRev: item.serviceRev,
    EACount: item.EACount,
    EARev: item.EARev,
    totalCount: item.totalCount,
    totalRev: item.totalRev,
    netRev: item.netRev,
    aov: item.aov,
  }));

  return (
    <Container style={{ marginTop: "1rem" }}>
      <Row style={{ marginBottom: "1rem", alignItems: "center" }}>
        <Col md={4} sm={12}>
          <FormGroup>
            <Label for="fromDate">From Date:</Label>
            <Flatpickr
              id="fromDate"
              className="form-control"
              style={{
                backgroundColor: "#fff",
                borderColor: "#343a40",
                color: "#343a40",
                borderRadius: "0.25rem",
                padding: "0.375rem 0.75rem",
                boxSizing: "border-box",
              }}
              value={fromDate}
              onChange={(date) => setFromDate(date[0])}
              placeholder="From Date"
              options={{ dateFormat: "Y-m-d" }}
            />
          </FormGroup>
        </Col>
        <Col md={4} sm={12}>
          <FormGroup>
            <Label for="toDate">To Date:</Label>
            <Flatpickr
              id="toDate"
              className="form-control"
              style={{
                backgroundColor: "#fff",
                borderColor: "#343a40",
                color: "#343a40",
                borderRadius: "0.25rem",
                padding: "0.375rem 0.75rem",
                boxSizing: "border-box",
              }}
              value={toDate}
              onChange={(date) => setToDate(date[0])}
              placeholder="To Date"
              options={{ dateFormat: "Y-m-d" }}
            />
          </FormGroup>
        </Col>
        <Col md={4} sm={12}>
          <FormGroup>
            <Label for="hostelFilter">Hostel Filter:</Label>
            <Input
              id="hostelFilter"
              type="text"
              value={hostelFilter}
              onChange={(e) => setHostelFilter(e.target.value)}
              placeholder="Filter by Hostel Name"
              style={{ maxWidth: "300px" }}
            />
          </FormGroup>
        </Col>
        <Col sm={12} className="d-flex">
          <FormGroup>
            <Button
              color="danger"
              onClick={handleReset}
              style={{ marginRight: "1rem" }}
            >
              Reset
            </Button>
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={`service_events_sales_report.csv`}
            >
              <Button color="success">Export</Button>
            </CSVLink>
          </FormGroup>
        </Col>
      </Row>
      <SalesTable data={filteredData} />
    </Container>
  );
};

export default IndexPage;
