import { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import toaster from "@components/common/Toaster";

const UrlForm = ({ showModal, closeModal, imageLink, addImageLink }) => {
  const [link, setLink] = useState("");

  //check valid url

  useEffect(() => {
    setLink(imageLink.link);
  }, [imageLink.link]);

  const handleClose = () => {
    closeModal();
    setLink("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!link.includes("http") || !link.includes("."))
      return toaster("error", "Please add a valid link");

    addImageLink(imageLink.index, link);
    handleClose();
    toaster("success", "⚠️ Link added, please save now");
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className='modal-dialog-centered modal-lg'
    >
      <ModalHeader
        className='bg-transparent'
        toggle={handleClose}
      ></ModalHeader>
      <ModalBody className='px-sm-5 mx-50 pb-5'>
        <div className='text-center mb-2'>
          <h3>Add image redirect link (optional)</h3>
        </div>
        <Row tag='form' className='gy-1 pt-75'>
          <Col xs={12}>
            <Label className='form-label' for='link'>
              Redirect Link
            </Label>

            <Input
              id='link'
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder='redirect link'
            />
          </Col>

          <Col xs={12} className='text-center mt-2'>
            <Button onClick={handleSubmit} className='me-1' color='light mr-3'>
              Proceed
            </Button>
            <Button
              type='reset'
              color='secondary'
              outline
              onClick={handleClose}
            >
              Discard
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UrlForm;
