import gql from "graphql-tag";

export const GET_STRIP_CONFIG = gql`
  query {
    getStripConfigData {
      _id
      title
      url
      stripType
      buttonTitle
      stripCategory
      hostels {
        _id
        name
      }
      destinations {
        _id
        destination
      }
    }
  }
`;
