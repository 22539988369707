import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import {
  convertDateStringToDetailedFormat,
  formatDateToYYYYMMDD,
  getDatesInRange,
} from "./helpers";
import {
  useGetBookingCalender,
  useGetGuestDatabase,
  useGetHostelsNameandId,
  useGetRoomTypeByHostelId,
} from "../../api/dataSource";
import FilterSection from "./FilterSection";
import Table from "./Table";
import toaster from "@components/common/Toaster";
import AppLoader from "./Loader";
import { Card, Col, Row } from "reactstrap";
import { generateFormattedDates } from "../CompetitorRates/helpers";

function BookingDashboard() {
  const default7days = generateFormattedDates(new Date(), 7);
  const regextoIdentifyStartDateWithoutInteractivity =
    /^(Sun|Mon|Tue|Wed|Thu|Fri|Sat),\s(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{1,2}$/;

  const [dateRanges, setDateRanges] = useState(default7days || []);
  const [dateRange, setDateRange] = useState({
    startDate: dateRanges[0],
    endDate: dateRanges[dateRanges.length - 1], //last date
  });

  const [hostel, setHostel] = useState();
  const [hostelsList, setHostelsList] = useState([]);
  const [roomNamesList, setRoomNamesList] = useState([]);

  const [isSearchResultComponentOpen, setIsSearchResultComponentOpen] =
    useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [defferedSearchKeyword, setDefferedSearchKeyword] = useState("");
  const { data: allGuestData, refetch: refetchAllGuest , loading : guestLoading } = useGetGuestDatabase(
    defferedSearchKeyword,
    hostelsList.length > 1 ? hostel?.value : hostelsList[0]?.value
  );

  const searchedGuestDataResults = allGuestData?.getGuestDatabase;
  // const searchedGuestDataResults = sampleDataForSearch;

  // useEffect(() => {
  //   if (data?.getGuestDatabase && searchKeyword !== "") {
  //     setTableData(data?.getGuestDatabase);
  //   } else {
  //     setTableData([]);
  //   }
  // }, [data, searchKeyword]);

  const handleClickOnSearch = () => {
    setIsSearchResultComponentOpen(true);
    setDefferedSearchKeyword(searchKeyword);
  };

  const cellRef = useRef(null);
  const roomNameRef = useRef(null);
  const tableContainerRef = useRef(null);

  const [widthOfEachCell, setWidthOfEachCell] = useState(120);
  const [totalWidthOfTableContainer, setTotalWidthOfTableContainer] =
    useState(0);

  const { data: allHostelsData } = useGetHostelsNameandId();
  const { data } = useGetRoomTypeByHostelId(hostel?.value);
  const roomNumbers = data?.get_room_types;
  const { data: _bookingsData, loading: bookingLoading } =
    useGetBookingCalender(
      hostelsList.length > 1 ? hostel?.value : hostelsList[0]?.value,
      formatDateToYYYYMMDD(dateRange.startDate ?? default7days[0]),
      formatDateToYYYYMMDD(
        dateRange.endDate ?? default7days[default7days.length - 1]
      )
    );
  const bookingData = _bookingsData?.get_booking_calendar || [];
  // const bookingData = sampleData;
  // console.log(bookingData);
  // console.log(hostelsList);
  // console.log(hostel);
  // console.log(roomNumbers)
  useEffect(() => {
    if (regextoIdentifyStartDateWithoutInteractivity.test(dateRange.startDate))
      return;
    if (dateRange.startDate && dateRange.endDate) {
      const dates = getDatesInRange(
        new Date(dateRange?.startDate),
        new Date(dateRange?.endDate)
      );
      // console.log(dates);
      setDateRanges(dates);
    }
  }, [dateRange]);

  useEffect(() => {
    if (tableContainerRef.current && roomNameRef.current && cellRef.current) {
      setTotalWidthOfTableContainer(
        // tableContainerRef.current.offsetWidth - roomNameRef.current.offsetWidth  --> this is not working
        cellRef.current.offsetWidth * dateRanges?.length
        // roomNameRef.current.offsetWidth
      );
      setWidthOfEachCell(cellRef.current.offsetWidth);
    }

    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelsNameandId?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelsNameandId?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelsNameandId;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);

      //fetch the default hostels if there is only one hostel asigned .
      if (HostelsList.length === 1) {
        setHostel(HostelsList[0]);
      }

      let roomNameList = roomNumbers?.map((item) => {
        return { label: item?.Name, value: item?.ID };
      });
      setRoomNamesList(roomNameList);
    }
  }, [dateRange, allHostelsData?.getHostelsNameandId, roomNumbers]);

  useEffect(() => {
    //problem is here cause the useEffect is getting exected but the tableContainerRef.current.offsetWidth is not changing
    if (tableContainerRef.current && roomNameRef.current && cellRef.current) {
      setTotalWidthOfTableContainer(
        // tableContainerRef.current.offsetWidth - roomNameRef.current.offsetWidth  --> this is not working
        cellRef.current.offsetWidth * dateRanges?.length
        // roomNameRef.current.offsetWidth
      );
      setWidthOfEachCell(cellRef.current.offsetWidth);
    }
  }, [dateRanges, _bookingsData]);
  /*
  cauttion : dont play with the dependencies
 */

  const handleDateRangeChange = (selectedDates) => {
    if (!selectedDates[0] || !selectedDates[1]) {
      return;
    }

    const startDate = selectedDates[0];
    let endDate = selectedDates[1];

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round((endDate - startDate) / oneDay);

    // Ensure endDate is at least 7 days after startDate
    if (diffDays < 7) {
      toaster("error", "At least 7 or more days should be selected");
      //revert back to what was previous as we cant controll the layout with the values in flat-picker
      const dates = getDatesInRange(
        new Date(dateRange.startDate),
        new Date(dateRange.endDate)
      );
      setDateRanges(dates);
      return;
      // endDate = new Date(startDate.getTime() + 7 * oneDay);
    }

    const formattedStartDate = convertDateStringToDetailedFormat(startDate);
    const formattedEndDate = convertDateStringToDetailedFormat(endDate);

    setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate });
  };

  if (bookingLoading) return <AppLoader />;

  return (
    <>
      <Row>
        <Col>
          <FilterSection
            hostel={hostel}
            setHostel={setHostel}
            hostelsList={hostelsList}
            roomNamesList={roomNamesList}
            handleDateRangeChange={handleDateRangeChange}
            dateRange={dateRange}
            searchkeyword={searchKeyword}
            setSearchkeyword={setSearchKeyword}
            handleClickOnSearch={handleClickOnSearch}
            searchResult={searchedGuestDataResults}
            searchKeyword={searchKeyword}
            isSearchResultComponentOpen={isSearchResultComponentOpen && !guestLoading}
            setIsSearchResultComponentOpen={setIsSearchResultComponentOpen}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card className="h-100">
            {/* <CardHeader>
              <CardTitle>
                <div>
                  <h3>Booking Dadhboard</h3>
                </div>
              </CardTitle>
            </CardHeader> */}
            {hostel ? (
              <Table
                dateRanges={dateRanges}
                tableContainerRef={tableContainerRef}
                cellRef={cellRef}
                roomNameRef={roomNameRef}
                bookingData={bookingData}
                roomNamesList={roomNamesList}
                roomNumbers={roomNumbers}
                totalWidthOfTableContainer={totalWidthOfTableContainer}
                widthOfEachCell={widthOfEachCell}
                hostel={hostel}
              />
            ) : (
              <span style={{ marginTop: "20px", marginLeft: "20px" }}>
                Please select Hostel
              </span>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default BookingDashboard;
