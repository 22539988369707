import { Edit, FileText, Link } from "react-feather";
import { Badge, Button } from "reactstrap";
import SwitchIcon from "@components/common/switch-icon";
import { useUpdateEventsAndActivities } from "../../../../../../api/dataSource";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";

const WebAppEventsAndActivitiesTableBody = ({
  tableRowData,
  openModal,
  handleEdit,
  handleCreateUpdateEventsAndActivities,
  refetch,
}) => {
  const [
    updateEventsAndActivitiesHandler,
    { data: updateEventsAndActivitiesData },
  ] = useUpdateEventsAndActivities();

  const createObject = (value, id, e) => {
    console.log(value, id);
    const data = {
      published: value,
    };
    if (id) {
      updateEventsAndActivitiesHandler(id, data);
      refetch();
    }
  };

  return (
    <>
      {tableRowData?.map(
        ({
          id,
          title,
          hostel,
          hostelID,
          category,
          price,
          frequency,
          published,
          eventDate,
          eventStartDate,
          eventEndDate,
        }) => (
          <tr key={id}>
            <td style={{ width: "10px" }}>
              <Button
                className="rounded-circle btn-icon"
                color="none"
                title="Edit"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                  handleEdit(id);
                }}
              >
                <Edit
                  color={reactFeatherIcon.iconColor}
                  size={reactFeatherIcon.iconSize}
                />
              </Button>
            </td>

            <td>
              <Button
                className="rounded-circle btn-icon"
                color="none"
                title="Overview"
                onClick={(e) => {
                  e.preventDefault();
                  handleCreateUpdateEventsAndActivities(id);
                }}
              >
                <FileText
                  color={reactFeatherIcon.iconColor}
                  size={reactFeatherIcon.iconSize}
                />
              </Button>

              {/* // do not remove the below commented code for link - Nitish */}

              {/* <Button
                href={`https://www.thehosteller.com/glu/events?hostelID=${hostelID}`}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-circle btn-icon"
                color="none"
                title="Check Services on GLU"
              >
                <Link size={15} />
              </Button> */}
            </td>
            <td>
              <span className="align-middle font-weight-bold">{title}</span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">{hostel}</span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">{category}</span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">{price}</span>
            </td>

            <td>
              <span className="align-middle font-weight-bold">{frequency}</span>
            </td>

            <td>
              <span className="align-middle font-weight-bold">
                {frequency === "once"
                  ? eventDate
                    ? new Date(eventDate).toLocaleDateString()
                    : null
                  : frequency === "weekly"
                  ? eventStartDate && eventEndDate
                    ? `${new Date(
                        eventStartDate
                      ).toLocaleDateString()} - ${new Date(
                        eventEndDate
                      ).toLocaleDateString()}`
                    : null
                  : null}
              </span>
            </td>

            {/* <td>
              <Badge
                pill
                color={published ? "light-success" : "light-danger"}
                className="mr-1"
              >
                {published ? "Active" : "Inactive"}
              </Badge>
            </td> */}
            <td>
              <SwitchIcon
                checked={published}
                id={id}
                name="status"
                handleChecked={(value) => createObject(value, id)}
                // onClick={(e) => createObject(!value, id, e)}
              />
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default WebAppEventsAndActivitiesTableBody;
