//Added By Gaurav
import gql from "graphql-tag";

// export const WEBSITE_HOURLY_SALES_REPORT = gql`
// query {
//     websiteHourlySalesReport {
//         Hour
//         bookingCount
//     }
// }
// `;

export const WEBSITE_HOURLY_SALES_REPORT = gql`
query websiteHourlySalesReport($DateRange: DateRange){
    websiteHourlySalesReport(DateRange:$DateRange) {
        Hour
        bookingCount
    }
}
`;
