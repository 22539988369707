import { useEffect, useState } from "react";
import { Edit, FileText, Trash2 } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { useGetAdminLoginLogsByEmail } from "../../api/AdminLoginLogs/Queries";
import { useDeleteAdminLoginLogsByID } from "../../api/AdminLoginLogs/Mutation";
import { dateFormatHandler } from "@utils";
import toaster from "@components/common/Toaster";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Login Logs",
  "Department",
  "Name",
  "Email",
  "Role / Designation",
  "Status",
];

const AdminUsersTable = ({ openModal, setId, data }) => {
  const [loginLogsModal, setLoginLogsModal] = useState(false);
  const [email, setEmail] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [tableData, setTableData] = useState(data);

  const { data: loginLogsData, refetch } = useGetAdminLoginLogsByEmail(email);
  const [deleteAdminLoginLogsHandler, { data: deletedAdminLoginLogsData }] =
    useDeleteAdminLoginLogsByID();

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    setViewData(loginLogsData?.getAdminLoginLogsByEmail);
  }, [loginLogsData]);

  const handleAdminLoginLogsOpenModal = (email) => {
    setEmail(email);
    setLoginLogsModal(true);
  };

  const handleAdminLoginLogsCloseModal = () => {
    setEmail(null);
    setLoginLogsModal(false);
  };

  useEffect(() => {
    if (deletedAdminLoginLogsData?.deleteAdminLoginLogsByID?.status === true) {
      toaster("success", "Log deleted successfully");
    }
  }, [deletedAdminLoginLogsData]);

  const handleDeleteAdminLoginLogs = async (loginLogsID) => {
    await deleteAdminLoginLogsHandler(loginLogsID);

    setViewData(viewData.filter((item) => item._id !== loginLogsID));

    refetch();
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Staffs</h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add User
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            {data.length > 0 ? (
              <tbody>
                {tableData?.map((item) => (
                  <tr key={uuidv4()}>
                    <td style={{ width: "5%" }}>
                      <UncontrolledDropdown>
                        {/* <DropdownToggle
                        className="icon-btn hide-arrow"
                        color="transparent"
                        size="sm"
                        caret
                      >
                        <MoreVertical size={15} />
                      </DropdownToggle>
                      <DropdownMenu right> */}
                        <DropdownItem
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setId(item._id);
                            openModal();
                          }}
                        >
                          <Edit
                            className="mr-50"
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                          {/* <span className="align-middle">Edit</span> */}
                        </DropdownItem>
                        {/* <DropdownItem
                          href="/"
                          onClick={(e) => handleDelete(e, item)}
                        >
                          <Trash className="mr-50" size={15} />
                          <span className="align-middle">Delete</span>
                        </DropdownItem>
                      </DropdownMenu> */}
                      </UncontrolledDropdown>
                    </td>

                    <td style={{ width: "5%" }}>
                      <Button
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={() => {
                          handleAdminLoginLogsOpenModal(item?.email);
                        }}
                      >
                        <FileText
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td>{item?.department}</td>
                    <td>{item?.name}</td>
                    <td>{item?.email}</td>
                    <td>{item?.role}</td>
                    <td>
                      <Badge
                        pill
                        color={item.status ? "light-success" : "light-danger"}
                        className="mr-1"
                      >
                        {item.status ? "Active" : "Inactive"}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td
                    colSpan={tableHeaderData.length}
                    style={{ textAlign: "center", height: "150px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <h2>No Record Found</h2>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>

          <Modal
            isOpen={loginLogsModal}
            toggle={handleAdminLoginLogsCloseModal}
            size="xl"
          >
            <ModalHeader toggle={handleAdminLoginLogsCloseModal}>
              Admin Login Logs
            </ModalHeader>

            <ModalBody style={{ maxHeight: "85vh", overflowY: "auto" }}>
              {email && viewData?.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Delete</th>
                      <th>Email</th>
                      <th>Last Login</th>
                      <th>Login Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewData?.map((item) => (
                      <tr>
                        <td style={{ width: "5%" }}>
                          <Button
                            className="rounded-circle btn-icon"
                            color="none"
                            onClick={() => {
                              handleDeleteAdminLoginLogs(item?._id);
                            }}
                          >
                            <Trash2
                              color={reactFeatherIcon.iconColor}
                              size={reactFeatherIcon.iconSize}
                            />
                          </Button>
                        </td>
                        <td>{item?.email}</td>

                        <td>
                          {item?.createdAt ? (
                            <>
                              {new Date(item.createdAt)
                                .toLocaleDateString("en-IN", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                  timeZone: "Asia/Kolkata",
                                })
                                .replace(/\//g, "/")}{" "}
                              -{" "}
                              {new Date(item.createdAt).toLocaleTimeString(
                                "en-IN",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                  timeZone: "Asia/Kolkata",
                                }
                              )}
                            </>
                          ) : (
                            "Invalid Date"
                          )}
                        </td>

                        <td>
                          <Badge
                            pill
                            color={
                              item?.success ? "light-success" : "light-danger"
                            }
                            className="mr-1"
                          >
                            {item?.success ? "Successful" : "Failed"}
                          </Badge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p></p>
              )}
            </ModalBody>
          </Modal>
        </Card>
      </Col>
    </Row>
  );
};

export default AdminUsersTable;
