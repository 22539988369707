import gql from "graphql-tag";

export const GET_ALL_SEARCHES_IN_DATE_RANGE = gql`
mutation getCheckinData ($startDate: String, $endDate: String){
    getCheckinData(startDate:$startDate, endDate: $endDate){
       data{
        searches
        date
       }
       hostelName
    }
}
`