import { Coffee, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Ezee Meals")
  ? [
      {
        id: "ezee",
        title: "Ezee",
        icon: <Coffee size={20} />,
        badge: "light-warning",
        children: [
          {
            id: "ezeeMeals",
            title: "Ezee Meals",
            icon: <Circle size={12} />,
            navLink: "/ezee/ezeeMeals",
          },
        ],
      },
    ]
  : [];
