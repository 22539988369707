import React from 'react'
import Posts from './Posts'

const ConnectPosts = ({postList, refetch}) => {
  const postData = postList?.getAllPosts
  return (
    <div>
      <h3 style={{fontWeight:"800"}}>Recent Conversations</h3>
      {postData?.map((data, index)=><Posts key={index} post={data}/>)}
    </div>
  )
}

export default ConnectPosts