import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import {
  useCreatePressMention,
  useUploadImage,
  useUpdatePressMention,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SwitchIcon from "@components/common/switch-icon";
import { useParams } from "react-router-dom";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [title, setTitle] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [url, setUrl] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [status, setStatus] = useState(false);

  const [imagePreview, setImagePreview] = useState(null);
  const [newsImagePreview, setNewsImagePreview] = useState(null);
  const { id } = useParams();
  const [
    uploadImageHandler,
    { data: imageData, reset: imageReset, loading: imageLoad },
  ] = useUploadImage();

  const [
    uploadNewsImageHandler,
    { data: newsImageData, reset: newsImageReset, loading: newsImageLoad },
  ] = useUploadImage();

  const [createPressMentionHandler, { data }] = useCreatePressMention();

  const [updatePressMentionHandler, { data: updatedPressMentionData }] =
    useUpdatePressMention();

  useEffect(() => {
    if (data?.createPressMention?.title || updatedPressMentionData) {
      refetch();
      handleToggle();

      toaster("success", "Press Mention added successfully");
    }
  }, [data, updatedPressMentionData]);

  const uppyImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  const uppyNewsImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppyImage.use(thumbnailGenerator);
  uppyNewsImage.use(thumbnailGenerator);

  uppyImage.on("thumbnail:generated", (file, preview) => {
    setImagePreview([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyImage.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "icons/pressMention");
    toaster("success", "Image uploaded successfully");
  });

  uppyImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading image: ${error.message}`);
  });

  uppyNewsImage.on("thumbnail:generated", (file, preview) => {
    setNewsImagePreview([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyNewsImage.removeFile(file.id);
      return;
    }

    uploadNewsImageHandler(file.data, "icons/pressMention");
    toaster("success", "News image uploaded successfully");
  });

  uppyNewsImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading news image: ${error.message}`);
  });

  const renderImagePreview = () =>
    imagePreview ? (
      <img className="rounded mr-1 mb-2 w-50" src={imagePreview} alt="avatar" />
    ) : null;

  useEffect(() => {
    setImagePreview(imageData?.uploadImage?.url);
  }, [imageData]);

  const renderNewsImagePreview = () =>
    newsImagePreview ? (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={newsImagePreview}
        alt="avatar"
      />
    ) : null;

  useEffect(() => {
    setNewsImagePreview(newsImageData?.uploadImage?.url);
  }, [newsImageData]);

  const submitDataHandler = () => {
    let data = {
      title,
      publishedDate,
      url,
      image: imagePreview,
      newsImage: newsImagePreview,
      displayOrder: parseInt(displayOrder),
      status,
    };

    if (data.title) {
      if (editData?.length) {
        const { _id } = editData[0];

        updatePressMentionHandler(_id, data);
      } else {
        createPressMentionHandler(data);
      }

      setTitle("");
      setPublishedDate("");
      setUrl("");

      setImagePreview("");
      setNewsImagePreview("");
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  useEffect(() => {
    if (editData?.length) {
      const {
        title,
        publishedDate,
        url,
        image,
        newsImage,
        displayOrder,
        status,
      } = editData[0];
      setTitle(title);
      setPublishedDate(publishedDate);
      setUrl(url);
      setImagePreview(image);
      setNewsImagePreview(newsImage);
      setDisplayOrder(displayOrder === null ? "" : displayOrder);
      setStatus(status);
    } else {
      setTitle("");
      setPublishedDate("");
      setUrl("");
      setImagePreview("");
      setNewsImagePreview("");
      setDisplayOrder("");
      setStatus(false);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Title */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="title">
                Title*
              </Label>
              <Input
                type="text"
                value={title}
                id="title"
                name="title"
                placeholder="Add Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Published Date */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="published Date">
                Published Date*
              </Label>
              <Input
                type="text"
                value={publishedDate}
                id="publishedDate"
                name="publishedDate"
                placeholder="Add Published Date"
                onChange={(e) => setPublishedDate(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Display Order */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="displayOrder">
                Display Order*
              </Label>
              <Input
                type="number"
                value={displayOrder}
                id="displayOrder"
                name="displayOrder"
                placeholder="Add Display Order"
                onChange={(e) => setDisplayOrder(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! URL */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                URL*
              </Label>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add Url"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Image */}
          <Row>
            <Col sm="6">
              <p>Upload Image</p>
              <DragDrop
                note="Image size should be less than 500KB"
                width="100%"
                uppy={uppyImage}
                locale={{
                  strings: {
                    dropHereOr: "Drop image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
              <Col sm="13">
                <Label className="form-label w-100">Image preview here</Label>
                {imageLoad ? "Uploading image..." : renderImagePreview()}
              </Col>
            </Col>

            {/* // ! News Image */}
            <Col sm="6">
              <p>Upload News Image</p>
              <DragDrop
                note="News image size should be less than 500KB"
                width="100%"
                uppy={uppyNewsImage}
                locale={{
                  strings: {
                    dropHereOr: "Drop news image here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
              <Col sm="13">
                <Label className="form-label w-100">
                  News image preview here
                </Label>
                {newsImageLoad
                  ? "Uploading image..."
                  : renderNewsImagePreview()}
              </Col>
            </Col>

            {/* // ! Status */}
            <Col sm="12">
              <SwitchIcon
                checked={status}
                id="status"
                name="status"
                label="Publish on Website ?"
                handleChecked={(value) => setStatus(value)}
              />
            </Col>
          </Row>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
