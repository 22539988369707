import React, { useEffect, useState } from "react";
import { useGetPaginatedCafeMaster } from "../../../api/Unbox/UnboxCafeMaster/Queries";
import CafeMasterTable from "./CafeMasterTable";
import TablePagination from "../../../@core/components/common/TablePagination";
import AddEditModal from "./AddEditModal";

const UnboxCafeMaster = () => {
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const { data, refetch } = useGetPaginatedCafeMaster(limit, page);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };
  useEffect(() => {
    if (data?.getPaginatedCafeMaster?.data?.length > 0) {
      setTableData(data?.getPaginatedCafeMaster?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getPaginatedCafeMaster?.count / limit));
  }, [
    data?.getPaginatedCafeMaster?.count,
    data?.getPaginatedCafeMaster?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedCafeMaster?.data?.length > 0) {
      let findData = data?.getPaginatedCafeMaster?.data?.find(
        (item) => item?._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedCafeMaster?.data, id]);

  return (
    <div>
      <CafeMasterTable
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        setId={setId}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
      <AddEditModal
        setId={setId}
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default UnboxCafeMaster;
