import React, { useEffect, useState } from "react";
import CouponReportTable from "./CouponReportTable";
import { useGetCouponAppliedBookings, useGetAppliedCouponListForExport } from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { CSVLink } from "react-csv";
import { selectThemeColors } from "@utils";
import Select from "react-select";
import { useHostelList } from "../../../api/dataSource";
import { addDays } from "@utils";
import { dateValidator } from "../../../utility/Utils";
import toaster from "@components/common/Toaster";

const tableHeaderData = [
  { label: "Promo Code", key: "_id" },
  { label: "Usage count", key: "usageCount" },
  { label: "No of Nights", key: "totalNoOfNights" },
  { label: "Amount Discounted", key: "totalAmountDiscounted" },
  { label: "Amount Charged", key: "totalAmountCharged" },
];

const CouponReport = () => {
  const [fromDate, setFromDate] = useState(
    dateFormatter(addDays(dateFormatter(new Date()), -30))
  );
  const [toDate, setToDate] = useState(
    dateFormatter(addDays(dateFormatter(new Date()), 1))
  );
  const [hostel, setHostel] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useHostelList();

  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [searchFromDate, setSearchFromDate] = useState(dateFormatter(addDays(dateFormatter(new Date()), -30)));
  const [searchToDate, setSearchToDate] = useState(dateFormatter(addDays(dateFormatter(new Date()), 1)));
  const [searchHostel, setSearchHostel] = useState([])
  const [searchSKeyword, setSearchSKeyword] = useState("")
  const { data, refetch } = useGetCouponAppliedBookings(
    limit,
    page,
    searchFromDate,
    searchToDate,
    searchHostel.length > 0 ? searchHostel.map((hostel) => hostel.value) : [],
    searchSKeyword
  );

  const {data:exportList, refetch:exportRefetch} = useGetAppliedCouponListForExport(
    searchFromDate,
    searchToDate,
    searchSKeyword,
    searchHostel.length > 0 ? searchHostel.map((hostel) => hostel.value) : [],
  ) 

  useEffect(()=>{
    if(exportList){
      setTableDataExport(exportList?.getCouponAppliedBookingsForExport?.data)
    }
  },[])
  const [totalPage, setTotalPage] = useState(1);
  const [totalAmountDiscounted, setTotalAmountDiscounted] = useState(0);
  const [totalCharged, setTotalCharged] = useState(0);
  const [tableDataExport, setTableDataExport] = useState([])

  useEffect(()=>{
    if(exportList){
      setTableDataExport(exportList?.getCouponAppliedBookingsForExport?.data)
    }
  },[exportList])

  useEffect(() => {
    refetch();
  }, [page]);

  // useEffect(() => {
  //   setPage(1);
  // }, [searchKeyword]);

  // useEffect(() => {
  //   refetch();
  // }, [hostel]);

  useEffect(() => {
    let totalAmountDiscounted = 0;
    let totalCharged = 0;
    if (data?.getCouponAppliedBookings?.data?.length > 0) {
      setTableData(data?.getCouponAppliedBookings?.data);
      data?.getCouponAppliedBookings?.data?.map((item) => {
        totalCharged += item?.totalAmountCharged;
        return (totalAmountDiscounted =
          totalAmountDiscounted + item?.totalAmountDiscounted);
      });
      setTotalAmountDiscounted(totalAmountDiscounted);
      setTotalCharged(totalCharged);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getCouponAppliedBookings?.count / limit));
  }, [data]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const getSearchData = ()=>{
    if (dateValidator(fromDate, toDate)) {
      setSearchFromDate(fromDate)
      setSearchToDate(toDate);
      setSearchHostel(hostel);
      setSearchSKeyword(searchKeyword)
  } else {
    toaster("error", "Invalid dates");
  }
  }


  const handleResetData = ()=>{
    setTableData([]);
    setFromDate(dateFormatter(addDays(dateFormatter(new Date()), -30)));
    setToDate(dateFormatter(new Date()));
    setSearchFromDate(dateFormatter(addDays(dateFormatter(new Date()), -30)));
    setSearchToDate(dateFormatter(addDays(dateFormatter(new Date()), 1)));
    // refetch();
    // setSearchFromDate(fromDate);
    // setSearchToDate(toDate);


    setTotalAmountDiscounted(0);
    setTotalCharged(0)
    setHostel([])
    setSearchSKeyword("")
    setSearchKeyword("")
  }


  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        <Row>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                To Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Promo Code
              </Label>
              <Input
                type="text"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                placeholder="Search"
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{marginBottom:"10px", marginTop:"-10px"}}>
          <Col>
            <Button
              onClick={getSearchData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginRight: "10px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
            >
              Search
            </Button>

            <Button
              onClick={handleResetData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft: "10px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button>
             <CSVLink
              headers={tableHeaderData}
              data={tableDataExport}
              filename={"coupon_report.csv"}
            >
              <Button.Ripple
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginLeft: "10px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="success"
                appearance="success"
                type="button"
              >
                Export
              </Button.Ripple>
            </CSVLink> 
          </Col>
        </Row>
        {tableData.length > 0 ? <CouponReportTable 
        data={tableData} 
        refetch={refetch} 
        totalAmountDiscounted={totalAmountDiscounted} 
        totalAmountCharged={totalCharged}
        totalDataLength={exportList?.getCouponAppliedBookingsForExport?.data?.length}
        />:<h2 style={{textAlign:"center", marginTop:"150px"}}>No Record Found</h2>}
        
        {tableData.length>0 && <TablePagination setPage={setPage} page={page} totalPage={totalPage} />}
        
      </div>
    )
  );
};

export default CouponReport;
