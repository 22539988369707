import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  Table,
  Button,
  CustomInput,
} from "reactstrap";
import GoPaymentsSlideModal from "./GoPaymentsSlideModal";
import { useHostelList } from "../../../api/dataSource";
import {
  useCreateGoPaymentRequest,
  useUpdateGoPaymentRequest,
  useUpdatePendingInvoice,
} from "../../../api/GOPaymentRequest/Mutation";

import { useGetOpexVendors } from "../../../api/OpsHostelVendor/Queries";

import toaster from "@components/common/Toaster";
import Select from "react-select";
import AttachmentComponent from "./AttachmentComponent";
import Flatpickr from "react-flatpickr";
import HostelUtilitiesSubFields from "./SubHeadsDropdownCategory/HostelUtilitiesSubFields";
import HOSTEL_R_AND_M_SUBFIELDS from "./SubHeadsDropdownCategory/HostelR&MSubFields";
import HostelGeneralSubFields from "./SubHeadsDropdownCategory/HostelGeneralSubFields";
import HostelHKSubFields from "./SubHeadsDropdownCategory/HostelHKSubFields";
import F_AND_B_MATERIAL_SUBFIELDS from "./SubHeadsDropdownCategory/F&BMaterialSubFields";
import AncillaryPayoutSubFields from "./SubHeadsDropdownCategory/AncillaryPayoutSubFields";
import AncillaryMaterialSubFields from "./SubHeadsDropdownCategory/AncillaryMaterialSubFields";
import CapexSubHeadsSubFields from "./SubHeadsDropdownCategory/CapexSubHeadsSubFields";
import { Plus, Trash2 } from "react-feather";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const userDepartment = localStorage.getItem("department");
  const userRole = localStorage.getItem("role");
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [head, setHead] = useState(null);
  const [purpose, setPurpose] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [amount, setAmount] = useState(null);
  const [gst, setGst] = useState(null);
  const [status, setStatus] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [amountToPay, setAmountToPay] = useState(null);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [paymentDate, setPaymentDate] = useState(null);
  const [amountPaid, setAmountPaid] = useState("");
  const [bankDetailImageList, setBankDetailsImageList] = useState([]);
  const [poAttachmentList, setPoAttachmentList] = useState([]);
  const [invoiceAttachmentList, setInvoiceAttachmentList] = useState([]);
  const [paymentAttachmentList, setPaymentAttachmentList] = useState([]);
  const [amRemark, setAmRemark] = useState("");
  const [financeTeamRemark, setFinanceTeamRemark] = useState("");
  const [rmRemark, setRmRemark] = useState("");
  const [ceoRemark, setCeoRemark] = useState("");
  const [dummyState] = useState(null);
  const [dummyStatePo] = useState(null);
  const [dummyStatePayment] = useState(null);
  const [dummyStateInvoice] = useState(null);
  const [ifscCode, setIfscCode] = useState("");
  const [subHead, setSubHead] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [logs, setLogs] = useState([]);
  const [dataToSubmit, setDataToSubmit] = useState({});

  const { data: allHostelsData } = useHostelList();
  const [validationMessage, setValidationMessage] = useState("");
  const [selectedPercentage, setSelectedPercentage] = useState("custom");

  const [currentPaymentStatuses, setCurrentPaymentStatuses] = useState([]);

  const [paymentDetails, setPaymentDetails] = useState([
    { amountToPay: null, dueDate: null },
  ]);

  const [invoicePending, setInvoicePending] = useState(false);
  const [invoicePendingRemark, setInvoicePendingRemark] = useState(null);

  const addPaymentDetail = () => {
    setPaymentDetails([
      ...paymentDetails,
      { amountToPay: null, dueDate: null, paymentStatus: "Payment Due" },
      // { amountToPay: null, dueDate: null  },
    ]);
  };

  const removePaymentDetail = (index) => {
    const newPaymentDetails = paymentDetails.filter((_, i) => i !== index);
    setPaymentDetails(newPaymentDetails);
  };

  const handleChange = (index, field, value) => {
    const newPaymentDetails = paymentDetails.map((detail, i) => {
      if (i === index) {
        return { ...detail, [field]: value || null };
      }
      return detail;
    });
    setPaymentDetails(newPaymentDetails);
  };

  const severityOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const headList = [
    { label: "Hostel", value: "Hostel" },
    { label: "F&B", value: "F&B" },
    { label: "Ancillary", value: "Ancillary" },
  ];

  const statusListForAM = [
    { label: "AM Approved", value: "AM Approved" },
    { label: "AM Rejected", value: "AM Rejected" },
  ];

  const statusListForRM = [
    { label: "RM Approved", value: "RM Approved" },
    { label: "RM Rejected", value: "RM Rejected" },
  ];

  const statusListForCEO = [
    { label: "CEO Approved", value: "CEO Approved" },
    { label: "CEO Rejected", value: "CEO Rejected" },
  ];

  const statusListForFinance = [
    { label: "Payment Done", value: "Payment Done" },
    { label: "Payment Rejected", value: "Payment Rejected" },
  ];

  const paymentStatusFinanceList = [
    { label: "Payment Done", value: "Payment Done" },
    { label: "Payment Rejected", value: "Payment Rejected" },
  ];

  const subHeadOptions = {
    Hostel: [
      { label: "Utilities", value: "Utilities" },
      { label: "R&M", value: "R&M" },
      { label: "HK", value: "HK" },
      { label: "General", value: "General" },
    ],
    "F&B": [{ label: "Material", value: "Material" }],
    Ancillary: [
      { label: "Material", value: "Material" },
      { label: "Vendor Payout", value: "Vendor Payout" },
    ],
    CAPEX: [
      { label: "Hostel", value: "Hostel" },
      { label: "F&B", value: "F&B" },
      { label: "Ancillary", value: "Ancillary" },
    ],
  };

  const options =
    userRole === "Area Manager"
      ? statusListForAM
      : userRole === "Regional Manager"
      ? statusListForRM
      : userRole === "CEO"
      ? statusListForCEO
      : statusListForFinance;

  //upload type for uploaders
  const uploadTypeBankDetail = [
    { label: "imageURL", value: "bankDetailImage" },
  ];
  const uploadTypePo = [{ label: "imageURL", value: "poAttachment" }];
  const uploadTypeInvoice = [{ label: "imageURL", value: "invoiceAttachment" }];
  const uploadTypePayment = [
    { label: "imageURL", value: "financePaymentAttachment" },
  ];

  //create Handler
  const [createGoPaymentHandler, { data: createGoPaymentData }] =
    useCreateGoPaymentRequest();

  //update handler
  const [updateGoPaymentHandler, { data: updateGoPaymentData }] =
    useUpdateGoPaymentRequest();

  //upload pending invoice
  const [updatePendingInvoiceHandler, { data: updatePendingInvoiceData }] =
    useUpdatePendingInvoice();

  const handleUploadPendingInvoice = () => {
    if (editData?._id && invoiceAttachmentList?.length > 0) {
      updatePendingInvoiceHandler(editData._id, {
        imageURL: invoiceAttachmentList[0].imageURL,
      });
    }
    refetch();
  };

  //refetching and toggle logic
  useEffect(() => {
    if (createGoPaymentData?.createPaymentRequest || updateGoPaymentData) {
      refetch();
      handleToggle();
    }
  }, [createGoPaymentData?.createPaymentRequest, updateGoPaymentData]);

  useEffect(() => {
    const calculatedTotal = parseFloat(amount || 0) + parseFloat(gst || 0);
    const totalAmountRounded = parseFloat(calculatedTotal.toFixed(2));
    setTotalAmount(totalAmountRounded);

    if (selectedPercentage === "100") {
      setAmountToPay(totalAmountRounded);
    } else if (selectedPercentage === "50") {
      setAmountToPay(totalAmountRounded / 2);
    }
  }, [amount, gst, selectedPercentage]);

  const handleAmountToPayChange = (e) => {
    if (selectedPercentage === "custom") {
      const value = e.target.value;
      const parsedValue = parseFloat(value);

      if (value === "" || (!isNaN(parsedValue) && parsedValue >= 0)) {
        setAmountToPay(value);

        if (parsedValue > totalAmount) {
          setValidationMessage(
            "The amount to pay cannot be more than the total amount."
          );
        } else {
          setValidationMessage("");
        }
      }
    }
  };
  const handlePercentageChange = (value) => {
    setSelectedPercentage(value);
    if (value === "100") {
      setAmountToPay(totalAmount);
      setValidationMessage("");
    } else if (value === "50") {
      setAmountToPay(totalAmount / 2);
      setValidationMessage("");
    } else {
      setAmountToPay("");
    }
  };

  const handleAmountToPayForFinance = (e) => {
    const value = e.target.value;
    const parsedValue = parseFloat(value);

    if (value === "" || (!isNaN(parsedValue) && parsedValue >= 0)) {
      setAmountPaid(value);

      if (parsedValue > amountToPay) {
        setValidationMessage(
          "The amount cannot be more than the amount to pay."
        );
      } else {
        setValidationMessage("");
      }
    }
  };

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  const resetForm = () => {
    setHostel(null);
    setHead(null);
    setSubHead(null);
    setSeverity(null);
    setPurpose("");
    setVendorName("");
    setAmount("");
    setGst("");
    setTotalAmount("");
    setPaymentDetails([{ amountToPay: null, dueDate: null }]);
    setBankAccountNumber("");
    setIfscCode("");
    setBankDetailsImageList([]);
    setPoAttachmentList([]);
    setInvoiceAttachmentList([]);
    setPaymentAttachmentList([]);
    setLogs([]);
    setAmRemark("");
    setRmRemark("");
    setCeoRemark("");
    setFinanceTeamRemark("");
  };

  const isSelectDisabled = () => {
    if (userRole === "Area Manager") {
      return editData?.status !== "Pending";
    } else if (userRole === "Regional Manager") {
      return editData?.status !== "AM Approved";
    } else if (userRole === "CEO") {
      return editData?.status !== "RM Approved";
    } else if (userDepartment === "Finance") {
      return editData?.status !== "Payment Due";
    } else {
      return false;
    }
  };

  const { data: opexVendorData } = useGetOpexVendors(hostel?.value);

  useEffect(() => {
    if (editData?._id && opexVendorData) {
      const vendor = opexVendorData?.getOpexVendors?.find(
        (vendor) => vendor.vendorName === editData.vendorName
      );
      if (vendor) {
        setVendorName(vendor.vendorName);
        setBankAccountNumber(vendor.vendorBankAccNumber);
        setIfscCode(vendor.vendorIFSC || "");
      }
    }
  }, [editData, opexVendorData]);

  const handleVendorChange = (selectedOption) => {
    const selectedVendor = opexVendorData?.getOpexVendors?.find(
      (vendor) => vendor.vendorName === selectedOption.label
    );
    if (selectedVendor) {
      setVendorName(selectedVendor.vendorName);
      setBankAccountNumber(selectedVendor.vendorBankAccNumber);
      setIfscCode(selectedVendor.vendorIFSC || "");
    }
  };

  const handlePaymentDetailChange = (index, key, value) => {
    const updatedPaymentDetails = [...paymentDetails];
    if (key === "amountPaid" || key === "tdsAmount") {
      updatedPaymentDetails[index][key] = Number(value);
    } else {
      updatedPaymentDetails[index][key] = value;
    }

    if (!updatedPaymentDetails[index].paymentStatus) {
      updatedPaymentDetails[index].paymentStatus = "Payment Due";
    }

    setPaymentDetails(updatedPaymentDetails);
  };

  useEffect(() => {
    if (editData?._id) {
      setHostel(
        editData?.hostel?.name
          ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
          : null
      );
      setHead(
        editData?.head !== null
          ? { label: editData?.head, value: editData?.head }
          : null
      );
      setSubHead(
        editData?.subHead !== null
          ? { label: editData?.subHead, value: editData?.subHead }
          : null
      );
      setSeverity(
        editData?.severity !== null
          ? { label: editData?.severity, value: editData?.severity }
          : null
      );
      setPurpose(editData?.purpose !== null ? editData?.purpose : "");
      setVendorName(editData?.vendorName !== null ? editData?.vendorName : "");
      setAmount(editData?.amount !== null ? editData?.amount : "");
      setGst(editData?.gst !== null ? editData?.gst : "");
      setTotalAmount(
        editData?.totalAmount !== null ? editData?.totalAmount : ""
      );

      setPaymentDetails(
        editData?.paymentDetails?.map((detail) => ({
          amountToPay:
            detail?.amountToPay !== undefined ? detail.amountToPay : undefined,
          dueDate: detail?.dueDate ? new Date(detail.dueDate) : undefined,
          paymentStatus:
            detail?.paymentStatus !== undefined
              ? detail.paymentStatus
              : undefined,
          paymentDate: detail?.paymentDate
            ? new Date(detail.paymentDate)
            : undefined,
          bankRefNumber:
            detail?.bankRefNumber !== undefined
              ? detail.bankRefNumber
              : undefined,
          amountPaid:
            detail?.amountPaid !== undefined ? detail.amountPaid : undefined,
          tdsAmount:
            detail?.tdsAmount !== undefined ? detail.tdsAmount : undefined,
          financeTeamRemark:
            detail?.financeTeamRemark !== undefined
              ? detail.financeTeamRemark
              : undefined,
        })) || [{ amountToPay: undefined, dueDate: undefined }]
      );
      setPaymentAttachmentList(
        editData?.financePaymentAttachment
          ? editData?.financePaymentAttachment
          : []
      );

      setCurrentPaymentStatuses(
        editData.paymentDetails.map((detail) => detail.paymentStatus)
      );

      setBankAccountNumber(
        editData?.bankAccountNumber !== null ? editData?.bankAccountNumber : ""
      );
      setIfscCode(editData?.ifscCode !== null ? editData?.ifscCode : "");
      setBankDetailsImageList(
        editData?.bankDetailImage ? editData?.bankDetailImage : []
      );
      setPoAttachmentList(editData?.poAttachment ? editData?.poAttachment : []);
      setInvoiceAttachmentList(
        editData?.invoiceAttachment ? editData?.invoiceAttachment : []
      );

      if (userRole !== "Ground Staff") {
        setAmRemark(editData?.amRemark !== null ? editData?.amRemark : "");
        setRmRemark(editData?.rmRemark !== null ? editData?.rmRemark : "");
        setCeoRemark(editData?.ceoRemark !== null ? editData?.ceoRemark : "");
      }

      setAmountPaid(editData?.amountPaid !== null ? editData?.amountPaid : "");
      setInvoicePending(
        editData?.invoicePending ? editData?.invoicePending : false
      );

      setInvoicePendingRemark(
        editData?.invoicePendingRemark ? editData?.invoicePendingRemark : ""
      );

      setLogs(editData?.logs ? editData?.logs : []);
    } else {
      resetForm();
    }
  }, [editData]);

  const handleStatusChange = (index, status) => {
    const updatedStatuses = [...currentPaymentStatuses];
    updatedStatuses[index] = status;
    setCurrentPaymentStatuses(updatedStatuses);
    handlePaymentDetailChange(index, "paymentStatus", status);
  };

  const onSubmitHandler = () => {
    // Amount validation
    if (amountToPay > totalAmount) {
      toaster(
        "error",
        "The amount to pay cannot be more than the total amount."
      );
      return;
    }

    // Validate amountPaid
    if (amountPaid > amountToPay) {
      toaster("error", "The amount cannot be more than the amount to pay.");
      return;
    }

    if (totalAmount < 10000) {
      toaster("error", "The amount cannot be less than 10000");
      return;
    }

    for (const detail of paymentDetails) {
      if (!detail.amountToPay || !detail.dueDate) {
        toaster(
          "error",
          "Please ensure each payment detail has both amount to pay and due date."
        );
        return;
      }

      // if (detail.amountToPay < 10000) {
      //   toaster("error", "The amount to pay cannot be less than 10000");

      //   return;
      // }
    }

    // Validate Ground Staff
    if (
      userRole === "Ground Staff" &&
      (!hostel?.value ||
        !head?.value ||
        !subHead?.value ||
        !severity?.value ||
        !purpose.trim() ||
        !vendorName ||
        !bankAccountNumber ||
        !ifscCode ||
        amount == null ||
        amount < 0 ||
        gst == null ||
        gst < 0 ||
        totalAmount == null ||
        totalAmount < 0)
    ) {
      toaster("error", "Please fill all the required details marked with *");
      return;
    }

    // Validate Area Manager
    if (userRole === "Area Manager" && (!status?.value || !amRemark)) {
      toaster("error", "Please fill all the required details marked with *");
      return;
    }

    // Validate Regional Manager
    if (userRole === "Regional Manager" && (!status?.value || !rmRemark)) {
      toaster("error", "Please fill all the required details marked with *");
      return;
    }

    // Validate CEO
    if (userRole === "CEO" && (!status?.value || !ceoRemark)) {
      toaster("error", "Please fill all the required details marked with *");
      return;
    }

    //attachment for finance validation
    if (userDepartment === "Finance") {
      const missingAttachments = paymentDetails.some(
        (detail) =>
          detail.paymentStatus === "Payment Done" &&
          !paymentAttachmentList.length
      );

      if (missingAttachments) {
        toaster(
          "error",
          "Please upload an attachment for payments marked as 'Payment Done'."
        );
        return;
      }
    }

    //Invoice pending validation
    if (invoicePending && !invoicePendingRemark.trim()) {
      toaster("error", "Please provide a remark for Invoice Pending.");
      return;
    }

    // Specific validation for Finance department
    if (userDepartment === "Finance") {
      for (const detail of paymentDetails) {
        const amountToPay = Number(detail.amountToPay) || 0;
        const tdsAmount = Number(detail.tdsAmount) || 0;
        const amountPaid = Number(detail.amountPaid) || 0;

        if (tdsAmount + amountPaid > amountToPay) {
          toaster(
            "error",
            `For the payment detail with amount to pay ${amountToPay}, the sum of TDS amount (${tdsAmount}) and amount paid (${amountPaid}) exceeds the amount to pay.`
          );
          return;
        }
      }
    }

    // Validation for Finance department: Ensure rejected payments have remarks
    if (userDepartment === "Finance") {
      // Check for missing finance team remarks for rejected payments
      for (const detail of paymentDetails) {
        if (
          detail.paymentStatus === "Payment Rejected" &&
          !detail.financeTeamRemark
        ) {
          toaster(
            "error",
            "Please add a remark for payments marked as 'Payment Rejected'."
          );
          return;
        }
      }

      // Check for missing amountPaid for payments marked as 'Payment Done'
      for (const detail of paymentDetails) {
        if (
          detail.paymentStatus === "Payment Done" &&
          (detail.amountPaid == null || detail.amountPaid === "")
        ) {
          toaster(
            "error",
            "Please enter the amount paid for payments marked as 'Payment Done'."
          );
          return;
        }

        if (
          detail.paymentStatus === "Payment Done" &&
          (detail.paymentDate == null || detail.paymentDate === "")
        ) {
          toaster(
            "error",
            "Please select the payment date for payments marked as 'Payment Done'."
          );
          return;
        }

        if (
          detail.paymentStatus === "Payment Done" &&
          (detail.financeTeamRemark == null || detail.financeTeamRemark === "")
        ) {
          toaster(
            "error",
            "Please enter the remark for payments marked as 'Payment Done'."
          );
          return;
        }
      }
    }

    // Check if the total amount to pay across all payment details exceeds the totalAmount

    const totalPaymentDetailsAmount = paymentDetails
      .filter((detail) => detail.paymentStatus !== "Payment Rejected")
      .reduce((sum, detail) => {
        if (detail.paymentStatus === "Payment Due") {
          return sum + parseInt(detail.amountToPay || 0, 10);
        } else if (detail.paymentStatus === "Payment Done") {
          return (
            sum +
            parseInt(detail.amountPaid || 0, 10) +
            parseInt(detail.tdsAmount || 0, 10)
          );
        } else {
          return sum + parseInt(detail.amountToPay || 0, 10);
        }
      }, 0);

    if (totalPaymentDetailsAmount > parseInt(totalAmount, 10)) {
      toaster(
        "error",
        "The total amount to pay in payment details exceeds the total amount."
      );
      return;
    }

    const currentDate = new Date();
    paymentDetails.forEach((detail) => {
      if (detail.paymentDate) {
        const selectedDate = new Date(detail.paymentDate);
        const currentDate = new Date();
        selectedDate.setHours(
          currentDate.getHours(),
          currentDate.getMinutes(),
          currentDate.getSeconds()
        );
        detail.paymentDate = selectedDate;
      }
    });

    let newLog = `${localStorage.getItem("name")} from ${localStorage.getItem(
      "department"
    )} ${
      editData ? "modified" : "created"
    } this payment request entry on ${currentDate.toLocaleString("en-IN", {
      hour12: false,
    })}`;

    if (editData?._id) {
      if (userRole === "Ground Staff" && paymentDetails.length) {
        newLog += ` with a new payment request`;
      } else if (userRole === "Area Manager" && amRemark) {
        newLog += ` with Status: ${status?.value} and Remark: ${amRemark}`;
      } else if (userRole === "Regional Manager" && rmRemark) {
        newLog += ` with Status: ${status?.value} and Remark: ${rmRemark}`;
      } else if (userRole === "CEO" && ceoRemark) {
        newLog += ` with Status: ${status?.value} and Remark: ${ceoRemark}`;
      }
    }

    let data = {};
    if (userDepartment === "Finance") {
      data = {
        hostel: hostel?.value,
        totalAmount,
        status: editData?.status,
        invoicePending,
        invoicePendingRemark,
        logs: [...logs, newLog],
        financePaymentAttachment: paymentAttachmentList,

        paymentDetails: paymentDetails.map((detail) => ({
          ...detail,
          paymentStatus: detail.paymentStatus,
          amountPaid: detail.amountPaid ? Number(detail.amountPaid) : undefined,
          tdsAmount: detail.tdsAmount ? Number(detail.tdsAmount) : undefined,
          paymentDate: detail.paymentDate
            ? new Date(detail.paymentDate)
            : undefined,
          bankRefNumber: detail.bankRefNumber,
          financeTeamRemark: detail.financeTeamRemark,
        })),
      };
    } else {
      switch (userRole) {
        case "Ground Staff":
          data = {
            hostel: hostel?.value,
            head: head?.value,
            subHead: subHead?.value,
            severity: severity?.value,
            purpose: purpose.trim(),
            vendorName: vendorName.trim(),
            amount: Number(amount),
            gst: Number(gst),
            totalAmount: Number(totalAmount),
            paymentDetails: paymentDetails.map((detail) => ({
              ...detail,
              amountToPay: parseFloat(detail.amountToPay),
              dueDate: detail.dueDate,
            })),
            bankAccountNumber,
            ifscCode: ifscCode.trim(),
            bankDetailImage: bankDetailImageList,
            poAttachment: poAttachmentList,
            invoiceAttachment: invoiceAttachmentList,
            logs: [...logs, newLog],
            subFields: dataToSubmit,
          };
          break;

        case "Area Manager":
          data = {
            hostel: hostel?.value,
            totalAmount,
            status: status?.value,
            amRemark,
            logs: [...logs, newLog],
          };
          break;

        case "Regional Manager":
          data = {
            hostel: hostel?.value,
            totalAmount,
            status: status?.value,
            rmRemark,
            logs: [...logs, newLog],
          };
          break;

        case "CEO":
          data = {
            hostel: hostel?.value,
            totalAmount,
            status: status?.value,
            ceoRemark,
            logs: [...logs, newLog],
          };
          break;

        default:
          toaster("error", "Unexpected role");
          return;
      }
    }

    if (data) {
      if (editData?._id) {
        if (userRole === "Ground Staff") {
          updateGoPaymentHandler(editData?._id, {
            ...data,
            paymentDetails: paymentDetails.map((detail) => ({
              ...detail,
              amountToPay: parseFloat(detail.amountToPay),
              dueDate: detail.dueDate,
            })),
          });
          toaster("success", "Payment Details Updated Successfully");
        } else if (userRole === "Area Manager" && amRemark) {
          updateGoPaymentHandler(editData?._id, {
            ...data,
            status: status?.value,
            amRemark,
          });
          toaster("success", "Area Manager Remark Added Successfully");
        } else if (userRole === "Regional Manager" && rmRemark) {
          updateGoPaymentHandler(editData?._id, {
            ...data,
            status: status?.value,
            rmRemark,
          });
          toaster("success", "Regional Manager Remark Added Successfully");
        } else if (userRole === "CEO" && ceoRemark) {
          updateGoPaymentHandler(editData?._id, {
            ...data,
            status: status?.value,
            ceoRemark,
          });
          toaster("success", "CEO Remark Added Successfully");
        } else if (userDepartment === "Finance" && editData) {
          updateGoPaymentHandler(editData?._id, {
            ...data,
            financePaymentAttachment: paymentAttachmentList,
            paymentDetails: paymentDetails.map((detail) => ({
              ...detail,
              paymentStatus: detail.paymentStatus,
              amountPaid: detail.amountPaid
                ? Number(detail.amountPaid)
                : undefined,
              tdsAmount: detail.tdsAmount
                ? Number(detail.tdsAmount)
                : undefined,
              paymentDate: detail.paymentDate
                ? new Date(detail.paymentDate)
                : undefined,
              bankRefNumber: detail.bankRefNumber,
              financeTeamRemark: detail.financeTeamRemark,
            })),
          });

          toaster("success", "Finance Entry Updated Successfully");
        }
      } else {
        createGoPaymentHandler(data);
        toaster("success", "Payment Entry Added successfully");
      }
      resetForm();
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };

  return (
    <GoPaymentsSlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      editData={editData}
      userRole={userRole}
      userDepartment={userDepartment}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {/* GO Entry Part */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel name*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={hostelsList}
                value={hostel}
                isDisabled={editData?.hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="head">
                Request head*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="head"
                name="head"
                options={headList}
                isDisabled={editData?.head}
                value={head}
                onChange={(value) => {
                  setHead(value);
                  setSubHead(null);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {head && subHeadOptions[head.value] && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="subhead">
                  Request sub-head*
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="subhead"
                  name="subhead"
                  isDisabled={editData?.subHead}
                  options={subHeadOptions[head.value]}
                  value={subHead}
                  onChange={(value) => setSubHead(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          )}

          {((head?.value === "Hostel" && subHead?.value === "Utilities") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "Utilities")) && (
            <HostelUtilitiesSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((head?.value === "Hostel" && subHead?.value === "R&M") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "R&M")) && (
            <HOSTEL_R_AND_M_SUBFIELDS
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((head?.value === "Hostel" && subHead?.value === "HK") ||
            (editData?.head[0] === "Hostel" && editData?.subHead === "HK")) && (
            <HostelHKSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((head?.value === "Hostel" && subHead?.value === "General") ||
            (editData?.head[0] === "Hostel" &&
              editData?.subHead === "General")) && (
            <HostelGeneralSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((head?.value === "F&B" && subHead?.value === "Material") ||
            (editData?.head[0] === "F&B" &&
              editData?.subHead === "Material")) && (
            <F_AND_B_MATERIAL_SUBFIELDS
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((head?.value === "Ancillary" &&
            subHead?.value === "Vendor Payout") ||
            (editData?.head[0] === "Ancillary" &&
              editData?.subHead === "Vendor Payout")) && (
            <AncillaryPayoutSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((head?.value === "Ancillary" && subHead?.value === "Material") ||
            (editData?.head[0] === "Ancillary" &&
              editData?.subHead === "Material")) && (
            <AncillaryMaterialSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          {((head?.value === "CAPEX" &&
            (subHead?.value === "Hostel" ||
              subHead?.value === "F&B" ||
              subHead?.value === "Ancillary")) ||
            (editData?.head[0] === "CAPEX" &&
              (editData?.subHead === "Hostel" ||
                editData?.subHead === "F&B" ||
                editData?.subHead === "Ancillary"))) && (
            <CapexSubHeadsSubFields
              setDataToSubmit={setDataToSubmit}
              editData={editData}
            />
          )}

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="severity">
                Severity*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="severity"
                name="severity"
                options={severityOptions}
                isDisabled={editData?.severity}
                value={severity}
                onChange={(value) => {
                  setSeverity(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <hr />
            <FormGroup className="w-100">
              <Label className="form-label" for="purpose">
                Request description*
              </Label>
              <Input
                type="textarea"
                value={purpose}
                id="purpose"
                name="purpose"
                disabled={editData?.purpose}
                rows="1"
                placeholder="Add Purpose"
                onChange={(e) => setPurpose(e.target.value)}
              />
            </FormGroup>
          </Col>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr)",
              margin: "0 12px",
              gap: "10px",
            }}
          >
            <FormGroup className="">
              <Label className="form-label" for="amount">
                Requested amount*
              </Label>
              <Input
                type="number"
                value={amount}
                id="amount"
                name="amount"
                placeholder="Add Amount"
                disabled={editData?.amount}
                onChange={(e) => {
                  const value = e.target.value;
                  setAmount(value);
                }}
                onWheel={(e) => e.target.blur()}
                min="0"
              />
            </FormGroup>
            <FormGroup className="">
              <Label className="form-label" for="gst">
                Requested GST amount*
              </Label>
              <Input
                type="number"
                value={gst}
                id="gst"
                name="gst"
                disabled={editData?.gst || gst === 0}
                placeholder="Add GST Amount"
                onChange={(e) => {
                  const value = e.target.value;
                  setGst(value);
                }}
                min="0"
                onWheel={(e) => e.target.blur()}
              />
            </FormGroup>
          </div>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="totalAmount">
                Requested total amount*
              </Label>
              <Input
                type="number"
                value={totalAmount < 0 ? Math.abs(totalAmount) : totalAmount}
                id="totalAmount"
                name="totalAmount"
                placeholder="Total Amount will be calculated automatically"
                disabled
                style={{ opacity: 1, cursor: "not-allowed" }}
              />
            </FormGroup>
          </Col>

          <Col>
            <hr />
            <FormGroup>
              {paymentDetails.map((detail, index) => (
                <Row key={index} className="align-items-center mb-1">
                  <Col sm="5">
                    <FormGroup>
                      <Label
                        className="form-label"
                        for={`amountToPay-${index}`}
                      >
                        Requested amount to pay*
                      </Label>
                      <Input
                        type="number"
                        value={detail.amountToPay}
                        onChange={(e) =>
                          handleChange(index, "amountToPay", e.target.value)
                        }
                        placeholder="Amount to Pay"
                        onWheel={(e) => e.target.blur()}
                        disabled={
                          userRole !== "Ground Staff" ||
                          detail.paymentStatus === "Payment Done" ||
                          detail.paymentStatus === "Payment Rejected"
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="5">
                    <FormGroup>
                      <Label className="form-label" for={`dueDate-${index}`}>
                        Due date*
                      </Label>
                      <Flatpickr
                        className="form-control bg-white border-dark text-dark"
                        value={detail.dueDate ? new Date(detail.dueDate) : null}
                        onChange={(date) =>
                          handleChange(index, "dueDate", date[0] || null)
                        }
                        options={{
                          minDate: editData?._id ? undefined : "today",
                          defaultDate: detail.dueDate
                            ? new Date(detail.dueDate)
                            : new Date(),
                        }}
                        id={`dueDate-${index}`}
                        placeholder="Add Due date"
                        required
                        disabled={
                          userRole !== "Ground Staff" ||
                          detail.paymentStatus === "Payment Done" ||
                          detail.paymentStatus === "Payment Rejected"
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="2" className="text-end">
                    {userRole === "Ground Staff" &&
                      !editData?.status?.includes("Rejected") && (
                        <>
                          {index === 0 && (
                            <Button
                              color="primary"
                              onClick={addPaymentDetail}
                              style={{
                                borderRadius: "50%",
                                padding: "8px",
                                fontSize: "12px",
                              }}
                            >
                              <Plus size={12} />
                            </Button>
                          )}

                          {paymentDetails.length > 1 &&
                            index > 0 &&
                            (!editData ||
                              editData?.paymentDetails[index]?.paymentStatus ===
                                "Payment Due") && (
                              <Button
                                color="danger"
                                onClick={() => removePaymentDetail(index)}
                                style={{
                                  borderRadius: "50%",
                                  padding: "8px",
                                  fontSize: "12px",
                                }}
                              >
                                <Trash2 size={12} />
                              </Button>
                            )}
                        </>
                      )}
                  </Col>
                </Row>
              ))}
            </FormGroup>
          </Col>

          {/* vendor bank details */}
          <>
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorName">
                  Vendor name*
                </Label>
                <Select
                  id="vendorName"
                  name="vendorName"
                  options={opexVendorData?.getOpexVendors?.map((vendor) => ({
                    label: vendor.vendorName,
                    value: vendor._id,
                  }))}
                  value={{
                    label: vendorName,
                    value:
                      opexVendorData?.getOpexVendors?.find(
                        (vendor) => vendor.vendorName === vendorName
                      )?._id || "",
                  }}
                  onChange={handleVendorChange}
                  isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="bankAccountNumber">
                  Vendor bank account number
                </Label>
                <Input
                  type="text"
                  value={bankAccountNumber}
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  disabled
                  placeholder="Add Bank Account Number"
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="ifscCode">
                  Vendor bank IFSC code
                </Label>
                <Input
                  type="text"
                  value={ifscCode}
                  id="ifscCode"
                  name="ifscCode"
                  disabled
                  placeholder="Add Bank IFSC Code"
                  onChange={(e) => setIfscCode(e.target.value)}
                />
              </FormGroup>
            </Col>
          </>

          {/* image uploads */}
          {(bankDetailImageList?.length > 0 || userRole === "Ground Staff") && (
            <Col sm="12">
              <Label className="form-label" for="bankDetailImageList">
                <strong>Bank detail image</strong>
              </Label>
              <AttachmentComponent
                imageList={bankDetailImageList}
                setImageList={setBankDetailsImageList}
                editData={dummyState}
                uploadType={uploadTypeBankDetail}
                userRole={userRole}
                userDepartment={userDepartment}
              />
            </Col>
          )}
          {(poAttachmentList?.length > 0 || userRole === "Ground Staff") && (
            <Col sm="12">
              <Label className="form-label" for="poAttachmentList">
                <strong> PI/Estimate</strong>
              </Label>
              <AttachmentComponent
                imageList={poAttachmentList}
                setImageList={setPoAttachmentList}
                editData={dummyStatePo}
                uploadType={uploadTypePo}
                userRole={userRole}
                userDepartment={userDepartment}
              />
            </Col>
          )}
          <>
            <Col sm="12">
              <div className="d-flex align-items-center">
                <Label className="form-label mr-3" for="bankDetailImageList">
                  <strong>Invoice details</strong>
                </Label>
                {editData?.invoiceAttachment.length === 0 &&
                  userRole === "Ground Staff" && (
                    <Button.Ripple
                      color="success"
                      size="sm"
                      className=""
                      onClick={handleUploadPendingInvoice}
                    >
                      Upload pending invoice
                    </Button.Ripple>
                  )}
              </div>
              <AttachmentComponent
                imageList={invoiceAttachmentList}
                setImageList={setInvoiceAttachmentList}
                editData={dummyStateInvoice}
                uploadType={uploadTypeInvoice}
                userRole={userRole}
                userDepartment={userDepartment}
              />
            </Col>

            {/* status dropdown */}
          </>
          {((userRole === "Area Manager" && editData?.status === "Pending") ||
            (userRole === "Regional Manager" &&
              editData?.status === "AM Approved") ||
            (userRole === "CEO" && editData?.status === "RM Approved")) && (
            <Col sm="12">
              <FormGroup className="w-100 mt-1">
                <Label className="form-label" for="status">
                  Status*
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="status"
                  name="status"
                  isDisabled={isSelectDisabled()}
                  options={options}
                  onChange={(value) => setStatus(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          )}

          {/* Conditional rendering and remarks parts */}
          {userRole === "Area Manager" && editData?.status === "Pending" && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="amRemark">
                  Area mananger remark*
                </Label>
                <Input
                  type="textarea"
                  value={amRemark}
                  disabled={editData?.amRemark}
                  id="amRemark"
                  name="amRemark"
                  placeholder="Area Manager remark"
                  onChange={(e) => setAmRemark(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}

          {userRole === "Regional Manager" &&
            editData?.status === "AM Approved" && (
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="rmRemark">
                    Regional mananger remark*
                  </Label>
                  <Input
                    type="textarea"
                    value={rmRemark}
                    disabled={editData?.rmRemark}
                    id="rmRemark"
                    name="rmRemark"
                    placeholder="Regional Manager Remark"
                    onChange={(e) => setRmRemark(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          {userRole === "CEO" && editData?.status === "RM Approved" && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="ceoRemark">
                  CEO remark*
                </Label>
                <Input
                  type="textarea"
                  value={ceoRemark}
                  disabled={editData?.ceoRemark}
                  id="ceoRemark"
                  name="ceoRemark"
                  placeholder="CEO Remark"
                  onChange={(e) => setCeoRemark(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}

          {/* Finance Entry Part Start Here */}
          <>
            {(editData?.status === "Payment Done" ||
              editData?.status === "Payment Due" ||
              editData?.status === "Payment Rejected") &&
              editData?.paymentDetails?.length > 0 &&
              editData.paymentDetails
                .filter((paymentDetail) => {
                  if (userDepartment !== "Finance") {
                    return (
                      paymentDetail.paymentStatus === "Payment Done" ||
                      paymentDetail.paymentStatus === "Payment Due" ||
                      paymentDetail.paymentStatus === "Payment Rejected"
                    );
                  }
                  return true;
                })
                .map((paymentDetail, index) => {
                  const currentStatus = currentPaymentStatuses[index];
                  const isDisabled =
                    paymentDetail?.paymentStatus === "Payment Done" ||
                    paymentDetail?.paymentStatus === "Payment Rejected" ||
                    userDepartment !== "Finance";

                  return (
                    <div key={index} className="">
                      <span
                        style={{
                          backgroundColor: "#d4edda",
                          color: "#155724",
                          padding: "5px",
                          marginLeft: "12px",
                          borderRadius: "3px",
                          display: "inline-block",
                          fontWeight: "bold",
                          marginBottom: "5px",
                        }}
                      >
                        Payment Detail {index + 1}
                      </span>
                      <div id={`paymentDetail-${index}`} className="p-1">
                        <Row>
                          <Col sm="12">
                            <FormGroup>
                              <Label
                                className="form-label"
                                for={`paymentStatus-${index}`}
                              >
                                Payment Status
                              </Label>
                              <Select
                                isClearable={false}
                                isSearchable={true}
                                type="select"
                                options={paymentStatusFinanceList}
                                id={`paymentStatus-${index}`}
                                name={`paymentStatus-${index}`}
                                value={paymentStatusFinanceList.find(
                                  (option) => option.value === currentStatus
                                )}
                                onChange={(option) =>
                                  handleStatusChange(
                                    index,
                                    option ? option.value : ""
                                  )
                                }
                                isDisabled={isDisabled}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="12">
                            <FormGroup row>
                              <Col sm="6">
                                <Label
                                  className="form-label"
                                  for={`amountToPay-${index}`}
                                >
                                  Amount to Pay
                                </Label>
                                <Input
                                  type="number"
                                  value={paymentDetail.amountToPay}
                                  disabled
                                  id={`amountToPay-${index}`}
                                  name={`amountToPay-${index}`}
                                  placeholder="Amount to Pay"
                                />
                              </Col>
                              <Col sm="6">
                                <Label
                                  className="form-label"
                                  for={`dueDate-${index}`}
                                >
                                  Due Date
                                </Label>
                                <Input
                                  type="text"
                                  value={
                                    paymentDetail.dueDate
                                      ? new Date(
                                          paymentDetail.dueDate
                                        ).toLocaleDateString()
                                      : ""
                                  }
                                  disabled
                                  id={`dueDate-${index}`}
                                  name={`dueDate-${index}`}
                                  placeholder="Due Date"
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                        </Row>

                        {/* Editable Fields */}
                        {currentStatus !== "Payment Rejected" && (
                          <>
                            <Row>
                              <Col sm="6">
                                <FormGroup>
                                  <Label
                                    className="form-label"
                                    for={`paymentDate-${index}`}
                                  >
                                    Payment Date
                                  </Label>
                                  <Flatpickr
                                    className="form-control bg-white border-dark text-dark"
                                    value={paymentDetail.paymentDate}
                                    id={`paymentDate-${index}`}
                                    placeholder="Add Payment Date"
                                    // options={{
                                    //   minDate: "today",
                                    //   dateFormat: "Y-m-d",
                                    // }}

                                    options={{
                                      minDate: editData?._id
                                        ? undefined
                                        : "today",

                                      // defaultDate: paymentDetail.dueDate
                                      //   ? new Date(paymentDetail.dueDate)
                                      //   : new Date(),
                                    }}
                                    onChange={(date) =>
                                      handlePaymentDetailChange(
                                        index,
                                        "paymentDate",
                                        date[0]
                                      )
                                    }
                                    required
                                    disabled={isDisabled}
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm="6">
                                <FormGroup>
                                  <Label
                                    className="form-label"
                                    for={`bankRefNumber-${index}`}
                                  >
                                    Bank Reference Number
                                  </Label>
                                  <Input
                                    type="text"
                                    value={paymentDetail.bankRefNumber}
                                    id={`bankRefNumber-${index}`}
                                    name={`bankRefNumber-${index}`}
                                    placeholder="Add Bank Reference Number"
                                    onChange={(e) =>
                                      handlePaymentDetailChange(
                                        index,
                                        "bankRefNumber",
                                        e.target.value
                                      )
                                    }
                                    disabled={isDisabled}
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm="6">
                                <FormGroup>
                                  <Label
                                    className="form-label"
                                    for={`amountPaid-${index}`}
                                  >
                                    Amount Paid
                                  </Label>
                                  <Input
                                    type="number"
                                    value={paymentDetail.amountPaid}
                                    id={`amountPaid-${index}`}
                                    name={`amountPaid-${index}`}
                                    placeholder="Amount Paid"
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) =>
                                      handlePaymentDetailChange(
                                        index,
                                        "amountPaid",
                                        e.target.value
                                      )
                                    }
                                    disabled={isDisabled}
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm="6">
                                <FormGroup>
                                  <Label
                                    className="form-label"
                                    for={`tdsAmount-${index}`}
                                  >
                                    TDS Amount
                                  </Label>
                                  <Input
                                    type="number"
                                    value={paymentDetail.tdsAmount}
                                    id={`tdsAmount-${index}`}
                                    name={`tdsAmount-${index}`}
                                    placeholder="Add TDS Amount"
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) =>
                                      handlePaymentDetailChange(
                                        index,
                                        "tdsAmount",
                                        e.target.value
                                      )
                                    }
                                    disabled={isDisabled}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}

                        <Row>
                          <Col sm="12">
                            <FormGroup>
                              <Label
                                className="form-label"
                                for={`financeTeamRemark-${index}`}
                              >
                                Finance Team Remark
                              </Label>
                              <Input
                                type="textarea"
                                value={paymentDetail.financeTeamRemark}
                                id={`financeTeamRemark-${index}`}
                                name={`financeTeamRemark-${index}`}
                                placeholder="Remark"
                                disabled={isDisabled}
                                onChange={(e) =>
                                  handlePaymentDetailChange(
                                    index,
                                    "financeTeamRemark",
                                    e.target.value
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
          </>

          {(editData?.status === "Payment Done" ||
            userDepartment === "Finance" ||
            invoicePending === true) && (
            <Col sm="12">
              <hr />
              <Label>Payment attachment</Label>
              <AttachmentComponent
                imageList={paymentAttachmentList}
                setImageList={setPaymentAttachmentList}
                editData={dummyStatePayment}
                uploadType={uploadTypePayment}
                userRole={userRole}
                userDepartment={userDepartment}
              />
            </Col>
          )}

          {/* Invoice Pending Checkbox */}
          {(userDepartment === "Finance" || invoicePending) && (
            <Col sm="12">
              <CustomInput
                type="checkbox"
                label="Invoice pending"
                style={{
                  position: "absolute",
                  marginLeft: "10px",
                  top: "10px",
                }}
                id="invoicePendingCheck"
                checked={invoicePending}
                onChange={() =>
                  userDepartment === "Finance" &&
                  setInvoicePending(!invoicePending)
                } // Only Finance can toggle the checkbox
                disabled={userDepartment !== "Finance"} // Non-finance users see it as read only
              />
            </Col>
          )}

          {/* Invoice Pending Remark */}
          {(userDepartment === "Finance" || invoicePending) &&
            invoicePending && (
              <Col sm="12">
                <FormGroup>
                  <Label>Invoice pending remark</Label>
                  <Input
                    type="textarea"
                    id="invoicePendingRemark"
                    value={invoicePendingRemark}
                    onChange={(e) =>
                      userDepartment === "Finance" &&
                      setInvoicePendingRemark(e.target.value)
                    } // Only Finance can modify the remark
                    placeholder="Enter remarks here"
                    disabled={userDepartment !== "Finance"} // Non-finance users see it as read-only
                  />
                </FormGroup>
              </Col>
            )}

          <Col sm="12">
            <hr />
            <Table bordered>
              <tbody>
                {editData?.status && (
                  <tr>
                    <td>
                      <Label className="form-label" for="status">
                        <strong>Current status:</strong>
                      </Label>
                    </td>
                    <td>{editData?.status}</td>
                  </tr>
                )}
                {editData?.amRemark && (
                  <tr>
                    <td>
                      <Label className="form-label" for="amRemark">
                        <strong>AM remark:</strong>
                      </Label>
                    </td>
                    <td>{editData?.amRemark}</td>
                  </tr>
                )}
                {editData?.rmRemark && (
                  <tr>
                    <td>
                      <Label className="form-label" for="rmRemark">
                        <strong>RM remark:</strong>
                      </Label>
                    </td>
                    <td>{editData?.rmRemark}</td>
                  </tr>
                )}
                {editData?.ceoRemark && (
                  <tr>
                    <td>
                      <Label className="form-label" for="ceoRemark">
                        <strong>CEO remark:</strong>
                      </Label>
                    </td>
                    <td>{editData?.ceoRemark}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>

          {editData?.logs && (
            <Col sm="12">
              <hr />
              <FormGroup className="w-100">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>
                <br />
                <ul style={{ listStylePosition: "outside" }}>
                  {logs.map((log, index) => (
                    <li key={index}>
                      <Label className="form-label">{log}</Label>
                    </li>
                  ))}
                </ul>
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </GoPaymentsSlideModal>
  );
};

export default AddEditModal;
