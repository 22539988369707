import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { Edit } from "react-feather";
import { CSVLink } from "react-csv";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Booking ID",
  "Res ID",
  "Guest Name",
  "Hostel Name",
  "Category",
  "Title",
  "Quantity",
  "Amount (inc. GST)",
  "Checkin-out Date",
  "Booking Date/Time",
  "Delivery Date/Time",
  "Status",
  "Rating",
];

const AddonBookingsTable = ({
  openModal,
  setId,
  data,
  // exportData,
  // headers,
  dataCount,
  totalRevenue,
}) => {
  // const role = localStorage.getItem("role");

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>
                  Services CMS ({dataCount} Services) | Total Revenue (
                  {totalRevenue?.toFixed(3)})
                </h2>
              </div>
            </CardTitle>

            {/* Have added the condition because we have to disable the export button for Ground Operation */}
            {/* {role !== "Ground Staff" && (
              <CSVLink
                headers={headers}
                data={exportData}
                filename={`service_list.csv`}
              >
                <Button.Ripple
                  style={{
                    height: "40px",
                    marginTop: "22px",
                    marginLeft: "40px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="success"
                  appearance="success"
                  type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            )} */}
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>

            <tbody>
              {/* {tableData?.map((item) => { */}
              {data.map((item) => {
                const bookingDate = new Date(item?.createdAt);
                const bookingTime = new Date(item?.createdAt);
                const deliveryDate = new Date(item?.deliveryDate);
                const deliveryTime = new Date(item?.deliveryTime);
                const checkinDate = new Date(item?.checkinDate);
                const checkoutDate = new Date(item?.checkoutDate);

                return (
                  <tr key={uuidv4()}>
                    <td style={{ width: "5%" }}>
                      <Button
                        href="/"
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td>{item?.bookingId}</td>
                    <td>{item?.reservationId}</td>
                    {/* <td>
                      {checkoutDate.toLocaleDateString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}
                    </td> */}
                    <td>{item?.guestDetails?.GuestName}</td>
                    <td>{item?.hostel?.name}</td>
                    <td>{item?.category}</td>
                    <td>{item?.title}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.totalPrice.toFixed(2)}</td>
                    <td>
                      {checkinDate.toLocaleDateString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}{" "}
                      {checkoutDate.toLocaleDateString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}
                    </td>
                    <td>
                      {bookingDate.toLocaleDateString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}{" "}
                      {bookingTime.toLocaleTimeString()}
                    </td>
                    <td>
                      {deliveryDate.toLocaleDateString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}{" "}
                      {deliveryTime.toLocaleTimeString()}
                    </td>
                    <td>{item?.status}</td>
                    <td>{item?.rating}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default AddonBookingsTable;
