import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation, useQuery } from "@apollo/client";


export const UPDATE_MERCHANDISE_ORDER = gql`
mutation updateMerchandiseOrders($id:ID, $updateData:updateData){
    updateMerchandiseOrders(id:$id, updateData:$updateData){
        status
    }
}
`

export const GET_MERCHANDISE_XLSX_REPORT = gql`
    mutation getMerchandiseXlsxReport($filter:merchFilter){
    getMerchandiseXlsxReport(filter:$filter)
    }
`


export const useUpdateMerchandiseOrders = () => {
    const [MerchandiseOrderUpdate, { loading, error, data, refetch }] =
        useMutation(UPDATE_MERCHANDISE_ORDER);

    useLoadingHandler(loading);

    const updateMerchandiseOrderHandler = (id, updateData) => {
        MerchandiseOrderUpdate({
            variables: { id, updateData },
        });
    };

    return [updateMerchandiseOrderHandler, { loading, error, data, refetch }];
};

export const useGetMerchandiseReport = () => {
    const [getMerchandiseReport, { loading, error, data, refetch }] =
        useMutation(GET_MERCHANDISE_XLSX_REPORT);
    useLoadingHandler(loading);
    const getMerchandiseReportHandler = (filter) => {
        getMerchandiseReport({
            variables: {
                filter,
            },
        });
    };
    return [getMerchandiseReportHandler, { data, refetch, loading, error }];
};