import React, { useEffect, useState } from "react";
import { Trash } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateWorkationPackage } from "@api";
import { useParams } from "react-router";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";

const ThingsToknow = () => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [highlight, setHighlight] = useState([
    {
      id: uuidv4(),
      heading: "",
      textlist: EditorState.createEmpty(),
    },
  ]);
  const [updateWorkationPackageHandler, { data: updatdedData }] =
    useUpdateWorkationPackage();

  useEffect(() => {
    if (updatdedData?.updateWorkationPackage?._id) {
      toaster("success", "Submitted successfully");
    }
  }, [updatdedData]);

  useEffect(() => {
    if (state?.tableData?.thingsToKnow?.length) {
      let upadtedHighlight = state?.tableData?.thingsToKnow?.map((item) => {
        return {
          id: uuidv4(),
          heading: item?.heading,
          textlist: htmlToDraftConvert(item?.textlist),
        };
      });
      setHighlight(upadtedHighlight);
    }
  }, [state]);

  const handleChangeHighlightHeading = (heading, id) =>
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, heading: heading } : item
      )
    );

  const handleChangeHighlightTextlist = (textlist, id) =>
    setHighlight(
      highlight.map((item) =>
        item.id === id ? { ...item, textlist: textlist } : item
      )
    );

  const handleRemoveHighlight = (id) =>
    setHighlight(highlight.filter((item) => item.id !== id));

  const handleAddHighlight = () => {
    if (highlight.length < 3) {
      setHighlight([
        ...highlight,
        { id: uuidv4(), heading: "", textlist: EditorState.createEmpty() },
      ]);
    } else {
      toaster("error", "Max 3 are allowed");
    }
  };

  const submitHandler = (e) => {
    let isValid = false;
    e.preventDefault();
    highlight.forEach((item) => {
      delete item.id;
    });

    let updatedArray = highlight.map((item) => {
      let textlist = inputDataConvertor(item?.textlist);
      return { ...item, textlist: textlist };
    });

    const data = {
      thingsToKnow: updatedArray,
    };

    updatedArray?.forEach((item) => {
      if (item?.heading && item?.textlist) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          thingsToKnow: updatedArray,
        })
      );
      updateWorkationPackageHandler(id, data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <Row className='mt-2'>
          <Col>
            <Label className='form-label mt-2' for={"highlight"}>
              Things To Know
            </Label>
          </Col>
          {highlight.map(({ heading, textlist, id }) => (
            <Col className='d-flex align-items-end' sm='12' key={id}>
              <FormGroup className='w-100 mt-2'>
                <Input
                  type='text'
                  value={heading}
                  id={`name-${id}`}
                  name='name'
                  placeholder='Heading'
                  onChange={(e) =>
                    handleChangeHighlightHeading(e.target.value, id)
                  }
                />
                <div className='mt-1 '>
                  <MyEditor
                    editorState={textlist}
                    setEditorState={(val) =>
                      handleChangeHighlightTextlist(val, id)
                    }
                  />
                </div>
              </FormGroup>
              {highlight.length > 1 ? (
                <>
                  <Button.Ripple
                    className='btn-icon rounded-circle ml-1'
                    color='light'
                    type='button'
                    size='sm'
                    onClick={() => handleRemoveHighlight(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          ))}
        </Row>

        <SaveDiscardBtnGroup addAction={handleAddHighlight} />
      </form>
    </>
  );
};

export default ThingsToknow;
