import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import DetailsForm from "./DetailsForm";
import Media from "./Media";
import PhotosForm from "./PhotosForm";
import SEOForm from "./SEOForm";
import HostelsForm from "./HostelsForm";
import CmsTabs from "@components/Cms/CmsTabs";
import { useDispatch } from "react-redux";
import { useGetDestinationById } from "../../../../api/dataSource";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import NearByHostelsForm from "./NearByHostelsForm";
import Blog from "./Blog";
import FamousPlaces from "./FamousPlaces"
import HowToReach from "./HowToReach"
import Itinerary from "./Itinerary"
import MonthWiseTemperature from "./MonthWiseTemperature"
import ThingsTodo from "./ThingsTodo"
import EventsToDo from "./EventsToDo"

const tabOptions = [
  {
    id: 0,
    title: "Destination Details",
    formComponent: <DetailsForm />,
  },
  {
    id: 1,
    title: "Important Details",
    formComponent: <Media />,
  },
  {
    id: 2,
    title: "Photos",
    formComponent: <PhotosForm />,
  },
  {
    id: 3,
    title: "SEO",
    formComponent: <SEOForm />,
  },
  {
    id: 4,
    title: "Hostels",
    formComponent: <HostelsForm />,
  },
  {
    id: 5,
    title: "Near by Hostels",
    formComponent: <NearByHostelsForm />,
  },
  {
    id: 6,
    title: "Blogs",
    formComponent: <Blog />,
  },
  {
    id: 7,
    title: "Famous Places",
    formComponent: <FamousPlaces />,
  },
  {
    id: 8,
    title: "How To Reach",
    formComponent: <HowToReach />,
  },
  {
    id: 9,
    title: "Itinerary",
    formComponent: <Itinerary />,
  },
  // {
  //   id: 10,
  //   title: "Month Wise Temperature",
  //   formComponent: <MonthWiseTemperature />,
  // },
  {
    id: 11,
    title: "Things to do",
    formComponent: <ThingsTodo />,
  },
  {
    id: 12,
    title: "Events to do",
    formComponent: <EventsToDo />,
  },
];

const AddDestination = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [active, setActive] = useState(0);
  const { data } = useGetDestinationById(id);

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getDestinationById) {
      dispatch(tableDataHandler(data?.getDestinationById));
    }
  }, [data, dispatch]);

  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle>
                  {active !== 0 && "Destination Details - "}
                  {tabOptions.find((item) => item?.id === active).title}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item?.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default AddDestination;
