import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
// import TableList from "@components/common/TableList";
import TableList from "../common/TableList";
import { useGetAllPaginatedPressMention } from "../../../api/dataSource";
import { dateFormatHandler } from "@utils";
import { TABLE_TYPE } from "../common/TableList/constant";

const tableHeaderList = ["Action", "Title", "Published Date", "Status"];

const PressMention = ({
  filterData,
  AddEditModal,
  limit,
  setLimit,
  page,
  setPage,
}) => {
  // const [limit, setLimit] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  // const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { data, refetch } = useGetAllPaginatedPressMention(
    limit,
    page,
    filterData
  );

  const history = useHistory();

  const handleAdd = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    refetch();
  }, [page]);
  useEffect(() => {
    if (data) {
      let pressMentionList = [];
      data?.getPaginatedPressMentionList?.data?.map((item) => {
        pressMentionList.push({
          id: item._id,
          title: item?.title,
          publishedDate: item?.publishedDate,
          status: item?.status,
        });
      });

      setTableData(pressMentionList);
      setTotalPage(
        Math.ceil(data?.getPaginatedPressMentionList?.count / limit)
      );
    }
  }, [data]);

  useEffect(() => {
    if (editId && data?.getPaginatedPressMentionList?.data?.length) {
      let editData = data?.getPaginatedPressMentionList?.data.filter(
        (item) => item._id === editId
      );
      setEditData(editData);
    } else {
      setEditData(null);
    }
  }, [editId, data]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/cms/edit/pressMention/${id}`);
    } else {
      setEditId(id);
    }
  };

  return (
    <div>
      <TableList
        handleAdd={AddEditModal ? handleAdd : null}
        // filterHeader="All Press Mention"
        tableHeader="Press Mention"
        type={TABLE_TYPE.PRESSMENTION}
        tableData={tableData}
        tableHeaderList={tableHeaderList}
        openModal={() => setOpenModal(!openModal)}
        handleEdit={handleEdit}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
        refetch={refetch}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={() => setOpenModal(!openModal)}
          refetch={refetch}
          editData={editData}
          setEditId={setEditId}
        />
      )}
    </div>
  );
};

export default PressMention;
