// import React, { useState, useEffect } from "react";
// import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_green.css";
// import Select from "react-select";
// import { Container, Row, Col, FormGroup, Label, Button } from "reactstrap";
// import {
//   useGetServiceCategoryWiseRevenue,
//   useHostelList,
// } from "../../../api/dataSource";
// import CategoryWiseTable from "./CategoryWiseTable";
// import { selectThemeColors } from "@utils";

// const IndexPage = () => {
//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);
//   const [hostel, setHostel] = useState(null);
//   const [hostelsList, setHostelsList] = useState([]);

//   const { data: allHostelsData } = useHostelList();

//   useEffect(() => {
//     const today = new Date();
//     const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

//     setFromDate(startOfMonth);
//     setToDate(today);
//   }, []);

//   useEffect(() => {
//     const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
//     if (allHostelsData?.getHostelList?.length) {
//       const filteredHostels =
//         allowedHostels !== null && allowedHostels?.length !== 0
//           ? allowedHostels?.map((hostelID) => {
//               return allHostelsData?.getHostelList?.find(
//                 (item) => item?._id === hostelID
//               );
//             })
//           : allHostelsData?.getHostelList;
//       const hostelsList = filteredHostels?.map((item) => ({
//         label: item?.name,
//         value: item?._id,
//       }));
//       setHostelsList(hostelsList);
//     }
//   }, [allHostelsData?.getHostelList]);

//   const { data, loading, error, refetch } = useGetServiceCategoryWiseRevenue(
//     hostel?.value,
//     fromDate,
//     toDate
//   );

//   const handleReset = () => {
//     setFromDate(null);
//     setToDate(null);
//     setHostel(null);
//     refetch();
//   };

//   useEffect(() => {
//     if (fromDate && toDate) {
//       refetch();
//     }
//   }, [fromDate, toDate, hostel, refetch]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error.message}</p>;

//   const revenueData = data?.getServiceCategoryWiseRevenue || [];

//   return (
//     <Container style={{ marginTop: "1rem" }}>
//       <Row style={{ marginBottom: "1rem", alignItems: "center" }}>
//         <Col md={4} sm={12}>
//           <FormGroup>
//             <Label for="hostel">Hostel Name</Label>
//             <Select
//               isClearable
//               isSearchable
//               theme={selectThemeColors}
//               id="hostel"
//               name="hostel"
//               value={hostel}
//               options={hostelsList}
//               onChange={(value) => setHostel(value)}
//               className="basic-multi-select"
//               classNamePrefix="select"
//             />
//           </FormGroup>
//         </Col>
//         <Col md={4} sm={12}>
//           <FormGroup>
//             <Label for="fromDate">From Date:</Label>
//             <Flatpickr
//               id="fromDate"
//               className="form-control"
//               value={fromDate}
//               onChange={(date) => setFromDate(date[0])}
//               placeholder="From Date"
//               options={{ dateFormat: "Y-m-d" }}
//             />
//           </FormGroup>
//         </Col>
//         <Col md={4} sm={12}>
//           <FormGroup>
//             <Label for="toDate">To Date:</Label>
//             <Flatpickr
//               id="toDate"
//               className="form-control"
//               value={toDate}
//               onChange={(date) => setToDate(date[0])}
//               placeholder="To Date"
//               options={{ dateFormat: "Y-m-d" }}
//             />
//           </FormGroup>
//         </Col>
//         <Col sm={12} className="d-flex justify-content-end">
//           <Button color="danger" onClick={handleReset}>
//             Reset
//           </Button>
//         </Col>
//       </Row>
//       <CategoryWiseTable data={revenueData} />
//     </Container>
//   );
// };

// export default IndexPage;

import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import Select from "react-select";
import { Container, Row, Col, FormGroup, Label, Button } from "reactstrap";
import {
  useGetServiceCategoryWiseRevenue,
  useHostelList,
} from "../../../api/dataSource";
import CategoryWiseTable from "./CategoryWiseTable";
import { selectThemeColors } from "@utils";
import { CSVLink } from "react-csv";

const IndexPage = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [hostel, setHostel] = useState(null);
  const [hostelsList, setHostelsList] = useState([]);

  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    setFromDate(startOfMonth);
    setToDate(today);
  }, []);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      const hostelsList = filteredHostels?.map((item) => ({
        label: item?.name,
        value: item?._id,
      }));
      setHostelsList(hostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  const { data, loading, error, refetch } = useGetServiceCategoryWiseRevenue(
    hostel?.value,
    fromDate,
    toDate
  );

  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setHostel(null);
    refetch();
  };

  useEffect(() => {
    if (fromDate && toDate) {
      refetch();
    }
  }, [fromDate, toDate, hostel, refetch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const revenueData = data?.getServiceCategoryWiseRevenue || [];

  const csvHeaders = [
    { label: "Category", key: "category" },
    { label: "Booking Count", key: "serviceCount" },
    { label: "Revenue", key: "serviceRev" },
  ];

  const csvData = revenueData.map((item) => ({
    category: item.category,
    serviceCount: item.serviceCount,
    serviceRev: Number(item.serviceRev).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  }));

  return (
    <Container style={{ marginTop: "1rem" }}>
      <Row style={{ marginBottom: "1rem", alignItems: "center" }}>
        <Col md={4} sm={12}>
          <FormGroup>
            <Label for="hostel">Hostel Name</Label>
            <Select
              isClearable
              isSearchable
              theme={selectThemeColors}
              id="hostel"
              name="hostel"
              value={hostel}
              options={hostelsList}
              onChange={(value) => setHostel(value)}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col md={4} sm={12}>
          <FormGroup>
            <Label for="fromDate">From Date:</Label>
            <Flatpickr
              id="fromDate"
              className="form-control"
              value={fromDate}
              onChange={(date) => setFromDate(date[0])}
              placeholder="From Date"
              options={{ dateFormat: "Y-m-d" }}
            />
          </FormGroup>
        </Col>
        <Col md={4} sm={12}>
          <FormGroup>
            <Label for="toDate">To Date:</Label>
            <Flatpickr
              id="toDate"
              className="form-control"
              value={toDate}
              onChange={(date) => setToDate(date[0])}
              placeholder="To Date"
              options={{ dateFormat: "Y-m-d" }}
            />
          </FormGroup>
        </Col>
        <Col sm={12} className="d-flex justify-content-end">
          <Button
            color="danger"
            onClick={handleReset}
            style={{ marginRight: "1rem" }}
          >
            Reset
          </Button>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={`service_events_category_wise_report.csv`}
          >
            <Button color="success">Export</Button>
          </CSVLink>
        </Col>
      </Row>
      <CategoryWiseTable data={revenueData} />
    </Container>
  );
};

export default IndexPage;
