import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
  Badge,
  Button,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const tableHeaderData = ["FullName", "Email", "Phone number", "Gender"];

const StepoutUserTable = ({ data }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Step out Users</h2>
              </div>
            </CardTitle>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>

            <tbody>
              {data?.map((curr) => (
                <tr key={uuidv4()}>
                  <td>{curr.fullName}</td>
                  <td>{curr.email}</td>
                  <td>{curr.mobile}</td>
                  <td>{curr?.gender !== null ? curr.gender : "--"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default StepoutUserTable;
