import React, { Fragment, useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import { X, Link } from "react-feather";
import { useSelector } from "react-redux";
import { useUploadImage } from "@api";
import confirmDelete from "@components/common/confirmDelete";
import "./index.scss";
import { Col, Row } from "reactstrap";

const ConnectDocumentSection = ({
  imageList,
  setImageList,
  editData,
  uploadType,
  landingPage,
}) => {
  const [imageLink, setImageLink] = useState([]);

  const [uploadingType, setUploadingType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadImageHandler, { data: imageData, reset }] = useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".pdf"],
      maxFileSize: 2000000,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("complete", (result) => {
    setUploadingType(result?.successful[0]?.source, editData);

    result.successful[0]?.data &&
      uploadImageHandler(result?.successful[0]?.data, `Events_And_Activities`);
  });

  useEffect(() => {
    const newImage = {};

    uploadType
      ? (newImage.image = imageData?.uploadImage?.url)
      : (newImage.url = imageData?.uploadImage?.url);

    if (imageData?.uploadImage?.url) {
      setImageList((prev) => [newImage, ...prev]);

      reset();
      setUploadingType(null);
    }
  }, [imageData]);

  const removeImages = (image) => {
    const newSet = imageList?.filter((item) => {
      const imgUrl = item?.image ? item?.image : item?.url;
      return imgUrl !== image;
    });

    setImageList(newSet);
  };

  const handleRemoveImage = (image) => {
    confirmDelete(() => removeImages(image));
  };

  const openModal = (index, link) => {
    setShowModal(true);
    setImageLink({ index, link });
  };

  const closeModal = () => {
    setShowModal(false);
    setImageLink({});
  };

  const addImageLink = (index, link) => {
    let newImageList = [...imageList];
    const imgWithLink = { ...newImageList[index], link };

    newImageList[index] = imgWithLink;
    setImageList(newImageList);
  };

  return (
    <Row>
      <Col>
        <div className="imageSection__container">
          {uploadType?.map(({ value, label }) => (
            <div key={value}>
              {/* <h5 className="imageSection__header">
                <strong>Upload {label}</strong>
              </h5> */}
              <div className="imageSection__imagesContainer">
                {imageList?.map((item, index) => {
                  const image = item?.image || item?.url;
                  return (
                    <>
                      <div
                        key={index}
                        className="imagesection__imageBox"
                        style={{ width: "10em", height: "10em" }}
                      >
                        <img
                          className="imageSection__image"
                          src={image}
                          alt="Uploaded Document"
                          style={{
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(image, "_blank")}
                        />
                        <div className="imageSection_footer">
                          <div className="imageSection_icon imageSection__index">
                            {index + 1}
                          </div>
                          {landingPage && (
                            <div
                              onClick={() => openModal(index, item?.link)}
                              className="imageSection_icon imageSection__link"
                            >
                              <Link size={25} />
                            </div>
                          )}
                          <div
                            onClick={() => handleRemoveImage(image)}
                            className=" imageSection_icon imageSection__removeImage"
                          >
                            <X size={20} />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="relative">
                  <div className="">
                    <DragDrop
                      id={value}
                      width="10em"
                      height="10em"
                      locale={{
                        strings: {
                          dropHereOr: "Max 2 MB",
                        },
                      }}
                      uppy={uppy}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>
        {/* <div className="imageSection__container" style={{ display: "flex" }}>
          <div className="imageSection__imagesContainer">

          </div>
        </div> */}
      </Col>
    </Row>
  );
};

export default ConnectDocumentSection;
