import gql from "graphql-tag";

export const GET_PARTNER = gql`
  query getPartner {
    getPartner {
      _id
      banner
      whyHosteller
      zigZag
      howDoWe
    }
  }
`;
