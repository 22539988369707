import gql from "graphql-tag";

export const CREATE_OFFER = gql`
  mutation createOffer($OfferInput: OfferInput) {
    createOffer(OfferInput: $OfferInput) {
      success
      message
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation updateOffer($id: ID!, $OfferUpdate: OfferInput) {
    updateOffer(id: $id, OfferUpdate: $OfferUpdate) {
      _id
    }
  }
`;
