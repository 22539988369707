import { Globe, Circle } from "react-feather";

export default [
  {
    id: "webApp",
    title: "Web App",
    icon: <Globe size={20} />,
    badge: "light-warning",
    badgeText: "2",
    children: [
      {
        id: "webAppRequests",
        title: "Request",
        icon: <Circle size={12} />,
        navLink: "/webApp/request",
      },
      {
        id: "webAppHostelRequest",
        title: "Hostel Request",
        icon: <Circle size={12} />,
        navLink: "/webApp/hostelRequest",
      }
    ],
  },
];
