import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";

import { useResetAdminUserPassword } from "../../../../api/dataSource";
import toaster from "../../../components/common/Toaster/index";

function ChangePasswordModal({ setShowModal }) {
  const toggle = () => setShowModal(true);
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [resetAdminUserPasswordHandler, { data }] = useResetAdminUserPassword();

  const submitDataHandler = (e) => {
    let data = {
      email,
      oldPassword: password,
      newPassword,
      confirmNewPassword,
    };

    e.preventDefault();
    console.log(data, "FORM");
    resetAdminUserPasswordHandler(
      data.email,
      data.oldPassword,
      data.newPassword,
      data.confirmNewPassword,
    );
    toaster("success", `Password changed successfully`);
    setShowModal(false)
  };

  return (
    <div>
      <Modal isOpen={() => toggle}>
        <ModalHeader toggle={() => setShowModal(false)}>
          Change Password
        </ModalHeader>
        <ModalBody>
          <form onSubmit={submitDataHandler}>
            <Row>
              {/* // ! Email*/}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="email">
                    Email
                  </Label>
                  <Input
                    autoFocus={true}
                    type="email"
                    value={email}
                    id="email"
                    name="email"
                    placeholder="Add Email"
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </FormGroup>
              </Col>

              {/* // ! Password */}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="password">
                    Old Password{" "}
                    <span style={{ color: "#FF5733" }}>
                      (length should be	greater 6)
                    </span>
                  </Label>
                  <Input
                    autoFocus={true}
                    type="password"
                    value={password}
                    id="password"
                    name="password"
                    // placeholder="Add Old Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
              {/* // ! New Password */}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="newPassword">
                    New Password <span style={{ color: "#FF5733" }}>
                      (length should be greater 6)
                    </span>
                  </Label>
                  <Input
                    autoFocus={true}
                    type="password"
                    value={newPassword}
                    id="newPassword"
                    name="newPassword"
                    // placeholder="Add New Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
              {/* // ! Confirm New Password */}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="confirmNewPassword">
                    Confirm New Password <span style={{ color: "#FF5733" }}>
                      (length should be greater 6)
                    </span>
                  </Label>
                  <Input
                    autoFocus={true}
                    type="password"
                    value={confirmNewPassword}
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    // placeholder="Add New Password"
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col>
                <Input
                  type="submit"
                  style={{ backgroundColor: "#ffe700", color: "#000" }}
                />
              </Col>
            </Row>
          </form>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onSubmit={() => submitDataHandler}>
            Save
          </Button>{" "} */}
          {/* <Button color="danger" onClick={() => setShowModal(false)}>
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ChangePasswordModal;
