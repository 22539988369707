// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Badge,
} from "reactstrap";
import Overview from "./Overview";
import PurchaseAndFinance from "./PurchaseAndFinance";
import CmsTabs from "@components/Cms/CmsTabs";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetGOPurchaseRequestById,
  useGetVendorLastOrder,
} from "../../../../api/GOPurchaseRequest/Queries";
import { tableDataHandler } from "@store/Cms/cms.action";
import { useParams } from "react-router-dom";
import { pulse } from "./animation.css";

const tabOptions = [
  {
    id: 0,
    title: "Request Details",
    navLink: "/operations/goPurchase",
    formComponent: <Overview />,
  },
  {
    id: 1,
    title: "Payment Details",
    formComponent: <PurchaseAndFinance />,
  },
];

const AddGoPurchaseTabs = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [active, setActive] = useState(0);
  const state = useSelector((state) => state?.cms);

  const { data, refetch } = useGetGOPurchaseRequestById(id);

  const dbData = state?.tableData;
  const dbStatus = dbData?.status;
  
  // const { data: vendorLastOrderData } = useGetVendorLastOrder(
  //   dbData?.requestId ? dbData?.requestId : null,
  //   data?.getGOPurchaseRequestById?.hostel?._id,
  //   data?.getGOPurchaseRequestById?.vendor?._id
  // );

  // const vendorInfo = vendorLastOrderData?.getVendorLastOrder;

  // const dateOptions = {
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric",
  //   // hour: "numeric",
  //   // minute: "numeric",
  //   // second: "numeric",
  //   hour12: true,
  //   timeZone: "Asia/Kolkata",
  // };

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getGOPurchaseRequestById) {
      dispatch(tableDataHandler(data?.getGOPurchaseRequestById));
    }
    refetch();
  }, [data, dispatch]);

  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={tabOptions}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle>
                  Request Id{" "}
                  <Badge color={"light-info"}>{dbData?.requestId}</Badge> -
                  <span>&nbsp;</span>
                  Status{" "}
                  <Badge
                    color={
                      dbStatus?.includes("Approved") ||
                      dbStatus?.includes("Done") ||
                      dbStatus === "Request Completed"
                        ? "success"
                        : dbStatus?.includes("Rejected")
                        ? "danger"
                        : "warning"
                    }
                    className="pulse"
                  >
                    {dbStatus}
                  </Badge>
                  <span>&nbsp;</span>
                  {/* <b>
                    {tabOptions.find((item) => item.id === active).title}
                    <span>&nbsp;</span>Section
                  </b> */}
                </CardTitle>
                <br />
                <CardTitle>
                  {dbData?.totalAmount > 0 && (
                    <b>
                      <Badge color={"light-info"}>
                        {" "}
                        Total Amount - ₹{dbData?.totalAmount?.toFixed(2)}
                      </Badge>
                    </b>
                  )}

                  {dbData?.totalAmountPaid > 0 && (
                    <b>
                      <Badge color={"light-info"}>
                        {" "}
                        Total Amount Paid - ₹
                        {dbData?.totalAmountPaid?.toFixed(2)}
                      </Badge>
                    </b>
                  )}
                  {dbData?.totalTdsAmount > 0 && (
                    <b>
                      <Badge color={"light-info"}>
                        {" "}
                        Total TDS Amount - ₹{dbData?.totalTdsAmount?.toFixed(2)}
                      </Badge>
                    </b>
                  )}
                </CardTitle>
                {/* <br />
                <CardTitle>
                  {vendorInfo?.requestId && (
                    <b>
                      <Badge color={"light-info"}>
                        {`Order placed with same vendor on Date: ${new Intl.DateTimeFormat(
                          "en-IN",
                          dateOptions
                        ).format(
                          new Date(vendorInfo?.createdAt)
                        )}, RequestID: ${vendorInfo.requestId}`}
                      </Badge>
                    </b>
                  )}
                </CardTitle> */}
              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent}</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default AddGoPurchaseTabs;
