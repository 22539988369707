import React, { useEffect, useState } from "react";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import toaster from "@components/common/Toaster";
import {
  useUpdateHostel,
  useUpdateDestination,
  useUpdateBlogsBlog,
  useUpdateBlogCategory,
  useUpdateHomePageDetails,
} from "../../api/dataSource";
import SlideModal from "../../@core/components/common/slide-modal/index";
import { DragDrop } from "@uppy/react";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { useUploadImage } from "../../api/dataSource";

const AddEditModal = ({
  editData,
  open,
  handleToggle,
  selectModules,
  hostelRefetch,
  destinationRefetch,
  blogRefetch,
  blogCategoryRefetch,
  landingPageRefetch,
}) => {
  const [name, setName] = useState("");
  const [destination, setDestination] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [slug, setSlug] = useState("");
  const [seo, setSeo] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [previewArr, setPreviewArr] = useState([]);

  const [updateHostelHandler] = useUpdateHostel();

  const [updateDestinationHandler] = useUpdateDestination();

  const [updateBlogsBlogHandler] = useUpdateBlogsBlog();

  const [updateBlogCategoryHandler] = useUpdateBlogCategory();

  const [updateHomePageDetailsHandler] = useUpdateHomePageDetails();

  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();
  useEffect(() => {
    if (editData?._id) {
      setName(editData?.name);
      setDestination(editData?.destination);
      setBlogTitle(editData?.blogTitle);
      setCategoryName(editData?.categoryName);
      setSlug(editData?.slug);
      setMetaTitle(editData?.seo?.metaTitle);
      setMetaDesc(editData?.seo?.metaDesc);
      setKeywords(editData?.seo?.keywords);
      setPreviewArr([editData?.seo?.metaImage]);
    } else {
      setName("");
      setDestination("");
      setBlogTitle("");
      setCategoryName("");
      setSlug("");
      setSeo("");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      setPreviewArr([]);
    }
  }, [editData]);

  const onSubmitHandler = () => {
    let data = {
      seo: {
        metaTitle,
        metaDesc,
        keywords,
        metaImage: imageData?.uploadImage?.url,
      },
    };

    if (data?.seo?.metaTitle) {
      if (editData?._id) {
        if (selectModules?.label === "Hostels") {
          updateHostelHandler(editData?._id, data);
          hostelRefetch();
          toaster("success", "Hostel's SEO updated successfully");
        } else if (selectModules?.label === "Destinations") {
          updateDestinationHandler(editData?._id, data);
          destinationRefetch();
          toaster("success", "Destination's SEO updated successfully");
        } else if (selectModules?.label === "Blogs") {
          updateBlogsBlogHandler(editData?._id, data);
          blogRefetch();
          toaster("success", "Blogs SEO updated successfully");
        } else if (selectModules?.label === "Blogs Category") {
          updateBlogCategoryHandler(editData?._id, data);
          blogCategoryRefetch();
          toaster("success", "Blogs category SEO updated successfully");
        } else if (selectModules?.label === "Landing Page") {
          updateHomePageDetailsHandler(editData?._id, data);
          landingPageRefetch();
          toaster("success", "Landing pages SEO updated successfully");
        }
      }
      setDestination("");
      setBlogTitle("");
      setCategoryName("");
      setSlug("");
      setSeo("");
      setMetaTitle("");
      setMetaDesc("");
      setKeywords("");
      handleToggle();
    } else {
      toaster("error", "Please fill the details");
    }
  };

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppy.use(thumbnailGenerator);
  uppy.on("thumbnail:generated", (file, preview) => {
    setPreviewArr([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "Others/Seo");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading meta image: ${error.message}`);
  });

  const renderPreview = () => {
    if (previewArr?.length) {
      return previewArr?.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {selectModules?.label === "Hostels" ? (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="name">
                  Hostel
                </Label>
                <Input
                  type="text"
                  value={name}
                  id="name"
                  name="name"
                  disabled={editData?._id}
                />
              </FormGroup>
            </Col>
          ) : (
            <></>
          )}

          {selectModules?.label === "Destinations" ? (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="destination">
                  Destination
                </Label>
                <Input
                  type="text"
                  value={destination}
                  id="destination"
                  name="destination"
                  disabled={editData?._id}
                />
              </FormGroup>
            </Col>
          ) : (
            <></>
          )}

          {selectModules?.label === "Blogs" ? (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="blogTitle">
                  Blog
                </Label>
                <Input
                  type="text"
                  value={blogTitle}
                  id="blogTitle"
                  name="blogTitle"
                  disabled={editData?._id}
                />
              </FormGroup>
            </Col>
          ) : (
            <></>
          )}

          {selectModules?.label === "Blogs Category" ? (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="categoryName">
                  Blog category
                </Label>
                <Input
                  type="text"
                  value={categoryName}
                  id="categoryName"
                  name="categoryName"
                  disabled={editData?._id}
                />
              </FormGroup>
            </Col>
          ) : (
            <></>
          )}

          {selectModules?.label === "Landing Page" ? (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="slug">
                  Landing page
                </Label>
                <Input
                  type="text"
                  value={slug}
                  id="slug"
                  name="slug"
                  disabled={editData?._id}
                />
              </FormGroup>
            </Col>
          ) : (
            <></>
          )}

          <Col sm="11">
            <FormGroup className="w-100">
              <Label className="form-label" for="seo">
                SEO
              </Label>

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Title (ideal size 50 characters)
                </Label>
                <Input
                  type="text"
                  value={metaTitle}
                  id="metaTitle"
                  name="metaTitle"
                  placeholder="Add Meta Title"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
                <div style={{ textAlign: "right" }}>
                  <Label>Count: {metaTitle?.length}</Label>
                </div>
              </Col>
              <br />

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Meta Description (ideal size 150 characters)
                </Label>
                <Input
                  type="text"
                  value={metaDesc}
                  id="metaDesc"
                  name="metaDesc"
                  placeholder="Add Meta Desc"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
                <div style={{ textAlign: "right" }}>
                  <Label>Count: {metaDesc?.length}</Label>
                </div>
              </Col>
              <br />

              <Col sm="12">
                <Label className="form-label" for="seo">
                  Keywords
                </Label>
                <Input
                  type="text"
                  value={keywords}
                  id="keywords"
                  name="keywords"
                  placeholder="Add Keywords"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </Col>
              {selectModules?.label === "Landing Page" && (
                <Col sm="12">
                  <Label className="form-label" for="seo">
                    Meta Image
                  </Label>
                  <DragDrop
                    note="Image size should be less than 500 KB"
                    uppy={uppy}
                  />
                  {renderPreview()}
                </Col>
              )}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
