import { React, useEffect, useState } from "react";
import { useGetORMTicketsReport } from "../../api/dataSource";
import {
  Col,
  FormGroup,
  Label,
  Row,
  Table,
  Card,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import { dateFormatter, addDays } from "@utils";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { useHostelList } from "../../api/dataSource";
import { selectThemeColors } from "@utils";

const ORMTicketsReport = () => {
  //   const [fromDate, setFromDate] = useState(
  //     dateFormatter(addDays(dateFormatter(new Date()), -30))
  //   );
  //   const [toDate, setToDate] = useState(dateFormatter(new Date()));
  //   const { data: allHostelsData } = useHostelList();
  //   const [hostelsList, setHostelsList] = useState("");
  //   const [hostel, setHostel] = useState({
  //     label: "The Hosteller Delhi",
  //     value: "6230a0ec2b3ecc49305c4a95",
  //   });
  const [reviewDateTo, setReviewDateTo] = useState(null);
  const [reviewDateFrom, setReviewDateFrom] = useState(null);
  const [filterFields, setFilterFields] = useState({});
  const [hostelsList, setHostelsList] = useState([]);

  const [hostel, setHostel] = useState([]);
  const { data: allHostelsData } = useHostelList();

  const { data, refetch } = useGetORMTicketsReport(
    filterFields?.hostelIdArray,
    filterFields?.reviewDateFrom,
    filterFields?.reviewDateTo
  );

  const handleSearch = () => {
    const filterFields = {
      hostelIdArray: hostel?.map((hostel) => hostel?.value),
      reviewDateFrom,
      reviewDateTo,
    };

    setFilterFields(filterFields);
  };

  const handleReset = () => {
    setFilterFields(null);
    setReviewDateFrom(null);
    setReviewDateTo(null);
    setHostel([]);
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      HostelsList = allHostelsData.getHostelList.map((item) => ({
        label: item?.name,
        value: item?._id,
      }));
      console.log(HostelsList);
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  return (
    localStorage.getItem("modules")?.includes("ORM Reports") && (
      <>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Hostel</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Date Range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="reviewDate"
                placeholder="Review Date Range"
                options={{
                  mode: "range",
                }}
                value={[reviewDateFrom, reviewDateTo]}
                onChange={(date) => {
                  setReviewDateFrom(dateFormatter(date[0]));
                  setReviewDateTo(dateFormatter(date[1]));
                }}
              />
            </FormGroup>
          </Col>
          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
          {/* <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button> */}

          {/* <Col sm="2">
        <FormGroup className="w-100">
          <Label className="form-label" for="fromDate">
            From Date
          </Label>

          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={fromDate}
            onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
            id="fromDate"
            placeholder="From Date"
          />
        </FormGroup>
      </Col> */}
          {/* <Col sm="2">
        <FormGroup className="w-100">
          <Label className="form-label" for="toDate">
            To Date
          </Label>
          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={toDate}
            onChange={(date) => setToDate(dateFormatter(new Date(date)))}
            id="toDate"
            placeholder="To Date"
          />
        </FormGroup>
      </Col> */}
        </Row>
        <Row></Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>
                  <div>
                    <h2>Tickets Report</h2>
                  </div>
                </CardTitle>
              </CardHeader>
              <Table striped bordered>
                <thead className="text-center">
                  <tr>
                    <th colSpan="1" rowSpan="2">
                      Hostel Name
                    </th>
                    <th colSpan="1" rowSpan="2">
                      Tickets
                    </th>
                    <th colSpan="2">Tickets pending</th>
                    <th colSpan="2">Tickets Resolved</th>
                    <th colSpan="2">Avg Closure Time (HH:MM:SS)</th>
                  </tr>
                  <tr>
                    <th>Hostel</th>
                    <th>Cx</th>
                    <th>Hostel</th>
                    <th>Cx</th>
                    <th>Hostel</th>
                    <th>Cx</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.getTicketsReport?.map((item, index) => (
                    <tr className="text-center" key={index}>
                      <td>
                        <b>{item?.hostel}</b>
                      </td>
                      <td>{item?.tickets}</td>
                      <td>{item?.ticketsPending?.hostel}</td>
                      <td>{item?.ticketsPending?.cx}</td>
                      <td>{item?.ticketsResolved?.hostel}</td>
                      <td>{item?.ticketsResolved?.cx}</td>
                      <td>{item?.avgClosureTime?.hostel}</td>
                      <td>{item?.avgClosureTime?.cx}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </>
    )
  );
};
export default ORMTicketsReport;
