// ** React Imports
import { Fragment } from 'react';

// ** Dropdowns Imports
import UserDropdown from './UserDropdown';
import NavbarSearch from './NavbarSearch';

// ** Third Party Components
import { Sun, Moon,Menu } from 'react-feather';
import { NavItem, NavLink } from 'reactstrap';

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin,setMenuVisibility } = props;

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className='ficon' onClick={() => setSkin('light')} />;
    } else {
      return <Moon className='ficon' onClick={() => setSkin('dark')} />;
    }
  };

  return (
    <Fragment>
    <div className='bookmark-wrapper d-flex align-items-center'>
     <ul className='navbar-nav d-xl-none'>
        <NavItem className='mobile-menu mr-auto'>
          <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
            <Menu className='ficon' />
          </NavLink>
        </NavItem>
      </ul>
      </div>
      <ul className='nav navbar-nav align-items-center ml-auto'>
        <NavItem className='d-none d-lg-block'>
          <NavLink className='nav-link-style'>
            <ThemeToggler />
          </NavLink>
        </NavItem>
        <UserDropdown />
      </ul>
    </Fragment>
  );
};

export default ThemeNavbar;


        // <NavbarSearch />;

