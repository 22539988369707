// ! Coded by Ashwin

import gql from "graphql-tag";

export const GET_PAGINATED_DISPLAY_ADS = gql`
query getPaginatedDisplayAdsList($filter: filter) {
    getPaginatedDisplayAdsList(filter: $filter) {
      count
      page
      limit
      data {
        _id
        adName
        adType
        url
        portraitImage
        landscapeImage
        displayOrder
        status  
      }
    }
}
`