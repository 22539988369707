import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";

const HostelSalarySubFields = ({ setDataToSubmit, editData }) => {
  const [subHeadType, setSubHeadType] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const HostelSalaryType = [
    { label: "Hostel", value: "Hostel" },
    { label: "F&B", value: "F&B" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );
    setStartDate(
      editData?.subFields?.startDate !== null
        ? editData?.subFields?.startDate
        : new Date()
    );
    setEndDate(
      editData?.subFields?.endDate !== null
        ? editData?.subFields?.endDate
        : new Date()
    );
  }, [editData]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
      startDate,
      endDate,
    };

    setDataToSubmit(data);
  }, [subHeadType, startDate, endDate]);
  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="subHeadType">
            Salary Type
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={HostelSalaryType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            // isDisabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="start date">
            Salary Start Date
          </Label>

          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={startDate}
            onChange={(date) =>
              setStartDate(new Date(dateFormatter(new Date(date))))
            }
            id="startDate"
            placeholder="Select start date"
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="end date">
            Salary End Date
          </Label>

          <Flatpickr
            className="form-control bg-white border-dark text-dark"
            value={endDate}
            onChange={(date) =>
              setEndDate(new Date(dateFormatter(new Date(date))))
            }
            id="endDate"
            placeholder="Select end date"
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default HostelSalarySubFields;
