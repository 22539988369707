import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { Trash, Camera } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
  useUpdateStepOutProduct,
  useUploadImage,
} from "../../../api/dataSource";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import Avatar from "@components/common/avatar";
import confirmDelete from "@components/common/confirmDelete";

const Itinerary = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [updateStepOutProductHandler, { data }] = useUpdateStepOutProduct();
  const [uploadImageHandler, { data: imageData }] = useUploadImage();
  const [itinerary, setItinerary] = useState([
    {
      id: uuidv4(),
      name: "",
      displayOrder: 0,
      title: "",
      description: "",
      accommodation: "",
      activity: "",
      meals: "",
      transportation: "",
      images: [],
    },
  ]);
  const [index, setIndex] = useState("");
  const itineraryData = state?.tableData?.itinerary;

  useEffect(() => {
    if (itineraryData && itineraryData.length > 0) {
      let upadtedHighlight = itineraryData?.map((item) => {
        return {
          id: uuidv4(),
          name: item?.name,
          displayOrder: item?.displayOrder,
          title: item?.title,
          description: item?.description,
          accommodation: item?.accommodation,
          activity: item?.activity,
          meals: item?.meals,
          transportation: item?.transportation,
          images: item?.images,
        };
      });

      setItinerary(upadtedHighlight);
    }
  }, [itineraryData]);

  useEffect(() => {
    if (data?.updateStepOutProduct) {
      toaster("success", "Itinerary updated successfully");
    }
  }, [data?.updateStepOutProduct, itineraryData]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      let images = [];
      setItinerary(
        itinerary?.map((item) => {
          if (item?.id === index) {
            return {
              ...item,
              images: [...item?.images, imageData?.uploadImage?.url],
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  const fileUploader = (e, id, index) => {
    if (!e.target?.files[0]) return;
    setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }

    uploadImageHandler(
      e.target?.files[0],
      `${state?.tableData?.name?.toLowerCase()?.replaceAll(" ", "-")}` +
        `/STEPOUT`
    );
    toaster("success", "Itinerary image uploaded successfully");
  };

  const handleTextChangeName = (val, id, key) =>
    setItinerary(
      itinerary.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeTitle = (val, id, key) =>
    setItinerary(
      itinerary.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDescription = (val, id, key) =>
    setItinerary(
      itinerary.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDisplayOrder = (val, id, key) =>
    setItinerary(
      itinerary.map((item) =>
        item.id === id ? { ...item, [key]: parseInt(val) } : item
      )
    );

  const handleTextChangeAccommodation = (val, id, key) =>
    setItinerary(
      itinerary.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeActivity = (val, id, key) =>
    setItinerary(
      itinerary.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeMeals = (val, id, key) =>
    setItinerary(
      itinerary.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeTransportation = (val, id, key) =>
    setItinerary(
      itinerary.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleAddStepOutProduct = () =>
    setItinerary([
      ...itinerary,
      {
        id: uuidv4(),
        name: "",
        title: "",
        description: "",
        displayOrder: "",
        accommodation: "",
        activity: "",
        meals: "",
        transportation: "",
        images: [],
      },
    ]);

  const handleRemoveStepOutProduct = (id) => {
    // eslint-disable-next-line array-callback-return
    let newStepOutProductData = itinerary.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setItinerary(newStepOutProductData));
  };

  const handleSubmitHandler = () => {
    let isValid = true;
    let StepOutProductClone = JSON.parse(JSON.stringify(itinerary));
    StepOutProductClone.forEach((item) => {
      delete item.id;
    });

    let itineraryData = { itinerary: StepOutProductClone };

    StepOutProductClone?.forEach((item) => {
      isValid &&
        (item?.title && item?.description
          ? (isValid = true)
          : (isValid = false));
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...itineraryData,
        })
      );
      updateStepOutProductHandler(id, itineraryData);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitHandler();
      }}
    >
      {itinerary.map(
        (
          {
            id,
            name,
            displayOrder,
            title,
            description,
            accommodation,
            activity,
            meals,
            transportation,
            images,
          },
          index
        ) => (
          <div key={id}>
            <Row className="mt-2">
              <Col sm="12" md="11">
                <div style={{ display: "flex" }}>
                  <div className="mt-2 d-flex align-items-center ml-2">
                    <div className="mb-1 mr-2">
                      {images?.map((image, index) => (
                        <Avatar
                          key={index}
                          img={image}
                          alt={image}
                          className="ml-2"
                          imgHeight={100}
                          imgWidth={100}
                        />
                      ))}
                    </div>

                    <label htmlFor={`myFile${id}`}>
                      <p className="btn btn-sm btn-light">
                        <Camera size={15} />
                      </p>
                      <input
                        name={`myFile${id}`}
                        id={`myFile${id}`}
                        className="d-none"
                        type="file"
                        onChange={(e) => fileUploader(e, id)}
                      />
                    </label>
                  </div>
                </div>

                <FormGroup>
                  <FormGroup className="w-100">
                    <Label className="form-label" for="name">
                      Name
                    </Label>

                    <Input
                      type="text"
                      value={name}
                      id="name"
                      name="name"
                      placeholder={`Name`}
                      onChange={(e) =>
                        handleTextChangeName(e.target.value, id, "name")
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="displayOrder">
                      Display order
                    </Label>

                    <Input
                      rows={4}
                      type="number"
                      value={displayOrder}
                      id="displayOrder"
                      name="displayOrder"
                      placeholder="Display order"
                      onChange={(e) =>
                        handleTextChangeDisplayOrder(
                          e.target.value,
                          id,
                          "displayOrder"
                        )
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="title">
                      Title
                    </Label>

                    <Input
                      type="text"
                      value={title}
                      id="title"
                      name="title"
                      placeholder={`Title`}
                      onChange={(e) =>
                        handleTextChangeTitle(e.target.value, id, "title")
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="description">
                      Description
                    </Label>

                    <Input
                      rows={4}
                      type="textarea"
                      value={description}
                      id="description"
                      name="description"
                      placeholder="Description"
                      onChange={(e) =>
                        handleTextChangeDescription(
                          e.target.value,
                          id,
                          "description"
                        )
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="accommodation">
                      Accommodation
                    </Label>

                    <Input
                      type="text"
                      value={accommodation}
                      id="accommodation"
                      name="accommodation"
                      placeholder={`Title`}
                      onChange={(e) =>
                        handleTextChangeAccommodation(
                          e.target.value,
                          id,
                          "accommodation"
                        )
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="activity">
                      Activity
                    </Label>

                    <Input
                      type="text"
                      value={activity}
                      id="activity"
                      name="activity"
                      placeholder={`Activity`}
                      onChange={(e) =>
                        handleTextChangeActivity(e.target.value, id, "activity")
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="meals">
                      Meals
                    </Label>

                    <Input
                      type="text"
                      value={meals}
                      id="meals"
                      name="meals"
                      placeholder={`Meals`}
                      onChange={(e) =>
                        handleTextChangeMeals(e.target.value, id, "meals")
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="transportation">
                      Transportation
                    </Label>

                    <Input
                      type="text"
                      value={transportation}
                      id="transportation"
                      name="transportation"
                      placeholder={`Transportation`}
                      onChange={(e) =>
                        handleTextChangeTransportation(
                          e.target.value,
                          id,
                          "transportation"
                        )
                      }
                    />
                  </FormGroup>
                </FormGroup>
              </Col>

              <Col sm="12" md="1">
                {itinerary.length > 1 ? (
                  <>
                    <Button.Ripple
                      className="btn-icon rounded-circle mb-1 mr-1"
                      color="light"
                      type="button"
                      size="sm"
                      onClick={() => handleRemoveStepOutProduct(id)}
                    >
                      <Trash size={14} />
                    </Button.Ripple>
                  </>
                ) : null}
              </Col>
            </Row>
            <hr key={index} style={{ border: "1px solid #ffe700" }} />
          </div>
        )
      )}

      <SaveDiscardBtnGroup addAction={handleAddStepOutProduct} />
    </form>
  );
};

export default Itinerary;
