import gql from "graphql-tag";

export const CREATE_HOSTEL_COMPARISON = gql`
  mutation createHostelComparison($input: input) {
    createHostelComparison(input: $input) {
      _id
    }
  }
`;

export const UPDATE_HOSTEL_COMPARISON = gql`
  mutation updateHostelComparison($id: ID, $input: input) {
    updateHostelComparison(id: $id, input: $input) {
      _id
    }
  }
`;

//Added by Ashish
export const DELETE_HOSTEL_COMPARISON = gql`
  mutation DeleteHostelComparison($id: ID!) {
    deleteHostelComparison(id: $id) {
      _id
    }
  }
`;
