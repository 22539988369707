import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Plus, X, Camera } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import {
  useUpdateHomePageDetails,
  useUploadImage,
  useHostelList,
  useGetHomePageDetails,
} from "@api";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import Avatar from "@components/common/avatar";
import toaster from "@components/common/Toaster";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { dateFormatter } from "@utils";
import confirmDelete from "@components/common/confirmDelete";

const Events = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateHomePageDetailsHandler, { data: updatedHomePageData }] =
    useUpdateHomePageDetails();
  const [HostelsList, setHostelsList] = useState([]);
  const [events, setEvents] = useState([
    {
      id: uuidv4(),
      title: "",
      description: "",
      eventColor: "#ffe700",
      eventDate: new Date(),
      hostel: null,
    },
  ]);
  const [index, setIndex] = useState("");

  const { data: allHostelsData } = useHostelList();

  const { data: HomePageData, refetch } = useGetHomePageDetails(id);

  const [uploadEventImageHandler, { data: eventImageData }] = useUploadImage();

  useEffect(() => {
    if (state?.tableData?.events?.length) {
      let updatedEvents = state?.tableData?.events?.map((item) => {
        return {
          id: uuidv4(),
          title: item?.title !== null ? item?.title : "",
          description: item?.description !== null ? item?.description : "",
          eventColor: item?.eventColor !== null ? item?.eventColor : "#ffe700",
          eventDate: item?.eventDate !== null ? item?.eventDate : new Date(),
          image: item?.image !== null ? item?.image : "",
          hostel:
            item?.hostel?._id !== null
              ? { label: item?.hostel?.name, value: item?.hostel?._id }
              : null,
        };
      });
      setEvents(updatedEvents);
    }
  }, [state]);

  useEffect(() => {
    if (eventImageData?.uploadImage?.url) {
      setEvents(
        events.map((item) => {
          if (item.id === index) {
            return {
              ...item,
              image: eventImageData?.uploadImage?.url,
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [eventImageData?.uploadImage?.url]);

  const handleRemoveEvent = (id) =>
    confirmDelete(() => setEvents(events.filter((item) => item.id !== id)));

  const handleAddEvent = () =>
    setEvents([
      ...events,
      {
        id: uuidv4(),
        title: "",
        description: "",
        eventColor: "#ffe700",
        eventDate: new Date(),
        hostel: null,
      },
    ]);

  const handleChangeEventTitle = (title, id) => {
    setEvents(
      events.map((item) => (item.id === id ? { ...item, title: title } : item))
    );
  };

  const handleChangeEventDescription = (description, id) => {
    setEvents(
      events.map((item) =>
        item.id === id ? { ...item, description: description } : item
      )
    );
  };

  const handleChangeEventDate = (eventDate, id) => {
    setEvents(
      events.map((item) =>
        item.id === id ? { ...item, eventDate: eventDate } : item
      )
    );
  };
  const handleChangeEventColor = (eventColor, id) => {
    setEvents(
      events.map((item) =>
        item.id === id ? { ...item, eventColor: eventColor } : item
      )
    );
  };

  const handleChangeSelectedHostel = (value, id) => {
    setEvents(
      events.map((item) =>
        item.id === id
          ? { ...item, hostel: { label: value.label, value: value.value } }
          : item
      )
    );
  };

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      // eslint-disable-next-line array-callback-return
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (updatedHomePageData?.updateHomePage?._id) {
      // toaster("success", "Updated successfully");
      refetch();
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          events,
        })
      );
    }
  }, [dispatch, updatedHomePageData?.updateHomePage?._id]);

  const submitDataHandler = () => {
    let isValid = false;

    let data = {
      events: events?.map((eventItem) => {
        const { title, description, eventColor, eventDate, hostel, image } =
          eventItem;

        return {
          title,
          description,
          eventColor,
          eventDate,
          hostel: hostel?.value,
          image,
        };
      }),
    };

    events?.forEach((item) => {
      if (item?.title && item?.description) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    // if (isValid) {
    events?.forEach((item) => delete item.id);

    // dispatch(
    //   tableDataHandler({
    //     ...state?.tableData,
    //     events: data?.events,
    //   })
    // );
    updateHomePageDetailsHandler(id, data);
    toaster("success", "Events updated successfully");
    // } else {
    //   toaster("error", "Please fill all the event details");
    // }
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }
    uploadEventImageHandler(
      e.target.files[0],
      `${state.tableData?.name?.toLowerCase()?.replaceAll(" ", "-")}` +
        `/HOMEPAGE`
    );

    toaster("success", "Events image uploaded successfully");
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDataHandler();
      }}
    >
      <Row className="mt-2">
        {events.map(
          (
            { id, title, description, eventColor, eventDate, image, hostel },
            index
          ) => (
            <Col className="d-flex align-items-center" sm="12" key={id}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"Event" + id}>
                  Events {index + 1}
                </Label>

                <div className="mt-2 d-flex align-items-center">
                  <div className="mb-1 mr-2">
                    {
                      <Avatar
                        img={image}
                        alt={image}
                        imgHeight={100}
                        imgWidth={100}
                      />
                    }
                  </div>

                  <label htmlFor={`myFile${id}`}>
                    <p className="btn btn-sm btn-light">
                      <Camera size={15} />
                    </p>
                    <input
                      name={`myFile${id}`}
                      id={`myFile${id}`}
                      className="d-none"
                      type="file"
                      onChange={(e) => fileUploader(e, id)}
                    />
                  </label>
                </div>

                <Row>
                  <Col sm="6">
                    <Label className="form-label" for="title">
                      Title
                    </Label>
                    <Input
                      type="text"
                      value={title}
                      id={"title" + id}
                      name={"title"}
                      placeholder="Title"
                      onChange={(e) =>
                        handleChangeEventTitle(e.target.value, id)
                      }
                    />
                    <br />
                  </Col>

                  <Col sm="6">
                    <Label className="form-label" for="description">
                      Description
                    </Label>
                    <Input
                      type="textarea"
                      value={description}
                      id={"description" + id}
                      name={"description"}
                      placeholder="Description"
                      onChange={(e) =>
                        handleChangeEventDescription(e.target.value, id)
                      }
                    />
                    <br />
                  </Col>

                  <Col sm="6">
                    <Label className="form-label" for="eventDate">
                      Event Date
                    </Label>
                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      value={eventDate}
                      onChange={(date) =>
                        handleChangeEventDate(
                          new Date(dateFormatter(new Date(date))),
                          id
                        )
                      }
                      id="eventDate"
                      placeholder="Add Event Date"
                      required
                    />
                    <br />
                  </Col>

                  <Col sm="6">
                    <Label className="form-label" for="eventColor">
                      Event Color
                    </Label>
                    <Input
                      type="color"
                      value={eventColor}
                      id="eventColor"
                      name="eventColor"
                      placeholder="Add Event Color"
                      onChange={(e) =>
                        handleChangeEventColor(e.target.value, id)
                      }
                    />
                    <br />
                  </Col>

                  <Col sm="12">
                    <Label className="form-label" for="title">
                      Hostel
                    </Label>
                    <Select
                      isSearchable={true}
                      id="hostel"
                      name="hostel"
                      value={hostel}
                      options={HostelsList}
                      onChange={(value) => {
                        handleChangeSelectedHostel(value, id);
                      }}
                      classNamePrefix="select"
                    />
                    <br />
                  </Col>
                </Row>
              </FormGroup>
              <br />
              {events.length > 0 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveEvent(id)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          )
        )}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddEvent} />
    </form>
  );
};

export default Events;
