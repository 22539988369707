import React, { useState } from "react";
import AddEditModal from "./AddEditModal";
import PressMentionTable from "../../../@core/components/tables/PressMentionTable";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";

const PressMention = () => {
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);

  const statusType = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const handleSearch = () => {
    const data = {
      title,
      status: status?.value,
    };
    setFilterData(data);
    setPage(1);
  };

  const handleReset = () => {
    setTitle("");
    setStatus(null);
    setFilterData(null);
    setPage(1);
  };
  return (
    <>
      <Row>
        <Col sm="4">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="title">
                Title
              </Label>
            </strong>
            <Input
              type="text"
              value={title}
              id="title"
              name="title"
              placeholder="Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="status">
                Status
              </Label>
            </strong>
            <Select
              isSearchable={true}
              isClearable={true}
              id="status"
              name="status"
              value={status}
              options={statusType}
              onChange={(value) => setStatus(value)}
              className="react-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button>
          <Button
            className="pull-right"
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
      {localStorage.getItem("modules")?.includes("CMS") && (
        <PressMentionTable
          filterData={filterData}
          AddEditModal={AddEditModal}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
};
export default PressMention;
