import { Home } from 'react-feather';

export default [
  {
   
        id: 'guests',
        title: 'Guests',
        icon: <Home />,
        navLink: '/guests',
    
  },

];
