import React from "react";
import WorkationLandingPageTabs from "./Tabs";

const WorkationLanding = () => {
  return (
    localStorage.getItem("modules")?.includes("CMS") && (
      <WorkationLandingPageTabs />
    )
  );
};

export default WorkationLanding;
