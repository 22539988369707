import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Edit, Trash2 } from "react-feather";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";
import { useDeleteUnboxCoupon } from "../../../api/Unbox/UnboxCoupon/Mutations";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Promo Code",
  "Type",
  "Status",
  "Category",
  "Discount",
  "Expiry Date",
  "Max Discount Value",
  "Min Order Value",
];
const UnboxCouponTable = ({ openModal, tableData, setId, refetch }) => {
  // const [deleteUnboxCouponHandler] = useDeleteUnboxCoupon();
  // const deleteCouponFunction = async(id)=>{
  //     await deleteUnboxCouponHandler(id)
  //     refetch()
  // }
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>Unbox Coupon</CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Coupon
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData?.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>

            <tbody>
              {tableData?.map((item) => {
                return (
                  <tr key={uuidv4()}>
                    <td style={{ width: "9%" }}>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                        color="none"
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                      {/* <Button
                        onClick={() => {
                          deleteCouponFunction(item?._id);
                        }}
                        color="none"
                      >
                        <Trash2 size={15} />
                      </Button> */}
                    </td>
                    <td>{item?.promoCode}</td>
                    <td>{item?.couponType}</td>
                    <td>{item?.status}</td>
                    <td>{item?.couponCategory}</td>
                    <td>{item?.discount}</td>
                    <td>{dateFormatterDDMMYYYY(item?.expiryDate)}</td>
                    <td>{item?.maxDiscountValue}</td>
                    <td>{item?.minOrderValue}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default UnboxCouponTable;
