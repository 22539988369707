import React, { useEffect, useState } from "react";
import { Col, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";

const CapexSubHeadsSubFields = ({
  setDataToSubmit,
  editData,
  setTotalAmountWithoutGST,
}) => {
  const [subHeadType, setSubHeadType] = useState(null);
  const [vendorName, setVendorName] = useState("");
  const [warranty, setWarranty] = useState("");
  const [labourAmount, setLabourAmount] = useState("");
  const [materialAmount, setMaterialAmount] = useState("");

  const CapexSubFieldsType = [
    { label: "Civil", value: "Civil" },
    { label: "Furniture", value: "Furniture" },
    { label: "Furnishing", value: "Furnishing" },
    { label: "Fabrication", value: "Fabrication" },
    { label: "Electrical", value: "Electrical" },
    { label: "Electronics/Equipments", value: "Electronics/Equipments" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    setSubHeadType(
      editData?.subFields?.subHeadType !== null
        ? {
            label: editData?.subFields?.subHeadType,
            value: editData?.subFields?.subHeadType,
          }
        : null
    );
    setVendorName(
      editData?.subFields?.vendorName !== null
        ? editData?.subFields?.vendorName
        : ""
    );
    setWarranty(
      editData?.subFields?.warranty !== null
        ? editData?.subFields?.warranty
        : ""
    );
    setLabourAmount(
      editData?.subFields?.labourAmount !== null
        ? editData?.subFields?.labourAmount
        : ""
    );
    setMaterialAmount(
      editData?.subFields?.materialAmount !== null
        ? editData?.subFields?.materialAmount
        : ""
    );
  }, [editData]);

  const handleLabourAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setLabourAmount(value);
  };

  const handleMaterialAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setMaterialAmount(value);
  };

  useEffect(() => {
    const totalAmountWithoutGST =
      parseFloat(labourAmount?.toString().trim() || 0) +
      parseFloat(materialAmount?.toString().trim() || 0);

    setTotalAmountWithoutGST(totalAmountWithoutGST);
  }, [labourAmount, materialAmount]);

  useEffect(() => {
    const data = {
      subHeadType: subHeadType?.value,
      vendorName,
      warranty: Number(warranty),
      labourAmount: Number(labourAmount),
      materialAmount: Number(materialAmount),
    };
    setDataToSubmit(data);
  }, [subHeadType, vendorName, warranty, labourAmount, materialAmount]);

  return (
    <>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="subHeadType">
            Capex Type
          </Label>
          <Select
            isClearable={false}
            id="subHeadType"
            name="subHeadType"
            value={subHeadType}
            options={CapexSubFieldsType}
            required
            onChange={(value) => setSubHeadType(value)}
            classNamePrefix="select"
            // isDisabled={editData?._id}
          />
        </FormGroup>
      </Col>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="vendorName">
            Capex Vendor Name
          </Label>
          <Input
            type="text"
            value={vendorName}
            id="vendorName"
            name="vendorName"
            placeholder="Add Vendor Name"
            onChange={(e) => setVendorName(e.target.value)}
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>
      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="warranty">
            Capex Warranty (in months)
          </Label>
          <Input
            type="text"
            value={warranty}
            id="warranty"
            name="warranty"
            placeholder="Add Warranty"
            onChange={(e) => setWarranty(e.target.value)}
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="labourAmount">
            Capex Labour Amount (Without GST)
          </Label>
          <Input
            type="text"
            value={labourAmount}
            id="labourAmount"
            name="labourAmount"
            placeholder="Add Labour Amount"
            // onChange={(e) => setLabourAmount(e.target.value)}
            onChange={handleLabourAmountChange}
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>

      <Col sm="12">
        <FormGroup className="w-100">
          <Label className="form-label" for="materialAmount">
            Capex Material Amount (Without GST)
          </Label>
          <Input
            type="text"
            value={materialAmount}
            id="materialAmount"
            name="materialAmount"
            placeholder="Add Material Amount"
            // onChange={(e) => setMaterialAmount(e.target.value)}
            onChange={handleMaterialAmountChange}
            // disabled={editData?._id}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default CapexSubHeadsSubFields;
