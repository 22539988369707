import React from "react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Card, Row, CardHeader, CardTitle } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";

const FeedbackHostelWise = ({ hosteldata: data }) => {
  //for table
  const [tableData, setTableData] = useState(data);

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  });
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      let hostelTableData = [];
      let hostelList = [];
      let good = [],
        bad = [],
        excellent = [];
      data?.map((item) => {
        hostelList.push(item?._id);

        let badCount = 0,
          goodCount = 0,
          excellentCount = 0;
        item?.feedbacks?.map((item2) => {
          if (item2.customerFeedback === "Bad") {
            badCount = item2.feedbackCount;
          } else if (item2.customerFeedback === "Good") {
            goodCount = item2.feedbackCount;
          } else {
            excellentCount = item2.feedbackCount;
          }
        });
        bad.push(badCount);
        good.push(goodCount);
        excellent.push(excellentCount);

        hostelTableData.push({
          hostelName: item?._id,
          bad: badCount,
          good: goodCount,
          excellent: excellentCount,
        });
      });
      setTableData(hostelTableData);
      setSeries([
        {
          name: "Bad",
          data: bad,
        },
        {
          name: "Good",
          data: good,
        },

        {
          name: "Excellent",
          data: excellent,
        },
      ]);

      setOptions({
        colors: ["#FF2C2C", "#FFE700", "#4CAF50"],

        chart: {
          type: "bar",
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "16px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
        },
        title: {},
        xaxis: {
          categories: hostelList,
          title: {
            text: "FEEDBACK COUNT",
          },
        },
        yaxis: {
          title: {
            text: "HOSTEL WISE FEEDBACK",
          },
        },

        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
          offsetX: 40,
        },
      });
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row className="mt-2">
      <Col sm="6">
        <Card>
          <CardHeader>
            <CardTitle>Hostelwise feedback analysis</CardTitle>
          </CardHeader>
          <div style={{ height: "1400px", overflow: "auto" }}>
            {/* <div style={{ height: "auto", overflow: "auto" }}> */}{" "}
            <Table responsive>
              <thead>
                <tr>
                  <td>
                    <h5>Hostel Name</h5>
                  </td>
                  <td>
                    <h5>Bad</h5>
                  </td>
                  <td>
                    <h5>Good</h5>
                  </td>
                  <td>
                    <h5>Excellent</h5>
                  </td>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item) => (
                  <tr key={uuidv4()}>
                    <td>{item?.hostelName.replace("The Hosteller", "")}</td>
                    <td>{item?.bad}</td>
                    <td>{item?.good}</td>
                    <td>{item?.excellent}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>

      <Col sm="6">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            // height={"auto"}
            height={1500}
          />
        </div>
      </Col>
    </Row>
  );
};

export default FeedbackHostelWise;
