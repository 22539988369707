// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

const DetailsForm = () => {
  const state = useSelector((state) => state?.cms);

  const [destination, setDestination] = useState("");
  const [stateOfIND, setStateOfIND] = useState("");
  const [description, setDescription] = useState("");
  const [interest, setInterest] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (state?.tableData) {
      setDestination(state?.tableData?.destination);
      setDescription(state?.tableData?.description);
      setStateOfIND(state?.tableData?.state);
      setStatus(state?.tableData?.status === true ? "Active" : "Inactive");
      setInterest(state?.tableData?.interest);
    }
  }, [state]);
  return (
    <>
      <form>
        <Row className="mt-2">
          <Col sm="12" md="6">
            <FormGroup>
              <Label className="form-label" for="name">
                Destination Name
              </Label>
              <Input
                type="text"
                disabled
                defaultValue={destination}
                id="destination"
                name="destination"
                placeholder="destination"
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup>
              <Label className="form-label" for="description">
                Destination Description
              </Label>
              <Input
                type="text"
                disabled
                defaultValue={description}
                id="description"
                name="description"
                placeholder="Destination Description"
              />
            </FormGroup>
          </Col>

          <Col sm="12" md="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="state">
                State of India
              </Label>
              <Input
                type="text"
                disabled
                defaultValue={stateOfIND}
                id="stateOfIND"
                name="stateOfIND"
                placeholder="State of India"
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="status">
                Status
              </Label>
              <Input
                type="text"
                disabled
                value={status}
                id="status"
                name="status"
                placeholder="Status"
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="interest">
                Interest
              </Label>
              <Input
                type="text"
                disabled
                defaultValue={interest}
                id="interest"
                name="interest"
                placeholder="Interest"
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default DetailsForm;
