import React, { useEffect, useState } from "react";
import PropertyWiseNoShowCancellationTable from "./PropertyWiseNoShowCancellationTable";
import {
  useGetPropertyWiseNoShowAndCancellationReport,
  useExportNoshowCancelReservationData,
} from "../../../api/PropertyWiseNoShowCancellation/Queries";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { dateValidator, dateFormatter, addDays } from "../../../utility/Utils";
import toaster from "@components/common/Toaster";

const PropertyWiseNoShowCancellation = () => {
  const [tableData, setTableData] = useState([]);
  const [reservationData, setReservationData] = useState([]);
  const [dates, setDates] = useState(null);
  const [startDate, setStartDate] = useState(dateFormatter(new Date()));
  const [endDate, setEndDate] = useState(dateFormatter(new Date()));
  const [BookingStatus, setBookingStatus] = useState({
    label: "Cancel",
    value: "Cancel",
  });
  const [reportType, setReportType] = useState({
    label: "Property Wise",
    value: "Property Wise",
  });
  const { data } = useGetPropertyWiseNoShowAndCancellationReport(dates);
  const { data: reservationDetails } =
    useExportNoshowCancelReservationData(dates);

  const fromDateHandler = (date) => {
    setStartDate(dateFormatter(new Date(date)));
  };

  const toDateHandler = (date) => {
    setEndDate(dateFormatter(new Date(date)));
  };

  const tableHeaderData = [
    {
      label: dates?.reportType === "Property Wise" ? "Hostel" : "Source",
      key: dates?.reportType === "Property Wise" ? "hostelName" : "_id",
    },

    { label: "Paid Booking", key: "paidBookings" },
    { label: "Unpaid Booking", key: "unpaidBookings" },
    { label: "Total Bookings", key: "totalBookings" },
    { label: "Revenue", key: "revenue" },
  ];
  const exportReservationDetails = [
    { label: "ReservationNo", key: "ReservationNo" },
    { label: "GuestName", key: "GuestName" },
    { label: "Email", key: "Email" },
    { label: "ReservationDate", key: "ReservationDate" },
    { label: "ArrivalDate", key: "ArrivalDate" },
    { label: "DepartureDate", key: "DepartureDate" },
    { label: "Hostel Name", key: "hostelName" },
    { label: "Mobile", key: "Mobile" },
    { label: "Status", key: "Status" },
    { label: "BookingStatus", key: "BookingStatus" },
    { label: "Source", key: "Source" },
    { label: "Deposit", key: "Deposit" },
    { label: "Due Amount", key: "DueAmount" },
    { label: "No Of Guest", key: "NoOfGuest" },
    { label: "No Of Nights", key: "NoOfNights" },
    { label: "Room", key: "Room" },
    { label: "TotalExclusiveTax", key: "TotalExclusivTax" },
    { label: "TotalInclusiveTax", key: "TotalInclusiveTax" },
    { label: "Totaltax", key: "Totaltax" },
  ];

  const ReportTypeList = [
    { label: "Property Wise", value: "Property Wise" },
    { label: "Source Wise", value: "Source Wise" },
  ];

  const BookingStatusList = [
    { label: "No Show", value: "No Show" },
    { label: "Cancel", value: "Cancel" },
  ];

  useEffect(() => {
    if (data?.getPropertyWiseNoShowAndCancellationReport?.data) {
      setTableData(data?.getPropertyWiseNoShowAndCancellationReport?.data);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (reservationDetails?.exportNoshowCancelReservationData) {
      setReservationData(reservationDetails?.exportNoshowCancelReservationData);
    } else {
      setReservationData([]);
    }
  }, [reservationDetails?.exportNoshowCancelReservationData]);

  const handleSearch = () => {
    if (dateValidator(startDate, endDate)) {
      const dates = {
        startDate,
        endDate,
        BookingStatus: BookingStatus?.value,
        reportType: reportType?.value,
      };
      setDates(dates);
    } else {
      toaster("error", "Invalid Dates");
    }
  };

  const handleResetData = () => {
    setStartDate(dateFormatter(new Date()));
    setEndDate(dateFormatter(new Date()));
    setBookingStatus(null);
    setReportType(null);
    setDates(null);
  };
  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="startDate">
                From Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={startDate}
                onChange={fromDateHandler}
                id="startDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="endDate">
                To Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={endDate}
                onChange={toDateHandler}
                id="endDate"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="reportType">
                Report Type
              </Label>

              <Select
                isClearable={false}
                isSearchable={true}
                id="reportType"
                name="reportType"
                value={reportType}
                options={ReportTypeList}
                onChange={(value) => {
                  setReportType(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="BookingStatus">
                Booking Status
              </Label>

              <Select
                isClearable={false}
                isSearchable={true}
                id="BookingStatus"
                name="BookingStatus"
                value={BookingStatus}
                options={BookingStatusList}
                onChange={(value) => {
                  setBookingStatus(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col style={{ marginTop: "22px", display: "flex", align: "left" }}>
            <Button.Ripple
              onClick={handleSearch}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="primary"
              appearance="danger"
              type="button"
            >
              Search
            </Button.Ripple>
            <Button.Ripple
              onClick={handleResetData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button.Ripple>

            <FormGroup>
              <CSVLink
                headers={tableHeaderData}
                data={tableData}
                filename={"Bookings_report.csv"}
              >
                <Button.Ripple
                  style={{
                    backgroundColor: "#ffe700",
                    height: "40px",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="success"
                  appearance="success"
                  type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            </FormGroup>
            <FormGroup>
              <CSVLink
                headers={exportReservationDetails}
                data={reservationData}
                filename={"Bookings_report.csv"}
              >
                <Button.Ripple
                  style={{
                    backgroundColor: "#ffe700",
                    height: "40px",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="secondary"
                  appearance="success"
                  type="button"
                >
                  Export Details
                </Button.Ripple>
              </CSVLink>
            </FormGroup>
          </Col>
        </Row>

        {tableData.length > 0 ? (
          <PropertyWiseNoShowCancellationTable
            data={tableData}
            filter={dates}
          />
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Record Found
          </h2>
        )}
      </div>
    )
  );
};

export default PropertyWiseNoShowCancellation;
