import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Edit, FileText } from "react-feather";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";
const tableHeaderData = ["Action", "", "Product Name", "Price", "Tax(%)"];

const MerchandiseProductTable = ({
  openModal,
  setId,
  tableData,
  handleCreateUpdateMerchandiseProducts,
}) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>Merchandise</h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add product
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td style={{ width: "10px" }}>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>

                  <td style={{ width: "10px" }}>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCreateUpdateMerchandiseProducts(item?._id);
                      }}
                    >
                      <FileText
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>{item?.name}</td>
                  <td>{item?.price}</td>
                  <td>{item?.tax}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default MerchandiseProductTable;
