// ! Added by Ashwin
import gql from "graphql-tag";

export const CREATE_ADMIN_USERS = gql`
  mutation createAdminUsers($adminUserInput: AdminUserInput) {
    createAdminUsers(adminUserInput: $adminUserInput) {
      department
      name
      email
      password
      role
      modules
      hostels
      status
      message
      logs
    }
  }
`;

export const UPDATE_ADMIN_USERS = gql`
  mutation updateAdminUsers($id: ID, $adminUserUpdate: AdminUserInput) {
    updateAdminUsers(id: $id, adminUserUpdate: $adminUserUpdate) {
      name
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation forgetPassword($email: String) {
    forgetPassword(email: $email) {
      status
    }
  }
`;

export const RESET_ADMIN_USERS_PASSWORD = gql`
  mutation resetAdminUserPassword(
    $email: String
    $oldPassword: String
    $newPassword: String
    $confirmNewPassword: String
  ) {
    resetAdminUserPassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
    ) {
      status
      message
    }
  }
`;

export const UPDATE_FIREBASE_TOKEN = gql`
  mutation updateFirebaseToken($email : String!, $token: String!) {
    updateFirebaseToken(email: $email, token: $token) {
        token
    }
  }
`