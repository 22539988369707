import React, { useEffect } from "react";
import { FormGroup, Input, Label, Row, Col, CardBody, Card } from "reactstrap";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import { useUploadImage } from "@api";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const ExperienceCard = ({ title, section = {}, setSection }) => {
  const handleChange = (e) => {
    e.preventDefault();
    setSection((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const [uploadImageHandler, { loading: imageLoad, data: imageData }] =
    useUploadImage();

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 1000000,
    },
  });

  useEffect(() => {
    if (imageData?.uploadImage) {
      setSection((prev) => ({ ...prev, image: imageData?.uploadImage?.url }));
    }
  }, [imageData?.uploadImage]);

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file) => {
    uploadImageHandler(file.data, `about`);
  });

  const renderPreview = () => {
    return (
      section.image && (
        <img
          className='rounded mr-1 mb-2 w-50'
          src={section.image}
          alt='avatar'
        />
      )
    );
  };

  return (
    <Card className='border mx-1'>
      <CardBody>
        <h4 className='mb-2'>{title} Section</h4>

        <Row>
          <Col className='ml-1' sm='12'>
            <FormGroup className='w-100'>
              <Label className='form-label' htmlFor='description'>
                description
              </Label>

              <Input
                type='text'
                value={section.description || ""}
                id='description'
                name='description'
                placeholder='description'
                onChange={handleChange}
              />
            </FormGroup>
          </Col>

          <Col sm='6' className='mb-2'>
            <Label className='form-label' htmlFor='location'>
              Card Image
            </Label>
            <DragDrop
              uppy={uppy}
              width='100%'
              note='Max size 1MB'
              locale={{
                strings: {
                  dropHereOr: "Drop image here or %{browse}",
                  browse: "browse",
                },
              }}
            />
          </Col>
          <Col sm='6'>
            <Label className='form-label w-100'>Image preview here</Label>
            {imageLoad ? "Uploading image..." : renderPreview()}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ExperienceCard;
